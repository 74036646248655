import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CpsDetailWysiwyg = _resolveComponent("CpsDetailWysiwyg")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, { "container-fluid": "" }, {
    default: _withCtx(() => [
      _createVNode(_component_CpsDetailWysiwyg, {
        id: _ctx.id,
        mode: _ctx.mode,
        "lib-code": _ctx.libCode
      }, null, 8, ["id", "mode", "lib-code"])
    ]),
    _: 1
  }))
}