
import {defineComponent} from "vue";
import {mapActions} from 'vuex';
import {TextWithTitleContentRestDtoModel} from "@/models/api/pharma-cms-content-block/TextWithTitleContentRestDtoModel";
import {CodeDescriptionRestDto,} from "@/api/pharma-cms-content-block/models";
import AbstractCbCommon from "@/components/layouts/content-pages/wysiwyg/structures/AbstractCbCommon.vue";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import {ContentBlockTypesEnum} from "@/helpers/functions/enum";
import {CopyCpAbstractPayload} from "@/models/payload/CopyCpAbstractPayload";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import InputText from "@/components/UI/InputText.vue";
import InputTextEditor from "@/components/UI/InputTextEditor.vue";

export default defineComponent({
  name: "CbTextWithTitle",
  components: {InputTextEditor, InputText, InputSelect, BaseSpinner},
  extends: AbstractCbCommon,
  data: () => ({
    contentBlock: TextWithTitleContentRestDtoModel.createWithDefaults(),
  }),
  watch: {
    contentBlock() {
      this.onCopyCpAbstractChanged();
    },
  },
  computed: {
    enumTitleSizeCode(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.TITLE_SIZE);
    },
    isPageTitle(): boolean {
      return this.cbType === ContentBlockTypesEnum.PAGE_TITLE;
    },
  },
  methods: {
    ...mapActions('cms_content_block/normal_content_block/text_with_title', [
      'create', 'find', 'save',
    ]),
    determineEnums(): EnumClassNameCmsEnum[] {
      return [
        EnumClassNameCmsEnum.TITLE_SIZE,
      ];
    },
    getDefaultCbModel(): TextWithTitleContentRestDtoModel {
      return TextWithTitleContentRestDtoModel.createWithDefaults();
    },
    onCopyCpAbstractChanged(): void {
      const title = this.contentBlock.title?.nl ?? null;
      const content = this.contentBlock.content?.nl ?? null;
      this.copyCpAbstractPayload = new CopyCpAbstractPayload(content, null, title);

      this.$emit('abstractContentChanged', this.copyCpAbstractPayload);
    },
    pasteCbMetadata(): void {
      // When calling clone method directly on object, method is not found. So this is a hacky workaround
      const cbClone = TextWithTitleContentRestDtoModel.cloneFrom(this.contentBlock);

      cbClone.cloneMetadataFrom(this.getClipboardContentBlock(this.cbType) as TextWithTitleContentRestDtoModel);
      this.contentBlock = cbClone;
    },
  }
});
