
import {defineComponent} from "vue";
import {mapActions} from 'vuex';
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import AbstractCbCommon from "@/components/layouts/content-pages/wysiwyg/structures/AbstractCbCommon.vue";
import {DocumentContentRestDtoModel} from "@/models/api/pharma-cms-content-block/DocumentContentRestDtoModel";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import Subtitle from "@/components/UI/Subtitle.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import InputFile from "@/components/UI/InputFile.vue";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import InputText from "@/components/UI/InputText.vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import {ButtonContentRestDtoModel} from "@/models/api/pharma-cms-content-block/ButtonContentRestDtoModel";

export default defineComponent({
  name: "CbDocument",
  components: {InputSelect, InputText, InputCheckboxBoolean, InputFile, BaseSpinner, Subtitle},
  extends: AbstractCbCommon,
  data: () => ({
    contentBlock: DocumentContentRestDtoModel.createWithDefaults(),
    docType: 'PAGE_DOC' as string,
  }),
  computed: {
    btnLayoutOptions(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.BUTTON_LAYOUT);
    },
    existingDocumentKey(): string {
      return this.contentBlock.document_key;
    },
  },
  methods: {
    ...mapActions('cms_content_block/normal_content_block/document', ['create', 'find', 'save']),
    // todo check if doc key is set on upload, else throw an exception
    // if (!this.contentBlock.document_key.length) {
    //   throw [new DpException('Het uploaden van een document is verplicht bij het opslaan.')];
    // }
    determineEnums(): EnumClassNameCmsEnum[] {
      return [
        EnumClassNameCmsEnum.BUTTON_LAYOUT
      ];
    },
    getDefaultCbModel(): DocumentContentRestDtoModel {
      return DocumentContentRestDtoModel.createWithDefaults();
    },
    setDocumentKey(key: string | null) {
      this.contentBlock.document_key = key ?? '';
    },
    pasteCbMetadata(): void {
      // When calling clone method directly on object, method is not found. So this is a hacky workaround
      const cbClone = DocumentContentRestDtoModel.cloneFrom(this.contentBlock);

      cbClone.cloneMetadataFrom(this.getClipboardContentBlock(this.cbType) as DocumentContentRestDtoModel);
      this.contentBlock = cbClone;
    },
  }
});
