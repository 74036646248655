
import {defineComponent} from 'vue'
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {CodeDescriptionRestDto} from "@/api/pharma-order-settings/models";
import {DeliveryMethodsRestService} from "@/services/rest/settings/DeliveryMethodsRestService";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import BaseContainer from "@/components/UI/Bootstrap/BaseContainer.vue";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import {useToast} from "vue-toastification";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";

export default defineComponent({
  name: "DeliveryMethods",
  components: {
    BaseContainer,
    BaseTitle,
    AlertError2,
  },
  data() {
    return {
      authorizedMethods: [] as CodeDescriptionRestDto[],
      supportedMethodCodes: [] as string[],

      toaster: useToast(),
      pageUI: UIStateDto.createWithDefaults(),
    }
  },
  mounted() {
    this.reloadContent();
    this.pageUI.setReady();
  },
  methods: {
    async reloadContent(): Promise<void> {
      this.pageUI
        .setNotReady()
        .clearError();

      try {
        this.authorizedMethods = await DeliveryMethodsRestService.getInstance().findAuthorizedDeliveryMethods();
        this.supportedMethodCodes = await DeliveryMethodsRestService.getInstance().findSupportedDeliveryMethodCodes();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.pageUI.setReady();
      }
    },
    isActive(methodCode: string): boolean {
      return this.supportedMethodCodes.includes(methodCode);
    },
    toggleIsActive(methodCode: string): void {
      if (this.isActive(methodCode)) {
        this.supportedMethodCodes = this.supportedMethodCodes.filter(item => item !== methodCode);
      } else {
        this.supportedMethodCodes.push(methodCode);
      }
    },
    async submitData(): Promise<void> {
      this.pageUI
        .setNotReady()
        .clearError();

      try {
        await DeliveryMethodsRestService.getInstance()
          .synchronizeSupportedDeliveryMethods(this.supportedMethodCodes);

        await handleSavedSuccessfully();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.pageUI.setReady();
      }
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .catch(exceptions, true);
    }
  }
})
