import {defineStore} from "pinia";
import {ColorSchemeRestDto} from "@/api/pharma-cms-color-scheme/models";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import {ContentPageRowClipboardModel} from "@/models/clipboard/ContentPageRowClipboardModel";
import {IdRestDto} from "@/api/pharma-cms-content-page/models";
import {ContentPageEndpointsApiService} from "@/main";
import {ColorSchemeClipboardModel} from "@/models/clipboard/ColorSchemeClipboardModel";
import {ContentBlockInRowRestDtoModel} from "@/models/ContentBlockInRowRestDtoModel";
import {ContentPageRowRestDtoModel} from "@/models/ContentPageRowRestDtoModel";

interface State {
    color_scheme: ColorSchemeClipboardModel,
    content_block: Map<string, object>,
    content_block_in_row: ContentBlockInRowRestDtoModel | null,
    content_page_row: ContentPageRowRestDtoModel | null,
    content_page_row_ids: ContentPageRowClipboardModel,
}

export const useClipboardStore = defineStore("ClipboardStore", {
    state: (): State => {
        return {
            color_scheme: ColorSchemeClipboardModel.createWithDefaults(),
            content_block: new Map<string, object>(),
            content_block_in_row: null,
            content_page_row: null,
            content_page_row_ids: ContentPageRowClipboardModel.createWithDefaults()
        }
    },
    actions: {
        copyToClipboardColorScheme(color_scheme: ColorSchemeRestDto | null, active_color_scheme: CodeDescriptionRestDto | null): void {
            this.color_scheme = JSON.parse(JSON.stringify(new ColorSchemeClipboardModel(color_scheme, active_color_scheme)));
        },
        copyToClipboardContentBlock(content_block: object, type: string) {
            this.content_block.set(type, JSON.parse(JSON.stringify(content_block)));
        },
        copyToClipboardContentBlockInRow(content_block_in_row: ContentBlockInRowRestDtoModel): void {
            this.content_block_in_row = JSON.parse(JSON.stringify(content_block_in_row));
        },
        copyToClipboardContentPageRow(content_page_row: ContentPageRowRestDtoModel): void {
            this.content_page_row = JSON.parse(JSON.stringify(content_page_row));
        },
        copyToClipboardContentPageRowIds(row_id: string | null, from_page_id: string | null): void {
            this.content_page_row_ids = JSON.parse(JSON.stringify(new ContentPageRowClipboardModel(row_id, from_page_id)));
        },
        async pasteClipboardContentPageRowIds(to_page_id: string): Promise<void> {
            const body = {id: this.content_page_row_ids.rowId} as IdRestDto;

            await ContentPageEndpointsApiService.pasteRowOnContentPage(body, to_page_id);
        },
    },
    getters: {
        getClipboardColorScheme(state: State): ColorSchemeClipboardModel {
            return state.color_scheme;
        },
        getClipboardContentBlock: (state: State) => (type: string): object | undefined => {
            return state.content_block.get(type);
        },
        getClipboardContentBlockInRow(state: State): ContentBlockInRowRestDtoModel | null {
            return state.content_block_in_row;
        },
        getClipboardContentPageRow(state: State): ContentPageRowRestDtoModel | null {
            return state.content_page_row;
        },
        getClipboardContentPageRowIds(state: State): ContentPageRowClipboardModel {
            return state.content_page_row_ids;
        }
    }
});
