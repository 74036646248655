
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {WysiwygContentPageRestDto} from "@/api/pharma-cms-content-page/models";
import {Exception} from "@/api/interfaces";
import AutoSaveFeedback from "@/components/UI/AutoSaveFeedback.vue";
import {AutoSaveFeedbackStatusEnum} from "@/models/enum/AutoSaveFeedbackStatusEnum";
import {ContentBlockTypesEnum} from "@/helpers/functions/enum";
import {TinyEmitter} from "tiny-emitter";
import CbTextWithImage from "@/components/layouts/content-pages/wysiwyg/structures/CbTextWithImage.vue";
import BaseOffcanvasButtonClose from "@/components/UI/Bootstrap/Offcanvas/BaseOffcanvasButtonClose.vue";

export default defineComponent({
  name: "ContentPagePageAbstract",
  components: {BaseOffcanvasButtonClose, CbTextWithImage, AutoSaveFeedback},
  props: {
    bus: {
      type: TinyEmitter,
      required: true
    }
  },
  data() {
    return {
      isReady: false as boolean,
      exceptions: [] as Exception[],
      autoSaveFeedbackStatus: AutoSaveFeedbackStatusEnum.NONE as AutoSaveFeedbackStatusEnum,
      contentBlockOverviewType: ContentBlockTypesEnum.OVERVIEW_INFO as string,
    };
  },
  watch: {
    id: {
      handler() {
        this.reloadContent();
      },
      immediate: true,
    },
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cms_content_page_wysiwyg', ['getContentPage']),
    contentPage(): WysiwygContentPageRestDto {
      return this.getContentPage;
    },
  },
  methods: {
    ...mapActions('cms_content_page_wysiwyg', ['saveContentPage']),
    save(): void {
      this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.BUSY;
      this.bus.emit('submit');
    },
    onChange(): void {
      this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.UNSAVED_CHANGES;
    },
    setExceptions(exceptions: any): void {
      if (exceptions.length) {
        this.exceptions = exceptions;
        this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.FAILED;
      }
    },
    setHasUnsavedChanges(): void {
      this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.UNSAVED_CHANGES;
    },
    async reloadContent(): Promise<void> {
      this.isReady = false;
      this.exceptions = [];

      try {
        this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.NONE;
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }

      this.isReady = true;
    },
    async afterSaveContent(cbId: string | null): Promise<void> {
      this.exceptions = [];

      try {
        if (this.contentPage.cp && cbId !== null) {
          this.contentPage.cp.overview_cb_id = cbId;
        }

        await this.saveContentPage();

        this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.SUCCESS;
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }
    },
  },
});
