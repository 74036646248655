// Docs - https://vue-i18n.intlify.dev/
import {createI18n} from "vue-i18n";

import messages_nl from "@/assets/messages/nl.json";
import messages_en from "@/assets/messages/en.json";
import messages_fr from "@/assets/messages/fr.json";

import cms_nl from "@/assets/messages/cms/nl.json";
import cms_en from "@/assets/messages/cms/en.json";
import cms_fr from "@/assets/messages/cms/fr.json";

import descriptions_nl from "@/assets/messages/descriptions/nl.json";
import descriptions_en from "@/assets/messages/descriptions/en.json";
import descriptions_fr from "@/assets/messages/descriptions/fr.json";

import validations_nl from "@/assets/messages/validations/nl.json";
import validations_en from "@/assets/messages/validations/en.json";
import validations_fr from "@/assets/messages/validations/fr.json";


const i18n = createI18n({
    locale: 'nl',
    fallbackLocale: 'en',
    messages: {
        nl: {
            ...messages_nl,
            ...cms_nl,
            ...descriptions_nl,
            ...validations_nl,
        },
        en: {
            ...messages_en,
            ...cms_en,
            ...descriptions_en,
            ...validations_en,
        },
        fr: {
            ...messages_fr,
            ...cms_fr,
            ...descriptions_fr,
            ...validations_fr,
        }
    },
    //TODO remove this when XSS protection is done
    warnHtmlInMessage: false,
});

export default i18n;
