/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Menu API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ElementTargetPositionRestDto } from '../models';
import { FullMenuRestDto } from '../models';
import { IdResponseRestDto } from '../models';
import { MenuItemCreateRestDto } from '../models';
import { MenuItemRestDto } from '../models';
import { MenuItemsResultsRestDto } from '../models';
import { MenuOverviewResultsRestDto } from '../models';
import { MenuRestDto } from '../models';
import { RelativeUrlRestDto } from '../models';
/**
 * MenuEndpointsApi - axios parameter creator
 * @export
 */
export const MenuEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.MENU.16 Create named menu.
         * @param {MenuRestDto} body 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMenu: async (body: MenuRestDto, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createMenu.');
            }
            const localVarPath = `/menu/v1/menu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.02 Create menu item.
         * @param {MenuItemCreateRestDto} body 
         * @param {string} menu_id Uniquely identifies the menu to which the menu item is connected.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMenuItem: async (body: MenuItemCreateRestDto, menu_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createMenuItem.');
            }
            // verify required parameter 'menu_id' is not null or undefined
            if (menu_id === null || menu_id === undefined) {
                throw new RequiredError('menu_id','Required parameter menu_id was null or undefined when calling createMenuItem.');
            }
            const localVarPath = `/menu/v1/menu/{menu_id}/menu_item`
                .replace(`{${"menu_id"}}`, encodeURIComponent(String(menu_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible deleting a menu item. Note that it is not possible to delete a menu item that still has a visibility rule, the rule should be removed first.
         * @summary REST.API.CMS.MENU.04 Delete menu item.
         * @param {string} menu_item_id Uniquely identifies the menu item to update.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMenuItem: async (menu_item_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'menu_item_id' is not null or undefined
            if (menu_item_id === null || menu_item_id === undefined) {
                throw new RequiredError('menu_item_id','Required parameter menu_item_id was null or undefined when calling deleteMenuItem.');
            }
            const localVarPath = `/menu/v1/menu_item/{menu_item_id}`
                .replace(`{${"menu_item_id"}}`, encodeURIComponent(String(menu_item_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.15 Get the menu by id.
         * @param {string} menu_id The unique identification of the menu.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMenuById: async (menu_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'menu_id' is not null or undefined
            if (menu_id === null || menu_id === undefined) {
                throw new RequiredError('menu_id','Required parameter menu_id was null or undefined when calling findMenuById.');
            }
            const localVarPath = `/menu/v1/menu/{menu_id}/`
                .replace(`{${"menu_id"}}`, encodeURIComponent(String(menu_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.01 Get the menu for this slot.
         * @param {string} menu_slot_code The slot of the menu.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMenuBySlot: async (menu_slot_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'menu_slot_code' is not null or undefined
            if (menu_slot_code === null || menu_slot_code === undefined) {
                throw new RequiredError('menu_slot_code','Required parameter menu_slot_code was null or undefined when calling findMenuBySlot.');
            }
            const localVarPath = `/menu/v1/menu_slot/{menu_slot_code}/menu`
                .replace(`{${"menu_slot_code"}}`, encodeURIComponent(String(menu_slot_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.05 Get the menu item by id.
         * @param {string} menu_item_id Uniquely identifies the menu item.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMenuItemById: async (menu_item_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'menu_item_id' is not null or undefined
            if (menu_item_id === null || menu_item_id === undefined) {
                throw new RequiredError('menu_item_id','Required parameter menu_item_id was null or undefined when calling findMenuItemById.');
            }
            const localVarPath = `/menu/v1/menu_item/{menu_item_id}`
                .replace(`{${"menu_item_id"}}`, encodeURIComponent(String(menu_item_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.14 Get a list of available menus.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMenus: async (x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/menu/v1/menu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.12 Get a list of relative urls (may depend on available license)
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRelativeUrls: async (x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/menu/v1/menu_item/relative_url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.11 Get the menu items of menu or submenu by id.
         * @param {string} menu_id Uniquely identifies the menu or submenu.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuItemsById: async (menu_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'menu_id' is not null or undefined
            if (menu_id === null || menu_id === undefined) {
                throw new RequiredError('menu_id','Required parameter menu_id was null or undefined when calling getMenuItemsById.');
            }
            const localVarPath = `/menu/v1/menu/{menu_id}/menu_item`
                .replace(`{${"menu_id"}}`, encodeURIComponent(String(menu_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.09 Move menu item one position down.
         * @param {string} menu_item_id The unique identifier of the menu item to move.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveMenuItemDown: async (menu_item_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'menu_item_id' is not null or undefined
            if (menu_item_id === null || menu_item_id === undefined) {
                throw new RequiredError('menu_item_id','Required parameter menu_item_id was null or undefined when calling moveMenuItemDown.');
            }
            const localVarPath = `/menu/v1/menu_item/{menu_item_id}/move/down`
                .replace(`{${"menu_item_id"}}`, encodeURIComponent(String(menu_item_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.10 Move menu item to position between two others.
         * @param {ElementTargetPositionRestDto} body 
         * @param {string} menu_item_id The unique identifier of the menu item to move.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveMenuItemTo: async (body: ElementTargetPositionRestDto, menu_item_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling moveMenuItemTo.');
            }
            // verify required parameter 'menu_item_id' is not null or undefined
            if (menu_item_id === null || menu_item_id === undefined) {
                throw new RequiredError('menu_item_id','Required parameter menu_item_id was null or undefined when calling moveMenuItemTo.');
            }
            const localVarPath = `/menu/v1/menu_item/{menu_item_id}/move/to`
                .replace(`{${"menu_item_id"}}`, encodeURIComponent(String(menu_item_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.07 Move menu item to the bottom position.
         * @param {string} menu_item_id The unique identifier of the menu item to move.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveMenuItemToBottom: async (menu_item_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'menu_item_id' is not null or undefined
            if (menu_item_id === null || menu_item_id === undefined) {
                throw new RequiredError('menu_item_id','Required parameter menu_item_id was null or undefined when calling moveMenuItemToBottom.');
            }
            const localVarPath = `/menu/v1/menu_item/{menu_item_id}/move/to_bottom`
                .replace(`{${"menu_item_id"}}`, encodeURIComponent(String(menu_item_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.06 Move menu item to the top position.
         * @param {string} menu_item_id The unique identifier of menu item to move.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveMenuItemToTop: async (menu_item_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'menu_item_id' is not null or undefined
            if (menu_item_id === null || menu_item_id === undefined) {
                throw new RequiredError('menu_item_id','Required parameter menu_item_id was null or undefined when calling moveMenuItemToTop.');
            }
            const localVarPath = `/menu/v1/menu_item/{menu_item_id}/move/to_top`
                .replace(`{${"menu_item_id"}}`, encodeURIComponent(String(menu_item_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.08 Move menu item one position up.
         * @param {string} menu_item_id The unique identifier of the menu item to move.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveMenuItemUp: async (menu_item_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'menu_item_id' is not null or undefined
            if (menu_item_id === null || menu_item_id === undefined) {
                throw new RequiredError('menu_item_id','Required parameter menu_item_id was null or undefined when calling moveMenuItemUp.');
            }
            const localVarPath = `/menu/v1/menu_item/{menu_item_id}/move/up`
                .replace(`{${"menu_item_id"}}`, encodeURIComponent(String(menu_item_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.13 Update menu.
         * @param {MenuRestDto} body 
         * @param {string} menu_id Uniquely identifies the menu to update.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMenu: async (body: MenuRestDto, menu_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateMenu.');
            }
            // verify required parameter 'menu_id' is not null or undefined
            if (menu_id === null || menu_id === undefined) {
                throw new RequiredError('menu_id','Required parameter menu_id was null or undefined when calling updateMenu.');
            }
            const localVarPath = `/menu/v1/menu/{menu_id}/`
                .replace(`{${"menu_id"}}`, encodeURIComponent(String(menu_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.03 Update menu item.
         * @param {MenuItemRestDto} body 
         * @param {string} menu_item_id Uniquely identifies the menu item to update.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMenuItem: async (body: MenuItemRestDto, menu_item_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateMenuItem.');
            }
            // verify required parameter 'menu_item_id' is not null or undefined
            if (menu_item_id === null || menu_item_id === undefined) {
                throw new RequiredError('menu_item_id','Required parameter menu_item_id was null or undefined when calling updateMenuItem.');
            }
            const localVarPath = `/menu/v1/menu_item/{menu_item_id}`
                .replace(`{${"menu_item_id"}}`, encodeURIComponent(String(menu_item_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MenuEndpointsApi - functional programming interface
 * @export
 */
export const MenuEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.MENU.16 Create named menu.
         * @param {MenuRestDto} body 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMenu(_axios: AxiosInstance, body: MenuRestDto, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MenuEndpointsApiAxiosParamCreator(configuration).createMenu(body, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.02 Create menu item.
         * @param {MenuItemCreateRestDto} body 
         * @param {string} menu_id Uniquely identifies the menu to which the menu item is connected.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMenuItem(_axios: AxiosInstance, body: MenuItemCreateRestDto, menu_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResponseRestDto>> {
            const localVarAxiosArgs = await MenuEndpointsApiAxiosParamCreator(configuration).createMenuItem(body, menu_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible deleting a menu item. Note that it is not possible to delete a menu item that still has a visibility rule, the rule should be removed first.
         * @summary REST.API.CMS.MENU.04 Delete menu item.
         * @param {string} menu_item_id Uniquely identifies the menu item to update.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMenuItem(_axios: AxiosInstance, menu_item_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MenuEndpointsApiAxiosParamCreator(configuration).deleteMenuItem(menu_item_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.15 Get the menu by id.
         * @param {string} menu_id The unique identification of the menu.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMenuById(_axios: AxiosInstance, menu_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullMenuRestDto>> {
            const localVarAxiosArgs = await MenuEndpointsApiAxiosParamCreator(configuration).findMenuById(menu_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.01 Get the menu for this slot.
         * @param {string} menu_slot_code The slot of the menu.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMenuBySlot(_axios: AxiosInstance, menu_slot_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullMenuRestDto>> {
            const localVarAxiosArgs = await MenuEndpointsApiAxiosParamCreator(configuration).findMenuBySlot(menu_slot_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.05 Get the menu item by id.
         * @param {string} menu_item_id Uniquely identifies the menu item.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMenuItemById(_axios: AxiosInstance, menu_item_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenuItemRestDto>> {
            const localVarAxiosArgs = await MenuEndpointsApiAxiosParamCreator(configuration).findMenuItemById(menu_item_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.14 Get a list of available menus.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMenus(_axios: AxiosInstance, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenuOverviewResultsRestDto>> {
            const localVarAxiosArgs = await MenuEndpointsApiAxiosParamCreator(configuration).findMenus(x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.12 Get a list of relative urls (may depend on available license)
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findRelativeUrls(_axios: AxiosInstance, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RelativeUrlRestDto>>> {
            const localVarAxiosArgs = await MenuEndpointsApiAxiosParamCreator(configuration).findRelativeUrls(x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.11 Get the menu items of menu or submenu by id.
         * @param {string} menu_id Uniquely identifies the menu or submenu.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMenuItemsById(_axios: AxiosInstance, menu_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenuItemsResultsRestDto>> {
            const localVarAxiosArgs = await MenuEndpointsApiAxiosParamCreator(configuration).getMenuItemsById(menu_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.09 Move menu item one position down.
         * @param {string} menu_item_id The unique identifier of the menu item to move.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveMenuItemDown(_axios: AxiosInstance, menu_item_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MenuEndpointsApiAxiosParamCreator(configuration).moveMenuItemDown(menu_item_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.10 Move menu item to position between two others.
         * @param {ElementTargetPositionRestDto} body 
         * @param {string} menu_item_id The unique identifier of the menu item to move.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveMenuItemTo(_axios: AxiosInstance, body: ElementTargetPositionRestDto, menu_item_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MenuEndpointsApiAxiosParamCreator(configuration).moveMenuItemTo(body, menu_item_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.07 Move menu item to the bottom position.
         * @param {string} menu_item_id The unique identifier of the menu item to move.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveMenuItemToBottom(_axios: AxiosInstance, menu_item_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MenuEndpointsApiAxiosParamCreator(configuration).moveMenuItemToBottom(menu_item_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.06 Move menu item to the top position.
         * @param {string} menu_item_id The unique identifier of menu item to move.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveMenuItemToTop(_axios: AxiosInstance, menu_item_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MenuEndpointsApiAxiosParamCreator(configuration).moveMenuItemToTop(menu_item_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.08 Move menu item one position up.
         * @param {string} menu_item_id The unique identifier of the menu item to move.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveMenuItemUp(_axios: AxiosInstance, menu_item_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MenuEndpointsApiAxiosParamCreator(configuration).moveMenuItemUp(menu_item_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.13 Update menu.
         * @param {MenuRestDto} body 
         * @param {string} menu_id Uniquely identifies the menu to update.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMenu(_axios: AxiosInstance, body: MenuRestDto, menu_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MenuEndpointsApiAxiosParamCreator(configuration).updateMenu(body, menu_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.MENU.03 Update menu item.
         * @param {MenuItemRestDto} body 
         * @param {string} menu_item_id Uniquely identifies the menu item to update.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMenuItem(_axios: AxiosInstance, body: MenuItemRestDto, menu_item_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MenuEndpointsApiAxiosParamCreator(configuration).updateMenuItem(body, menu_item_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MenuEndpointsApi - object-oriented interface
 * @export
 * @class MenuEndpointsApi
 * @extends {BaseAPI}
 */
export class MenuEndpointsApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.CMS.MENU.16 Create named menu.
     * @param {MenuRestDto} body 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuEndpointsApi
     */
     public createMenu(body: MenuRestDto, x_dp_customer_code?: string|null, options?: any) {
        return MenuEndpointsApiFp(this.configuration).createMenu(this.axios, body, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.MENU.02 Create menu item.
     * @param {MenuItemCreateRestDto} body 
     * @param {string} menu_id Uniquely identifies the menu to which the menu item is connected.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuEndpointsApi
     */
     public createMenuItem(body: MenuItemCreateRestDto, menu_id: string, x_dp_customer_code?: string|null, options?: any) {
        return MenuEndpointsApiFp(this.configuration).createMenuItem(this.axios, body, menu_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible deleting a menu item. Note that it is not possible to delete a menu item that still has a visibility rule, the rule should be removed first.
     * @summary REST.API.CMS.MENU.04 Delete menu item.
     * @param {string} menu_item_id Uniquely identifies the menu item to update.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuEndpointsApi
     */
     public deleteMenuItem(menu_item_id: string, x_dp_customer_code?: string|null, options?: any) {
        return MenuEndpointsApiFp(this.configuration).deleteMenuItem(this.axios, menu_item_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.MENU.15 Get the menu by id.
     * @param {string} menu_id The unique identification of the menu.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuEndpointsApi
     */
     public findMenuById(menu_id: string, x_dp_customer_code?: string|null, options?: any) {
        return MenuEndpointsApiFp(this.configuration).findMenuById(this.axios, menu_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.MENU.01 Get the menu for this slot.
     * @param {string} menu_slot_code The slot of the menu.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuEndpointsApi
     */
     public findMenuBySlot(menu_slot_code: string, x_dp_customer_code?: string|null, options?: any) {
        return MenuEndpointsApiFp(this.configuration).findMenuBySlot(this.axios, menu_slot_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.MENU.05 Get the menu item by id.
     * @param {string} menu_item_id Uniquely identifies the menu item.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuEndpointsApi
     */
     public findMenuItemById(menu_item_id: string, x_dp_customer_code?: string|null, options?: any) {
        return MenuEndpointsApiFp(this.configuration).findMenuItemById(this.axios, menu_item_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.MENU.14 Get a list of available menus.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuEndpointsApi
     */
     public findMenus(x_dp_customer_code?: string|null, options?: any) {
        return MenuEndpointsApiFp(this.configuration).findMenus(this.axios, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.MENU.12 Get a list of relative urls (may depend on available license)
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuEndpointsApi
     */
     public findRelativeUrls(x_dp_customer_code?: string|null, options?: any) {
        return MenuEndpointsApiFp(this.configuration).findRelativeUrls(this.axios, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.MENU.11 Get the menu items of menu or submenu by id.
     * @param {string} menu_id Uniquely identifies the menu or submenu.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuEndpointsApi
     */
     public getMenuItemsById(menu_id: string, x_dp_customer_code?: string|null, options?: any) {
        return MenuEndpointsApiFp(this.configuration).getMenuItemsById(this.axios, menu_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.MENU.09 Move menu item one position down.
     * @param {string} menu_item_id The unique identifier of the menu item to move.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuEndpointsApi
     */
     public moveMenuItemDown(menu_item_id: string, x_dp_customer_code?: string|null, options?: any) {
        return MenuEndpointsApiFp(this.configuration).moveMenuItemDown(this.axios, menu_item_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.MENU.10 Move menu item to position between two others.
     * @param {ElementTargetPositionRestDto} body 
     * @param {string} menu_item_id The unique identifier of the menu item to move.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuEndpointsApi
     */
     public moveMenuItemTo(body: ElementTargetPositionRestDto, menu_item_id: string, x_dp_customer_code?: string|null, options?: any) {
        return MenuEndpointsApiFp(this.configuration).moveMenuItemTo(this.axios, body, menu_item_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.MENU.07 Move menu item to the bottom position.
     * @param {string} menu_item_id The unique identifier of the menu item to move.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuEndpointsApi
     */
     public moveMenuItemToBottom(menu_item_id: string, x_dp_customer_code?: string|null, options?: any) {
        return MenuEndpointsApiFp(this.configuration).moveMenuItemToBottom(this.axios, menu_item_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.MENU.06 Move menu item to the top position.
     * @param {string} menu_item_id The unique identifier of menu item to move.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuEndpointsApi
     */
     public moveMenuItemToTop(menu_item_id: string, x_dp_customer_code?: string|null, options?: any) {
        return MenuEndpointsApiFp(this.configuration).moveMenuItemToTop(this.axios, menu_item_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.MENU.08 Move menu item one position up.
     * @param {string} menu_item_id The unique identifier of the menu item to move.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuEndpointsApi
     */
     public moveMenuItemUp(menu_item_id: string, x_dp_customer_code?: string|null, options?: any) {
        return MenuEndpointsApiFp(this.configuration).moveMenuItemUp(this.axios, menu_item_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.MENU.13 Update menu.
     * @param {MenuRestDto} body 
     * @param {string} menu_id Uniquely identifies the menu to update.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuEndpointsApi
     */
     public updateMenu(body: MenuRestDto, menu_id: string, x_dp_customer_code?: string|null, options?: any) {
        return MenuEndpointsApiFp(this.configuration).updateMenu(this.axios, body, menu_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.MENU.03 Update menu item.
     * @param {MenuItemRestDto} body 
     * @param {string} menu_item_id Uniquely identifies the menu item to update.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuEndpointsApi
     */
     public updateMenuItem(body: MenuItemRestDto, menu_item_id: string, x_dp_customer_code?: string|null, options?: any) {
        return MenuEndpointsApiFp(this.configuration).updateMenuItem(this.axios, body, menu_item_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
