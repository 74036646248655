import {
    ContentBlockMetadataRestDto,
    MultilingualFieldRestDto,
    SimpleTextContentRestDto
} from "@/api/pharma-cms-content-block/models";
import {StatusEnum} from "@/api/enums/status-enum";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";

export class SimpleTextContentRestDtoModel implements SimpleTextContentRestDto {
    cb_metadata: ContentBlockMetadataRestDto | null;
    content: MultilingualFieldRestDto;
    status_code: StatusEnum;

    constructor(cb_metadata: ContentBlockMetadataRestDto | null, content: MultilingualFieldRestDto, status_code: StatusEnum) {
        this.cb_metadata = cb_metadata;
        this.content = content;
        this.status_code = status_code;
    }

    public static createWithDefaults(): SimpleTextContentRestDtoModel {
        return new this(
            null,
            MultilingualRestDtoModel.createWithDefaults(),
            StatusEnum.PUBLISHED
        );
    }
}
