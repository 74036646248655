import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.category.parent_categories?.length)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.category.parent_categories, (parent, i) => {
          return (_openBlock(), _createElementBlock("p", {
            key: parent.code,
            class: "tree-item mb-0",
            style: _normalizeStyle('margin-left:' + i +'rem')
          }, [
            (i !== 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, "- "))
              : _createCommentVNode("", true),
            _createTextVNode(_toDisplayString(parent.description), 1)
          ], 4))
        }), 128))
      : _createCommentVNode("", true),
    _createElementVNode("p", {
      class: "tree-item mb-0",
      style: _normalizeStyle('margin-left:' + ((_ctx.category.parent_categories) ? _ctx.category.parent_categories.length : 0) +'rem')
    }, [
      (_ctx.category.parent_categories?.length)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "- "))
        : _createCommentVNode("", true),
      _createTextVNode(_toDisplayString(_ctx.category.category.description), 1)
    ], 4)
  ], 64))
}