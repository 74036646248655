import {NameValuePairCompactRestDto, NameValuePairOverviewRestDto} from "@/api/pharma-order-settings/models";

function parseSavedNameValuePairs(name_value_pair_overview: NameValuePairOverviewRestDto[]): NameValuePairCompactRestDto[] {
    const parsed_name_value_pair_overview = JSON.parse(JSON.stringify(name_value_pair_overview));

    parsed_name_value_pair_overview.forEach((nvp: any) => {
        if (nvp.value === '') {
            nvp.value = null;
        }
        delete nvp['description'];
    });

    return parsed_name_value_pair_overview;
}

export {parseSavedNameValuePairs}
