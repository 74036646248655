
import {defineComponent} from "vue";
import BaseContainer from "@/components/UI/Bootstrap/BaseContainer.vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {Config} from "@/models/facade/Config";
import {CustomerContext} from "@/context/CustomerContext";
import {UtilityHelper} from "@/helpers/UtilityHelper";

export default defineComponent({
  name: "Sendcloud",
  components: {
    BaseTitle, BaseContainer
  },
  computed: {
    UtilityHelper() {
      return UtilityHelper
    },
    webhookUrl() {
      return 'https://' + Config.getInstance().read('apiGwHost') + '/pharma-shipping/public/webhook/v1/sendcloud/customer/' + CustomerContext.getCustomerCode() + '/pharmacy/' + CustomerContext.getPharmacyCode();
    }
  }
});
