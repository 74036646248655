import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-0"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_alert = _resolveComponent("base-alert")!

  return (_ctx.exceptions.length > 0)
    ? (_openBlock(), _createBlock(_component_base_alert, {
        key: 0,
        class: "alert-danger",
        icon: "error"
      }, {
        default: _withCtx(() => [
          (_ctx.multiple)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.exceptions, (exception) => {
                  return (_openBlock(), _createElementBlock("li", { key: exception }, _toDisplayString(exception.message), 1))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.exceptions[0].message), 1))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}