
import {defineComponent} from "vue";
import {I18nContext} from "@/context/I18nContext";
import {AbilityContext} from "@/context/AbilityContext";

export default defineComponent({
  name: "MultilingualSwitcher",
  computed: {
    AbilityContext() {
      return AbilityContext
    }
  },
  emits: ['updateActiveLanguage'],
  props: {
    activeLanguage: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      required: false,
      default() {
        return I18nContext.getLanguages();
      }
    },
    errors: {
      type: Object,
      required: true,
    },
    mb: {
      type: Number,
      required: false,
      default: 4,
    }
  },
  data: () => ({
    isReady: false,
  }),
  mounted() {
    this.isReady = true;
  },
  methods: {
    languageHasErrors(errors: object, language: string): boolean {
      for (const [key] of Object.entries(errors)) {
        if (key.endsWith('.' + language)) {
          return true;
        }
      }

      return false;
    },
  }
});
