/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Order Enum API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CodeDescriptionRestDto } from '../models';
import { EnumContentRestDto } from '../models';
import { UpdateEnumContentRestDto } from '../models';
/**
 * EnumEndpointsApi - axios parameter creator
 * @export
 */
export const EnumEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.ORDER.ENUM.05 Find enum content of a specified type for an enum.
         * @param {string} enum_class_name Identifies the enum values that are requested.
         * @param {string} enum_code Identifies the concrete enum value.
         * @param {string} type_code Identifies the content type.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_pharmacy_code] Filter de resultaten tot die van de gespecifieerde pharmacy.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderEnumContentForType: async (enum_class_name: string, enum_code: string, type_code: string, x_dp_customer_code?: string|null, filter_pharmacy_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'enum_class_name' is not null or undefined
            if (enum_class_name === null || enum_class_name === undefined) {
                throw new RequiredError('enum_class_name','Required parameter enum_class_name was null or undefined when calling getOrderEnumContentForType.');
            }
            // verify required parameter 'enum_code' is not null or undefined
            if (enum_code === null || enum_code === undefined) {
                throw new RequiredError('enum_code','Required parameter enum_code was null or undefined when calling getOrderEnumContentForType.');
            }
            // verify required parameter 'type_code' is not null or undefined
            if (type_code === null || type_code === undefined) {
                throw new RequiredError('type_code','Required parameter type_code was null or undefined when calling getOrderEnumContentForType.');
            }
            const localVarPath = `/enum/v1/enum/by_classname/{enum_class_name}/{enum_code}/content/{type_code}`
                .replace(`{${"enum_class_name"}}`, encodeURIComponent(String(enum_class_name)))
                .replace(`{${"enum_code"}}`, encodeURIComponent(String(enum_code)))
                .replace(`{${"type_code"}}`, encodeURIComponent(String(type_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_pharmacy_code || typeof(filter_pharmacy_code) === "boolean") {
                localVarQueryParameter['filter_pharmacy_code'] = filter_pharmacy_code;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.ORDER.ENUM.04 Find enum content types of a specified enum.
         * @param {string} enum_class_name Identifies the enum values that are requested.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderEnumContentTypes: async (enum_class_name: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'enum_class_name' is not null or undefined
            if (enum_class_name === null || enum_class_name === undefined) {
                throw new RequiredError('enum_class_name','Required parameter enum_class_name was null or undefined when calling getOrderEnumContentTypes.');
            }
            const localVarPath = `/enum/v1/enum/by_classname/{enum_class_name}/content_type`
                .replace(`{${"enum_class_name"}}`, encodeURIComponent(String(enum_class_name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.ORDER.ENUM.03 Find enum values for a specified classname.
         * @param {string} enum_class_name Identifies the enum values that are requested.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderEnumValuesByClassname: async (enum_class_name: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'enum_class_name' is not null or undefined
            if (enum_class_name === null || enum_class_name === undefined) {
                throw new RequiredError('enum_class_name','Required parameter enum_class_name was null or undefined when calling getOrderEnumValuesByClassname.');
            }
            const localVarPath = `/enum/v1/enum/by_classname/{enum_class_name}`
                .replace(`{${"enum_class_name"}}`, encodeURIComponent(String(enum_class_name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.ORDER.ENUM.06 Update enum content of a specified type for an enum.
         * @param {UpdateEnumContentRestDto} body 
         * @param {string} enum_class_name Identifies the enum values that are requested.
         * @param {string} enum_code Identifies the concrete enum value.
         * @param {string} type_code Identifies the content type.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderEnumContentForType: async (body: UpdateEnumContentRestDto, enum_class_name: string, enum_code: string, type_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateOrderEnumContentForType.');
            }
            // verify required parameter 'enum_class_name' is not null or undefined
            if (enum_class_name === null || enum_class_name === undefined) {
                throw new RequiredError('enum_class_name','Required parameter enum_class_name was null or undefined when calling updateOrderEnumContentForType.');
            }
            // verify required parameter 'enum_code' is not null or undefined
            if (enum_code === null || enum_code === undefined) {
                throw new RequiredError('enum_code','Required parameter enum_code was null or undefined when calling updateOrderEnumContentForType.');
            }
            // verify required parameter 'type_code' is not null or undefined
            if (type_code === null || type_code === undefined) {
                throw new RequiredError('type_code','Required parameter type_code was null or undefined when calling updateOrderEnumContentForType.');
            }
            const localVarPath = `/enum/v1/enum/by_classname/{enum_class_name}/{enum_code}/content/{type_code}`
                .replace(`{${"enum_class_name"}}`, encodeURIComponent(String(enum_class_name)))
                .replace(`{${"enum_code"}}`, encodeURIComponent(String(enum_code)))
                .replace(`{${"type_code"}}`, encodeURIComponent(String(type_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.ORDER.ENUM.07 Update enum fallback content of a specified type for an enum.
         * @param {UpdateEnumContentRestDto} body 
         * @param {string} enum_class_name Identifies the enum values that are requested.
         * @param {string} enum_code Identifies the concrete enum value.
         * @param {string} type_code Identifies the content type.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderEnumFallbackContentForType: async (body: UpdateEnumContentRestDto, enum_class_name: string, enum_code: string, type_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateOrderEnumFallbackContentForType.');
            }
            // verify required parameter 'enum_class_name' is not null or undefined
            if (enum_class_name === null || enum_class_name === undefined) {
                throw new RequiredError('enum_class_name','Required parameter enum_class_name was null or undefined when calling updateOrderEnumFallbackContentForType.');
            }
            // verify required parameter 'enum_code' is not null or undefined
            if (enum_code === null || enum_code === undefined) {
                throw new RequiredError('enum_code','Required parameter enum_code was null or undefined when calling updateOrderEnumFallbackContentForType.');
            }
            // verify required parameter 'type_code' is not null or undefined
            if (type_code === null || type_code === undefined) {
                throw new RequiredError('type_code','Required parameter type_code was null or undefined when calling updateOrderEnumFallbackContentForType.');
            }
            const localVarPath = `/enum/v1/enum/by_classname/{enum_class_name}/{enum_code}/fallback_content/{type_code}`
                .replace(`{${"enum_class_name"}}`, encodeURIComponent(String(enum_class_name)))
                .replace(`{${"enum_code"}}`, encodeURIComponent(String(enum_code)))
                .replace(`{${"type_code"}}`, encodeURIComponent(String(type_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnumEndpointsApi - functional programming interface
 * @export
 */
export const EnumEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.ORDER.ENUM.05 Find enum content of a specified type for an enum.
         * @param {string} enum_class_name Identifies the enum values that are requested.
         * @param {string} enum_code Identifies the concrete enum value.
         * @param {string} type_code Identifies the content type.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_pharmacy_code] Filter de resultaten tot die van de gespecifieerde pharmacy.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderEnumContentForType(_axios: AxiosInstance, enum_class_name: string, enum_code: string, type_code: string, x_dp_customer_code?: string|null, filter_pharmacy_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnumContentRestDto>> {
            const localVarAxiosArgs = await EnumEndpointsApiAxiosParamCreator(configuration).getOrderEnumContentForType(enum_class_name, enum_code, type_code, x_dp_customer_code, filter_pharmacy_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.ORDER.ENUM.04 Find enum content types of a specified enum.
         * @param {string} enum_class_name Identifies the enum values that are requested.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderEnumContentTypes(_axios: AxiosInstance, enum_class_name: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CodeDescriptionRestDto>>> {
            const localVarAxiosArgs = await EnumEndpointsApiAxiosParamCreator(configuration).getOrderEnumContentTypes(enum_class_name, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.ORDER.ENUM.03 Find enum values for a specified classname.
         * @param {string} enum_class_name Identifies the enum values that are requested.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderEnumValuesByClassname(_axios: AxiosInstance, enum_class_name: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CodeDescriptionRestDto>>> {
            const localVarAxiosArgs = await EnumEndpointsApiAxiosParamCreator(configuration).getOrderEnumValuesByClassname(enum_class_name, x_dp_customer_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.ORDER.ENUM.06 Update enum content of a specified type for an enum.
         * @param {UpdateEnumContentRestDto} body 
         * @param {string} enum_class_name Identifies the enum values that are requested.
         * @param {string} enum_code Identifies the concrete enum value.
         * @param {string} type_code Identifies the content type.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderEnumContentForType(_axios: AxiosInstance, body: UpdateEnumContentRestDto, enum_class_name: string, enum_code: string, type_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await EnumEndpointsApiAxiosParamCreator(configuration).updateOrderEnumContentForType(body, enum_class_name, enum_code, type_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.ORDER.ENUM.07 Update enum fallback content of a specified type for an enum.
         * @param {UpdateEnumContentRestDto} body 
         * @param {string} enum_class_name Identifies the enum values that are requested.
         * @param {string} enum_code Identifies the concrete enum value.
         * @param {string} type_code Identifies the content type.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderEnumFallbackContentForType(_axios: AxiosInstance, body: UpdateEnumContentRestDto, enum_class_name: string, enum_code: string, type_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await EnumEndpointsApiAxiosParamCreator(configuration).updateOrderEnumFallbackContentForType(body, enum_class_name, enum_code, type_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * EnumEndpointsApi - object-oriented interface
 * @export
 * @class EnumEndpointsApi
 * @extends {BaseAPI}
 */
export class EnumEndpointsApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.ORDER.ENUM.05 Find enum content of a specified type for an enum.
     * @param {string} enum_class_name Identifies the enum values that are requested.
     * @param {string} enum_code Identifies the concrete enum value.
     * @param {string} type_code Identifies the content type.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [filter_pharmacy_code] Filter de resultaten tot die van de gespecifieerde pharmacy.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumEndpointsApi
     */
     public getOrderEnumContentForType(enum_class_name: string, enum_code: string, type_code: string, x_dp_customer_code?: string|null, filter_pharmacy_code?: string|null, options?: any) {
        return EnumEndpointsApiFp(this.configuration).getOrderEnumContentForType(this.axios, enum_class_name, enum_code, type_code, x_dp_customer_code, filter_pharmacy_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.ORDER.ENUM.04 Find enum content types of a specified enum.
     * @param {string} enum_class_name Identifies the enum values that are requested.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumEndpointsApi
     */
     public getOrderEnumContentTypes(enum_class_name: string, x_dp_customer_code?: string|null, options?: any) {
        return EnumEndpointsApiFp(this.configuration).getOrderEnumContentTypes(this.axios, enum_class_name, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.ORDER.ENUM.03 Find enum values for a specified classname.
     * @param {string} enum_class_name Identifies the enum values that are requested.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumEndpointsApi
     */
     public getOrderEnumValuesByClassname(enum_class_name: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any) {
        return EnumEndpointsApiFp(this.configuration).getOrderEnumValuesByClassname(this.axios, enum_class_name, x_dp_customer_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.ORDER.ENUM.06 Update enum content of a specified type for an enum.
     * @param {UpdateEnumContentRestDto} body 
     * @param {string} enum_class_name Identifies the enum values that are requested.
     * @param {string} enum_code Identifies the concrete enum value.
     * @param {string} type_code Identifies the content type.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumEndpointsApi
     */
     public updateOrderEnumContentForType(body: UpdateEnumContentRestDto, enum_class_name: string, enum_code: string, type_code: string, x_dp_customer_code?: string|null, options?: any) {
        return EnumEndpointsApiFp(this.configuration).updateOrderEnumContentForType(this.axios, body, enum_class_name, enum_code, type_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.ORDER.ENUM.07 Update enum fallback content of a specified type for an enum.
     * @param {UpdateEnumContentRestDto} body 
     * @param {string} enum_class_name Identifies the enum values that are requested.
     * @param {string} enum_code Identifies the concrete enum value.
     * @param {string} type_code Identifies the content type.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumEndpointsApi
     */
     public updateOrderEnumFallbackContentForType(body: UpdateEnumContentRestDto, enum_class_name: string, enum_code: string, type_code: string, x_dp_customer_code?: string|null, options?: any) {
        return EnumEndpointsApiFp(this.configuration).updateOrderEnumFallbackContentForType(this.axios, body, enum_class_name, enum_code, type_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
