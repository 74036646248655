import {RestService} from "@/services/rest/RestService";
import {TeamEndpointsApi} from "@/api/pharma-cms-pharmacy";
import {TeamRestDtoModel} from "@/models/TeamRestDtoModel";

export class TeamRestService extends RestService {
    private apiService: TeamEndpointsApi;

    protected constructor() {
        super();

        this.apiService = new TeamEndpointsApi(this.getDpAxiosInstance(), undefined, this.getConfig().cmsUrl);
    }

    public async findTeam(): Promise<TeamRestDtoModel> {
        const response = await this.apiService.getTeam(this.getPharmacyCode());

        if (response.status === 204) {
            return TeamRestDtoModel.createWithDefaults();
        }

        return response.data as TeamRestDtoModel;
    }

    public async updateTeam(team: TeamRestDtoModel): Promise<void> {
        await this.apiService.updateTeam(team, this.getPharmacyCode());
    }
}
