import {RestService} from "@/services/rest/RestService";
import {LandingPageEndpointsApi} from "@/api/pharma-cms-pharmacy";
import {PharmacyLandingPageRefRestDto} from "@/api/pharma-cms-pharmacy/models";

export class PharmacyLandingPageRestService extends RestService {

    // todo change apiService after spec correction
    private apiService: LandingPageEndpointsApi;

    protected constructor() {
        super();

        this.apiService = new LandingPageEndpointsApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cmsUrl);
    }

    public async findLandingPageRef(pharmacy_code: string): Promise<PharmacyLandingPageRefRestDto> {
        const response = await this.apiService.getPharmacyLandingPageRef(pharmacy_code);

        return response.data;
    }

    public async updateLandingPageRef(landing_page_ref: PharmacyLandingPageRefRestDto, pharmacy_code: string): Promise<void> {
        await this.apiService.updatePharmacyLandingPageRef(landing_page_ref, pharmacy_code);
    }
}
