
import {defineComponent} from "vue";
import {Exception} from "@/api/interfaces";
import {mapGetters} from "vuex";
import store from "@/store";
import {StarterDiscountRestDto} from "@/api/pharma-cpc-mgmt/models";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";

export default defineComponent({
  name: "StarterDiscounts",
  components: {BaseSpinner, BaseTitle},
  data: () => ({
    isReady: false as Boolean,
    error: null,
    exceptions: [] as Exception[],
    savedSuccessfully: false as Boolean,
    starterDiscount: {
      discount_percentage: 0,
      is_standard_price_visible: false,
      is_discount_visible: false
    } as StarterDiscountRestDto,
    chosenDiscountOption: 'none' as String,
  }),
  computed: {
    ...mapGetters('cpc_mgmt', ['getStarterDiscount']),
  },
  mounted() {
    this.reloadContent();
  },
  watch: {
    chosenDiscountOption(newValue) {
      if (newValue === 'price-percentage') {
        this.starterDiscount.is_standard_price_visible = true;
        this.starterDiscount.is_discount_visible = true;
      } else if (newValue === 'price') {
        this.starterDiscount.is_standard_price_visible = true;
        this.starterDiscount.is_discount_visible = false;
      } else if (newValue === 'none') {
        this.starterDiscount.is_standard_price_visible = false;
        this.starterDiscount.is_discount_visible = false;
      }
    },
  },
  methods: {
    removeDiscount() {
      this.starterDiscount.discount_percentage = 0;
      this.selectDiscountOption('none');
    },
    selectDiscountOption(option: string) {
      this.chosenDiscountOption = option;
    },
    submitData() {
      this.saveStarterDiscount();
    },
    async reloadContent() {
      try {
        this.isReady = false;

        await this.searchStarterDiscount();
        this.starterDiscount = this.getStarterDiscount;

        if (this.starterDiscount.is_standard_price_visible && this.starterDiscount.is_discount_visible) {
          this.chosenDiscountOption = 'price-percentage';
        } else if (this.starterDiscount.is_standard_price_visible && !this.starterDiscount.is_discount_visible) {
          this.chosenDiscountOption = 'price';
        } else {
          this.chosenDiscountOption = 'none';
        }

        this.isReady = true;
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async searchStarterDiscount() {
      try {
        await store.dispatch('cpc_mgmt/searchStarterDiscount');
      } catch (exception: any) {
        this.exceptions = exception;
      }
    },
    async saveStarterDiscount() {
      try {
        const payload = {
          body: this.starterDiscount,
        };
        await store.dispatch('cpc_mgmt/saveStarterDiscount', payload);

        this.savedSuccessfully = true;
        setTimeout(() => this.savedSuccessfully = false, 2000);
      } catch (exception: any) {
        this.exceptions = exception;
      }
    },
  },
});
