import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, KeepAlive as _KeepAlive, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "badge bg-green ms-3 fs-6"
}
const _hoisted_2 = { class: "mb-4" }
const _hoisted_3 = {
  class: "btn-group",
  role: "group",
  "aria-label": "Own product tabs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_alert_success = _resolveComponent("alert-success")!
  const _component_alert_error = _resolveComponent("alert-error")!
  const _component_base_spinner = _resolveComponent("base-spinner")!
  const _component_base_container = _resolveComponent("base-container")!
  const _component_CatalogBuildStatus = _resolveComponent("CatalogBuildStatus")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_container, { "has-build-status-on-page": "" }, {
      default: _withCtx(() => [
        _createVNode(_component_BaseTitle, {
          title: _ctx.$t('ownProduct'),
          mode: this.mode,
          "help-text": _ctx.$t('descriptionOwnProductsDetail'),
          "back-route": {name: this.returnRoute},
          "show-refresh-button": "",
          onRefresh: this.reloadContent
        }, {
          icons: _withCtx(() => [
            (this.product.name)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(this.product.name), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["title", "mode", "help-text", "back-route", "onRefresh"]),
        _createVNode(_component_alert_success, {
          "show-if": this.savedSuccessfully,
          class: "mb-3"
        }, null, 8, ["show-if"]),
        _createVNode(_component_alert_error, {
          exceptions: this.exceptions,
          class: "mb-3"
        }, null, 8, ["exceptions"]),
        (!this.isReady)
          ? (_openBlock(), _createBlock(_component_base_spinner, { key: 0 }))
          : _createCommentVNode("", true),
        (this.isReady)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("nav", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("button", {
                    type: "button",
                    class: _normalizeClass(["btn btn-outline-primary", {'active':_ctx.currentComponent === 'product'}]),
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setCurrentComponent('product')))
                  }, _toDisplayString(_ctx.$t('product')), 3),
                  _createElementVNode("button", {
                    type: "button",
                    class: _normalizeClass(["btn btn-outline-primary", {'active':_ctx.currentComponent === 'properties'}]),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setCurrentComponent('properties')))
                  }, _toDisplayString(_ctx.$t('properties')), 3)
                ])
              ]),
              (_openBlock(), _createBlock(_KeepAlive, null, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentComponent), _mergeProps(_ctx.currentProperties, { onSubmit: _ctx.submitData }), null, 16, ["onSubmit"]))
              ], 1024))
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_CatalogBuildStatus)
  ], 64))
}