import {
    cloneButtonRestDtoMetaData, cloneSymbolRestDtoMetaData,
    cloneTextWithSymbolContentRestDtoMetaData,
    ContentBlockMetadataRestDto,
    TextWithSymbolContentRestDto
} from "@/api/pharma-cms-content-block/models";
import {StatusEnum} from "@/api/enums/status-enum";
import {SymbolRestDtoModel} from "@/models/api/pharma-cms-content-block/SymbolRestDtoModel";
import {NormalContentBlockModel} from "@/models/api/pharma-cms-content-block/NormalContentBlockModel";
import {TextWithImageContentBlockLayoutEnum} from "@/api/enums/text-with-image-content-block-layout-enum";
import {TitleSizeEnum} from "@/api/enums/title-size-enum";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import {ButtonRestDtoModel} from "@/models/api/pharma-cms-content-block/ButtonRestDtoModel";
import {ButtonHorizontalPositionEnum} from "@/api/enums/button-horizontal-position-enum";

export class TextWithSymbolContentRestDtoModel extends NormalContentBlockModel implements TextWithSymbolContentRestDto {
    button?: ButtonRestDtoModel | null;
    button_horizontal_position_code?: ButtonHorizontalPositionEnum | null;
    cb_metadata?: ContentBlockMetadataRestDto;
    content?: MultilingualRestDtoModel | null;
    layout_code?: TextWithImageContentBlockLayoutEnum | null;
    sort_weight?: number;
    status_code: StatusEnum;
    symbol: SymbolRestDtoModel;
    title?: MultilingualRestDtoModel | null;
    title_size_code?: TitleSizeEnum | null;

    constructor(symbol: SymbolRestDtoModel, status_code: StatusEnum) {
        super();
        this.symbol = symbol;
        this.status_code = status_code;
    }

    public static createWithDefaults(): TextWithSymbolContentRestDtoModel {
        const model = new this(
            SymbolRestDtoModel.createWithDefaults(),
            StatusEnum.PUBLISHED
        );
        model.button_horizontal_position_code = ButtonHorizontalPositionEnum.LEFT;
        model.content = MultilingualRestDtoModel.createWithDefaults();
        model.layout_code = TextWithImageContentBlockLayoutEnum.IMAGE_TO_THE_LEFT_OF_TEXT;
        model.title = MultilingualRestDtoModel.createWithDefaults();
        model.title_size_code = TitleSizeEnum.H2;

        return model;
    }

    public static cloneFrom(source: TextWithSymbolContentRestDtoModel): TextWithSymbolContentRestDtoModel {
        const model = new this(source.symbol, source.status_code);
        model.button = source.button;
        model.button_horizontal_position_code = source.button_horizontal_position_code;
        model.cb_metadata = source.cb_metadata;
        model.content = source.content;
        model.layout_code = source.layout_code;
        model.sort_weight = source.sort_weight;
        model.title = source.title;
        model.title_size_code = source.title_size_code;

        return model;
    }

    public cloneMetadataFrom(source: TextWithSymbolContentRestDtoModel): void {
        cloneTextWithSymbolContentRestDtoMetaData(this, source);
        cloneSymbolRestDtoMetaData(this.symbol, source.symbol);

        if (this.button && source.button) {
            cloneButtonRestDtoMetaData(this.button, source.button)
        }
    }
}
