
import {defineComponent} from 'vue'
import {generateDomId} from "@/helpers/functions/string";

export default defineComponent({
  name: "InputRadioBoolean",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Boolean,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    rules: {
      type: String,
      required: false,
    },

    // BOOLEANS
    canClear: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    isInverted: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      id: generateDomId('formCheckRadio') as string,
    }
  },
  computed: {
    model: {
      get(): boolean | null {
        if (this.modelValue === true || this.modelValue === false) {
          return (this.isInverted) ? !this.modelValue : this.modelValue;
        }

        return null;
      },
      set(value: boolean | undefined | null): void {
        if (value === true || value === false) {
          this.$emit('update:modelValue', (this.isInverted) ? !value : value);
          return;
        }

        this.$emit('update:modelValue', null);
      }
    },
    canBeCleared(): boolean {
      return this.canClear && this.model !== null;
    }
  },
})
