import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "col-auto d-flex align-items-center" }
const _hoisted_3 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_4 = ["for"]
const _hoisted_5 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputColor = _resolveComponent("InputColor")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_dp_form = _resolveComponent("dp-form")!

  return (_openBlock(), _createBlock(_component_dp_form, {
    as: "form",
    novalidate: "",
    class: "row align-items-center"
  }, {
    default: _withCtx(({ errors }) => [
      _createElementVNode("label", {
        for: this.name,
        class: _normalizeClass(`col-${this.label_col_size} ${this.label_bold ? 'fw-700' : ''} mb-3`)
      }, _toDisplayString(this.label), 11, _hoisted_1),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_InputColor, {
          "model-value": this.colorPickerPayload.hex,
          "onUpdate:modelValue": this.onColorChanged,
          name: this.name,
          "show-value": "",
          class: "mb-3 me-3",
          errors: errors,
          disabled: _ctx.read_only
        }, null, 8, ["model-value", "onUpdate:modelValue", "name", "errors", "disabled"]),
        (_ctx.read_only)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("label", {
                for: _ctx.name + 'opacity'
              }, _toDisplayString(_ctx.$t('opacity')), 9, _hoisted_4),
              _createElementVNode("span", {
                id: _ctx.name + 'opacity',
                class: "ps-2"
              }, _toDisplayString(_ctx.colorPickerPayload.colorRestDto.opacity), 9, _hoisted_5)
            ]))
          : (_openBlock(), _createBlock(_component_InputNumber, {
              key: 1,
              modelValue: this.colorPickerPayload.colorRestDto.opacity,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.colorPickerPayload.colorRestDto.opacity) = $event)),
              label: _ctx.$t('opacity'),
              name: "opacity",
              min: 0,
              max: 1,
              step: 0.1,
              rules: "required",
              errors: errors
            }, null, 8, ["modelValue", "label", "step", "errors"]))
      ])
    ]),
    _: 1
  }))
}