import {PasswordRestDto} from "@/api/pharma-auth-pharmacist-user/models";

export class PasswordRestDtoModel implements PasswordRestDto {

    constructor(
        public password: string
    ) {}

    public static createWithDefaults(): PasswordRestDtoModel {
        return new this('');
    }
}
