
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {Exception} from "@/api/interfaces";
import {OrcaReservationCampaignOverviewRestDto} from "@/api/pharma-order-orca-customer-mgmt/models";
import {arrayHasContent} from "@/helpers/functions/arrays";
import ReservationCampaignsOverviewItem
  from "@/components/layouts/reservation-campaigns/ReservationCampaignsOverviewItem.vue";
import {AuthContext} from "@/context/AuthContext";
import BaseTitle from "@/components/UI/BaseTitle.vue";

export default defineComponent({
  name: "ReservationCampaignsOverview",
  components: {BaseTitle, ReservationCampaignsOverviewItem},
  data: () => ({
    isReady: false as boolean,
    exceptions: [] as Exception[],
  }),
  mounted() {
    this.reloadContent();
  },
  computed: {
    AuthContext() {
      return AuthContext;
    },
    ...mapGetters('order_orca_customer_mgmt', ["getReservationCampaignOverview"]),
    reservationCampaigns(): OrcaReservationCampaignOverviewRestDto[] {
      return this.getReservationCampaignOverview;
    },
    hasReservationCampaigns(): boolean {
      return arrayHasContent(this.reservationCampaigns);
    },
  },
  methods: {
    ...mapActions('order_orca_customer_mgmt', ["deleteReservationCampaign", "searchReservationCampaignOverview", "createCampaignWithDefaults"]),
    async newOzempicCampaign() {
      try {
        await this.createCampaignWithDefaults('WEIGHT_LOSS');
        await this.reloadContent();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async newCovidVaccinationCampaign() {
      try {
        await this.createCampaignWithDefaults('COVID_VACCINATION');
        await this.reloadContent();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async reloadContent() {
      this.isReady = false;
      this.exceptions = [];

      try {
        await this.searchReservationCampaignOverview();
      } catch (exceptions: any) {
        this.isReady = true;
        this.exceptions = exceptions;
      }

      this.isReady = true;
    },
    async deleteCampaign(id: string) {
      try {
        const answer = window.confirm(this.$t('deleteConfirmation'));

        if (answer) {
          this.isReady = false;
          this.exceptions = [];

          await this.deleteReservationCampaign(id)

          setTimeout(() => this.reloadContent(), 1000);
        } else {
          return;
        }
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    }
  },
});
