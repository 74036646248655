
import {defineComponent} from "vue";
import {generateUUIDv4} from "@/helpers/functions/string";

export default defineComponent({
  name: 'Subtitle',
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
    help: {
      type: String,
      required: false,
      default: null,
    },
    helpPosition: {
      type: String,
      required: false,
      default: 'top',
    },
    mb: {
      type: String,
      required: false,
      default: '4',
    }
  },
  data() {
    return {
      tooltipId: generateUUIDv4('popover-'),
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize(): Promise<void> {
    }
  }
});
