import {ElementTargetPositionRestDto} from "@/api/pharma-cms-content-page/models";
import {ContentBlockInRowEndpointsApiService} from "@/main";
import {MoveActionEnum} from "@/models/enum/MoveActionEnum";

export default {
    state() {
        return {}
    },
    actions: {
        async copyContentBlockInRow(context: any, payload: any) {
            const row_id = payload.row_id as string;
            const cb_in_row_id = payload.cb_in_row_id as string;
            const body = {} as ElementTargetPositionRestDto;

            await ContentBlockInRowEndpointsApiService.copyContentBlockInRow(body, row_id, cb_in_row_id);
        },
        async deleteContentBlockInRow(context: any, payload: any) {
            const row_id = payload.row_id as string;
            const cb_in_row_id = payload.cb_in_row_id as string;

            await ContentBlockInRowEndpointsApiService.deleteContentBlockInRow(row_id, cb_in_row_id);
        },
        async moveContentBlockInRow(context: any, payload: any) {
            const row_id = payload.row_id as string;
            const cb_in_row_id = payload.cb_in_row_id as string;
            const move_action = payload.move_action as MoveActionEnum;

            switch (move_action) {
                case MoveActionEnum.TOP:
                    await ContentBlockInRowEndpointsApiService.moveContentBlockInRowToTop(row_id, cb_in_row_id);
                    break;
                case MoveActionEnum.BOTTOM:
                    await ContentBlockInRowEndpointsApiService.moveContentBlockInRowToBottom(row_id, cb_in_row_id);
                    break;
                case MoveActionEnum.UP:
                    await ContentBlockInRowEndpointsApiService.moveContentBlockInRowUp(row_id, cb_in_row_id);
                    break;
                case MoveActionEnum.DOWN:
                    await ContentBlockInRowEndpointsApiService.moveContentBlockInRowDown(row_id, cb_in_row_id);
                    break;
                default:
                    throw new Error('Unsupported move_action to call ' + move_action);
            }
        },
    },
    mutations: {},
    getters: {}
}
