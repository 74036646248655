
import {defineComponent, nextTick} from "vue";
import {Exception} from "@/api/interfaces";
import {mapActions, mapGetters} from "vuex";
import {
  CodeDescriptionRestDto,
  ColorRestDto,
  ContentPageRowRestDto,
  WysiwygContentPageRestDto,
  WysiwygContentPageRowRestDto,
} from "@/api/pharma-cms-content-page/models";
import ContentPageRowDetail from "@/components/layouts/content-pages/wysiwyg/ContentPageRowDetail.vue";
import ContentPageColumnDetail from "@/components/layouts/content-pages/wysiwyg/ContentPageColumnDetail.vue";
import {PredefinedContentPageRowEnum} from "@/models/enum/PredefinedContentPageRowEnum";
import ContentPageAddColumn from "@/components/layouts/content-pages/wysiwyg/ContentPageAddColumn.vue";
import {default as Offcanvas} from 'bootstrap/js/dist/offcanvas';
import {default as Modal} from "bootstrap/js/dist/modal";
import {Container, Draggable} from "vue-dndrop";
import ConfirmModal from "@/components/UI/ConfirmModal.vue";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import {useToast} from "vue-toastification";
import {Popover, Tooltip} from "bootstrap";
import BaseTitle from "@/components/UI/BaseTitle.vue"
import {generateContentPagePreviewUrl} from "@/helpers/functions/route";
import {AutoSaveFeedbackStatusEnum} from "@/models/enum/AutoSaveFeedbackStatusEnum";
import ContentPagePageSettings from "@/components/layouts/content-pages/wysiwyg/ContentPagePageSettings.vue";
import ContentPagePageAbstract from "@/components/layouts/content-pages/wysiwyg/ContentPagePageAbstract.vue";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import {StatusEnum} from "@/api/enums/status-enum";
import {TinyEmitter} from "tiny-emitter";
import {MoveActionEnum} from "@/models/enum/MoveActionEnum";
import {ContentCustomerRestService} from "@/services/rest/content-customer/ContentCustomerRestService";
import {ColorSchemeRestDtoModel} from "@/models/api/pharma-cms-color-scheme/ColorSchemeRestDtoModel";
import ContentPageColumn from "@/components/layouts/content-pages/wysiwyg/ContentPageColumn.vue";
import {AuthContext} from "@/context/AuthContext";
import MetadataIcons from "@/components/layouts/content-pages/wysiwyg/misc/MetadataIcons.vue";
import {ContentPageRowClipboardModel} from "@/models/clipboard/ContentPageRowClipboardModel";
import ContentPageCopy from "@/components/layouts/content-pages/wysiwyg/ContentPageCopy.vue";
import {mapActions as mapActionsP, mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import {useClipboardStore} from "@/stores/ClipboardStore";
import BaseOffcanvas from "@/components/UI/Bootstrap/Offcanvas/BaseOffcanvas.vue";
import BaseOffcanvasButtonClose from "@/components/UI/Bootstrap/Offcanvas/BaseOffcanvasButtonClose.vue";
import BaseOffcanvasButtonOpen from "@/components/UI/Bootstrap/Offcanvas/BaseOffcanvasButtonOpen.vue";
import ContentPageRowVisibilityRule from "@/components/layouts/content-pages/wysiwyg/ContentPageRowVisibilityRule.vue";
import {AbilityContext} from "@/context/AbilityContext";
import ContentPageColumnVisibilityRule
  from "@/components/layouts/content-pages/wysiwyg/ContentPageColumnVisibilityRule.vue";
import {getDescriptionVisibilityRuleStatus} from "@/helpers/functions/visibility-rules";
import SnippetMetaData from "@/components/layouts/content-pages/snippets/SnippetMetaData.vue";
import {SnippetRestService} from "@/services/rest/csm-snippet/SnippetRestService";

const __default__ = defineComponent({
  name: "CpsDetailWysiwyg",
  components: {
    SnippetMetaData,
    ContentPageColumnVisibilityRule,
    ContentPageRowVisibilityRule,
    BaseOffcanvasButtonOpen,
    BaseOffcanvasButtonClose,
    BaseOffcanvas,
    ContentPageCopy,
    MetadataIcons,
    ContentPageColumn,
    ContentPagePageAbstract,
    ContentPagePageSettings,
    ContentPageAddColumn,
    ContentPageColumnDetail,
    ContentPageRowDetail,
    Container,
    Draggable,
    BaseTitle,
  },
  props: {
    id: {
      type: String,
    },
    mode: {
      type: String,
      required: true,
    },
    libCode: {
      type: String,
      required: false,
    },
    isHomepage: {
      type: Boolean,
      required: false,
      default: false
    },
    isNamedPage: {
      type: Boolean,
      required: false,
      default: false
    },
    namedPageTypeCode: {
      type: String,
      required: false,
    },
    backRoute: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      bus: new TinyEmitter(),
      busAddColumn: new TinyEmitter(),
      exceptions: [] as Exception[],
      isReady: false as boolean,
      pageType: null as string | null,
      savedSuccessfully: false as boolean,
      colorScheme: ColorSchemeRestDtoModel.createWithDefaults() as ColorSchemeRestDtoModel,

      enums: {
        predefinedContentPageRowEnum: PredefinedContentPageRowEnum,
        autoSaveFeedbackStatus: AutoSaveFeedbackStatusEnum,
      },

      activeRowId: null as string | null,
      activeColumnId: null as string | null,
      activeColumnRowId: null as string | null,
      hasHomepage: false as boolean,
      hasNamedPage: false as boolean,

      toaster: useToast(),

      editRowPopover: null as Popover | null,
      editColumnPopover: null as Popover | null,

      addRowOffcanvas: null as Offcanvas | null,
      editRowOffcanvas: null as Offcanvas | null,
      editRowVisibilityRuleOffcanvas: null as Offcanvas | null,

      addColumnOffcanvas: null as Offcanvas | null,
      editColumnOffcanvas: null as Offcanvas | null,
      editColumnVisibilityRuleOffcanvas: null as Offcanvas | null,

      copyContentPageModal: null as Modal | null,

      tooltips: [] as Tooltip[],
    };
  },
  mounted() {
    this.reloadContent();

    this.addRowOffcanvas = new Offcanvas('#offcanvas-add-row');
    this.editRowOffcanvas = new Offcanvas('#offcanvas-edit-row');
    this.editRowVisibilityRuleOffcanvas = new Offcanvas('#offcanvas-edit-row-visibility-rule');

    this.addColumnOffcanvas = new Offcanvas('#offcanvas-add-column');
    this.editColumnOffcanvas = new Offcanvas('#offcanvas-edit-column');
    this.editColumnVisibilityRuleOffcanvas = new Offcanvas('#offcanvas-edit-column-visibility-rule');

    if (this.backRoute !== 'snippets') {
      this.copyContentPageModal = new Modal('#copy-content-page-modal');
    }
  },
  beforeUnmount() {
    this.editRowPopover?.dispose();
    this.editColumnPopover?.dispose();
    this.tooltips.forEach(tt => {
      tt.dispose();
    });
  },
  unmounted() {
    this.copyContentPageModal?.dispose();
  },
  methods: {
    getDescriptionVisibilityRuleStatus,
    ...mapActions("cms_content_page", [
      "createHomePage",
      "createNamedPage",
      "searchPageTypeEnums",
      'moveContentBlockInRow',
      'copyContentBlockInRow',
      'deleteContentBlockInRow',
      'moveContentPageRow',
      'copyContentPageRow',
      'deleteContentPageRow',
    ]),
    ...mapActions("cms_content_page_wysiwyg", [
      "clearContentPage",
      "createContentPageRow",
      "searchContentPage",
      "searchHomePage",
      "searchNamedPage",
      "saveContentPage",
      "moveColumnToPosition",
    ]),
    ...mapActions('cms_enum', ['findManyEnumByClassname']),
    ...mapActionsP(useClipboardStore, ["copyToClipboardContentPageRowIds", "pasteClipboardContentPageRowIds"]),

    async moveColumn(moveAction: MoveActionEnum): Promise<void> {
      await this.moveContentBlockInRow({
        row_id: this.activeColumnRowId,
        cb_in_row_id: this.activeColumnId,
        move_action: moveAction,
      });

      this.editColumnPopover?.hide();
      this.hideTooltips();
      this.toaster.success(this.$t('cms.successfullyMovedColumn'));

      await this.reloadContent();
    },
    async duplicateColumn(): Promise<void> {
      await this.copyContentBlockInRow({
        row_id: this.activeColumnRowId,
        cb_in_row_id: this.activeColumnId,
      });

      this.editColumnPopover?.hide();
      this.hideTooltips();
      this.toaster.success(this.$t('cms.successfullyDuplicatedColumn'));

      await this.reloadContent();
    },
    confirmDeleteColumn(): void {
      const {reveal, onConfirm} = createConfirmDialog(ConfirmModal, {
        title: this.$t('cms.deleteColumn'),
        body: this.$t('cms.confirmDeleteColumn'),
        confirmButtonText: this.$t('delete'),
        confirmButtonClass: 'btn-danger',
      });

      onConfirm(async () => {
        await this.deleteContentBlockInRow({
          row_id: this.activeColumnRowId,
          cb_in_row_id: this.activeColumnId,
        });

        this.editColumnPopover?.hide();
        this.hideTooltips();
        this.toaster.success(this.$t('cms.successfullyDeletedColumn'));

        await this.reloadContent();
      });

      reveal();
    },
    async moveRow(moveAction: MoveActionEnum, rowId?: string): Promise<void> {
      await this.moveContentPageRow({
        page_id: this.contentPage.id,
        row_id: rowId ?? this.activeRowId,
        move_action: moveAction,
      });

      this.editRowPopover?.hide();
      this.hideTooltips();
      this.toaster.success(this.$t('cms.successfullyMovedRow'));

      await this.reloadContent();
    },
    copyRow(): void {
      this.copyToClipboardContentPageRowIds(this.activeRowId, this.contentPage.id);

      this.editRowPopover?.hide();
      this.hideTooltips();
      this.toaster.info(this.$t('cms.successfullyCopiedRow'));
    },
    async pasteRow(): Promise<void> {
      await this.pasteClipboardContentPageRowIds(this.contentPage.id);

      this.toaster.success(this.$t('cms.successfullyPastedRow'));

      await this.reloadContent();
      window.scrollTo(0, document.body.scrollHeight);
    },
    async duplicateRow(): Promise<void> {
      await this.copyContentPageRow({
        page_id: this.contentPage.id,
        row_id: this.activeRowId,
      });

      this.editRowPopover?.hide();
      this.hideTooltips();
      this.toaster.success(this.$t('cms.successfullyDuplicatedRow'));

      await this.reloadContent();
    },
    confirmDeleteRow() {
      const {reveal, onConfirm} = createConfirmDialog(ConfirmModal, {
        title: this.$t('cms.deleteRow'),
        body: this.$t('cms.confirmDeleteRow'),
        confirmButtonText: this.$t('delete'),
        confirmButtonClass: 'btn-danger',
      });

      onConfirm(async () => {
        await this.deleteContentPageRow({
          page_id: this.contentPage.id,
          row_id: this.activeRowId,
        });

        this.editRowPopover?.hide();
        this.hideTooltips();
        this.toaster.success(this.$t('cms.successfullyDeletedRow'));

        await this.reloadContent();
      });

      reveal();
    },

    async onDrop(dropResult: any): Promise<void> {
      if (dropResult.removedIndex === dropResult.addedIndex) {
        // unchanged positions
        return;
      }

      const rowId = dropResult.element.getAttribute('data-row-id');

      console.log('dropResult', dropResult);
      console.log('rowId', rowId);

      let rowIndex = null;

      const row = this.contentPage.rows.find((row: WysiwygContentPageRowRestDto, index: number) => {
        if (row.id === rowId) {
          rowIndex = index;
          return true;
        }

        return false;
      }) as WysiwygContentPageRowRestDto;

      console.log('rowIndex', rowIndex);

      console.log('row', row);

      const column = row.columns[dropResult.removedIndex];
      const columnId = column.id;

      if (rowIndex !== null) {
        this.contentPage.rows[rowIndex].columns = this.applyDrag(row.columns, dropResult, column);
      }

      let moveAction = null;

      if (dropResult.addedIndex > dropResult.removedIndex) {
        moveAction = MoveActionEnum.DOWN;
      } else {
        moveAction = MoveActionEnum.UP;
      }

      const payload = {
        row_id: row.id,
        cb_in_row_id: columnId,
        move_action: moveAction,
      };

      const noOfCalls = Math.abs(dropResult.addedIndex - dropResult.removedIndex);

      for (let i = 0; i < noOfCalls; i++) {
        await this.moveContentBlockInRow(payload);
        this.toaster.success(this.$t('cms.successfullyMovedColumn'));
      }

      this.editColumnPopover?.update();

      /*let before = null;
      let after = null;

      if (dropResult.addedIndex > dropResult.removedIndex) {
        after = row.columns[dropResult.addedIndex].id;
      } else {
        before = row.columns[dropResult.addedIndex].id;
      }

      const payload = {
        rowId: rowId,
        columnId: columnId,
        position: {
          before_id: before,
          after_id: after,
        },
      };

      this.moveColumnToPosition(payload);*/
    },
    applyDrag(arr: any, dragResult: any, itemToAdd: any) {
      const {removedIndex, addedIndex} = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;

      const result = [...arr];

      console.log('removedIndex', removedIndex);
      console.log('addedIndex', addedIndex);

      if (removedIndex !== null) {
        result.splice(removedIndex, 1);
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }

      return result;
    },
    activateRow(rowId: string): void {
      this.editColumnPopover?.hide();

      this.activeRowId = rowId;

      this.editRowPopover?.dispose();

      const content = this.$refs.editRowPopoverContent;

      this.editRowPopover = new Popover('#edit-row-' + rowId, {
        content: content,
        html: true,
        placement: 'left',
        trigger: 'manual',
        customClass: 'wysiwyg-popover',
      } as Partial<Popover.Options>);

      this.editRowPopover.show();
    },
    openEditRow(): void {
      this.editRowPopover?.hide();
      this.hideTooltips();
    },
    openEditRowVisibilityRule(): void {
      this.editRowPopover?.hide();
      this.hideTooltips();
    },
    openAddColumn(): void {
      this.busAddColumn.emit('openAddColumn');
      this.addColumnOffcanvas?.show();
      this.editRowPopover?.hide();
      this.hideTooltips();
    },
    openAddColumnFromRow(rowId: string): void {
      this.activeRowId = rowId;
      this.openAddColumn();
    },
    openEditColumn(): void {
      this.hideTooltips();
    },
    openEditColumnVisibilityRule(): void {
      this.hideTooltips();
    },
    openPageAbstract(): void {
      if (!this.contentPage.cp?.overview_cb_id) {
        this.reloadContent();
      }
      this.bus.emit('openPageAbstract');
    },
    activateColumn(columnId: string, rowId: string): void {
      this.editRowPopover?.hide();

      this.activeColumnId = columnId;
      this.activeColumnRowId = rowId;

      this.editColumnPopover?.dispose();

      const content = this.$refs.editColumnPopoverContent;

      this.editColumnPopover = new Popover('#column-' + columnId, {
        content: content,
        html: true,
        placement: 'top',
        trigger: 'manual',
        customClass: 'wysiwyg-popover',
      } as Partial<Popover.Options>);

      this.editColumnPopover.show();
    },
    async createHomepagePressed(): Promise<void> {
      this.isReady = false;
      this.exceptions = [];

      try {
        await this.createHomePage();
        await this.reloadContent();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      this.isReady = true;
    },
    async createNamedPagePressed(): Promise<void> {
      this.isReady = false;
      this.exceptions = [];

      try {
        await this.createNamedPage(this.namedPageTypeCode);
        await this.reloadContent();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      this.isReady = true;
    },
    async reloadContent(): Promise<void> {
      this.isReady = false;
      this.exceptions = [];

      try {
        await this.searchPageTypeEnums(this.isLibrary);
        if (this.isHomepage) {
          this.hasHomepage = await this.searchHomePage();
        } else if (this.isNamedPage) {
          this.hasNamedPage = await this.searchNamedPage(this.namedPageTypeCode)
        } else {
          if (this.mode.toLowerCase() === 'new') {
            this.clearContentPage();
            this.pageType = (this.isLibrary) ? (this.libCode ?? null) : this.pageTypeEnums[0].code;
          } else {
            await Promise.all([
              this.searchContentPage(this.id),
            ]);
            if (this.contentPage.type) {
              this.pageType = this.contentPage.type.code;
            }
          }
        }

        await this.findManyEnumByClassname([
          EnumClassNameCmsEnum.CONTENT_STATUS,
          EnumClassNameCmsEnum.CONTENT_BLOCK_STRUCTURE
        ]);

        if (this.isLibrary) {
          this.colorScheme = ColorSchemeRestDtoModel.createWithDefaults();
        } else {
          this.colorScheme = new ColorSchemeRestDtoModel(await ContentCustomerRestService.getInstance().findCustomerColorScheme());
        }
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      this.isReady = true;

      await nextTick();
      this.initializeTooltips();
    },
    initializeTooltips(): void {
      document.querySelectorAll('#cps-detail-wysiwyg [data-bs-toggle="tooltip"]').forEach(el => {
        const tooltip = new Tooltip(el as Element);
        this.tooltips.push(tooltip);
      });
    },
    hideTooltips(): void {
      this.tooltips.forEach(tt => {
        tt.hide();
      });
    },
    async afterSave(): Promise<void> {
      if (this.mode.toLowerCase() === 'new') {
        await this.$router.push(this.returnRoute);
      } else {
        window.scrollTo(0, 0);

        await this.reloadContent();
        this.savedSuccessfully = true;
        setTimeout(() => this.savedSuccessfully = false, 2000);
      }
    },
    async addRow(type: PredefinedContentPageRowEnum): Promise<void> {
      switch (type) {
        case PredefinedContentPageRowEnum.EMPTY:
          await this.createContentPageRow(this.contentPage.id);
          break;
      }

      this.toaster.success(this.$t('cms.successfullyAddedRow'));

      await this.reloadContent();

      window.scrollTo(0, document.body.scrollHeight);
    },

    onContentPageAddColumnSubmit(): void {
      this.addColumnOffcanvas?.hide();

      this.toaster.success(this.$t('cms.successfullyAddedColumn'));

      this.reloadContent();
    },

    getColumnWidthCode(columnWidthEnum: string): string {
      return 'col col-' + columnWidthEnum.split('_')[1];
    },
    getHeightable(heightableEnum: string): string {
      return 'dp-h-' + heightableEnum.split('_')[1];
    },
    getRowClasses(row: ContentPageRowRestDto): string {
      return 'dndrop-container horizontal row'
        + ' ' + this.getHorizontalAlignment(row.items_horizontal_alignment_code)
        + ' ' + this.getVerticalAlignment(row.items_vertical_alignment_code)
        + ' ' + this.getHorizontalGutter(row.horizontal_gutter_code);
    },
    getHorizontalAlignment(horizontalAlignmentEnum: string): string {
      return 'justify-content-' + horizontalAlignmentEnum.toLowerCase();
    },
    getVerticalAlignment(verticalAlignmentEnum: string): string {
      if (verticalAlignmentEnum === 'MIDDLE') {
        verticalAlignmentEnum = 'center';
      }

      return 'align-items-' + verticalAlignmentEnum.toLowerCase();
    },
    getHorizontalGutter(horizontalGutterEnum: string): string {
      return 'gx-' + horizontalGutterEnum.replace('G_', '');
    },
    getBackgroundClasses(backgroundColorCodeEnum: string, backgroundColor: ColorRestDto): string {
      if (backgroundColor) {
        return '';
      }
      return 'dp-bg-' + backgroundColorCodeEnum.toLowerCase().replaceAll('_', '-');
    },
    getBackgroundStyle(backgroundColor: ColorRestDto): any {
      if (!backgroundColor) {
        return null;
      }
      return {
        backgroundColor: `rgba(${backgroundColor.rgb.red}, ${backgroundColor.rgb.green}, ${backgroundColor.rgb.blue}, ${backgroundColor.opacity})`
      };
    },
    async setStatusCode(status_code: StatusEnum): Promise<void> {
      this.exceptions = [];
      let initialStatusCode;

      try {
        if (this.contentPage?.cp?.status_code) {
          initialStatusCode = this.contentPage.cp.status_code;
          this.contentPage.cp.status_code = status_code;
        }

        await this.saveContentPage();
        if (this.backRoute === 'snippets') {
          SnippetRestService.getInstance().snippetsChanged();
        }

        this.toaster.success(this.$t('cms.successfullyUpdatedStatus'));
      } catch (exceptions: any) {
        this.exceptions = exceptions;

        if (this.contentPage?.cp?.status_code) {
          this.contentPage.cp.status_code = initialStatusCode;
        }
      }
    }
  },
  computed: {
    StatusEnum() {
      return StatusEnum
    },
    AbilityContext() {
      return AbilityContext
    },
    ...mapGetters("cms_content_page", ["getContentPageLibraryType", "getPageTypeEnums", "getContentPageLibraryName"]),
    ...mapGetters("cms_content_page_wysiwyg", ["getContentPage"]),
    ...mapGetters('cms_enum', ['getEnumByClassname']),
    ...mapState(useClipboardStore, ["getClipboardContentPageRowIds"]),
    ...mapState(useCustomerStore, ["getCustomers"]),
    contentPage(): WysiwygContentPageRestDto {
      return this.getContentPage;
    },
    previewUrl(): string {
      return generateContentPagePreviewUrl(this.contentPage.id);
    },
    contentPageRowClipboard(): ContentPageRowClipboardModel {
      return this.getClipboardContentPageRowIds;
    },
    pageTypeEnums(): CodeDescriptionRestDto[] {
      return this.getPageTypeEnums;
    },
    title(): string {
      if (this.isHomepage) {
        return this.$t('homepage');
      }
      if (this.isLibrary) {
        return `${this.$t('websiteContent')} ${this.$t('library').toLowerCase()} (${this.getContentPageLibraryName(this.libCode)})`;
      }

      return this.contentPage.cp?.name ?? this.$t('websiteContent');
    },
    libraryType(): string | undefined {
      if (this.isLibrary) {
        return this.getContentPageLibraryType(this.libCode);
      }
      return undefined;
    },
    returnRoute(): Object {
      if (this.isHomepage) {
        return {name: 'home'};
      }
      if (this.isNamedPage) {
        return {name: 'home'};
      }
      if (this.isLibrary) {
        return {name: 'libraries-content-pages-overview', params: {lib_code: this.libCode}};
      }

      return {name: this.backRoute};
    },
    isDpUser(): boolean {
      return AuthContext.isDpUser();
    },
    isLibrary(): boolean {
      return !!this.libCode;
    },
    enumContentStatus(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCmsEnum.CONTENT_STATUS);
    },
    MoveActionEnum() {
      return MoveActionEnum
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "84e8b70a": (_ctx.colorScheme?.primary_color),
  "0f2a457f": (_ctx.colorScheme?.primary_lighter_color),
  "2d7b3bdc": (_ctx.colorScheme?.primary_light_color),
  "09b6192d": (_ctx.colorScheme?.secondary_color),
  "a9942f9e": (_ctx.colorScheme?.secondary_lighter_color),
  "08db0944": (_ctx.colorScheme?.secondary_light_color)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__