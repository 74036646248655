
import {defineComponent} from 'vue'
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import BaseContainer from "@/components/UI/Bootstrap/BaseContainer.vue";
import {useToast} from "vue-toastification";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import OptionPackCard from "@/components/layouts/licensing/OptionPackCard.vue";
import {CustomerContext} from "@/context/CustomerContext";

export default defineComponent({
  name: "OptionPacks",
  components: {
    OptionPackCard,
    BaseContainer,
    BaseTitle,
    AlertError2,
  },
  data() {
    return {

      mailToTarget: 'mail@digital-pharma.be',

      toaster: useToast(),
      pageUI: UIStateDto.createWithDefaults(),

    }
  },
  computed: {},
  mounted() {
    this.reloadContent();
    this.pageUI.setReady();
  },
  methods: {
    async reloadContent(): Promise<void> {
      this.pageUI
        .setNotReady()
        .clearError();

      try {
        console.log('reloadContent');
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.pageUI.setReady();
      }
    },
    orderMailToLink(productCode: string): string {
      const recipient = this.mailToTarget;
      const productDesc = this.productDescription(productCode);
      const subject = encodeURIComponent("Bestelling optiepakket " + productDesc);
      const customerCode = CustomerContext.getCustomerCode();
      const bodyText = `Beste,\n\nGraag wil ik het optiepakket "${productDesc}" bestellen.\n\nVriendelijke groeten \n\n\nTechnische referentie: ${customerCode}`;
      const body = encodeURIComponent(bodyText);

      return `mailto:${recipient}?subject=${subject}&body=${body}`;
    },
    moreInfoMailToLink(productCode: string): string {
      const recipient = this.mailToTarget;
      const productDesc = this.productDescription(productCode);
      const subject = encodeURIComponent("Informatie over optiepakket " + productDesc);
      const customerCode = CustomerContext.getCustomerCode();
      const bodyText = `Beste,\n\nGraag wil ik meer informatie over het optiepakket "${productDesc}".  Kan je mij hierover contacteren?\n\nVriendelijke groeten \n\n\nTechnische referentie: ${customerCode}`;
      const body = encodeURIComponent(bodyText);

      return `mailto:${recipient}?subject=${subject}&body=${body}`;
    },
    productDescription(productCode: string): string {
      if (productCode === 'ALTERNATIVE_DELIVERY_METHODS_PACK') {
        return 'Alternatieve levermethoden';
      } else if (productCode === 'PIM_FOR_WEBSITE_PACK') {
        return 'PharmaPIM';
      } else if (productCode === 'MULTI_PHARMACY_PACK') {
        return 'Multi-apotheek';
      } else if (productCode === 'PHARMACY_GROUP_MEMBER_PACK') {
        return 'Apothekersgroep';
      } else if (productCode === 'PHARMACY_SW_SYNC_PACK') {
        return 'Apotheek Software sync';
      } else if (productCode === 'ONLINE_PAYMENT_PACK') {
        return 'Online betalen';
      } else if (productCode === 'MULTILINGUAL') {
        return 'Meertaligheid';
      } else if (productCode === 'ORDER_SMS_COMMUNICATION') {
        return 'SMS Communicatie';
      } else if (productCode === 'ORCA_MODULE') {
        return 'ORCA Reservatietool';
      } else if (productCode === 'PUBLISH_DP_ARTICLES') {
        return 'Gezondheidsartikels';
      }

      return '';
    },
    availableFor(productCode: string): string[] {
      if (productCode === 'MULTI_PHARMACY_PACK') {
        return ['Website (Premium)', 'Website (Basic)'];
      } else if (productCode === 'PHARMACY_GROUP_MEMBER_PACK') {
        return ['Website (Premium)', 'Website (Basic)'];
      } else if (productCode === 'MULTILINGUAL') {
        return ['Website (Premium)', 'Website (Basic)', 'Website (Starter)', 'Pharmacollect+ (Template design)', 'Pharmacollect+ (APB design)', 'Pharmacollect (Template design)', 'Pharmacollect (APB design)'];
      } else if (productCode === 'PUBLISH_DP_ARTICLES') {
        return ['Website (Premium)'];
      } else if (productCode === 'ORCA_MODULE') {
        return ['Website (Premium)', 'Website (Basic)', 'Website (Starter)'];
      }

      return ['Website (Premium)', 'Website (Basic)', 'Pharmacollect+ (Template design)', 'Pharmacollect+ (APB design)'];
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .catch(exceptions, true);
    }
  }
})
