import {
    CustomerProductPropertiesResultsRestDto,
    CustomerProductPropertyRestDto
} from "@/api/pharma-cpc-product-mgmt/models";
import {StrategyEnum} from "@/api/enums/strategy-enum";

export class CustomerProductPropertyRestDtoModel implements CustomerProductPropertyRestDto {
    language_code: string;
    strategy_code: StrategyEnum;
    type_code: string;
    value: string;

    constructor(typeCode: string, languageCode: string) {
        this.language_code = languageCode;
        this.strategy_code = StrategyEnum.SHOW_BOTH;
        this.type_code = typeCode;
        this.value = '';
    }

    public static createWithoutDefaults(): CustomerProductPropertyRestDtoModel {
        return new this('', '');
    }

    public static createWithDefaults(typeCode: string, languageCode: string): CustomerProductPropertyRestDtoModel {
        return new this(typeCode, languageCode);
    }
}
