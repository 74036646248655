import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CpsOverview = _resolveComponent("CpsOverview")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, { "container-fluid": "" }, {
    default: _withCtx(() => [
      _createVNode(_component_CpsOverview, {
        "lib-code": this.libCode,
        "lib-page-type-code": this.libPageTypeCode
      }, null, 8, ["lib-code", "lib-page-type-code"])
    ]),
    _: 1
  }))
}