
import {defineComponent} from "vue";

export default defineComponent({
  name: "BasePlaceholderTable",
  props: {
    nCols: {
      type: Number,
      required: true,
    },
  }
});
