import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-119b16f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row gx-5" }
const _hoisted_2 = { class: "col-4 col-xl-3 sidebar" }
const _hoisted_3 = { class: "col-8 col-xl-9 overview" }
const _hoisted_4 = { class: "row row-list gx-5" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = {
  key: 0,
  class: "col-4 col-xl-6 col-xxl-4"
}
const _hoisted_7 = { class: "row row-tiles" }
const _hoisted_8 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_AlertError2 = _resolveComponent("AlertError2")!
  const _component_OverviewCriteria = _resolveComponent("OverviewCriteria")!
  const _component_OverviewLegend = _resolveComponent("OverviewLegend")!
  const _component_OverviewControls = _resolveComponent("OverviewControls")!
  const _component_OverviewTable = _resolveComponent("OverviewTable")!
  const _component_OverviewCategorization = _resolveComponent("OverviewCategorization")!
  const _component_OverviewTiles = _resolveComponent("OverviewTiles")!
  const _component_base_container = _resolveComponent("base-container")!
  const _component_PimProductsDetail = _resolveComponent("PimProductsDetail")!
  const _component_BaseOffcanvas = _resolveComponent("BaseOffcanvas")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_container, { "container-fluid": "" }, {
      default: _withCtx(() => [
        _createVNode(_component_BaseTitle, {
          title: "PIM producten overzicht",
          mb: 3,
          "show-refresh-button": "",
          onRefresh: this.reloadContent
        }, null, 8, ["onRefresh"]),
        _createVNode(_component_AlertError2, {
          error: this.productsOverviewUI.getError()
        }, null, 8, ["error"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_OverviewCriteria, {
              onReloadedContent: this.onCriteriaInitialized,
              onSubmit: this.reloadContent
            }, null, 8, ["onReloadedContent", "onSubmit"]),
            _createVNode(_component_OverviewLegend, { class: "mt-4" })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_OverviewControls, {
                  "products-overview": this.productsOverview,
                  "has-products": this.hasProducts,
                  "has-category-expected-toggle": true,
                  class: "mb-3",
                  onChange: this.onControlsChanged,
                  onDownloadCsv: this.downloadOverviewAsCSV
                }, null, 8, ["products-overview", "has-products", "onChange", "onDownloadCsv"]),
                _createVNode(_component_OverviewTable, {
                  "products-overview": this.productsOverview,
                  "has-products": this.hasProducts,
                  ui: this.productsOverviewUI,
                  "is-category-expected": this.productsPaging.isCategoriesExpected,
                  onDeletedProduct: this.reloadContent,
                  onSelectedDpCat: this.onSelectedDpCat,
                  onSelectedIntCat: this.onSelectedIntCat,
                  onExceptions: this.setExceptions,
                  onOpenOffCanvasProductDetail: this.onOpenProductDetail
                }, null, 8, ["products-overview", "has-products", "ui", "is-category-expected", "onDeletedProduct", "onSelectedDpCat", "onSelectedIntCat", "onExceptions", "onOpenOffCanvasProductDetail"]),
                _createVNode(_component_OverviewControls, {
                  "products-overview": this.productsOverview,
                  "has-products": this.hasProducts,
                  "has-category-expected-toggle": true,
                  class: "mt-3",
                  onChange: this.onControlsChanged,
                  onDownloadCsv: this.downloadOverviewAsCSV
                }, null, 8, ["products-overview", "has-products", "onChange", "onDownloadCsv"])
              ]),
              (_ctx.AbilityContext.isAuthorizedForFeature('PIM_PRODUCT_CATEGORY'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (this.criteriaInitialized)
                      ? (_openBlock(), _createBlock(_component_OverviewCategorization, {
                          key: 0,
                          "products-overview": this.productsOverview,
                          onSubmitted: this.onCategorizationSubmitted
                        }, null, 8, ["products-overview", "onSubmitted"]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ], 512), [
              [_vShow, this.productsPaging.layout === _ctx.PimProductsOverviewPageLayoutEnum.LIST]
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_OverviewControls, {
                  "products-overview": this.productsOverview,
                  "has-products": this.hasProducts,
                  class: "mb-3",
                  onChange: this.onControlsChanged,
                  onDownloadCsv: this.downloadOverviewAsCSV
                }, null, 8, ["products-overview", "has-products", "onChange", "onDownloadCsv"]),
                _createVNode(_component_OverviewTiles, {
                  "products-overview": this.productsOverview,
                  "has-products": this.hasProducts,
                  ui: this.productsOverviewUI,
                  onOpenOffCanvasProductDetail: this.onOpenProductDetail
                }, null, 8, ["products-overview", "has-products", "ui", "onOpenOffCanvasProductDetail"]),
                _createVNode(_component_OverviewControls, {
                  "products-overview": this.productsOverview,
                  "has-products": this.hasProducts,
                  class: "mt-3",
                  onChange: this.onControlsChanged,
                  onDownloadCsv: this.downloadOverviewAsCSV
                }, null, 8, ["products-overview", "has-products", "onChange", "onDownloadCsv"])
              ])
            ], 512), [
              [_vShow, this.productsPaging.layout === _ctx.PimProductsOverviewPageLayoutEnum.TILES]
            ])
          ])
        ])
      ]),
      _: 1
    }),
    (_ctx.AbilityContext.isAuthorizedForFeature('PIM_PRODUCT_INFO'))
      ? (_openBlock(), _createBlock(_component_BaseOffcanvas, {
          key: 0,
          id: this.offcanvasProductDetailId,
          title: "PIM product",
          label: "PIM product",
          class: "offcanvas-wide",
          "custom-content": ""
        }, {
          default: _withCtx(() => [
            (this.selectedDetailProduct?.product_info?.cnk_code)
              ? (_openBlock(), _createBlock(_component_PimProductsDetail, {
                  key: 0,
                  "cnk-code": this.selectedDetailProduct.product_info.cnk_code
                }, null, 8, ["cnk-code"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["id"]))
      : _createCommentVNode("", true)
  ], 64))
}