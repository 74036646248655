
import {defineComponent} from "vue";

export default defineComponent({
  name: "ProductDetailCustomerProductTab",
  emits: ["savedSuccessfully"],
  props: {
    productCode: {
      type: String,
      required: true
    },
    cnkCode: {
      type: String,
      required: false
    }
  },
});
