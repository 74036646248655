import {RestService} from "@/services/rest/RestService";
import {SimpleContentBlockEndpointsApi} from "@/api/pharma-cms-content-block";
import {SimpleTextContentRestDto} from "@/api/pharma-cms-content-block/models";
import {
    SimpleTextContentRestDtoModel
} from "@/models/api/pharma-cms-simple-content-block/SimpleTextContentRestDtoModel";

export class SimpleContentBlockRestService extends RestService {
    private apiService: SimpleContentBlockEndpointsApi;

    protected constructor() {
        super();
        this.apiService = new SimpleContentBlockEndpointsApi(this.getDpAxiosInstance(), undefined, this.getApiGwUrl('/pharma-cms'));
    }

    public async getSimpleContentBlockText(type: string): Promise<SimpleTextContentRestDtoModel> {
        const response = await this.apiService.getSimpleContentBlockText(type);

        if (response.status === 204) {
            return SimpleTextContentRestDtoModel.createWithDefaults();
        }

        return response.data as SimpleTextContentRestDtoModel;
    }

    public async updateSimpleContentBlockText(type: string, dto: SimpleTextContentRestDto): Promise<void> {
        await this.apiService.updateSimpleContentBlockText(dto, type);
    }
}
