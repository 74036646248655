
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {stringHasContent} from "@/helpers/functions/string";

export default defineComponent({
  name: "ProductSelectionBrandsFilters",
  emits: ["submit"],
  computed: {
    ...mapGetters('cpc_mgmt', ['getSearchBrandName']),
    searchQuery: {
      get(): string | undefined {
        return this.getSearchBrandName;
      },
      set(value: string) {
        (value.length > 0) ? this.setSearchBrandName(value) : this.clearSearchBrandName();
      },
    },
    hasSearchQuery(): boolean {
      return stringHasContent(this.searchQuery);
    },
  },
  mounted() {
    this.clearSearchBrandName();
  },
  methods: {
    ...mapActions('cpc_mgmt', [
      'clearSearchBrandName',
      'setSearchBrandName',
    ]),
    async clearSearch() {
      this.clearSearchBrandName();
      this.$emit('submit');
    },
  }
});
