import {CmsEnumEndpointsApiService} from "@/main";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-enum/models";
import {DpException} from "@/exception/DpException";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";

export default {
    namespaced: true,
    state() {
        return {
            enums: new Map<EnumClassNameCmsEnum, CodeDescriptionRestDto[]>(),

            items_horizontal_alignment_enum: [] as CodeDescriptionRestDto[],
            items_vertical_alignment_enum: [] as CodeDescriptionRestDto[],
        };
    },
    actions: {
        async findEnumByClassname(context: any, className: EnumClassNameCmsEnum): Promise<void> {
            if (!context.state.enums.has(className)) {
                const response = await CmsEnumEndpointsApiService.getCmsEnumValuesByClassname('App\\Model\\Enum\\' + className);
                context.state.enums.set(className, response.data as CodeDescriptionRestDto[]);
            }
        },
        async findManyEnumByClassname(context: any, classNames: EnumClassNameCmsEnum[]): Promise<void> {
            const promises = [] as Promise<void>[];

            classNames.forEach((className: EnumClassNameCmsEnum) => {
                promises.push(context.dispatch("findEnumByClassname", className));
            });

            await Promise.all(promises);
        },

        // @deprecated
        async searchEnum(context: any, enum_class_name: string) {
            if (context.getters.getEnum(enum_class_name).length === 0) {
                const response = await CmsEnumEndpointsApiService.getCmsEnumValuesByClassname('App\\Model\\Enum\\' + enum_class_name);

                context.commit("setEnum", {
                    enum: response.data,
                    enum_class_name: enum_class_name
                });
            }
        }
    },
    mutations: {
        // @deprecated
        setEnum(state: any, payload: any) {
            const enumeration = payload.enum as CodeDescriptionRestDto[];
            const enum_class_name = payload.enum_class_name as string;

            //todo map gebruiken
            switch (enum_class_name) {
                case EnumClassNameCmsEnum.ITEMS_HORIZONTAL_ALIGNMENT :
                    state.items_horizontal_alignment_enum = enumeration;
                    break;
                case EnumClassNameCmsEnum.ITEMS_VERTICAL_ALIGNMENT :
                    state.items_vertical_alignment_enum = enumeration;
                    break;
                default:
                    throw [new DpException('Unrecognized enum_class_name to set: ' + enum_class_name)];
            }
        }
    },
    getters: {
        getEnumByClassname: (state: any) => (className: EnumClassNameCmsEnum): CodeDescriptionRestDto[] => {
            if (!state.enums.has(className)) {
                throw new DpException('Enum `' + className + '` has not been initialized, yet.');
            }

            return state.enums.get(className);
        },
        getEnumByClassnameOrDefault: (state: any, getters: any) => (className: EnumClassNameCmsEnum, defaultValue: any): CodeDescriptionRestDto[]|any => {
            try {
                return getters.getEnumByClassname(className);
            } catch (exceptions: any) {
                return defaultValue;
            }
        },
        getCodeDescriptionByCodeFromEnumByClassname: (state: any, getters: any) => (className: EnumClassNameCmsEnum, code: string): CodeDescriptionRestDto|null => {
            return getters.getEnumByClassname(className).find((cd: CodeDescriptionRestDto) => {
                return cd.code === code;
            }) ?? null;
        },

        // @deprecated
        getEnum: (state: any) => (enum_class_name: string): CodeDescriptionRestDto[] => {
            //todo map gebruiken
            switch (enum_class_name) {
                case EnumClassNameCmsEnum.ITEMS_HORIZONTAL_ALIGNMENT :
                    return state.items_horizontal_alignment_enum;
                case EnumClassNameCmsEnum.ITEMS_VERTICAL_ALIGNMENT :
                    return state.items_vertical_alignment_enum;
                default:
                    throw [new DpException('Unrecognized enum_class_name to get: ' + enum_class_name)];
            }
        }
    },
};
