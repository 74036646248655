import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-363a57c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-group-item d-flex justify-content-between align-items-center" }
const _hoisted_2 = { class: "trademark-name lh-sm mb-0" }
const _hoisted_3 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductSelectionTrademarksSelectionItemBadges = _resolveComponent("ProductSelectionTrademarksSelectionItemBadges")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.trademarkItem.name), 1),
    _createElementVNode("div", {
      class: "btn-toolbar justify-content-end align-items-center",
      role: "toolbar",
      "aria-label": `TrademarkItem buttons for ${_ctx.trademarkItem.name}`
    }, [
      (_ctx.hasProducts)
        ? (_openBlock(), _createBlock(_component_ProductSelectionTrademarksSelectionItemBadges, {
            key: 0,
            "trademark-item": _ctx.trademarkItem
          }, null, 8, ["trademark-item"]))
        : _createCommentVNode("", true),
      (_ctx.filterSelectionTypeCode !== 'BASE_SELECTION')
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "btn btn-warning ms-2",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateTrademarkItem(_ctx.trademarkItem.trademark_code,'BASE_SELECTION')))
          }, _toDisplayString('B')))
        : _createCommentVNode("", true),
      (_ctx.filterSelectionTypeCode !== 'INCLUDE')
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            class: "btn btn-secondary ms-2",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateTrademarkItem(_ctx.trademarkItem.trademark_code,'INCLUDE')))
          }, _toDisplayString('+')))
        : _createCommentVNode("", true),
      (_ctx.filterSelectionTypeCode !== 'EXCLUDE')
        ? (_openBlock(), _createElementBlock("button", {
            key: 3,
            class: "btn btn-danger ms-2",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateTrademarkItem(_ctx.trademarkItem.trademark_code,'EXCLUDE')))
          }, _toDisplayString('-')))
        : _createCommentVNode("", true)
    ], 8, _hoisted_3)
  ]))
}