/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum InSlotPositionEnum {
    MAIN_CATEGORIES_TOP = 'MAIN_CATEGORIES_TOP',
    MAIN_CATEGORIES_BOTTOM = 'MAIN_CATEGORIES_BOTTOM',
    PROD_CATALOG_TOP = 'PROD_CATALOG_TOP',
    PROD_CATALOG_BOTTOM = 'PROD_CATALOG_BOTTOM',
    PROD_DETAIL_BOTTOM = 'PROD_DETAIL_BOTTOM',
    SHOPPING_BASKET_TOP = 'SHOPPING_BASKET_TOP',
    SHOPPING_BASKET_ABOVE_BUTTONS = 'SHOPPING_BASKET_ABOVE_BUTTONS',
    CHECKOUT_USER_DELIVERY_METHOD_ABOVE_BUTTONS = 'CHECKOUT_USER_DELIVERY_METHOD_ABOVE_BUTTONS',
    CHECKOUT_USER_DELIVERY_METHOD_BOTTOM = 'CHECKOUT_USER_DELIVERY_METHOD_BOTTOM',
    CHECKOUT_USER_VALIDATE_ABOVE_BUTTONS = 'CHECKOUT_USER_VALIDATE_ABOVE_BUTTONS',
    CHECKOUT_USER_VALIDATE_BOTTOM = 'CHECKOUT_USER_VALIDATE_BOTTOM',
    CHECKOUT_GUEST_BOTTOM = 'CHECKOUT_GUEST_BOTTOM',
    ORDER_SENT_BOTTOM = 'ORDER_SENT_BOTTOM',
    CONFIRM_ACCOUNT_INVITATION_BOTTOM_BOTTOM = 'CONFIRM_ACCOUNT_INVITATION_BOTTOM_BOTTOM',
}