import {CmsFooterContent1RestDto} from "@/api/pharma-cms-footer/models";
import {PharmacyInfoLayoutEnum} from "@/api/enums/pharmacy-info-layout-enum";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import {FooterContent1LayoutEnum} from "@/api/enums/footer-content1-layout-enum";

export class CmsFooterContent1RestDtoModel implements CmsFooterContent1RestDto {
    layout_code: FooterContent1LayoutEnum;
    pharmacy_info_layout_code?: PharmacyInfoLayoutEnum | null;
    is_show_public_email: boolean;
    is_show_public_address: boolean;
    is_show_public_phone: boolean;
    is_show_openinghours_week?: boolean | null;
    is_show_openinghours_today?: boolean | null;
    is_show_social_media: boolean;
    text_top?: MultilingualRestDtoModel | null;
    text_bottom?: MultilingualRestDtoModel | null;


    constructor(layout_code: FooterContent1LayoutEnum, pharmacy_info_layout_code: PharmacyInfoLayoutEnum | null, is_show_public_email: boolean, is_show_public_address: boolean, is_show_public_phone: boolean, is_show_openinghours_week: boolean | null, is_show_openinghours_today: boolean | null, is_show_social_media: boolean, text_top: MultilingualRestDtoModel | null, text_bottom: MultilingualRestDtoModel | null) {
        this.layout_code = layout_code;
        this.pharmacy_info_layout_code = pharmacy_info_layout_code;
        this.is_show_public_email = is_show_public_email;
        this.is_show_public_address = is_show_public_address;
        this.is_show_public_phone = is_show_public_phone;
        this.is_show_openinghours_week = is_show_openinghours_week;
        this.is_show_openinghours_today = is_show_openinghours_today;
        this.is_show_social_media = is_show_social_media;
        this.text_top = text_top;
        this.text_bottom = text_bottom;
    }

    public static createWithDefaults(): CmsFooterContent1RestDtoModel {
        return new this(
            FooterContent1LayoutEnum.PHARMACY_INFO_LEFT,
            PharmacyInfoLayoutEnum.MAIN_PHARMACY_ONLY,
            false,
            false,
            false,
            false,
            false,
            false,
            MultilingualRestDtoModel.createWithDefaults(),
            MultilingualRestDtoModel.createWithDefaults()
        );
    }
}
