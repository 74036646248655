/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum SymbolFamilyEnum {
    MATERIAL_SYMBOLS_OUTLINED = 'MATERIAL_SYMBOLS_OUTLINED',
    MATERIAL_SYMBOLS_ROUNDED = 'MATERIAL_SYMBOLS_ROUNDED',
    MATERIAL_SYMBOLS_SHARP = 'MATERIAL_SYMBOLS_SHARP',
}