
import {defineComponent} from "vue";
import {ProductFilterConditionRestDto} from "@/api/pharma-cpc-mgmt/models";
import {mapActions} from "vuex";
import ProductFilterBrands from "@/components/layouts/catalog/product-filter/ProductFilterBrands.vue";
import ProductFilterTrademarks from "@/components/layouts/catalog/product-filter/ProductFilterTrademarks.vue";
import ProductFilterCnkList from "@/components/layouts/catalog/product-filter/ProductFilterCnkList.vue";
import ProductFilterCategories from "@/components/layouts/catalog/product-filter/ProductFilterCategories.vue";
import {determineProductFilterConditionType} from "@/helpers/functions/catalog";
import {DpException} from "@/exception/DpException";
import DiscountPricelistSection from "@/components/layouts/catalog/discounts-pricelists/DiscountPriceListSection.vue";
import {PropType} from "vue/dist/vue";
import {ProductFilterApplyTypeEnum} from "@/models/enum/ProductFilterApplyTypeEnum";

export default defineComponent({
  name: "ProductFilter",
  components: {
    DiscountPricelistSection,
    ProductFilterCategories,
    ProductFilterCnkList, ProductFilterBrands, ProductFilterTrademarks
  },
  emits: [],
  props: {
    applyOnType: {
      type: String as PropType<ProductFilterApplyTypeEnum>,
      required: true
    },
    index: {
      type: Number,
      required: true,
    },
    filterConditionObject: {
      type: Object,
      required: true,
    },
    //TODO check which exact type errors is
    errors: [Array, Object, String],
  },
  computed: {
    filterCondition(): ProductFilterConditionRestDto {
      return this.filterConditionObject;
    },
    filterConditionType(): string {
      return determineProductFilterConditionType(this.filterCondition);
    },
    title(): string {
      switch (this.filterConditionType) {
        case 'all':
          return this.$t('allProducts');
        case 'cnk-list':
          return this.$t('cnkList');
        case 'brands-categories':
          return this.$t('labsBrandsAndCategories');
        case 'trademarks-categories':
          return this.$t('addTrademarksAndCategories');
        default:
          //TODO ask proper validation
          throw [new DpException('Unrecognized Filter condition to create title')];
      }
    }
  },
  methods: {
    ...mapActions('cpc_mgmt', ['removeFilterCondition']),
  }
});
