
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {
  PagedTrademarkSelectionOverviewRestDto,
} from "@/api/pharma-cpc-mgmt/models";
import {formatIntlNumber} from '@/helpers/functions/intl-format';
import ProductSelectionTrademarksSelectionItem
  from "@/components/layouts/catalog/product-selection/ProductSelectionTrademarksSelectionItem.vue";
import BaseCard from "@/components/UI/Bootstrap/Card/BaseCard.vue";
import BaseCardHeader from "@/components/UI/Bootstrap/Card/BaseCardHeader.vue";
import BaseCardBody from "@/components/UI/Bootstrap/Card/BaseCardBody.vue";
import BasePagination from "@/components/UI/Bootstrap/BasePagination.vue";
import {arrayHasContent} from "@/helpers/functions/arrays";
import {SelectionTypeEnum} from "@/api/enums/selection-type-enum";

export default defineComponent({
  name: "ProductSelectionTrademarksSelection",
  components: {BasePagination, BaseCardBody, BaseCardHeader, BaseCard, TrademarkItem: ProductSelectionTrademarksSelectionItem},
  emits: ["updateTrademarkItem", "updatedPage"],
  props: {
    filterSelectionTypeCode: {
      type: String,
      required: false,
      default: undefined,
    },
    isReady: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('cpc_mgmt', ['getProductSelectionTrademarks']),
    trademarkSelectionOverview(): PagedTrademarkSelectionOverviewRestDto {
      return this.getProductSelectionTrademarks(this.filterSelectionTypeCode);
    },
    hasTrademarks(): boolean {
      return arrayHasContent(this.trademarkSelectionOverview.results);
    },
    itemsCountText(): String {
      const itemLabel = (this.trademarkSelectionOverview.paging_metadata?.total_elements === 1) ? this.$t('item') : this.$t('items');
      return `${formatIntlNumber(this.trademarkSelectionOverview.paging_metadata?.total_elements ?? 0, this.$i18n.locale)} ${itemLabel.toLowerCase()}`;
    },
    title(): Object {
      switch (this.filterSelectionTypeCode) {
        case SelectionTypeEnum.BASE_SELECTION:
          return {name: 'followBaseSelection', icon: 'B', btnClass: 'btn-warning'};
        case SelectionTypeEnum.INCLUDE:
          return {name: 'doFollow', icon: '+', btnClass: 'btn-secondary'};
        case SelectionTypeEnum.EXCLUDE:
          return {name: 'doNotFollow', icon: '-', btnClass: 'btn-danger'};
        case undefined:
          return {name: 'allTrademarks', icon: 'A', btnClass: 'btn-dark'};
        default:
          throw new Error('Unsupported filterSelectionTypeCode to map ' + this.filterSelectionTypeCode);
      }
    },
  },
  methods: {
    ...mapActions('cpc_mgmt', ['setCurrentPage']),
    updatePage(page: number) {
      this.setCurrentPage({
        filter_selection_type_code: this.filterSelectionTypeCode,
        page: page,
        type: 'TRADEMARK'
      })
      this.$emit('updatedPage', page);
    },
    updateTrademarkItem(trademark_code: string, selection_type_code: SelectionTypeEnum) {
      this.$emit('updateTrademarkItem', trademark_code, selection_type_code);
    },
  }
});
