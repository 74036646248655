
import {defineComponent, ref} from "vue";
import {Exception} from "@/api/interfaces";
import {PageLinkPayload} from "@/models/payload/PageLinkPayload";
import {PageLinkTypeEnum} from "@/models/enum/PageLinkTypeEnum";
import {generateUUIDv4, slugify} from "@/helpers/functions/string";
import {mapActions} from "vuex";
import {TargetPageInfoRestDto} from "@/api/pharma-cms-content-page/models";
import CpAbstractsOverview from "@/components/layouts/content-pages/overview/CpAbstractsOverview.vue";
import {LinkTargetEnum} from "@/models/enum/LinkTargetEnum";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-enum/models";
import {RelativeUrlRestDto} from "@/api/pharma-cms-menu/models";
import {MenuRestService} from "@/services/rest/cms-menu/MenuRestService";

export default defineComponent({
  name: "PageLinkPicker",
  emits: ["changed", "changedText", "exceptions"],
  components: {CpAbstractsOverview},
  props: {
    payload: {
      type: PageLinkPayload,
      required: true,
    },
    enableNoLink: {
      type: Boolean,
      required: false,
      default: true
    },
    enableTarget: {
      type: Boolean,
      required: false,
      default: true
    },
    enableUrl: {
      type: Boolean,
      required: false,
      default: true
    },
    enableAltLangPages: {
      type: Boolean,
      required: false,
      default: false
    },
    enableSelectUrl: {
      type: Boolean,
      required: false,
      default: false
    },
    errors: {
      type: Object,
    }
  },
  data() {
    return {
      isReady: false as boolean,
      uuid: generateUUIDv4(),
      linkedContentPage: null as TargetPageInfoRestDto | null,
      relativeUrls: [] as RelativeUrlRestDto[],
      selectedRelativeUrlOption: null as string | null,
      extLinkOptions: [
        {code: LinkTargetEnum.BLANK, description: this.$t('newWindow')},
        {code: LinkTargetEnum.SELF, description: this.$t('currentWindow')},
      ] as CodeDescriptionRestDto[],
    }
  },
  setup(props) {
    const internalPayload = ref(PageLinkPayload.cloneFrom(props.payload));
    const modalId = generateUUIDv4('modal-');

    return {
      internalPayload,
      modalId
    };
  },
  mounted() {
    this.reloadContent();
  },
  watch: {
    payload: {
      handler(newPayload: PageLinkPayload) {
        this.internalPayload = PageLinkPayload.cloneFrom(newPayload);
      },
      deep: true,
      immediate: false
    },
    internalPayload: {
      handler() {
        this.$emit('changed', this.internalPayload);
      },
      deep: true,
    }
  },
  computed: {
    PageLinkTypeEnum() {
      return PageLinkTypeEnum;
    },
    relativeUrlsOptions(): CodeDescriptionRestDto[] {
      if (this.enableUrl && this.enableSelectUrl) {
        let relativeUrlsOptions = [] as CodeDescriptionRestDto[];

        this.relativeUrls.forEach((relative_url: RelativeUrlRestDto) => {
          relativeUrlsOptions.push({
            code: slugify(relative_url.name),
            description: relative_url.name,
          });
        });

        return relativeUrlsOptions;
      }

      return [];
    },
  },
  methods: {
    ...mapActions('cms_content_page', ['searchContentPageOverviewCb']),
    clearPageId(): void {
      this.linkedContentPage = null;
      this.internalPayload.page_id = null;
    },
    async getLinkedContentPage(pageId: string): Promise<void> {
      try {
        this.linkedContentPage = await this.searchContentPageOverviewCb(pageId);
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }
    },
    isPageLinkType(type: PageLinkTypeEnum): boolean {
      return this.internalPayload.type === type;
    },
    async reloadContent(): Promise<void> {
      try {
        if (this.isPageLinkType(PageLinkTypeEnum.PAGE) && this.internalPayload.page_id !== null) {
          await this.getLinkedContentPage(this.internalPayload.page_id);
        }
        if (this.enableUrl && this.enableSelectUrl) {
          this.relativeUrls = await MenuRestService.getInstance().findRelativeUrls();
        }
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }
    },
    setExceptions(exceptions: Exception[]): void {
      this.$emit('exceptions', exceptions);
    },
    setLinkType(type: PageLinkTypeEnum): void {
      this.internalPayload.type = type;
    },
    setPageId(id: string): void {
      // console.log('setPageId');
      this.internalPayload.page_id = id;
      this.getLinkedContentPage(id);
    },
    setRelativeUrl(): void {
      const selectedRelativeUrl = this.relativeUrls.find((relative_url: RelativeUrlRestDto) => slugify(relative_url.name) === this.selectedRelativeUrlOption) as RelativeUrlRestDto;

      if (selectedRelativeUrl) {
        this.internalPayload.external_url = selectedRelativeUrl.url;
        this.internalPayload.page_id = null;
        this.setLinkType(PageLinkTypeEnum.URL);

        this.$emit('changedText', selectedRelativeUrl.name);
      }
    },
  }
});
