import {GlobalFilterExcludeConditionsRestDto} from "@/api/pharma-cpc-mgmt/models";

export class GlobalFilterExcludeConditionsRestDtoModel implements GlobalFilterExcludeConditionsRestDto {
    is_medicine_prescription_not_required_excluded: boolean;
    is_no_customer_specific_price_available_excluded: boolean;
    is_out_of_pharmacy_stock: boolean;
    is_prescription_required_excluded: boolean;
    is_veterinary_medicine_excluded: boolean;


    constructor() {
        this.is_medicine_prescription_not_required_excluded = false;
        this.is_no_customer_specific_price_available_excluded = false;
        this.is_out_of_pharmacy_stock = false;
        this.is_prescription_required_excluded = false;
        this.is_veterinary_medicine_excluded = false;
    }

    public static createWithDefaults(): GlobalFilterExcludeConditionsRestDto {
        return new this();
    }
}
