
import {Exception} from '@/api/interfaces';
import {defineComponent} from "vue";
import {
  CodeDescriptionRestDto,
  SimpleTextWithImageContentRestDto,
  TextWithImageContentRestDto
} from "@/api/pharma-cms-content-block/models";
import {ExtLinkTargetEnum} from "@/api/enums/ext-link-target-enum";
import {StatusEnum} from "@/api/enums/status-enum";
import {mapActions, mapGetters} from 'vuex';
import CbForm from "@/components/layouts/content-blocks/forms/CbForm.vue";
import {emptyButton} from "@/helpers/functions/content_blocks/empty_content";
import {hasButton} from "@/helpers/functions/content_blocks/validation";
import {emptyMultilingualTextField} from "@/helpers/functions/multilingual";
import ability from '@/config/ability.js';
import {TargetPageInfoRestDto} from "@/api/pharma-cms-content-page/models";
import {stringHasContent} from "@/helpers/functions/string";
import BaseLinkPickerInternalLink from "@/components/UI/BaseLinkPickerInternalLink.vue";
import BaseLinkPicker from "@/components/UI/BaseLinkPicker.vue";
import {DpException} from "@/exception/DpException";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import {ImageRestDtoModel} from "@/models/api/pharma-cms-content-block/ImageRestDtoModel";
import {BorderTypeEnum} from "@/api/enums/border-type-enum";
import {ImageWidthEnum} from "@/api/enums/image-width-enum";
import {LightboxLayoutEnum} from "@/api/enums/lightbox-layout-enum";
import InputTextEditor from "@/components/UI/InputTextEditor.vue";

export default defineComponent({
  name: "CbFormTextWithImage",
  components: {InputTextEditor, BaseLinkPicker, BaseLinkPickerInternalLink, CbForm},
  props: {
    cbId: {
      type: String,
      required: false,
    },
    cbType: {
      type: String,
      required: true
    },
    canCopyFromLibrary: {
      type: Boolean,
      required: false,
      default: false,
    },
    contentLibraryCode: {
      type: String,
      required: false,
    },
    docType: {
      type: String,
      required: true,
    },
    isClearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLibrary: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNormalContentBlock: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPublishable: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasButtonSection: {
      type: Boolean,
      required: false,
      default: true,
    },
    mode: {
      type: String,
      required: true,
    },
    returnRoute: {
      type: Object,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    description2: {
      type: String,
      required: false,
    },
    description3: {
      type: String,
      required: false,
    },
    description4: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      error: null,
      exceptions: [] as Exception[],
      isReady: false as boolean,
      savedSuccessfully: false as boolean,
      imageKey: null as string | null,
      btnLinkType: null as string | null,
      linkedBtnContentPage: {} as TargetPageInfoRestDto,
      extLinkOptions: [
        {code: ExtLinkTargetEnum.BLANK, description: this.$t('newWindow')},
        {code: ExtLinkTargetEnum.SELF, description: this.$t('currentWindow')},
      ] as CodeDescriptionRestDto[],
      statusOptions: [
        {code: StatusEnum.PUBLISHED, description: this.$t('published')},
        {code: StatusEnum.NOT_PUBLISHED, description: this.$t('notPublished')},
      ] as CodeDescriptionRestDto[]
    };
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cms_content_block', [
      'getSimpleContentBlockTextWithImage',
      'getNormalContentBlockTextWithImage'
    ]),
    ...mapGetters('cms_content_page', ['getContentPage']),
    contentBlock(): TextWithImageContentRestDto | SimpleTextWithImageContentRestDto {
      if (this.isLibrary || this.isNormalContentBlock) {
        return this.getNormalContentBlockTextWithImage(this.cbType) as TextWithImageContentRestDto;
      } else {
        return this.getSimpleContentBlockTextWithImage(this.cbType) as SimpleTextWithImageContentRestDto;
      }
    },
    existingDocumentKey(): string | null {
      if ((this.isLibrary || this.isNormalContentBlock) && this.mode === 'new') {
        return null;
      } else if ('image' in this.contentBlock) {
        //todo tmp fix so image prop of cb is recognized
        // this.contentBlock = this.contentBlock as TextWithImageContentRestDto;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.contentBlock.image?.image_key?.nl ?? null;
        //return null;
      } else {
        return this.contentBlock.image_key?.nl ?? null;
      }
    },
    canLinkInternalPage(): boolean {
      return ability.can('use-feature', 'MGMT_CONTENT_PAGE') && this.cbType === 'SMA_HOMEPAGE_TEXT_WITH_IMAGE';
    },
    hasButton(): boolean {
      return hasButton(this.contentBlock.button);
    },
    hasExternalLink(): boolean {
      return stringHasContent(this.contentBlock.button?.button_url?.nl);
    },
    hasInternalLink(): boolean {
      return (this.contentBlock.button?.button_page_id && stringHasContent(this.contentBlock.button?.button_page_id?.nl)) as boolean;
    },
    isCopiedContent(): boolean {
      //TODO: create logic for determining whether cb is copied or not
      return false;
    },
  },
  methods: {
    ...mapActions('cms_content_block', [
      'clearNormalContentBlockTextWithImage',
      'clearSimpleContentBlock',
      'createNormalContentBlockTextWithImage',
      'createLibContentBlockTextWithImage',
      'saveSimpleContentBlockTextWithImage',
      'saveNormalContentBlockTextWithImage',
      'searchSimpleContentBlockTextWithImage',
      'searchNormalContentBlockTextWithImage'
    ]),
    ...mapActions('cms_content_page', ['searchContentPageOverviewCb']),
    ...mapActions('cms_document', ['clearPreviewDocument', 'clearUploadedDocumentKey']),
    clearButton() {
      this.contentBlock.button = emptyButton();
      if (this.btnLinkType === 'page') {
        this.linkedBtnContentPage = {};
      }
    },
    async clearContent() {
      if (this.isLibrary || this.isNormalContentBlock) {
        this.clearNormalContentBlockTextWithImage(this.cbType);
      } else {
        this.clearSimpleContentBlock(this.cbType);
      }
    },
    copiedLibContentBlock() {
      this.reloadContent().then(() => {
        this.savedSuccessfully = true;
        setTimeout(() => this.savedSuccessfully = false, 2000);
      });
    },
    determineLinkType() {
      if (this.contentBlock.button?.button_url?.nl) {
        this.btnLinkType = 'url';
      } else if (this.contentBlock.button?.button_page_id?.nl) {
        this.btnLinkType = 'page';
      } else {
        this.btnLinkType = 'url';
      }
    },
    async getLinkedContentPage() {
      try {
        this.linkedBtnContentPage = await this.searchContentPageOverviewCb(this.contentBlock.button?.button_page_id?.nl);
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }
    },
    parsePageReferences() {
      if (this.contentBlock.button) {
        switch (this.btnLinkType) {
          case 'url':
            this.contentBlock.button.button_page_id = emptyMultilingualTextField();
            break;
          case 'page':
            this.contentBlock.button.button_url = emptyMultilingualTextField();
            break;
        }
      }
    },
    setImageKey(document_key: string | null) {
      this.imageKey = document_key;
    },
    setLinkType(link_type: string) {
      this.btnLinkType = link_type;
    },
    async setPageId(page_id: string | null) {
      if (!this.contentBlock.button) {
        this.contentBlock.button = emptyButton();
      }

      if (this.contentBlock.button?.button_page_id && page_id) {
        this.contentBlock.button.button_page_id.nl = page_id;
        await this.getLinkedContentPage();
      } else {
        this.contentBlock.button.button_page_id = emptyMultilingualTextField();
      }

    },
    async submitData() {
      try {
        if (this.canLinkInternalPage) {
          this.parsePageReferences();
        }

        if ((this.hasInternalLink || this.hasExternalLink) && !stringHasContent(this.contentBlock.button?.button_text?.nl)) {
          throw [new DpException('Indien je voor de knop een link toevoegt, moet ook de knoptekst worden ingevuld.')];
        }

        if ('image' in this.contentBlock) {
          if (this.contentBlock.image) {
            this.contentBlock.image.image_key = new MultilingualRestDtoModel(null, null, null, this.imageKey);
          } else {
            this.contentBlock.image = new ImageRestDtoModel(
              BorderTypeEnum.NONE,
              new MultilingualRestDtoModel(null, null, null, this.imageKey),
              ImageWidthEnum.W_AUTO,
              LightboxLayoutEnum.NONE
            );
          }

          //todo tmp fix
          this.contentBlock.image_key = null;

          if (this.mode === 'new') {
            if (this.isLibrary) {
              await this.createLibContentBlockTextWithImage({
                lib_code: this.contentLibraryCode,
                type: this.cbType
              });
            } else {
              await this.createNormalContentBlockTextWithImage(this.cbType);
            }
          } else if (this.mode === 'edit') {
            await this.saveNormalContentBlockTextWithImage({
              id: this.cbId,
              type: this.cbType,
            });
          }
        } else {
          this.contentBlock.image_key = new MultilingualRestDtoModel(null, null, null, this.imageKey);
          await this.saveSimpleContentBlockTextWithImage(this.cbType);
        }

        await this.afterSave();
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }
    },
    async reloadContent() {
      this.isReady = false;
      this.exceptions = [];

      try {
        await this.clearPreviewDocument(this.docType);
        await this.clearUploadedDocumentKey(this.docType);

        if (this.isLibrary || this.isNormalContentBlock) {
          if (this.mode === 'new') {
            this.clearNormalContentBlockTextWithImage(this.cbType);
          } else {
            await this.searchNormalContentBlockTextWithImage({
              id: this.cbId,
              type: this.cbType,
            });
          }

          if (this.canLinkInternalPage) {
            this.parsePageReferences();
            this.determineLinkType();
            if (this.btnLinkType === 'page') {
              await this.getLinkedContentPage();
            }
          }

          this.setImageKey(this.existingDocumentKey);
        } else {
          await this.searchSimpleContentBlockTextWithImage({type: this.cbType});
          if (this.contentBlock.image_key?.nl) {
            this.setImageKey(this.contentBlock.image_key.nl);
          }
        }

        if (!this.hasButton) {
          this.clearButton();
        }
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }

      this.isReady = true;
    },
    setExceptions(exceptions: Exception[]) {
      window.scrollTo(0, 0);
      this.exceptions = exceptions;
    },
    async afterSave() {
      if (this.returnRoute) {
        await this.$router.push(this.returnRoute);
      } else {
        this.reloadContent().then(() => {
          this.savedSuccessfully = true;
          setTimeout(() => this.savedSuccessfully = false, 2000);
        });
      }
    }
  }
});
