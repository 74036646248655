<template>
  <base-container position-center>
    <div class="coming-soon d-flex flex-column justify-content-center align-items-center">
      <i class="mas d-block mb-4">engineering</i>
      <h2 class="mb-3">{{ $t('comingSoon') }}</h2>
      <div class="content w-50 text-center">
        <slot>
          <p class="mb-0">{{ $t('descriptionComingSoon') }}</p>
        </slot>
      </div>
    </div>
  </base-container>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "ComingSoon",
});
</script>

<style scoped lang="scss">
.coming-soon {
  height: 75vh;

  i, h2 {
    color: var(--dp-gray-400);
  }

  i {
    font-size: 8rem;
  }
}
</style>
