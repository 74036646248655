import {TextFragmentRestDto} from "@/api/pharma-pim-mgmt/models";

export class TextFragmentRestDtoModel implements TextFragmentRestDto {
    content?: string | null;
    title: string;

    constructor(title: string) {
        this.title = title;
    }

    public static createWithDefaults(): TextFragmentRestDtoModel {
        return new this(
            '',
        )
    }
}
