import {BackgroundImageRestDto,} from "@/api/pharma-cms-content-page/models";
import {BackgroundImageAttachmentEnum} from "@/api/enums/background-image-attachment-enum";
import {BackgroundImageHorizontalPositionEnum} from "@/api/enums/background-image-horizontal-position-enum";
import {BackgroundImageRepeatEnum} from "@/api/enums/background-image-repeat-enum";
import {BackgroundImageSizeEnum} from "@/api/enums/background-image-size-enum";
import {BackgroundImageVerticalPositionEnum} from "@/api/enums/background-image-vertical-position-enum";
import {BorderRadiusRestDtoModel} from "@/models/BorderRadiusRestDtoModel";

export class BackgroundImageRestDtoModel implements BackgroundImageRestDto {
    image_key: string;
    image_url: string;
    horizontal_position_code: BackgroundImageHorizontalPositionEnum;
    vertical_position_code: BackgroundImageVerticalPositionEnum;
    repeat_code: BackgroundImageRepeatEnum;
    size_code: BackgroundImageSizeEnum;
    attachment_code: BackgroundImageAttachmentEnum;

    constructor(image_key: string, image_url: string, horizontal_position_code: BackgroundImageHorizontalPositionEnum, vertical_position_code: BackgroundImageVerticalPositionEnum, repeat_code: BackgroundImageRepeatEnum, size_code: BackgroundImageSizeEnum, attachment_code: BackgroundImageAttachmentEnum) {
        this.image_key = image_key;
        this.image_url = image_url;
        this.horizontal_position_code = horizontal_position_code;
        this.vertical_position_code = vertical_position_code;
        this.repeat_code = repeat_code;
        this.size_code = size_code;
        this.attachment_code = attachment_code;
    }

    static createWithDefaults(): BackgroundImageRestDtoModel {
        return new this('',''
            ,BackgroundImageHorizontalPositionEnum.CENTER
            ,BackgroundImageVerticalPositionEnum.TOP
            ,BackgroundImageRepeatEnum.NO_REPEAT
            ,BackgroundImageSizeEnum.AUTO
            ,BackgroundImageAttachmentEnum.SCROLL
        );
    }

}
