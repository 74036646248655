import store from "@/store";
import {Ability, AbilityBuilder} from "@casl/ability";
import {ProductFeatureAuthorizationRestDto} from "@/api/pharma-license-license-info/models";
import {AuthContext} from "@/context/AuthContext";

export default async function updateAbilities(ability: any, customer_code: string) {
    await store.dispatch('license_info/searchProductFeatureAuthorizationOverview', customer_code);
    const featuresOverview = store.getters['license_info/getProductFeatureAuthorizationOverview']
    const {can, cannot, rules} = new AbilityBuilder(Ability);
    if (featuresOverview && featuresOverview.length > 0) {
        featuresOverview.forEach(function (authorization: ProductFeatureAuthorizationRestDto) {
            if (authorization.product !== undefined && authorization.product !== null) {
                can('use-license', authorization.product.code);
            }
            if (authorization.is_dp_user_feature === undefined
                || authorization.is_dp_user_feature === null
                || !authorization.is_dp_user_feature
                || AuthContext.isDpUser()
            ) {
                can('use-feature', authorization.feature.code);
            }
            // console.log('feature', authorization.feature.code)  ;
        });
    } else {
        can('do', 'nothing');
    }

    if (ability) {
        ability.update(rules);
    }
}
