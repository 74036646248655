
import {defineComponent} from "vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {arrayHasContent} from "@/helpers/functions/arrays";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import {FooterOverviewRestDto} from "@/api/pharma-cms-footer/models";
import {FooterRestService} from "@/services/rest/cms-footer/FooterRestService";

export default defineComponent({
  name: "FooterOverview",
  components: {AlertError2, BaseTitle},
  data() {
    return {
      footerStacks: [] as FooterOverviewRestDto[],
      footerStackOverviewUI: UIStateDto.createWithDefaults(),
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    hasFooterStacks(): boolean {
      return arrayHasContent(this.footerStacks);
    }
  },
  methods: {
    async reloadContent(): Promise<void> {
      this.footerStackOverviewUI
        .clearError()
        .setNotReady();

      try {
        this.footerStacks = await FooterRestService.getInstance()
          .findFooterStackOverview();
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.footerStackOverviewUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.footerStackOverviewUI as UIStateDto))
          .catch(exceptions, true);
      } finally {
        this.footerStackOverviewUI.setReady();
      }
    }
  }
});
