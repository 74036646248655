
import {defineComponent} from "vue";
import CpAbstractsOverviewItem from "@/components/layouts/content-pages/overview/CpAbstractsOverviewItem.vue";
import {FullContentPageRestDto} from "@/api/pharma-cms-content-page/models";
import {mapActions, mapGetters} from "vuex";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import {arrayHasContent} from "@/helpers/functions/arrays";

export default defineComponent({
  name: "CpAbstractsOverview",
  components: {BaseSpinner, CpAbstractsOverviewItem},
  emits: ["exceptions", "selectedCp"],
  props: {
    enableAltLangPages: {
      type: Boolean,
      required: false,
      default: false
    },
    libCode: {
      type: String,
      required: false,
      default: null
    },
    typeOfLink: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      isReady: false as boolean,
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters("cms_content_page", ["getContentPageOverview", "getLibContentPages"]),
    contentPages(): FullContentPageRestDto[] {
      if (this.isLibrary) {
        return this.getLibContentPages(this.libCode);
      } else {
        return this.getContentPageOverview.results;
      }
    },
    hasContentPages(): boolean {
      return arrayHasContent(this.contentPages);
    },
    isLibrary(): boolean {
      return !!this.libCode;
    }
  },
  methods: {
    ...mapActions("cms_content_page", ["searchContentPageOverview", "searchLibContentPages"]),
    async reloadContent() {
      this.isReady = false;

      try {
        if (this.isLibrary) {
          await this.searchLibContentPages(this.libCode);
        } else {
          let payload = undefined;
          if (this.enableAltLangPages) {
            payload = {expect_alt_lang_pages: true};
          }
          await this.searchContentPageOverview(payload);
        }
      } catch (exceptions: any) {
        this.$emit('exceptions', exceptions);
        this.isReady = true;
      }
      this.isReady = true;
    },
    selectedCp(page_id: string) {
      switch (this.typeOfLink) {
        case 'btn':
          this.$emit('selectedCp', page_id, 'btn');
          break;
        case 'img':
          this.$emit('selectedCp', page_id, 'img');
          break;
        default:
          this.$emit('selectedCp', page_id);
      }
    }
  }
})
