import {FindNormalContentBlockPayload} from "@/models/payload/FindNormalContentBlockPayload";
import {ContentBlockEndpointsApiService} from "@/main";
import {SaveNormalContentBlockPayload} from "@/models/payload/SaveNormalContentBlockPayload";
import {CreateNormalContentBlockPayload} from "@/models/payload/CreateNormalContentBlockPayload";
import {PageReferencesContentRestDtoModel} from "@/models/PageReferencesContentRestDtoModel";

export default {
    namespaced: true,
    actions: {
        async create(context: any, payload: CreateNormalContentBlockPayload): Promise<string> {
            console.log('PageReference::create()');
            const response = await ContentBlockEndpointsApiService.createNormalContentBlockPageReferences(payload.cb as PageReferencesContentRestDtoModel, payload.type);

            return response.data.id as string;
        },
        async find(context: any, payload: FindNormalContentBlockPayload): Promise<PageReferencesContentRestDtoModel> {
            console.log('PageReference::find()');
            const response = await ContentBlockEndpointsApiService.getNormalContentBlockPageReferences(payload.type, payload.id);

            return response.data as PageReferencesContentRestDtoModel;
        },
        async save(context: any, payload: SaveNormalContentBlockPayload): Promise<void> {
            console.log('PageReference::save()');
            await ContentBlockEndpointsApiService.updateNormalContentBlockPageReferences(payload.cb as PageReferencesContentRestDtoModel, payload.type, payload.id);
        },
    }
};
