import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-edd45788"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mb-4" }
const _hoisted_3 = { class: "row row-document mb-3" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "row row-show-file-details mb-4" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "row row-text mb-3" }
const _hoisted_8 = { class: "col-12" }
const _hoisted_9 = { class: "d-flex align-items-center" }
const _hoisted_10 = { class: "row row-button-text-layout mb-3" }
const _hoisted_11 = { class: "col-9" }
const _hoisted_12 = { class: "d-flex align-items-center" }
const _hoisted_13 = { class: "col-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_InputFile = _resolveComponent("InputFile")!
  const _component_InputCheckboxBoolean = _resolveComponent("InputCheckboxBoolean")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Subtitle = _resolveComponent("Subtitle")!
  const _component_InputSelect = _resolveComponent("InputSelect")!
  const _component_dp_form = _resolveComponent("dp-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseSpinner, {
      spin: !this.isReady
    }, null, 8, ["spin"]),
    (this.isReady)
      ? (_openBlock(), _createBlock(_component_dp_form, {
          key: 0,
          as: "form",
          novalidate: ""
        }, {
          default: _withCtx(({ errors }) => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_InputFile, {
                      id: "file-input",
                      onImageUploaded: this.setDocumentKey,
                      onImageCleared: _cache[0] || (_cache[0] = ($event: any) => (this.setDocumentKey(null))),
                      existingDocumentKey: this.existingDocumentKey,
                      typeCode: this.docType,
                      errors: errors
                    }, null, 8, ["onImageUploaded", "existingDocumentKey", "typeCode", "errors"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_InputCheckboxBoolean, {
                      modelValue: this.contentBlock.is_show_file_details,
                      "onUpdate:modelValue": [
                        _cache[1] || (_cache[1] = ($event: any) => ((this.contentBlock.is_show_file_details) = $event)),
                        this.onChange
                      ],
                      label: _ctx.$t('cms.showFileProperties'),
                      name: "showFileDetails",
                      errors: errors
                    }, null, 8, ["modelValue", "label", "errors", "onUpdate:modelValue"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_InputText, {
                        modelValue: this.contentBlock.title.nl,
                        "onUpdate:modelValue": [
                          _cache[2] || (_cache[2] = ($event: any) => ((this.contentBlock.title.nl) = $event)),
                          this.onChange
                        ],
                        label: _ctx.$t('text'),
                        name: "text",
                        rules: "max:500",
                        errors: errors
                      }, null, 8, ["modelValue", "label", "errors", "onUpdate:modelValue"]),
                      _createVNode(_component_Subtitle, {
                        help: _ctx.$t('cms.helpTextDocumentTitle'),
                        "help-position": "left",
                        mb: "3"
                      }, null, 8, ["help"])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_InputText, {
                        modelValue: this.contentBlock.button_text,
                        "onUpdate:modelValue": [
                          _cache[3] || (_cache[3] = ($event: any) => ((this.contentBlock.button_text) = $event)),
                          this.onChange
                        ],
                        label: _ctx.$t('description'),
                        name: "description",
                        rules: "max:100",
                        errors: errors
                      }, null, 8, ["modelValue", "label", "errors", "onUpdate:modelValue"]),
                      _createVNode(_component_Subtitle, {
                        help: _ctx.$t('cms.helpTextDocumentDescription'),
                        mb: "3"
                      }, null, 8, ["help"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_InputSelect, {
                      modelValue: this.contentBlock.button_layout_code,
                      "onUpdate:modelValue": [
                        _cache[4] || (_cache[4] = ($event: any) => ((this.contentBlock.button_layout_code) = $event)),
                        this.onChange
                      ],
                      options: this.btnLayoutOptions,
                      label: _ctx.$t('layout'),
                      name: "buttonLayout",
                      selectText: _ctx.$t('selectText'),
                      errors: errors
                    }, null, 8, ["modelValue", "options", "label", "selectText", "errors", "onUpdate:modelValue"])
                  ])
                ])
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}