import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xxl-8 col-xl-12 mb-4" }
const _hoisted_3 = { class: "row align-items-center" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = {
  key: 0,
  class: "col"
}
const _hoisted_6 = { class: "d-inline-block mt-3 pt-4 border-top border-2 border-light" }
const _hoisted_7 = {
  type: "submit",
  class: "btn btn-primary"
}
const _hoisted_8 = {
  key: 2,
  class: "text-muted ms-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_url = _resolveComponent("input-url")!
  const _component_input_text = _resolveComponent("input-text")!
  const _component_input_number = _resolveComponent("input-number")!
  const _component_input_select = _resolveComponent("input-select")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_dp_form = _resolveComponent("dp-form")!
  const _component_cb_form = _resolveComponent("cb-form")!

  return (_openBlock(), _createBlock(_component_cb_form, {
    onCopiedLibCb: this.copiedLibContentBlock,
    "cb-type": this.cbType,
    exceptions: this.exceptions,
    "can-copy-from-library": this.canCopyFromLibrary,
    "is-library": this.isLibrary,
    "is-ready": this.isReady,
    "saved-successfully": this.savedSuccessfully,
    title: this.title ?? null,
    description: this.description,
    description2: this.description2,
    description3: this.description3,
    description4: this.description4
  }, {
    default: _withCtx(() => [
      _createVNode(_component_dp_form, {
        as: "form",
        onSubmit: _ctx.submitData,
        novalidate: ""
      }, {
        default: _withCtx(({ errors }) => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  (_ctx.inputType==='url')
                    ? (_openBlock(), _createBlock(_component_input_url, {
                        key: 0,
                        modelValue: _ctx.contentBlock.content.nl,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.contentBlock.content.nl) = $event)),
                        placeholder: "https://www.mijnapotheek.be",
                        label: this.fieldTitle ?? _ctx.$t('url'),
                        name: "url",
                        rules: _ctx.isNotRequired ? '' : 'required',
                        errors: errors
                      }, null, 8, ["modelValue", "label", "rules", "errors"]))
                    : (_openBlock(), _createBlock(_component_input_text, {
                        key: 1,
                        modelValue: _ctx.contentBlock.content.nl,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.contentBlock.content.nl) = $event)),
                        label: this.fieldTitle ?? _ctx.$t('title'),
                        name: "title",
                        rules: _ctx.isNotRequired ? '' : 'required',
                        errors: errors
                      }, null, 8, ["modelValue", "label", "rules", "errors"]))
                ]),
                (_ctx.canRestoreDefaultValues)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("button", {
                        onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.restoreDefaultValues && _ctx.restoreDefaultValues(...args)), ["prevent"])),
                        class: "btn btn-secondary mb-3"
                      }, _toDisplayString(_ctx.$t('restoreDefaultValues')), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            (this.mode !== 'new' && this.isLibrary)
              ? (_openBlock(), _createBlock(_component_input_number, {
                  key: 0,
                  label: _ctx.$t('sortWeight'),
                  placeholder: "sortWeight",
                  name: "sortWeight",
                  modelValue: _ctx.contentBlock.sort_weight,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.contentBlock.sort_weight) = $event)),
                  errors: errors
                }, null, 8, ["label", "modelValue", "errors"]))
              : _createCommentVNode("", true),
            (_ctx.isPublishable)
              ? (_openBlock(), _createBlock(_component_input_select, {
                  key: 1,
                  name: "cbStatus",
                  class: "fw-bold",
                  selectText: _ctx.$t('selectText'),
                  modelValue: _ctx.contentBlock.status_code,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.contentBlock.status_code) = $event)),
                  options: _ctx.statusOptions,
                  errors: errors
                }, null, 8, ["selectText", "modelValue", "options", "errors"]))
              : _createCommentVNode("", true),
            _createElementVNode("button", _hoisted_7, _toDisplayString(_ctx.$t('save')), 1),
            _createVNode(_component_router_link, {
              to: {name: (_ctx.returnRoute) ? _ctx.returnRoute : 'home'},
              class: "ms-3 btn btn-outline-primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
              ]),
              _: 1
            }, 8, ["to"]),
            (this.cachingDelayMinutes !== null)
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('cachingDelayMinutes', [this.cachingDelayMinutes])), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }, 8, ["onCopiedLibCb", "cb-type", "exceptions", "can-copy-from-library", "is-library", "is-ready", "saved-successfully", "title", "description", "description2", "description3", "description4"]))
}