
import {defineComponent} from "vue";
import BaseModal from "@/components/UI/Bootstrap/Modal/BaseModal.vue";
import {stringHasContent, stripHtmlTags} from "@/helpers/functions/string";
import LazyImage from "@/components/UI/LazyImage.vue";

export default defineComponent({
  name: "CbsOverviewItem",
  components: {
    LazyImage,
    BaseModal
  },
  emits: ["copyContentBlock", "deleteContentBlock"],
  props: {
    contentBlock: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    },
    contentType: {
      type: String,
      required: true,
    },
    canCopyItem: {
      type: Boolean,
      required: false,
      default: false,
    },
    canPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
    modalId: {
      type: String,
      required: false,
      default: '',
    },
    routeObjectEditCb: {
      type: Object,
      required: false,
      default() {
        return null;
      }
    },
  },
  data: () => ({
    isReady: false as boolean,
  }),
  computed: {
    canUseModal(): boolean {
      return (this.modalId.length > 0);
    },
    previewModalId(): string {
      return 'previewModalId' + this.contentBlock.id;
    },
    strippedShortContent(): string {
      let shortContent = null as string | null;

      if (this.contentType === 'BANNER_IN_SLOT') {
        shortContent = this.contentBlock.title;
      } else {
        shortContent = this.contentBlock.short_content;
      }

      return (shortContent) ? stripHtmlTags(this.abbreviate(shortContent)) : '';
    },
  },
  methods: {
    abbreviate(content: string|null): string {
      if (content) {
        if (content.length > 100) {
          return content.substr(0, 75) + '...';
        }

        return content;
      }

      return '';
    },
    copyContentBlock(cbId: string) {
      this.$emit('copyContentBlock', cbId);
    },
    deleteContentBlock(cbId: string) {
      this.$emit('deleteContentBlock', cbId);
    },
    dynamicRouteObjectEditCb(cbId: string): object {
      let route = JSON.parse(JSON.stringify(this.routeObjectEditCb));

      if (route.params) {
        route.params.id = cbId;
      } else {
        route.params = {id: cbId};
      }

      return route;
    },
  },
});
