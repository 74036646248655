/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Master Digital Pharma Name Value Pair API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { DpNameValuePairDetailRestDto } from '../models';
import { DpNameValuePairOverviewResultsRestDto } from '../models';
import { DpNameValuePairValueRestDto } from '../models';
/**
 * NvpAPIApi - axios parameter creator
 * @export
 */
export const NvpAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.NVP.04 Create a name value pair.
         * @param {DpNameValuePairValueRestDto} body 
         * @param {string} name Uniquely identifies a NVP name.
         * @param {string} [x_dp_module_code] The unique identification of the Digital-Pharma module.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDpNvp: async (body: DpNameValuePairValueRestDto, name: string, x_dp_module_code?: string|null, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createDpNvp.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling createDpNvp.');
            }
            const localVarPath = `/nvp/v1/nvp/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_module_code !== undefined && x_dp_module_code !== null) {
                localVarHeaderParameter['x-dp-module-code'] = String(x_dp_module_code);
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.NVP.01 Find name value pairs.
         * @param {string} [x_dp_module_code] The unique identification of the Digital-Pharma module.
         * @param {string} [search_name] Filter the results based on the nvp&#x27;s name.
         * @param {string} [search_customer_name] Filter the results based on the customer&#x27;s name.
         * @param {string} [filter_customer_code] Filter the results based on the customer&#x27;s code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDpNvps: async (x_dp_module_code?: string|null, search_name?: string|null, search_customer_name?: string|null, filter_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/nvp/v1/nvp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!search_name || typeof(search_name) === "boolean") {
                localVarQueryParameter['search_name'] = search_name;
            }

            if (!!search_customer_name || typeof(search_customer_name) === "boolean") {
                localVarQueryParameter['search_customer_name'] = search_customer_name;
            }

            if (!!filter_customer_code || typeof(filter_customer_code) === "boolean") {
                localVarQueryParameter['filter_customer_code'] = filter_customer_code;
            }

            if (x_dp_module_code !== undefined && x_dp_module_code !== null) {
                localVarHeaderParameter['x-dp-module-code'] = String(x_dp_module_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.NVP.03 Get name value pair details.
         * @param {string} id Uniquely identifies a NVP.
         * @param {string} [x_dp_module_code] The unique identification of the Digital-Pharma module.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDpNvpById: async (id: string, x_dp_module_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDpNvpById.');
            }
            const localVarPath = `/nvp/v1/nvp/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_module_code !== undefined && x_dp_module_code !== null) {
                localVarHeaderParameter['x-dp-module-code'] = String(x_dp_module_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.NVP.07 Get name value pair details by name and for a specific customer.
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} name Uniquely identifies a NVP.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {string} [x_dp_module_code] The unique identification of the Digital-Pharma module.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDpNvpByName: async (x_dp_customer_code: string, name: string, x_dp_language?: string|null, x_dp_module_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'x_dp_customer_code' is not null or undefined
            if (x_dp_customer_code === null || x_dp_customer_code === undefined) {
                throw new RequiredError('x_dp_customer_code','Required parameter x_dp_customer_code was null or undefined when calling getDpNvpByName.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling getDpNvpByName.');
            }
            const localVarPath = `/nvp/v1/nvp/by_name/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_module_code !== undefined && x_dp_module_code !== null) {
                localVarHeaderParameter['x-dp-module-code'] = String(x_dp_module_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.NVP.02 Update name value pair.
         * @param {DpNameValuePairValueRestDto} body 
         * @param {string} id Uniquely identifies a NVP.
         * @param {string} [x_dp_module_code] The unique identification of the Digital-Pharma module.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDpNvp: async (body: DpNameValuePairValueRestDto, id: string, x_dp_module_code?: string|null, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateDpNvp.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateDpNvp.');
            }
            const localVarPath = `/nvp/v1/nvp/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_module_code !== undefined && x_dp_module_code !== null) {
                localVarHeaderParameter['x-dp-module-code'] = String(x_dp_module_code);
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Sync (create or update) a name value pair by name for a specific customer
         * @summary REST.API.NVP.06 Sync name value pair by name and for a customer.
         * @param {DpNameValuePairValueRestDto} body 
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} name Uniquely identifies a NVP.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {string} [x_dp_module_code] The unique identification of the Digital-Pharma module.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDpNvp_1: async (body: DpNameValuePairValueRestDto, x_dp_customer_code: string, name: string, x_dp_language?: string|null, x_dp_module_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateDpNvp_1.');
            }
            // verify required parameter 'x_dp_customer_code' is not null or undefined
            if (x_dp_customer_code === null || x_dp_customer_code === undefined) {
                throw new RequiredError('x_dp_customer_code','Required parameter x_dp_customer_code was null or undefined when calling updateDpNvp_1.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling updateDpNvp_1.');
            }
            const localVarPath = `/nvp/v1/nvp/by_name/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_module_code !== undefined && x_dp_module_code !== null) {
                localVarHeaderParameter['x-dp-module-code'] = String(x_dp_module_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NvpAPIApi - functional programming interface
 * @export
 */
export const NvpAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.NVP.04 Create a name value pair.
         * @param {DpNameValuePairValueRestDto} body 
         * @param {string} name Uniquely identifies a NVP name.
         * @param {string} [x_dp_module_code] The unique identification of the Digital-Pharma module.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDpNvp(_axios: AxiosInstance, body: DpNameValuePairValueRestDto, name: string, x_dp_module_code?: string|null, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await NvpAPIApiAxiosParamCreator(configuration).createDpNvp(body, name, x_dp_module_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.NVP.01 Find name value pairs.
         * @param {string} [x_dp_module_code] The unique identification of the Digital-Pharma module.
         * @param {string} [search_name] Filter the results based on the nvp&#x27;s name.
         * @param {string} [search_customer_name] Filter the results based on the customer&#x27;s name.
         * @param {string} [filter_customer_code] Filter the results based on the customer&#x27;s code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findDpNvps(_axios: AxiosInstance, x_dp_module_code?: string|null, search_name?: string|null, search_customer_name?: string|null, filter_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DpNameValuePairOverviewResultsRestDto>> {
            const localVarAxiosArgs = await NvpAPIApiAxiosParamCreator(configuration).findDpNvps(x_dp_module_code, search_name, search_customer_name, filter_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.NVP.03 Get name value pair details.
         * @param {string} id Uniquely identifies a NVP.
         * @param {string} [x_dp_module_code] The unique identification of the Digital-Pharma module.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDpNvpById(_axios: AxiosInstance, id: string, x_dp_module_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DpNameValuePairDetailRestDto>> {
            const localVarAxiosArgs = await NvpAPIApiAxiosParamCreator(configuration).getDpNvpById(id, x_dp_module_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.NVP.07 Get name value pair details by name and for a specific customer.
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} name Uniquely identifies a NVP.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {string} [x_dp_module_code] The unique identification of the Digital-Pharma module.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDpNvpByName(_axios: AxiosInstance, x_dp_customer_code: string, name: string, x_dp_language?: string|null, x_dp_module_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DpNameValuePairDetailRestDto>> {
            const localVarAxiosArgs = await NvpAPIApiAxiosParamCreator(configuration).getDpNvpByName(x_dp_customer_code, name, x_dp_language, x_dp_module_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.NVP.02 Update name value pair.
         * @param {DpNameValuePairValueRestDto} body 
         * @param {string} id Uniquely identifies a NVP.
         * @param {string} [x_dp_module_code] The unique identification of the Digital-Pharma module.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDpNvp(_axios: AxiosInstance, body: DpNameValuePairValueRestDto, id: string, x_dp_module_code?: string|null, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await NvpAPIApiAxiosParamCreator(configuration).updateDpNvp(body, id, x_dp_module_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Sync (create or update) a name value pair by name for a specific customer
         * @summary REST.API.NVP.06 Sync name value pair by name and for a customer.
         * @param {DpNameValuePairValueRestDto} body 
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} name Uniquely identifies a NVP.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {string} [x_dp_module_code] The unique identification of the Digital-Pharma module.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDpNvp_1(_axios: AxiosInstance, body: DpNameValuePairValueRestDto, x_dp_customer_code: string, name: string, x_dp_language?: string|null, x_dp_module_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await NvpAPIApiAxiosParamCreator(configuration).updateDpNvp_1(body, x_dp_customer_code, name, x_dp_language, x_dp_module_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * NvpAPIApi - object-oriented interface
 * @export
 * @class NvpAPIApi
 * @extends {BaseAPI}
 */
export class NvpAPIApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.NVP.04 Create a name value pair.
     * @param {DpNameValuePairValueRestDto} body 
     * @param {string} name Uniquely identifies a NVP name.
     * @param {string} [x_dp_module_code] The unique identification of the Digital-Pharma module.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NvpAPIApi
     */
     public createDpNvp(body: DpNameValuePairValueRestDto, name: string, x_dp_module_code?: string|null, x_dp_customer_code?: string|null, options?: any) {
        return NvpAPIApiFp(this.configuration).createDpNvp(this.axios, body, name, x_dp_module_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.NVP.01 Find name value pairs.
     * @param {string} [x_dp_module_code] The unique identification of the Digital-Pharma module.
     * @param {string} [search_name] Filter the results based on the nvp&#x27;s name.
     * @param {string} [search_customer_name] Filter the results based on the customer&#x27;s name.
     * @param {string} [filter_customer_code] Filter the results based on the customer&#x27;s code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NvpAPIApi
     */
     public findDpNvps(x_dp_module_code?: string|null, search_name?: string|null, search_customer_name?: string|null, filter_customer_code?: string|null, options?: any) {
        return NvpAPIApiFp(this.configuration).findDpNvps(this.axios, x_dp_module_code, search_name, search_customer_name, filter_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.NVP.03 Get name value pair details.
     * @param {string} id Uniquely identifies a NVP.
     * @param {string} [x_dp_module_code] The unique identification of the Digital-Pharma module.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NvpAPIApi
     */
     public getDpNvpById(id: string, x_dp_module_code?: string|null, options?: any) {
        return NvpAPIApiFp(this.configuration).getDpNvpById(this.axios, id, x_dp_module_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.NVP.07 Get name value pair details by name and for a specific customer.
     * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} name Uniquely identifies a NVP.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {string} [x_dp_module_code] The unique identification of the Digital-Pharma module.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NvpAPIApi
     */
     public getDpNvpByName(x_dp_customer_code: string, name: string, x_dp_language?: string|null, x_dp_module_code?: string|null, options?: any) {
        return NvpAPIApiFp(this.configuration).getDpNvpByName(this.axios, x_dp_customer_code, name, x_dp_language, x_dp_module_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.NVP.02 Update name value pair.
     * @param {DpNameValuePairValueRestDto} body 
     * @param {string} id Uniquely identifies a NVP.
     * @param {string} [x_dp_module_code] The unique identification of the Digital-Pharma module.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NvpAPIApi
     */
     public updateDpNvp(body: DpNameValuePairValueRestDto, id: string, x_dp_module_code?: string|null, x_dp_customer_code?: string|null, options?: any) {
        return NvpAPIApiFp(this.configuration).updateDpNvp(this.axios, body, id, x_dp_module_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sync (create or update) a name value pair by name for a specific customer
     * @summary REST.API.NVP.06 Sync name value pair by name and for a customer.
     * @param {DpNameValuePairValueRestDto} body 
     * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} name Uniquely identifies a NVP.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {string} [x_dp_module_code] The unique identification of the Digital-Pharma module.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NvpAPIApi
     */
     public updateDpNvp_1(body: DpNameValuePairValueRestDto, x_dp_customer_code: string, name: string, x_dp_language?: string|null, x_dp_module_code?: string|null, options?: any) {
        return NvpAPIApiFp(this.configuration).updateDpNvp_1(this.axios, body, x_dp_customer_code, name, x_dp_language, x_dp_module_code, options).then((request) => request(this.axios, this.basePath));
    }

}
