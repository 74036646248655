/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Content Page API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { TargetPageInfoRestDto } from '../models';
import { TextWithImageAbstractRestDto } from '../models';
/**
 * ContentPageAbstractEndpointsApi - axios parameter creator
 * @export
 */
export const ContentPageAbstractEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.CPO.04 Get the overview contentblock of the content page.
         * @param {string} page_id The unique identifier of this content page.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentPageOverviewCb: async (page_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page_id' is not null or undefined
            if (page_id === null || page_id === undefined) {
                throw new RequiredError('page_id','Required parameter page_id was null or undefined when calling getContentPageOverviewCb.');
            }
            const localVarPath = `/content_page/v1/content_page/{page_id}/overview_cb`
                .replace(`{${"page_id"}}`, encodeURIComponent(String(page_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPO.02 Update the content of the overview contentblock of the content page.
         * @param {TextWithImageAbstractRestDto} body 
         * @param {string} page_id The unique identifier of this content page.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentPageOverviewCbContent: async (body: TextWithImageAbstractRestDto, page_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateContentPageOverviewCbContent.');
            }
            // verify required parameter 'page_id' is not null or undefined
            if (page_id === null || page_id === undefined) {
                throw new RequiredError('page_id','Required parameter page_id was null or undefined when calling updateContentPageOverviewCbContent.');
            }
            const localVarPath = `/content_page/v1/content_page/{page_id}/overview_cb/content`
                .replace(`{${"page_id"}}`, encodeURIComponent(String(page_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPO.03 Update the image of the overview contentblock of the content page.
         * @param {TextWithImageAbstractRestDto} body 
         * @param {string} page_id The unique identifier of this content page.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentPageOverviewCbImage: async (body: TextWithImageAbstractRestDto, page_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateContentPageOverviewCbImage.');
            }
            // verify required parameter 'page_id' is not null or undefined
            if (page_id === null || page_id === undefined) {
                throw new RequiredError('page_id','Required parameter page_id was null or undefined when calling updateContentPageOverviewCbImage.');
            }
            const localVarPath = `/content_page/v1/content_page/{page_id}/overview_cb/image_key`
                .replace(`{${"page_id"}}`, encodeURIComponent(String(page_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPO.01 Update the title of the overview contentblock of the content page.
         * @param {TextWithImageAbstractRestDto} body 
         * @param {string} page_id The unique identifier of this content page.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentPageOverviewCbTitle: async (body: TextWithImageAbstractRestDto, page_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateContentPageOverviewCbTitle.');
            }
            // verify required parameter 'page_id' is not null or undefined
            if (page_id === null || page_id === undefined) {
                throw new RequiredError('page_id','Required parameter page_id was null or undefined when calling updateContentPageOverviewCbTitle.');
            }
            const localVarPath = `/content_page/v1/content_page/{page_id}/overview_cb/title`
                .replace(`{${"page_id"}}`, encodeURIComponent(String(page_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentPageAbstractEndpointsApi - functional programming interface
 * @export
 */
export const ContentPageAbstractEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.CPO.04 Get the overview contentblock of the content page.
         * @param {string} page_id The unique identifier of this content page.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentPageOverviewCb(_axios: AxiosInstance, page_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TargetPageInfoRestDto>> {
            const localVarAxiosArgs = await ContentPageAbstractEndpointsApiAxiosParamCreator(configuration).getContentPageOverviewCb(page_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPO.02 Update the content of the overview contentblock of the content page.
         * @param {TextWithImageAbstractRestDto} body 
         * @param {string} page_id The unique identifier of this content page.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContentPageOverviewCbContent(_axios: AxiosInstance, body: TextWithImageAbstractRestDto, page_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentPageAbstractEndpointsApiAxiosParamCreator(configuration).updateContentPageOverviewCbContent(body, page_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPO.03 Update the image of the overview contentblock of the content page.
         * @param {TextWithImageAbstractRestDto} body 
         * @param {string} page_id The unique identifier of this content page.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContentPageOverviewCbImage(_axios: AxiosInstance, body: TextWithImageAbstractRestDto, page_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentPageAbstractEndpointsApiAxiosParamCreator(configuration).updateContentPageOverviewCbImage(body, page_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPO.01 Update the title of the overview contentblock of the content page.
         * @param {TextWithImageAbstractRestDto} body 
         * @param {string} page_id The unique identifier of this content page.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContentPageOverviewCbTitle(_axios: AxiosInstance, body: TextWithImageAbstractRestDto, page_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentPageAbstractEndpointsApiAxiosParamCreator(configuration).updateContentPageOverviewCbTitle(body, page_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ContentPageAbstractEndpointsApi - object-oriented interface
 * @export
 * @class ContentPageAbstractEndpointsApi
 * @extends {BaseAPI}
 */
export class ContentPageAbstractEndpointsApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.CMS.CPO.04 Get the overview contentblock of the content page.
     * @param {string} page_id The unique identifier of this content page.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageAbstractEndpointsApi
     */
     public getContentPageOverviewCb(page_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentPageAbstractEndpointsApiFp(this.configuration).getContentPageOverviewCb(this.axios, page_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CPO.02 Update the content of the overview contentblock of the content page.
     * @param {TextWithImageAbstractRestDto} body 
     * @param {string} page_id The unique identifier of this content page.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageAbstractEndpointsApi
     */
     public updateContentPageOverviewCbContent(body: TextWithImageAbstractRestDto, page_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentPageAbstractEndpointsApiFp(this.configuration).updateContentPageOverviewCbContent(this.axios, body, page_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CPO.03 Update the image of the overview contentblock of the content page.
     * @param {TextWithImageAbstractRestDto} body 
     * @param {string} page_id The unique identifier of this content page.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageAbstractEndpointsApi
     */
     public updateContentPageOverviewCbImage(body: TextWithImageAbstractRestDto, page_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentPageAbstractEndpointsApiFp(this.configuration).updateContentPageOverviewCbImage(this.axios, body, page_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CPO.01 Update the title of the overview contentblock of the content page.
     * @param {TextWithImageAbstractRestDto} body 
     * @param {string} page_id The unique identifier of this content page.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageAbstractEndpointsApi
     */
     public updateContentPageOverviewCbTitle(body: TextWithImageAbstractRestDto, page_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentPageAbstractEndpointsApiFp(this.configuration).updateContentPageOverviewCbTitle(this.axios, body, page_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
