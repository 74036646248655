import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6be50f02"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["data-bs-target", "aria-expanded", "aria-controls"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("h2", {
    class: "accordion-header",
    id: _ctx.id
  }, [
    _createElementVNode("button", {
      class: _normalizeClass(['accordion-button', {'collapsed': !_ctx.isOpen}]),
      type: "button",
      "data-bs-toggle": "collapse",
      "data-bs-target": '#' + _ctx.target,
      "aria-expanded": _ctx.isOpen ? 'true' : 'false',
      "aria-controls": _ctx.target
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 10, _hoisted_2)
  ], 8, _hoisted_1))
}