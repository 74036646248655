/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum ColumnWidthEnum {
    COL_1_12 = 'COL_1_12',
    COL_2_12 = 'COL_2_12',
    COL_3_12 = 'COL_3_12',
    COL_4_12 = 'COL_4_12',
    COL_5_12 = 'COL_5_12',
    COL_6_12 = 'COL_6_12',
    COL_7_12 = 'COL_7_12',
    COL_8_12 = 'COL_8_12',
    COL_9_12 = 'COL_9_12',
    COL_10_12 = 'COL_10_12',
    COL_11_12 = 'COL_11_12',
    COL_12_12 = 'COL_12_12',
}