
import {defineComponent} from 'vue'
import {Pp2ProductDetailRestDtoModel} from "@/models/api/pharma-pim-pp2/Pp2ProductDetailRestDtoModel";
import {UIStateDto} from "@/dtos/UIStateDto";
import {PimProductsRestService} from "@/services/rest/pim-pp2/PimProductsRestService";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {IPrinsLaboRestDto} from "@/api/pharma-pim-pp2/models";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import {UtilityHelper} from "@/helpers/UtilityHelper";

export default defineComponent({
  name: "PimProductsDetailLab",
  computed: {
    UtilityHelper() {
      return UtilityHelper
    }
  },
  components: {BaseSpinner, AlertError2},
  props: {
    cnkCode: {
      type: Number,
      required: true
    },
    product: {
      type: [Pp2ProductDetailRestDtoModel, Object],
      required: true
    }
  },
  data() {
    return {
      iPrinsLabo: {} as IPrinsLaboRestDto,
      iPrinsLaboUI: UIStateDto.createWithDefaults()
    }
  },
  watch: {
    cnkCode() {
      this.reloadContent();
    }
  },
  mounted() {
    this.reloadContent();
  },
  methods: {
    getMultilingualAddress(address?: string | null, municipality?: string | null): string {
      let returnValue = '' as string;

      if (address) {
        returnValue += address;
      }
      if (this.iPrinsLabo.zip_code || municipality) {
        returnValue += '<br>';
      }
      if (this.iPrinsLabo.zip_code) {
        returnValue += this.iPrinsLabo.zip_code;
      }
      if (municipality) {
        returnValue += ' ' + municipality;
      }
      if (this.iPrinsLabo.country_code) {
        returnValue += '<br>' + this.iPrinsLabo.country_code;
      }

      return returnValue
    },
    async reloadContent(): Promise<void> {
      this.iPrinsLaboUI
        .setNotReady()
        .clearError();

      try {
        this.iPrinsLabo = await PimProductsRestService.getInstance()
          .findIPrinsProductManufacturer(this.cnkCode);
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.iPrinsLaboUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.iPrinsLaboUI as UIStateDto))
          .catch(exceptions, true);
      } finally {
        this.iPrinsLaboUI.setReady();
      }
    },
  },
});
