
import {defineComponent} from "vue";

export default defineComponent({
  name: "BasePaginationItem",
  emits: ["changePage"],
  props: {
    paginationData: {
      type: Object,
      required: true,
    },
    pageNumber: {
      type: Number,
      required: false,
    },
    isFirst: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLast: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  computed: {
    pageItemClasses(): Object {
      return {
        'disabled': !this.pageNumber && !this.isFirst && !this.isLast,
        'active': this.pageNumber === this.paginationData.page_number,
      };
    },
  },
  methods: {
    changePage() {
      if (this.pageNumber) {
        this.$emit('changePage', this.pageNumber);
      } else if (this.isFirst) {
        this.$emit('changePage', 1);
      } else if (this.isLast) {
        this.$emit('changePage', this.paginationData.total_pages);
      } else {
        throw new Error('Unsupported changePage parameter');
      }
    }
  }
});
