import {PimEnumEndpointsApiService} from "@/main";
import {CodeDescriptionRestDto} from "@/api/pharma-pim-enum/models";
import {DpException} from "@/exception/DpException";
import {EnumClassNamePimEnum} from "@/models/enum/EnumClassNamePimEnum";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";

interface findEnumByClassNamePayload {
    className: EnumClassNamePimEnum;
    folder?: string;
}

interface findEnumByClassNamesPayload {
    classNames: EnumClassNamePimEnum[];
    folder?: string;
}

export default {
    namespaced: true,
    state() {
        return {
            enums: new Map<EnumClassNamePimEnum, CodeDescriptionRestDto[]>(),
        };
    },
    actions: {
        async findEnumByClassname(context: any, className: EnumClassNamePimEnum): Promise<void> {
            if (!context.state.enums.has(className)) {
                let enumClassName = `App\\Model\\Enum\\Pim\\${className}`;
                if (className.startsWith("Denum")) {
                    enumClassName = `App\\Model\\Entity\\Pim\\${className}`;
                }
                const response = await PimEnumEndpointsApiService
                    .getPimEnumValuesByClassname(enumClassName);
                context.state.enums.set(className, response.data as CodeDescriptionRestDto[]);
            }
        },
        async findManyEnumByClassname(context: any, classNames: EnumClassNamePimEnum[]): Promise<void> {
            const promises = [] as Promise<void>[];

            classNames?.forEach((className: EnumClassNamePimEnum) => {
                promises.push(context.dispatch("findEnumByClassname", className));
            });

            await Promise.all(promises);
        },
    },
    mutations: {},
    getters: {
        getEnumByClassname: (state: any) => (className: EnumClassNamePimEnum): CodeDescriptionRestDto[] => {
            if (!state.enums.has(className)) {
                throw new DpException('Enum `' + className + '` has not been initialized, yet.');
            }

            return state.enums.get(className);
        },
        getEnumByClassnameOrDefault: (state: any, getters: any) => (className: EnumClassNamePimEnum, defaultValue: any): CodeDescriptionRestDto[] | any => {
            try {
                return getters.getEnumByClassname(className);
            } catch (exceptions: any) {
                return defaultValue;
            }
        },
        getCodeDescriptionByCodeFromEnumByClassname: (state: any, getters: any) => (className: EnumClassNamePimEnum, code: string): CodeDescriptionRestDto | null => {
            return getters.getEnumByClassname(className).find((cd: CodeDescriptionRestDto) => {
                return cd.code === code;
            }) ?? null;
        },
    },
};
