import {PimProductsOverviewPageLayoutEnum} from "@/models/enum/pim/PimProductsOverviewPageLayoutEnum";

export class PimProductsPagingModel {
    layout: PimProductsOverviewPageLayoutEnum;
    isCategoriesExpected: boolean;
    number: number;
    size: string;


    constructor(layout: PimProductsOverviewPageLayoutEnum, number: number, size: string) {
        this.layout = layout;
        this.number = number;
        this.size = size;
        this.isCategoriesExpected = false;
    }

    public static createWithDefaults(): PimProductsPagingModel {
        return new this(
            PimProductsOverviewPageLayoutEnum.LIST,
            1,
            '24'
        );
    }
}
