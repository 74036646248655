import {OrderSettingsEndpointsApiService} from "@/main";
import {
    NameValuePairCompactRestDto,
    NameValuePairOverviewRestDto, NameValuePairValueRestDto,
} from "@/api/pharma-order-settings/models";
import {parseSavedNameValuePairs} from "@/store/pharma-order-settings/middleware";
import {OrderSettingsNameValuePairEnum} from "@/models/enum/OrderSettingsNameValuePairEnum";
import {CustomerContext} from "@/context/CustomerContext";

export default {
    namespaced: true,
    state() {
        return {
            name_value_pair_overview: [] as NameValuePairOverviewRestDto[],
        }
    },
    actions: {
        async searchNameValuePairs(context: any) {
            const response = await OrderSettingsEndpointsApiService.findNvps();

            context.commit("setNameValuePairs", response.data);
        },
        async searchNameValuePairsPharmacy(context: any) {
            const pharmacy_code = CustomerContext.getPharmacyCode();

            const response = await OrderSettingsEndpointsApiService.findPharmacyNvps(pharmacy_code);

            context.commit("setNameValuePairs", response.data);
        },
        async saveNameValuePairsBulk(context: any) {
            const compact_name_value_pairs = parseSavedNameValuePairs(context.getters.getNameValuePairs) as NameValuePairCompactRestDto[];

            await OrderSettingsEndpointsApiService.updateNvpsInBulk(compact_name_value_pairs);
        },
        async saveNameValuePairPharmacy(context: any, name: string) {
            const nvp_value = {
                value: context.getters.getSingleNameValuePair(name).value
            } as NameValuePairValueRestDto;
            const pharmacy_code = CustomerContext.getPharmacyCode();

            await OrderSettingsEndpointsApiService.updatePharmacyNvp(nvp_value, name, pharmacy_code);
        }
    },
    mutations: {
        setNameValuePairs(state: any, name_value_pair_overview: NameValuePairOverviewRestDto[]) {
            state.name_value_pair_overview = name_value_pair_overview;
        },
    },
    getters: {
        getNameValuePairs(state: any): NameValuePairOverviewRestDto[] {
            return state.name_value_pair_overview;
        },
        getSingleNameValuePair: (state: any) => (name: OrderSettingsNameValuePairEnum): NameValuePairOverviewRestDto => {
            return state.name_value_pair_overview.find((nvp: NameValuePairOverviewRestDto) => nvp.name === name);
        },
    }
}
