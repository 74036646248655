
import {defineComponent} from "vue";
import {Exception} from "@/api/interfaces";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {AbilityContext} from "@/context/AbilityContext";
import {AuthContext} from "@/context/AuthContext";
import {UIStateDto} from "@/dtos/UIStateDto";
import {MenuRestDtoModel} from "@/models/api/pharma-cms-menu/MenuRestDtoModel";
import InputText from "@/components/UI/InputText.vue";
import {MenuRestService} from "@/services/rest/cms-menu/MenuRestService";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import {MenuLayoutEnum} from "@/api/enums/menu-layout-enum";
import {MenuHorizontalPositionEnum} from "@/api/enums/menu-horizontal-position-enum";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import {createCodeDescriptionArrayFromEnum} from "@/helpers/functions/enum";

export default defineComponent({
  name: "CreateMenu",
  components: {InputCheckboxBoolean, InputText, BaseTitle},
  data() {
    return {
      exceptions: [] as Exception[],
      ui: UIStateDto.createWithDefaults(),

      menu: MenuRestDtoModel.createWithDefaults(),
    };
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    AuthContext() {
      return AuthContext
    },
    AbilityContext() {
      return AbilityContext
    },
    menuLayoutOptions(): CodeDescriptionRestDto[] {
      // Todo, enums ophalen via api als Frits klaar is
      return createCodeDescriptionArrayFromEnum(MenuLayoutEnum);
    },
    menuHorizontalPositionOptions(): CodeDescriptionRestDto[] {
      // Todo, enums ophalen via api als Frits klaar is
      return createCodeDescriptionArrayFromEnum(MenuHorizontalPositionEnum);
    },
  },
  methods: {
    async reloadContent() {
      this.ui.clearError().setNotReady();
      this.exceptions = [];
      try {
        //
      } finally {
        this.ui.setIsReady(true);
      }
    },
    async submitForNewMenu() {
      this.ui.clearError();

      try {
        let service = MenuRestService.getInstance();
        await service.createMenu(this.menu);
        await this.$router.push({name: 'menus'});
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.ui as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.ui as UIStateDto))
        .catch(exceptions, true);
    }

  }
});
