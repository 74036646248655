import {MenuOverviewRestDto, MenuOverviewResultsRestDto} from "@/api/pharma-cms-menu/models";

export class MenuOverviewResultsRestDtoModel implements MenuOverviewResultsRestDto {
    results: MenuOverviewRestDto[];

    constructor(results: MenuOverviewRestDto[]) {
        this.results = results;
    }

    public static createWithDefaults(): MenuOverviewResultsRestDtoModel {
        return new this([]);
    }
}
