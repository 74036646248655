import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_spinner = _resolveComponent("base-spinner")!
  const _component_CpAbstractsOverviewItem = _resolveComponent("CpAbstractsOverviewItem")!

  return (!_ctx.isReady)
    ? (_openBlock(), _createBlock(_component_base_spinner, { key: 0 }))
    : (!_ctx.hasContentPages)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.$t('noContentFound')), 1))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.contentPages, (contentPage) => {
          return (_openBlock(), _createBlock(_component_CpAbstractsOverviewItem, {
            key: contentPage.id,
            "content-page": contentPage,
            "is-library": _ctx.isLibrary,
            onExceptions: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('exceptions'))),
            onSelectedCp: _ctx.selectedCp
          }, null, 8, ["content-page", "is-library", "onSelectedCp"]))
        }), 128))
}