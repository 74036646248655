import {PageLinkTypeEnum} from "@/models/enum/PageLinkTypeEnum";
import {LinkTargetEnum} from "@/models/enum/LinkTargetEnum";
import {PageRefTargetEnum} from "@/api/enums/page-ref-target-enum";
import {TargetEnum} from "@/api/enums/target-enum";

export class PageLinkPayload {
    external_url: string | null;
    page_id: string | null;
    //todo change target prop of banner cb to LinkTargetEnum
    target: LinkTargetEnum | PageRefTargetEnum | TargetEnum;
    type: PageLinkTypeEnum;

    constructor(external_url: string | null, page_id: string | null, target: LinkTargetEnum | PageRefTargetEnum | TargetEnum) {
        this.external_url = external_url;
        this.page_id = page_id;
        this.target = target;
        this.type = this.detectPageLinkType();
    }

    public static createWithDefaults(): PageLinkPayload {
        return new PageLinkPayload(null, null, LinkTargetEnum.SELF);
    }

    public static cloneFrom(payload: PageLinkPayload): PageLinkPayload {
        return new PageLinkPayload(payload.external_url, payload.page_id, payload.target);
    }

    protected detectPageLinkType(): PageLinkTypeEnum {
        if (this.external_url !== null) {
            return PageLinkTypeEnum.URL;
        }

        if (this.page_id !== null) {
            return PageLinkTypeEnum.PAGE;
        }

        return PageLinkTypeEnum.NONE;
    }
}
