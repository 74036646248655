
import {mapActions, mapGetters} from 'vuex';
import {mapActions as mapActionsP, mapState} from 'pinia';
import {defineComponent, inject} from "vue";
import {Exception} from '@/api/interfaces';
import updateAbilities from '@/config/UpdateAbility';
import {ABILITY_TOKEN} from '@casl/vue';
import {CustomerOverviewRestDto} from "@/api/pharma-customer/models";
import ability from "@/config/ability";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-pharmacy/models";
import {Collapse} from "bootstrap";
import {CustomerService} from "@/services/business/CustomerService";
import {AuthContext} from "@/context/AuthContext";
import {useCustomerStore} from "@/stores/CustomerStore";
import {CustomerCriteriaRestDtoModel} from "@/models/api/pharma-customer/CustomerCriteriaRestDtoModel";
import {CustomerContext} from "@/context/CustomerContext";

export default defineComponent({
  setup() {
    const ability = inject(ABILITY_TOKEN);
    return {
      ability
    }
  },
  data: () => ({
    exceptions: [] as Exception[],
    amountOfCustomersToShow: 9 as number,

    customerCollapse: null as Collapse | null,
    pharmacyCollapse: null as Collapse | null,
  }),
  mounted() {
    this.reloadContent().then(() => {
      this.customerCollapse = Collapse.getOrCreateInstance('#customer-collapse', {
        toggle: false,
      } as Partial<Collapse.Options>);
    });
  },
  // watch: {
  //   async currentCustomerCode() {
  //     if (this.isMultiPharmacy) {
  //       console.log('isMultiPharmacy')
  //       await this.searchPharmacies();
  //     }
  //   }
  // },
  computed: {
    ...mapGetters(['getCurrentCustomerCode', 'getUser', 'isAuthenticated']),
    ...mapGetters('license_info', ['getProductFeatureAuthorizationOverview']),
    ...mapState(useCustomerStore, [
      "getCriteria",
      "getCustomers",
      "getCustomersWithCriteria",
      "getPharmacyCode",
      "getPharmacies"
    ]),
    currentCustomer(): CustomerOverviewRestDto | null {
      const currentCustomer = this.getCustomers.find((customer: CustomerOverviewRestDto) => customer.customer_code === this.currentCustomerCode);
      if (currentCustomer) {
        return currentCustomer;
      }

      return null;
    },
    currentPharmacyName(): string | null {
      if (this.isMultiPharmacy) {
        const currentPharmacy = this.pharmacyOverview.find((pharmacy: CodeDescriptionRestDto) => pharmacy.code === this.currentPharmacyCode);
        if (currentPharmacy) {
          return currentPharmacy.description;
        }
      }

      return null;
    },
    currentCustomerCode(): string | null {
      return this.getCurrentCustomerCode;
    },
    currentPharmacyCode(): string | null {
      return this.getPharmacyCode;
    },
    customerCriteria(): CustomerCriteriaRestDtoModel {
      return this.getCriteria.customer;
    },
    customerOverview(): CustomerOverviewRestDto[] {
      if (this.getCustomersWithCriteria?.length > this.amountOfCustomersToShow) {
        return this.getCustomersWithCriteria.slice(0, this.amountOfCustomersToShow);
      }
      return this.getCustomersWithCriteria;
    },
    pharmacyOverview(): CodeDescriptionRestDto[] {
      return this.getPharmacies;
    },
    hasMoreCustomersToShow(): boolean {
      return (this.getCustomersWithCriteria && this.getCustomersWithCriteria?.length > this.amountOfCustomersToShow) || this.isDpUser;
    },
    isDpUser(): boolean {
      return AuthContext.isDpUser(true);
    },
    isMultiPharmacy(): boolean {
      return ability.can('use-feature', 'MULTI_PHARMACY');
    },
  },
  methods: {
    ...mapActions(["changeCustomer"]),
    ...mapActions("customer_settings", ["searchCustomerMainDomain", "searchCustomerMainLicense"]),
    ...mapActionsP(useCustomerStore, [
      "searchCustomersWithCriteria",
      "setPharmacyCode",
      "searchPharmacies",
      "clearCustomerCriteria"
    ]),
    async reloadContent(): Promise<void> {
      this.exceptions = [];

      try {
        await this.switchCustomer();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async searchCustomers(): Promise<void> {
      this.exceptions = [];

      try {
        await this.searchCustomersWithCriteria();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async resetSearchCustomers(): Promise<void> {
      this.clearCustomerCriteria();

      await this.searchCustomers();
    },
    async switchCustomer(customer_code?: string): Promise<void> {
      this.exceptions = [];

      try {
        await this.changeCustomer(customer_code);

        if (!customer_code) {
          customer_code = CustomerContext.getCustomerCode();
        } else {
          await this.$router.push({name: 'home'});
        }

        await Promise.all([
          this.searchPharmacies(),
          this.searchCustomerMainDomain(customer_code),
          this.searchCustomerMainLicense(customer_code),
          updateAbilities(this.ability, customer_code)
        ]);
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      this.customerCollapse?.hide();
      if (this.isMultiPharmacy) {
        this.pharmacyCollapse = Collapse.getOrCreateInstance('#pharmacy-collapse', {
          toggle: false,
        } as Partial<Collapse.Options>);
        this.pharmacyCollapse?.hide();
      } else {
        this.pharmacyCollapse?.dispose();
      }
    },
    async switchPharmacy(pharmacy_code: string): Promise<void> {
      this.exceptions = [];

      try {
        await this.$router.push({name: 'home'});

        await CustomerService.getInstance()
          .changePharmacyHook(pharmacy_code);

        await this.setPharmacyCode(pharmacy_code);
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      this.pharmacyCollapse?.hide();
    },
    async toggleCustomerCollapse(): Promise<void> {
      this.customerCollapse?.toggle();
    },
    async togglePharmacyCollapse(): Promise<void> {
      this.pharmacyCollapse?.toggle();
    }
  }
});
