import {MoveToRestDto} from "@/api/pharma-pim-category/models";

export class MoveToRestDtoModel implements MoveToRestDto {
    from_dp_category_code?: string | null;
    from_internal_category_code?: string | null;
    cnk_codes?: number[] | null;


    constructor(from_dp_category_code?: string | null, from_internal_category_code?: string | null, cnk_codes?: number[] | null) {
        this.from_dp_category_code = from_dp_category_code;
        this.from_internal_category_code = from_internal_category_code;
        this.cnk_codes = cnk_codes;
    }

    public static createWithDefaults(): MoveToRestDtoModel {
        return new this();
    }
}
