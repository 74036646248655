
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {arrayHasContent} from "@/helpers/functions/arrays";
import CbLibraryOverviewItem from "@/components/layouts/content-pages/wysiwyg/libraries/CbLibraryOverviewItem.vue";
import {TinyEmitter} from "tiny-emitter";

export default defineComponent({
  name: "CbLibraryOverview",
  components: {CbLibraryOverviewItem},
  emits: ["exceptions"],
  props: {
    cbType: {
      type: String,
      required: true,
    },
    libraryCode: {
      type: String,
      required: true,
    },
    bus: {
      type: TinyEmitter,
      required: true,
    }
  },
  data: () => ({
    isReady: false as boolean,
  }),
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cms_content_block', ["getLibContentBlocks"]),
    hasContentBlocks() {
      return arrayHasContent(this.libContentBlocks);
    },
    libContentBlocks(): [] {
      return this.getLibContentBlocks(this.libraryCode);
    },
  },
  methods: {
    ...mapActions('cms_content_block', ['searchLibContentBlocks']),
    async reloadContent(): Promise<void> {
      this.isReady = false;

      try {
        await this.searchLibContentBlocks(this.libraryCode);
      } catch (exceptions: any) {
        this.$emit('exceptions', exceptions);
      }

      this.isReady = true;
    },
  },
});
