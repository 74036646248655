import {
    ContentBlockInRowRestDto, ContentPageRestDto, ContentPageRowCreateRestDto,
    ContentPageRowRestDto, ElementTargetPositionRestDto, WysiwygContentBlockInRowRestDto,
    WysiwygContentPageRestDto, WysiwygContentPageRowRestDto
} from "@/api/pharma-cms-content-page/models";
import {
    ContentBlockInRowEndpointsApiService,
    ContentPageEndpointsApiService,
    ContentPageRowEndpointsApiService
} from "@/main";
import {
    emptyWysiwygContentPage,
    parseSearchedContentPageWysiwyg
} from "@/store/pharma-cms-content-page/middleware";
import {ContentPageRowRestDtoModel} from "@/models/ContentPageRowRestDtoModel";
import {ContentBlockInRowCreateRestDtoModel} from "@/models/ContentBlockInRowCreateRestDtoModel";

export default {
    namespaced: true,
    state() {
        return {
            content_page: {} as WysiwygContentPageRestDto,
        }
    },
    actions: {
        clearContentPage(context: any) {
            context.commit("clearContentPage");
        },
        async searchContentPage(context: any, id: string) {
            const response = await ContentPageEndpointsApiService.getContentPageWysiwyg(id);

            context.commit("setContentPage", parseSearchedContentPageWysiwyg(response.data));
        },
        async searchHomePage(context: any): Promise<Boolean> {
            const response = await ContentPageEndpointsApiService.getHomePageWysiwyg();

            if (response.status === 204) {
                context.commit("clearContentPage");
                return false;
            }
            context.commit("setContentPage", parseSearchedContentPageWysiwyg(response.data));
            return true;
        },
        async searchNamedPage(context: any, code: string): Promise<Boolean> {
            const response = await ContentPageEndpointsApiService.getNamedPageWysiwyg(code);

            if (response.status === 204) {
                context.commit("clearContentPage");
                return false;
            }
            context.commit("setContentPage", parseSearchedContentPageWysiwyg(response.data));
            return true;
        },
        async saveContentPage(context: any) {
            const contentPage = parseSearchedContentPageWysiwyg(context.getters.getContentPage);

            await ContentPageEndpointsApiService.updateContentPage(contentPage.cp as ContentPageRestDto, contentPage.id);
        },
        async saveContentPageRow(context: any, payload: any) {
            const pageId = payload.page_id as string;
            const rowId = payload.row_id as string;
            const row = payload.row as ContentPageRowRestDto;

            await ContentPageRowEndpointsApiService.updateContentPageRow(row, pageId, rowId);
        },
        async saveContentPageColumn(context: any, payload: any) {
            const rowId = payload.row_id as string;
            const cbInRowId = payload.cb_in_row_id as string;
            const cbInRow = payload.cb_in_row as ContentBlockInRowRestDto;

            await ContentBlockInRowEndpointsApiService.updateContentBlockInRow(cbInRow, rowId, cbInRowId);
        },
        async createContentPageRow(context: any, pageId: string) {
            const contentPageRow = {
                target_position: {},
                row: ContentPageRowRestDtoModel.createWithDefaults(),
            } as ContentPageRowCreateRestDto;

            await ContentPageRowEndpointsApiService.createContentPageRow(contentPageRow, pageId);
        },
        async createContentBlockInRow(context: any, payload: any): Promise<string> {
            const rowId = payload.row_id as string;
            const cbInRow = payload.cb_in_row as ContentBlockInRowCreateRestDtoModel;

            const response = await ContentBlockInRowEndpointsApiService.createContentBlockInRow(cbInRow, rowId);
            return response.data.id as string;
        },
        async moveColumnToPosition(context: any, payload: any) {
            const rowId = payload.rowId as string;
            const columnId = payload.columnId as string;
            const position = payload.position as ElementTargetPositionRestDto;

            await ContentBlockInRowEndpointsApiService.moveContentBlockInRowTo(position, rowId, columnId);
        }
    },
    mutations: {
        clearContentPage(state: any) {
            state.content_page = emptyWysiwygContentPage();
        },
        setContentPage(state: any, content_page: WysiwygContentPageRestDto) {
            state.content_page = content_page;
        }
    },
    getters: {
        getContentPage(state: any): WysiwygContentPageRestDto {
            return state.content_page;
        },
        getWysiwygContentPageRowById: (state: any) => (id: string): WysiwygContentPageRowRestDto | undefined => {
            return state.content_page.rows.find((e: WysiwygContentPageRowRestDto) => {
                return e.id === id;
            });
        },
        getWysiwygContentBlockInRowById: (state: any) => (id: string): WysiwygContentBlockInRowRestDto | undefined => {
            for (let i = 0; i < state.content_page.rows.length; i++) {
                for (let j = 0; j < state.content_page.rows[i].columns.length; j++) {
                    if (state.content_page.rows[i].columns[j].id === id) {
                        return state.content_page.rows[i].columns[j];
                    }
                }
            }
        }
    },
};
