import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cbs_overview = _resolveComponent("cbs-overview")!

  return (_openBlock(), _createBlock(_component_cbs_overview, {
    onCopyContentBlock: _cache[0] || (_cache[0] = ($event: any) => (null/*this.copyContentBlock*/)),
    onCopiedLibCb: this.reloadContent,
    onDeleteContentBlock: this.deleteContentBlock,
    "content-blocks": this.contentBlocks,
    "content-type": this.isBannerInSlot ? 'BANNER_IN_SLOT' : 'BANNER',
    "cb-type": this.cbType,
    "can-copy-item": this.canCopyItem,
    "can-use-library": this.canUseLibrary,
    "is-ready": this.isReady,
    "has-content-blocks": this.hasContentBlocks,
    description: this.description,
    title: this.title,
    "route-object-edit-cb": this.routeObjectEditCb,
    "route-object-new-cb": this.routeObjectNewCb,
    "table-header-items": this.tableHeaderItems
  }, null, 8, ["onCopiedLibCb", "onDeleteContentBlock", "content-blocks", "content-type", "cb-type", "can-copy-item", "can-use-library", "is-ready", "has-content-blocks", "description", "title", "route-object-edit-cb", "route-object-new-cb", "table-header-items"]))
}