import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_FooterDetailContent1 = _resolveComponent("FooterDetailContent1")!
  const _component_FooterDetailContent2 = _resolveComponent("FooterDetailContent2")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_BaseTitle, {
        title: _ctx.$t('footer'),
        "back-route": {name: 'footers'},
        mode: _ctx.PageDetailModeEnum.EDIT,
        "help-text": "Voorbeeld van een helptekst.",
        mb: 3
      }, null, 8, ["title", "mode"]),
      (this.type === _ctx.FooterContentTypeEnum.FOOTER_CONTENT_1)
        ? (_openBlock(), _createBlock(_component_FooterDetailContent1, {
            key: 0,
            id: this.id,
            type: this.type
          }, null, 8, ["id", "type"]))
        : _createCommentVNode("", true),
      (this.type === _ctx.FooterContentTypeEnum.FOOTER_CONTENT_2)
        ? (_openBlock(), _createBlock(_component_FooterDetailContent2, {
            key: 1,
            id: this.id,
            type: this.type
          }, null, 8, ["id", "type"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}