import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";

export class CodeDescriptionRestDtoModel implements CodeDescriptionRestDto {
    code: string;
    description?: string | null;

    constructor(code: string, description?: string | null) {
        this.code = code;
        this.description = description ?? undefined;
    }

    public static createWithDefaults(): CodeDescriptionRestDtoModel {
        return new CodeDescriptionRestDtoModel('code', 'description');
    }
}
