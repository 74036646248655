import {RestService} from "@/services/rest/RestService";
import {CustomerDomainSettingsAPIApi} from "@/api/pharma-customer-settings";

export class CustomerDomainRestService extends RestService {

    private apiService: CustomerDomainSettingsAPIApi;

    constructor() {
        super();

        this.apiService = new CustomerDomainSettingsAPIApi(this.getDpAxiosInstance(), undefined, this.getConfig().customerUrl);
    }

    public async findCustomerMainDomain(customerCode: string): Promise<string> {
        const response = await this.apiService.findCustomerMainDomain(customerCode);

        return response.data.domain_url;
    }
}
