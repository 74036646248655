import {CmsHeaderContent1RestDto, CmsPharmacyGroupRestDto} from "@/api/pharma-cms-header/models";
import {HeaderContent1LayoutEnum} from "@/api/enums/header-content1-layout-enum";

export class CmsHeaderContent1RestDtoModel implements CmsHeaderContent1RestDto {
    layout_code: HeaderContent1LayoutEnum;
    is_show_pharmacy_name: boolean | null;
    is_show_opening_info: boolean;
    is_show_phone_number: boolean;
    is_show_social_media: boolean;
    is_show_pharmacy_on_call: boolean;
    pharmacy_group: CmsPharmacyGroupRestDto | null;
    is_sticky: boolean;

    constructor(layout_code: HeaderContent1LayoutEnum, is_show_opening_info: boolean, is_show_phone_number: boolean, is_show_social_media: boolean, is_show_pharmacy_on_call: boolean, is_show_pharmacy_name: boolean | null, pharmacy_group: CmsPharmacyGroupRestDto | null,is_sticky:boolean) {
        this.layout_code = layout_code;
        this.is_show_pharmacy_name = is_show_pharmacy_name;
        this.is_show_opening_info = is_show_opening_info;
        this.is_show_phone_number = is_show_phone_number;
        this.is_show_social_media = is_show_social_media;
        this.is_show_pharmacy_on_call = is_show_pharmacy_on_call;
        this.pharmacy_group = pharmacy_group;
        this.is_sticky = is_sticky;
    }

    public static createWithDefaults(): CmsHeaderContent1RestDtoModel {
        return new this(
            HeaderContent1LayoutEnum.MAIN_PHARMACY_ONLY,
            false,
            false,
            false,
            false,
            false,
            null,
            false
        );
    }
}
