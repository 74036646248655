
import {defineComponent} from "vue";
import {mapActions} from 'vuex';
import {ImageContentRestDtoModel} from "@/models/api/pharma-cms-content-block/ImageContentRestDtoModel";
import {
  CodeDescriptionRestDto
} from "@/api/pharma-cms-content-block/models";
import PageLinkPicker from "@/components/UI/PageLinkPicker.vue";
import {PageLinkPayload} from "@/models/payload/PageLinkPayload";
import {PageLinkTypeEnum} from "@/models/enum/PageLinkTypeEnum";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import AbstractCbCommon from "@/components/layouts/content-pages/wysiwyg/structures/AbstractCbCommon.vue";
import {LinkTargetEnum} from "@/models/enum/LinkTargetEnum";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import {ImageTargetEnum} from "@/api/enums/image-target-enum";
import {CopyCpAbstractPayload} from "@/models/payload/CopyCpAbstractPayload";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import {CmsDocumentRestService} from "@/services/rest/cms-document/CmsDocumentRestService";
import InputFile from "@/components/UI/InputFile.vue";
import InputSelect from "@/components/UI/InputSelect.vue";

export default defineComponent({
  name: "CbImage",
  extends: AbstractCbCommon,
  components: {InputSelect, InputFile, BaseSpinner, PageLinkPicker},
  data: () => ({
    contentBlock: ImageContentRestDtoModel.createWithDefaults(),
    pageLinkPayload: PageLinkPayload.createWithDefaults(),
    docType: 'PAGE_IMG' as string,
    imagePreviewUrl: null as string | null,
  }),
  watch: {
    contentBlock() {
     this.buildPageLinkPayload();

      if (this.existingDocumentKey) {
        CmsDocumentRestService.getInstance()
          .findPreviewUrl(this.existingDocumentKey).then((url: string) => {
          this.imagePreviewUrl = url;
        });
      } else {
        this.imagePreviewUrl = null;
      }

      this.onCopyCpAbstractChanged();
    },
  },
  computed: {
    enumBorderTypeCode(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.BORDER_TYPE);
    },
    enumBorderWidthCode(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.BORDER_WIDTH);
    },
    enumImageWidthCode(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.IMAGE_WIDTH);
    },
    enumLightboxLayoutCode(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.LIGHTBOX);
    },
    existingDocumentKey(): string | null {
      return this.contentBlock.image.image_key.nl ?? null;
    },
  },
  methods: {
    ...mapActions('cms_content_block/normal_content_block/image', ['create', 'find', 'save']),
    determineEnums(): EnumClassNameCmsEnum[] {
      return [
        EnumClassNameCmsEnum.BORDER_TYPE,
        EnumClassNameCmsEnum.BORDER_WIDTH,
        EnumClassNameCmsEnum.IMAGE_WIDTH,
        EnumClassNameCmsEnum.LIGHTBOX,
      ];
    },
    getDefaultCbModel(): ImageContentRestDtoModel {
      return ImageContentRestDtoModel.createWithDefaults();
    },
    buildPageLinkPayload():void {
      const externalUrl = this.contentBlock.image.image_url?.nl ?? null;
      const pageId = this.contentBlock.image.image_page_id?.nl ?? null;
      const target = this.contentBlock.image.image_target ?? LinkTargetEnum.SELF;

      this.pageLinkPayload = new PageLinkPayload(externalUrl, pageId, target.toString() as LinkTargetEnum);
    },
    onPageLinkPickerChanged(payload: PageLinkPayload) {
      if (payload.type === PageLinkTypeEnum.NONE) {
        this.contentBlock.image.image_url = undefined;
        this.contentBlock.image.image_page_id = undefined;
        this.contentBlock.image.image_target = undefined;
      } else if (payload.type === PageLinkTypeEnum.URL) {
        this.contentBlock.image.image_url = new MultilingualRestDtoModel(null, null, null, payload.external_url);
        this.contentBlock.image.image_page_id = undefined;
        this.contentBlock.image.image_target = payload.target.toString() as ImageTargetEnum;
      } else if (payload.type === PageLinkTypeEnum.PAGE) {
        this.contentBlock.image.image_url = undefined;
        this.contentBlock.image.image_page_id = new MultilingualRestDtoModel(null, null, null, payload.page_id);
        this.contentBlock.image.image_target = payload.target.toString() as ImageTargetEnum;
      }

      this.onChange();
    },
    onCopyCpAbstractChanged(): void {
      const imageKey = this.contentBlock.image.image_key?.nl ?? null;
      this.copyCpAbstractPayload = new CopyCpAbstractPayload(null, imageKey, null);

      this.$emit('abstractContentChanged', this.copyCpAbstractPayload);
    },
    async setImageKey(key: string | null): Promise<void> {
      this.contentBlock.image.image_key = new MultilingualRestDtoModel(null, null, null, key);

      if (key !== null) {
        this.imagePreviewUrl = await CmsDocumentRestService.getInstance().findPreviewUrl(key);
      } else {
        this.imagePreviewUrl = null;
      }

      this.onChange();
    },
    pasteCbMetadata(): void {
      // When calling clone method directly on object, method is not found. So this is a hacky workaround
      const cbClone = ImageContentRestDtoModel.cloneFrom(this.contentBlock);

      cbClone.cloneMetadataFrom(this.getClipboardContentBlock(this.cbType) as ImageContentRestDtoModel);
      this.contentBlock = cbClone;

      this.buildPageLinkPayload();
    },
  }
});
