
import {defineComponent} from "vue";
import {MultilingualProductLabelRestDtoModel} from "@/models/api/pharma-cpc-mgmt/MultilingualProductLabelRestDtoModel";
import {ColorSchemeRestDtoModel} from "@/models/api/pharma-cms-color-scheme/ColorSchemeRestDtoModel";
import {BaseColorRestDto} from "@/api/interfaces/base-color-rest-dto";
import {SchemeColorEnum} from "@/api/enums/scheme-color-enum";
import {ProductLabelPositionEnum} from "@/api/enums/product-label-position-enum";

export default defineComponent({
  name: "RenderedProductLabel",
  props: {
    productLabel: {
      type: [MultilingualProductLabelRestDtoModel, Object],
      required: false
    },
    activeLanguage: {
      type: String,
      required: true,
    },
    positioned: {
      type: Boolean,
      required: false,
      default: false
    },
    colorScheme: {
      type: [ColorSchemeRestDtoModel, Object],
      required: true,
    },
  },
  computed: {
    textColor(): string {
      if (this.productLabel?.text_color) {
        return this.getCssColor(this.productLabel.text_color);
      }
      if (!this.colorScheme) {
        return '';
      }
      switch (this.productLabel?.scheme_color_code) {
        case SchemeColorEnum.PRIMARY:
          return this.colorScheme.text_color_on_primary_color;
        case SchemeColorEnum.PRIMARY_LIGHTER:
          return this.colorScheme.text_color_on_light_color;
        case SchemeColorEnum.PRIMARY_LIGHT:
        case SchemeColorEnum.SECONDARY_LIGHT:
          return this.colorScheme.text_color_on_light_color;
        case SchemeColorEnum.SECONDARY:
          return this.colorScheme.text_color_on_secondary_color;
        case SchemeColorEnum.SECONDARY_LIGHTER:
          return this.colorScheme.text_color_on_secondary_lighter_color;
        case SchemeColorEnum.GREY:
          return '#323232';
        default:
          return this.colorScheme.text_color_on_light_color;
      }
    },
    backgroundColor(): string {
      if (this.productLabel?.background_color) {
        return this.getCssColor(this.productLabel.background_color);
      }
      if (!this.colorScheme) {
        return '';
      }
      switch (this.productLabel?.scheme_color_code) {
        case SchemeColorEnum.PRIMARY:
          return this.colorScheme.primary_color;
        case SchemeColorEnum.PRIMARY_LIGHTER:
          return this.colorScheme.primary_lighter_color;
        case SchemeColorEnum.PRIMARY_LIGHT:
          return this.colorScheme.primary_light_color;
        case SchemeColorEnum.SECONDARY:
          return this.colorScheme.secondary_color;
        case SchemeColorEnum.SECONDARY_LIGHTER:
          return this.colorScheme.secondary_lighter_color;
        case SchemeColorEnum.SECONDARY_LIGHT:
          return this.colorScheme.secondary_light_color;
        case SchemeColorEnum.GREY:
          return '#F1F1F1';
        default:
          return 'rgba(0, 0, 0, 0)';
      }
    },
    content(): string {
      return this.productLabel?.content[this.activeLanguage];
    },
    classForLabel(): string {
      return 'badge custom_badge position-absolute px-2 ms-2 mt-2 me-2 mb-2 ';
    },
    classForPositionedLabel(): string {
      let cssClass = this.classForLabel;
      switch (this.productLabel?.position_code) {
        case ProductLabelPositionEnum.TOP_LEFT: {
          cssClass += 'start-0 top-0'
          break;
        }
        case ProductLabelPositionEnum.TOP_RIGHT: {
          cssClass += 'end-0 top-0'
          break;
        }
        case ProductLabelPositionEnum.BOTTOM_LEFT: {
          cssClass += 'start-0 bottom-0'
          break;
        }
        case ProductLabelPositionEnum.BOTTOM_RIGHT: {
          cssClass += 'end-0 bottom-0'
          break;
        }
      }

      return cssClass;
    }
  },
  methods: {
    getCssColor(colorDto: BaseColorRestDto) {
      return 'rgba(' +
        colorDto.rgb.red + ', ' +
        colorDto.rgb.green + ', ' +
        colorDto.rgb.green + ', ' +
        colorDto.opacity + ')';
    }
  }
});
