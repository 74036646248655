
import {defineComponent, PropType} from "vue";
import {arrayHasContent} from "@/helpers/functions/arrays";
import {CodeDescriptionRestDto} from "@/api/pharma-cpc-product/models";
import SelectTrademarksSelectedItem from "@/components/layouts/catalog/SelectTrademarksSelectedItem.vue";

export default defineComponent({
  name: "SelectTrademarksSelected",
  components: {SelectTrademarksSelectedItem},
  emits: ["removeTrademark"],
  props: {
    selectedTrademarkCodes: {
      type: Array as PropType<string[]>,
      required: true
    },
    catalogCustomerCode: {
      type: String,
      required: false,
    },
    pimTrademarks: {
      type: Array as PropType<CodeDescriptionRestDto[]>,
      required: false
    }
  },
  computed: {
    hasSelectedTrademarkCodes(): boolean {
      return arrayHasContent(this.selectedTrademarkCodes);
    },
  },
  methods: {
    removeTrademark(trademark_code: string) {
      this.$emit('removeTrademark', trademark_code);
    },
  }
});
