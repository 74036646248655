
import {defineComponent} from "vue";
import {ProductFilterConditionRestDto} from "@/api/pharma-cpc-mgmt/models";

export default defineComponent({
  name: "ProductFilterCnkList",
  emits: [],
  props: {
    fieldName: {
      type: String,
      required: false,
      default: 'cnk_list',
    },
    filterConditionObject: {
      type: Object,
      required: true,
    },
    selectionType: {
      type: String,
      required: true,
      validate(value: string) {
        return value.toUpperCase() === 'INCLUDE' || value.toUpperCase() === 'EXCLUDE';
      }
    },
    //TODO check which exact type errors is
    errors: [Array, Object, String],
  },
  computed: {
    filterCondition(): ProductFilterConditionRestDto {
      return this.filterConditionObject;
    },
  }
});
