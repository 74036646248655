
import {defineComponent} from "vue";
import {mapActions, mapGetters} from 'vuex';
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import AbstractCbCommon from "@/components/layouts/content-pages/wysiwyg/structures/AbstractCbCommon.vue";
import {PageReferencesContentRestDtoModel} from "@/models/PageReferencesContentRestDtoModel";
import {DpException} from "@/exception/DpException";
import {PageReferenceTypeEnum} from "@/models/enum/PageReferenceTypeEnum";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import {OrderFieldEnum} from "@/api/enums/order-field-enum";
import {PageReferenceOrderByRestDtoModel} from "@/models/PageReferenceOrderByRestDtoModel";
import Subtitle from "@/components/UI/Subtitle.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import InputCheckbox from "@/components/UI/InputCheckbox.vue";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import {MoveActionEnum} from "@/models/enum/MoveActionEnum";
import InputNumber from "@/components/UI/InputNumber.vue";

export default defineComponent({
  name: "CbPageReferences",
  components: {InputNumber, InputCheckboxBoolean, InputCheckbox, InputSelect, BaseSpinner, Subtitle},
  extends: AbstractCbCommon,
  data: () => ({
    allowedOrderFieldsForExtRef: [
      OrderFieldEnum.SORT_WEIGHT,
      OrderFieldEnum.CREATED
    ] as string[],
    contentBlock: PageReferencesContentRestDtoModel.createWithDefaults(),
    referenceType: PageReferenceTypeEnum.INTERNAL as PageReferenceTypeEnum,
    selectedOrderFilter: PageReferenceOrderByRestDtoModel.createWithDefaults(),
    // todo tmp fix until label functionality implemented
    filterLabel: null as string | null,
    useFilterShowOnHomepage: false as boolean,
  }),
  computed: {
    PageReferenceTypeEnum() {
      return PageReferenceTypeEnum
    },
    MoveActionEnum() {
      return MoveActionEnum;
    },
    ...mapGetters('cms_content_page', ["getPageAbstractLibraries", "getPageTypeEnums"]),
    pageAbstractLibOptions(): CodeDescriptionRestDto[] {
      return this.getPageAbstractLibraries
    },
    pageTypeOptions(): CodeDescriptionRestDto[] {
      return this.getPageTypeEnums
    },
    imageFitOptions(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.OBJECT_FIT);
    },
    // imageHeightOptions(): CodeDescriptionRestDto[] {
    //   return this.getEnum(EnumClassNameCmsEnum.IMAGE_HEIGHT);
    // },
    imageHorizontalPositionOptions(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.OBJECT_HORIZONTAL_POSITION);
    },
    imageVerticalPositionOptions(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.OBJECT_VERTICAL_POSITION);
    },
    orderDirectionOptions(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.ORDER_DIRECTION);
    },
    orderFieldOptions(): CodeDescriptionRestDto[] {
      if (this.referenceType === PageReferenceTypeEnum.EXTERNAL) {
        return this.getEnum(EnumClassNameCmsEnum.PAGE_REFS_ORDER_FIELD)
          .filter((order_field: CodeDescriptionRestDto) => this.allowedOrderFieldsForExtRef.includes(order_field.code));
      }
      return this.getEnum(EnumClassNameCmsEnum.PAGE_REFS_ORDER_FIELD);
    },
    overviewPageLayoutOptions(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.OVERVIEW_PAGE_LAYOUT);
    },
    visibilityOptions(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.PAGE_REFS_VISIBILITY);
    },
  },
  methods: {
    ...mapActions('cms_content_block/normal_content_block/page_reference', ['create', 'find', 'save']),
    ...mapActions('cms_content_page', ["searchPageAbstractLibraries"]),
    determineEnums(): EnumClassNameCmsEnum[] {
      return [
        // EnumClassNameCmsEnum.IMAGE_HEIGHT,
        EnumClassNameCmsEnum.OBJECT_FIT,
        EnumClassNameCmsEnum.OBJECT_HORIZONTAL_POSITION,
        EnumClassNameCmsEnum.OBJECT_VERTICAL_POSITION,
        EnumClassNameCmsEnum.ORDER_DIRECTION,
        EnumClassNameCmsEnum.OVERVIEW_PAGE_LAYOUT,
        EnumClassNameCmsEnum.PAGE_REFS_ORDER_FIELD,
        EnumClassNameCmsEnum.PAGE_REFS_VISIBILITY
      ];
    },
    async beforeReloadContent(): Promise<void> {
      await this.searchPageAbstractLibraries();
    },
    afterReloadContent(): void {
      if (this.contentBlock.page_type_code) {
        this.referenceType = PageReferenceTypeEnum.INTERNAL;
      } else if (this.contentBlock.page_abstr_lib_code) {
        this.referenceType = PageReferenceTypeEnum.EXTERNAL;
      } else {
        this.referenceType = PageReferenceTypeEnum.INTERNAL;
      }

      if (!this.contentBlock.order_by_fields) {
        this.contentBlock.order_by_fields = [];
      }

      if (typeof this.contentBlock.filter_show_on_homepage !== "boolean") {
        this.useFilterShowOnHomepage = false;
        this.contentBlock.filter_show_on_homepage = false;
      } else {
        this.useFilterShowOnHomepage = true;
      }

      // todo tmp fix until label functionality implemented
      if (this.contentBlock.filter_label && this.contentBlock.filter_label.length > 0) {
        this.filterLabel = this.contentBlock.filter_label[0];
      }
    },
    beforeSubmitData(): void {
      switch (this.referenceType) {
        case PageReferenceTypeEnum.INTERNAL:
          this.contentBlock.page_abstr_lib_code = null;
          break;
        case PageReferenceTypeEnum.EXTERNAL:
          this.contentBlock.page_type_code = null;

          this.contentBlock.filter_label = null;
          this.contentBlock.filter_show_on_homepage = null;
          break;
        default:
          throw [new DpException('Unrecognized reference type')];
      }

      if (
        (!this.contentBlock.page_abstr_lib_code && !this.contentBlock.page_type_code) ||
        (this.referenceType === PageReferenceTypeEnum.INTERNAL && !this.contentBlock.page_type_code) ||
        (this.referenceType === PageReferenceTypeEnum.EXTERNAL && !this.contentBlock.page_abstr_lib_code)
      ) {
        throw [new DpException(this.$t('Reference needs to be added.'))];
      }

      if (!this.useFilterShowOnHomepage) {
        this.contentBlock.filter_show_on_homepage = null;
      }

      // todo tmp fix until label functionality implemented
      if (this.filterLabel && this.filterLabel.length > 0) {
        this.contentBlock.filter_label = [this.filterLabel];
      } else {
        this.contentBlock.filter_label = undefined;
      }
    },
    afterSubmitData() {
      if (typeof this.contentBlock.filter_show_on_homepage !== "boolean") {
        this.useFilterShowOnHomepage = false;
        this.contentBlock.filter_show_on_homepage = false;
      }
    },
    getDefaultCbModel(): PageReferencesContentRestDtoModel {
      return PageReferencesContentRestDtoModel.createWithDefaults();
    },
    addOrderFilter(): void {
      this.contentBlock.order_by_fields?.push(JSON.parse(JSON.stringify(this.selectedOrderFilter)));

      this.onChange();
    },
    deleteOrderFilter(index: number): void {
      this.contentBlock.order_by_fields?.splice(index, 1);

      this.onChange();
    },
    moveOrderFilter(index: number, move_direction: MoveActionEnum): void {
      switch (move_direction) {
        case MoveActionEnum.UP:
          if (index !== 0) {
            this.contentBlock.order_by_fields?.splice(index - 1, 0, this.contentBlock.order_by_fields?.splice(index, 1)[0]);

            this.onChange();
          }
          break;
        case MoveActionEnum.DOWN:
          if (index - 1 !== this.contentBlock.order_by_fields?.length) {
            this.contentBlock.order_by_fields?.splice(index + 1, 0, this.contentBlock.order_by_fields?.splice(index, 1)[0]);

            this.onChange();
          }
          break;
      }
    },
    toggleReferenceType(reference_type: PageReferenceTypeEnum): void {
      this.referenceType = reference_type;

      this.onChange();
    },
    pasteCbMetadata(): void {
      // When calling clone method directly on object, method is not found. So this is a hacky workaround
      const cbClone = PageReferencesContentRestDtoModel.cloneFrom(this.contentBlock);

      cbClone.cloneMetadataFrom(this.getClipboardContentBlock(this.cbType) as PageReferencesContentRestDtoModel);
      this.contentBlock = cbClone;
    },
  }
});
