/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum VerticalImageShiftEnum {
    SHIFT_UP_L = 'SHIFT_UP_L',
    SHIFT_UP_M = 'SHIFT_UP_M',
    SHIFT_UP_S = 'SHIFT_UP_S',
    NO_SHIFT = 'NO_SHIFT',
    SHIFT_DOWN_S = 'SHIFT_DOWN_S',
    SHIFT_DOWN_M = 'SHIFT_DOWN_M',
    SHIFT_DOWN_L = 'SHIFT_DOWN_L',
}