
import {defineComponent} from 'vue'

export default defineComponent({
  name: "PimProductsOverviewLegend",
  props: {
    isForPharmacist: {
      type: Boolean,
      default: false,
      required: false
    }
  },

})
