export class AuthInfoModel {
    currentCustomerCode: string | null;
    user: object | null;
    accessToken: string | null;
    idToken: string | null;
    didAutoLogout: boolean;

    constructor(currentCustomerCode: string | null, user: object | null, accessToken: string | null, idToken: string | null, didAutoLogout: boolean) {
        this.currentCustomerCode = currentCustomerCode;
        this.user = user;
        this.accessToken = accessToken;
        this.idToken = idToken;
        this.didAutoLogout = didAutoLogout;
    }

    public static createWithDefaults(): AuthInfoModel {
        return new this(null, null, null, null, false);
    }
}
