export enum ContentBlockStructureEnum {
    ARTICLE_HEADING = 'ARTICLE_HEADING',
    BANNER = 'BANNER',
    BANNER_IN_SLOT = 'BANNER_IN_SLOT',
    BANNER_SLOT = 'BANNER_SLOT',
    BUTTON = 'BUTTON',
    CALL_TO_ACTION = 'CALL_TO_ACTION',
    CONTACT_FORM = 'CONTACT_FORM',
    DOCUMENT = 'DOCUMENT',
    HTML = 'HTML',
    IMAGE = 'IMAGE',
    PAGE_REFERENCES = 'PAGE_REFERENCES',
    PAGE_TITLE = 'PAGE_TITLE',
    PHARMACY_INFO = 'PHARMACY_INFO',
    PRODUCT_FILTER_AS_GRID = 'PRODUCT_FILTER_AS_GRID',
    PRODUCT_FILTER_AS_SLIDER = 'PRODUCT_FILTER_AS_SLIDER',
    ROW_SEPARATOR = 'ROW_SEPARATOR',
    SYMBOL = 'SYMBOL',
    TEAM_INFO = 'TEAM_INFO',
    TEXT = 'TEXT',
    TEXT_CARD = 'TEXT_CARD',
    TEXT_WITH_IMAGE = 'TEXT_WITH_IMAGE',
    TEXT_WITH_SYMBOL = 'TEXT_WITH_SYMBOL',
    TEXT_WITH_TITLE = 'TEXT_WITH_TITLE',
}
