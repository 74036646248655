/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum BorderTypeEnum {
    NONE = 'NONE',
    SHADOW = 'SHADOW',
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
    GREY = 'GREY',
}