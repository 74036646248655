import {CodeDescriptionRestDto, LaboProductInfoRestDto} from "@/api/pharma-pim-labo/models";

export class LaboProductInfoRestDtoModel implements LaboProductInfoRestDto {
    cnk_code: number;
    name: string;
    // todo fix api because main_image_url is multilingual field
    main_image_url?: string | null;
    is_commercially_available?: boolean | null;
    is_image_available?: boolean | null;
    is_medicine?: boolean | null;
    is_medical_aid?: boolean | null;
    is_prescription_required?: boolean | null;
    usage?: CodeDescriptionRestDto | null;
    brand?: CodeDescriptionRestDto | null;

    constructor(cnk_code: number, name: string) {
        this.cnk_code = cnk_code;
        this.name = name;
    }

    public static createWithDefaults(): LaboProductInfoRestDtoModel {
        return new this(999999, '');
    }
}
