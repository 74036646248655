
import {defineComponent} from "vue";

export default defineComponent({
  name: "BaseContainer",
  props: {
    containerFluid: {
      type: Boolean,
      required: false,
      default: false,
    },
    positionCenter: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasBuildStatusOnPage: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  computed: {
    containerClass(): string {
      if (this.containerFluid) {
        return 'container-fluid';
      }
      return 'container';
    },
    positionClasses(): string {
      if (this.positionCenter) {
        return 'm-auto';
      }
      return 'ms-0 ps-4';
    }
  }
});
