/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */

import {ContentBlockMetadataRestDto} from ".";
import {MultilingualFieldRestDto} from ".";
import {MultilingualMax500FieldRestDto} from ".";
import {ButtonLayoutEnum} from "@/api/enums/button-layout-enum";
import {StatusEnum} from "@/api/enums/status-enum";

/**
 * @export
 * @interface DocumentContentRestDto
 */
export interface DocumentContentRestDto {
    /**
     * The status of this content block.
     * @type {StatusEnum}
     * @memberof DocumentContentRestDto
     */
    status_code: StatusEnum;

    /**
     * The sorting value of this content block.
     * @type {number|null}
     * @memberof DocumentContentRestDto
     */
    sort_weight?: number|null;

    /**
     * 
     * @type {MultilingualMax500FieldRestDto|null}
     * @memberof DocumentContentRestDto
     */
    title?: MultilingualMax500FieldRestDto|null;

    /**
     * 
     * @type {MultilingualFieldRestDto|null}
     * @memberof DocumentContentRestDto
     */
    content?: MultilingualFieldRestDto|null;

    /**
     * The key with which the document can be retrieved.
     * @type {string}
     * @memberof DocumentContentRestDto
     */
    document_key: string;

    /**
     * The text of the download butten/link.
     * @type {string|null}
     * @memberof DocumentContentRestDto
     */
    button_text?: string|null;

    /**
     * The specifies layout of this button.
     * @type {ButtonLayoutEnum|null}
     * @memberof DocumentContentRestDto
     */
    button_layout_code?: ButtonLayoutEnum|null;

    /**
     * If true, the details like file name, file size will be shown.
     * @type {boolean|null}
     * @memberof DocumentContentRestDto
     */
    is_show_file_details?: boolean|null;

    /**
     * 
     * @type {ContentBlockMetadataRestDto|null}
     * @memberof DocumentContentRestDto
     */
    cb_metadata?: ContentBlockMetadataRestDto|null;

}

/**
 * @export
 * @function cloneDocumentContentRestDtoMetaData
 */
export function cloneDocumentContentRestDtoMetaData(dto: DocumentContentRestDto, source: DocumentContentRestDto): void {
    dto.button_layout_code = source.button_layout_code;
    dto.is_show_file_details = source.is_show_file_details;
}
