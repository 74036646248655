import {ProductFilterRestDto} from "@/api/pharma-cpc-mgmt/models";
import {
    GlobalFilterExcludeConditionsRestDtoModel
} from "@/models/api/pharma-cpc-mgmt/GlobalFilterExcludeConditionsRestDtoModel";
import {ProductFilterConditionRestDtoModel} from "@/models/api/pharma-cpc-mgmt/ProductFilterConditionRestDtoModel";

export class ProductFilterRestDtoModel implements ProductFilterRestDto {
    code?: string | null;
    name?: string | null;
    filter_conditions: ProductFilterConditionRestDtoModel[];
    global_exclude_conditions?: GlobalFilterExcludeConditionsRestDtoModel|null;
    global_included_cnk_codes?: string[]|null;

    constructor() {
        this.filter_conditions = [];
    }

    public static createWithDefaults(): ProductFilterRestDtoModel {
        return new this();
    }
}
