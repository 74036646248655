import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mb-4" }
const _hoisted_3 = { class: "row align-items-center" }
const _hoisted_4 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_InputTextEditor = _resolveComponent("InputTextEditor")!
  const _component_dp_form = _resolveComponent("dp-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseSpinner, {
      spin: !this.isReady
    }, null, 8, ["spin"]),
    _withDirectives(_createVNode(_component_dp_form, {
      as: "form",
      onSubmit: _ctx.submitData,
      novalidate: ""
    }, {
      default: _withCtx(({ errors }) => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_InputTextEditor, {
                  modelValue: this.contentBlock.content.nl,
                  "onUpdate:modelValue": [
                    _cache[0] || (_cache[0] = ($event: any) => ((this.contentBlock.content.nl) = $event)),
                    this.onChange
                  ],
                  label: _ctx.$t('content'),
                  name: "content",
                  errors: errors
                }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "errors"])
              ])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["onSubmit"]), [
      [_vShow, this.isReady]
    ])
  ], 64))
}