
import store from "@/store";
import {Exception} from "@/api/interfaces";
import {defineComponent} from "vue";
import {mapGetters} from "vuex";
import {OpeningHoursForDayRestDto, OpeningHoursForWeekRestDto} from "@/api/pharma-cms-pharmacy/models";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";

export default defineComponent({
  name: "StandardOpeningHours",
  components: {BaseTitle},
  data: () => ({
    error: null,
    exceptions: [] as Exception[],
    isReady: false as Boolean,
    savedSuccessfully: false as Boolean,
    pharmacyCode: '' as String,
    initialOpeningHoursWeek: {} as OpeningHoursForWeekRestDto,
    standardOpeningHoursWeek: {
      monday: {
        open: false,
        hours1_from: null,
        hours1_till: null,
        hours2_from: null,
        hours2_till: null,
        hours3_from: null,
        hours3_till: null,
      },
      tuesday: {
        open: false,
        hours1_from: null,
        hours1_till: null,
        hours2_from: null,
        hours2_till: null,
        hours3_from: null,
        hours3_till: null,
      },
      wednesday: {
        open: false,
        hours1_from: null,
        hours1_till: null,
        hours2_from: null,
        hours2_till: null,
        hours3_from: null,
        hours3_till: null,
      },
      thursday: {
        open: false,
        hours1_from: null,
        hours1_till: null,
        hours2_from: null,
        hours2_till: null,
        hours3_from: null,
        hours3_till: null,
      },
      friday: {
        open: false,
        hours1_from: null,
        hours1_till: null,
        hours2_from: null,
        hours2_till: null,
        hours3_from: null,
        hours3_till: null,
      },
      saturday: {
        open: false,
        hours1_from: null,
        hours1_till: null,
        hours2_from: null,
        hours2_till: null,
        hours3_from: null,
        hours3_till: null,
      },
      sunday: {
        open: false,
        hours1_from: null,
        hours1_till: null,
        hours2_from: null,
        hours2_till: null,
        hours3_from: null,
        hours3_till: null,
      },
    } as OpeningHoursForWeekRestDto,
  }),
  computed: {
    ...mapGetters('cms_pharmacy', ['getStandardOpeningHours']),
    ...mapState(useCustomerStore, ["getPharmacyCode"]),
    inputTimeRulesFromTo() {
      return (openingHoursDayFrom: any) => (openingHoursDayFrom === null || openingHoursDayFrom.length === 0) ? '' : 'required';
    },
    inputTimeRulesOpenClosed() {
      return (openingHoursDayOpen: boolean) => openingHoursDayOpen ? 'required' : '';
    },
  },
  mounted() {
    this.reloadContent();
  },
  methods: {
    toggleDayTimeValues(day: keyof OpeningHoursForWeekRestDto) {
      if (this.standardOpeningHoursWeek[day].open) {
        this.initialOpeningHoursWeek[day].open = true;
        this.standardOpeningHoursWeek[day] = JSON.parse(JSON.stringify(this.initialOpeningHoursWeek[day]));
      } else {
        this.standardOpeningHoursWeek[day] = {
          open: false,
          hours1_from: null,
          hours1_till: null,
          hours2_from: null,
          hours2_till: null,
          hours3_from: null,
          hours3_till: null,
        } as OpeningHoursForDayRestDto;
      }
    },
    submitData() {
      this.saveStandardOpeningHours();
    },
    async reloadContent() {
      try {
        this.isReady = false;

        this.pharmacyCode = this.getPharmacyCode;
        await this.searchStandardOpeningHours();
        this.standardOpeningHoursWeek = this.getStandardOpeningHours;
        this.initialOpeningHoursWeek = JSON.parse(JSON.stringify(this.standardOpeningHoursWeek));

        this.isReady = true;
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async searchStandardOpeningHours() {
      try {
        await store.dispatch('cms_pharmacy/searchStandardOpeningHours', this.pharmacyCode);
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async saveStandardOpeningHours() {
      try {
        const payload = {
          body: this.standardOpeningHoursWeek,
          pharmacy_code: this.pharmacyCode,
        }
        await store.dispatch('cms_pharmacy/saveStandardOpeningHours', payload);

        await this.reloadContent();

        this.savedSuccessfully = true;
        setTimeout(() => this.savedSuccessfully = false, 2000);
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
  },
});
