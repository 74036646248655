import ButtonModule from "@/store/pharma-cms-content-block/normal-content-block/button";
import ContactFormModule from "@/store/pharma-cms-content-block/normal-content-block/contact_form";
import DocumentModule from "@/store/pharma-cms-content-block/normal-content-block/document";
import HtmlModule from "@/store/pharma-cms-content-block/normal-content-block/html";
import ImageModule from "@/store/pharma-cms-content-block/normal-content-block/image";
import PageReferenceModule from "@/store/pharma-cms-content-block/normal-content-block/page_reference";
import PharmacyInfoModule from "@/store/pharma-cms-content-block/normal-content-block/pharmacy_info";
import ProductFilterModule from "@/store/pharma-cms-content-block/normal-content-block/product_filter";
import RowSeparatorModule from "@/store/pharma-cms-content-block/normal-content-block/row_separator";
import SymbolModule from "@/store/pharma-cms-content-block/normal-content-block/symbol";
import TeamInfoModule from "@/store/pharma-cms-content-block/normal-content-block/team_info";
import TextModule from "@/store/pharma-cms-content-block/normal-content-block/text";
import TextWithImageModule from "@/store/pharma-cms-content-block/normal-content-block/text_with_image";
import TextWithSymbolModule from "@/store/pharma-cms-content-block/normal-content-block/text_with_symbol";
import TextWithTitleModule from "@/store/pharma-cms-content-block/normal-content-block/text_with_title";

export default {
    namespaced: true,
    modules: {
        button: ButtonModule,
        contact_form: ContactFormModule,
        document: DocumentModule,
        html: HtmlModule,
        image: ImageModule,
        page_reference: PageReferenceModule,
        pharmacy_info: PharmacyInfoModule,
        product_filter: ProductFilterModule,
        row_separator: RowSeparatorModule,
        symbol: SymbolModule,
        team_info: TeamInfoModule,
        text: TextModule,
        text_with_image: TextWithImageModule,
        text_with_symbol: TextWithSymbolModule,
        text_with_title: TextWithTitleModule,
    },
}
