import {PageReferenceRestDto} from "@/api/pharma-cms-menu/models";
import {TargetEnum} from "@/api/enums/target-enum";
import {ContentPageReferenceRestDtoModel} from "@/models/api/pharma-cms-menu/ContentPageReferenceRestDtoModel";

export class PageReferenceRestDtoModel implements PageReferenceRestDto {
    internal_page_reference?: ContentPageReferenceRestDtoModel | null;
    external_url?: string | null;
    target?: TargetEnum | null;

    constructor(internal_page_reference: ContentPageReferenceRestDtoModel | null, external_url: string | null, target: TargetEnum | null) {
        this.internal_page_reference = internal_page_reference;
        this.external_url = external_url;
        this.target = target;
    }

    public static createWithDefaults(): PageReferenceRestDtoModel {
        return new this(ContentPageReferenceRestDtoModel.createWithDefaults(), null, TargetEnum.SELF);
    }
}
