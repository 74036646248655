
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {Exception} from "@/api/interfaces";
import {CatalogBuildStatusRestDto} from "@/api/pharma-cpc-mgmt/models";

export default defineComponent({
  name: "CatalogBuildStatus",
  data: () => ({
    exceptions: [] as Exception[],
    isReady: false as boolean,
    lastRetrievedTimeStamp: '' as string,
  }),
  computed: {
    ...mapGetters('cpc_mgmt', ['getCatalogBuildStatus']),
    catalogusBuildStatus(): CatalogBuildStatusRestDto {
      return this.getCatalogBuildStatus;
    },
    buildStatusClass(): Object {
      switch (this.catalogusBuildStatus.status.code) {
        case 'NEW':
          return 'bg-info';
        case 'BUILD_REQUESTED':
          return 'bg-warning';
        case 'BUILDING':
          return 'bg-warning';
        case 'BUILT':
          return 'bg-success';
        case 'BUILD_FAILED':
          return 'bg-danger';
        default:
          throw new Error('Unsupported build status to generate content: ' + this.catalogusBuildStatus.status);
      }
    },
    locale(): string {
      return this.$i18n.locale;
    },
    synchonizeCatalogEnabled(): Object {
      return this.catalogusBuildStatus.status.code != 'BUILD_REQUESTED';
    },
    showEndedOn(): Object {
      return this.catalogusBuildStatus.status.code != 'BUILD_REQUESTED' && this.catalogusBuildStatus.status.code != 'BUILDING' && this.catalogusBuildStatus.status.code != 'NEW';
    },
    buildStatusTimeStamp(): string {
      const statusTimestamp = this.catalogusBuildStatus.status_timestamp;
      if (! statusTimestamp) {
        return '';
      }

      return new Intl.DateTimeFormat(this.locale, {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric'
      }).format(new Date(statusTimestamp)).toString();
    }
  },
  mounted() {
    this.reloadContent();
  },
  methods: {
    ...mapActions('cpc_mgmt', ['searchCatalogBuildStatus', 'buildCatalog']),
    async reloadContent() {
      this.isReady = false;

      try {
        await this.doReloadContent();
      } catch (exceptions: any) {
        this.isReady = true;
        this.exceptions = exceptions;
      }

      this.lastRetrievedTimeStamp = new Intl.DateTimeFormat(this.locale, {
        hour: 'numeric', minute: 'numeric', second: 'numeric'
      }).format(new Date()).toString();

      this.isReady = true;
    },
    async syncCatalog() {
      this.isReady = false;
      try {
        await this.buildCatalog();
        await this.doReloadContent();
      } catch (exceptions: any) {
        this.isReady = true;
        this.exceptions = exceptions;
      }
      this.isReady = true;
    },
    async doReloadContent() {
      await this.searchCatalogBuildStatus();

      this.lastRetrievedTimeStamp = new Intl.DateTimeFormat(this.$i18n.locale, {
        hour: 'numeric', minute: 'numeric', second: 'numeric'
      }).format(new Date()).toString();
    }
  }
});
