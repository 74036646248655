
import {Exception} from '@/api/interfaces';
import {defineComponent} from "vue";
import {
  ButtonContentRestDto,
  CodeDescriptionRestDto, SimpleButtonContentRestDto,
} from "@/api/pharma-cms-content-block/models";
import {ExtLinkTargetEnum} from "@/api/enums/ext-link-target-enum";
import {StatusEnum} from "@/api/enums/status-enum";
import {mapActions, mapGetters} from 'vuex';
import CbForm from "@/components/layouts/content-blocks/forms/CbForm.vue";

export default defineComponent({
  name: "CbFormButton",
  components: {CbForm},
  props: {
    canCopyFromLibrary: {
      type: Boolean,
      required: false,
      default: false,
    },
    canFallback: {
      type: Boolean,
      required: false,
      default: false,
    },
    canRestoreDefaultValues: {
      type: Boolean,
      required: false,
      default: false,
    },
    cbId: {
      type: String,
      required: false,
    },
    cbType: {
      type: String,
      required: true
    },
    contentLibraryCode: {
      type: String,
      required: false,
    },
    hasTarget: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasText: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLibrary: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNotRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPublishable: {
      type: Boolean,
      required: false,
      default: false,
    },
    mode: {
      type: String,
      required: true,
    },
    returnRoute: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    description2: {
      type: String,
      required: false,
    },
    description3: {
      type: String,
      required: false,
    },
    description4: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      error: null,
      exceptions: [] as Exception[],
      isReady: false as Boolean,
      savedSuccessfully: false as Boolean,
      extLinkOptions: [
        {code: ExtLinkTargetEnum.BLANK, description: this.$t('newWindow')},
        {code: ExtLinkTargetEnum.SELF, description: this.$t('currentWindow')},
      ] as CodeDescriptionRestDto[],
      statusOptions: [
        {code: StatusEnum.PUBLISHED, description: this.$t('published')},
        {code: StatusEnum.NOT_PUBLISHED, description: this.$t('notPublished')},
      ] as CodeDescriptionRestDto[]
    };
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cms_content_block', [
      'getSimpleContentBlockButton',
      'getNormalContentBlockButton',
    ]),
    contentBlock(): ButtonContentRestDto | SimpleButtonContentRestDto {
      //todo integrate when lib needed
      // if (this.isLibrary) {
      //   return this.getNormalContentBlockButton(this.cbType) as ButtonContentRestDto;
      // } else {
      return this.getSimpleContentBlockButton(this.cbType) as SimpleButtonContentRestDto;
      // }
    },
    isButton(): Boolean {
      return this.hasTarget && this.hasText;
    },
    isCopiedContent(): Boolean {
      //TODO: create logic for determining whether cb is copied or not
      return false;
    },
  },
  methods: {
    ...mapActions('cms_content_block', [
      'clearNormalContentBlock',
      'clearSimpleContentBlock',
      'searchSimpleContentBlockButton',
      'searchStandardSimpleContentBlockButton',
      'saveSimpleContentBlockButton',
    ]),
    copiedLibContentBlock() {
      this.reloadContent().then(() => {
        this.savedSuccessfully = true;
        setTimeout(() => this.savedSuccessfully = false, 2000);
      });
    },
    async submitData() {
      this.exceptions = [];

      try {
        if (this.isLibrary) {
          if (this.mode === 'new') {
            //TODO: integrate createLibContentBlockButton when needed
          } else {
            //TODO: integrate saveNormalContentBlockButton when needed
          }
        } else {
          await this.saveSimpleContentBlockButton(this.cbType);
        }

        await this.afterSave();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async reloadContent() {
      this.isReady = false;
      this.exceptions = [];

      try {
        if (this.isLibrary) {
          if (this.mode === 'new') {
            this.clearNormalContentBlock(this.cbType);
          } else {
            //TODO: integrate searchNormalContentBlockButton when needed
          }
        } else {
          await this.searchSimpleContentBlockButton({
            type: this.cbType,
            fallback_allowed: this.canFallback,
          });
        }
      } catch (exceptions: any) {
        this.exceptions = exceptions;
        this.isReady = true;
      }

      this.isReady = true;
    },
    async restoreDefaultValues() {
      //todo integrate when needed
      // this.exceptions = [];
      //
      // try {
      //   await this.searchStandardSimpleContentBlockText(this.cbType);
      // } catch (exceptions: any) {
      //   this.exceptions = exceptions;
      // }
    },
    async afterSave() {
      if (this.returnRoute) {
        await this.$router.push({name: this.returnRoute});
      } else {
        this.reloadContent().then(() => {
          this.savedSuccessfully = true;
          setTimeout(() => this.savedSuccessfully = false, 2000);
        });
      }
    },
  }
});
