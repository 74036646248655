/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum VisibilityRuleTypeEnum {
    VISIBLE_WITHIN_PERIOD = 'VISIBLE_WITHIN_PERIOD',
    HIDE = 'HIDE',
    HIDE_WHEN_NOT_LOGGED_IN = 'HIDE_WHEN_NOT_LOGGED_IN',
    PRODUCT_FILTER_WITH_PRODUCTS = 'PRODUCT_FILTER_WITH_PRODUCTS',
    PRODUCT_FILTER_NO_PRODUCTS = 'PRODUCT_FILTER_NO_PRODUCTS',
}