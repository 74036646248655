
import {defineComponent} from "vue";
import {CodeDescriptionRestDto, MultilingualProductPropertyRestDto} from "@/api/pharma-cpc-product-mgmt/models";
import {mapGetters} from "vuex";
import {PRODUCT_PROPERTY_TYPE_CODES_ENUM} from "@/store/pharma-cpc-product-mgmt/middleware";
import {PropType} from "vue/dist/vue";

export default defineComponent({
  name: "CustomerProductPropertiesItem",
  props: {
    productProperty: {
      type: Object as PropType<MultilingualProductPropertyRestDto>,
      required: true,
    },
  },
  computed: {
    ...mapGetters('cpc_product_mgmt', ['getCustomerProductProperty']),
    productPropertyComputed() : MultilingualProductPropertyRestDto {
      return this.productProperty;
    },
    productPropertyType(): CodeDescriptionRestDto | undefined {
      return PRODUCT_PROPERTY_TYPE_CODES_ENUM
          .find((productPropertyType: CodeDescriptionRestDto) => productPropertyType.code === this.productProperty.property_type_code);
    },
  },
});
