/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum HorizontalImageShiftEnum {
    SHIFT_LEFT_L = 'SHIFT_LEFT_L',
    SHIFT_LEFT_M = 'SHIFT_LEFT_M',
    SHIFT_LEFT_S = 'SHIFT_LEFT_S',
    NO_SHIFT = 'NO_SHIFT',
    SHIFT_RIGHT_S = 'SHIFT_RIGHT_S',
    SHIFT_RIGHT_M = 'SHIFT_RIGHT_M',
    SHIFT_RIGHT_L = 'SHIFT_RIGHT_L',
}