import {CodeDescriptionRestDto, CustomerProductRestDto} from "@/api/pharma-cpc-product-mgmt/models";
import {customProductCodeIsValid, productCodeIsValid} from "@/helpers/functions/products";
import {DpException} from "@/exception/DpException";

const PRODUCT_PROPERTY_TYPE_CODES_ENUM = [
    {
        code: "APPLICATION",
        description: "application"
    },
    {
        code: "COMPOSITION",
        description: "composition"
    },
    {
        code: "DOSAGE",
        description: "dosage"
    },
    {
        code: "PRESERVATION",
        description: "preservation"
    },
    {
        code: "PROPERTIES",
        description: "properties"
    },
    {
        code: "USAGE",
        description: "usage"
    },
] as CodeDescriptionRestDto[];

const PIM_NAMED_FRAMENENTS_CODES_ENUM = [
    {
        code: "DESCRIPTION",
        description: "description"
    },
    {
        code: "APPLICATION",
        description: "application"
    },
    {
        code: "COMPOSITION",
        description: "composition"
    },
    {
        code: "USAGE",
        description: "usage"
    },
    {
        code: "INDICATION",
        description: "indication"
    },
    {
        code: "CONTRA_INDICATION",
        description: "contraIndication"
    },
    {
        code: "PROPERTIES",
        description: "properties"
    },
    {
        code: "PRESERVATION",
        description: "preservation"
    },
    {
        code: "DOSAGE",
        description: "dosage"
    },
    {
        code: "WARNING",
        description: "warning"
    },
] as CodeDescriptionRestDto[];




function validateCustomerProduct(customer_product: CustomerProductRestDto) {
    if (!productCodeIsValid(customer_product.cnk_code)) {
         //TODO: ASK proper vue error handling
         //TODO implement i18n in store
         // throw [new DpException(useI18n().t('exceptionOnlyNumbersCnk')]);
         throw [new DpException('Een CNK code mag enkel getallen bevatten.')];
     }

     if (!customProductCodeIsValid(customer_product.cnk_code)) {
         //TODO: ASK proper vue error handling
         //TODO implement i18n in store
         // throw [new DpException(useI18n().t('exceptionInvalidCustomCnk')]);
         throw [new DpException('Een eigen CNK code dient te beginnen met "9" of "09".')];
     }

}



export {
    PRODUCT_PROPERTY_TYPE_CODES_ENUM,
    PIM_NAMED_FRAMENENTS_CODES_ENUM,
    validateCustomerProduct
};
