/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Customer-Product-Catalog
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { IdResponseRestDto } from '../models';
import { StarterBrandOverviewRestDto } from '../models';
import { StarterBrandRestDto } from '../models';
import { StarterDiscountRestDto } from '../models';
/**
 * StarterSettingsAPIApi - axios parameter creator
 * @export
 */
export const StarterSettingsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CPC.CM.07 Add a brand to the productselection of the Starter catalog.
         * @param {StarterBrandRestDto} body 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBrandToStarterProductSelection: async (body: StarterBrandRestDto, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addBrandToStarterProductSelection.');
            }
            const localVarPath = `/customer_mgmt/v1/catalog/product_selection/starter_brand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CPC.CM.09 Delete a brand from the productselection of the Starter catalog.
         * @param {string} brand_code Identifies the brand that is beïng removed from the selection.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBrandFromStarterProductSelection: async (brand_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'brand_code' is not null or undefined
            if (brand_code === null || brand_code === undefined) {
                throw new RequiredError('brand_code','Required parameter brand_code was null or undefined when calling deleteBrandFromStarterProductSelection.');
            }
            const localVarPath = `/customer_mgmt/v1/catalog/product_selection/starter_brand/{brand_code}`
                .replace(`{${"brand_code"}}`, encodeURIComponent(String(brand_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for fetching the product selection brands of the Starter catalog.
         * @summary REST.API.CPC.CM.08 List the brands of the productselection of the Starter catalog.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [search_name] Filter the results so that the given value occurs in the name of the brand. (Case insensitive)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBrandsOfStarterProductSelection: async (x_dp_customer_code?: string|null, search_name?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer_mgmt/v1/catalog/product_selection/starter_brand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!search_name || typeof(search_name) === "boolean") {
                localVarQueryParameter['search_name'] = search_name;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for fetching the discount percentage of the customer specific product catalog.
         * @summary REST.API.CPC.CM.06 Get discount percentage.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscountPercentage: async (x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer_mgmt/v1/catalog/starter_discount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for changing the discount percentage of a Starter product catalog.
         * @summary REST.API.CPC.CM.05 Update discount percentage.
         * @param {StarterDiscountRestDto} body 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStaterDiscount: async (body: StarterDiscountRestDto, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateStaterDiscount.');
            }
            const localVarPath = `/customer_mgmt/v1/catalog/starter_discount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StarterSettingsAPIApi - functional programming interface
 * @export
 */
export const StarterSettingsAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CPC.CM.07 Add a brand to the productselection of the Starter catalog.
         * @param {StarterBrandRestDto} body 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBrandToStarterProductSelection(_axios: AxiosInstance, body: StarterBrandRestDto, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResponseRestDto>> {
            const localVarAxiosArgs = await StarterSettingsAPIApiAxiosParamCreator(configuration).addBrandToStarterProductSelection(body, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CPC.CM.09 Delete a brand from the productselection of the Starter catalog.
         * @param {string} brand_code Identifies the brand that is beïng removed from the selection.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBrandFromStarterProductSelection(_axios: AxiosInstance, brand_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await StarterSettingsAPIApiAxiosParamCreator(configuration).deleteBrandFromStarterProductSelection(brand_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for fetching the product selection brands of the Starter catalog.
         * @summary REST.API.CPC.CM.08 List the brands of the productselection of the Starter catalog.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [search_name] Filter the results so that the given value occurs in the name of the brand. (Case insensitive)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findBrandsOfStarterProductSelection(_axios: AxiosInstance, x_dp_customer_code?: string|null, search_name?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StarterBrandOverviewRestDto>> {
            const localVarAxiosArgs = await StarterSettingsAPIApiAxiosParamCreator(configuration).findBrandsOfStarterProductSelection(x_dp_customer_code, search_name, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for fetching the discount percentage of the customer specific product catalog.
         * @summary REST.API.CPC.CM.06 Get discount percentage.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDiscountPercentage(_axios: AxiosInstance, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StarterDiscountRestDto>> {
            const localVarAxiosArgs = await StarterSettingsAPIApiAxiosParamCreator(configuration).getDiscountPercentage(x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for changing the discount percentage of a Starter product catalog.
         * @summary REST.API.CPC.CM.05 Update discount percentage.
         * @param {StarterDiscountRestDto} body 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStaterDiscount(_axios: AxiosInstance, body: StarterDiscountRestDto, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await StarterSettingsAPIApiAxiosParamCreator(configuration).updateStaterDiscount(body, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StarterSettingsAPIApi - object-oriented interface
 * @export
 * @class StarterSettingsAPIApi
 * @extends {BaseAPI}
 */
export class StarterSettingsAPIApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.CPC.CM.07 Add a brand to the productselection of the Starter catalog.
     * @param {StarterBrandRestDto} body 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StarterSettingsAPIApi
     */
     public addBrandToStarterProductSelection(body: StarterBrandRestDto, x_dp_customer_code?: string|null, options?: any) {
        return StarterSettingsAPIApiFp(this.configuration).addBrandToStarterProductSelection(this.axios, body, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CPC.CM.09 Delete a brand from the productselection of the Starter catalog.
     * @param {string} brand_code Identifies the brand that is beïng removed from the selection.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StarterSettingsAPIApi
     */
     public deleteBrandFromStarterProductSelection(brand_code: string, x_dp_customer_code?: string|null, options?: any) {
        return StarterSettingsAPIApiFp(this.configuration).deleteBrandFromStarterProductSelection(this.axios, brand_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for fetching the product selection brands of the Starter catalog.
     * @summary REST.API.CPC.CM.08 List the brands of the productselection of the Starter catalog.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [search_name] Filter the results so that the given value occurs in the name of the brand. (Case insensitive)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StarterSettingsAPIApi
     */
     public findBrandsOfStarterProductSelection(x_dp_customer_code?: string|null, search_name?: string|null, options?: any) {
        return StarterSettingsAPIApiFp(this.configuration).findBrandsOfStarterProductSelection(this.axios, x_dp_customer_code, search_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for fetching the discount percentage of the customer specific product catalog.
     * @summary REST.API.CPC.CM.06 Get discount percentage.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StarterSettingsAPIApi
     */
     public getDiscountPercentage(x_dp_customer_code?: string|null, options?: any) {
        return StarterSettingsAPIApiFp(this.configuration).getDiscountPercentage(this.axios, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for changing the discount percentage of a Starter product catalog.
     * @summary REST.API.CPC.CM.05 Update discount percentage.
     * @param {StarterDiscountRestDto} body 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StarterSettingsAPIApi
     */
     public updateStaterDiscount(body: StarterDiscountRestDto, x_dp_customer_code?: string|null, options?: any) {
        return StarterSettingsAPIApiFp(this.configuration).updateStaterDiscount(this.axios, body, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
