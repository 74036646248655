import {RgbRestDtoModel} from "@/models/api/RgbRestDtoModel";
import {BaseColorRestDto} from "@/api/interfaces/base-color-rest-dto";

export class ColorRestDtoModel implements BaseColorRestDto {
    opacity: number;
    rgb: RgbRestDtoModel;

    constructor(opacity: number, rgb: RgbRestDtoModel) {
        this.opacity = opacity;
        this.rgb = rgb;
    }

    public static createWithDefaults(): ColorRestDtoModel {
        return new this(
            1,
            RgbRestDtoModel.createWithDefaults()
        );
    }

    public static cloneFrom(source: ColorRestDtoModel): ColorRestDtoModel {
        return new this(
            source.opacity,
            RgbRestDtoModel.cloneFrom(source.rgb)
        );
    }

}
