import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row",
  id: "closing-period-detail"
}
const _hoisted_2 = { class: "col-xxl-8" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col mb-4" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-6" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "d-flex align-items-center mt-3 pt-4 border-top border-2 border-light" }
const _hoisted_11 = {
  type: "submit",
  class: "btn btn-primary"
}
const _hoisted_12 = { class: "text-muted ms-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_alert_error = _resolveComponent("alert-error")!
  const _component_MultilingualSwitcher = _resolveComponent("MultilingualSwitcher")!
  const _component_i18n_input_text = _resolveComponent("i18n-input-text")!
  const _component_input_date = _resolveComponent("input-date")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_dp_form = _resolveComponent("dp-form")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      (this.isReady)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_BaseTitle, {
                title: _ctx.mode === 'edit' ? _ctx.$t('editClosingPeriod'): _ctx.$t('newClosingPeriod'),
                "help-text": _ctx.$t('closingPeriodCreateDescription'),
                "back-route": {name: 'closing-periods'}
              }, null, 8, ["title", "help-text"]),
              _createVNode(_component_alert_error, {
                exceptions: _ctx.exceptions,
                class: "mb-3"
              }, null, 8, ["exceptions"]),
              _createVNode(_component_dp_form, {
                as: "form",
                onSubmit: _ctx.submitData,
                novalidate: ""
              }, {
                default: _withCtx(({ errors }) => [
                  _createVNode(_component_MultilingualSwitcher, {
                    errors: errors,
                    "active-language": this.activeLanguage,
                    onUpdateActiveLanguage: _cache[0] || (_cache[0] = newValue => this.activeLanguage = newValue)
                  }, null, 8, ["errors", "active-language"]),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_i18n_input_text, {
                            label: _ctx.$t('name'),
                            "active-language": this.activeLanguage,
                            errors: errors,
                            data: _ctx.closingPeriod.name,
                            "onUpdate:data": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.closingPeriod.name) = $event)),
                            name: "name",
                            placeholder: "name",
                            rules: "required|max:100"
                          }, null, 8, ["label", "active-language", "errors", "data"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_input_date, {
                            label: _ctx.$t('begin'),
                            placeholder: "DD-MM-JJJJ",
                            name: "begin",
                            modelValue: _ctx.closingPeriod.begin,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.closingPeriod.begin) = $event)),
                            rules: "required|check_date:@begin,@end",
                            errors: errors,
                            onBlur: _ctx.copyToEndDate
                          }, null, 8, ["label", "modelValue", "errors", "onBlur"])
                        ]),
                        _createElementVNode("div", _hoisted_9, [
                          _createVNode(_component_input_date, {
                            label: _ctx.$t('end'),
                            placeholder: "DD-MM-JJJJ",
                            name: "end",
                            modelValue: _ctx.closingPeriod.end,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.closingPeriod.end) = $event)),
                            rules: "required|check_date:@begin,@end",
                            errors: errors
                          }, null, 8, ["label", "modelValue", "errors"])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("button", _hoisted_11, _toDisplayString(_ctx.$t('save')), 1),
                    _createVNode(_component_router_link, {
                      class: "ms-3 btn btn-outline-primary",
                      to: {name: 'closing-periods'}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('cachingDelayMinutes', [5])), 1)
                  ])
                ]),
                _: 1
              }, 8, ["onSubmit"])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}