
import {defineComponent} from "vue";
import {TextWithImageAbstractRestDto} from "@/api/pharma-cms-content-page/models";
import {stringHasContent} from "@/helpers/functions/string";
import {mapActions, mapGetters} from "vuex";

export default defineComponent({
  name: "CpsOverviewItemAbstract",
  emits: ["exceptions"],
  props: {
    cpAbstract: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      docType: 'PAGE_IMG' as string,
      signedUrl: null as string | null,
    }
  },
  mounted() {
    this.fetchSignedUrl();
  },
  computed: {
    ...mapGetters('cms_document', ['getPreviewDocument']),
    contentPageAbstract(): TextWithImageAbstractRestDto {
      return this.cpAbstract;
    },
    content(): string {
      if (this.contentPageAbstract.content && stringHasContent(this.contentPageAbstract.content)) {
        if (this.contentPageAbstract.content.length > 100) {
          return this.contentPageAbstract.content.substr(0, 100) + '...';
        }

        return this.contentPageAbstract.content;
      }

      return '';
    }
  },
  methods: {
    ...mapActions('cms_document', ['searchPreviewDocument']),
    async fetchSignedUrl(): Promise<void> {
      if (this.contentPageAbstract.image_url) {
        this.signedUrl = this.contentPageAbstract.image_url;
        return;
      }

      try {
        if (this.contentPageAbstract.image_key) {
          await this.searchPreviewDocument({
            document_type: this.docType,
            document_key: this.contentPageAbstract.image_key,
          });
          this.signedUrl = this.getPreviewDocument(this.docType).url;
        }
      } catch (exceptions: any) {
        this.$emit('exceptions', exceptions);
      }
    },
  }
});
