
import {defineComponent} from 'vue'

export default defineComponent({
  name: "TheSideBarSubMenuItem",
  props: {
    to: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
})
