import {ContentPageRowEndpointsApiService} from "@/main";
import {MoveActionEnum} from "@/models/enum/MoveActionEnum";

export default {
    state() {
        return {}
    },
    actions: {
        async copyContentPageRow(context: any, payload: any) {
            const page_id = payload.page_id as string;
            const row_id = payload.row_id as string;

            await ContentPageRowEndpointsApiService.copyContentPageRow(page_id, row_id);
        },
        async deleteContentPageRow(context: any, payload: any) {
            const page_id = payload.page_id as string;
            const row_id = payload.row_id as string;

            await ContentPageRowEndpointsApiService.deleteContentPageRow(page_id, row_id);
        },
        async moveContentPageRow(context: any, payload: any) {
            const page_id = payload.page_id as string;
            const row_id = payload.row_id as string;
            const move_action = payload.move_action as MoveActionEnum;

            switch (move_action) {
                case MoveActionEnum.TOP:
                    await ContentPageRowEndpointsApiService.moveContentPageRowToTop(page_id, row_id);
                    break;
                case MoveActionEnum.BOTTOM:
                    await ContentPageRowEndpointsApiService.moveContentPageRowToBottom(page_id, row_id);
                    break;
                case MoveActionEnum.UP:
                    await ContentPageRowEndpointsApiService.moveContentPageRowUp(page_id, row_id);
                    break;
                case MoveActionEnum.DOWN:
                    await ContentPageRowEndpointsApiService.moveContentPageRowDown(page_id, row_id);
                    break;
                default:
                    throw new Error('Unsupported move_action to call ' + move_action);
            }
        }
    },
    mutations: {},
    getters: {}
}
