/*
* Receives a date object
* And converts it to a date string with format YYYY-MM-DD
*/
function formatDateObjectToDateString(date: Date): string {
    const dd = date.getDate().toString().padStart(2, '0') as string;
    const mm = (date.getMonth() + 1).toString().padStart(2, '0') as string;
    const yyyy = date.getFullYear().toString() as string;

    return `${yyyy}-${mm}-${dd}`
}

/*
* Receives a date string in format YYYY-MM-DD
* And converts it to a date string with format DD-MM-YYYY
*/
function formatDateToLocalFormat(date: string): string {
    const dateParts = date.split('-', 3) as string[];

    return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
}


export {formatDateObjectToDateString, formatDateToLocalFormat};