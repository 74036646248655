import {FindNormalContentBlockPayload} from "@/models/payload/FindNormalContentBlockPayload";
import {ContentBlockEndpointsApiService} from "@/main";
import {SaveNormalContentBlockPayload} from "@/models/payload/SaveNormalContentBlockPayload";
import {CreateNormalContentBlockPayload} from "@/models/payload/CreateNormalContentBlockPayload";
import {ContactFormContentRestDtoModel} from "@/models/ContactFormContentRestDtoModel";

export default {
    namespaced: true,
    actions: {
        async create(context: any, payload: CreateNormalContentBlockPayload): Promise<string> {
            console.log('ContactForm::create()');
            const response = await ContentBlockEndpointsApiService.createNormalContentBlockContactForm(payload.cb as ContactFormContentRestDtoModel, payload.type);

            return response.data.id as string;
        },
        async find(context: any, payload: FindNormalContentBlockPayload): Promise<ContactFormContentRestDtoModel> {
            console.log('ContactForm::find()');
            const response = await ContentBlockEndpointsApiService.getNormalContentBlockContactForm(payload.type, payload.id);

            return response.data as ContactFormContentRestDtoModel;
        },
        async save(context: any, payload: SaveNormalContentBlockPayload): Promise<void> {
            console.log('ContactForm::save()');
            await ContentBlockEndpointsApiService.updateNormalContentBlockContactForm(payload.cb as ContactFormContentRestDtoModel, payload.type, payload.id);
        },
    }
};
