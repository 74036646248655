/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum BannerContentV2LinkTypeEnum {
    NO_LINK = 'NO_LINK',
    PRODUCT_FILTER = 'PRODUCT_FILTER',
    EXTERNAL_LINK = 'EXTERNAL_LINK',
    INTERNAL_PAGE_REF = 'INTERNAL_PAGE_REF',
}