import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  type: "submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertError = _resolveComponent("AlertError")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_dp_form = _resolveComponent("dp-form")!
  const _component_InputSelect = _resolveComponent("InputSelect")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AlertError, {
      exceptions: this.exceptions,
      class: "mb-4"
    }, null, 8, ["exceptions"]),
    _createVNode(_component_dp_form, {
      as: "form",
      novalidate: ""
    }, {
      default: _withCtx(({ errors }) => [
        _createVNode(_component_InputText, {
          modelValue: this.customerCriteria.search_name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.customerCriteria.search_name) = $event)),
          onInput: this.searchCustomers,
          label: `${_ctx.$t('searchCustomer')}...`,
          name: "search_customer",
          errors: errors
        }, null, 8, ["modelValue", "onInput", "label", "errors"])
      ]),
      _: 1
    }),
    _createVNode(_component_dp_form, {
      onSubmit: this.copyContentPageToCustomer,
      as: "form",
      novalidate: ""
    }, {
      default: _withCtx(({ errors }) => [
        _createVNode(_component_InputSelect, {
          modelValue: this.customerCodeCopyTo,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.customerCodeCopyTo) = $event)),
          options: this.customerOptions,
          label: this.$t('copyTo'),
          name: "copy_to",
          selectText: _ctx.$t('selectText'),
          rules: "required",
          errors: errors
        }, null, 8, ["modelValue", "options", "label", "selectText", "errors"]),
        _createElementVNode("button", _hoisted_1, _toDisplayString(_ctx.$t('copy')), 1)
      ]),
      _: 1
    }, 8, ["onSubmit"])
  ], 64))
}