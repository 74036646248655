import {Singleton} from "@/patterns/Singleton";
import {StorageInterface} from "@/models/facade/storage/StorageInterface";
import {LocalStorageKeyEnum} from "@/models/enum/LocalStorageKeyEnum";
import {SessionStorageKeyEnum} from "@/models/enum/SessionStorageKeyEnum";

export class LocalStorage extends Singleton implements StorageInterface {

    get(key: LocalStorageKeyEnum|SessionStorageKeyEnum, defaultValue?: any): any {
        return localStorage.getItem(key.toString()) || defaultValue;
    }

    remove(key: LocalStorageKeyEnum|SessionStorageKeyEnum): void {
        localStorage.removeItem(key.toString());
    }

    set(key: LocalStorageKeyEnum|SessionStorageKeyEnum, value: any): void {
        localStorage.setItem(key.toString(), value);
    }
}
