/*
* Receives a button object and checks if its content are filled in
*/
import {ButtonRestDto} from "@/api/pharma-cms-content-block/models";

function hasButton(button: ButtonRestDto | null | undefined): boolean {
    if (button) {
        const hasText = button.button_text?.nl && button.button_text.nl.length > 0 as boolean;
        const hasUrl = button.button_url?.nl && button.button_url.nl.length > 0 as boolean;
        const hasPageId = button.button_page_id?.nl && button.button_page_id.nl.length > 0 as boolean;

        return (hasText && (hasUrl || hasPageId)) as boolean;
    }
    return false;
}

function isValidButton(button: ButtonRestDto | null | undefined): boolean {
    if (button) {
        const validText = button.button_text?.nl && button.button_text.nl.length > 0 as boolean;
        const validUrl = button.button_url?.nl && button.button_url.nl.length > 0 as boolean;
        const validPageId = button.button_page_id?.nl && button.button_page_id.nl.length > 0 as boolean;

        return (validText && (validUrl || validPageId)) as boolean;
    }
    return false;
}

export {
    hasButton,
    isValidButton
};
