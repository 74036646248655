
import {defineComponent} from "vue";
import {formatDateToLocalFormat} from "@/helpers/functions/date-time";

export default defineComponent({
  name: "ReservationCampaignsOverviewItem",
  emits: ["deleteReservationCampaign"],
  props: {
    reservationCampaign: {
      type: Object,
      required: true,
    },
  },
  computed: {
    reservationCampaignPeriod(): string {
      if (this.reservationCampaign.begin_date && this.reservationCampaign.end_date) {
        return `${formatDateToLocalFormat(this.reservationCampaign.begin_date)} ${this.$t('till').toLowerCase()} ${formatDateToLocalFormat(this.reservationCampaign.end_date)}`;
      } else if (this.reservationCampaign.begin_date && !this.reservationCampaign.end_date) {
        return `${this.$t('asOf')} ${formatDateToLocalFormat(this.reservationCampaign.begin_date)}`;
      } else if (!this.reservationCampaign.begin_date && this.reservationCampaign.end_date) {
        return `${this.$t('till')} ${formatDateToLocalFormat(this.reservationCampaign.end_date)}`;
      }
      return this.$t('notSet');
    }
  },
});
