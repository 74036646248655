import {TruncateTextRestDto} from "@/api/pharma-cms-utility/models";

export class TruncateTextRestDtoModel implements TruncateTextRestDto {
    ellipsis?: string;
    exact?: boolean;
    html?: boolean;
    length: number;
    text: string;
    trim_width?: boolean;

    constructor(text: string, length: number) {
        this.text = text;
        this.length = length;
    }
}
