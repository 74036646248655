/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Pharmacy API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { IdResponseRestDto } from '../models';
import { OpeningHoursPeriodRestDto } from '../models';
import { PeriodOverviewRestDto } from '../models';
/**
 * OpeningInfoOpeningHoursPeriodsEndpointsApi - axios parameter creator
 * @export
 */
export const OpeningInfoOpeningHoursPeriodsEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.PH.OH.06 Create Opening Hours Period.
         * @param {OpeningHoursPeriodRestDto} body 
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Opening Hours Period belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOpeningHoursPeriod: async (body: OpeningHoursPeriodRestDto, pharmacy_code: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createOpeningHoursPeriod.');
            }
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling createOpeningHoursPeriod.');
            }
            const localVarPath = `/pharmacy/v1/pharmacy/{pharmacy_code}/opening_hours_period`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.OH.10 Delete Opening Hours Period.
         * @param {string} id The unique identifier of this Team Member.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Opening Hours Period belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context. &lt;BR&gt; &lt;b&gt;Note&lt;/b&gt;: DELETE is not allowed for Opening Hours Periods of type DEFAULT.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOpeningHoursPeriod: async (id: string, pharmacy_code: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOpeningHoursPeriod.');
            }
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling deleteOpeningHoursPeriod.');
            }
            const localVarPath = `/pharmacy/v1/pharmacy/{pharmacy_code}/opening_hours_period/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.OH.07 Find Opening Hours Periods.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Opening Hours Period belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_type_code] Filter the resuls so that they match the provided type.
         * @param {string} [search_name] Filter the results so that the given value occurs in the name of the period. (Case insensitive)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOpeningHoursPeriods: async (pharmacy_code: string, x_dp_customer_code?: string, filter_type_code?: string, search_name?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling findOpeningHoursPeriods.');
            }
            const localVarPath = `/pharmacy/v1/pharmacy/{pharmacy_code}/opening_hours_period`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_type_code || typeof(filter_type_code) === "boolean") {
                localVarQueryParameter['filter_type_code'] = filter_type_code;
            }

            if (!!search_name || typeof(search_name) === "boolean") {
                localVarQueryParameter['search_name'] = search_name;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.OH.09 Get Opening Hours Period.
         * @param {string} id The unique identifier of this Opening Hours Period.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Opening Hours Period belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpeningHoursPeriod: async (id: string, pharmacy_code: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOpeningHoursPeriod.');
            }
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling getOpeningHoursPeriod.');
            }
            const localVarPath = `/pharmacy/v1/pharmacy/{pharmacy_code}/opening_hours_period/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.OH.08 Update Opening Hours Period.
         * @param {OpeningHoursPeriodRestDto} body 
         * @param {string} id The unique identifier of this Opening Hours Period.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Opening Hours Period belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpeningHoursPeriod: async (body: OpeningHoursPeriodRestDto, id: string, pharmacy_code: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateOpeningHoursPeriod.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOpeningHoursPeriod.');
            }
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling updateOpeningHoursPeriod.');
            }
            const localVarPath = `/pharmacy/v1/pharmacy/{pharmacy_code}/opening_hours_period/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpeningInfoOpeningHoursPeriodsEndpointsApi - functional programming interface
 * @export
 */
export const OpeningInfoOpeningHoursPeriodsEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.PH.OH.06 Create Opening Hours Period.
         * @param {OpeningHoursPeriodRestDto} body 
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Opening Hours Period belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOpeningHoursPeriod(_axios: AxiosInstance, body: OpeningHoursPeriodRestDto, pharmacy_code: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResponseRestDto>> {
            const localVarAxiosArgs = await OpeningInfoOpeningHoursPeriodsEndpointsApiAxiosParamCreator(configuration).createOpeningHoursPeriod(body, pharmacy_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.OH.10 Delete Opening Hours Period.
         * @param {string} id The unique identifier of this Team Member.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Opening Hours Period belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context. &lt;BR&gt; &lt;b&gt;Note&lt;/b&gt;: DELETE is not allowed for Opening Hours Periods of type DEFAULT.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOpeningHoursPeriod(_axios: AxiosInstance, id: string, pharmacy_code: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OpeningInfoOpeningHoursPeriodsEndpointsApiAxiosParamCreator(configuration).deleteOpeningHoursPeriod(id, pharmacy_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.OH.07 Find Opening Hours Periods.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Opening Hours Period belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_type_code] Filter the resuls so that they match the provided type.
         * @param {string} [search_name] Filter the results so that the given value occurs in the name of the period. (Case insensitive)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOpeningHoursPeriods(_axios: AxiosInstance, pharmacy_code: string, x_dp_customer_code?: string, filter_type_code?: string, search_name?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PeriodOverviewRestDto>>> {
            const localVarAxiosArgs = await OpeningInfoOpeningHoursPeriodsEndpointsApiAxiosParamCreator(configuration).findOpeningHoursPeriods(pharmacy_code, x_dp_customer_code, filter_type_code, search_name, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.OH.09 Get Opening Hours Period.
         * @param {string} id The unique identifier of this Opening Hours Period.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Opening Hours Period belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOpeningHoursPeriod(_axios: AxiosInstance, id: string, pharmacy_code: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpeningHoursPeriodRestDto>> {
            const localVarAxiosArgs = await OpeningInfoOpeningHoursPeriodsEndpointsApiAxiosParamCreator(configuration).getOpeningHoursPeriod(id, pharmacy_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.OH.08 Update Opening Hours Period.
         * @param {OpeningHoursPeriodRestDto} body 
         * @param {string} id The unique identifier of this Opening Hours Period.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Opening Hours Period belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOpeningHoursPeriod(_axios: AxiosInstance, body: OpeningHoursPeriodRestDto, id: string, pharmacy_code: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OpeningInfoOpeningHoursPeriodsEndpointsApiAxiosParamCreator(configuration).updateOpeningHoursPeriod(body, id, pharmacy_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OpeningInfoOpeningHoursPeriodsEndpointsApi - object-oriented interface
 * @export
 * @class OpeningInfoOpeningHoursPeriodsEndpointsApi
 * @extends {BaseAPI}
 */
export class OpeningInfoOpeningHoursPeriodsEndpointsApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.CMS.PH.OH.06 Create Opening Hours Period.
     * @param {OpeningHoursPeriodRestDto} body 
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Opening Hours Period belongs.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpeningInfoOpeningHoursPeriodsEndpointsApi
     */
     public createOpeningHoursPeriod(body: OpeningHoursPeriodRestDto, pharmacy_code: string, x_dp_customer_code?: string, options?: any) {
        return OpeningInfoOpeningHoursPeriodsEndpointsApiFp(this.configuration).createOpeningHoursPeriod(this.axios, body, pharmacy_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.PH.OH.10 Delete Opening Hours Period.
     * @param {string} id The unique identifier of this Team Member.
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Opening Hours Period belongs.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context. &lt;BR&gt; &lt;b&gt;Note&lt;/b&gt;: DELETE is not allowed for Opening Hours Periods of type DEFAULT.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpeningInfoOpeningHoursPeriodsEndpointsApi
     */
     public deleteOpeningHoursPeriod(id: string, pharmacy_code: string, x_dp_customer_code?: string, options?: any) {
        return OpeningInfoOpeningHoursPeriodsEndpointsApiFp(this.configuration).deleteOpeningHoursPeriod(this.axios, id, pharmacy_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.PH.OH.07 Find Opening Hours Periods.
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Opening Hours Period belongs.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [filter_type_code] Filter the resuls so that they match the provided type.
     * @param {string} [search_name] Filter the results so that the given value occurs in the name of the period. (Case insensitive)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpeningInfoOpeningHoursPeriodsEndpointsApi
     */
     public findOpeningHoursPeriods(pharmacy_code: string, x_dp_customer_code?: string, filter_type_code?: string, search_name?: string, options?: any) {
        return OpeningInfoOpeningHoursPeriodsEndpointsApiFp(this.configuration).findOpeningHoursPeriods(this.axios, pharmacy_code, x_dp_customer_code, filter_type_code, search_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.PH.OH.09 Get Opening Hours Period.
     * @param {string} id The unique identifier of this Opening Hours Period.
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Opening Hours Period belongs.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpeningInfoOpeningHoursPeriodsEndpointsApi
     */
     public getOpeningHoursPeriod(id: string, pharmacy_code: string, x_dp_customer_code?: string, options?: any) {
        return OpeningInfoOpeningHoursPeriodsEndpointsApiFp(this.configuration).getOpeningHoursPeriod(this.axios, id, pharmacy_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.PH.OH.08 Update Opening Hours Period.
     * @param {OpeningHoursPeriodRestDto} body 
     * @param {string} id The unique identifier of this Opening Hours Period.
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Opening Hours Period belongs.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpeningInfoOpeningHoursPeriodsEndpointsApi
     */
     public updateOpeningHoursPeriod(body: OpeningHoursPeriodRestDto, id: string, pharmacy_code: string, x_dp_customer_code?: string, options?: any) {
        return OpeningInfoOpeningHoursPeriodsEndpointsApiFp(this.configuration).updateOpeningHoursPeriod(this.axios, body, id, pharmacy_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
