
import {Exception} from "@/api/interfaces";
import {defineComponent} from "vue";
import BaseContainer from "@/components/UI/Bootstrap/BaseContainer.vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {TeamMemberOverviewRestDtoModel} from "@/models/TeamMemberOverviewRestDtoModel";
import {TeamMemberRestService} from "@/services/rest/cms-pharmacy/TeamMemberRestService";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import ConfirmModal from "@/components/UI/ConfirmModal.vue";
import {useToast} from "vue-toastification";

export default defineComponent({
  name: "TeamMembersOverview",
  components: {BaseTitle, BaseContainer},
  data: () => ({
    isReady: false as boolean,
    exceptions: [] as Exception[],
    toaster: useToast(),

    teamMembers: [] as TeamMemberOverviewRestDtoModel[],
  }),
  mounted() {
    this.reloadContent();
  },
  methods: {
    async reloadContent() {
      this.isReady = false;
      this.exceptions = [];

      try {
        this.teamMembers = await TeamMemberRestService.getInstance().findTeamMembers();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      } finally {
        this.isReady = true;
      }
    },
    async deleteMember(id: string, firstName?: string, lastName?: string): Promise<void> {
      try {
        const {reveal, onConfirm} = createConfirmDialog(ConfirmModal, {
          title: 'Teamlid verwijderen',
          body: 'Bent u zeker dat u <strong>' + firstName  + ' ' + lastName + '</strong> wilt verwijderen?',
          confirmButtonText: 'Verwijderen',
          confirmButtonClass: 'btn-danger',
        });

        onConfirm(async () => {
          try {
            await TeamMemberRestService.getInstance().deleteTeamMember(id);
            this.toaster.success('Teamlid succesvol verwijderd.');
          } catch (exceptions: any) {
            this.exceptions = exceptions;
          }

          await this.reloadContent();
        });

        await reveal();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    }
  }
});
