import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7754396a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row row-product-property mb-3" }
const _hoisted_2 = { class: "col-6" }
const _hoisted_3 = { class: "d-flex align-items-center mb-1" }
const _hoisted_4 = ["for"]
const _hoisted_5 = ["name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("header", _hoisted_3, [
        _createElementVNode("label", {
          for: _ctx.productProperty.property_type_code,
          class: "fw-bold me-2"
        }, _toDisplayString(_ctx.$t(_ctx.productPropertyType.description)), 9, _hoisted_4)
      ]),
      _createElementVNode("main", null, [
        _withDirectives(_createElementVNode("textarea", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.productPropertyComputed.value.nl) = $event)),
          name: _ctx.productProperty.property_type_code,
          class: "w-100",
          rows: "5"
        }, null, 8, _hoisted_5), [
          [_vModelText, _ctx.productPropertyComputed.value.nl]
        ])
      ])
    ])
  ]))
}