import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/teamlid-placeholder.png'


const _withScopeId = n => (_pushScopeId("data-v-0756ff2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-file-container" }
const _hoisted_2 = {
  key: 0,
  class: "alert alert-danger my-4"
}
const _hoisted_3 = { class: "row justify-content-start align-items-center" }
const _hoisted_4 = { class: "col-auto upload-preview" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 2,
  src: _imports_0
}
const _hoisted_7 = { class: "col-6" }
const _hoisted_8 = ["for"]
const _hoisted_9 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_spinner = _resolveComponent("base-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      (_ctx.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.error), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.uploading)
            ? (_openBlock(), _createBlock(_component_base_spinner, { key: 0 }))
            : (_ctx.preview_url)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: _ctx.preview_url,
                  alt: "preview uploaded image"
                }, null, 8, _hoisted_5))
              : (_openBlock(), _createElementBlock("img", _hoisted_6))
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", {
            for: _ctx.id,
            class: "form-label"
          }, _toDisplayString(_ctx.label), 9, _hoisted_8),
          _createElementVNode("input", {
            ref: `fileupload-${_ctx.id}`,
            class: "form-control",
            type: "file",
            id: _ctx.id,
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChosenFile && _ctx.handleChosenFile(...args)))
          }, null, 40, _hoisted_9)
        ])
      ]),
      _createElementVNode("button", {
        class: "btn btn-outline-primary mt-4",
        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clear && _ctx.clear(...args)), ["prevent"]))
      }, _toDisplayString(_ctx.$t('delete')), 1)
    ])
  ]))
}