
import {defineComponent} from "vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {HeaderRestService} from "@/services/rest/cms-header/HeaderRestService";
import {HeaderOverviewRestDto} from "@/api/pharma-cms-header/models";
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {arrayHasContent} from "@/helpers/functions/arrays";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";

export default defineComponent({
  name: "HeaderOverview",
  components: {AlertError2, BaseTitle},
  data() {
    return {
      headerStacks: [] as HeaderOverviewRestDto[],
      headerStackOverviewUI: UIStateDto.createWithDefaults(),
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    hasHeaderStacks(): boolean {
      return arrayHasContent(this.headerStacks);
    }
  },
  methods: {
    async reloadContent(): Promise<void> {
      this.headerStackOverviewUI
        .clearError()
        .setNotReady();

      try {
        this.headerStacks = await HeaderRestService.getInstance()
          .findHeaderStackOverview();
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.headerStackOverviewUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.headerStackOverviewUI as UIStateDto))
          .catch(exceptions, true);
      } finally {
        this.headerStackOverviewUI.setReady();
      }
    }
  }
});
