/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */

import {BackgroundImageRestDto} from ".";
import {BorderRadiusRestDto} from ".";
import {ColorRestDto} from ".";
import {DeviceVisibilityRestDto} from ".";
import {BackgroundColorEnum} from "@/api/enums/background-color-enum";
import {ContainerTypeEnum} from "@/api/enums/container-type-enum";
import {HorizontalGutterEnum} from "@/api/enums/horizontal-gutter-enum";
import {ItemsHorizontalAlignmentEnum} from "@/api/enums/items-horizontal-alignment-enum";
import {ItemsVerticalAlignmentEnum} from "@/api/enums/items-vertical-alignment-enum";
import {MarginBottomEnum} from "@/api/enums/margin-bottom-enum";
import {MarginTopEnum} from "@/api/enums/margin-top-enum";
import {PaddingBottomEnum} from "@/api/enums/padding-bottom-enum";
import {PaddingTopEnum} from "@/api/enums/padding-top-enum";

/**
 * @export
 * @interface ContentPageRowRestDto
 */
export interface ContentPageRowRestDto {
    /**
     * To manipulate the vertical alignment of the columns in this row.
     * @type {ItemsVerticalAlignmentEnum}
     * @memberof ContentPageRowRestDto
     */
    items_vertical_alignment_code: ItemsVerticalAlignmentEnum;

    /**
     * To manipulate the vertical alignment of the columns in this row.
     * @type {ItemsHorizontalAlignmentEnum}
     * @memberof ContentPageRowRestDto
     */
    items_horizontal_alignment_code: ItemsHorizontalAlignmentEnum;

    /**
     * Specifies the top margin of this row.
     * @type {MarginTopEnum}
     * @memberof ContentPageRowRestDto
     */
    margin_top_code: MarginTopEnum;

    /**
     * Specifies the bottom margin of this row.
     * @type {MarginBottomEnum}
     * @memberof ContentPageRowRestDto
     */
    margin_bottom_code: MarginBottomEnum;

    /**
     * Specifies the top padding of this row.
     * @type {PaddingTopEnum}
     * @memberof ContentPageRowRestDto
     */
    padding_top_code: PaddingTopEnum;

    /**
     * Specifies the bottom padding of this row.
     * @type {PaddingBottomEnum}
     * @memberof ContentPageRowRestDto
     */
    padding_bottom_code: PaddingBottomEnum;

    /**
     * Specifies the space between columns of this row.
     * @type {HorizontalGutterEnum}
     * @memberof ContentPageRowRestDto
     */
    horizontal_gutter_code: HorizontalGutterEnum;

    /**
     * Specifies the background color of this row.
     * @type {BackgroundColorEnum}
     * @memberof ContentPageRowRestDto
     */
    background_color_code: BackgroundColorEnum;

    /**
     * 
     * @type {ColorRestDto|null}
     * @memberof ContentPageRowRestDto
     */
    background_color?: ColorRestDto|null;

    /**
     * 
     * @type {BackgroundImageRestDto|null}
     * @memberof ContentPageRowRestDto
     */
    background_image?: BackgroundImageRestDto|null;

    /**
     * 
     * @type {BorderRadiusRestDto}
     * @memberof ContentPageRowRestDto
     */
    border_radius: BorderRadiusRestDto;

    /**
     * 
     * @type {DeviceVisibilityRestDto|null}
     * @memberof ContentPageRowRestDto
     */
    device_visibility?: DeviceVisibilityRestDto|null;

    /**
     * Specifies the container type of this row.
     * @type {ContainerTypeEnum}
     * @memberof ContentPageRowRestDto
     */
    container_type_code: ContainerTypeEnum;

}

/**
 * @export
 * @function cloneContentPageRowRestDtoMetaData
 */
export function cloneContentPageRowRestDtoMetaData(dto: ContentPageRowRestDto, source: ContentPageRowRestDto): void {
    dto.items_vertical_alignment_code = source.items_vertical_alignment_code;
    dto.items_horizontal_alignment_code = source.items_horizontal_alignment_code;
    dto.margin_top_code = source.margin_top_code;
    dto.margin_bottom_code = source.margin_bottom_code;
    dto.padding_top_code = source.padding_top_code;
    dto.padding_bottom_code = source.padding_bottom_code;
    dto.horizontal_gutter_code = source.horizontal_gutter_code;
    dto.background_color_code = source.background_color_code;
    dto.container_type_code = source.container_type_code;
}
