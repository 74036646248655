import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e6fa2a14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "preview" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex align-items-center"
}
const _hoisted_3 = { class: "icons" }
const _hoisted_4 = { class: "cnk" }
const _hoisted_5 = { class: "name" }
const _hoisted_6 = { class: "d-flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PimProductsOverviewTableItemPreview = _resolveComponent("PimProductsOverviewTableItemPreview")!
  const _component_ItemIcons = _resolveComponent("ItemIcons")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("td", _hoisted_1, [
      (this.product.product_info.main_image_url)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("i", {
              class: "mas mas-fill-1 fs-6 cu-p",
              onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (this.showPreview = true)),
              onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (this.showPreview = false))
            }, "visibility", 32)
          ]))
        : _createCommentVNode("", true),
      (this.showPreview)
        ? (_openBlock(), _createBlock(_component_PimProductsOverviewTableItemPreview, {
            key: 1,
            product: this.product
          }, null, 8, ["product"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("td", _hoisted_3, [
      _createVNode(_component_ItemIcons, {
        product: this.product
      }, null, 8, ["product"])
    ]),
    _createElementVNode("td", _hoisted_4, _toDisplayString(this.product.product_info.cnk_code), 1),
    _createElementVNode("td", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (_ctx.AbilityContext.isAuthorizedForFeature('PIM_LABO_PRODUCT_INFO'))
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-link text-start text-decoration-none text-black lh-1 p-0",
              onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (this.openOffCanvasProductDetail && this.openOffCanvasProductDetail(...args)), ["prevent"]))
            }, _toDisplayString(this.product.product_info.name), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}