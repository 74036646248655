
import {defineComponent} from "vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {PharmacyLandingPageRestService} from "@/services/rest/cms-pharmacy/PharmacyLandingPageRestService";
import {PharmacyLandingPageRefRestDto} from "@/api/pharma-cms-pharmacy/models";
import PageLinkPicker from "@/components/UI/PageLinkPicker.vue";
import {PageLinkPayload} from "@/models/payload/PageLinkPayload";
import {PageLinkTypeEnum} from "@/models/enum/PageLinkTypeEnum";
import {DpException} from "@/exception/DpException";
import {PageRefTargetEnum} from "@/api/enums/page-ref-target-enum";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import {I18nContext} from "@/context/I18nContext";
import MultilingualSwitcher from "@/components/UI/MultilingualSwitcher.vue";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {UIStateDto} from "@/dtos/UIStateDto";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import {mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";

export default defineComponent({
  name: "PharmacyLandingPage",
  components: {BaseSpinner, AlertError2, MultilingualSwitcher, PageLinkPicker, BaseTitle},
  data() {
    return {
      activeLanguage: I18nContext.getDefaultApiLanguageKey(),
      languages: I18nContext.getLanguages(),

      pageLinkPayloads: {
        'nl': PageLinkPayload.createWithDefaults(),
        'fr': PageLinkPayload.createWithDefaults(),
        'en': PageLinkPayload.createWithDefaults(),
        'de': PageLinkPayload.createWithDefaults()
      } as any,

      pharmacyLandingPageRef: {} as PharmacyLandingPageRefRestDto,
      pharmacyLandingPageRefUI: UIStateDto.createWithDefaults()
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapState(useCustomerStore, ["getPharmacyCode"]),
    pharmacyCode(): string {
      return this.getPharmacyCode;
    }
  },
  methods: {
    onPageLinkPickerChanged(payload: PageLinkPayload) {
      // console.log('onPageLinkPickerChanged: ', payload)
      if (payload.type === PageLinkTypeEnum.NONE) {
        this.pharmacyLandingPageRef.landing_page_id[this.activeLanguage] = null;
      } else if (payload.type === PageLinkTypeEnum.URL) {
        throw [new DpException('PageLinkType URL is not possible in this context.')];
      } else if (payload.type === PageLinkTypeEnum.PAGE) {
        this.pharmacyLandingPageRef.landing_page_id[this.activeLanguage] = payload.page_id;
      }
    },
    setExceptions(exceptions: unknown) {
      this.pharmacyLandingPageRefUI.clearError();

      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pharmacyLandingPageRefUI as UIStateDto))
        .catch(exceptions, true);
    },
    async reloadContent(): Promise<void> {
      this.pharmacyLandingPageRefUI.setNotReady();

      try {
        this.pharmacyLandingPageRef = await PharmacyLandingPageRestService.getInstance()
          .findLandingPageRef(this.pharmacyCode);

        const keys = Object.keys(this.pageLinkPayloads);
        keys.forEach((key: string) => {
          this.pageLinkPayloads[key] = new PageLinkPayload(
            null,
            this.pharmacyLandingPageRef.landing_page_id[key] ?? null,
            PageRefTargetEnum.SELF
          );
          // console.log(this.pageLinkPayloads[key]);
        });
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pharmacyLandingPageRefUI as UIStateDto))
          .catch(exceptions, true);
      } finally {
        this.pharmacyLandingPageRefUI.setReady();
      }
    },
    async submitData(): Promise<void> {
      this.pharmacyLandingPageRefUI
        .clearError()
        .setNotReady();

      try {
        await PharmacyLandingPageRestService.getInstance()
          .updateLandingPageRef(this.pharmacyLandingPageRef, this.pharmacyCode);
        await handleSavedSuccessfully();

        await this.reloadContent();
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pharmacyLandingPageRefUI as UIStateDto))
          .catch(exceptions, true);
      } finally {
        this.pharmacyLandingPageRefUI.setReady();
      }
    }
  }
});
