
import {defineComponent} from "vue";

export default  defineComponent({
  name: "BaseModalButton",
  props: {
    class: {
      type: String,
      required: false,
    },
    modalId: {
      type: String,
      required: false,
      default: 'baseModal',
    },
    mode: {
      type: String,
      required: false,
      default: 'open',
    }
  },
  data: () => ({
    closeButton: null as HTMLButtonElement | null,
  }),
  methods: {
    clickClose() {
      if (this.$refs.closeButton) {
        (this.$refs.closeButton as any).click(); // Ensure TypeScript recognizes this
      }
    }
  }
});
