export class Singleton {

    protected static instances = new Map<string, Singleton>();

    protected constructor() {
    }

    // @ts-ignore
    public static getInstance<T extends typeof Singleton>(this: T): InstanceType<T> {
        const name = this.name;

        if (!this.instances.has(name)) {
            // @ts-ignore
            this.instances.set(name, (new this()) as InstanceType<T>);
        }

        // @ts-ignore
        return this.instances.get(name);
    }
}
