<script>
import AbstractInputControl from "@/components/UI/AbstractInputControl";

export default {
  name: "InputEmail",
  extends: AbstractInputControl,
  methods: {
    determineInputType() {
      return 'email';
    },
    determineRules() {
      return ['email'];
    },
  },
};
</script>
