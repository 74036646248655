import {BusinessService} from "@/services/business/BusinessService";
import {ContentCustomerRestService} from "@/services/rest/content-customer/ContentCustomerRestService";
import {LanguageSettingsRestDto} from "@/api/pharma-content-customer/models";
import {I18nContext} from "@/context/I18nContext";

export class I18nContextService extends BusinessService {
    public async updateI18nContext(): Promise<void> {
        const dto: LanguageSettingsRestDto = await ContentCustomerRestService
            .getInstance()
            .findSupportedLanguages();

        const languages = [dto?.default_language_code ?? '']
            .concat(dto?.additional_language_codes ?? []);

        I18nContext.setDefaultLanguage(dto?.default_language_code ?? '');
        I18nContext.setLanguages(languages);
    }
}
