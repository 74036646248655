
import {defineComponent} from "vue";
import {ColorSchemeRestDtoModel} from "@/models/api/pharma-cms-color-scheme/ColorSchemeRestDtoModel";

export default defineComponent({
  name: "DefaultLabel",
  props: {
    content: {
      type: String,
      required: true,
    },
    colorScheme: {
      type: [ColorSchemeRestDtoModel, Object],
      required: true,
    },
  },
  computed: {
    textColor(): string {
      return this.colorScheme.text_color_on_secondary_color;
    },
    backgroundColor(): string {
      return this.colorScheme.secondary_color;
    },
    classForLabel(): string {
      return 'badge custom_badge position-absolute px-2 ms-2 mt-2 me-2 mb-2 start-0 bottom-0';
    },
  },
});
