/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum SymbolGradeEnum {
    GRADE_MIN_25 = 'GRADE_MIN_25',
    GRADE_0 = 'GRADE_0',
    GRADE_200 = 'GRADE_200',
}