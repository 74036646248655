import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "row mb-3 align-items-center" }
const _hoisted_2 = { class: "col-9 col-xl-10 flex-column" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "btn btn-primary",
  disabled: ""
}
const _hoisted_6 = { class: "col-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LazyImage = _resolveComponent("LazyImage")!
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_base_card_body = _resolveComponent("base-card-body")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_base_modal_button = _resolveComponent("base-modal-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_card, {
        style: _normalizeStyle(_ctx.cardStyles)
      }, {
        default: _withCtx(() => [
          (['IMAGE', 'TEXT_WITH_IMAGE'].includes(_ctx.libType) && this.contentBlock.cb?.image_key?.nl)
            ? (_openBlock(), _createBlock(_Suspense, { key: 0 }, {
                fallback: _withCtx(() => [
                  _createVNode(_component_BaseSpinner)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_LazyImage, {
                    "image-key": this.contentBlock.cb.image_key.nl,
                    class: "card-img-top",
                    style: _normalizeStyle(this.imageStyles)
                  }, null, 8, ["image-key", "style"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (['TEXT_WITH_IMAGE', 'TEXT_WITH_TITLE'].includes(_ctx.libType))
            ? (_openBlock(), _createBlock(_component_base_card_body, { key: 1 }, {
                default: _withCtx(() => [
                  _createElementVNode("h5", _hoisted_3, _toDisplayString(this.contentBlock.cb.title.nl), 1),
                  _createElementVNode("div", {
                    innerHTML: this.content,
                    class: "card-text"
                  }, null, 8, _hoisted_4),
                  (this.libType === 'TEXT_WITH_IMAGE' && this.contentBlock.cb?.button?.button_text?.nl)
                    ? (_openBlock(), _createElementBlock("button", _hoisted_5, _toDisplayString(this.contentBlock.cb.button.button_text.nl), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["style"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_base_modal_button, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('copied', this.contentBlock.id))),
        class: "btn btn-primary",
        mode: "close"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('select')), 1)
        ]),
        _: 1
      })
    ])
  ]))
}