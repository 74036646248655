import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ef45ee3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-group-item d-flex justify-content-between align-items-center" }
const _hoisted_2 = { class: "product-extra d-flex align-items-center" }
const _hoisted_3 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductSelectionProductsSelectionItemBadge = _resolveComponent("ProductSelectionProductsSelectionItemBadge")!
  const _component_ProductSelectionProductsSelectionItemPreview = _resolveComponent("ProductSelectionProductsSelectionItemPreview")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["product-info pe-2", {'cu-p': this.hasMainImageUrl}]),
      onMouseenter: _cache[3] || (_cache[3] = ($event: any) => (this.showPreview = true)),
      onMouseleave: _cache[4] || (_cache[4] = ($event: any) => (this.showPreview = false))
    }, [
      _createElementVNode("p", {
        class: "product-name lh-sm mb-0",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (this.openOffCanvasProductDetail && this.openOffCanvasProductDetail(...args)), ["prevent"]))
      }, _toDisplayString(this.productItem.name), 1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", {
          class: "text-muted fs-7 lh-sm mb-0 me-2",
          onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (this.openOffCanvasProductDetail && this.openOffCanvasProductDetail(...args)), ["prevent"]))
        }, _toDisplayString(this.productItem.brand.description), 1),
        _createElementVNode("p", {
          class: "text-muted fs-7 lh-sm mb-0",
          onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (this.openOffCanvasProductDetail && this.openOffCanvasProductDetail(...args)), ["prevent"]))
        }, _toDisplayString(this.productItem.cnk_code), 1)
      ])
    ], 34),
    _createElementVNode("div", {
      class: "btn-toolbar justify-content-end align-items-center",
      role: "toolbar",
      "aria-label": `ProductItem buttons for ${this.productItem.name}`
    }, [
      (this.hasProducts)
        ? (_openBlock(), _createBlock(_component_ProductSelectionProductsSelectionItemBadge, {
            key: 0,
            "product-item": this.productItem,
            class: "me-1"
          }, null, 8, ["product-item"]))
        : _createCommentVNode("", true),
      (_ctx.filterSelectionTypeCode !== 'BASE_SELECTION')
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "btn btn-warning ms-2",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (this.updateProductItem(this.productItem.product_code,'BASE_SELECTION')))
          }, _toDisplayString('B')))
        : _createCommentVNode("", true),
      (_ctx.filterSelectionTypeCode !== 'INCLUDE')
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            class: "btn btn-secondary ms-2",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (this.updateProductItem(this.productItem.product_code,'INCLUDE')))
          }, _toDisplayString('+')))
        : _createCommentVNode("", true),
      (_ctx.filterSelectionTypeCode !== 'EXCLUDE')
        ? (_openBlock(), _createElementBlock("button", {
            key: 3,
            class: "btn btn-danger ms-2",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (this.updateProductItem(this.productItem.product_code,'EXCLUDE')))
          }, _toDisplayString('-')))
        : _createCommentVNode("", true)
    ], 8, _hoisted_3),
    (this.showPreview && this.hasMainImageUrl)
      ? (_openBlock(), _createBlock(_component_ProductSelectionProductsSelectionItemPreview, {
          key: 0,
          "product-item": this.productItem
        }, null, 8, ["product-item"]))
      : _createCommentVNode("", true)
  ]))
}