import {ContentVisibilityRuleRestDto} from "@/api/pharma-cms-menu/models";
import {VisibilityRuleTypeEnum} from "@/api/enums/visibility-rule-type-enum";

export class ContentVisibilityRuleRestDtoModel implements ContentVisibilityRuleRestDto {
    type_code: VisibilityRuleTypeEnum;
    product_filter_code?: string | null;
    begin_date?: string | null;
    end_date?: string | null;


    constructor(type_code: VisibilityRuleTypeEnum, product_filter_code: string | null, begin_date: string | null, end_date: string | null) {
        this.type_code = type_code;
        this.product_filter_code = product_filter_code;
        this.begin_date = begin_date;
        this.end_date = end_date;
    }

    public static createWithDefaults(): ContentVisibilityRuleRestDtoModel {
        return new this(VisibilityRuleTypeEnum.HIDE, null, null, null);
    }
}
