import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (this.imageUrl.length)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: this.imageUrl,
        alt: this.alt
      }, null, 8, _hoisted_1))
    : _createCommentVNode("", true)
}