/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum BackgroundImageSizeEnum {
    AUTO = 'AUTO',
    COVER = 'COVER',
    CONTAIN = 'CONTAIN',
}