import {Service} from "@/services/Service";
import {CacheKeyEnum} from "@/services/cache/CacheKeyEnum";

export class CacheService extends Service {

    private cache = new Map<string, any>();

    protected constructor() {
        super();
    }

    public get(key: CacheKeyEnum|string, customerCode: string|null = null): any {
        return this.cache.get((customerCode ?? this.getCustomerCode()) + '.' + key);
    }

    public set(key: CacheKeyEnum|string, value: any, customerCode: string|null = null): any {
        this.cache.set((customerCode ?? this.getCustomerCode()) + '.' + key, value);
    }

    public invalidate(key: CacheKeyEnum|string, customerCode: string|null = null): void {
        this.cache.set((customerCode ?? this.getCustomerCode()) + '.' + key, null);
    }

}
