export class PimLaboProductsCriteriaModel {
    filter_brand_code?: string | null;
    filter_cnk_code?: number | null;
    filter_category_code?: string | null;
    filter_is_image_available?: boolean | null;
    filter_is_commercially_available?: boolean | null;
    search_keyword?: string | null;

    constructor() {
    }

    public static createWithDefaults(): PimLaboProductsCriteriaModel {
        const model = new this();

        model.filter_brand_code = null;
        model.filter_cnk_code = null;
        model.filter_category_code = null;
        model.filter_is_image_available = true;
        model.filter_is_commercially_available = true;
        model.search_keyword = null;

        return model;
    }

    public static createWithCnkOnly(cnk_code?: number | null): PimLaboProductsCriteriaModel {
        const model = this.createWithDefaults();

        model.filter_cnk_code = cnk_code;

        return model;
    }
}
