
import {TrainingsRestService} from "@/services/rest/trainings/TrainingsRestService";
import {defineComponent} from "vue";

export default defineComponent({
  name: "Training",
  data() {
      return {
        isReady: false as boolean,
        trainingsData: null as Object|null,
      };
  },
  mounted(): void {
    this.findTrainingsData();
  },
  methods: {
    async findTrainingsData(): Promise<void> {
      this.isReady = false;

      this.trainingsData = await TrainingsRestService.getInstance().findTrainingsData();

      this.isReady = true;
    }
  }
});
