
import store from "@/store";
import {Exception} from "@/api/interfaces";
import {defineComponent} from "vue";
import {PeriodOverviewRestDto} from "@/api/pharma-cms-pharmacy/models";
import {mapGetters} from "vuex";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";

export default defineComponent({
  name: "OpeningHoursPeriodsOverview",
  components: {BaseTitle},
  data: () => ({
    isReady: false as Boolean,
    error: null,
    exceptions: [] as Exception[],
    pharmacyCode: '' as String,
    openingHoursPeriods: {} as PeriodOverviewRestDto,
  }),
  computed: {
    ...mapGetters('cms_pharmacy', ['getOpeningHoursPeriods']),
    ...mapState(useCustomerStore, ["getPharmacyCode"]),
  },
  mounted() {
    this.reloadContent();
  },
  methods: {
    async reloadContent() {
      try {
        this.isReady = false;

        this.pharmacyCode = this.getPharmacyCode;
        await this.searchOpeningHoursPeriods();
        this.openingHoursPeriods = this.getOpeningHoursPeriods;

        this.isReady = true;
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async deleteOpeningHoursPeriod(id: string) {
      try {
        const answer = window.confirm(this.$t('deleteConfirmation'));
        if (answer) {
          const payload = {
            id: id,
            pharmacy_code: this.pharmacyCode,
          };
          await store.dispatch('cms_pharmacy/deleteOpeningHoursPeriod', payload).then(() => {
            this.reloadContent();
          });
        }
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async searchOpeningHoursPeriods() {
      try {
        await store.dispatch('cms_pharmacy/searchOpeningHoursPeriods', this.pharmacyCode);
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    formatDateString(date: string) {
      let locale = this.$i18n.locale;
      switch (locale) {
        case 'nl':
          locale = 'fr';
          break;
      }

      return new Intl.DateTimeFormat(locale).format(new Date(date));
    }
  }
});
