/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum SymbolOpticalSizeEnum {
    OPTSZ_20 = 'OPTSZ_20',
    OPTSZ_24 = 'OPTSZ_24',
    OPTSZ_40 = 'OPTSZ_40',
    OPTSZ_48 = 'OPTSZ_48',
}