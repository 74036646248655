import {CpcDiscountSchemeEndpointsApiService} from "@/main";
import {
    DiscountSchemeOverviewRestDto,
    DiscountSchemeRestDto,
} from "@/api/pharma-cpc-mgmt/models";
import {formatDateObjectToDateString} from "@/helpers/functions/date-time";
import {
    emptyDiscountScheme,
} from "@/store/pharma-cpc-mgmt/middleware";
import {MoveActionEnum} from "@/models/enum/MoveActionEnum";


export default {
    state() {
        return {
            discount_scheme: {} as DiscountSchemeRestDto,
            discount_schemes: [] as DiscountSchemeOverviewRestDto[],
        };
    },
    actions: {
        clearDiscountScheme(context: any) {
            context.commit('clearDiscountScheme');
        },
        clearDiscountSchemePeriod(context: any) {
            context.commit('clearDiscountSchemePeriod');
        },
        setDiscountSchemeValue(context: any, payload: any) {
            context.commit('setDiscountSchemeValue', {
                key: payload.key as string,
                value: payload.value as boolean | string | number,
            });
        },
        async deleteDiscountScheme(context: any, id: string) {
            await CpcDiscountSchemeEndpointsApiService.deleteDiscountScheme(id);
        },
        async moveDiscountScheme(context: any, payload: any) {
            const id = payload.id as string;
            const moveAction = payload.move_action as MoveActionEnum;

            switch (moveAction) {
                case MoveActionEnum.TOP:
                    await CpcDiscountSchemeEndpointsApiService.moveDiscountSchemeToTop(id);
                    break;
                case MoveActionEnum.BOTTOM:
                    await CpcDiscountSchemeEndpointsApiService.moveDiscountSchemeToBottom(id);
                    break;
                case MoveActionEnum.UP:
                    await CpcDiscountSchemeEndpointsApiService.moveDiscountSchemeUp(id);
                    break;
                case MoveActionEnum.DOWN:
                    await CpcDiscountSchemeEndpointsApiService.moveDiscountSchemeDown(id);
                    break;
                default:
                    throw new Error('Unsupported moveAction to call ' + moveAction);
            }
        },
        async searchDiscountScheme(context: any, id: string) {
            const response = await CpcDiscountSchemeEndpointsApiService.getDiscountScheme(id);

            context.commit('setDiscountScheme', response.data);
        },
        async searchDiscountSchemes(context: any) {
            const response = await CpcDiscountSchemeEndpointsApiService.findDiscountSchemes(undefined);
            context.commit('setDiscountSchemes', response.data);
        },
    },
    mutations: {
        clearDiscountScheme(state: any) {
            state.discount_scheme = emptyDiscountScheme();
        },
        clearDiscountSchemePeriod(state: any) {
            state.discount_scheme.begin_date = formatDateObjectToDateString(new Date());
            state.discount_scheme.end_date = undefined;
        },
        setDiscountScheme(state: any, discount_scheme: DiscountSchemeRestDto) {
            state.discount_scheme = discount_scheme;
        },
        setDiscountSchemeValue(state: any, payload: any) {
            state.discount_scheme[payload.key as string] = payload.value as boolean | string | number;
        },
        setDiscountSchemes(state: any, discount_schemes: DiscountSchemeOverviewRestDto[]) {
            state.discount_schemes = discount_schemes;
        },
    },
    getters: {
        getDiscountScheme(state: any): DiscountSchemeRestDto {
            return state.discount_scheme;
        },
        getDiscountSchemes(state: any): DiscountSchemeOverviewRestDto[] {
            return state.discount_schemes;
        }
    },
}
