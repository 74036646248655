/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Pharmacy API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * The necessary fields for rendering a button.
 * @export
 * @interface PharmacyRestDto
 */
export interface PharmacyRestDto {
    /**
     * 
     * @type {MultilingualMax100FieldRestDto}
     * @memberof PharmacyRestDto
     */
    company_name: any;
    /**
     * 
     * @type {string}
     * @memberof PharmacyRestDto
     */
    company_email: any;
    /**
     * 
     * @type {string}
     * @memberof PharmacyRestDto
     */
    company_phone: any;
    /**
     * 
     * @type {AddressRestDto}
     * @memberof PharmacyRestDto
     */
    company_address: any;
    /**
     * 
     * @type {string}
     * @memberof PharmacyRestDto
     */
    vat_number?: any;
    /**
     * 
     * @type {string}
     * @memberof PharmacyRestDto
     */
    rpr?: any;
    /**
     * Indication if the public pharmacy inforamtion is different then the official company information.
     * @type {boolean}
     * @memberof PharmacyRestDto
     */
    different_contactinfo?: any;
    /**
     * 
     * @type {MultilingualMax100FieldRestDto}
     * @memberof PharmacyRestDto
     */
    public_name: any;
    /**
     * 
     * @type {MultilingualMax100FieldRestDto}
     * @memberof PharmacyRestDto
     */
    public_name_short?: any;
    /**
     * 
     * @type {string}
     * @memberof PharmacyRestDto
     */
    public_email?: any;
    /**
     * 
     * @type {string}
     * @memberof PharmacyRestDto
     */
    public_phone?: any;
    /**
     * 
     * @type {AddressRestDto}
     * @memberof PharmacyRestDto
     */
    public_address?: any;
    /**
     * 
     * @type {string}
     * @memberof PharmacyRestDto
     */
    apb_number: any;
    /**
     * 
     * @type {MultilingualMax100FieldRestDto}
     * @memberof PharmacyRestDto
     */
    pharmacy_holder_name?: any;
    /**
     * 
     * @type {string}
     * @memberof PharmacyRestDto
     */
    link_to_facebook?: any;
    /**
     * 
     * @type {string}
     * @memberof PharmacyRestDto
     */
    link_to_instagram?: any;
    /**
     * 
     * @type {string}
     * @memberof PharmacyRestDto
     */
    link_to_twitter?: any;
    /**
     * 
     * @type {string}
     * @memberof PharmacyRestDto
     */
    link_to_youtube?: any;
    /**
     * 
     * @type {string}
     * @memberof PharmacyRestDto
     */
    link_to_linkedin?: any;
    /**
     * 
     * @type {string}
     * @memberof PharmacyRestDto
     */
    link_to_whatsapp?: any;
    /**
     * Only relevant for multi-pharmacy.
     * @type {string}
     * @memberof PharmacyRestDto
     */
    image_key?: any;
    /**
     * The status of this content block.
     * @type {string}
     * @memberof PharmacyRestDto
     */
    status_code: PharmacyRestDtoStatusCodeEnum;
    /**
     * The sorting value of this content block.
     * @type {number}
     * @memberof PharmacyRestDto
     */
    sort_weight?: any;
}

/**
    * @export
    * @enum {string}
    */
export enum PharmacyRestDtoStatusCodeEnum {
    PUBLISHED = 'PUBLISHED',
    NOTPUBLISHED = 'NOT_PUBLISHED'
}

