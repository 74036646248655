/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */

import {ColorRestDto} from ".";
import {MultilingualMax2000FieldRestDto} from ".";
import {MultilingualMax50FieldRestDto} from ".";
import {SymbolFamilyEnum} from "@/api/enums/symbol-family-enum";
import {SymbolGradeEnum} from "@/api/enums/symbol-grade-enum";
import {SymbolHorizontalPositionEnum} from "@/api/enums/symbol-horizontal-position-enum";
import {SymbolOpticalSizeEnum} from "@/api/enums/symbol-optical-size-enum";
import {SymbolTargetEnum} from "@/api/enums/symbol-target-enum";
import {SymbolVerticalPositionEnum} from "@/api/enums/symbol-vertical-position-enum";
import {SymbolWeightEnum} from "@/api/enums/symbol-weight-enum";

/**
 * @export
 * @interface SymbolRestDto
 */
export interface SymbolRestDto {
    /**
     * Identifies the symbol
     * @type {SymbolFamilyEnum}
     * @memberof SymbolRestDto
     */
    family_code: SymbolFamilyEnum;

    /**
     * Identifies the symbol
     * @type {string}
     * @memberof SymbolRestDto
     */
    name_code: string;

    /**
     * The html target for this symbol page ref.
     * @type {SymbolTargetEnum|null}
     * @memberof SymbolRestDto
     */
    symbol_target?: SymbolTargetEnum|null;

    /**
     * 
     * @type {MultilingualMax2000FieldRestDto|null}
     * @memberof SymbolRestDto
     */
    symbol_url?: MultilingualMax2000FieldRestDto|null;

    /**
     * 
     * @type {MultilingualMax50FieldRestDto|null}
     * @memberof SymbolRestDto
     */
    symbol_page_id?: MultilingualMax50FieldRestDto|null;

    /**
     * Font size in rem
     * @type {number}
     * @memberof SymbolRestDto
     */
    size_in_rem: number;

    /**
     * 
     * @type {ColorRestDto}
     * @memberof SymbolRestDto
     */
    default_state_color: ColorRestDto;

    /**
     * 
     * @type {ColorRestDto}
     * @memberof SymbolRestDto
     */
    hover_state_color: ColorRestDto;

    /**
     * If true, the icon will be rendered filled.
     * @type {boolean|null}
     * @memberof SymbolRestDto
     */
    is_filled?: boolean|null;

    /**
     * The symbol&#039;s stroke weight.
     * @type {SymbolWeightEnum}
     * @memberof SymbolRestDto
     */
    weight_code: SymbolWeightEnum;

    /**
     * Weight and grade affect a symbol&#039;s thickness. Adjustments to grade are more granular than adjustments to weight and have a small impact on the size of the symbol.
     * @type {SymbolGradeEnum}
     * @memberof SymbolRestDto
     */
    grade_code: SymbolGradeEnum;

    /**
     * For the image to look the same at different sizes, the stroke weight (thickness) changes as the icon size scales. Optical Size offers a wayto automatically adjust the stroke weight when you increase or decreasethe symbol size.
     * @type {SymbolOpticalSizeEnum}
     * @memberof SymbolRestDto
     */
    optical_size_code: SymbolOpticalSizeEnum;

    /**
     * Determines the horizontal object-position for the symbol.
     * @type {SymbolHorizontalPositionEnum}
     * @memberof SymbolRestDto
     */
    horizontal_position_code: SymbolHorizontalPositionEnum;

    /**
     * Determines the vertical object-position for the symbol.
     * @type {SymbolVerticalPositionEnum}
     * @memberof SymbolRestDto
     */
    vertical_position_code: SymbolVerticalPositionEnum;

}

/**
 * @export
 * @function cloneSymbolRestDtoMetaData
 */
export function cloneSymbolRestDtoMetaData(dto: SymbolRestDto, source: SymbolRestDto): void {
    dto.family_code = source.family_code;
    dto.symbol_target = source.symbol_target;
    dto.size_in_rem = source.size_in_rem;
    dto.default_state_color = source.default_state_color;
    dto.hover_state_color = source.hover_state_color;
    dto.is_filled = source.is_filled;
    dto.weight_code = source.weight_code;
    dto.grade_code = source.grade_code;
    dto.optical_size_code = source.optical_size_code;
    dto.horizontal_position_code = source.horizontal_position_code;
    dto.vertical_position_code = source.vertical_position_code;
}
