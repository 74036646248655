
import {defineComponent} from "vue";
import BannerSlotDetail from "@/components/layouts/banners/BannerSlotDetail.vue";

export default defineComponent({
  name: "ContentProviderBannerSlotDetail",
  components: {BannerSlotDetail},
  props: ["mode"],
  data() {
    return {
      cbId: this.$route.params.id as string,
    };
  },
})
