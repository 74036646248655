import {
    ContentPageCreateRestDto,
    FullContentPageRestDto,
    PagedContentPageOverviewRestDto, PageTypeRestDto
} from "@/api/pharma-cms-content-page/models";
import {ContentPageEndpointsApiService} from "@/main";
import {
    emptyFullContentPage, parseCreatedContentPage
} from "@/store/pharma-cms-content-page/middleware";
import {MoveActionEnum} from "@/models/enum/MoveActionEnum";

export default {
    state() {
        return {
            content_page: {} as FullContentPageRestDto,
            content_page_overview: {} as PagedContentPageOverviewRestDto
        }
    },
    actions: {
        clearContentPage(context: any) {
            context.commit("clearContentPage");
        },
        // setContentPageOverviewCbId(context: any, id: IdRestDto) {
        //     context.commit("setContentPageOverviewCbId", id.id as string);
        // },
        async copyContentPage(context: any, payload: any) {
            const id = payload.id as string;
            const to_customer_code = payload.to_customer_code as string | undefined;

            await ContentPageEndpointsApiService.copyContentPage(id, undefined, to_customer_code);
        },
        async createContentPage(context: any, payload?: any) {
            let content_page = {} as ContentPageCreateRestDto;
            if (typeof payload === 'undefined') {
                content_page = parseCreatedContentPage(context.getters.getContentPage);
            } else {
                const type_code = payload.type_code as string | undefined;
                const library_code = payload.library_code as string | undefined;
                content_page = parseCreatedContentPage(context.getters.getContentPage, type_code, library_code);
            }

            await ContentPageEndpointsApiService.createContentPage(content_page);
        },
        async createHomePage() {
            await ContentPageEndpointsApiService.createHomePage();
        },
        async createNamedPage(context: any, code: string) {
            await ContentPageEndpointsApiService.createNamedPage(code);
        },
        async deleteContentPage(context: any, id: string) {
            await ContentPageEndpointsApiService.deleteContentPage(id);
        },
        async moveContentPage(context: any, payload: any) {
            const page_id = payload.page_id as string;
            const move_action = payload.move_action as MoveActionEnum;

            switch (move_action) {
                case MoveActionEnum.TOP:
                    await ContentPageEndpointsApiService.moveContentPageToTop(page_id);
                    break;
                case MoveActionEnum.BOTTOM:
                    await ContentPageEndpointsApiService.moveContentPageToBottom(page_id);
                    break;
                case MoveActionEnum.UP:
                    await ContentPageEndpointsApiService.moveContentPageUp(page_id);
                    break;
                case MoveActionEnum.DOWN:
                    await ContentPageEndpointsApiService.moveContentPageDown(page_id);
                    break;
                default:
                    throw new Error('Unsupported move_action to call ' + move_action);
            }
        },
        // async searchContentPage(context: any, id: string) {
        //     const response = await ContentPageEndpointsApiService.getContentPage(id);
        //
        //     context.commit("setContentPage", parseSearchedContentPage(response.data));
        // },
        async searchContentPageOverview(context: any, payload?: any) {
            let search_name = undefined as string | undefined;
            let filter_type_code = undefined as string | undefined;
            let filter_library_code = undefined as string | undefined;
            let expect_alt_lang_pages = undefined as boolean | undefined;

            if (typeof payload !== 'undefined') {
                search_name = payload.search_name ?? undefined;
                filter_type_code = payload.filter_type_code ?? undefined;
                filter_library_code = payload.filter_library_code ?? undefined;
                expect_alt_lang_pages = payload.expect_alt_lang_pages ?? undefined;
            }

            const response = await ContentPageEndpointsApiService.findContentPages(undefined, search_name, filter_type_code, filter_library_code, expect_alt_lang_pages);

            context.commit("setContentPageOverview", response.data);
        },
        // async saveContentPage(context: any, id: string) {
        //     const content_page = parseSavedContentPage(context.getters.getContentPage.cp) as ContentPageRestDto;
        //
        //     await ContentPageEndpointsApiService.updateContentPage(content_page, id);
        // },
        async updateContentPageType(context: any, payload: any) {
            const id = payload.id as string;
            const page_type = {type_code: payload.page_type} as PageTypeRestDto;

            await ContentPageEndpointsApiService.updateContentPageType(page_type, id);
        },
    },
    mutations: {
        clearContentPage(state: any) {
            state.content_page = emptyFullContentPage();
        },
        setContentPage(state: any, content_page: FullContentPageRestDto) {
            state.content_page = content_page;
        },
        setContentPageOverview(state: any, content_page_overview: PagedContentPageOverviewRestDto) {
            state.content_page_overview = content_page_overview;
        },
        // setContentPageOverviewCbId(state: any, payload: string) {
        //     state.content_page.cp.overview_cb_id = payload;
        // },
    },
    getters: {
        getContentPage(state: any): FullContentPageRestDto {
            return state.content_page;
        },
        getContentPageOverview(state: any): PagedContentPageOverviewRestDto {
            return state.content_page_overview;
        },
    }
}
