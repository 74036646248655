import {ContentPageReferenceRestDto} from "@/api/pharma-cms-menu/models";
import {PageTypeEnum} from "@/api/enums/page-type-enum";

export class ContentPageReferenceRestDtoModel implements ContentPageReferenceRestDto {
    page_id?: string | null;
    page_type?: PageTypeEnum | null;

    constructor(page_id: string | null, page_type: PageTypeEnum | null) {
        this.page_id = page_id;
        this.page_type = page_type;
    }

    public static createWithDefaults(): ContentPageReferenceRestDtoModel {
        return new this(null, null);
    }
}
