import {SnippetCreateRestDto, SnippetRestDto} from "@/api/pharma-cms-snippet/models";
import {StatusEnum} from "@/api/enums/status-enum";
import {InSlotPositionEnum} from "@/api/enums/in-slot-position-enum";
import {SnippetRestDtoModel} from "@/models/api/pharma-cms-snippet/SnippetRestDtoModel";

export class SnippetCreateRestDtoModel implements SnippetCreateRestDto {

    snippet: SnippetRestDtoModel;
    in_slot_position?: InSlotPositionEnum|null;
    in_slot_position_description?: string|null;
    status_code: StatusEnum;
    status_description?: string|null;

    constructor(snippet: SnippetRestDto,
                in_slot_position: InSlotPositionEnum|null,
                status_code: StatusEnum) {
        this.snippet = snippet;
        this.in_slot_position = in_slot_position;
        this.status_code = status_code;
    }

    public static createWithDefaults(): SnippetCreateRestDtoModel {
        return new this(SnippetRestDtoModel.createWithDefaults(),
            InSlotPositionEnum.MAIN_CATEGORIES_TOP,
            StatusEnum.NOT_PUBLISHED);
    }
}
