
import {defineComponent} from "vue";

export default defineComponent({
  name: "BaseLinkPicker",
  emits: ["setLinkType"],
  props: {
    linkType: {
      type: String
    },
    typeOfLink: {
      type: String
    }
  },
  methods: {
    setLinkType(link_type: string, type_of_link: string | null | undefined) {
      this.$emit('setLinkType', link_type, type_of_link);
    },
  }
});
