
import {defineComponent} from 'vue'
import {UtilityHelper} from "@/helpers/UtilityHelper";
import InputText from "@/components/UI/InputText.vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import {EnumClassNamePimEnum} from "@/models/enum/EnumClassNamePimEnum";
import {mapGetters} from "vuex";
import {PimFeedbackRestDtoModel} from "@/models/api/pharma-pim-mgmt/PimFeedbackRestDtoModel";
import InputTextEditor from "@/components/UI/InputTextEditor.vue";

export default defineComponent({
  name: "PimFeedbackPanel",
  emits: ["submitPimFeedback", "submitPimFeedbackCancel"],
  components: {InputTextEditor, InputText, InputSelect},
  props: {
    isGeneralRemark: {
      type: Boolean,
      required: true,
    },
    isShowFieldCode: {
      type: Boolean,
      required: false,
      default: false
    },
    fieldCode: {
      type: String,
      required: false,
    },
    textFragmentId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      localFeedback: PimFeedbackRestDtoModel.createWithDefaults(),
      localFieldCode: this.fieldCode,
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('pim_enum', ["getEnumByClassname", "getCodeDescriptionByCodeFromEnumByClassname"]),
    UtilityHelper() {
      return UtilityHelper
    },
    enumFragmentNameCode(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNamePimEnum.TEXT_FRAGMENT_NAME);
    },
    enumPimProductFieldCode(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNamePimEnum.PIM_PRODUCT_FIELD);
    },
  },
  methods: {
    reloadContent(): void {
      this.localFeedback.field_code = this.fieldCode;
      this.localFeedback.text_fragment_id = this.textFragmentId;
    },
    submitPimFeedback(): void {
      this.$emit('submitPimFeedback', this.localFeedback);
    },
    submitPimFeedbackCancel(): void {
      this.$emit('submitPimFeedbackCancel');
    },
  }
})
