import {TeamMemberRestDto, TeamMemberRestDtoStatusCodeEnum} from "@/api/pharma-cms-pharmacy/models";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";

export class TeamMemberRestDtoModel implements TeamMemberRestDto {
    first_name?: string|null;
    last_name?: string|null;
    function_title?: MultilingualRestDtoModel;
    image_key?: string|null;
    content?: MultilingualRestDtoModel;
    sort_weight?: number;
    status_code: TeamMemberRestDtoStatusCodeEnum;

    constructor(status_code: TeamMemberRestDtoStatusCodeEnum) {
        this.status_code = status_code;
    }

    public static createWithDefaults(): TeamMemberRestDtoModel {
        const model = new TeamMemberRestDtoModel(TeamMemberRestDtoStatusCodeEnum.PUBLISHED);
        model.first_name = null;
        model.last_name = null;
        model.function_title = MultilingualRestDtoModel.createWithDefaults();
        model.image_key = null;
        model.content = MultilingualRestDtoModel.createWithDefaults();
        model.sort_weight = 90;

        return model;
    }
}
