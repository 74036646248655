import {NormalContentBlockModel} from "@/models/api/pharma-cms-content-block/NormalContentBlockModel";
import {
    cloneDocumentContentRestDtoMetaData,
    ContentBlockMetadataRestDto,
    DocumentContentRestDto
} from "@/api/pharma-cms-content-block/models";
import {StatusEnum} from "@/api/enums/status-enum";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import {ButtonLayoutEnum} from "@/api/enums/button-layout-enum";

export class DocumentContentRestDtoModel extends NormalContentBlockModel implements DocumentContentRestDto {
    button_layout_code?: ButtonLayoutEnum;
    button_text?: string;
    cb_metadata?: ContentBlockMetadataRestDto;
    content?: MultilingualRestDtoModel;
    //todo maybe make document_key nullable?
    document_key: string;
    is_show_file_details?: boolean;
    sort_weight?: number;
    status_code: StatusEnum;
    title?: MultilingualRestDtoModel;

    constructor(document_key: string, status_code: StatusEnum) {
        super();
        this.document_key = document_key;
        this.status_code = status_code;
    }

    public static createWithDefaults(): DocumentContentRestDtoModel {
        const model = new DocumentContentRestDtoModel('', StatusEnum.PUBLISHED);
        model.button_layout_code = ButtonLayoutEnum.PRIMARY;
        model.content = MultilingualRestDtoModel.createWithDefaults();
        model.is_show_file_details = true;
        model.title = MultilingualRestDtoModel.createWithDefaults();
        // model.sort_weight = NormalContentBlockModel.getDefaultSortWeight();

        return model;
    }

    public static cloneFrom(source: DocumentContentRestDtoModel): DocumentContentRestDtoModel {
        const model = new this(source.document_key, source.status_code);
        model.cb_metadata = source.cb_metadata;
        model.button_layout_code = source.button_layout_code;
        model.button_text = source.button_text;
        model.content = source.content;
        model.is_show_file_details = source.is_show_file_details;
        model.sort_weight = source.sort_weight;
        model.title = source.title;

        return model;
    }

    public cloneMetadataFrom(source: DocumentContentRestDtoModel): void {
        cloneDocumentContentRestDtoMetaData(this, source);
    }
}
