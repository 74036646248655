/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Customer
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CodeDescriptionRestDto } from '../models';
/**
 * CustomerNamesApi - axios parameter creator
 * @export
 */
export const CustomerNamesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API to find the names of the customers.
         * @summary REST.API.PC.CUS.05 Find customer names.
         * @param {string} [search_name] Zoek de resultaten waarbij de opgegeven parameter voorkomt in de waarde van het name veld. (Er wordt case insensitive gezocht.)
         * @param {string} [filter_license_product_code] Filter de resultaten op basis van de aanwezigheid van een actieve licentie voor de gespecifieerde Digital-Pharma product code.
         * @param {string} [filter_product_type_code] Filter de resultaten op basis van de aanwezigheid van een actieve licentie voor een product van het gespecifieerde Digital-Pharma product type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCustomerNames: async (search_name?: string, filter_license_product_code?: string, filter_product_type_code?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/v1/customer/name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!search_name || typeof(search_name) === "boolean") {
                localVarQueryParameter['search_name'] = search_name;
            }

            if (!!filter_license_product_code || typeof(filter_license_product_code) === "boolean") {
                localVarQueryParameter['filter_license_product_code'] = filter_license_product_code;
            }

            if (!!filter_product_type_code || typeof(filter_product_type_code) === "boolean") {
                localVarQueryParameter['filter_product_type_code'] = filter_product_type_code;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerNamesApi - functional programming interface
 * @export
 */
export const CustomerNamesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API to find the names of the customers.
         * @summary REST.API.PC.CUS.05 Find customer names.
         * @param {string} [search_name] Zoek de resultaten waarbij de opgegeven parameter voorkomt in de waarde van het name veld. (Er wordt case insensitive gezocht.)
         * @param {string} [filter_license_product_code] Filter de resultaten op basis van de aanwezigheid van een actieve licentie voor de gespecifieerde Digital-Pharma product code.
         * @param {string} [filter_product_type_code] Filter de resultaten op basis van de aanwezigheid van een actieve licentie voor een product van het gespecifieerde Digital-Pharma product type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCustomerNames(_axios: AxiosInstance, search_name?: string, filter_license_product_code?: string, filter_product_type_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CodeDescriptionRestDto>>> {
            const localVarAxiosArgs = await CustomerNamesApiAxiosParamCreator(configuration).findCustomerNames(search_name, filter_license_product_code, filter_product_type_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CustomerNamesApi - object-oriented interface
 * @export
 * @class CustomerNamesApi
 * @extends {BaseAPI}
 */
export class CustomerNamesApi extends BaseAPI {
    /**
     * API to find the names of the customers.
     * @summary REST.API.PC.CUS.05 Find customer names.
     * @param {string} [search_name] Zoek de resultaten waarbij de opgegeven parameter voorkomt in de waarde van het name veld. (Er wordt case insensitive gezocht.)
     * @param {string} [filter_license_product_code] Filter de resultaten op basis van de aanwezigheid van een actieve licentie voor de gespecifieerde Digital-Pharma product code.
     * @param {string} [filter_product_type_code] Filter de resultaten op basis van de aanwezigheid van een actieve licentie voor een product van het gespecifieerde Digital-Pharma product type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerNamesApi
     */
     public findCustomerNames(search_name?: string, filter_license_product_code?: string, filter_product_type_code?: string, options?: any) {
        return CustomerNamesApiFp(this.configuration).findCustomerNames(this.axios, search_name, filter_license_product_code, filter_product_type_code, options).then((request) => request(this.axios, this.basePath));
    }

}
