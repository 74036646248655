import axios from 'axios';
import { registerErrorInterceptor } from '@/helpers/interceptors/response/error-interceptor';
import { registerAuthInterceptor } from '@/helpers/interceptors/request/auth-interceptor';
import { registerCustomerCodeInterceptor } from '@/helpers/interceptors/request/x-dp-customer-code-interceptor';
import { registerLanguageInterceptor } from '@/helpers/interceptors/request/x-dp-language-interceptor';

export const createInstance = (config) => {
  const instance = axios.create(config);

  registerErrorInterceptor(instance);
  registerAuthInterceptor(instance);
  registerCustomerCodeInterceptor(instance);
  registerLanguageInterceptor(instance);

  instance.defaults.headers.common['Content-Type'] = 'application/json';

  return instance;
};
