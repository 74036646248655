/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Customer-Product-Catalog
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CnkCodesResponseRestDto } from '../models';
import { CodeResponseRestDto } from '../models';
import { ProductFilterCopySourceRestDto } from '../models';
import { ProductFilterOverviewRestDto } from '../models';
import { ProductFilterRestDto } from '../models';
/**
 * ProductFiltersAPIApi - axios parameter creator
 * @export
 */
export const ProductFiltersAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for copying a product filter of another customer's product filter to this customer's product filters.
         * @summary REST.API.CPC.PF.03 Copy a product filter to this customer's filters.
         * @param {ProductFilterCopySourceRestDto} body 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyProductFilter: async (body: ProductFilterCopySourceRestDto, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling copyProductFilter.');
            }
            const localVarPath = `/customer_mgmt/v1/product_filter/copy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for deleting a product filter.
         * @summary REST.API.CPC.PF.07 Delete product filter.
         * @param {string} filter_code The identifier of the product filter (unique per customer).
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductFilter: async (filter_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter_code' is not null or undefined
            if (filter_code === null || filter_code === undefined) {
                throw new RequiredError('filter_code','Required parameter filter_code was null or undefined when calling deleteProductFilter.');
            }
            const localVarPath = `/customer_mgmt/v1/product_filter/{filter_code}`
                .replace(`{${"filter_code"}}`, encodeURIComponent(String(filter_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving the list of products for a saved product filter..
         * @summary REST.API.CPC.PF.02 Find the list of cnk codes of a product filter.
         * @param {string} filter_code The identifier of the product filter (unique per customer).
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCnkCodesOfProductFilter: async (filter_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter_code' is not null or undefined
            if (filter_code === null || filter_code === undefined) {
                throw new RequiredError('filter_code','Required parameter filter_code was null or undefined when calling findCnkCodesOfProductFilter.');
            }
            const localVarPath = `/customer_mgmt/v1/product_filter/{filter_code}/cnk_list`
                .replace(`{${"filter_code"}}`, encodeURIComponent(String(filter_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving a complex saved product filter.
         * @summary REST.API.CPC.PF.05 Find the product filter.
         * @param {string} filter_code The identifier of the product filter (unique per customer).
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findComplexProductFilter: async (filter_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter_code' is not null or undefined
            if (filter_code === null || filter_code === undefined) {
                throw new RequiredError('filter_code','Required parameter filter_code was null or undefined when calling findComplexProductFilter.');
            }
            const localVarPath = `/customer_mgmt/v1/product_filter/{filter_code}`
                .replace(`{${"filter_code"}}`, encodeURIComponent(String(filter_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving the product filters.
         * @summary REST.API.CPC.PF.06 Find  product filters.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [filter_is_named] Filter de resultaten tot die een naam hebben
         * @param {boolean} [filter_is_precalculated] Filter de resultaten tot die een naam hebben
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductFilters: async (x_dp_customer_code?: string|null, filter_is_named?: boolean|null, filter_is_precalculated?: boolean|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer_mgmt/v1/product_filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_is_named || typeof(filter_is_named) === "boolean") {
                localVarQueryParameter['filter_is_named'] = filter_is_named;
            }

            if (!!filter_is_precalculated || typeof(filter_is_precalculated) === "boolean") {
                localVarQueryParameter['filter_is_precalculated'] = filter_is_precalculated;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for synchronizing a complex product filter.
         * @summary REST.API.CPC.PF.04 Synchronize complex product filter.
         * @param {ProductFilterRestDto} body 
         * @param {string} filter_code The identifier of the product filter (unique per customer).
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncComplexProductFilter: async (body: ProductFilterRestDto, filter_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling syncComplexProductFilter.');
            }
            // verify required parameter 'filter_code' is not null or undefined
            if (filter_code === null || filter_code === undefined) {
                throw new RequiredError('filter_code','Required parameter filter_code was null or undefined when calling syncComplexProductFilter.');
            }
            const localVarPath = `/customer_mgmt/v1/product_filter/{filter_code}`
                .replace(`{${"filter_code"}}`, encodeURIComponent(String(filter_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for synchronizing the list of products for a saved product filter based on a list of cnk codes.
         * @summary REST.API.CPC.PF.01 Synchronize product filter based on list of cnk codes.
         * @param {Array&lt;string&gt;} body 
         * @param {string} filter_code The identifier of the product filter (unique per customer).
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProductFilter: async (body: Array<string>, filter_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling syncProductFilter.');
            }
            // verify required parameter 'filter_code' is not null or undefined
            if (filter_code === null || filter_code === undefined) {
                throw new RequiredError('filter_code','Required parameter filter_code was null or undefined when calling syncProductFilter.');
            }
            const localVarPath = `/customer_mgmt/v1/product_filter/{filter_code}/cnk_list`
                .replace(`{${"filter_code"}}`, encodeURIComponent(String(filter_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductFiltersAPIApi - functional programming interface
 * @export
 */
export const ProductFiltersAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for copying a product filter of another customer's product filter to this customer's product filters.
         * @summary REST.API.CPC.PF.03 Copy a product filter to this customer's filters.
         * @param {ProductFilterCopySourceRestDto} body 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyProductFilter(_axios: AxiosInstance, body: ProductFilterCopySourceRestDto, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodeResponseRestDto>> {
            const localVarAxiosArgs = await ProductFiltersAPIApiAxiosParamCreator(configuration).copyProductFilter(body, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for deleting a product filter.
         * @summary REST.API.CPC.PF.07 Delete product filter.
         * @param {string} filter_code The identifier of the product filter (unique per customer).
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductFilter(_axios: AxiosInstance, filter_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductFiltersAPIApiAxiosParamCreator(configuration).deleteProductFilter(filter_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving the list of products for a saved product filter..
         * @summary REST.API.CPC.PF.02 Find the list of cnk codes of a product filter.
         * @param {string} filter_code The identifier of the product filter (unique per customer).
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCnkCodesOfProductFilter(_axios: AxiosInstance, filter_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CnkCodesResponseRestDto>> {
            const localVarAxiosArgs = await ProductFiltersAPIApiAxiosParamCreator(configuration).findCnkCodesOfProductFilter(filter_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving a complex saved product filter.
         * @summary REST.API.CPC.PF.05 Find the product filter.
         * @param {string} filter_code The identifier of the product filter (unique per customer).
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findComplexProductFilter(_axios: AxiosInstance, filter_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductFilterRestDto>> {
            const localVarAxiosArgs = await ProductFiltersAPIApiAxiosParamCreator(configuration).findComplexProductFilter(filter_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving the product filters.
         * @summary REST.API.CPC.PF.06 Find  product filters.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [filter_is_named] Filter de resultaten tot die een naam hebben
         * @param {boolean} [filter_is_precalculated] Filter de resultaten tot die een naam hebben
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProductFilters(_axios: AxiosInstance, x_dp_customer_code?: string|null, filter_is_named?: boolean|null, filter_is_precalculated?: boolean|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductFilterOverviewRestDto>> {
            const localVarAxiosArgs = await ProductFiltersAPIApiAxiosParamCreator(configuration).findProductFilters(x_dp_customer_code, filter_is_named, filter_is_precalculated, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for synchronizing a complex product filter.
         * @summary REST.API.CPC.PF.04 Synchronize complex product filter.
         * @param {ProductFilterRestDto} body 
         * @param {string} filter_code The identifier of the product filter (unique per customer).
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncComplexProductFilter(_axios: AxiosInstance, body: ProductFilterRestDto, filter_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductFiltersAPIApiAxiosParamCreator(configuration).syncComplexProductFilter(body, filter_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for synchronizing the list of products for a saved product filter based on a list of cnk codes.
         * @summary REST.API.CPC.PF.01 Synchronize product filter based on list of cnk codes.
         * @param {Array&lt;string&gt;} body 
         * @param {string} filter_code The identifier of the product filter (unique per customer).
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncProductFilter(_axios: AxiosInstance, body: Array<string>, filter_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductFiltersAPIApiAxiosParamCreator(configuration).syncProductFilter(body, filter_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProductFiltersAPIApi - object-oriented interface
 * @export
 * @class ProductFiltersAPIApi
 * @extends {BaseAPI}
 */
export class ProductFiltersAPIApi extends BaseAPI {
    /**
     * API endpoint responsible for copying a product filter of another customer's product filter to this customer's product filters.
     * @summary REST.API.CPC.PF.03 Copy a product filter to this customer's filters.
     * @param {ProductFilterCopySourceRestDto} body 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductFiltersAPIApi
     */
     public copyProductFilter(body: ProductFilterCopySourceRestDto, x_dp_customer_code?: string|null, options?: any) {
        return ProductFiltersAPIApiFp(this.configuration).copyProductFilter(this.axios, body, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for deleting a product filter.
     * @summary REST.API.CPC.PF.07 Delete product filter.
     * @param {string} filter_code The identifier of the product filter (unique per customer).
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductFiltersAPIApi
     */
     public deleteProductFilter(filter_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ProductFiltersAPIApiFp(this.configuration).deleteProductFilter(this.axios, filter_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving the list of products for a saved product filter..
     * @summary REST.API.CPC.PF.02 Find the list of cnk codes of a product filter.
     * @param {string} filter_code The identifier of the product filter (unique per customer).
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductFiltersAPIApi
     */
     public findCnkCodesOfProductFilter(filter_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ProductFiltersAPIApiFp(this.configuration).findCnkCodesOfProductFilter(this.axios, filter_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving a complex saved product filter.
     * @summary REST.API.CPC.PF.05 Find the product filter.
     * @param {string} filter_code The identifier of the product filter (unique per customer).
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductFiltersAPIApi
     */
     public findComplexProductFilter(filter_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ProductFiltersAPIApiFp(this.configuration).findComplexProductFilter(this.axios, filter_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving the product filters.
     * @summary REST.API.CPC.PF.06 Find  product filters.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {boolean} [filter_is_named] Filter de resultaten tot die een naam hebben
     * @param {boolean} [filter_is_precalculated] Filter de resultaten tot die een naam hebben
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductFiltersAPIApi
     */
     public findProductFilters(x_dp_customer_code?: string|null, filter_is_named?: boolean|null, filter_is_precalculated?: boolean|null, options?: any) {
        return ProductFiltersAPIApiFp(this.configuration).findProductFilters(this.axios, x_dp_customer_code, filter_is_named, filter_is_precalculated, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for synchronizing a complex product filter.
     * @summary REST.API.CPC.PF.04 Synchronize complex product filter.
     * @param {ProductFilterRestDto} body 
     * @param {string} filter_code The identifier of the product filter (unique per customer).
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductFiltersAPIApi
     */
     public syncComplexProductFilter(body: ProductFilterRestDto, filter_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ProductFiltersAPIApiFp(this.configuration).syncComplexProductFilter(this.axios, body, filter_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for synchronizing the list of products for a saved product filter based on a list of cnk codes.
     * @summary REST.API.CPC.PF.01 Synchronize product filter based on list of cnk codes.
     * @param {Array&lt;string&gt;} body 
     * @param {string} filter_code The identifier of the product filter (unique per customer).
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductFiltersAPIApi
     */
     public syncProductFilter(body: Array<string>, filter_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ProductFiltersAPIApiFp(this.configuration).syncProductFilter(this.axios, body, filter_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
