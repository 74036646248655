
import {defineComponent, ref} from 'vue'
import InputColor from "@/components/UI/InputColor.vue";
import InputNumber from "@/components/UI/InputNumber.vue";
import {ColorPickerPayload} from "@/models/payload/ColorPickerPayload";
import {ColorRestDtoModel} from "@/models/api/ColorRestDtoModel";

export default defineComponent({
  name: "ColorPicker",
  emits: ["changed"],
  components: {InputColor, InputNumber},
  props: {
    color: {
      type: [ColorRestDtoModel, Object],
      required: true
    },
    label: {
      type: String,
      required: true
    },
    label_col_size: {
      type: Number,
      default: 2,
      required: false
    },
    label_bold: {
      type: Boolean,
      default: true,
      required: false
    },
    name: {
      type: String,
      required: true
    },
    read_only: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const colorPickerPayload = ref(new ColorPickerPayload(props.color as ColorRestDtoModel));

    return {
      colorPickerPayload
    };
  },
  watch: {
    colorPickerPayload: {
      handler(newPayload: ColorRestDtoModel) {
        this.$emit('changed', newPayload);
      },
      deep: true,
    }
  },
  methods: {
    onColorChanged(hex: string) {
      this.colorPickerPayload.hex = hex;
      this.colorPickerPayload.setRGB(hex);
    },
  }
});
