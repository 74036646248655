import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "col"
}
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.positioned)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (_ctx.productLabel)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(_ctx.classForPositionedLabel),
              style: _normalizeStyle('--bs-badge-font-weight: 400; color: ' + _ctx.textColor + '; background-color: ' + _ctx.backgroundColor),
              innerHTML: _ctx.content
            }, null, 14, _hoisted_1))
          : _createCommentVNode("", true)
      ], 64))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (!_ctx.productLabel)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('noProductLabelConfigured')), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", null, [
                  _createElementVNode("span", {
                    class: _normalizeClass(_ctx.classForLabel),
                    style: _normalizeStyle('--bs-badge-font-weight: 400; color: ' + _ctx.textColor + '; background-color: ' + _ctx.backgroundColor),
                    innerHTML: _ctx.content
                  }, null, 14, _hoisted_5)
                ])
              ])
            ]))
      ], 64))
}