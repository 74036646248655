import {ContentBlockLinkRestDto} from "@/api/pharma-cms-content-block/models";

export class ContentBlockLinkRestDtoModel implements ContentBlockLinkRestDto {
    to_cb_id: string;
    from_cb_id?: string | null;
    from_cb_type_code?: string | null;
    force_copy?: boolean | null;


    constructor(to_cb_id: string, from_cb_id?: string | null, from_cb_type_code?: string | null, force_copy?: boolean | null) {
        this.to_cb_id = to_cb_id;
        this.from_cb_id = from_cb_id;
        this.from_cb_type_code = from_cb_type_code;
        this.force_copy = force_copy;
    }

    public static createWithDefaults(): ContentBlockLinkRestDtoModel {
        return new this('to_cb_id');
    }
}
