import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col col-xl-8 col-xxl-6" }
const _hoisted_3 = { class: "buttons" }
const _hoisted_4 = {
  type: "submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_AlertError2 = _resolveComponent("AlertError2")!
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_MultilingualSwitcher = _resolveComponent("MultilingualSwitcher")!
  const _component_PageLinkPicker = _resolveComponent("PageLinkPicker")!
  const _component_dp_form = _resolveComponent("dp-form")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_BaseTitle, {
        title: _ctx.$t('landingPage'),
        mb: 4
      }, null, 8, ["title"]),
      _createVNode(_component_AlertError2, {
        error: this.pharmacyLandingPageRefUI.getError()
      }, null, 8, ["error"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BaseSpinner, {
            spin: this.pharmacyLandingPageRefUI.isNotReady()
          }, null, 8, ["spin"]),
          (this.pharmacyLandingPageRefUI.isReady())
            ? (_openBlock(), _createBlock(_component_dp_form, {
                key: 0,
                as: "form",
                onSubmit: this.submitData,
                novalidate: ""
              }, {
                default: _withCtx(({ errors }) => [
                  _createVNode(_component_MultilingualSwitcher, {
                    errors: errors,
                    "active-language": this.activeLanguage,
                    onUpdateActiveLanguage: _cache[0] || (_cache[0] = newValue => this.activeLanguage = newValue)
                  }, null, 8, ["errors", "active-language"]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.languages, (language) => {
                    return (_openBlock(), _createElementBlock("div", { key: language }, [
                      (_openBlock(), _createBlock(_KeepAlive, null, [
                        (language === this.activeLanguage)
                          ? (_openBlock(), _createBlock(_component_PageLinkPicker, {
                              key: 0,
                              payload: this.pageLinkPayloads[this.activeLanguage],
                              "enable-url": false,
                              "enable-target": false,
                              "enable-alt-lang-pages": "",
                              errors: errors,
                              onChanged: this.onPageLinkPickerChanged,
                              onExceptions: this.setExceptions
                            }, null, 8, ["payload", "errors", "onChanged", "onExceptions"]))
                          : _createCommentVNode("", true)
                      ], 1024))
                    ]))
                  }), 128)),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("button", _hoisted_4, _toDisplayString(_ctx.$t('save')), 1)
                  ])
                ]),
                _: 1
              }, 8, ["onSubmit"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}