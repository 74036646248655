
import {Exception} from '@/api/interfaces';
import {defineComponent} from "vue";
import {
  CodeDescriptionRestDto,
  SimpleDocumentContentRestDto
} from "@/api/pharma-cms-content-block/models";
import {StatusEnum} from "@/api/enums/status-enum";
import {mapActions, mapGetters} from 'vuex';
import CbForm from "@/components/layouts/content-blocks/forms/CbForm.vue";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";

export default defineComponent({
  name: "CbFormDocument",
  components: {CbForm},
  props: {
    cbId: {
      type: String,
      required: false,
    },
    cbType: {
      type: String,
      required: true,
    },
    canCopyFromLibrary: {
      type: Boolean,
      required: false,
      default: false,
    },
    canRestoreDefaultValues: {
      type: Boolean,
      required: false,
      default: false,
    },
    docType: {
      type: String,
      required: true,
    },
    contentLibraryCode: {
      type: String,
      required: false,
    },
    isLibrary: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPublishable: {
      type: Boolean,
      required: false,
      default: false,
    },
    mode: {
      type: String,
      required: true,
    },
    returnRoute: {
      type: Object,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    description2: {
      type: String,
      required: false,
    },
    description3: {
      type: String,
      required: false,
    },
    description4: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      error: null,
      exceptions: [] as Exception[],
      isReady: false as boolean,
      isInitialValueSet: false as boolean,
      documentKey: null as string | null | undefined,
      initialDocumentKey: '' as string | null | undefined,
      savedSuccessfully: false as boolean,
      statusOptions: [
        {code: StatusEnum.PUBLISHED, description: this.$t('published')},
        {code: StatusEnum.NOT_PUBLISHED, description: this.$t('notPublished')},
      ] as CodeDescriptionRestDto[]
    };
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cms_content_block', [
      'getSimpleContentBlockDocument',
    ]),
    contentBlock(): SimpleDocumentContentRestDto {
        return this.getSimpleContentBlockDocument(this.cbType) as SimpleDocumentContentRestDto;
    },
    existingDocumentKey(): string | null | undefined {
      if (this.mode === 'new') {
        return null;
      } else {
        return this.contentBlock.document_key?.nl;
      }
    },
    isContentChanged(): boolean {
      return this.initialDocumentKey !== this.documentKey;
    },
  },
  methods: {
    ...mapActions('cms_content_block', [
      'searchSimpleContentBlockDocument',
      'searchStandardSimpleContentBlockDocument',
      'saveSimpleContentBlockDocument', 'deleteSimpleContentBlock',
    ]),
    ...mapActions('cms_document', ['clearPreviewDocument', 'clearUploadedDocumentKey']),
    setDocumentKey(document_key?: string | null) {
      this.documentKey = document_key;
    },
    async restoreInitialValue() {
      this.setDocumentKey(this.initialDocumentKey);
      await this.submitData();
      this.initialDocumentKey = this.contentBlock.document_key?.nl;
    },
    async submitData() {
      try {
        if (this.documentKey === null) {
          await this.deleteSimpleContentBlock(this.cbType);
        } else {
          this.contentBlock.document_key = new MultilingualRestDtoModel(null, null, null, this.documentKey ?? null);
          await this.saveSimpleContentBlockDocument(this.cbType);
        }


        await this.afterSave();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async restoreDefaultValues() {
      this.exceptions = [];

      try {
        this.isReady = false;
        await this.searchStandardSimpleContentBlockDocument(this.cbType);
        this.setDocumentKey(this.contentBlock.document_key?.nl);
        this.isReady = true;
      } catch (exceptions: any) {
        this.exceptions = exceptions;
        this.isReady = true;
      }
    },
    async reloadContent() {
      this.isReady = false;
      this.exceptions = [];

      try {
        await this.clearPreviewDocument(this.docType);
        await this.clearUploadedDocumentKey(this.docType);

        await this.searchSimpleContentBlockDocument(this.cbType);
        this.setDocumentKey(this.contentBlock.document_key?.nl);

        if (!this.isInitialValueSet) {
          this.initialDocumentKey = this.contentBlock.document_key?.nl;
          this.isInitialValueSet = true;
        }
      } catch (exceptions: any) {
        this.exceptions = exceptions;
        this.isReady = true;
      }

      this.isReady = true;
    },
    async afterSave() {
      if (this.returnRoute) {
        await this.$router.push(this.returnRoute);
      } else {
        this.reloadContent().then(() => {
          this.savedSuccessfully = true;
          setTimeout(() => this.savedSuccessfully = false, 2000);
        });
      }
    }
  }
});
