
import {defineComponent, PropType} from 'vue'
import {UtilityHelper} from "@/helpers/UtilityHelper";
import InputSelect from "@/components/UI/InputSelect.vue";
import {FullNamedTextFragmentRestDto} from "@/api/pharma-pim-mgmt/models";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import {EnumClassNamePimEnum} from "@/models/enum/EnumClassNamePimEnum";
import {mapGetters} from "vuex";
import {NamedTextFragmentRestDtoModel} from "@/models/api/pharma-pim-mgmt/NamedTextFragmentRestDtoModel";
import InputTextEditor from "@/components/UI/InputTextEditor.vue";

export default defineComponent({
  name: "NamedTextFragmentPanel",
  emits: ["submitNamedFragmentContent", "submitNamedFragmentCancel"],
  components: {InputTextEditor, InputSelect},
  props: {
    namedTextFragment: {
      type: Object as PropType<FullNamedTextFragmentRestDto>,
      required: false
    },
  },
  data() {
    return {
      localNamedTextFragment: NamedTextFragmentRestDtoModel.createWithDefaults(),
    }
  },
  watch: {
    namedTextFragment: {
      handler() {
        this.reloadContent();
      },
      deep: true,
      immediate: true,
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('pim_enum', ["getEnumByClassname", "getCodeDescriptionByCodeFromEnumByClassname"]),
    UtilityHelper() {
      return UtilityHelper
    },
    enumFragmentNameCode(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNamePimEnum.TEXT_FRAGMENT_NAME);
    },
  },
  methods: {
    reloadContent(): void {
      if (this.namedTextFragment !== null && this.namedTextFragment !== undefined) {
        this.localNamedTextFragment = new NamedTextFragmentRestDtoModel(this.namedTextFragment?.text_fragment?.code ?? '', this.namedTextFragment?.text_fragment?.fragment?.content ?? null);
      }
    },
    submitNamedFragmentContent(): void {
      this.$emit('submitNamedFragmentContent', this.namedTextFragment?.id ?? null, this.localNamedTextFragment);
    },
    submitNamedFragmentCancel(): void {
      this.$emit('submitNamedFragmentCancel', this.namedTextFragment?.id ?? null);
    },
  }
})
