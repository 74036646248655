import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-232b755e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row mb-3 align-items-center" }
const _hoisted_2 = { class: "col-9 col-xl-10 flex-column" }
const _hoisted_3 = { class: "card-title me-5" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "card-title me-5" }
const _hoisted_6 = { class: "card-text fst-italic" }
const _hoisted_7 = { class: "col-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_card_image = _resolveComponent("base-card-image")!
  const _component_base_card_body = _resolveComponent("base-card-body")!
  const _component_ButtonContentPagePreview = _resolveComponent("ButtonContentPagePreview")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_base_modal_button = _resolveComponent("base-modal-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.contentPageAbstract)
        ? (_openBlock(), _createBlock(_component_base_card, {
            key: 0,
            class: "flex-row align-items-start"
          }, {
            default: _withCtx(() => [
              (this.signedUrl && this.signedUrl.length > 0)
                ? (_openBlock(), _createBlock(_component_base_card_image, {
                    key: 0,
                    src: _ctx.signedUrl
                  }, null, 8, ["src"]))
                : _createCommentVNode("", true),
              _createVNode(_component_base_card_body, null, {
                default: _withCtx(() => [
                  _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.contentPageAbstract.title), 1),
                  (this.contentPageAbstractContent.length)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        innerHTML: this.contentPageAbstractContent,
                        class: "card-text"
                      }, null, 8, _hoisted_4))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_ButtonContentPagePreview, {
                "page-id": _ctx.contentPage.id,
                class: "position-absolute top-0 end-0 mt-3 me-3"
              }, null, 8, ["page-id"])
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_base_card, {
            key: 1,
            class: "flex-row align-items-start"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_base_card_body, null, {
                default: _withCtx(() => [
                  _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.contentPage.cp.name), 1),
                  _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('noPreview')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ButtonContentPagePreview, {
                "page-id": _ctx.contentPage.id,
                class: "position-absolute top-0 end-0 mt-3 me-3"
              }, null, 8, ["page-id"])
            ]),
            _: 1
          }))
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_base_modal_button, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('selectedCp', _ctx.contentPage.id))),
        class: "btn btn-primary",
        mode: "close"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('select')), 1)
        ]),
        _: 1
      })
    ])
  ]))
}