<script>
import AbstractInputControl from "@/components/UI/AbstractInputControl";

export default {
  name: 'InputTime',
  extends: AbstractInputControl,
  props: {
    withSeconds: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  methods: {
    determineInputType() {
      return 'time';
    },
    determineRules() {
      return [];
    },
    determinePlaceholder() {
      if (this.withSeconds) {
        return this.$t('inputPlaceholderHMS');
      }

      return this.$t('inputPlaceholderHM');
    },
    determineStep() {
      if (this.withSeconds) {
        return 1;
      } else {
        return 60;
      }
    },
  },
};
</script>
