import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79d3c4b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row product-filters mb-3" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "row products-selection" }
const _hoisted_4 = { class: "col-4" }
const _hoisted_5 = { class: "col-4" }
const _hoisted_6 = { class: "col-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertError = _resolveComponent("AlertError")!
  const _component_ProductSelectionProductsFilters = _resolveComponent("ProductSelectionProductsFilters")!
  const _component_ProductSelectionProductsSelection = _resolveComponent("ProductSelectionProductsSelection")!
  const _component_ProductSelectionProductsProductDetail = _resolveComponent("ProductSelectionProductsProductDetail")!
  const _component_BaseOffcanvas = _resolveComponent("BaseOffcanvas")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AlertError, {
      exceptions: this.exceptions,
      class: "mb-3"
    }, null, 8, ["exceptions"]),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ProductSelectionProductsFilters, { onSubmit: _ctx.submitSearch }, null, 8, ["onSubmit"])
      ])
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ProductSelectionProductsSelection, {
          "is-ready": _ctx.isReadyBase,
          "filter-selection-type-code": "BASE_SELECTION",
          onUpdateProductItem: _ctx.updateProductItem,
          onUpdatedPage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchProducts('BASE_SELECTION'))),
          onOpenOffCanvasProductDetail: this.onOpenProductDetail
        }, null, 8, ["is-ready", "onUpdateProductItem", "onOpenOffCanvasProductDetail"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_ProductSelectionProductsSelection, {
          "filter-selection-type-code": "INCLUDE",
          "is-ready": _ctx.isReadyInclude,
          onUpdateProductItem: _ctx.updateProductItem,
          onUpdatedPage: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchProducts('INCLUDE'))),
          onOpenOffCanvasProductDetail: this.onOpenProductDetail
        }, null, 8, ["is-ready", "onUpdateProductItem", "onOpenOffCanvasProductDetail"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_ProductSelectionProductsSelection, {
          "filter-selection-type-code": "EXCLUDE",
          "is-ready": _ctx.isReadyExclude,
          onUpdateProductItem: _ctx.updateProductItem,
          onUpdatedPage: _cache[2] || (_cache[2] = ($event: any) => (_ctx.searchProducts('EXCLUDE'))),
          onOpenOffCanvasProductDetail: this.onOpenProductDetail
        }, null, 8, ["is-ready", "onUpdateProductItem", "onOpenOffCanvasProductDetail"])
      ])
    ]),
    (_ctx.AbilityContext.isAuthorizedForFeature('PIM_PRODUCT_INFO_GET_AS_PHARMACIST'))
      ? (_openBlock(), _createBlock(_component_BaseOffcanvas, {
          key: 0,
          id: this.offcanvasProductDetailId,
          title: "PIM product",
          label: "PIM product",
          class: "offcanvas-wide",
          "custom-content": ""
        }, {
          default: _withCtx(() => [
            (this.selectedProductDto?.cnk_code)
              ? (_openBlock(), _createBlock(_component_ProductSelectionProductsProductDetail, {
                  key: 0,
                  "cnk-code": Number(this.selectedProductDto.cnk_code)
                }, null, 8, ["cnk-code"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["id"]))
      : _createCommentVNode("", true)
  ], 64))
}