
import {defineComponent} from "vue";
import CpAbstractsOverview from "@/components/layouts/content-pages/overview/CpAbstractsOverview.vue";
import {TextWithImageAbstractRestDto} from "@/api/pharma-cms-content-page/models";
import {mapActions, mapGetters} from "vuex";
import {truncateHtml} from "@/helpers/functions/string";
import {StatusEnum} from "@/api/enums/status-enum";

export default defineComponent({
  name: "BaseLinkPickerInternalLink",
  components: {CpAbstractsOverview},
  emits: ["clear", "set", "exceptions"],
  data() {
    return {
      docType: 'PAGE_IMG' as string,
      signedUrl: null as string | null,
    };
  },
  props: {
    id: {
      type: String,
      required: false
    },
    linkedContentPage: {
      type: Object,
      required: true
    },
    hasInternalPageLink: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters('cms_document', ['getPreviewDocument']),
    linkedPageName(): string | null {
      if (this.hasInternalPageLink) {
        return this.linkedContentPage.name;
      }
      return null;
    },
    linkedPageOverview(): TextWithImageAbstractRestDto | null {
      return this.linkedContentPage.overview_cb
    },
    modalId(): string {
      const modalId = 'modalCpOverview';
      if (this.id) {
        return modalId + this.id;
      }
      return modalId;
    },
    previewContent(): string | null {
      if (this.linkedPageOverview && this.linkedPageOverview.content) {
        return truncateHtml(this.linkedPageOverview.content, 300);
      }
      return null;
    },
    hasLinkedPageOverview(): boolean {
      // @ts-ignore
      return this.linkedPageOverview && (this.linkedPageOverview.title || this.linkedPageOverview.content || this.linkedPageOverview.image_key);
    },
    isDeleted(): boolean {
      return this.linkedContentPage.is_page_deleted;
    },
    isNotPublished(): boolean {
      return this.linkedContentPage.status_code === StatusEnum.NOT_PUBLISHED;
    }
  },
  methods: {
    ...mapActions('cms_document', ['searchPreviewDocument']),
    clearPageId() {
      this.$emit("clear");
    },
    setPageId(page_id: string) {
      this.$emit("set", page_id);
    }
  }
})
