/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 * Generated: Thu, 29 Jun 2023 12:19:55 +0000
 */
export enum HeaderContent1LayoutEnum {
    MAIN_PHARMACY_ONLY = 'MAIN_PHARMACY_ONLY',
    ALL_PHARMACIES_HORIZONTAL = 'ALL_PHARMACIES_HORIZONTAL',
    ALL_PHARMACIES_VERTICAL = 'ALL_PHARMACIES_VERTICAL',
}