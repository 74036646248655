/*
* Overview of available enums: https://gitlab.com/digital-pharma-dp3/pharma-customer-product-catalog/-/tree/master/application/src/Model/Enum
*/
export enum EnumClassNamePimEnum {
    TEXT_FRAGMENT_NAME = "TextFragmentNameEnum",
    PIM_PRODUCT_FIELD = "PimProductFieldEnum",
    PIM_TRADEMARK = "DenumProductTrademark",
    PIM_TARGET_GROUP = "DenumTargetGroup",
    PIM_UNIT = "DenumUnit",
}
