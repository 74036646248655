<template>
  <div>
    <label v-if="label" :for="name" class="form-label fw-bold">
      {{ label }}
      <span v-if="isRequired" class="text-danger">*</span>
    </label>

    <div class="form-control" :class="{ 'is-invalid': errors[name] }">
      <CodeEditor
        v-model="value"
        @update:modelValue="updateValue"
        :name="name"
        :id="name"
        class="w-100"
        :languages="[['html', 'HTML']]"
        :theme="'light'"
        :wrap_code="true"
        :font_size="'1rem'"
      ></CodeEditor>
    </div>
    <dp-error-message class="invalid-feedback d-block" :name="name"></dp-error-message>
  </div>
</template>

<script>
// TODO add code editor lib
// https://ourcodeworld.com/articles/read/309/top-5-best-code-editor-plugins-written-in-javascript
import {computed, watch} from "vue";
import {useField} from 'vee-validate';
import CodeEditor from 'simple-code-editor';

export default {
  name: "InputHtmlTest",
  components: {
    CodeEditor
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      required: true,
      default: '',
    },
    errors: {
      type: Object,
    },
    height: {
      type: Number,
      default: 300,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    rules: {
      type: String,
    },
  },
  setup(props, context) {
    const {value} = useField(props.name, props.rules, {initialValue: props.modelValue ?? ''});

    const isRequired = computed(() => {
      return props.rules && props.rules.includes("required");
    });

    function updateValue(value) {
      context.emit('update:modelValue', value);
    }

    watch(
      () => props.modelValue, (v) => {
        value.value = v;
      }
    );

    return {
      value,
      isRequired,
      updateValue,
    };
  },
};
</script>

<style scoped lang="scss">
.form-control {
  padding: unset;
  border-color: transparent;
  border-radius: inherit;

  &.is-invalid {
    padding: inherit;
    border-color: var(--dp-danger);
  }
}
</style>
