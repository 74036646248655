import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row gx-3" }
const _hoisted_2 = {
  key: 0,
  class: "col-auto fw-bold small text-uppercase text-muted mb-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_TilesItem = _resolveComponent("TilesItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseSpinner, {
      spin: this.ui.isNotReady()
    }, null, 8, ["spin"]),
    (this.ui.isReady() && !this.hasProducts)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('noResults')), 1))
      : (this.ui.isReady() && this.hasProducts)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(this.productsOverview.results, (product) => {
            return (_openBlock(), _createElementBlock("div", {
              key: product.id,
              class: "col-4 col-xl-3 col-xxl-2"
            }, [
              _createVNode(_component_TilesItem, {
                product: product,
                class: "mb-3",
                onOpenOffCanvasProductDetail: product => this.$emit('openOffCanvasProductDetail', product)
              }, null, 8, ["product", "onOpenOffCanvasProductDetail"])
            ]))
          }), 128))
        : _createCommentVNode("", true)
  ]))
}