
import {defineComponent} from "vue";
import {Exception} from "@/api/interfaces";
import store from "@/store";
import {mapGetters} from "vuex";
import {DpException} from "@/exception/DpException";
import CbFormTextWithTitle from "@/components/layouts/content-blocks/forms/CbFormTextWithTitle.vue";
import {
  convertProductCodesArrayToList,
  convertProductCodesListToArray,
  productCodesArrayIsValid
} from "@/helpers/functions/products";
import InputTextEditor from "@/components/UI/InputTextEditor.vue";

export default defineComponent({
  name: "ProductsInTheSpotlight",
  components: {InputTextEditor, CbFormTextWithTitle},
  data: () => ({
    isReady: false as Boolean,
    filterCode: 'PRODUCTS_IN_THE_SPOTLIGHT',
    error: null,
    exceptions: [] as Exception[],
    savedSuccessfully: false as Boolean,
    maxProductsInTheSpotlight: 20 as Number,
    productsInTheSpotlight: '' as string,
  }),
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters(['getCurrentCustomerCode']),
    ...mapGetters('cpc_mgmt', ['getCnkListProductFilter']),
    currentCustomerCode(): String {
      return this.getCurrentCustomerCode;
    },
  },
  methods: {
    submitData() {
      this.saveProductsInTheSpotlight();
    },
    async reloadContent() {
      try {
        this.isReady = false;

        await this.searchProductFilter();
        this.productsInTheSpotlight = convertProductCodesArrayToList(this.getCnkListProductFilter.cnk_codes);

        this.isReady = true;
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async saveProductsInTheSpotlight() {
      this.isReady = false;

      try {
        const productsInTheSpotlightArray = convertProductCodesListToArray(this.productsInTheSpotlight) as string[];

        if (productsInTheSpotlightArray.length > this.maxProductsInTheSpotlight) {
          this.isReady = true;
          //TODO: ASK proper vue error handling
          this.exceptions = [new DpException(this.$t('errorTooManyProductsInTheSpotlight'))];
        } else if (!productCodesArrayIsValid(productsInTheSpotlightArray)) {
          this.isReady = true;
          //TODO: ASK proper vue error handling
          this.exceptions = [new DpException(this.$t('exceptionOnlyNumbersCnk'))];
        } else {
          await store.dispatch('cpc_mgmt/saveCnkListProductFilter', {
            body: productsInTheSpotlightArray,
            filter_code: this.filterCode,
          });

          await this.reloadContent().then(() => {
            this.exceptions = [];
            this.savedSuccessfully = true;
            setTimeout(() => this.savedSuccessfully = false, 2000);
          });
        }
      } catch (exceptions: any) {
        this.isReady = true;
        this.exceptions = exceptions;
      }
    },
    async searchProductFilter() {
      try {
        await store.dispatch('cpc_mgmt/searchCnkListProductFilter', this.filterCode)
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
  }
});
