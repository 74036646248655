
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {Exception} from "@/api/interfaces";
import {arrayHasContent} from "@/helpers/functions/arrays";
import {ContentBlockTypesEnum} from "@/helpers/functions/enum";
import {LibraryOverviewRestDto} from "@/api/pharma-cms-content-block/models";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {DpException} from "@/exception/DpException";
import {LibraryStructureTypeEnum} from "@/models/enum/LibraryStructureTypeEnum";
import {CodeDescriptionRestDto, PageLibraryOverviewRestDto} from "@/api/pharma-cms-content-page/models";

export default defineComponent({
  name: "LibrariesAvailable",
  components: {BaseTitle},
  data() {
    return {
      exceptions: [] as Exception[],
      isReady: false as boolean,
      cbStructure: this.$route.params.cb_structure as string,
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cms_content_block', ["getContentBlockLibsForType"]),
    ...mapGetters('cms_content_page', ["getPageAbstractLibraries", "getContentPageLibrariesForContentProvider"]),
    articleAbstractLibs(): CodeDescriptionRestDto[] {
      return this.getPageAbstractLibraries;
    },
    contentBlockLibs(): LibraryOverviewRestDto[] {
      return this.getContentBlockLibsForType(this.cbStructure);
    },
    contentPageLibs(): PageLibraryOverviewRestDto[] {
      return this.getContentPageLibrariesForContentProvider;
    },
    hasContentBlockLibs(): boolean {
      switch (this.structureType) {
        case LibraryStructureTypeEnum.ARTICLE_ABSTRACT:
          return arrayHasContent(this.articleAbstractLibs);
        case LibraryStructureTypeEnum.CONTENT_PAGE:
          return arrayHasContent(this.contentPageLibs);
        case LibraryStructureTypeEnum.CONTENT_BLOCK:
          return arrayHasContent(this.contentBlockLibs);
        default:
          return false;
      }
    },
    structureType(): LibraryStructureTypeEnum {
      switch (this.cbStructure) {
        case LibraryStructureTypeEnum.ARTICLE_ABSTRACT:
          return LibraryStructureTypeEnum.ARTICLE_ABSTRACT;
        case LibraryStructureTypeEnum.CONTENT_PAGE:
          return LibraryStructureTypeEnum.CONTENT_PAGE;
        case ContentBlockTypesEnum.BANNER:
        case ContentBlockTypesEnum.IMAGE:
        case ContentBlockTypesEnum.LOGO:
        case ContentBlockTypesEnum.TEXT_WITH_IMAGE:
        case ContentBlockTypesEnum.TEXT_WITH_TITLE:
          return LibraryStructureTypeEnum.CONTENT_BLOCK;
        default:
          throw [new DpException('Unrecognized cb structure: ' + this.cbStructure)];
      }
    },
    title(): string {
      switch (this.cbStructure) {
        case LibraryStructureTypeEnum.ARTICLE_ABSTRACT:
          return this.$t('librariesArticleAbstract');
        case LibraryStructureTypeEnum.CONTENT_PAGE:
          return this.$t('librariesContentPage');
        case ContentBlockTypesEnum.BANNER:
          return this.$t('librariesBanner');
        case ContentBlockTypesEnum.IMAGE:
          return this.$t('librariesImage');
        case ContentBlockTypesEnum.LOGO:
          return this.$t('librariesLogo');
        case ContentBlockTypesEnum.TEXT_WITH_IMAGE:
          return this.$t('librariesTextWithImage');
        case ContentBlockTypesEnum.TEXT_WITH_TITLE:
          return this.$t('librariesTextWithTitle');
        default:
          return this.$t('library');
      }
    },
    ContentBlockTypesEnum() {
      return ContentBlockTypesEnum
    },
    LibraryStructureTypeEnum() {
      return LibraryStructureTypeEnum
    },
  },
  methods: {
    ...mapActions('cms_content_block', ["searchContentBlockLibsForType"]),
    ...mapActions('cms_content_page', ["searchPageAbstractLibraries", "searchContentPageLibrariesForContentProvider"]),
    async reloadContent() {
      this.isReady = false;
      this.exceptions = [];

      try {
        switch (this.structureType) {
          case LibraryStructureTypeEnum.ARTICLE_ABSTRACT:
            await this.searchPageAbstractLibraries(true);
            break;
          case LibraryStructureTypeEnum.CONTENT_PAGE:
            await this.searchContentPageLibrariesForContentProvider();
            break;
          case LibraryStructureTypeEnum.CONTENT_BLOCK:
            await this.searchContentBlockLibsForType({
              type: this.cbStructure,
              filter_is_owner: true
            });
            break;
        }
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      window.scrollTo(0, 0);
      this.isReady = true;
    }
  }
});
