
import {defineComponent, PropType} from "vue";
import {ProductFilterConditionRestDto, ProductFilterRestDto} from "@/api/pharma-cpc-mgmt/models";
import {determineProductFilterConditionType} from "@/helpers/functions/catalog";
import ProductFilter from "@/components/layouts/catalog/product-filter/ProductFilter.vue";
import {mapActions} from "vuex";
import {ProductFilterApplyTypeEnum} from "@/models/enum/ProductFilterApplyTypeEnum";
import DiscountPricelistSection from "@/components/layouts/catalog/discounts-pricelists/DiscountPriceListSection.vue";

export default defineComponent({
  name: "ProductFilters",
  components: {DiscountPricelistSection, ProductFilter},
  props: {
    applyOnType: {
      type: String as PropType<ProductFilterApplyTypeEnum>,
      required: true
    },
    isAllProductsFilterAllowed: {
      type: Boolean,
      required: false,
      default: false
    },
    productFilter: {
      type: Object,
      required: true
    },
    //TODO check which exact type errors is
    errors: [Array, Object, String],
  },
  computed: {
    ProductFilterApplyTypeEnum() {
      return ProductFilterApplyTypeEnum;
    },
    computedProductFilter(): ProductFilterRestDto {
      return this.productFilter as ProductFilterRestDto;
    },
    productFilterConditions(): ProductFilterConditionRestDto[] {
      if (!this.productFilter.filter_conditions) {
        return [] as ProductFilterConditionRestDto[];
      }
      return this.productFilter.filter_conditions;
    },
    usedFilterConditionTypes(): string[] {
      let usedFilterConditionTypes = [] as string[];
      this.productFilterConditions.forEach((filter_condition: ProductFilterConditionRestDto) => {
        usedFilterConditionTypes.push(determineProductFilterConditionType(filter_condition));
      })

      return usedFilterConditionTypes;
    },
  },
  methods: {
    ...mapActions('cpc_mgmt', [
      'addFilterCondition',
    ]),
  }
});
