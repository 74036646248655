import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-left col-12 col-xl-8 mb-3 mb-xl-0" }
const _hoisted_3 = { class: "row row-name-cnk mt-2 mb-3" }
const _hoisted_4 = { class: "col-9" }
const _hoisted_5 = { class: "product-name" }
const _hoisted_6 = { class: "col-3" }
const _hoisted_7 = { class: "product-cnk" }
const _hoisted_8 = { class: "row row-description mb-3" }
const _hoisted_9 = { class: "col-8" }
const _hoisted_10 = { class: "product-description" }
const _hoisted_11 = { class: "col-4" }
const _hoisted_12 = { class: "product-image" }
const _hoisted_13 = {
  for: "product-file-input",
  class: "form-label fw-700"
}
const _hoisted_14 = { class: "col-right col-12 col-xl-4 col-xxl-3 offset-xxl-1" }
const _hoisted_15 = { class: "row row-price mb-3" }
const _hoisted_16 = { class: "col-3 col-xl" }
const _hoisted_17 = { class: "product-price-with-vat" }
const _hoisted_18 = { class: "col-3 col-xl" }
const _hoisted_19 = { class: "product-vat-percentage" }
const _hoisted_20 = { class: "row row-categories mb-3" }
const _hoisted_21 = { class: "col-9 col-xl" }
const _hoisted_22 = { class: "fs-6 fw-700 mb-2" }
const _hoisted_23 = { class: "row row-trademark mb-3" }
const _hoisted_24 = { class: "col-4 col-xl-7" }
const _hoisted_25 = { class: "product-usage d-flex align-items-center" }
const _hoisted_26 = { class: "row row-usage mb-3" }
const _hoisted_27 = { class: "col-4 col-xl-7" }
const _hoisted_28 = { class: "product-usage d-flex align-items-center" }
const _hoisted_29 = { class: "d-inline-block mt-3 pt-4 border-top border-2 border-light" }
const _hoisted_30 = {
  type: "submit",
  class: "btn btn-primary"
}
const _hoisted_31 = {
  key: 0,
  class: "spinner-border spinner-border-sm ms-2",
  role: "status",
  "aria-hidden": "true"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_text = _resolveComponent("input-text")!
  const _component_input_text_editor = _resolveComponent("input-text-editor")!
  const _component_input_file = _resolveComponent("input-file")!
  const _component_input_number = _resolveComponent("input-number")!
  const _component_SelectCategories = _resolveComponent("SelectCategories")!
  const _component_input_select = _resolveComponent("input-select")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_dp_form = _resolveComponent("dp-form")!

  return (_ctx.isReady)
    ? (_openBlock(), _createBlock(_component_dp_form, {
        key: 0,
        as: "form",
        onSubmit: _ctx.submitData,
        novalidate: ""
      }, {
        default: _withCtx(({ errors }) => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("section", _hoisted_5, [
                    _createVNode(_component_input_text, {
                      modelValue: _ctx.product.name,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.product.name) = $event)),
                      modelModifiers: { trim: true },
                      label: _ctx.$t('name'),
                      name: "name",
                      rules: "required|max:100",
                      errors: errors
                    }, null, 8, ["modelValue", "label", "errors"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("section", _hoisted_7, [
                    _createVNode(_component_input_text, {
                      modelValue: _ctx.product.cnk_code,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.product.cnk_code) = $event)),
                      modelModifiers: { trim: true },
                      label: _ctx.$t('cnkCode'),
                      name: "cnkCode",
                      rules: "required|min:6|max:8",
                      disabled: _ctx.mode === 'edit',
                      errors: errors
                    }, null, 8, ["modelValue", "label", "disabled", "errors"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("section", _hoisted_10, [
                    _createVNode(_component_input_text_editor, {
                      modelValue: _ctx.product.description.nl,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.product.description.nl) = $event)),
                      label: _ctx.$t('description'),
                      name: _ctx.$t('description'),
                      errors: errors
                    }, null, 8, ["modelValue", "label", "name", "errors"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("section", _hoisted_12, [
                    _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t('productImage')), 1),
                    _createVNode(_component_input_file, {
                      id: "product-file-input",
                      "is-cpc": "",
                      existingDocumentKey: _ctx.existingDocumentKey,
                      "type-code": _ctx.docType,
                      onImageUploaded: _ctx.setImageKey,
                      onImageCleared: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setImageKey(null)))
                    }, null, 8, ["existingDocumentKey", "type-code", "onImageUploaded"])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("section", _hoisted_17, [
                    _createVNode(_component_input_number, {
                      modelValue: _ctx.product.price_with_vat,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.product.price_with_vat) = $event)),
                      mode: "group-start",
                      "addon-text": "€",
                      label: _ctx.$t('priceInclVat'),
                      name: _ctx.$t('priceInclVat'),
                      step: 0.01,
                      min: 0,
                      rules: "required",
                      errors: errors
                    }, null, 8, ["modelValue", "label", "name", "step", "errors"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("section", _hoisted_19, [
                    _createVNode(_component_input_number, {
                      modelValue: _ctx.product.vat_percentage,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.product.vat_percentage) = $event)),
                      mode: "group-end",
                      "addon-text": "%",
                      label: _ctx.$t('vatPercentage'),
                      name: _ctx.$t('vatPercentage'),
                      step: 0.01,
                      min: 0,
                      rules: "required",
                      errors: errors
                    }, null, 8, ["modelValue", "label", "name", "step", "errors"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t('productCategories')), 1),
                  _createVNode(_component_SelectCategories, {
                    "selected-product-categories": _ctx.product.category_codes ?? [],
                    onAddCategory: _ctx.addCategory,
                    onRemoveCategory: _ctx.removeCategory
                  }, null, 8, ["selected-product-categories", "onAddCategory", "onRemoveCategory"])
                ])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("section", _hoisted_25, [
                    _createVNode(_component_input_select, {
                      modelValue: _ctx.product.trademark_code,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.product.trademark_code) = $event)),
                      label: _ctx.$t('trademark'),
                      name: _ctx.$t('trademark'),
                      selectText: _ctx.$t('selectText'),
                      options: _ctx.trademarkOptions,
                      "can-clear": "",
                      errors: errors
                    }, null, 8, ["modelValue", "label", "name", "selectText", "options", "errors"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("section", _hoisted_28, [
                    _createVNode(_component_input_select, {
                      modelValue: _ctx.product.usage_type_code,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.product.usage_type_code) = $event)),
                      label: _ctx.$t('usage'),
                      name: _ctx.$t('usage'),
                      selectText: _ctx.$t('selectText'),
                      options: _ctx.usageTypeOptions,
                      "can-clear": "",
                      errors: errors
                    }, null, 8, ["modelValue", "label", "name", "selectText", "options", "errors"])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_29, [
            _createVNode(_component_input_select, {
              modelValue: _ctx.product.status_code,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.product.status_code) = $event)),
              name: "status",
              selectText: _ctx.$t('selectText'),
              options: _ctx.statusOptions,
              rules: "required",
              errors: errors
            }, null, 8, ["modelValue", "selectText", "options", "errors"]),
            _createElementVNode("button", _hoisted_30, [
              _createTextVNode(_toDisplayString(_ctx.$t('save')) + " ", 1),
              (_ctx.isSubmitting)
                ? (_openBlock(), _createElementBlock("span", _hoisted_31))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_router_link, {
              to: {name: _ctx.returnRoute},
              class: "ms-3 btn btn-outline-primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"]))
    : _createCommentVNode("", true)
}