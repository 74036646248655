
import {defineComponent} from "vue";
import config from '@/../public/config.json';
import store from "@/store";
import LocaleChanger from "@/components/UI/LocaleChanger.vue";
import {AuthContext} from "@/context/AuthContext";
import {CustomerContext} from "@/context/CustomerContext";
import {UtilityHelper} from "@/helpers/UtilityHelper";
import {TrainingsRestService} from "@/services/rest/trainings/TrainingsRestService";
import {mapGetters} from "vuex";
import {CustomerDomainOverviewRestDto} from "@/api/pharma-customer-settings/models";
import {AbilityContext} from "@/context/AbilityContext";

export default defineComponent({
  name: "TheSecondaryMenu",
  components: {LocaleChanger},
  data: () => ({
    bohUrl: config.bohUrl,
    trainingsData: null as Object | null,
  }),
  computed: {
    AbilityContext() {
      return AbilityContext
    },
    ...mapGetters(['isAuthenticated']),
    ...mapGetters('customer_settings', ['getCustomerMainDomain']),
    AuthContext() {
      return AuthContext;
    },
    CustomerContext() {
      return CustomerContext;
    },
    UtilityHelper() {
      return UtilityHelper;
    },
    customerMainDomain(): CustomerDomainOverviewRestDto | null {
      return this.getCustomerMainDomain ?? null;
    },
    isShowTrainingAndBoh(): boolean {
      return AbilityContext.isAuthorizedForAnyLicense('DP_WEBSITE_STARTER', 'DP_WEBSITE_BASIC', 'DP_WEBSITE_PREMIUM', 'PHARMACOLLECT_APB', 'PHARMACOLLECT_TEMPLATE', 'PHARMACOLLECT_PLUS_TEMPLATE', 'PHARMACOLLECT_PLUS_APB', 'DP_PHARMACY_GROUP_WEBSITE');
    }
  },
  mounted(): void {
    this.findTrainingsData();
  },
  methods: {
    async findTrainingsData(): Promise<void> {
      this.trainingsData = await TrainingsRestService.getInstance().findTrainingsData();
    },
    async login(): Promise<void> {
      await store.dispatch('login');
    },
    logout(): void {
      store.dispatch('logout');
      this.$router.replace('/home');
    },
  },
});
