/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum PaddingTopEnum {
    P_0 = 'P_0',
    P_1 = 'P_1',
    P_2 = 'P_2',
    P_3 = 'P_3',
    P_4 = 'P_4',
    P_5 = 'P_5',
}