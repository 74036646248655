import {RestService} from "@/services/rest/RestService";
import {CodeDescriptionRestDto} from "@/api/pharma-order-settings/models";
import {EnumEndpointsApi as OrderEnumEndpointsApi} from "@/api/pharma-order-enum";
import {EnumEndpointsApi as CpcEnumEndpointsApi} from "@/api/pharma-cpc-enum";
import {EnumEndpointsApi as CmsEnumEndpointsApi} from "@/api/pharma-cms-enum";
import {EnumContentRestDtoModel} from "@/models/api/pharma-order-enum/EnumContentRestDtoModel";
import {MultilingualFieldRestDto} from "@/api/pharma-order-enum/models";
import {UpdateEnumContentRestDtoModel} from "@/models/api/pharma-order-enum/UpdateEnumContentRestDtoModel";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import {CacheService} from "@/services/cache/CacheService";
import {EnumClassNameCpcEnum} from "@/models/enum/EnumClassNameCpcEnum";

export class EnumRestService extends RestService {

    private orderEnumApi: OrderEnumEndpointsApi;
    private cpcEnumApi: CpcEnumEndpointsApi;
    private cmsEnumApi: CmsEnumEndpointsApi;

    private static readonly ORDER_ENUM_PREFIX: string = "order_enum_";
    private static readonly CPC_ENUM_PREFIX: string = "cpc_enum_";
    private static readonly CMS_ENUM_PREFIX: string = "cms_enum_";


    protected constructor() {
        super();

        this.orderEnumApi = new OrderEnumEndpointsApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().orderUrl);
        this.cpcEnumApi = new CpcEnumEndpointsApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cpcUrl);
        this.cmsEnumApi = new CmsEnumEndpointsApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cmsUrl);
    }

    public async getOrderEnumDescription(enumClassName: string, enumCode: string): Promise<string> {
        const values = await this.getOrderEnumValuesByClassname(enumClassName);

        for (const val of values) {
            if (val.code === enumCode) {
                return (val.description === undefined || val.description === null ? '' : val.description);
            }
        }

        return '';
    }

    public async getOrderEnumValuesByClassname(enumClassName: string): Promise<CodeDescriptionRestDto[]> {
        let values = CacheService.getInstance().get(EnumRestService.ORDER_ENUM_PREFIX + enumClassName) as CodeDescriptionRestDto[] | undefined;
        if (!values) {
            const response = await this.orderEnumApi.getOrderEnumValuesByClassname(enumClassName);
            values = response.data;
            CacheService.getInstance().set(EnumRestService.ORDER_ENUM_PREFIX + enumClassName, values);
        }

        return values;
    }

    public async getOrderEnumContentForType(pharmacyCode: string | null, enumClassName: string, enumCode: string, typeCode: string): Promise<EnumContentRestDtoModel> {
        const response = await this.orderEnumApi.getOrderEnumContentForType(enumClassName, enumCode, typeCode, null, pharmacyCode);

        return response.data;
    }

    public async updateOrderEnumContentForType(pharmacyCode: string | null, content: MultilingualFieldRestDto, enumClassName: string, enumCode: string, typeCode: string): Promise<void> {
        const updateDto = UpdateEnumContentRestDtoModel.createWithDefaults()
        updateDto.pharmacy_code = pharmacyCode
        updateDto.content = content;

        await this.orderEnumApi.updateOrderEnumContentForType(updateDto, enumClassName, enumCode, typeCode);
    }

    public async updateOrderEnumFallbackContentForType(content: MultilingualFieldRestDto, enumClassName: string, enumCode: string, typeCode: string): Promise<void> {
        const updateDto = UpdateEnumContentRestDtoModel.createWithDefaults()
        updateDto.content = content;

        await this.orderEnumApi.updateOrderEnumFallbackContentForType(updateDto, enumClassName, enumCode, typeCode);
    }

    public async getCmsEnumValuesByClassname(enumClassName: EnumClassNameCmsEnum): Promise<CodeDescriptionRestDto[]> {
        let values = CacheService.getInstance().get(EnumRestService.CMS_ENUM_PREFIX + enumClassName) as CodeDescriptionRestDto[] | undefined;
        if (!values) {
            const response = await this.cmsEnumApi.getCmsEnumValuesByClassname(enumClassName);
            values = response.data;
            CacheService.getInstance().set(EnumRestService.CMS_ENUM_PREFIX + enumClassName, values);
        }

        return values;
    }

    public async getCpcEnumValuesByClassname(enumClassName: EnumClassNameCpcEnum): Promise<CodeDescriptionRestDto[]> {
        let values = CacheService.getInstance().get(EnumRestService.CPC_ENUM_PREFIX + enumClassName) as CodeDescriptionRestDto[] | undefined;
        if (!values) {
            const response = await this.cpcEnumApi.getCpcEnumValuesByClassname(enumClassName);
            values = response.data;
            CacheService.getInstance().set(EnumRestService.CPC_ENUM_PREFIX + enumClassName, values);
        }

        return values;
    }
}
