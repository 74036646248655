
import {defineComponent} from 'vue'
import {Pp2ProductOverviewRestDtoModel} from "@/models/api/pharma-pim-pp2/Pp2ProductOverviewRestDtoModel";
import PimProductsOverviewTableItemPreview from "@/components/layouts/pim/PimProductsOverviewTableItemPreview.vue";
import ItemIcons from "@/components/layouts/pim/PimProductsOverviewItemIcons.vue";
import {AbilityContext} from "@/context/AbilityContext";
import {mapActions, mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import {PimCategorizationRestService} from "@/services/rest/pim-category/PimCategorizationRestService";
import {stringHasContent} from "@/helpers/functions/string";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import {numberHasContent} from "@/helpers/functions/number";
import {CnkListRestDto} from "@/api/pharma-pim-category/models";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import ConfirmModal from "@/components/UI/ConfirmModal.vue";

export default defineComponent({
  name: "PimProductsOverviewTableItem",
  emits: ["deletedProduct", "selectedIntCat", "selectedDpCat", "exceptions", "openOffCanvasProductDetail"],
  components: {ItemIcons, PimProductsOverviewTableItemPreview},
  props: {
    product: {
      type: [Pp2ProductOverviewRestDtoModel, Object],
      required: true
    },
    isCategoryExpected: {
      type: Boolean,
      required: false
    },
  },
  data() {
    return {
      showPreview: false as boolean
    }
  },
  computed: {
    ...mapState(useCustomerStore, ["canCategorizePimProducts", "isPimProductSelectedForCategorization", "getCriteria"]),
    AbilityContext() {
      return AbilityContext
    },
    canDelete(): boolean {
      return this.product?.is_removable && this.canCategorizePimProducts;
    },
    isSelectedForCategorization: {
      get(): boolean {
        return this.isPimProductSelectedForCategorization(this.product.product_info.cnk_code);
      },
      set(value: boolean): void {
        this.setPimProductSelectedForCategorization(value, this.product.product_info.cnk_code);
      },
    }
  },
  methods: {
    ...mapActions(useCustomerStore, ["setPimProductSelectedForCategorization"]),
    selectMultipleForCategorization(event: any) {
      console.log('select multiple', event);
    },
    emitSelectedDpCat(dpCatCode: string): void {
      this.$emit('selectedDpCat', dpCatCode);
    },
    emitSelectedIntCat(intCatCode: string): void {
      this.$emit('selectedIntCat', intCatCode);
    },
    async deleteProductFromCategorization(): Promise<void> {
      try {
        const cnkList = {cnk_codes: [this.product.product_info.cnk_code]} as CnkListRestDto;

        // todo translate
        const {reveal, onConfirm} = createConfirmDialog(ConfirmModal, {
          title: 'Categorisatie verwijderen',
          body: 'Ben je zeker dat je de categorisatie wenst te verwijderen?',
          confirmButtonText: this.$t('delete'),
          confirmButtonClass: 'btn-danger',
        });

        onConfirm(async () => {
          if (!stringHasContent(this.getCriteria.pim_products.filter_category_code_dp_managed) && stringHasContent(this.getCriteria.pim_products.filter_category_code_internal)) {
            // DELETE from internal category
            await PimCategorizationRestService.getInstance()
              .removeProductsFromInternalCategory(cnkList, this.getCriteria.pim_products.filter_category_code_internal);
          } else if (stringHasContent(this.getCriteria.pim_products.filter_category_code_dp_managed) && !stringHasContent(this.getCriteria.pim_products.filter_category_code_internal)) {
            // DELETE from dp managed category
            await PimCategorizationRestService.getInstance()
              .removeProductsFromDpCategory(cnkList, this.getCriteria.pim_products.filter_category_code_dp_managed);
          } else {
            throw 'Either filter_category_code_dp_managed or filter_category_code_internal has to be set';
          }

          await handleSavedSuccessfully(this.$t('deletedSuccessfully'));
          this.$emit('deletedProduct');
        });

        await reveal();
      } catch (exceptions: unknown) {
        this.$emit('exceptions', exceptions);
      }
    },
    openOffCanvasProductDetail(): void {
      if (AbilityContext.isAuthorizedForFeature('PIM_PRODUCT_INFO') && this.product.product_info.cnk_code) {
        this.$emit("openOffCanvasProductDetail", this.product);
      }
    },
  }
})
