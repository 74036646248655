import {CustomerDomainSettingsEndpointsApiService} from "@/main";
import store from "@/store";
import {CustomerDomainOverviewRestDto} from "@/api/pharma-customer-settings/models/customer-domain-overview-rest-dto";
import {LanguageSettingsRestDto} from "@/api/pharma-content-customer/models";
import {ContentCustomerRestService} from "@/services/rest/content-customer/ContentCustomerRestService";
import {CodeDescriptionRestDto} from "@/api/pharma-license-license-info/models";
import {ProductFeatureRestService} from "@/services/rest/license-license-info/ProductFeatureRestService";

export default {
    namespaced: true,
    state() {
        return {
            customer_main_domain: null as CustomerDomainOverviewRestDto|null,
            customer_language_settings: null as LanguageSettingsRestDto|null,
            customer_main_license: null as CodeDescriptionRestDto|null,
        };
    },
    actions: {
        async searchCustomerMainDomain(context: any, customer_code: string) {
            if (!customer_code) {
                customer_code = context.rootGetters.getCurrentCustomerCode;
            }

            const response = await CustomerDomainSettingsEndpointsApiService.findCustomerMainDomain(customer_code);

            if (response.status === 200) {
                store.commit('customer_settings/setCustomerMainDomain', response.data);
            } else if (response.status === 204) {
                store.commit('customer_settings/setCustomerMainDomain', undefined);
            }
        },
        async searchCustomerMainLicense(context: any, customer_code: string) {
            if (!customer_code) {
                customer_code = context.rootGetters.getCurrentCustomerCode;
            }

            const licenseDto: CodeDescriptionRestDto = await ProductFeatureRestService.getInstance()
                .findMainProductLicense();
            store.commit('customer_settings/setCustomerMainLicense', licenseDto);
        },
        async searchCustomerLanguageSettings(context: any, customer_code: string|null = null) {
            if (customer_code === null) {
                customer_code = context.rootGetters.getCurrentCustomerCode;
            }

            context.state.customer_language_settings = await ContentCustomerRestService.getInstance().findSupportedLanguages() as LanguageSettingsRestDto;
        },
    },
    mutations: {
        setCustomerMainDomain(state: any, payload: any) {
            store.state.customer_main_domain = payload;
        },
        setCustomerMainLicense(state: any, payload: any) {
            store.state.customer_main_license = payload;
        },
    },
    getters: {
        getCustomerMainDomain(): CustomerDomainOverviewRestDto|null {
            return store.state.customer_main_domain;
        },
        getCustomerMainLicense(): CodeDescriptionRestDto|null {
            return store.state.customer_main_license;
        },
        getCustomerLanguageSettingsOrFail(state: any): LanguageSettingsRestDto {
            const dto = state.customer_language_settings as LanguageSettingsRestDto;

            if (dto === null) {
                throw new Error('Customer language settings not fetched, yet.');
            }

            return dto;
        },
    },
};
