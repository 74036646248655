
import {defineComponent} from "vue";

export default defineComponent({
  name: "MenusOverviewItemReferenceInfo",
  props: {
    pageReference: {
      type: Object,
      required: true,
    }
  },
});
