import {POSITION, useToast} from "vue-toastification";
import i18n from "@/modules/i18n";

export class UtilityHelper {
    public static async copyToClipboard(value: string): Promise<void> {
        await navigator.clipboard.writeText(value);

        useToast().info(i18n.global.t('copied') + '!', {
            position: POSITION.TOP_LEFT,
            timeout: 1000,
        });
    }

    public static async copyInnerTextToClipboard(event: Event): Promise<void> {
        const el = event.target as Element;

        await this.copyToClipboard(el.textContent ?? '');
    }

    public static consoleLog(value: any): void {
        console.log(value);
    }

    public static emptyToNull(value: string|null|undefined): string|null {
        if (value === undefined || value === null || value === '') {
            return null;
        }

        return value;
    }
}
