import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mb-4" }
const _hoisted_3 = { class: "row align-items-center" }
const _hoisted_4 = { class: "col-4" }
const _hoisted_5 = { class: "col-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_InputSelect = _resolveComponent("InputSelect")!
  const _component_dp_form = _resolveComponent("dp-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseSpinner, {
      spin: !this.isReady
    }, null, 8, ["spin"]),
    _withDirectives(_createVNode(_component_dp_form, {
      as: "form",
      onSubmit: _ctx.submitData,
      novalidate: ""
    }, {
      default: _withCtx(({ errors }) => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_InputSelect, {
                  modelValue: this.contentBlock.height_code,
                  "onUpdate:modelValue": [
                    _cache[0] || (_cache[0] = ($event: any) => ((this.contentBlock.height_code) = $event)),
                    this.onChange
                  ],
                  options: this.enumHeightCode,
                  label: _ctx.$t('height'),
                  name: "heightCode",
                  selectText: _ctx.$t('selectText'),
                  rules: "required",
                  errors: errors
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "label", "selectText", "errors"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_InputSelect, {
                  modelValue: this.contentBlock.background_color_code,
                  "onUpdate:modelValue": [
                    _cache[1] || (_cache[1] = ($event: any) => ((this.contentBlock.background_color_code) = $event)),
                    this.onChange
                  ],
                  options: this.enumBackgroundColorCode,
                  label: _ctx.$t('cms.background'),
                  name: "backgroundColorCode",
                  selectText: _ctx.$t('selectText'),
                  rules: "required",
                  errors: errors
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "label", "selectText", "errors"])
              ])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["onSubmit"]), [
      [_vShow, this.isReady]
    ])
  ], 64))
}