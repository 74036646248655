/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Customer-Product-Catalog
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CatalogBuildStatusRestDto } from '../models';
import { CodeDescriptionRestDto } from '../models';
import { PagedBrandSelectionOverviewRestDto } from '../models';
import { PagedProductSelectionOverviewRestDto } from '../models';
import { PagedTrademarkSelectionOverviewRestDto } from '../models';
import { ProductImportSettingsRestDto } from '../models';
import { ProductSelectionTypeRestDto } from '../models';
import { PublicationStatusRestDto } from '../models';
/**
 * CatalogSettingsAPIApi - axios parameter creator
 * @export
 */
export const CatalogSettingsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for building the customer specific product catalog.
         * @summary REST.API.CPC.CM.15 Build catalogus.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildCatalog: async (x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer_mgmt/v1/catalog/build`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for fetching the details of the product selection settings of a customer specific product catalog.
         * @summary REST.API.CPC.CM.02 Fetch product selection settings.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductSelectionBase: async (x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer_mgmt/v1/catalog/product_selection/base`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for fetching the details of the product selection settings for brands of a customer specific product catalog.
         * @summary REST.API.CPC.CM.03 Fetch product selection settings for brands.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [search_brand_name] Search criterium, find products with a brand that matches the search string.
         * @param {string} [filter_selection_type_code] Indicates if products should be explicitly included, excluded or follow the base selection.
         * @param {number} [page] Specifies the page number that is expected of the paged result.
         * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductSelectionBrands: async (x_dp_customer_code?: string|null, search_brand_name?: string|null, filter_selection_type_code?: string|null, page?: number|null, page_size?: number|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer_mgmt/v1/catalog/product_selection/brand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!search_brand_name || typeof(search_brand_name) === "boolean") {
                localVarQueryParameter['search_brand_name'] = search_brand_name;
            }

            if (!!filter_selection_type_code || typeof(filter_selection_type_code) === "boolean") {
                localVarQueryParameter['filter_selection_type_code'] = filter_selection_type_code;
            }

            if (!!page || typeof(page) === "boolean") {
                localVarQueryParameter['page'] = page;
            }

            if (!!page_size || typeof(page_size) === "boolean") {
                localVarQueryParameter['page_size'] = page_size;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for fetching the details of the product selection settings for products of a customer specific product catalog.
         * @summary REST.API.CPC.CM.12 Fetch product selection settings for products.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_selection_type_code] Indicates if products should be explicitly included, excluded or follow the base selection.
         * @param {string} [filter_usage_type_code] Indicates if the usage type of the product.
         * @param {string} [filter_cnk_code] The cnk code of the product.
         * @param {boolean} [filter_is_medicine] Indication if this product is considered a medicine.
         * @param {boolean} [filter_is_medical_aid] Indication if this product is considered a medicical aid.
         * @param {boolean} [filter_is_prescription_required] Indication if a prescription is required for this product.
         * @param {string} [search_brand_name] Search criterium, find products with a brand that matches the search string.
         * @param {string} [search_labo_name] Search criterium, find products with a labo that matches the search string.
         * @param {string} [search_trademark_name] Search criterium, find products with a trademark that matches the search string.
         * @param {string} [search_product_name] Search criterium, find products with a name that matches the search string.
         * @param {number} [page] Specifies the page number that is expected of the paged result.
         * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductSelectionProducts: async (x_dp_customer_code?: string|null, filter_selection_type_code?: string|null, filter_usage_type_code?: string|null, filter_cnk_code?: string|null, filter_is_medicine?: boolean|null, filter_is_medical_aid?: boolean|null, filter_is_prescription_required?: boolean|null, search_brand_name?: string|null, search_labo_name?: string|null, search_trademark_name?: string|null, search_product_name?: string|null, page?: number|null, page_size?: number|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer_mgmt/v1/catalog/product_selection/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_selection_type_code || typeof(filter_selection_type_code) === "boolean") {
                localVarQueryParameter['filter_selection_type_code'] = filter_selection_type_code;
            }

            if (!!filter_usage_type_code || typeof(filter_usage_type_code) === "boolean") {
                localVarQueryParameter['filter_usage_type_code'] = filter_usage_type_code;
            }

            if (!!filter_cnk_code || typeof(filter_cnk_code) === "boolean") {
                localVarQueryParameter['filter_cnk_code'] = filter_cnk_code;
            }

            if (!!filter_is_medicine || typeof(filter_is_medicine) === "boolean") {
                localVarQueryParameter['filter_is_medicine'] = filter_is_medicine;
            }

            if (!!filter_is_medical_aid || typeof(filter_is_medical_aid) === "boolean") {
                localVarQueryParameter['filter_is_medical_aid'] = filter_is_medical_aid;
            }

            if (!!filter_is_prescription_required || typeof(filter_is_prescription_required) === "boolean") {
                localVarQueryParameter['filter_is_prescription_required'] = filter_is_prescription_required;
            }

            if (!!search_brand_name || typeof(search_brand_name) === "boolean") {
                localVarQueryParameter['search_brand_name'] = search_brand_name;
            }

            if (!!search_labo_name || typeof(search_labo_name) === "boolean") {
                localVarQueryParameter['search_labo_name'] = search_labo_name;
            }

            if (!!search_trademark_name || typeof(search_trademark_name) === "boolean") {
                localVarQueryParameter['search_trademark_name'] = search_trademark_name;
            }

            if (!!search_product_name || typeof(search_product_name) === "boolean") {
                localVarQueryParameter['search_product_name'] = search_product_name;
            }

            if (!!page || typeof(page) === "boolean") {
                localVarQueryParameter['page'] = page;
            }

            if (!!page_size || typeof(page_size) === "boolean") {
                localVarQueryParameter['page_size'] = page_size;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for fetching the details of the product selection settings for trademarks of a customer specific product catalog.
         * @summary REST.API.CPC.CM.16 Fetch product selection settings for trademarks.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [search_trademark_name] Search criterium, find products with a trademark that matches the search string.
         * @param {string} [filter_selection_type_code] Indicates if products should be explicitly included, excluded or follow the base selection.
         * @param {number} [page] Specifies the page number that is expected of the paged result.
         * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductSelectionTrademarks: async (x_dp_customer_code?: string|null, search_trademark_name?: string|null, filter_selection_type_code?: string|null, page?: number|null, page_size?: number|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer_mgmt/v1/catalog/product_selection/trademark`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!search_trademark_name || typeof(search_trademark_name) === "boolean") {
                localVarQueryParameter['search_trademark_name'] = search_trademark_name;
            }

            if (!!filter_selection_type_code || typeof(filter_selection_type_code) === "boolean") {
                localVarQueryParameter['filter_selection_type_code'] = filter_selection_type_code;
            }

            if (!!page || typeof(page) === "boolean") {
                localVarQueryParameter['page'] = page;
            }

            if (!!page_size || typeof(page_size) === "boolean") {
                localVarQueryParameter['page_size'] = page_size;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for fetching the build status of the customer specific product catalog.
         * @summary REST.API.CPC.CM.14 Get build catalog status.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCatalogBuildStatus: async (x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer_mgmt/v1/catalog/build_status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for fetching the status of the customer specific product catalog.
         * @summary REST.API.CPC.CM.10 Get catalog status.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCatalogStatus: async (x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer_mgmt/v1/catalog/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for changing the status of the customer specific product catalog.
         * @summary REST.API.CPC.CM.13 Update catalog status.
         * @param {PublicationStatusRestDto} body 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCatalogStatus: async (body: PublicationStatusRestDto, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateCatalogStatus.');
            }
            const localVarPath = `/customer_mgmt/v1/catalog/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for changing the product selection settings of a customer specific product catalog. 
         * @summary REST.API.CPC.CM.01 Update product selection settings.
         * @param {ProductImportSettingsRestDto} body 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductSelectionBase: async (body: ProductImportSettingsRestDto, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateProductSelectionBase.');
            }
            const localVarPath = `/customer_mgmt/v1/catalog/product_selection/base`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for adding and removing a brand from the product selection settings of a customer specific product catalog. 
         * @summary REST.API.CPC.CM.04 Add or remove brand from product selection.
         * @param {ProductSelectionTypeRestDto} body 
         * @param {string} brand_code The unique identification of the brand.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductSelectionBrand: async (body: ProductSelectionTypeRestDto, brand_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateProductSelectionBrand.');
            }
            // verify required parameter 'brand_code' is not null or undefined
            if (brand_code === null || brand_code === undefined) {
                throw new RequiredError('brand_code','Required parameter brand_code was null or undefined when calling updateProductSelectionBrand.');
            }
            const localVarPath = `/customer_mgmt/v1/catalog/product_selection/brand/{brand_code}`
                .replace(`{${"brand_code"}}`, encodeURIComponent(String(brand_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for adding or removing a product from the product selection settings of a customer specific product catalog. 
         * @summary REST.API.CPC.CM.11 Add or remove product from product selection.
         * @param {ProductSelectionTypeRestDto} body 
         * @param {string} product_code The unique identification of the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductSelectionProduct: async (body: ProductSelectionTypeRestDto, product_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateProductSelectionProduct.');
            }
            // verify required parameter 'product_code' is not null or undefined
            if (product_code === null || product_code === undefined) {
                throw new RequiredError('product_code','Required parameter product_code was null or undefined when calling updateProductSelectionProduct.');
            }
            const localVarPath = `/customer_mgmt/v1/catalog/product_selection/product/{product_code}`
                .replace(`{${"product_code"}}`, encodeURIComponent(String(product_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for adding and removing a trademark from the product selection settings of a customer specific product catalog. 
         * @summary REST.API.CPC.CM.17 Add or remove trademark from product selection.
         * @param {ProductSelectionTypeRestDto} body 
         * @param {string} trademark_code The unique identification of the trademark.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductSelectionTrademark: async (body: ProductSelectionTypeRestDto, trademark_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateProductSelectionTrademark.');
            }
            // verify required parameter 'trademark_code' is not null or undefined
            if (trademark_code === null || trademark_code === undefined) {
                throw new RequiredError('trademark_code','Required parameter trademark_code was null or undefined when calling updateProductSelectionTrademark.');
            }
            const localVarPath = `/customer_mgmt/v1/catalog/product_selection/trademark/{trademark_code}`
                .replace(`{${"trademark_code"}}`, encodeURIComponent(String(trademark_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CatalogSettingsAPIApi - functional programming interface
 * @export
 */
export const CatalogSettingsAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for building the customer specific product catalog.
         * @summary REST.API.CPC.CM.15 Build catalogus.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buildCatalog(_axios: AxiosInstance, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CatalogSettingsAPIApiAxiosParamCreator(configuration).buildCatalog(x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for fetching the details of the product selection settings of a customer specific product catalog.
         * @summary REST.API.CPC.CM.02 Fetch product selection settings.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProductSelectionBase(_axios: AxiosInstance, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductImportSettingsRestDto>> {
            const localVarAxiosArgs = await CatalogSettingsAPIApiAxiosParamCreator(configuration).findProductSelectionBase(x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for fetching the details of the product selection settings for brands of a customer specific product catalog.
         * @summary REST.API.CPC.CM.03 Fetch product selection settings for brands.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [search_brand_name] Search criterium, find products with a brand that matches the search string.
         * @param {string} [filter_selection_type_code] Indicates if products should be explicitly included, excluded or follow the base selection.
         * @param {number} [page] Specifies the page number that is expected of the paged result.
         * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProductSelectionBrands(_axios: AxiosInstance, x_dp_customer_code?: string|null, search_brand_name?: string|null, filter_selection_type_code?: string|null, page?: number|null, page_size?: number|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedBrandSelectionOverviewRestDto>> {
            const localVarAxiosArgs = await CatalogSettingsAPIApiAxiosParamCreator(configuration).findProductSelectionBrands(x_dp_customer_code, search_brand_name, filter_selection_type_code, page, page_size, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for fetching the details of the product selection settings for products of a customer specific product catalog.
         * @summary REST.API.CPC.CM.12 Fetch product selection settings for products.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_selection_type_code] Indicates if products should be explicitly included, excluded or follow the base selection.
         * @param {string} [filter_usage_type_code] Indicates if the usage type of the product.
         * @param {string} [filter_cnk_code] The cnk code of the product.
         * @param {boolean} [filter_is_medicine] Indication if this product is considered a medicine.
         * @param {boolean} [filter_is_medical_aid] Indication if this product is considered a medicical aid.
         * @param {boolean} [filter_is_prescription_required] Indication if a prescription is required for this product.
         * @param {string} [search_brand_name] Search criterium, find products with a brand that matches the search string.
         * @param {string} [search_labo_name] Search criterium, find products with a labo that matches the search string.
         * @param {string} [search_trademark_name] Search criterium, find products with a trademark that matches the search string.
         * @param {string} [search_product_name] Search criterium, find products with a name that matches the search string.
         * @param {number} [page] Specifies the page number that is expected of the paged result.
         * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProductSelectionProducts(_axios: AxiosInstance, x_dp_customer_code?: string|null, filter_selection_type_code?: string|null, filter_usage_type_code?: string|null, filter_cnk_code?: string|null, filter_is_medicine?: boolean|null, filter_is_medical_aid?: boolean|null, filter_is_prescription_required?: boolean|null, search_brand_name?: string|null, search_labo_name?: string|null, search_trademark_name?: string|null, search_product_name?: string|null, page?: number|null, page_size?: number|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedProductSelectionOverviewRestDto>> {
            const localVarAxiosArgs = await CatalogSettingsAPIApiAxiosParamCreator(configuration).findProductSelectionProducts(x_dp_customer_code, filter_selection_type_code, filter_usage_type_code, filter_cnk_code, filter_is_medicine, filter_is_medical_aid, filter_is_prescription_required, search_brand_name, search_labo_name, search_trademark_name, search_product_name, page, page_size, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for fetching the details of the product selection settings for trademarks of a customer specific product catalog.
         * @summary REST.API.CPC.CM.16 Fetch product selection settings for trademarks.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [search_trademark_name] Search criterium, find products with a trademark that matches the search string.
         * @param {string} [filter_selection_type_code] Indicates if products should be explicitly included, excluded or follow the base selection.
         * @param {number} [page] Specifies the page number that is expected of the paged result.
         * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProductSelectionTrademarks(_axios: AxiosInstance, x_dp_customer_code?: string|null, search_trademark_name?: string|null, filter_selection_type_code?: string|null, page?: number|null, page_size?: number|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedTrademarkSelectionOverviewRestDto>> {
            const localVarAxiosArgs = await CatalogSettingsAPIApiAxiosParamCreator(configuration).findProductSelectionTrademarks(x_dp_customer_code, search_trademark_name, filter_selection_type_code, page, page_size, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for fetching the build status of the customer specific product catalog.
         * @summary REST.API.CPC.CM.14 Get build catalog status.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCatalogBuildStatus(_axios: AxiosInstance, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogBuildStatusRestDto>> {
            const localVarAxiosArgs = await CatalogSettingsAPIApiAxiosParamCreator(configuration).getCatalogBuildStatus(x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for fetching the status of the customer specific product catalog.
         * @summary REST.API.CPC.CM.10 Get catalog status.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCatalogStatus(_axios: AxiosInstance, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodeDescriptionRestDto>> {
            const localVarAxiosArgs = await CatalogSettingsAPIApiAxiosParamCreator(configuration).getCatalogStatus(x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for changing the status of the customer specific product catalog.
         * @summary REST.API.CPC.CM.13 Update catalog status.
         * @param {PublicationStatusRestDto} body 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCatalogStatus(_axios: AxiosInstance, body: PublicationStatusRestDto, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CatalogSettingsAPIApiAxiosParamCreator(configuration).updateCatalogStatus(body, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for changing the product selection settings of a customer specific product catalog. 
         * @summary REST.API.CPC.CM.01 Update product selection settings.
         * @param {ProductImportSettingsRestDto} body 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProductSelectionBase(_axios: AxiosInstance, body: ProductImportSettingsRestDto, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CatalogSettingsAPIApiAxiosParamCreator(configuration).updateProductSelectionBase(body, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for adding and removing a brand from the product selection settings of a customer specific product catalog. 
         * @summary REST.API.CPC.CM.04 Add or remove brand from product selection.
         * @param {ProductSelectionTypeRestDto} body 
         * @param {string} brand_code The unique identification of the brand.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProductSelectionBrand(_axios: AxiosInstance, body: ProductSelectionTypeRestDto, brand_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CatalogSettingsAPIApiAxiosParamCreator(configuration).updateProductSelectionBrand(body, brand_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for adding or removing a product from the product selection settings of a customer specific product catalog. 
         * @summary REST.API.CPC.CM.11 Add or remove product from product selection.
         * @param {ProductSelectionTypeRestDto} body 
         * @param {string} product_code The unique identification of the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProductSelectionProduct(_axios: AxiosInstance, body: ProductSelectionTypeRestDto, product_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CatalogSettingsAPIApiAxiosParamCreator(configuration).updateProductSelectionProduct(body, product_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for adding and removing a trademark from the product selection settings of a customer specific product catalog. 
         * @summary REST.API.CPC.CM.17 Add or remove trademark from product selection.
         * @param {ProductSelectionTypeRestDto} body 
         * @param {string} trademark_code The unique identification of the trademark.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProductSelectionTrademark(_axios: AxiosInstance, body: ProductSelectionTypeRestDto, trademark_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CatalogSettingsAPIApiAxiosParamCreator(configuration).updateProductSelectionTrademark(body, trademark_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CatalogSettingsAPIApi - object-oriented interface
 * @export
 * @class CatalogSettingsAPIApi
 * @extends {BaseAPI}
 */
export class CatalogSettingsAPIApi extends BaseAPI {
    /**
     * API endpoint responsible for building the customer specific product catalog.
     * @summary REST.API.CPC.CM.15 Build catalogus.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogSettingsAPIApi
     */
     public buildCatalog(x_dp_customer_code?: string|null, options?: any) {
        return CatalogSettingsAPIApiFp(this.configuration).buildCatalog(this.axios, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for fetching the details of the product selection settings of a customer specific product catalog.
     * @summary REST.API.CPC.CM.02 Fetch product selection settings.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogSettingsAPIApi
     */
     public findProductSelectionBase(x_dp_customer_code?: string|null, options?: any) {
        return CatalogSettingsAPIApiFp(this.configuration).findProductSelectionBase(this.axios, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for fetching the details of the product selection settings for brands of a customer specific product catalog.
     * @summary REST.API.CPC.CM.03 Fetch product selection settings for brands.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [search_brand_name] Search criterium, find products with a brand that matches the search string.
     * @param {string} [filter_selection_type_code] Indicates if products should be explicitly included, excluded or follow the base selection.
     * @param {number} [page] Specifies the page number that is expected of the paged result.
     * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogSettingsAPIApi
     */
     public findProductSelectionBrands(x_dp_customer_code?: string|null, search_brand_name?: string|null, filter_selection_type_code?: string|null, page?: number|null, page_size?: number|null, options?: any) {
        return CatalogSettingsAPIApiFp(this.configuration).findProductSelectionBrands(this.axios, x_dp_customer_code, search_brand_name, filter_selection_type_code, page, page_size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for fetching the details of the product selection settings for products of a customer specific product catalog.
     * @summary REST.API.CPC.CM.12 Fetch product selection settings for products.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [filter_selection_type_code] Indicates if products should be explicitly included, excluded or follow the base selection.
     * @param {string} [filter_usage_type_code] Indicates if the usage type of the product.
     * @param {string} [filter_cnk_code] The cnk code of the product.
     * @param {boolean} [filter_is_medicine] Indication if this product is considered a medicine.
     * @param {boolean} [filter_is_medical_aid] Indication if this product is considered a medicical aid.
     * @param {boolean} [filter_is_prescription_required] Indication if a prescription is required for this product.
     * @param {string} [search_brand_name] Search criterium, find products with a brand that matches the search string.
     * @param {string} [search_labo_name] Search criterium, find products with a labo that matches the search string.
     * @param {string} [search_trademark_name] Search criterium, find products with a trademark that matches the search string.
     * @param {string} [search_product_name] Search criterium, find products with a name that matches the search string.
     * @param {number} [page] Specifies the page number that is expected of the paged result.
     * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogSettingsAPIApi
     */
     public findProductSelectionProducts(x_dp_customer_code?: string|null, filter_selection_type_code?: string|null, filter_usage_type_code?: string|null, filter_cnk_code?: string|null, filter_is_medicine?: boolean|null, filter_is_medical_aid?: boolean|null, filter_is_prescription_required?: boolean|null, search_brand_name?: string|null, search_labo_name?: string|null, search_trademark_name?: string|null, search_product_name?: string|null, page?: number|null, page_size?: number|null, options?: any) {
        return CatalogSettingsAPIApiFp(this.configuration).findProductSelectionProducts(this.axios, x_dp_customer_code, filter_selection_type_code, filter_usage_type_code, filter_cnk_code, filter_is_medicine, filter_is_medical_aid, filter_is_prescription_required, search_brand_name, search_labo_name, search_trademark_name, search_product_name, page, page_size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for fetching the details of the product selection settings for trademarks of a customer specific product catalog.
     * @summary REST.API.CPC.CM.16 Fetch product selection settings for trademarks.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [search_trademark_name] Search criterium, find products with a trademark that matches the search string.
     * @param {string} [filter_selection_type_code] Indicates if products should be explicitly included, excluded or follow the base selection.
     * @param {number} [page] Specifies the page number that is expected of the paged result.
     * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogSettingsAPIApi
     */
     public findProductSelectionTrademarks(x_dp_customer_code?: string|null, search_trademark_name?: string|null, filter_selection_type_code?: string|null, page?: number|null, page_size?: number|null, options?: any) {
        return CatalogSettingsAPIApiFp(this.configuration).findProductSelectionTrademarks(this.axios, x_dp_customer_code, search_trademark_name, filter_selection_type_code, page, page_size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for fetching the build status of the customer specific product catalog.
     * @summary REST.API.CPC.CM.14 Get build catalog status.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogSettingsAPIApi
     */
     public getCatalogBuildStatus(x_dp_customer_code?: string|null, options?: any) {
        return CatalogSettingsAPIApiFp(this.configuration).getCatalogBuildStatus(this.axios, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for fetching the status of the customer specific product catalog.
     * @summary REST.API.CPC.CM.10 Get catalog status.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogSettingsAPIApi
     */
     public getCatalogStatus(x_dp_customer_code?: string|null, options?: any) {
        return CatalogSettingsAPIApiFp(this.configuration).getCatalogStatus(this.axios, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for changing the status of the customer specific product catalog.
     * @summary REST.API.CPC.CM.13 Update catalog status.
     * @param {PublicationStatusRestDto} body 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogSettingsAPIApi
     */
     public updateCatalogStatus(body: PublicationStatusRestDto, x_dp_customer_code?: string|null, options?: any) {
        return CatalogSettingsAPIApiFp(this.configuration).updateCatalogStatus(this.axios, body, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for changing the product selection settings of a customer specific product catalog. 
     * @summary REST.API.CPC.CM.01 Update product selection settings.
     * @param {ProductImportSettingsRestDto} body 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogSettingsAPIApi
     */
     public updateProductSelectionBase(body: ProductImportSettingsRestDto, x_dp_customer_code?: string|null, options?: any) {
        return CatalogSettingsAPIApiFp(this.configuration).updateProductSelectionBase(this.axios, body, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for adding and removing a brand from the product selection settings of a customer specific product catalog. 
     * @summary REST.API.CPC.CM.04 Add or remove brand from product selection.
     * @param {ProductSelectionTypeRestDto} body 
     * @param {string} brand_code The unique identification of the brand.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogSettingsAPIApi
     */
     public updateProductSelectionBrand(body: ProductSelectionTypeRestDto, brand_code: string, x_dp_customer_code?: string|null, options?: any) {
        return CatalogSettingsAPIApiFp(this.configuration).updateProductSelectionBrand(this.axios, body, brand_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for adding or removing a product from the product selection settings of a customer specific product catalog. 
     * @summary REST.API.CPC.CM.11 Add or remove product from product selection.
     * @param {ProductSelectionTypeRestDto} body 
     * @param {string} product_code The unique identification of the product.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogSettingsAPIApi
     */
     public updateProductSelectionProduct(body: ProductSelectionTypeRestDto, product_code: string, x_dp_customer_code?: string|null, options?: any) {
        return CatalogSettingsAPIApiFp(this.configuration).updateProductSelectionProduct(this.axios, body, product_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for adding and removing a trademark from the product selection settings of a customer specific product catalog. 
     * @summary REST.API.CPC.CM.17 Add or remove trademark from product selection.
     * @param {ProductSelectionTypeRestDto} body 
     * @param {string} trademark_code The unique identification of the trademark.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogSettingsAPIApi
     */
     public updateProductSelectionTrademark(body: ProductSelectionTypeRestDto, trademark_code: string, x_dp_customer_code?: string|null, options?: any) {
        return CatalogSettingsAPIApiFp(this.configuration).updateProductSelectionTrademark(this.axios, body, trademark_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
