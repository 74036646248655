import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09fa3023"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-dp-tooltip"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["product-item-badge overflow-visible mas mas-fill-1 fs-6", this.tooltipInfo.class]),
    "data-dp-tooltip": this.tooltipInfo.title,
    "data-dp-position": "top"
  }, "circle", 10, _hoisted_1))
}