
import {mapActions} from "vuex";
import {defineComponent} from "vue";
import {
  ProductFilterConditionRestDto,
  ProductFilterCriteriumTrademarkRestDto,
} from "@/api/pharma-cpc-mgmt/models";
import {DpException} from "@/exception/DpException";
import {PropType} from "vue/dist/vue";
import {ProductFilterApplyTypeEnum} from "@/models/enum/ProductFilterApplyTypeEnum";
import SelectTrademarks from "@/components/layouts/catalog/SelectTrademarks.vue";
import {SelectionTypeEnum} from "@/api/enums/selection-type-enum";

export default defineComponent({
  name: "ProductFilterTrademarks",
  components: {SelectTrademarks},
  props: {
    applyOnType: {
      type: String as PropType<ProductFilterApplyTypeEnum>,
      required: true
    },
    filterIndex: {
      type: Number,
      required: true,
    },
    filterConditionObject: {
      type: Object,
      required: true,
    },
    selectionType: {
      type: String,
      required: true,
    },
  },
  computed: {
    filterCondition(): ProductFilterConditionRestDto {
      return this.filterConditionObject;
    },
    productTrademarkFilters(): ProductFilterCriteriumTrademarkRestDto[] {
      return this.filterCondition.product_trademark_filters ?? [];
    },
    productTrademarkFilterCodes(): string[] {
      const productTrademarkFilterCodes = [] as string[];
      for (const productTrademarkFilter of this.productTrademarkFilters) {
        if (productTrademarkFilter.selection_type_code === this.selectionTypeCode) {
          productTrademarkFilterCodes.push(productTrademarkFilter.trademark_code);
        }
      }
      return productTrademarkFilterCodes;
    },
    selectionTypeCode(): SelectionTypeEnum {
      switch (this.selectionType.toUpperCase()) {
        case 'INCLUDE':
          return SelectionTypeEnum.INCLUDE;
        case 'EXCLUDE':
          return SelectionTypeEnum.EXCLUDE;
        default:
          throw [new DpException('Unsupported selectionType to search for discount trademark filter')];
      }
    }
  },
  methods: {
    ...mapActions('cpc_mgmt', ['addProductTrademarkFilter', 'removeProductTrademarkFilter']),
    addTrademark(trademark_code: string) {
      this.addProductTrademarkFilter({
        filter_index: this.filterIndex,
        product_trademark_filter: {
          selection_type_code: this.selectionTypeCode,
          trademark_code: trademark_code,
        } as ProductFilterCriteriumTrademarkRestDto,
        apply_on_type: this.applyOnType
      });
    },
    removeTrademark(trademark_code: string) {
      this.removeProductTrademarkFilter({
        filter_index: this.filterIndex,
        product_trademark_filter: {
          selection_type_code: this.selectionTypeCode,
          trademark_code: trademark_code,
        } as ProductFilterCriteriumTrademarkRestDto,
        apply_on_type: this.applyOnType
      });
    },
  }
});
