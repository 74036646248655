import store from '@/store';

export function registerCustomerCodeInterceptor(instance) {
  instance.interceptors.request.use(
    (config) => {
      let customerCode = store.getters.getCurrentCustomerCode;
        if (! customerCode) {
            customerCode = 'APO_99991_77c86596-4ed0-4deb-bf8a-42cfcd9728ab';
        }
        // const customerCode = 'APO_99991_77c86596-4ed0-4deb-bf8a-42cfcd9728ab';
      if (customerCode) {
        config.headers['x-dp-customer-code'] = customerCode;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
}
