import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row gx-5 mb-4" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = {
  key: 0,
  class: "row"
}
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = { class: "d-inline-block mt-3 pt-4 border-top border-2 border-light" }
const _hoisted_8 = {
  type: "submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_file = _resolveComponent("input-file")!
  const _component_input_select = _resolveComponent("input-select")!
  const _component_dp_form = _resolveComponent("dp-form")!
  const _component_cb_form = _resolveComponent("cb-form")!

  return (_openBlock(), _createBlock(_component_cb_form, {
    "cb-type": this.cbType,
    exceptions: this.exceptions,
    "can-copy-from-library": this.canCopyFromLibrary,
    "is-library": this.isLibrary,
    "is-ready": this.isReady,
    mode: this.mode,
    "return-route": this.returnRoute,
    "saved-successfully": _ctx.savedSuccessfully,
    title: this.title ?? null,
    description: this.description,
    description2: this.description2,
    description3: this.description3,
    description4: this.description4
  }, {
    default: _withCtx(() => [
      _createVNode(_component_dp_form, {
        onSubmit: _ctx.submitData,
        as: "form",
        novalidate: ""
      }, {
        default: _withCtx(({ errors }) => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_input_file, {
                onImageUploaded: _ctx.setDocumentKey,
                onImageCleared: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setDocumentKey(null))),
                id: "document-file-input",
                existingDocumentKey: _ctx.existingDocumentKey,
                typeCode: this.docType
              }, null, 8, ["onImageUploaded", "existingDocumentKey", "typeCode"])
            ])
          ]),
          (_ctx.canRestoreDefaultValues)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("button", {
                    onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.restoreDefaultValues && _ctx.restoreDefaultValues(...args)), ["prevent"])),
                    class: "btn btn-secondary mb-3"
                  }, _toDisplayString(_ctx.$t('restoreDefaultValues')), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                (_ctx.isPublishable)
                  ? (_openBlock(), _createBlock(_component_input_select, {
                      key: 0,
                      name: "cbStatus",
                      class: "fw-bold",
                      selectText: _ctx.$t('selectText'),
                      modelValue: _ctx.contentBlock.status_code,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.contentBlock.status_code) = $event)),
                      options: _ctx.statusOptions,
                      errors: errors
                    }, null, 8, ["selectText", "modelValue", "options", "errors"]))
                  : _createCommentVNode("", true),
                _createElementVNode("button", _hoisted_8, _toDisplayString(_ctx.$t('save')), 1),
                (_ctx.isContentChanged)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.restoreInitialValue && _ctx.restoreInitialValue(...args))),
                      class: "ms-3 btn btn btn-outline-primary"
                    }, _toDisplayString(_ctx.$t('cancel')), 1))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }, 8, ["cb-type", "exceptions", "can-copy-from-library", "is-library", "is-ready", "mode", "return-route", "saved-successfully", "title", "description", "description2", "description3", "description4"]))
}