
import {defineComponent} from "vue";
import {Exception} from "@/api/interfaces";
import {mapActions, mapGetters} from "vuex";
import {NameValuePairOverviewRestDto} from "@/api/pharma-order-settings/models";
import ability from "@/config/ability";
import {OrderSettingsNameValuePairEnum} from "@/models/enum/OrderSettingsNameValuePairEnum";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import AlertSuccess from "@/components/UI/Bootstrap/Alert/AlertSuccess.vue";
import AlertError from "@/components/UI/Bootstrap/Alert/AlertError.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";

export default defineComponent({
  name: "OrderMails",
  components: {BaseSpinner, AlertError, AlertSuccess, BaseTitle},
  data: () => ({
    error: null,
    exceptions: [] as Exception[],
    isReady: false as boolean,
    savedSuccessfully: false as boolean,
  }),
  computed: {
    ...mapGetters("order_settings", ["getSingleNameValuePair"]),
    nvpOrderConfirmationEmailBCC(): NameValuePairOverviewRestDto {
      return this.getSingleNameValuePair(OrderSettingsNameValuePairEnum.ORDER_CONFIRMATION_EMAIL_BCC);
    },
    nvpOrderNotificationEmailReplyTo(): NameValuePairOverviewRestDto {
      return this.getSingleNameValuePair(OrderSettingsNameValuePairEnum.ORDER_NOTIFICATION_EMAIL_REPLY_TO);
    },
    isMultiPharmacy(): boolean {
      return ability.can('use-feature', 'MULTI_PHARMACY');
    },
    hasEmailSettings(): boolean {
      return !(!this.nvpOrderConfirmationEmailBCC || !this.nvpOrderNotificationEmailReplyTo);
    }
  },
  mounted() {
    this.reloadContent();
  },
  methods: {
    ...mapActions("order_settings", [
      "searchNameValuePairs",
      "searchNameValuePairsPharmacy",
      "saveNameValuePairsBulk",
      "saveNameValuePairPharmacy"
    ]),
    submitData() {
      this.saveNameValuePairs();
    },
    async reloadContent() {
      this.isReady = false;
      this.exceptions = [];

      try {
        if (this.isMultiPharmacy) {
          await this.searchNameValuePairsPharmacy();
        } else {
          await this.searchNameValuePairs();
        }
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      this.isReady = true;
    },
    async saveNameValuePairs() {
      this.exceptions = [];

      try {
        if (this.isMultiPharmacy) {
          await Promise.all([
            this.saveNameValuePairPharmacy(OrderSettingsNameValuePairEnum.ORDER_CONFIRMATION_EMAIL_BCC),
            this.saveNameValuePairPharmacy(OrderSettingsNameValuePairEnum.ORDER_NOTIFICATION_EMAIL_REPLY_TO)
          ])
        } else {
          await this.saveNameValuePairsBulk();
        }

        window.scrollTo(0, 0);
        await this.reloadContent();
        this.savedSuccessfully = true;
        setTimeout(() => this.savedSuccessfully = false, 2000);
      } catch (exception: any) {
        this.exceptions = exception;
      }
    },
  },
})
