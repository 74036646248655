
import {defineComponent} from 'vue'
import {convertProductCodesListToArray, productCodesArrayIsValid} from "@/helpers/functions/products";
import {DpException} from "@/exception/DpException";
import InputTextEditor from "@/components/UI/InputTextEditor.vue";

export default defineComponent({
  name: "InputCnkList",
  components: {InputTextEditor},
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Array,
      required: true,
      default: [] as string[],
    },
    errors: {
      type: Object,
      required: false,
    },
    fieldLabel: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      internalModel: '<p>' + this.modelValue.join(', ') + '</p>',
    };
  },
  watch: {
    modelValue: function (newVal) {
      this.internalModel = '<p>' + newVal.join(', ') + '</p>';
    },
  },
  methods: {
    onBlur() {
      const result = convertProductCodesListToArray(this.internalModel);
      if (!productCodesArrayIsValid(result)) {
        // throw [new DpException(useI18n().t('exceptionOnlyNumbersCnk')]);
        throw [new DpException('Een CNK code mag enkel getallen bevatten.')];
      }
      this.$emit('update:modelValue', result);
    },
  },
});
