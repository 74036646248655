
import {defineComponent} from "vue";
import {TextWithImageAbstractRestDto} from "@/api/pharma-cms-content-page/models";
import {mapActions, mapGetters} from "vuex";
import ButtonContentPagePreview from "@/components/layouts/content-pages/ButtonContentPagePreview.vue";
import {UtilityRestService} from "@/services/rest/cms-utility/UtilityRestService";

export default defineComponent({
  name: "CpAbstractsOverviewItem",
  components: {ButtonContentPagePreview},
  emits: ["exceptions", "selectedCp"],
  data() {
    return {
      docType: 'PAGE_IMG' as string,
      signedUrl: null as string | null,

      contentPageAbstractContent: '' as string
    }
  },
  props: {
    contentPage: {
      type: Object,
      required: true,
    },
    isLibrary: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted() {
    this.fetchSignedUrl();
    if (this.contentPageAbstract) {
      this.truncateContent();
    }
  },
  watch: {
    contentPageAbstract(newValue) {
      if (newValue) {
        this.truncateContent();
      }
    }
  },
  computed: {
    ...mapGetters('cms_document', ['getPreviewDocument']),
    contentPageAbstract(): TextWithImageAbstractRestDto {
      return this.contentPage.cp_abstract;
    }
  },
  methods: {
    ...mapActions('cms_document', ['searchPreviewDocument']),
    async fetchSignedUrl() {
      try {
        if (this.contentPageAbstract?.image_key) {
          await this.searchPreviewDocument({
            document_type: this.docType,
            document_key: this.contentPageAbstract.image_key,
          });
          this.signedUrl = this.getPreviewDocument(this.docType).url;
        }
      } catch (exceptions: any) {
        this.$emit('exceptions', exceptions);
      }
    },
    async truncateContent(): Promise<void> {
      this.contentPageAbstractContent = await UtilityRestService.getInstance()
        .truncateHtml(this.contentPageAbstract?.content ?? '', 300);
    }
  }
})
