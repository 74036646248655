import {
    ImageRestDto
} from "@/api/pharma-cms-content-block/models";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import {ImageWidthEnum} from "@/api/enums/image-width-enum";
import {ImageTargetEnum} from "@/api/enums/image-target-enum";
import {BorderWidthEnum} from "@/api/enums/border-width-enum";
import {BorderTypeEnum} from "@/api/enums/border-type-enum";
import {LightboxLayoutEnum} from "@/api/enums/lightbox-layout-enum";

export class ImageRestDtoModel implements ImageRestDto {
    border_type_code: BorderTypeEnum;
    border_width_code?: BorderWidthEnum;
    image_key: MultilingualRestDtoModel;
    image_page_id?: MultilingualRestDtoModel;
    image_target?: ImageTargetEnum;
    image_url?: MultilingualRestDtoModel;
    image_width_code: ImageWidthEnum;
    lightbox_layout_code: LightboxLayoutEnum;
    title?: MultilingualRestDtoModel;

    constructor(border_type_code: BorderTypeEnum, image_key: MultilingualRestDtoModel, image_width_code: ImageWidthEnum, lightbox_layout_code: LightboxLayoutEnum) {
        this.border_type_code = border_type_code;
        this.image_key = image_key;
        this.image_width_code = image_width_code;
        this.lightbox_layout_code = lightbox_layout_code;
    }

    public static createWithDefaults(): ImageRestDtoModel {
        const model = new ImageRestDtoModel(
            BorderTypeEnum.NONE,
            MultilingualRestDtoModel.createWithDefaults(),
            ImageWidthEnum.W_AUTO,
            LightboxLayoutEnum.NONE
        );

        model.image_key = MultilingualRestDtoModel.createWithDefaults();
        model.title = MultilingualRestDtoModel.createWithDefaults();

        return model;
    }
}
