import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LibContentBlocksItemBanner = _resolveComponent("LibContentBlocksItemBanner")!
  const _component_LibContentBlocksItemContentBlock = _resolveComponent("LibContentBlocksItemContentBlock")!

  return (['BANNER', 'BANNER_IN_SLOT'].includes(this.libType))
    ? (_openBlock(), _createBlock(_component_LibContentBlocksItemBanner, {
        key: 0,
        "lib-type": this.libType,
        "content-block": this.contentBlock,
        onCopied: this.onCopied
      }, null, 8, ["lib-type", "content-block", "onCopied"]))
    : (_openBlock(), _createBlock(_component_LibContentBlocksItemContentBlock, {
        key: 1,
        "lib-type": this.libType,
        "content-block": this.contentBlock,
        onCopied: this.onCopied
      }, null, 8, ["lib-type", "content-block", "onCopied"]))
}