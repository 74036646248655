import {NormalContentBlockModel} from "@/models/api/pharma-cms-content-block/NormalContentBlockModel";
import {
    cloneTextWithTitleContentRestDtoMetaData,
    ContentBlockMetadataRestDto,
    TextWithTitleContentRestDto
} from "@/api/pharma-cms-content-block/models";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import {StatusEnum} from "@/api/enums/status-enum";
import {TitleSizeEnum} from "@/api/enums/title-size-enum";

export class TextWithTitleContentRestDtoModel extends NormalContentBlockModel implements TextWithTitleContentRestDto {
    cb_metadata?: ContentBlockMetadataRestDto;
    content?: MultilingualRestDtoModel;
    sort_weight?: number;
    status_code: StatusEnum;
    title?: MultilingualRestDtoModel;
    title_size_code?: TitleSizeEnum;

    constructor(status_code: StatusEnum) {
        super();
        this.status_code = status_code;
    }

    public static createWithDefaults(): TextWithTitleContentRestDtoModel {
        const model = new this(StatusEnum.PUBLISHED);
        model.content = MultilingualRestDtoModel.createWithDefaults();
        model.title = MultilingualRestDtoModel.createWithDefaults();
        model.title_size_code = TitleSizeEnum.H2;
        // model.sort_weight = NormalContentBlockModel.getDefaultSortWeight();

        return model;
    }

    public static cloneFrom(source: TextWithTitleContentRestDtoModel): TextWithTitleContentRestDtoModel {
        const model = new this(source.status_code);
        model.cb_metadata = source.cb_metadata;
        model.content = source.content;
        model.sort_weight = source.sort_weight;
        model.title = source.title;
        model.title_size_code = source.title_size_code;

        return model;
    }

    public cloneMetadataFrom(source: TextWithTitleContentRestDtoModel): void {
        cloneTextWithTitleContentRestDtoMetaData(this, source);
    }
}
