
import {Exception} from '@/api/interfaces';
import {defineComponent} from "vue";
import {
  CodeDescriptionRestDto, SimpleTextWithTitleContentRestDto, TextWithTitleContentRestDto
} from "@/api/pharma-cms-content-block/models";
import {StatusEnum} from "@/api/enums/status-enum";
import {mapActions, mapGetters} from 'vuex';
import CbForm from "@/components/layouts/content-blocks/forms/CbForm.vue";
import InputTextEditor from "@/components/UI/InputTextEditor.vue";

export default defineComponent({
  name: "CbFormTextWithTitle",
  components: {InputTextEditor, CbForm},
  props: {
    canCopyFromLibrary: {
      type: Boolean,
      required: false,
      default: false,
    },
    canFallback: {
      type: Boolean,
      required: false,
      default: false,
    },
    cbId: {
      type: String,
      required: false,
    },
    cbType: {
      type: String,
      required: true
    },
    contentLibraryCode: {
      type: String,
      required: false,
    },
    isLibrary: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNormalContentBlock: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPublishable: {
      type: Boolean,
      required: false,
      default: false,
    },
    mode: {
      type: String,
      required: true,
    },
    returnRoute: {
      type: Object,
      required: false,
    },
    textareaHeight: {
      type: Number,
      required: false,
      default: 300,
    },
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    description2: {
      type: String,
      required: false,
    },
    description3: {
      type: String,
      required: false,
    },
    description4: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      error: null,
      exceptions: [] as Exception[],
      isReady: false as Boolean,
      savedSuccessfully: false as Boolean,
      statusOptions: [
        {code: StatusEnum.PUBLISHED, description: this.$t('published')},
        {code: StatusEnum.NOT_PUBLISHED, description: this.$t('notPublished')},
      ] as CodeDescriptionRestDto[]
    };
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cms_content_block', [
      'getSimpleContentBlockTextWithTitle',
      'getNormalContentBlockTextWithTitle'
    ]),
    contentBlock(): TextWithTitleContentRestDto | SimpleTextWithTitleContentRestDto {
      if (this.isLibrary || this.isNormalContentBlock) {
        return this.getNormalContentBlockTextWithTitle(this.cbType) as TextWithTitleContentRestDto;
      } else {
        return this.getSimpleContentBlockTextWithTitle(this.cbType) as SimpleTextWithTitleContentRestDto;
      }
    },
    isCopiedContent(): Boolean {
      //TODO: create logic for determining whether cb is copied or not
      return false;
    },
  },
  methods: {
    ...mapActions('cms_content_block', [
      'clearNormalContentBlockTextWithTitle',
      'createLibContentBlockTextWithTitle',
      'createNormalContentBlockTextWithTitle',
      'searchSimpleContentBlockTextWithTitle',
      'searchNormalContentBlockTextWithTitle',
      'saveSimpleContentBlockTextWithTitle',
      'saveNormalContentBlockTextWithTitle',
    ]),
    copiedLibContentBlock() {
      this.reloadContent().then(() => {
        this.savedSuccessfully = true;
        setTimeout(() => this.savedSuccessfully = false, 2000);
      });
    },
    async submitData() {
      try {
        if (this.isLibrary || this.isNormalContentBlock) {
          if (this.mode === 'new') {
            if (this.isLibrary) {
              await this.createLibContentBlockTextWithTitle({
                lib_code: this.contentLibraryCode,
                type: this.cbType
              });
            } else {
              await this.createNormalContentBlockTextWithTitle(this.cbType);
            }
          } else if (this.mode === 'edit') {
            await this.saveNormalContentBlockTextWithTitle({
              id: this.cbId,
              type: this.cbType,
            });
          }
        } else {
          await this.saveSimpleContentBlockTextWithTitle(this.cbType);
        }

        await this.afterSave();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async reloadContent() {
      this.isReady = false;
      this.exceptions = [];

      try {
        if (this.isLibrary || this.isNormalContentBlock) {
          if (this.mode === 'new') {
            this.clearNormalContentBlockTextWithTitle(this.cbType);
          } else {
            await this.searchNormalContentBlockTextWithTitle({
              id: this.cbId,
              type: this.cbType,
            });
          }
        } else {
          await this.searchSimpleContentBlockTextWithTitle({
            type: this.cbType,
            fallback_allowed: this.canFallback,
          });
        }
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      this.isReady = true;
    },
    async afterSave() {
      if (this.returnRoute) {
        await this.$router.push(this.returnRoute);
      } else {
        this.reloadContent().then(() => {
          this.savedSuccessfully = true;
          setTimeout(() => this.savedSuccessfully = false, 2000);
        });
      }
    }
  }
});
