/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum ColumnHorizontalAlignmentEnum {
    CENTER = 'CENTER',
    START = 'START',
    END = 'END',
}