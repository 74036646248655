
import {mapGetters} from 'vuex';
import {defineComponent} from "vue";
import store from "@/store";
import config from "../../public/config.json";
import BaseContainer from "@/components/UI/Bootstrap/BaseContainer.vue";
import {AbilityContext} from "@/context/AbilityContext";

export default defineComponent({
  components: {BaseContainer},
  data() {
    return {
      urlPharmacistMgmt: config.mgmtUrl,
      urlBoh: config.bohUrl
    }
  },
  computed: {
    AbilityContext() {
      return AbilityContext
    },
    ...mapGetters(['getUser', 'isAuthenticated']),
    ...mapGetters('customer_settings', ['getCustomerMainDomain'])
  },
  methods: {
    async login() {
      await store.dispatch('login');
    }
  },
});
