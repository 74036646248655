
import {defineComponent} from "vue";
import {generateContentPagePreviewUrl} from "@/helpers/functions/route";

export default defineComponent({
  name: "ButtonContentPagePreview",
  props: {
    pageId: {
      type: String,
      required: true
    },
  },
  computed: {
    previewUrl(): string {
      return generateContentPagePreviewUrl(this.pageId);
    }
  }
});
