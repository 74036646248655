
import {defineComponent, PropType} from "vue";
import {SelectableProductRestDto} from "@/api/pharma-cpc-mgmt/models";

export default defineComponent({
  name: "ProductSelectionProductsSelectionItemPreview",
  props: {
    productItem: {
      type: Object as PropType<SelectableProductRestDto>,
      required: true,
    },
  }
});
