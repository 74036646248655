/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Customer-Product-Catalog
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CatalogProductDetailRestDto } from '../models';
import { PagedCatalogProductOverviewResultsRestDto } from '../models';
import { CatalogProductsCriteriaRestDto } from '../models';
/**
 * CatalogProductAPIApi - axios parameter creator
 * @export
 */
export const CatalogProductAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for retrieving the products from catalog.
         * @summary REST.API.CPC.PROD.01 Find catalog products
         * @param {string} [accept] application/json OR text/csv
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {number} [page] Specifies the page number that is expected of the paged result.
         * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
         * @param {number} [filter_cnk_code] Filters the results to the specified CNK code.
         * @param {string} [filter_category_code] Filters the results to the specified (DP managed) category.
         * @param {boolean} [expect_products_in_subcategory] If provided and true, the category filters are interpreted in a way that products in subcategories are inclued.
         * @param {string} [filter_labo_code] A &#x27;|&#x27; separated list of product labo codes used as search criterium.
         * @param {string} [filter_trademark_code] A &#x27;|&#x27; separated list of product brand codes used as search criterium.
         * @param {string} [filter_product_filter_code] A product filter code used as search criterium indicating all products that correspond to the specified product filter are expected.
         * @param {string} [filter_active_discount_scheme_id] A all products that are currently in the specified discount scheme of a calculated catalog.
         * @param {string} [search_keyword] A keyword used as search criterium
         * @param {boolean} [filter_is_custom_sort_weight_available] When true, the products are filtered based on the existence of a customer specific sort weight.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCatalogProducts: async (accept?: string|null, x_dp_customer_code?: string|null, x_dp_language?: string|null, page?: number|null, page_size?: number|null, filter_cnk_code?: number|null, filter_category_code?: string|null, expect_products_in_subcategory?: boolean|null, filter_labo_code?: string|null, filter_trademark_code?: string|null, filter_product_filter_code?: string|null, filter_active_discount_scheme_id?: string|null, search_keyword?: string|null, filter_is_custom_sort_weight_available?: boolean|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/product_mgmt/v1/catalog/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (!!page || typeof(page) === "boolean") {
                localVarQueryParameter['page'] = page;
            }

            if (!!page_size || typeof(page_size) === "boolean") {
                localVarQueryParameter['page_size'] = page_size;
            }

            if (!!filter_cnk_code || typeof(filter_cnk_code) === "boolean") {
                localVarQueryParameter['filter_cnk_code'] = filter_cnk_code;
            }

            if (!!filter_category_code || typeof(filter_category_code) === "boolean") {
                localVarQueryParameter['filter_category_code'] = filter_category_code;
            }

            if (!!expect_products_in_subcategory || typeof(expect_products_in_subcategory) === "boolean") {
                localVarQueryParameter['expect_products_in_subcategory'] = expect_products_in_subcategory;
            }

            if (!!filter_labo_code || typeof(filter_labo_code) === "boolean") {
                localVarQueryParameter['filter_labo_code'] = filter_labo_code;
            }

            if (!!filter_trademark_code || typeof(filter_trademark_code) === "boolean") {
                localVarQueryParameter['filter_trademark_code'] = filter_trademark_code;
            }

            if (!!filter_product_filter_code || typeof(filter_product_filter_code) === "boolean") {
                localVarQueryParameter['filter_product_filter_code'] = filter_product_filter_code;
            }

            if (!!filter_active_discount_scheme_id || typeof(filter_active_discount_scheme_id) === "boolean") {
                localVarQueryParameter['filter_active_discount_scheme_id'] = filter_active_discount_scheme_id;
            }

            if (!!search_keyword || typeof(search_keyword) === "boolean") {
                localVarQueryParameter['search_keyword'] = search_keyword;
            }

            if (!!filter_is_custom_sort_weight_available || typeof(filter_is_custom_sort_weight_available) === "boolean") {
                localVarQueryParameter['filter_is_custom_sort_weight_available'] = filter_is_custom_sort_weight_available;
            }

            if (accept !== undefined && accept !== null) {
                localVarHeaderParameter['Accept'] = String(accept);
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving the product details.
         * @summary REST.API.CPC.PROD.02 Retrieve catalog product details
         * @param {string} product_code The code that uniquely identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCatalogProductDetail: async (product_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'product_code' is not null or undefined
            if (product_code === null || product_code === undefined) {
                throw new RequiredError('product_code','Required parameter product_code was null or undefined when calling getCatalogProductDetail.');
            }
            const localVarPath = `/product_mgmt/v1/catalog/product/{product_code}`
                .replace(`{${"product_code"}}`, encodeURIComponent(String(product_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CatalogProductAPIApi - functional programming interface
 * @export
 */
export const CatalogProductAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for retrieving the products from catalog.
         * @summary REST.API.CPC.PROD.01 Find catalog products
         * @param {string} [accept] application/json OR text/csv
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {number} [page] Specifies the page number that is expected of the paged result.
         * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
         * @param {number} [filter_cnk_code] Filters the results to the specified CNK code.
         * @param {string} [filter_category_code] Filters the results to the specified (DP managed) category.
         * @param {boolean} [expect_products_in_subcategory] If provided and true, the category filters are interpreted in a way that products in subcategories are inclued.
         * @param {string} [filter_labo_code] A &#x27;|&#x27; separated list of product labo codes used as search criterium.
         * @param {string} [filter_trademark_code] A &#x27;|&#x27; separated list of product brand codes used as search criterium.
         * @param {string} [filter_product_filter_code] A product filter code used as search criterium indicating all products that correspond to the specified product filter are expected.
         * @param {string} [filter_active_discount_scheme_id] A all products that are currently in the specified discount scheme of a calculated catalog.
         * @param {string} [search_keyword] A keyword used as search criterium
         * @param {boolean} [filter_is_custom_sort_weight_available] When true, the products are filtered based on the existence of a customer specific sort weight.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCatalogProducts(_axios: AxiosInstance, accept?: string|null, x_dp_customer_code?: string|null, x_dp_language?: string|null, page?: number|null, page_size?: number|null, filter_cnk_code?: number|null, filter_category_code?: string|null, expect_products_in_subcategory?: boolean|null, filter_labo_code?: string|null, filter_trademark_code?: string|null, filter_product_filter_code?: string|null, filter_active_discount_scheme_id?: string|null, search_keyword?: string|null, filter_is_custom_sort_weight_available?: boolean|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedCatalogProductOverviewResultsRestDto>> {
            const localVarAxiosArgs = await CatalogProductAPIApiAxiosParamCreator(configuration).findCatalogProducts(accept, x_dp_customer_code, x_dp_language, page, page_size, filter_cnk_code, filter_category_code, expect_products_in_subcategory, filter_labo_code, filter_trademark_code, filter_product_filter_code, filter_active_discount_scheme_id, search_keyword, filter_is_custom_sort_weight_available, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving the product details.
         * @summary REST.API.CPC.PROD.02 Retrieve catalog product details
         * @param {string} product_code The code that uniquely identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCatalogProductDetail(_axios: AxiosInstance, product_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogProductDetailRestDto>> {
            const localVarAxiosArgs = await CatalogProductAPIApiAxiosParamCreator(configuration).getCatalogProductDetail(product_code, x_dp_customer_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CatalogProductAPIApi - object-oriented interface
 * @export
 * @class CatalogProductAPIApi
 * @extends {BaseAPI}
 */
export class CatalogProductAPIApi extends BaseAPI {
    /**
     * API endpoint responsible for retrieving the products from catalog.
     * @summary REST.API.CPC.PROD.01 Find catalog products
     * @param {string} [accept] application/json OR text/csv
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {number} [page] Specifies the page number that is expected of the paged result.
     * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
     * @param {number} [filter_cnk_code] Filters the results to the specified CNK code.
     * @param {string} [filter_category_code] Filters the results to the specified (DP managed) category.
     * @param {boolean} [expect_products_in_subcategory] If provided and true, the category filters are interpreted in a way that products in subcategories are inclued.
     * @param {string} [filter_labo_code] A &#x27;|&#x27; separated list of product labo codes used as search criterium.
     * @param {string} [filter_trademark_code] A &#x27;|&#x27; separated list of product brand codes used as search criterium.
     * @param {string} [filter_product_filter_code] A product filter code used as search criterium indicating all products that correspond to the specified product filter are expected.
     * @param {string} [filter_active_discount_scheme_id] A all products that are currently in the specified discount scheme of a calculated catalog.
     * @param {string} [search_keyword] A keyword used as search criterium
     * @param {boolean} [filter_is_custom_sort_weight_available] When true, the products are filtered based on the existence of a customer specific sort weight.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogProductAPIApi
     */
     private findCatalogProducts(accept?: string|null, x_dp_customer_code?: string|null, x_dp_language?: string|null, page?: number|null, page_size?: number|null, filter_cnk_code?: number|null, filter_category_code?: string|null, expect_products_in_subcategory?: boolean|null, filter_labo_code?: string|null, filter_trademark_code?: string|null, filter_product_filter_code?: string|null, filter_active_discount_scheme_id?: string|null, search_keyword?: string|null, filter_is_custom_sort_weight_available?: boolean|null, options?: any) {
        return CatalogProductAPIApiFp(this.configuration).findCatalogProducts(this.axios, accept, x_dp_customer_code, x_dp_language, page, page_size, filter_cnk_code, filter_category_code, expect_products_in_subcategory, filter_labo_code, filter_trademark_code, filter_product_filter_code, filter_active_discount_scheme_id, search_keyword, filter_is_custom_sort_weight_available, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving the products from catalog.
     * @summary REST.API.CPC.PROD.01 Find catalog products
     * @param {CatalogProductsCriteriaRestDto} [dto] The CriteriaRestDto for the api call.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogProductAPIApi
     */
    public findCatalogProductsByCatalogProductsCriteriaRestDto(dto: CatalogProductsCriteriaRestDto, options?: any){
        return this.findCatalogProducts(dto?.accept, dto?.x_dp_customer_code, dto?.x_dp_language, dto?.page, dto?.page_size, dto?.filter_cnk_code, dto?.filter_category_code, dto?.expect_products_in_subcategory, dto?.filter_labo_code, dto?.filter_trademark_code, dto?.filter_product_filter_code, dto?.filter_active_discount_scheme_id, dto?.search_keyword, dto?.filter_is_custom_sort_weight_available, options);
    }
    /**
     * API endpoint responsible for retrieving the product details.
     * @summary REST.API.CPC.PROD.02 Retrieve catalog product details
     * @param {string} product_code The code that uniquely identifies the product.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogProductAPIApi
     */
     public getCatalogProductDetail(product_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any) {
        return CatalogProductAPIApiFp(this.configuration).getCatalogProductDetail(this.axios, product_code, x_dp_customer_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

}
