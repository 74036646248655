import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-bs-target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.mode === 'open')
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: _normalizeClass(this.class),
        type: "button",
        "data-bs-toggle": "modal",
        "data-bs-target": '#' + _ctx.modalId
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 10, _hoisted_1))
    : (_ctx.mode === 'close')
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          ref: "closeButton",
          class: _normalizeClass(this.class),
          type: "button",
          "data-bs-dismiss": "modal"
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2))
      : _createCommentVNode("", true)
}