
import {defineComponent} from "vue";

export default defineComponent({
  name: "BaseAlert",
  props: {
    icon: {
      type: String,
      required: false,
    },
    //TODO: fix error when dismissing
    //Uncaught (in promise) TypeError: Cannot read properties of null (reading 'insertBefore')
    isDismissible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    alertClasses() {
      let classes = '';

      if (this.isDismissible) {
        classes += ' alert-dismissible fade show';
      }
      if (this.icon) {
        classes += ' d-flex align-items-center';
      }

      return classes;
    },
  }
});
