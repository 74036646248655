import {EnumContentRestDto, MultilingualFieldRestDto} from "@/api/pharma-order-enum/models";

export class MultilingualFieldRestDtoModel implements MultilingualFieldRestDto {
    de?: string | null;
    en?: string | null;
    fr?: string | null;
    nl?: string | null;


    constructor() {
    }

    public static createWithDefaults(): MultilingualFieldRestDtoModel {
        return new this()
    }
}
