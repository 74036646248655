import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cbs_overview = _resolveComponent("cbs-overview")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_cbs_overview, {
        "content-type": "BANNER_SLOT",
        onCopiedLibCb: _ctx.reloadContent,
        "content-blocks": _ctx.contentBlocks,
        "cb-type": _ctx.cbType,
        "can-use-library": false,
        "is-ready": _ctx.isReady,
        "has-content-blocks": _ctx.hasContentBlocks,
        description: _ctx.description,
        title: _ctx.title,
        "route-object-edit-cb": _ctx.routeObjectEditCb,
        "table-header-items": ['', _ctx.$t('name'), _ctx.$t('status'), '']
      }, null, 8, ["onCopiedLibCb", "content-blocks", "cb-type", "is-ready", "has-content-blocks", "description", "title", "route-object-edit-cb", "table-header-items"])
    ]),
    _: 1
  }))
}