/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */

import {ContentBlockMetadataRestDto} from ".";
import {StatusEnum} from "@/api/enums/status-enum";

/**
 * @export
 * @interface BannerSlotContentRestDto
 */
export interface BannerSlotContentRestDto {
    /**
     * The status of this content block.
     * @type {StatusEnum}
     * @memberof BannerSlotContentRestDto
     */
    status_code: StatusEnum;

    /**
     * The sorting value of this content block.
     * @type {number|null}
     * @memberof BannerSlotContentRestDto
     */
    sort_weight?: number|null;

    /**
     * Specifies the position on the website where this banner slot is located.
     * @type {string}
     * @memberof BannerSlotContentRestDto
     */
    position_code: string;

    /**
     * Specifies the maximum size for this slot. SIZE_1_2 means that there can fit 2 banners of that size in this banner slot.
     * @type {string}
     * @memberof BannerSlotContentRestDto
     */
    max_content_size_code: string;

    /**
     * The unique identifier of the banner slot to which this slot is subscribed to follow.
     * @type {string|null}
     * @memberof BannerSlotContentRestDto
     */
    subscription_slot_id?: string|null;

    /**
     * 
     * @type {ContentBlockMetadataRestDto|null}
     * @memberof BannerSlotContentRestDto
     */
    cb_metadata?: ContentBlockMetadataRestDto|null;

}

/**
 * @export
 * @function cloneBannerSlotContentRestDtoMetaData
 */
export function cloneBannerSlotContentRestDtoMetaData(dto: BannerSlotContentRestDto, source: BannerSlotContentRestDto): void {
    dto.position_code = source.position_code;
    dto.max_content_size_code = source.max_content_size_code;
}
