import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = {
  key: 0,
  class: "base-title-button"
}
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 col-xl-11 col-xxl-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_content_library_available = _resolveComponent("base-content-library-available")!
  const _component_base_title = _resolveComponent("base-title")!
  const _component_AlertSuccess = _resolveComponent("AlertSuccess")!
  const _component_AlertError = _resolveComponent("AlertError")!
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", _hoisted_1, [
      _createVNode(_component_base_title, {
        title: this.title,
        mode: this.mode,
        "back-route": (typeof this.returnRoute === 'string') ? {name: this.returnRoute} : this.returnRoute,
        "help-text": this.computedDescription,
        "is-library": this.isLibrary,
        mb: 3
      }, {
        buttons: _withCtx(() => [
          (this.canCopyFromLibrary)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_base_content_library_available, {
                  "cb-type": this.cbType,
                  title: (this.computedTitle.length > 0 ? this.computedTitle + ' ' : '') + _ctx.$t('library'),
                  onCopied: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('copiedLibCb')))
                }, null, 8, ["cb-type", "title"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title", "mode", "back-route", "help-text", "is-library"]),
      _createVNode(_component_AlertSuccess, {
        "show-if": this.savedSuccessfully,
        class: "mt-3"
      }, null, 8, ["show-if"]),
      _createVNode(_component_AlertError, {
        exceptions: this.exceptions,
        class: "mt-3"
      }, null, 8, ["exceptions"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_BaseSpinner, {
          spin: !this.isReady
        }, null, 8, ["spin"]),
        (this.isReady)
          ? _renderSlot(_ctx.$slots, "default", { key: 0 })
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}