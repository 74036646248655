import {
    FullNamedTextFragmentRestDto,
    FullProductInfoRestDto, FullTextFragmentRestDto,
    ProductDetailsRestDto
} from "@/api/pharma-pim-mgmt/models";
import {ProductDetailsRestDtoModel} from "@/models/api/pharma-pim-mgmt/ProductDetailsRestDtoModel";

export class FullProductInfoRestDtoModel implements FullProductInfoRestDto {
    product_code: string;
    details: ProductDetailsRestDto;
    named_text_fragments: FullNamedTextFragmentRestDto[];
    text_fragments: FullTextFragmentRestDto[];

    constructor(details: ProductDetailsRestDto, named_text_fragments: FullNamedTextFragmentRestDto[], text_fragments: FullTextFragmentRestDto[]) {
        this.product_code = '';
        this.details = details;
        this.named_text_fragments = named_text_fragments;
        this.text_fragments = text_fragments;
    }

    public static createWithDefaults(): FullProductInfoRestDtoModel {
        return new this(
            ProductDetailsRestDtoModel.createWithDefaults(),
            [],
            []
        )
    }
}
