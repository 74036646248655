
import {defineComponent} from "vue";
import {ExternalSystemSyncTaskOverviewRestDto} from "@/api/pharma-cpc-mgmt/models";
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {arrayHasContent} from "@/helpers/functions/arrays";
import {formatDateTime} from "@/helpers/functions/date";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {ExternalSystemSyncRestService} from "@/services/rest/cpc-mgmt/ExternalSystemSyncRestService";

export default defineComponent({
  name: "ProductSelectionBaseSyncHistory",
  components: {BaseTitle},
  props: {
    externalSystemTypeCode: {
      type: String,
      required: true
    },
  },
  data: () => ({
    synchronisationHistory: [] as ExternalSystemSyncTaskOverviewRestDto[],
    synchronisationHistoryUI: UIStateDto.createWithDefaults(),
  }),
  mounted() {
    this.reloadContent();
  },
  computed: {
    hasHistory(): boolean {
      return arrayHasContent(this.synchronisationHistory);
    }
  },
  methods: {
    formatDateTime,
    async reloadContent(): Promise<void> {
      this.synchronisationHistoryUI.setNotReady();

      try {
        this.synchronisationHistory = await ExternalSystemSyncRestService.getInstance()
          .findExternalSystemSyncTaskHistory(this.externalSystemTypeCode);
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.synchronisationHistoryUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.synchronisationHistoryUI as UIStateDto))
          .catch(exceptions, true);
      } finally {
        this.synchronisationHistoryUI.setReady();
      }
    }
  },

});
