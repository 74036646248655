
import {defineComponent} from "vue";
import AbstractHeaderFooterDetailContent
  from "@/components/layouts/header-footer/AbstractHeaderFooterDetailContent.vue";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import Subtitle from "@/components/UI/Subtitle.vue";
import {CmsHeaderContent0RestDtoModel} from "@/models/api/pharma-cms-header/CmsHeaderContent0RestDtoModel";

export default defineComponent({
  name: "HeaderDetailContent0",
  components: {
    Subtitle, BaseSpinner, InputCheckboxBoolean, AlertError2
  },
  extends: AbstractHeaderFooterDetailContent,
  data: () => ({
    headerContent: CmsHeaderContent0RestDtoModel.createWithDefaults()
  })
});
