import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d22d5126"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xxl-12" }
const _hoisted_3 = { class: "checkbox-open-closed col-2 mb-0 d-flex flex-row-reverse justify-content-end align-items-center" }
const _hoisted_4 = ["for"]
const _hoisted_5 = ["onUpdate:modelValue", "onChange", "name", "id"]
const _hoisted_6 = { class: "label-open-closed col-1" }
const _hoisted_7 = { class: "hours col-12 col-xl-9 col-xxl-7" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-4 from-till d-flex justify-content-center" }
const _hoisted_10 = { class: "col-4 from-till d-flex justify-content-center" }
const _hoisted_11 = { class: "col-4 from-till d-flex justify-content-center" }
const _hoisted_12 = { class: "d-flex flex-column justify-content-start" }
const _hoisted_13 = { class: "col d-flex" }
const _hoisted_14 = { class: "d-flex align-items-center mt-4 pt-4 border-top border-2 border-light" }
const _hoisted_15 = {
  type: "submit",
  class: "btn btn-primary"
}
const _hoisted_16 = { class: "text-muted ms-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_alert_success = _resolveComponent("alert-success")!
  const _component_alert_error = _resolveComponent("alert-error")!
  const _component_input_time = _resolveComponent("input-time")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_dp_form = _resolveComponent("dp-form")!
  const _component_base_spinner = _resolveComponent("base-spinner")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_BaseTitle, {
        title: _ctx.$t('standardOpeningHours'),
        "help-text": _ctx.$t('standardOpeningHoursDescription')
      }, null, 8, ["title", "help-text"]),
      _createVNode(_component_alert_success, {
        "show-if": _ctx.savedSuccessfully,
        class: "mb-3"
      }, null, 8, ["show-if"]),
      _createVNode(_component_alert_error, {
        exceptions: _ctx.exceptions,
        class: "mb-3"
      }, null, 8, ["exceptions"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.isReady)
            ? (_openBlock(), _createBlock(_component_dp_form, {
                key: 0,
                as: "form",
                onSubmit: _ctx.submitData,
                novalidate: ""
              }, {
                default: _withCtx(({ errors }) => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.standardOpeningHoursWeek, (openingHoursDay, dayName) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: dayName,
                      class: "opening-hours-day row d-flex align-items-center"
                    }, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("label", {
                          for: dayName + 'OpenClosed',
                          class: "fw-0 form-label px-2 mb-0"
                        }, _toDisplayString(_ctx.$t(dayName)), 9, _hoisted_4),
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": ($event: any) => ((openingHoursDay.open) = $event),
                          onChange: ($event: any) => (_ctx.toggleDayTimeValues(dayName)),
                          type: "checkbox",
                          name: dayName + 'OpenClosed',
                          id: dayName + 'OpenClosed'
                        }, null, 40, _hoisted_5), [
                          [_vModelCheckbox, openingHoursDay.open]
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("p", {
                          class: _normalizeClass([openingHoursDay.open ? 'text-success':'text-danger','m-0 fw-bold'])
                        }, _toDisplayString(openingHoursDay.open ? _ctx.$t('open') : _ctx.$t('closed')), 3)
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            _createVNode(_component_input_time, {
                              modelValue: openingHoursDay.hours1_from,
                              "onUpdate:modelValue": ($event: any) => ((openingHoursDay.hours1_from) = $event),
                              label: _ctx.$t('from'),
                              name: dayName + 'Hours1From',
                              rules: _ctx.inputTimeRulesOpenClosed(openingHoursDay.open),
                              errors: errors
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "name", "rules", "errors"]),
                            _createVNode(_component_input_time, {
                              modelValue: openingHoursDay.hours1_till,
                              "onUpdate:modelValue": ($event: any) => ((openingHoursDay.hours1_till) = $event),
                              label: _ctx.$t('till'),
                              name: dayName + 'Hours1Till',
                              rules: _ctx.inputTimeRulesOpenClosed(openingHoursDay.open),
                              errors: errors
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "name", "rules", "errors"])
                          ]),
                          _createElementVNode("div", _hoisted_10, [
                            _createVNode(_component_input_time, {
                              modelValue: openingHoursDay.hours2_from,
                              "onUpdate:modelValue": ($event: any) => ((openingHoursDay.hours2_from) = $event),
                              label: _ctx.$t('from'),
                              name: dayName + 'Hours2From',
                              rules: _ctx.inputTimeRulesFromTo(openingHoursDay.hours2_from),
                              errors: errors
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "name", "rules", "errors"]),
                            _createVNode(_component_input_time, {
                              modelValue: openingHoursDay.hours2_till,
                              "onUpdate:modelValue": ($event: any) => ((openingHoursDay.hours2_till) = $event),
                              label: _ctx.$t('till'),
                              name: dayName + 'Hours2Till',
                              rules: _ctx.inputTimeRulesFromTo(openingHoursDay.hours2_from),
                              errors: errors
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "name", "rules", "errors"])
                          ]),
                          _createElementVNode("div", _hoisted_11, [
                            _createVNode(_component_input_time, {
                              modelValue: openingHoursDay.hours3_from,
                              "onUpdate:modelValue": ($event: any) => ((openingHoursDay.hours3_from) = $event),
                              label: _ctx.$t('from'),
                              name: dayName + 'Hours3From',
                              rules: _ctx.inputTimeRulesFromTo(openingHoursDay.hours3_from),
                              errors: errors
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "name", "rules", "errors"]),
                            _createVNode(_component_input_time, {
                              modelValue: openingHoursDay.hours3_till,
                              "onUpdate:modelValue": ($event: any) => ((openingHoursDay.hours3_till) = $event),
                              label: _ctx.$t('till'),
                              name: dayName + 'Hours3Till',
                              rules: _ctx.inputTimeRulesFromTo(openingHoursDay.hours3_from),
                              errors: errors
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "name", "rules", "errors"])
                          ])
                        ])
                      ])
                    ]))
                  }), 128)),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("button", {
                        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.reloadContent && _ctx.reloadContent(...args)), ["prevent"])),
                        class: "btn btn-secondary me-3"
                      }, _toDisplayString(_ctx.$t('contentReload')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("button", _hoisted_15, _toDisplayString(_ctx.$t('save')), 1),
                      _createVNode(_component_router_link, {
                        class: "ms-3 btn btn-outline-primary",
                        to: "/"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
                        ]),
                        _: 1
                      }),
                      _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('cachingDelayMinutes', [5])), 1)
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["onSubmit"]))
            : (_openBlock(), _createBlock(_component_base_spinner, {
                key: 1,
                "spinner-class": "my-5"
              }))
        ])
      ])
    ]),
    _: 1
  }))
}