
import {defineComponent, ref} from "vue";
import {ProductFilterPickerPayload} from "@/models/payload/ProductFilterPickerPayload";
import {ProductFilterTypeEnum} from "@/models/enum/ProductFilterTypeEnum";
import ProductFilters from "@/components/layouts/catalog/product-filter/ProductFilters.vue";
import {generateUuid} from "@/helpers/functions/string";
import ability from "@/config/ability";
import ButtonProductFilterPreview from "@/components/layouts/catalog/ButtonProductFilterPreview.vue";
import {ProductFilterApplyTypeEnum} from "@/models/enum/ProductFilterApplyTypeEnum";

export default defineComponent({
  name: "ProductFilterPicker",
  components: {ButtonProductFilterPreview, ProductFilters},
  emits: ['changed'],
  props: {
    payload: {
      type: ProductFilterPickerPayload,
      required: true,
    },
    productFilter: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
    }
  },
  data() {
    return {
      isReady: false as boolean,
      productFilterTypeEnum: ProductFilterTypeEnum,
    }
  },
  setup(props) {
    const internalPayload = ref(ProductFilterPickerPayload.cloneFrom(props.payload));

    const initialCode = internalPayload.value.code;
    const initialType = internalPayload.value.type;
    // console.log('initialCode: ', initialCode)
    // console.log('initialType: ', initialType)

    return {
      internalPayload,
      initialCode,
      initialType
    };
  },
  watch: {
    payload: {
      handler(newPayload: ProductFilterPickerPayload) {
        // console.log('payload changed: ', newPayload);
        this.internalPayload = ProductFilterPickerPayload.cloneFrom(newPayload);
      },
      deep: true,
      immediate: false
    },
    internalPayload: {
      handler() {
        // console.log('internalPayload changed: ', this.internalPayload);
        this.$emit('changed', this.internalPayload);
      },
      deep: true,
    }
  },
  computed: {
    ProductFilterApplyTypeEnum() {
      return ProductFilterApplyTypeEnum
    },
    canUseNamedProductFilter(): boolean {
      return ability.can('use-feature', 'NAMED_PRODUCT_FILTER');
    },
  },
  methods: {
    isProductFilterType(type: ProductFilterTypeEnum): boolean {
      return this.internalPayload.type === type;
    },
    setProductFilterType(type: ProductFilterTypeEnum) {
      // console.log('setProductFilterType', type);
      this.internalPayload.type = type;

      if (type === this.initialType) {
        this.internalPayload.code = this.initialCode;
      } else if (type === ProductFilterTypeEnum.CUSTOM) {
        this.internalPayload.code = generateUuid();
      } else if (type === ProductFilterTypeEnum.NAMED) {
        this.internalPayload.code = null;
      }
    },
  }
});
