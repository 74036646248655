/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum StrategyEnum {
    SHOW_OWN_CONTENT = 'SHOW_OWN_CONTENT',
    SHOW_EXTERNAL_CONTENT = 'SHOW_EXTERNAL_CONTENT',
    SHOW_BOTH = 'SHOW_BOTH',
    SHOW_NO_CONTENT = 'SHOW_NO_CONTENT',
}