
import {config} from "@/helpers/fetch-config";
import {version} from "../../package.json";
import BaseCard from "@/components/UI/Bootstrap/Card/BaseCard.vue";
import BaseCardHeader from "@/components/UI/Bootstrap/Card/BaseCardHeader.vue";
import BaseCardBody from "@/components/UI/Bootstrap/Card/BaseCardBody.vue";
import {AbilityContext} from "../context/AbilityContext";
import BaseContainer from "@/components/UI/Bootstrap/BaseContainer.vue";
import Dashboard from "@/components/layouts/master/Dashboard.vue";

export default {
  name: "Support",
  computed: {
    AbilityContext() {
      return AbilityContext;
    },
    licences(): string[] {
      return AbilityContext.getLicensesAsDescription();
    }
  },
  components: {Dashboard, BaseContainer, BaseCardBody, BaseCardHeader, BaseCard},
  data: () => ({
    config,
    appVersion: version,
    origin: window.location.origin
  }),
}
