import {NormalContentBlockModel} from "@/models/api/pharma-cms-content-block/NormalContentBlockModel";
import {
    cloneTeamInfoContentRestDtoMetaData,
    ContentBlockMetadataRestDto,
    TeamInfoContentRestDto
} from "@/api/pharma-cms-content-block/models";
import {StatusEnum} from "@/api/enums/status-enum";

export class TeamInfoContentRestDtoModel extends NormalContentBlockModel implements TeamInfoContentRestDto {
    cb_metadata?: ContentBlockMetadataRestDto;
    pharmacy_code?: string | null;
    is_show_about_us: boolean;
    is_show_team_members: boolean;
    sort_weight?: number;
    status_code: StatusEnum;

    constructor(is_show_about_us: boolean, is_show_team_members: boolean, status_code: StatusEnum) {
        super();
        this.is_show_about_us = is_show_about_us;
        this.is_show_team_members = is_show_team_members;
        this.status_code = status_code;
    }

    public static createWithDefaults(): TeamInfoContentRestDtoModel {
        const model = new this(false, true, StatusEnum.PUBLISHED);
        model.pharmacy_code = null;
        // model.sort_weight = NormalContentBlockModel.getDefaultSortWeight();

        return model;
    }

    public static cloneFrom(source: TeamInfoContentRestDtoModel): TeamInfoContentRestDtoModel {
        const model = new this(
            source.is_show_about_us,
            source.is_show_team_members,
            source.status_code
        );
        model.cb_metadata = source.cb_metadata;
        model.pharmacy_code = source.pharmacy_code;
        model.sort_weight = source.sort_weight;

        return model;
    }

    public cloneMetadataFrom(source: TeamInfoContentRestDtoModel): void {
        cloneTeamInfoContentRestDtoMetaData(this, source);
    }
}
