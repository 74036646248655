import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bg-white border border-1 rounded p-3 pb-0"
}
const _hoisted_2 = {
  key: 0,
  class: "d-flex align-items-center justify-content-between lh-1 fs-5 mb-3"
}
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = {
  key: 0,
  class: "mas me-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (this.isReady)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        (_ctx.title.length > 0)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, [
                (_ctx.icon.length > 1)
                  ? (_openBlock(), _createElementBlock("i", _hoisted_4, _toDisplayString(_ctx.icon), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default")
      ]))
    : _createCommentVNode("", true)
}