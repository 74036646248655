// Docs - https://github.com/avil13/vue-sweetalert2

import vm from '@/main'; // Vue instance

// Error toast
export function onError(title, message) {
  vm.$swal({
    toast: true,
    position: 'top',
    icon: 'error',
    title,
    text: message,
    showConfirmButton: false,
    timer: '10000',
    timerProgressBar: true,
    showCloseButton: true,
    width: '100%'
  });
}

// Warning toast
export function onWarn(message) {
  vm.$swal({
    toast: true,
    position: 'top',
    icon: 'warning',
    title: message,
    showConfirmButton: false,
    timer: '10000',
    timerProgressBar: true,
    showCloseButton: true,
    width: '100%'
  });
}

// Info toast
export function onInfo() {
  console.log('Not implemented');
}
