import { onError } from '@/helpers/toast-service';

export function registerErrorInterceptor(instance) {
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      const exceptions = error?.response?.data?.exceptions;

      // Handle errors with decent error response
      if (exceptions) {
        const codes = exceptions.map((e) => Number(e.code));

        // Global handling for error codes in given range, leading zeros will be ignored
        if (inRange(codes[0], 30000, 100000)) {
          onError(error, exceptions[0]?.message);
          return error;
        } else {
          // Other errors code need component error handling
          console.log('Passing error, needs to be handled in component by catch clause');
          console.log(exceptions)
          return Promise.reject(exceptions);
        }
      } else {
        // Handle other errors
        onError(error);
        return error;
      }
    }
  );
}

function inRange(x, min, max) {
  return x >= min && x <= max;
}
