import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-405d03d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    src: this.product.product_info.main_image_url.nl ?? this.product.product_info.main_image_url,
    alt: this.product.product_info.name,
    class: "position-absolute img-thumbnail"
  }, null, 8, _hoisted_1))
}