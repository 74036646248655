import {defineStore} from "pinia";
import {CustomerOverviewRestDto} from "@/api/pharma-customer/models";
import {
    CpcProductBrandEndpointsApiService,
    CpcProductCategoryEndpointsApiService,
    CpcProductTrademarkEndpointsApiService,
    PharmacyInformationEndpointsApiService
} from "@/main";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-pharmacy/models";
import {Storage} from "@/models/facade/Storage";
import {LocalStorageKeyEnum} from "@/models/enum/LocalStorageKeyEnum";
import {CustomerService} from "@/services/business/CustomerService";
import {CustomerCRUDRestService} from "@/services/rest/customer/CustomerCRUDRestService";
import {ContentPageCriteriaModel} from "@/models/criteria/ContentPageCriteriaModel";
import {CustomerCriteriaRestDtoModel} from "@/models/api/pharma-customer/CustomerCriteriaRestDtoModel";
import {AuthContext} from "@/context/AuthContext";
import {PimProductsCriteriaModel} from "@/models/criteria/PimProductsCriteriaModel";
import {PimProductsPagingModel} from "@/models/paging/PimProductsPagingModel";
import {CustomerContext} from "@/context/CustomerContext";
import {ProductCategoryOverviewRestDto, ProductCategoryRestDto} from "@/api/pharma-cpc-product/models";
import {DpException} from "@/exception/DpException";
import {PimCategoryRestService} from "@/services/rest/pim-category/PimCategoryRestService";
import {arrayHasContent} from "@/helpers/functions/arrays";
import {PimLaboProductsCriteriaModel} from "@/models/criteria/PimLaboProductsCriteriaModel";
import {stringHasContent} from "@/helpers/functions/string";
import {CatalogProductsCriteriaModel} from "@/models/criteria/CatalogProductsCriteriaModel";

interface CustomerCatalog {
    all_brands: Map<string, CodeDescriptionRestDto[]>
    all_trademarks: Map<string, CodeDescriptionRestDto[]>
    all_categories: Map<string, ProductCategoryOverviewRestDto[]>
    product_categories_tree: Map<string, ProductCategoryRestDto>
    internal_product_categories: CodeDescriptionRestDto[]
}

interface Criteria {
    customer: CustomerCriteriaRestDtoModel;
    content_page: ContentPageCriteriaModel;
    pim_products: PimProductsCriteriaModel;
    pim_labo_products: PimLaboProductsCriteriaModel;
    catalog_products: CatalogProductsCriteriaModel;
}

interface Paging {
    pim_products: PimProductsPagingModel;
}

interface PimState {
    pim_products_is_initialized: boolean;
    pim_products_criteria_is_changed: boolean;
    pim_products_selected_for_categorization: number[];
    pim_labo_products_is_initialized: boolean;
    pim_labo_products_criteria_is_changed: boolean;
    catalog_products_is_initialized: boolean;
    catalog_products_criteria_is_changed: boolean;
}

interface State {
    customers: CustomerOverviewRestDto[];
    customers_with_criteria: CustomerOverviewRestDto[];
    customer_catalog: CustomerCatalog,

    pharmacy_code: string;
    pharmacies: CodeDescriptionRestDto[];

    criteria: Criteria;
    paging: Paging;

    pimState: PimState;
}

export const useCustomerStore = defineStore("CustomerStore", {
    state: (): State => {
        return {
            customers: [],
            customers_with_criteria: [],
            customer_catalog: {
                all_brands: new Map<string, CodeDescriptionRestDto[]>(),
                all_trademarks: new Map<string, CodeDescriptionRestDto[]>(),
                all_categories: new Map<string, ProductCategoryOverviewRestDto[]>(),
                product_categories_tree: new Map<string, ProductCategoryRestDto>(),
                internal_product_categories: []
            },

            pharmacy_code: '',
            pharmacies: [],

            criteria: {
                customer: CustomerCriteriaRestDtoModel.createWithDefaults(),
                content_page: ContentPageCriteriaModel.createWithDefaults(),
                pim_products: PimProductsCriteriaModel.createWithDefaults(),
                pim_labo_products: PimLaboProductsCriteriaModel.createWithDefaults(),
                catalog_products: CatalogProductsCriteriaModel.createWithDefaults(),
            },
            paging: {
                pim_products: PimProductsPagingModel.createWithDefaults(),
            },

            pimState: {
                pim_products_is_initialized: false,
                pim_products_criteria_is_changed: false,
                pim_products_selected_for_categorization: [],
                pim_labo_products_is_initialized: false,
                pim_labo_products_criteria_is_changed: false,
                catalog_products_is_initialized: false,
                catalog_products_criteria_is_changed: false,
            }
        }
    },
    actions: {
        async initialize(customer_criteria_search_name: string | null): Promise<void> {
            if (customer_criteria_search_name) {
                this.criteria.customer.search_name = customer_criteria_search_name;
            } else if (AuthContext.isDpUser(true)) {
                this.criteria.customer.search_name = 'demo';
            }

            await Promise.all([
                this.searchCustomersWithCriteria(),
                this.searchCustomers(),
            ]);
        },

        // CUSTOMER
        async searchCustomers(): Promise<void> {
            this.customers = await CustomerCRUDRestService.getInstance()
                .findCustomerOverview(CustomerCriteriaRestDtoModel.createWithDefaults());
        },
        async searchCustomersWithCriteria(): Promise<void> {
            this.customers_with_criteria = await CustomerCRUDRestService.getInstance()
                .findCustomerOverview(this.criteria.customer);
        },

        // CATALOG
        async searchAllBrandsCustomer(customer_code?: string | null, language?: string | null): Promise<void> {
            const s_customer_code = customer_code ?? CustomerContext.getCustomerCode() as string;
            const s_language = language ?? undefined as string | undefined;
            // const s_language = language ?? i18n.global.locale as string | undefined;
            const catalog_key = s_customer_code + s_language as string;

            if (!this.customer_catalog.all_brands.has(catalog_key)) {
                const response = await CpcProductBrandEndpointsApiService.findProductBrands(s_customer_code, undefined, s_language);

                this.customer_catalog.all_brands.set(catalog_key, response.data);
            }
        },
        async searchAllTrademarksCustomer(customer_code?: string | null, language?: string | null): Promise<void> {
            const s_customer_code = customer_code ?? CustomerContext.getCustomerCode() as string;
            const s_language = language ?? undefined as string | undefined;
            // const s_language = language ?? i18n.global.locale as string | undefined;
            const catalog_key = s_customer_code + s_language as string;

            if (!this.customer_catalog.all_trademarks.has(catalog_key)) {
                const response = await CpcProductTrademarkEndpointsApiService.findProductTrademarks(s_customer_code, undefined, s_language);

                this.customer_catalog.all_trademarks.set(catalog_key, response.data);
            }
        },
        async searchAllCategoriesCustomer(customer_code?: string | null, language?: string | null): Promise<void> {
            const s_customer_code = customer_code ?? CustomerContext.getCustomerCode() as string;
            const s_language = language ?? undefined as string | undefined;
            // const s_language = language ?? i18n.global.locale as string | undefined;
            const catalog_key = s_customer_code + s_language as string;

            if (!this.customer_catalog.all_categories.has(catalog_key)) {
                const response = await CpcProductCategoryEndpointsApiService.findProductCategories(s_customer_code, undefined, s_language);

                // type casting because spec wrongly indicates return type to not be an array
                this.customer_catalog.all_categories.set(catalog_key, response.data as ProductCategoryOverviewRestDto[]);
            }
        },
        async searchProductCategoriesTreeCustomer(customer_code?: string | null, language?: string | null): Promise<void> {
            const s_customer_code = customer_code ?? CustomerContext.getCustomerCode() as string;
            const s_language = language ?? undefined as string | undefined;
            // const s_language = language ?? i18n.global.locale as string | undefined;
            const catalog_key = s_customer_code + s_language as string;

            if (!this.customer_catalog.product_categories_tree.has(catalog_key)) {
                const response = await CpcProductCategoryEndpointsApiService.findProductCategoriesTree(s_customer_code, undefined, s_language);

                this.customer_catalog.product_categories_tree.set(catalog_key, response.data);
            }
        },
        async searchInternalProductCategories(search_name?: string | null): Promise<void> {
            if (!arrayHasContent(this.customer_catalog.internal_product_categories)) {
                this.customer_catalog.internal_product_categories = await PimCategoryRestService.getInstance()
                    .findInternalProductCategories(search_name);
            }
        },

        // PHARMACY
        async searchPharmacies(): Promise<void> {
            const response = await PharmacyInformationEndpointsApiService
                .findPharmacies();

            const hasActivePharmacyCode = response.data
                .find((pharmacy: CodeDescriptionRestDto) => pharmacy.code === Storage.local.get(LocalStorageKeyEnum.CURRENT_PHARMACY_CODE));

            this.pharmacies = response.data;
            await this.setPharmacyCode(hasActivePharmacyCode
                ? Storage.local.get(LocalStorageKeyEnum.CURRENT_PHARMACY_CODE)
                : response.data[0].code.toString()
            );
        },
        async setPharmacyCode(pharmacy_code: string): Promise<void> {
            this.pharmacy_code = pharmacy_code;
            Storage.local.set(LocalStorageKeyEnum.CURRENT_PHARMACY_CODE, pharmacy_code);

            await CustomerService.getInstance().changePharmacyHook(pharmacy_code);
        },

        //PIM
        setPimProductSelectedForCategorization(selected: boolean, cnk_code: number): void {
            if (selected) {
                this.pimState.pim_products_selected_for_categorization.push(cnk_code);
            } else {
                this.pimState.pim_products_selected_for_categorization = this.pimState.pim_products_selected_for_categorization
                    .filter((pim_product_selected: number) => pim_product_selected !== cnk_code);
            }
            console.log('setPimProductsSelectedForCategorization', this.pimState.pim_products_selected_for_categorization);
        },
        setPimProductsSelectedForCategorization(selected: boolean, cnk_codes: number[]): void {
            if (selected) {
                this.pimState.pim_products_selected_for_categorization = cnk_codes;
            } else {
                this.clearPimProductsSelectedForCategorization();
            }
            console.log('setPimProductsSelectedForCategorization', this.pimState.pim_products_selected_for_categorization);
        },

        // CLEAR
        clearCustomerCriteria(): void {
            this.criteria.customer = CustomerCriteriaRestDtoModel.createWithDefaults();
        },
        clearPimProductsCriteria(): void {
            this.criteria.pim_products = PimProductsCriteriaModel.createWithDefaults();
        },
        clearPimLaboProductsCriteria(): void {
            this.criteria.pim_labo_products = PimLaboProductsCriteriaModel.createWithDefaults();
        },
        clearCatalogProductsCriteria(): void {
            this.criteria.catalog_products = CatalogProductsCriteriaModel.createWithDefaults();
        },
        clearPimProductsPaging(): void {
            this.paging.pim_products = PimProductsPagingModel.createWithDefaults();
        },
        clearPimProductsSelectedForCategorization(): void {
            this.pimState.pim_products_selected_for_categorization = [];
        },
    },
    getters: {
        // CUSTOMER
        getCustomers(state: State): CustomerOverviewRestDto[] {
            return state.customers;
        },
        getCustomersWithCriteria(state: State): CustomerOverviewRestDto[] {
            return state.customers_with_criteria;
        },

        // CATALOG
        getAllBrandsCustomer: (state: State) => (customer_code?: string | null, language?: string | null): CodeDescriptionRestDto[] => {
            const s_customer_code = customer_code ?? CustomerContext.getCustomerCode() as string;
            const s_language = language ?? undefined as string | undefined;
            // const s_language = language ?? i18n.global.locale as string | undefined;
            const catalog_key = s_customer_code + s_language as string;

            if (!state.customer_catalog.all_brands.has(catalog_key)) {
                throw new DpException(`All brands of customer ${s_customer_code} in language ${s_language} has not been initialized, yet.`);
            }

            return state.customer_catalog.all_brands.get(catalog_key) as CodeDescriptionRestDto[];
        },
        getAllTrademarksCustomer: (state: State) => (customer_code?: string | null, language?: string | null): CodeDescriptionRestDto[] => {
            const s_customer_code = customer_code ?? CustomerContext.getCustomerCode() as string;
            const s_language = language ?? undefined as string | undefined;
            // const s_language = language ?? i18n.global.locale as string | undefined;
            const catalog_key = s_customer_code + s_language as string;

            if (!state.customer_catalog.all_trademarks.has(catalog_key)) {
                throw new DpException(`All trademarks of customer ${s_customer_code} in language ${s_language} has not been initialized, yet.`);
            }

            return state.customer_catalog.all_trademarks.get(catalog_key) as CodeDescriptionRestDto[];
        },
        getAllCategoriesCustomer: (state: State) => (customer_code?: string | null, language?: string | null): ProductCategoryOverviewRestDto[] => {
            const s_customer_code = customer_code ?? CustomerContext.getCustomerCode() as string;
            const s_language = language ?? undefined as string | undefined;
            // const s_language = language ?? i18n.global.locale as string | undefined;
            const catalog_key = s_customer_code + s_language as string;

            if (!state.customer_catalog.all_categories.has(catalog_key)) {
                throw new DpException(`All categories of customer ${s_customer_code} in language ${s_language} has not been initialized, yet.`);
            }

            return state.customer_catalog.all_categories.get(catalog_key) as ProductCategoryOverviewRestDto[];
        },
        getProductCategoriesTreeCustomer: (state: State) => (customer_code?: string | null, language?: string | null): ProductCategoryRestDto => {
            const s_customer_code = customer_code ?? CustomerContext.getCustomerCode() as string;
            const s_language = language ?? undefined as string | undefined;
            // const s_language = language ?? i18n.global.locale as string | undefined;
            const catalog_key = s_customer_code + s_language as string;

            if (!state.customer_catalog.product_categories_tree.has(catalog_key)) {
                throw new DpException(`Product categories tree of customer ${s_customer_code} in language ${s_language} has not been initialized, yet.`);
            }

            return state.customer_catalog.product_categories_tree.get(catalog_key) as ProductCategoryRestDto;
        },
        getInternalProductCategories(state: State): CodeDescriptionRestDto[] {
            if (!arrayHasContent(state.customer_catalog.internal_product_categories)) {
                throw new DpException(`Internal product categories has not been initialized, yet.`);
            }

            return state.customer_catalog.internal_product_categories;
        },

        // PHARMACY
        getPharmacyCode(state: State): string {
            return state.pharmacy_code;
        },
        getPharmacies(state: State): CodeDescriptionRestDto[] {
            return state.pharmacies;
        },

        // Other
        getCriteria(state: State): Criteria {
            return state.criteria;
        },
        getPaging(state: State): Paging {
            return state.paging;
        },
        getPimState(state: State): PimState {
            return state.pimState;
        },

        // PIM
        canCategorizePimProducts(state: State): boolean {
            return !state.pimState.pim_products_criteria_is_changed && !state.criteria.pim_products.expect_products_in_subcategory && (
                (stringHasContent(state.criteria.pim_products.filter_category_code_dp_managed) && !stringHasContent(state.criteria.pim_products.filter_category_code_internal)) ||
                (!stringHasContent(state.criteria.pim_products.filter_category_code_dp_managed) && stringHasContent(state.criteria.pim_products.filter_category_code_internal))
            );
        },
        canMoveUncategorizedPimProducts(state: State): boolean {
            return !state.pimState.pim_products_criteria_is_changed &&
                (state.criteria.pim_products.filter_is_not_in_internal_category === true && state.criteria.pim_products.filter_dp_managed_category_cardinality === 'ZERO') &&
                (!stringHasContent(state.criteria.pim_products.filter_category_code_dp_managed) && !stringHasContent(state.criteria.pim_products.filter_category_code_internal));
        },
        isPimProductSelectedForCategorization: (state: State) => (cnk_code: number): boolean => {
            return state.pimState.pim_products_selected_for_categorization.includes(cnk_code);
        }
    }
});
