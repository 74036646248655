
import {defineComponent, PropType} from "vue";
import {FullMenuItemRestDto} from "@/api/pharma-cms-menu/models";
import {arrayHasContent} from "@/helpers/functions/arrays";
import MenusOverviewItem from "@/components/layouts/menus/MenusOverviewItem.vue";
import {MoveActionEnum} from "@/models/enum/MoveActionEnum";
import {TinyEmitter} from "tiny-emitter";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import ConfirmModal from "@/components/UI/ConfirmModal.vue";
import {MenuRestService} from "@/services/rest/cms-menu/MenuRestService";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import {UIStateDto} from "@/dtos/UIStateDto";
import i18n from "@/modules/i18n";

export default defineComponent({
  name: "MenusOverview",
  components: {MenusOverviewItem},
  props: {
    menuId: {
      type: String,
      required: true
    },
    menuItems: {
      type: Array as PropType<FullMenuItemRestDto[]>,
      required: true
    },
    menuUi: {
      type: Object as PropType<UIStateDto>,
      required: true
    },
    bus: {
      type: TinyEmitter,
      required: true,
    },
    subMenuId: {
      type: String,
      required: false
    },
    isSubMenu: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted() {
    this.bus.on('move', this.moveItem);
    this.bus.on('delete', this.deleteItem);
  },
  computed: {
    hasMenuItems(): boolean {
      return arrayHasContent(this.menuItems);
    }
  },
  methods: {
    async deleteItem(menuItemId: string): Promise<void> {
      this.bus.emit('clearExceptions');

      try {
        const {reveal, onConfirm} = createConfirmDialog(ConfirmModal, {
          title: 'Menu item verwijderen',
          body: this.$t('deleteConfirmation'),
          confirmButtonText: this.$t('delete'),
          confirmButtonClass: 'btn-danger',
        });

        onConfirm(async () => {
          this.setIsReady(false);

          await MenuRestService.getInstance().deleteMenuItem(menuItemId);

          await handleSavedSuccessfully(i18n.global.t('deletedSuccessfully'));
          await this.bus.emit('reload');
        });

        await reveal();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.setIsReady(true);
      }
    },
    async moveItem(menuItemId: string, moveAction: MoveActionEnum): Promise<void> {
      this.setIsReady(false);

      try {
        await MenuRestService.getInstance().moveMenuItem(menuItemId, moveAction);

        await handleSavedSuccessfully(i18n.global.t('movedSuccessfully'));
        await this.bus.emit('reload');
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.setIsReady(true);
      }
    },
    setExceptions(exceptions: unknown): void {
      this.bus.emit('setExceptions', exceptions);
    },
    setIsReady(isReady: boolean): void {
      this.bus.emit('setIsReady', isReady);
    }
  }
});

