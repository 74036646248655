import {BusinessService} from "@/services/business/BusinessService";
import {I18nContextService} from "@/services/business/I18nContextService";
import {CustomerContext} from "@/context/CustomerContext";
import {CustomerContextService} from "@/services/business/CustomerContextService";
import {AbilityContextService} from "@/services/business/AbilityContextService";
import {CustomerStore, PharmacyStore} from "@/main";

export class CustomerService extends BusinessService {
    public async changeCustomerHook(customerCode: string): Promise<void> {
        const customerCriteriaSearchName: string | null = CustomerStore.getCriteria.customer.search_name ?? null;
        CustomerStore.$reset();

        await CustomerContextService.getInstance()
            .updateCustomerContext(customerCode);

        await I18nContextService.getInstance()
            .updateI18nContext();

        await AbilityContextService.getInstance()
            .updateAbilityContext();

        await CustomerStore.initialize(customerCriteriaSearchName);
    }

    public async changePharmacyHook(pharmacyCode: string): Promise<void> {
        PharmacyStore.$reset();

        CustomerContext.setPharmacyCode(pharmacyCode);

        await PharmacyStore.initialize();
    }
}
