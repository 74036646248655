
import {defineComponent, PropType} from 'vue';
import {Exception} from '@/api/interfaces';
import BaseAlert from "@/components/UI/Bootstrap/Alert/BaseAlert.vue";

export default defineComponent({
  name: "AlertError",
  components: {BaseAlert},
  props: {
    exceptions: {
      type: Array as PropType<Exception[]>,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
