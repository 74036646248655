import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(_ctx.classForLabel),
    style: _normalizeStyle('--bs-badge-font-weight: 400; color: ' + _ctx.textColor + '; background-color: ' + _ctx.backgroundColor),
    innerHTML: _ctx.content
  }, null, 14, _hoisted_1))
}