import {AxiosError} from "axios";
import {ErrorHandler} from "@/error/ErrorHandler";
import {UIStateDto} from "@/dtos/UIStateDto";

export class AxiosErrorHandler extends ErrorHandler {
    public qualify(error: unknown): boolean {
        return error instanceof AxiosError;
    }

    public static createWithDefaultUIStateBehavior(uiStateDto: UIStateDto): AxiosErrorHandler {
        return new AxiosErrorHandler((error: any) => {
            uiStateDto.setError(AxiosErrorHandler.getMessageFromError(error));
        });
    }

    public static getMessageFromError(error: AxiosError): string {
        return error.message + ' (' + (error.status ?? error.code ?? '') + ')';
    }
}