/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum BackgroundColorEnum {
    PRIMARY = 'PRIMARY',
    PRIMARY_LIGHTER = 'PRIMARY_LIGHTER',
    PRIMARY_LIGHT = 'PRIMARY_LIGHT',
    SECONDARY = 'SECONDARY',
    SECONDARY_LIGHTER = 'SECONDARY_LIGHTER',
    SECONDARY_LIGHT = 'SECONDARY_LIGHT',
    GREY = 'GREY',
    TRANSPARENT = 'TRANSPARENT',
}