import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, withModifiers as _withModifiers, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0013f8b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column justify-content-between h-100" }
const _hoisted_2 = { class: "row row-name mb-2" }
const _hoisted_3 = { class: "col-6" }
const _hoisted_4 = { class: "col-6" }
const _hoisted_5 = {
  key: 0,
  class: "row row-settings mb-2"
}
const _hoisted_6 = { class: "col-6" }
const _hoisted_7 = {
  key: 1,
  class: "row row-name mb-2"
}
const _hoisted_8 = { class: "row row-name mb-2" }
const _hoisted_9 = { class: "col-6" }
const _hoisted_10 = { class: "bg-white border border-1 rounded p-3 pb-0" }
const _hoisted_11 = { class: "normal-filter-conditions" }
const _hoisted_12 = { class: "mb-3" }
const _hoisted_13 = { class: "fs-5 lh-sm text-white bg-primary rounded m-0 px-2 py-1" }
const _hoisted_14 = { class: "row row-settings mb-2" }
const _hoisted_15 = { class: "col-6" }
const _hoisted_16 = { class: "row row-settings mb-2" }
const _hoisted_17 = { class: "col-6" }
const _hoisted_18 = { class: "row row-settings mb-2" }
const _hoisted_19 = { class: "col-6" }
const _hoisted_20 = { class: "row row-settings mb-2" }
const _hoisted_21 = { class: "col-6" }
const _hoisted_22 = { class: "col-6" }
const _hoisted_23 = { class: "bg-white border border-1 rounded p-3 pb-0" }
const _hoisted_24 = { class: "normal-filter-conditions" }
const _hoisted_25 = { class: "mb-3" }
const _hoisted_26 = { class: "fs-5 lh-sm text-white bg-primary rounded m-0 px-2 py-1" }
const _hoisted_27 = { class: "d-flex flex-column" }
const _hoisted_28 = {
  key: 0,
  class: "d-inline-block mt-3 pt-4 border-top border-2 border-light"
}
const _hoisted_29 = {
  key: 1,
  class: "row align-items-center pt-3 border-top border-1 border-black border-opacity-25"
}
const _hoisted_30 = { class: "col-auto" }
const _hoisted_31 = { class: "col-auto" }
const _hoisted_32 = { class: "text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertError2 = _resolveComponent("AlertError2")!
  const _component_base_spinner = _resolveComponent("base-spinner")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_input_select = _resolveComponent("input-select")!
  const _component_SelectTrademarks = _resolveComponent("SelectTrademarks")!
  const _component_SelectCategories = _resolveComponent("SelectCategories")!
  const _component_InputCheckboxBoolean = _resolveComponent("InputCheckboxBoolean")!
  const _component_InputCheckboxMultiSelect = _resolveComponent("InputCheckboxMultiSelect")!
  const _component_dp_form = _resolveComponent("dp-form")!
  const _component_base_offcanvas_button_close = _resolveComponent("base-offcanvas-button-close")!
  const _component_CatalogBuildStatus = _resolveComponent("CatalogBuildStatus")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AlertError2, {
      error: this.pageUI.getError()
    }, null, 8, ["error"]),
    (!this.pageUI.getIsReady())
      ? (_openBlock(), _createBlock(_component_base_spinner, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (this.pageUI.getIsReady())
        ? (_openBlock(), _createBlock(_component_dp_form, {
            key: 0,
            as: "form",
            onSubmit: _ctx.submitData,
            novalidate: ""
          }, {
            default: _withCtx(({ errors }) => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.snippet.snippet.name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.snippet.snippet.name) = $event)),
                    modelModifiers: { trim: true },
                    label: _ctx.$t('name'),
                    name: "name",
                    rules: "required|max:100",
                    errors: errors
                  }, null, 8, ["modelValue", "label", "errors"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  (_ctx.mode === 'new')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_input_select, {
                            modelValue: _ctx.snippet.status_code,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.snippet.status_code) = $event)),
                            name: _ctx.$t('status'),
                            options: _ctx.statusOptions,
                            rules: "required",
                            errors: errors
                          }, null, 8, ["modelValue", "name", "options", "errors"])
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t('status')) + ": " + _toDisplayString(_ctx.snippet.status_description), 1)
                      ]))
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("header", _hoisted_12, [
                        _createElementVNode("h3", _hoisted_13, _toDisplayString(_ctx.$t('context')), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_InputText, {
                          modelValue: _ctx.snippet.snippet.context.product_code,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.snippet.snippet.context.product_code) = $event)),
                          label: _ctx.$t('cnkCode'),
                          name: "filter_cnk_code",
                          class: "mt-1",
                          "can-clear": "",
                          errors: errors,
                          onChange: _ctx.onSnippetChanged
                        }, null, 8, ["modelValue", "label", "errors", "onChange"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        _createVNode(_component_SelectTrademarks, {
                          "selected-trademark-codes": _ctx.snippet.snippet.context.trademark_code ? [_ctx.snippet.snippet.context.trademark_code] : [],
                          "catalog-customer-code": _ctx.catalogCustomerCode,
                          class: _normalizeClass([{'trademark-selected': _ctx.snippet.snippet.context.trademark_code}, "mb-3"]),
                          "btn-class": "btn btn-icon btn-outline-primary",
                          onAddTrademark: _ctx.setTrademarkCode,
                          onRemoveTrademark: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setTrademarkCode(undefined)))
                        }, null, 8, ["selected-trademark-codes", "catalog-customer-code", "class", "onAddTrademark"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("div", _hoisted_19, [
                        _createVNode(_component_SelectCategories, {
                          "selected-product-categories": _ctx.snippet.snippet.context.category_code ? [_ctx.snippet.snippet.context.category_code] : [],
                          "catalog-customer-code": _ctx.catalogCustomerCode,
                          class: _normalizeClass([{'category-selected': _ctx.snippet.snippet.context.category_code}, "mb-2"]),
                          "btn-class": "btn btn-icon btn-sm btn-outline-primary w-100",
                          "btn-icon": "category",
                          onAddCategory: _ctx.setCategoryCodeDpManaged,
                          onRemoveCategory: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setCategoryCodeDpManaged(undefined)))
                        }, null, 8, ["selected-product-categories", "catalog-customer-code", "class", "onAddCategory"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _createVNode(_component_InputCheckboxBoolean, {
                          modelValue: _ctx.snippet.snippet.context.is_include_subcategory,
                          "onUpdate:modelValue": [
                            _cache[5] || (_cache[5] = ($event: any) => ((_ctx.snippet.snippet.context.is_include_subcategory) = $event)),
                            _ctx.onSnippetChanged
                          ],
                          label: _ctx.$t('includeSubcategories'),
                          name: "expect_products_in_subcategory",
                          class: "mb-4",
                          errors: errors
                        }, null, 8, ["modelValue", "label", "errors", "onUpdate:modelValue"])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("header", _hoisted_25, [
                        _createElementVNode("h3", _hoisted_26, _toDisplayString(_ctx.$t('position')), 1)
                      ])
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slotPositions, (position) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: position.code
                      }, [
                        _createVNode(_component_InputCheckboxMultiSelect, {
                          modelValue: _ctx.selectedPositions,
                          "onUpdate:modelValue": [
                            _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedPositions) = $event)),
                            _ctx.onSnippetChanged
                          ],
                          label: position.description,
                          value: position.code,
                          name: "positions",
                          errors: errors
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "value", "errors"])
                      ]))
                    }), 128))
                  ])
                ])
              ])
            ]),
            _: 1
          }, 8, ["onSubmit"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_27, [
        (_ctx.mode === 'new')
          ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
              _createElementVNode("button", {
                onClick: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitData && _ctx.submitData(...args)), ["prevent"])),
                class: "btn btn-primary"
              }, _toDisplayString(_ctx.$t('save')), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.mode === 'edit')
          ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("button", {
                  onClick: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitData && _ctx.submitData(...args)), ["prevent"])),
                  class: "btn btn-primary"
                }, _toDisplayString(_ctx.$t('save')), 1)
              ]),
              _createElementVNode("div", _hoisted_31, [
                _createVNode(_component_base_offcanvas_button_close, { class: "btn btn-light" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('close')), 1)
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.$t('cachingDelayMinutes', [5])), 1)
      ])
    ]),
    _createVNode(_component_CatalogBuildStatus)
  ], 64))
}