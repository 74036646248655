import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cb_form_document = _resolveComponent("cb-form-document")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_cb_form_document, {
        "can-restore-default-values": !$options.CustomerContext.isDpContentProviderCustomerCode(),
        "cb-type": "REIMBURSED_CARE_RATES_DOCUMENT",
        "doc-type": "REIMBURSED_CARE_RATES_DOC",
        mode: "edit",
        title: _ctx.$t('reimburesedCareRates'),
        description: _ctx.$t('descriptionReimburesedCareRates')
      }, null, 8, ["can-restore-default-values", "title", "description"])
    ]),
    _: 1
  }))
}