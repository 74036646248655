import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = {
  class: "btn-group",
  role: "group",
  "aria-label": "Base selection tabs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_base_container = _resolveComponent("base-container")!
  const _component_CatalogBuildStatus = _resolveComponent("CatalogBuildStatus")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_container, {
      "container-fluid": "",
      "has-build-status-on-page": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BaseTitle, {
          title: _ctx.$t('productChoice'),
          "help-text": _ctx.$t('descriptionProductChoice'),
          mb: 3
        }, null, 8, ["title", "help-text"]),
        _createElementVNode("nav", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
              type: "button",
              class: _normalizeClass(["btn btn-outline-primary", {'active': this.currentComponent === 'product-selection-base'}]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (this.setCurrentComponent('product-selection-base')))
            }, _toDisplayString(_ctx.$t('baseSelection')), 3),
            _createElementVNode("button", {
              type: "button",
              class: _normalizeClass(["btn btn-outline-primary", {'active': this.currentComponent === 'product-selection-brands'}]),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (this.setCurrentComponent('product-selection-brands')))
            }, _toDisplayString(_ctx.$t('labs')), 3),
            _createElementVNode("button", {
              type: "button",
              class: _normalizeClass(["btn btn-outline-primary", {'active': this.currentComponent === 'product-selection-trademarks'}]),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (this.setCurrentComponent('product-selection-trademarks')))
            }, _toDisplayString(_ctx.$t('trademarks')), 3),
            _createElementVNode("button", {
              type: "button",
              class: _normalizeClass(["btn btn-outline-primary", {'active': this.currentComponent === 'product-selection-products'}]),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (this.setCurrentComponent('product-selection-products')))
            }, _toDisplayString(_ctx.$t('products')), 3)
          ])
        ]),
        (_openBlock(), _createBlock(_KeepAlive, null, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(this.currentComponent), _mergeProps(this.currentProperties, {
            onSavedSuccessfully: this.handleSavedSuccessfully
          }), null, 16, ["onSavedSuccessfully"]))
        ], 1024))
      ]),
      _: 1
    }),
    _createVNode(_component_CatalogBuildStatus)
  ], 64))
}