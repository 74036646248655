import {ContentPageAbstractEndpointsApiService} from "@/main";
import {TargetPageInfoRestDto, TextWithImageAbstractRestDto} from "@/api/pharma-cms-content-page/models";

export default {
    state() {
        return {}
    },
    actions: {
        async searchContentPageOverviewCb(context: any, page_id: string): Promise<TargetPageInfoRestDto | undefined> {
            const response = await ContentPageAbstractEndpointsApiService.getContentPageOverviewCb(page_id);

            if (response.status === 200) {
                return response.data;
            } else if (response.status === 204) {
                return {} as TargetPageInfoRestDto;
            }
        },
        async saveContentPageOverviewCbContent(context: any, payload: any) {
            const page_id = payload.page_id as string;
            const body = {
                content: payload.content as string
            } as TextWithImageAbstractRestDto;

            await ContentPageAbstractEndpointsApiService.updateContentPageOverviewCbContent(body, page_id);
        },
        async saveContentPageOverviewCbImage(context: any, payload: any) {
            const page_id = payload.page_id as string;
            const body = {
                image_key: payload.image_key as string
            } as TextWithImageAbstractRestDto;

            await ContentPageAbstractEndpointsApiService.updateContentPageOverviewCbImage(body, page_id);
        },
        async saveContentPageOverviewCbTitle(context: any, payload: any) {
            const page_id = payload.page_id as string;
            const body = {
                title: payload.title as string
            } as TextWithImageAbstractRestDto;

            await ContentPageAbstractEndpointsApiService.updateContentPageOverviewCbTitle(body, page_id);
        }
    },
    mutations: {},
    getters: {}
}