
import {defineComponent} from 'vue'

export default defineComponent({
  name: "BaseOffcanvasButtonClose",
  props: {
    class: {
      type: String,
      required: false
    }
  }
})
