
import {defineComponent} from "vue";
import CpsOverview from "@/components/layouts/content-pages/overview/CpsOverview.vue";

export default defineComponent({
  name: "ContentPageLibraryOverview",
  components: {CpsOverview},
  data() {
    return {
      libCode: this.$route.params.lib_code as string,
      libPageTypeCode: this.$route.params.page_type_code as string
    }
  },
});
