import {CustomerProductSortWeightAPIApi} from "@/api/pharma-cpc-product-mgmt";
import {RestService} from "@/services/rest/RestService";
import {MoveToPositionRestDto} from "@/api/pharma-cpc-product-mgmt/models";

export class ProductCategorizationRestService extends RestService {
    private customerProductSortWeightAPIApi: CustomerProductSortWeightAPIApi;

    protected constructor() {
        super();

        this.customerProductSortWeightAPIApi = new CustomerProductSortWeightAPIApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cpcUrl);
    }
    public async createCustomerProductSortWeight(productCode: string): Promise<void> {
        await this.customerProductSortWeightAPIApi.createCustomerProductSortWeight(productCode);
    }

    public async deleteCustomerProductSortWeight(productCode: string): Promise<void> {
        await this.customerProductSortWeightAPIApi.deleteCustomerProductSortWeight(productCode);
    }

    public async moveCustomerProductSortWeightTo(productCode: string, body?: MoveToPositionRestDto|null): Promise<void> {
        await this.customerProductSortWeightAPIApi.moveCustomerProductSortWeightTo(productCode, body);
    }

    public async moveCustomerProductSortWeightToBottom(productCode: string): Promise<void> {
        await this.customerProductSortWeightAPIApi.moveCustomerProductSortWeightToBottom(productCode);
    }

    public async moveCustomerProductSortWeightToTop(productCode: string): Promise<void> {
        await this.customerProductSortWeightAPIApi.moveCustomerProductSortWeightToTop(productCode);
    }

}
