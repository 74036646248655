/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Order Orca Customer Management API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface OrcaReservationCampaignRestDto
 */
export interface OrcaReservationCampaignRestDto {
    /**
     * Griepvaccin 2022-2023
     * @type {string}
     * @memberof OrcaReservationCampaignRestDto
     */
    name: any;
    /**
     * Internal description of the campaign.
     * @type {string}
     * @memberof OrcaReservationCampaignRestDto
     */
    description?: any;
    /**
     * The type of this reservation campaign.
     * @type {string}
     * @memberof OrcaReservationCampaignRestDto
     */
    type_code: OrcaReservationCampaignRestDtoTypeCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof OrcaReservationCampaignRestDto
     */
    begin_date?: any;
    /**
     * 
     * @type {string}
     * @memberof OrcaReservationCampaignRestDto
     */
    end_date?: any;
    /**
     * If true, a patient is asked for the specific product that is prescribed in case of a prescription.
     * @type {boolean}
     * @memberof OrcaReservationCampaignRestDto
     */
    is_patient_product_input_enabled?: any;
    /**
     * If true, SMS communication for this campaign is enabled.
     * @type {boolean}
     * @memberof OrcaReservationCampaignRestDto
     */
    is_sms_communication_enabled?: any;
    /**
     * The status of this reservation campaign.
     * @type {string}
     * @memberof OrcaReservationCampaignRestDto
     */
    status_code: OrcaReservationCampaignRestDtoStatusCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum OrcaReservationCampaignRestDtoTypeCodeEnum {
    FLUVACCINE = 'FLU_VACCINE',
    WEIGHTLOSS = 'WEIGHT_LOSS'
}
/**
    * @export
    * @enum {string}
    */
export enum OrcaReservationCampaignRestDtoStatusCodeEnum {
    NEW = 'NEW',
    ACTIVE = 'ACTIVE',
    CLOSED = 'CLOSED'
}

