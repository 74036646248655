import {MultilingualMax50FieldRestDto, MultilingualTextFieldRestDto} from "@/api/pharma-cpc-product-mgmt/models";
import {MultilingualMax100FieldRestDto} from "@/api/pharma-cpc-mgmt/models";

export class MultilingualTextFieldRestDtoModel implements MultilingualTextFieldRestDto, MultilingualMax50FieldRestDto, MultilingualMax100FieldRestDto {
    de?: string | null;
    en?: string | null;
    fr?: string | null;
    nl?: string | null;


    constructor() {
    }

    public static createWithDefaults(): MultilingualTextFieldRestDtoModel {
        return new this();
    }
}
