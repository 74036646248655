import {
    CatalogProductDetailRestDto, ProductCategoryWithParentsRestDto, ProductDiscountRestDto,
    ProductInfoRestDto,
    ProductLabelRestDto
} from "@/api/pharma-cpc-product-mgmt/models";
import {ProductPriceRestDtoModel} from "@/models/api/pharma-cpc-mgmt/ProductPriceRestDtoModel";
import {ProductInfoRestDtoModel} from "@/models/api/pharma-cpc-mgmt/ProductInfoRestDtoModel";

export class CatalogProductDetailRestDtoModel implements CatalogProductDetailRestDto {
    product_code: string;
    product_code_alias: number;
    product_source_code: string;
    main_image_url: string;
    product_info: ProductInfoRestDto;
    categories: ProductCategoryWithParentsRestDto[] | null
    product_price: ProductPriceRestDtoModel;
    discount?: ProductDiscountRestDto | null;
    product_labels: ProductLabelRestDto[];


    constructor() {
        this.product_code = '';
        this.product_code_alias = -1;
        this.product_source_code = '';
        this.main_image_url = '';
        this.product_info = ProductInfoRestDtoModel.createWithDefaults();
        this.categories = [];
        this.product_price = ProductPriceRestDtoModel.createWithDefaults();
        this.product_labels = [];
    }

    public static createWithDefaults(): CatalogProductDetailRestDtoModel {
        return new this();
    }
}
