import {PimFeedbackRestDto} from "@/api/pharma-pim-mgmt/models";

export class PimFeedbackRestDtoModel implements PimFeedbackRestDto {
    field_code?: string | null;
    text_fragment_id?: string | null;
    proposed_new_fragment_name_code?: string | null;
    proposed_new_fragment_title?: string | null;
    proposed_new_value?: string | null;
    remarks?: string | null;

    constructor() {
        this.proposed_new_fragment_name_code = null;
        this.proposed_new_fragment_title = null;
        this.proposed_new_value = null;
    }

    public static createWithDefaults(): PimFeedbackRestDtoModel {
        return new this()
    }
}
