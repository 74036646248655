import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Suspense as _Suspense, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LazyImage = _resolveComponent("LazyImage")!
  const _component_base_modal_button = _resolveComponent("base-modal-button")!

  return (this.imageKey)
    ? (_openBlock(), _createBlock(_Suspense, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_base_modal_button, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('copied', _ctx.contentBlock.id))),
            class: "item mx-2 mb-3 border-0 p-0",
            mode: "close"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_LazyImage, {
                "image-key": this.imageKey,
                alt: "Banner preview",
                class: "w-100 h-100"
              }, null, 8, ["image-key"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}