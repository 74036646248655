import {RestService} from "@/services/rest/RestService";
import {CatalogSettingsAPIApi} from "@/api/pharma-cpc-mgmt";
import {
    ProductImportSettingsRestDto
} from "@/api/pharma-cpc-mgmt/models";

export class CatalogSettingsRestService extends RestService {

    private apiService: CatalogSettingsAPIApi;

    protected constructor() {
        super();

        this.apiService = new CatalogSettingsAPIApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cpcUrl);
    }

    public async findProductSelectionBase(): Promise<ProductImportSettingsRestDto> {
        const response = await this.apiService.findProductSelectionBase();

        return response.data;
    }

    public async updateProductSelectionBase(productImportSettings: ProductImportSettingsRestDto): Promise<void> {
        await this.apiService.updateProductSelectionBase(productImportSettings);
    }
}
