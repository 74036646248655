import {RestService} from "@/services/rest/RestService";
import {ContentPageRowEndpointsApi} from "@/api/pharma-cms-content-page";
import {ContentVisibilityRuleForElementRestDto} from "@/api/pharma-cms-content-page/models";

export class ContentPageRowRestService extends RestService {

    private apiService: ContentPageRowEndpointsApi;

    protected constructor() {
        super();

        this.apiService = new ContentPageRowEndpointsApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cmsUrl);
    }

    public async getContentPageRowVisibilityRule(pageId: string, rowId: string): Promise<ContentVisibilityRuleForElementRestDto> {
        const response = await this.apiService.getContentPageRowVisibilityRule(pageId, rowId);

        return response.data;
    }

    public async updateContentPageRowVisibilityRule(visibilityRuleForElement: ContentVisibilityRuleForElementRestDto, pageId: string, rowId: string): Promise<void> {
        await this.apiService.updateContentPageRowVisibilityRule(visibilityRuleForElement, pageId, rowId);
    }
}
