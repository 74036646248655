
import {defineComponent} from "vue";
import ProductCategoriesTreeItem from "@/components/UI/catalog/ProductCategoriesTreeItem.vue";

export default defineComponent({
  name: "ProductCategoriesTree",
  emits: ["selectCategory"],
  components: {ProductCategoriesTreeItem},
  props: {
    categoriesTree: {
      type: Object,
      required: true
    }
  },
  methods: {
    selectCategory(category_code: string): void {
      this.$emit('selectCategory', category_code);
    }
  }
});
