import {PageLinkTypeEnum} from "@/models/enum/PageLinkTypeEnum";
import {LinkTargetEnum} from "@/models/enum/LinkTargetEnum";
import {PageRefTargetEnum} from "@/api/enums/page-ref-target-enum";
import {TargetEnum} from "@/api/enums/target-enum";
import {MultilingualPayload} from "@/models/payload/MultilingualPayload";
import {BannerContentV2RestDto} from "@/api/pharma-cms-content-block/models";
import {BannerContentV2LinkTypeEnum} from "@/api/enums/banner-content-v2-link-type-enum";

export class I18nPageLinkPayload {
    constructor(
        public external_url: MultilingualPayload,
        public page_id: MultilingualPayload,
        //todo change target prop of banner cb to LinkTargetEnum
        public target: LinkTargetEnum | PageRefTargetEnum | TargetEnum,
        public type: PageLinkTypeEnum
    ) {}

    public static createWithDefaults(): I18nPageLinkPayload {
        return new this(new MultilingualPayload(), new MultilingualPayload(), LinkTargetEnum.SELF, PageLinkTypeEnum.NONE);
    }

    public static cloneFrom(payload: I18nPageLinkPayload): I18nPageLinkPayload {
        return new this(payload.external_url, payload.page_id, payload.target, payload.type);
    }

    public static fromBannerV2(cb: BannerContentV2RestDto): I18nPageLinkPayload {
        const a = this.createWithDefaults();

        a.external_url = MultilingualPayload.fromMultilingualRestDto(cb.ext_link_url ?? null);
        a.page_id = MultilingualPayload.fromMultilingualRestDto(cb.internal_page_id ?? null);
        a.target = cb.page_ref_target ?? LinkTargetEnum.SELF;

        switch (cb.link_type_code) {
            case BannerContentV2LinkTypeEnum.NO_LINK:
                a.type = PageLinkTypeEnum.NONE;
                break;
            case BannerContentV2LinkTypeEnum.EXTERNAL_LINK:
                a.type = PageLinkTypeEnum.URL;
                break;
            case BannerContentV2LinkTypeEnum.INTERNAL_PAGE_REF:
                a.type = PageLinkTypeEnum.PAGE;
                break;
            case BannerContentV2LinkTypeEnum.PRODUCT_FILTER:
                a.type = PageLinkTypeEnum.PRODUCT_FILTER;
                break;
        }

        return a;
    }

    public resetExternalUrl(): void {
        this.external_url = new MultilingualPayload();
    }

    public resetPageId(): void {
        this.page_id = new MultilingualPayload();
    }
}
