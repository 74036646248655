
import {defineComponent} from "vue";
import CpsDetailWysiwyg from "@/components/layouts/content-pages/detail/CpsDetailWysiwyg.vue";

export default defineComponent({
  name: "ContentPagesDetailWysiwyg",
  components: {CpsDetailWysiwyg},
  props: ['mode'],
  data() {
    return {
      id: this.$route.params.id as string,
      libCode: this.$route.params.lib_code as string,
      libPageTypeCode: this.$route.params.page_type_code as string
    };
  },
});
