import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/product-card-discount/product_card_discount_price_percentage.png'
import _imports_1 from '@/assets/images/product-card-discount/product_card_discount_price.png'
import _imports_2 from '@/assets/images/product-card-discount/product_card_discount_none.png'


const _withScopeId = n => (_pushScopeId("data-v-00228a3f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fw-700" }
const _hoisted_2 = { class: "row mb-5" }
const _hoisted_3 = { class: "col-md-12 col-xxl-9" }
const _hoisted_4 = { class: "discount-options row d-flex align-items-stretch" }
const _hoisted_5 = { class: "discount-option col d-flex flex-column justify-content-between" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "discount-option col d-flex flex-column justify-content-between" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "discount-option col d-flex flex-column justify-content-between" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-xxl-8" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-4" }
const _hoisted_15 = { class: "row form-buttons d-flex flex-column justify-content-start" }
const _hoisted_16 = { class: "col d-flex" }
const _hoisted_17 = { class: "d-inline-block mt-4 pt-4 border-top border-2 border-light" }
const _hoisted_18 = {
  type: "submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_alert_success = _resolveComponent("alert-success")!
  const _component_alert_error = _resolveComponent("alert-error")!
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_input_number = _resolveComponent("input-number")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_dp_form = _resolveComponent("dp-form")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_BaseTitle, {
        title: _ctx.$t('discount'),
        "help-text": _ctx.$t('descriptionStarterDiscount'),
        "show-refresh-button": "",
        onRefresh: this.reloadContent
      }, null, 8, ["title", "help-text", "onRefresh"]),
      _createVNode(_component_alert_success, {
        "show-if": this.savedSuccessfully,
        class: "mb-3"
      }, null, 8, ["show-if"]),
      _createVNode(_component_alert_error, {
        exceptions: this.exceptions,
        class: "mb-3"
      }, null, 8, ["exceptions"]),
      _createVNode(_component_BaseSpinner, {
        spin: !this.isReady,
        "spinner-class": "my-5"
      }, null, 8, ["spin"]),
      (this.isReady)
        ? (_openBlock(), _createBlock(_component_dp_form, {
            key: 0,
            as: "form",
            onSubmit: _ctx.submitData,
            novalidate: "",
            class: "mb-5"
          }, {
            default: _withCtx(({ errors }) => [
              _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('discountChoose')), 1),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("p", {
                        class: _normalizeClass({'text-primary': _ctx.chosenDiscountOption === 'price-percentage'})
                      }, _toDisplayString(_ctx.$t('discountOptionOne')), 3),
                      _createElementVNode("div", {
                        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.selectDiscountOption('price-percentage')), ["prevent"])),
                        class: "d-flex flex-column justify-content-end mt-4 pe-5"
                      }, [
                        _createElementVNode("img", {
                          class: _normalizeClass(["d-inline-block w-100", {'opacity-50': _ctx.chosenDiscountOption !== 'price-percentage'}]),
                          src: _imports_0
                        }, null, 2),
                        _createElementVNode("button", {
                          class: _normalizeClass(["btn", _ctx.chosenDiscountOption === 'price-percentage' ? 'btn-outline-primary': 'btn-primary']),
                          disabled: _ctx.chosenDiscountOption === 'price-percentage'
                        }, _toDisplayString(_ctx.chosenDiscountOption === 'price-percentage' ? _ctx.$t('selected') : _ctx.$t('select')), 11, _hoisted_6)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("p", {
                        class: _normalizeClass({'text-primary': _ctx.chosenDiscountOption === 'price'})
                      }, _toDisplayString(_ctx.$t('discountOptionTwo')), 3),
                      _createElementVNode("div", {
                        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.selectDiscountOption('price')), ["prevent"])),
                        class: "d-flex flex-column justify-content-end mt-4 pe-5"
                      }, [
                        _createElementVNode("img", {
                          class: _normalizeClass(["d-inline-block w-100", {'opacity-50': _ctx.chosenDiscountOption !== 'price'}]),
                          src: _imports_1
                        }, null, 2),
                        _createElementVNode("button", {
                          class: _normalizeClass(["btn", _ctx.chosenDiscountOption === 'price' ? 'btn-outline-primary': 'btn-primary']),
                          disabled: _ctx.chosenDiscountOption === 'price'
                        }, _toDisplayString(_ctx.chosenDiscountOption === 'price' ? _ctx.$t('selected') : _ctx.$t('select')), 11, _hoisted_8)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("p", {
                        class: _normalizeClass({'text-primary': _ctx.chosenDiscountOption === 'none'})
                      }, _toDisplayString(_ctx.$t('discountOptionThree')), 3),
                      _createElementVNode("div", {
                        onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.selectDiscountOption('none')), ["prevent"])),
                        class: "d-flex flex-column justify-content-end mt-4 pe-5"
                      }, [
                        _createElementVNode("img", {
                          class: _normalizeClass(["d-inline-block w-100", {'opacity-50': _ctx.chosenDiscountOption !== 'none'}]),
                          src: _imports_2
                        }, null, 2),
                        _createElementVNode("button", {
                          class: _normalizeClass(["btn", _ctx.chosenDiscountOption === 'none' ? 'btn-outline-primary': 'btn-primary']),
                          disabled: _ctx.chosenDiscountOption === 'none'
                        }, _toDisplayString(_ctx.chosenDiscountOption === 'none' ? _ctx.$t('selected') : _ctx.$t('select')), 11, _hoisted_10)
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createVNode(_component_input_number, {
                        modelValue: _ctx.starterDiscount.discount_percentage,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.starterDiscount.discount_percentage) = $event)),
                        modelModifiers: { number: true },
                        label: _ctx.$t('discountPercentageLabel'),
                        name: "discountPercentage",
                        min: 0,
                        max: 100,
                        step: 0.01,
                        rules: "required",
                        errors: errors
                      }, null, 8, ["modelValue", "label", "step", "errors"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("button", {
                        onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.reloadContent && _ctx.reloadContent(...args)), ["prevent"])),
                        class: "btn btn-secondary me-3"
                      }, _toDisplayString(_ctx.$t('contentReload')), 1),
                      _createElementVNode("button", {
                        onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.removeDiscount && _ctx.removeDiscount(...args)), ["prevent"])),
                        class: "btn btn-danger"
                      }, _toDisplayString(_ctx.$t('removeDiscount')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("button", _hoisted_18, _toDisplayString(_ctx.$t('save')), 1),
                      _createVNode(_component_router_link, {
                        class: "ms-3 btn btn-outline-primary",
                        to: "/"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ])
                ])
              ])
            ]),
            _: 1
          }, 8, ["onSubmit"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}