import {NormalContentBlockModel} from "@/models/api/pharma-cms-content-block/NormalContentBlockModel";
import {
    cloneRowSeparatorContentRestDtoMetaData,
    RowSeparatorContentRestDto
} from "@/api/pharma-cms-content-block/models";
import {BackgroundColorEnum} from "@/api/enums/background-color-enum";
import {HeightEnum} from "@/api/enums/height-enum";
import {StatusEnum} from "@/api/enums/status-enum";

export class RowSeparatorContentRestDtoModel extends NormalContentBlockModel implements RowSeparatorContentRestDto {
    background_color_code: BackgroundColorEnum;
    cb_metadata?: any;
    height_code: HeightEnum;
    sort_weight?: number;
    status_code: StatusEnum;

    constructor(background_color_code: BackgroundColorEnum, height_code: HeightEnum, status_code: StatusEnum) {
        super();
        this.background_color_code = background_color_code;
        this.height_code = height_code;
        this.status_code = status_code;
    }

    public static createWithDefaults(): RowSeparatorContentRestDtoModel {
        return new this(BackgroundColorEnum.PRIMARY, HeightEnum.M, StatusEnum.PUBLISHED);
    }

    public static cloneFrom(source: RowSeparatorContentRestDtoModel): RowSeparatorContentRestDtoModel {
        const model = new this(
            source.background_color_code,
            source.height_code,
            source.status_code
        );
        model.cb_metadata = source.cb_metadata;
        model.sort_weight = source.sort_weight;

        return model;
    }

    public cloneMetadataFrom(source: RowSeparatorContentRestDtoModel): void {
        cloneRowSeparatorContentRestDtoMetaData(this, source);
    }
}
