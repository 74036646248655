import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "row mb-4" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = {
  key: 0,
  class: "my-3"
}
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_LibContentBlocksItem = _resolveComponent("LibContentBlocksItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseSpinner, {
        spin: !this.isReady
      }, null, 8, ["spin"]),
      (this.isReady)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (!this.hasContentBlocks)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('noContentFound')), 1))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(this.libraryContentBlocks, (libraryContentBlock) => {
                  return (_openBlock(), _createBlock(_component_LibContentBlocksItem, {
                    key: libraryContentBlock.id,
                    "content-block": libraryContentBlock,
                    "lib-type": this.libType,
                    onCopied: this.copyContentBlock
                  }, null, 8, ["content-block", "lib-type", "onCopied"]))
                }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}