
import {defineComponent} from "vue";
import {mapActions} from 'vuex';
import {TextContentRestDtoModel} from "@/models/api/pharma-cms-content-block/TextContentRestDtoModel";
import AbstractCbCommon from "@/components/layouts/content-pages/wysiwyg/structures/AbstractCbCommon.vue";
import {CopyCpAbstractPayload} from "@/models/payload/CopyCpAbstractPayload";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import InputTextEditor from "@/components/UI/InputTextEditor.vue";

export default defineComponent({
  name: "CbText",
  components: {InputTextEditor, BaseSpinner},
  extends: AbstractCbCommon,
  data: () => ({
    contentBlock: TextContentRestDtoModel.createWithDefaults(),
  }),
  watch: {
    contentBlock() {
      this.onCopyCpAbstractChanged();
    },
  },
  methods: {
    ...mapActions('cms_content_block/normal_content_block/text', [
      'create', 'find', 'save',
    ]),
    getDefaultCbModel(): TextContentRestDtoModel {
      return TextContentRestDtoModel.createWithDefaults();
    },
    onCopyCpAbstractChanged(): void {
      const content = this.contentBlock.content?.nl ?? null;
      this.copyCpAbstractPayload = new CopyCpAbstractPayload(content, null, null);

      this.$emit('abstractContentChanged', this.copyCpAbstractPayload);
    },
  }
});
