
import {defineComponent} from "vue";
import {PageDetailModeEnum} from "@/models/enum/PageDetailModeEnum";
import {HeaderContentTypeEnum} from "@/models/enum/HeaderContentTypeEnum";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import HeaderDetailContent1 from "@/components/layouts/header-footer/HeaderDetailContent1.vue";
import HeaderDetailContent0 from "@/components/layouts/header-footer/HeaderDetailContent0.vue";

export default defineComponent({
  name: "HeaderDetail",
  components: {HeaderDetailContent0, HeaderDetailContent1, BaseTitle},
  data() {
    return {
      id: this.$route.params.id as string,
      type: this.$route.params.type as string,
    }
  },
  computed: {
    HeaderContentTypeEnum() {
      return HeaderContentTypeEnum
    },
    PageDetailModeEnum() {
      return PageDetailModeEnum
    }
  }
});
