
import {defineComponent, PropType} from "vue";
import {arrayHasContent} from "@/helpers/functions/arrays";
import SelectBrandsSelectedItem
  from "@/components/layouts/catalog/SelectBrandsSelectedItem.vue";
import {CodeDescriptionRestDto} from "@/api/pharma-cpc-product/models";

export default defineComponent({
  name: "SelectBrandsSelected",
  components: {SelectBrandsSelectedItem},
  emits: ["removeBrand"],
  props: {
    selectedBrandCodes: {
      type: Array as PropType<string[]>,
      required: true
    },
    catalogCustomerCode: {
      type: String,
      required: false,
    },
    pimLaboBrands: {
      type: Array as PropType<CodeDescriptionRestDto[]>,
      required: false
    }
  },
  computed: {
    hasSelectedBrandCodes(): boolean {
      return arrayHasContent(this.selectedBrandCodes);
    },
  },
  methods: {
    removeBrand(brand_code: string) {
      this.$emit('removeBrand', brand_code);
    },
  }
});
