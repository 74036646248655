import {MultilingualProductPropertyRestDto, MultilingualTextFieldRestDto} from "@/api/pharma-cpc-product-mgmt/models";
import {
    MultilingualTextFieldRestDtoModel
} from "@/models/api/pharma-cpc-product-mgmt/MultilingualTextFieldRestDtoModel";

export class MultilingualProductPropertyRestDtoModel implements MultilingualProductPropertyRestDto {
    property_type_code: string;
    value: MultilingualTextFieldRestDto;

    constructor(type: string, val: MultilingualTextFieldRestDto) {
        this.property_type_code = type;
        this.value = val;
    }

    public static createWithDefaults(): MultilingualProductPropertyRestDtoModel {
        return new this('', MultilingualTextFieldRestDtoModel.createWithDefaults());
    }
}
