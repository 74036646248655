/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-PIM Product Category API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CnkListRestDto } from '../models';
import { MoveToRestDto } from '../models';
/**
 * CategorizationAPIApi - axios parameter creator
 * @export
 */
export const CategorizationAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add a DP managed category to the specified product.
         * @summary REST.API.PIM.CAT.11 Add a DP managed category to the specified product
         * @param {number} cnk_code Uniquely identifies a product
         * @param {string} category_code The category code that uniquely identifies the category.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDpCategoryToProduct: async (cnk_code: number, category_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cnk_code' is not null or undefined
            if (cnk_code === null || cnk_code === undefined) {
                throw new RequiredError('cnk_code','Required parameter cnk_code was null or undefined when calling addDpCategoryToProduct.');
            }
            // verify required parameter 'category_code' is not null or undefined
            if (category_code === null || category_code === undefined) {
                throw new RequiredError('category_code','Required parameter category_code was null or undefined when calling addDpCategoryToProduct.');
            }
            const localVarPath = `/category/v1/product/{cnk_code}/add_dp_category/{category_code}`
                .replace(`{${"cnk_code"}}`, encodeURIComponent(String(cnk_code)))
                .replace(`{${"category_code"}}`, encodeURIComponent(String(category_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Add an internal category to the specified product.
         * @summary REST.API.PIM.CAT.12 Add an internal category to the specified product
         * @param {number} cnk_code Uniquely identifies a product
         * @param {string} category_code The category code that uniquely identifies the category.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInternalCategoryToProduct: async (cnk_code: number, category_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cnk_code' is not null or undefined
            if (cnk_code === null || cnk_code === undefined) {
                throw new RequiredError('cnk_code','Required parameter cnk_code was null or undefined when calling addInternalCategoryToProduct.');
            }
            // verify required parameter 'category_code' is not null or undefined
            if (category_code === null || category_code === undefined) {
                throw new RequiredError('category_code','Required parameter category_code was null or undefined when calling addInternalCategoryToProduct.');
            }
            const localVarPath = `/category/v1/product/{cnk_code}/add_internal_category/{category_code}`
                .replace(`{${"cnk_code"}}`, encodeURIComponent(String(cnk_code)))
                .replace(`{${"category_code"}}`, encodeURIComponent(String(category_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * All products are copied to the specified category (if they don't already belong to that category), if they belong to other DP managed OR internal categories, those associations do not change.
         * @summary REST.API.PIM.CAT.01 Copy products to DP managed category
         * @param {CnkListRestDto} body 
         * @param {string} category_code The category code that uniquely identifies the category. (Note that multiple categories can be identified by using &#x27;|&#x27; as separator)
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyProductsToDpCategory: async (body: CnkListRestDto, category_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling copyProductsToDpCategory.');
            }
            // verify required parameter 'category_code' is not null or undefined
            if (category_code === null || category_code === undefined) {
                throw new RequiredError('category_code','Required parameter category_code was null or undefined when calling copyProductsToDpCategory.');
            }
            const localVarPath = `/category/v1/dp_managed_category/{category_code}/copy_products_to`
                .replace(`{${"category_code"}}`, encodeURIComponent(String(category_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * All products are copied to the specified category (if they don't already belong to that category), if they belong to other DP managed OR internal categories, those associations do not change.
         * @summary REST.API.PIM.CAT.04 Copy products to internal category
         * @param {CnkListRestDto} body 
         * @param {string} category_code The product CNK code that uniquely identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyProductsToInternalCategory: async (body: CnkListRestDto, category_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling copyProductsToInternalCategory.');
            }
            // verify required parameter 'category_code' is not null or undefined
            if (category_code === null || category_code === undefined) {
                throw new RequiredError('category_code','Required parameter category_code was null or undefined when calling copyProductsToInternalCategory.');
            }
            const localVarPath = `/category/v1/internal_category/{category_code}/copy_products_to`
                .replace(`{${"category_code"}}`, encodeURIComponent(String(category_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.PIM.CAT.08 Export categories
         * @param {string} [accept] text/csv
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDpCategories: async (accept?: string|null, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/category/v1/dp_managed_category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accept !== undefined && accept !== null) {
                localVarHeaderParameter['Accept'] = String(accept);
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.PIM.CAT.09 Export products with category
         * @param {string} [accept] text/csv
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProductsInDpCategories: async (accept?: string|null, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/category/v1/product_in_dp_managed_category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accept !== undefined && accept !== null) {
                localVarHeaderParameter['Accept'] = String(accept);
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This is the legacy export that returns max 1 row per product and max 4 categories per product
         * @summary REST.API.PIM.CAT.10 Export products with categories
         * @param {string} [accept] text/csv
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProductsWithDpCategories: async (accept?: string|null, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/category/v1/product_with_dp_managed_categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accept !== undefined && accept !== null) {
                localVarHeaderParameter['Accept'] = String(accept);
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * All products are moved to the specified category (if they don't already belong to that category), if they belong to other DP managed OR internal categories, those associations will be removed.
         * @summary REST.API.PIM.CAT.02 Move products to DP managed category
         * @param {MoveToRestDto} body 
         * @param {string} category_code The category code that uniquely identifies the category. (Note that multiple categories can be identified by using &#x27;|&#x27; as separator)
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveProductsToDpCategory: async (body: MoveToRestDto, category_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling moveProductsToDpCategory.');
            }
            // verify required parameter 'category_code' is not null or undefined
            if (category_code === null || category_code === undefined) {
                throw new RequiredError('category_code','Required parameter category_code was null or undefined when calling moveProductsToDpCategory.');
            }
            const localVarPath = `/category/v1/dp_managed_category/{category_code}/move_products_to`
                .replace(`{${"category_code"}}`, encodeURIComponent(String(category_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * All products are moved to the specified category (if they don't already belong to that category), if they belong to other DP managed OR internal categories, those associations will be removed.
         * @summary REST.API.PIM.CAT.05 Move products to internal category
         * @param {MoveToRestDto} body 
         * @param {string} category_code The product CNK code that uniquely identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveProductsToInternalCategory: async (body: MoveToRestDto, category_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling moveProductsToInternalCategory.');
            }
            // verify required parameter 'category_code' is not null or undefined
            if (category_code === null || category_code === undefined) {
                throw new RequiredError('category_code','Required parameter category_code was null or undefined when calling moveProductsToInternalCategory.');
            }
            const localVarPath = `/category/v1/internal_category/{category_code}/move_products_to`
                .replace(`{${"category_code"}}`, encodeURIComponent(String(category_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * All products are removed from the specified category.
         * @summary REST.API.PIM.CAT.03 Remove products from DP managed category
         * @param {CnkListRestDto} body 
         * @param {string} category_code The product CNK code that uniquely identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProductsFromDpCategory: async (body: CnkListRestDto, category_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling removeProductsFromDpCategory.');
            }
            // verify required parameter 'category_code' is not null or undefined
            if (category_code === null || category_code === undefined) {
                throw new RequiredError('category_code','Required parameter category_code was null or undefined when calling removeProductsFromDpCategory.');
            }
            const localVarPath = `/category/v1/dp_managed_category/{category_code}/remove_products`
                .replace(`{${"category_code"}}`, encodeURIComponent(String(category_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * All products are removed from the specified category.
         * @summary REST.API.PIM.CAT.06 Remove products from internal category
         * @param {CnkListRestDto} body 
         * @param {string} category_code The product CNK code that uniquely identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProductsFromInternalCategory: async (body: CnkListRestDto, category_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling removeProductsFromInternalCategory.');
            }
            // verify required parameter 'category_code' is not null or undefined
            if (category_code === null || category_code === undefined) {
                throw new RequiredError('category_code','Required parameter category_code was null or undefined when calling removeProductsFromInternalCategory.');
            }
            const localVarPath = `/category/v1/internal_category/{category_code}/remove_products`
                .replace(`{${"category_code"}}`, encodeURIComponent(String(category_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategorizationAPIApi - functional programming interface
 * @export
 */
export const CategorizationAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Add a DP managed category to the specified product.
         * @summary REST.API.PIM.CAT.11 Add a DP managed category to the specified product
         * @param {number} cnk_code Uniquely identifies a product
         * @param {string} category_code The category code that uniquely identifies the category.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDpCategoryToProduct(_axios: AxiosInstance, cnk_code: number, category_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CategorizationAPIApiAxiosParamCreator(configuration).addDpCategoryToProduct(cnk_code, category_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Add an internal category to the specified product.
         * @summary REST.API.PIM.CAT.12 Add an internal category to the specified product
         * @param {number} cnk_code Uniquely identifies a product
         * @param {string} category_code The category code that uniquely identifies the category.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addInternalCategoryToProduct(_axios: AxiosInstance, cnk_code: number, category_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CategorizationAPIApiAxiosParamCreator(configuration).addInternalCategoryToProduct(cnk_code, category_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * All products are copied to the specified category (if they don't already belong to that category), if they belong to other DP managed OR internal categories, those associations do not change.
         * @summary REST.API.PIM.CAT.01 Copy products to DP managed category
         * @param {CnkListRestDto} body 
         * @param {string} category_code The category code that uniquely identifies the category. (Note that multiple categories can be identified by using &#x27;|&#x27; as separator)
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyProductsToDpCategory(_axios: AxiosInstance, body: CnkListRestDto, category_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CategorizationAPIApiAxiosParamCreator(configuration).copyProductsToDpCategory(body, category_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * All products are copied to the specified category (if they don't already belong to that category), if they belong to other DP managed OR internal categories, those associations do not change.
         * @summary REST.API.PIM.CAT.04 Copy products to internal category
         * @param {CnkListRestDto} body 
         * @param {string} category_code The product CNK code that uniquely identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyProductsToInternalCategory(_axios: AxiosInstance, body: CnkListRestDto, category_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CategorizationAPIApiAxiosParamCreator(configuration).copyProductsToInternalCategory(body, category_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.PIM.CAT.08 Export categories
         * @param {string} [accept] text/csv
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportDpCategories(_axios: AxiosInstance, accept?: string|null, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CategorizationAPIApiAxiosParamCreator(configuration).exportDpCategories(accept, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.PIM.CAT.09 Export products with category
         * @param {string} [accept] text/csv
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportProductsInDpCategories(_axios: AxiosInstance, accept?: string|null, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CategorizationAPIApiAxiosParamCreator(configuration).exportProductsInDpCategories(accept, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This is the legacy export that returns max 1 row per product and max 4 categories per product
         * @summary REST.API.PIM.CAT.10 Export products with categories
         * @param {string} [accept] text/csv
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportProductsWithDpCategories(_axios: AxiosInstance, accept?: string|null, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CategorizationAPIApiAxiosParamCreator(configuration).exportProductsWithDpCategories(accept, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * All products are moved to the specified category (if they don't already belong to that category), if they belong to other DP managed OR internal categories, those associations will be removed.
         * @summary REST.API.PIM.CAT.02 Move products to DP managed category
         * @param {MoveToRestDto} body 
         * @param {string} category_code The category code that uniquely identifies the category. (Note that multiple categories can be identified by using &#x27;|&#x27; as separator)
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveProductsToDpCategory(_axios: AxiosInstance, body: MoveToRestDto, category_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CategorizationAPIApiAxiosParamCreator(configuration).moveProductsToDpCategory(body, category_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * All products are moved to the specified category (if they don't already belong to that category), if they belong to other DP managed OR internal categories, those associations will be removed.
         * @summary REST.API.PIM.CAT.05 Move products to internal category
         * @param {MoveToRestDto} body 
         * @param {string} category_code The product CNK code that uniquely identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveProductsToInternalCategory(_axios: AxiosInstance, body: MoveToRestDto, category_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CategorizationAPIApiAxiosParamCreator(configuration).moveProductsToInternalCategory(body, category_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * All products are removed from the specified category.
         * @summary REST.API.PIM.CAT.03 Remove products from DP managed category
         * @param {CnkListRestDto} body 
         * @param {string} category_code The product CNK code that uniquely identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeProductsFromDpCategory(_axios: AxiosInstance, body: CnkListRestDto, category_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CategorizationAPIApiAxiosParamCreator(configuration).removeProductsFromDpCategory(body, category_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * All products are removed from the specified category.
         * @summary REST.API.PIM.CAT.06 Remove products from internal category
         * @param {CnkListRestDto} body 
         * @param {string} category_code The product CNK code that uniquely identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeProductsFromInternalCategory(_axios: AxiosInstance, body: CnkListRestDto, category_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CategorizationAPIApiAxiosParamCreator(configuration).removeProductsFromInternalCategory(body, category_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CategorizationAPIApi - object-oriented interface
 * @export
 * @class CategorizationAPIApi
 * @extends {BaseAPI}
 */
export class CategorizationAPIApi extends BaseAPI {
    /**
     * Add a DP managed category to the specified product.
     * @summary REST.API.PIM.CAT.11 Add a DP managed category to the specified product
     * @param {number} cnk_code Uniquely identifies a product
     * @param {string} category_code The category code that uniquely identifies the category.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategorizationAPIApi
     */
     public addDpCategoryToProduct(cnk_code: number, category_code: string, x_dp_customer_code?: string|null, options?: any) {
        return CategorizationAPIApiFp(this.configuration).addDpCategoryToProduct(this.axios, cnk_code, category_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add an internal category to the specified product.
     * @summary REST.API.PIM.CAT.12 Add an internal category to the specified product
     * @param {number} cnk_code Uniquely identifies a product
     * @param {string} category_code The category code that uniquely identifies the category.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategorizationAPIApi
     */
     public addInternalCategoryToProduct(cnk_code: number, category_code: string, x_dp_customer_code?: string|null, options?: any) {
        return CategorizationAPIApiFp(this.configuration).addInternalCategoryToProduct(this.axios, cnk_code, category_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * All products are copied to the specified category (if they don't already belong to that category), if they belong to other DP managed OR internal categories, those associations do not change.
     * @summary REST.API.PIM.CAT.01 Copy products to DP managed category
     * @param {CnkListRestDto} body 
     * @param {string} category_code The category code that uniquely identifies the category. (Note that multiple categories can be identified by using &#x27;|&#x27; as separator)
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategorizationAPIApi
     */
     public copyProductsToDpCategory(body: CnkListRestDto, category_code: string, x_dp_customer_code?: string|null, options?: any) {
        return CategorizationAPIApiFp(this.configuration).copyProductsToDpCategory(this.axios, body, category_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * All products are copied to the specified category (if they don't already belong to that category), if they belong to other DP managed OR internal categories, those associations do not change.
     * @summary REST.API.PIM.CAT.04 Copy products to internal category
     * @param {CnkListRestDto} body 
     * @param {string} category_code The product CNK code that uniquely identifies the product.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategorizationAPIApi
     */
     public copyProductsToInternalCategory(body: CnkListRestDto, category_code: string, x_dp_customer_code?: string|null, options?: any) {
        return CategorizationAPIApiFp(this.configuration).copyProductsToInternalCategory(this.axios, body, category_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.PIM.CAT.08 Export categories
     * @param {string} [accept] text/csv
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategorizationAPIApi
     */
     public exportDpCategories(accept?: string|null, x_dp_customer_code?: string|null, options?: any) {
        return CategorizationAPIApiFp(this.configuration).exportDpCategories(this.axios, accept, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.PIM.CAT.09 Export products with category
     * @param {string} [accept] text/csv
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategorizationAPIApi
     */
     public exportProductsInDpCategories(accept?: string|null, x_dp_customer_code?: string|null, options?: any) {
        return CategorizationAPIApiFp(this.configuration).exportProductsInDpCategories(this.axios, accept, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is the legacy export that returns max 1 row per product and max 4 categories per product
     * @summary REST.API.PIM.CAT.10 Export products with categories
     * @param {string} [accept] text/csv
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategorizationAPIApi
     */
     public exportProductsWithDpCategories(accept?: string|null, x_dp_customer_code?: string|null, options?: any) {
        return CategorizationAPIApiFp(this.configuration).exportProductsWithDpCategories(this.axios, accept, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * All products are moved to the specified category (if they don't already belong to that category), if they belong to other DP managed OR internal categories, those associations will be removed.
     * @summary REST.API.PIM.CAT.02 Move products to DP managed category
     * @param {MoveToRestDto} body 
     * @param {string} category_code The category code that uniquely identifies the category. (Note that multiple categories can be identified by using &#x27;|&#x27; as separator)
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategorizationAPIApi
     */
     public moveProductsToDpCategory(body: MoveToRestDto, category_code: string, x_dp_customer_code?: string|null, options?: any) {
        return CategorizationAPIApiFp(this.configuration).moveProductsToDpCategory(this.axios, body, category_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * All products are moved to the specified category (if they don't already belong to that category), if they belong to other DP managed OR internal categories, those associations will be removed.
     * @summary REST.API.PIM.CAT.05 Move products to internal category
     * @param {MoveToRestDto} body 
     * @param {string} category_code The product CNK code that uniquely identifies the product.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategorizationAPIApi
     */
     public moveProductsToInternalCategory(body: MoveToRestDto, category_code: string, x_dp_customer_code?: string|null, options?: any) {
        return CategorizationAPIApiFp(this.configuration).moveProductsToInternalCategory(this.axios, body, category_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * All products are removed from the specified category.
     * @summary REST.API.PIM.CAT.03 Remove products from DP managed category
     * @param {CnkListRestDto} body 
     * @param {string} category_code The product CNK code that uniquely identifies the product.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategorizationAPIApi
     */
     public removeProductsFromDpCategory(body: CnkListRestDto, category_code: string, x_dp_customer_code?: string|null, options?: any) {
        return CategorizationAPIApiFp(this.configuration).removeProductsFromDpCategory(this.axios, body, category_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * All products are removed from the specified category.
     * @summary REST.API.PIM.CAT.06 Remove products from internal category
     * @param {CnkListRestDto} body 
     * @param {string} category_code The product CNK code that uniquely identifies the product.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategorizationAPIApi
     */
     public removeProductsFromInternalCategory(body: CnkListRestDto, category_code: string, x_dp_customer_code?: string|null, options?: any) {
        return CategorizationAPIApiFp(this.configuration).removeProductsFromInternalCategory(this.axios, body, category_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
