import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentPageElementVisibilityRule = _resolveComponent("ContentPageElementVisibilityRule")!

  return (_openBlock(), _createBlock(_component_ContentPageElementVisibilityRule, {
    "row-id": this.rowId,
    "page-id": this.pageId,
    onSaved: _cache[0] || (_cache[0] = ($event: any) => (this.$emit('saved')))
  }, null, 8, ["row-id", "page-id"]))
}