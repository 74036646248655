
import {defineComponent} from "vue";
import {formatDateToLocalFormat} from "@/helpers/functions/date-time";
import {MoveActionEnum} from "@/models/enum/MoveActionEnum";
import {DiscountSchemeTypeEnum} from "@/api/enums/discount-scheme-type-enum";
import {PropType} from "vue/dist/vue";
import {DiscountSchemeOverviewRestDto} from "@/api/pharma-cpc-mgmt/models";

export default defineComponent({
  name: "DiscountsPriceListsOverviewItem",
  emits: ["deleteScheme", "moveScheme"],
  props: {
    data: {
      type: Object as PropType<DiscountSchemeOverviewRestDto>,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validate(value: string) {
        return (value === 'DISCOUNT') || (value === 'PRICELIST');
      }
    },
  },
  computed: {
    DiscountSchemeTypeEnum() {
      return DiscountSchemeTypeEnum;
    },
    isDiscount(): Boolean {
      return this.type === 'DISCOUNT';
    },
    isPriceList(): Boolean {
      return this.type === 'PRICELIST';
    },
    clientGroup(): string {
      return (this.data.client_group) ? this.data.client_group.description : '';
    },
    discountValue(): string {
      if (this.data.discount_value) {
        switch (this.data.type.code) {
          case 'PERCENTAGE':
            return this.data.discount_value + '%';
          case 'BUY_X_GET_Y_PERCENTAGE':
            return this.data.discount_value + '%';
          case 'AMOUNT':
            return '€ ' + this.data.discount_value;
          case 'BUY_X_GET_Y_AMOUNT':
            return '€ ' + this.data.discount_value;
          default :
            return this.data.discount_value + '';
        }
      }
      return '/';
    },
    beginDate(): string {
      return (this.data.begin_date) ? formatDateToLocalFormat(this.data.begin_date) : '';
    },
    endDate(): string {
      return (this.data.end_date) ? formatDateToLocalFormat(this.data.end_date) : '';
    },
    MoveActionEnum() {
      return MoveActionEnum
    },
  },
  methods: {
    deleteScheme(id: string) {
      this.$emit('deleteScheme', id);
    },
    moveScheme(id: string, moveAction: MoveActionEnum) {
      this.$emit('moveScheme', id, moveAction);
    },
  }
});
