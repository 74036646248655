/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Customer-Product-Catalog
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { FullMultilingualProductLabelRestDto } from '../models';
import { IdResponseRestDto } from '../models';
import { MultilingualProductLabelRestDto } from '../models';
/**
 * ProductLabelsAPIApi - axios parameter creator
 * @export
 */
export const ProductLabelsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for creating a product label and associating it with a discount scheme.
         * @summary REST.API.CPC.PRL.04 Create a product label for discount scheme.
         * @param {MultilingualProductLabelRestDto} body 
         * @param {string} id The identifier of the discount scheme to which the new product label will be associated.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductLabelForDiscountScheme: async (body: MultilingualProductLabelRestDto, id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createProductLabelForDiscountScheme.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createProductLabelForDiscountScheme.');
            }
            const localVarPath = `/customer_mgmt/v1/catalog/discount_scheme/{id}/product_label/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for creating a product label and associating it with a named product filter.
         * @summary REST.API.CPC.PRL.05 Create a product label for a named product filter.
         * @param {MultilingualProductLabelRestDto} body 
         * @param {string} filter_code The identifier of the product filter to which the new product label will be associated.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductLabelForNamedProductFilter: async (body: MultilingualProductLabelRestDto, filter_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createProductLabelForNamedProductFilter.');
            }
            // verify required parameter 'filter_code' is not null or undefined
            if (filter_code === null || filter_code === undefined) {
                throw new RequiredError('filter_code','Required parameter filter_code was null or undefined when calling createProductLabelForNamedProductFilter.');
            }
            const localVarPath = `/customer_mgmt/v1/product_filter/{filter_code}/product_label/`
                .replace(`{${"filter_code"}}`, encodeURIComponent(String(filter_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for deleting a product label.
         * @summary REST.API.CPC.PRL.03 Delete product label.
         * @param {string} id The identifier of the product label.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductLabel: async (id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteProductLabel.');
            }
            const localVarPath = `/customer_mgmt/v1/catalog/product_label/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving a product label associated with a discount scheme.
         * @summary REST.API.CPC.PRL.01 Find the product label for the discount scheme with the given id.
         * @param {string} id The identifier of the discount scheme.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductLabelForDiscountScheme: async (id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProductLabelForDiscountScheme.');
            }
            const localVarPath = `/customer_mgmt/v1/catalog/discount_scheme/{id}/product_label/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving a product label associated with a product filter.
         * @summary REST.API.CPC.PRL.06 Find the product label for the product filter with the given id.
         * @param {string} filter_code The identifier of the product_filter.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductLabelForProductFilter: async (filter_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter_code' is not null or undefined
            if (filter_code === null || filter_code === undefined) {
                throw new RequiredError('filter_code','Required parameter filter_code was null or undefined when calling getProductLabelForProductFilter.');
            }
            const localVarPath = `/customer_mgmt/v1/product_filter/{filter_code}/product_label/`
                .replace(`{${"filter_code"}}`, encodeURIComponent(String(filter_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for updating a product label.
         * @summary REST.API.CPC.PRL.02 Update product label.
         * @param {MultilingualProductLabelRestDto} body 
         * @param {string} id The identifier of the product label.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductLabel: async (body: MultilingualProductLabelRestDto, id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateProductLabel.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateProductLabel.');
            }
            const localVarPath = `/customer_mgmt/v1/catalog/product_label/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductLabelsAPIApi - functional programming interface
 * @export
 */
export const ProductLabelsAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for creating a product label and associating it with a discount scheme.
         * @summary REST.API.CPC.PRL.04 Create a product label for discount scheme.
         * @param {MultilingualProductLabelRestDto} body 
         * @param {string} id The identifier of the discount scheme to which the new product label will be associated.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProductLabelForDiscountScheme(_axios: AxiosInstance, body: MultilingualProductLabelRestDto, id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResponseRestDto>> {
            const localVarAxiosArgs = await ProductLabelsAPIApiAxiosParamCreator(configuration).createProductLabelForDiscountScheme(body, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for creating a product label and associating it with a named product filter.
         * @summary REST.API.CPC.PRL.05 Create a product label for a named product filter.
         * @param {MultilingualProductLabelRestDto} body 
         * @param {string} filter_code The identifier of the product filter to which the new product label will be associated.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProductLabelForNamedProductFilter(_axios: AxiosInstance, body: MultilingualProductLabelRestDto, filter_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResponseRestDto>> {
            const localVarAxiosArgs = await ProductLabelsAPIApiAxiosParamCreator(configuration).createProductLabelForNamedProductFilter(body, filter_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for deleting a product label.
         * @summary REST.API.CPC.PRL.03 Delete product label.
         * @param {string} id The identifier of the product label.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductLabel(_axios: AxiosInstance, id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductLabelsAPIApiAxiosParamCreator(configuration).deleteProductLabel(id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving a product label associated with a discount scheme.
         * @summary REST.API.CPC.PRL.01 Find the product label for the discount scheme with the given id.
         * @param {string} id The identifier of the discount scheme.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductLabelForDiscountScheme(_axios: AxiosInstance, id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullMultilingualProductLabelRestDto>> {
            const localVarAxiosArgs = await ProductLabelsAPIApiAxiosParamCreator(configuration).getProductLabelForDiscountScheme(id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving a product label associated with a product filter.
         * @summary REST.API.CPC.PRL.06 Find the product label for the product filter with the given id.
         * @param {string} filter_code The identifier of the product_filter.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductLabelForProductFilter(_axios: AxiosInstance, filter_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullMultilingualProductLabelRestDto>> {
            const localVarAxiosArgs = await ProductLabelsAPIApiAxiosParamCreator(configuration).getProductLabelForProductFilter(filter_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for updating a product label.
         * @summary REST.API.CPC.PRL.02 Update product label.
         * @param {MultilingualProductLabelRestDto} body 
         * @param {string} id The identifier of the product label.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProductLabel(_axios: AxiosInstance, body: MultilingualProductLabelRestDto, id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductLabelsAPIApiAxiosParamCreator(configuration).updateProductLabel(body, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProductLabelsAPIApi - object-oriented interface
 * @export
 * @class ProductLabelsAPIApi
 * @extends {BaseAPI}
 */
export class ProductLabelsAPIApi extends BaseAPI {
    /**
     * API endpoint responsible for creating a product label and associating it with a discount scheme.
     * @summary REST.API.CPC.PRL.04 Create a product label for discount scheme.
     * @param {MultilingualProductLabelRestDto} body 
     * @param {string} id The identifier of the discount scheme to which the new product label will be associated.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductLabelsAPIApi
     */
     public createProductLabelForDiscountScheme(body: MultilingualProductLabelRestDto, id: string, x_dp_customer_code?: string|null, options?: any) {
        return ProductLabelsAPIApiFp(this.configuration).createProductLabelForDiscountScheme(this.axios, body, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for creating a product label and associating it with a named product filter.
     * @summary REST.API.CPC.PRL.05 Create a product label for a named product filter.
     * @param {MultilingualProductLabelRestDto} body 
     * @param {string} filter_code The identifier of the product filter to which the new product label will be associated.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductLabelsAPIApi
     */
     public createProductLabelForNamedProductFilter(body: MultilingualProductLabelRestDto, filter_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ProductLabelsAPIApiFp(this.configuration).createProductLabelForNamedProductFilter(this.axios, body, filter_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for deleting a product label.
     * @summary REST.API.CPC.PRL.03 Delete product label.
     * @param {string} id The identifier of the product label.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductLabelsAPIApi
     */
     public deleteProductLabel(id: string, x_dp_customer_code?: string|null, options?: any) {
        return ProductLabelsAPIApiFp(this.configuration).deleteProductLabel(this.axios, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving a product label associated with a discount scheme.
     * @summary REST.API.CPC.PRL.01 Find the product label for the discount scheme with the given id.
     * @param {string} id The identifier of the discount scheme.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductLabelsAPIApi
     */
     public getProductLabelForDiscountScheme(id: string, x_dp_customer_code?: string|null, options?: any) {
        return ProductLabelsAPIApiFp(this.configuration).getProductLabelForDiscountScheme(this.axios, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving a product label associated with a product filter.
     * @summary REST.API.CPC.PRL.06 Find the product label for the product filter with the given id.
     * @param {string} filter_code The identifier of the product_filter.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductLabelsAPIApi
     */
     public getProductLabelForProductFilter(filter_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ProductLabelsAPIApiFp(this.configuration).getProductLabelForProductFilter(this.axios, filter_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for updating a product label.
     * @summary REST.API.CPC.PRL.02 Update product label.
     * @param {MultilingualProductLabelRestDto} body 
     * @param {string} id The identifier of the product label.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductLabelsAPIApi
     */
     public updateProductLabel(body: MultilingualProductLabelRestDto, id: string, x_dp_customer_code?: string|null, options?: any) {
        return ProductLabelsAPIApiFp(this.configuration).updateProductLabel(this.axios, body, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
