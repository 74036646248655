
import {Exception} from "@/api/interfaces";
import {defineComponent} from 'vue';
import {useToast} from "vue-toastification";
import {TeamRestDtoModel} from "@/models/TeamRestDtoModel";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import MultilingualSwitcher from "@/components/UI/MultilingualSwitcher.vue";
import {I18nContext} from "@/context/I18nContext";
import I18nInputText from "@/components/UI/I18n/I18nInputText.vue";
import I18nInputImage from "@/components/UI/I18n/I18nInputImage.vue";
import I18nInputTextEditor from "@/components/UI/I18n/I18nInputTextEditor.vue";
import {TeamRestService} from "@/services/rest/cms-pharmacy/TeamRestService";

export default defineComponent({
  name: "AboutUs",
  components: {I18nInputTextEditor, I18nInputImage, I18nInputText, MultilingualSwitcher, BaseTitle},
  data: () => ({
    isReady: false as boolean,
    exceptions: [] as Exception[],
    toaster: useToast(),

    teamAbout: TeamRestDtoModel.createWithDefaults(),
    activeLanguage: I18nContext.getDefaultApiLanguageKey(),
  }),
  mounted() {
    this.reloadContent();
  },
  methods: {
    async reloadContent() {
      this.isReady = false;
      this.exceptions = [];

      try {
        await this.searchTeam();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      } finally {
        this.isReady = true;
      }
    },
    async searchTeam() {
      this.exceptions = [];

      try {
        this.teamAbout = await TeamRestService.getInstance().findTeam();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async saveTeamAbout() {
      this.exceptions = [];

      try {
        await TeamRestService.getInstance().updateTeam(this.teamAbout);

        this.toaster.success(this.$t('savedSuccessfully'));
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
  }
})
