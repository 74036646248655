import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row discount-filter-brands" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectBrands = _resolveComponent("SelectBrands")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SelectBrands, {
      "selected-brand-codes": this.productBrandFilterCodes,
      class: "col-8",
      onAddBrand: this.addBrand,
      onRemoveBrand: this.removeBrand
    }, null, 8, ["selected-brand-codes", "onAddBrand", "onRemoveBrand"])
  ]))
}