import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cb_form_text_with_image = _resolveComponent("cb-form-text-with-image")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_cb_form_text_with_image, {
        "can-copy-from-library": "",
        "cb-type": "CATEGORIES_CONTENT_BLOCK",
        "doc-type": "CB_TEXT_WITH_IMAGE_IMG",
        mode: "edit",
        "is-clearable": "",
        "is-publishable": "",
        title: _ctx.$t('categoriesContentBlock'),
        description: _ctx.$t('descriptionCategoriesContentBlock'),
        description2: _ctx.$t('publishedText')
      }, null, 8, ["title", "description", "description2"])
    ]),
    _: 1
  }))
}