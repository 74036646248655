
import {defineComponent} from "vue";
import {mapGetters} from "vuex";
import {ShoppingCartDiscountSchemeOverviewRestDto} from "@/api/pharma-cpc-mgmt/models";
import ShoppingCartDiscountsOverviewItem from "@/components/layouts/catalog/discounts-pricelists/ShoppingCartDiscountsOverviewItem.vue";
import {NVPRestService} from "@/services/rest/master/NVPRestService";
import {DpModuleEnum} from "@/api/enums/dp-module-enum";
import {CustomerContext} from "@/context/CustomerContext";
import {DpNameValuePairValueRestDtoModel} from "@/models/api/pharma-master/DpNameValuePairValueRestDtoModel";
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";

export default defineComponent({
  name: "ShoppingCartDiscountsOverview",
  components: {ShoppingCartDiscountsOverviewItem},
  emits: ["deleteShoppingCartDiscountScheme"],
  props: {
    isReady: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      NVP_NAME: 'IS_HIDE_PROMO_CODE_INPUT_FIELD',
      nvp: new DpNameValuePairValueRestDtoModel(CustomerContext.getCustomerCode(), 'false'),
      hidePromoCodeInputField: false,
      uiState: UIStateDto.createWithDefaults(),
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cpc_mgmt', ['getShoppingCartDiscountSchemes']),
    shoppingCartDiscountSchemes(): ShoppingCartDiscountSchemeOverviewRestDto[] {
      return this.getShoppingCartDiscountSchemes;
    },
    hasShoppingCartDiscountSchemes(): Boolean {
      return (Array.isArray(this.shoppingCartDiscountSchemes) && this.shoppingCartDiscountSchemes.length > 0);
    },
  },
  methods: {
    deleteShoppingCartDiscountScheme(id: string) {
      this.$emit('deleteShoppingCartDiscountScheme', id);
    },
    async updateHidePromoField() {
      this.nvp.value = '' + this.hidePromoCodeInputField;
      await NVPRestService.getInstance().updateDpNvpByName(this.nvp, CustomerContext.getCustomerCode(), this.NVP_NAME, DpModuleEnum.CPC);
      await this.reloadContent();
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.uiState as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.uiState as UIStateDto))
        .catch(exceptions, true);
    },
    async reloadContent() {
      this.uiState
        .setNotReady()
        .clearError();

      try {
        const existingNvp = await NVPRestService.getInstance()
          .getDpNvpByName(CustomerContext.getCustomerCode(), this.NVP_NAME, DpModuleEnum.CPC);
        if (existingNvp) {
          this.nvp = new DpNameValuePairValueRestDtoModel(CustomerContext.getCustomerCode(), existingNvp.value);
          this.hidePromoCodeInputField = 'true' === existingNvp.value;
        } else {
          this.nvp = new DpNameValuePairValueRestDtoModel(CustomerContext.getCustomerCode(), 'false');
          this.hidePromoCodeInputField = false;
        }
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.uiState.setReady();
      }
    },
  }
});
