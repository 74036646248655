
import {defineComponent, PropType} from "vue";
import {Exception} from "@/api/interfaces";
import {CmsBannerImageRestDto} from "@/api/pharma-cms-content-block/models";
import {CmsDocumentRestService} from "@/services/rest/cms-document/CmsDocumentRestService";
import {CmsBannerImageRestDtoModel} from "@/models/api/pharma-cms-content-block/CmsBannerImageRestDtoModel";

export default defineComponent({
  name: "BannerLibraryDetailBannerItem",
  emits: ["bannerImageUploaded", "exceptions"],
  props: {
    bannerImage: {
      type: Object as () => CmsBannerImageRestDto | null,
      default: null,
      required: false,
    },
    languageCode: {
      type: [String, null] as PropType<string | null>,
      required: true,
    },
    contentSizeCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      exceptions: [] as Exception[],
      isHovering: false as boolean,
      isReady: false as boolean,
      docType: 'BANNER_IMG' as string,
      previewUrl: null as string|null,
    };
  },
  mounted() {
    this.reloadContent();
  },
  watch: {
    existingDocumentKey: {
      handler() {
        this.reloadContent();
      },
      immediate: true
    },
  },
  computed: {
    existingDocumentKey(): string|null {
      if (!this.hasBannerImage) {
        return null;
      }

      return this.bannerImage?.image_key ?? null;
    },
    hasBannerImage(): boolean {
      return this.bannerImage !== null && this.bannerImage.image_key !== '';
    },
    imageSize(): object {
      const imageDimensions = {
        SIZE_1_1: {
          width: 1296,
          height: 185,
        },
        SIZE_1_2: {
          width: 628,
          height: 185,
        }
      };
      const ratioSIZE_1_1 = imageDimensions['SIZE_1_1'].width / imageDimensions['SIZE_1_1'].height as number;
      const ratioSIZE_1_2 = imageDimensions['SIZE_1_2'].width / imageDimensions['SIZE_1_2'].height as number;

      let styleObject = {
        width: '',
        height: '75px'
      };
      const heightInteger = parseInt(styleObject.height.replace('px', '')) as number;

      switch (this.bannerImage?.content_size_code) {
        case 'SIZE_1_1':
          styleObject.width = (heightInteger * ratioSIZE_1_1).toString() + 'px';
          break;
        case 'SIZE_1_2':
          styleObject.width = (heightInteger * ratioSIZE_1_2).toString() + 'px';
      }

      return styleObject;
    },
    modalId(): string {
      return 'editBannerModal' + this.bannerImage?.language_code + '_' + this.bannerImage?.content_size_code;
    }
  },
  methods: {
    bannerImageUploaded(docKey: string) {
      let banner = this.bannerImage;
      if (banner === null) {
        banner = new CmsBannerImageRestDtoModel(this.contentSizeCode, docKey, this.languageCode);
      }

      this.$emit('bannerImageUploaded', banner);
    },
    setIsHovering(hoverState: boolean) {
      this.isHovering = hoverState;
    },
    throwExceptions(exceptions: Exception[]) {
      this.$emit('exceptions', exceptions);
    },
    async reloadContent() {
      this.isReady = false;

      try {
        if (this.hasBannerImage) {
          this.previewUrl = await CmsDocumentRestService.getInstance()
            .findPreviewUrl(this.existingDocumentKey)
        }
      } catch (exceptions: any) {
        this.$emit('exceptions', exceptions);
      }

      this.isReady = true;
    },
  }
});
