import {FindNormalContentBlockPayload} from "@/models/payload/FindNormalContentBlockPayload";
import {ContentBlockEndpointsApiService} from "@/main";
import {SaveNormalContentBlockPayload} from "@/models/payload/SaveNormalContentBlockPayload";
import {CreateNormalContentBlockPayload} from "@/models/payload/CreateNormalContentBlockPayload";
import {TeamInfoContentRestDtoModel} from "@/models/TeamInfoContentRestDtoModel";

export default {
    namespaced: true,
    actions: {
        async create(context: any, payload: CreateNormalContentBlockPayload): Promise<string> {
            console.log('TeamInfo::create()');
            const response = await ContentBlockEndpointsApiService.createNormalContentBlockTeamInfo(payload.cb as TeamInfoContentRestDtoModel, payload.type);

            return response.data.id as string;
        },
        async find(context: any, payload: FindNormalContentBlockPayload): Promise<TeamInfoContentRestDtoModel> {
            console.log('TeamInfo::find()');
            const response = await ContentBlockEndpointsApiService.getNormalContentBlockTeamInfo(payload.type, payload.id);

            return response.data as TeamInfoContentRestDtoModel;
        },
        async save(context: any, payload: SaveNormalContentBlockPayload): Promise<void> {
            console.log('TeamInfo::save()');
            await ContentBlockEndpointsApiService.updateNormalContentBlockTeamInfo(payload.cb as TeamInfoContentRestDtoModel, payload.type, payload.id);
        },
    }
};
