
import {defineComponent} from "vue";
import {formatDateToLocalFormat} from "@/helpers/functions/date-time";
import {ShoppingCartDiscountTypeEnum} from "@/api/enums/shopping-cart-discount-type-enum";
import {PropType} from "vue/dist/vue";
import {ShoppingCartDiscountSchemeOverviewRestDto} from "@/api/pharma-cpc-mgmt/models";

export default defineComponent({
  name: "ShoppingCartDiscountsOverviewItem",
  emits: ["deleteShoppingCartDiscountScheme"],
  props: {
    data: {
      type: Object as PropType<ShoppingCartDiscountSchemeOverviewRestDto>,
      required: true,
    },
  },
  computed: {
    DiscountSchemeTypeEnum() {
      return ShoppingCartDiscountTypeEnum;
    },
    clientGroup(): string {
      return (this.data.client_group) ? this.data.client_group.description : '';
    },
    discountValue(): string {
      if (this.data.discount_value) {
        switch (this.data.type.code) {
          case 'PERCENTAGE':
            return this.data.discount_value + '%';
          case 'BUY_X_GET_Y_PERCENTAGE':
            return this.data.discount_value + '%';
          case 'AMOUNT':
            return '€ ' + this.data.discount_value;
          case 'BUY_X_GET_Y_AMOUNT':
            return '€ ' + this.data.discount_value;
          default :
            return this.data.discount_value + '';
        }
      }
      return '/';
    },
    beginDate(): string {
      return (this.data.begin_date) ? formatDateToLocalFormat(this.data.begin_date) : '';
    },
    endDate(): string {
      return (this.data.end_date) ? formatDateToLocalFormat(this.data.end_date) : '';
    },
  },
  methods: {
    deleteShoppingCartDiscountScheme(id: string) {
      this.$emit('deleteShoppingCartDiscountScheme', id);
    },
  }
});
