import ContentPageModule from "@/store/pharma-cms-content-page/content_pages";
import ContentPageAbstractModule from "@/store/pharma-cms-content-page/content_page_abstracts";
import ContentPageLibrariesModule from "@/store/pharma-cms-content-page/content_page_libraries";
import ContentPageRowModule from "@/store/pharma-cms-content-page/content_page_rows";
import ContentBlockInRowModule from "@/store/pharma-cms-content-page/content_block_in_row";
import {ContentPageEnumEndpointsApiService} from "@/main";
import {
    CodeDescriptionRestDto
} from "@/api/pharma-cms-content-page/models";


export default {
    namespaced: true,
    modules: {
        content_pages: ContentPageModule,
        content_page_abstracts: ContentPageAbstractModule,
        content_page_libraries: ContentPageLibrariesModule,
        content_page_rows: ContentPageRowModule,
        content_block_in_row: ContentBlockInRowModule,
    },
    state() {
        return {
            page_abstract_libraries: [] as CodeDescriptionRestDto[],
            page_type_enums: [] as CodeDescriptionRestDto[],
        }
    },
    actions: {
        async searchPageAbstractLibraries(context: any, filter_is_owner?: boolean) {
            const response = await ContentPageEnumEndpointsApiService.findPageAbstractLibraries(undefined, filter_is_owner);

            context.commit("setPageAbstractLibraries", response.data);
        },
        async searchPageTypeEnums(context: any, filter_is_library_type: boolean) {
            const response = await ContentPageEnumEndpointsApiService.findPageTypeEnums(undefined, filter_is_library_type);

            context.commit("setPageTypeEnums", response.data);
        }
    },
    mutations: {
        setPageAbstractLibraries(state: any, payload: CodeDescriptionRestDto[]) {
            state.page_abstract_libraries = payload;
        },
        setPageTypeEnums(state: any, payload: CodeDescriptionRestDto[]) {
            state.page_type_enums = payload;
        }
    },
    getters: {
        getPageAbstractLibraries(state: any): CodeDescriptionRestDto[] {
            return state.page_abstract_libraries;
        },
        getPageTypeEnums(state: any): CodeDescriptionRestDto[] {
            return state.page_type_enums;
        }
    },
}
