/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Customer-Product-Catalog
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { MoveToPositionRestDto } from '../models';
/**
 * CustomerProductSortWeightAPIApi - axios parameter creator
 * @export
 */
export const CustomerProductSortWeightAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for creating a customer specific sort weight for a product.
         * @summary REST.API.CPC.CSW.02 Create a customer sort weight for a product.
         * @param {string} product_code Identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerProductSortWeight: async (product_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'product_code' is not null or undefined
            if (product_code === null || product_code === undefined) {
                throw new RequiredError('product_code','Required parameter product_code was null or undefined when calling createCustomerProductSortWeight.');
            }
            const localVarPath = `/product_mgmt/v1/catalog/product/{product_code}/customer_product_sort_weight`
                .replace(`{${"product_code"}}`, encodeURIComponent(String(product_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for deleting a customer specific sort weight for a product.
         * @summary REST.API.CPC.CSW.03 Delete a customer sort weight for a product and a category.
         * @param {string} product_code Identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerProductSortWeight: async (product_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'product_code' is not null or undefined
            if (product_code === null || product_code === undefined) {
                throw new RequiredError('product_code','Required parameter product_code was null or undefined when calling deleteCustomerProductSortWeight.');
            }
            const localVarPath = `/product_mgmt/v1/catalog/product/{product_code}/customer_product_sort_weight`
                .replace(`{${"product_code"}}`, encodeURIComponent(String(product_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for moving the sort order of this sort weight to a specific position.
         * @summary REST.API.CPC.CSW.12 Move customer_product_sort_weight to a specific position.
         * @param {string} product_code The unique identification of the customer product sort weight.
         * @param {MoveToPositionRestDto} [body]
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveCustomerProductSortWeightTo: async (product_code: string, body?: MoveToPositionRestDto|null, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'product_code' is not null or undefined
            if (product_code === null || product_code === undefined) {
                throw new RequiredError('product_code','Required parameter product_code was null or undefined when calling moveCustomerProductSortWeightTo.');
            }
            const localVarPath = `/product_mgmt/v1/catalog/product/{product_code}/customer_product_sort_weight/move/to`
                .replace(`{${"product_code"}}`, encodeURIComponent(String(product_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for moving the sort order of this sort weight to the bottom position.
         * @summary REST.API.CPC.CSW.11 Move customer_product_sort_weight to the bottom position.
         * @param {string} product_code The unique identification of the customer product sort weight.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveCustomerProductSortWeightToBottom: async (product_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'product_code' is not null or undefined
            if (product_code === null || product_code === undefined) {
                throw new RequiredError('product_code','Required parameter product_code was null or undefined when calling moveCustomerProductSortWeightToBottom.');
            }
            const localVarPath = `/product_mgmt/v1/catalog/product/{product_code}/customer_product_sort_weight/move/to_bottom`
                .replace(`{${"product_code"}}`, encodeURIComponent(String(product_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for moving the sort order of this sort weight to the top position.
         * @summary REST.API.CPC.CSW.10 Move customer_product_sort_weight to the top position.
         * @param {string} product_code The unique identification of the customer product sort weight.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveCustomerProductSortWeightToTop: async (product_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'product_code' is not null or undefined
            if (product_code === null || product_code === undefined) {
                throw new RequiredError('product_code','Required parameter product_code was null or undefined when calling moveCustomerProductSortWeightToTop.');
            }
            const localVarPath = `/product_mgmt/v1/catalog/product/{product_code}/customer_product_sort_weight/move/to_top`
                .replace(`{${"product_code"}}`, encodeURIComponent(String(product_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerProductSortWeightAPIApi - functional programming interface
 * @export
 */
export const CustomerProductSortWeightAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for creating a customer specific sort weight for a product.
         * @summary REST.API.CPC.CSW.02 Create a customer sort weight for a product.
         * @param {string} product_code Identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomerProductSortWeight(_axios: AxiosInstance, product_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustomerProductSortWeightAPIApiAxiosParamCreator(configuration).createCustomerProductSortWeight(product_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for deleting a customer specific sort weight for a product.
         * @summary REST.API.CPC.CSW.03 Delete a customer sort weight for a product and a category.
         * @param {string} product_code Identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomerProductSortWeight(_axios: AxiosInstance, product_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustomerProductSortWeightAPIApiAxiosParamCreator(configuration).deleteCustomerProductSortWeight(product_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for moving the sort order of this sort weight to a specific position.
         * @summary REST.API.CPC.CSW.12 Move customer_product_sort_weight to a specific position.
         * @param {string} product_code The unique identification of the customer product sort weight.
         * @param {MoveToPositionRestDto} [body]
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveCustomerProductSortWeightTo(_axios: AxiosInstance, product_code: string, body?: MoveToPositionRestDto|null, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustomerProductSortWeightAPIApiAxiosParamCreator(configuration).moveCustomerProductSortWeightTo(product_code, body, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for moving the sort order of this sort weight to the bottom position.
         * @summary REST.API.CPC.CSW.11 Move customer_product_sort_weight to the bottom position.
         * @param {string} product_code The unique identification of the customer product sort weight.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveCustomerProductSortWeightToBottom(_axios: AxiosInstance, product_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustomerProductSortWeightAPIApiAxiosParamCreator(configuration).moveCustomerProductSortWeightToBottom(product_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for moving the sort order of this sort weight to the top position.
         * @summary REST.API.CPC.CSW.10 Move customer_product_sort_weight to the top position.
         * @param {string} product_code The unique identification of the customer product sort weight.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveCustomerProductSortWeightToTop(_axios: AxiosInstance, product_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustomerProductSortWeightAPIApiAxiosParamCreator(configuration).moveCustomerProductSortWeightToTop(product_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CustomerProductSortWeightAPIApi - object-oriented interface
 * @export
 * @class CustomerProductSortWeightAPIApi
 * @extends {BaseAPI}
 */
export class CustomerProductSortWeightAPIApi extends BaseAPI {
    /**
     * API endpoint responsible for creating a customer specific sort weight for a product.
     * @summary REST.API.CPC.CSW.02 Create a customer sort weight for a product.
     * @param {string} product_code Identifies the product.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProductSortWeightAPIApi
     */
     public createCustomerProductSortWeight(product_code: string, x_dp_customer_code?: string|null, options?: any) {
        return CustomerProductSortWeightAPIApiFp(this.configuration).createCustomerProductSortWeight(this.axios, product_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for deleting a customer specific sort weight for a product.
     * @summary REST.API.CPC.CSW.03 Delete a customer sort weight for a product and a category.
     * @param {string} product_code Identifies the product.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProductSortWeightAPIApi
     */
     public deleteCustomerProductSortWeight(product_code: string, x_dp_customer_code?: string|null, options?: any) {
        return CustomerProductSortWeightAPIApiFp(this.configuration).deleteCustomerProductSortWeight(this.axios, product_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for moving the sort order of this sort weight to a specific position.
     * @summary REST.API.CPC.CSW.12 Move customer_product_sort_weight to a specific position.
     * @param {string} product_code The unique identification of the customer product sort weight.
     * @param {MoveToPositionRestDto} [body]
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProductSortWeightAPIApi
     */
     public moveCustomerProductSortWeightTo(product_code: string, body?: MoveToPositionRestDto|null, x_dp_customer_code?: string|null, options?: any) {
        return CustomerProductSortWeightAPIApiFp(this.configuration).moveCustomerProductSortWeightTo(this.axios, product_code, body, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for moving the sort order of this sort weight to the bottom position.
     * @summary REST.API.CPC.CSW.11 Move customer_product_sort_weight to the bottom position.
     * @param {string} product_code The unique identification of the customer product sort weight.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProductSortWeightAPIApi
     */
     public moveCustomerProductSortWeightToBottom(product_code: string, x_dp_customer_code?: string|null, options?: any) {
        return CustomerProductSortWeightAPIApiFp(this.configuration).moveCustomerProductSortWeightToBottom(this.axios, product_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for moving the sort order of this sort weight to the top position.
     * @summary REST.API.CPC.CSW.10 Move customer_product_sort_weight to the top position.
     * @param {string} product_code The unique identification of the customer product sort weight.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProductSortWeightAPIApi
     */
     public moveCustomerProductSortWeightToTop(product_code: string, x_dp_customer_code?: string|null, options?: any) {
        return CustomerProductSortWeightAPIApiFp(this.configuration).moveCustomerProductSortWeightToTop(this.axios, product_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
