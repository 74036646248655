import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(['btn ', {'btn-primary': _ctx.isCurrent, 'btn-outline-primary': !_ctx.isCurrent}]),
    type: "button",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setMaxContentSize(_ctx.displayedMaxContentSizeCode )))
  }, [
    (this.displayedMaxContentSizeCode === 'SIZE_1_1')
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(['border border-1 rounded px-5 me-1',{'border-white': _ctx.isCurrent, 'border-primary': !_ctx.isCurrent}])
        }, " 1/1 ", 2))
      : (this.displayedMaxContentSizeCode === 'SIZE_1_2')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("span", {
              class: _normalizeClass(['border border-1 rounded px-3 me-1',{'border-white': _ctx.isCurrent, 'border-primary': !_ctx.isCurrent}])
            }, " 1/2 ", 2),
            _createElementVNode("span", {
              class: _normalizeClass(['border border-1 rounded px-3',{'border-white': _ctx.isCurrent, 'border-primary': !_ctx.isCurrent}])
            }, " 1/2 ", 2)
          ], 64))
        : _createCommentVNode("", true)
  ], 2))
}