
import {defineComponent} from "vue";
import CbFormTextWithTitle from "@/components/layouts/content-blocks/forms/CbFormTextWithTitle.vue";

export default defineComponent({
  name: "OrderLabelContentBlocksDetail",
  components: {CbFormTextWithTitle},
  props: ['mode'],
  data() {
    return {
      cbId: this.$route.params.id as String,
    };
  },
  mounted() {
    const menuItem = document.getElementById('content-detail');
    if (menuItem != null) {
      menuItem.classList.add('active', 'router-link-exact-active');
    }
  }
});
