
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {
  CodeDescriptionRestDto,
  OrcaReservationCampaignRestDto, OrcaReservationCampaignRestDtoStatusCodeEnum,
  OrcaReservationCampaignRestDtoTypeCodeEnum
} from "@/api/pharma-order-orca-customer-mgmt/models";
import InputTextEditor from "@/components/UI/InputTextEditor.vue";


export default defineComponent({
  name: "ReservationCampaignSettings",
  components: {InputTextEditor},
  emits: ["submit"],
  props: {
    mode: {
      type: String,
      required: true,
      validate(value: string) {
        return value.toLowerCase() === 'new' || value.toLowerCase() === 'edit';
      }
    },
    isSubmitting: {
      type: Boolean,
      required: true,
    }
  },
  computed: {
    ...mapGetters('order_orca_customer_mgmt', ["getReservationCampaign"]),
    reservationCampaign(): OrcaReservationCampaignRestDto {
      return this.getReservationCampaign;
    },
    statusOptions(): CodeDescriptionRestDto[] {
      return [
        {
          code: OrcaReservationCampaignRestDtoStatusCodeEnum.NEW,
          description: this.$t('reservationCampaignStatusNew'),
        },
        {
          code: OrcaReservationCampaignRestDtoStatusCodeEnum.ACTIVE,
          description: this.$t('reservationCampaignStatusActive'),
        },
        {
          code: OrcaReservationCampaignRestDtoStatusCodeEnum.CLOSED,
          description: this.$t('reservationCampaignStatusClosed'),
        },
      ]
    },
    typeOptions(): CodeDescriptionRestDto[] {
      return [
        {
          code: OrcaReservationCampaignRestDtoTypeCodeEnum.FLUVACCINE,
          description: this.$t('fluVaccine'),
        },
        {
          code: OrcaReservationCampaignRestDtoTypeCodeEnum.WEIGHTLOSS,
          description: this.$t('reservationCampaignTypeWeightLoss'),
        }
      ]
    },
  },
  methods: {
    ...mapActions('order_orca_customer_mgmt', ["clearReservationCampaignPeriod"]),
    async submitData() {
      this.$emit('submit');
    }
  }
});
