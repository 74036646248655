import {RestService} from "@/services/rest/RestService";
import {ProductLabelsAPIApi} from "@/api/pharma-cpc-mgmt";
import {FullMultilingualProductLabelRestDto, MultilingualProductLabelRestDto} from "@/api/pharma-cpc-mgmt/models";

export class ProductLabelRestService extends RestService {

    private apiService: ProductLabelsAPIApi;

    protected constructor() {
        super();

        this.apiService = new ProductLabelsAPIApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cpcUrl);
    }

    public async createProductLabelForNamedProductFilter(pfId: string, body: MultilingualProductLabelRestDto): Promise<void> {
        await this.apiService.createProductLabelForNamedProductFilter(body, pfId);
    }

    public async createProductLabelForDiscountScheme(dsId: string, body: MultilingualProductLabelRestDto): Promise<void> {
        await this.apiService.createProductLabelForDiscountScheme(body, dsId);
    }

    public async updateProductLabel(id: string, body: MultilingualProductLabelRestDto): Promise<void> {
        await this.apiService.updateProductLabel(body, id);
    }

    public async getProductLabelForProductFilter(pfId: string): Promise<FullMultilingualProductLabelRestDto> {
        const response = await this.apiService.getProductLabelForProductFilter(pfId);

        return response.data;
    }

    public async getProductLabelForDiscountScheme(dsId: string): Promise<FullMultilingualProductLabelRestDto> {
        const response = await this.apiService.getProductLabelForDiscountScheme(dsId);

        return response.data;
    }

    public async deleteProductLabel(labelId: string): Promise<void> {
        await this.apiService.deleteProductLabel(labelId);
    }
}
