import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex align-items-center mb-2"
}
const _hoisted_2 = {
  key: 0,
  class: "product-filter-picker btn-group btn-group-sm w-auto me-3",
  role: "group",
  "aria-label": "Product filter type"
}
const _hoisted_3 = { class: "row" }
const _hoisted_4 = {
  key: 0,
  class: "col-12"
}
const _hoisted_5 = {
  key: 1,
  class: "col-3"
}
const _hoisted_6 = { class: "text-muted mt-3 mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonProductFilterPreview = _resolveComponent("ButtonProductFilterPreview")!
  const _component_ProductFilters = _resolveComponent("ProductFilters")!
  const _component_input_select = _resolveComponent("input-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (this.canUseNamedProductFilter || this.payload.code)
      ? (_openBlock(), _createElementBlock("header", _hoisted_1, [
          (this.canUseNamedProductFilter)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("button", {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (this.setProductFilterType(_ctx.productFilterTypeEnum.CUSTOM))),
                  class: _normalizeClass(['btn btn-outline-primary', {'active': this.isProductFilterType(_ctx.productFilterTypeEnum.CUSTOM)}]),
                  type: "button"
                }, _toDisplayString(_ctx.$t('customFilter')), 3),
                _createElementVNode("button", {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (this.setProductFilterType(_ctx.productFilterTypeEnum.NAMED))),
                  class: _normalizeClass(['btn btn-outline-primary', {'active': this.isProductFilterType(_ctx.productFilterTypeEnum.NAMED)}]),
                  type: "button"
                }, _toDisplayString(_ctx.$t('existingFilter')), 3)
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_ButtonProductFilterPreview, {
            "product-filter-code": this.payload.code
          }, null, 8, ["product-filter-code"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (this.isProductFilterType(_ctx.productFilterTypeEnum.CUSTOM))
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_ProductFilters, {
              "apply-on-type": _ctx.ProductFilterApplyTypeEnum.CB,
              "product-filter": this.productFilter,
              errors: _ctx.errors
            }, null, 8, ["apply-on-type", "product-filter", "errors"])
          ]))
        : _createCommentVNode("", true),
      (this.isProductFilterType(_ctx.productFilterTypeEnum.NAMED))
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_input_select, {
              modelValue: this.internalPayload.code,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.internalPayload.code) = $event)),
              name: "productFilterType",
              selectText: _ctx.$t('selectText'),
              options: this.internalPayload.named_product_filter_options,
              rules: "required",
              errors: _ctx.errors
            }, null, 8, ["modelValue", "selectText", "options", "errors"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('cachingDelayMinutes', [5])), 1)
  ], 64))
}