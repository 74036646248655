<template>
  <div class="input-color">
    <div class="d-flex flex-row">
      <dp-field
        :modelValue="modelValue"
        @update:modelValue="updateValue"
        type="color"
        :label="label" :name="name"
        :placeholder="placeholder"
        :disabled="disabled"
        :class="{ 'is-invalid': errors[name] }"
        :id="name"
        :rules="rules"
      />
      <label for="name" class="ps-2"> {{ label }} </label>
    </div>
    <div v-show="showValue">
      <p>{{ modelValue }}</p>
    </div>
    <dp-error-message class="invalid-feedback d-block" :name="name"></dp-error-message>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    errors: {
      type: Object,
    },
    rules: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    placeholder: {
      type: String,
    },
    showValue: {
      type: Boolean,
    }
  },
  methods: {
    updateValue(value) {
      this.$emit('update:modelValue', value);
    },
  },
};
</script>

<style scoped lang="css">
.form-control {
  border: none !important;
  padding-left: 0 !important;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0;
  font-size: 14px;
}

p {
  margin-bottom: 0;
}

input:disabled {
  cursor: not-allowed;
}

input {
  -webkit-appearance: none;
  border: none !important;
}

.input-color {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
</style>
