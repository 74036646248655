import {FindNormalContentBlockPayload} from "@/models/payload/FindNormalContentBlockPayload";
import {ContentBlockEndpointsApiService} from "@/main";
import {SaveNormalContentBlockPayload} from "@/models/payload/SaveNormalContentBlockPayload";
import {CreateNormalContentBlockPayload} from "@/models/payload/CreateNormalContentBlockPayload";
import {HtmlContentRestDtoModel} from "@/models/api/pharma-cms-content-block/HtmlContentRestDtoModel";

export default {
    namespaced: true,
    actions: {
        async create(context: any, payload: CreateNormalContentBlockPayload): Promise<string> {
            console.log('Html::create()');
            const response = await ContentBlockEndpointsApiService.createNormalContentBlockHtml(payload.cb as HtmlContentRestDtoModel, payload.type);

            return response.data.id as string;
        },
        async find(context: any, payload: FindNormalContentBlockPayload): Promise<HtmlContentRestDtoModel> {
            console.log('Html::find()');
            const response = await ContentBlockEndpointsApiService.getNormalContentBlockHtml(payload.type, payload.id);

            return response.data as HtmlContentRestDtoModel;
        },
        async save(context: any, payload: SaveNormalContentBlockPayload): Promise<void> {
            console.log('Html::save()');
            await ContentBlockEndpointsApiService.updateNormalContentBlockHtml(payload.cb as HtmlContentRestDtoModel, payload.type, payload.id);
        },
    }
};
