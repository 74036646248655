import {ProductPriceRestDto} from "@/api/pharma-cpc-mgmt/models";

export class ProductPriceRestDtoModel implements ProductPriceRestDto {
    discount_percentage?: number | null;
    discount_value_with_vat?: number | null;
    sale_price_with_vat: number;
    standard_price_with_vat: number;
    vat_percentage: number;

    constructor() {
        this.sale_price_with_vat = 0;
        this.standard_price_with_vat = 0;
        this.vat_percentage = 0;
    }

    public static createWithDefaults(): ProductPriceRestDtoModel {
        return new this();
    }
}
