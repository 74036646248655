import {
    CustomerProductPropertiesResultsRestDto,
    CustomerProductPropertyRestDto
} from "@/api/pharma-cpc-product-mgmt/models";

export class CustomerProductPropertiesResultsRestDtoModel implements CustomerProductPropertiesResultsRestDto {

    results: CustomerProductPropertyRestDto[];


    constructor() {
        this.results = [];
    }

    public static createWithDefaults(): CustomerProductPropertiesResultsRestDtoModel {
        return new this();
    }
}
