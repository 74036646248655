import {ClosingPeriodRestDto} from "@/api/pharma-cms-pharmacy/models";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";

export class ClosingPeriodRestDtoModel implements ClosingPeriodRestDto {
    begin: any;
    end: any;
    name: MultilingualRestDtoModel;

    constructor(begin: string, end: string, name: MultilingualRestDtoModel) {
        this.begin = begin;
        this.end = end;
        this.name = name;
    }

    public static createWithDefaults(): ClosingPeriodRestDtoModel {
        return new ClosingPeriodRestDtoModel(
            '',
            '',
            MultilingualRestDtoModel.createWithDefaults(),
        );
    }
}
