<template>
  <div class="fslightbox">
    <button :class="this.class" @click.prevent="this.toggler = !this.toggler">
      <slot></slot>
    </button>

    <FsLightbox
      :toggler="this.toggler"
      :sources="this.sources"
      :customAttributes="this.customAttributes"
      :type="this.type"
      :key="this.key"
      :disableLocalStorage="false"
      :exitFullscreenOnClose="true"
      :loadOnlyCurrentSource="true"
      :onOpen="this.onOpen"
      :onClose="this.onClose"
      :onInit="this.onInit"
      :onShow="this.onShow"
    />
  </div>
</template>

<script>
import FsLightbox from "fslightbox-vue/v3";
import {generateDomId} from "@/helpers/functions/string";
import {FsLightboxTypeEnum} from "@/models/enum/FsLightboxTypeEnum";

export default {
  emits: ["onOpen", "onClose", "onInit", "onShow"],
  components: {FsLightbox},
  props: {
    sources: {
      required: true,
      default: []
    },
    class: {
      required: false,
      default: ''
    },
    customAttributes: {
      required: false,
      default: []
    },
    type: {
      required: false,
      default: FsLightboxTypeEnum.IMAGE
    },
  },
  data() {
    return {
      toggler: false,
      key: generateDomId(),
    }
  },
  methods: {
    onOpen(instance) {
      // console.log('onOpen', instance);
      this.$emit("onOpen", instance);
    },
    onClose(instance) {
      // console.log('onClose', instance);
      this.$emit("onClose", instance);
    },
    onInit(instance) {
      // console.log('onInit', instance);
      this.$emit("onInit", instance);
    },
    onShow(instance) {
      // console.log('onShow', instance);
      this.$emit("onShow", instance);
    },
  }
};
</script>
