import {RestService} from "@/services/rest/RestService";
import {PharmacyInformationEndpointsApi} from "@/api/pharma-cms-pharmacy";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-pharmacy/models";

export class PharmacyInformationRestService extends RestService {

    private apiService: PharmacyInformationEndpointsApi;

    protected constructor() {
        super();

        this.apiService = new PharmacyInformationEndpointsApi(this.getDpAxiosInstance(), undefined, this.getConfig().cmsUrl);
    }

    public async findPharmacies(filterIsInGroup: boolean | undefined): Promise<CodeDescriptionRestDto[]> {
        const response = await this.apiService.findPharmacies(undefined, filterIsInGroup);

        return response.data;
    }
}
