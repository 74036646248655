import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.error)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        innerHTML: _ctx.error,
        class: "alert alert-danger",
        role: "alert"
      }, null, 8, _hoisted_1))
    : _createCommentVNode("", true)
}