import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fst-italic mb-0"
}
const _hoisted_2 = {
  key: 1,
  class: "content"
}
const _hoisted_3 = { class: "table table-hover mb-0" }
const _hoisted_4 = { class: "fw-700" }
const _hoisted_5 = { class: "fw-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertError2 = _resolveComponent("AlertError2")!
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_base_accordion_item = _resolveComponent("base-accordion-item")!
  const _component_base_accordion = _resolveComponent("base-accordion")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AlertError2, {
      error: this.dashboardUI.getError()
    }, null, 8, ["error"]),
    _createVNode(_component_BaseSpinner, {
      spin: this.dashboardUI.isNotReady()
    }, null, 8, ["spin"]),
    (this.dashboardUI.isReady() && !this.hasMonthlyDashboards)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.$t('noContentFound')), 1))
      : _createCommentVNode("", true),
    (this.dashboardUI.isReady() && this.hasMonthlyDashboards)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_base_accordion, { id: "dashboard" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.monthlyDashboardPerCustomer, (monthlyDashboard, customer_code, index) => {
                return (_openBlock(), _createBlock(_component_base_accordion_item, {
                  key: monthlyDashboard,
                  collapsed: index !== 0,
                  index: index + 1,
                  "parent-id": "dashboard",
                  title: monthlyDashboard[0].customer.name
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("table", _hoisted_3, [
                      _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('year')), 1),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.getMonthsForLocale(this.$i18n.locale,'short'), (month) => {
                            return (_openBlock(), _createElementBlock("th", { key: month }, _toDisplayString(this.toInitCapitalize(month)), 1))
                          }), 128)),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('total').toUpperCase()), 1)
                        ])
                      ]),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(monthlyDashboard, (monthlyDashboardRow) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: monthlyDashboardRow.year
                          }, [
                            _createElementVNode("td", _hoisted_4, _toDisplayString(monthlyDashboardRow.year), 1),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.getMonthsForLocale('en','long'), (month) => {
                              return (_openBlock(), _createElementBlock("td", { key: month }, _toDisplayString(monthlyDashboardRow[month.toLowerCase()]), 1))
                            }), 128)),
                            _createElementVNode("td", _hoisted_5, _toDisplayString(monthlyDashboardRow.sum), 1)
                          ]))
                        }), 128))
                      ])
                    ])
                  ]),
                  _: 2
                }, 1032, ["collapsed", "index", "title"]))
              }), 128))
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}