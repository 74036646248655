import {NormalContentBlockModel} from "@/models/api/pharma-cms-content-block/NormalContentBlockModel";
import {
    ContactFormContentRestDto,
    ContentBlockMetadataRestDto,
} from "@/api/pharma-cms-content-block/models";
import {StatusEnum} from "@/api/enums/status-enum";

export class ContactFormContentRestDtoModel extends NormalContentBlockModel implements ContactFormContentRestDto {
    cb_metadata?: ContentBlockMetadataRestDto;
    pharmacy_code?: string | null;
    sort_weight?: number;
    status_code: StatusEnum;

    constructor(status_code: StatusEnum) {
        super();
        this.status_code = status_code;
    }

    public static createWithDefaults(): ContactFormContentRestDtoModel {
        const model = new ContactFormContentRestDtoModel(StatusEnum.PUBLISHED);
        model.pharmacy_code = null;
        // model.sort_weight = NormalContentBlockModel.getDefaultSortWeight();

        return model;
    }
}
