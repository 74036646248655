import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, mergeProps as _mergeProps, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = {
  key: 0,
  class: "modal-header"
}
const _hoisted_4 = { class: "modal-title" }
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = {
  key: 1,
  class: "modal-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_modal_button = _resolveComponent("base-modal-button")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", _mergeProps({
      id: _ctx.modalId,
      class: "modal fade",
      "aria-labelledby": "modalLabel",
      "aria-hidden": "true",
      tabindex: "-1",
      "data-bs-focus": "false"
    }, _ctx.staticAttribute), [
      _createElementVNode("div", {
        class: _normalizeClass(['modal-dialog', _ctx.dialogClasses])
      }, [
        _createElementVNode("div", _hoisted_2, [
          (!_ctx.noHeader)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.title), 1),
                _createVNode(_component_base_modal_button, {
                  ref: "closeButton",
                  mode: "close",
                  class: "btn-close",
                  "aria-label": "Close"
                }, null, 512)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "default")
          ]),
          (!_ctx.noFooter)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _renderSlot(_ctx.$slots, "footer", {}, () => [
                  _createVNode(_component_base_modal_button, {
                    mode: "close",
                    class: "btn btn-outline-primary"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
                    ]),
                    _: 1
                  })
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ], 2)
    ], 16, _hoisted_1)
  ]))
}