
import {defineComponent} from "vue";
import {DpException} from "@/exception/DpException";
import {AVAILABLE_CONTENT_SIZE_CODES} from "@/store/pharma-cms-content-block/middleware";
import {mapActions} from "vuex";

export default defineComponent({
  name: "BannerSlotDetailButtonType",
  props: {
    bannerSlotType: {
      type: String,
      required: true,
    },
    currentMaxContentSizeCode: {
      type: String,
      required: true,
      validator(value: string) {
        return AVAILABLE_CONTENT_SIZE_CODES.includes(value);
      }
    },
    displayedMaxContentSizeCode: {
      type: String,
      required: true,
      validator(value: string) {
        return AVAILABLE_CONTENT_SIZE_CODES.includes(value);
      }
    },
  },
  computed: {
    isCurrent(): boolean {
      return this.currentMaxContentSizeCode === this.displayedMaxContentSizeCode;
    },
  },
  methods: {
    ...mapActions('cms_content_block', ['setBannerSlotField']),
    setMaxContentSize(max_content_size: string) {
      if (AVAILABLE_CONTENT_SIZE_CODES.includes(max_content_size)) {
        this.setBannerSlotField({
          type: this.bannerSlotType,
          key: 'max_content_size_code',
          value: max_content_size
        });
      } else {
        throw [new DpException('Unsupported max_content_size_code: ' + max_content_size)]
      }
    },
  }
});
