import {POSITION, useToast} from "vue-toastification";
import I18n from "@/modules/i18n";

async function handleSavedSuccessfully(message?: string): Promise<void> {
    const toast = useToast();

    if (message === undefined) {
        message = I18n.global.t('savedSuccessfully');
    }

    toast.success(message, {
        position: POSITION.TOP_RIGHT,
    });
}

export {
    handleSavedSuccessfully,
};
