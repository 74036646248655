
import {defineComponent} from "vue";
import AlertError from "@/components/UI/Bootstrap/Alert/AlertError.vue";
import {Exception} from "@/api/interfaces";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {UIStateDto} from "@/dtos/UIStateDto";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";

export default defineComponent({
  name: "ProductDetailPhotosTab",
  components: {AlertError,},
  emits: ["savedSuccessfully"],
  props: {
    productCode: {
      type: String,
      required: true
    },
    cnkCode: {
      type: String,
      required: false
    }
  },
  data: () => ({
    exceptions: [] as Exception[],
    tabUI: UIStateDto.createWithDefaults(),
  }),
  mounted() {
    this.reloadContent();
  },
  methods: {
    async reloadContent() {
      this.tabUI
        .setNotReady()
        .clearError();

      try {
        console.log("reload");
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.tabUI.setReady();
      }
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.tabUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.tabUI as UIStateDto))
        .catch(exceptions, true);
    }
  },
});
