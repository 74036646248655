
import {defineComponent} from 'vue'

export default defineComponent({
  name: "BaseOffcanvasButtonOpen",
  props: {
    offcanvasId: {
      type: String,
      required: true
    },
    class: {
      type: String,
      required: false
    },
    tooltip: {
      type: String,
      required: false,
      default: null
    }
  }
})
