
import {ContentOverviewRestDto} from "@/api/pharma-cms-content-block/models";
import {Exception} from "@/api/interfaces";
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import CbsOverview from "@/components/layouts/content-blocks/overview/CbsOverview.vue";
import {arrayHasContent} from "@/helpers/functions/arrays";

export default defineComponent({
  name: "BannerSlotOverview",
  components: {CbsOverview},
  props: {
    isLibrary: {
      type: Boolean,
      required: false,
      default: false,
    },
    routeObjectEditCb: {
      type: Object,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      exceptions: [] as Exception[],
      isReady: false as boolean,
      cbType: this.isLibrary ? 'LIB_BANNER_SLOT' : 'BANNER_SLOT' as string
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cms_content_block', ['getNormalContentBlocksOverview']),
    contentBlocks(): ContentOverviewRestDto[] {
      return this.getNormalContentBlocksOverview;
    },
    hasContentBlocks(): boolean {
      return arrayHasContent(this.contentBlocks);
    },
  },
  methods: {
    ...mapActions('cms_content_block', ['searchNormalContentBlocksOverview',]),
    async fillContentBlocksWithImage() {
      if (this.hasContentBlocks) {
        this.contentBlocks.forEach((contentBlock: any) => {
          let imageName = '' as string;

          switch (contentBlock.title) {
            case 'Homepagina boven':
            case 'Homepagina top':
            case 'Page d\'accueil ci-dessus':
              imageName = 'SMA_HOME_TOP';
              break;
            case 'Homepagina onder':
            case 'Homepagina bottom':
            case 'Page d\'accueil sous':
              imageName = 'SMA_HOME_BOTTOM';
              break;
            case 'Boven cataloguspagina\'s':
            case 'Above the catalog page':
            case 'Haut des pages du catalogue':
              imageName = 'PROD_CAT_SLOT';
              break;
          }

          //TODO use bigger images
          contentBlock.image_url = require(`@/assets/images/banner-slots/${imageName.toLowerCase()}.png`);
        })
      }
    },
    async reloadContent() {
      this.isReady = false;
      this.exceptions = [];

      try {
        await this.searchNormalContentBlocksOverview({
          type: this.cbType,
        })
        await this.fillContentBlocksWithImage();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
        this.isReady = true;
      }

      this.isReady = true;
    },
  }
});
