export class ContentPageCriteriaModel {
    expect_alt_lang_pages?: boolean;
    filter_type_code: string | null;
    filter_library_code?: string | null;
    search_name?: string | null;


    constructor(filter_type_code: string | null) {
        this.filter_type_code = filter_type_code;
    }

    public static createWithDefaults(): ContentPageCriteriaModel {
        return new this(null);
    }
}
