
import {defineComponent} from "vue";
import ProductSelectionTrademarksSelectionItemBadges
  from "@/components/layouts/catalog/product-selection/ProductSelectionTrademarksSelectionItemBadges.vue";
import {SelectionTypeEnum} from "@/api/enums/selection-type-enum";
import {PropType} from "vue/dist/vue";
import {SelectableProductRestDto, SelectableTrademarkRestDto} from "@/api/pharma-cpc-mgmt/models";

export default defineComponent({
  name: "ProductSelectionTrademarksSelectionItem",
  components: {ProductSelectionTrademarksSelectionItemBadges},
  emits: ["updateTrademarkItem"],
  props: {
    trademarkItem: {
      type: Object as PropType<SelectableTrademarkRestDto>,
      required: true,
    },
    filterSelectionTypeCode: {
      type: String,
      required: false,
      default: undefined,
    }
  },
  computed: {
    hasProducts(): Boolean {
      return this.trademarkItem.base_selection_product_count > 0 || this.trademarkItem.customer_catalog_product_count > 0;
    }
  },
  methods: {
    updateTrademarkItem(trademark_code: string, selection_type: string) {
      let selection_type_code = '' as SelectionTypeEnum;
      switch (selection_type) {
        case 'BASE_SELECTION':
          selection_type_code = SelectionTypeEnum.BASE_SELECTION;
          break;
        case 'INCLUDE':
          selection_type_code = SelectionTypeEnum.INCLUDE;
          break;
        case 'EXCLUDE':
          selection_type_code = SelectionTypeEnum.EXCLUDE;
          break;
      }

      this.$emit('updateTrademarkItem', trademark_code, selection_type_code);
    }
  }
});
