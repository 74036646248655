
import {defineComponent} from 'vue'
import ContentPageElementVisibilityRule
  from "@/components/layouts/content-pages/wysiwyg/ContentPageElementVisibilityRule.vue";

export default defineComponent({
  name: "ContentPageRowVisibilityRule",
  emits: ["saved"],
  components: {ContentPageElementVisibilityRule},
  props: {
    pageId: {
      type: String,
      required: true
    },
    rowId: {
      type: String,
      required: true
    }
  }
})
