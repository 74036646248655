
import {defineComponent} from "vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {PageDetailModeEnum} from "@/models/enum/PageDetailModeEnum";
import FooterDetailContent1 from "@/components/layouts/header-footer/FooterDetailContent1.vue";
import {FooterContentTypeEnum} from "@/models/enum/FooterContentTypeEnum";
import FooterDetailContent2 from "@/components/layouts/header-footer/FooterDetailContent2.vue";

export default defineComponent({
  name: "FooterDetail",
  components: {FooterDetailContent2, FooterDetailContent1, BaseTitle},
  data() {
    return {
      id: this.$route.params.id as string,
      type: this.$route.params.type as string
    }
  },
  computed: {
    FooterContentTypeEnum() {
      return FooterContentTypeEnum
    },
    PageDetailModeEnum() {
      return PageDetailModeEnum
    }
  }
});
