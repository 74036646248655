/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Content Page API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CodeDescriptionRestDto } from '../models';
/**
 * ContentPageEnumEndpointsApi - axios parameter creator
 * @export
 */
export const ContentPageEnumEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.ENUM.02 Find page abstract libraries that can be used in a CbPageReferences
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [filter_is_owner] Filter de resultaten tot die waarvoor deze customer de owner is.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPageAbstractLibraries: async (x_dp_customer_code?: string|null, filter_is_owner?: boolean|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/content_page/v1/enum/page_abstract_library`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_is_owner || typeof(filter_is_owner) === "boolean") {
                localVarQueryParameter['filter_is_owner'] = filter_is_owner;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.ENUM.01 Find page type enums
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [filter_is_library_type] Filter de resultaten ahv of het een page type is van een library of niet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPageTypeEnums: async (x_dp_customer_code?: string|null, filter_is_library_type?: boolean|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/content_page/v1/enum/page_type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_is_library_type || typeof(filter_is_library_type) === "boolean") {
                localVarQueryParameter['filter_is_library_type'] = filter_is_library_type;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentPageEnumEndpointsApi - functional programming interface
 * @export
 */
export const ContentPageEnumEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.ENUM.02 Find page abstract libraries that can be used in a CbPageReferences
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [filter_is_owner] Filter de resultaten tot die waarvoor deze customer de owner is.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPageAbstractLibraries(_axios: AxiosInstance, x_dp_customer_code?: string|null, filter_is_owner?: boolean|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CodeDescriptionRestDto>>> {
            const localVarAxiosArgs = await ContentPageEnumEndpointsApiAxiosParamCreator(configuration).findPageAbstractLibraries(x_dp_customer_code, filter_is_owner, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.ENUM.01 Find page type enums
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [filter_is_library_type] Filter de resultaten ahv of het een page type is van een library of niet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPageTypeEnums(_axios: AxiosInstance, x_dp_customer_code?: string|null, filter_is_library_type?: boolean|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CodeDescriptionRestDto>>> {
            const localVarAxiosArgs = await ContentPageEnumEndpointsApiAxiosParamCreator(configuration).findPageTypeEnums(x_dp_customer_code, filter_is_library_type, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ContentPageEnumEndpointsApi - object-oriented interface
 * @export
 * @class ContentPageEnumEndpointsApi
 * @extends {BaseAPI}
 */
export class ContentPageEnumEndpointsApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.CMS.ENUM.02 Find page abstract libraries that can be used in a CbPageReferences
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {boolean} [filter_is_owner] Filter de resultaten tot die waarvoor deze customer de owner is.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageEnumEndpointsApi
     */
     public findPageAbstractLibraries(x_dp_customer_code?: string|null, filter_is_owner?: boolean|null, options?: any) {
        return ContentPageEnumEndpointsApiFp(this.configuration).findPageAbstractLibraries(this.axios, x_dp_customer_code, filter_is_owner, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.ENUM.01 Find page type enums
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {boolean} [filter_is_library_type] Filter de resultaten ahv of het een page type is van een library of niet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageEnumEndpointsApi
     */
     public findPageTypeEnums(x_dp_customer_code?: string|null, filter_is_library_type?: boolean|null, options?: any) {
        return ContentPageEnumEndpointsApiFp(this.configuration).findPageTypeEnums(this.axios, x_dp_customer_code, filter_is_library_type, options).then((request) => request(this.axios, this.basePath));
    }

}
