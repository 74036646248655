<template>
  <base-container>
    <header>
      <h1>{{ $t('dashboard') }}</h1>
    </header>
    <div class="row">
      <DashboardCard
          icon="home"
          :title="$t('homepage')"
          link="logo"/>
      <DashboardCard
          icon="check_box_outline_blank"
          :title="$t('banners')"
          link="customer-banner-slots"/>
      <DashboardCard
          icon="schedule"
          :title="$t('openingHours')"
          link="standard-hours"/>
      <DashboardCard
          icon="medical_information"
          :title="$t('pharmacyData')"
          link="pharmacy-info"/>
    </div>
    <div class="row">
      <DashboardCard
          icon="campaign"
          :title="$t('reservationCampaigns')"
          link="reservation-campaigns"/>
      <DashboardCard
          icon="storefront"
          :title="$t('catalog')"
          link="product-selection"/>
      <DashboardCard
          icon="settings"
          :title="$t('generalSettings')"
          link="color-scheme"/>
      <DashboardCard
          icon="help"
          :title="$t('needHelp')"
          link="support"/>
    </div>
  </base-container>
</template>

<script>
import DashboardCard from "@/components/layouts/DashboardCard";

export default {
  name: "DashboardHomepage",
  components: {DashboardCard}
}
</script>

<style scoped>

</style>
