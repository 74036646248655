/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Customer Settings
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CustomerDomainOverviewRestDto } from '../models';
import { CustomerDomainRestDto } from '../models';
/**
 * CustomerDomainSettingsAPIApi - axios parameter creator
 * @export
 */
export const CustomerDomainSettingsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for managing the Customer Domain settings.
         * @summary REST.API.PC.SET.05 Create Customer Domain
         * @param {CustomerDomainRestDto} body 
         * @param {string} customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerDomain: async (body: CustomerDomainRestDto, customer_code: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createCustomerDomain.');
            }
            // verify required parameter 'customer_code' is not null or undefined
            if (customer_code === null || customer_code === undefined) {
                throw new RequiredError('customer_code','Required parameter customer_code was null or undefined when calling createCustomerDomain.');
            }
            const localVarPath = `/customer/v1/customer/{customer_code}/settings/domain`
                .replace(`{${"customer_code"}}`, encodeURIComponent(String(customer_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for managing the Customer Domain settings.
         * @summary REST.API.PC.SET.08 Delete Customer Domain
         * @param {string} customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} id Uniquely identifies the Customer Domain.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerDomain: async (customer_code: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer_code' is not null or undefined
            if (customer_code === null || customer_code === undefined) {
                throw new RequiredError('customer_code','Required parameter customer_code was null or undefined when calling deleteCustomerDomain.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCustomerDomain.');
            }
            const localVarPath = `/customer/v1/customer/{customer_code}/settings/domain/{id}`
                .replace(`{${"customer_code"}}`, encodeURIComponent(String(customer_code)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for managing the Customer Domain settings.
         * @summary REST.API.PC.SET.06 Find Customer Domains
         * @param {string} customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCustomerDomain: async (customer_code: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer_code' is not null or undefined
            if (customer_code === null || customer_code === undefined) {
                throw new RequiredError('customer_code','Required parameter customer_code was null or undefined when calling findCustomerDomain.');
            }
            const localVarPath = `/customer/v1/customer/{customer_code}/settings/domain`
                .replace(`{${"customer_code"}}`, encodeURIComponent(String(customer_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving the main domain for a customer. Oldest created domain with is_test = false and is_active = true
         * @summary REST.API.PC.SET.09 Find main domain for customer
         * @param {string} customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCustomerMainDomain: async (customer_code: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer_code' is not null or undefined
            if (customer_code === null || customer_code === undefined) {
                throw new RequiredError('customer_code','Required parameter customer_code was null or undefined when calling findCustomerMainDomain.');
            }
            const localVarPath = `/customer/v1/customer/{customer_code}/settings/domain/main`
                .replace(`{${"customer_code"}}`, encodeURIComponent(String(customer_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for managing the Customer Domain settings.
         * @summary REST.API.PC.SET.07 Update Customer Domain
         * @param {CustomerDomainRestDto} body 
         * @param {string} customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} id Uniquely identifies the Customer Domain.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerDomain: async (body: CustomerDomainRestDto, customer_code: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateCustomerDomain.');
            }
            // verify required parameter 'customer_code' is not null or undefined
            if (customer_code === null || customer_code === undefined) {
                throw new RequiredError('customer_code','Required parameter customer_code was null or undefined when calling updateCustomerDomain.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateCustomerDomain.');
            }
            const localVarPath = `/customer/v1/customer/{customer_code}/settings/domain/{id}`
                .replace(`{${"customer_code"}}`, encodeURIComponent(String(customer_code)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerDomainSettingsAPIApi - functional programming interface
 * @export
 */
export const CustomerDomainSettingsAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for managing the Customer Domain settings.
         * @summary REST.API.PC.SET.05 Create Customer Domain
         * @param {CustomerDomainRestDto} body 
         * @param {string} customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomerDomain(_axios: AxiosInstance, body: CustomerDomainRestDto, customer_code: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustomerDomainSettingsAPIApiAxiosParamCreator(configuration).createCustomerDomain(body, customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for managing the Customer Domain settings.
         * @summary REST.API.PC.SET.08 Delete Customer Domain
         * @param {string} customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} id Uniquely identifies the Customer Domain.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomerDomain(_axios: AxiosInstance, customer_code: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustomerDomainSettingsAPIApiAxiosParamCreator(configuration).deleteCustomerDomain(customer_code, id, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for managing the Customer Domain settings.
         * @summary REST.API.PC.SET.06 Find Customer Domains
         * @param {string} customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCustomerDomain(_axios: AxiosInstance, customer_code: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerDomainOverviewRestDto>>> {
            const localVarAxiosArgs = await CustomerDomainSettingsAPIApiAxiosParamCreator(configuration).findCustomerDomain(customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving the main domain for a customer. Oldest created domain with is_test = false and is_active = true
         * @summary REST.API.PC.SET.09 Find main domain for customer
         * @param {string} customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCustomerMainDomain(_axios: AxiosInstance, customer_code: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDomainOverviewRestDto>> {
            const localVarAxiosArgs = await CustomerDomainSettingsAPIApiAxiosParamCreator(configuration).findCustomerMainDomain(customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for managing the Customer Domain settings.
         * @summary REST.API.PC.SET.07 Update Customer Domain
         * @param {CustomerDomainRestDto} body 
         * @param {string} customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} id Uniquely identifies the Customer Domain.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerDomain(_axios: AxiosInstance, body: CustomerDomainRestDto, customer_code: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustomerDomainSettingsAPIApiAxiosParamCreator(configuration).updateCustomerDomain(body, customer_code, id, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CustomerDomainSettingsAPIApi - object-oriented interface
 * @export
 * @class CustomerDomainSettingsAPIApi
 * @extends {BaseAPI}
 */
export class CustomerDomainSettingsAPIApi extends BaseAPI {
    /**
     * API endpoint responsible for managing the Customer Domain settings.
     * @summary REST.API.PC.SET.05 Create Customer Domain
     * @param {CustomerDomainRestDto} body 
     * @param {string} customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerDomainSettingsAPIApi
     */
     public createCustomerDomain(body: CustomerDomainRestDto, customer_code: string, options?: any) {
        return CustomerDomainSettingsAPIApiFp(this.configuration).createCustomerDomain(this.axios, body, customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for managing the Customer Domain settings.
     * @summary REST.API.PC.SET.08 Delete Customer Domain
     * @param {string} customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} id Uniquely identifies the Customer Domain.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerDomainSettingsAPIApi
     */
     public deleteCustomerDomain(customer_code: string, id: string, options?: any) {
        return CustomerDomainSettingsAPIApiFp(this.configuration).deleteCustomerDomain(this.axios, customer_code, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for managing the Customer Domain settings.
     * @summary REST.API.PC.SET.06 Find Customer Domains
     * @param {string} customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerDomainSettingsAPIApi
     */
     public findCustomerDomain(customer_code: string, options?: any) {
        return CustomerDomainSettingsAPIApiFp(this.configuration).findCustomerDomain(this.axios, customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving the main domain for a customer. Oldest created domain with is_test = false and is_active = true
     * @summary REST.API.PC.SET.09 Find main domain for customer
     * @param {string} customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerDomainSettingsAPIApi
     */
     public findCustomerMainDomain(customer_code: string, options?: any) {
        return CustomerDomainSettingsAPIApiFp(this.configuration).findCustomerMainDomain(this.axios, customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for managing the Customer Domain settings.
     * @summary REST.API.PC.SET.07 Update Customer Domain
     * @param {CustomerDomainRestDto} body 
     * @param {string} customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} id Uniquely identifies the Customer Domain.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerDomainSettingsAPIApi
     */
     public updateCustomerDomain(body: CustomerDomainRestDto, customer_code: string, id: string, options?: any) {
        return CustomerDomainSettingsAPIApiFp(this.configuration).updateCustomerDomain(this.axios, body, customer_code, id, options).then((request) => request(this.axios, this.basePath));
    }

}
