import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = {
  key: 0,
  class: "mas me-2"
}
const _hoisted_3 = { class: "text-decoration-underline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: "d-flex align-items-center text-decoration-none",
    href: _ctx.pageReference.url,
    target: "_blank"
  }, [
    (_ctx.pageReference.icon)
      ? (_openBlock(), _createElementBlock("i", _hoisted_2, _toDisplayString(_ctx.pageReference.icon), 1))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.pageReference.name), 1)
  ], 8, _hoisted_1))
}