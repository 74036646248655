import {
    ButtonContentRestDto, cloneButtonRestDtoMetaData,
    ContentBlockMetadataRestDto
} from "@/api/pharma-cms-content-block/models";
import {NormalContentBlockModel} from "@/models/api/pharma-cms-content-block/NormalContentBlockModel";
import {ButtonRestDtoModel} from "@/models/api/pharma-cms-content-block/ButtonRestDtoModel";
import {StatusEnum} from "@/api/enums/status-enum";

export class ButtonContentRestDtoModel extends NormalContentBlockModel implements ButtonContentRestDto {
    button: ButtonRestDtoModel;
    cb_metadata?: ContentBlockMetadataRestDto;
    sort_weight?: number;
    status_code: StatusEnum;

    constructor(status_code: StatusEnum, button: ButtonRestDtoModel, cb_metadata?: ContentBlockMetadataRestDto, sort_weight?: number) {
        super();
        this.button = button;
        this.cb_metadata = cb_metadata;
        this.sort_weight = sort_weight;
        this.status_code = status_code;
    }

    public static createWithDefaults(): ButtonContentRestDtoModel {
        return new this(
            StatusEnum.PUBLISHED,
            ButtonRestDtoModel.createWithDefaults(),
            undefined,
            // this.getDefaultSortWeight()
        );
    }

    public static cloneFrom(source: ButtonContentRestDtoModel): ButtonContentRestDtoModel {
        return new this(
            source.status_code,
            source.button,
            source.cb_metadata,
            source.sort_weight,
        );
    }

    public cloneMetadataFrom(source: ButtonContentRestDtoModel): void {
        cloneButtonRestDtoMetaData(this.button, source.button);
    }
}
