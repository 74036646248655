/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Footer API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CmsFooterContent1RestDto } from '../models';
import { CmsFooterContent2RestDto } from '../models';
import { FooterOverviewResultsRestDto } from '../models';
/**
 * FooterEndpointsApi - axios parameter creator
 * @export
 */
export const FooterEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.FOOT.02 Get footer of type footer_content_1.
         * @param {string} id Uniquely identefies the footer content to update.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFooterContent1: async (id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getFooterContent1.');
            }
            const localVarPath = `/footer/v1/footer_content_1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.FOOT.04 Get footer of type footer_content_2.
         * @param {string} id Uniquely identefies the footer content to update.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFooterContent2: async (id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getFooterContent2.');
            }
            const localVarPath = `/footer/v1/footer_content_2/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.FOOT.01 Get an overview of the footer stack.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFooterStackOverview: async (x_dp_customer_code?: string, x_dp_language?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/footer/v1/footer/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.FOOT.03 Update footer of type footer_content_1.
         * @param {CmsFooterContent1RestDto} body 
         * @param {string} id Uniquely identefies the footer content to update.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFooterContent1: async (body: CmsFooterContent1RestDto, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateFooterContent1.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateFooterContent1.');
            }
            const localVarPath = `/footer/v1/footer_content_1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.FOOT.05 Update footer of type footer_content_2.
         * @param {CmsFooterContent2RestDto} body 
         * @param {string} id Uniquely identefies the footer content to update.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFooterContent2: async (body: CmsFooterContent2RestDto, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateFooterContent2.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateFooterContent2.');
            }
            const localVarPath = `/footer/v1/footer_content_2/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FooterEndpointsApi - functional programming interface
 * @export
 */
export const FooterEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.FOOT.02 Get footer of type footer_content_1.
         * @param {string} id Uniquely identefies the footer content to update.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFooterContent1(_axios: AxiosInstance, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CmsFooterContent1RestDto>> {
            const localVarAxiosArgs = await FooterEndpointsApiAxiosParamCreator(configuration).getFooterContent1(id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.FOOT.04 Get footer of type footer_content_2.
         * @param {string} id Uniquely identefies the footer content to update.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFooterContent2(_axios: AxiosInstance, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CmsFooterContent2RestDto>> {
            const localVarAxiosArgs = await FooterEndpointsApiAxiosParamCreator(configuration).getFooterContent2(id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.FOOT.01 Get an overview of the footer stack.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFooterStackOverview(_axios: AxiosInstance, x_dp_customer_code?: string, x_dp_language?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FooterOverviewResultsRestDto>> {
            const localVarAxiosArgs = await FooterEndpointsApiAxiosParamCreator(configuration).getFooterStackOverview(x_dp_customer_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.FOOT.03 Update footer of type footer_content_1.
         * @param {CmsFooterContent1RestDto} body 
         * @param {string} id Uniquely identefies the footer content to update.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFooterContent1(_axios: AxiosInstance, body: CmsFooterContent1RestDto, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await FooterEndpointsApiAxiosParamCreator(configuration).updateFooterContent1(body, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.FOOT.05 Update footer of type footer_content_2.
         * @param {CmsFooterContent2RestDto} body 
         * @param {string} id Uniquely identefies the footer content to update.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFooterContent2(_axios: AxiosInstance, body: CmsFooterContent2RestDto, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await FooterEndpointsApiAxiosParamCreator(configuration).updateFooterContent2(body, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * FooterEndpointsApi - object-oriented interface
 * @export
 * @class FooterEndpointsApi
 * @extends {BaseAPI}
 */
export class FooterEndpointsApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.CMS.FOOT.02 Get footer of type footer_content_1.
     * @param {string} id Uniquely identefies the footer content to update.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FooterEndpointsApi
     */
     public getFooterContent1(id: string, x_dp_customer_code?: string, options?: any) {
        return FooterEndpointsApiFp(this.configuration).getFooterContent1(this.axios, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.FOOT.04 Get footer of type footer_content_2.
     * @param {string} id Uniquely identefies the footer content to update.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FooterEndpointsApi
     */
     public getFooterContent2(id: string, x_dp_customer_code?: string, options?: any) {
        return FooterEndpointsApiFp(this.configuration).getFooterContent2(this.axios, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.FOOT.01 Get an overview of the footer stack.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FooterEndpointsApi
     */
     public getFooterStackOverview(x_dp_customer_code?: string, x_dp_language?: string, options?: any) {
        return FooterEndpointsApiFp(this.configuration).getFooterStackOverview(this.axios, x_dp_customer_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.FOOT.03 Update footer of type footer_content_1.
     * @param {CmsFooterContent1RestDto} body 
     * @param {string} id Uniquely identefies the footer content to update.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FooterEndpointsApi
     */
     public updateFooterContent1(body: CmsFooterContent1RestDto, id: string, x_dp_customer_code?: string, options?: any) {
        return FooterEndpointsApiFp(this.configuration).updateFooterContent1(this.axios, body, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.FOOT.05 Update footer of type footer_content_2.
     * @param {CmsFooterContent2RestDto} body 
     * @param {string} id Uniquely identefies the footer content to update.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FooterEndpointsApi
     */
     public updateFooterContent2(body: CmsFooterContent2RestDto, id: string, x_dp_customer_code?: string, options?: any) {
        return FooterEndpointsApiFp(this.configuration).updateFooterContent2(this.axios, body, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
