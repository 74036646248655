import {Context} from "@/patterns/Context";

export class SnippetContext extends Context {
    private static _selectedSlotPosition: string;

    static getSelectedSlotPosition(): string {
        return this._selectedSlotPosition;
    }

    static setSelectedSlotPosition(value: string): void {
        this._selectedSlotPosition = value;
    }
}
