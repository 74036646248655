import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50f7ebf4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mb-4" }
const _hoisted_3 = { class: "row row-banner-slot" }
const _hoisted_4 = { class: "col-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_InputSelect = _resolveComponent("InputSelect")!
  const _component_dp_form = _resolveComponent("dp-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseSpinner, {
      spin: !this.isReady
    }, null, 8, ["spin"]),
    (this.isReady)
      ? (_openBlock(), _createBlock(_component_dp_form, {
          key: 0,
          as: "form",
          novalidate: ""
        }, {
          default: _withCtx(({ errors }) => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_InputSelect, {
                      modelValue: this.internalCbId,
                      "onUpdate:modelValue": [
                        _cache[0] || (_cache[0] = ($event: any) => ((this.internalCbId) = $event)),
                        this.onChange
                      ],
                      options: this.bannerSlotOptions,
                      name: "bannerSlotId",
                      selectText: _ctx.$t('selectText'),
                      rules: "required",
                      errors: errors
                    }, null, 8, ["modelValue", "options", "selectText", "errors", "onUpdate:modelValue"])
                  ])
                ])
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}