import {PagedLaboProductOverviewResultsRestDto} from "@/api/pharma-pim-labo/models";
import {PagingMetadataRestDto} from "@/api/pharma-pim-pp2/models";
import {LaboProductOverviewRestDtoModel} from "@/models/api/pharma-pim-labo/LaboProductOverviewRestDtoModel";

export class PagedLaboProductOverviewResultsRestDtoModel implements PagedLaboProductOverviewResultsRestDto {
    paging_metadata?: PagingMetadataRestDto | null;
    results: LaboProductOverviewRestDtoModel[];

    constructor(results: LaboProductOverviewRestDtoModel[]) {
        this.results = results;
    }

    public static createWithDefaults(): PagedLaboProductOverviewResultsRestDtoModel {
        return new this([]);
    }
}
