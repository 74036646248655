
import {defineComponent} from 'vue'
import {PageDetailModeEnum} from "@/models/enum/PageDetailModeEnum";
import {PimProductsRestService} from "@/services/rest/pim-pp2/PimProductsRestService";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {UIStateDto} from "@/dtos/UIStateDto";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import {Pp2ProductDetailRestDtoModel} from "@/models/api/pharma-pim-pp2/Pp2ProductDetailRestDtoModel";
import BaseCard from "@/components/UI/Bootstrap/Card/BaseCard.vue";
import BaseCardHeader from "@/components/UI/Bootstrap/Card/BaseCardHeader.vue";
import BaseCardBody from "@/components/UI/Bootstrap/Card/BaseCardBody.vue";
import MultilingualSwitcher from "@/components/UI/MultilingualSwitcher.vue";
import PimProductsDetailLab from "@/components/layouts/pim/PimProductsDetailLab.vue";
import PimProductsDetailPrices from "@/components/layouts/pim/PimProductsDetailPrices.vue";
import {generateDomId} from "@/helpers/functions/string";
import {UtilityHelper} from "@/helpers/UtilityHelper";
import {arrayHasContent} from "@/helpers/functions/arrays";
import Lightbox from "@/components/UI/Lightbox.vue";
import BaseModal from "@/components/UI/Bootstrap/Modal/BaseModal.vue";
import BaseModalButton from "@/components/UI/Bootstrap/Modal/BaseModalButton.vue";
import PimProductsOverviewLegend from "@/components/layouts/pim/PimProductsOverviewLegend.vue";
import PimProductsOverviewItemIcons from "@/components/layouts/pim/PimProductsOverviewItemIcons.vue";
import PimProductsDetailCategories from "@/components/layouts/pim/PimProductsDetailCategories.vue";
import {FullProductInfoRestDtoModel} from "@/models/api/pharma-pim-mgmt/FullProductInfoRestDtoModel";
import {PimMgmtRestService} from "@/services/rest/pim-mgmt/PimMgmtRestService";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import {NamedTextFragmentRestDtoModel} from "@/models/api/pharma-pim-mgmt/NamedTextFragmentRestDtoModel";
import NamedTextFragmentPanel from "@/components/layouts/pim/mgmt/NamedTextFragmentPanel.vue";
import PimFeedbackPanel from "@/components/layouts/pim/mgmt/PimFeedbackPanel.vue";
import {EnumClassNamePimEnum} from "@/models/enum/EnumClassNamePimEnum";
import {mapActions, mapGetters} from "vuex";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import ConfirmModal from "@/components/UI/ConfirmModal.vue";
import {Tooltip} from "bootstrap";
import {useToast} from "vue-toastification";
import {PimFeedbackRestDtoModel} from "@/models/api/pharma-pim-mgmt/PimFeedbackRestDtoModel";
import {AbilityContext} from "@/context/AbilityContext";
import {ProductDetailsCoreRestDtoModel} from "@/models/api/pharma-pim-mgmt/ProductDetailsCoreRestDtoModel";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import SelectCategories from "@/components/layouts/catalog/SelectCategories.vue";
import {Config} from "@/models/facade/Config";
import {mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import {PimCategorizationRestService} from "@/services/rest/pim-category/PimCategorizationRestService";
import {CnkListRestDto} from "@/api/pharma-pim-category/models";

export default defineComponent({
  name: "PimProductsDetail",
  components: {
    SelectCategories,
    PimProductsDetailCategories,
    PimProductsOverviewItemIcons,
    PimProductsOverviewLegend,
    NamedTextFragmentPanel,
    PimFeedbackPanel,
    BaseModalButton,
    BaseModal,
    Lightbox,
    PimProductsDetailPrices,
    PimProductsDetailLab,
    MultilingualSwitcher,
    BaseCardBody,
    BaseCardHeader,
    BaseCard,
    BaseSpinner,
    AlertError2,
  },
  props: {
    cnkCode: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      catalogCustomerCode: Config.getInstance().getConfig().STARTER_CATALOG_CUSTOMER_CODE as string,

      modalProducerInfoId: generateDomId('modal-producer-info-') as string,
      modalPriceHistoryId: generateDomId('modal-price-history-') as string,

      activeLanguage: 'nl' as string,
      languages: ['nl', 'fr', 'en', 'de'] as string[],

      mode: PageDetailModeEnum.EDIT,

      product: Pp2ProductDetailRestDtoModel.createWithDefaults(),
      productInfoNl: FullProductInfoRestDtoModel.createWithDefaults(),
      isEditingNamedTextFragmentWithId: null as string | null,
      isShowFeedbackForFragmentWithId: null as string | null,
      isNewNamedTextFragmentPanelOpen: false,
      isNewTextFragmentPanelOpen: false,
      isShowGeneralFeedback: false,

      isCorePimDetailsEditMode: false,
      corePimDetailsDto: null as ProductDetailsCoreRestDtoModel | null,

      isShowAddDpmanagedCategory: false,
      dpManagedCategoryToAddCode: null as string | null,

      isShowAddInternalCategory: false,
      internalCategoryToAddCode: null as string | null,

      toaster: useToast(),
      tooltips: [] as Tooltip[],

      productUI: UIStateDto.createWithDefaults()
    }
  },
  watch: {
    cnkCode() {
      this.reloadContent();
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('pim_enum', ['getEnumByClassname']),
    ...mapState(useCustomerStore, ["getInternalProductCategories"]),
    AbilityContext() {
      return AbilityContext
    },
    UtilityHelper() {
      return UtilityHelper
    },
    hasProductProperties(): boolean {
      return arrayHasContent(this.product.product_properties);
    },
    farmaFrameThumbnailNl(): string {
      return "https://api.farmaframe.be/v1/image/thumbnail/nl/" + this.product.product_info.cnk_code + "/150";
    },
    farmaFrameThumbnailFr(): string {
      return "https://api.farmaframe.be/v1/image/thumbnail/fr/" + this.product.product_info.cnk_code + "/150";
    },
    trademarkOptions(): CodeDescriptionRestDto[] {
      if (this.isCorePimDetailsEditMode) {
        return this.getEnumByClassname(EnumClassNamePimEnum.PIM_TRADEMARK) as CodeDescriptionRestDto[];
      } else {
        return [];
      }
    },
    targetGroupOptions(): CodeDescriptionRestDto[] {
      if (this.isCorePimDetailsEditMode) {
        return this.getEnumByClassname(EnumClassNamePimEnum.PIM_TARGET_GROUP) as CodeDescriptionRestDto[];
      } else {
        return [];
      }
    },
    unitOptions(): CodeDescriptionRestDto[] {
      if (this.isCorePimDetailsEditMode) {
        return this.getEnumByClassname(EnumClassNamePimEnum.PIM_UNIT) as CodeDescriptionRestDto[];
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions('pim_enum', ["findManyEnumByClassname"]),
    ...mapActions('pim_enum', ["findEnumByClassname"]),
    async reloadContent(): Promise<void> {
      this.productUI
        .setNotReady()
        .clearError();

      this.isCorePimDetailsEditMode = false;
      this.isShowAddInternalCategory = false;
      this.isShowAddDpmanagedCategory = false;
      this.internalCategoryToAddCode = null;
      this.dpManagedCategoryToAddCode = null;

      try {
        if (this.mode === PageDetailModeEnum.EDIT) {
          await Promise.all([
            this.product = await PimProductsRestService.getInstance().findPimProductForCnkCode(this.cnkCode),
            this.findManyEnumByClassname([
              EnumClassNamePimEnum.TEXT_FRAGMENT_NAME,
              EnumClassNamePimEnum.PIM_PRODUCT_FIELD,
            ]),
            await this.reloadPimContent(),
          ]);

        }
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.productUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.productUI as UIStateDto))
          .catch(exceptions, true);
      } finally {
        this.productUI.setReady();
      }

    },
    async toggleEditCorePimDetails(): Promise<void> {
      if (!this.isCorePimDetailsEditMode) {
        await Promise.all([
          this.corePimDetailsDto = ProductDetailsCoreRestDtoModel.createFromProductDetailsRestDto(this.productInfoNl?.details),
          this.findManyEnumByClassname([EnumClassNamePimEnum.PIM_TRADEMARK, EnumClassNamePimEnum.PIM_TARGET_GROUP, EnumClassNamePimEnum.PIM_UNIT,]),
        ]);
      }
      this.isCorePimDetailsEditMode = !this.isCorePimDetailsEditMode;
    },
    async removeDpCategory(categoryCode: string): Promise<void> {
      try {
        const payload = {
          cnk_codes: [this.cnkCode],
        } as CnkListRestDto;
        await PimCategorizationRestService.getInstance().removeProductsFromDpCategory(payload, categoryCode);
        this.reloadContent();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }
    },
    async removeIntCat(categoryCode: string): Promise<void> {
      try {
        const payload = {
          cnk_codes: [this.cnkCode],
        } as CnkListRestDto;
        await PimCategorizationRestService.getInstance().removeProductsFromInternalCategory(payload, categoryCode);
        this.reloadContent();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }
    },
    async submitAddDpCategory(): Promise<void> {
      try {
        await PimCategorizationRestService.getInstance().addDpCategoryToProduct(this.cnkCode, this.dpManagedCategoryToAddCode);
        this.reloadContent();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }
    },
    async submitAddInternalCategory(): Promise<void> {
      try {
        await PimCategorizationRestService.getInstance().addInternalCategoryToProduct(this.cnkCode, this.internalCategoryToAddCode);
        this.reloadContent();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }
    },
    async submitPimCoreData(): Promise<void> {
      try {
        await PimMgmtRestService.getInstance().updatePimDetailsCore(this.corePimDetailsDto, this.product.product_code);
        this.reloadContent();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }

    },
    async submitNamedFragmentContent(fragmentId: string | null, namedFragment: NamedTextFragmentRestDtoModel): Promise<void> {
      this.productUI.clearError();

      try {
        if (fragmentId !== null) {
          await PimMgmtRestService.getInstance().updateNamedTextFragment(fragmentId, namedFragment);
        } else {
          await PimMgmtRestService.getInstance().createNamedTextFragment(this.cnkCode, 'nl', namedFragment);
          this.isNewNamedTextFragmentPanelOpen = false;
        }
        await this.reloadPimContent();

        await handleSavedSuccessfully();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }
    },
    async submitNamedFragmentCancel(fragmentId: string | null): Promise<void> {
      this.isNewNamedTextFragmentPanelOpen = false;
      if (fragmentId !== null) {
        this.toggleIsEditingNamedTextFragmentWithId(fragmentId);
      }
    },
    async submitPimFeedback(feedback: PimFeedbackRestDtoModel): Promise<void> {
      this.productUI.clearError();

      try {
        await PimMgmtRestService.getInstance().createPimFeedback(this.cnkCode, 'nl', feedback);
        if (feedback.text_fragment_id != null) {
          this.toggleIsShowFeedbackForFragmentWithId(feedback.text_fragment_id);
        } else {
          this.isShowGeneralFeedback = false
        }

        await handleSavedSuccessfully();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }
    },
    async submitPimFeedbackCancel(fragmentId: string | null): Promise<void> {
      this.isShowGeneralFeedback = false
      if (fragmentId !== null) {
        this.toggleIsShowFeedbackForFragmentWithId(fragmentId);
      }
    },
    openNewNamedTextFragmentPanel(): void {
      this.isNewNamedTextFragmentPanelOpen = true;
    },
    toggleIsEditingNamedTextFragmentWithId(fragmentId: string): void {
      this.isShowFeedbackForFragmentWithId = null;
      this.isNewNamedTextFragmentPanelOpen = false;
      if (this.isEditingNamedTextFragmentWithId === fragmentId) {
        this.isEditingNamedTextFragmentWithId = null;
      } else {
        this.isEditingNamedTextFragmentWithId = fragmentId;
      }
    },
    toggleIsShowFeedbackForFragmentWithId(fragmentId: string): void {
      this.isEditingNamedTextFragmentWithId = null;
      this.isNewNamedTextFragmentPanelOpen = false;
      if (this.isShowFeedbackForFragmentWithId === fragmentId) {
        this.isShowFeedbackForFragmentWithId = null;
      } else {
        this.isShowFeedbackForFragmentWithId = fragmentId;
      }
    },
    async deleteNamedTextFragmentWithId(fragmentId: string): Promise<void> {
      this.isEditingNamedTextFragmentWithId = null;
      try {
        await PimMgmtRestService.getInstance().deleteNamedTextFragment(fragmentId);
        await this.reloadPimContent();

        await handleSavedSuccessfully();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }
    },
    initializeTooltips(): void {
      document.querySelectorAll('#pim-products-detail [data-bs-toggle="tooltip"]').forEach(el => {
        const tooltip = new Tooltip(el as Element);
        this.tooltips.push(tooltip);
      });
    },
    hideTooltips(): void {
      this.tooltips.forEach(tt => {
        tt.hide();
      });
    },
    confirmDeleteFragment(fragmentId: string): void {
      const {reveal, onConfirm} = createConfirmDialog(ConfirmModal, {
        title: this.$t('cms.delete'),
        body: this.$t('cms.confirmDeleteTextFragment'),
        confirmButtonText: this.$t('delete'),
        confirmButtonClass: 'btn-danger',
      });

      onConfirm(async () => {
        await this.deleteNamedTextFragmentWithId(fragmentId);

        this.hideTooltips();
        this.toaster.success(this.$t('cms.successfullyDeleted'));

        await this.reloadContent();
      });

      reveal();
    },
    async reloadPimContent(): Promise<void> {
      this.productInfoNl = await PimMgmtRestService.getInstance()
        .findMgmtProductInfo(this.cnkCode, 'nl');
      this.isEditingNamedTextFragmentWithId = null;
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.productUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.productUI as UIStateDto))
        .catch(exceptions, true);
    }
  },
})
