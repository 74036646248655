
import {defineComponent, ref} from 'vue';
import {createInstance} from '@/helpers/axios';
import {config} from '@/helpers/fetch-config';
import {Exception} from "@/api/interfaces";
import {mapActions, mapGetters} from "vuex";
import {generateUUIDv4} from "@/helpers/functions/string";

export default defineComponent({
  emits: ["exceptions", "imageCleared", "imageUploaded"],
  props: {
    id: {
      type: String,
      required: false,
      default: generateUUIDv4('file-input-'),
    },
    isCpc: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    typeCode: {
      type: String,
      required: true,
    },
    existingDocumentKey: {
      type: String,
    },
    mode: {
      type: String,
      required: false,
      default: 'default',
    },
    canBeDeleted: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: () => ({
    file: ref<File | null>(null),
    previewUrl: '',
    isUploading: false as Boolean,
    error: '',
    exceptions: [] as Exception[],
    uploadLimit: config.uploadFileSizeLimitMB ?? 10,
  }),
  mounted() {
    this.reloadContent();
  },
  watch: {
    existingDocumentKey() {
      this.reloadContent();
    }
  },
  computed: {
    ...mapGetters('cms_document', {
      getCmsPreviewDocument: 'getPreviewDocument',
      getCmsUploadedDocumentKey: 'getUploadedDocumentKey'
    }),
    ...mapGetters('cpc_document', {
      getCpcPreviewDocument: 'getPreviewDocument',
      getCpcUploadedDocumentKey: 'getUploadedDocumentKey'
    }),
    fileName(): string {
      return this.previewUrl.substr(this.previewUrl.lastIndexOf('/') + 1);
    },
    isDocument(): boolean {
      return this.typeCode === 'PAGE_DOC' || this.typeCode === 'REIMBURSED_CARE_RATES_DOC';
    }
    // TODO make this url load from store dynamically
    // previewUrl(): String {
    //   return (this.isCpc) ? this.getCpcPreviewDocument(this.typeCode).url : this.getCmsPreviewDocument(this.typeCode).url;
    // },
  },
  methods: {
    ...mapActions('cms_document', {
      clearCmsPreviewDocument: 'clearPreviewDocument',
      clearCmsUploadedDocumentKey: 'clearUploadedDocumentKey',
      searchCmsPreviewDocument: 'searchPreviewDocument',
      setCmsUploadedDocumentKey: 'setUploadedDocumentKey'
    }),
    ...mapActions('cpc_document', {
      clearCpcPreviewDocument: 'clearPreviewDocument',
      clearCpcUploadedDocumentKey: 'clearUploadedDocumentKey',
      searchCpcPreviewDocument: 'searchPreviewDocument',
      setCpcUploadedDocumentKey: 'setUploadedDocumentKey'
    }),
    reloadContent() {
      if (this.existingDocumentKey) {
        this.searchPreviewDocument(this.existingDocumentKey);
      } else {
        this.previewUrl = '';
      }
    },
    dragover(event: any) {
      // event.preventDefault();

      if (!event.currentTarget.classList.contains('dragging')) {
        event.currentTarget.classList.add('dragging');
      }
    },
    dragleave(event: any) {
      event.currentTarget.classList.remove('dragging');
    },
    drop(event: any) {
      // event.preventDefault();
      // this.$refs.file.files = event.dataTransfer.files;
      // this.onChange(); // Trigger the onChange event manually

      event.currentTarget.classList.remove('dragging');
    },
    clear() {
      let answer = window.confirm(this.$t('deleteConfirmation'));

      if (answer) {
        const uploadInput: any = this.$refs[`fileupload-${this.id}`];
        uploadInput.value = null;
        this.file = null;
        this.previewUrl = '';
        // (this.isCpc) ? this.clearCpcPreviewDocument(this.typeCode) : this.clearCmsPreviewDocument(this.typeCode);
        this.error = '';
        (this.isCpc) ? this.clearCpcUploadedDocumentKey(this.typeCode) : this.clearCmsUploadedDocumentKey(this.typeCode);

        this.$emit('imageCleared');
      } else {
        return
      }
    },
    handleChosenFile(event: any) {
      this.file = event.target.files[0];

      if (this.file) {
        if (this.file.size > this.uploadLimit * 1024 * 1024) {
          event.target.value = '';
          throw new Error(this.$t('uploadFileSizeTooBig', {maxSize: this.uploadLimit}));
        }
      }
      this.uploadFile();
    },
    async searchPreviewDocument(existing_document_key: String) {
      try {
        const payload = {
          document_type: this.typeCode,
          document_key: existing_document_key,
        };
        (this.isCpc) ? await this.searchCpcPreviewDocument(payload) : await this.searchCmsPreviewDocument(payload);

        this.previewUrl = (this.isCpc) ? this.getCpcPreviewDocument(this.typeCode).signed_url : this.getCmsPreviewDocument(this.typeCode).url;
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async uploadFile() {
      if (!this.file) {
        return;
      }
      this.isUploading = true;

      try {
        const axios = createInstance();
        const formConfig = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };

        const formData = new FormData();
        formData.append('file', this.file);

        const uploadUrl = (this.isCpc) ? config.fileUploadUrlCpc.replace('{type_code}', this.typeCode) : config.fileUploadUrl.replace('{type_code}', this.typeCode);

        const response = await axios.post(uploadUrl, formData, formConfig);
        const documentKey = response?.data?.document_key;
        if (documentKey) {
          const url = (this.isCpc) ? config.fileUrlDownloadUrlCpc.replace('{document_key}', documentKey) : config.fileUrlDownloadUrl.replace('{document_key}', documentKey);
          const image = await axios.get(url);
          this.previewUrl = (this.isCpc) ? image?.data?.signed_url : image?.data?.url;
          this.isUploading = false;
          this.error = '';

          const payload = {
            document_type: this.typeCode,
            document_key: documentKey,
          };
          (this.isCpc) ? this.setCpcUploadedDocumentKey(payload) : this.setCmsUploadedDocumentKey(payload);

          this.$emit('imageUploaded', (this.isCpc) ? this.getCpcUploadedDocumentKey(this.typeCode) : this.getCmsUploadedDocumentKey(this.typeCode));
        } else {
          throw new Error('No document key');
        }
      } catch (exceptions: any) {
        console.log(exceptions)
        this.isUploading = false;

        if (this.mode === 'default') {
          this.exceptions = exceptions;
        } else if (this.mode === 'dropzone') {
          this.$emit('exceptions', exceptions as Exception[]);
        }
      }
    },
  },
});
