import {CpcShoppingCartDiscountSchemeEndpointsApiService} from "@/main";
import {
    ShoppingCartDiscountSchemeOverviewRestDto,
    ShoppingCartDiscountSchemeRestDto,
} from "@/api/pharma-cpc-mgmt/models";
import {formatDateObjectToDateString} from "@/helpers/functions/date-time";
import {
    emptyShoppingCartDiscountScheme,
} from "@/store/pharma-cpc-mgmt/middleware";

export default {
    state() {
        return {
            shopping_cart_discount_scheme: {} as ShoppingCartDiscountSchemeRestDto,
            shopping_cart_discount_schemes: [] as ShoppingCartDiscountSchemeOverviewRestDto[],
        };
    },
    actions: {
        clearShoppingCartDiscountScheme(context: any) {
            context.commit('clearShoppingCartDiscountScheme');
        },
        clearShoppingCartDiscountSchemePeriod(context: any) {
            context.commit('clearShoppingCartDiscountSchemePeriod');
        },
        setShoppingCartDiscountSchemeValue(context: any, payload: any) {
            context.commit('setShoppingCartDiscountSchemeValue', {
                key: payload.key as string,
                value: payload.value as boolean | string | number,
            });
        },
        async deleteShoppingCartDiscountScheme(context: any, id: string) {
            await CpcShoppingCartDiscountSchemeEndpointsApiService.deleteShoppingCartDiscountScheme(id);
        },
        async searchShoppingCartDiscountScheme(context: any, id: string) {
            const response =
                await CpcShoppingCartDiscountSchemeEndpointsApiService.getShoppingCartDiscountScheme(id);

            context.commit('setShoppingCartDiscountScheme', response.data);
        },
        async searchShoppingCartDiscountSchemes(context: any) {
            const response =
                await CpcShoppingCartDiscountSchemeEndpointsApiService.findShoppingCartDiscountSchemes(undefined);
            context.commit('setShoppingCartDiscountSchemes', response.data);
        },
    },
    mutations: {
        clearShoppingCartDiscountScheme(state: any) {
            state.shopping_cart_discount_scheme = emptyShoppingCartDiscountScheme();
        },
        clearShoppingCartDiscountSchemePeriod(state: any) {
            state.shopping_cart_discount_scheme.begin_date = formatDateObjectToDateString(new Date());
            state.shopping_cart_discount_scheme.end_date = undefined;
        },
        setShoppingCartDiscountScheme(state: any, discount_scheme: ShoppingCartDiscountSchemeRestDto) {
            state.shopping_cart_discount_scheme = discount_scheme;
        },
        setShoppingCartDiscountSchemeValue(state: any, payload: any) {
            state.shopping_cart_discount_scheme[payload.key as string] = payload.value as boolean | string | number;
        },
        setShoppingCartDiscountSchemes(state: any, discount_schemes: ShoppingCartDiscountSchemeOverviewRestDto[]) {
            state.shopping_cart_discount_schemes = discount_schemes;
        },
    },
    getters: {
        getShoppingCartDiscountScheme(state: any): ShoppingCartDiscountSchemeRestDto {
            return state.shopping_cart_discount_scheme;
        },
        getShoppingCartDiscountSchemes(state: any): ShoppingCartDiscountSchemeOverviewRestDto[] {
            return state.shopping_cart_discount_schemes;
        }
    },
}
