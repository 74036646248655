
import {defineComponent} from 'vue'
import {UIStateDto} from "@/dtos/UIStateDto";
import {PimProductsRestService} from "@/services/rest/pim-pp2/PimProductsRestService";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {IPrinsPriceRestDto} from "@/api/pharma-pim-pp2/models";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import {arrayHasContent} from "@/helpers/functions/arrays";
import {formatDate} from "@/helpers/functions/date";

export default defineComponent({
  name: "PimProductsDetailPrices",
  components: {AlertError2},
  props: {
    cnkCode: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      iPrinsPrices: [] as IPrinsPriceRestDto[],
      iPrinsPricesUI: UIStateDto.createWithDefaults()
    }
  },
  watch: {
    cnkCode() {
      this.reloadContent();
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    hasPrices(): boolean {
      return arrayHasContent(this.iPrinsPrices);
    },
  },
  methods: {
    formatDate,
    async reloadContent(): Promise<void> {
      this.iPrinsPricesUI
        .setNotReady()
        .clearError();

      try {
        this.iPrinsPrices = (await PimProductsRestService.getInstance()
          .findIPrinsProductPrices(this.cnkCode)).results;
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.iPrinsPricesUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.iPrinsPricesUI as UIStateDto))
          .catch(exceptions, true);
      } finally {
        this.iPrinsPricesUI.setReady();
      }
    },
  },
});
