import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f2bf2b7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  disabled: ""
}
const _hoisted_2 = ["value"]
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  key: 2,
  class: "form-help"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dp_field = _resolveComponent("dp-field")!
  const _component_dp_error_message = _resolveComponent("dp-error-message")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dp-input mb-3", {
        'clearable': this.canClear,
        'can-be-cleared': this.canClear && this.modelValue,
        'form-floating': this.isFloating,
        'has-error': this.errors[_ctx.name],
      }])
  }, [
    _createVNode(_component_dp_field, {
      name: this.name,
      rules: this.rules,
      as: "select",
      "aria-label": 'Select ' + this.name,
      class: _normalizeClass(["form-select", {
          'form-select-sm': (this.inputSize === this.enums.inputSizeEnum.SM),
          'form-select-lg': (this.inputSize === this.enums.inputSizeEnum.LG),
          'is-invalid': this.errors[_ctx.name],
        }]),
      id: this.name,
      modelValue: this.modelValue,
      placeholder: this.placeholder,
      disabled: this.disabled,
      "onUpdate:modelValue": this.updateValue
    }, {
      default: _withCtx(() => [
        (this.selectText)
          ? (_openBlock(), _createElementBlock("option", _hoisted_1, _toDisplayString(this.selectText), 1))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.selectOptions, (option) => {
          return (_openBlock(), _createElementBlock("option", {
            key: option.code,
            value: option.code,
            class: "fw-700"
          }, _toDisplayString(option.description), 9, _hoisted_2))
        }), 128))
      ]),
      _: 1
    }, 8, ["name", "rules", "aria-label", "class", "id", "modelValue", "placeholder", "disabled", "onUpdate:modelValue"]),
    (this.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: this.name,
          class: "form-label"
        }, _toDisplayString(this.label), 9, _hoisted_3))
      : _createCommentVNode("", true),
    (this.canClear && this.modelValue)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (this.updateValue(null)), ["prevent"])),
          class: "btn-close",
          type: "reset"
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_dp_error_message, {
      class: "invalid-feedback d-block",
      name: this.name
    }, null, 8, ["name"]),
    (this.help)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(this.help), 1))
      : _createCommentVNode("", true)
  ], 2))
}