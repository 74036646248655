import {DpExceptions} from "@/error/DpExceptions";

export function registerErrorInterceptorV2(instance) {
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      const exceptions = error?.response?.data?.exceptions;

      if (exceptions) {
          return Promise.reject(new DpExceptions(exceptions, error?.request, error?.response));
      }

      return Promise.reject(error);
    }
  );
}
