import {ProductDetailsCoreRestDto, ProductDetailsRestDto} from "@/api/pharma-pim-mgmt/models";

export class ProductDetailsCoreRestDtoModel implements ProductDetailsCoreRestDto {
    commercial_name?: string | null;
    shape?: string | null;
    target_group_code?: string | null;
    trademark_code?: string | null;
    unit_code?: string | null;


    constructor() {
    }

    public static createWithDefaults(): ProductDetailsCoreRestDto {
        return new this()
    }

    public static createFromProductDetailsRestDto(detailDto: ProductDetailsRestDto | null): ProductDetailsCoreRestDto {
        const result= ProductDetailsCoreRestDtoModel.createWithDefaults();
        if (detailDto === null || detailDto === undefined) {
            return result;
        }

        result.commercial_name = detailDto.commercial_name;
        result.shape = detailDto.shape;
        result.target_group_code = detailDto.target_group?.code;
        result.trademark_code = detailDto.trademark?.code;
        result.unit_code = detailDto.unit?.code;

        return result;
    }
}
