/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Snippet API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { IdResponseRestDto } from '../models';
import { PagedSnippetInSlotOverviewRestDto } from '../models';
import { SnippetCreateRestDto } from '../models';
import { SnippetRestDto } from '../models';
/**
 * ContentPageSnippetEndpointsApi - axios parameter creator
 * @export
 */
export const ContentPageSnippetEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.SNIS.02 Add a snippet in a slot position
         * @param {string} snippet_id The unique identifier of the snippet.
         * @param {string} position_code The unique identifier of the snippet.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSnippetInSlot: async (snippet_id: string, position_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'snippet_id' is not null or undefined
            if (snippet_id === null || snippet_id === undefined) {
                throw new RequiredError('snippet_id','Required parameter snippet_id was null or undefined when calling addSnippetInSlot.');
            }
            // verify required parameter 'position_code' is not null or undefined
            if (position_code === null || position_code === undefined) {
                throw new RequiredError('position_code','Required parameter position_code was null or undefined when calling addSnippetInSlot.');
            }
            const localVarPath = `/content_page/v1/snippet_in_slot/{snippet_id}/{position_code}`
                .replace(`{${"snippet_id"}}`, encodeURIComponent(String(snippet_id)))
                .replace(`{${"position_code"}}`, encodeURIComponent(String(position_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Duplicate the snippet and the corresponding content page.
         * @summary REST.API.CMS.SNIP.05 Copy snippet.
         * @param {string} id The unique identifier of this snippet in slot.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [to_customer_code] When provided, the snippet and page will be copied to the customer that corresponds to the value of this param. NOTE that this parameter is ONLY allowed by a Digital Pharma user..
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copySnippet: async (id: string, x_dp_customer_code?: string|null, to_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling copySnippet.');
            }
            const localVarPath = `/content_page/v1/snippet/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!to_customer_code || typeof(to_customer_code) === "boolean") {
                localVarQueryParameter['to_customer_code'] = to_customer_code;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.SNIP.02 Create snippet.
         * @param {SnippetCreateRestDto} body 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSnippet: async (body: SnippetCreateRestDto, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createSnippet.');
            }
            const localVarPath = `/content_page/v1/snippet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.SNIP.04 Delete snippet.
         * @param {string} id The unique identifier of this snippet in slot.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSnippet: async (id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteSnippet.');
            }
            const localVarPath = `/content_page/v1/snippet/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.SNIS.03 Remove a snippet from a slot position
         * @param {string} snippet_id The unique identifier of the snippet.
         * @param {string} position_code The unique identifier of the snippet.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSnippetInSlot: async (snippet_id: string, position_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'snippet_id' is not null or undefined
            if (snippet_id === null || snippet_id === undefined) {
                throw new RequiredError('snippet_id','Required parameter snippet_id was null or undefined when calling deleteSnippetInSlot.');
            }
            // verify required parameter 'position_code' is not null or undefined
            if (position_code === null || position_code === undefined) {
                throw new RequiredError('position_code','Required parameter position_code was null or undefined when calling deleteSnippetInSlot.');
            }
            const localVarPath = `/content_page/v1/snippet_in_slot/{snippet_id}/{position_code}`
                .replace(`{${"snippet_id"}}`, encodeURIComponent(String(snippet_id)))
                .replace(`{${"position_code"}}`, encodeURIComponent(String(position_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.SNIS.01 Get all snippets in slot for a customer.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSnippetsInSlot: async (x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/content_page/v1/snippet_in_slot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.SNIP.03 Update snippet.
         * @param {SnippetRestDto} body 
         * @param {string} id The unique identifier of this snippet in slot.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSnippet: async (body: SnippetRestDto, id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateSnippet.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateSnippet.');
            }
            const localVarPath = `/content_page/v1/snippet/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentPageSnippetEndpointsApi - functional programming interface
 * @export
 */
export const ContentPageSnippetEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.SNIS.02 Add a snippet in a slot position
         * @param {string} snippet_id The unique identifier of the snippet.
         * @param {string} position_code The unique identifier of the snippet.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSnippetInSlot(_axios: AxiosInstance, snippet_id: string, position_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentPageSnippetEndpointsApiAxiosParamCreator(configuration).addSnippetInSlot(snippet_id, position_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Duplicate the snippet and the corresponding content page.
         * @summary REST.API.CMS.SNIP.05 Copy snippet.
         * @param {string} id The unique identifier of this snippet in slot.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [to_customer_code] When provided, the snippet and page will be copied to the customer that corresponds to the value of this param. NOTE that this parameter is ONLY allowed by a Digital Pharma user..
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copySnippet(_axios: AxiosInstance, id: string, x_dp_customer_code?: string|null, to_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentPageSnippetEndpointsApiAxiosParamCreator(configuration).copySnippet(id, x_dp_customer_code, to_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.SNIP.02 Create snippet.
         * @param {SnippetCreateRestDto} body 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSnippet(_axios: AxiosInstance, body: SnippetCreateRestDto, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResponseRestDto>> {
            const localVarAxiosArgs = await ContentPageSnippetEndpointsApiAxiosParamCreator(configuration).createSnippet(body, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.SNIP.04 Delete snippet.
         * @param {string} id The unique identifier of this snippet in slot.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSnippet(_axios: AxiosInstance, id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentPageSnippetEndpointsApiAxiosParamCreator(configuration).deleteSnippet(id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.SNIS.03 Remove a snippet from a slot position
         * @param {string} snippet_id The unique identifier of the snippet.
         * @param {string} position_code The unique identifier of the snippet.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSnippetInSlot(_axios: AxiosInstance, snippet_id: string, position_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentPageSnippetEndpointsApiAxiosParamCreator(configuration).deleteSnippetInSlot(snippet_id, position_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.SNIS.01 Get all snippets in slot for a customer.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSnippetsInSlot(_axios: AxiosInstance, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedSnippetInSlotOverviewRestDto>> {
            const localVarAxiosArgs = await ContentPageSnippetEndpointsApiAxiosParamCreator(configuration).findSnippetsInSlot(x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.SNIP.03 Update snippet.
         * @param {SnippetRestDto} body 
         * @param {string} id The unique identifier of this snippet in slot.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSnippet(_axios: AxiosInstance, body: SnippetRestDto, id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentPageSnippetEndpointsApiAxiosParamCreator(configuration).updateSnippet(body, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ContentPageSnippetEndpointsApi - object-oriented interface
 * @export
 * @class ContentPageSnippetEndpointsApi
 * @extends {BaseAPI}
 */
export class ContentPageSnippetEndpointsApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.CMS.SNIS.02 Add a snippet in a slot position
     * @param {string} snippet_id The unique identifier of the snippet.
     * @param {string} position_code The unique identifier of the snippet.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageSnippetEndpointsApi
     */
     public addSnippetInSlot(snippet_id: string, position_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentPageSnippetEndpointsApiFp(this.configuration).addSnippetInSlot(this.axios, snippet_id, position_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Duplicate the snippet and the corresponding content page.
     * @summary REST.API.CMS.SNIP.05 Copy snippet.
     * @param {string} id The unique identifier of this snippet in slot.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [to_customer_code] When provided, the snippet and page will be copied to the customer that corresponds to the value of this param. NOTE that this parameter is ONLY allowed by a Digital Pharma user..
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageSnippetEndpointsApi
     */
     public copySnippet(id: string, x_dp_customer_code?: string|null, to_customer_code?: string|null, options?: any) {
        return ContentPageSnippetEndpointsApiFp(this.configuration).copySnippet(this.axios, id, x_dp_customer_code, to_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.SNIP.02 Create snippet.
     * @param {SnippetCreateRestDto} body 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageSnippetEndpointsApi
     */
     public createSnippet(body: SnippetCreateRestDto, x_dp_customer_code?: string|null, options?: any) {
        return ContentPageSnippetEndpointsApiFp(this.configuration).createSnippet(this.axios, body, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.SNIP.04 Delete snippet.
     * @param {string} id The unique identifier of this snippet in slot.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageSnippetEndpointsApi
     */
     public deleteSnippet(id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentPageSnippetEndpointsApiFp(this.configuration).deleteSnippet(this.axios, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.SNIS.03 Remove a snippet from a slot position
     * @param {string} snippet_id The unique identifier of the snippet.
     * @param {string} position_code The unique identifier of the snippet.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageSnippetEndpointsApi
     */
     public deleteSnippetInSlot(snippet_id: string, position_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentPageSnippetEndpointsApiFp(this.configuration).deleteSnippetInSlot(this.axios, snippet_id, position_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.SNIS.01 Get all snippets in slot for a customer.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageSnippetEndpointsApi
     */
     public findSnippetsInSlot(x_dp_customer_code?: string|null, options?: any) {
        return ContentPageSnippetEndpointsApiFp(this.configuration).findSnippetsInSlot(this.axios, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.SNIP.03 Update snippet.
     * @param {SnippetRestDto} body 
     * @param {string} id The unique identifier of this snippet in slot.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageSnippetEndpointsApi
     */
     public updateSnippet(body: SnippetRestDto, id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentPageSnippetEndpointsApiFp(this.configuration).updateSnippet(this.axios, body, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
