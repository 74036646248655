export class NormalContentBlockModel {
    // protected static DEFAULT_SORT_WEIGHT: number = 0;

    constructor() {
    }

    // public static getDefaultSortWeight(): number {
    //     return this.DEFAULT_SORT_WEIGHT;
    // }
}
