import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "row mb-4" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = {
  key: 1,
  class: "my-3"
}
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_spinner = _resolveComponent("base-spinner")!
  const _component_CbLibraryOverviewItem = _resolveComponent("CbLibraryOverviewItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!this.isReady)
        ? (_openBlock(), _createBlock(_component_base_spinner, { key: 0 }))
        : _createCommentVNode("", true),
      (this.isReady)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (!this.hasContentBlocks)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('noContentFound')), 1))
              : _createCommentVNode("", true),
            (this.hasContentBlocks)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(this.libContentBlocks, (contentBlock) => {
                  return (_openBlock(), _createBlock(_component_CbLibraryOverviewItem, {
                    key: contentBlock.id,
                    "content-block": contentBlock,
                    bus: this.bus
                  }, null, 8, ["content-block", "bus"]))
                }), 128))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}