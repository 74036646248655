
import {defineComponent} from 'vue'
import {mapActions, mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import BaseContainer from "@/components/UI/Bootstrap/BaseContainer.vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {UIStateDto} from "@/dtos/UIStateDto";
import {PimProductsRestService} from "@/services/rest/pim-pp2/PimProductsRestService";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import {
  PagedPp2ProductOverviewResultsRestDtoModel
} from "@/models/api/pharma-pim-pp2/PagedPp2ProductOverviewResultsRestDtoModel";
import {arrayHasContent} from "@/helpers/functions/arrays";
import PimProductsOverviewTable from "@/components/layouts/pim/PimProductsOverviewTable.vue";
import {PimProductsOverviewPageLayoutEnum} from "@/models/enum/pim/PimProductsOverviewPageLayoutEnum";
import PimProductsOverviewTiles from "@/components/layouts/pim/PimProductsOverviewTiles.vue";
import PimProductsOverviewControls from "@/components/layouts/pim/PimProductsOverviewControls.vue";
import PimProductsOverviewCriteria from "@/components/layouts/pim/PimProductsOverviewCriteria.vue";
import {PimProductsPagingModel} from "@/models/paging/PimProductsPagingModel";
import {PimProductsCriteriaModel} from "@/models/criteria/PimProductsCriteriaModel";
import PimProductsOverviewCategorization from "@/components/layouts/pim/PimProductsOverviewCategorization.vue";
import {AbilityContext} from "@/context/AbilityContext";
import PimProductsOverviewLegend from "@/components/layouts/pim/PimProductsOverviewLegend.vue";
import BaseOffcanvas from "@/components/UI/Bootstrap/Offcanvas/BaseOffcanvas.vue";
import PimProductsDetail from "@/components/layouts/pim/PimProductsDetail.vue";
import {Pp2ProductOverviewRestDtoModel} from "@/models/api/pharma-pim-pp2/Pp2ProductOverviewRestDtoModel";
import {generateDomId} from "@/helpers/functions/string";
import {default as Offcanvas} from 'bootstrap/js/dist/offcanvas';

export default defineComponent({
  name: "PimProductsOverview",
  components: {
    PimProductsDetail,
    BaseOffcanvas,
    OverviewLegend: PimProductsOverviewLegend,
    OverviewCategorization: PimProductsOverviewCategorization,
    OverviewControls: PimProductsOverviewControls,
    OverviewCriteria: PimProductsOverviewCriteria,
    OverviewTable: PimProductsOverviewTable,
    OverviewTiles: PimProductsOverviewTiles,
    AlertError2, BaseTitle, BaseContainer
  },
  data() {
    return {
      offcanvasProductDetail: null as Offcanvas | null,
      offcanvasProductDetailId: generateDomId('offcanvas-product-detail') as string,

      criteriaInitialized: false as boolean,

      productsOverview: PagedPp2ProductOverviewResultsRestDtoModel.createWithDefaults(),
      productsOverviewUI: UIStateDto.createWithDefaults(),

      selectedDetailProduct: null as Pp2ProductOverviewRestDtoModel | null,
    }
  },
  mounted() {
    this.offcanvasProductDetail = new Offcanvas('#' + this.offcanvasProductDetailId);

    if (this.getPimState.pim_products_is_initialized) {
      this.reloadContent();
    } else {
      this.productsOverviewUI.setReady();
    }
  },
  computed: {
    AbilityContext() {
      return AbilityContext
    },
    ...mapState(useCustomerStore, ["getCriteria", "getPaging", "getPimState"]),
    PimProductsOverviewPageLayoutEnum() {
      return PimProductsOverviewPageLayoutEnum;
    },
    productsCriteria(): PimProductsCriteriaModel {
      return this.getCriteria.pim_products;
    },
    productsPaging(): PimProductsPagingModel {
      return this.getPaging.pim_products;
    },
    hasProducts(): boolean {
      return arrayHasContent(this.productsOverview.results);
    }
  },
  methods: {
    ...mapActions(useCustomerStore, ["clearPimProductsSelectedForCategorization"]),
    onControlsChanged(): void {
      if (this.getPimState.pim_products_is_initialized) {
        this.reloadContent();
      }
    },
    onCriteriaInitialized(): void {
      this.criteriaInitialized = true;
    },
    onCategorizationSubmitted(): void {
      this.reloadContent();
    },
    onOpenProductDetail(selectedProduct: Pp2ProductOverviewRestDtoModel): void {
      this.selectedDetailProduct = selectedProduct;
      this.offcanvasProductDetail?.show();
    },
    onSelectedDpCat(dpCatCode: string): void {
      this.getCriteria.pim_products.filter_category_code_dp_managed = dpCatCode;
      this.getCriteria.pim_products.filter_dp_managed_category_cardinality = null;
      this.getCriteria.pim_products.filter_category_code_internal = null;
      this.getCriteria.pim_products.filter_is_not_in_internal_category = null;
      this.getPimState.pim_products_criteria_is_changed = true;
    },
    onSelectedIntCat(intCatCode: string): void {
      this.getCriteria.pim_products.filter_category_code_dp_managed = null;
      this.getCriteria.pim_products.filter_dp_managed_category_cardinality = null;
      this.getCriteria.pim_products.filter_category_code_internal = intCatCode;
      this.getCriteria.pim_products.filter_is_not_in_internal_category = null;
      this.getPimState.pim_products_criteria_is_changed = true;
    },
    async downloadOverviewAsCSV(): Promise<any> {
      try {
        await PimProductsRestService.getInstance().exportPimProductOverviewAsCsv(this.productsCriteria);
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }
    },
    async reloadContent(): Promise<void> {
      this.productsOverviewUI
        .setNotReady()
        .clearError();

      try {
        this.offcanvasProductDetail?.hide();
        this.clearPimProductsSelectedForCategorization();

        if (this.productsPaging.layout === PimProductsOverviewPageLayoutEnum.TILES) {
          this.productsCriteria.expect_categories = false; // In TILES modes, categories will never be shown
        }
        this.productsOverview = await PimProductsRestService.getInstance()
          .findPimProductOverview(this.productsCriteria, this.productsPaging);

        this.getPimState.pim_products_is_initialized = true;
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.productsOverviewUI.setReady();
      }
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.productsOverviewUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.productsOverviewUI as UIStateDto))
        .catch(exceptions, true);
    }
  }
})
