
import {defineComponent} from "vue";
import {mapActions} from 'vuex';
import {
  CodeDescriptionRestDto
} from "@/api/pharma-cms-content-block/models";
import PageLinkPicker from "@/components/UI/PageLinkPicker.vue";
import {PageLinkPayload} from "@/models/payload/PageLinkPayload";
import {PageLinkTypeEnum} from "@/models/enum/PageLinkTypeEnum";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import AbstractCbCommon from "@/components/layouts/content-pages/wysiwyg/structures/AbstractCbCommon.vue";
import {LinkTargetEnum} from "@/models/enum/LinkTargetEnum";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import {SymbolTargetEnum} from "@/api/enums/symbol-target-enum";
import Subtitle from "@/components/UI/Subtitle.vue";
import InputNumber from "@/components/UI/InputNumber.vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import InputText from "@/components/UI/InputText.vue";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import PreviewSymbol from "@/components/UI/PreviewSymbol.vue";
import ColorPicker from "@/components/UI/ColorPicker.vue";
import {ColorPickerPayload} from "@/models/payload/ColorPickerPayload";
import {
  TextWithSymbolContentRestDtoModel
} from "@/models/api/pharma-cms-content-block/TextWithSymbolContentRestDtoModel";
import {ButtonRestDtoModel} from "@/models/api/pharma-cms-content-block/ButtonRestDtoModel";
import {ButtonTargetEnum} from "@/api/enums/button-target-enum";
import {CopyCpAbstractPayload} from "@/models/payload/CopyCpAbstractPayload";
import InputTextEditor from "@/components/UI/InputTextEditor.vue";

export default defineComponent({
  name: "CbTextWithSymbol",
  extends: AbstractCbCommon,
  components: {
    InputTextEditor,
    ColorPicker,
    PreviewSymbol,
    Subtitle, InputCheckboxBoolean, InputText, InputSelect, InputNumber, PageLinkPicker, BaseSpinner
  },
  data: () => ({
    contentBlock: TextWithSymbolContentRestDtoModel.createWithDefaults(),
    pageLinkSymbolPayload: PageLinkPayload.createWithDefaults(),
    pageLinkButtonPayload: PageLinkPayload.createWithDefaults(),
    useButton: false as boolean,
    removedButton: ButtonRestDtoModel.createWithDefaults(),
  }),
  watch: {
    contentBlock() {
      this.buildPageLinkPayload();

      if (this.contentBlock.button) {
        this.useButton = true;
        this.removedButton = this.contentBlock.button;
      } else {
        this.useButton = false;
        this.contentBlock.button = undefined;
        this.removedButton = ButtonRestDtoModel.createWithDefaults();
      }
    },
    useButton(newVal) {
      if (newVal === true) {
        this.contentBlock.button = this.removedButton;

        this.buildPageLinkButtonPayload();
      } else {
        if (this.contentBlock.button !== undefined) {
          this.removedButton = this.contentBlock.button as ButtonRestDtoModel;
        }
        this.contentBlock.button = undefined
      }
    }
  },
  computed: {
    enumButtonLayoutCode(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.BUTTON_LAYOUT);
    },
    enumLayoutCode(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.TEXT_WITH_IMAGE_LAYOUT);
    },
    enumObjectHorizontalPosition(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.OBJECT_HORIZONTAL_POSITION);
    },
    enumSymbolFamily(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.SYMBOL_FAMILY_ENUM);
    },
    enumSymbolGrade(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.SYMBOL_GRADE_ENUM);
    },
    enumSymbolOpticalSize(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.SYMBOL_OPTICAL_SIZE_ENUM);
    },
    enumSymbolWeight(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.SYMBOL_WEIGHT_ENUM);
    },
    enumTitleSizeCode(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.TITLE_SIZE);
    },
    enumVerticalPosition(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.OBJECT_VERTICAL_POSITION);
    },
  },
  methods: {
    ...mapActions('cms_content_block/normal_content_block/text_with_symbol', ['create', 'find', 'save']),
    afterReloadContent() {
      if (this.contentBlock.button) {
        this.useButton = true;
        this.removedButton = this.contentBlock.button;
      }
    },
    determineEnums(): EnumClassNameCmsEnum[] {
      return [
        EnumClassNameCmsEnum.BUTTON_LAYOUT,
        EnumClassNameCmsEnum.OBJECT_HORIZONTAL_POSITION,
        EnumClassNameCmsEnum.OBJECT_VERTICAL_POSITION,
        EnumClassNameCmsEnum.SYMBOL_FAMILY_ENUM,
        EnumClassNameCmsEnum.SYMBOL_GRADE_ENUM,
        EnumClassNameCmsEnum.SYMBOL_OPTICAL_SIZE_ENUM,
        EnumClassNameCmsEnum.SYMBOL_WEIGHT_ENUM,
        EnumClassNameCmsEnum.TEXT_WITH_IMAGE_LAYOUT,
        EnumClassNameCmsEnum.TITLE_SIZE,
      ];
    },
    getDefaultCbModel(): TextWithSymbolContentRestDtoModel {
      return TextWithSymbolContentRestDtoModel.createWithDefaults();
    },
    buildPageLinkPayload(): void {
      this.buildPageLinkButtonPayload();
      this.buildPageLinkSymbolPayload();
    },
    buildPageLinkButtonPayload(): void {
      const externalUrlButton = this.contentBlock.button?.button_url?.nl ?? null;
      const pageIdButton = this.contentBlock.button?.button_page_id?.nl ?? null;
      const targetButton = this.contentBlock.button?.button_target ?? LinkTargetEnum.SELF;

      this.pageLinkButtonPayload = new PageLinkPayload(externalUrlButton, pageIdButton, targetButton.toString() as LinkTargetEnum);
    },
    buildPageLinkSymbolPayload(): void {
      const externalUrlSymbol = this.contentBlock.symbol.symbol_url?.nl ?? null;
      const pageIdSymbol = this.contentBlock.symbol.symbol_page_id?.nl ?? null;
      const targetSymbol = this.contentBlock.symbol.symbol_target ?? LinkTargetEnum.SELF;

      this.pageLinkSymbolPayload = new PageLinkPayload(externalUrlSymbol, pageIdSymbol, targetSymbol.toString() as LinkTargetEnum);
    },
    onChangeColorDefault(payload: ColorPickerPayload): void {
      this.contentBlock.symbol.default_state_color = payload.colorRestDto;
      this.onChange();
    },
    onChangeColorHover(payload: ColorPickerPayload): void {
      this.contentBlock.symbol.hover_state_color = payload.colorRestDto;
      this.onChange();
    },
    onChangeSymbolName(): void {
      this.contentBlock.symbol.name_code = this.contentBlock.symbol.name_code
        .toLowerCase()
        .replaceAll(' ', '_');
      this.onChange();
    },
    onCopyCpAbstractChanged(): void {
      const title = this.contentBlock.title?.nl ?? null;
      const content = this.contentBlock.content?.nl ?? null;
      this.copyCpAbstractPayload = new CopyCpAbstractPayload(content, null, title);

      this.$emit('abstractContentChanged', this.copyCpAbstractPayload);
    },
    onPageLinkPickerSymbolChanged(payload: PageLinkPayload): void {
      if (payload.type === PageLinkTypeEnum.NONE) {
        this.contentBlock.symbol.symbol_url = undefined;
        this.contentBlock.symbol.symbol_page_id = undefined;
        this.contentBlock.symbol.symbol_target = undefined;
      } else if (payload.type === PageLinkTypeEnum.URL) {
        this.contentBlock.symbol.symbol_url = new MultilingualRestDtoModel(null, null, null, payload.external_url);
        this.contentBlock.symbol.symbol_page_id = undefined;
        this.contentBlock.symbol.symbol_target = payload.target.toString() as SymbolTargetEnum;
      } else if (payload.type === PageLinkTypeEnum.PAGE) {
        this.contentBlock.symbol.symbol_url = undefined;
        this.contentBlock.symbol.symbol_page_id = new MultilingualRestDtoModel(null, null, null, payload.page_id);
        this.contentBlock.symbol.symbol_target = payload.target.toString() as SymbolTargetEnum;
      }

      this.onChange();
    },
    onPageLinkPickerButtonChanged(payload: PageLinkPayload) {
      if (this.contentBlock.button) {
        if (payload.type === PageLinkTypeEnum.NONE) {
          this.contentBlock.button.button_url = MultilingualRestDtoModel.createWithDefaults();
          this.contentBlock.button.button_page_id = undefined;
          this.contentBlock.button.button_target = ButtonTargetEnum.SELF;
        } else if (payload.type === PageLinkTypeEnum.URL) {
          this.contentBlock.button.button_url = new MultilingualRestDtoModel(null, null, null, payload.external_url);
          this.contentBlock.button.button_page_id = undefined;
          this.contentBlock.button.button_target = payload.target.toString() as ButtonTargetEnum;
        } else if (payload.type === PageLinkTypeEnum.PAGE) {
          this.contentBlock.button.button_url = MultilingualRestDtoModel.createWithDefaults();
          this.contentBlock.button.button_page_id = new MultilingualRestDtoModel(null, null, null, payload.page_id);
          this.contentBlock.button.button_target = payload.target.toString() as ButtonTargetEnum;
        }
      }

      this.onChange();
    },
    pasteCbMetadata(): void {
      // When calling clone method directly on object, method is not found. So this is a hacky workaround
      const cbClone = TextWithSymbolContentRestDtoModel.cloneFrom(this.contentBlock);

      cbClone.cloneMetadataFrom(this.getClipboardContentBlock(this.cbType) as TextWithSymbolContentRestDtoModel);
      this.contentBlock = cbClone;

      this.buildPageLinkPayload();
    },
  }
});
