
import {defineComponent} from "vue";
import {CodeDescriptionRestDto, ContentPageRestDto} from "@/api/pharma-cms-content-page/models";
import CpsOverviewItemAbstract from "@/components/layouts/content-pages/overview/CpsOverviewItemAbstract.vue";
import ButtonContentPagePreview from "@/components/layouts/content-pages/ButtonContentPagePreview.vue";
import {mapGetters} from "vuex";
import {LinkTypeEnum} from "@/api/enums/link-type-enum";
import {MoveActionEnum} from "@/models/enum/MoveActionEnum";
import {AuthContext} from "@/context/AuthContext";
import {formatDate} from "@/helpers/functions/date";
import {FullContentPageRestDtoModel} from "@/models/api/pharma-cms-content-page/FullContentPageRestDtoModel";
import {StatusEnum} from "@/api/enums/status-enum";
import {ValueEnum} from "@/api/enums/value-enum";
import {AbilityContext} from "@/context/AbilityContext";
import InputSelect from "@/components/UI/InputSelect.vue";
import BaseMoveButtons from "@/components/UI/BaseMoveButtons.vue";

export default defineComponent({
  name: "CpsOverviewItem",
  components: {BaseMoveButtons, InputSelect, ButtonContentPagePreview, CpsOverviewItemAbstract},
  emits: ["copy", "delete", "move", "updateIsPublishedOnHome", "updateStatus", "updateType"],
  props: {
    fullContentPage: {
      type: [FullContentPageRestDtoModel, Object],
      required: true,
    },
    type: {
      type: String,
      required: false
    },
    canMove: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      pageType: this.fullContentPage.type.code as string,
    }
  },
  watch: {
    async pageType(newType, oldType) {
      if (newType !== oldType) {
        this.$emit('updateType', this.fullContentPage.id, newType);
      }
    }
  },
  computed: {
    ...mapGetters('cms_content_page', ["getPageTypeEnums"]),
    StatusEnum() {
      return StatusEnum
    },
    MoveActionEnum() {
      return MoveActionEnum
    },
    contentPage(): ContentPageRestDto {
      return this.fullContentPage.cp;
    },
    canUpdatePage(): boolean {
      return AbilityContext.isAuthorizedForFeature('MGMT_CONTENT_PAGE') && !this.isLinkedPage;
    },
    isDpUser(): boolean {
      return AuthContext.isDpUser();
    },
    isLibrary(): boolean {
      return !!this.type;
    },
    isLinkedPage(): boolean {
      return this.contentPage.cb_metadata?.link_type_code === LinkTypeEnum.IMMUTABLE;
    },
    pageTypeEnums(): CodeDescriptionRestDto[] {
      return this.getPageTypeEnums;
    }
  },
  methods: {
    formatDate,
    moveContentPage(id: string, moveAction: MoveActionEnum) {
      this.$emit('move', id, moveAction);
    },
    updateIsPublishedOnHome(id: string, isPublishedOnHome: boolean) {
      if (this.canUpdatePage) {
        this.$emit('updateIsPublishedOnHome', id, isPublishedOnHome);
      }
    },
    updateStatus(id: string, status: ValueEnum) {
      if (this.canUpdatePage) {
        this.$emit('updateStatus', id, status);
      }
    }
  }
});
