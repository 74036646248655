import {NormalContentBlockModel} from "@/models/api/pharma-cms-content-block/NormalContentBlockModel";
import {
    cloneImageRestDtoMetaData,
    ContentBlockMetadataRestDto,
    ImageContentRestDto
} from "@/api/pharma-cms-content-block/models";
import {ImageRestDtoModel} from "@/models/api/pharma-cms-content-block/ImageRestDtoModel";
import {StatusEnum} from "@/api/enums/status-enum";

export class ImageContentRestDtoModel extends NormalContentBlockModel implements ImageContentRestDto {
    cb_metadata?: ContentBlockMetadataRestDto;
    image: ImageRestDtoModel;
    sort_weight?: number;
    status_code: StatusEnum;

    constructor(image: ImageRestDtoModel, status_code: StatusEnum) {
        super();
        this.image = image;
        this.status_code = status_code;
    }

    public static createWithDefaults(): ImageContentRestDtoModel {
        return new this(ImageRestDtoModel.createWithDefaults(), StatusEnum.PUBLISHED);
    }

    public static cloneFrom(source: ImageContentRestDtoModel): ImageContentRestDtoModel {
        const model = new this(source.image, source.status_code);
        model.cb_metadata = source.cb_metadata;
        model.sort_weight = source.sort_weight;

        return model;
    }

    public cloneMetadataFrom(source: ImageContentRestDtoModel): void {
        cloneImageRestDtoMetaData(this.image, source.image);
    }
}
