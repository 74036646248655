/**
 * Download a blob as file
 * @param blob
 * @param filename
 */
function downloadBlobAsFile(blob: Blob, filename: string): void
{
    const href = window.URL.createObjectURL(blob);
    const anchorElement = document.createElement('a');

    anchorElement.href = href;
    anchorElement.download = filename;

    document.body.appendChild(anchorElement);
    anchorElement.click();

    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
}

export {
    downloadBlobAsFile,
};
