/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum HorizontalGutterEnum {
    G_0 = 'G_0',
    G_1 = 'G_1',
    G_2 = 'G_2',
    G_3 = 'G_3',
    G_4 = 'G_4',
    G_5 = 'G_5',
}