/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-PIM Management API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { FullProductInfoRestDto } from '../models';
import { NamedTextFragmentRestDto } from '../models';
import { ProductDetailsCoreRestDto } from '../models';
import { TextFragmentRestDto } from '../models';
/**
 * PIMMgmtAPIApi - axios parameter creator
 * @export
 */
export const PIMMgmtAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for creating a new named text fragment for a product.
         * @summary REST.API.PIM.TF.06 Create named text fragment
         * @param {NamedTextFragmentRestDto} body 
         * @param {number} cnk_code The product CNK code that uniquely identifies the product.
         * @param {string} language_code The language in which the product info is requested.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNamedTextFragment: async (body: NamedTextFragmentRestDto, cnk_code: number, language_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createNamedTextFragment.');
            }
            // verify required parameter 'cnk_code' is not null or undefined
            if (cnk_code === null || cnk_code === undefined) {
                throw new RequiredError('cnk_code','Required parameter cnk_code was null or undefined when calling createNamedTextFragment.');
            }
            // verify required parameter 'language_code' is not null or undefined
            if (language_code === null || language_code === undefined) {
                throw new RequiredError('language_code','Required parameter language_code was null or undefined when calling createNamedTextFragment.');
            }
            const localVarPath = `/mgmt/v1/product/by_cnk/{cnk_code}/lang/{language_code}/named_text_fragment`
                .replace(`{${"cnk_code"}}`, encodeURIComponent(String(cnk_code)))
                .replace(`{${"language_code"}}`, encodeURIComponent(String(language_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for creating a new text fragment for a product.
         * @summary REST.API.PIM.TF.05 Create text fragment
         * @param {TextFragmentRestDto} body 
         * @param {number} cnk_code The product CNK code that uniquely identifies the product.
         * @param {string} language_code The language in which the product info is requested.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTextFragment: async (body: TextFragmentRestDto, cnk_code: number, language_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createTextFragment.');
            }
            // verify required parameter 'cnk_code' is not null or undefined
            if (cnk_code === null || cnk_code === undefined) {
                throw new RequiredError('cnk_code','Required parameter cnk_code was null or undefined when calling createTextFragment.');
            }
            // verify required parameter 'language_code' is not null or undefined
            if (language_code === null || language_code === undefined) {
                throw new RequiredError('language_code','Required parameter language_code was null or undefined when calling createTextFragment.');
            }
            const localVarPath = `/mgmt/v1/product/by_cnk/{cnk_code}/lang/{language_code}/text_fragment`
                .replace(`{${"cnk_code"}}`, encodeURIComponent(String(cnk_code)))
                .replace(`{${"language_code"}}`, encodeURIComponent(String(language_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.PIM.TF.04 Delete named text fragment
         * @param {string} id Uniquely identifies the text fragment.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamedTextFragment: async (id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteNamedTextFragment.');
            }
            const localVarPath = `/mgmt/v1/product/{product_code}/pim_details/core`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.PIM.TF.04 Delete named text fragment
         * @param {string} id Uniquely identifies the text fragment.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNamedTextFragment_1: async (id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteNamedTextFragment_1.');
            }
            const localVarPath = `/mgmt/v1/named_text_fragment/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.PIM.TF.02 Delete text fragment
         * @param {string} id Uniquely identifies the text fragment.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTextFragment: async (id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteTextFragment.');
            }
            const localVarPath = `/mgmt/v1/text_fragment/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving the products.
         * @summary REST.API.PIM.PR.01 Find product info
         * @param {number} cnk_code The product CNK code that uniquely identifies the product.
         * @param {string} language_code The language in which the product info is requested.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {string} [x_dp_expect_data_profile] Indication for wich profile the data is requested (determines the fields in the response).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMgmtProductInfo: async (cnk_code: number, language_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, x_dp_expect_data_profile?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cnk_code' is not null or undefined
            if (cnk_code === null || cnk_code === undefined) {
                throw new RequiredError('cnk_code','Required parameter cnk_code was null or undefined when calling findMgmtProductInfo.');
            }
            // verify required parameter 'language_code' is not null or undefined
            if (language_code === null || language_code === undefined) {
                throw new RequiredError('language_code','Required parameter language_code was null or undefined when calling findMgmtProductInfo.');
            }
            const localVarPath = `/mgmt/v1/product/by_cnk/{cnk_code}/lang/{language_code}`
                .replace(`{${"cnk_code"}}`, encodeURIComponent(String(cnk_code)))
                .replace(`{${"language_code"}}`, encodeURIComponent(String(language_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            if (x_dp_expect_data_profile !== undefined && x_dp_expect_data_profile !== null) {
                localVarHeaderParameter['x-dp-expect-data-profile'] = String(x_dp_expect_data_profile);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.PIM.TF.03 Update named text fragment
         * @param {NamedTextFragmentRestDto} body 
         * @param {string} id Uniquely identifies the text fragment.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNamedTextFragment: async (body: NamedTextFragmentRestDto, id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateNamedTextFragment.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateNamedTextFragment.');
            }
            const localVarPath = `/mgmt/v1/named_text_fragment/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.PIM.PR.03 Update core PIM details of a product
         * @param {ProductDetailsCoreRestDto} body 
         * @param {string} product_code The code that uniquely identifies the ordered product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePimDetailsCore: async (body: ProductDetailsCoreRestDto, product_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updatePimDetailsCore.');
            }
            // verify required parameter 'product_code' is not null or undefined
            if (product_code === null || product_code === undefined) {
                throw new RequiredError('product_code','Required parameter product_code was null or undefined when calling updatePimDetailsCore.');
            }
            const localVarPath = `/mgmt/v1/product/{product_code}/pim_details/core`
                .replace(`{${"product_code"}}`, encodeURIComponent(String(product_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.PIM.TF.01 Update text fragment
         * @param {TextFragmentRestDto} body 
         * @param {string} id Uniquely identifies the text fragment.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTextFragment: async (body: TextFragmentRestDto, id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateTextFragment.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateTextFragment.');
            }
            const localVarPath = `/mgmt/v1/text_fragment/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PIMMgmtAPIApi - functional programming interface
 * @export
 */
export const PIMMgmtAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for creating a new named text fragment for a product.
         * @summary REST.API.PIM.TF.06 Create named text fragment
         * @param {NamedTextFragmentRestDto} body 
         * @param {number} cnk_code The product CNK code that uniquely identifies the product.
         * @param {string} language_code The language in which the product info is requested.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNamedTextFragment(_axios: AxiosInstance, body: NamedTextFragmentRestDto, cnk_code: number, language_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NamedTextFragmentRestDto>> {
            const localVarAxiosArgs = await PIMMgmtAPIApiAxiosParamCreator(configuration).createNamedTextFragment(body, cnk_code, language_code, x_dp_customer_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for creating a new text fragment for a product.
         * @summary REST.API.PIM.TF.05 Create text fragment
         * @param {TextFragmentRestDto} body 
         * @param {number} cnk_code The product CNK code that uniquely identifies the product.
         * @param {string} language_code The language in which the product info is requested.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTextFragment(_axios: AxiosInstance, body: TextFragmentRestDto, cnk_code: number, language_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TextFragmentRestDto>> {
            const localVarAxiosArgs = await PIMMgmtAPIApiAxiosParamCreator(configuration).createTextFragment(body, cnk_code, language_code, x_dp_customer_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.PIM.TF.04 Delete named text fragment
         * @param {string} id Uniquely identifies the text fragment.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNamedTextFragment(_axios: AxiosInstance, id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PIMMgmtAPIApiAxiosParamCreator(configuration).deleteNamedTextFragment(id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.PIM.TF.04 Delete named text fragment
         * @param {string} id Uniquely identifies the text fragment.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNamedTextFragment_1(_axios: AxiosInstance, id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PIMMgmtAPIApiAxiosParamCreator(configuration).deleteNamedTextFragment_1(id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.PIM.TF.02 Delete text fragment
         * @param {string} id Uniquely identifies the text fragment.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTextFragment(_axios: AxiosInstance, id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PIMMgmtAPIApiAxiosParamCreator(configuration).deleteTextFragment(id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving the products.
         * @summary REST.API.PIM.PR.01 Find product info
         * @param {number} cnk_code The product CNK code that uniquely identifies the product.
         * @param {string} language_code The language in which the product info is requested.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {string} [x_dp_expect_data_profile] Indication for wich profile the data is requested (determines the fields in the response).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMgmtProductInfo(_axios: AxiosInstance, cnk_code: number, language_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, x_dp_expect_data_profile?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullProductInfoRestDto>> {
            const localVarAxiosArgs = await PIMMgmtAPIApiAxiosParamCreator(configuration).findMgmtProductInfo(cnk_code, language_code, x_dp_customer_code, x_dp_language, x_dp_expect_data_profile, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.PIM.TF.03 Update named text fragment
         * @param {NamedTextFragmentRestDto} body 
         * @param {string} id Uniquely identifies the text fragment.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNamedTextFragment(_axios: AxiosInstance, body: NamedTextFragmentRestDto, id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PIMMgmtAPIApiAxiosParamCreator(configuration).updateNamedTextFragment(body, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.PIM.PR.03 Update core PIM details of a product
         * @param {ProductDetailsCoreRestDto} body 
         * @param {string} product_code The code that uniquely identifies the ordered product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePimDetailsCore(_axios: AxiosInstance, body: ProductDetailsCoreRestDto, product_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PIMMgmtAPIApiAxiosParamCreator(configuration).updatePimDetailsCore(body, product_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.PIM.TF.01 Update text fragment
         * @param {TextFragmentRestDto} body 
         * @param {string} id Uniquely identifies the text fragment.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTextFragment(_axios: AxiosInstance, body: TextFragmentRestDto, id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PIMMgmtAPIApiAxiosParamCreator(configuration).updateTextFragment(body, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PIMMgmtAPIApi - object-oriented interface
 * @export
 * @class PIMMgmtAPIApi
 * @extends {BaseAPI}
 */
export class PIMMgmtAPIApi extends BaseAPI {
    /**
     * API endpoint responsible for creating a new named text fragment for a product.
     * @summary REST.API.PIM.TF.06 Create named text fragment
     * @param {NamedTextFragmentRestDto} body 
     * @param {number} cnk_code The product CNK code that uniquely identifies the product.
     * @param {string} language_code The language in which the product info is requested.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PIMMgmtAPIApi
     */
     public createNamedTextFragment(body: NamedTextFragmentRestDto, cnk_code: number, language_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any) {
        return PIMMgmtAPIApiFp(this.configuration).createNamedTextFragment(this.axios, body, cnk_code, language_code, x_dp_customer_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for creating a new text fragment for a product.
     * @summary REST.API.PIM.TF.05 Create text fragment
     * @param {TextFragmentRestDto} body 
     * @param {number} cnk_code The product CNK code that uniquely identifies the product.
     * @param {string} language_code The language in which the product info is requested.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PIMMgmtAPIApi
     */
     public createTextFragment(body: TextFragmentRestDto, cnk_code: number, language_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any) {
        return PIMMgmtAPIApiFp(this.configuration).createTextFragment(this.axios, body, cnk_code, language_code, x_dp_customer_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.PIM.TF.04 Delete named text fragment
     * @param {string} id Uniquely identifies the text fragment.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PIMMgmtAPIApi
     */
     public deleteNamedTextFragment(id: string, x_dp_customer_code?: string|null, options?: any) {
        return PIMMgmtAPIApiFp(this.configuration).deleteNamedTextFragment(this.axios, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.PIM.TF.04 Delete named text fragment
     * @param {string} id Uniquely identifies the text fragment.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PIMMgmtAPIApi
     */
     public deleteNamedTextFragment_1(id: string, x_dp_customer_code?: string|null, options?: any) {
        return PIMMgmtAPIApiFp(this.configuration).deleteNamedTextFragment_1(this.axios, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.PIM.TF.02 Delete text fragment
     * @param {string} id Uniquely identifies the text fragment.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PIMMgmtAPIApi
     */
     public deleteTextFragment(id: string, x_dp_customer_code?: string|null, options?: any) {
        return PIMMgmtAPIApiFp(this.configuration).deleteTextFragment(this.axios, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving the products.
     * @summary REST.API.PIM.PR.01 Find product info
     * @param {number} cnk_code The product CNK code that uniquely identifies the product.
     * @param {string} language_code The language in which the product info is requested.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {string} [x_dp_expect_data_profile] Indication for wich profile the data is requested (determines the fields in the response).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PIMMgmtAPIApi
     */
     public findMgmtProductInfo(cnk_code: number, language_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, x_dp_expect_data_profile?: string|null, options?: any) {
        return PIMMgmtAPIApiFp(this.configuration).findMgmtProductInfo(this.axios, cnk_code, language_code, x_dp_customer_code, x_dp_language, x_dp_expect_data_profile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.PIM.TF.03 Update named text fragment
     * @param {NamedTextFragmentRestDto} body 
     * @param {string} id Uniquely identifies the text fragment.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PIMMgmtAPIApi
     */
     public updateNamedTextFragment(body: NamedTextFragmentRestDto, id: string, x_dp_customer_code?: string|null, options?: any) {
        return PIMMgmtAPIApiFp(this.configuration).updateNamedTextFragment(this.axios, body, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.PIM.PR.03 Update core PIM details of a product
     * @param {ProductDetailsCoreRestDto} body 
     * @param {string} product_code The code that uniquely identifies the ordered product.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PIMMgmtAPIApi
     */
     public updatePimDetailsCore(body: ProductDetailsCoreRestDto, product_code: string, x_dp_customer_code?: string|null, options?: any) {
        return PIMMgmtAPIApiFp(this.configuration).updatePimDetailsCore(this.axios, body, product_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.PIM.TF.01 Update text fragment
     * @param {TextFragmentRestDto} body 
     * @param {string} id Uniquely identifies the text fragment.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PIMMgmtAPIApi
     */
     public updateTextFragment(body: TextFragmentRestDto, id: string, x_dp_customer_code?: string|null, options?: any) {
        return PIMMgmtAPIApiFp(this.configuration).updateTextFragment(this.axios, body, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
