/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Master Digital Pharma Dashboard API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { MonthlyDashboardResultsRestDto } from '../models';
import { MonthlyDashboardTypeResultsRestDto } from '../models';
/**
 * DashboardAPIApi - axios parameter creator
 * @export
 */
export const DashboardAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.DBT.01 Retrieve the list of monthly dashboard types.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMonthlyDashboardTypes: async (x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/v1/monthly_dashboard_type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * By default this API returns data of the current year and the previous year.
         * @summary REST.API.DB.01 Retrieve the monthly dashboard data.
         * @param {string} dashboard_type_code The unique identifier of the dashboard type.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {string} [x_dp_module_code] The unique identification of the Digital-Pharma module.
         * @param {string} [search_customer_name] Filter the results based on the customer&#x27;s name.
         * @param {string} [filter_customer_code] Filter the results based on the customer&#x27;s code. (Required, except for DP_00000, thus Digital-Pharma itself)
         * @param {boolean} [filter_customer_groups] Filter the results to customer groups and sum the data of the group members together at group level.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMonthlyDashboardsForType: async (dashboard_type_code: string, x_dp_customer_code?: string, x_dp_module_code?: string, search_customer_name?: string, filter_customer_code?: string, filter_customer_groups?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dashboard_type_code' is not null or undefined
            if (dashboard_type_code === null || dashboard_type_code === undefined) {
                throw new RequiredError('dashboard_type_code','Required parameter dashboard_type_code was null or undefined when calling findMonthlyDashboardsForType.');
            }
            const localVarPath = `/dashboard/v1/monthly_dashboard/{dashboard_type_code}`
                .replace(`{${"dashboard_type_code"}}`, encodeURIComponent(String(dashboard_type_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!search_customer_name || typeof(search_customer_name) === "boolean") {
                localVarQueryParameter['search_customer_name'] = search_customer_name;
            }

            if (!!filter_customer_code || typeof(filter_customer_code) === "boolean") {
                localVarQueryParameter['filter_customer_code'] = filter_customer_code;
            }

            if (!!filter_customer_groups || typeof(filter_customer_groups) === "boolean") {
                localVarQueryParameter['filter_customer_groups'] = filter_customer_groups;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_module_code !== undefined && x_dp_module_code !== null) {
                localVarHeaderParameter['x-dp-module-code'] = String(x_dp_module_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardAPIApi - functional programming interface
 * @export
 */
export const DashboardAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.DBT.01 Retrieve the list of monthly dashboard types.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMonthlyDashboardTypes(_axios: AxiosInstance, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonthlyDashboardTypeResultsRestDto>> {
            const localVarAxiosArgs = await DashboardAPIApiAxiosParamCreator(configuration).findMonthlyDashboardTypes(x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * By default this API returns data of the current year and the previous year.
         * @summary REST.API.DB.01 Retrieve the monthly dashboard data.
         * @param {string} dashboard_type_code The unique identifier of the dashboard type.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {string} [x_dp_module_code] The unique identification of the Digital-Pharma module.
         * @param {string} [search_customer_name] Filter the results based on the customer&#x27;s name.
         * @param {string} [filter_customer_code] Filter the results based on the customer&#x27;s code. (Required, except for DP_00000, thus Digital-Pharma itself)
         * @param {boolean} [filter_customer_groups] Filter the results to customer groups and sum the data of the group members together at group level.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMonthlyDashboardsForType(_axios: AxiosInstance, dashboard_type_code: string, x_dp_customer_code?: string, x_dp_module_code?: string, search_customer_name?: string, filter_customer_code?: string, filter_customer_groups?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonthlyDashboardResultsRestDto>> {
            const localVarAxiosArgs = await DashboardAPIApiAxiosParamCreator(configuration).findMonthlyDashboardsForType(dashboard_type_code, x_dp_customer_code, x_dp_module_code, search_customer_name, filter_customer_code, filter_customer_groups, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DashboardAPIApi - object-oriented interface
 * @export
 * @class DashboardAPIApi
 * @extends {BaseAPI}
 */
export class DashboardAPIApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.DBT.01 Retrieve the list of monthly dashboard types.
     * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardAPIApi
     */
     public findMonthlyDashboardTypes(x_dp_customer_code?: string, options?: any) {
        return DashboardAPIApiFp(this.configuration).findMonthlyDashboardTypes(this.axios, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * By default this API returns data of the current year and the previous year.
     * @summary REST.API.DB.01 Retrieve the monthly dashboard data.
     * @param {string} dashboard_type_code The unique identifier of the dashboard type.
     * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
     * @param {string} [x_dp_module_code] The unique identification of the Digital-Pharma module.
     * @param {string} [search_customer_name] Filter the results based on the customer&#x27;s name.
     * @param {string} [filter_customer_code] Filter the results based on the customer&#x27;s code. (Required, except for DP_00000, thus Digital-Pharma itself)
     * @param {boolean} [filter_customer_groups] Filter the results to customer groups and sum the data of the group members together at group level.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardAPIApi
     */
     public findMonthlyDashboardsForType(dashboard_type_code: string, x_dp_customer_code?: string, x_dp_module_code?: string, search_customer_name?: string, filter_customer_code?: string, filter_customer_groups?: boolean, options?: any) {
        return DashboardAPIApiFp(this.configuration).findMonthlyDashboardsForType(this.axios, dashboard_type_code, x_dp_customer_code, x_dp_module_code, search_customer_name, filter_customer_code, filter_customer_groups, options).then((request) => request(this.axios, this.basePath));
    }

}
