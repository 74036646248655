<template>
  <div class="card-header d-flex align-items-center justify-content-between">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BaseCardHeader",
};
</script>