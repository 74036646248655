import {v4 as uuidv4} from 'uuid';
import {customAlphabet} from "nanoid";

const nanoidForDOM = customAlphabet('abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', 10)

/**
 * Generates a random UUID
 */
function generateUuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

/**
 * Generate UUIDv4 based on official library with optional prefix
 * @param prefix
 */
function generateUUIDv4(prefix?: string): string {
    return (prefix ?? '') + uuidv4();
}

function generateDomId(prefix?: string, size: number = 10): string {
    return (prefix ?? '') + nanoidForDOM(size);
}

/*
* Receives a string and converts it to a slug format
*/
function slugify(string: string): string {
    return string
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');
}

function isString(value: unknown): value is string {
    return typeof value === "string";
}

function isEmptyString(value: unknown): boolean {
    return !isString(value) || value.trim().length === 0;
}

/*
* Checks if a string contains content
* Receives a possible string
* Returns a boolean
*/
function stringHasContent(str?: string | null): boolean {
    return (typeof str !== 'undefined' && str !== undefined && str !== null && str.length > 0);
}

/*
* Receives a string with HTML tags and optional array of excluded tags
* And converts it to a string without HTML tags
*/
function stripHtmlTags(originalString: string, excludedTags?: string[]): string {
    //TODO find a way to exclude tags
    return originalString.replace(/(<([^>]+)>)/gi, "");
}

/*
* Capitalizes the first letter of every word of a string.
*/
function toInitCapitalize(string: string): string {
    const words = string.split(" ") as string[];

    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(" ");
}

/*
* Truncates a html string to a maximum length. If it is longer than the max length, ... will be added to the end.
*/
function truncateHtml(html: string, max_length: number): string {
    if (html.length > max_length) {
        const lengthWithTags = html.length as number;
        const lengthWithoutTags = stripHtmlTags(html).length as number;

        if (lengthWithoutTags > max_length) {
            //make substring of max length + length of tags
            html = html.substr(0, max_length + (lengthWithTags - lengthWithoutTags));

            //find last occurrence of < or & and remove everything from this pos to the end
            let posChar = -1 as number;
            posChar = html.lastIndexOf('<');
            posChar = (html.lastIndexOf('&') > posChar) ? html.lastIndexOf('&') : posChar;
            if (posChar > max_length) {
                html = html.substr(0, posChar);
            }

            html += '...</p>';
        }
    }

    return html;
}

/*
* Truncates a string to a maximum length. If it is longer than the max length, ... will be added to the end.
*/
function truncateString(str: string | null, max_length: number): string | null {
    if (str && str.length > max_length) {
        return str.substr(0, max_length) + '...';
    }
    return str;
}


export {
    generateUuid,
    generateUUIDv4,
    generateDomId,
    slugify,
    stringHasContent,
    stripHtmlTags,
    toInitCapitalize,
    truncateHtml,
    truncateString,
    isString,
    isEmptyString,
};
