import {
    BannerContentV2RestDto,
    ButtonContentRestDto,
    ButtonRestDto,
    DocumentContentRestDto,
    HtmlContentRestDto,
    ImageContentRestDto,
    ImageRestDto,
    PageReferencesContentRestDto,
    ProductFilterContentRestDto,
    RowSeparatorContentRestDto,
    SimpleButtonContentRestDto,
    SimpleDocumentContentRestDto,
    SimpleImageContentRestDto,
    SimpleTextContentRestDto,
    SimpleTextWithImageContentRestDto,
    SimpleTextWithTitleContentRestDto,
    TextContentRestDto,
    TextWithImageContentRestDto,
    TextWithTitleContentRestDto
} from "@/api/pharma-cms-content-block/models";
import {emptyMultilingualTextField} from "@/helpers/functions/multilingual";
import {ButtonTargetEnum} from "@/api/enums/button-target-enum";
import {ImageWidthEnum} from "@/api/enums/image-width-enum";
import {BorderTypeEnum} from "@/api/enums/border-type-enum";
import {StatusEnum} from "@/api/enums/status-enum";
import {TitleSizeEnum} from "@/api/enums/title-size-enum";
import {HeightEnum} from "@/api/enums/height-enum";
import {BackgroundColorEnum} from "@/api/enums/background-color-enum";
import {PageReferencesContentLayoutEnum} from "@/api/enums/page-references-content-layout-enum";
import {BannerContentV2LinkTypeEnum} from "@/api/enums/banner-content-v2-link-type-enum";
import {HorizontalImageShiftEnum} from "@/api/enums/horizontal-image-shift-enum";
import {VerticalImageShiftEnum} from "@/api/enums/vertical-image-shift-enum";
import {ButtonHorizontalPositionEnum} from "@/api/enums/button-horizontal-position-enum";
import {LightboxLayoutEnum} from "@/api/enums/lightbox-layout-enum";

function emptyButton(): ButtonRestDto {
    return {
        button_target: ButtonTargetEnum.BLANK,
        button_url: emptyMultilingualTextField(),
        button_text: emptyMultilingualTextField(),
        button_page_id: emptyMultilingualTextField(),
        button_layout_code: undefined,
    };
}

function emptyImage(): ImageRestDto {
    return {
        title: emptyMultilingualTextField(),
        image_key: emptyMultilingualTextField(),
        image_target: undefined,
        image_url: emptyMultilingualTextField(),
        image_page_id: emptyMultilingualTextField(),
        image_width_code: ImageWidthEnum.W_AUTO,
        lightbox_layout_code: LightboxLayoutEnum.NONE,
        border_type_code: BorderTypeEnum.NONE,
        border_width_code: undefined,
    };
}

function emptySimpleContentBlockButton(): SimpleButtonContentRestDto {
    const emptyCbButton = {
        status_code: StatusEnum.PUBLISHED,
        button: emptyButton()
    }
    emptyCbButton.button.button_target = ButtonTargetEnum.SELF;

    return emptyCbButton;
}

function emptySimpleContentBlockImage(): SimpleImageContentRestDto {
    return {
        //TODO: add enum for SimpleContentBlockImage
        status_code: StatusEnum.PUBLISHED,
        image_key: {
            nl: '',
            fr: '',
            en: '',
            de: '',
        },
    };
}

function emptySimpleContentBlockDocument(): SimpleDocumentContentRestDto {
    return {
        //TODO: add enum for SimpleContentBlockDocument
        status_code: StatusEnum.PUBLISHED,
        document_key: {
            nl: '',
            fr: '',
            en: '',
            de: '',
        },
    };
}

function emptySimpleContentBlockText(): SimpleTextContentRestDto {
    return {
        status_code: StatusEnum.PUBLISHED,
        content: {
            nl: '',
            fr: '',
            en: '',
            de: '',
        }
    };
}

function emptySimpleContentBlockTextWithImage(): SimpleTextWithImageContentRestDto {
    return {
        status_code: StatusEnum.NOT_PUBLISHED,
        title: {
            nl: '',
            fr: '',
            en: '',
            de: '',
        },
        content: {
            nl: '',
            fr: '',
            en: '',
            de: '',
        },
        image_key: {
            nl: null,
            de: null,
            en: null,
            fr: null
        },
        button: emptyButton(),
        horizontal_image_shift_code:HorizontalImageShiftEnum.NO_SHIFT,
        vertical_image_shift_code:VerticalImageShiftEnum.NO_SHIFT,
        button_horizontal_position_code:ButtonHorizontalPositionEnum.LEFT
    };
}

function emptySimpleContentBlockTextWithTitle(): SimpleTextWithTitleContentRestDto {
    return {
        status_code: StatusEnum.PUBLISHED,
        title: {
            nl: '',
            fr: '',
            en: '',
            de: '',
        },
        content: {
            nl: '',
            fr: '',
            en: '',
            de: '',
        }
    };
}

function emptyNormalContentBlockBanner(): BannerContentV2RestDto {
    return {
        status_code: StatusEnum.PUBLISHED,
        banner_images: [],
        description: emptyMultilingualTextField(),
        product_filter_code: null,
        ext_link_url: emptyMultilingualTextField(),
        internal_page_id: emptyMultilingualTextField(),
        page_ref_target: undefined,
        category_code: undefined,
        begin_date: undefined,
        end_date: undefined,
        link_type_code: BannerContentV2LinkTypeEnum.NO_LINK,
    };
}

function emptyNormalContentBlockButton(): ButtonContentRestDto {
    return {
        status_code: StatusEnum.PUBLISHED,
        button: emptyButton(),
    };
}

function emptyNormalContentBlockDocument(): DocumentContentRestDto {
    return {
        status_code: StatusEnum.PUBLISHED,
        title: emptyMultilingualTextField(),
        content: emptyMultilingualTextField(),
        document_key: '',
        button_text: undefined,
        button_layout_code: undefined,
        is_show_file_details: true,
    };
}

function emptyNormalContentBlockImage(): ImageContentRestDto {
    return {
        status_code: StatusEnum.PUBLISHED,
        image_key: emptyMultilingualTextField(),
        image: emptyImage(),
    };
}

function emptyNormalContentBlockPageReferences(): PageReferencesContentRestDto {
    return {
        status_code: StatusEnum.PUBLISHED,
        layout_code: PageReferencesContentLayoutEnum.GRID_1XN,
        page_type_code: undefined,
        page_abstr_lib_code: undefined,
    };
}

function emptyNormalContentBlockProductFilter(): ProductFilterContentRestDto {
    return {
        status_code: StatusEnum.PUBLISHED,
        title: undefined,
        content: undefined,
        title_size_code: TitleSizeEnum.H2,
        product_filter_code: null,
    };
}

function emptyNormalContentBlockRowSeparator(): RowSeparatorContentRestDto {
    return {
        status_code: StatusEnum.PUBLISHED,
        height_code: HeightEnum.M,
        background_color_code: BackgroundColorEnum.GREY,
    };
}

function emptyNormalContentBlockText(): TextContentRestDto {
    return {
        status_code: StatusEnum.PUBLISHED,
        content: {
            nl: '',
            fr: '',
            en: '',
            de: ''
        },
    };
}

function emptyNormalContentBlockTextWithImage(): TextWithImageContentRestDto {
    return {
        status_code: StatusEnum.PUBLISHED,
        layout_code: undefined,
        title_size_code: undefined,
        title: emptyMultilingualTextField(),
        content: emptyMultilingualTextField(),
        image_key: emptyMultilingualTextField(),
        image: emptyImage(),
        button: emptyButton(),
        horizontal_image_shift_code:HorizontalImageShiftEnum.NO_SHIFT,
        vertical_image_shift_code:VerticalImageShiftEnum.NO_SHIFT,
        button_horizontal_position_code:ButtonHorizontalPositionEnum.LEFT
    };
}

function emptyNormalContentBlockTextWithTitle(): TextWithTitleContentRestDto {
    return {
        status_code: StatusEnum.PUBLISHED,
        title_size_code: undefined,
        title: emptyMultilingualTextField(),
        content: emptyMultilingualTextField(),
    };
}

function emptyNormalContentBlockHtml(): HtmlContentRestDto {
    return {
        status_code: StatusEnum.PUBLISHED,
        content: ''
    };
}

export {
    emptyButton,
    emptyImage,
    emptySimpleContentBlockButton,
    emptyNormalContentBlockDocument,
    emptySimpleContentBlockImage,
    emptySimpleContentBlockDocument,
    emptySimpleContentBlockText,
    emptySimpleContentBlockTextWithImage,
    emptySimpleContentBlockTextWithTitle,
    emptyNormalContentBlockBanner,
    emptyNormalContentBlockButton,
    emptyNormalContentBlockImage,
    emptyNormalContentBlockPageReferences,
    emptyNormalContentBlockProductFilter,
    emptyNormalContentBlockRowSeparator,
    emptyNormalContentBlockText,
    emptyNormalContentBlockTextWithImage,
    emptyNormalContentBlockTextWithTitle,
    emptyNormalContentBlockHtml
};
