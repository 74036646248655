import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mb-4" }
const _hoisted_3 = { class: "button mb-4" }
const _hoisted_4 = { class: "row row-layout mb-4" }
const _hoisted_5 = { class: "col-auto" }
const _hoisted_6 = { class: "row row-text align-items-center mb-4" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "row row-link" }
const _hoisted_9 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_InputSelect = _resolveComponent("InputSelect")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_PageLinkPicker = _resolveComponent("PageLinkPicker")!
  const _component_dp_form = _resolveComponent("dp-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseSpinner, {
      spin: !this.isReady
    }, null, 8, ["spin"]),
    (this.isReady)
      ? (_openBlock(), _createBlock(_component_dp_form, {
          key: 0,
          as: "form",
          onSubmit: _ctx.submitData,
          novalidate: ""
        }, {
          default: _withCtx(({ errors }) => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("section", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_InputSelect, {
                        modelValue: this.contentBlock.button.button_layout_code,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.contentBlock.button.button_layout_code) = $event)),
                        options: this.btnLayoutOptions,
                        label: _ctx.$t('layout'),
                        name: "buttonLayout",
                        selectText: _ctx.$t('selectText'),
                        errors: errors
                      }, null, 8, ["modelValue", "options", "label", "selectText", "errors"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_InputText, {
                        modelValue: this.contentBlock.button.button_text.nl,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.contentBlock.button.button_text.nl) = $event)),
                        label: _ctx.$t('buttonText'),
                        name: "buttonText",
                        placeholder: "buttonText",
                        rules: "required|max:100",
                        errors: errors
                      }, null, 8, ["modelValue", "label", "errors"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_PageLinkPicker, {
                        "enable-no-link": this.cbType !== 'PAGE_CONTENT_CALL_TO_ACTION',
                        payload: this.pageLinkPayload,
                        errors: errors,
                        onChanged: this.onPageLinkPickerChanged
                      }, null, 8, ["enable-no-link", "payload", "errors", "onChanged"])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["onSubmit"]))
      : _createCommentVNode("", true)
  ], 64))
}