import Axios from "@/models/facade/Axios";
import {AxiosInstance} from "axios";
import {Service} from "@/services/Service";

export class RestService extends Service {

    protected constructor() {
        super();
    }

    protected getDpAxiosInstance(): AxiosInstance {
        return Axios.getInstance().getDpAxiosInstance();
    }

    protected getDpAxiosV2Instance(): AxiosInstance {
        return Axios.getInstance().getDpAxiosV2Instance();
    }

    protected getAxiosInstance(): AxiosInstance {
        return Axios.getInstance().getAxiosInstance();
    }

    protected getApiGwUrl(basePath: string): string {
        basePath = basePath.startsWith('/') ? basePath.slice(1) : basePath;

        return "https://" + this.getConfig().apiGwHost + '/' + basePath;
    }
}
