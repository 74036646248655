import {PaymentProviderSettingsRestDto} from "@/api/pharma-pay-payment/models";

export class PaymentProviderSettingsRestDtoModel implements PaymentProviderSettingsRestDto {
    auth_token: string;
    is_active: boolean;

    constructor(auth_token: string, is_active: boolean) {
        this.auth_token = auth_token;
        this.is_active = is_active;
    }

    public static createWithDefaults(): PaymentProviderSettingsRestDtoModel {
        return new PaymentProviderSettingsRestDtoModel('', false);
    }
}
