/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Pharmacy API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { OpeningHoursForWeekRestDto } from '../models';
/**
 * OpeningInfoStandardOpeningHoursEndpointsApi - axios parameter creator
 * @export
 */
export const OpeningInfoStandardOpeningHoursEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.PH.OH.16 Find Standard Opening Hours.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Closing Period belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStandardOpeningHours: async (pharmacy_code: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling findStandardOpeningHours.');
            }
            const localVarPath = `/pharmacy/v1/pharmacy/{pharmacy_code}/standard_opening_hours`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.OH.17 Update Standard Opening Hours.
         * @param {OpeningHoursForWeekRestDto} body 
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Closing Period belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStandardOpeningHours: async (body: OpeningHoursForWeekRestDto, pharmacy_code: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateStandardOpeningHours.');
            }
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling updateStandardOpeningHours.');
            }
            const localVarPath = `/pharmacy/v1/pharmacy/{pharmacy_code}/standard_opening_hours`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpeningInfoStandardOpeningHoursEndpointsApi - functional programming interface
 * @export
 */
export const OpeningInfoStandardOpeningHoursEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.PH.OH.16 Find Standard Opening Hours.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Closing Period belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findStandardOpeningHours(_axios: AxiosInstance, pharmacy_code: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpeningHoursForWeekRestDto>>> {
            const localVarAxiosArgs = await OpeningInfoStandardOpeningHoursEndpointsApiAxiosParamCreator(configuration).findStandardOpeningHours(pharmacy_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.OH.17 Update Standard Opening Hours.
         * @param {OpeningHoursForWeekRestDto} body 
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Closing Period belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStandardOpeningHours(_axios: AxiosInstance, body: OpeningHoursForWeekRestDto, pharmacy_code: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OpeningInfoStandardOpeningHoursEndpointsApiAxiosParamCreator(configuration).updateStandardOpeningHours(body, pharmacy_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OpeningInfoStandardOpeningHoursEndpointsApi - object-oriented interface
 * @export
 * @class OpeningInfoStandardOpeningHoursEndpointsApi
 * @extends {BaseAPI}
 */
export class OpeningInfoStandardOpeningHoursEndpointsApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.CMS.PH.OH.16 Find Standard Opening Hours.
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Closing Period belongs.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpeningInfoStandardOpeningHoursEndpointsApi
     */
     public findStandardOpeningHours(pharmacy_code: string, x_dp_customer_code?: string, options?: any) {
        return OpeningInfoStandardOpeningHoursEndpointsApiFp(this.configuration).findStandardOpeningHours(this.axios, pharmacy_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.PH.OH.17 Update Standard Opening Hours.
     * @param {OpeningHoursForWeekRestDto} body 
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Closing Period belongs.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpeningInfoStandardOpeningHoursEndpointsApi
     */
     public updateStandardOpeningHours(body: OpeningHoursForWeekRestDto, pharmacy_code: string, x_dp_customer_code?: string, options?: any) {
        return OpeningInfoStandardOpeningHoursEndpointsApiFp(this.configuration).updateStandardOpeningHours(this.axios, body, pharmacy_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
