
import {defineComponent} from "vue";
import BaseModalButton from "@/components/UI/Bootstrap/Modal/BaseModalButton.vue";
import BaseContentLibraryAvailable from "@/components/UI/BaseContentLibraryAvailable.vue";
import ContentBlocksOverviewItem from "@/components/layouts/content-blocks/overview/CbsOverviewItem.vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";

export default defineComponent({
  name: "CbsOverview",
  components: {BaseTitle, ContentBlocksOverviewItem, BaseContentLibraryAvailable, BaseModalButton},
  emits: ["copyContentBlock", "copiedLibCb", "deleteContentBlock", "refresh"],
  props: {
    contentBlocks: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    },
    contentType: {
      type: String,
      required: true,
    },
    cbType: {
      type: String,
      required: false,
    },
    srcLibCode: {
      type: String,
      required: false,
      default: null
    },
    canCopyItem: {
      type: Boolean,
      required: false,
      default: false,
    },
    canPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
    canUseLibrary: {
      type: Boolean,
      required: false,
      default: false,
    },
    isReady: {
      type: Boolean,
      required: true,
    },
    hasContentBlocks: {
      type: Boolean,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    modalId: {
      type: String,
      required: false,
      default: '',
    },
    routeObjectBack: {
      type: Object,
      required: false,
      default() {
        return null;
      }
    },
    routeObjectEditCb: {
      type: Object,
      required: false,
      default() {
        return null;
      }
    },
    routeObjectNewCb: {
      type: Object,
      required: false,
      default() {
        return null;
      }
    },
    tableHeaderItems: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    }
  },
  computed: {
    canUseModal(): boolean {
      return (this.modalId.length > 0);
    },
    isLibrary(): boolean {
      return !!this.srcLibCode;
    }
  },
  methods: {
    copyContentBlock(cbId: string) {
      this.$emit('copyContentBlock', cbId);
    },
    deleteContentBlock(cbId: string) {
      this.$emit('deleteContentBlock', cbId);
    },
  },
});
