import {RestService} from "@/services/rest/RestService";
import {ContentPageEndpointsApi} from "@/api/pharma-cms-content-page";
import {ValueEnum} from "@/api/enums/value-enum";

export class ContentPageRestService extends RestService {

    private apiService: ContentPageEndpointsApi;

    protected constructor() {
        super();

        this.apiService = new ContentPageEndpointsApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cmsUrl);
    }

    public async updateIsPublishedOnHome(isPublishedOnHome: boolean, pageId: string): Promise<void> {
        await this.apiService.updateIsPublishedOnHome({value: isPublishedOnHome}, pageId);
    }

    public async updatePageStatusCode(status: ValueEnum, pageId: string): Promise<void> {
        await this.apiService.updatePageStatusCode({value: status}, pageId);
    }
}
