
import {defineComponent} from "vue";
import {stringHasContent} from "@/helpers/functions/string";
import {TargetEnum} from "@/api/enums/target-enum";

export default defineComponent({
  name: "MenusOverviewItemTarget",
  props: {
    target: {
      type: String,
      required: true,
    }
  },
  computed: {
    targetValue(): string {
      if (stringHasContent(this.target)) {
        switch (this.target) {
          case TargetEnum.SELF:
            return this.$t('currentWindow');
          case TargetEnum.BLANK:
            return this.$t('newWindow');
        }
      }

      return '/';
    },
  },
});
