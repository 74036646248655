/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Header API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CmsHeaderContent0RestDto } from '../models';
import { CmsHeaderContent1RestDto } from '../models';
import { HeaderOverviewResultsRestDto } from '../models';
/**
 * HeaderEndpointsApi - axios parameter creator
 * @export
 */
export const HeaderEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.HEAD.04 Get header of type header_content_0.
         * @param {string} id Uniquely identefies the footer content to update.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHeaderContent0: async (id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getHeaderContent0.');
            }
            const localVarPath = `/header/v1/header_content_0/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.HEAD.02 Get header of type header_content_1.
         * @param {string} id Uniquely identefies the footer content to update.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHeaderContent1: async (id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getHeaderContent1.');
            }
            const localVarPath = `/header/v1/header_content_1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.HEAD.01 Get an overview of the header stack.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHeaderStackOverview: async (x_dp_customer_code?: string, x_dp_language?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/header/v1/header/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.HEAD.05 Update header of type header_content_0.
         * @param {CmsHeaderContent0RestDto} body 
         * @param {string} id Uniquely identefies the footer content to update.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHeaderContent0: async (body: CmsHeaderContent0RestDto, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateHeaderContent0.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateHeaderContent0.');
            }
            const localVarPath = `/header/v1/header_content_0/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.HEAD.03 Update header of type header_content_1.
         * @param {CmsHeaderContent1RestDto} body 
         * @param {string} id Uniquely identefies the footer content to update.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHeaderContent1: async (body: CmsHeaderContent1RestDto, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateHeaderContent1.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateHeaderContent1.');
            }
            const localVarPath = `/header/v1/header_content_1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HeaderEndpointsApi - functional programming interface
 * @export
 */
export const HeaderEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.HEAD.04 Get header of type header_content_0.
         * @param {string} id Uniquely identefies the footer content to update.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHeaderContent0(_axios: AxiosInstance, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CmsHeaderContent0RestDto>> {
            const localVarAxiosArgs = await HeaderEndpointsApiAxiosParamCreator(configuration).getHeaderContent0(id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.HEAD.02 Get header of type header_content_1.
         * @param {string} id Uniquely identefies the footer content to update.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHeaderContent1(_axios: AxiosInstance, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CmsHeaderContent1RestDto>> {
            const localVarAxiosArgs = await HeaderEndpointsApiAxiosParamCreator(configuration).getHeaderContent1(id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.HEAD.01 Get an overview of the header stack.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHeaderStackOverview(_axios: AxiosInstance, x_dp_customer_code?: string, x_dp_language?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HeaderOverviewResultsRestDto>> {
            const localVarAxiosArgs = await HeaderEndpointsApiAxiosParamCreator(configuration).getHeaderStackOverview(x_dp_customer_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.HEAD.05 Update header of type header_content_0.
         * @param {CmsHeaderContent0RestDto} body 
         * @param {string} id Uniquely identefies the footer content to update.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHeaderContent0(_axios: AxiosInstance, body: CmsHeaderContent0RestDto, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await HeaderEndpointsApiAxiosParamCreator(configuration).updateHeaderContent0(body, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.HEAD.03 Update header of type header_content_1.
         * @param {CmsHeaderContent1RestDto} body 
         * @param {string} id Uniquely identefies the footer content to update.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHeaderContent1(_axios: AxiosInstance, body: CmsHeaderContent1RestDto, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await HeaderEndpointsApiAxiosParamCreator(configuration).updateHeaderContent1(body, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * HeaderEndpointsApi - object-oriented interface
 * @export
 * @class HeaderEndpointsApi
 * @extends {BaseAPI}
 */
export class HeaderEndpointsApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.CMS.HEAD.04 Get header of type header_content_0.
     * @param {string} id Uniquely identefies the footer content to update.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeaderEndpointsApi
     */
     public getHeaderContent0(id: string, x_dp_customer_code?: string, options?: any) {
        return HeaderEndpointsApiFp(this.configuration).getHeaderContent0(this.axios, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.HEAD.02 Get header of type header_content_1.
     * @param {string} id Uniquely identefies the footer content to update.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeaderEndpointsApi
     */
     public getHeaderContent1(id: string, x_dp_customer_code?: string, options?: any) {
        return HeaderEndpointsApiFp(this.configuration).getHeaderContent1(this.axios, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.HEAD.01 Get an overview of the header stack.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeaderEndpointsApi
     */
     public getHeaderStackOverview(x_dp_customer_code?: string, x_dp_language?: string, options?: any) {
        return HeaderEndpointsApiFp(this.configuration).getHeaderStackOverview(this.axios, x_dp_customer_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.HEAD.05 Update header of type header_content_0.
     * @param {CmsHeaderContent0RestDto} body 
     * @param {string} id Uniquely identefies the footer content to update.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeaderEndpointsApi
     */
     public updateHeaderContent0(body: CmsHeaderContent0RestDto, id: string, x_dp_customer_code?: string, options?: any) {
        return HeaderEndpointsApiFp(this.configuration).updateHeaderContent0(this.axios, body, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.HEAD.03 Update header of type header_content_1.
     * @param {CmsHeaderContent1RestDto} body 
     * @param {string} id Uniquely identefies the footer content to update.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Menu Endpoints
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeaderEndpointsApi
     */
     public updateHeaderContent1(body: CmsHeaderContent1RestDto, id: string, x_dp_customer_code?: string, options?: any) {
        return HeaderEndpointsApiFp(this.configuration).updateHeaderContent1(this.axios, body, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
