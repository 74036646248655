import {NormalContentBlockModel} from "@/models/api/pharma-cms-content-block/NormalContentBlockModel";
import {
    clonePharmacyInfoContentRestDtoMetaData,
    ContentBlockMetadataRestDto,
    PharmacyInfoContentRestDto
} from "@/api/pharma-cms-content-block/models";
import {StatusEnum} from "@/api/enums/status-enum";
import {PharmacyInfoContentLayoutEnum} from "@/api/enums/pharmacy-info-content-layout-enum";
import {TextWithImageContentRestDtoModel} from "@/models/api/pharma-cms-content-block/TextWithImageContentRestDtoModel";
import {BackgroundColorEnum} from "@/api/enums/background-color-enum";

export class PharmacyInfoContentRestDtoModel extends NormalContentBlockModel implements PharmacyInfoContentRestDto {
    background_color_code?: BackgroundColorEnum;
    cb_metadata?: ContentBlockMetadataRestDto;
    pharmacy_code?: string | null;
    is_show_apb_number: boolean;
    is_show_contactinfo: boolean;
    is_show_companyinfo: boolean;
    is_show_openinghours_title: boolean;
    is_show_openinghours_today: boolean;
    is_show_openinghours_week: boolean;
    is_show_pharmacy_holder: boolean;
    is_show_social_media: boolean;
    layout_code: PharmacyInfoContentLayoutEnum;
    sort_weight?: number;
    status_code: StatusEnum;
    twi?: TextWithImageContentRestDtoModel;

    constructor(
        is_show_apb_number: boolean,
        is_show_contactinfo: boolean,
        is_show_companyinfo: boolean,
        is_show_openinghours_title: boolean,
        is_show_openinghours_today: boolean,
        is_show_openinghours_week: boolean,
        is_show_pharmacy_holder: boolean,
        is_show_social_media: boolean,
        layout_code: PharmacyInfoContentLayoutEnum,
        status_code: StatusEnum
    ) {
        super();
        this.is_show_apb_number = is_show_apb_number;
        this.is_show_contactinfo = is_show_contactinfo;
        this.is_show_companyinfo = is_show_companyinfo;
        this.is_show_openinghours_title = is_show_openinghours_title;
        this.is_show_openinghours_today = is_show_openinghours_today;
        this.is_show_openinghours_week = is_show_openinghours_week;
        this.is_show_pharmacy_holder = is_show_pharmacy_holder;
        this.is_show_social_media = is_show_social_media;
        this.layout_code = layout_code;
        this.status_code = status_code;
    }

    public static createWithDefaults(): PharmacyInfoContentRestDtoModel {
        const model = new PharmacyInfoContentRestDtoModel(
            true,
            true,
            false,
            false,
            false,
            false,
            true,
            true,
            PharmacyInfoContentLayoutEnum.SHORT,
            StatusEnum.PUBLISHED
        );
        model.background_color_code = BackgroundColorEnum.TRANSPARENT;
        model.pharmacy_code = null;
        // model.sort_weight = NormalContentBlockModel.getDefaultSortWeight();
        model.twi = undefined;

        return model;
    }

    public static cloneFrom(source: PharmacyInfoContentRestDtoModel): PharmacyInfoContentRestDtoModel {
        const model = new this(
            source.is_show_apb_number,
            source.is_show_contactinfo,
            source.is_show_companyinfo,
            source.is_show_openinghours_title,
            source.is_show_openinghours_today,
            source.is_show_openinghours_week,
            source.is_show_pharmacy_holder,
            source.is_show_social_media,
            source.layout_code,
            source.status_code
        );
        model.background_color_code = source.background_color_code;
        model.pharmacy_code = source.pharmacy_code;
        model.sort_weight = source.sort_weight;
        model.twi = source.twi;

        return model;
    }

    public cloneMetadataFrom(source: PharmacyInfoContentRestDtoModel): void {
        clonePharmacyInfoContentRestDtoMetaData(this, source);
    }
}
