/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum SymbolWeightEnum {
    FW_100 = 'FW_100',
    FW_200 = 'FW_200',
    FW_300 = 'FW_300',
    FW_400 = 'FW_400',
    FW_500 = 'FW_500',
    FW_600 = 'FW_600',
    FW_700 = 'FW_700',
    FW_800 = 'FW_800',
    FW_900 = 'FW_900',
}