
import {defineComponent} from 'vue'
import {UIStateDto} from "@/dtos/UIStateDto";
import {
  PagedPp2ProductOverviewResultsRestDtoModel
} from "@/models/api/pharma-pim-pp2/PagedPp2ProductOverviewResultsRestDtoModel";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import PimProductsOverviewTilesItem from "@/components/layouts/pim/PimProductsOverviewTilesItem.vue";

export default defineComponent({
  name: "PimProductsOverviewTiles",
  emits: ["openOffCanvasProductDetail"],
  components: {
    TilesItem: PimProductsOverviewTilesItem,
    BaseSpinner
  },
  props: {
    productsOverview: {
      type: [PagedPp2ProductOverviewResultsRestDtoModel, Object],
      required: true
    },
    hasProducts: {
      type: Boolean,
      required: true
    },
    ui: {
      type: UIStateDto,
      required: true
    }
  }
})
