import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8b6b2866"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "selected-trademarks d-flex flex-wrap" }
const _hoisted_2 = {
  key: 0,
  class: "fst-italic m-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectTrademarksSelectedItem = _resolveComponent("SelectTrademarksSelectedItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!this.hasSelectedTrademarkCodes)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('noTrademarksAdded')), 1))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(this.selectedTrademarkCodes, (selectedTrademarkCode) => {
          return (_openBlock(), _createBlock(_component_SelectTrademarksSelectedItem, {
            key: selectedTrademarkCode,
            "selected-trademark-code": selectedTrademarkCode,
            "catalog-customer-code": this.catalogCustomerCode,
            "pim-trademarks": this.pimTrademarks,
            onRemoveTrademark: this.removeTrademark
          }, null, 8, ["selected-trademark-code", "catalog-customer-code", "pim-trademarks", "onRemoveTrademark"]))
        }), 128))
  ]))
}