import {CustomerProductPriceRestDto} from "@/api/pharma-cpc-product-mgmt/models";

export class NulllableCustomerProductPriceRestDtoModel {
    standard_price_with_vat?: number | null;
    vat_percentage?: number | null;

    constructor(standard_price_with_vat?: number, vat_percentage?: number) {
        this.standard_price_with_vat = standard_price_with_vat;
        this.vat_percentage = vat_percentage;
    }

    public static createWithDefaults(standard_price_with_vat?: number, vat_percentage?: number): NulllableCustomerProductPriceRestDtoModel {
        return new this(standard_price_with_vat, vat_percentage);
    }

    public static createWithDefaultsFromDto(dto: CustomerProductPriceRestDto): NulllableCustomerProductPriceRestDtoModel {
        return NulllableCustomerProductPriceRestDtoModel.createWithDefaults(dto.standard_price_with_vat, dto.vat_percentage);
    }

}
