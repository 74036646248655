import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28193c94"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mb-4" }
const _hoisted_3 = { class: "subtitle" }
const _hoisted_4 = { class: "row row-pharmacy mb-3" }
const _hoisted_5 = { class: "col-auto" }
const _hoisted_6 = { class: "subtitle" }
const _hoisted_7 = { class: "row row-settings" }
const _hoisted_8 = { class: "col-12" }
const _hoisted_9 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_InputSelect = _resolveComponent("InputSelect")!
  const _component_InputCheckboxBoolean = _resolveComponent("InputCheckboxBoolean")!
  const _component_dp_form = _resolveComponent("dp-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseSpinner, {
      spin: !this.isReady
    }, null, 8, ["spin"]),
    (this.isReady)
      ? (_openBlock(), _createBlock(_component_dp_form, {
          key: 0,
          as: "form",
          novalidate: ""
        }, {
          default: _withCtx(({ errors }) => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('pharmacy')), 1)
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_InputSelect, {
                      modelValue: this.contentBlock.pharmacy_code,
                      "onUpdate:modelValue": [
                        _cache[0] || (_cache[0] = ($event: any) => ((this.contentBlock.pharmacy_code) = $event)),
                        this.onChange
                      ],
                      options: this.pharmacyOptions,
                      name: "pharmacy",
                      selectText: _ctx.$t('selectText'),
                      rules: "required",
                      errors: errors
                    }, null, 8, ["modelValue", "options", "selectText", "errors", "onUpdate:modelValue"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('settings')), 1)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_InputCheckboxBoolean, {
                      modelValue: this.contentBlock.is_show_about_us,
                      "onUpdate:modelValue": [
                        _cache[1] || (_cache[1] = ($event: any) => ((this.contentBlock.is_show_about_us) = $event)),
                        this.onChange
                      ],
                      label: _ctx.$t('cms.showAboutUsInfo'),
                      name: "is_show_about_us",
                      errors: errors
                    }, null, 8, ["modelValue", "label", "errors", "onUpdate:modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_InputCheckboxBoolean, {
                      modelValue: this.contentBlock.is_show_team_members,
                      "onUpdate:modelValue": [
                        _cache[2] || (_cache[2] = ($event: any) => ((this.contentBlock.is_show_team_members) = $event)),
                        this.onChange
                      ],
                      label: _ctx.$t('cms.showTeamMembers'),
                      name: "is_show_team_members",
                      errors: errors
                    }, null, 8, ["modelValue", "label", "errors", "onUpdate:modelValue"])
                  ])
                ])
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}