<template>
  <div class="form-control" :class="{ 'is-invalid': errors[this.name] }">
    <CodeEditor
      v-model="this.model"
      :name="this.name"
      :id="this.name"
      :height="this.height"
      :languages="this.languages"
      :font_size="this.fontSize"
      class="w-100"
      :theme="'light'"
      :wrap_code="true"
    ></CodeEditor>
  </div>
  <dp-error-message class="invalid-feedback d-block" :name="this.name"></dp-error-message>
</template>

<script>
import CodeEditor from "simple-code-editor";
import {generateDomId} from "@/helpers/functions/string";

export default {
  emits: ['update:modelValue'],
  components: {CodeEditor},
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    errors: {
      type: Object,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    height: {
      type: String,
      required: false,
      default: '200px',
    },
    fontSize: {
      type: String,
      required: false,
      default: '1rem',
    },
    name: {
      type: String,
      required: false,
      default() {
        return generateDomId('ce-');
      },
    }
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        return this.$emit('update:modelValue', value);
      }
    }
  },
};
</script>
