
import {defineComponent} from "vue";
import {PaymentProviderRestService} from "@/services/rest/pay-payment/PaymentProviderRestService";
import {CustomerContext} from "@/context/CustomerContext";
import {CodeDescriptionRestDtoModel} from "@/models/CodeDescriptionRestDtoModel";
import BaseContainer from "@/components/UI/Bootstrap/BaseContainer.vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {arrayHasContent} from "@/helpers/functions/arrays";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {UIStateDto} from "@/dtos/UIStateDto";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import {PaymentProviderSettingsRestDtoModel} from "@/models/api/pharma-pay-payment/PaymentProviderSettingsRestDtoModel";
import BaseIconIsActive from "@/components/UI/BaseIconIsActive.vue";
import BaseModal from "@/components/UI/Bootstrap/Modal/BaseModal.vue";
import {default as Modal} from 'bootstrap/js/dist/modal';
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import PaymentProviderSettings from "@/components/layouts/pay/PaymentProviderSettings.vue";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import ConfirmModal from "@/components/UI/ConfirmModal.vue";

export default defineComponent({
  name: "PharmaPayOverview",
  components: {
    PaymentProviderSettings,
    BaseModal, BaseIconIsActive, AlertError2, BaseTitle, BaseContainer
  },
  data() {
    return {
      modalPaymentProvidersSettings: null as Modal | null,

      paymentProviders: [] as CodeDescriptionRestDtoModel[],
      paymentProvidersUI: UIStateDto.createWithDefaults(),
      paymentProvidersSettings: {} as any,
      paymentProviderSettingsUI: UIStateDto.createWithDefaults(),

      activePaymentProvider: null as CodeDescriptionRestDtoModel | null
    }
  },
  mounted() {
    this.modalPaymentProvidersSettings = new Modal('#modal-payment-provider-settings');
    this.reloadContent();
  },
  unmounted() {
    this.modalPaymentProvidersSettings?.dispose();
  },
  computed: {
    hasPaymentProviders(): boolean {
      return arrayHasContent(this.paymentProviders);
    }
  },
  methods: {
    getPaymentProviderSettings(paymentProviderCode: string): PaymentProviderSettingsRestDtoModel {
      return this.paymentProvidersSettings[paymentProviderCode];
    },
    openPaymentProviderSettings(paymentProvider: CodeDescriptionRestDtoModel): void {
      this.activePaymentProvider = paymentProvider;
      this.modalPaymentProvidersSettings?.show();
    },
    async disconnectPaymentProvider(paymentProviderCode: string): Promise<void> {
      this.paymentProvidersUI.clearError();

      try {
        // todo translations
        const {reveal, onConfirm} = createConfirmDialog(ConfirmModal, {
          title: this.$t('disconnect'),
          body: 'Bent u zeker dat u deze betaalprovider wenst te ontkoppelen?',
          confirmButtonText: this.$t('disconnect'),
          confirmButtonClass: 'btn-danger',
        });

        onConfirm(async () => {
          await PaymentProviderRestService.getInstance()
            .disconnectPaymentProvider(CustomerContext.getPharmacyCode(), paymentProviderCode);

          await handleSavedSuccessfully();
          await this.reloadContent();
        });

        await reveal();
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.paymentProvidersUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.paymentProvidersUI as UIStateDto))
          .catch(exceptions, true);
      }
    },
    async onPaymentProviderSettingsSaved(): Promise<void> {
      await handleSavedSuccessfully();
      await this.modalPaymentProvidersSettings?.hide();
      await this.reloadContent();
    },
    async reloadContent(): Promise<void> {
      this.paymentProvidersUI
        .clearError()
        .setNotReady();

      try {
        this.paymentProviders = await PaymentProviderRestService.getInstance()
          .findAvailablePaymentProviders(CustomerContext.getPharmacyCode());

        const requests = [] as any[];
        this.paymentProviders.forEach((paymentProvider: CodeDescriptionRestDtoModel) => {
          requests.push(this.searchPaymentProviderSettings(paymentProvider.code));
        });
        await Promise.all(requests);
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.paymentProvidersUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.paymentProvidersUI as UIStateDto))
          .catch(exceptions, true);
      } finally {
        this.paymentProvidersUI.setReady();
      }
    },
    async searchPaymentProviderSettings(paymentProviderCode: string): Promise<void> {
      let response = await PaymentProviderRestService.getInstance()
        .findPaymentProviderSettings(CustomerContext.getPharmacyCode(), paymentProviderCode);

      if (response) {
        this.paymentProvidersSettings[paymentProviderCode] = response;
      } else {
        this.paymentProvidersSettings[paymentProviderCode] = PaymentProviderSettingsRestDtoModel.createWithDefaults();
      }
    }
  }
});
