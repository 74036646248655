import {Singleton} from "@/patterns/Singleton";
import configFile from '@/../public/config.json';

export class Config extends Singleton {

    private readonly config: Object;

    protected constructor() {
        super();
        this.config = configFile as Object;
    }

    public getConfig(): Object {
        return this.config;
    }

    public read(key: string): any
    {
        type ObjectKey = keyof typeof configFile;

        return configFile[key as ObjectKey];
    }
}
