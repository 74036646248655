import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = { class: "col-6" }
const _hoisted_3 = { class: "fs-5 mb-0" }
const _hoisted_4 = { class: "table table-hover align-middle" }
const _hoisted_5 = { class: "border-dark border-bottom pb-2" }
const _hoisted_6 = { class: "col-1" }
const _hoisted_7 = { class: "col-1" }
const _hoisted_8 = { class: "col-1" }
const _hoisted_9 = { class: "col-1" }
const _hoisted_10 = { class: "ms-2" }
const _hoisted_11 = { class: "ms-2" }
const _hoisted_12 = { class: "ms-2" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "col-6" }
const _hoisted_16 = { class: "fs-5 mb-0" }
const _hoisted_17 = { class: "table table-hover align-middle" }
const _hoisted_18 = { class: "border-dark border-bottom pb-2" }
const _hoisted_19 = { class: "col-1" }
const _hoisted_20 = { class: "col-1" }
const _hoisted_21 = { class: "col-1" }
const _hoisted_22 = { class: "col-1" }
const _hoisted_23 = { class: "ms-2" }
const _hoisted_24 = { class: "ms-2" }
const _hoisted_25 = { class: "ms-2" }
const _hoisted_26 = {
  key: 1,
  class: "row"
}
const _hoisted_27 = { class: "col-6" }
const _hoisted_28 = { class: "fs-5 mb-0" }
const _hoisted_29 = { class: "row mt-2" }
const _hoisted_30 = { class: "col-auto" }
const _hoisted_31 = { class: "col-auto" }
const _hoisted_32 = { class: "row" }
const _hoisted_33 = { class: "col-auto" }
const _hoisted_34 = { class: "buttons d-flex justify-content-begin start" }
const _hoisted_35 = {
  class: "btn btn-sm btn-primary me-2",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertError2 = _resolveComponent("AlertError2")!
  const _component_BaseCardHeader = _resolveComponent("BaseCardHeader")!
  const _component_BaseCardBody = _resolveComponent("BaseCardBody")!
  const _component_BaseCard = _resolveComponent("BaseCard")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_dp_form = _resolveComponent("dp-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AlertError2, {
      error: _ctx.tabUI.getError()
    }, null, 8, ["error"]),
    (_ctx.priceInfo)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_BaseCard, { class: "standard-prices text-bg-light border-light mb-3" }, {
              default: _withCtx(() => [
                _createVNode(_component_BaseCardHeader, { class: "p-2" }, {
                  default: _withCtx(() => [
                    _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('standardPrices')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_BaseCardBody, { class: "p-2" }, {
                  default: _withCtx(() => [
                    _createElementVNode("table", _hoisted_4, [
                      _createElementVNode("thead", _hoisted_5, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t('standardPrice')), 1),
                          _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.$t('VATPerc')), 1),
                          _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.$t('priceSource')), 1),
                          _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.$t('status')), 1)
                        ])
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.priceInfo.standard_prices, (stPrice, index) => {
                        return (_openBlock(), _createElementBlock("tr", { key: index }, [
                          _createElementVNode("td", null, [
                            _createElementVNode("div", _hoisted_10, " € " + _toDisplayString(stPrice.standard_price_with_vat), 1)
                          ]),
                          _createElementVNode("td", null, [
                            _createElementVNode("div", null, _toDisplayString(stPrice.vat_percentage) + "% ", 1)
                          ]),
                          _createElementVNode("td", null, [
                            _createElementVNode("div", _hoisted_11, _toDisplayString(stPrice.source?.description), 1)
                          ]),
                          _createElementVNode("td", null, [
                            _createElementVNode("div", _hoisted_12, [
                              (index === _ctx.priceInfo.standard_prices.length - 1)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.$t('active')), 1))
                                : (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString("-")))
                            ])
                          ])
                        ]))
                      }), 128))
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_BaseCard, { class: "catalog-prices text-bg-light border-light mb-3" }, {
              default: _withCtx(() => [
                _createVNode(_component_BaseCardHeader, { class: "p-2" }, {
                  default: _withCtx(() => [
                    _createElementVNode("h3", _hoisted_16, _toDisplayString(_ctx.$t('catalogSalePrices')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_BaseCardBody, { class: "p-2" }, {
                  default: _withCtx(() => [
                    _createElementVNode("table", _hoisted_17, [
                      _createElementVNode("thead", _hoisted_18, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", _hoisted_19, _toDisplayString(_ctx.$t('clientGroup')), 1),
                          _createElementVNode("th", _hoisted_20, _toDisplayString(_ctx.$t('salePrice')), 1),
                          _createElementVNode("th", _hoisted_21, _toDisplayString(_ctx.$t('VATPerc')), 1),
                          _createElementVNode("th", _hoisted_22, _toDisplayString(_ctx.$t('discountSchemeOrPriceList')), 1)
                        ])
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.priceInfo.catalog_sale_prices, (salePrice, index) => {
                        return (_openBlock(), _createElementBlock("tr", { key: index }, [
                          _createElementVNode("td", null, [
                            _createElementVNode("div", _hoisted_23, _toDisplayString(salePrice.client_group ? salePrice.client_group.description : '-'), 1)
                          ]),
                          _createElementVNode("td", null, [
                            _createElementVNode("div", _hoisted_24, " € " + _toDisplayString(salePrice.sale_price_with_vat), 1)
                          ]),
                          _createElementVNode("td", null, [
                            _createElementVNode("div", null, _toDisplayString(salePrice.vat_percentage) + "% ", 1)
                          ]),
                          _createElementVNode("td", null, [
                            _createElementVNode("div", _hoisted_25, _toDisplayString(salePrice.discount_scheme_name ?? salePrice.pricelist_name ?? '-'), 1)
                          ])
                        ]))
                      }), 128))
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.customerPrice)
      ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, [
            _createVNode(_component_BaseCard, { class: "customer-price text-bg-light border-light mb-3" }, {
              default: _withCtx(() => [
                _createVNode(_component_BaseCardHeader, { class: "p-2" }, {
                  default: _withCtx(() => [
                    _createElementVNode("h3", _hoisted_28, _toDisplayString(_ctx.$t('customerPrice')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_BaseCardBody, { class: "p-2" }, {
                  default: _withCtx(() => [
                    _withDirectives(_createVNode(_component_dp_form, {
                      as: "form",
                      novalidate: "",
                      onSubmit: _ctx.submitCustomerPrice
                    }, {
                      default: _withCtx(({ errors }) => [
                        _createElementVNode("div", _hoisted_29, [
                          _createElementVNode("div", _hoisted_30, [
                            _createVNode(_component_InputNumber, {
                              modelValue: _ctx.customerPrice.standard_price_with_vat,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.customerPrice.standard_price_with_vat) = $event)),
                              label: _ctx.$t('standardPrice'),
                              name: "standardPrice",
                              min: 0,
                              step: 0.1,
                              rules: "required",
                              errors: errors
                            }, null, 8, ["modelValue", "label", "step", "errors"])
                          ]),
                          _createElementVNode("div", _hoisted_31, [
                            _createVNode(_component_InputNumber, {
                              modelValue: _ctx.customerPrice.vat_percentage,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customerPrice.vat_percentage) = $event)),
                              label: _ctx.$t('VAT') + '%',
                              name: "vat",
                              min: 0,
                              step: 0.1,
                              rules: "required",
                              errors: errors
                            }, null, 8, ["modelValue", "label", "step", "errors"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_32, [
                          _createElementVNode("div", _hoisted_33, [
                            _createElementVNode("div", _hoisted_34, [
                              _createElementVNode("button", _hoisted_35, _toDisplayString(_ctx.$t('save')), 1),
                              _createElementVNode("button", {
                                onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.deleteCustomerPrice && _ctx.deleteCustomerPrice(...args)), ["prevent"])),
                                class: "btn btn-outline-primary me-2",
                                type: "button"
                              }, _toDisplayString(_ctx.$t('delete')), 1)
                            ])
                          ])
                        ])
                      ]),
                      _: 1
                    }, 8, ["onSubmit"]), [
                      [_vShow, _ctx.tabUI.isReady()]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}