import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "pagination-block" }
const _hoisted_2 = {
  key: 0,
  class: "page-items-count mb-0 fs-7"
}
const _hoisted_3 = {
  key: 1,
  class: "mt-3",
  "aria-label": "Page navigation"
}
const _hoisted_4 = { class: "pagination pagination-sm flex-wrap mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasePaginationItem = _resolveComponent("BasePaginationItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (this.showPageItemsCount)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(this.itemsCountOfTotal), 1))
      : _createCommentVNode("", true),
    (this.paginationData.total_pages > 1)
      ? (_openBlock(), _createElementBlock("nav", _hoisted_3, [
          _createElementVNode("ul", _hoisted_4, [
            (!this.paginationData.is_first)
              ? (_openBlock(), _createBlock(_component_BasePaginationItem, {
                  key: 0,
                  "pagination-data": this.paginationData,
                  "is-first": "",
                  onChangePage: this.changePage
                }, null, 8, ["pagination-data", "onChangePage"]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.pageNumbersToShow, (pageNumber) => {
              return (_openBlock(), _createBlock(_component_BasePaginationItem, {
                key: pageNumber,
                "pagination-data": this.paginationData,
                "page-number": pageNumber,
                onChangePage: this.changePage
              }, null, 8, ["pagination-data", "page-number", "onChangePage"]))
            }), 128)),
            (!this.paginationData.is_last)
              ? (_openBlock(), _createBlock(_component_BasePaginationItem, {
                  key: 1,
                  "pagination-data": this.paginationData,
                  "is-last": "",
                  onChangePage: this.changePage
                }, null, 8, ["pagination-data", "onChangePage"]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}