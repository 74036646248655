import {RestService} from "@/services/rest/RestService";
import {ProductBrandOrderAPIApi} from "@/api/pharma-cpc-mgmt";
import {
    ProductBrandOrderCreateRestDto,
    ProductBrandOrderOverviewRestDto
} from "@/api/pharma-cpc-mgmt/models";
import {DpException} from "@/exception/DpException";
import {MoveActionEnum} from "@/models/enum/MoveActionEnum";
import {BrandTypeEnum} from "@/api/enums/brand-type-enum";

export class ProductBrandOrderRestService extends RestService {

    private apiService: ProductBrandOrderAPIApi;

    protected constructor() {
        super();

        this.apiService = new ProductBrandOrderAPIApi(this.getDpAxiosInstance(), undefined, this.getConfig().cpcUrl);
    }

    public async findBrandOrders(): Promise<ProductBrandOrderOverviewRestDto[]> {
        const response = await this.apiService.findBrandOrders();
        return response.data.results;
    }

    public async createBrandOrder(brandCode: string, type: BrandTypeEnum): Promise<void> {
        const payload = {
            brand_code: brandCode,
            brand_type_code: type,
        } as ProductBrandOrderCreateRestDto;

        await this.apiService.createBrandOrder(payload);
    }

    public async deleteBrandOrder(brandCode: string): Promise<void> {
        await this.apiService.deleteBrandOrder(brandCode);
    }

    public async moveBrandOrder(brandCode: string, moveAction: MoveActionEnum): Promise<void> {
        switch (moveAction) {
            case MoveActionEnum.TOP:
                await this.apiService.moveBrandOrderToTop(brandCode);
                break;
            case MoveActionEnum.UP:
                await this.apiService.moveBrandOrderUp(brandCode);
                break;
            case MoveActionEnum.DOWN:
                await this.apiService.moveBrandOrderDown(brandCode);
                break;
            case MoveActionEnum.BOTTOM:
                await this.apiService.moveBrandOrderToBottom(brandCode);
                break;
            default:
                throw [new DpException('Unsupported moveAction to call ' + moveAction)];
        }
    }
}
