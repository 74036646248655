/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */

import {ContentBlockMetadataRestDto} from ".";
import {BackgroundColorEnum} from "@/api/enums/background-color-enum";
import {HeightEnum} from "@/api/enums/height-enum";
import {StatusEnum} from "@/api/enums/status-enum";

/**
 * @export
 * @interface RowSeparatorContentRestDto
 */
export interface RowSeparatorContentRestDto {
    /**
     * The status of this content block.
     * @type {StatusEnum}
     * @memberof RowSeparatorContentRestDto
     */
    status_code: StatusEnum;

    /**
     * The sorting value of this content block.
     * @type {number|null}
     * @memberof RowSeparatorContentRestDto
     */
    sort_weight?: number|null;

    /**
     * The height of the row separator.
     * @type {HeightEnum}
     * @memberof RowSeparatorContentRestDto
     */
    height_code: HeightEnum;

    /**
     * Specifies the background color of this row.
     * @type {BackgroundColorEnum}
     * @memberof RowSeparatorContentRestDto
     */
    background_color_code: BackgroundColorEnum;

    /**
     * 
     * @type {ContentBlockMetadataRestDto|null}
     * @memberof RowSeparatorContentRestDto
     */
    cb_metadata?: ContentBlockMetadataRestDto|null;

}

/**
 * @export
 * @function cloneRowSeparatorContentRestDtoMetaData
 */
export function cloneRowSeparatorContentRestDtoMetaData(dto: RowSeparatorContentRestDto, source: RowSeparatorContentRestDto): void {
    dto.sort_weight = source.sort_weight;
    dto.height_code = source.height_code;
    dto.background_color_code = source.background_color_code;
}
