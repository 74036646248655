
import {defineComponent} from 'vue'
import {Pp2ProductOverviewRestDtoModel} from "@/models/api/pharma-pim-pp2/Pp2ProductOverviewRestDtoModel";
import {LaboProductOverviewRestDtoModel} from "@/models/api/pharma-pim-labo/LaboProductOverviewRestDtoModel";

export default defineComponent({
  name: "PimProductsOverviewTableItemPreview",
  props: {
    product: {
      type: [Pp2ProductOverviewRestDtoModel, LaboProductOverviewRestDtoModel, Object],
      required: true
    }
  }
})
