import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row gx-5 mb-4" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "d-inline-block mt-3 pt-4 border-top border-2 border-light" }
const _hoisted_6 = {
  type: "submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_text = _resolveComponent("input-text")!
  const _component_input_text_editor = _resolveComponent("input-text-editor")!
  const _component_input_number = _resolveComponent("input-number")!
  const _component_input_select = _resolveComponent("input-select")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_dp_form = _resolveComponent("dp-form")!
  const _component_cb_form = _resolveComponent("cb-form")!

  return (_openBlock(), _createBlock(_component_cb_form, {
    onCopiedLibCb: _ctx.copiedLibContentBlock,
    "cb-type": _ctx.cbType,
    exceptions: _ctx.exceptions,
    "can-copy-from-library": _ctx.canCopyFromLibrary,
    "is-library": _ctx.isLibrary,
    "is-ready": this.isReady,
    mode: _ctx.mode,
    "return-route": this.returnRoute,
    "saved-successfully": this.savedSuccessfully,
    title: this.title ?? null,
    description: _ctx.description,
    description2: _ctx.description2,
    description3: _ctx.description3,
    description4: _ctx.description4
  }, {
    default: _withCtx(() => [
      _createVNode(_component_dp_form, {
        as: "form",
        onSubmit: _ctx.submitData,
        novalidate: ""
      }, {
        default: _withCtx(({ errors }) => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_input_text, {
                label: _ctx.$t('title'),
                name: "title",
                modelValue: _ctx.contentBlock.title.nl,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.contentBlock.title.nl) = $event)),
                errors: errors
              }, null, 8, ["label", "modelValue", "errors"]),
              _createVNode(_component_input_text_editor, {
                label: _ctx.$t('content'),
                name: "content",
                modelValue: _ctx.contentBlock.content.nl,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.contentBlock.content.nl) = $event)),
                height: _ctx.textareaHeight,
                errors: errors
              }, null, 8, ["label", "modelValue", "height", "errors"])
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                (_ctx.mode !== 'new' && (_ctx.isLibrary || _ctx.isNormalContentBlock))
                  ? (_openBlock(), _createBlock(_component_input_number, {
                      key: 0,
                      label: _ctx.$t('sortWeight'),
                      placeholder: "sortWeight",
                      name: "sortWeight",
                      modelValue: _ctx.contentBlock.sort_weight,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.contentBlock.sort_weight) = $event)),
                      errors: errors
                    }, null, 8, ["label", "modelValue", "errors"]))
                  : _createCommentVNode("", true),
                (_ctx.isPublishable)
                  ? (_openBlock(), _createBlock(_component_input_select, {
                      key: 1,
                      name: "cbStatus",
                      class: "fw-bold",
                      selectText: _ctx.$t('selectText'),
                      modelValue: _ctx.contentBlock.status_code,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.contentBlock.status_code) = $event)),
                      options: _ctx.statusOptions,
                      errors: errors
                    }, null, 8, ["selectText", "modelValue", "options", "errors"]))
                  : _createCommentVNode("", true),
                _createElementVNode("button", _hoisted_6, _toDisplayString(_ctx.$t('save')), 1),
                _createVNode(_component_router_link, {
                  to: _ctx.returnRoute ?? {name: 'home'},
                  class: "ms-3 btn btn-outline-primary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
                  ]),
                  _: 1
                }, 8, ["to"])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }, 8, ["onCopiedLibCb", "cb-type", "exceptions", "can-copy-from-library", "is-library", "is-ready", "mode", "return-route", "saved-successfully", "title", "description", "description2", "description3", "description4"]))
}