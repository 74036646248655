
import {defineComponent, PropType} from "vue";
import LibContentBlocks from "@/components/layouts/lib-content-blocks/LibContentBlocks.vue";
import {LibraryOverviewRestDtoModel} from "@/models/api/pharma-cms-content-block-library/LibraryOverviewRestDtoModel";
import {generateUUIDv4} from "@/helpers/functions/string";

export default defineComponent({
  name: "BannerSlotDetailBannerLibrary",
  components: {LibContentBlocks},
  emits: ["copied", "exceptions"],
  props: {
    bannerSlotId: {
      type: String,
      required: true
    },
    bannerLibType: {
      type: String,
      required: true
    },
    bannerLibraries: {
      type: Array as PropType<LibraryOverviewRestDtoModel[]>,
      required: true,
    },
    cbType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isReady: false as boolean,
      modalId: generateUUIDv4('banners-for-slot-modal-') as string,
      savedSuccessfully: false as boolean,
    };
  },
  methods: {
    setExceptions(exceptions: unknown) {
      this.$emit('exceptions', exceptions);
    },
  }
});
