
import {defineComponent} from "vue";
import {mapActions} from 'vuex';
import AbstractCbCommon from "@/components/layouts/content-pages/wysiwyg/structures/AbstractCbCommon.vue";
import {RowSeparatorContentRestDtoModel} from "@/models/RowSeparatorContentRestDtoModel";
import {
  CodeDescriptionRestDto,
} from "@/api/pharma-cms-content-block/models";
import {createCodeDescriptionArrayFromEnum} from "@/helpers/functions/enum";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import {HeightEnum} from "@/api/enums/height-enum";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import InputSelect from "@/components/UI/InputSelect.vue";

export default defineComponent({
  name: "CbRowSeparator",
  components: {InputSelect, BaseSpinner},
  extends: AbstractCbCommon,
  data: () => ({
    contentBlock: RowSeparatorContentRestDtoModel.createWithDefaults(),
  }),
  computed: {
    enumBackgroundColorCode(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.BACKGROUND_COLOR);
    },
    enumHeightCode(): CodeDescriptionRestDto[] {
      // todo !!!
      return createCodeDescriptionArrayFromEnum(HeightEnum);
    }
  },
  methods: {
    ...mapActions('cms_content_block/normal_content_block/row_separator', [
      'create', 'find', 'save',
    ]),
    getDefaultCbModel(): RowSeparatorContentRestDtoModel {
      return RowSeparatorContentRestDtoModel.createWithDefaults();
    },
    determineEnums(): EnumClassNameCmsEnum[] {
      return [
        EnumClassNameCmsEnum.BACKGROUND_COLOR,
      ];
    },
    pasteCbMetadata(): void {
      // When calling clone method directly on object, method is not found. So this is a hacky workaround
      const cbClone = RowSeparatorContentRestDtoModel.cloneFrom(this.contentBlock);

      cbClone.cloneMetadataFrom(this.getClipboardContentBlock(this.cbType) as RowSeparatorContentRestDtoModel);
      this.contentBlock = cbClone;
    },
  }
});
