import {PageElementMetadataRestDto} from "@/api/pharma-cms-content-page/models";

export class PageElementMetadataRestDtoModel implements PageElementMetadataRestDto {
    is_write_locked?: boolean | null;
    is_delete_locked?: boolean | null;
    is_copy_locked?: boolean | null;

    constructor(is_write_locked?: boolean | null, is_delete_locked?: boolean | null, is_copy_locked?: boolean | null) {
        this.is_write_locked = is_write_locked ?? undefined;
        this.is_delete_locked = is_delete_locked ?? undefined;
        this.is_copy_locked = is_copy_locked ?? undefined;
    }

    public static createWithDefaults(): PageElementMetadataRestDtoModel {
        return new PageElementMetadataRestDtoModel(false, false, false);
    }
}
