
import {defineComponent} from "vue";
import {TinyEmitter} from "tiny-emitter";
import {CreateNormalContentBlockPayload} from "@/models/payload/CreateNormalContentBlockPayload";
import {SaveNormalContentBlockPayload} from "@/models/payload/SaveNormalContentBlockPayload";
import {FindNormalContentBlockPayload} from "@/models/payload/FindNormalContentBlockPayload";
import {Exception} from "@/api/interfaces";
import {NormalContentBlockModel} from "@/models/api/pharma-cms-content-block/NormalContentBlockModel";
import {DpException} from "@/exception/DpException";
import {mapActions, mapGetters} from "vuex";
import {mapActions as mapActionsP, mapState} from "pinia";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-enum/models";
import {CopyCpAbstractPayload} from "@/models/payload/CopyCpAbstractPayload";
import {useClipboardStore} from "@/stores/ClipboardStore";
import {useToast} from "vue-toastification";

export default defineComponent({
  name: "AbstractCbCommon",
  abstract: true,
  emits: ['saved', 'exceptions', 'hasUnsavedChanges', 'abstractContentChanged'],
  props: {
    cbId: {
      type: String,
      required: false,
    },
    cbType: {
      type: String,
      required: true,
    },
    bus: {
      type: TinyEmitter,
      required: true,
    }
  },
  data: () => ({
    isReady: false as boolean,
    error: null,
    contentBlock: new NormalContentBlockModel(),
    copyCpAbstractPayload: CopyCpAbstractPayload.createWithDefaults(),
    toaster: useToast(),
  }),
  watch: {
    cbId: {
      handler() {
        this.reloadContent();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('cms_enum', ['getEnumByClassname']),
    ...mapState(useClipboardStore, ['getClipboardContentBlock']),
  },
  methods: {
    ...mapActions('cms_content_block', ['createContentBlockLink']),
    ...mapActions('cms_enum', ['findManyEnumByClassname']),
    ...mapActionsP(useClipboardStore, ['copyToClipboardContentBlock']),
    async find(payload: FindNormalContentBlockPayload): Promise<NormalContentBlockModel> {
      throw [new DpException('AbstractCbCommon::find() should be overwritten by the dedicated component.')];
    },
    async create(payload: CreateNormalContentBlockPayload): Promise<void> {
      throw [new DpException('AbstractCbCommon::create() should be overwritten by the dedicated component.')];
    },
    async save(payload: SaveNormalContentBlockPayload): Promise<void> {
      throw [new DpException('AbstractCbCommon::save() should be overwritten by the dedicated component.')];
    },
    getDefaultCbModel(): NormalContentBlockModel {
      throw [new DpException('AbstractCbCommon::getDefaultCbModel() should be overwritten by the dedicated component.')];
    },
    buildPageLinkPayload(): void {
      throw [new DpException('AbstractCbCommon::buildPageLinkPayload() should be overwritten by the dedicated component.')];
    },
    onCopyCpAbstractChanged(): void {
      this.$emit('abstractContentChanged', this.copyCpAbstractPayload);
    },
    onCopyCbMetadata(): void {
      this.copyToClipboardContentBlock(this.contentBlock, this.cbType);

      this.toaster.info(this.$t('cms.successfullyCopiedMetadata'));
    },
    onPasteCbMetadata(): void {
      this.pasteCbMetadata();

      this.onChange();
      this.toaster.info(this.$t('cms.successfullyPastedMetadata'));
    },
    onChange(): void {
      this.$emit('hasUnsavedChanges');
      this.onCopyCpAbstractChanged();
    },
    determineEnums(): EnumClassNameCmsEnum[] {
      return [];
    },
    getEnum(className: EnumClassNameCmsEnum): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(className);
    },
    async copyLibCb(lib_cb_id: string): Promise<void> {
      try {
        this.setExceptions([]);

        let cbId = null;

        if (this.cbId === undefined) {
          const payload = new CreateNormalContentBlockPayload(this.cbType, this.contentBlock);
          cbId = await this.create(payload);
        }

        const payload = {
          from_cb_type_code: this.cbType,
          from_cb_id: this.cbId ?? cbId,
          to_cb_id: lib_cb_id,
          force_copy: false,
        };
        await this.createContentBlockLink(payload);

        await this.reloadContent();

        this.$emit('saved', cbId);
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }
    },
    pasteCbMetadata(): void {
      throw [new DpException('AbstractCbCommon::pasteCbMetadata() should be overwritten by the dedicated component.')];
    },
    async reloadContent(): Promise<void> {
      this.isReady = false;
      try {
        await this.beforeReloadContent();

        this.bus.on('selectedLibCb', this.copyLibCb);
        this.bus.on('submit', this.submitData);
        this.bus.on('onCopyCbMetadata', this.onCopyCbMetadata);
        this.bus.on('onPasteCbMetadata', this.onPasteCbMetadata);

        await this.findManyEnumByClassname(this.determineEnums());

        this.onCopyCpAbstractChanged();

        if (this.cbId === undefined) {
          this.contentBlock = this.getDefaultCbModel();
        } else {
          const payload = new FindNormalContentBlockPayload(this.cbId, this.cbType);

          this.contentBlock = await this.find(payload);
        }

        await this.afterReloadContent();
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }

      this.isReady = true;
    },
    async beforeReloadContent(): Promise<void> {
    },
    async afterReloadContent(): Promise<void> {
    },
    async submitData(): Promise<void> {
      try {
        this.setExceptions([]);
        await this.beforeSubmitData();

        let cbId = null;

        if (this.cbId === undefined) {
          const payload = new CreateNormalContentBlockPayload(this.cbType, this.contentBlock);
          cbId = await this.create(payload);
        } else {
          const payload = new SaveNormalContentBlockPayload(this.cbId, this.cbType, this.contentBlock);
          await this.save(payload);
        }

        this.$emit('saved', cbId);

        await this.afterSubmitData();
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }
    },
    async beforeSubmitData(): Promise<void> {
    },
    async afterSubmitData(): Promise<void> {
    },
    setExceptions(exceptions: Exception[]) {
      this.$emit('exceptions', exceptions);
    },
  }
});
