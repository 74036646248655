import {
    PagedPp2ProductOverviewResultsRestDto,
    PagingMetadataRestDto,
} from "@/api/pharma-pim-pp2/models";
import {Pp2ProductOverviewRestDtoModel} from "@/models/api/pharma-pim-pp2/Pp2ProductOverviewRestDtoModel";

export class PagedPp2ProductOverviewResultsRestDtoModel implements PagedPp2ProductOverviewResultsRestDto {
    paging_metadata?: PagingMetadataRestDto | null;
    results: Pp2ProductOverviewRestDtoModel[];

    constructor(results: Pp2ProductOverviewRestDtoModel[]) {
        this.results = results;
    }

    public static createWithDefaults(): PagedPp2ProductOverviewResultsRestDtoModel {
        return new this([]);
    }
}
