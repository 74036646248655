import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-db71b5f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_alert = _resolveComponent("base-alert")!

  return (_ctx.message)
    ? (_openBlock(), _createBlock(_component_base_alert, {
        key: 0,
        class: "alert-warning",
        icon: "warning"
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_1, [
            _createElementVNode("strong", null, _toDisplayString(`${_ctx.$t('attention').toUpperCase()}: `), 1),
            _createTextVNode(_toDisplayString(_ctx.message), 1)
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}