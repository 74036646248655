/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Utility API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { TextUtilityResponseRestDto } from '../models';
import { TruncateTextRestDto } from '../models';
/**
 * UtilityEndpointsApi - axios parameter creator
 * @export
 */
export const UtilityEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.UTIL.01 Truncate text.
         * @param {TruncateTextRestDto} body 
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        truncateText: async (body: TruncateTextRestDto, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling truncateText.');
            }
            const localVarPath = `/utility/v1/text/truncate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityEndpointsApi - functional programming interface
 * @export
 */
export const UtilityEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.UTIL.01 Truncate text.
         * @param {TruncateTextRestDto} body 
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async truncateText(_axios: AxiosInstance, body: TruncateTextRestDto, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TextUtilityResponseRestDto>> {
            const localVarAxiosArgs = await UtilityEndpointsApiAxiosParamCreator(configuration).truncateText(body, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UtilityEndpointsApi - object-oriented interface
 * @export
 * @class UtilityEndpointsApi
 * @extends {BaseAPI}
 */
export class UtilityEndpointsApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.CMS.UTIL.01 Truncate text.
     * @param {TruncateTextRestDto} body 
     * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityEndpointsApi
     */
     public truncateText(body: TruncateTextRestDto, x_dp_customer_code?: string, options?: any) {
        return UtilityEndpointsApiFp(this.configuration).truncateText(this.axios, body, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
