
import {defineComponent} from "vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import BaseContentLibraryAvailable from "@/components/UI/BaseContentLibraryAvailable.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import AlertSuccess from "@/components/UI/Bootstrap/Alert/AlertSuccess.vue";
import AlertError from "@/components/UI/Bootstrap/Alert/AlertError.vue";

export default defineComponent({
  name: "CbForm",
  components: {AlertError, AlertSuccess, BaseSpinner, BaseContentLibraryAvailable, BaseTitle},
  emits: ["copiedLibCb"],
  props: {
    cbType: {
      type: String,
      required: true,
    },
    exceptions: {
      type: Array,
      required: true,
    },
    canCopyFromLibrary: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLibrary: {
      type: Boolean,
      required: false,
      default: false,
    },
    isReady: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String,
      required: false
    },
    savedSuccessfully: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    returnRoute: {
      type: [String, Object],
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    description2: {
      type: String,
      required: false,
    },
    description3: {
      type: String,
      required: false,
    },
    description4: {
      type: String,
      required: false,
    },
  },
  computed: {
    computedDescription(): string | undefined {
      if (this.description || this.description2 || this.description3 || this.description4) {
        let result = '' as string;

        if (this.description) {
          result += this.description;
        }
        if (this.description2) {
          result += this.description2;
        }
        if (this.description3) {
          result += this.description3;
        }
        if (this.description4) {
          result += this.description4;
        }

        return result;
      }

      return undefined;
    },
    computedTitle(): string {
      return this.title ?? '';
    }
  }
});
