import {customerNameCRUDAPIService} from "@/main";
import store from "@/store";
import {CodeDescriptionRestDto} from "@/api/pharma-customer-name/models";

export default {
    namespaced: true,
    state() {
        return {
            customer_name: [] as CodeDescriptionRestDto[]
        };
    },
    actions: {
        async searchCustomers() {
            customerNameCRUDAPIService.findCustomerNames().then(response => {
                store.commit('customer_name/setCustomers', response.data);
            });
        },
    },
    mutations: {
        setCustomers(state: any, payload: any) {
            state.customer_name = payload;
        },
    },
    getters: {
        getCustomers(state: any): CodeDescriptionRestDto[] {
            return state.customer_name;
        }
    },
};
