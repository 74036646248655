import {LaboProductOverviewRestDto} from "@/api/pharma-pim-labo/models";
import {LaboProductInfoRestDtoModel} from "@/models/api/pharma-pim-labo/LaboProductInfoRestDtoModel";

export class LaboProductOverviewRestDtoModel implements LaboProductOverviewRestDto {
    id: number;
    product_info: LaboProductInfoRestDtoModel;

    constructor(id: number, product_info: LaboProductInfoRestDtoModel) {
        this.id = id;
        this.product_info = product_info;
    }

    public static createWithDefaults(): LaboProductOverviewRestDtoModel {
        return new this(
            0,
            LaboProductInfoRestDtoModel.createWithDefaults()
        );
    }
}
