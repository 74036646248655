import {simpleContentBlockEndpointsApiService, simpleImageContentBlockEndpointsApiService} from "@/main";
import {
    SimpleButtonContentRestDto, SimpleDocumentContentRestDto,
    SimpleImageContentRestDto,
    SimpleTextContentRestDto,
    SimpleTextWithImageContentRestDto,
    SimpleTextWithTitleContentRestDto,
} from "@/api/pharma-cms-content-block/models";
import {
    emptySimpleContentBlockButton, emptySimpleContentBlockDocument,
    emptySimpleContentBlockImage,
    emptySimpleContentBlockText, emptySimpleContentBlockTextWithImage, emptySimpleContentBlockTextWithTitle
} from "@/helpers/functions/content_blocks/empty_content";
import {isValidButton} from "@/helpers/functions/content_blocks/validation";
import {StatusEnum} from "@/api/enums/status-enum";

export default {
    state() {
        // todo remove EXTERNAL_HOME_PAGE_URL when migration complete
        return {
            logo_img_id: {} as SimpleImageContentRestDto,
            pharmacy_website_main_img_id: {} as SimpleImageContentRestDto,
            sticky_logo: {} as SimpleImageContentRestDto,
            catalog_title: {} as SimpleTextContentRestDto,
            pharmacy_group_public_name: {} as SimpleTextContentRestDto,
            pharmacy_on_call_url: {} as SimpleTextContentRestDto,
            gtm_code: {} as SimpleTextContentRestDto,
            basic_homepage_meta: {} as SimpleTextWithImageContentRestDto,
            external_homepage_button: {} as SimpleButtonContentRestDto,
            categories_content_block: {} as SimpleTextWithImageContentRestDto,
            general_message: {} as SimpleTextWithTitleContentRestDto,
            products_in_the_spotlight: {} as SimpleTextWithTitleContentRestDto,
            welcome_txt: {} as SimpleTextWithTitleContentRestDto,
            reimbursed_care_rates_doc: {} as SimpleDocumentContentRestDto,
        }
    },
    actions: {
        clearSimpleContentBlock(context: any, type: string) {
            context.commit("clearSimpleContentBlock", type);
        },
        async searchSimpleContentBlockButton(context: any, payload: any) {
            const type = payload.type as string;
            const fallback_allowed = payload.fallback_allowed as boolean | undefined;

            const response = await simpleContentBlockEndpointsApiService.getSimpleContentBlockButton(type, undefined, fallback_allowed);

            if (response.status === 204) {
                context.commit('clearSimpleContentBlock', type);
            } else {
                context.commit('setSimpleContentBlockButton', {
                    cb: response.data,
                    type: type,
                });
            }
        },
        async searchSimpleContentBlockImage(context: any, type: string) {
            const response = await simpleImageContentBlockEndpointsApiService.getSimpleContentBlockImage(type);

            if (response.status === 204) {
                context.commit('clearSimpleContentBlock', type);
            } else {
                context.commit('setSimpleContentBlockImage', {
                    type: type,
                    cb: response.data,
                });
            }
        },
        async searchSimpleContentBlockDocument(context: any, type: string) {
            const response = await simpleImageContentBlockEndpointsApiService.getSimpleContentBlockDocument(type);

            if (response.status === 204) {
                context.commit('clearSimpleContentBlock', type);
            } else {
                context.commit('setSimpleContentBlockDocument', {
                    type: type,
                    cb: response.data,
                });
            }
        },
        async searchSimpleContentBlockText(context: any, payload: any) {
            const type = payload.type as string;
            const fallback_allowed = payload.fallback_allowed as boolean | undefined;

            const response = await simpleContentBlockEndpointsApiService.getSimpleContentBlockText(type, undefined, fallback_allowed);

            if (response.status === 204) {
                context.commit('clearSimpleContentBlock', type);
            } else {
                context.commit('setSimpleContentBlockText', {
                    cb: response.data,
                    type: type,
                });
            }
        },
        async searchSimpleContentBlockTextWithImage(context: any, payload: any) {
            const type = payload.type as string;
            const fallback_allowed = payload.fallback_allowed as boolean | undefined;

            const response = await simpleContentBlockEndpointsApiService.getSimpleContentBlockTextWithImage(type, undefined, fallback_allowed);

            if (response.status === 204) {
                context.commit('clearSimpleContentBlock', type);
            } else {
                context.commit('setSimpleContentBlockTextWithImage', {
                    type: type,
                    cb: response.data,
                });
            }
        },
        async searchSimpleContentBlockTextWithTitle(context: any, payload: any) {
            const type = payload.type as string;
            const fallback_allowed = payload.fallback_allowed as boolean | undefined;

            const response = await simpleContentBlockEndpointsApiService.getSimpleContentBlockTextWithTitle(type, undefined, fallback_allowed);

            if (response.status === 204) {
                context.commit('clearSimpleContentBlock', type);
            } else {
                context.commit('setSimpleContentBlockTextWithTitle', {
                    cb: response.data,
                    type: type,
                });
            }
        },
        async searchStandardSimpleContentBlockText(context: any, type: string) {
            const response = await simpleContentBlockEndpointsApiService.getStandardSimpleContentBlockText(type);

            context.commit("setSimpleContentBlockText", {
                cb: response.data,
                type: type,
            });
        },
        async searchStandardSimpleContentBlockDocument(context: any, type: string) {
            const response = await simpleContentBlockEndpointsApiService.getStandardSimpleContentBlockDocument(type);

            console.log('searchStandardSimpleContentBlockDocument', response)

            if (response.status === 204) {
                context.commit('clearSimpleContentBlock', type);
            } else {
                context.commit("setSimpleContentBlockDocument", {
                    cb: response.data,
                    type: type,
                });
            }
        },
        async searchStandardSimpleContentBlockTextWithTitle(context: any, type: string) {
            const response = await simpleContentBlockEndpointsApiService.getStandardSimpleContentBlockTextWithTitle(type);

            context.commit("setSimpleContentBlockTextWithTitle", {
                cb: response.data,
                type: type,
            })
        },
        async saveSimpleContentBlockButton(context: any, type: string) {
            const cb = context.getters.getSimpleContentBlockButton(type);
            await simpleContentBlockEndpointsApiService.updateSimpleContentBlockButton(cb, type);
        },
        async saveSimpleContentBlockImage(context: any, type: string) {
            const cb = context.getters.getSimpleContentBlockImage(type);

            await simpleContentBlockEndpointsApiService.updateSimpleContentBlockImage(cb, type);
        },
        async saveSimpleContentBlockDocument(context: any, type: string) {
            const cb = context.getters.getSimpleContentBlockDocument(type);

            await simpleContentBlockEndpointsApiService.updateSimpleContentBlockDocument(cb, type);
        },
        async saveSimpleContentBlockText(context: any, type: string) {
            const cb = context.getters.getSimpleContentBlockText(type);
            await simpleContentBlockEndpointsApiService.updateSimpleContentBlockText(cb, type);
        },
        async saveSimpleContentBlockTextWithImage(context: any, type: string) {
            const cb = JSON.parse(JSON.stringify(context.getters.getSimpleContentBlockTextWithImage(type)));

            cb.button = isValidButton(cb.button) ? cb.button : null;

            await simpleContentBlockEndpointsApiService.updateSimpleContentBlockTextWithImage(cb, type);
        },
        async saveSimpleContentBlockTextWithTitle(context: any, type: any) {
            const cb = context.getters.getSimpleContentBlockTextWithTitle(type);
            await simpleContentBlockEndpointsApiService.updateSimpleContentBlockTextWithTitle(cb, type);
        },
        async deleteSimpleContentBlock(context: any, type: string) {
            await simpleContentBlockEndpointsApiService.deleteSimpleContentBlock(type);
        },
    },
    mutations: {
        clearSimpleContentBlock(state: any, type: string) {
            // todo map gebruiken
            if (type === 'LOGO_IMG_ID') {
                state.logo_img_id = emptySimpleContentBlockImage();
            } else if (type === 'PHARMACY_WEBSITE_MAIN_IMG_ID') {
                state.pharmacy_website_main_img_id = emptySimpleContentBlockImage();
            } else if (type === 'STICKY_LOGO') {
                state.sticky_logo = emptySimpleContentBlockImage();
            } else if (type === 'CATALOG_TITLE') {
                state.catalog_title = emptySimpleContentBlockText();
            } else if (type === 'PHARMACY_GROUP_PUBLIC_NAME') {
                state.pharmacy_group_public_name = emptySimpleContentBlockText();
            } else if (type === 'PHARMACY_ON_CALL_URL') {
                state.pharmacy_on_call_url = emptySimpleContentBlockText();
            } else if (type === 'GTAG_GTM_CODE') {
                state.gtm_code = emptySimpleContentBlockText();
            } else if (type === 'BASIC_HOMEPAGE_META_ABSTRACT') {
                state.basic_homepage_meta = emptySimpleContentBlockTextWithImage();
                state.basic_homepage_meta.status_code = StatusEnum.PUBLISHED;
            } else if (type === 'EXTERNAL_HOME_PAGE_LINK') {
                state.external_homepage_button = emptySimpleContentBlockButton();
            } else if (type === 'CATEGORIES_CONTENT_BLOCK') {
                state.categories_content_block = emptySimpleContentBlockTextWithImage();
            } else if (type === 'GENERAL_MESSAGE') {
                state.general_message = emptySimpleContentBlockTextWithTitle();
            } else if (type === 'PRODUCTS_IN_THE_SPOTLIGHT') {
                state.products_in_the_spotlight = emptySimpleContentBlockTextWithTitle();
            } else if (type === 'WELCOME_TXT') {
                state.welcome_txt = emptySimpleContentBlockTextWithTitle();
            } else if (type === 'REIMBURSED_CARE_RATES_DOCUMENT') {
                state.reimbursed_care_rates_doc = emptySimpleContentBlockDocument();
            } else {
                throw new Error('Unsupported simple content block to clear ' + type);
            }
        },
        setSimpleContentBlockButton(state: any, payload: any) {
            const cb = payload.cb as SimpleButtonContentRestDto;
            const type = payload.type as string;

            // todo map gebruiken
            if (type === 'EXTERNAL_HOME_PAGE_LINK') {
                state.external_homepage_button = cb;
            } else {
                throw new Error('Unsupported SimpleContentBlockButton to set ' + type);
            }
        },
        setSimpleContentBlockImage(state: any, payload: any) {
            const cb = payload.cb;
            const type = payload.type;

            // todo map gebruiken
            if (type === 'LOGO_IMG_ID') {
                state.logo_img_id = cb;
            } else if (type === 'STICKY_LOGO') {
                state.sticky_logo = cb;
            } else if (type === 'PHARMACY_WEBSITE_MAIN_IMG_ID') {
                state.pharmacy_website_main_img_id = cb;
            } else {
                throw new Error('Unsupported SimpleContentBlockImage to set ' + type);
            }
        },
        setSimpleContentBlockDocument(state: any, payload: any) {
            const cb = payload.cb;
            const type = payload.type;

            // todo map gebruiken
            if (type === 'REIMBURSED_CARE_RATES_DOCUMENT') {
                state.reimbursed_care_rates_doc = cb;
            } else {
                throw new Error('Unsupported SimpleContentBlockDocument to set ' + type);
            }
        },
        setSimpleContentBlockText(state: any, payload: any) {
            const cb = payload.cb as SimpleTextContentRestDto;
            const type = payload.type as string;

            // todo map gebruiken
            if (type === 'CATALOG_TITLE') {
                state.catalog_title = cb;
            } else if (type === 'PHARMACY_GROUP_PUBLIC_NAME') {
                state.pharmacy_group_public_name = cb;
            } else if (type === 'PHARMACY_ON_CALL_URL') {
                state.pharmacy_on_call_url = cb;
            } else if (type === 'GTAG_GTM_CODE') {
                state.gtm_code = cb;
            } else {
                throw new Error('Unsupported SimpleContentBlockText to set ' + type);
            }
        },
        setSimpleContentBlockTextWithImage(state: any, payload: any) {
            const cb = payload.cb;
            const type = payload.type;

            // todo map gebruiken
            if (type === 'CATEGORIES_CONTENT_BLOCK') {
                state.categories_content_block = cb;
            } else if (type === 'BASIC_HOMEPAGE_META_ABSTRACT') {
                state.basic_homepage_meta = cb;
            } else {
                throw new Error('Unsupported SimpleContentBlockTextWithImage to set ' + type);
            }
        },
        setSimpleContentBlockTextWithTitle(state: any, payload: any) {
            const cb = payload.cb as SimpleTextWithTitleContentRestDto;
            const type = payload.type as string;

            // todo map gebruiken
            if (type === 'GENERAL_MESSAGE') {
                state.general_message = cb;
            } else if (type === 'WELCOME_TXT') {
                state.welcome_txt = cb;
            } else if (type === 'PRODUCTS_IN_THE_SPOTLIGHT') {
                state.products_in_the_spotlight = cb;
            } else {
                throw new Error('Unsupported SimpleContentBlockTextWithTitle to set ' + type);
            }
        },
    },
    getters: {
        getSimpleContentBlockButton: (state: any) => (type: string): SimpleButtonContentRestDto => {
            // todo map gebruiken
            if (type === 'EXTERNAL_HOME_PAGE_LINK') {
                return state.external_homepage_button;
            } else {
                throw new Error('Unsupported SimpleContentBlockButton to get ' + type);
            }
        },
        getSimpleContentBlockImage: (state: any) => (type: string): SimpleImageContentRestDto => {
            // todo map gebruiken
            if (type === 'LOGO_IMG_ID') {
                return state.logo_img_id;
            } else if (type === 'PHARMACY_WEBSITE_MAIN_IMG_ID') {
                return state.pharmacy_website_main_img_id;
            } else if (type === 'STICKY_LOGO') {
                return state.sticky_logo;
            } else {
                throw new Error('Unsupported SimpleContentBlockImage to get ' + type);
            }
        },
        getSimpleContentBlockDocument: (state: any) => (type: string): SimpleDocumentContentRestDto => {
            // todo map gebruiken
            if (type === 'REIMBURSED_CARE_RATES_DOCUMENT') {
                return state.reimbursed_care_rates_doc;
            } else {
                throw new Error('Unsupported SimpleContentBlockImage to get ' + type);
            }
        },
        getSimpleContentBlockText: (state: any) => (type: string): SimpleTextContentRestDto => {
            // todo map gebruiken
            if (type === 'CATALOG_TITLE') {
                return state.catalog_title;
            } else if (type === 'PHARMACY_GROUP_PUBLIC_NAME') {
                return state.pharmacy_group_public_name;
            } else if (type === 'PHARMACY_ON_CALL_URL') {
                return state.pharmacy_on_call_url;
            } else if (type === 'GTAG_GTM_CODE') {
                return state.gtm_code;
            } else {
                throw new Error('Unsupported SimpleContentBlockText to get ' + type);
            }
        },
        getSimpleContentBlockTextWithImage: (state: any) => (type: string): SimpleTextWithImageContentRestDto => {
            // todo map gebruiken
            if (type === 'CATEGORIES_CONTENT_BLOCK') {
                return state.categories_content_block;
            } else if (type === 'BASIC_HOMEPAGE_META_ABSTRACT') {
                return state.basic_homepage_meta;
            } else {
                throw new Error('Unsupported SimpleContentBlockTextWithImage to get ' + type);
            }
        },
        getSimpleContentBlockTextWithTitle: (state: any) => (type: string): SimpleTextWithTitleContentRestDto => {
            // todo map gebruiken
            if (type === 'GENERAL_MESSAGE') {
                return state.general_message;
            } else if (type === 'PRODUCTS_IN_THE_SPOTLIGHT') {
                return state.products_in_the_spotlight;
            } else if (type === 'WELCOME_TXT') {
                return state.welcome_txt;
            } else {
                throw new Error('Unsupported SimpleContentBlockTextWithTitle to get ' + type);
            }
        },
    }
}
