/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Content Block API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CodeDescriptionRestDto } from '../models';
/**
 * ContentBlockEnumEndpointsApi - axios parameter creator
 * @export
 */
export const ContentBlockEnumEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.ENUM.04 Find content block structure type enums
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [filter_is_supported_on_page] If true, filter the result to those types that are supported on a content page. If false, the other way around.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findContentBlockStructureTypeEnums: async (x_dp_customer_code?: string|null, filter_is_supported_on_page?: boolean|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/content_block/v1/enum/content_block_structure_type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_is_supported_on_page || typeof(filter_is_supported_on_page) === "boolean") {
                localVarQueryParameter['filter_is_supported_on_page'] = filter_is_supported_on_page;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentBlockEnumEndpointsApi - functional programming interface
 * @export
 */
export const ContentBlockEnumEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.ENUM.04 Find content block structure type enums
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [filter_is_supported_on_page] If true, filter the result to those types that are supported on a content page. If false, the other way around.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findContentBlockStructureTypeEnums(_axios: AxiosInstance, x_dp_customer_code?: string|null, filter_is_supported_on_page?: boolean|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CodeDescriptionRestDto>>> {
            const localVarAxiosArgs = await ContentBlockEnumEndpointsApiAxiosParamCreator(configuration).findContentBlockStructureTypeEnums(x_dp_customer_code, filter_is_supported_on_page, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ContentBlockEnumEndpointsApi - object-oriented interface
 * @export
 * @class ContentBlockEnumEndpointsApi
 * @extends {BaseAPI}
 */
export class ContentBlockEnumEndpointsApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.CMS.ENUM.04 Find content block structure type enums
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {boolean} [filter_is_supported_on_page] If true, filter the result to those types that are supported on a content page. If false, the other way around.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEnumEndpointsApi
     */
     public findContentBlockStructureTypeEnums(x_dp_customer_code?: string|null, filter_is_supported_on_page?: boolean|null, options?: any) {
        return ContentBlockEnumEndpointsApiFp(this.configuration).findContentBlockStructureTypeEnums(this.axios, x_dp_customer_code, filter_is_supported_on_page, options).then((request) => request(this.axios, this.basePath));
    }

}
