/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum ActionEnum {
    MOVE_TO = 'MOVE_TO',
    COPY_TO = 'COPY_TO',
    HIDE = 'HIDE',
}