
import {defineComponent} from "vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import SnippetMetaData from "@/components/layouts/content-pages/snippets/SnippetMetaData.vue";

export default defineComponent({
  name: "SnippetDetail",
  components: {
    SnippetMetaData,
    BaseTitle,
  },
  props: {
    id: {
      type: String,
    },
    mode: {
      type: String,
    },
  },
});
