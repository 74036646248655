
import {defineComponent} from "vue";
import {CustomerProductOverviewRestDto} from "@/api/pharma-cpc-product-mgmt/models";

export default defineComponent({
  name: "ProductsOverviewItem",
  emits: ["delete"],
  props: {
    productItem: {
      type: Object,
      required: true
    }
  },
  computed: {
    product(): CustomerProductOverviewRestDto {
      return this.productItem as CustomerProductOverviewRestDto;
    }
  },
  methods: {
    deleteProduct(productCode: string) {
      this.$emit('delete', productCode);
    },
  }
});
