import {BackgroundImageRestDto,} from "@/api/pharma-cms-content-page/models";
import {BackgroundImageHorizontalPositionEnum} from "@/api/enums/background-image-horizontal-position-enum";
import {BackgroundImageRepeatEnum} from "@/api/enums/background-image-repeat-enum";
import {BackgroundImageSizeEnum} from "@/api/enums/background-image-size-enum";
import {BackgroundImageVerticalPositionEnum} from "@/api/enums/background-image-vertical-position-enum";
import {BackgroundImageAttachmentEnum} from "@/api/enums/background-image-attachment-enum";

export class BackgroundImageRestDtoModel implements BackgroundImageRestDto {
    attachment_code: BackgroundImageAttachmentEnum;
    horizontal_position_code: BackgroundImageHorizontalPositionEnum;
    image_key: string;
    image_url: string;
    repeat_code: BackgroundImageRepeatEnum;
    size_code: BackgroundImageSizeEnum;
    vertical_position_code: BackgroundImageVerticalPositionEnum;

    constructor(
        image_key: string
        , image_url: string
        , horizontal_position_code: BackgroundImageHorizontalPositionEnum
        , vertical_position_code: BackgroundImageVerticalPositionEnum
        , repeat_code: BackgroundImageRepeatEnum
        , size_code: BackgroundImageSizeEnum
        , attachment_code: BackgroundImageAttachmentEnum
    ) {
        this.horizontal_position_code = horizontal_position_code;
        this.vertical_position_code = vertical_position_code;
        this.image_key = image_key;
        this.image_url = image_url;
        this.repeat_code = repeat_code;
        this.size_code = size_code;
        this.attachment_code = attachment_code;
    }

    static createWithDefaults(): BackgroundImageRestDtoModel {
        return new this('' ,''
            , BackgroundImageHorizontalPositionEnum.CENTER
            , BackgroundImageVerticalPositionEnum.CENTER
            , BackgroundImageRepeatEnum.REPEAT
            , BackgroundImageSizeEnum.AUTO
            , BackgroundImageAttachmentEnum.FIXED
        );
    }

}
