
import {defineComponent} from "vue";

export default defineComponent({
  name: "BaseAccordion",
  props: {
    id: {
      type: String,
      required: true,
    }
  }
});
