import {NormalContentBlockModel} from "@/models/api/pharma-cms-content-block/NormalContentBlockModel";
import {
    BannerContentV2RestDto,
    CmsBannerImageRestDto,
    ContentBlockMetadataRestDto,
    MultilingualMax2000FieldRestDto,
    MultilingualMax500FieldRestDto
} from "@/api/pharma-cms-content-block/models";
import {BannerContentV2LinkTypeEnum} from "@/api/enums/banner-content-v2-link-type-enum";
import {PageRefTargetEnum} from "@/api/enums/page-ref-target-enum";
import {StatusEnum} from "@/api/enums/status-enum";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";

export class BannerContentV2RestDtoModel extends NormalContentBlockModel implements BannerContentV2RestDto {
    banner_images: CmsBannerImageRestDto[];
    begin_date?: string | null;
    category_code?: string | null;
    cb_metadata?: ContentBlockMetadataRestDto | null;
    description?: MultilingualMax500FieldRestDto | null;
    end_date?: string | null;
    ext_link_url?: MultilingualMax2000FieldRestDto | null;
    internal_page_id?: MultilingualMax500FieldRestDto | null;
    link_type_code: BannerContentV2LinkTypeEnum;
    page_ref_target?: PageRefTargetEnum | null;
    product_filter_code?: string | null;
    sort_weight?: number | null;
    status_code: StatusEnum;

    constructor(banner_images: CmsBannerImageRestDto[], link_type_code: BannerContentV2LinkTypeEnum, status_code: StatusEnum) {
        super();
        this.banner_images = banner_images;
        this.begin_date = null;
        this.category_code = null;
        this.cb_metadata = null;
        this.description = MultilingualRestDtoModel.createWithDefaults();
        this.end_date = null;
        this.ext_link_url = MultilingualRestDtoModel.createWithDefaults();
        this.internal_page_id = MultilingualRestDtoModel.createWithDefaults();
        this.link_type_code = link_type_code;
        this.page_ref_target = null;
        this.product_filter_code = null;
        this.sort_weight = null;
        this.status_code = status_code;
    }

    public static createWithDefaults(): BannerContentV2RestDtoModel {
        return new this(
            [],
            BannerContentV2LinkTypeEnum.NO_LINK,
            StatusEnum.PUBLISHED
        );
    }
}
