/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */

import {ContentBlockMetadataRestDto} from ".";
import {StatusEnum} from "@/api/enums/status-enum";

/**
 * @export
 * @interface TeamInfoContentRestDto
 */
export interface TeamInfoContentRestDto {
    /**
     * The status of this content block.
     * @type {StatusEnum}
     * @memberof TeamInfoContentRestDto
     */
    status_code: StatusEnum;

    /**
     * Uniquely identifies the pharmacy for which information should be shown.
     * @type {string|null}
     * @memberof TeamInfoContentRestDto
     */
    pharmacy_code?: string|null;

    /**
     * When true, the general team info of the pharmacy should be shown.
     * @type {boolean}
     * @memberof TeamInfoContentRestDto
     */
    is_show_about_us: boolean;

    /**
     * When true, the team members of the pharmacy should be shown.
     * @type {boolean}
     * @memberof TeamInfoContentRestDto
     */
    is_show_team_members: boolean;

    /**
     * The sorting value of this content block.
     * @type {number|null}
     * @memberof TeamInfoContentRestDto
     */
    sort_weight?: number|null;

    /**
     * 
     * @type {ContentBlockMetadataRestDto|null}
     * @memberof TeamInfoContentRestDto
     */
    cb_metadata?: ContentBlockMetadataRestDto|null;

}

/**
 * @export
 * @function cloneTeamInfoContentRestDtoMetaData
 */
export function cloneTeamInfoContentRestDtoMetaData(dto: TeamInfoContentRestDto, source: TeamInfoContentRestDto): void {
    dto.is_show_about_us = source.is_show_about_us;
    dto.is_show_team_members = source.is_show_team_members;
}
