
import {defineComponent} from "vue";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {UIStateDto} from "@/dtos/UIStateDto";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import InputText from "@/components/UI/InputText.vue";
import {
  ProductCustomNamesOverviewRestDtoModel
} from "@/models/api/pharma-cpc-product-mgmt/ProductCustomNamesOverviewRestDtoModel";
import {MultilingualFieldRestDtoModel} from "@/models/api/pharma-order-enum/MultilingualFieldRestDtoModel";
import {CatalogProductsRestService} from "@/services/rest/cpc-mgmt/CatalogProductsRestService";
import {ProductCustomNameRestDtoModel} from "@/models/api/pharma-cpc-product-mgmt/ProductCustomNameRestDtoModel";
import {AbilityContext} from "@/context/AbilityContext";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";

export default defineComponent({
  name: "CustomNames",
  components: {
    AlertError2,
    InputText,
  },
  emits: ["savedSuccessfully"],
  props: {
    productCode: {
      type: String,
      required: true
    },
  },
  computed: {
    AbilityContext() {
      return AbilityContext
    }
  },
  data: () => ({
    componentUI: UIStateDto.createWithDefaults(),

    customNames: ProductCustomNamesOverviewRestDtoModel.createWithDefaults(),
  }),
  mounted() {
    this.reloadContent();
  },
  methods: {
    async reloadContent() {
      this.componentUI
        .setNotReady()
        .clearError();

      try {
        this.customNames = await CatalogProductsRestService.getInstance()
          .findProductCustomNames(this.productCode, this.productCode);
        if (!this.customNames.custom_name) {
          this.customNames.custom_name = ProductCustomNameRestDtoModel.createWithDefaults();
        } else if (!this.customNames.custom_name.name) {
          this.customNames.custom_name.name = MultilingualFieldRestDtoModel.createWithDefaults();
        }
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.componentUI.setReady();
      }
    },
    async submitCustomProductName(): Promise<void> {
      await CatalogProductsRestService.getInstance().syncProductCustomNames(this.productCode, this.customNames.custom_name);
      this.$emit('savedSuccessfully', null);
    },
    async deleteCustomProductName(): Promise<void> {
      if (this.customNames.custom_name?.name) {
        this.customNames.custom_name.name = MultilingualFieldRestDtoModel.createWithDefaults();
        await CatalogProductsRestService.getInstance().syncProductCustomNames(this.productCode, this.customNames.custom_name);
      }
      this.$emit('savedSuccessfully', null);
    },

    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.componentUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.componentUI as UIStateDto))
        .catch(exceptions, true);
    }
  },
});
