
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {
  OrcaReservationCampaignContentRestDto,
  OrcaReservationCampaignRestDto
} from "@/api/pharma-order-orca-customer-mgmt/models";
import InputTextEditor from "@/components/UI/InputTextEditor.vue";

export default defineComponent({
  name: "ReservationCampaignContent",
  components: {InputTextEditor},
  emits: ["submit"],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    }
  },
  computed: {
    ...mapGetters('order_orca_customer_mgmt', ["getReservationCampaign", "getReservationCampaignContent"]),
    reservationCampaign(): OrcaReservationCampaignRestDto {
      return this.getReservationCampaign;
    },
    reservationCampaignContent(): OrcaReservationCampaignContentRestDto {
      return this.getReservationCampaignContent;
    },
  },
  methods: {
    ...mapActions('order_orca_customer_mgmt', [
      "saveReservationCampaignContent"
    ]),
    async submitData() {
      this.$emit('submit');
    }
  }
});
