
import {defineComponent, PropType} from "vue";
import {FullMenuItemRestDto} from "@/api/pharma-cms-menu/models";
import {mapActions} from "vuex";
import {stringHasContent} from "@/helpers/functions/string";
import MenusOverviewItemReferenceInfo from "@/components/layouts/menus/MenusOverviewItemReferenceInfo.vue";
import MenusOverviewItemTarget from "@/components/layouts/menus/MenusOverviewItemTarget.vue";
import {generateContentPagePreviewUrl} from "@/helpers/functions/route";
import {TargetPageInfoRestDto} from "@/api/pharma-cms-content-page/models";
import MenusOverviewItemReferenceEdit from "@/components/layouts/menus/MenusOverviewItemReferenceEdit.vue";
import {StatusEnum} from "@/api/enums/status-enum";
import {MoveActionEnum} from "@/models/enum/MoveActionEnum";
import {CustomerContext} from "@/context/CustomerContext";
import {MenuItemRestDtoModel} from "@/models/api/pharma-cms-menu/MenuItemRestDtoModel";
import {MenuRestService} from "@/services/rest/cms-menu/MenuRestService";
import BaseIconIsActive from "@/components/UI/BaseIconIsActive.vue";
import BaseMoveButtons from "@/components/UI/BaseMoveButtons.vue";

export default defineComponent({
  name: "MenusOverviewItem",
  emits: ["exceptions", "delete", "move"],
  components: {
    BaseMoveButtons,
    BaseIconIsActive,
    MenusOverviewItemReferenceEdit,
    MenusOverviewItemTarget,
    MenusOverviewItemReferenceInfo
  },
  props: {
    menuId: {
      type: String,
      required: false
    },
    fullMenuItem: {
      type: Object as PropType<FullMenuItemRestDto>,
      required: true
    },
    isSubMenu: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSubMenuItem: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      isReadyPageReference: false as boolean,
      isReadySubMenu: false as boolean,
      linkedContentPage: {} as TargetPageInfoRestDto,
      subMenu: [] as FullMenuItemRestDto[],
    }
  },
  mounted() {
    if (this.hasSubMenu) {
      this.searchSubMenu();
    }
    if (this.hasPageReference) {
      this.getLinkedContentPage();
    }
  },
  computed: {
    MoveActionEnum() {
      return MoveActionEnum;
    },
    StatusEnum() {
      return StatusEnum;
    },
    editRoute(): object {
      return {
        name: this.isSubMenu ? 'edit-sub-menu-item' : 'edit-menu-item',
        params: {menu_id: this.menuId, menu_item_id: this.menuItemId}
      }
    },
    menuItem(): MenuItemRestDtoModel {
      return this.fullMenuItem.menu_item ?? MenuItemRestDtoModel.createWithDefaults();
    },
    menuItemId(): string {
      return this.fullMenuItem.id;
    },
    pageReference(): object {
      const page_reference = {
        name: null as string | null,
        icon: null as string | null,
        url: null as string | null,
        page_id: null as string | null
      };
      const domain = (CustomerContext.hasMainDomain())
        ? 'https://' + CustomerContext.getMainDomain()
        : null as string | null;

      if (this.menuItem.page_reference?.internal_page_reference) {
        page_reference.name = (this.linkedContentPage && this.linkedContentPage.name)
          ? this.linkedContentPage.name
          : this.menuItem.page_reference.internal_page_reference.page_id ?? null;
        page_reference.icon = 'article';
        page_reference.url = (CustomerContext.hasMainDomain() && this.menuItem.page_reference.internal_page_reference.page_id)
          ? generateContentPagePreviewUrl(this.menuItem.page_reference.internal_page_reference.page_id)
          : null;
        if (this.canEditContentPage) {
          page_reference.page_id = this.menuItem.page_reference.internal_page_reference.page_id ?? null;
        }
      } else if (this.menuItem.page_reference?.external_url) {
        page_reference.name = this.menuItem.page_reference.external_url;
        page_reference.icon = 'link';
        page_reference.url = (CustomerContext.hasMainDomain() && this.menuItem.page_reference.external_url.startsWith('/'))
          ? domain + this.menuItem.page_reference.external_url
          : this.menuItem.page_reference.external_url;
      }

      return page_reference;
    },
    tableCellClasses(): object {
      return {
        'border-top': !this.isSubMenuItem,
        'border-bottom-0': this.hasSubMenu || this.isSubMenuItem,
        'pt-1 pb-1': this.isSubMenuItem,
        'pb-0': this.hasSubMenu
      }
    },
    canEditContentPage(): boolean {
      return !this.isDeletedContentPage && !this.isLinkedContentPage;
    },
    hasPageReference(): boolean {
      return !!this.menuItem.page_reference;
    },
    hasSubMenu(): boolean {
      return !this.isSubMenuItem && stringHasContent(this.menuItem.submenu_id);
    },
    isDeletedContentPage(): boolean {
      return !!this.linkedContentPage.is_page_deleted;
    },
    isLinkedContentPage(): boolean {
      return !!this.linkedContentPage.is_linked_content;
    },
    isNotPublishedContentPage(): boolean {
      return this.linkedContentPage.status_code === StatusEnum.NOT_PUBLISHED;
    }
  },
  methods: {
    ...mapActions('cms_content_page', ["searchContentPageOverviewCb"]),
    async getLinkedContentPage() {
      this.isReadyPageReference = false;

      try {
        if (this.menuItem.page_reference?.internal_page_reference) {
          this.linkedContentPage = await this.searchContentPageOverviewCb(this.menuItem.page_reference.internal_page_reference.page_id);
        }
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.isReadyPageReference = true;
      }
    },
    deleteItem(menuItemId: string): void {
      this.$emit('delete', menuItemId);
    },
    async moveItem(menuItemId: string, moveAction: MoveActionEnum): Promise<void> {
      await this.$emit('move', menuItemId, moveAction);
    },
    setExceptions(exceptions: unknown): void {
      this.$emit('exceptions', exceptions);
    },
    async searchSubMenu() {
      this.isReadySubMenu = false;

      try {
        this.subMenu = await MenuRestService.getInstance().findMenuItemsById(this.menuItem.submenu_id);
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.isReadySubMenu = true;
      }
    },
  },
});
