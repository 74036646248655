<template>
  <div class="card mb-4">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h3 class="text-primary mb-0">
        {{ this.title }}
      </h3>
      <div class="d-flex justify-content-center m-0">
        <div v-if="orderMailToLink !== null && orderMailToLink !== undefined" class="ms-2 text-center">
          <a :href="this.orderMailToLink" class="btn btn-primary fw-700">Bestel hier</a>
        </div>
        <div v-if="moreInfoMailToLink !== null && moreInfoMailToLink !== undefined" class="ms-2 text-center">
          <a :href="this.moreInfoMailToLink" class="btn btn-primary fw-700">Meer info</a>
        </div>
      </div>
    </div>
    <div class="card-body">
      <slot></slot>
      <div v-if="this.setupPrice || this.licensePrice" class="row">
        <div class="col-xxl-6">
          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="this.setupPrice">
              <th scope="row">Eénmalige opzetprijs</th>
              <td>{{ this.setupPrice }}</td>
            </tr>
            <tr v-if="this.licensePrice">
              <th scope="row">Licentieprijs</th>
              <td>{{ this.licensePrice }}<span v-if="this.licensePriceSuffix !== null">{{
                  this.licensePriceSuffix
                }}</span></td>
            </tr>
            </tbody>
          </table>
          <span v-if="this.priceInfo !== null" class="mt-2">
              {{ this.priceInfo }}
          </span>
        </div>
      </div>
      <div v-if="this.availableFor.length > 0" class="row mt-3">
        <div class="col-xxl-6 fs-7 fst-italic">
          Beschikbaar voor volgende website licenties:
          <lu>
            <li v-for="licenseDesc in this.availableFor" :key="licenseDesc">{{ licenseDesc }}</li>
          </lu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "OptionPackCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    setupPrice: {
      type: String,
      required: false,
    },
    licensePrice: {
      type: String,
      required: false,
    },
    licensePriceSuffix: {
      type: String,
      required: false,
    },
    priceInfo: {
      type: String,
      required: false,
    },
    orderMailToLink: {
      type: String,
      required: true,
    },
    moreInfoMailToLink: {
      type: String,
      required: false,
    },
    availableFor: {
      type: Array,
      default: () => [],
      validator: (value) => value.every(item => typeof item === 'string'),
      required: false,
    },
  },
};
</script>
