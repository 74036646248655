/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 * Generated: Fri, 09 Jun 2023 12:38:24 +0000
 */
export enum DpModuleEnum {
    ORDER = 'ORDER',
    CPC = 'CPC',
    AUTH = 'AUTH',
}