/* eslint-disable no-unused-vars */
export enum LocalStorageKeyEnum {
    ACCESS_TOKEN = 'access_token',
    CONFIG = 'config',
    CURRENT_CUSTOMER_CODE = 'currentCustomerCode',
    CURRENT_PHARMACY_CODE = 'currentPharmacyCode',
    ID_TOKEN = 'id_token',
    OAUTH2_TOKEN = 'oauth2_token',
    TOKEN_EXPIRATION = 'tokenExpiration',
}
