
import {defineComponent} from "vue";
import BannerDetail from '@/components/layouts/banners/BannerDetail.vue';

export default defineComponent({
  name: "CustomerBannerDetail",
  components: {BannerDetail},
  props: ["mode"],
  data() {
    return {
      cbId: this.$route.params.id as String,
    };
  },
});
