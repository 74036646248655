import {RestService} from "@/services/rest/RestService";
import {Pp2ProductAPIApi} from "@/api/pharma-pim-pp2";
import {
    IPrinsLaboRestDto, IPrinsPriceOverviewResultsRestDto,
    PagedPp2ProductOverviewResultsRestDto,
    Pp2ProductDetailRestDto,
} from "@/api/pharma-pim-pp2/models";
import {PimProductsCriteriaModel} from "@/models/criteria/PimProductsCriteriaModel";
import {PimProductsPagingModel} from "@/models/paging/PimProductsPagingModel";
import {Pp2ProductsCriteriaRestDtoModel} from "@/models/api/pharma-pim-pp2/Pp2ProductsCriteriaRestDtoModel";
import {downloadBlobAsFile} from "@/helpers/functions/file";
import {AxiosResponse} from "axios";

export class PimProductsRestService extends RestService {

    private apiService: Pp2ProductAPIApi;

    protected constructor() {
        super();

        this.apiService = new Pp2ProductAPIApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().pimUrl);
    }

    public async findPimProductOverview(products_criteria: PimProductsCriteriaModel, products_paging: PimProductsPagingModel): Promise<PagedPp2ProductOverviewResultsRestDto> {
        const pp2_products_criteria = new Pp2ProductsCriteriaRestDtoModel(
            products_criteria,
            products_paging,
            undefined
        );
        const response = await this.apiService
            .findPp2ProductsByPp2ProductsCriteriaRestDto(pp2_products_criteria);

        return response.data;
    }

    public async exportPimProductOverviewAsCsv(products_criteria: PimProductsCriteriaModel, filename: string = 'pim_product_overview.csv'): Promise<void> {
        const pp2_products_criteria = new Pp2ProductsCriteriaRestDtoModel(
            products_criteria,
            undefined,
            'text/csv'
        );
        const response: AxiosResponse<any, any> = await this.apiService
            .findPp2ProductsByPp2ProductsCriteriaRestDto(pp2_products_criteria);

        downloadBlobAsFile(response.data as Blob, filename);
    }

    public async findPimProductForCnkCode(cnk_code: number): Promise<Pp2ProductDetailRestDto> {
        const response = await this.apiService
            .getPp2ProductForCnkCode(cnk_code);

        return response.data;
    }

    public async findIPrinsProductManufacturer(cnk_code: number): Promise<IPrinsLaboRestDto> {
        const response = await this.apiService
            .getIPrinsProductManufacturer(cnk_code);

        return response.data;
    }

    public async findIPrinsProductPrices(cnk_code: number): Promise<IPrinsPriceOverviewResultsRestDto> {
        const response = await this.apiService
            .getIPrinsProductPrices(cnk_code);

        return response.data;
    }
}
