/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Customer-Product-Catalog Products
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { MainCategoriesResultsRestDto } from '../models';
import { ProductCategoryFilterCriteriaRestDto } from '../models';
import { ProductCategoryOverviewRestDto } from '../models';
import { ProductCategoryRestDto } from '../models';
/**
 * ProductCategoryAPIApi - axios parameter creator
 * @export
 */
export const ProductCategoryAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for retrieving the product categories.
         * @summary REST.API.CPC.CAT.01 Find the main product categories
         * @param {string} customer_code Identifies the product catalog that is being accessed.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {string} [filter_catalog_subset_product_filter_code] A product filter code used to define a subset of the catalog to work with (for example a subset of all products and categories for healthcare professionals.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMainProductCategories: async (customer_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, filter_catalog_subset_product_filter_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer_code' is not null or undefined
            if (customer_code === null || customer_code === undefined) {
                throw new RequiredError('customer_code','Required parameter customer_code was null or undefined when calling findMainProductCategories.');
            }
            const localVarPath = `/product_category/v1/catalog/{customer_code}/category/main`
                .replace(`{${"customer_code"}}`, encodeURIComponent(String(customer_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (!!filter_catalog_subset_product_filter_code || typeof(filter_catalog_subset_product_filter_code) === "boolean") {
                localVarQueryParameter['filter_catalog_subset_product_filter_code'] = filter_catalog_subset_product_filter_code;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving the product categories.
         * @summary REST.API.CPC.CAT.04 Find the categories
         * @param {string} customer_code Identifies the product catalog that is being accessed.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {string} [filter_category_code] Limit the result to the specified categories.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductCategories: async (customer_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, filter_category_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer_code' is not null or undefined
            if (customer_code === null || customer_code === undefined) {
                throw new RequiredError('customer_code','Required parameter customer_code was null or undefined when calling findProductCategories.');
            }
            const localVarPath = `/product_category/v1/catalog/{customer_code}/category`
                .replace(`{${"customer_code"}}`, encodeURIComponent(String(customer_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (!!filter_category_code || typeof(filter_category_code) === "boolean") {
                localVarQueryParameter['filter_category_code'] = filter_category_code;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving the tree of product categories.
         * @summary REST.API.CPC.CAT.03 Find the categories tree structure
         * @param {string} customer_code Identifies the product catalog that is being accessed.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductCategoriesTree: async (customer_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer_code' is not null or undefined
            if (customer_code === null || customer_code === undefined) {
                throw new RequiredError('customer_code','Required parameter customer_code was null or undefined when calling findProductCategoriesTree.');
            }
            const localVarPath = `/product_category/v1/catalog/{customer_code}/category/tree`
                .replace(`{${"customer_code"}}`, encodeURIComponent(String(customer_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving the criteria for a product categories.
         * @summary REST.API.CPC.CAT.02 Find the filter criteria for a product category.
         * @param {string} customer_code Identifies the product catalog that is being accessed.
         * @param {string} category_code The unique identification of product category.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {string} [filter_catalog_subset_product_filter_code] A product filter code used to define a subset of the catalog to work with (for example a subset of all products and categories for healthcare professionals.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductCategoryCriteria: async (customer_code: string, category_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, filter_catalog_subset_product_filter_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer_code' is not null or undefined
            if (customer_code === null || customer_code === undefined) {
                throw new RequiredError('customer_code','Required parameter customer_code was null or undefined when calling findProductCategoryCriteria.');
            }
            // verify required parameter 'category_code' is not null or undefined
            if (category_code === null || category_code === undefined) {
                throw new RequiredError('category_code','Required parameter category_code was null or undefined when calling findProductCategoryCriteria.');
            }
            const localVarPath = `/product_category/v1/catalog/{customer_code}/category/filter_criteria/{category_code}`
                .replace(`{${"customer_code"}}`, encodeURIComponent(String(customer_code)))
                .replace(`{${"category_code"}}`, encodeURIComponent(String(category_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (!!filter_catalog_subset_product_filter_code || typeof(filter_catalog_subset_product_filter_code) === "boolean") {
                localVarQueryParameter['filter_catalog_subset_product_filter_code'] = filter_catalog_subset_product_filter_code;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductCategoryAPIApi - functional programming interface
 * @export
 */
export const ProductCategoryAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for retrieving the product categories.
         * @summary REST.API.CPC.CAT.01 Find the main product categories
         * @param {string} customer_code Identifies the product catalog that is being accessed.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {string} [filter_catalog_subset_product_filter_code] A product filter code used to define a subset of the catalog to work with (for example a subset of all products and categories for healthcare professionals.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMainProductCategories(_axios: AxiosInstance, customer_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, filter_catalog_subset_product_filter_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MainCategoriesResultsRestDto>> {
            const localVarAxiosArgs = await ProductCategoryAPIApiAxiosParamCreator(configuration).findMainProductCategories(customer_code, x_dp_customer_code, x_dp_language, filter_catalog_subset_product_filter_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving the product categories.
         * @summary REST.API.CPC.CAT.04 Find the categories
         * @param {string} customer_code Identifies the product catalog that is being accessed.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {string} [filter_category_code] Limit the result to the specified categories.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProductCategories(_axios: AxiosInstance, customer_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, filter_category_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductCategoryOverviewRestDto>>> {
            const localVarAxiosArgs = await ProductCategoryAPIApiAxiosParamCreator(configuration).findProductCategories(customer_code, x_dp_customer_code, x_dp_language, filter_category_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving the tree of product categories.
         * @summary REST.API.CPC.CAT.03 Find the categories tree structure
         * @param {string} customer_code Identifies the product catalog that is being accessed.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProductCategoriesTree(_axios: AxiosInstance, customer_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductCategoryRestDto>> {
            const localVarAxiosArgs = await ProductCategoryAPIApiAxiosParamCreator(configuration).findProductCategoriesTree(customer_code, x_dp_customer_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving the criteria for a product categories.
         * @summary REST.API.CPC.CAT.02 Find the filter criteria for a product category.
         * @param {string} customer_code Identifies the product catalog that is being accessed.
         * @param {string} category_code The unique identification of product category.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {string} [filter_catalog_subset_product_filter_code] A product filter code used to define a subset of the catalog to work with (for example a subset of all products and categories for healthcare professionals.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProductCategoryCriteria(_axios: AxiosInstance, customer_code: string, category_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, filter_catalog_subset_product_filter_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductCategoryFilterCriteriaRestDto>> {
            const localVarAxiosArgs = await ProductCategoryAPIApiAxiosParamCreator(configuration).findProductCategoryCriteria(customer_code, category_code, x_dp_customer_code, x_dp_language, filter_catalog_subset_product_filter_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProductCategoryAPIApi - object-oriented interface
 * @export
 * @class ProductCategoryAPIApi
 * @extends {BaseAPI}
 */
export class ProductCategoryAPIApi extends BaseAPI {
    /**
     * API endpoint responsible for retrieving the product categories.
     * @summary REST.API.CPC.CAT.01 Find the main product categories
     * @param {string} customer_code Identifies the product catalog that is being accessed.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {string} [filter_catalog_subset_product_filter_code] A product filter code used to define a subset of the catalog to work with (for example a subset of all products and categories for healthcare professionals.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoryAPIApi
     */
     public findMainProductCategories(customer_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, filter_catalog_subset_product_filter_code?: string|null, options?: any) {
        return ProductCategoryAPIApiFp(this.configuration).findMainProductCategories(this.axios, customer_code, x_dp_customer_code, x_dp_language, filter_catalog_subset_product_filter_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving the product categories.
     * @summary REST.API.CPC.CAT.04 Find the categories
     * @param {string} customer_code Identifies the product catalog that is being accessed.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {string} [filter_category_code] Limit the result to the specified categories.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoryAPIApi
     */
     public findProductCategories(customer_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, filter_category_code?: string|null, options?: any) {
        return ProductCategoryAPIApiFp(this.configuration).findProductCategories(this.axios, customer_code, x_dp_customer_code, x_dp_language, filter_category_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving the tree of product categories.
     * @summary REST.API.CPC.CAT.03 Find the categories tree structure
     * @param {string} customer_code Identifies the product catalog that is being accessed.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoryAPIApi
     */
     public findProductCategoriesTree(customer_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any) {
        return ProductCategoryAPIApiFp(this.configuration).findProductCategoriesTree(this.axios, customer_code, x_dp_customer_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving the criteria for a product categories.
     * @summary REST.API.CPC.CAT.02 Find the filter criteria for a product category.
     * @param {string} customer_code Identifies the product catalog that is being accessed.
     * @param {string} category_code The unique identification of product category.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {string} [filter_catalog_subset_product_filter_code] A product filter code used to define a subset of the catalog to work with (for example a subset of all products and categories for healthcare professionals.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoryAPIApi
     */
     public findProductCategoryCriteria(customer_code: string, category_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, filter_catalog_subset_product_filter_code?: string|null, options?: any) {
        return ProductCategoryAPIApiFp(this.configuration).findProductCategoryCriteria(this.axios, customer_code, category_code, x_dp_customer_code, x_dp_language, filter_catalog_subset_product_filter_code, options).then((request) => request(this.axios, this.basePath));
    }

}
