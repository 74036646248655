import {Singleton} from "@/patterns/Singleton";
import axios, {AxiosInstance, AxiosRequestConfig} from "axios";
import {createInstance} from "@/helpers/axios";
import {registerErrorInterceptor} from "@/helpers/interceptors/response/error-interceptor";
import {registerErrorInterceptorV2} from "@/helpers/interceptors/response/error-interceptor-v2";
import {registerAuthInterceptor} from "@/helpers/interceptors/request/auth-interceptor";
import {registerCustomerCodeInterceptor} from "@/helpers/interceptors/request/x-dp-customer-code-interceptor";
import {registerLanguageInterceptor} from "@/helpers/interceptors/request/x-dp-language-interceptor";
import {registerAcceptInterceptor} from "@/helpers/interceptors/request/accept-interceptor";

export default class Axios extends Singleton {
    private readonly axiosInstance: AxiosInstance;
    private readonly dpAxiosInstance: AxiosInstance;
    private readonly dpAxiosV2Instance: AxiosInstance;

    protected constructor() {
        super();

        this.axiosInstance = this.createInstance();
        this.dpAxiosInstance = createInstance();
        this.dpAxiosV2Instance = this.createDpAxiosV2Instance();
    }

    public getAxiosInstance(): AxiosInstance {
        return this.axiosInstance;
    }

    public getDpAxiosInstance(): AxiosInstance {
        return this.dpAxiosInstance;
    }

    public getDpAxiosV2Instance(): AxiosInstance {
        return this.dpAxiosV2Instance;
    }

    protected createInstance(config?: AxiosRequestConfig): AxiosInstance {
        const instance = axios.create(config);

        registerErrorInterceptor(instance);

        instance.defaults.headers.common['Content-Type'] = 'application/json';

        return instance;
    }

    protected createDpAxiosV2Instance(config?: AxiosRequestConfig): AxiosInstance {
        const instance = axios.create(config);

        registerErrorInterceptorV2(instance);
        registerAuthInterceptor(instance);
        registerCustomerCodeInterceptor(instance);
        registerLanguageInterceptor(instance);
        registerAcceptInterceptor(instance);

        instance.defaults.headers.common['Content-Type'] = 'application/json';

        return instance;
    }
}
