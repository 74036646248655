import {Context} from "@/patterns/Context";

export class I18nContext extends Context {

    private static defaultLanguage: string;
    private static languages: string[];

    public static getDefaultLanguage(): string {
        return this.defaultLanguage;
    }

    public static setDefaultLanguage(value: string): void {
        this.defaultLanguage = value;
    }

    public static getLanguages(): string[] {
        return this.languages;
    }

    public static setLanguages(value: string[]): void {
        this.languages = value;
    }

    public static getDefaultApiLanguageKey(): string {
        // TODO: change this to check for multilingual feature once AbilityContext is ready
        if (this.languages.length == 1) {
            return this.languages[0];
        }
        if (this.languages.length > 1) {
            return this.getDefaultLanguage();
        }

        return 'nl';
    }
}
