import {RestService} from "@/services/rest/RestService";

export class TrainingsRestService extends RestService {

    public async findTrainingsData(): Promise<Object> {
        const response = await this.getAxiosInstance().get('https://cdn.dp2.digital-pharma.eu/trainings/trainings.json');

        return response.data;
    }
}
