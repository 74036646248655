import bannersModule from "@/store/pharma-cms-content-block/banners/banners";
import {ContentBlockEndpointsApiService, ContentBlockLibraryEndpointsApiService} from "@/main";
import {
    ButtonContentRestDto,
    ContentBlockResponseRestDto,
    ContentOverviewRestDto,
    HtmlContentRestDto,
    ImageContentRestDto,
    DocumentContentRestDto,
    PageReferencesContentRestDto,
    ProductFilterContentRestDto,
    RowSeparatorContentRestDto,
    TextContentRestDto,
    TextWithImageContentRestDto,
    TextWithTitleContentRestDto
} from "@/api/pharma-cms-content-block/models";
import {isValidButton} from "@/helpers/functions/content_blocks/validation";
import {
    emptyNormalContentBlockBanner,
    emptyNormalContentBlockButton, emptyNormalContentBlockDocument,
    emptyNormalContentBlockHtml,
    emptyNormalContentBlockImage,
    emptyNormalContentBlockPageReferences,
    emptyNormalContentBlockProductFilter,
    emptyNormalContentBlockRowSeparator,
    emptyNormalContentBlockText,
    emptyNormalContentBlockTextWithImage,
    emptyNormalContentBlockTextWithTitle
} from "@/helpers/functions/content_blocks/empty_content";
import {ContentBlockTypesEnum} from "@/helpers/functions/enum";
import {StatusEnum} from "@/api/enums/status-enum";

export default {
    modules: {
        banners: bannersModule,
    },
    state() {
        return {
            cb_id: "" as string,

            cb_button: {} as ButtonContentRestDto,
            cb_call_to_action: {} as ButtonContentRestDto,
            cb_document: {} as DocumentContentRestDto,
            cb_html: {} as HtmlContentRestDto,
            cb_page_references: {} as PageReferencesContentRestDto,
            cb_product_filter: {} as ProductFilterContentRestDto,
            cb_row_separator: {} as RowSeparatorContentRestDto,
            cb_text: {} as TextContentRestDto,
            cb_text_card: {} as TextContentRestDto,
            cb_overview: [] as ContentOverviewRestDto[],

            cbs_image: {} as object,
            cbs_docs: {} as object,
            cbs_text_with_image: {} as object,
            cbs_text_with_title: {} as object,
        }
    },
    actions: {
        clearNormalContentBlock(context: any, type: string) {
            context.commit("clearNormalContentBlock", type);
        },
        clearNormalContentBlockImage(context: any, type: string) {
            context.commit("clearNormalContentBlockImage", type);
        },
        clearNormalContentBlockDocument(context: any, type: string) {
            context.commit("clearNormalContentBlockDocument", type);
        },
        clearNormalContentBlockTextWithImage(context: any, type: string) {
            context.commit("clearNormalContentBlockTextWithImage", type);
        },
        clearNormalContentBlockTextWithTitle(context: any, type: string) {
            context.commit("clearNormalContentBlockTextWithTitle", type);
        },

        async createLibContentBlockImage(context: any, payload: any) {
            const library_code = payload.library_code as string;
            const type = payload.type as string;
            const cb = context.getters.getNormalContentBlockImage(type) as ImageContentRestDto;

            await ContentBlockLibraryEndpointsApiService.createLibContentBlockImage(cb, library_code);
        },
        async createLibContentBlockDocument(context: any, payload: any) {
            throw new Error('Not implemented');
        },
        async createLibContentBlockTextWithImage(context: any, payload: any) {
            const lib_code = payload.lib_code as string;
            const type = payload.type as string;

            const cb = JSON.parse(JSON.stringify(context.getters.getNormalContentBlockTextWithImage(type))) as TextWithImageContentRestDto;

            //TODO add middleware
            cb.button = isValidButton(cb.button) ? cb.button : null;

            await ContentBlockLibraryEndpointsApiService.createLibContentBlockTextWithImage(cb, lib_code);
        },
        async createLibContentBlockTextWithTitle(context: any, payload: any) {
            const lib_code = payload.lib_code as string;
            const type = payload.type as string;

            const cb = JSON.parse(JSON.stringify(context.getters.getNormalContentBlockTextWithTitle(type))) as TextWithTitleContentRestDto;

            await ContentBlockLibraryEndpointsApiService.createLibContentBlockTextWithTitle(cb, lib_code);
        },

        async createNormalContentBlockButton(context: any, type: string): Promise<ContentBlockResponseRestDto> {
            const cb = JSON.parse(JSON.stringify(context.getters.getNormalContentBlockButton(type))) as ButtonContentRestDto;

            const response = await ContentBlockEndpointsApiService.createNormalContentBlockButton(cb, type);
            return response.data;
        },
        async createNormalContentBlockDocument(context: any, type: string): Promise<ContentBlockResponseRestDto> {
            const cb = JSON.parse(JSON.stringify(context.getters.getNormalContentBlockDocument(type))) as DocumentContentRestDto;

            const response = await ContentBlockEndpointsApiService.createNormalContentBlockDocument(cb, type);
            return response.data;
        },
        async createNormalContentBlockImage(context: any, type: string): Promise<ContentBlockResponseRestDto> {
            const cb = JSON.parse(JSON.stringify(context.getters.getNormalContentBlockImage(type))) as ImageContentRestDto;

            const response = await ContentBlockEndpointsApiService.createNormalContentBlockImage(cb, type);

            return response.data;
        },
        async createNormalContentBlockPageReferences(context: any, type: string): Promise<ContentBlockResponseRestDto> {
            const cb = JSON.parse(JSON.stringify(context.getters.getNormalContentBlockPageReferences(type))) as PageReferencesContentRestDto;

            const response = await ContentBlockEndpointsApiService.createNormalContentBlockPageReferences(cb, type);
            return response.data;
        },
        async createNormalContentBlockProductFilter(context: any, type: string): Promise<ContentBlockResponseRestDto> {
            //product filters are saved through the CPC
            const cb = JSON.parse(JSON.stringify(context.getters.getNormalContentBlockProductFilter(type))) as ProductFilterContentRestDto;

            const response = await ContentBlockEndpointsApiService.createNormalContentBlockProductFilter(cb, type);
            return response.data;
        },
        async createNormalContentBlockRowSeparator(context: any, type: string): Promise<ContentBlockResponseRestDto> {
            const cb = JSON.parse(JSON.stringify(context.getters.getNormalContentBlockRowSeparator(type))) as RowSeparatorContentRestDto;

            const response = await ContentBlockEndpointsApiService.createNormalContentBlockRowSeparator(cb, type);
            return response.data;
        },
        async createNormalContentBlockText(context: any, type: string): Promise<ContentBlockResponseRestDto> {
            const cb = JSON.parse(JSON.stringify(context.getters.getNormalContentBlockText(type))) as TextContentRestDto;

            const response = await ContentBlockEndpointsApiService.createNormalContentBlockText(cb, type);
            return response.data;
        },
        async createNormalContentBlockTextWithImage(context: any, type: string): Promise<ContentBlockResponseRestDto> {
            const cb = JSON.parse(JSON.stringify(context.getters.getNormalContentBlockTextWithImage(type))) as TextWithImageContentRestDto;

            //TODO make middleware
            cb.button = isValidButton(cb.button) ? cb.button : null;

            const response = await ContentBlockEndpointsApiService.createNormalContentBlockTextWithImage(cb, type);
            return response.data;
        },
        async createNormalContentBlockTextWithTitle(context: any, type: string): Promise<ContentBlockResponseRestDto> {
            const cb = JSON.parse(JSON.stringify(context.getters.getNormalContentBlockTextWithTitle(type))) as TextWithTitleContentRestDto;

            const response = await ContentBlockEndpointsApiService.createNormalContentBlockTextWithTitle(cb, type);

            return response.data;
        },
        async createNormalContentBlockHtml(context: any, type: string): Promise<ContentBlockResponseRestDto> {
            const cb = JSON.parse(JSON.stringify(context.getters.getNormalContentBlockHtml(type))) as HtmlContentRestDto;

            const response = await ContentBlockEndpointsApiService.createNormalContentBlockHtml(cb, type);
            return response.data;
        },

        async deleteNormalContentBlock(context: any, payload: any) {
            const id = payload.id as string;
            const type = payload.type as string;

            await ContentBlockEndpointsApiService.deleteNormalContentBlock(type, id);
        },

        async searchNormalContentBlockButton(context: any, payload: any) {
            const id = payload.id as string;
            const type = payload.type as string;

            const response = await ContentBlockEndpointsApiService.getNormalContentBlockButton(type, id);

            if (response.status === 204) {
                context.commit("clearNormalContentBlock", type);
            } else {
                context.commit('setNormalContentBlockButton', {
                    type: type,
                    cb: response.data,
                });
            }
        },
        async searchNormalContentBlockDocument(context: any, payload: any) {
            const id = payload.id as string;
            const type = payload.type as string;

            const response = await ContentBlockEndpointsApiService.getNormalContentBlockDocument(type, id);

            if (response.status === 204) {
                context.commit("clearNormalContentBlock", type);
            } else {
                context.commit('setNormalContentBlockDocument', {
                    type: type,
                    cb: response.data,
                });
            }
        },
        async searchNormalContentBlockImage(context: any, payload: any) {
            const id = payload.id as string;
            const type = payload.type as string;

            const response = await ContentBlockEndpointsApiService.getNormalContentBlockImage(type, id);

            if (response.status === 204) {
                context.commit("clearNormalContentBlockImage", type);
            } else {
                context.commit('setNormalContentBlockImage', {
                    type: type,
                    cb: response.data,
                });
            }
        },
        async searchNormalContentBlockPageReferences(context: any, payload: any) {
            const id = payload.id as string;
            const type = payload.type as string;

            const response = await ContentBlockEndpointsApiService.getNormalContentBlockPageReferences(type, id);

            if (response.status === 204) {
                context.commit("clearNormalContentBlock", type);
            } else {
                context.commit('setNormalContentBlockPageReferences', {
                    type: type,
                    cb: response.data,
                });
            }
        },
        async searchNormalContentBlockProductFilter(context: any, payload: any) {
            const id = payload.id as string;
            const type = payload.type as string;

            const response = await ContentBlockEndpointsApiService.getNormalContentBlockProductFilter(type, id);

            if (response.status === 204) {
                context.commit("clearNormalContentBlock", type);
            } else {
                context.commit('setNormalContentBlockProductFilter', {
                    type: type,
                    cb: response.data,
                });
            }
        },
        async searchNormalContentBlockRowSeparator(context: any, payload: any) {
            const id = payload.id as string;
            const type = payload.type as string;

            const response = await ContentBlockEndpointsApiService.getNormalContentBlockRowSeparator(type, id);

            if (response.status === 204) {
                context.commit("clearNormalContentBlock", type);
            } else {
                context.commit('setNormalContentBlockRowSeparator', {
                    type: type,
                    cb: response.data,
                });
            }
        },
        async searchNormalContentBlockText(context: any, payload: any) {
            const id = payload.id as string;
            const type = payload.type as string;

            const response = await ContentBlockEndpointsApiService.getNormalContentBlockText(type, id);

            if (response.status === 204) {
                context.commit("clearNormalContentBlock", type);
            } else {
                context.commit("setNormalContentBlockText", {
                    type: type,
                    cb: response.data,
                });
            }
        },
        async searchNormalContentBlockTextWithImage(context: any, payload: any) {
            const id = payload.id as string;
            const type = payload.type as string;

            const response = await ContentBlockEndpointsApiService.getNormalContentBlockTextWithImage(type, id);

            if (response.status === 204) {
                context.commit("clearNormalContentBlockTextWithImage", type);
            } else {
                context.commit('setNormalContentBlockTextWithImage', {
                    type: type,
                    cb: response.data,
                });
            }
        },
        async searchNormalContentBlockTextWithTitle(context: any, payload: any) {
            const id = payload.id as string;
            const type = payload.type as string;

            const response = await ContentBlockEndpointsApiService.getNormalContentBlockTextWithTitle(type, id);

            if (response.status === 204) {
                context.commit("clearNormalContentBlockTextWithTitle", type);
            } else {
                context.commit("setNormalContentBlockTextWithTitle", {
                    type: type,
                    cb: response.data,
                });
            }
        },
        async searchNormalContentBlockHtml(context: any, payload: any) {
            const id = payload.id as string;
            const type = payload.type as string;

            const response = await ContentBlockEndpointsApiService.getNormalContentBlockHtml(type, id);

            if (response.status === 204) {
                context.commit("clearNormalContentBlock", type);
            } else {
                context.commit("setNormalContentBlockHtml", {
                    type: type,
                    cb: response.data,
                });
            }
        },
        async searchNormalContentBlocksOverview(context: any, payload: any) {
            const type = payload.type as string;
            const filter_status_code = payload.filter_status_code as string | undefined;
            const filter_library_code = payload.filter_library_code as string | undefined;
            const filter_linked = payload.filter_linked as boolean | undefined;

            const response = await ContentBlockEndpointsApiService.findNormalContentBlocks(type, undefined, filter_status_code, filter_library_code, filter_linked);

            context.commit("setNormalContentBlocksOverview", {
                type: type,
                cbs: response.data,
            });
        },

        async saveNormalContentBlockButton(context: any, payload: any) {
            const id = payload.id as string;
            const type = payload.type as string;
            const cb = context.getters.getNormalContentBlockButton(type) as ButtonContentRestDto;

            await ContentBlockEndpointsApiService.updateNormalContentBlockButton(cb, type, id);
        },
        async saveNormalContentBlockDocument(context: any, payload: any) {
            const id = payload.id as string;
            const type = payload.type as string;
            const cb = context.getters.getNormalContentBlockDocument(type) as DocumentContentRestDto;

            await ContentBlockEndpointsApiService.updateNormalContentBlockDocument(cb, type, id);
        },
        async saveNormalContentBlockImage(context: any, payload: any) {
            const id = payload.id as string;
            const type = payload.type as string;
            const cb = context.getters.getNormalContentBlockImage(type) as ImageContentRestDto;

            await ContentBlockEndpointsApiService.updateNormalContentBlockImage(cb, type, id);
        },
        async saveNormalContentBlockPageReferences(context: any, payload: any) {
            const id = payload.id as string;
            const type = payload.type as string;
            const cb = context.getters.getNormalContentBlockPageReferences(type) as PageReferencesContentRestDto;

            await ContentBlockEndpointsApiService.updateNormalContentBlockPageReferences(cb, type, id);
        },
        async saveNormalContentBlockProductFilter(context: any, payload: any) {
            //product filters are saved through the CPC
            const id = payload.id as string;
            const type = payload.type as string;
            const cb = context.getters.getNormalContentBlockProductFilter(type) as ProductFilterContentRestDto;

            await ContentBlockEndpointsApiService.updateNormalContentBlockProductFilter(cb, type, id);
        },
        async saveNormalContentBlockRowSeparator(context: any, payload: any) {
            const id = payload.id as string;
            const type = payload.type as string;
            const cb = context.getters.getNormalContentBlockRowSeparator(type) as RowSeparatorContentRestDto;

            await ContentBlockEndpointsApiService.updateNormalContentBlockRowSeparator(cb, type, id);
        },
        async saveNormalContentBlockText(context: any, payload: any) {
            const id = payload.id as string;
            const type = payload.type as string;
            const cb = context.getters.getNormalContentBlockText(type) as TextContentRestDto;

            await ContentBlockEndpointsApiService.updateNormalContentBlockText(cb, type, id);
        },
        async saveNormalContentBlockTextWithImage(context: any, payload: any) {
            const id = payload.id as string;
            const type = payload.type as string;
            const cb = JSON.parse(JSON.stringify(context.getters.getNormalContentBlockTextWithImage(type))) as TextWithImageContentRestDto;
            //TODO make middleware

            cb.button = (cb.button !== undefined && isValidButton(cb.button)) ? cb.button : undefined;

            await ContentBlockEndpointsApiService.updateNormalContentBlockTextWithImage(cb, type, id);
        },
        async saveNormalContentBlockTextWithTitle(context: any, payload: any) {
            const id = payload.id as string;
            const type = payload.type as string;
            const cb = context.getters.getNormalContentBlockTextWithTitle(type) as TextWithTitleContentRestDto;

            await ContentBlockEndpointsApiService.updateNormalContentBlockTextWithTitle(cb, type, id);
        },
        async saveNormalContentBlockHtml(context: any, payload: any) {
            const id = payload.id as string;
            const type = payload.type as string;
            const cb = context.getters.getNormalContentBlockHtml(type) as HtmlContentRestDto;

            await ContentBlockEndpointsApiService.updateNormalContentBlockHtml(cb, type, id);
        },
    },
    mutations: {
        clearNormalContentBlock(state: any, type: string) {
            // todo map gebruiken
            if (type === 'BANNER') {
                state.banners.cb_banner = emptyNormalContentBlockBanner();
                state.banners.cb_banner.banner_images = JSON.parse(JSON.stringify(state.banners.empty_banner_images));
            } else if (type === 'LIB_BANNER') {
                state.banners.cb_lib_banner = emptyNormalContentBlockBanner();
                state.banners.cb_lib_banner.banner_images = JSON.parse(JSON.stringify(state.banners.empty_banner_images));
            } else if (type === ContentBlockTypesEnum.BUTTON) {
                state.cb_button = emptyNormalContentBlockButton();
                state.cb_button.status_code = StatusEnum.NOT_PUBLISHED;
            } else if (type === ContentBlockTypesEnum.CALL_TO_ACTION) {
                state.cb_call_to_action = emptyNormalContentBlockButton();
                state.cb_call_to_action.status_code = StatusEnum.NOT_PUBLISHED;
            } else if (type === ContentBlockTypesEnum.DOCUMENT) {
                state.cb_document = emptyNormalContentBlockDocument();
                state.cb_document.status_code = StatusEnum.NOT_PUBLISHED;
            } else if (type === ContentBlockTypesEnum.PAGE_REFERENCES) {
                state.cb_page_references = emptyNormalContentBlockPageReferences();
                state.cb_page_references.status_code = StatusEnum.NOT_PUBLISHED;
            } else if (type === ContentBlockTypesEnum.PRODUCT_FILTER_AS_GRID || type === ContentBlockTypesEnum.PRODUCT_FILTER_AS_SLIDER) {
                state.cb_product_filter = emptyNormalContentBlockProductFilter();
                state.cb_product_filter.status_code = StatusEnum.NOT_PUBLISHED;
            } else if (type === ContentBlockTypesEnum.ROW_SEPARATOR) {
                state.cb_row_separator = emptyNormalContentBlockRowSeparator();
                state.cb_row_separator.status_code = StatusEnum.NOT_PUBLISHED;
            } else if (type === ContentBlockTypesEnum.TEXT) {
                state.cb_text = emptyNormalContentBlockText();
                state.cb_text.status_code = StatusEnum.NOT_PUBLISHED;
            } else if (type === ContentBlockTypesEnum.TEXT_CARD) {
                state.cb_text_card = emptyNormalContentBlockText();
                state.cb_text_card.status_code = StatusEnum.NOT_PUBLISHED;
            } else if (type === ContentBlockTypesEnum.HTML) {
                state.cb_html = emptyNormalContentBlockHtml();
                state.cb_html.status_code = StatusEnum.NOT_PUBLISHED;
            } else {
                throw new Error('Unsupported normal content block to clear ' + type);
            }
        },
        clearNormalContentBlockImage(state: any, type: string) {
            state.cbs_image[type] = emptyNormalContentBlockImage();
            // state.cbs_image[type].status_code = StatusEnum.NOT_PUBLISHED;
        },
        clearNormalContentBlockDocument(state: any, type: string) {
            state.cbs_docs[type] = emptyNormalContentBlockDocument();
            // state.cbs_docs[type].status_code = StatusEnum.NOT_PUBLISHED;
        },
        clearNormalContentBlockTextWithImage(state: any, type: string) {
            state.cbs_text_with_image[type] = emptyNormalContentBlockTextWithImage();
            // state.cbs_text_with_image[type].status_code = StatusEnum.NOT_PUBLISHED;
        },
        clearNormalContentBlockTextWithTitle(state: any, type: string) {
            state.cbs_text_with_title[type] = emptyNormalContentBlockTextWithTitle();
            // state.cbs_text_with_title[type].status_code = StatusEnum.NOT_PUBLISHED;
        },
        setContentBlockId(state: any, cb_id: string) {
            state.cb_id = cb_id;
        },
        setNormalContentBlockButton(state: any, payload: any) {
            const type = payload.type as string;
            const cb = payload.cb as ButtonContentRestDto;

            // todo map gebruiken
            switch (type) {
                case ContentBlockTypesEnum.BUTTON:
                    state.cb_button = cb;
                    break;
                case ContentBlockTypesEnum.CALL_TO_ACTION:
                    state.cb_call_to_action = cb;
                    break;
                default:
                    throw new Error('Unsupported NormalContentBlockButton to set ' + type);

            }
        },
        setNormalContentBlockDocument(state: any, payload: any) {
            const type = payload.type as string;
            const cb = payload.cb as DocumentContentRestDto;

            // todo map gebruiken
            switch (type) {
                case ContentBlockTypesEnum.DOCUMENT:
                    state.cb_document = cb;
                    break;
                default:
                    throw new Error('Unsupported NormalContentBlockDocument to set ' + type);
            }
        },
        setNormalContentBlockImage(state: any, payload: any) {
            state.cbs_image[payload.type] = payload.cb;
        },
        setNormalContentBlockPageReferences(state: any, payload: any) {
            const type = payload.type;
            const cb = payload.cb;

            // todo map gebruiken
            switch (type) {
                case ContentBlockTypesEnum.PAGE_REFERENCES:
                    state.cb_page_references = cb;
                    break;
                default:
                    throw new Error('Unsupported NormalContentBlockPageReferences to set ' + type);
            }
        },
        setNormalContentBlockProductFilter(state: any, payload: any) {
            const type = payload.type;
            const cb = payload.cb;

            // todo map gebruiken
            switch (type) {
                case ContentBlockTypesEnum.PRODUCT_FILTER_AS_GRID:
                case ContentBlockTypesEnum.PRODUCT_FILTER_AS_SLIDER:
                    state.cb_product_filter = cb;
                    break;
                default:
                    throw new Error('Unsupported NormalContentBlockProductFilter to set ' + type);
            }
        },
        setNormalContentBlockRowSeparator(state: any, payload: any) {
            const type = payload.type;
            const cb = payload.cb;

            // todo map gebruiken
            switch (type) {
                case ContentBlockTypesEnum.ROW_SEPARATOR:
                    state.cb_row_separator = cb;
                    break;
                default:
                    throw new Error('Unsupported NormalContentBlockRowSeparator to set ' + type);
            }
        },
        setNormalContentBlockText(state: any, payload: any) {
            const type = payload.type;
            const cb = payload.cb;

            // todo map gebruiken
            switch (type) {
                case ContentBlockTypesEnum.TEXT:
                    state.cb_text = cb;
                    break;
                case ContentBlockTypesEnum.TEXT_CARD:
                    state.cb_text_card = cb;
                    break;
                default:
                    throw new Error('Unsupported NormalContentBlockText to set ' + type);
            }
        },
        setNormalContentBlockTextWithImage(state: any, payload: any) {
            state.cbs_text_with_image[payload.type] = payload.cb;
        },
        setNormalContentBlockTextWithTitle(state: any, payload: any) {
            state.cbs_text_with_title[payload.type] = payload.cb;
        },
        setNormalContentBlockHtml(state: any, payload: any) {
            const type = payload.type;
            const cb = payload.cb;

            // todo map gebruiken
            if (type === ContentBlockTypesEnum.HTML) {
                state.cb_html = cb;
            } else {
                throw new Error('Unsupported NormalContentBlockHtml to set ' + type);
            }
        },
        setNormalContentBlocksOverview(state: any, payload: any) {
            // const cbs = payload['cbs'];
            // const type = payload['type'];
            state.cb_overview = payload.cbs;
        }
    },
    getters: {
        getContentBlockId(state: any): string {
            return state.cb_id;
        },
        getNormalContentBlockButton: (state: any) => (type: string): ButtonContentRestDto => {
            // todo map gebruiken
            switch (type) {
                case ContentBlockTypesEnum.BUTTON:
                    return state.cb_button;
                case ContentBlockTypesEnum.CALL_TO_ACTION:
                    return state.cb_call_to_action;
                default:
                    throw new Error('Unsupported NormalContentBlockButton to get ' + type);
            }
        },
        getNormalContentBlockDocument: (state: any) => (type: string): DocumentContentRestDto => {
            // todo map gebruiken
            switch (type) {
                case ContentBlockTypesEnum.DOCUMENT:
                    return state.cb_document;
                default:
                    throw new Error('Unsupported NormalContentBlockDocument to get ' + type);
            }
        },
        getNormalContentBlockImage: (state: any) => (type: string): ImageContentRestDto => {
            return state.cbs_image[type];
        },
        getNormalContentBlockPageReferences: (state: any) => (type: string): PageReferencesContentRestDto => {
            // todo map gebruiken
            switch (type) {
                case ContentBlockTypesEnum.PAGE_REFERENCES:
                    return state.cb_page_references;
                default:
                    throw new Error('Unsupported NormalContentBlockPageReferences to get ' + type);
            }
        },
        getNormalContentBlockProductFilter: (state: any) => (type: string): ProductFilterContentRestDto => {
            // todo map gebruiken
            switch (type) {
                case ContentBlockTypesEnum.PRODUCT_FILTER_AS_GRID:
                case ContentBlockTypesEnum.PRODUCT_FILTER_AS_SLIDER:
                    return state.cb_product_filter;
                default:
                    throw new Error('Unsupported NormalContentBlockProductFilter to get ' + type);
            }
        },
        getNormalContentBlockRowSeparator: (state: any) => (type: string): RowSeparatorContentRestDto => {
            // todo map gebruiken
            switch (type) {
                case ContentBlockTypesEnum.ROW_SEPARATOR:
                    return state.cb_row_separator;
                default:
                    throw new Error('Unsupported NormalContentBlockRowSeparator to get ' + type);
            }
        },
        getNormalContentBlockText: (state: any) => (type: string): TextContentRestDto => {
            // todo map gebruiken
            switch (type) {
                case ContentBlockTypesEnum.TEXT:
                    return state.cb_text;
                case ContentBlockTypesEnum.TEXT_CARD:
                    return state.cb_text_card;
                default:
                    throw new Error('Unsupported NormalContentBlockText to get ' + type);
            }
        },
        getNormalContentBlockTextWithImage: (state: any) => (type: string): TextWithImageContentRestDto => {
            return state.cbs_text_with_image[type];
        },
        getNormalContentBlockTextWithTitle: (state: any) => (type: string): TextWithTitleContentRestDto => {
            return state.cbs_text_with_title[type];
        },
        getNormalContentBlockHtml: (state: any) => (type: string): HtmlContentRestDto => {
            if (type === ContentBlockTypesEnum.HTML) {
                return state.cb_html;
            } else {
                throw new Error('Unsupported NormalContentBlockHtml to get ' + type);
            }
        },
        getNormalContentBlocksOverview(state: any): ContentOverviewRestDto[] {
            return state.cb_overview;
        }
    }
}
