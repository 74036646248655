<template>
  <div v-if="!this.mode.startsWith('group')"
       class="dp-input mb-3"
       :class="{
        'clearable': this.canClear,
        'can-be-cleared': this.canClear && this.modelValue,
        'form-floating': this.isFloating,
        'has-error': this.errors[name],
       }"
  >
    <dp-field
      :label="label"
      :name="name"
      :rules="rules"
      type="number"
      class="form-control"
      :class="{
        'form-control-sm': (this.inputSize === this.enums.inputSizeEnum.SM),
        'form-control-lg': (this.inputSize === this.enums.inputSizeEnum.LG),
        'is-invalid': errors[name],
      }"
      :id="name"
      :min="min"
      :max="max"
      :step="step"
      :modelValue="modelValue"
      :placeholder="this.getPlaceholder()"
      :disabled="disabled"
      @update:modelValue="updateValue"
    />

    <label :for="name" class="form-label fw-normal">
      {{ label }}<strong v-show="checkRequired(rules)"> *</strong>
    </label>

    <button v-if="this.canClear && this.modelValue"
            @click.stop.prevent="this.clearValue"
            class="btn-close"
            type="reset"></button>

    <dp-error-message class="invalid-feedback d-block" :name="name"></dp-error-message>
  </div>

  <template v-else-if="mode.startsWith('group')">
    <div class="input-group mb-3">
      <span v-if="this.mode === 'group-start' && this.addonText" class="input-group-text" :id="name + 'Addon'">
        {{ this.addonText }}
      </span>

      <div class="dp-input form-floating"
           :class="{
              'clearable': this.canClear,
              'can-be-cleared': this.canClear && this.modelValue,
              'has-error': this.errors[name],
           }"
      >
        <dp-field
          :label="label"
          :name="name"
          :rules="rules"
          type="number"
          class="form-control"
          :class="{
            'clearable': this.canClear,
            'can-be-cleared': this.canClear && this.modelValue,
            'form-control-sm': (this.inputSize === this.enums.inputSizeEnum.SM),
            'form-control-lg': (this.inputSize === this.enums.inputSizeEnum.LG),
            'is-invalid': errors[name],
          }"
          :id="name"
          :min="min"
          :max="max"
          :step="step"
          :modelValue="modelValue"
          :placeholder="placeholder"
          :aria-describedby="name + 'Addon'"
          :disabled="disabled"
          @update:modelValue="updateValue"
        />

        <label :for="name" class="form-label">
          {{ label }}<strong v-show="checkRequired(rules)"> *</strong>
        </label>

        <button v-if="this.canClear && this.modelValue"
                @click.stop.prevent="this.clearValue"
                class="btn-close"></button>
      </div>

      <span v-if="this.mode === 'group-end' && this.addonText" class="input-group-text" :id="name + 'Addon'">
        {{ this.addonText }}
      </span>
    </div>
  </template>
</template>

<script>
import {InputSizeEnum} from "@/models/enum/InputSizeEnum";

export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Number,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    errors: {
      type: Object,
    },
    rules: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    min: {
      type: Number,
      required: false,
    },
    max: {
      type: Number,
      required: false,
    },
    step: {
      type: Number,
      required: false,
      default: 1,
    },
    mode: {
      type: String,
      required: false,
      default: 'float',
      validate(value) {
        return value === 'float' || value === 'group-start' || value === 'group-end';
      }
    },
    addonText: {
      type: String,
      required: false,
      validate(value) {
        return this.mode.startsWith('group') && value;
      }
    },
    inputSize: {
      type: String,
      required: false,
      default: InputSizeEnum.SM,
    },

    // BOOLEANS
    canClear: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isFloating: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      enums: {
        inputSizeEnum: InputSizeEnum,
      },
    }
  },
  methods: {
    checkRequired(rules) {
      if (rules && rules.includes("required")) {
        return true;
      }
    },
    determinePlaceholder() {
      return null;
    },
    getPlaceholder() {
      return this.determinePlaceholder() ?? this.placeholder ?? this.label;
    },
    clearValue() {
      this.$emit('update:modelValue', null);
    },
    updateValue(value) {
      value = parseFloat(value);
      if (this.min && value < this.min) {
        value = this.min;
      } else if (this.max && value > this.max) {
        value = this.max;
      }

      this.$emit('update:modelValue', value);
    },
  },
};
</script>

<style scoped lang="scss">
.form-floating label {
  display: block;
  margin-bottom: 0.5rem;
}

input:disabled {
  cursor: not-allowed;
}

.dp-input {
  &.clearable {
    position: relative;

    button.btn-close {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 0.7rem 0.5rem;
    }
  }

  &.can-be-cleared input {
    padding-right: 2rem;
  }
}
</style>
