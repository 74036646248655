import {RestService} from "@/services/rest/RestService";
import {ContentBlockLinkEndpointsApi} from "@/api/pharma-cms-content-block";
import {ContentBlockLinkRestDtoModel} from "@/models/api/pharma-cms-content-block-library/ContentBlockLinkRestDtoModel";
import {IdResponseRestDto} from "@/api/pharma-cms-content-block/models";

export class ContentBlockLinkRestService extends RestService {

    private apiService: ContentBlockLinkEndpointsApi;

    protected constructor() {
        super();

        this.apiService = new ContentBlockLinkEndpointsApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cmsUrl);
    }

    public async createContentBlockLink(toCbId: string, fromCbTypeCode?: string | null, fromCbId?: string | null, forceCopy?: boolean | null): Promise<IdResponseRestDto> {
        const body = new ContentBlockLinkRestDtoModel(toCbId, fromCbId, fromCbTypeCode, forceCopy);
        const response = await this.apiService.createContentBlockLink(body, '');

        return response.data;
    }
}
