import {NormalContentBlockModel} from "@/models/api/pharma-cms-content-block/NormalContentBlockModel";
import {
    cloneProductFilterContentRestDtoMetaData,
    ContentBlockMetadataRestDto, ProductFilterContentRestDto
} from "@/api/pharma-cms-content-block/models";
import {StatusEnum} from "@/api/enums/status-enum";
import {TitleSizeEnum} from "@/api/enums/title-size-enum";

export class ProductFilterContentRestDtoModel extends NormalContentBlockModel implements ProductFilterContentRestDto {
    cb_metadata?: ContentBlockMetadataRestDto;
    content?: string | null;
    product_filter_code?: string | null;
    sort_weight?: number;
    status_code: StatusEnum;
    title?: string | null;
    title_size_code?: TitleSizeEnum;

    constructor(status_code: StatusEnum) {
        super();
        this.status_code = status_code;
    }

    public static createWithDefaults(): ProductFilterContentRestDtoModel {
        const model = new this(StatusEnum.PUBLISHED);
        model.content = null;
        model.product_filter_code = null;
        // model.sort_weight = NormalContentBlockModel.getDefaultSortWeight();
        model.title = null;
        model.title_size_code = TitleSizeEnum.H2;

        return model;
    }

    public static cloneFrom(source: ProductFilterContentRestDtoModel): ProductFilterContentRestDtoModel {
        const model = new this(source.status_code);
        model.cb_metadata = source.cb_metadata;
        model.content = source.content;
        model.product_filter_code = source.product_filter_code;
        model.sort_weight = source.sort_weight;
        model.title = source.title;
        model.title_size_code = source.title_size_code;

        return model;
    }

    public cloneMetadataFrom(source: ProductFilterContentRestDtoModel): void {
        cloneProductFilterContentRestDtoMetaData(this, source);
    }
}
