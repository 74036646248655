import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55dfcdba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title d-flex align-items-center" }
const _hoisted_2 = { class: "fs-5 mb-0" }
const _hoisted_3 = {
  key: 0,
  class: "badge bg-dark"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_card_header = _resolveComponent("base-card-header")!
  const _component_base_spinner = _resolveComponent("base-spinner")!
  const _component_base_card_body = _resolveComponent("base-card-body")!
  const _component_TrademarkItem = _resolveComponent("TrademarkItem")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_BasePagination = _resolveComponent("BasePagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_base_card_header, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("button", {
                class: _normalizeClass(["btn me-2 lh-1", _ctx.title.btnClass]),
                disabled: ""
              }, _toDisplayString(_ctx.title.icon), 3),
              _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.title.name)), 1)
            ]),
            (_ctx.isReady && _ctx.hasTrademarks)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.itemsCountText), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (!_ctx.isReady)
          ? (_openBlock(), _createBlock(_component_base_spinner, {
              key: 0,
              "spinner-class": "p-2"
            }))
          : (!_ctx.hasTrademarks)
            ? (_openBlock(), _createBlock(_component_base_card_body, {
                key: 1,
                text: _ctx.$t('noResults')
              }, null, 8, ["text"]))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.trademarkSelectionOverview.results, (trademarkItem) => {
                return (_openBlock(), _createElementBlock("ul", {
                  class: "list-group list-group-flush",
                  key: trademarkItem.trademark_code
                }, [
                  _createVNode(_component_TrademarkItem, {
                    "trademark-item": trademarkItem,
                    "filter-selection-type-code": _ctx.filterSelectionTypeCode,
                    onUpdateTrademarkItem: _ctx.updateTrademarkItem
                  }, null, 8, ["trademark-item", "filter-selection-type-code", "onUpdateTrademarkItem"])
                ]))
              }), 128))
      ]),
      _: 1
    }),
    (_ctx.isReady && _ctx.hasTrademarks)
      ? (_openBlock(), _createBlock(_component_BasePagination, {
          key: 0,
          "pagination-data": _ctx.trademarkSelectionOverview.paging_metadata,
          "n-pagination-items": 7,
          "show-page-items-count": "",
          onChangePage: _ctx.updatePage
        }, null, 8, ["pagination-data", "onChangePage"]))
      : _createCommentVNode("", true)
  ], 64))
}