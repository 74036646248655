
import {defineComponent} from "vue";
import {mapActions, mapGetters} from 'vuex';
import AbstractCbCommon from "@/components/layouts/content-pages/wysiwyg/structures/AbstractCbCommon.vue";
import {BannerSlotContentRestDtoModel} from "@/models/BannerSlotContentRestDtoModel";
import {CodeDescriptionRestDto, ContentOverviewRestDto} from "@/api/pharma-cms-content-block/models";
import {DpException} from "@/exception/DpException";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import InputSelect from "@/components/UI/InputSelect.vue";

export default defineComponent({
  name: "CbBannerSlot",
  components: {InputSelect, BaseSpinner},
  extends: AbstractCbCommon,
  data: () => ({
    internalCbId: undefined as string | null | undefined,
  }),
  computed: {
    ...mapGetters('cms_content_block', ['getNormalContentBlocksOverview']),
    bannerSlotOptions(): CodeDescriptionRestDto[] {
      const bannerSlotOptions = [] as CodeDescriptionRestDto[];

      this.getNormalContentBlocksOverview.forEach((banner_slot: ContentOverviewRestDto) => {
        bannerSlotOptions.push(
          {code: banner_slot.id, description: banner_slot.title}
        );
      });

      return bannerSlotOptions;
    },
  },
  methods: {
    ...mapActions('cms_content_block', ['searchNormalContentBlocksOverview',]),
    async reloadContent(): Promise<void> {
      this.isReady = false;

      try {
        this.internalCbId = this.cbId;

        this.bus.on('submit', this.submitData);

        this.onCopyCpAbstractChanged();

        await this.searchNormalContentBlocksOverview({type: "BANNER_SLOT"});
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }

      this.isReady = true;
    },
    async submitData(): Promise<void> {
      try {
        this.setExceptions([]);

        if (!this.internalCbId) {
          throw [new DpException(this.$t('Banner slot needs to be selected.'))]
        }

        this.$emit('saved', this.internalCbId);
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }
    },
    getDefaultCbModel(): BannerSlotContentRestDtoModel {
      return BannerSlotContentRestDtoModel.createWithDefaults();
    },
  }
});
