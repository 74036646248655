import {SnippetContextRestDto, SnippetRestDto} from "@/api/pharma-cms-snippet/models";
import {SnippetContextRestDtoModel} from "@/models/api/pharma-cms-snippet/SnippetContextRestDtoModel";

export class SnippetRestDtoModel implements SnippetRestDto {

    name: string;
    context: SnippetContextRestDto;

    constructor(name: string,
                context: SnippetContextRestDto) {
        this.name = name;
        this.context = context;
    }

    public static createWithDefaults(): SnippetRestDtoModel {
        return new this(
            '',
            SnippetContextRestDtoModel.createWithDefaults()
        );
    }
}
