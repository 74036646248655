import {NormalContentBlockModel} from "@/models/api/pharma-cms-content-block/NormalContentBlockModel";
import {
    clonePageReferencesContentRestDtoMetaData,
    ContentBlockMetadataRestDto,
    PageReferencesContentRestDto
} from "@/api/pharma-cms-content-block/models";
import {StatusEnum} from "@/api/enums/status-enum";
import {VisibilityEnum} from "@/api/enums/visibility-enum";
import {ImageFitEnum} from "@/api/enums/image-fit-enum";
import {ImageHorizontalPositionEnum} from "@/api/enums/image-horizontal-position-enum";
import {ImageVerticalPositionEnum} from "@/api/enums/image-vertical-position-enum";
import {PageReferencesContentLayoutEnum} from "@/api/enums/page-references-content-layout-enum";
import {PageReferenceOrderByRestDtoModel} from "@/models/PageReferenceOrderByRestDtoModel";

export class PageReferencesContentRestDtoModel extends NormalContentBlockModel implements PageReferencesContentRestDto {
    cb_metadata?: ContentBlockMetadataRestDto;
    filter_label?: string[] | null;
    filter_show_on_homepage?: boolean | null;
    image_fit_code?: ImageFitEnum;
    image_horizontal_position_code?: ImageHorizontalPositionEnum;
    image_vertical_position_code?: ImageVerticalPositionEnum;
    layout_code: PageReferencesContentLayoutEnum;
    limit_references_count?: number | null;
    order_by_fields?: PageReferenceOrderByRestDtoModel[];
    page_abstr_lib_code?: string | null;
    page_type_code?: string | null;
    references_per_page?: number | null;
    sort_weight?: number;
    status_code: StatusEnum;
    visibility_code?: VisibilityEnum;

    constructor(layout_code: PageReferencesContentLayoutEnum, status_code: StatusEnum) {
        super();
        this.layout_code = layout_code;
        this.status_code = status_code;
    }

    public static createWithDefaults(): PageReferencesContentRestDtoModel {
        const model = new PageReferencesContentRestDtoModel(PageReferencesContentLayoutEnum.GRID_1XN, StatusEnum.PUBLISHED);
        model.filter_label = null;
        model.filter_show_on_homepage = null;
        model.image_fit_code = ImageFitEnum.NONE;
        model.image_horizontal_position_code = ImageHorizontalPositionEnum.CENTER;
        model.image_vertical_position_code = ImageVerticalPositionEnum.CENTER;
        model.limit_references_count = null;
        model.order_by_fields = [];
        model.references_per_page = null;
        model.page_abstr_lib_code = null;
        model.page_type_code = null;
        // model.sort_weight = NormalContentBlockModel.getDefaultSortWeight();
        model.visibility_code = VisibilityEnum.ALL;

        return model;
    }

    public static cloneFrom(source: PageReferencesContentRestDtoModel): PageReferencesContentRestDtoModel {
        const model = new this(source.layout_code, source.status_code);
        model.cb_metadata = source.cb_metadata;
        model.filter_label = source.filter_label;
        model.filter_show_on_homepage = source.filter_show_on_homepage;
        model.image_fit_code = source.image_fit_code;
        model.image_horizontal_position_code = source.image_horizontal_position_code;
        model.image_vertical_position_code = source.image_vertical_position_code;
        model.limit_references_count = source.limit_references_count;
        model.order_by_fields = source.order_by_fields;
        model.page_abstr_lib_code = source.page_abstr_lib_code;
        model.page_type_code = source.page_type_code;
        model.references_per_page = source.references_per_page;
        model.sort_weight = source.sort_weight;
        model.visibility_code = source.visibility_code;

        return model;
    }

    public cloneMetadataFrom(source: PageReferencesContentRestDtoModel): void {
        clonePageReferencesContentRestDtoMetaData(this, source);
    }
}
