
import {defineComponent} from 'vue';
import {createInstance} from '@/helpers/axios';
import {config} from '@/helpers/fetch-config';
import store from "@/store";
import {Exception} from "@/api/interfaces";
import {mapGetters} from "vuex";

export default defineComponent({
  emits: ["imageCleared", "imageUploaded"],
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    typeCode: {
      type: String,
      required: true,
    },
    existingDocumentKey: {
      type: String,
    }
  },
  data: () => ({
    file: '',
    preview_url: '',
    uploading: false,
    error: '',
    exceptions: [] as Exception[],
  }),
  mounted() {
    if (this.existingDocumentKey) {
      this.searchPreviewDocument(this.existingDocumentKey);
    } else {
      console.log("mounted input-file no existingDocumentKey found...")
    }
  },
  computed: {
    ...mapGetters('cms_document', ['getPreviewDocument']),
    ...mapGetters('cms_document', ['getUploadedDocumentKey']),
  },
  methods: {
    async searchPreviewDocument(existing_document_key: String) {
      try {
        const payload = {
          document_type: this.typeCode,
          document_key: existing_document_key,
        }
        await store.dispatch('cms_document/searchPreviewDocument', payload);
        const doc_from_store = this.getPreviewDocument(this.typeCode)
        this.preview_url = doc_from_store.url;
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    handleChosenFile(event: any) {
      const files = event.target.files;
      this.file = files[0];
      this.uploadFile();
    },
    clear() {
      var answer = window.confirm(this.$t('deleteConfirmation'));
      if (answer) {
        const uploadInput: any = this.$refs[`fileupload-${this.id}`];
        uploadInput.value = null;
        this.file = '';
        this.preview_url = '';
        this.error = '';
        const payload = {
          document_type: this.typeCode,
          document_key: null
        }
        store.commit('cms_document/setUploadedDocumentKey', payload);

        this.$emit('imageCleared');
      } else {
        return
      }
    },
    async uploadFile() {
      this.uploading = true;
      try {
        const axios = createInstance();
        const formConfig = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };

        const formData = new FormData();
        formData.append('file', this.file);

        const uploadUrl = config.fileUploadUrl.replace('{type_code}', this.typeCode);
        // const uploadUrl = (this.isCpc) ? config.fileUploadUrlCpc.replace('{type_code}', this.typeCode) : config.fileUploadUrl.replace('{type_code}', this.typeCode);
        uploadUrl.replace('{type_code}', this.typeCode);
        const response = await axios.post(uploadUrl, formData, formConfig);
        const documentKey = response?.data?.document_key;
        if (documentKey) {
          const url = config.fileUrlDownloadUrl.replace('{document_key}', documentKey);
          // const url = (this.isCpc) ? config.fileUrlDownloadUrlCpc.replace('{document_key}', documentKey) : config.fileUrlDownloadUrl.replace('{document_key}', documentKey);
          const image = await axios.get(url);
          this.preview_url = image?.data?.url;
          this.uploading = false;
          this.error = '';
          const payload = {
            document_type: this.typeCode,
            document_key: documentKey,
          }
          store.commit('cms_document/setUploadedDocumentKey', payload);

          this.$emit('imageUploaded', this.getUploadedDocumentKey(this.typeCode));
        } else {
          throw new Error('No document key');
        }
      } catch (error: any) {
        this.uploading = false;
        this.error = error;
      }
    },
  },
});
