
import {defineComponent} from "vue";
import {Exception} from "@/api/interfaces";
import {DpException} from "@/exception/DpException";
import {mapActions} from "vuex";
import ReservationCampaignSettings from "@/components/layouts/reservation-campaigns/ReservationCampaignSettings.vue";
import ReservationCampaignContent from "@/components/layouts/reservation-campaigns/ReservationCampaignContent.vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";

export default defineComponent({
  name: "ReservationCampaignsDetail",
  components: {BaseTitle, ReservationCampaignSettings, ReservationCampaignContent},
  props: ['mode'],
  data() {
    return {
      currentComponent: 'reservation-campaign-settings' as string,
      exceptions: [] as Exception[],
      id: this.$route.params.id as string,
      isReady: false as boolean,
      isSubmitting: false as boolean,
      savedSuccessfully: false as boolean,
    };
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    currentProperties(): Object {
      switch (this.currentComponent) {
        case 'reservation-campaign-settings':
          return {
            mode: this.mode,
            isSubmitting: this.isSubmitting,
          };
        case 'reservation-campaign-content':
          return {
            isSubmitting: this.isSubmitting,
          };
        default:
          throw [new DpException('Unsupported reservation campaign component')];
      }
    },
  },
  methods: {
    ...mapActions('order_orca_customer_mgmt', [
      "clearReservationCampaign",
      "clearReservationCampaignContent",
      "createReservationCampaign",
      "saveReservationCampaign",
      "saveReservationCampaignContent",
      "searchReservationCampaign",
      "searchReservationCampaignContent"
    ]),
    setCurrentComponent(cmp: string) {
      this.currentComponent = cmp;
    },
    async submitData() {
      this.isSubmitting = true;
      this.exceptions = [];

      try {
        if (this.mode.toLowerCase() === 'new') {
          await this.createReservationCampaign();
        } else {
          //Save in this order for dependencies
          await this.saveReservationCampaign(this.id);
          await this.saveReservationCampaignContent(this.id);
        }

        await this.afterSave();
      } catch (exceptions: any) {
        this.isSubmitting = false;
        this.exceptions = exceptions;
        window.scrollTo(0, 0);
      }

      this.isSubmitting = false;
    },
    async reloadContent() {
      this.isReady = false;
      this.exceptions = [];

      try {
        if (this.mode.toLowerCase() === 'new') {
          this.clearReservationCampaign();
          this.clearReservationCampaignContent();
        } else {
          await Promise.all([
            this.searchReservationCampaign(this.id),
            this.searchReservationCampaignContent(this.id),
          ])
        }
      } catch (exceptions: any) {
        this.isReady = true;
        this.exceptions = exceptions;
      }

      this.isReady = true;
    },
    async afterSave() {
      if (this.mode.toLowerCase() === 'new') {
        await this.$router.push({name: 'reservation-campaigns'});
      } else {
        window.scrollTo(0, 0);

        await this.reloadContent();
        this.savedSuccessfully = true;
        setTimeout(() => this.savedSuccessfully = false, 2000);
      }
    },
  },
});
