
import {defineComponent} from 'vue';
import {Exception} from "@/api/interfaces";
import {generateUUIDv4} from "@/helpers/functions/string";
import {CmsDocumentRestService} from "@/services/rest/cms-document/CmsDocumentRestService";
import {CpcDocumentRestService} from "@/services/rest/cpc-document/CpcDocumentRestService";
import {PropOptions} from "vue-class-component";
import {config} from "@/helpers/fetch-config";

export default defineComponent({
  name: 'InputImage',
  emits: [
    'update:modelValue',
    'imageCleared',
    'exceptions',
  ],
  props: {
    modelValue: {
      type: String,
      required: false,
    } as PropOptions<string | null>,
    id: {
      type: String,
      required: false,
      default() {
        return generateUUIDv4('file-input-');
      }
    },
    isCpc: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    typeCode: {
      type: String,
      required: false,
      default: 'PAGE_IMG'
    },
    deletable: {
      type: Boolean,
      required: false,
      default: true,
    },
    height: {
      type: Number,
      required: false,
      default: 250,
    },
    rules: {
      type: String,
      required: false,
      default: '',
    },
    isMultilingual: {
      type: Boolean,
      required: false,
      default: false,
    },
    language: {
      type: String,
      required: false,
      default: 'nl'
    }
  },
  data: () => ({
    previewUrl: null as string|null,

    isDragOver: false as boolean,
    isUploading: false as boolean,

    error: '',

    exceptions: [] as Exception[],
    uploadLimit: config.uploadFileSizeLimitMB ?? 10,
  }),
  mounted() {
    this.reloadContent();
  },
  computed: {
    fileName(): string {
      return this.previewUrl?.substr(this.previewUrl?.lastIndexOf('/') + 1) ?? '';
    },
    isRequired(): boolean {
      return this.rules !== undefined && this.rules.includes("required");
    },
  },
  methods: {
    openFileChooser() {
      document.getElementById(this.id)?.click();
    },
    reloadContent() {
      this.previewUrl = '';

      if (this.modelValue) {
        this.searchPreviewDocument(this.modelValue);
      }
    },
    dragOver(event: any) {
      this.isDragOver = true;

      if (!event.currentTarget.classList.contains('dragging')) {
        event.currentTarget.classList.add('dragging');
      }
    },
    dragLeave(event: any) {
      this.isDragOver = false;

      event.currentTarget.classList.remove('dragging');
    },
    drop(event: any) {
      this.isDragOver = false;

      event.currentTarget.classList.remove('dragging');
      if (event.dataTransfer.files.length > 0) {
        if (event.dataTransfer.files[0]) {
          if (event.dataTransfer.files[0].size > this.uploadLimit * 1024 * 1024) {
            throw new Error(this.$t('uploadFileSizeTooBig', {maxSize: this.uploadLimit}));
          }
        }
        this.uploadFile(event.dataTransfer.files[0] as File);
      }
    },
    clear() {
      // todo: use new confirm dialog
      let answer = window.confirm(this.$t('deleteConfirmation'));

      if (answer) {
        this.previewUrl = '';
        this.error = '';

        this.$emit('update:modelValue', null);
        this.$emit('imageCleared');
      }
    },
    handleChosenFile(event: any) {
      if (event.target.files[0]) {
        if (event.target.files[0].size > this.uploadLimit * 1024 * 1024) {
          event.target.value = '';
          throw new Error(this.$t('uploadFileSizeTooBig', {maxSize: this.uploadLimit}));
        }
      }
      this.uploadFile(event.target.files[0]);
    },
    async searchPreviewDocument(documentKey: String): Promise<void> {
      try {
        if (this.isCpc) {
          this.previewUrl = await CpcDocumentRestService.getInstance()
            .findPreviewUrl(documentKey);
        } else {
          this.previewUrl = await CmsDocumentRestService.getInstance()
            .findPreviewUrl(documentKey);
        }
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async uploadFile(file: File): Promise<void> {
      this.isUploading = true;

      try {
        let documentKey = null;

        if (this.isCpc) {
          documentKey = await CpcDocumentRestService.getInstance()
            .uploadFile(this.typeCode, file);
        } else {
          documentKey = await CmsDocumentRestService.getInstance()
            .uploadFile(this.typeCode, file);
        }

        if (!documentKey) {
          throw new Error('No document key');
        }

        this.$emit('update:modelValue', documentKey);
        await this.searchPreviewDocument(documentKey);

        this.error = '';
      } catch (exceptions: any) {
        this.$emit('exceptions', exceptions as Exception[]);
      } finally {
        this.isUploading = false;
      }
    },
  },
});
