
import {defineComponent} from "vue";
import {mapActions} from 'vuex';
import AbstractCbCommon from "@/components/layouts/content-pages/wysiwyg/structures/AbstractCbCommon.vue";
import {ContactFormContentRestDtoModel} from "@/models/ContactFormContentRestDtoModel";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import {DpException} from "@/exception/DpException";
import {PharmacyInformationRestService} from "@/services/rest/cms-pharmacy/PharmacyInformationRestService";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import InputSelect from "@/components/UI/InputSelect.vue";

export default defineComponent({
  name: "CbContactForm",
  components: {InputSelect, BaseSpinner},
  extends: AbstractCbCommon,
  data: () => ({
    contentBlock: ContactFormContentRestDtoModel.createWithDefaults(),
    pharmacyOptions: [] as CodeDescriptionRestDto[]
  }),
  methods: {
    ...mapActions('cms_content_block/normal_content_block/contact_form', ['create', 'find', 'save']),
    async afterReloadContent(): Promise<void> {
      await this.findPharmacies();

      if (!this.contentBlock.pharmacy_code) {
        this.contentBlock.pharmacy_code = this.pharmacyOptions[0].code;
      }
    },
    beforeSubmitData(): void {
      if (!this.contentBlock.pharmacy_code) {
        throw [new DpException(this.$t('Pharmacy needs to be selected.'))]
      }
    },
    getDefaultCbModel(): ContactFormContentRestDtoModel {
      return ContactFormContentRestDtoModel.createWithDefaults();
    },
    async findPharmacies(): Promise<void> {
      try {
        this.pharmacyOptions = await PharmacyInformationRestService
          .getInstance()
          .findPharmacies(true);
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }
    }
  }
});
