import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "aria-labelledby", "data-bs-parent"]
const _hoisted_2 = { class: "accordion-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: _normalizeClass(['accordion-collapse collapse', {'show': _ctx.isOpen}]),
    "aria-labelledby": _ctx.labelId,
    "data-bs-parent": (_ctx.isAlwaysOpen) ? undefined : '#' + this.parentId
  }, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 10, _hoisted_1))
}