
import {defineComponent} from "vue";
import ProductSelectionBrandsSelectionItemBadges
  from "@/components/layouts/catalog/product-selection/ProductSelectionBrandsSelectionItemBadges.vue";
import {PropType} from "vue/dist/vue";
import {SelectableBrandRestDto, SelectableProductRestDto} from "@/api/pharma-cpc-mgmt/models";
import {SelectionTypeEnum} from "@/api/enums/selection-type-enum";

export default defineComponent({
  name: "ProductSelectionBrandsSelectionItem",
  components: {ProductSelectionBrandsSelectionItemBadges},
  emits: ["updateBrandItem"],
  props: {
    brandItem: {
      type: Object as PropType<SelectableBrandRestDto>,
      required: true,
    },
    filterSelectionTypeCode: {
      type: String,
      required: false,
      default: undefined,
    }
  },
  computed: {
    hasProducts(): Boolean {
      return this.brandItem.base_selection_product_count > 0 || this.brandItem.customer_catalog_product_count > 0;
    }
  },
  methods: {
    updateBrandItem(brand_code: string, selection_type: string) {
      let selection_type_code = '' as SelectionTypeEnum;
      switch (selection_type) {
        case 'BASE_SELECTION':
          selection_type_code = SelectionTypeEnum.BASE_SELECTION;
          break;
        case 'INCLUDE':
          selection_type_code = SelectionTypeEnum.INCLUDE;
          break;
        case 'EXCLUDE':
          selection_type_code = SelectionTypeEnum.EXCLUDE;
          break;
      }

      this.$emit('updateBrandItem', brand_code, selection_type_code);
    }
  }
});
