
import {defineComponent} from "vue";
import {Exception} from "@/api/interfaces";
import {mapActions, mapGetters} from "vuex";
import {arrayHasContent} from "@/helpers/functions/arrays";
import ProductsOverviewItem from "@/components/layouts/catalog/customer-products/ProductsOverviewItem.vue";
import CatalogBuildStatus from "@/components/layouts/catalog/CatalogBuildStatus.vue";
import {CustomerProductOverviewRestDto, PagingMetadataRestDto} from "@/api/pharma-cpc-product-mgmt/models";
import BaseTitle from "@/components/UI/BaseTitle.vue";

export default defineComponent({
  name: "CustomerProductsOverview",
  components: {BaseTitle, CatalogBuildStatus, ProductsOverviewItem},
  data() {
    return {
      exceptions: [] as Exception[],
      isReady: false as boolean,
      savedSuccessfully: false as boolean,
      tableHeaderItems: [this.$t('name'), this.$t('cnkCode'), this.$t('priceInclVat'), this.$t('vatPercentage'), this.$t('status'), ''] as string[],
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cpc_product_mgmt', ['getCustomerProductOverview']),
    pagingMetadata(): PagingMetadataRestDto {
      return this.getCustomerProductOverview.paging_metadata;
    },
    products(): CustomerProductOverviewRestDto[] {
      return this.getCustomerProductOverview.results;
    },
    hasProducts(): boolean {
      return arrayHasContent(this.products);
    }
  },
  methods: {
    ...mapActions('cpc_product_mgmt', ['deleteCustomerProduct', 'searchCustomerProductOverview']),
    async reloadContent() {
      this.isReady = false;
      this.exceptions = [];

      try {
        await this.searchCustomerProductOverview();
      } catch (exceptions: any) {
        this.isReady = true;
        this.exceptions = exceptions;
      }

      this.isReady = true;
    },
    async deleteProduct(product_code: string) {
      try {
        const answer = window.confirm(this.$t('deleteConfirmation'));

        if (answer) {
          this.isReady = false;

          await this.deleteCustomerProduct(product_code);

          await this.reloadContent();
        } else {
          return;
        }
      } catch (exceptions: any) {
        this.isReady = true;
        this.exceptions = exceptions;
      }
    }
  }
});
