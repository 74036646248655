
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {WysiwygContentPageRestDto} from "@/api/pharma-cms-content-page/models";
import {Exception} from "@/api/interfaces";
import AutoSaveFeedback from "@/components/UI/AutoSaveFeedback.vue";
import {AutoSaveFeedbackStatusEnum} from "@/models/enum/AutoSaveFeedbackStatusEnum";
import Subtitle from "@/components/UI/Subtitle.vue";
import ability from "@/config/ability";
import BaseOffcanvasButtonClose from "@/components/UI/Bootstrap/Offcanvas/BaseOffcanvasButtonClose.vue";

export default defineComponent({
  name: "ContentPagePageSettings",
  components: {BaseOffcanvasButtonClose, Subtitle, AutoSaveFeedback},
  props: {
    pageType: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      exceptions: [] as Exception[],
      autoSaveFeedbackStatus: AutoSaveFeedbackStatusEnum.NONE as AutoSaveFeedbackStatusEnum,
    };
  },
  computed: {
    ...mapGetters('cms_content_page_wysiwyg', ["getContentPage"]),
    contentPage(): WysiwygContentPageRestDto {
      return this.getContentPage;
    },
    isMultiLingual(): boolean {
      return ability.can('use-feature', 'MULTILINGUAL');
    },
  },
  methods: {
    ...mapActions('cms_content_page_wysiwyg', ['saveContentPage']),
    async save(): Promise<void> {
      this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.BUSY;

      try {
        await this.saveContentPage(this.contentPage.id);

        this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.SUCCESS;
      } catch (exceptions: any) {
        this.exceptions = exceptions;

        this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.FAILED;
      }
    },
    onChange(): void {
      this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.UNSAVED_CHANGES;
    },
  },
});
