import {RestService} from "@/services/rest/RestService";
import {Pp2ProductBrandAPIApi} from "@/api/pharma-pim-pp2";
import {CodeDescriptionRestDto} from "@/api/pharma-pim-pp2/models";

export class PimProductBrandRestService extends RestService {

    private apiService: Pp2ProductBrandAPIApi;

    protected constructor() {
        super();

        this.apiService = new Pp2ProductBrandAPIApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().pimUrl);
    }

    public async findPp2ProductBrands(search_name?: string | null): Promise<CodeDescriptionRestDto[]> {
        const response = await this.apiService
            .findPp2ProductBrands(undefined, undefined, search_name);

        return response.data;
    }
}
