export class UIStateDto {
    private _isReady: boolean;
    private error: string | null;

    constructor() {
        this._isReady = false;
        this.error = null;
    }

    public static createWithDefaults(): UIStateDto {
        return new this();
    }

    public isReady(): boolean {
        return this._isReady;
    }

    public isNotReady(): boolean {
        return !this._isReady;
    }

    public getIsReady(): boolean {
        return this._isReady;
    }

    public getError(): string | null {
        return this.error;
    }

    public hasError(): boolean {
        return this.error !== null;
    }

    public setReady(): UIStateDto {
        this._isReady = true;

        return this;
    }

    public setNotReady(): UIStateDto {
        this._isReady = false;

        return this;
    }

    public setIsReady(isReady: boolean): UIStateDto {
        this._isReady = isReady;

        return this;
    }

    public setError(error: string | null): UIStateDto {
        this.error = error;

        return this;
    }

    public clearError(): UIStateDto {
        this.error = null;

        return this;
    }
}
