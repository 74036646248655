import {CodeDescriptionRestDto, LaboProductDetailRestDto} from "@/api/pharma-pim-labo/models";
import {LaboProductInfoRestDtoModel} from "@/models/api/pharma-pim-labo/LaboProductInfoRestDtoModel";

export class LaboProductDetailRestDtoModel implements LaboProductDetailRestDto {
    id: number;
    product_code?: string | null;
    product_info: LaboProductInfoRestDtoModel;
    categories?: CodeDescriptionRestDto[] | null;

    constructor(id: number, product_info: LaboProductInfoRestDtoModel) {
        this.id = id;
        this.product_info = product_info;
    }

    public static createWithDefaults(): LaboProductDetailRestDtoModel {
        return new this(0, LaboProductInfoRestDtoModel.createWithDefaults());
    }
}
