
import {defineComponent, PropType} from 'vue'
import {OffcanvasPositionEnum} from "@/models/enum/bootstrap/OffcanvasPositionEnum";
import {generateDomId} from "@/helpers/functions/string";

export default defineComponent({
  name: "BaseOffcanvas",
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    class: {
      type: String,
      required: false,
      default: ''
    },
    id: {
      type: String,
      required: false,
      default: generateDomId('offcanvas')
    },
    label: {
      type: String,
      required: false,
      default: 'offcanvasLabel'
    },
    position: {
      type: String as PropType<OffcanvasPositionEnum>,
      required: false,
      default: OffcanvasPositionEnum.END
    },
    canScrollBody: {
      type: Boolean,
      required: false,
      default: false
    },
    dontUseBackdrop: {
      type: Boolean,
      required: false,
      default: false
    },
    noHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    customContent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    attributes(): object {
      return {
        "data-bs-backdrop": this.dontUseBackdrop ? "false" : "static",
        "data-bs-scroll": this.canScrollBody ? "true" : "false"
      };
    }
  }
});
