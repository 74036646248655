
import {defineComponent} from "vue";
import {SimpleContentBlockRestService} from "@/services/rest/cms-content-block/SimpleContentBlockRestService";
import InputCodeEditor from "@/components/UI/InputCodeEditor.vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {
  SimpleTextContentRestDtoModel
} from "@/models/api/pharma-cms-simple-content-block/SimpleTextContentRestDtoModel";
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";

export default defineComponent({
  name: "SimpleTextCbCustom",
  components: {BaseSpinner, AlertError2, BaseTitle, InputCodeEditor},
  props: {
    cbType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      simpleTextCb: SimpleTextContentRestDtoModel.createWithDefaults(),
      simpleTextCbUI: UIStateDto.createWithDefaults(),
    };
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    codeEditorLanguages(): [string[]] {
      switch (this.cbType) {
        case 'CUSTOM_CSS':
          return [['css', 'CSS']];
        case 'CUSTOM_JS':
          return [['js', 'JS']];
        default:
          return [['', '']];
      }
    },
    title(): string {
      switch (this.cbType) {
        case 'CUSTOM_CSS':
          return 'Custom CSS';
        case 'CUSTOM_JS':
          return 'Custom JS';
        default:
          return 'Custom CB';
      }
    }
  },
  methods: {
    async reloadContent() {
      this.simpleTextCbUI.setNotReady();

      try {
        this.simpleTextCb = await SimpleContentBlockRestService.getInstance()
          .getSimpleContentBlockText(this.cbType);

        if (this.simpleTextCb.content.nl === null) {
          this.simpleTextCb.content.nl = '';
        }
      } catch (exceptions: unknown) {
        this.handleExceptions(exceptions);
      } finally {
        this.simpleTextCbUI.setReady();
      }
    },
    async submitData() {
      this.simpleTextCbUI.clearError();

      try {
        await SimpleContentBlockRestService.getInstance()
          .updateSimpleContentBlockText(this.cbType, this.simpleTextCb);

        await handleSavedSuccessfully();
        await this.reloadContent();
      } catch (exceptions: unknown) {
        this.handleExceptions(exceptions);
      }
    },
    handleExceptions(exceptions: unknown) {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.simpleTextCbUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.simpleTextCbUI as UIStateDto))
        .catch(exceptions, true);
    }
  },
});
