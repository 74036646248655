import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-4" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-4" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-auto" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-2" }
const _hoisted_9 = { class: "col-2" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-2" }
const _hoisted_12 = { class: "row bottom" }
const _hoisted_13 = { class: "col-12" }
const _hoisted_14 = { class: "d-inline-block pt-4 border-top border-2 border-light" }
const _hoisted_15 = {
  type: "submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_alert_success = _resolveComponent("alert-success")!
  const _component_AlertError2 = _resolveComponent("AlertError2")!
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_input_select = _resolveComponent("input-select")!
  const _component_input_text = _resolveComponent("input-text")!
  const _component_input_number = _resolveComponent("input-number")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_dp_form = _resolveComponent("dp-form")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_BaseTitle, {
        title: _ctx.$t('deliveryCosts'),
        "back-route": this.returnRouteObject,
        mode: this.mode,
        "help-text": _ctx.$t('descriptionDeliveryCostRuleDetail'),
        mb: 5,
        "show-refresh-button": "",
        onRefresh: this.reloadContent
      }, null, 8, ["title", "back-route", "mode", "help-text", "onRefresh"]),
      _createVNode(_component_alert_success, {
        "show-if": _ctx.savedSuccessfully,
        class: "mb-5"
      }, null, 8, ["show-if"]),
      _createVNode(_component_AlertError2, {
        error: this.pageUI.getError()
      }, null, 8, ["error"]),
      _createVNode(_component_BaseSpinner, {
        spin: !this.pageUI.getIsReady()
      }, null, 8, ["spin"]),
      _createElementVNode("div", null, [
        (this.pageUI.getIsReady())
          ? (_openBlock(), _createBlock(_component_dp_form, {
              key: 0,
              as: "form",
              onSubmit: _ctx.submitData,
              novalidate: ""
            }, {
              default: _withCtx(({ errors }) => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_input_select, {
                      modelValue: _ctx.detailModel.delivery_method_code,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.detailModel.delivery_method_code) = $event)),
                      label: _ctx.$t('deliveryMethod'),
                      name: "deliveryMethod",
                      options: this.authorizedMethods,
                      rules: "required",
                      errors: errors
                    }, null, 8, ["modelValue", "label", "options", "errors"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_input_select, {
                      modelValue: _ctx.detailModel.country_code,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.detailModel.country_code) = $event)),
                      label: _ctx.$t('country'),
                      name: "country_code",
                      options: _ctx.countriesForDropdown,
                      errors: errors
                    }, null, 8, ["modelValue", "label", "options", "errors"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_input_text, {
                      modelValue: _ctx.detailModel.postal_code,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.detailModel.postal_code) = $event)),
                      modelModifiers: { trim: true },
                      label: _ctx.$t('postalCode'),
                      name: "postalCode",
                      rules: "max:6",
                      errors: errors
                    }, null, 8, ["modelValue", "label", "errors"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_input_number, {
                      modelValue: _ctx.detailModel.order_amount_with_vat_from,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.detailModel.order_amount_with_vat_from) = $event)),
                      modelModifiers: { number: true },
                      label: _ctx.$t('fromOrEq'),
                      name: "order_amount_with_vat_from",
                      min: 0,
                      step: 0.01,
                      errors: errors
                    }, null, 8, ["modelValue", "label", "step", "errors"])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_input_number, {
                      modelValue: _ctx.detailModel.order_amount_with_vat_to,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.detailModel.order_amount_with_vat_to) = $event)),
                      modelModifiers: { number: true },
                      label: _ctx.$t('to'),
                      name: "order_amount_with_vat_to",
                      min: 0,
                      step: 0.01,
                      errors: errors
                    }, null, 8, ["modelValue", "label", "step", "errors"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_input_number, {
                      modelValue: _ctx.detailModel.delivery_price_with_vat,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.detailModel.delivery_price_with_vat) = $event)),
                      modelModifiers: { number: true },
                      label: _ctx.$t('deliveryCost') + ' (' + _ctx.$t('inclVat') + ')',
                      name: "delivery_price_with_vat",
                      min: 0,
                      step: 0.01,
                      rules: "required",
                      errors: errors
                    }, null, 8, ["modelValue", "label", "step", "errors"])
                  ])
                ]),
                _createElementVNode("section", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("button", _hoisted_15, _toDisplayString(_ctx.$t('save')), 1),
                      _createVNode(_component_router_link, {
                        class: "ms-3 btn btn-outline-primary",
                        to: this.returnRouteObject
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["onSubmit"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}