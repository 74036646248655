import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73096bd4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "d-flex align-items-center me-2"
}
const _hoisted_2 = { class: "mas" }
const _hoisted_3 = {
  key: 1,
  type: "button",
  class: "btn-close",
  "data-bs-dismiss": "alert",
  "aria-label": "Close"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['alert p-3', _ctx.alertClasses]),
    role: "alert"
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("i", _hoisted_2, _toDisplayString(_ctx.icon), 1)
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.isDismissible)
      ? (_openBlock(), _createElementBlock("button", _hoisted_3))
      : _createCommentVNode("", true)
  ], 2))
}