
import {defineComponent} from "vue"
import {SymbolRestDtoModel} from "@/models/api/pharma-cms-content-block/SymbolRestDtoModel";
import {SymbolHorizontalPositionEnum} from "@/api/enums/symbol-horizontal-position-enum";
import {SymbolVerticalPositionEnum} from "@/api/enums/symbol-vertical-position-enum";
import {convertRgbToHex} from "@/helpers/functions/color";

const __default__ = defineComponent({
  name: "PreviewSymbol",
  props: {
    symbol: {
      type: [SymbolRestDtoModel, Object],
      required: true,
      default: SymbolRestDtoModel.createWithDefaults()
    }
  },
  data() {
    return {
      rootFontSize: '14px'
    }
  },
  computed: {
    colorDefault(): string {
      return convertRgbToHex(this.symbol.default_state_color.rgb);
    },
    colorHover(): string {
      return convertRgbToHex(this.symbol.hover_state_color.rgb);
    },
    fontFamily(): string {
      return this.symbol.family_code
        .replaceAll('_', ' ')
        .toLowerCase();
    },
    masFill(): number {
      return this.symbol?.is_filled ? 1 : 0;
    },
    masGrad(): string {
      return this.symbol.grade_code
        .replaceAll('MIN', '-')
        .replace(/[^\d-]/g, '');
    },
    masOpsz(): string {
      return this.symbol.optical_size_code.replace(/\D/g, '');
    },
    masWght(): string {
      return this.symbol.weight_code.replace(/\D/g, '');
    },
    horizClass(): string {
      switch (this.symbol.horizontal_position_code) {
        case SymbolHorizontalPositionEnum.LEFT:
          return 'justify-content-start';
        case SymbolHorizontalPositionEnum.CENTER:
          return 'justify-content-center';
        case SymbolHorizontalPositionEnum.RIGHT:
          return 'justify-content-end';
      }

      return 'justify-content-start';
    },
    vertClass(): string {
      switch (this.symbol.vertical_position_code) {
        case SymbolVerticalPositionEnum.TOP:
          return 'align-items-start';
        case SymbolVerticalPositionEnum.CENTER:
          return 'align-items-center';
        case SymbolVerticalPositionEnum.BOTTOM:
          return 'align-items-end';
      }

      return 'align-items-start';
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "0655a88c": (_ctx.rootFontSize),
  "a1b13db4": (_ctx.colorDefault),
  "6ce7b8c6": (_ctx.symbol.default_state_color.opacity),
  "6d28965b": (_ctx.fontFamily),
  "1ecbf0fd": (_ctx.symbol.size_in_rem + 'em'),
  "ddea3a8c": (_ctx.masFill),
  "dddad5da": (_ctx.masWght),
  "dde910f6": (_ctx.masGrad),
  "dde1d582": (_ctx.masOpsz),
  "01c0c961": (_ctx.colorHover),
  "4037567e": (_ctx.symbol.hover_state_color.opacity)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__