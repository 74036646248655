import {ProductSelectionWholesalerRestDto} from "@/api/pharma-cpc-mgmt/models/product-selection-wholesaler-rest-dto";

export class ProductSelectionWholesalerRestDtoModel implements ProductSelectionWholesalerRestDto {
    is_added_to_base_selection: boolean;
    is_filtered_available_in_stock: boolean;


    constructor() {
        this.is_added_to_base_selection = false;
        this.is_filtered_available_in_stock = false;
    }

    public static createWithDefaults(): ProductSelectionWholesalerRestDtoModel {
        return new this();
    }
}
