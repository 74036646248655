/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Pharmacy API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ClosingPeriodRestDto } from '../models';
import { PeriodOverviewRestDto } from '../models';
/**
 * OpeningInfoClosingPeriodsEndpointsApi - axios parameter creator
 * @export
 */
export const OpeningInfoClosingPeriodsEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.PH.OH.01 Create Closing Period.
         * @param {ClosingPeriodRestDto} body 
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Closing Period belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClosingPeriod: async (body: ClosingPeriodRestDto, pharmacy_code: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createClosingPeriod.');
            }
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling createClosingPeriod.');
            }
            const localVarPath = `/pharmacy/v1/pharmacy/{pharmacy_code}/closing_period`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.OH.05 Delete Closing Period.
         * @param {string} id The unique identifier of this Team Member.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Closing Period belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClosingPeriod: async (id: string, pharmacy_code: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteClosingPeriod.');
            }
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling deleteClosingPeriod.');
            }
            const localVarPath = `/pharmacy/v1/pharmacy/{pharmacy_code}/closing_period/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.OH.02 Find Closing Periods.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Closing Period belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {number} [filter_year] Filter the resuls so that the period overlaps with the given year.
         * @param {string} [search_name] Filter the results so that the given value occurs in the name of the period. (Case insensitive)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findClosingPeriods: async (pharmacy_code: string, x_dp_customer_code?: string, filter_year?: number, search_name?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling findClosingPeriods.');
            }
            const localVarPath = `/pharmacy/v1/pharmacy/{pharmacy_code}/closing_period`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_year || typeof(filter_year) === "boolean") {
                localVarQueryParameter['filter_year'] = filter_year;
            }

            if (!!search_name || typeof(search_name) === "boolean") {
                localVarQueryParameter['search_name'] = search_name;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.OH.04 Get Closing Period.
         * @param {string} id The unique identifier of this Closing Period.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Closing Period belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClosingPeriod: async (id: string, pharmacy_code: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getClosingPeriod.');
            }
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling getClosingPeriod.');
            }
            const localVarPath = `/pharmacy/v1/pharmacy/{pharmacy_code}/closing_period/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.OH.03 Update Closing Period.
         * @param {ClosingPeriodRestDto} body 
         * @param {string} id The unique identifier of this Closing Period.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Closing Period belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClosingPeriod: async (body: ClosingPeriodRestDto, id: string, pharmacy_code: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateClosingPeriod.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateClosingPeriod.');
            }
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling updateClosingPeriod.');
            }
            const localVarPath = `/pharmacy/v1/pharmacy/{pharmacy_code}/closing_period/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpeningInfoClosingPeriodsEndpointsApi - functional programming interface
 * @export
 */
export const OpeningInfoClosingPeriodsEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.PH.OH.01 Create Closing Period.
         * @param {ClosingPeriodRestDto} body 
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Closing Period belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClosingPeriod(_axios: AxiosInstance, body: ClosingPeriodRestDto, pharmacy_code: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClosingPeriodRestDto>> {
            const localVarAxiosArgs = await OpeningInfoClosingPeriodsEndpointsApiAxiosParamCreator(configuration).createClosingPeriod(body, pharmacy_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.OH.05 Delete Closing Period.
         * @param {string} id The unique identifier of this Team Member.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Closing Period belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClosingPeriod(_axios: AxiosInstance, id: string, pharmacy_code: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OpeningInfoClosingPeriodsEndpointsApiAxiosParamCreator(configuration).deleteClosingPeriod(id, pharmacy_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.OH.02 Find Closing Periods.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Closing Period belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {number} [filter_year] Filter the resuls so that the period overlaps with the given year.
         * @param {string} [search_name] Filter the results so that the given value occurs in the name of the period. (Case insensitive)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findClosingPeriods(_axios: AxiosInstance, pharmacy_code: string, x_dp_customer_code?: string, filter_year?: number, search_name?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PeriodOverviewRestDto>>> {
            const localVarAxiosArgs = await OpeningInfoClosingPeriodsEndpointsApiAxiosParamCreator(configuration).findClosingPeriods(pharmacy_code, x_dp_customer_code, filter_year, search_name, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.OH.04 Get Closing Period.
         * @param {string} id The unique identifier of this Closing Period.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Closing Period belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClosingPeriod(_axios: AxiosInstance, id: string, pharmacy_code: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClosingPeriodRestDto>> {
            const localVarAxiosArgs = await OpeningInfoClosingPeriodsEndpointsApiAxiosParamCreator(configuration).getClosingPeriod(id, pharmacy_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.OH.03 Update Closing Period.
         * @param {ClosingPeriodRestDto} body 
         * @param {string} id The unique identifier of this Closing Period.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Closing Period belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClosingPeriod(_axios: AxiosInstance, body: ClosingPeriodRestDto, id: string, pharmacy_code: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OpeningInfoClosingPeriodsEndpointsApiAxiosParamCreator(configuration).updateClosingPeriod(body, id, pharmacy_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OpeningInfoClosingPeriodsEndpointsApi - object-oriented interface
 * @export
 * @class OpeningInfoClosingPeriodsEndpointsApi
 * @extends {BaseAPI}
 */
export class OpeningInfoClosingPeriodsEndpointsApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.CMS.PH.OH.01 Create Closing Period.
     * @param {ClosingPeriodRestDto} body 
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Closing Period belongs.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpeningInfoClosingPeriodsEndpointsApi
     */
     public createClosingPeriod(body: ClosingPeriodRestDto, pharmacy_code: string, x_dp_customer_code?: string, options?: any) {
        return OpeningInfoClosingPeriodsEndpointsApiFp(this.configuration).createClosingPeriod(this.axios, body, pharmacy_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.PH.OH.05 Delete Closing Period.
     * @param {string} id The unique identifier of this Team Member.
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Closing Period belongs.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpeningInfoClosingPeriodsEndpointsApi
     */
     public deleteClosingPeriod(id: string, pharmacy_code: string, x_dp_customer_code?: string, options?: any) {
        return OpeningInfoClosingPeriodsEndpointsApiFp(this.configuration).deleteClosingPeriod(this.axios, id, pharmacy_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.PH.OH.02 Find Closing Periods.
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Closing Period belongs.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {number} [filter_year] Filter the resuls so that the period overlaps with the given year.
     * @param {string} [search_name] Filter the results so that the given value occurs in the name of the period. (Case insensitive)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpeningInfoClosingPeriodsEndpointsApi
     */
     public findClosingPeriods(pharmacy_code: string, x_dp_customer_code?: string, filter_year?: number, search_name?: string, options?: any) {
        return OpeningInfoClosingPeriodsEndpointsApiFp(this.configuration).findClosingPeriods(this.axios, pharmacy_code, x_dp_customer_code, filter_year, search_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.PH.OH.04 Get Closing Period.
     * @param {string} id The unique identifier of this Closing Period.
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Closing Period belongs.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpeningInfoClosingPeriodsEndpointsApi
     */
     public getClosingPeriod(id: string, pharmacy_code: string, x_dp_customer_code?: string, options?: any) {
        return OpeningInfoClosingPeriodsEndpointsApiFp(this.configuration).getClosingPeriod(this.axios, id, pharmacy_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.PH.OH.03 Update Closing Period.
     * @param {ClosingPeriodRestDto} body 
     * @param {string} id The unique identifier of this Closing Period.
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the Closing Period belongs.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpeningInfoClosingPeriodsEndpointsApi
     */
     public updateClosingPeriod(body: ClosingPeriodRestDto, id: string, pharmacy_code: string, x_dp_customer_code?: string, options?: any) {
        return OpeningInfoClosingPeriodsEndpointsApiFp(this.configuration).updateClosingPeriod(this.axios, body, id, pharmacy_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
