
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {ContentOverviewRestDto} from "@/api/pharma-cms-content-block/models";
import BaseContainer from "@/components/UI/Bootstrap/BaseContainer.vue";
import CbsOverview from "@/components/layouts/content-blocks/overview/CbsOverview.vue";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import ConfirmModal from "@/components/UI/ConfirmModal.vue";
import {Exception} from "@/api/interfaces";
import {useToast} from "vue-toastification";
import {arrayHasContent} from "@/helpers/functions/arrays";
import {ContentTypeEnum} from "@/models/enum/ContentTypeEnum";
import {ContentBlockTypesEnum} from "@/helpers/functions/enum";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-page/models";
import {LibraryStructureTypeEnum} from "@/models/enum/LibraryStructureTypeEnum";
import {AbilityContext} from "@/context/AbilityContext";
import {
  ContentBlockLibraryRestService
} from "@/services/rest/cms-content-block/ContentBlockLibraryRestService";
import {LibraryOverviewRestDtoModel} from "@/models/api/pharma-cms-content-block-library/LibraryOverviewRestDtoModel";

export default defineComponent({
  name: "LibraryOverview",
  components: {BaseContainer, CbsOverview},
  data() {
    return {
      cbStructure: this.$route.params.cb_structure as string,
      cbType: this.$route.params.cb_type as string,
      docType: "PAGE_IMG" as string,
      libCode: this.$route.params.lib_type as string,
      libraryName: null as string | null,
      exceptions: [] as Exception[],
      isReady: false as boolean,
      toaster: useToast()
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cms_content_block', ["getContentBlockLibsForType", "getNormalContentBlocksOverview"]),
    ...mapGetters('cms_content_page', ["getPageAbstractLibraries"]),
    AbilityContext() {
      return AbilityContext
    },
    contentBlocks(): ContentOverviewRestDto[] {
      return this.getNormalContentBlocksOverview;
    },
    contentType(): ContentTypeEnum | null {
      switch (this.cbStructure) {
        case ContentBlockTypesEnum.BANNER:
          return ContentTypeEnum.BANNER;
        case ContentBlockTypesEnum.LOGO:
          return ContentTypeEnum.IMAGE;
        case ContentBlockTypesEnum.IMAGE:
          return ContentTypeEnum.IMAGE;
        case ContentBlockTypesEnum.TEXT_WITH_IMAGE:
          return ContentTypeEnum.TEXT_WITH_IMAGE;
        case ContentBlockTypesEnum.TEXT_WITH_TITLE:
          return ContentTypeEnum.TEXT_WITH_TITLE;
        default:
          return null;
      }
    },
    hasContentBlocks(): boolean {
      return arrayHasContent(this.contentBlocks);
    },
    title(): string | undefined {
      let libStructureName;

      switch (this.cbStructure) {
        case LibraryStructureTypeEnum.ARTICLE_ABSTRACT:
          libStructureName = this.$t('articleAbstracts');
          break;
        case LibraryStructureTypeEnum.CONTENT_PAGE:
          libStructureName = this.$t('contentPages');
          break;
        case ContentBlockTypesEnum.BANNER:
          libStructureName = this.$t('banners');
          break;
        case ContentBlockTypesEnum.IMAGE:
        case ContentBlockTypesEnum.LOGO:
          libStructureName = this.$t('images');
          break;
        case ContentBlockTypesEnum.TEXT_WITH_IMAGE:
          libStructureName = this.$t('textsWithImage');
          break;
        case ContentBlockTypesEnum.TEXT_WITH_TITLE:
          libStructureName = this.$t('textsWithTitle');
          break;
      }

      return `${libStructureName}${this.libraryName ? ' / ' + this.libraryName : ''}`;
    },
    tableHeadingItems(): string[] {
      let tableHeadingItems = [] as string[];

      switch (this.cbStructure) {
        case ContentBlockTypesEnum.BANNER:
          tableHeadingItems = [this.$t('banner'), this.$t('link'), this.$t('category'), this.$t('remark')];
          break;
        case ContentBlockTypesEnum.IMAGE:
          tableHeadingItems = [this.$t('image')];
          break;
        case ContentBlockTypesEnum.LOGO:
          tableHeadingItems = [this.$t('image')];
          break;
        case ContentBlockTypesEnum.TEXT_WITH_TITLE:
          tableHeadingItems = [this.$t('title'), this.$t('shortContent')];
          break;
        case ContentBlockTypesEnum.TEXT_WITH_IMAGE:
          tableHeadingItems = [this.$t('image'), this.$t('title'), this.$t('shortContent')];
          break;
      }
      tableHeadingItems.push(this.$t('sortWeight'), this.$t('status'), '');

      return tableHeadingItems;
    }
  },
  methods: {
    ...mapActions('cms_content_block', ["deleteNormalContentBlock", "searchNormalContentBlocksOverview", "searchContentBlockLibsForType"]),
    deleteContentBlock(id: string): void {
      this.exceptions = [];

      try {
        const {reveal, onConfirm} = createConfirmDialog(ConfirmModal, {
          title: 'Inhoudsblok verwijderen',
          body: 'Bent u zeker dat u deze inhoudsblok uit de lijst wilt verwijderen?',
          confirmButtonText: this.$t('delete'),
          confirmButtonClass: 'btn-danger',
        });

        onConfirm(async () => {
          await this.deleteNormalContentBlock({
            type: this.cbType,
            id: id,
          });

          this.toaster.success('Inhoudsblok succesvol verwijderd.');

          await this.reloadContent();
        });

        reveal();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async reloadContent(): Promise<void> {
      this.isReady = false;

      try {
        await this.searchNormalContentBlocksOverview({
          type: this.cbType,
          filter_library_code: this.libCode,
        });

        if (this.cbType === 'LIB_EXT_ARTICLE_ABSTRACT') {
          this.libraryName = this.getPageAbstractLibraries.find((lib: CodeDescriptionRestDto) => lib.code === this.libCode)?.description;
        } else {
          const cbLibs = await ContentBlockLibraryRestService.getInstance().findContentBlockLibsForType(this.cbType);
          this.libraryName = cbLibs.find((cbLib: LibraryOverviewRestDtoModel) => cbLib.library?.code === this.libCode)?.library?.description;
        }
        if (AbilityContext.isAuthorizedForFeature('MGMT_CUSTOMER_LIB_CONTENT')) {
          await this.searchContentBlockLibsForType({
            type: this.cbType,
          });
        }
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      this.isReady = true;
    },
  }
});
