import {OpeningHoursPeriodRestDto} from "@/api/pharma-cms-pharmacy/models";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import {OpeningHoursForWeekRestDtoModel} from "@/models/OpeningHoursForWeekRestDtoModel";

export class OpeningHoursPeriodRestDtoModel implements OpeningHoursPeriodRestDto {
    name: MultilingualRestDtoModel;
    begin: string;
    end: string;
    opening_hours: OpeningHoursForWeekRestDtoModel;

    constructor(name: MultilingualRestDtoModel, begin: string, end: string, opening_hours: OpeningHoursForWeekRestDtoModel) {
        this.name = name;
        this.begin = begin;
        this.end = end;
        this.opening_hours = opening_hours;
    }

    public static createWithDefaults(): OpeningHoursPeriodRestDtoModel {
        return new OpeningHoursPeriodRestDtoModel(
            MultilingualRestDtoModel.createWithDefaults(),
            '',
            '',
            OpeningHoursForWeekRestDtoModel.createWithDefaults(),
        );
    }
}
