import {NormalContentBlockModel} from "@/models/api/pharma-cms-content-block/NormalContentBlockModel";
import {
    BannerSlotContentRestDto, cloneBannerSlotContentRestDtoMetaData,
    ContentBlockMetadataRestDto,
} from "@/api/pharma-cms-content-block/models";
import {StatusEnum} from "@/api/enums/status-enum";

export class BannerSlotContentRestDtoModel extends NormalContentBlockModel implements BannerSlotContentRestDto {
    cb_metadata?: ContentBlockMetadataRestDto;
    max_content_size_code: string;
    position_code: string;
    sort_weight?: number;
    status_code: StatusEnum;
    subscription_slot_id?: string | null;

    constructor(max_content_size_code: string, position_code: string, status_code: StatusEnum) {
        super();
        this.max_content_size_code = max_content_size_code;
        this.position_code = position_code;
        this.status_code = status_code;
    }

    public static createWithDefaults(): BannerSlotContentRestDtoModel {
        const model = new BannerSlotContentRestDtoModel('SIZE_1_1', 'SMA_HOME_TOP', StatusEnum.PUBLISHED);
        model.subscription_slot_id = null;
        // model.sort_weight = NormalContentBlockModel.getDefaultSortWeight();

        return model;
    }

    public cloneMetadataFrom(source: BannerSlotContentRestDtoModel): void {
        cloneBannerSlotContentRestDtoMetaData(this, source)
    }
}
