import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-be838dba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-radio-boolean form-check mb-3" }
const _hoisted_2 = { class: "position-relative" }
const _hoisted_3 = { class: "d-flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dp_field = _resolveComponent("dp-field")!
  const _component_dp_error_message = _resolveComponent("dp-error-message")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_dp_field, {
          type: "radio",
          value: true,
          modelValue: this.model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.model) = $event)),
          name: this.name,
          id: this.id + 'True',
          class: _normalizeClass(["form-check-input form-check-input-true me-1", { 'is-invalid': this.errors[this.name] }]),
          disabled: this.disabled,
          rules: this.rules
        }, null, 8, ["modelValue", "name", "id", "class", "disabled", "rules"]),
        _createVNode(_component_dp_field, {
          type: "radio",
          value: false,
          modelValue: this.model,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.model) = $event)),
          name: this.name,
          id: this.id + 'False',
          class: _normalizeClass(["form-check-input form-check-input-false", { 'is-invalid': this.errors[this.name] }]),
          disabled: this.disabled,
          rules: this.rules
        }, null, 8, ["modelValue", "name", "id", "class", "disabled", "rules"]),
        _createElementVNode("label", {
          class: _normalizeClass(["form-label form-check-label lh-1 ms-2", {'me-4': this.canBeCleared}])
        }, _toDisplayString(this.label ?? 'Label'), 3)
      ]),
      (this.canBeCleared)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "btn-close position-absolute top-50 end-0",
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (this.model = null), ["prevent"]))
          }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_dp_error_message, {
      class: "invalid-feedback d-block",
      name: this.name
    }, null, 8, ["name"])
  ]))
}