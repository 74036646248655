import {EnumContentRestDto, MultilingualFieldRestDto} from "@/api/pharma-order-enum/models";
import {MultilingualFieldRestDtoModel} from "@/models/api/pharma-order-enum/MultilingualFieldRestDtoModel";

export class EnumContentRestDtoModel implements EnumContentRestDto {
    field_type_code?: string | null;
    content?: MultilingualFieldRestDtoModel | null;
    fallback_content?: MultilingualFieldRestDto | null;

    constructor() {
    }

    public static createWithDefaults(): EnumContentRestDtoModel {
        return new this()
    }
}
