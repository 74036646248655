
import {defineComponent} from 'vue'
import {UIStateDto} from "@/dtos/UIStateDto";
import BasePlaceholderTable from "@/components/UI/Bootstrap/BasePlaceholderTable.vue";
import CatalogProductsOverviewTableItem
  from "@/components/layouts/catalog/products/CatalogProductsOverviewTableItem.vue";
import {
  PagedCatalogProductOverviewResultsRestDtoModel
} from "@/models/api/pharma-cpc-product-mgmt/PagedCatalogProductOverviewResultsRestDtoModel";
import {ProductCategorizationRestService} from "@/services/rest/cpc-product-mgmt/ProductSortWeightRestService";

export default defineComponent({
  name: "CatalogProductsOverviewTable",
  components: {CatalogProductsOverviewTableItem, BasePlaceholderTable},
  emits: ['dataChanged'],
  data() {
    return {
      draggedIndex: -1,
    }
  },
  props: {
    productsOverview: {
      type: [PagedCatalogProductOverviewResultsRestDtoModel, Object],
      required: true
    },
    hasProducts: {
      type: Boolean,
      required: true
    },
    ui: {
      type: UIStateDto,
      required: true
    },
    onlyProductsWithCustomWeights: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    dragStart(event: any, index: number) {
      this.draggedIndex = index;
    },
    async drop(event: any, targetIndex: number) {
      const draggedItem = this.productsOverview.results[this.draggedIndex];
      const targetItem = this.productsOverview.results[targetIndex];
      await ProductCategorizationRestService.getInstance().moveCustomerProductSortWeightTo(
        draggedItem.product_code, {sort_weight: targetItem.customer_product_sort_weight});
      this.$emit('dataChanged');
    },
  }
})
