import {MultilingualFieldRestDtoModel} from "@/models/api/pharma-order-enum/MultilingualFieldRestDtoModel";
import {
    MultilingualMax255FieldRestDto,
    ProductCustomNameRestDto,
    ProductCustomNamesOverviewRestDto
} from "@/api/pharma-cpc-product-mgmt/models";
import {ProductCustomNameRestDtoModel} from "@/models/api/pharma-cpc-product-mgmt/ProductCustomNameRestDtoModel";

export class ProductCustomNamesOverviewRestDtoModel implements ProductCustomNamesOverviewRestDto {

    custom_name?: ProductCustomNameRestDto | null;
    customer_catalog_name: MultilingualMax255FieldRestDto;
    master_catalog_name: MultilingualMax255FieldRestDto;

    constructor() {
        this.customer_catalog_name = MultilingualFieldRestDtoModel.createWithDefaults();
        this.master_catalog_name = MultilingualFieldRestDtoModel.createWithDefaults();
        this.custom_name = ProductCustomNameRestDtoModel.createWithDefaults();
    }

    public static createWithDefaults(): ProductCustomNamesOverviewRestDtoModel {
        return new this();
    }
}

