import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "buttons d-flex justify-content-end align-items-center mt-3" }
const _hoisted_2 = {
  type: "submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertError2 = _resolveComponent("AlertError2")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputCheckboxBoolean = _resolveComponent("InputCheckboxBoolean")!
  const _component_base_modal_button = _resolveComponent("base-modal-button")!
  const _component_dp_form = _resolveComponent("dp-form")!

  return (_openBlock(), _createBlock(_component_dp_form, {
    as: "form",
    onSubmit: this.submitData,
    novalidate: ""
  }, {
    default: _withCtx(({ errors }) => [
      _createVNode(_component_AlertError2, {
        error: this.paymentProviderSettingsUI.getError()
      }, null, 8, ["error"]),
      _createVNode(_component_InputText, {
        modelValue: this.paymentProviderSettingsClone.auth_token,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.paymentProviderSettingsClone.auth_token) = $event)),
        modelModifiers: { trim: true },
        name: "auth-token",
        label: _ctx.$t('authenticationToken'),
        rules: "required",
        errors: errors
      }, null, 8, ["modelValue", "label", "errors"]),
      (this.paymentProviderSettingsClone?.auth_token?.length)
        ? (_openBlock(), _createBlock(_component_InputCheckboxBoolean, {
            key: 0,
            modelValue: this.paymentProviderSettingsClone.is_active,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.paymentProviderSettingsClone.is_active) = $event)),
            name: "is-active",
            label: _ctx.$t('active'),
            errors: errors
          }, null, 8, ["modelValue", "label", "errors"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_base_modal_button, {
          mode: "close",
          class: "btn btn-outline-primary me-2"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
          ]),
          _: 1
        }),
        _createElementVNode("button", _hoisted_2, _toDisplayString(_ctx.$t('save')), 1)
      ])
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}