import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BannerOverview = _resolveComponent("BannerOverview", true)!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, { "container-fluid": "" }, {
    default: _withCtx(() => [
      _createVNode(_component_BannerOverview, {
        "can-copy-item": "",
        "can-use-library": "",
        "is-library": false,
        "route-object-edit-cb": {name: 'customer-banners-edit'},
        "route-object-new-cb": {name: 'customer-banners-new'},
        description: _ctx.$t('descriptionBannerOverviewCustomer'),
        title: _ctx.$t('bannersOverview')
      }, null, 8, ["description", "title"])
    ]),
    _: 1
  }))
}