/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */

import {ContentBlockMetadataRestDto} from ".";
import {PageReferenceOrderByRestDto} from ".";
import {ImageFitEnum} from "@/api/enums/image-fit-enum";
import {ImageHorizontalPositionEnum} from "@/api/enums/image-horizontal-position-enum";
import {ImageVerticalPositionEnum} from "@/api/enums/image-vertical-position-enum";
import {PageReferencesContentLayoutEnum} from "@/api/enums/page-references-content-layout-enum";
import {StatusEnum} from "@/api/enums/status-enum";
import {VisibilityEnum} from "@/api/enums/visibility-enum";

/**
 * @export
 * @interface PageReferencesContentRestDto
 */
export interface PageReferencesContentRestDto {
    /**
     * The status of this content block.
     * @type {StatusEnum}
     * @memberof PageReferencesContentRestDto
     */
    status_code: StatusEnum;

    /**
     * The sorting value of this content block.
     * @type {number|null}
     * @memberof PageReferencesContentRestDto
     */
    sort_weight?: number|null;

    /**
     * The layout in which the referenced pages should be shown.
     * @type {PageReferencesContentLayoutEnum}
     * @memberof PageReferencesContentRestDto
     */
    layout_code: PageReferencesContentLayoutEnum;

    /**
     * Determines what will be visible.
     * @type {VisibilityEnum|null}
     * @memberof PageReferencesContentRestDto
     */
    visibility_code?: VisibilityEnum|null;

    /**
     * Determines the object-fit style for the image.
     * @type {ImageFitEnum|null}
     * @memberof PageReferencesContentRestDto
     */
    image_fit_code?: ImageFitEnum|null;

    /**
     * Determines the horizontal object-position for the image.
     * @type {ImageHorizontalPositionEnum|null}
     * @memberof PageReferencesContentRestDto
     */
    image_horizontal_position_code?: ImageHorizontalPositionEnum|null;

    /**
     * Determines the vertical object-position for the image.
     * @type {ImageVerticalPositionEnum|null}
     * @memberof PageReferencesContentRestDto
     */
    image_vertical_position_code?: ImageVerticalPositionEnum|null;

    /**
     * The content block type (=collection type) of the internal pages that are referenced in this content block. (When specified, page_abstr_lib_code must be null.)
     * @type {string|null}
     * @memberof PageReferencesContentRestDto
     */
    page_type_code?: string|null;

    /**
     * The library code that contains the abstracts of the external pages that are referenced in this content block. (When specified, page_abstr_lib_code must be null.)
     * @type {string|null}
     * @memberof PageReferencesContentRestDto
     */
    page_abstr_lib_code?: string|null;

    /**
     * If true, only pages with is_on_homepage = true will be shown.
     * @type {boolean|null}
     * @memberof PageReferencesContentRestDto
     */
    filter_show_on_homepage?: boolean|null;

    /**
     * If provided, only pages with a corresponding label will be shown.
     * @type {string[]|null}
     * @memberof PageReferencesContentRestDto
     */
    filter_label?: string[]|null;

    /**
     * The number of references will be limited to this number.
     * @type {number|null}
     * @memberof PageReferencesContentRestDto
     */
    limit_references_count?: number|null;

    /**
     * If specified, paging will be applied and the number indicates the maximum number of results per page. (NOTE! This is only allowed when there is 1 such block on a Page.)
     * @type {number|null}
     * @memberof PageReferencesContentRestDto
     */
    references_per_page?: number|null;

    /**
     * Defines how the pages will be sorted. (NOTE! When page_abstr_lib_code is not null, only SORT_WEIGHT and CREATED are allowed as sort criteria.)
     * @type {PageReferenceOrderByRestDto[]|null}
     * @memberof PageReferencesContentRestDto
     */
    order_by_fields?: PageReferenceOrderByRestDto[]|null;

    /**
     * 
     * @type {ContentBlockMetadataRestDto|null}
     * @memberof PageReferencesContentRestDto
     */
    cb_metadata?: ContentBlockMetadataRestDto|null;

}

/**
 * @export
 * @function clonePageReferencesContentRestDtoMetaData
 */
export function clonePageReferencesContentRestDtoMetaData(dto: PageReferencesContentRestDto, source: PageReferencesContentRestDto): void {
    dto.layout_code = source.layout_code;
    dto.visibility_code = source.visibility_code;
    dto.image_fit_code = source.image_fit_code;
    dto.image_horizontal_position_code = source.image_horizontal_position_code;
    dto.image_vertical_position_code = source.image_vertical_position_code;
    dto.filter_show_on_homepage = source.filter_show_on_homepage;
    dto.filter_label = source.filter_label;
    dto.limit_references_count = source.limit_references_count;
    dto.references_per_page = source.references_per_page;
    dto.order_by_fields = source.order_by_fields;
}
