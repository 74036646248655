import {CpcProductMgmtCustomerProductsEndpointsApiService} from "@/main";
import {
    CustomerProductRestDto,
    MultilingualProductPropertyRestDto,
    PagedCustomerProductOverviewRestDto
} from "@/api/pharma-cpc-product-mgmt/models";
import {
    validateCustomerProduct,
} from "@/store/pharma-cpc-product-mgmt/middleware";
import {CustomerProductRestDtoModel} from "@/models/api/pharma-cpc-product-mgmt/CustomerProductRestDtoModel";


export default {
    state() {
        return {
            customer_product: CustomerProductRestDtoModel.createWithDefaults(),
            customer_product_overview: {} as PagedCustomerProductOverviewRestDto,
        }
    },
    actions: {
        clearCustomerProduct(context: any) {
            context.commit("clearCustomerProduct");
        },
        addProductCategory(context: any, category_code: string) {
            context.commit("addProductCategory", category_code);
        },
        removeProductCategory(context: any, category_code: string) {
            context.commit("removeProductCategory", category_code);
        },
        async createCustomerProduct(context: any) {
            validateCustomerProduct(context.getters.getCustomerProduct);
            await CpcProductMgmtCustomerProductsEndpointsApiService.createCustomerProduct(context.getters.getCustomerProduct);
        },
        async deleteCustomerProduct(context: any, product_code: string) {
            await CpcProductMgmtCustomerProductsEndpointsApiService.deleteCustomerProduct(product_code);
        },
        async saveCustomerProduct(context: any, product_code: string) {
            validateCustomerProduct(context.getters.getCustomerProduct);
            await CpcProductMgmtCustomerProductsEndpointsApiService.updateCustomerProduct(context.getters.getCustomerProduct, product_code);
        },
        async searchCustomerProduct(context: any, product_code: string) {
            const response = await CpcProductMgmtCustomerProductsEndpointsApiService.getCustomerProduct(product_code);

            context.commit("setCustomerProduct", response.data);
        },
        async searchCustomerProductOverview(context: any) {
            const response = await CpcProductMgmtCustomerProductsEndpointsApiService.findCustomerProducts();

            context.commit("setCustomerProductOverview", response.data);
        },
    },
    mutations: {
        clearCustomerProduct(state: any) {
            state.customer_product = CustomerProductRestDtoModel.createWithDefaults();
        },
        addProductCategory(state: any, category_code: string) {
            if (!state.customer_product.category_codes) {
                state.customer_product.category_codes = [];
            }
            state.customer_product.category_codes.push(category_code);
        },
        removeProductCategory(state: any, category_code: string) {
            state.customer_product.category_codes = state.customer_product.category_codes
                .filter((category_code_in_state: string) => category_code_in_state !== category_code);
        },
        setCustomerProduct(state: any, product: CustomerProductRestDto) {
            state.customer_product = product;
        },
        setCustomerProductOverview(state: any, product_overview: PagedCustomerProductOverviewRestDto) {
            state.customer_product_overview = product_overview;
        },
    },
    getters: {
        getCustomerProduct(state: any): CustomerProductRestDto {
            return state.customer_product;
        },
        getCustomerProductProperty: (state: any) => (property_type_code: string): MultilingualProductPropertyRestDto => {
            return state.customer_product.product_properties
                .find((product_property: MultilingualProductPropertyRestDto) => product_property.property_type_code === property_type_code) as MultilingualProductPropertyRestDto;
        },
        getCustomerProductOverview(state: any): PagedCustomerProductOverviewRestDto {
            return state.customer_product_overview;
        },
    }
}
