import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(this.class),
    type: "button",
    "data-bs-dismiss": "offcanvas",
    "aria-label": _ctx.$t('close')
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}