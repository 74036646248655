
import {defineComponent} from "vue";
import {arrayHasContent} from "@/helpers/functions/arrays";

export default defineComponent({
  name: "ProductCategoriesTreeItem",
  emits: ["selectCategory"],
  props: {
    categoriesTreeItem: {
      type: Object,
      required: false,
      default: function () {
        return {};
      }
    }
  },
  computed: {
    categoryExists(): boolean {
      return 'current_category' in this.categoriesTreeItem;
    },
    hasChildren(): boolean {
      return this.categoryExists && 'subcategories' in this.categoriesTreeItem && arrayHasContent(this.categoriesTreeItem.subcategories);
    },
    collapseId(): string {
      return 'collapse_' + this.categoriesTreeItem.current_category.code;
    },
  },
  methods: {
    selectCategory(category_code: string) {
      this.$emit('selectCategory', category_code);
    }
  }
});
