
import {defineComponent} from 'vue';

export default defineComponent({
  name: "InputRadio",
  emits: ['update:modelValue'],
  props: {
    value: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    errors: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true
    },
    rules: {
      type: String,
      required: false,
    },
  },
  computed: {
    model: {
      get(): string {
        return this.modelValue;
      },
      set(value: string) {
        this.$emit('update:modelValue', value)
      }
    }
  },
});
