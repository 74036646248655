import {RestService} from "@/services/rest/RestService";
import {ContentBlockInRowEndpointsApi} from "@/api/pharma-cms-content-page";
import {ContentVisibilityRuleForElementRestDto} from "@/api/pharma-cms-content-page/models";

export class ContentBlockInRowRestService extends RestService {

    private apiService: ContentBlockInRowEndpointsApi;

    protected constructor() {
        super();

        this.apiService = new ContentBlockInRowEndpointsApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cmsUrl);
    }

    public async getContentBlockInRowVisibilityRule(rowId: string, cbInRowId: string): Promise<ContentVisibilityRuleForElementRestDto> {
        const response = await this.apiService.getContentBlockInRowVisibilityRule(rowId, cbInRowId);

        return response.data;
    }

    public async updateContentBlockInRowVisibilityRule(visibilityRuleForElement: ContentVisibilityRuleForElementRestDto, rowId: string, cbInRowId: string): Promise<void> {
        await this.apiService.updateContentBlockInRowVisibilityRule(visibilityRuleForElement, rowId, cbInRowId);
    }
}
