import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e589029"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row mt-5" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "own-banners mb-5" }
const _hoisted_4 = { class: "d-flex justify-content-between align-items-end" }
const _hoisted_5 = { class: "own-banner-type" }
const _hoisted_6 = { class: "fs-6 fw-bold" }
const _hoisted_7 = { class: "d-flex align-items-stretch" }
const _hoisted_8 = { class: "own-banner-overview" }
const _hoisted_9 = {
  key: 0,
  class: "overlay position-absolute start-0 top-0 w-100 h-100 bg-white"
}
const _hoisted_10 = {
  key: 0,
  class: "content-provider-slots mb-4"
}
const _hoisted_11 = { class: "row bottom" }
const _hoisted_12 = { class: "col-12" }
const _hoisted_13 = { class: "d-inline-block pt-4 border-top border-2 border-light" }
const _hoisted_14 = {
  type: "submit",
  class: "btn btn-primary"
}
const _hoisted_15 = {
  key: 0,
  class: "spinner-border spinner-border-sm ms-2",
  role: "status",
  "aria-hidden": "true"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_AlertSuccess = _resolveComponent("AlertSuccess")!
  const _component_AlertError = _resolveComponent("AlertError")!
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_ButtonSubscribeToSlot = _resolveComponent("ButtonSubscribeToSlot")!
  const _component_ButtonType = _resolveComponent("ButtonType")!
  const _component_BannerLibrary = _resolveComponent("BannerLibrary")!
  const _component_BannerOverview = _resolveComponent("BannerOverview")!
  const _component_BannerSlotPreview = _resolveComponent("BannerSlotPreview")!
  const _component_input_select = _resolveComponent("input-select")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_dp_form = _resolveComponent("dp-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseTitle, {
      title: _ctx.$t('bannerSlot'),
      "help-text": _ctx.$t('descriptionBannerSlotDetailCustomer') + _ctx.$t('descriptionBannerSlotDetailCustomer2'),
      "back-route": this.returnRoute,
      mode: this.mode,
      "is-library": this.isLibrary
    }, null, 8, ["title", "help-text", "back-route", "mode", "is-library"]),
    _createVNode(_component_AlertSuccess, {
      "show-if": this.savedSuccessfully,
      class: "mt-3"
    }, null, 8, ["show-if"]),
    _createVNode(_component_AlertError, {
      exceptions: this.exceptions,
      class: "mt-3"
    }, null, 8, ["exceptions"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BaseSpinner, {
          spin: !this.isReady
        }, null, 8, ["spin"]),
        (this.isReady)
          ? (_openBlock(), _createBlock(_component_dp_form, {
              key: 0,
              as: "form",
              onSubmit: this.submitData,
              novalidate: ""
            }, {
              default: _withCtx(({ errors }) => [
                _createElementVNode("section", _hoisted_3, [
                  (!this.isLibrary)
                    ? (_openBlock(), _createBlock(_component_ButtonSubscribeToSlot, {
                        key: 0,
                        description: _ctx.$t('ownBannerSelection'),
                        "slot-id": null,
                        "current-subscription-id": this.currentSubscriptionId,
                        class: "mb-4",
                        onSet: this.setSubscribeToSlotId
                      }, null, 8, ["description", "current-subscription-id", "onSet"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("article", {
                    class: _normalizeClass(["option-own-banners position-relative", {'bordered border-1 border-light rounded p-3': !this.isLibrary}])
                  }, [
                    _createElementVNode("header", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t('bannerType')), 1),
                        _createElementVNode("div", _hoisted_7, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.AVAILABLE_CONTENT_SIZE_CODES, (AVAILABLE_CONTENT_SIZE_CODE) => {
                            return (_openBlock(), _createBlock(_component_ButtonType, {
                              key: AVAILABLE_CONTENT_SIZE_CODE,
                              "banner-slot-type": this.bannerSlotType,
                              "current-max-content-size-code": this.bannerSlot.max_content_size_code,
                              "displayed-max-content-size-code": AVAILABLE_CONTENT_SIZE_CODE,
                              class: "py-1 me-3"
                            }, null, 8, ["banner-slot-type", "current-max-content-size-code", "displayed-max-content-size-code"]))
                          }), 128))
                        ])
                      ]),
                      _createVNode(_component_BannerLibrary, {
                        "banner-slot-id": this.cbId,
                        "banner-lib-type": this.bannerLibType,
                        "banner-libraries": this.availableBannersForSlotLibraries,
                        "cb-type": this.bannerInSlotType,
                        onCopied: this.afterAddBanner,
                        onExceptions: this.setExceptions
                      }, null, 8, ["banner-slot-id", "banner-lib-type", "banner-libraries", "cb-type", "onCopied", "onExceptions"])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      (_openBlock(), _createBlock(_component_BannerOverview, {
                        key: _ctx.bannerOverviewKey,
                        "is-banner-in-slot": "",
                        "is-library": this.isLibrary,
                        "banner-slot-position-code": this.bannerSlot.position_code,
                        onExceptions: this.setExceptions
                      }, null, 8, ["is-library", "banner-slot-position-code", "onExceptions"]))
                    ]),
                    (!this.isCurrentSubscription(null))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9))
                      : _createCommentVNode("", true)
                  ], 2)
                ]),
                (!this.isLibrary)
                  ? (_openBlock(), _createElementBlock("section", _hoisted_10, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.bannerSlotsContent, (bannerSlotContent) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: bannerSlotContent.id,
                          class: _normalizeClass(["banner-slot-library d-flex align-items-center mb-4", 'option-' + bannerSlotContent.cb.position_code.replaceAll('_','-').toLowerCase()])
                        }, [
                          _createVNode(_component_ButtonSubscribeToSlot, {
                            description: this.getBannerSlotLibraryName(bannerSlotContent.cb.position_code),
                            "slot-id": bannerSlotContent.id,
                            "current-subscription-id": _ctx.currentSubscriptionId,
                            onSet: this.setSubscribeToSlotId
                          }, null, 8, ["description", "slot-id", "current-subscription-id", "onSet"]),
                          _createVNode(_component_BannerSlotPreview, {
                            "position-code": bannerSlotContent.cb.position_code,
                            "is-current-subscription": this.isCurrentSubscription(bannerSlotContent.id),
                            onExceptions: this.setExceptions
                          }, null, 8, ["position-code", "is-current-subscription", "onExceptions"])
                        ], 2))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("section", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(_component_input_select, {
                        name: "cbStatus",
                        selectText: _ctx.$t('selectText'),
                        modelValue: this.bannerSlot.status_code,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.bannerSlot.status_code) = $event)),
                        options: this.statusOptions,
                        errors: errors
                      }, null, 8, ["selectText", "modelValue", "options", "errors"]),
                      _createElementVNode("button", _hoisted_14, [
                        _createTextVNode(_toDisplayString(_ctx.$t('save')) + " ", 1),
                        (this.isSubmitting)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_15))
                          : _createCommentVNode("", true)
                      ]),
                      _createVNode(_component_router_link, {
                        class: "ms-3 btn btn-outline-primary",
                        to: this.returnRoute
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["onSubmit"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}