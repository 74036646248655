export class ContentPageRowClipboardModel {
    rowId: string | null;
    fromPageId: string | null;

    constructor(rowId: string | null, fromPageId: string | null) {
        this.rowId = rowId;
        this.fromPageId = fromPageId;
    }

    public static createWithDefaults(): ContentPageRowClipboardModel {
        return new this(null, null);
    }
}
