import {CustomerCriteriaRestDto} from "@/api/pharma-customer/models";

export class CustomerCriteriaRestDtoModel implements CustomerCriteriaRestDto {
    search_name: string | null;
    filter_account_number?: number | null;
    filter_customer_number?: number | null;
    filter_license_product_code?: string | null;
    filter_product_type_code?: string | null;

    constructor(search_name: string | null) {
        this.search_name = search_name;
    }

    public static createWithDefaults(): CustomerCriteriaRestDtoModel {
        return new this(null);
    }
}
