import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26966b84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "selected-brands d-flex flex-wrap" }
const _hoisted_2 = {
  key: 0,
  class: "fst-italic m-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectBrandsSelectedItem = _resolveComponent("SelectBrandsSelectedItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!this.hasSelectedBrandCodes)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('noBrandsAdded')), 1))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(this.selectedBrandCodes, (selectedBrandCode) => {
          return (_openBlock(), _createBlock(_component_SelectBrandsSelectedItem, {
            key: selectedBrandCode,
            "selected-brand-code": selectedBrandCode,
            "catalog-customer-code": this.catalogCustomerCode,
            "pim-labo-brands": this.pimLaboBrands,
            onRemoveBrand: this.removeBrand
          }, null, 8, ["selected-brand-code", "catalog-customer-code", "pim-labo-brands", "onRemoveBrand"]))
        }), 128))
  ]))
}