
import {defineComponent} from "vue";
import BaseContainer from "@/components/UI/Bootstrap/BaseContainer.vue";
import BannerDetail from "@/components/layouts/banners/BannerDetail.vue";
import CbFormImage from "@/components/layouts/content-blocks/forms/CbFormImage.vue";
import CbFormTextWithImage from "@/components/layouts/content-blocks/forms/CbFormTextWithImage.vue";
import CbFormTextWithTitle from "@/components/layouts/content-blocks/forms/CbFormTextWithTitle.vue";
import {LibraryOverviewRestDto} from "@/api/pharma-cms-content-block/models";
import {mapGetters} from "vuex";
import {ContentBlockTypesEnum} from "@/helpers/functions/enum";
import {DpException} from "@/exception/DpException";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-page/models";
import {LibraryStructureTypeEnum} from "@/models/enum/LibraryStructureTypeEnum";

export default defineComponent({
  name: "LibraryDetail",
  components: {BannerDetail, CbFormImage, CbFormTextWithImage, CbFormTextWithTitle, BaseContainer},
  props: ['mode'],
  data() {
    return {
      cbStructure: this.$route.params.cb_structure as string,
      cbType: this.$route.params.cb_type as string,
      docType: "PAGE_IMG" as string,
      libType: this.$route.params.lib_type as string,
      id: this.$route.params.id as string,
    };
  },
  computed: {
    ...mapGetters('cms_content_block', ["getContentBlockLibsForType"]),
    ...mapGetters('cms_content_page', ["getPageAbstractLibraries"]),
    currentComponent(): string {
      switch (this.cbStructure) {
        case ContentBlockTypesEnum.BANNER:
          return 'banner-detail';
        case ContentBlockTypesEnum.IMAGE:
          return 'cb-form-image';
        case ContentBlockTypesEnum.LOGO:
          return 'cb-form-image';
        case ContentBlockTypesEnum.TEXT_WITH_IMAGE:
          return 'cb-form-text-with-image';
        case ContentBlockTypesEnum.TEXT_WITH_TITLE:
          return 'cb-form-text-with-title';
        default:
          throw [new DpException('Unrecognized cb type ' + this.cbStructure)];
      }
    },
    currentProperties(): object {
      switch (this.cbStructure) {
        case ContentBlockTypesEnum.BANNER:
          return {};
        case ContentBlockTypesEnum.LOGO:
        case ContentBlockTypesEnum.IMAGE:
          return {
            cbType: this.cbType,
            docType: this.docType
          };
        case ContentBlockTypesEnum.TEXT_WITH_IMAGE:
          return {
            cbType: this.cbType,
            docType: this.docType,
            isPublishable: true
          };
        case ContentBlockTypesEnum.TEXT_WITH_TITLE:
          return {
            cbType: this.cbType,
            isPublishable: true
          };
        default:
          throw [new DpException('Unrecognized cb type.')];
      }
    },
    title(): string | undefined {
      let libName;
      let libStructureName;

      if (this.cbType === 'LIB_EXT_ARTICLE_ABSTRACT') {
        libName = this.getPageAbstractLibraries.find((lib: CodeDescriptionRestDto) => lib.code === this.libType)?.description;
      } else {
        libName = this.getContentBlockLibsForType(this.cbStructure).find((cbLib: LibraryOverviewRestDto) => cbLib.library?.code === this.libType)?.library?.description;
      }

      switch (this.cbStructure) {
        case LibraryStructureTypeEnum.ARTICLE_ABSTRACT:
          libStructureName = this.$t('articleAbstracts');
          break;
        case LibraryStructureTypeEnum.CONTENT_PAGE:
          libStructureName = this.$t('contentPages');
          break;
        case ContentBlockTypesEnum.BANNER:
          libStructureName = this.$t('banners');
          break;
        case ContentBlockTypesEnum.IMAGE:
          libStructureName = this.$t('images');
          break;
        case ContentBlockTypesEnum.TEXT_WITH_IMAGE:
          libStructureName = this.$t('textsWithImage');
          break;
        case ContentBlockTypesEnum.TEXT_WITH_TITLE:
          libStructureName = this.$t('textsWithTitle');
          break;
      }

      return `${libStructureName} / ${libName}`;
    },
  }
});
