/*
* Checks if an array contains content
* Receives an array of any type
* Returns a boolean
*/
function arrayHasContent(array: any[]): boolean {
    return (Array.isArray(array) && array.length > 0);
}


export {arrayHasContent};