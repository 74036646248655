import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gx-5 mb-3" }
const _hoisted_2 = { class: "col-left col-auto" }
const _hoisted_3 = { class: "col-right col col-xxl-6" }
const _hoisted_4 = { class: "row row-texts" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "mb-3" }
const _hoisted_7 = {
  class: "btn btn-primary",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertError2 = _resolveComponent("AlertError2")!
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_Subtitle = _resolveComponent("Subtitle")!
  const _component_InputSelect = _resolveComponent("InputSelect")!
  const _component_InputCheckboxBoolean = _resolveComponent("InputCheckboxBoolean")!
  const _component_MultilingualSwitcher = _resolveComponent("MultilingualSwitcher")!
  const _component_I18nInputTextEditor = _resolveComponent("I18nInputTextEditor")!
  const _component_dp_form = _resolveComponent("dp-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AlertError2, {
      error: this.headerFooterContentUI.getError()
    }, null, 8, ["error"]),
    _createVNode(_component_BaseSpinner, {
      spin: this.headerFooterContentUI.isNotReady()
    }, null, 8, ["spin"]),
    (this.headerFooterContentUI.isReady())
      ? (_openBlock(), _createBlock(_component_dp_form, {
          key: 0,
          onSubmit: this.submitData,
          novalidate: ""
        }, {
          default: _withCtx(({errors}) => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_Subtitle, {
                  title: _ctx.$t('settings'),
                  mb: "3"
                }, null, 8, ["title"]),
                _createVNode(_component_InputSelect, {
                  modelValue: this.footerContent.layout_code,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.footerContent.layout_code) = $event)),
                  options: this.layoutOptions,
                  name: "layoutCode",
                  label: _ctx.$t('layout'),
                  selectText: _ctx.$t('selectText'),
                  rules: "required",
                  errors: errors
                }, null, 8, ["modelValue", "options", "label", "selectText", "errors"]),
                _createVNode(_component_InputSelect, {
                  modelValue: this.footerContent.pharmacy_info_layout_code,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.footerContent.pharmacy_info_layout_code) = $event)),
                  options: this.pharmacyInfoLayoutOptions,
                  name: "pharmacyInfoLayoutCode",
                  label: `${_ctx.$t('layout')} (${_ctx.$t('pharmacyData').toLowerCase()})`,
                  selectText: _ctx.$t('selectText'),
                  errors: errors
                }, null, 8, ["modelValue", "options", "label", "selectText", "errors"]),
                _createVNode(_component_InputCheckboxBoolean, {
                  modelValue: this.footerContent.is_show_public_address,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.footerContent.is_show_public_address) = $event)),
                  name: "isShowPublicAddress",
                  label: `${_ctx.$t('show')} ${_ctx.$t('publicAddress').toLowerCase()}`,
                  errors: errors
                }, null, 8, ["modelValue", "label", "errors"]),
                _createVNode(_component_InputCheckboxBoolean, {
                  modelValue: this.footerContent.is_show_public_email,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((this.footerContent.is_show_public_email) = $event)),
                  name: "isShowPublicEmail",
                  label: `${_ctx.$t('show')} ${_ctx.$t('publicEmail').toLowerCase()}`,
                  errors: errors
                }, null, 8, ["modelValue", "label", "errors"]),
                _createVNode(_component_InputCheckboxBoolean, {
                  modelValue: this.footerContent.is_show_public_phone,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((this.footerContent.is_show_public_phone) = $event)),
                  name: "isShowPublicPhoneNumber",
                  label: `${_ctx.$t('show')} ${_ctx.$t('publicPhoneNumber').toLowerCase()}`,
                  errors: errors
                }, null, 8, ["modelValue", "label", "errors"]),
                _createVNode(_component_InputCheckboxBoolean, {
                  modelValue: this.footerContent.is_show_social_media,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((this.footerContent.is_show_social_media) = $event)),
                  name: "isShowSocialMedia",
                  label: `${_ctx.$t('show')} ${_ctx.$t('socialMedia').toLowerCase()}`,
                  errors: errors
                }, null, 8, ["modelValue", "label", "errors"])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_Subtitle, {
                  title: _ctx.$t('content'),
                  mb: "3"
                }, null, 8, ["title"]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_MultilingualSwitcher, {
                    "active-language": this.activeLanguage,
                    onUpdateActiveLanguage: _cache[6] || (_cache[6] = newValue => this.activeLanguage = newValue),
                    errors: errors
                  }, null, 8, ["active-language", "errors"]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_I18nInputTextEditor, {
                        data: this.footerContent.text_top,
                        "active-language": this.activeLanguage,
                        name: "textTop",
                        label: `${_ctx.$t('text')} ${_ctx.$t('top').toLowerCase()}`,
                        errors: errors
                      }, null, 8, ["data", "active-language", "label", "errors"])
                    ]),
                    _createElementVNode("div", null, [
                      _createVNode(_component_I18nInputTextEditor, {
                        data: this.footerContent.text_bottom,
                        "active-language": this.activeLanguage,
                        name: "textBottom",
                        label: `${_ctx.$t('text')} ${_ctx.$t('bottom').toLowerCase()}`,
                        errors: errors
                      }, null, 8, ["data", "active-language", "label", "errors"])
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("button", _hoisted_7, _toDisplayString(_ctx.$t('save')), 1)
          ]),
          _: 1
        }, 8, ["onSubmit"]))
      : _createCommentVNode("", true)
  ], 64))
}