import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-8 col-xl-7 col-xxl-6" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_alert_error = _resolveComponent("alert-error")!
  const _component_input_password = _resolveComponent("input-password")!
  const _component_dp_form = _resolveComponent("dp-form")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BaseTitle, {
            title: _ctx.$t('changePassword'),
            mb: 4
          }, null, 8, ["title"]),
          _createVNode(_component_alert_error, {
            exceptions: _ctx.exceptions,
            class: "mb-3"
          }, null, 8, ["exceptions"]),
          _createVNode(_component_dp_form, {
            ref: "newPasswordForm",
            as: "form",
            onSubmit: _ctx.submitData,
            novalidate: ""
          }, {
            default: _withCtx(({ errors, isSubmitting }) => [
              _createVNode(_component_input_password, {
                modelValue: this.passwordModel.password,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.passwordModel.password) = $event)),
                label: _ctx.$t('newPassword'),
                placeholder: "password",
                name: "password",
                rules: "min:8|max:150|pwd",
                errors: errors,
                help: _ctx.$t('newPasswordRequirements')
              }, null, 8, ["modelValue", "label", "errors", "help"]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("button", {
                  type: "submit",
                  class: "btn btn-primary",
                  disabled: isSubmitting
                }, _toDisplayString(_ctx.$t('save')), 9, _hoisted_4)
              ])
            ]),
            _: 1
          }, 8, ["onSubmit"])
        ])
      ])
    ]),
    _: 1
  }))
}