/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Content Page API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { FullContentPageRestDto } from '../models';
import { PageLibraryOverviewRestDto } from '../models';
/**
 * ContentBlockLibraryEndpointsApi - axios parameter creator
 * @export
 */
export const ContentBlockLibraryEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.CPL.01 Retrieve library content pages in the specified library.
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_status_code] Filter the results based on a match on status_code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLibraryContentBlocksContentPage: async (library_code: string, x_dp_customer_code?: string|null, filter_status_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'library_code' is not null or undefined
            if (library_code === null || library_code === undefined) {
                throw new RequiredError('library_code','Required parameter library_code was null or undefined when calling getLibraryContentBlocksContentPage.');
            }
            const localVarPath = `/content_page/v1/library/{library_code}/content_page`
                .replace(`{${"library_code"}}`, encodeURIComponent(String(library_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_status_code || typeof(filter_status_code) === "boolean") {
                localVarQueryParameter['filter_status_code'] = filter_status_code;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPL.02 Retrieve libraries for content provider.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageLibraryOverviewRestDto: async (x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/content_page/v1/library`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentBlockLibraryEndpointsApi - functional programming interface
 * @export
 */
export const ContentBlockLibraryEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.CPL.01 Retrieve library content pages in the specified library.
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_status_code] Filter the results based on a match on status_code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLibraryContentBlocksContentPage(_axios: AxiosInstance, library_code: string, x_dp_customer_code?: string|null, filter_status_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FullContentPageRestDto>>> {
            const localVarAxiosArgs = await ContentBlockLibraryEndpointsApiAxiosParamCreator(configuration).getLibraryContentBlocksContentPage(library_code, x_dp_customer_code, filter_status_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPL.02 Retrieve libraries for content provider.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageLibraryOverviewRestDto(_axios: AxiosInstance, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PageLibraryOverviewRestDto>>> {
            const localVarAxiosArgs = await ContentBlockLibraryEndpointsApiAxiosParamCreator(configuration).pageLibraryOverviewRestDto(x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ContentBlockLibraryEndpointsApi - object-oriented interface
 * @export
 * @class ContentBlockLibraryEndpointsApi
 * @extends {BaseAPI}
 */
export class ContentBlockLibraryEndpointsApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.CMS.CPL.01 Retrieve library content pages in the specified library.
     * @param {string} library_code The code that identifier the content block library.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [filter_status_code] Filter the results based on a match on status_code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockLibraryEndpointsApi
     */
     public getLibraryContentBlocksContentPage(library_code: string, x_dp_customer_code?: string|null, filter_status_code?: string|null, options?: any) {
        return ContentBlockLibraryEndpointsApiFp(this.configuration).getLibraryContentBlocksContentPage(this.axios, library_code, x_dp_customer_code, filter_status_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CPL.02 Retrieve libraries for content provider.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockLibraryEndpointsApi
     */
     public pageLibraryOverviewRestDto(x_dp_customer_code?: string|null, options?: any) {
        return ContentBlockLibraryEndpointsApiFp(this.configuration).pageLibraryOverviewRestDto(this.axios, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
