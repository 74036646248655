import {
    cloneSymbolRestDtoMetaData,
    ContentBlockMetadataRestDto,
    SymbolContentRestDto,
} from "@/api/pharma-cms-content-block/models";
import {StatusEnum} from "@/api/enums/status-enum";
import {SymbolRestDtoModel} from "@/models/api/pharma-cms-content-block/SymbolRestDtoModel";
import {NormalContentBlockModel} from "@/models/api/pharma-cms-content-block/NormalContentBlockModel";

export class SymbolContentRestDtoModel extends NormalContentBlockModel implements SymbolContentRestDto {
    status_code: StatusEnum;
    sort_weight?: number;
    symbol: SymbolRestDtoModel;
    cb_metadata?: ContentBlockMetadataRestDto;

    constructor(symbol: SymbolRestDtoModel, status_code: StatusEnum) {
        super();
        this.symbol = symbol;
        this.status_code = status_code;
    }

    public static createWithDefaults(): SymbolContentRestDtoModel {
        return new this(
            SymbolRestDtoModel.createWithDefaults(),
            StatusEnum.PUBLISHED
        );
    }

    public static cloneFrom(source: SymbolContentRestDtoModel): SymbolContentRestDtoModel {
        const modal = new this(
            source.symbol,
            source.status_code
        );
        modal.cb_metadata = source.cb_metadata;
        modal.sort_weight = source.sort_weight;

        return modal;
    }

    public cloneMetadataFrom(source: SymbolContentRestDtoModel): void {
        cloneSymbolRestDtoMetaData(this.symbol, source.symbol);
    }
}
