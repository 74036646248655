/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Customer-Product-Catalog
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ExternalSystemSyncTaskResultsRestDto } from '../models';
/**
 * ExternalSystemSyncAPIApi - axios parameter creator
 * @export
 */
export const ExternalSystemSyncAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for fetching the last 10 external system sync tasks
         * @summary REST.API.CPC.PSS.01 Get history of external system sync tasks.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_ext_system_type_code] Filter the results based on the type of external system.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalSystemSyncTaskHistory: async (x_dp_customer_code?: string|null, filter_ext_system_type_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer_mgmt/v1/external_system_sync/task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_ext_system_type_code || typeof(filter_ext_system_type_code) === "boolean") {
                localVarQueryParameter['filter_ext_system_type_code'] = filter_ext_system_type_code;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExternalSystemSyncAPIApi - functional programming interface
 * @export
 */
export const ExternalSystemSyncAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for fetching the last 10 external system sync tasks
         * @summary REST.API.CPC.PSS.01 Get history of external system sync tasks.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_ext_system_type_code] Filter the results based on the type of external system.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExternalSystemSyncTaskHistory(_axios: AxiosInstance, x_dp_customer_code?: string|null, filter_ext_system_type_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalSystemSyncTaskResultsRestDto>> {
            const localVarAxiosArgs = await ExternalSystemSyncAPIApiAxiosParamCreator(configuration).getExternalSystemSyncTaskHistory(x_dp_customer_code, filter_ext_system_type_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ExternalSystemSyncAPIApi - object-oriented interface
 * @export
 * @class ExternalSystemSyncAPIApi
 * @extends {BaseAPI}
 */
export class ExternalSystemSyncAPIApi extends BaseAPI {
    /**
     * API endpoint responsible for fetching the last 10 external system sync tasks
     * @summary REST.API.CPC.PSS.01 Get history of external system sync tasks.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [filter_ext_system_type_code] Filter the results based on the type of external system.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalSystemSyncAPIApi
     */
     public getExternalSystemSyncTaskHistory(x_dp_customer_code?: string|null, filter_ext_system_type_code?: string|null, options?: any) {
        return ExternalSystemSyncAPIApiFp(this.configuration).getExternalSystemSyncTaskHistory(this.axios, x_dp_customer_code, filter_ext_system_type_code, options).then((request) => request(this.axios, this.basePath));
    }

}
