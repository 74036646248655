/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum DiscountSchemeTypeEnum {
    MANUAL_INPUT = 'MANUAL_INPUT',
    ADOPT_FROM_PHARMACY_SOFTWARE = 'ADOPT_FROM_PHARMACY_SOFTWARE',
    SHARED_DISCOUNT_SCHEME = 'SHARED_DISCOUNT_SCHEME',
}