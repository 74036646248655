<template>
  <div class="callback">Callback: loading...</div>
</template>

<script>
export default {
  methods: {
    handleAuthenticated() {
      // The results are sent back via URL params, eg:
      // http://localhost:8080/oauth2/callback#id_token=....&access_token=...&expires_in=3600&token_type=Bearer
      // The hash params should be parsed manually, it is not supported via this.$route.query...
      const parsedParams = {};
      this.$route.hash.split('&')
        .map(part => part.replace(/^#/, ''))
        .forEach(param => {
          const parts = param.split('=');
          parsedParams[parts[0]] = parts[1];
        });
      this.$store.dispatch('handleAuthCallback', parsedParams);
      this.$router.push('/home')
    },
  },
  mounted() {
      this.handleAuthenticated()
  },
};
</script>


