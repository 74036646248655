import {CmsFooterContent2RestDto} from "@/api/pharma-cms-footer/models";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";

export class CmsFooterContent2RestDtoModel implements CmsFooterContent2RestDto {
    main_pharmacy_text?: MultilingualRestDtoModel | null;
    extra_text?: MultilingualRestDtoModel | null;

    constructor(main_pharmacy_text: MultilingualRestDtoModel | null, extra_text: MultilingualRestDtoModel | null) {
        this.main_pharmacy_text = main_pharmacy_text;
        this.extra_text = extra_text;
    }

    public static createWithDefaults(): CmsFooterContent2RestDtoModel {
        return new this(
            MultilingualRestDtoModel.createWithDefaults(),
            MultilingualRestDtoModel.createWithDefaults()
        );
    }
}
