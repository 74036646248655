import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "discount-filter-cnk-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_cnk_list = _resolveComponent("input-cnk-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.selectionType.toUpperCase() === 'INCLUDE')
      ? (_openBlock(), _createBlock(_component_input_cnk_list, {
          key: 0,
          modelValue: _ctx.filterCondition.cnk_codes_included,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterCondition.cnk_codes_included) = $event)),
          mode: "bare",
          height: 150,
          label: _ctx.$t('cnkCodesIncluded'),
          name: this.fieldName + '_include',
          errors: _ctx.errors
        }, null, 8, ["modelValue", "label", "name", "errors"]))
      : (_ctx.selectionType.toUpperCase() === 'EXCLUDE')
        ? (_openBlock(), _createBlock(_component_input_cnk_list, {
            key: 1,
            modelValue: _ctx.filterCondition.cnk_codes_excluded,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filterCondition.cnk_codes_excluded) = $event)),
            mode: "bare",
            height: 150,
            label: _ctx.$t('cnkCodesExcluded'),
            name: this.fieldName + '_exclude',
            errors: _ctx.errors
          }, null, 8, ["modelValue", "label", "name", "errors"]))
        : _createCommentVNode("", true)
  ]))
}