import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59efe886"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col mb-4" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-4" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-4" }
const _hoisted_7 = { class: "col-4" }
const _hoisted_8 = { class: "checkbox-open-closed col-2 mb-0 d-flex flex-row-reverse justify-content-end align-items-center" }
const _hoisted_9 = ["for"]
const _hoisted_10 = ["onUpdate:modelValue", "onChange", "name", "id"]
const _hoisted_11 = { class: "label-open-closed col-1" }
const _hoisted_12 = { class: "hours col-12 col-xl-9 col-xxl-7" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-4 from-till d-flex justify-content-center" }
const _hoisted_15 = { class: "col-4 from-till d-flex justify-content-center" }
const _hoisted_16 = { class: "col-4 from-till d-flex justify-content-center" }
const _hoisted_17 = { class: "d-flex flex-column justify-content-start" }
const _hoisted_18 = { class: "col d-flex" }
const _hoisted_19 = { class: "d-flex align-items-center mt-3 pt-4 border-top border-2 border-light" }
const _hoisted_20 = {
  type: "submit",
  class: "btn btn-primary"
}
const _hoisted_21 = { class: "text-muted ms-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_alert_error = _resolveComponent("alert-error")!
  const _component_MultilingualSwitcher = _resolveComponent("MultilingualSwitcher")!
  const _component_I18nInputText = _resolveComponent("I18nInputText")!
  const _component_input_date = _resolveComponent("input-date")!
  const _component_input_time = _resolveComponent("input-time")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_dp_form = _resolveComponent("dp-form")!
  const _component_base_spinner = _resolveComponent("base-spinner")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_BaseTitle, {
        title: _ctx.mode === 'new' ? _ctx.$t('newExceptionalOpeningHours') : _ctx.$t('editExceptionalOpeningHours'),
        "help-text": _ctx.$t('openingHoursPeriodDescription'),
        "back-route": {name: 'exceptional-hours'}
      }, null, 8, ["title", "help-text"]),
      _createVNode(_component_alert_error, {
        exceptions: _ctx.exceptions,
        class: "my-3"
      }, null, 8, ["exceptions"]),
      (_ctx.isReady)
        ? (_openBlock(), _createBlock(_component_dp_form, {
            key: 0,
            as: "form",
            onSubmit: _ctx.submitData,
            novalidate: ""
          }, {
            default: _withCtx(({ errors }) => [
              _createVNode(_component_MultilingualSwitcher, {
                errors: errors,
                "active-language": this.activeLanguage,
                onUpdateActiveLanguage: _cache[0] || (_cache[0] = newValue => this.activeLanguage = newValue)
              }, null, 8, ["errors", "active-language"]),
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_I18nInputText, {
                        data: _ctx.openingHoursPeriod.name,
                        "active-language": this.activeLanguage,
                        label: _ctx.$t('name'),
                        placeholder: _ctx.$t('name'),
                        name: "name",
                        rules: "required|max:100",
                        errors: errors
                      }, null, 8, ["data", "active-language", "label", "placeholder", "errors"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_input_date, {
                        label: _ctx.$t('begin'),
                        placeholder: _ctx.$t('inputPlaceholderDDMMYYYY'),
                        name: "begin",
                        modelValue: _ctx.openingHoursPeriod.begin,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.openingHoursPeriod.begin) = $event)),
                        rules: "required|check_date:@begin,@end",
                        onBlur: _ctx.copyToEndDate,
                        errors: errors
                      }, null, 8, ["label", "placeholder", "modelValue", "onBlur", "errors"])
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_input_date, {
                        label: _ctx.$t('end'),
                        placeholder: _ctx.$t('inputPlaceholderDDMMYYYY'),
                        name: "end",
                        modelValue: _ctx.openingHoursPeriod.end,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.openingHoursPeriod.end) = $event)),
                        rules: "required|check_date:@begin,@end",
                        errors: errors
                      }, null, 8, ["label", "placeholder", "modelValue", "errors"])
                    ])
                  ])
                ])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.openingHoursPeriod.opening_hours, (openingHoursDay, dayName) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: dayName,
                  class: "opening-hours-day row d-flex align-items-center"
                }, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("label", {
                      for: dayName + 'OpenClosed',
                      class: "fw-0 form-label px-2 mb-0"
                    }, _toDisplayString(_ctx.$t(dayName)), 9, _hoisted_9),
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": ($event: any) => ((openingHoursDay.open) = $event),
                      onChange: ($event: any) => (_ctx.toggleDayTimeValues(dayName)),
                      type: "checkbox",
                      name: dayName + 'OpenClosed',
                      id: dayName + 'OpenClosed'
                    }, null, 40, _hoisted_10), [
                      [_vModelCheckbox, openingHoursDay.open]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("p", {
                      class: _normalizeClass([openingHoursDay.open ? 'text-success':'text-danger','m-0 fw-bold'])
                    }, _toDisplayString(openingHoursDay.open ? _ctx.$t('open') : _ctx.$t('closed')), 3)
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createVNode(_component_input_time, {
                          modelValue: openingHoursDay.hours1_from,
                          "onUpdate:modelValue": ($event: any) => ((openingHoursDay.hours1_from) = $event),
                          label: _ctx.$t('from'),
                          name: dayName + 'hours1From',
                          rules: _ctx.inputTimeRulesOpenClosed(openingHoursDay.open),
                          errors: errors
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "name", "rules", "errors"]),
                        _createVNode(_component_input_time, {
                          modelValue: openingHoursDay.hours1_till,
                          "onUpdate:modelValue": ($event: any) => ((openingHoursDay.hours1_till) = $event),
                          label: _ctx.$t('till'),
                          name: dayName + 'hours1Till',
                          rules: _ctx.inputTimeRulesOpenClosed(openingHoursDay.open),
                          errors: errors
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "name", "rules", "errors"])
                      ]),
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_input_time, {
                          modelValue: openingHoursDay.hours2_from,
                          "onUpdate:modelValue": ($event: any) => ((openingHoursDay.hours2_from) = $event),
                          label: _ctx.$t('from'),
                          name: dayName + 'hours2From',
                          rules: _ctx.inputTimeRulesFromTo(openingHoursDay.hours2_from),
                          errors: errors
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "name", "rules", "errors"]),
                        _createVNode(_component_input_time, {
                          modelValue: openingHoursDay.hours2_till,
                          "onUpdate:modelValue": ($event: any) => ((openingHoursDay.hours2_till) = $event),
                          label: _ctx.$t('till'),
                          name: dayName + 'hours2Till',
                          rules: _ctx.inputTimeRulesFromTo(openingHoursDay.hours2_from),
                          errors: errors
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "name", "rules", "errors"])
                      ]),
                      _createElementVNode("div", _hoisted_16, [
                        _createVNode(_component_input_time, {
                          modelValue: openingHoursDay.hours3_from,
                          "onUpdate:modelValue": ($event: any) => ((openingHoursDay.hours3_from) = $event),
                          label: _ctx.$t('from'),
                          name: dayName + 'hours3From',
                          rules: _ctx.inputTimeRulesFromTo(openingHoursDay.hours3_from),
                          errors: errors
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "name", "rules", "errors"]),
                        _createVNode(_component_input_time, {
                          modelValue: openingHoursDay.hours3_till,
                          "onUpdate:modelValue": ($event: any) => ((openingHoursDay.hours3_till) = $event),
                          label: _ctx.$t('till'),
                          name: dayName + 'hours3Till',
                          rules: _ctx.inputTimeRulesFromTo(openingHoursDay.hours3_from),
                          errors: errors
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "name", "rules", "errors"])
                      ])
                    ])
                  ])
                ]))
              }), 128)),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("button", {
                    onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.reloadContent && _ctx.reloadContent(...args)), ["prevent"])),
                    class: "btn btn-secondary me-3"
                  }, _toDisplayString(_ctx.$t('contentReload')), 1)
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("button", _hoisted_20, _toDisplayString(_ctx.$t('save')), 1),
                  _createVNode(_component_router_link, {
                    class: "ms-3 btn btn-outline-primary",
                    to: {name: 'exceptional-hours'}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t('cachingDelayMinutes', [5])), 1)
                ])
              ])
            ]),
            _: 1
          }, 8, ["onSubmit"]))
        : (_openBlock(), _createBlock(_component_base_spinner, {
            key: 1,
            "spinner-class": "my-5"
          }))
    ]),
    _: 1
  }))
}