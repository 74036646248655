import {RestService} from "@/services/rest/RestService";
import {DocumentAPIApi} from "@/api/pharma-cms-document";

export class CmsDocumentRestService extends RestService {

    private apiService: DocumentAPIApi;

    protected constructor() {
        super();

        this.apiService = new DocumentAPIApi(this.getDpAxiosInstance(), undefined, this.getConfig().cmsUrl);
    }

    public async findPreviewUrl(documentKey: string): Promise<string> {
        const response = await this.apiService.documentV1DocumentDocumentKeyPublicUrlGet(documentKey);

        return response.data.url;
    }

    public async uploadFile(typeCode: String, file: File): Promise<string|null> {
        const url = this.getConfig().fileUploadUrl.replace('{type_code}', typeCode);
        const formData = new FormData();
        formData.append('file', file);
        const formConfig = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        const response = await this.getDpAxiosInstance().post(url, formData, formConfig);

        return response?.data?.document_key ?? null;
    }
}
