
import {defineComponent, PropType} from 'vue'
import SelectTrademarksSelected from "@/components/layouts/catalog/SelectTrademarksSelected.vue";
import {mapActions, mapGetters} from "vuex";
import {Exception} from "@/api/interfaces";
import {stringHasContent} from "@/helpers/functions/string";
import {CodeDescriptionRestDto} from "@/api/pharma-cpc-product/models";
import {arrayHasContent} from "@/helpers/functions/arrays";
import {Config} from "@/models/facade/Config";
import {PimProductBrandRestService} from "@/services/rest/pim-pp2/PimProductBrandRestService";

export default defineComponent({
  name: "SelectTrademarks",
  components: {SelectTrademarksSelected},
  emits: ["addTrademark", "removeTrademark"],
  props: {
    selectedTrademarkCodes: {
      type: Array as PropType<string[]>,
      required: true
    },
    btnClass: {
      type: String,
      required: false,
      default: 'btn btn-icon btn-primary border-0'
    },
    catalogCustomerCode: {
      type: String,
      required: false,
    },
    isPimTrademarks: {
      type: Boolean,
      required: false,
      default: false
    },
    isPimProducts: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      exceptions: [] as Exception[],
      isReadySearch: true as boolean,

      searchQuery: '' as string,
      pimTrademarks: [] as CodeDescriptionRestDto[],
    }
  },
  mounted() {
    this.clearSearch();
  },
  computed: {
    ...mapGetters('cpc_product', ['getProductTrademarks']),
    searchedTrademarks(): CodeDescriptionRestDto[] {
      if (arrayHasContent(this.selectedTrademarkCodes)) {
        if (this.isPimTrademarks) {
          return this.pimTrademarks.filter((trademark: CodeDescriptionRestDto) => {
            return !trademark.code.includes(this.selectedTrademarkCodes);
          });
        } else {
          return this.getProductTrademarks.filter((trademark: CodeDescriptionRestDto) => {
            return !trademark.code.includes(this.selectedTrademarkCodes);
          });
        }
      }

      if (this.isPimTrademarks) {
        return this.pimTrademarks;
      }
      return this.getProductTrademarks;
    },
    hasSearchedTrademarks(): boolean {
      return arrayHasContent(this.searchedTrademarks);
    },
    hasSearchQuery(): boolean {
      return stringHasContent(this.searchQuery);
    },
  },
  methods: {
    ...mapActions('cpc_product', ['clearProductTrademarks', 'searchProductTrademarks']),
    addTrademark(trademark_code: string): void {
      this.$emit('addTrademark', trademark_code);

      this.clearSearch();
    },
    removeTrademark(trademark_code: string): void {
      this.$emit('removeTrademark', trademark_code);
    },
    submitSearch(): void {
      if (this.searchQuery.length === 0) {
        this.clearSearch();
      } else if (this.searchQuery.length >= 2) {
        this.searchTrademarks();
      }
    },
    clearSearch(): void {
      this.searchQuery = '';
      if (!this.isPimTrademarks) {
        this.clearProductTrademarks();
      }
    },
    async searchTrademarks(): Promise<void> {
      this.isReadySearch = false;
      this.exceptions = [];

      try {
        if (this.isPimTrademarks) {
          this.pimTrademarks = await PimProductBrandRestService.getInstance()
            .findPp2ProductTrademarks(this.searchQuery);
        } else {
          const searchPayload = {
            search_name: this.searchQuery,
            language: this.$i18n.locale,
          } as any;
          if (this.isPimProducts) {
            searchPayload['customer_code'] = Config.getInstance().getConfig().STARTER_CATALOG_CUSTOMER_CODE;
          }

          await this.searchProductTrademarks(searchPayload);
        }
      } catch (exceptions: any) {
        this.exceptions.push(exceptions);
      } finally {
        this.isReadySearch = true;
      }
    },
  }
})
