
import {defineComponent} from "vue";
import {PaymentProviderSettingsRestDtoModel} from "@/models/api/pharma-pay-payment/PaymentProviderSettingsRestDtoModel";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {UIStateDto} from "@/dtos/UIStateDto";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import InputText from "@/components/UI/InputText.vue";
import {PaymentProviderRestService} from "@/services/rest/pay-payment/PaymentProviderRestService";
import {CustomerContext} from "@/context/CustomerContext";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";

export default defineComponent({
  name: "PaymentProviderSettings",
  emits: ["saved"],
  components: {InputText, InputCheckboxBoolean, AlertError2},
  props: {
    paymentProviderCode: {
      type: String,
      required: true
    },
    paymentProviderSettings: {
      type: [PaymentProviderSettingsRestDtoModel, Object],
      required: true,
      default: PaymentProviderSettingsRestDtoModel.createWithDefaults()
    }
  },
  data() {
    return {
      paymentProviderSettingsClone: PaymentProviderSettingsRestDtoModel.createWithDefaults(),
      paymentProviderSettingsUI: UIStateDto.createWithDefaults(),
    }
  },
  watch: {
    paymentProviderCode: {
      handler() {
        this.paymentProviderSettingsClone = JSON.parse(JSON.stringify(this.paymentProviderSettings));
      },
      immediate: true
    }
  },
  methods: {
    async submitData(): Promise<void> {
      this.paymentProviderSettingsUI.clearError();

      try {
        await PaymentProviderRestService.getInstance()
          .updatePaymentProviderSettings(this.paymentProviderSettingsClone, CustomerContext.getPharmacyCode(), this.paymentProviderCode);
        this.$emit('saved');
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.paymentProviderSettingsUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.paymentProviderSettingsUI as UIStateDto))
          .catch(exceptions, true);
      }
    }
  }
});
