import {FindNormalContentBlockPayload} from "@/models/payload/FindNormalContentBlockPayload";
import {ContentBlockEndpointsApiService} from "@/main";
import {SaveNormalContentBlockPayload} from "@/models/payload/SaveNormalContentBlockPayload";
import {CreateNormalContentBlockPayload} from "@/models/payload/CreateNormalContentBlockPayload";
import {DocumentContentRestDtoModel} from "@/models/api/pharma-cms-content-block/DocumentContentRestDtoModel";

export default {
    namespaced: true,
    actions: {
        async create(context: any, payload: CreateNormalContentBlockPayload): Promise<string> {
            console.log('Document::create()');
            const response = await ContentBlockEndpointsApiService.createNormalContentBlockDocument(payload.cb as DocumentContentRestDtoModel, payload.type);

            return response.data.id as string;
        },
        async find(context: any, payload: FindNormalContentBlockPayload): Promise<DocumentContentRestDtoModel> {
            console.log('Document::find()');
            const response = await ContentBlockEndpointsApiService.getNormalContentBlockDocument(payload.type, payload.id);

            return response.data as DocumentContentRestDtoModel;
        },
        async save(context: any, payload: SaveNormalContentBlockPayload): Promise<void> {
            console.log('Document::save()');
            await ContentBlockEndpointsApiService.updateNormalContentBlockDocument(payload.cb as DocumentContentRestDtoModel, payload.type, payload.id);
        },
    }
};
