
import {defineComponent, PropType} from "vue";
import {generateProductFilterPreviewUrl} from "@/helpers/functions/route";

export default defineComponent({
  name: "ButtonProductFilterPreview",
  props: {
    productFilterCode: {
      type: String as PropType<string | null>,
      required: true
    },
  },
  computed: {
    previewUrl(): string | null {
      if (this.productFilterCode) {
        return generateProductFilterPreviewUrl(this.productFilterCode);
      }

      return null;
    }
  }
});
