/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum ImageFitEnum {
    NONE = 'NONE',
    CONTAIN = 'CONTAIN',
    COVER = 'COVER',
}