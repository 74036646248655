
import {defineComponent, ref, watch} from "vue";
import {mapActions, mapGetters} from "vuex";
import {CodeDescriptionRestDto, DiscountSchemeRestDto, MultilingualFieldRestDto, MultilingualProductLabelRestDto,} from "@/api/pharma-cpc-mgmt/models";
import {defineRule} from "vee-validate";
import CatalogBuildStatus from "@/components/layouts/catalog/CatalogBuildStatus.vue";
import DiscountPricelistSection from "@/components/layouts/catalog/discounts-pricelists/DiscountPriceListSection.vue";
import ProductFilters from "@/components/layouts/catalog/product-filter/ProductFilters.vue";
import {UtilityRestService} from "@/services/rest/cms-utility/UtilityRestService";
import MultilingualSwitcher from "@/components/UI/MultilingualSwitcher.vue";
import {I18nContext} from "@/context/I18nContext";
import {useToast} from "vue-toastification";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import I18nInputTextEditor from "@/components/UI/I18n/I18nInputTextEditor.vue";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import {isEmptyString} from "@/helpers/functions/string";
import {ProductFilterApplyTypeEnum} from "@/models/enum/ProductFilterApplyTypeEnum";
import ButtonProductFilterPreview from "@/components/layouts/catalog/ButtonProductFilterPreview.vue";
import {mapActions as mapActionsP} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import {DiscountTypeEnum} from "@/api/enums/discount-type-enum";
import {StatusEnum} from "@/api/enums/status-enum";
import {DiscountSchemeTypeEnum} from "@/api/enums/discount-scheme-type-enum";
import {EnumClassNameCpcEnum} from "@/models/enum/EnumClassNameCpcEnum";
import {DiscountSchemeRestService} from "@/services/rest/cpc-mgmt/DiscountSchemeRestService";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {UIStateDto} from "@/dtos/UIStateDto";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import StaffDiscountsteps from "@/components/layouts/catalog/discount-steps/StaffDiscountSteps.vue";
import BaseOffcanvasButtonOpen from "@/components/UI/Bootstrap/Offcanvas/BaseOffcanvasButtonOpen.vue";
import BaseOffcanvas from "@/components/UI/Bootstrap/Offcanvas/BaseOffcanvas.vue";
import ProductLabel from "@/components/layouts/catalog/product-labels/ProductLabel.vue";
import {AbilityContext} from "@/context/AbilityContext";
import RenderedProductLabel from "@/components/layouts/catalog/product-labels/RenderedProductLabel.vue";
import {ProductLabelRestService} from "@/services/rest/cpc-mgmt/ProductLabelRestService";
import {EnumRestService} from "@/services/rest/enum/EnumRestService";
import DefaultLabel from "@/components/layouts/catalog/product-labels/DefaultLabel.vue";
import {ColorSchemeRestDtoModel} from "@/models/api/pharma-cms-color-scheme/ColorSchemeRestDtoModel";
import {ColorSchemeRestService} from "@/services/rest/cms-color-scheme/ColorSchemeRestService";

//TODO re-evaluate these rules
defineRule("check_date", (value: any, [begin, end]: any) => {
  if ((begin && end) && begin > end) {
    return 'De einddatum moet gelijk aan of later zijn';
  }
  return true;
});

export default defineComponent({
  name: "DiscountsDetail",
  components: {
    DefaultLabel,
    RenderedProductLabel,
    ProductLabel,
    StaffDiscountsteps,
    AlertError2,
    ButtonProductFilterPreview,
    I18nInputTextEditor,
    BaseTitle,
    BaseOffcanvasButtonOpen,
    BaseOffcanvas,
    MultilingualSwitcher,
    ProductFilters,
    DiscountPricelistSection,
    CatalogBuildStatus
  },
  props: ['mode'],
  data() {
    return {
      id: this.$route.params.id,
      canShowDiscountMessage: true,
      returnRoute: 'discounts-price-lists' as string,

      discountMessageTimeoutId: null as number | null,
      discountMessage: MultilingualRestDtoModel.createWithDefaults(),

      activeLanguage: I18nContext.getDefaultApiLanguageKey(),
      //Hack: and errors object has to be passed to the MultilingualSwitcher, but I can't use the errors object from the form,
      // because this switcher is not inside the form.
      languageSwitchErrors: ref([]),

      discountTypeOptions: [] as CodeDescriptionRestDto[],
      combinationDiscountOptions: [] as CodeDescriptionRestDto[],

      staffKey: 0,
      toaster: useToast(),
      pageUI: UIStateDto.createWithDefaults(),
      productLabel: null as MultilingualProductLabelRestDto | null,
      colorScheme: ColorSchemeRestDtoModel.createWithDefaults(),
    }
  },
  mounted() {
    this.reloadContent();

    //This hack is needed to make sure the tinyMCE editor for the discount message does not crash when
    //the editor for the unapplied message is removed.
    //TinyMCE removes something global in the unmount hook.  So if a page contains more than 1 TinyMCE editor, and one of them
    //is removed, then the others no longer function.  So we remove the other one here, wait for the next tick, and add it again.
    //When adding a new one, proper initialization is done, and the editor works fine.
    watch(
      () => this.isShowUnappliedMessage, (v) => {
        if (!v) {
          this.canShowDiscountMessage = false;
          this.$nextTick(() => {
            this.canShowDiscountMessage = true;
          });
        }
      }
    );
  },
  computed: {
    ...mapGetters('cpc_mgmt', ['getDiscountScheme']),
    ...mapGetters('idm_user', ['getClientGroups']),
    AbilityContext() {
      return AbilityContext
    },
    DiscountSchemeTypeEnum() {
      return DiscountSchemeTypeEnum
    },
    ProductFilterApplyTypeEnum() {
      return ProductFilterApplyTypeEnum
    },
    discountScheme(): DiscountSchemeRestDto {
      return this.getDiscountScheme;
    },
    isDiscountValueApplicable(): boolean {
      return this.discountScheme.scheme_type_code !== undefined && this.discountScheme.scheme_type_code === DiscountSchemeTypeEnum.MANUAL_INPUT
        && (this.discountScheme.type_code !== DiscountTypeEnum.BUY_X_GET_Y_FREE && this.discountScheme.type_code !== DiscountTypeEnum.PERCENTAGE_PER_QUANTITY && this.discountScheme.type_code !== DiscountTypeEnum.AMOUNT_PER_QUANTITY);
    },
    isStaffDiscount(): boolean {
      return this.discountScheme.scheme_type_code !== undefined && this.discountScheme.scheme_type_code === DiscountSchemeTypeEnum.MANUAL_INPUT
        && (this.discountScheme.type_code === DiscountTypeEnum.AMOUNT_PER_QUANTITY || this.discountScheme.type_code === DiscountTypeEnum.PERCENTAGE_PER_QUANTITY);
    },
    isXYDiscount(): boolean {
      return this.discountScheme.scheme_type_code !== undefined && this.discountScheme.scheme_type_code === DiscountSchemeTypeEnum.MANUAL_INPUT
        && (this.discountScheme.type_code === DiscountTypeEnum.BUY_X_GET_Y_FREE || this.discountScheme.type_code === DiscountTypeEnum.BUY_X_GET_Y_PERCENTAGE || this.discountScheme.type_code === DiscountTypeEnum.BUY_X_GET_Y_AMOUNT);
    },
    isOrderBasedDiscount(): boolean {
      return this.discountScheme.scheme_type_code !== undefined && this.discountScheme.scheme_type_code === DiscountSchemeTypeEnum.MANUAL_INPUT
        && (this.discountScheme.type_code !== DiscountTypeEnum.PERCENTAGE && this.discountScheme.type_code !== DiscountTypeEnum.AMOUNT);
    },
    isShowUnappliedMessage(): boolean {
      return this.discountScheme.type_code === DiscountTypeEnum.PERCENTAGE_PER_QUANTITY
        || this.discountScheme.type_code === DiscountTypeEnum.BUY_X_GET_Y_FREE
        || this.discountScheme.type_code === DiscountTypeEnum.AMOUNT_PER_QUANTITY
        || this.discountScheme.type_code === DiscountTypeEnum.BUY_X_GET_Y_AMOUNT
        || this.discountScheme.type_code === DiscountTypeEnum.BUY_X_GET_Y_PERCENTAGE;
    },
    discountLabel(): string {
      if (this.discountScheme.discount_value !== null && this.discountScheme.discount_value !== undefined && this.discountScheme.discount_value > 0) {
        if (this.discountScheme.type_code === DiscountTypeEnum.PERCENTAGE) {
          return `- ${this.discountScheme.discount_value}%`
        } else if (this.discountScheme.type_code === DiscountTypeEnum.AMOUNT) {
          return `€ -${this.discountScheme.discount_value}`
        }
      }

      return '';
    },
    selectedDiscountOption: {
      get(): string {
        const discountVisible = this.discountScheme.is_discount_visible;
        const standardPriceVisible = this.discountScheme.is_standard_price_visible;

        if (!discountVisible && !standardPriceVisible) {
          return 'none';
        } else if (!discountVisible && standardPriceVisible) {
          return 'price';
        } else if (discountVisible && standardPriceVisible) {
          return 'pricePercent';
        } else {
          return '';
        }
      },
      set(value: string) {
        switch (value) {
          case 'none':
            this.setDiscountSchemeValue({key: 'is_standard_price_visible', value: false});
            this.setDiscountSchemeValue({key: 'is_discount_visible', value: false});
            break;
          case 'price':
            this.setDiscountSchemeValue({key: 'is_standard_price_visible', value: true});
            this.setDiscountSchemeValue({key: 'is_discount_visible', value: false});
            break;
          case 'pricePercent':
            this.setDiscountSchemeValue({key: 'is_standard_price_visible', value: true});
            this.setDiscountSchemeValue({key: 'is_discount_visible', value: true});
            break;
          default:
            throw new Error('Unsupported selectedDiscountOption to set.');
        }
      }
    },
    selectedDiscountOptionImage(): string | null {
      switch (this.selectedDiscountOption) {
        case '':
        case 'none':
          return 'product_card_discount_none.png';
        case 'price':
        case 'pricePercent':
          return 'product_card_discount_price.png';
        // case 'pricePercent':
        //   return 'product_card_discount_price_percentage.png';
        default:
          throw new Error('Unsupported selectedDiscountOption to search.');
      }
    },
    statusOptions(): CodeDescriptionRestDto[] {
      return [
        {
          code: StatusEnum.PUBLISHED,
          description: this.$t('published'),
        },
        {
          code: StatusEnum.NOT_PUBLISHED,
          description: this.$t('notPublished'),
        },
      ];
    },
    clientGroups(): CodeDescriptionRestDto[] {
      return [
        {
          code: '',
          description: this.$t('none'),
        }
      ].concat(this.getClientGroups);
    },
  },
  methods: {
    ...mapActions('cpc_mgmt', [
      'clearDiscountScheme',
      'clearDiscountSchemePeriod',
      'setDiscountSchemeValue',
      'searchDiscountScheme',
    ]),
    ...mapActions('idm_user', ['searchClientGroups']),
    ...mapActionsP(useCustomerStore, ["searchAllBrandsCustomer", "searchAllTrademarksCustomer", "searchAllCategoriesCustomer", "searchProductCategoriesTreeCustomer"]),
    isEmptyString,
    async submitData() {
      this.pageUI
        .clearError()
        .setNotReady();

      try {
        if (this.mode === 'new') {
          await DiscountSchemeRestService.getInstance().createDiscountScheme(this.discountScheme);
        } else {
          await DiscountSchemeRestService.getInstance().updateDiscountScheme(this.id, this.discountScheme);
        }
        await this.afterSave();
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
        window.scrollTo(0, 0);
      } finally {
        this.pageUI.setReady();
      }
    },
    async reloadContent() {
      this.pageUI
        .clearError()
        .setNotReady();
      try {
        this.discountTypeOptions =
          await EnumRestService.getInstance().getCpcEnumValuesByClassname(EnumClassNameCpcEnum.DISCOUNT_TYPE);
        this.combinationDiscountOptions =
          await EnumRestService.getInstance().getCpcEnumValuesByClassname(EnumClassNameCpcEnum.COMBINATION_DISOOUNT_SCOPE);

        if (this.mode === 'new') {
          this.clearDiscountScheme();
          await Promise.all([
            this.searchAllBrandsCustomer(),
            this.searchAllTrademarksCustomer(),
            this.searchAllCategoriesCustomer(),
            this.searchProductCategoriesTreeCustomer(),
            this.searchClientGroups(),
          ]);
        } else {
          await Promise.all([
            this.searchDiscountScheme(this.id),
            this.searchAllBrandsCustomer(),
            this.searchAllTrademarksCustomer(),
            this.searchAllCategoriesCustomer(),
            this.searchProductCategoriesTreeCustomer(),
            this.searchClientGroups(),
          ]);
        }
        this.colorScheme = await ColorSchemeRestService.getInstance().findColorScheme();
        this.discountMessageUpdated(this.discountScheme.discount_message);
        await this.labelUpdated();
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
        window.scrollTo(0, 0);
      } finally {
        this.pageUI.setReady();
      }
    },
    async labelUpdated() {
      this.pageUI
        .clearError()
        .setNotReady();
      try {
        const labelResult = await ProductLabelRestService.getInstance().getProductLabelForDiscountScheme(this.id);
        if (labelResult) {
          this.productLabel = labelResult.label;
        } else {
          this.productLabel = null;
        }
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
        window.scrollTo(0, 0);
      } finally {
        this.pageUI.setReady();
      }
    },
    async afterSave() {
      if (this.mode === 'new') {
        await this.$router.push({name: this.returnRoute});
      } else {
        this.toaster.success(this.$t('savedSuccessfully'));

        await this.reloadContent();
      }
    },
    discountMessageUpdated(value: any): void {
      this.discountScheme.discount_message = value;

      if (this.discountMessageTimeoutId !== null) {
        clearTimeout(this.discountMessageTimeoutId);
      }

      this.discountMessageTimeoutId = setTimeout(() => {
        Object.keys(this.discountMessage).forEach(async (key: string) => {
          if (this.discountScheme.discount_message !== null && this.discountScheme.discount_message !== undefined) {
            let value = this.discountScheme.discount_message[key as keyof MultilingualFieldRestDto];
            if (!isEmptyString(value)) {
              value = await UtilityRestService.getInstance()
                .truncateHtml(value, 100);
            }

            this.discountMessage.setPropValue(key, value);
          }
        });
      }, 1000);
    },
    staffStepsUpdate(): void {
      this.staffKey++;
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .catch(exceptions, true);
    }
  },
});
