import {EnumContentRestDto, MultilingualFieldRestDto, UpdateEnumContentRestDto} from "@/api/pharma-order-enum/models";
import {MultilingualFieldRestDtoModel} from "@/models/api/pharma-order-enum/MultilingualFieldRestDtoModel";

export class UpdateEnumContentRestDtoModel implements UpdateEnumContentRestDto {
    content: MultilingualFieldRestDtoModel;
    pharmacy_code?: string | null;

    constructor() {
        this.content = MultilingualFieldRestDtoModel.createWithDefaults();
    }

    public static createWithDefaults(): UpdateEnumContentRestDto {
        return new this()
    }
}
