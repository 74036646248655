
import {defineComponent} from "vue";

export default defineComponent({
  name: "BaseAccordionCollapse",
  props: {
    id: {
      type: String,
      required: true,
    },
    labelId: {
      type: String,
      required: true,
    },
    parentId: {
      type: String,
      required: true,
    },
    isAlwaysOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    dynamicProperties(): Object {
      return {
        dataBsParent: (this.isAlwaysOpen) ? undefined : '#' + this.parentId,
      }
    },
  }
});
