<script>
import AbstractInputControl from "@/components/UI/AbstractInputControl";

export default {
  name: "InputDate",
  extends: AbstractInputControl,
  methods: {
    determineInputType() {
      return 'date';
    },
    determineRules() {
      return [];
    },
  },
}
</script>
