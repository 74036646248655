/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */

import {MultilingualMax100FieldRestDto} from ".";
import {MultilingualMax2000FieldRestDto} from ".";
import {MultilingualMax50FieldRestDto} from ".";
import {ButtonLayoutEnum} from "@/api/enums/button-layout-enum";
import {ButtonTargetEnum} from "@/api/enums/button-target-enum";

/**
 * @export
 * @interface ButtonRestDto
 */
export interface ButtonRestDto {
    /**
     * The html target for this button.
     * @type {ButtonTargetEnum|null}
     * @memberof ButtonRestDto
     */
    button_target?: ButtonTargetEnum|null;

    /**
     * 
     * @type {MultilingualMax2000FieldRestDto|null}
     * @memberof ButtonRestDto
     */
    button_url?: MultilingualMax2000FieldRestDto|null;

    /**
     * 
     * @type {MultilingualMax100FieldRestDto}
     * @memberof ButtonRestDto
     */
    button_text: MultilingualMax100FieldRestDto;

    /**
     * 
     * @type {MultilingualMax50FieldRestDto|null}
     * @memberof ButtonRestDto
     */
    button_page_id?: MultilingualMax50FieldRestDto|null;

    /**
     * The specifies layout of this button.
     * @type {ButtonLayoutEnum|null}
     * @memberof ButtonRestDto
     */
    button_layout_code?: ButtonLayoutEnum|null;

}

/**
 * @export
 * @function cloneButtonRestDtoMetaData
 */
export function cloneButtonRestDtoMetaData(dto: ButtonRestDto, source: ButtonRestDto): void {
    dto.button_target = source.button_target;
    dto.button_layout_code = source.button_layout_code;
}
