
import {defineComponent} from "vue";
import CpsDetail from "@/components/layouts/content-pages/detail/CpsDetail.vue";

export default defineComponent({
  name: "ContentPagesDetail",
  components: {CpsDetail},
  props: ['mode'],
  data() {
    return {
      id: this.$route.params.id as string,
    };
  },
});
