import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "row mb-3 align-items-center" }
const _hoisted_2 = { class: "col-9 col-xl-10 flex-column" }
const _hoisted_3 = {
  key: 0,
  class: "card-title"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 2,
  class: "btn btn-primary",
  disabled: ""
}
const _hoisted_6 = { class: "col-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_card_image = _resolveComponent("base-card-image")!
  const _component_base_card_body = _resolveComponent("base-card-body")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_base_modal_button = _resolveComponent("base-modal-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_card, {
        style: _normalizeStyle(this.cardStyles)
      }, {
        default: _withCtx(() => [
          (this.imagePreviewUrl)
            ? (_openBlock(), _createBlock(_component_base_card_image, {
                key: 0,
                src: this.imagePreviewUrl,
                style: _normalizeStyle(this.imageStyles)
              }, null, 8, ["src", "style"]))
            : _createCommentVNode("", true),
          _createVNode(_component_base_card_body, null, {
            default: _withCtx(() => [
              (this.contentBlock.cb?.title?.nl)
                ? (_openBlock(), _createElementBlock("h5", _hoisted_3, _toDisplayString(this.contentBlock.cb.title.nl), 1))
                : _createCommentVNode("", true),
              (this.contentBlock.cb?.content?.nl)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 1,
                    innerHTML: this.contentBlock.cb.content.nl,
                    class: "card-text"
                  }, null, 8, _hoisted_4))
                : _createCommentVNode("", true),
              (this.contentBlock.cb?.button?.button_text?.nl)
                ? (_openBlock(), _createElementBlock("button", _hoisted_5, _toDisplayString(this.contentBlock.cb.button.button_text.nl), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["style"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_base_modal_button, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (this.bus.emit('selectedLibCb', this.contentBlock.id))),
        class: "btn btn-primary",
        mode: "close"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('select')), 1)
        ]),
        _: 1
      })
    ])
  ]))
}