
import {defineComponent} from "vue";
import {Exception} from "@/api/interfaces";
import {mapActions, mapGetters} from "vuex";
import {
  CodeDescriptionRestDto,
  MultilingualFieldRestDto,
  PricelistRestDto,
} from "@/api/pharma-cpc-mgmt/models";
import {defineRule} from "vee-validate";
import CatalogBuildStatus from "@/components/layouts/catalog/CatalogBuildStatus.vue";
import {isEmptyString} from "@/helpers/functions/string";
import DiscountPricelistSection from "@/components/layouts/catalog/discounts-pricelists/DiscountPriceListSection.vue";
import {I18nContext} from "@/context/I18nContext";
import I18nInputTextEditor from "@/components/UI/I18n/I18nInputTextEditor.vue";
import MultilingualSwitcher from "@/components/UI/MultilingualSwitcher.vue";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import {UtilityRestService} from "@/services/rest/cms-utility/UtilityRestService";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {StatusEnum} from "@/api/enums/status-enum";

//TODO re-evaluate these rules
defineRule("check_date", (value: any, [begin, end]: any) => {
  if ((begin && end) && begin > end) {
    return 'De einddatum moet gelijk aan of later zijn';
  }
  return true;
});

export default defineComponent({
  name: "PriceListsDetail",
  components: {
    BaseTitle,
    MultilingualSwitcher, I18nInputTextEditor, DiscountPricelistSection, CatalogBuildStatus
  },
  props: ['mode'],
  data() {
    return {
      exceptions: [] as Exception[],
      id: this.$route.params.id,
      isReady: false as Boolean,
      returnRoute: 'discounts-price-lists' as string,
      savedSuccessfully: false as Boolean,

      discountMessageTimeoutId: null as number | null,
      discountMessage: MultilingualRestDtoModel.createWithDefaults(),

      activeLanguage: I18nContext.getDefaultApiLanguageKey(),
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cpc_mgmt', ['getPriceList']),
    ...mapGetters('idm_user', ['getClientGroups']),
    priceList(): PricelistRestDto {
      return this.getPriceList;
    },
    selectedDiscountOption: {
      get(): string {
        const discountVisible = this.priceList.is_discount_visible;
        const standardPriceVisible = this.priceList.is_standard_price_visible;

        if (!discountVisible && !standardPriceVisible) {
          return 'none';
        } else if (!discountVisible && standardPriceVisible) {
          return 'price';
        } else if (discountVisible && standardPriceVisible) {
          return 'pricePercent';
        } else {
          return '';
        }
      },
      set(value: string) {
        switch (value) {
          case 'none':
            this.setPriceListValue({key: 'is_standard_price_visible', value: false});
            this.setPriceListValue({key: 'is_discount_visible', value: false});
            break;
          case 'price':
            this.setPriceListValue({key: 'is_standard_price_visible', value: true});
            this.setPriceListValue({key: 'is_discount_visible', value: false});
            break;
          case 'pricePercent':
            this.setPriceListValue({key: 'is_standard_price_visible', value: true});
            this.setPriceListValue({key: 'is_discount_visible', value: true});
            break;
          default:
            throw new Error('Unsupported selectedDiscountOption to set.');
        }
      }
    },
    selectedDiscountOptionImage(): string | null {
      switch (this.selectedDiscountOption) {
        case '':
        case 'none':
          return 'product_card_discount_none.png';
        case 'price':
          return 'product_card_discount_price.png';
        case 'pricePercent':
          return 'product_card_discount_price_percentage.png';
        default:
          throw new Error('Unsupported selectedDiscountOption to search.');
      }
    },
    statusOptions(): CodeDescriptionRestDto[] {
      return [
        {
          code: StatusEnum.PUBLISHED,
          description: this.$t('published'),
        },
        {
          code: StatusEnum.NOT_PUBLISHED,
          description: this.$t('notPublished'),
        },
      ];
    },
    clientGroups(): CodeDescriptionRestDto[] {
      return [
        {
          code: '',
          description: this.$t('none'),
        }
      ].concat(this.getClientGroups);
    },
  },
  methods: {
    isEmptyString,
    ...mapActions('cpc_mgmt', [
      'clearPriceList',
      'clearPriceListPeriod',
      'setPriceListValue',
      'createPriceList',
      'searchPriceList',
      'savePriceList',
    ]),
    ...mapActions('idm_user', ['searchClientGroups']),
    async submitData() {
      this.exceptions = [];

      try {
        if (this.mode === 'new') {
          await this.createPriceList(this.priceList);
        } else {
          await this.savePriceList({
            id: this.id,
            price_list: this.priceList,
          });
        }

        await this.afterSave();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
        window.scrollTo(0, 0);
      }
    },
    async reloadContent() {
      this.isReady = false;
      this.exceptions = [];

      try {
        if (this.mode === 'new') {
          await Promise.all([
            this.searchClientGroups(),
            this.clearPriceList()
          ]);
        } else {
          await Promise.all([
            this.searchClientGroups(),
            this.searchPriceList(this.id)
          ]);
        }
        this.discountMessageUpdated(this.priceList.discount_message);

        this.isReady = true;
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async afterSave() {
      if (this.mode === 'new') {
        await this.$router.push({name: this.returnRoute});
      } else {
        window.scrollTo(0, 0);

        await this.reloadContent();
        this.savedSuccessfully = true;
        setTimeout(() => this.savedSuccessfully = false, 2000);
      }
    },
    getMultiLingualValue(dto: MultilingualFieldRestDto | null | undefined, fieldName: string): string | null {
      if (! dto) {
        return null;
      }
      const dtoElement = dto[fieldName as 'nl' | 'fr' | 'en' | 'de'];
      if (dtoElement === undefined) {
        return null;
      }

      return dtoElement;
    },
    discountMessageUpdated(value: any): void {
      this.priceList.discount_message = value;

      if (this.discountMessageTimeoutId !== null) {
        clearTimeout(this.discountMessageTimeoutId);
      }

      this.discountMessageTimeoutId = setTimeout(() => {
        Object.keys(this.discountMessage).forEach(async (key: string) => {
          let value = this.getMultiLingualValue(this.priceList.discount_message, key);

          if (!isEmptyString(value)) {
            value = await UtilityRestService.getInstance()
              .truncateHtml(value, 100);
          }

          this.discountMessage.setPropValue(key, value);
        });
      }, 1000);
    }
  },
});
