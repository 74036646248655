/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum DiscountTypeEnum {
    PERCENTAGE = 'PERCENTAGE',
    AMOUNT = 'AMOUNT',
    PERCENTAGE_PER_QUANTITY = 'PERCENTAGE_PER_QUANTITY',
    AMOUNT_PER_QUANTITY = 'AMOUNT_PER_QUANTITY',
    BUY_X_GET_Y_FREE = 'BUY_X_GET_Y_FREE',
    BUY_X_GET_Y_PERCENTAGE = 'BUY_X_GET_Y_PERCENTAGE',
    BUY_X_GET_Y_AMOUNT = 'BUY_X_GET_Y_AMOUNT',
}