
import {defineComponent} from "vue";
import ProductSelectionBase from "@/components/layouts/catalog/product-selection/ProductSelectionBase.vue";
import ProductSelectionBrands from "@/components/layouts/catalog/product-selection/ProductSelectionBrands.vue";
import ProductSelectionTrademarks from "@/components/layouts/catalog/product-selection/ProductSelectionTrademarks.vue";
import ProductSelectionProducts from "@/components/layouts/catalog/product-selection/ProductSelectionProducts.vue";
import CatalogBuildStatus from "@/components/layouts/catalog/CatalogBuildStatus.vue";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import BaseTitle from "@/components/UI/BaseTitle.vue";

export default defineComponent({
  name: "ProductSelection",
  components: {BaseTitle, CatalogBuildStatus, ProductSelectionBase, ProductSelectionBrands, ProductSelectionTrademarks, ProductSelectionProducts},
  data: () => ({
    currentComponent: 'product-selection-base' as string,
  }),
  computed: {
    //TODO search for something we can use to check if contents of base have changed
    currentProperties(): object {
      switch (this.currentComponent) {
        case 'product-selection-base':
          return {};
        case 'product-selection-brands':
          return {};
        case 'product-selection-trademarks':
          return {};
        case 'product-selection-products':
          return {};
        default:
          return {};
      }
    },
  },
  methods: {
    handleSavedSuccessfully,
    setCurrentComponent(cmp: string) {
      this.currentComponent = cmp;
    }
  },
});
