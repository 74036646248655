/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum MarginBottomEnum {
    M_0 = 'M_0',
    M_1 = 'M_1',
    M_2 = 'M_2',
    M_3 = 'M_3',
    M_4 = 'M_4',
    M_5 = 'M_5',
}