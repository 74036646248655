/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Customer-Product-Catalog
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { PagedProductBrandOrderOverviewResultsRestDto } from '../models';
import { ProductBrandOrderCreateRestDto } from '../models';
/**
 * ProductBrandOrderAPIApi - axios parameter creator
 * @export
 */
export const ProductBrandOrderAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for creating a discount scheme.
         * @summary REST.API.CPC.BO.02 Add a brand to the list of preferred brands.
         * @param {ProductBrandOrderCreateRestDto} body 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBrandOrder: async (body: ProductBrandOrderCreateRestDto, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createBrandOrder.');
            }
            const localVarPath = `/customer_mgmt/v1/catalog/product_brand_order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for deleting a brand order.
         * @summary REST.API.CPC.BO.03 Delete brand from sorted list of preferred brands.
         * @param {string} brand_code The unique identification of the product brand.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBrandOrder: async (brand_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'brand_code' is not null or undefined
            if (brand_code === null || brand_code === undefined) {
                throw new RequiredError('brand_code','Required parameter brand_code was null or undefined when calling deleteBrandOrder.');
            }
            const localVarPath = `/customer_mgmt/v1/catalog/product_brand_order/{brand_code}`
                .replace(`{${"brand_code"}}`, encodeURIComponent(String(brand_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for fetching the sorted list of preferred brands that have an explicit order
         * @summary REST.API.CPC.BO.01 Fetch the sorted list of brands that have an explicit order
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBrandOrders: async (x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer_mgmt/v1/catalog/product_brand_order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for moving the brand order one position down.
         * @summary REST.API.CPC.BO.07 Move brand order one position down.
         * @param {string} brand_code The unique identification of the product brand.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveBrandOrderDown: async (brand_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'brand_code' is not null or undefined
            if (brand_code === null || brand_code === undefined) {
                throw new RequiredError('brand_code','Required parameter brand_code was null or undefined when calling moveBrandOrderDown.');
            }
            const localVarPath = `/customer_mgmt/v1/catalog/product_brand_order/{brand_code}/move/down`
                .replace(`{${"brand_code"}}`, encodeURIComponent(String(brand_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for moving the sort order of this brand order to the bottom position.
         * @summary REST.API.CPC.BO.05 Move brand order to the bottom position.
         * @param {string} brand_code The unique identification of the product brand.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveBrandOrderToBottom: async (brand_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'brand_code' is not null or undefined
            if (brand_code === null || brand_code === undefined) {
                throw new RequiredError('brand_code','Required parameter brand_code was null or undefined when calling moveBrandOrderToBottom.');
            }
            const localVarPath = `/customer_mgmt/v1/catalog/product_brand_order/{brand_code}/move/to_bottom`
                .replace(`{${"brand_code"}}`, encodeURIComponent(String(brand_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for moving the sort order of this brand order to the top position.
         * @summary REST.API.CPC.BO.04 Move brand order to the top position.
         * @param {string} brand_code The unique identification of the product brand.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveBrandOrderToTop: async (brand_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'brand_code' is not null or undefined
            if (brand_code === null || brand_code === undefined) {
                throw new RequiredError('brand_code','Required parameter brand_code was null or undefined when calling moveBrandOrderToTop.');
            }
            const localVarPath = `/customer_mgmt/v1/catalog/product_brand_order/{brand_code}/move/to_top`
                .replace(`{${"brand_code"}}`, encodeURIComponent(String(brand_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for moving the brand order one position up.
         * @summary REST.API.CPC.BO.06 Move brand order one position up.
         * @param {string} brand_code The unique identification of the product brand.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveBrandOrderUp: async (brand_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'brand_code' is not null or undefined
            if (brand_code === null || brand_code === undefined) {
                throw new RequiredError('brand_code','Required parameter brand_code was null or undefined when calling moveBrandOrderUp.');
            }
            const localVarPath = `/customer_mgmt/v1/catalog/product_brand_order/{brand_code}/move/up`
                .replace(`{${"brand_code"}}`, encodeURIComponent(String(brand_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductBrandOrderAPIApi - functional programming interface
 * @export
 */
export const ProductBrandOrderAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for creating a discount scheme.
         * @summary REST.API.CPC.BO.02 Add a brand to the list of preferred brands.
         * @param {ProductBrandOrderCreateRestDto} body 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBrandOrder(_axios: AxiosInstance, body: ProductBrandOrderCreateRestDto, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductBrandOrderAPIApiAxiosParamCreator(configuration).createBrandOrder(body, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for deleting a brand order.
         * @summary REST.API.CPC.BO.03 Delete brand from sorted list of preferred brands.
         * @param {string} brand_code The unique identification of the product brand.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBrandOrder(_axios: AxiosInstance, brand_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductBrandOrderAPIApiAxiosParamCreator(configuration).deleteBrandOrder(brand_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for fetching the sorted list of preferred brands that have an explicit order
         * @summary REST.API.CPC.BO.01 Fetch the sorted list of brands that have an explicit order
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findBrandOrders(_axios: AxiosInstance, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedProductBrandOrderOverviewResultsRestDto>> {
            const localVarAxiosArgs = await ProductBrandOrderAPIApiAxiosParamCreator(configuration).findBrandOrders(x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for moving the brand order one position down.
         * @summary REST.API.CPC.BO.07 Move brand order one position down.
         * @param {string} brand_code The unique identification of the product brand.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveBrandOrderDown(_axios: AxiosInstance, brand_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductBrandOrderAPIApiAxiosParamCreator(configuration).moveBrandOrderDown(brand_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for moving the sort order of this brand order to the bottom position.
         * @summary REST.API.CPC.BO.05 Move brand order to the bottom position.
         * @param {string} brand_code The unique identification of the product brand.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveBrandOrderToBottom(_axios: AxiosInstance, brand_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductBrandOrderAPIApiAxiosParamCreator(configuration).moveBrandOrderToBottom(brand_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for moving the sort order of this brand order to the top position.
         * @summary REST.API.CPC.BO.04 Move brand order to the top position.
         * @param {string} brand_code The unique identification of the product brand.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveBrandOrderToTop(_axios: AxiosInstance, brand_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductBrandOrderAPIApiAxiosParamCreator(configuration).moveBrandOrderToTop(brand_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for moving the brand order one position up.
         * @summary REST.API.CPC.BO.06 Move brand order one position up.
         * @param {string} brand_code The unique identification of the product brand.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveBrandOrderUp(_axios: AxiosInstance, brand_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductBrandOrderAPIApiAxiosParamCreator(configuration).moveBrandOrderUp(brand_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProductBrandOrderAPIApi - object-oriented interface
 * @export
 * @class ProductBrandOrderAPIApi
 * @extends {BaseAPI}
 */
export class ProductBrandOrderAPIApi extends BaseAPI {
    /**
     * API endpoint responsible for creating a discount scheme.
     * @summary REST.API.CPC.BO.02 Add a brand to the list of preferred brands.
     * @param {ProductBrandOrderCreateRestDto} body 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBrandOrderAPIApi
     */
     public createBrandOrder(body: ProductBrandOrderCreateRestDto, x_dp_customer_code?: string|null, options?: any) {
        return ProductBrandOrderAPIApiFp(this.configuration).createBrandOrder(this.axios, body, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for deleting a brand order.
     * @summary REST.API.CPC.BO.03 Delete brand from sorted list of preferred brands.
     * @param {string} brand_code The unique identification of the product brand.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBrandOrderAPIApi
     */
     public deleteBrandOrder(brand_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ProductBrandOrderAPIApiFp(this.configuration).deleteBrandOrder(this.axios, brand_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for fetching the sorted list of preferred brands that have an explicit order
     * @summary REST.API.CPC.BO.01 Fetch the sorted list of brands that have an explicit order
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBrandOrderAPIApi
     */
     public findBrandOrders(x_dp_customer_code?: string|null, options?: any) {
        return ProductBrandOrderAPIApiFp(this.configuration).findBrandOrders(this.axios, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for moving the brand order one position down.
     * @summary REST.API.CPC.BO.07 Move brand order one position down.
     * @param {string} brand_code The unique identification of the product brand.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBrandOrderAPIApi
     */
     public moveBrandOrderDown(brand_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ProductBrandOrderAPIApiFp(this.configuration).moveBrandOrderDown(this.axios, brand_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for moving the sort order of this brand order to the bottom position.
     * @summary REST.API.CPC.BO.05 Move brand order to the bottom position.
     * @param {string} brand_code The unique identification of the product brand.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBrandOrderAPIApi
     */
     public moveBrandOrderToBottom(brand_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ProductBrandOrderAPIApiFp(this.configuration).moveBrandOrderToBottom(this.axios, brand_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for moving the sort order of this brand order to the top position.
     * @summary REST.API.CPC.BO.04 Move brand order to the top position.
     * @param {string} brand_code The unique identification of the product brand.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBrandOrderAPIApi
     */
     public moveBrandOrderToTop(brand_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ProductBrandOrderAPIApiFp(this.configuration).moveBrandOrderToTop(this.axios, brand_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for moving the brand order one position up.
     * @summary REST.API.CPC.BO.06 Move brand order one position up.
     * @param {string} brand_code The unique identification of the product brand.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBrandOrderAPIApi
     */
     public moveBrandOrderUp(brand_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ProductBrandOrderAPIApiFp(this.configuration).moveBrandOrderUp(this.axios, brand_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
