
import {defineComponent} from 'vue'
import {ProductCategoryWithParentsRestDto} from "@/api/pharma-cpc-product-mgmt/models";
import {PropType} from "vue/dist/vue";

export default defineComponent({
  name: "CategoryWithParents",
  props: {
    category: {
      type: Object as PropType<ProductCategoryWithParentsRestDto>,
      required: true
    },
  },
})
