import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row mb-3" }
const _hoisted_2 = { class: "col-lg-6 justify-content-start" }
const _hoisted_3 = { class: "col-lg-6 justify-content-start" }
const _hoisted_4 = {
  type: "submit",
  class: "flex-1 me-3 btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_alert_success = _resolveComponent("alert-success")!
  const _component_alert_error = _resolveComponent("alert-error")!
  const _component_input_radio = _resolveComponent("input-radio")!
  const _component_input_checkbox = _resolveComponent("input-checkbox")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_dp_form = _resolveComponent("dp-form")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_alert_success, {
        "show-if": _ctx.savedSuccessfully,
        class: "mt-3"
      }, null, 8, ["show-if"]),
      _createVNode(_component_alert_error, {
        exceptions: _ctx.exceptions,
        class: "mt-3"
      }, null, 8, ["exceptions"]),
      _createVNode(_component_dp_form, {
        class: "mx-3",
        novalidate: ""
      }, {
        default: _withCtx(({ errors }) => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h3", null, _toDisplayString(_ctx.$t('defaultLanguage')), 1),
              _createVNode(_component_input_radio, {
                class: "d-flex flex-row-reverse align-items-center justify-content-end",
                label: _ctx.$t('dutch'),
                name: "language",
                errors: errors,
                disabled: true
              }, null, 8, ["label", "errors"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h3", null, _toDisplayString(_ctx.$t('otherLanguage')), 1),
              _createVNode(_component_input_checkbox, {
                class: "d-flex flex-row-reverse align-items-center justify-content-end",
                label: _ctx.$t('french'),
                name: "frans",
                errors: errors
              }, null, 8, ["label", "errors"]),
              _createVNode(_component_input_checkbox, {
                class: "d-flex flex-row-reverse align-items-center justify-content-end",
                label: _ctx.$t('english'),
                name: "engels",
                errors: errors
              }, null, 8, ["label", "errors"])
            ])
          ]),
          _createElementVNode("button", _hoisted_4, _toDisplayString(_ctx.$t('save')), 1),
          _createVNode(_component_router_link, {
            class: "flex-1 btn btn-outline-primary",
            to: "/"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}