
import {Exception} from '@/api/interfaces';
import {defineComponent} from "vue";
import {
  CodeDescriptionRestDto,
  ImageContentRestDto,
  SimpleImageContentRestDto
} from "@/api/pharma-cms-content-block/models";
import {StatusEnum} from "@/api/enums/status-enum";
import {mapActions, mapGetters} from 'vuex';
import CbForm from "@/components/layouts/content-blocks/forms/CbForm.vue";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";

export default defineComponent({
  name: "CbFormImage",
  components: {CbForm},
  props: {
    cbId: {
      type: String,
      required: false,
    },
    cbType: {
      type: String,
      required: true,
    },
    canCopyFromLibrary: {
      type: Boolean,
      required: false,
      default: false,
    },
    docType: {
      type: String,
      required: true,
    },
    contentLibraryCode: {
      type: String,
      required: false,
    },
    isLibrary: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPublishable: {
      type: Boolean,
      required: false,
      default: true,
    },
    mode: {
      type: String,
      required: true,
    },
    returnRoute: {
      type: Object,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    description2: {
      type: String,
      required: false,
    },
    description3: {
      type: String,
      required: false,
    },
    description4: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      error: null,
      exceptions: [] as Exception[],
      isReady: false as boolean,
      isInitialValueSet: false as boolean,
      imageKey: null as string | null | undefined,
      initialImageKey: '' as string | null | undefined,
      savedSuccessfully: false as boolean,
      statusOptions: [
        {code: StatusEnum.PUBLISHED, description: this.$t('published')},
        {code: StatusEnum.NOT_PUBLISHED, description: this.$t('notPublished')},
      ] as CodeDescriptionRestDto[]
    };
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cms_content_block', [
      'getSimpleContentBlockImage',
      'getNormalContentBlockImage',
    ]),
    contentBlock(): ImageContentRestDto | SimpleImageContentRestDto {
      if (this.isLibrary) {
        return this.getNormalContentBlockImage(this.cbType) as ImageContentRestDto;
      } else {
        return this.getSimpleContentBlockImage(this.cbType) as SimpleImageContentRestDto;
      }
    },
    existingDocumentKey(): string | null | undefined {
      if (this.isLibrary && this.mode === 'new') {
        return null;
      } else if (this.isLibrary) {
        //todo tmp fix so image prop of cb is recognized
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.contentBlock = this.contentBlock as ImageContentRestDto;
        return this.contentBlock.image.image_key.nl;
      } else {
        return this.contentBlock.image_key?.nl;
      }
    },
    isContentChanged(): boolean {
      return this.initialImageKey !== this.imageKey;
    },
    isCopiedContent(): boolean {
      //TODO: create logic for determining whether cb is copied or not
      return false;
    },
  },
  methods: {
    ...mapActions('cms_content_block', [
      'clearNormalContentBlockImage',
      'createLibContentBlockImage',
      'searchSimpleContentBlockImage',
      'searchNormalContentBlockImage',
      'saveSimpleContentBlockImage',
      'saveNormalContentBlockImage',
    ]),
    ...mapActions('cms_document', ['clearPreviewDocument', 'clearUploadedDocumentKey']),
    copiedLibContentBlock() {
      this.reloadContent().then(() => {
        this.savedSuccessfully = true;
        setTimeout(() => this.savedSuccessfully = false, 2000);
      });
    },
    setImageKey(document_key?: string | null) {
      this.imageKey = document_key;
    },
    async restoreInitialValue() {
      this.setImageKey(this.initialImageKey);
      await this.submitData();
      this.initialImageKey = this.contentBlock.image_key?.nl;
    },
    async submitData() {
      try {
        if (this.isLibrary) {
          //todo tmp fix so image prop of cb is recognized
          this.contentBlock = this.contentBlock as ImageContentRestDto;
          this.contentBlock.image.image_key.nl = this.imageKey;
          //todo tmp fix
          this.contentBlock.image_key = null;

          if (this.mode === 'new') {
            await this.createLibContentBlockImage({
              type: this.cbType,
              library_code: this.contentLibraryCode,
            });
          } else if (this.mode === 'edit') {
            await this.saveNormalContentBlockImage({
              id: this.cbId,
              type: this.cbType,
            });
          }
        } else {
          this.contentBlock.image_key = new MultilingualRestDtoModel(null, null, null, this.imageKey ?? null);
          await this.saveSimpleContentBlockImage(this.cbType);
        }

        await this.afterSave();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async reloadContent() {
      this.isReady = false;
      this.exceptions = [];

      try {
        await this.clearPreviewDocument(this.docType);
        await this.clearUploadedDocumentKey(this.docType);

        if (this.isLibrary) {
          if (this.mode === 'new') {
            this.clearNormalContentBlockImage(this.cbType);
          } else {
            await this.searchNormalContentBlockImage({
              id: this.cbId,
              type: this.cbType,
            });
          }

          this.setImageKey(this.existingDocumentKey);
        } else {
          await this.searchSimpleContentBlockImage(this.cbType);
          this.setImageKey(this.contentBlock.image_key?.nl);
        }

        if (!this.isInitialValueSet) {
          this.initialImageKey = this.contentBlock.image_key?.nl;
          this.isInitialValueSet = true;
        }
      } catch (exceptions: any) {
        this.exceptions = exceptions;
        this.isReady = true;
      }

      this.isReady = true;
    },
    async afterSave() {
      if (this.returnRoute) {
        await this.$router.push(this.returnRoute);
      } else {
        this.reloadContent().then(() => {
          this.savedSuccessfully = true;
          setTimeout(() => this.savedSuccessfully = false, 2000);
        });
      }
    }
  }
});
