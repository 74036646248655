import {TargetPageInfoRestDto} from "@/api/pharma-cms-content-page/models";

export class I18nTargetPageInfoDto {
    [key: string]: TargetPageInfoRestDto | null;

    constructor(
        public nl: TargetPageInfoRestDto | null = null,
        public fr: TargetPageInfoRestDto | null = null,
        public en: TargetPageInfoRestDto | null = null,
        public de: TargetPageInfoRestDto | null = null,
    ) {}
}
