/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum ButtonLayoutEnum {
    PRIMARY_OUTLINE = 'PRIMARY_OUTLINE',
    PRIMARY = 'PRIMARY',
    SECONDARY_OUTLINE = 'SECONDARY_OUTLINE',
    SECONDARY = 'SECONDARY',
    LINK = 'LINK',
}