import {BusinessService} from "@/services/business/BusinessService";
import {CustomerContext} from "@/context/CustomerContext";
import {CustomerDomainRestService} from "@/services/rest/customer-settings/CustomerDomainRestService";

export class CustomerContextService extends BusinessService {

    public async updateCustomerContext(customerCode: string): Promise<void> {
        CustomerContext.setCustomerCode(customerCode);

        const mainDomain = await CustomerDomainRestService.getInstance()
            .findCustomerMainDomain(customerCode);

        CustomerContext.setMainDomain(mainDomain);
    }
}
