import {
    BackgroundImageRestDto, BorderRadiusRestDto, cloneBackgroundImageRestDtoMetaData,
    ColorRestDto, ContentBlockInRowRestDto, DeviceVisibilityRestDto,
} from "@/api/pharma-cms-content-page/models";
import {ContentBlockStructureEnum} from "@/models/enum/ContentBlockStructureEnum";
import {BackgroundColorEnum} from "@/api/enums/background-color-enum";
import {ColumnHorizontalAlignmentEnum} from "@/api/enums/column-horizontal-alignment-enum";
import {ColumnWidthEnum} from "@/api/enums/column-width-enum";
import {PaddingBottomEnum} from "@/api/enums/padding-bottom-enum";
import {PaddingLeftEnum} from "@/api/enums/padding-left-enum";
import {PaddingRightEnum} from "@/api/enums/padding-right-enum";
import {PaddingTopEnum} from "@/api/enums/padding-top-enum";
import {BorderRadiusRestDtoModel} from "@/models/BorderRadiusRestDtoModel";
import {BackgroundImageRestDtoModel} from "@/models/BackgroundImageRestDtoModel";
import {ColorRestDtoModel} from "@/models/api/ColorRestDtoModel";

export class ContentBlockInRowRestDtoModel implements ContentBlockInRowRestDto {
    cb_id?: string;
    background_color_code?: BackgroundColorEnum;
    background_color?: ColorRestDto;
    background_image?: BackgroundImageRestDto;
    border_radius: BorderRadiusRestDto;
    device_visibility?: DeviceVisibilityRestDto;
    column_horizontal_alignment_code?: ColumnHorizontalAlignmentEnum;
    column_width_code: ColumnWidthEnum;
    padding_bottom_code?: PaddingBottomEnum;
    padding_left_code?: PaddingLeftEnum;
    padding_right_code?: PaddingRightEnum;
    padding_top_code?: PaddingTopEnum;
    structure_code: ContentBlockStructureEnum;

    constructor(column_width_code: ColumnWidthEnum, structure_code: ContentBlockStructureEnum, cb_id?: string) {
        this.column_width_code = column_width_code;
        this.structure_code = structure_code;
        this.cb_id = cb_id;
        this.border_radius = BorderRadiusRestDtoModel.createWithDefaults();
    }

    static createWithDefaults(): ContentBlockInRowRestDtoModel {
        const model = new this(
            ColumnWidthEnum.COL_12_12,
            ContentBlockStructureEnum.TEXT,
        );

        model.background_color_code = BackgroundColorEnum.TRANSPARENT;
        model.padding_top_code = PaddingTopEnum.P_0;
        model.padding_bottom_code = PaddingBottomEnum.P_0;
        model.padding_left_code = PaddingLeftEnum.P_0;
        model.padding_right_code = PaddingRightEnum.P_0;
        model.column_horizontal_alignment_code = ColumnHorizontalAlignmentEnum.START;

        return model;
    }

    public static cloneFrom(source: ContentBlockInRowRestDtoModel): ContentBlockInRowRestDtoModel {
        const model = new this(
            source.column_width_code,
            source.structure_code,
        );

        model.background_color_code = source.background_color_code;
        model.padding_top_code = source.padding_top_code;
        model.padding_bottom_code = source.padding_bottom_code;
        model.padding_left_code = source.padding_left_code;
        model.padding_right_code = source.padding_right_code;
        model.column_horizontal_alignment_code = source.column_horizontal_alignment_code;

        if (source.border_radius !== null && source.border_radius !== undefined) {
            model.border_radius = BorderRadiusRestDtoModel.cloneFrom(source.border_radius);
        }
        if (source.background_color !== null && source.background_color !== undefined) {
            model.background_color = ColorRestDtoModel.cloneFrom(source.background_color);
        }

        if (source.background_image !== null && source.background_image !== undefined) {
            model.background_image = BackgroundImageRestDtoModel.createWithDefaults();
            cloneBackgroundImageRestDtoMetaData(model.background_image, source.background_image);
        }

        return model;
    }
}
