import {
    CodeDescriptionRestDto,
    ContentPageCreateRestDto,
    ContentPageRestDto,
    FullContentPageRestDto,
    WysiwygContentPageRestDto,
} from "@/api/pharma-cms-content-page/models";
import {StatusEnum} from "@/api/enums/status-enum";

function emptyContentPage(): ContentPageRestDto {
    return {
        status_code: StatusEnum.PUBLISHED,
        name: '',
        slug: undefined,
        publication_date: undefined,
        last_modified_date: undefined,
        source_text: undefined,
        source_link: undefined,
        label: undefined,
        is_published_on_home: false,
        overview_cb_id: undefined,
    };
}

function emptyFullContentPage(): FullContentPageRestDto {
    return {
        id: '',
        alias: undefined,
        created: undefined,
        type: undefined,
        cp: emptyContentPage(),
        cp_abstract: undefined
    };
}

function emptyWysiwygContentPage(): WysiwygContentPageRestDto {
    return {
        id: '',
        content_id: '',
        type: {
            code: '',
            description: undefined
        } as CodeDescriptionRestDto,
        cp: emptyContentPage(),
        cp_abstract: undefined,
        rows: []
    };
}

function parseCreatedContentPage(content_page: ContentPageRestDto, type_code?: string, library_code?: string): ContentPageCreateRestDto {
    const parsed_content_page = JSON.parse(JSON.stringify(content_page));
    const parsed_created_content_page = {
        status_code: parsed_content_page.cp.status_code,
        name: parsed_content_page.cp.name,
        type_code: type_code,
        library_code: library_code,
    } as ContentPageCreateRestDto;

    return parsed_created_content_page;
}

function parseSearchedContentPageWysiwyg(content_page: WysiwygContentPageRestDto): WysiwygContentPageRestDto {
    return JSON.parse(JSON.stringify(content_page)) as WysiwygContentPageRestDto;
}

export {
    emptyContentPage,
    emptyFullContentPage,
    emptyWysiwygContentPage,
    parseCreatedContentPage,
    parseSearchedContentPageWysiwyg,
};
