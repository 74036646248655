
import {defineComponent, PropType} from "vue";
import {AutoSaveFeedbackStatusEnum} from "@/models/enum/AutoSaveFeedbackStatusEnum";
import I18n from "@/modules/i18n";

export default defineComponent({
  name: "AutoSaveFeedback",
  props: {
    unsavedChangesText: {
      type: String,
      required: false,
      default() {
        return I18n.global.t('cms.autoSaveFeedbackUnsavedChanged');
      }
    },
    busyText: {
      type: String,
      required: false,
      default() {
        return I18n.global.t('cms.autoSaveFeedbackBusy');
      }
    },
    successText: {
      type: String,
      required: false,
      default() {
        return I18n.global.t('cms.autoSaveFeedbackSuccess');
      }
    },
    failedText: {
      type: String,
      required: false,
      default() {
        return I18n.global.t('cms.autoSaveFeedbackFailed');
      }
    },
    status: {
      type: String as PropType<AutoSaveFeedbackStatusEnum>,
      required: true,
    }
  },
  data() {
    return {
      autoSaveFeedbackStatusEnum: AutoSaveFeedbackStatusEnum,
    };
  },
});
