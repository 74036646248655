import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cb_form_image = _resolveComponent("cb-form-image")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_cb_form_image, {
        "can-copy-from-library": "",
        "cb-type": "STICKY_LOGO",
        "doc-type": "LOGO_IMG",
        mode: "edit",
        title: _ctx.$t('stickyLogo'),
        description: _ctx.$t('descriptions.stickyLogo')
      }, null, 8, ["title", "description"])
    ]),
    _: 1
  }))
}