
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {stringHasContent} from "@/helpers/functions/string";

export default defineComponent({
  name: "ButtonContentPageSaveAbstract",
  emits: ["exceptions"],
  props: {
    content: {
      type: String,
      required: false,
    },
    image: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    isWysiwyg: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      isSubmitting: false as boolean,
      savedSuccessfully: false as boolean,
    }
  },
  computed: {
    ...mapGetters('cms_content_page', ["getContentPage"]),
    ...mapGetters('cms_content_page_wysiwyg', {getContentPageWysiwyg: 'getContentPage'}),
    pageId(): string {
      return (this.isWysiwyg) ? this.getContentPageWysiwyg.id : this.getContentPage.id;
    },
    hasContentToUse(): boolean {
      return stringHasContent(this.content) || stringHasContent(this.image) || stringHasContent(this.title);
    },
  },
  methods: {
    ...mapActions('cms_content_page', ["saveContentPageOverviewCbContent", "saveContentPageOverviewCbImage", "saveContentPageOverviewCbTitle"]),
    async saveContentPageOverviewCb() {
      this.isSubmitting = true;
      this.savedSuccessfully = false;

      try {
        if (this.hasContentToUse) {
          if (stringHasContent(this.content)) {
            await this.saveContentPageOverviewCbContent({
              content: this.content,
              page_id: this.pageId
            });
          }
          if (stringHasContent(this.image)) {
            await this.saveContentPageOverviewCbImage({
              image_key: this.image,
              page_id: this.pageId
            });
          }
          if (stringHasContent(this.title)) {
            await this.saveContentPageOverviewCbTitle({
              title: this.title,
              page_id: this.pageId
            });
          }
        }
      } catch (exceptions: any) {
        this.$emit('exceptions', exceptions);
      }

      this.isSubmitting = false;
      this.savedSuccessfully = true;
      setTimeout(() => this.savedSuccessfully = false, 2000);
    }
  }
});
