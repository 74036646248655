
import {defineComponent} from 'vue'
import {UIStateDto} from "@/dtos/UIStateDto";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import {
  PagedLaboProductOverviewResultsRestDtoModel
} from "@/models/api/pharma-pim-labo/PagedLaboProductOverviewResultsRestDtoModel";
import PimLabProductsOverviewTilesItem from "@/components/layouts/pim/PimLabProductsOverviewTilesItem.vue";

export default defineComponent({
  name: "PimLabProductsOverviewTiles",
  emits: ["openOffCanvasProductDetail"],
  components: {
    TilesItem: PimLabProductsOverviewTilesItem,
    BaseSpinner
  },
  props: {
    productsOverview: {
      type: [PagedLaboProductOverviewResultsRestDtoModel, Object],
      required: true
    },
    hasProducts: {
      type: Boolean,
      required: true
    },
    ui: {
      type: UIStateDto,
      required: true
    }
  }
})
