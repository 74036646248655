
import {defineComponent} from "vue";
import ProductSelectionBrandsSelection
  from "@/components/layouts/catalog/product-selection/ProductSelectionBrandsSelection.vue";
import {mapActions} from "vuex";
import ProductSelectionBrandsFilters
  from "@/components/layouts/catalog/product-selection/ProductSelectionBrandsFilters.vue";
import AlertError from "@/components/UI/Bootstrap/Alert/AlertError.vue";
import {Exception} from "@/api/interfaces";
import {SelectionTypeEnum} from "@/api/enums/selection-type-enum";

export default defineComponent({
  name: "ProductSelectionBrands",
  components: {AlertError, ProductSelectionBrandsFilters, ProductSelectionBrandsSelection},
  emits: ["savedSuccessfully"],
  data: () => ({
    exceptions: [] as Exception[],

    isReadyBase: false as Boolean,
    isReadyInclude: false as Boolean,
    isReadyExclude: false as Boolean,
  }),
  mounted() {
    this.reloadContent(false);
  },
  methods: {
    ...mapActions('cpc_mgmt', [
      'resetCurrentPage',
      'searchProductSelectionBrands',
      'saveProductSelectionBrand'
    ]),
    resetAllCurrentPages() {
      this.resetCurrentPage({
        filter_selection_type_code: SelectionTypeEnum.BASE_SELECTION,
        type: 'BRAND'
      });
      this.resetCurrentPage({
        filter_selection_type_code: SelectionTypeEnum.INCLUDE,
        type: 'BRAND'
      });
      this.resetCurrentPage({
        filter_selection_type_code: SelectionTypeEnum.EXCLUDE,
        type: 'BRAND'
      });
    },
    setIsReady(filter_selection_type_code: string, value: boolean) {
      switch (filter_selection_type_code) {
        case SelectionTypeEnum.BASE_SELECTION:
          this.isReadyBase = value;
          break;
        case SelectionTypeEnum.INCLUDE:
          this.isReadyInclude = value;
          break;
        case SelectionTypeEnum.EXCLUDE:
          this.isReadyExclude = value;
          break;
        default:
          throw new Error('Unsupported isReady to search ' + filter_selection_type_code);
      }
    },
    async submitSearch() {
      //When initiating a search, set all pages back to 1
      this.resetAllCurrentPages();
      await this.reloadContent(true);
    },
    async reloadContent(isBaseSelectionNeeded : boolean) {
      try {
        const promises = [
          this.searchBrands(SelectionTypeEnum.INCLUDE),
          this.searchBrands(SelectionTypeEnum.EXCLUDE)
        ];
        if (isBaseSelectionNeeded) {
          promises.unshift(this.searchBrands(SelectionTypeEnum.BASE_SELECTION));
        } else {
          this.setIsReady(SelectionTypeEnum.BASE_SELECTION, true);
        }
        await Promise.all(promises);
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async searchBrands(filter_selection_type_code: string) {
      this.setIsReady(filter_selection_type_code, false);

      try {
        await this.searchProductSelectionBrands({
          filter_selection_type_code: filter_selection_type_code,
        });
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      this.setIsReady(filter_selection_type_code, true);
    },
    async updateBrandItem(brand_code: string, selection_type_code: SelectionTypeEnum) {
      this.exceptions = [];

      try {
        // console.log(`Update brand with code: ${brand_code} (${selection_type_code})`);
        await this.saveProductSelectionBrand({
          brand_code: brand_code,
          selection_type_code: selection_type_code,
        });
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      await this.reloadContent(true);
    },
  }
});
