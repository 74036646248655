import {RestService} from "@/services/rest/RestService";
import {ContentPageSnippetEndpointsApi} from "@/api/pharma-cms-snippet";
import {PagedSnippetInSlotOverviewRestDto, SnippetCreateRestDto, SnippetInSlotOverviewRestDto, SnippetRestDto} from "@/api/pharma-cms-snippet/models";
import {IdResponseRestDto} from "@/api/pharma-cms-content-block/models";
import {CacheService} from "@/services/cache/CacheService";
import {CacheKeyEnum} from "@/services/cache/CacheKeyEnum";
import {ProductCategoryRestDto} from "@/api/pharma-cpc-product/models";
import {ProductRestService} from "@/services/rest/cpc-product/ProductRestService";
import {CustomerContext} from "@/context/CustomerContext";

export class SnippetRestService extends RestService {

    private apiService: ContentPageSnippetEndpointsApi;

    protected constructor() {
        super();

        this.apiService = new ContentPageSnippetEndpointsApi(this.getDpAxiosInstance(), undefined, this.getConfig().cmsUrl);
    }

    public snippetsChanged(): void {
        CacheService.getInstance().invalidate(CacheKeyEnum.PAGED_SNIPPET_IN_SLOT_OVERVIEW);
    }

    public async addSnippetInSlot(snippet_id: string, position_code: string): Promise<void> {
        await this.apiService.addSnippetInSlot(snippet_id, position_code);
        CacheService.getInstance().invalidate(CacheKeyEnum.PAGED_SNIPPET_IN_SLOT_OVERVIEW);
    }

    public async copySnippet(id: string): Promise<void> {
        await this.apiService.copySnippet(id);
        CacheService.getInstance().invalidate(CacheKeyEnum.PAGED_SNIPPET_IN_SLOT_OVERVIEW);
    }

    public async createSnippet(body: SnippetCreateRestDto): Promise<IdResponseRestDto> {
        await this.normalizeFieldsForSave(body.snippet);
        const response = await this.apiService.createSnippet(body);
        CacheService.getInstance().invalidate(CacheKeyEnum.PAGED_SNIPPET_IN_SLOT_OVERVIEW);

        return response.data;
    }

    public async getPositionCodesForSnippet(snippetId: string): Promise<string[]> {
        const allSnippetsInSlotOverviewRestDto = await this.findSnippetsInSlot();
        if (!allSnippetsInSlotOverviewRestDto || !allSnippetsInSlotOverviewRestDto.results?.length) {
            return [];
        }

        const result: string[] = [];
        for (const snippet of allSnippetsInSlotOverviewRestDto.results) {
            if (snippet.id === snippetId) {
                result.push(snippet.slot_position.code);
            }
        }

        return result;
    }

    public async deleteSnippet(id: string): Promise<void> {
        await this.apiService.deleteSnippet(id);
        CacheService.getInstance().invalidate(CacheKeyEnum.PAGED_SNIPPET_IN_SLOT_OVERVIEW);
    }

    public async deleteSnippetInSlot(snippet_id: string, position_code: string): Promise<void> {
        await this.apiService.deleteSnippetInSlot(snippet_id, position_code);
        CacheService.getInstance().invalidate(CacheKeyEnum.PAGED_SNIPPET_IN_SLOT_OVERVIEW);
    }

    public async findSnippetsInSlot(): Promise<PagedSnippetInSlotOverviewRestDto> {
        let result: PagedSnippetInSlotOverviewRestDto =
            CacheService.getInstance().get(CacheKeyEnum.PAGED_SNIPPET_IN_SLOT_OVERVIEW);
        if (result) {
            return result;
        }

        const response = await this.apiService.findSnippetsInSlot();
        result = response.data;
        CacheService.getInstance().set(CacheKeyEnum.PAGED_SNIPPET_IN_SLOT_OVERVIEW, result);

        return result;
    }

    public async updateSnippet(body: SnippetRestDto, id: string): Promise<void> {
        await this.normalizeFieldsForSave(body);
        await this.apiService.updateSnippet(body, id);
        CacheService.getInstance().invalidate(CacheKeyEnum.PAGED_SNIPPET_IN_SLOT_OVERVIEW);
    }

    public async getSnippetInSlot(id: string): Promise<SnippetInSlotOverviewRestDto|null> {
        const allSnippetsInSlotOverviewRestDto = await this.findSnippetsInSlot();
        if (!allSnippetsInSlotOverviewRestDto || !allSnippetsInSlotOverviewRestDto.results?.length) {
            return null;
        }

        for (const snippet of allSnippetsInSlotOverviewRestDto.results) {
            if (snippet.id === id) {
                return snippet;
            }
        }

        return null;
    }

    private async getCategoryPathByCode(categoryCode: string|null|undefined): Promise<string|null> {
        if (!categoryCode) {
            return null;
        }

        const tree: ProductCategoryRestDto =
            await ProductRestService.getInstance().getProductCategoriesTreeCustomer(CustomerContext.getCustomerCode());

        return this.getChildCodeOrNull(categoryCode, tree);
    }

    private getChildCodeOrNull(categoryCode: string, subTree: ProductCategoryRestDto): string|null {
        if (subTree.current_category?.code === categoryCode) {
            return categoryCode;
        }

        if (!subTree.subcategories?.length) {
            return null;
        }

        for (const category of subTree.subcategories) {
            const path: string|null = this.getChildCodeOrNull(categoryCode, category);
            if (path) {
                return subTree.current_category?.code + '|' + path;
            }
        }

        return null;
    }

    private async normalizeFieldsForSave(body: SnippetRestDto): Promise<void> {
        body.context.category_path_by_code = await this.getCategoryPathByCode(body.context.category_code);
        if (!body.context.category_code) {
            body.context.is_include_subcategory = undefined;
        }
    }

}
