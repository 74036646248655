import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_SnippetMetaData = _resolveComponent("SnippetMetaData")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, { "has-build-status-on-page": "" }, {
    default: _withCtx(() => [
      _createVNode(_component_BaseTitle, {
        title: _ctx.$t('snippet'),
        mode: this.mode,
        "help-text": _ctx.$t('descriptionSnippet'),
        "back-route": {name: 'snippets'}
      }, null, 8, ["title", "mode", "help-text"]),
      _createVNode(_component_SnippetMetaData, {
        mode: _ctx.mode,
        id: _ctx.id
      }, null, 8, ["mode", "id"])
    ]),
    _: 1
  }))
}