
import {defineComponent} from 'vue'
import ItemIcons from "@/components/layouts/pim/PimProductsOverviewItemIcons.vue";
import {
  CatalogProductOverviewRestDtoModel
} from "@/models/api/pharma-cpc-product-mgmt/CatalogProductOverviewRestDtoModel";
import {ProductCategorizationRestService} from "@/services/rest/cpc-product-mgmt/ProductSortWeightRestService";

export default defineComponent({
  name: "CatalogProductsOverviewTableItem",
  components: {ItemIcons},
  emits: ['dataChanged'],
  props: {
    product: {
      type: [CatalogProductOverviewRestDtoModel, Object],
      required: true
    },
    onlyProductsWithCustomWeights: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    openProductDetail(): void {
      this.$router.push({name: 'catalog-products-detail', params: {product_code: this.product.product_code}});
    },
    async addSortWeight(): Promise<void> {
      await ProductCategorizationRestService.getInstance().createCustomerProductSortWeight(this.product.product_code);
      this.$emit('dataChanged');
    },
    async deleteSortWeight(): Promise<void> {
      await ProductCategorizationRestService.getInstance().deleteCustomerProductSortWeight(this.product.product_code);
      this.$emit('dataChanged');
    },
    async moveToTop(): Promise<void> {
      await ProductCategorizationRestService.getInstance().moveCustomerProductSortWeightToTop(this.product.product_code);
      this.$emit('dataChanged');
    },
    async moveToBottom(): Promise<void> {
      await ProductCategorizationRestService.getInstance().moveCustomerProductSortWeightToBottom(this.product.product_code);
      this.$emit('dataChanged');
    },
  }
})
