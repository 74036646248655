
import {defineComponent} from "vue";
import {ProductCategoryRestDto} from "@/api/pharma-cpc-product/models";
import ProductCategoriesTree from "@/components/UI/catalog/ProductCategoriesTree.vue";
import {default as Modal} from "bootstrap/js/dist/modal";
import BaseModal from "@/components/UI/Bootstrap/Modal/BaseModal.vue";
import BaseModalButton from "@/components/UI/Bootstrap/Modal/BaseModalButton.vue";
import SelectCategoriesSelected from "@/components/layouts/catalog/SelectCategoriesSelected.vue";
import {generateDomId} from "@/helpers/functions/string";
import {ProductRestService} from "@/services/rest/cpc-product/ProductRestService";

export default defineComponent({
  name: "SelectCategories",
  components: {
    SelectCategoriesSelected,
    BaseModalButton, BaseModal, ProductCategoriesTree
  },
  emits: ["addCategory", "removeCategory"],
  props: {
    selectedProductCategories: {
      type: Array,
      required: true,
    },
    btnClass: {
      type: String,
      required: false,
      default: 'btn btn-icon btn-primary w-100 mb-3'
    },
    btnIcon: {
      type: String,
      required: false,
      default: 'search'
    },
    catalogCustomerCode: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      selectCategoriesModal: null as Modal | null,
      selectCategoriesModalId: generateDomId('select-categories-modal') as string,
      categoriesTree: null as ProductCategoryRestDto | null,
    }
  },
  mounted() {
    this.selectCategoriesModal = new Modal('#' + this.selectCategoriesModalId);
    this.reloadContent();
  },
  unmounted() {
    this.selectCategoriesModal?.dispose();
  },
  computed: {
    formattedSelectedProductCategories(): string[] {
      let formattedSelectedProductCategories = [] as string[];

      this.selectedProductCategories.forEach((selectedProductCategory: any) => {
        if (typeof selectedProductCategory === 'string') {
          formattedSelectedProductCategories.push(selectedProductCategory);
        } else if (typeof selectedProductCategory === 'object' && 'category_code' in selectedProductCategory) {
          formattedSelectedProductCategories.push(selectedProductCategory.category_code as string);
        }
      });

      return formattedSelectedProductCategories;
    },
  },
  methods: {
    addCategory(category_code: string) {
      this.$emit('addCategory', category_code);
    },
    removeCategory(category_code: string) {
      this.$emit('removeCategory', category_code);
    },
    async reloadContent() {
      this.categoriesTree = await ProductRestService.getInstance().getProductCategoriesTreeCustomer(this.catalogCustomerCode);
    }
  }
});
