import {
    FullContentPageRestDto, PageLibraryOverviewRestDto
} from "@/api/pharma-cms-content-page/models";
import {ContentPagesLibraryEndpointsApiService} from "@/main";
import {DpException} from "@/exception/DpException";
import {StatusEnum} from "@/api/enums/status-enum";

export default {
    state() {
        return {
            cp_libraries_for_content_provider: [] as PageLibraryOverviewRestDto[],
            lib_cps: {} as Object,
        }
    },
    actions: {
        async searchContentPageLibrariesForContentProvider(context: any) {
            const response = await ContentPagesLibraryEndpointsApiService.pageLibraryOverviewRestDto();

            context.commit("setContentPageLibrariesForContentProvider", response.data);
        },
        async searchLibContentPages(context: any, lib_code: string) {
            const filter_status_code = StatusEnum.PUBLISHED;

            const response = await ContentPagesLibraryEndpointsApiService.getLibraryContentBlocksContentPage(lib_code, undefined, filter_status_code);

            context.commit("setLibContentPages", {
                lib_code: lib_code,
                lib: response.data
            });
        }
    },
    mutations: {
        setContentPageLibrariesForContentProvider(state: any, cp_libraries: PageLibraryOverviewRestDto[]) {
            state.cp_libraries_for_content_provider = cp_libraries;
        },
        setLibContentPages(state: any, payload: any) {
            // todo maybe add validation for supported types

            state.lib_cps[payload.lib_code] = payload.lib;
        },
    },
    getters: {
        getContentPageLibrariesForContentProvider(state: any): PageLibraryOverviewRestDto[] {
            return state.cp_libraries_for_content_provider;
        },
        getContentPageLibraryName: (state: any) => (lib_code: string): string => {
            return state.cp_libraries_for_content_provider.find((cp_library: PageLibraryOverviewRestDto) => cp_library.lib_code === lib_code).lib_name;
        },
        getContentPageLibraryType: (state: any) => (lib_code: string): string => {
            return state.cp_libraries_for_content_provider.find((cp_library: PageLibraryOverviewRestDto) => cp_library.lib_code === lib_code).page_type_code;
        },
        getLibContentPages: (state: any) => (lib_code: string): FullContentPageRestDto[] => {
            // todo maybe add validation for supported types

            try {
                return state.lib_cps[lib_code];
            } catch (e) {
                console.log(e);
                throw [new DpException('Unsupported cp library to get ' + lib_code)];
            }
        },
    }
}
