/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Content Page API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ContentPageRowCreateRestDto } from '../models';
import { ContentPageRowRestDto } from '../models';
import { ContentVisibilityRuleForElementRestDto } from '../models';
import { ElementTargetPositionRestDto } from '../models';
import { FullContentPageRowRestDto } from '../models';
import { IdResponseRestDto } from '../models';
/**
 * ContentPageRowEndpointsApi - axios parameter creator
 * @export
 */
export const ContentPageRowEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.CPR.11 Copy this row to the next position.
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} row_id The unique identifier of this content page row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyContentPageRow: async (page_id: string, row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page_id' is not null or undefined
            if (page_id === null || page_id === undefined) {
                throw new RequiredError('page_id','Required parameter page_id was null or undefined when calling copyContentPageRow.');
            }
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling copyContentPageRow.');
            }
            const localVarPath = `/content_page/v1/content_page/{page_id}/row/{row_id}/copy`
                .replace(`{${"page_id"}}`, encodeURIComponent(String(page_id)))
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPR.02 Create row for content page.
         * @param {ContentPageRowCreateRestDto} body 
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContentPageRow: async (body: ContentPageRowCreateRestDto, page_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createContentPageRow.');
            }
            // verify required parameter 'page_id' is not null or undefined
            if (page_id === null || page_id === undefined) {
                throw new RequiredError('page_id','Required parameter page_id was null or undefined when calling createContentPageRow.');
            }
            const localVarPath = `/content_page/v1/content_page/{page_id}/row`
                .replace(`{${"page_id"}}`, encodeURIComponent(String(page_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPR.05 Delete content page row.
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} row_id The unique identifier of this content page row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContentPageRow: async (page_id: string, row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page_id' is not null or undefined
            if (page_id === null || page_id === undefined) {
                throw new RequiredError('page_id','Required parameter page_id was null or undefined when calling deleteContentPageRow.');
            }
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling deleteContentPageRow.');
            }
            const localVarPath = `/content_page/v1/content_page/{page_id}/row/{row_id}`
                .replace(`{${"page_id"}}`, encodeURIComponent(String(page_id)))
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPR.01 Get all rows for content pages.
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findContentPageRows: async (page_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page_id' is not null or undefined
            if (page_id === null || page_id === undefined) {
                throw new RequiredError('page_id','Required parameter page_id was null or undefined when calling findContentPageRows.');
            }
            const localVarPath = `/content_page/v1/content_page/{page_id}/row`
                .replace(`{${"page_id"}}`, encodeURIComponent(String(page_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPR.04 Get content page row.
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} row_id The unique identifier of this content page row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentPageRow: async (page_id: string, row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page_id' is not null or undefined
            if (page_id === null || page_id === undefined) {
                throw new RequiredError('page_id','Required parameter page_id was null or undefined when calling getContentPageRow.');
            }
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling getContentPageRow.');
            }
            const localVarPath = `/content_page/v1/content_page/{page_id}/row/{row_id}`
                .replace(`{${"page_id"}}`, encodeURIComponent(String(page_id)))
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPR.13 Get the visibility rule of this page row.
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} row_id The unique identifier of this content page row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentPageRowVisibilityRule: async (page_id: string, row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page_id' is not null or undefined
            if (page_id === null || page_id === undefined) {
                throw new RequiredError('page_id','Required parameter page_id was null or undefined when calling getContentPageRowVisibilityRule.');
            }
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling getContentPageRowVisibilityRule.');
            }
            const localVarPath = `/content_page/v1/content_page/{page_id}/row/{row_id}/visibility_rule`
                .replace(`{${"page_id"}}`, encodeURIComponent(String(page_id)))
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPR.09 Move row one position down.
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} row_id The unique identifier of this content page row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveContentPageRowDown: async (page_id: string, row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page_id' is not null or undefined
            if (page_id === null || page_id === undefined) {
                throw new RequiredError('page_id','Required parameter page_id was null or undefined when calling moveContentPageRowDown.');
            }
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling moveContentPageRowDown.');
            }
            const localVarPath = `/content_page/v1/content_page/{page_id}/row/{row_id}/move/down`
                .replace(`{${"page_id"}}`, encodeURIComponent(String(page_id)))
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPR.10 Move row to position between two other rows.
         * @param {ElementTargetPositionRestDto} body 
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} row_id The unique identifier of this content page row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveContentPageRowTo: async (body: ElementTargetPositionRestDto, page_id: string, row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling moveContentPageRowTo.');
            }
            // verify required parameter 'page_id' is not null or undefined
            if (page_id === null || page_id === undefined) {
                throw new RequiredError('page_id','Required parameter page_id was null or undefined when calling moveContentPageRowTo.');
            }
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling moveContentPageRowTo.');
            }
            const localVarPath = `/content_page/v1/content_page/{page_id}/row/{row_id}/move/to`
                .replace(`{${"page_id"}}`, encodeURIComponent(String(page_id)))
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPR.07 Move row to the bottom position.
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} row_id The unique identifier of this content page row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveContentPageRowToBottom: async (page_id: string, row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page_id' is not null or undefined
            if (page_id === null || page_id === undefined) {
                throw new RequiredError('page_id','Required parameter page_id was null or undefined when calling moveContentPageRowToBottom.');
            }
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling moveContentPageRowToBottom.');
            }
            const localVarPath = `/content_page/v1/content_page/{page_id}/row/{row_id}/move/to_bottom`
                .replace(`{${"page_id"}}`, encodeURIComponent(String(page_id)))
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPR.06 Move row to the top position.
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} row_id The unique identifier of this content page row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveContentPageRowToTop: async (page_id: string, row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page_id' is not null or undefined
            if (page_id === null || page_id === undefined) {
                throw new RequiredError('page_id','Required parameter page_id was null or undefined when calling moveContentPageRowToTop.');
            }
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling moveContentPageRowToTop.');
            }
            const localVarPath = `/content_page/v1/content_page/{page_id}/row/{row_id}/move/to_top`
                .replace(`{${"page_id"}}`, encodeURIComponent(String(page_id)))
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPR.08 Move row one position up.
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} row_id The unique identifier of this content page row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveContentPageRowUp: async (page_id: string, row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page_id' is not null or undefined
            if (page_id === null || page_id === undefined) {
                throw new RequiredError('page_id','Required parameter page_id was null or undefined when calling moveContentPageRowUp.');
            }
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling moveContentPageRowUp.');
            }
            const localVarPath = `/content_page/v1/content_page/{page_id}/row/{row_id}/move/up`
                .replace(`{${"page_id"}}`, encodeURIComponent(String(page_id)))
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPR.03 Update content page row.
         * @param {ContentPageRowRestDto} body 
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} row_id The unique identifier of this content page row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentPageRow: async (body: ContentPageRowRestDto, page_id: string, row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateContentPageRow.');
            }
            // verify required parameter 'page_id' is not null or undefined
            if (page_id === null || page_id === undefined) {
                throw new RequiredError('page_id','Required parameter page_id was null or undefined when calling updateContentPageRow.');
            }
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling updateContentPageRow.');
            }
            const localVarPath = `/content_page/v1/content_page/{page_id}/row/{row_id}`
                .replace(`{${"page_id"}}`, encodeURIComponent(String(page_id)))
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPR.12 Update the visibility rule of this page row.
         * @param {ContentVisibilityRuleForElementRestDto} body 
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} row_id The unique identifier of this content page row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentPageRowVisibilityRule: async (body: ContentVisibilityRuleForElementRestDto, page_id: string, row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateContentPageRowVisibilityRule.');
            }
            // verify required parameter 'page_id' is not null or undefined
            if (page_id === null || page_id === undefined) {
                throw new RequiredError('page_id','Required parameter page_id was null or undefined when calling updateContentPageRowVisibilityRule.');
            }
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling updateContentPageRowVisibilityRule.');
            }
            const localVarPath = `/content_page/v1/content_page/{page_id}/row/{row_id}/visibility_rule`
                .replace(`{${"page_id"}}`, encodeURIComponent(String(page_id)))
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentPageRowEndpointsApi - functional programming interface
 * @export
 */
export const ContentPageRowEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.CPR.11 Copy this row to the next position.
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} row_id The unique identifier of this content page row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyContentPageRow(_axios: AxiosInstance, page_id: string, row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentPageRowEndpointsApiAxiosParamCreator(configuration).copyContentPageRow(page_id, row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPR.02 Create row for content page.
         * @param {ContentPageRowCreateRestDto} body 
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContentPageRow(_axios: AxiosInstance, body: ContentPageRowCreateRestDto, page_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResponseRestDto>> {
            const localVarAxiosArgs = await ContentPageRowEndpointsApiAxiosParamCreator(configuration).createContentPageRow(body, page_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPR.05 Delete content page row.
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} row_id The unique identifier of this content page row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContentPageRow(_axios: AxiosInstance, page_id: string, row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentPageRowEndpointsApiAxiosParamCreator(configuration).deleteContentPageRow(page_id, row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPR.01 Get all rows for content pages.
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findContentPageRows(_axios: AxiosInstance, page_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FullContentPageRowRestDto>>> {
            const localVarAxiosArgs = await ContentPageRowEndpointsApiAxiosParamCreator(configuration).findContentPageRows(page_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPR.04 Get content page row.
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} row_id The unique identifier of this content page row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentPageRow(_axios: AxiosInstance, page_id: string, row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentPageRowRestDto>> {
            const localVarAxiosArgs = await ContentPageRowEndpointsApiAxiosParamCreator(configuration).getContentPageRow(page_id, row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPR.13 Get the visibility rule of this page row.
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} row_id The unique identifier of this content page row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentPageRowVisibilityRule(_axios: AxiosInstance, page_id: string, row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentVisibilityRuleForElementRestDto>> {
            const localVarAxiosArgs = await ContentPageRowEndpointsApiAxiosParamCreator(configuration).getContentPageRowVisibilityRule(page_id, row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPR.09 Move row one position down.
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} row_id The unique identifier of this content page row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveContentPageRowDown(_axios: AxiosInstance, page_id: string, row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentPageRowEndpointsApiAxiosParamCreator(configuration).moveContentPageRowDown(page_id, row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPR.10 Move row to position between two other rows.
         * @param {ElementTargetPositionRestDto} body 
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} row_id The unique identifier of this content page row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveContentPageRowTo(_axios: AxiosInstance, body: ElementTargetPositionRestDto, page_id: string, row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentPageRowEndpointsApiAxiosParamCreator(configuration).moveContentPageRowTo(body, page_id, row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPR.07 Move row to the bottom position.
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} row_id The unique identifier of this content page row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveContentPageRowToBottom(_axios: AxiosInstance, page_id: string, row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentPageRowEndpointsApiAxiosParamCreator(configuration).moveContentPageRowToBottom(page_id, row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPR.06 Move row to the top position.
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} row_id The unique identifier of this content page row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveContentPageRowToTop(_axios: AxiosInstance, page_id: string, row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentPageRowEndpointsApiAxiosParamCreator(configuration).moveContentPageRowToTop(page_id, row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPR.08 Move row one position up.
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} row_id The unique identifier of this content page row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveContentPageRowUp(_axios: AxiosInstance, page_id: string, row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentPageRowEndpointsApiAxiosParamCreator(configuration).moveContentPageRowUp(page_id, row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPR.03 Update content page row.
         * @param {ContentPageRowRestDto} body 
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} row_id The unique identifier of this content page row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContentPageRow(_axios: AxiosInstance, body: ContentPageRowRestDto, page_id: string, row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentPageRowEndpointsApiAxiosParamCreator(configuration).updateContentPageRow(body, page_id, row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CPR.12 Update the visibility rule of this page row.
         * @param {ContentVisibilityRuleForElementRestDto} body 
         * @param {string} page_id The unique identifier of the content page this row belongs to.
         * @param {string} row_id The unique identifier of this content page row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContentPageRowVisibilityRule(_axios: AxiosInstance, body: ContentVisibilityRuleForElementRestDto, page_id: string, row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentPageRowEndpointsApiAxiosParamCreator(configuration).updateContentPageRowVisibilityRule(body, page_id, row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ContentPageRowEndpointsApi - object-oriented interface
 * @export
 * @class ContentPageRowEndpointsApi
 * @extends {BaseAPI}
 */
export class ContentPageRowEndpointsApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.CMS.CPR.11 Copy this row to the next position.
     * @param {string} page_id The unique identifier of the content page this row belongs to.
     * @param {string} row_id The unique identifier of this content page row.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageRowEndpointsApi
     */
     public copyContentPageRow(page_id: string, row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentPageRowEndpointsApiFp(this.configuration).copyContentPageRow(this.axios, page_id, row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CPR.02 Create row for content page.
     * @param {ContentPageRowCreateRestDto} body 
     * @param {string} page_id The unique identifier of the content page this row belongs to.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageRowEndpointsApi
     */
     public createContentPageRow(body: ContentPageRowCreateRestDto, page_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentPageRowEndpointsApiFp(this.configuration).createContentPageRow(this.axios, body, page_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CPR.05 Delete content page row.
     * @param {string} page_id The unique identifier of the content page this row belongs to.
     * @param {string} row_id The unique identifier of this content page row.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageRowEndpointsApi
     */
     public deleteContentPageRow(page_id: string, row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentPageRowEndpointsApiFp(this.configuration).deleteContentPageRow(this.axios, page_id, row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CPR.01 Get all rows for content pages.
     * @param {string} page_id The unique identifier of the content page this row belongs to.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageRowEndpointsApi
     */
     public findContentPageRows(page_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentPageRowEndpointsApiFp(this.configuration).findContentPageRows(this.axios, page_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CPR.04 Get content page row.
     * @param {string} page_id The unique identifier of the content page this row belongs to.
     * @param {string} row_id The unique identifier of this content page row.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageRowEndpointsApi
     */
     public getContentPageRow(page_id: string, row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentPageRowEndpointsApiFp(this.configuration).getContentPageRow(this.axios, page_id, row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CPR.13 Get the visibility rule of this page row.
     * @param {string} page_id The unique identifier of the content page this row belongs to.
     * @param {string} row_id The unique identifier of this content page row.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageRowEndpointsApi
     */
     public getContentPageRowVisibilityRule(page_id: string, row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentPageRowEndpointsApiFp(this.configuration).getContentPageRowVisibilityRule(this.axios, page_id, row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CPR.09 Move row one position down.
     * @param {string} page_id The unique identifier of the content page this row belongs to.
     * @param {string} row_id The unique identifier of this content page row.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageRowEndpointsApi
     */
     public moveContentPageRowDown(page_id: string, row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentPageRowEndpointsApiFp(this.configuration).moveContentPageRowDown(this.axios, page_id, row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CPR.10 Move row to position between two other rows.
     * @param {ElementTargetPositionRestDto} body 
     * @param {string} page_id The unique identifier of the content page this row belongs to.
     * @param {string} row_id The unique identifier of this content page row.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageRowEndpointsApi
     */
     public moveContentPageRowTo(body: ElementTargetPositionRestDto, page_id: string, row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentPageRowEndpointsApiFp(this.configuration).moveContentPageRowTo(this.axios, body, page_id, row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CPR.07 Move row to the bottom position.
     * @param {string} page_id The unique identifier of the content page this row belongs to.
     * @param {string} row_id The unique identifier of this content page row.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageRowEndpointsApi
     */
     public moveContentPageRowToBottom(page_id: string, row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentPageRowEndpointsApiFp(this.configuration).moveContentPageRowToBottom(this.axios, page_id, row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CPR.06 Move row to the top position.
     * @param {string} page_id The unique identifier of the content page this row belongs to.
     * @param {string} row_id The unique identifier of this content page row.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageRowEndpointsApi
     */
     public moveContentPageRowToTop(page_id: string, row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentPageRowEndpointsApiFp(this.configuration).moveContentPageRowToTop(this.axios, page_id, row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CPR.08 Move row one position up.
     * @param {string} page_id The unique identifier of the content page this row belongs to.
     * @param {string} row_id The unique identifier of this content page row.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageRowEndpointsApi
     */
     public moveContentPageRowUp(page_id: string, row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentPageRowEndpointsApiFp(this.configuration).moveContentPageRowUp(this.axios, page_id, row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CPR.03 Update content page row.
     * @param {ContentPageRowRestDto} body 
     * @param {string} page_id The unique identifier of the content page this row belongs to.
     * @param {string} row_id The unique identifier of this content page row.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageRowEndpointsApi
     */
     public updateContentPageRow(body: ContentPageRowRestDto, page_id: string, row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentPageRowEndpointsApiFp(this.configuration).updateContentPageRow(this.axios, body, page_id, row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CPR.12 Update the visibility rule of this page row.
     * @param {ContentVisibilityRuleForElementRestDto} body 
     * @param {string} page_id The unique identifier of the content page this row belongs to.
     * @param {string} row_id The unique identifier of this content page row.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentPageRowEndpointsApi
     */
     public updateContentPageRowVisibilityRule(body: ContentVisibilityRuleForElementRestDto, page_id: string, row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentPageRowEndpointsApiFp(this.configuration).updateContentPageRowVisibilityRule(this.axios, body, page_id, row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
