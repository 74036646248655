/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-PIM Pharmaphoto2 API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CodeDescriptionRestDto } from '../models';
/**
 * Pp2ProductBrandAPIApi - axios parameter creator
 * @export
 */
export const Pp2ProductBrandAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for retrieving the product brands.
         * @summary REST.API.PIM.BRA.01 Find the product brands
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {string} [search_name] Filter the results so that the given value occurs in the name of the brand. (Case insensitive)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPp2ProductBrands: async (x_dp_customer_code?: string|null, x_dp_language?: string|null, search_name?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/pp2/v1/product_brand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (!!search_name || typeof(search_name) === "boolean") {
                localVarQueryParameter['search_name'] = search_name;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Pp2ProductBrandAPIApi - functional programming interface
 * @export
 */
export const Pp2ProductBrandAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for retrieving the product brands.
         * @summary REST.API.PIM.BRA.01 Find the product brands
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {string} [search_name] Filter the results so that the given value occurs in the name of the brand. (Case insensitive)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPp2ProductBrands(_axios: AxiosInstance, x_dp_customer_code?: string|null, x_dp_language?: string|null, search_name?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CodeDescriptionRestDto>>> {
            const localVarAxiosArgs = await Pp2ProductBrandAPIApiAxiosParamCreator(configuration).findPp2ProductBrands(x_dp_customer_code, x_dp_language, search_name, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * Pp2ProductBrandAPIApi - object-oriented interface
 * @export
 * @class Pp2ProductBrandAPIApi
 * @extends {BaseAPI}
 */
export class Pp2ProductBrandAPIApi extends BaseAPI {
    /**
     * API endpoint responsible for retrieving the product brands.
     * @summary REST.API.PIM.BRA.01 Find the product brands
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {string} [search_name] Filter the results so that the given value occurs in the name of the brand. (Case insensitive)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Pp2ProductBrandAPIApi
     */
     public findPp2ProductBrands(x_dp_customer_code?: string|null, x_dp_language?: string|null, search_name?: string|null, options?: any) {
        return Pp2ProductBrandAPIApiFp(this.configuration).findPp2ProductBrands(this.axios, x_dp_customer_code, x_dp_language, search_name, options).then((request) => request(this.axios, this.basePath));
    }

}
