import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "fs-5 mb-0" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "row d-flex justify-content-start align-items-center" }
const _hoisted_4 = { class: "fs-5 mb-0" }
const _hoisted_5 = { class: "row bottom" }
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = { class: "d-inline-block pt-4 border-top border-2 border-light" }
const _hoisted_8 = {
  type: "submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_AlertError2 = _resolveComponent("AlertError2")!
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_BaseCardHeader = _resolveComponent("BaseCardHeader")!
  const _component_BaseCardBody = _resolveComponent("BaseCardBody")!
  const _component_BaseCard = _resolveComponent("BaseCard")!
  const _component_InputCheckboxBoolean = _resolveComponent("InputCheckboxBoolean")!
  const _component_input_text_editor = _resolveComponent("input-text-editor")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_dp_form = _resolveComponent("dp-form")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_BaseTitle, {
        title: _ctx.title,
        mb: 5,
        "show-refresh-button": "",
        onRefresh: _ctx.reloadContent
      }, null, 8, ["title", "onRefresh"]),
      _createVNode(_component_AlertError2, {
        error: _ctx.pageUI.getError()
      }, null, 8, ["error"]),
      _createVNode(_component_BaseSpinner, {
        spin: !_ctx.pageUI.getIsReady()
      }, null, 8, ["spin"]),
      _createElementVNode("div", null, [
        (_ctx.pageUI.getIsReady())
          ? (_openBlock(), _createBlock(_component_dp_form, {
              key: 0,
              as: "form",
              onSubmit: _ctx.submitData,
              novalidate: ""
            }, {
              default: _withCtx(({ errors }) => [
                _createVNode(_component_BaseCard, { class: "fallback text-bg-light border-light mb-3" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BaseCardHeader, { class: "p-2" }, {
                      default: _withCtx(() => [
                        _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('defaultContent')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_BaseCardBody, { class: "p-2" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "enumeration_fallback_content",
                          innerHTML: _ctx.fallbackModel.content.nl
                        }, null, 8, _hoisted_2)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_InputCheckboxBoolean, {
                      modelValue: _ctx.overrideFallback,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.overrideFallback) = $event)),
                      errors: errors,
                      name: "override_enumeration_content"
                    }, null, 8, ["modelValue", "errors"])
                  ]),
                  _createElementVNode("div", null, _toDisplayString(_ctx.$t('overwriteWithOwnContent')), 1)
                ]),
                (_ctx.overrideFallback)
                  ? (_openBlock(), _createBlock(_component_BaseCard, {
                      key: 0,
                      class: "fallback text-bg-light border-light mb-3"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BaseCardHeader, { class: "p-2" }, {
                          default: _withCtx(() => [
                            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t('ownContent')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_BaseCardBody, { class: "p-2" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_input_text_editor, {
                              errors: errors,
                              modelValue: _ctx.detailModel.content.nl,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.detailModel.content.nl) = $event)),
                              name: "enumeration_content"
                            }, null, 8, ["errors", "modelValue"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                _createElementVNode("section", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("button", _hoisted_8, _toDisplayString(_ctx.$t('save')), 1),
                      _createVNode(_component_router_link, {
                        class: "ms-3 btn btn-outline-primary",
                        to: {name: 'delivery-method-texts',}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["onSubmit"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}