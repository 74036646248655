
import {Exception} from '@/api/interfaces';
import {defineComponent} from "vue";
import {
  SimpleTextContentRestDto,
  TextContentRestDto,
} from "@/api/pharma-cms-content-block/models";
import {StatusEnum} from "@/api/enums/status-enum";
import {mapActions, mapGetters} from 'vuex';
import CbForm from "@/components/layouts/content-blocks/forms/CbForm.vue";

export default defineComponent({
  name: "CbFormText",
  components: {CbForm},
  props: {
    canCopyFromLibrary: {
      type: Boolean,
      required: false,
      default: false,
    },
    canFallback: {
      type: Boolean,
      required: false,
      default: false,
    },
    canRestoreDefaultValues: {
      type: Boolean,
      required: false,
      default: false,
    },
    cbId: {
      type: String,
      required: false,
    },
    cbType: {
      type: String,
      required: true
    },
    contentLibraryCode: {
      type: String,
      required: false,
    },
    inputType: {
      type: String,
      required: false,
      default: 'text',
    },
    isLibrary: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNotRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPublishable: {
      type: Boolean,
      required: false,
      default: false,
    },
    mode: {
      type: String,
      required: true,
    },
    returnRoute: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    fieldTitle: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    description2: {
      type: String,
      required: false,
    },
    description3: {
      type: String,
      required: false,
    },
    description4: {
      type: String,
      required: false,
    },
    cachingDelayMinutes: {
      type: Number,
      required: false,
      default: null,
    }
  },
  data: () => ({
    error: null,
    exceptions: [] as Exception[],
    isReady: false as Boolean,
    savedSuccessfully: false as Boolean,
  }),
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cms_content_block', [
      'getSimpleContentBlockText',
      'getNormalContentBlockText',
    ]),
    contentBlock(): TextContentRestDto | SimpleTextContentRestDto {
      if (this.isLibrary) {
        return this.getNormalContentBlockText(this.cbType) as TextContentRestDto;
      } else {
        return this.getSimpleContentBlockText(this.cbType) as SimpleTextContentRestDto;
      }
    },
    isCopiedContent(): Boolean {
      //TODO: create logic for determining whether cb is copied or not
      return false;
    },
    statusOptions(): Object[] {
      return [
        {
          code: StatusEnum.PUBLISHED,
          description: this.$t('published'),
        },
        {
          code: StatusEnum.NOT_PUBLISHED,
          description: this.$t('notPublished'),
        },
      ];
    },
  },
  methods: {
    ...mapActions('cms_content_block', [
      'clearNormalContentBlock',
      'searchSimpleContentBlockText',
      'searchStandardSimpleContentBlockText',
      'saveSimpleContentBlockText',
    ]),
    copiedLibContentBlock() {
      this.reloadContent().then(() => {
        this.savedSuccessfully = true;
        setTimeout(() => this.savedSuccessfully = false, 2000);
      });
    },
    async submitData() {
      this.exceptions = [];

      try {
        if (this.isLibrary) {
          if (this.mode === 'new') {
            //TODO: integrate createLibContentBlockText when needed
          } else {
            //TODO: integrate saveNormalContentBlockText when needed
          }
        } else {
          await this.saveSimpleContentBlockText(this.cbType);
        }

        await this.afterSave();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async reloadContent() {
      this.isReady = false;
      this.exceptions = [];

      try {
        if (this.isLibrary) {
          if (this.mode === 'new') {
            this.clearNormalContentBlock(this.cbType);
          } else {
            //TODO: integrate searchNormalContentBlockText when needed
          }
        } else {
          await this.searchSimpleContentBlockText({
            type: this.cbType,
            fallback_allowed: this.canFallback,
          });
        }
      } catch (exceptions: any) {
        this.exceptions = exceptions;
        this.isReady = true;
      }

      this.isReady = true;
    },
    async restoreDefaultValues() {
      this.exceptions = [];

      try {
        await this.searchStandardSimpleContentBlockText(this.cbType);
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async afterSave() {
      if (this.returnRoute) {
        await this.$router.push({name: this.returnRoute});
      } else {
        this.reloadContent().then(() => {
          this.savedSuccessfully = true;
          setTimeout(() => this.savedSuccessfully = false, 2000);
        });
      }
    },
  }
});
