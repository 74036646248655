
import {defineComponent} from "vue";
import {ProductCategoryOverviewRestDto} from "@/api/pharma-cpc-product/models";
import {default as Tooltip} from 'bootstrap/js/dist/tooltip';
import {CodeDescriptionRestDto} from "@/api/pharma-cpc-mgmt/models";
import {ProductRestService} from "@/services/rest/cpc-product/ProductRestService";

export default defineComponent({
  name: "SelectCategoriesSelectedItem",
  emits: ["removeCategory"],
  props: {
    selectedProductCategoryCode: {
      type: String,
      required: true,
    },
    catalogCustomerCode: {
      type: String,
      required: false,
    },
    tooltipId: {
      type: String,
      required: false,
      default: 'tt'
    },
  },
  data() {
    return {
      refTooltip: `ref_${this.tooltipId}_${this.selectedProductCategoryCode}` as string,
      tooltip: {} as Tooltip,
      productCategories: [] as ProductCategoryOverviewRestDto[],
    }
  },
  mounted() {
    new Tooltip(this.$refs[this.refTooltip] as Element);
    this.tooltip = Tooltip.getInstance('#' + this.refTooltip) as Tooltip;
    this.reloadContent();
  },
  computed: {
    categoryInfo(): ProductCategoryOverviewRestDto {
      return this.productCategories.find(
        (category: ProductCategoryOverviewRestDto) => category.current_category.code === this.selectedProductCategoryCode) as ProductCategoryOverviewRestDto;
    },
    tooltipContent(): string {
      if (!this.categoryInfo) {
        return '';
      }

      let tooltipContent = '' as string;
      this.categoryInfo.parent_categories.forEach((parent_category: CodeDescriptionRestDto) => {
        //Exclude category "All categories"
        if (parent_category.code !== 'e324df81-ef0f-11eb-b460-00505685df1b') {
          tooltipContent += parent_category.description + ' / ';
        }
      });

      tooltipContent += '<strong>' + this.categoryInfo.current_category.description + '</strong>';

      return tooltipContent;
    }
  },
  methods: {
    removeCategory(category_code: string) {
      this.tooltip?.dispose();
      this.$emit('removeCategory', category_code);
    },
    async reloadContent() {
      this.productCategories = await ProductRestService.getInstance().getAllCategoriesCustomer(this.catalogCustomerCode);
    }
  }
});
