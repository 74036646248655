import {ProductFilterTypeEnum} from "@/models/enum/ProductFilterTypeEnum";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";

export class ProductFilterPickerPayload {
    code: string | null;
    named_product_filter_options: CodeDescriptionRestDto[];
    type: ProductFilterTypeEnum;

    constructor(product_filter_code: string | null, named_product_filter_options: CodeDescriptionRestDto[]) {
        this.code = product_filter_code;
        this.named_product_filter_options = named_product_filter_options;
        this.type = this.detectProductFilterType();
    }

    public static createWithDefaults(): ProductFilterPickerPayload {
        return new ProductFilterPickerPayload(null, []);
    }

    public static cloneFrom(payload: ProductFilterPickerPayload): ProductFilterPickerPayload {
        return new ProductFilterPickerPayload(payload.code, payload.named_product_filter_options);
    }

    protected detectProductFilterType(): ProductFilterTypeEnum {
        for (let i = 0; i < this.named_product_filter_options.length; i++) {
            if (this.named_product_filter_options[i].code === this.code) {
                return ProductFilterTypeEnum.NAMED;
            }
        }
        return ProductFilterTypeEnum.CUSTOM;
    }
}
