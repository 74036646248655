import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row discount-filter-trademarks" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectTrademarks = _resolveComponent("SelectTrademarks")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SelectTrademarks, {
      "selected-trademark-codes": this.productTrademarkFilterCodes,
      class: "col-8",
      onAddTrademark: this.addTrademark,
      onRemoveTrademark: this.removeTrademark
    }, null, 8, ["selected-trademark-codes", "onAddTrademark", "onRemoveTrademark"])
  ]))
}