
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {Exception} from "@/api/interfaces";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import {ContentBlockInRowRestDtoModel} from "@/models/ContentBlockInRowRestDtoModel";
import {ContentBlockInRowCreateRestDtoModel} from "@/models/ContentBlockInRowCreateRestDtoModel";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import ButtonCbLibraries from "@/components/layouts/content-pages/wysiwyg/libraries/ButtonCbLibraries.vue";
import {TinyEmitter} from "tiny-emitter";
import {ContentBlockTypesEnum} from "@/helpers/functions/enum";
import {CreateNormalContentBlockPayload} from "@/models/payload/CreateNormalContentBlockPayload";
import {DpException} from "@/exception/DpException";
import ContentBlockTypesWithLibrary from "@/models/rules/ContentBlockTypesWithLibrary";
import {ImageContentRestDtoModel} from "@/models/api/pharma-cms-content-block/ImageContentRestDtoModel";
import {TextWithImageContentRestDtoModel} from "@/models/api/pharma-cms-content-block/TextWithImageContentRestDtoModel";
import {TextWithTitleContentRestDtoModel} from "@/models/api/pharma-cms-content-block/TextWithTitleContentRestDtoModel";
import InputSelect from "@/components/UI/InputSelect.vue";

export default defineComponent({
  name: "ContentPageAddColumn",
  components: {InputSelect, ButtonCbLibraries},
  emits: ['submit'],
  props: {
    bus: {
      type: TinyEmitter,
      required: true
    },
    rowId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isReady: false as boolean,

      cbInRow: ContentBlockInRowRestDtoModel.createWithDefaults(),
      exceptions: [] as Exception[],

      contentBlockStructureTypeEnums: null as CodeDescriptionRestDto[] | null,
      contentBlockTypesEnum: ContentBlockTypesEnum as unknown as ContentBlockTypesEnum[]
    };
  },
  computed: {
    ...mapGetters('cms_content_page_wysiwyg', ["getWysiwygContentBlockInRowById"]),
    ...mapGetters('cms_enum', ['getEnumByClassname', 'getCodeDescriptionByCodeFromEnumByClassname']),
    columnWidthCodeEnum(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCmsEnum.COLUMN_SIZE);
    },
    contentBlockStructureDescription(): string {
      return this.getCodeDescriptionByCodeFromEnumByClassname(EnumClassNameCmsEnum.CONTENT_BLOCK_STRUCTURE, this.cbInRow?.structure_code)?.description ?? '';
    },
    cbType(): ContentBlockTypesEnum {
      const structureCode = this.cbInRow.structure_code as any;
      return this.contentBlockTypesEnum[structureCode];
    }
  },
  mounted() {
    this.isReady = false;

    this.bus.on('openAddColumn', this.resetCbInRow);
    this.bus.on('selectedLibCb', this.saveColumnFromLib);

    this.findContentBlockStructureTypeEnums(true)
      .then(enums => {
        this.contentBlockStructureTypeEnums = enums;

        this.findEnumByClassname(EnumClassNameCmsEnum.COLUMN_SIZE)
          .then(() => {
            this.isReady = true;
          });
      });
  },
  methods: {
    ...mapActions('cms_content_page_wysiwyg', ["createContentBlockInRow"]),
    ...mapActions('cms_enum', ['findEnumByClassname']),
    ...mapActions('cms_content_block', ['findContentBlockStructureTypeEnums', 'createContentBlockLink']),
    ...mapActions('cms_content_block/normal_content_block/image', {createImageContentBlock: 'create'}),
    ...mapActions('cms_content_block/normal_content_block/text_with_image', {createTextWithImageContentBlock: 'create'}),
    ...mapActions('cms_content_block/normal_content_block/text_with_title', {createTextWithTitleContentBlock: 'create'}),
    resetCbInRow(): void {
      this.cbInRow = ContentBlockInRowRestDtoModel.createWithDefaults();
    },
    async saveColumn(): Promise<void> {
      this.exceptions = [];

      try {
        await this.createContentBlockInRow({
          row_id: this.rowId,
          cb_in_row: new ContentBlockInRowCreateRestDtoModel(null, this.cbInRow),
        });

        this.$emit('submit');
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async saveColumnFromLib(lib_cb_id: string): Promise<void> {
      this.exceptions = [];

      try {
        if (!ContentBlockTypesWithLibrary.includes(this.cbType)) {
          throw [new DpException('CB type library not supported!')];
        }

        // CB createn
        let contentBlock;
        switch (this.cbType) {
          case ContentBlockTypesEnum.IMAGE:
            contentBlock = ImageContentRestDtoModel.createWithDefaults();
            break;
          case ContentBlockTypesEnum.TEXT_WITH_IMAGE:
            contentBlock = TextWithImageContentRestDtoModel.createWithDefaults();
            break;
          case ContentBlockTypesEnum.TEXT_WITH_TITLE:
            contentBlock = TextWithTitleContentRestDtoModel.createWithDefaults();
            break;
          default:
            throw [new DpException('Variable contentBlock not initialised!')];
        }

        const cbPayload = new CreateNormalContentBlockPayload(this.cbType, contentBlock);

        let cbId;
        switch (this.cbType) {
          case ContentBlockTypesEnum.IMAGE:
            cbId = await this.createImageContentBlock(cbPayload);
            break;
          case ContentBlockTypesEnum.TEXT_WITH_IMAGE:
            cbId = await this.createTextWithImageContentBlock(cbPayload);
            break;
          case ContentBlockTypesEnum.TEXT_WITH_TITLE:
            cbId = await this.createTextWithTitleContentBlock(cbPayload);
            break;
          default:
            throw [new DpException('Variable cbId not initialised!')];
        }
        if (!cbId) {
          throw [new DpException('No cbId received from create!')];
        }

        // CB linken met de gekozen library content
        const cbLinkPayload = {
          from_cb_type_code: this.cbType,
          from_cb_id: cbId,
          to_cb_id: lib_cb_id,
          force_copy: false,
        };
        await this.createContentBlockLink(cbLinkPayload);

        //CB koppelen met CB in row
        this.cbInRow.cb_id = cbId;

        // CB in row createn
        await this.saveColumn();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    submitData(): void {
      this.saveColumn();
    },
    setExceptions(exceptions: Exception[]): void {
      this.exceptions = exceptions;
    }
  },
});
