import {
    CodeDescriptionRestDto,
    MultilingualFieldRestDto,
    Pp2ProductInfoRestDto,
    Pp2ProductPriceRestDto
} from "@/api/pharma-pim-pp2/models";

export class Pp2ProductInfoRestDtoModel implements Pp2ProductInfoRestDto {
    cnk_code?: number|null;
    name: string;
    main_image_url?: MultilingualFieldRestDto|null;
    is_apb_out_of_business?: boolean|null;
    is_image_available?: boolean|null;
    is_medicine?: boolean|null;
    is_medical_aid?: boolean|null;
    is_prescription_required?: boolean|null;
    usage?: CodeDescriptionRestDto|null;
    brand?: CodeDescriptionRestDto|null;
    product_price?: Pp2ProductPriceRestDto|null;

    constructor(name: string) {
        this.name = name;
    }

    public static createWithDefaults():Pp2ProductInfoRestDtoModel {
        return  new this('');
    }
}
