import {SnippetContextRestDto} from "@/api/pharma-cms-snippet/models";

export class SnippetContextRestDtoModel implements SnippetContextRestDto {

    product_code?: string|null;
    category_code?: string|null;
    category_path_by_code?: string|null;
    is_include_subcategory?: boolean|null;
    trademark_code?: string|null;

    constructor(is_include_subcategory: boolean) {
        this.is_include_subcategory = is_include_subcategory;
    }

    public static createWithDefaults(): SnippetContextRestDtoModel {
        return new this(false);
    }
}
