/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum ImageVerticalPositionEnum {
    CENTER = 'CENTER',
    TOP = 'TOP',
    BOTTOM = 'BOTTOM',
}