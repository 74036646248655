
import {defineComponent} from "vue";
import {mapActions} from 'vuex';
import AbstractCbCommon from "@/components/layouts/content-pages/wysiwyg/structures/AbstractCbCommon.vue";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import {PharmacyInfoContentRestDtoModel} from "@/models/api/pharma-cms-content-block/PharmacyInfoContentRestDtoModel";
import {TextWithImageContentRestDtoModel} from "@/models/api/pharma-cms-content-block/TextWithImageContentRestDtoModel";
import CbPharmacyInfoCb from "@/components/layouts/content-pages/wysiwyg/structures/CbPharmacyInfoCb.vue";
import {DpException} from "@/exception/DpException";
import {PharmacyInformationRestService} from "@/services/rest/cms-pharmacy/PharmacyInformationRestService";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import Subtitle from "@/components/UI/Subtitle.vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";

export default defineComponent({
  name: "CbPharmacyInfo",
  components: {InputCheckboxBoolean, InputSelect, Subtitle, BaseSpinner, CbPharmacyInfoCb},
  extends: AbstractCbCommon,
  data: () => ({
    contentBlock: PharmacyInfoContentRestDtoModel.createWithDefaults(),
    pharmacyOptions: [] as CodeDescriptionRestDto[],
    removedTextWithImage: TextWithImageContentRestDtoModel.createWithDefaults(),
    useTextWithImage: false as boolean,
  }),
  watch: {
    useTextWithImage(newVal) {
      if (newVal === true) {
        this.contentBlock.twi = this.removedTextWithImage;
      } else {
        if (this.contentBlock.twi !== undefined) {
          this.removedTextWithImage = this.contentBlock.twi;
        }
        this.contentBlock.twi = undefined
      }
    }
  },
  computed: {
    // enumContentLayout(): CodeDescriptionRestDto[] {
    //   return createCodeDescriptionArrayFromEnum(PharmacyInfoContentLayoutEnum);
    // },
  },
  methods: {
    ...mapActions('cms_content_block/normal_content_block/pharmacy_info', ['create', 'find', 'save']),
    async afterReloadContent(): Promise<void> {
      await this.findPharmacies();

      if (!this.contentBlock.pharmacy_code) {
        this.contentBlock.pharmacy_code = this.pharmacyOptions[0].code;
      }

      if (this.contentBlock.twi) {
        this.useTextWithImage = true;
        this.removedTextWithImage = this.contentBlock.twi;
      }
    },
    beforeSubmitData() {
      if (!this.contentBlock.pharmacy_code) {
        throw [new DpException(this.$t('Pharmacy needs to be selected.'))]
      }
    },
    getDefaultCbModel(): PharmacyInfoContentRestDtoModel {
      return PharmacyInfoContentRestDtoModel.createWithDefaults();
    },
    async findPharmacies(): Promise<void> {
      try {
        this.pharmacyOptions = await PharmacyInformationRestService
          .getInstance()
          .findPharmacies(true);
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }
    },
    onTextWithImageChanged(cb_text_with_image: TextWithImageContentRestDtoModel) {
      this.contentBlock.twi = cb_text_with_image;
    },
    pasteCbMetadata(): void {
      // When calling clone method directly on object, method is not found. So this is a hacky workaround
      const cbClone = PharmacyInfoContentRestDtoModel.cloneFrom(this.contentBlock);

      cbClone.cloneMetadataFrom(this.getClipboardContentBlock(this.cbType) as PharmacyInfoContentRestDtoModel);
      this.contentBlock = cbClone;
    },
  }
});
