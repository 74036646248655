export class FindNormalContentBlockPayload {
    id: string;
    type: string;

    constructor(id: string, type: string) {
        this.id = id;
        this.type = type;
    }

    static create(id: string, type: string): FindNormalContentBlockPayload {
        return new FindNormalContentBlockPayload(id, type);
    }
}
