
import {defineComponent} from "vue";
import ButtonContentPagePreview from "@/components/layouts/content-pages/ButtonContentPagePreview.vue";
import {AuthContext} from "@/context/AuthContext";
import {formatDate} from "@/helpers/functions/date";
import {StatusEnum} from "@/api/enums/status-enum";
import {ValueEnum} from "@/api/enums/value-enum";
import {AbilityContext} from "@/context/AbilityContext";
import {SnippetInSlotOverviewRestDtoModel} from "@/models/api/pharma-cms-snippet/SnippetInSlotOverviewRestDtoModel";
import {CodeDescriptionRestDto} from "@/api/pharma-cpc-mgmt/models";
import {ProductRestService} from "@/services/rest/cpc-product/ProductRestService";
import {CustomerContext} from "@/context/CustomerContext";
import {ProductCategoryOverviewRestDto} from "@/api/pharma-cpc-product/models";

export default defineComponent({
  name: "SnippetOverviewItem",
  components: {ButtonContentPagePreview},
  emits: ["copy", "delete", "updateStatus"],
  props: {
    snippet: {
      type: [SnippetInSlotOverviewRestDtoModel, Object],
      required: true,
    },
  },
  data() {
    return {
      allTrademarks: [] as CodeDescriptionRestDto[],
      allCategories: [] as ProductCategoryOverviewRestDto[],
    }
  },
  mounted() {
    this.reloadContent();
  },
  watch: {
  },
  computed: {
    StatusEnum() {
      return StatusEnum
    },
    canUpdateSnippet(): boolean {
      // TODO: other checks needed?
      return AbilityContext.isAuthorizedForFeature('MGMT_CONTENT_PAGE');
    },
    isDpUser(): boolean {
      return AuthContext.isDpUser();
    },
    trademark(): string {
      if (!this.snippet?.context?.trademark_code) {
        return '';
      }

      const foundTrademark = this.allTrademarks.find((trademark: any) => trademark.code === this.snippet.context.trademark_code);
      return foundTrademark?.description as string;
    },
    category(): string {
      if (!this.snippet?.context?.category_code) {
        return '';
      }

      const foundCategory = this.allCategories.find((category: any) => category.current_category.code === this.snippet.context.category_code);
      return foundCategory?.current_category?.description as string;
    },
  },
  methods: {
    formatDate,
    updateStatus(id: string, status: ValueEnum) {
      if (this.canUpdateSnippet) {
        this.$emit('updateStatus', id, status);
      }
    },
    async reloadContent() {
      this.allTrademarks= await ProductRestService.getInstance().getAllTrademarks(CustomerContext.getCustomerCode());
      this.allCategories= await ProductRestService.getInstance().getAllCategoriesCustomer(CustomerContext.getCustomerCode());
    }
  }
});
