
import {defineComponent} from "vue";

export default defineComponent({
  name: "BaseTabLink",
  props: {
    target: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    }
  }
});
