import {CpcDocumentEndpointsApiService} from "@/main";
import {UrlResponseRestDto} from "@/api/pharma-cpc-document/models";

export default {
    namespaced: true,
    state() {
        return {
            product_img_document_key: String,
            product_img_url: {} as UrlResponseRestDto,
        };
    },
    actions: {
        async searchPreviewDocument(context: any, payload: any) {
            const document_type = payload['document_type'];
            const document_key = payload['document_key'];
            const response = await CpcDocumentEndpointsApiService.documentV1DocumentDocumentKeySignedUrlGet(document_key);

            context.commit("setPreviewDocument", {
                document_type: document_type,
                document_url: response.data
            });
            context.commit("setUploadedDocumentKey", payload);
        },
        clearPreviewDocument(context: any, document_type: string): void {
            context.commit('setPreviewDocument', {
                document_type: document_type,
                document_key: null,
            });
        },
        clearUploadedDocumentKey(context: any, type: string): void {
            context.commit('setUploadedDocumentKey', {
                document_type: type,
                document_key: null,
            });
        },
        setUploadedDocumentKey(context: any, payload: any): void {
            context.commit('setUploadedDocumentKey', {
                document_type: payload.document_type,
                document_key: payload.document_key,
            });
        },
    },
    mutations: {
        setPreviewDocument(state: any, payload: any) {
            const document_type = payload['document_type'];
            const document_url = payload['document_url'];

            // todo map gebruiken
            switch (document_type) {
                case 'PRODUCT_IMG':
                    state.product_img_url = document_url;
                    break;
                default:
                    throw new Error('Unsupported document_type to set ' + document_type);
            }
        },
        setUploadedDocumentKey(state: any, payload: any) {
            const document_type = payload['document_type'];
            const document_key = payload['document_key'];

            // todo map gebruiken
            switch (document_type) {
                case 'PRODUCT_IMG':
                    state.product_img_document_key = document_key;
                    break;
                default:
                    throw new Error('Unsupported document_type to set ' + document_type);
            }
        },
    },
    getters: {
        getPreviewDocument: (state: any) => (document_type: string) => {
            // todo map gebruiken
            switch (document_type) {
                case 'PRODUCT_IMG':
                    return state.product_img_url;
                default:
                    throw new Error('Unsupported document_type to get ' + document_type);
            }
        },
        getUploadedDocumentKey: (state: any) => (document_type: string) => {
            // todo map gebruiken
            switch (document_type) {
                case 'PRODUCT_IMG':
                    return state.product_img_document_key;
                default:
                    throw new Error('Unsupported document_type to get ' + document_type);
            }
        },
    },
};
