
import store from "@/store";
import {Exception} from "@/api/interfaces";
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import CbsOverview from "@/components/layouts/content-blocks/overview/CbsOverview.vue";
import {ContentOverviewRestDto} from "@/api/pharma-cms-content-block/models";

export default defineComponent({
  name: "StandardHomepageContentBlocksOverview",
  components: {CbsOverview},
  data: () => ({
    isReady: false as boolean,
    error: null,
    exceptions: [] as Exception[],
    cbType: 'SMA_HOMEPAGE_TEXT_WITH_IMAGE',
  }),
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cms_content_block', ['getNormalContentBlocksOverview']),
    contentBlocks(): ContentOverviewRestDto[] {
      return this.getNormalContentBlocksOverview;
    },
    hasContentBlocks(): Boolean {
      return (Array.isArray(this.contentBlocks) && this.contentBlocks.length > 0);
    },
  },
  methods: {
    ...mapActions('cms_content_block', [
      'searchNormalContentBlocksOverview',
    ]),
    async reloadContent() {
      this.isReady = false;

      try {
         await this.searchNormalContentBlocksOverview({type: this.cbType});
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      this.isReady = true;
    },
    async deleteContentBlock(id: string) {
      try {
        const answer = window.confirm(this.$t('deleteConfirmation'));

        if (answer) {
          this.isReady = false;

          await store.dispatch('cms_content_block/deleteNormalContentBlock', {
            type: this.cbType,
            id: id,
          });

          setTimeout(() => this.reloadContent(), 1000);
        } else {
          return;
        }
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
  }
});
