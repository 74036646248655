
import AbstractInputControl from "@/components/UI/AbstractInputControl.vue";

export default {
  extends: AbstractInputControl,
  methods: {
    determineInputType() {
      return 'url';
    },
    determineRules() {
      return ['url'];
    }
  }
};
