import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "the-sidebar-sub-menu-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createVNode(_component_router_link, {
      class: "link d-block text-white text-decoration-none fw-400 rounded-0 py-2",
      to: this.to
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(this.title), 1)
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}