/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 * Generated: Tue, 18 Apr 2023 09:32:32 +0000
 */
export enum FooterContent1LayoutEnum {
    PHARMACY_INFO_LEFT = 'PHARMACY_INFO_LEFT',
    PHARMACY_INFO_TOP = 'PHARMACY_INFO_TOP',
}