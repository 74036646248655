
import {defineComponent} from 'vue'
import PimProductsOverviewTableItemPreview from "@/components/layouts/pim/PimProductsOverviewTableItemPreview.vue";
import ItemIcons from "@/components/layouts/pim/PimProductsOverviewItemIcons.vue";
import {LaboProductOverviewRestDtoModel} from "@/models/api/pharma-pim-labo/LaboProductOverviewRestDtoModel";
import {AbilityContext} from "@/context/AbilityContext";

export default defineComponent({
  name: "PimLabProductsOverviewTableItem",
  emits: ["openOffCanvasProductDetail"],
  components: {ItemIcons, PimProductsOverviewTableItemPreview},
  props: {
    product: {
      type: [LaboProductOverviewRestDtoModel, Object],
      required: true
    }
  },
  data() {
    return {
      showPreview: false as boolean
    }
  },
  computed:{
    AbilityContext() {
      return AbilityContext
    },
  },
  methods:{
    openOffCanvasProductDetail(): void {
      if (AbilityContext.isAuthorizedForFeature('PIM_LABO_PRODUCT_INFO') && this.product.product_info.cnk_code){
        this.$emit("openOffCanvasProductDetail", this.product);
      }
    },
  }
})
