import {NamedTextFragmentRestDto, TextFragmentRestDto} from "@/api/pharma-pim-mgmt/models";
import {TextFragmentRestDtoModel} from "@/models/api/pharma-pim-mgmt/TextFragmentRestDtoModel";

export class NamedTextFragmentRestDtoModel implements NamedTextFragmentRestDto {
    code: string | null;
    fragment: TextFragmentRestDto | null;

    constructor(code: string, content: string | null) {
        this.code = code;
        this.fragment = TextFragmentRestDtoModel.createWithDefaults()
        this.fragment.content = content;
    }

    public static createWithDefaults(): NamedTextFragmentRestDtoModel {
        return new this(
            '',
            null,
        )
    }
}
