import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b7fa28d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "selected-categories d-flex flex-wrap" }
const _hoisted_2 = {
  key: 0,
  class: "fst-italic lh-1 m-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectCategoriesSelectedItem = _resolveComponent("SelectCategoriesSelectedItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!this.hasSelectedProductCategories)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('noCategoriesAdded')), 1))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(this.selectedProductCategories, (selectedProductCategory) => {
          return (_openBlock(), _createBlock(_component_SelectCategoriesSelectedItem, {
            key: selectedProductCategory,
            "selected-product-category-code": selectedProductCategory,
            "catalog-customer-code": this.catalogCustomerCode,
            "tooltip-id": _ctx.id,
            onRemoveCategory: this.removeCategory
          }, null, 8, ["selected-product-category-code", "catalog-customer-code", "tooltip-id", "onRemoveCategory"]))
        }), 128))
  ]))
}