import {LaboProductsCriteriaRestDto} from "@/api/pharma-pim-labo/models";
import {PimProductsPagingModel} from "@/models/paging/PimProductsPagingModel";
import {PimLaboProductsCriteriaModel} from "@/models/criteria/PimLaboProductsCriteriaModel";

export class LaboProductsCriteriaRestDtoModel implements LaboProductsCriteriaRestDto {
    accept?: string | null;

    x_dp_customer_code?: string | null;
    x_dp_language?: string | null;

    page?: number | null;
    page_size?: number | null;

    filter_brand_code?: string | null;
    filter_cnk_code?: number | null;
    filter_category_code?: string | null;
    filter_is_image_available?: boolean | null;
    filter_is_commercially_available?: boolean | null;
    search_keyword?: string | null;

    constructor(criteria: PimLaboProductsCriteriaModel, paging?: PimProductsPagingModel, accept?: string | null) {
        this.filter_brand_code = criteria.filter_brand_code;
        this.filter_cnk_code = criteria.filter_cnk_code;
        this.filter_category_code = criteria.filter_category_code;
        this.filter_is_image_available = criteria.filter_is_image_available;
        this.filter_is_commercially_available = criteria.filter_is_commercially_available;
        this.search_keyword = criteria.search_keyword;

        this.page = paging?.number;
        this.page_size = paging?.size ? parseInt(paging.size) : undefined;

        this.accept = accept;
    }

    public static createWithDefaults(): LaboProductsCriteriaRestDtoModel {
        return new this(
            PimLaboProductsCriteriaModel.createWithDefaults(),
            PimProductsPagingModel.createWithDefaults()
        );
    }
}
