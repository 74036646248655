
import {defineComponent} from 'vue'
import {mapActions, mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import {CategorizationTypeEnum} from "@/models/enum/CategorizationTypeEnum";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import {Config} from "@/models/facade/Config";
import InputSelect from "@/components/UI/InputSelect.vue";
import SelectCategories from "@/components/layouts/catalog/SelectCategories.vue";
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import {CategorizationActionEnum} from "@/models/enum/CategorizationActionEnum";
import {PimCategorizationRestService} from "@/services/rest/pim-category/PimCategorizationRestService";
import {CnkListRestDto} from "@/api/pharma-pim-category/models";
import {arrayHasContent} from "@/helpers/functions/arrays";
import {stringHasContent} from "@/helpers/functions/string";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import {
  PagedPp2ProductOverviewResultsRestDtoModel
} from "@/models/api/pharma-pim-pp2/PagedPp2ProductOverviewResultsRestDtoModel";
import {ProductCategoryOverviewRestDto} from "@/api/pharma-cpc-product/models";
import {MoveToRestDtoModel} from "@/models/api/pharma-pim-category/MoveToRestDtoModel";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import ConfirmModal from "@/components/UI/ConfirmModal.vue";

export default defineComponent({
  name: "PimProductsOverviewCategorization",
  emits: ["submitted"],
  components: {BaseSpinner, AlertError2, SelectCategories, InputSelect},
  props: {
    productsOverview: {
      type: [PagedPp2ProductOverviewResultsRestDtoModel, Object],
      required: true
    }
  },
  data() {
    return {
      catalogCustomerCode: Config.getInstance().getConfig().STARTER_CATALOG_CUSTOMER_CODE as string,

      categorizationUI: UIStateDto.createWithDefaults(),

      sourceCategorizationType: CategorizationTypeEnum.DP_MANAGED,
      targetCategorizationType: CategorizationTypeEnum.DP_MANAGED,

      targetDpManagedCategoryCodes: [] as string[],

      targetInternalCategoryCode: null as string | null,


    }
  },
  watch: {
    sourceDpManagedCategoryCode() {
      this.onSourceCategoryChanged(CategorizationTypeEnum.DP_MANAGED);
    },
    sourceInternalCategoryCode() {
      this.onSourceCategoryChanged(CategorizationTypeEnum.INTERNAL);
    },
  },
  computed: {
    ...mapState(useCustomerStore, ["getCriteria", "getPimState", "getInternalProductCategories", "getAllCategoriesCustomer", "canCategorizePimProducts", "canMoveUncategorizedPimProducts"]),
    CategorizationActionEnum() {
      return CategorizationActionEnum;
    },
    CategorizationTypeEnum() {
      return CategorizationTypeEnum;
    },
    productCategoryFilters(): string[] {
      return this.targetDpManagedCategoryCodes;
    },
    productsSelectedForCategorization(): number[] {
      return this.getPimState.pim_products_selected_for_categorization;
    },
    sourceDpManagedCategoryCode(): string | null {
      return this.getCriteria.pim_products.filter_category_code_dp_managed ?? null;
    },
    sourceInternalCategoryCode(): string | null {
      return this.getCriteria.pim_products.filter_category_code_internal ?? null;
    },
    canDeleteCategory(): boolean {
      return arrayHasContent(this.productsSelectedForCategorization) && this.canCategorizePimProducts;
    },
    canEditCategory(): boolean {
      return arrayHasContent(this.productsSelectedForCategorization) && this.canCategorizePimProducts && this.hasTargetCategory;
    },
    canMoveCategoryNotYetCategorized(): boolean {
      return arrayHasContent(this.productsSelectedForCategorization) && this.canMoveUncategorizedPimProducts && this.hasTargetCategory;
    },
    hasTargetCategory(): boolean {
      return (
        (this.targetCategorizationType === CategorizationTypeEnum.DP_MANAGED && this.targetDpManagedCategoryCodes.length > 0) ||
        (this.targetCategorizationType === CategorizationTypeEnum.INTERNAL && stringHasContent(this.targetInternalCategoryCode))
      );
    },
    optionsInternalProductCategory(): CodeDescriptionRestDto[] {
      return this.getInternalProductCategories;
    },
  },
  mounted() {
    this.reloadContent();
  },
  methods: {
    ...mapActions(useCustomerStore, ["searchAllCategoriesCustomer", "searchProductCategoriesTreeCustomer", "searchInternalProductCategories"]),
    addCategory(category_code: string) {
      this.targetDpManagedCategoryCodes.push(category_code);
    },
    removeCategory(category_code: string) {
      const index = this.targetDpManagedCategoryCodes.indexOf(category_code);
      if (index > -1) {
        this.targetDpManagedCategoryCodes.splice(index, 1);
      }
    },
    getDpManagedCategoryName(categoryCode: string): string | null {
      return this.getAllCategoriesCustomer(this.catalogCustomerCode)
        .find((category: ProductCategoryOverviewRestDto) => category.current_category.code === categoryCode)
        ?.current_category?.description ?? null;
    },
    getInternalCategoryName(categoryCode: string): string | null {
      return this.getInternalProductCategories
        .find((category: CodeDescriptionRestDto) => category.code === categoryCode)
        ?.description ?? null;
    },
    onSourceCategoryChanged(changedCategoryType: CategorizationTypeEnum): void {
      if (this.sourceDpManagedCategoryCode && this.sourceInternalCategoryCode) {
        this.sourceCategorizationType = changedCategoryType;
      } else if (this.sourceDpManagedCategoryCode && !this.sourceInternalCategoryCode) {
        this.sourceCategorizationType = CategorizationTypeEnum.DP_MANAGED;
      } else if (!this.sourceDpManagedCategoryCode && this.sourceInternalCategoryCode) {
        this.sourceCategorizationType = CategorizationTypeEnum.INTERNAL;
      }
    },
    async reloadContent(): Promise<void> {
      this.categorizationUI
        .setNotReady()
        .clearError();

      try {
        await Promise.all([
          this.searchAllCategoriesCustomer(this.catalogCustomerCode),
          this.searchProductCategoriesTreeCustomer(this.catalogCustomerCode),
          this.searchInternalProductCategories(),
        ]);
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.categorizationUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.categorizationUI as UIStateDto))
          .catch(exceptions, true);
      } finally {
        this.categorizationUI.setReady();
      }
    },
    async submitData(categorizationAction: CategorizationActionEnum): Promise<void> {
      this.categorizationUI.clearError();

      try {
        const cnkList = {cnk_codes: this.productsSelectedForCategorization} as CnkListRestDto;
        const moveToDto = new MoveToRestDtoModel(
          this.sourceDpManagedCategoryCode ?? undefined,
          this.sourceInternalCategoryCode ?? undefined,
          this.productsSelectedForCategorization
        );

        // todo translate
        const {reveal, onConfirm} = createConfirmDialog(ConfirmModal, {
          title: 'Categorisatie verwijderen',
          body: 'Ben je zeker dat je de categorisatie wenst te verwijderen?',
          confirmButtonText: this.$t('delete'),
          confirmButtonClass: 'btn-danger',
        });

        switch (categorizationAction) {
          case CategorizationActionEnum.REMOVE:
            onConfirm(async () => {
              if (this.sourceCategorizationType === CategorizationTypeEnum.DP_MANAGED) {
                await PimCategorizationRestService.getInstance().removeProductsFromDpCategory(cnkList, this.sourceDpManagedCategoryCode);
              } else if (this.sourceCategorizationType === CategorizationTypeEnum.INTERNAL) {
                await PimCategorizationRestService.getInstance().removeProductsFromInternalCategory(cnkList, this.sourceInternalCategoryCode);
              } else {
                throw 'sourceCategorizationType not initialized yet';
              }

              await handleSavedSuccessfully(this.$t('deletedSuccessfully'));
              this.$emit('submitted');
            });

            await reveal();
            break;
          case CategorizationActionEnum.COPY:
            if (this.targetCategorizationType === CategorizationTypeEnum.DP_MANAGED) {
              const implodedCodes = this.targetDpManagedCategoryCodes.join('|');
              await PimCategorizationRestService.getInstance().copyProductsToDpCategory(cnkList, implodedCodes);
            } else if (this.targetCategorizationType === CategorizationTypeEnum.INTERNAL) {
              await PimCategorizationRestService.getInstance().copyProductsToInternalCategory(cnkList, this.targetInternalCategoryCode);
            } else {
              throw 'targetCategorizationType not initialized yet';
            }

            await handleSavedSuccessfully(this.$t('copiedSuccessfully'));
            this.$emit('submitted');
            break;
          case CategorizationActionEnum.MOVE:
            if (this.targetCategorizationType === CategorizationTypeEnum.DP_MANAGED) {
              const implodedCodes = this.targetDpManagedCategoryCodes.join('|');
              await PimCategorizationRestService.getInstance().moveProductsToDpCategory(moveToDto, implodedCodes);
            } else if (this.targetCategorizationType === CategorizationTypeEnum.INTERNAL) {
              await PimCategorizationRestService.getInstance().moveProductsToInternalCategory(moveToDto, this.targetInternalCategoryCode);
            } else {
              throw 'targetCategorizationType not initialized yet';
            }

            await handleSavedSuccessfully(this.$t('movedSuccessfully'));
            this.$emit('submitted');
            break;
        }
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.categorizationUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.categorizationUI as UIStateDto))
          .catch(exceptions, true);
      }
    }
  }
});
