/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum TextWithImageContentBlockLayoutEnum {
    IMAGE_ABOVE_TEXT = 'IMAGE_ABOVE_TEXT',
    IMAGE_TO_THE_LEFT_OF_TEXT = 'IMAGE_TO_THE_LEFT_OF_TEXT',
    IMAGE_TO_THE_RIGHT_OF_TEXT = 'IMAGE_TO_THE_RIGHT_OF_TEXT',
    TITLE_ABOVE_IMAGE_TO_THE_LEFT_OF_TEXT = 'TITLE_ABOVE_IMAGE_TO_THE_LEFT_OF_TEXT',
    TITLE_ABOVE_IMAGE_TO_THE_RIGHT_OF_TEXT = 'TITLE_ABOVE_IMAGE_TO_THE_RIGHT_OF_TEXT',
}