/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Customer-Product-Catalog
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CustomerCategorizationActionOverviewResultsRestDto } from '../models';
import { CustomerCategorizationActionRestDto } from '../models';
import { ProductCategoriesForProductRestDto } from '../models';
/**
 * ProductCategorizationAPIApi - axios parameter creator
 * @export
 */
export const ProductCategorizationAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for adding a categorization action for a product.
         * @summary REST.API.CPC.PMCA.02 Add categorization action for a product
         * @param {CustomerCategorizationActionRestDto} body 
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} product_code Identifies the product.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductCategorizationAction: async (body: CustomerCategorizationActionRestDto, x_dp_customer_code: string, product_code: string, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addProductCategorizationAction.');
            }
            // verify required parameter 'x_dp_customer_code' is not null or undefined
            if (x_dp_customer_code === null || x_dp_customer_code === undefined) {
                throw new RequiredError('x_dp_customer_code','Required parameter x_dp_customer_code was null or undefined when calling addProductCategorizationAction.');
            }
            // verify required parameter 'product_code' is not null or undefined
            if (product_code === null || product_code === undefined) {
                throw new RequiredError('product_code','Required parameter product_code was null or undefined when calling addProductCategorizationAction.');
            }
            const localVarPath = `/product_mgmt/v1/catalog/product/{product_code}/customer_categorization_action`
                .replace(`{${"product_code"}}`, encodeURIComponent(String(product_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for removing a categorization action for a product.
         * @summary REST.API.CPC.PMCA.03 Delete categorization action for a product
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} product_code Identifies the product.
         * @param {string} action_code Identifies the categorization action type.
         * @param {string} category_code Identifies the categorization action.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductCategorizationAction: async (x_dp_customer_code: string, product_code: string, action_code: string, category_code: string, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'x_dp_customer_code' is not null or undefined
            if (x_dp_customer_code === null || x_dp_customer_code === undefined) {
                throw new RequiredError('x_dp_customer_code','Required parameter x_dp_customer_code was null or undefined when calling deleteProductCategorizationAction.');
            }
            // verify required parameter 'product_code' is not null or undefined
            if (product_code === null || product_code === undefined) {
                throw new RequiredError('product_code','Required parameter product_code was null or undefined when calling deleteProductCategorizationAction.');
            }
            // verify required parameter 'action_code' is not null or undefined
            if (action_code === null || action_code === undefined) {
                throw new RequiredError('action_code','Required parameter action_code was null or undefined when calling deleteProductCategorizationAction.');
            }
            // verify required parameter 'category_code' is not null or undefined
            if (category_code === null || category_code === undefined) {
                throw new RequiredError('category_code','Required parameter category_code was null or undefined when calling deleteProductCategorizationAction.');
            }
            const localVarPath = `/product_mgmt/v1/catalog/product/{product_code}/customer_categorization_action/{action_code}/{category_code}`
                .replace(`{${"product_code"}}`, encodeURIComponent(String(product_code)))
                .replace(`{${"action_code"}}`, encodeURIComponent(String(action_code)))
                .replace(`{${"category_code"}}`, encodeURIComponent(String(category_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving the customer categorization actions for a product.
         * @summary REST.API.CPC.PMCA.01 Find the customer categorization actions for a product
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} product_code Identifies the product.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCustomerCategorizationActions: async (x_dp_customer_code: string, product_code: string, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'x_dp_customer_code' is not null or undefined
            if (x_dp_customer_code === null || x_dp_customer_code === undefined) {
                throw new RequiredError('x_dp_customer_code','Required parameter x_dp_customer_code was null or undefined when calling findCustomerCategorizationActions.');
            }
            // verify required parameter 'product_code' is not null or undefined
            if (product_code === null || product_code === undefined) {
                throw new RequiredError('product_code','Required parameter product_code was null or undefined when calling findCustomerCategorizationActions.');
            }
            const localVarPath = `/product_mgmt/v1/catalog/product/{product_code}/customer_categorization_action`
                .replace(`{${"product_code"}}`, encodeURIComponent(String(product_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving the product categories from the master dataset for a product. This can be the default master or Rainpharma master dataset, depending on the source of the product.
         * @summary REST.API.CPC.PMPC.01 Find the dp categories for a product
         * @param {string} product_code Identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMasterProductCategories: async (product_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'product_code' is not null or undefined
            if (product_code === null || product_code === undefined) {
                throw new RequiredError('product_code','Required parameter product_code was null or undefined when calling findMasterProductCategories.');
            }
            const localVarPath = `/product_mgmt/v1/master_catalog/product/{product_code}/category`
                .replace(`{${"product_code"}}`, encodeURIComponent(String(product_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductCategorizationAPIApi - functional programming interface
 * @export
 */
export const ProductCategorizationAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for adding a categorization action for a product.
         * @summary REST.API.CPC.PMCA.02 Add categorization action for a product
         * @param {CustomerCategorizationActionRestDto} body 
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} product_code Identifies the product.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProductCategorizationAction(_axios: AxiosInstance, body: CustomerCategorizationActionRestDto, x_dp_customer_code: string, product_code: string, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductCategorizationAPIApiAxiosParamCreator(configuration).addProductCategorizationAction(body, x_dp_customer_code, product_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for removing a categorization action for a product.
         * @summary REST.API.CPC.PMCA.03 Delete categorization action for a product
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} product_code Identifies the product.
         * @param {string} action_code Identifies the categorization action type.
         * @param {string} category_code Identifies the categorization action.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductCategorizationAction(_axios: AxiosInstance, x_dp_customer_code: string, product_code: string, action_code: string, category_code: string, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductCategorizationAPIApiAxiosParamCreator(configuration).deleteProductCategorizationAction(x_dp_customer_code, product_code, action_code, category_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving the customer categorization actions for a product.
         * @summary REST.API.CPC.PMCA.01 Find the customer categorization actions for a product
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} product_code Identifies the product.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCustomerCategorizationActions(_axios: AxiosInstance, x_dp_customer_code: string, product_code: string, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerCategorizationActionOverviewResultsRestDto>> {
            const localVarAxiosArgs = await ProductCategorizationAPIApiAxiosParamCreator(configuration).findCustomerCategorizationActions(x_dp_customer_code, product_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving the product categories from the master dataset for a product. This can be the default master or Rainpharma master dataset, depending on the source of the product.
         * @summary REST.API.CPC.PMPC.01 Find the dp categories for a product
         * @param {string} product_code Identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMasterProductCategories(_axios: AxiosInstance, product_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductCategoriesForProductRestDto>> {
            const localVarAxiosArgs = await ProductCategorizationAPIApiAxiosParamCreator(configuration).findMasterProductCategories(product_code, x_dp_customer_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProductCategorizationAPIApi - object-oriented interface
 * @export
 * @class ProductCategorizationAPIApi
 * @extends {BaseAPI}
 */
export class ProductCategorizationAPIApi extends BaseAPI {
    /**
     * API endpoint responsible for adding a categorization action for a product.
     * @summary REST.API.CPC.PMCA.02 Add categorization action for a product
     * @param {CustomerCategorizationActionRestDto} body 
     * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} product_code Identifies the product.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategorizationAPIApi
     */
     public addProductCategorizationAction(body: CustomerCategorizationActionRestDto, x_dp_customer_code: string, product_code: string, x_dp_language?: string|null, options?: any) {
        return ProductCategorizationAPIApiFp(this.configuration).addProductCategorizationAction(this.axios, body, x_dp_customer_code, product_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for removing a categorization action for a product.
     * @summary REST.API.CPC.PMCA.03 Delete categorization action for a product
     * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} product_code Identifies the product.
     * @param {string} action_code Identifies the categorization action type.
     * @param {string} category_code Identifies the categorization action.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategorizationAPIApi
     */
     public deleteProductCategorizationAction(x_dp_customer_code: string, product_code: string, action_code: string, category_code: string, x_dp_language?: string|null, options?: any) {
        return ProductCategorizationAPIApiFp(this.configuration).deleteProductCategorizationAction(this.axios, x_dp_customer_code, product_code, action_code, category_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving the customer categorization actions for a product.
     * @summary REST.API.CPC.PMCA.01 Find the customer categorization actions for a product
     * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} product_code Identifies the product.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategorizationAPIApi
     */
     public findCustomerCategorizationActions(x_dp_customer_code: string, product_code: string, x_dp_language?: string|null, options?: any) {
        return ProductCategorizationAPIApiFp(this.configuration).findCustomerCategorizationActions(this.axios, x_dp_customer_code, product_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving the product categories from the master dataset for a product. This can be the default master or Rainpharma master dataset, depending on the source of the product.
     * @summary REST.API.CPC.PMPC.01 Find the dp categories for a product
     * @param {string} product_code Identifies the product.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategorizationAPIApi
     */
     public findMasterProductCategories(product_code: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any) {
        return ProductCategorizationAPIApiFp(this.configuration).findMasterProductCategories(this.axios, product_code, x_dp_customer_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

}
