
import {Exception} from "@/api/interfaces";
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {
  BannerSlotContentRestDto,
  CodeDescriptionRestDto,
  FullBannerSlotContentRestDto
} from "@/api/pharma-cms-content-block/models";
import {StatusEnum} from "@/api/enums/status-enum";
import BannerSlotDetailBannerLibrary from "@/components/layouts/banners/BannerSlotDetailBannerLibrary.vue";
import BannerOverview from '@/components/layouts/banners/BannerOverview.vue';
import BannerSlotDetailButtonSubscribeToSlot
  from "@/components/layouts/banners/BannerSlotDetailButtonSubscribeToSlot.vue";
import BannerSlotDetailButtonType from "@/components/layouts/banners/BannerSlotDetailButtonType.vue";
import {AVAILABLE_CONTENT_SIZE_CODES} from "@/store/pharma-cms-content-block/middleware";
import BannerSlotDetailPreviewBannerSlot from "@/components/layouts/banners/BannerSlotDetailPreviewBannerSlot.vue";
import {
  ContentBlockLibraryRestService
} from "@/services/rest/cms-content-block/ContentBlockLibraryRestService";
import {ContentBlockTypesEnum} from "@/helpers/functions/enum";
import {LibraryOverviewRestDtoModel} from "@/models/api/pharma-cms-content-block-library/LibraryOverviewRestDtoModel";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import AlertSuccess from "@/components/UI/Bootstrap/Alert/AlertSuccess.vue";
import AlertError from "@/components/UI/Bootstrap/Alert/AlertError.vue";
import {generateUUIDv4} from "@/helpers/functions/string";

export default defineComponent({
  name: "BannerSlotDetail",
  components: {
    AlertError,
    AlertSuccess,
    BaseTitle,
    BaseSpinner,
    BannerLibrary: BannerSlotDetailBannerLibrary,
    BannerOverview,
    BannerSlotPreview: BannerSlotDetailPreviewBannerSlot,
    ButtonSubscribeToSlot: BannerSlotDetailButtonSubscribeToSlot,
    ButtonType: BannerSlotDetailButtonType,
  },
  props: {
    cbId: {
      type: String,
      required: false,
    },
    mode: {
      type: String,
      required: true,
    },
    isLibrary: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      exceptions: [] as Exception[],
      returnRoute: this.isLibrary ? {name: 'content-provider-banner-slots'} : {name: 'customer-banner-slots'} as object,
      isSubmitting: false as boolean,
      isReady: false as boolean,
      bannerOverviewKey:generateUUIDv4(),
      savedSuccessfully: false as boolean,

      availableBannerSlots: [] as LibraryOverviewRestDtoModel[],
      availableBannersForSlotLibraries: [
        new LibraryOverviewRestDtoModel({code: 'OWN_BANNERS', description: this.$t('banners')})
      ],
      bannerSlotsContent: [] as FullBannerSlotContentRestDto[],

      AVAILABLE_CONTENT_SIZE_CODES: AVAILABLE_CONTENT_SIZE_CODES as string[],
      bannerSlotType: this.isLibrary ? 'LIB_BANNER_SLOT' : 'BANNER_SLOT' as string,
      bannerLibType: this.isLibrary ? 'LIB_BANNER' : 'BANNER' as string,
      bannerInSlotType: 'BANNER_IN_SLOT' as string,
      statusOptions: [
        {code: StatusEnum.PUBLISHED, description: this.$t('published')},
        {code: StatusEnum.NOT_PUBLISHED, description: this.$t('notPublished')}
      ] as CodeDescriptionRestDto[],
    };
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cms_content_block', ["getNormalContentBlockBannerSlot"]),
    bannerSlot(): BannerSlotContentRestDto {
      return this.getNormalContentBlockBannerSlot(this.bannerSlotType);
    },
    currentSubscriptionId(): string | null {
      return this.bannerSlot.subscription_slot_id ?? null;
    }
  },
  methods: {
    ...mapActions('cms_content_block', ["searchNormalContentBlockBannerSlot", "saveNormalContentBlockBannerSlot",]),
    async searchAvailableBannerSlots(): Promise<void> {
      this.availableBannerSlots = await ContentBlockLibraryRestService.getInstance()
        .findContentBlockLibsForType('BANNER_SLOT');
    },
    async searchAvailableBannersForSlotLibraries(): Promise<void> {
      this.availableBannersForSlotLibraries = await ContentBlockLibraryRestService.getInstance()
        .findContentBlockLibsForType(ContentBlockTypesEnum.BANNER, true);
    },
    async searchBannerSlotsContent(libCode: string | undefined): Promise<void> {
      if (libCode) {
        const bannerSlotLib = await ContentBlockLibraryRestService.getInstance()
          .findLibraryContentBlocksBannerSlot(libCode, StatusEnum.PUBLISHED);
        bannerSlotLib.forEach((bannerSlotContent: FullBannerSlotContentRestDto) => {
          this.bannerSlotsContent.push(bannerSlotContent);
        });
      }
    },
    async reloadContent() {
      this.isReady = false;
      this.exceptions = [];

      try {
        const initialPromises = [
          this.searchNormalContentBlockBannerSlot({
            id: this.cbId,
            type: this.bannerSlotType
          })
        ] as any[];
        if (this.isLibrary) {
          initialPromises.push(this.searchAvailableBannersForSlotLibraries());
        } else {
          initialPromises.push(this.searchAvailableBannerSlots());
        }
        await Promise.all(initialPromises);

        this.bannerSlotsContent = [];
        if (!this.isLibrary) {
          this.availableBannerSlots.forEach((availableBannerSlot: LibraryOverviewRestDtoModel) => {
            this.searchBannerSlotsContent(availableBannerSlot.library?.code)
          });
        }
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }

      this.isReady = true;
    },
    async reloadBanners() {
      this.bannerOverviewKey = generateUUIDv4();
    },
    async submitData() {
      this.isSubmitting = true;
      this.exceptions = [];

      try {
        await this.saveNormalContentBlockBannerSlot({
          id: this.cbId,
          type: this.bannerSlotType
        });

        await this.afterSave();
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }

      this.isSubmitting = false;
    },
    async afterSave() {
      window.scrollTo(0, 0);

      await this.reloadContent();
      this.savedSuccessfully = true;
      setTimeout(() => this.savedSuccessfully = false, 2000);
    },
    async afterAddBanner() {
      window.scrollTo(0, 0);

      await this.reloadBanners();
    },
    getBannerSlotLibraryName(position_code: string): string | null {
      switch (position_code) {
        case 'LIB_SLOT_1':
          return `Digital Pharma ${this.$t('banners')} 1`;
        case 'LIB_SLOT_2':
          return `Digital Pharma ${this.$t('banners')} 2`;
        case 'LIB_GROUP_SLOT_1':
          return `${this.$t('pharmacyGroup')} ${this.$t('banners')} 1`;
        case 'LIB_GROUP_SLOT_2':
          return `${this.$t('pharmacyGroup')} ${this.$t('banners')} 2`;
        default:
          return null;
      }
    },
    setSubscribeToSlotId(slot_id: string | null) {
      this.bannerSlot.subscription_slot_id = slot_id;
    },
    setExceptions(exceptions: Exception[]) {
      window.scrollTo(0, 0);
      this.exceptions = exceptions;
    },
    isCurrentSubscription(subscriptionId: string | null): boolean {
      return this.currentSubscriptionId === subscriptionId;
    },
  }
});
