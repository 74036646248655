import {MenuRestDto} from "@/api/pharma-cms-menu/models";
import {MenuHorizontalPositionEnum} from "@/api/enums/menu-horizontal-position-enum";
import {MenuLayoutEnum} from "@/api/enums/menu-layout-enum";

export class MenuRestDtoModel implements MenuRestDto {
    code?: string | null;
    name?: string | null;
    horizontal_position_code: MenuHorizontalPositionEnum;
    is_submenu_allowed: boolean;
    layout_code: MenuLayoutEnum;


    constructor(layout_code: MenuLayoutEnum, horizontal_position_code: MenuHorizontalPositionEnum, is_submenu_allowed: boolean) {
        this.layout_code = layout_code;
        this.horizontal_position_code = horizontal_position_code;
        this.is_submenu_allowed = is_submenu_allowed;
    }

    public static createWithDefaults(): MenuRestDtoModel {
        const menu = new this(
            MenuLayoutEnum.BAR,
            MenuHorizontalPositionEnum.RIGHT,
            false,
        );

        return menu;
    }
}
