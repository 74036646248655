
import {defineComponent} from "vue";
import {default as Tooltip} from 'bootstrap/js/dist/tooltip';

export default defineComponent({
  name: "ProductSelectionBrandsSelectionItemBadges",
  props: {
    brandItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      refBadgeBs: `prod_count_bs_${this.brandItem.brand_code}` as string,
      refBadgeCc: `prod_count_cc_${this.brandItem.brand_code}` as string,
      tooltipBadgeBs: {} as Tooltip,
      tooltipBadgeCc: {} as Tooltip,
    }
  },
  mounted() {
    new Tooltip(this.$refs[this.refBadgeBs] as Element);
    new Tooltip(this.$refs[this.refBadgeCc] as Element);
    this.tooltipBadgeBs = Tooltip.getInstance('#' + this.refBadgeBs) as Tooltip;
    this.tooltipBadgeCc = Tooltip.getInstance('#' + this.refBadgeCc) as Tooltip;
  },
  unmounted() {
    this.tooltipBadgeBs?.dispose();
    this.tooltipBadgeCc?.dispose();
  },
  computed: {},
  methods: {
    tooltipTitle(type: string): string {
      let count = null as number | unknown;
      let productKey = '' as string;
      let legendKey = '' as string;

      switch (type) {
        case 'bs':
          count = this.brandItem.base_selection_product_count;
          legendKey = 'legendBaseSelectionProductCount';
          break;
        case 'cc':
          count = this.brandItem.customer_catalog_product_count;
          legendKey = 'legendCustomerCatalogProductCount';
          break;
        default:
          throw new Error('No tooltipTitle type found for ' + type);
      }

      productKey = (count === 1) ? 'product' : 'products';

      return `${count} ${this.$t(productKey).toLowerCase()} ${this.$t(legendKey)}`;
    },
  }
});
