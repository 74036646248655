<template>
  <input-text
    v-for="language in this.languages"
    :key="language"
    v-model="this.internalData[language]"
    v-show="language === this.activeLanguage"
    :label="this.label"
    :placeholder="this.placeholder"
    :name="`${this.name}.${language}`"
    :rules="this.rules"
    :errors="this.errors"
    :language="language"
    :isMultilingual="$can('use-feature', 'MULTILINGUAL')"
  />
</template>

<script>
import InputText from "@/components/UI/InputText.vue";
import {I18nContext} from "@/context/I18nContext";

export default {
  name: "I18nInputText",
  extends: InputText,
  props: {
    languages: {
      type: Array,
      required: false,
      default() {
        return I18nContext.getLanguages();
      }
    },
    activeLanguage: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  mounted() {
      this.languages.forEach(lang => {
        if (this.internalData[lang] === undefined) {
          this.internalData.lang = '';
        }
      });
  },
  data() {
    return {
      internalData: this.data,
    };
  },
}
</script>
