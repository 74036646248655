import {RestService} from "@/services/rest/RestService";
import {OAuth2Token} from "@/dtos/OAuth2Token";
import {generateUUIDv4} from "@/helpers/functions/string";
import {Storage} from "@/models/facade/Storage";
import {SessionStorageKeyEnum} from "@/models/enum/SessionStorageKeyEnum";

export class AuthRestService extends RestService {

    public getLoginUrl(): string {
        const origin: string = window.location.origin;
        const baseUrl: string = this.getConfig().authBaseUrl;
        const clientId: string = this.getConfig().authClientIdV2;
        const state: string = generateUUIDv4();
        const codeVerifier: string = generateUUIDv4();

        Storage.session.set(SessionStorageKeyEnum.AUTH_FLOW_STATE, state);
        Storage.session.set(SessionStorageKeyEnum.AUTH_FLOW_CODE_VERIFIER, codeVerifier);

        return `${baseUrl}/login?client_id=${clientId}&state=${state}&response_type=code&scope=email+openid+profile&redirect_uri=${origin}/oauth2/v2/callback`;
    }

    public async getTokenByCode(code: string, state: string): Promise<OAuth2Token> {
        const sessionState = Storage.session.get(SessionStorageKeyEnum.AUTH_FLOW_STATE, null);

        if (sessionState === null || state !== sessionState) {
            throw new Error('Invalid state param');
        }

        const origin: string = window.location.origin;
        const baseUrl: string = this.getConfig().authBaseUrl;
        const clientId: string = this.getConfig().authClientIdV2;
        const codeVerifier: string = Storage.session.get(SessionStorageKeyEnum.AUTH_FLOW_CODE_VERIFIER, '');

        const response = await this.getAxiosInstance().post(
            `${baseUrl}/oauth2/token`,
            new URLSearchParams({
                "grant_type": "authorization_code",
                "client_id": clientId,
                "code": code,
                "code_verifier": codeVerifier,
                "redirect_uri": `${origin}/oauth2/v2/callback`,
            })
        );

        if (response.status !== 200) {
            throw new Error(response.data);
        }

        return new OAuth2Token(
            response.data['access_token'],
            response.data['id_token'],
            response.data['refresh_token'],
            response.data['expires_in'],
            response.data['token_type']
        );
    }

    public async refreshToken(refreshToken: string): Promise<OAuth2Token> {
        const baseUrl: string = this.getConfig().authBaseUrl;
        const clientId: string = this.getConfig().authClientIdV2;

        const response = await this.getAxiosInstance().post(
            `${baseUrl}/oauth2/token`,
            new URLSearchParams({
                "grant_type": "refresh_token",
                "client_id": clientId,
                "refresh_token": refreshToken,
            })
        );

        if (response.status !== 200) {
            throw new Error(response.data);
        }

        return new OAuth2Token(
            response.data['access_token'],
            response.data['id_token'],
            refreshToken,
            response.data['expires_in'],
            response.data['token_type']
        );
    }
}
