
import {defineComponent} from "vue";
import {mapActions} from "vuex";
import {ContentOverviewRestDto} from "@/api/pharma-cms-content-block/models";
import {CmsDocumentRestService} from "@/services/rest/cms-document/CmsDocumentRestService";
import {StatusEnum} from "@/api/enums/status-enum";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";

export default defineComponent({
  name: "BannerSlotDetailPreviewBannerSlot",
  components: {BaseSpinner},
  emits: ["exceptions"],
  props: {
    positionCode: {
      type: String,
      required: true
    },
    isCurrentSubscription: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isReady: false as boolean,
      imageUrls: [] as string[],
    };
  },
  mounted() {
    this.searchBanners()
  },
  computed: {},
  methods: {
    ...mapActions('cms_content_block', ["findBannersInSlotsForSlot"]),
    async searchBanners(): Promise<void> {
      this.isReady = false;

      try {
        this.imageUrls = [];

        const payload = {
          type: 'BANNER_IN_SLOT',
          position_code: this.positionCode,
          filter_status_code: StatusEnum.PUBLISHED,
          filter_library_code: 'LIB_DP_BANNER_SLOTS',
        };
        const bannerOverview = await this.findBannersInSlotsForSlot(payload) as ContentOverviewRestDto[];

        const promises = [] as Promise<void>[];
        bannerOverview.forEach((banner: ContentOverviewRestDto) => {
          if (banner.image_key) {
            promises.push(this.findPreviewUrl(banner.image_key));
          }
        });
        await Promise.all(promises);
      } catch (exceptions: any) {
        this.$emit('exceptions', exceptions);
      }

      this.isReady = true;
    },
    async findPreviewUrl(documentKey: string): Promise<void> {
      this.imageUrls.push(await CmsDocumentRestService.getInstance().findPreviewUrl(documentKey));
    }
  }
});
