
import {defineComponent} from "vue";
import {arrayHasContent} from "@/helpers/functions/arrays";
import SelectCategoriesSelectedItem from "@/components/layouts/catalog/SelectCategoriesSelectedItem.vue";

export default defineComponent({
  name: "SelectCategoriesSelected",
  components: {SelectCategoriesSelectedItem},
  emits: ["removeCategory"],
  props: {
    selectedProductCategories: {
      type: Array,
      required: true,
    },
    catalogCustomerCode: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
  },
  computed: {
    hasSelectedProductCategories(): boolean {
      return arrayHasContent(this.selectedProductCategories);
    },
  },
  methods: {
    removeCategory(category_code: string) {
      this.$emit('removeCategory', category_code);
    },
  }
});
