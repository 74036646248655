
import {defineComponent, PropType} from "vue";

export default defineComponent({
  name: "BannerSlotDetailButtonSubscribeToSlot",
  emits: ["set"],
  props: {
    description: {
      type: String,
      required: true
    },
    slotId: {
      required: true,
      type: null as unknown as PropType<string | null>,
      validator: (v: any) => typeof v === 'string' || v === null,
    },
    currentSubscriptionId: {
      required: true,
      type: null as unknown as PropType<string | null>,
      validator: (v: any) => typeof v === 'string' || v === null,
    }
  },
  computed: {
    isActive(): boolean {
      return this.currentSubscriptionId === this.slotId;
    }
  },
  methods: {
    setSubscribeToSlotId() {
      this.$emit('set', this.slotId);
    }
  }
});
