
import {defineComponent} from "vue";

export default defineComponent({
  name: "BaseTabPane",
  props: {
    id: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    }
  }
});
