import {RgbRestDtoModel} from "@/models/api/pharma-cms-content-block/RgbRestDtoModel";

function componentToHex(c: number): string {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

function convertHexToRgb(hex: string): RgbRestDtoModel {
    hex = hex.replaceAll('#', '');

    if (hex.length !== 6) {
        throw "Only six-digit hex colors are allowed.";
    }

    const aRgbHex = hex.match(/.{1,2}/g) as string[];
    const red = parseInt(aRgbHex[0], 16);
    const green = parseInt(aRgbHex[1], 16);
    const blue = parseInt(aRgbHex[2], 16);

    return new RgbRestDtoModel(red, green, blue);
}

function convertRgbToHex(rgb: RgbRestDtoModel): string {
    return '#'
        + componentToHex(rgb.red)
        + componentToHex(rgb.green)
        + componentToHex(rgb.blue);
}

export {
    componentToHex,
    convertHexToRgb,
    convertRgbToHex,
}
