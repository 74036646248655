
import {defineComponent} from 'vue'
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {UIStateDto} from "@/dtos/UIStateDto";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import BaseCard from "@/components/UI/Bootstrap/Card/BaseCard.vue";
import BaseCardHeader from "@/components/UI/Bootstrap/Card/BaseCardHeader.vue";
import BaseCardBody from "@/components/UI/Bootstrap/Card/BaseCardBody.vue";
import {UtilityHelper} from "@/helpers/UtilityHelper";
import {FullProductInfoRestDtoModel} from "@/models/api/pharma-pim-mgmt/FullProductInfoRestDtoModel";
import {PimMgmtRestService} from "@/services/rest/pim-mgmt/PimMgmtRestService";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import {EnumClassNamePimEnum} from "@/models/enum/EnumClassNamePimEnum";
import {mapActions} from "vuex";
import {Tooltip} from "bootstrap";
import {useToast} from "vue-toastification";
import {PimFeedbackRestDtoModel} from "@/models/api/pharma-pim-mgmt/PimFeedbackRestDtoModel";
import PimFeedbackPanel from "@/components/layouts/pim/mgmt/PimFeedbackPanel.vue";

export default defineComponent({
  name: "ProductSelectionProductsProductDetail",
  components: {
    BaseCardBody,
    BaseCardHeader,
    BaseCard,
    BaseSpinner,
    AlertError2,
    PimFeedbackPanel,
  },
  props: {
    cnkCode: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      productInfoNl: FullProductInfoRestDtoModel.createWithDefaults(),
      isShowFeedbackForFragmentWithId: null as string | null,
      isShowGeneralFeedback: false,

      toaster: useToast(),
      tooltips: [] as Tooltip[],

      productUI: UIStateDto.createWithDefaults()
    }
  },
  watch: {
    cnkCode() {
      this.reloadContent();
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    UtilityHelper() {
      return UtilityHelper
    },
  },
  methods: {
    ...mapActions('pim_enum', ["findManyEnumByClassname"]),
    async reloadContent(): Promise<void> {
      this.productUI
        .setNotReady()
        .clearError();

      try {
        await Promise.all([
          this.findManyEnumByClassname([
                      EnumClassNamePimEnum.TEXT_FRAGMENT_NAME,
                      EnumClassNamePimEnum.PIM_PRODUCT_FIELD,
                    ]),
          await this.reloadPimContent(),
        ]);
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.productUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.productUI as UIStateDto))
          .catch(exceptions, true);
      } finally {
        this.productUI.setReady();
      }

    },
    async submitPimFeedback(feedback: PimFeedbackRestDtoModel): Promise<void> {
      this.productUI.clearError();

      try {
        await PimMgmtRestService.getInstance().createPimFeedback(this.cnkCode, 'nl', feedback);
        if (feedback.text_fragment_id != null) {
          this.toggleIsShowFeedbackForFragmentWithId(feedback.text_fragment_id);
        } else {
          this.isShowGeneralFeedback = false
        }

        await handleSavedSuccessfully();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }
    },
    async submitPimFeedbackCancel(fragmentId: string | null): Promise<void> {
      this.isShowGeneralFeedback = false
      if (fragmentId !== null) {
        this.toggleIsShowFeedbackForFragmentWithId(fragmentId);
      }
    },
    toggleIsShowFeedbackForFragmentWithId(fragmentId: string): void {
      if (this.isShowFeedbackForFragmentWithId === fragmentId) {
        this.isShowFeedbackForFragmentWithId = null;
      } else {
        this.isShowFeedbackForFragmentWithId = fragmentId;
      }
    },
    initializeTooltips(): void {
      document.querySelectorAll('#product-selection-products-product-detail [data-bs-toggle="tooltip"]').forEach(el => {
        const tooltip = new Tooltip(el as Element);
        this.tooltips.push(tooltip);
      });
    },
    hideTooltips(): void {
      this.tooltips.forEach(tt => {
        tt.hide();
      });
    },
    async reloadPimContent(): Promise<void> {
      this.productInfoNl = await PimMgmtRestService.getInstance()
        .findMgmtProductInfo(this.cnkCode, 'nl', 'PUBLIC_WEBSITE');
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.productUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.productUI as UIStateDto))
        .catch(exceptions, true);
    }
  },
})
