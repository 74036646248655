import {
    ProductDetailsRestDto,
    CodeDescriptionRestDto,
} from "@/api/pharma-pim-mgmt/models";

export class ProductDetailsRestDtoModel implements ProductDetailsRestDto {
    cnk_code?: number | null;
    commercial_name?: string | null;
    depth?: string | null;
    height?: string | null;
    name?: string | null;
    product_code: string;
    shape?: string | null;
    target_group?: CodeDescriptionRestDto | null;
    trademark?: CodeDescriptionRestDto | null;
    unit?: CodeDescriptionRestDto | null;
    volume?: string | null;
    weight?: string | null;
    width?: string | null;


    constructor(product_code: string) {
        this.product_code = product_code;
    }

    public static createWithDefaults(): ProductDetailsRestDtoModel {
        return new this('');
    }
}
