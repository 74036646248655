
import {defineComponent} from 'vue'
import PimProductsOverviewTableItem from "@/components/layouts/pim/PimProductsOverviewTableItem.vue";
import {UIStateDto} from "@/dtos/UIStateDto";
import {
  PagedPp2ProductOverviewResultsRestDtoModel
} from "@/models/api/pharma-pim-pp2/PagedPp2ProductOverviewResultsRestDtoModel";
import {AbilityContext} from "@/context/AbilityContext";
import {Pp2ProductOverviewRestDto} from "@/api/pharma-pim-pp2/models";
import {mapActions, mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import BasePlaceholderTable from "@/components/UI/Bootstrap/BasePlaceholderTable.vue";

export default defineComponent({
  name: "PimProductsOverviewTable",
  emits: ["deletedProduct", "selectedIntCat", "selectedDpCat", "exceptions", "openOffCanvasProductDetail"],
  components: {BasePlaceholderTable, PimProductsOverviewTableItem},
  props: {
    productsOverview: {
      type: [PagedPp2ProductOverviewResultsRestDtoModel, Object],
      required: true
    },
    hasProducts: {
      type: Boolean,
      required: true
    },
    isCategoryExpected: {
      type: Boolean,
      required: false
    },
    ui: {
      type: UIStateDto,
      required: true
    }
  },
  computed: {
    ...mapState(useCustomerStore, ["isPimProductSelectedForCategorization"]),
    AbilityContext() {
      return AbilityContext
    },
    allCnks(): number[] {
      const allCnks = [] as number[];

      this.productsOverview.results.forEach((product: Pp2ProductOverviewRestDto) => {
        if (product.product_info.cnk_code) {
          allCnks.push(product.product_info.cnk_code);
        }
      });

      return allCnks;
    },
    isAllProductsSelectedForCategorization: {
      get(): boolean {
        if (!this.hasProducts) {
          return false;
        }

        let isAllProductsSelectedForCategorization = true as boolean;

        this.allCnks.forEach((cnk_code: number) => {
          if (!this.isPimProductSelectedForCategorization(cnk_code)) {
            isAllProductsSelectedForCategorization = false;
            return;
          }
        });

        return isAllProductsSelectedForCategorization;
      },
      set(value: boolean): void {
        this.setPimProductsSelectedForCategorization(value, this.allCnks);
      },
    }
  },
  methods: {
    ...mapActions(useCustomerStore, ["setPimProductsSelectedForCategorization"]),
    onSelectedDpCat(dpCatCode: string): void {
      this.$emit('selectedDpCat', dpCatCode);
    },
    onSelectedIntCat(intCatCode: string): void {
      this.$emit('selectedIntCat', intCatCode);
    },
  }
})
