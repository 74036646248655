import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "link-picker btn-group btn-group-sm w-auto mb-2",
  role: "group",
  "aria-label": "Link type"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setLinkType('url', _ctx.typeOfLink))),
      class: _normalizeClass(['btn btn-outline-primary', {'active': _ctx.linkType === 'url'}]),
      type: "button"
    }, _toDisplayString(_ctx.$t('url')), 3),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setLinkType('page', _ctx.typeOfLink))),
      class: _normalizeClass(['btn btn-outline-primary', {'active': _ctx.linkType === 'page'}]),
      type: "button"
    }, _toDisplayString(_ctx.$t('page')), 3)
  ]))
}