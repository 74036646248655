
import {defineComponent} from 'vue';
import {PropOptions} from "vue-class-component";

export default defineComponent({
  name: "AlertError2",
  props: {
    error: {
      type: String,
      required: false,
      default: null
    } as PropOptions<string | null>
  },
});
