import {OrcaReservationCampaignApiService} from "@/main";
import {
    OrcaReservationCampaignContentRestDto,
    OrcaReservationCampaignOverviewRestDto,
    OrcaReservationCampaignRestDto,
    OrcaReservationCampaignRestDtoStatusCodeEnum,
    OrcaReservationCampaignRestDtoTypeCodeEnum, OrcaReservationCampaignTypeRestDto
} from "@/api/pharma-order-orca-customer-mgmt/models";
import {
    parseSavedReservationCampaign, parseSavedReservationCampaignContent,
    parseSearchedReservationCampaign, parseSearchedReservationCampaignContent
} from "@/store/pharma-order-orca-customer-mgmt/middleware";
import {CustomerContext} from "@/context/CustomerContext";

export default {
    namespaced: true,
    state() {
        return {
            reservation_campaign: {} as OrcaReservationCampaignRestDto,
            reservation_campaign_content: {} as OrcaReservationCampaignContentRestDto,
            reservation_campaign_overview: [] as OrcaReservationCampaignOverviewRestDto[],
        }
    },
    actions: {
        clearReservationCampaign(context: any) {
            context.commit("clearReservationCampaign");
        },
        clearReservationCampaignContent(context: any) {
            context.commit("clearReservationCampaignContent");
        },
        clearReservationCampaignPeriod(context: any) {
            context.commit("clearReservationCampaignPeriod");
        },
        async createReservationCampaign(context: any) {
            //TODO implement create when needed
            const reservation_campaign = parseSavedReservationCampaign(context.getters.getReservationCampaign);
            // const pharmacy_code = context.rootGetters["cms_pharmacy/getPharmacyCode"];

            console.log('Creating campaign');
            console.log(reservation_campaign);
            // await OrcaReservationCampaignApiService.createReservationCampaign(reservation_campaign, pharmacy_code);
        },
        async deleteReservationCampaign(context: any, id: string) {
            console.log('Deleting campaign with id: ' + id);
            //TODO implement delete when needed

            // const pharmacy_code = context.rootGetters["cms_pharmacy/getPharmacyCode"];
            // await OrcaReservationCampaignApiService.deleteReservationCampaign(pharmacy_code, id);
        },
        async searchReservationCampaign(context: any, id: string) {
            const pharmacy_code = CustomerContext.getPharmacyCode();
            const response = await OrcaReservationCampaignApiService.getReservationCampaign(pharmacy_code, id);

            context.commit("setReservationCampaign", parseSearchedReservationCampaign(response.data));
        },
        async searchReservationCampaignContent(context: any, id: string) {
            const pharmacy_code = CustomerContext.getPharmacyCode();
            const response = await OrcaReservationCampaignApiService.getReservationCampaignContent(pharmacy_code, id);

            context.commit("setReservationCampaignContent", parseSearchedReservationCampaignContent(response.data));
        },
        async searchReservationCampaignOverview(context: any) {
            const pharmacy_code = CustomerContext.getPharmacyCode();
            const response = await OrcaReservationCampaignApiService.findReservationCampaigns(pharmacy_code);

            context.commit("setReservationCampaignOverview", response.data);
        },
        async saveReservationCampaign(context: any, id: string) {
            const reservation_campaign = parseSavedReservationCampaign(context.getters.getReservationCampaign, context.getters.getReservationCampaignContent);
            const pharmacy_code = CustomerContext.getPharmacyCode();

            await OrcaReservationCampaignApiService.updateReservationCampaign(reservation_campaign, pharmacy_code, id);
        },
        async saveReservationCampaignContent(context: any, id: string) {
            const reservation_campaign_content = parseSavedReservationCampaignContent(context.getters.getReservationCampaignContent, context.getters.getReservationCampaign);
            const pharmacy_code = CustomerContext.getPharmacyCode();

            await OrcaReservationCampaignApiService.synchReservationCampaignContent(reservation_campaign_content, pharmacy_code, id);
        },
        async createCampaignWithDefaults(context: any, typeCode: string) {
            const typeDto = {
                type_code : typeCode
            } as OrcaReservationCampaignTypeRestDto;
            const pharmacy_code = CustomerContext.getPharmacyCode();

            await OrcaReservationCampaignApiService.createReservationCampaignWithDefaults(typeDto, pharmacy_code);
        },
    },
    mutations: {
        clearReservationCampaign(state: any) {
            state.reservation_campaign = {
                name: null,
                description: '',
                type_code: OrcaReservationCampaignRestDtoTypeCodeEnum.FLUVACCINE,
                begin_date: null,
                end_date: null,
                is_patient_product_input_enabled: false,
                status_code: OrcaReservationCampaignRestDtoStatusCodeEnum.NEW,
            };
        },
        clearReservationCampaignContent(state: any) {
            state.reservation_campaign_content = {
                title: null,
                menu_label: null,
                description_before: '',
                description_during: '',
                description_after: '',
                remark_description: '',
                extensive_explanation: '',
                ext_agenda_url: null,
                ext_agenda_email_notifications_description: '',
                ext_agenda_sms_notifications_description: '',
                ext_agenda_webpage_description: '',
            };
        },
        clearReservationCampaignPeriod(state: any) {
            state.reservation_campaign.begin_date = null;
            state.reservation_campaign.end_date = null;
        },
        setReservationCampaign(state: any, reservation_campaign: OrcaReservationCampaignRestDto) {
            state.reservation_campaign = reservation_campaign;
        },
        setReservationCampaignContent(state: any, reservation_campaign_content: OrcaReservationCampaignContentRestDto) {
            state.reservation_campaign_content = reservation_campaign_content;
        },
        setReservationCampaignOverview(state: any, reservation_campaign_overview: OrcaReservationCampaignOverviewRestDto[]) {
            state.reservation_campaign_overview = reservation_campaign_overview;
        },
    },
    getters: {
        getReservationCampaign(state: any): OrcaReservationCampaignRestDto {
            return state.reservation_campaign;
        },
        getReservationCampaignContent(state: any): OrcaReservationCampaignContentRestDto {
            return state.reservation_campaign_content;
        },
        getReservationCampaignOverview(state: any): OrcaReservationCampaignOverviewRestDto[] {
            return state.reservation_campaign_overview;
        },
    }
}
