
import {Exception} from '@/api/interfaces';
import store from '@/store';
import {defineComponent} from "vue";
import {mapGetters} from 'vuex';

import {defineRule} from "vee-validate";
import MultilingualSwitcher from "@/components/UI/MultilingualSwitcher.vue";
import {useToast} from "vue-toastification";
import I18nInputText from "@/components/UI/I18n/I18nInputText.vue";
import {ClosingPeriodRestDtoModel} from "@/models/ClosingPeriodRestDtoModel";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {I18nContext} from "@/context/I18nContext";
import {mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";

defineRule("check_date", (value: any, [begin, end]: any) => {
  if (begin > end) {
    return 'De einddatum moet gelijk aan of later zijn';
  }
  return true;
});

export default defineComponent({
  name: "ClosingPeriodsDetail",
  components: {BaseTitle, I18nInputText, MultilingualSwitcher},
  props: ['mode'],
  data: () => ({
    isReady: false as boolean,

    exceptions: [] as Exception[],
    savedSuccessfully: false,
    existingDocKey: false,
    pharmacyCode: '' as string,
    statusOptions: [],

    closingPeriod: ClosingPeriodRestDtoModel.createWithDefaults(),
    activeLanguage: I18nContext.getDefaultApiLanguageKey(),

    toaster: useToast(),
  }),
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cms_pharmacy', ["getClosingPeriod", "getClosingPeriod"]),
    ...mapState(useCustomerStore, ["getPharmacyCode"]),
  },
  methods: {
    submitData() {
      if (this.mode === 'new') {
        this.createClosingPeriod();
      } else {
        this.saveClosingPeriod();
      }
    },
    async reloadContent() {
      this.isReady = false;

      try {
        this.pharmacyCode = this.getPharmacyCode;

        if (this.mode === 'new') {
          this.closingPeriod = ClosingPeriodRestDtoModel.createWithDefaults();
        } else {
          await this.searchClosingPeriod();
          this.closingPeriod = this.getClosingPeriod;
        }

        this.isReady = true;
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    copyToEndDate() {
      if (this.closingPeriod.begin.length === 10 && this.closingPeriod.end.length < 10) {
        this.closingPeriod.end = this.closingPeriod.begin;
      }
    },
    async createClosingPeriod() {
      try {
        const payload = {
          closingPeriod: this.closingPeriod,
          pc: this.pharmacyCode,
        }
        await store.dispatch('cms_pharmacy/createClosingPeriod', payload);

        this.toaster.success(this.$t('savedSuccessfully'));

        await this.$router.push({
          name: 'closing-periods',
        });
      } catch (exceptions: any) {
        this.exceptions = exceptions
      }
    },
    async searchClosingPeriod() {
      try {
        const payload =
          {
            id: this.$route.params.id,
            pc: this.pharmacyCode,
          }
        await store.dispatch('cms_pharmacy/searchClosingPeriod', payload);
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async saveClosingPeriod() {
      try {
        let cp_from_store = this.getClosingPeriod;
        const payload = {
          cp: cp_from_store,
          pc: this.pharmacyCode,
          cp_id: this.$route.params.id,
        }
        await store.dispatch('cms_pharmacy/saveClosingPeriod', payload);

        this.toaster.success(this.$t('savedSuccessfully'));

        await this.$router.push({
          name: 'closing-periods',
        });
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    }
  }
});
