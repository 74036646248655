

import {defineComponent} from "vue";

export default defineComponent({
  name: "BaseModal",
  props: {
    modalId: {
      type: String,
      required: false,
      default: 'baseModal',
    },
    dialogClasses: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    isStatic: {
      type: Boolean,
      required: false,
      default: false,
    },
    noHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    noFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    closeButton: null as HTMLElement | null,
  }),
  computed: {
    staticAttribute() {
      if (!this.isStatic) {
        return null;
      }

      return {'data-bs-backdrop': 'static'}
    },
  },
  methods: {
    close() {
      if (this.$refs.closeButton) {
        (this.$refs.closeButton as any).clickClose(); // Ensure TypeScript recognizes this
      }
    }
  }
});
