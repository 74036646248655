import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mb-4" }
const _hoisted_3 = { class: "row row-title align-items-end" }
const _hoisted_4 = { class: "col-2" }
const _hoisted_5 = { class: "col-10" }
const _hoisted_6 = { class: "row row-content mb-4" }
const _hoisted_7 = { class: "row row-product-filter" }
const _hoisted_8 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_InputSelect = _resolveComponent("InputSelect")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputTextEditor = _resolveComponent("InputTextEditor")!
  const _component_Subtitle = _resolveComponent("Subtitle")!
  const _component_ProductFilterPicker = _resolveComponent("ProductFilterPicker")!
  const _component_dp_form = _resolveComponent("dp-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseSpinner, {
      spin: !_ctx.isReady
    }, null, 8, ["spin"]),
    (_ctx.isReady)
      ? (_openBlock(), _createBlock(_component_dp_form, {
          key: 0,
          as: "form",
          onSubmit: _ctx.submitData,
          novalidate: ""
        }, {
          default: _withCtx(({ errors }) => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_InputSelect, {
                      modelValue: this.contentBlock.title_size_code,
                      "onUpdate:modelValue": [
                        _cache[0] || (_cache[0] = ($event: any) => ((this.contentBlock.title_size_code) = $event)),
                        _ctx.onChange
                      ],
                      options: this.enumTitleSizeCode,
                      label: _ctx.$t('size'),
                      name: "size",
                      selectText: _ctx.$t('selectText'),
                      errors: errors
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "label", "selectText", "errors"])
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_InputText, {
                      modelValue: this.contentBlock.title,
                      "onUpdate:modelValue": [
                        _cache[1] || (_cache[1] = ($event: any) => ((this.contentBlock.title) = $event)),
                        this.onChange
                      ],
                      label: _ctx.$t('title'),
                      name: "title",
                      errors: errors
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "errors"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_InputTextEditor, {
                    modelValue: this.contentBlock.content,
                    "onUpdate:modelValue": [
                      _cache[2] || (_cache[2] = ($event: any) => ((this.contentBlock.content) = $event)),
                      this.onChange
                    ],
                    label: _ctx.$t('content'),
                    name: "content",
                    class: "col-12",
                    errors: errors
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "errors"])
                ]),
                _createVNode(_component_Subtitle, {
                  title: _ctx.$t('productFilter'),
                  mb: "3"
                }, null, 8, ["title"]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_ProductFilterPicker, {
                      payload: this.productFilterPickerPayload,
                      "product-filter": this.productFilter,
                      errors: errors,
                      onChanged: _ctx.onProductFilterPickerChanged
                    }, null, 8, ["payload", "product-filter", "errors", "onChanged"])
                  ])
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["onSubmit"]))
      : _createCommentVNode("", true)
  ], 64))
}