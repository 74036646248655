
import {defineComponent} from "vue";
import LibContentBlocksItem from "@/components/layouts/lib-content-blocks/LibContentBlocksItem.vue";
import {ContentOverviewRestDto} from "@/api/pharma-cms-content-block/models";
import {arrayHasContent} from "@/helpers/functions/arrays";
import {StatusEnum} from "@/api/enums/status-enum";
import {CodeDescriptionRestDtoModel} from "@/models/CodeDescriptionRestDtoModel";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import {ContentBlockStructureEnum} from "@/models/enum/ContentBlockStructureEnum";
import {
  NormalContentBlockRestService
} from "@/services/rest/cms-content-block/NormalContentBlockRestService";
import {
  ContentBlockLibraryRestService
} from "@/services/rest/cms-content-block/ContentBlockLibraryRestService";
import {ContentBlockLinkRestService} from "@/services/rest/cms-content-block/ContentBlockLinkRestService";

export default defineComponent({
  name: "LibContentBlocks",
  components: {BaseSpinner, LibContentBlocksItem},
  emits: ["copied", "exceptions"],
  props: {
    bannerSlotId: {
      type: String,
      required: false
    },
    bannerLibType: {
      type: String,
      required: false
    },
    cbType: {
      type: String,
      required: true,
    },
    library: {
      type: [CodeDescriptionRestDtoModel, Object],
      required: true,
    },
    srcLibCode: {
      type: String,
      required: false,
      default: null
    }
  },
  data: () => ({
    isReady: false as boolean,
    libraryContentBlocks: [] as any[]
  }),
  mounted() {
    this.reloadContent();
  },
  computed: {
    libType(): string {
      if (this.isBannerForSlot) {
        return ContentBlockStructureEnum.BANNER_IN_SLOT;
      } else if (this.library.code === 'LIB_DP_BANNERS_VOOR_DP_SLOTS' || this.library.code === 'LIB_DP_BANNERS_ALG' || this.library.code === 'LIB_FARMADA_BANNERS' || this.library.code.toUpperCase().includes('BANNERS')) {
        return ContentBlockStructureEnum.BANNER;
      } else if (this.library.code === 'LIB_DP_PHARMACY_LOGO' || this.library.code === 'LIB_DP_PHARMACY_WEBSITE_MAIN_IMG' || this.library.code.toUpperCase().includes('IMAGES')) {
        return ContentBlockStructureEnum.IMAGE;
      } else if (this.library.code === 'LIB_DP_CB_TEXT_WITH_IMG_ALG' || this.library.code === 'LIB_DP_CB_TEXT_WITH_IMG_EXT_APP' || this.library.code === 'LIB_DP_CB_TEXT_WITH_IMG_FARMADA' || this.library.code.toUpperCase().includes('TWI')) {
        return ContentBlockStructureEnum.TEXT_WITH_IMAGE;
      } else if (this.library.code === 'LIB_DP_WELCOME_TXT' || this.library.code.toUpperCase().includes('TWT')) {
        return ContentBlockStructureEnum.TEXT_WITH_TITLE;
      } else {
        return '';
      }
    },
    hasContentBlocks(): boolean {
      return arrayHasContent(this.libraryContentBlocks);
    },
    isBannerForSlot(): boolean {
      return this.cbType.toUpperCase().endsWith('BANNER_IN_SLOT');
    },
    isBannerForSlotLibrary(): boolean {
      return !!this.bannerLibType && this.bannerLibType.toUpperCase().startsWith('LIB');
    },
    isLibrary(): boolean {
      return !!this.srcLibCode;
    }
  },
  methods: {
    async reloadContent() {
      this.isReady = false;

      try {
        if (this.isBannerForSlot) {
          this.libraryContentBlocks = await NormalContentBlockRestService.getInstance()
            .findContentBlockOverview(this.bannerLibType, StatusEnum.PUBLISHED, this.isBannerForSlotLibrary ? this.library.code : undefined);
        } else {
          this.libraryContentBlocks = await ContentBlockLibraryRestService.getInstance()
            .findLibraryContentBlocks(this.library.code);
        }
      } catch (exceptions: unknown) {
        this.$emit('exceptions', exceptions);
      }

      this.isReady = true;
    },
    async copyContentBlock(id: string) {
      try {
        if (this.isLibrary) {
          await ContentBlockLibraryRestService.getInstance()
            .createLibContentBlockFrom(this.srcLibCode, id);
        } else if (this.isBannerForSlot) {
          const bannerInSlot = this.libraryContentBlocks
            .find((banner: ContentOverviewRestDto) => banner.id === id) as unknown as ContentOverviewRestDto;
          const contentBlock = {
            status_code: bannerInSlot?.status?.code,
            sort_weight: bannerInSlot.sort_weight,
            banner_slot_id: this.bannerSlotId,
            banner_id: bannerInSlot.id,
            //todo check if metadata needs to be included
            // cb_metadata: bannerInSlot.cb_metadata,
          };
          await NormalContentBlockRestService.getInstance()
            .createNormalContentBlockBannerInSlot(contentBlock, this.cbType);
        } else {
          await ContentBlockLinkRestService.getInstance()
            .createContentBlockLink(id, this.cbType, undefined, this.libType === 'BANNER');
        }

        this.$emit('copied');
      } catch (exceptions: unknown) {
        this.$emit('exceptions', exceptions);
      }
    },
  },
});
