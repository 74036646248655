import {convertHexToRgb, convertRgbToHex} from "@/helpers/functions/color";
import {ColorRestDtoModel} from "@/models/api/ColorRestDtoModel";

export class ColorPickerPayload {
    colorRestDto: ColorRestDtoModel;
    hex: string;

    constructor(colorDto: ColorRestDtoModel) {
        this.colorRestDto = colorDto;
        this.hex = convertRgbToHex(colorDto.rgb);
    }

    public static createWithDefaults(): ColorPickerPayload {
        return new this(ColorRestDtoModel.createWithDefaults());
    }

    public static cloneFrom(payload: ColorPickerPayload): ColorPickerPayload {
        return new this(payload.colorRestDto);
    }

    public setRGB(hex: string): void {
        this.colorRestDto.rgb = convertHexToRgb(hex);
    }
}
