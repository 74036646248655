import {RestService} from "@/services/rest/RestService";
import {HeaderEndpointsApi} from "@/api/pharma-cms-header";
import {
    CmsHeaderContent0RestDto,
    CmsHeaderContent1RestDto,
    HeaderOverviewRestDto,
} from "@/api/pharma-cms-header/models";

export class HeaderRestService extends RestService {

    private apiService: HeaderEndpointsApi;

    protected constructor() {
        super();

        this.apiService = new HeaderEndpointsApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cmsUrl);
    }

    public async findHeaderStackOverview(): Promise<HeaderOverviewRestDto[]> {
        const response = await this.apiService.getHeaderStackOverview();

        return (response.data.results) ?? [];
    }

    public async findHeaderContent0(id: string): Promise<CmsHeaderContent0RestDto> {
        const response = await this.apiService.getHeaderContent0(id);

        return response.data;
    }

    public async findHeaderContent1(id: string): Promise<CmsHeaderContent1RestDto> {
        const response = await this.apiService.getHeaderContent1(id);

        return response.data;
    }

    public async updateHeaderContent0(id: string, body: CmsHeaderContent0RestDto): Promise<void> {
        const response = await this.apiService.updateHeaderContent0(body, id);

        return response.data;
    }

    public async updateHeaderContent1(id: string, body: CmsHeaderContent1RestDto): Promise<void> {
        const response = await this.apiService.updateHeaderContent1(body, id);

        return response.data;
    }
}
