/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-PIM Enum API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CodeDescriptionRestDto } from '../models';
/**
 * EnumEndpointsApi - axios parameter creator
 * @export
 */
export const EnumEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.PIM.ENUM.01 Find enum values for a specified classname.
         * @param {string} enum_class_name Identifies the enum values that are requested.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPimEnumValuesByClassname: async (enum_class_name: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'enum_class_name' is not null or undefined
            if (enum_class_name === null || enum_class_name === undefined) {
                throw new RequiredError('enum_class_name','Required parameter enum_class_name was null or undefined when calling getPimEnumValuesByClassname.');
            }
            const localVarPath = `/enum/v1/enum/by_classname/{enum_class_name}`
                .replace(`{${"enum_class_name"}}`, encodeURIComponent(String(enum_class_name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnumEndpointsApi - functional programming interface
 * @export
 */
export const EnumEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.PIM.ENUM.01 Find enum values for a specified classname.
         * @param {string} enum_class_name Identifies the enum values that are requested.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPimEnumValuesByClassname(_axios: AxiosInstance, enum_class_name: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CodeDescriptionRestDto>>> {
            const localVarAxiosArgs = await EnumEndpointsApiAxiosParamCreator(configuration).getPimEnumValuesByClassname(enum_class_name, x_dp_customer_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * EnumEndpointsApi - object-oriented interface
 * @export
 * @class EnumEndpointsApi
 * @extends {BaseAPI}
 */
export class EnumEndpointsApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.PIM.ENUM.01 Find enum values for a specified classname.
     * @param {string} enum_class_name Identifies the enum values that are requested.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumEndpointsApi
     */
     public getPimEnumValuesByClassname(enum_class_name: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any) {
        return EnumEndpointsApiFp(this.configuration).getPimEnumValuesByClassname(this.axios, enum_class_name, x_dp_customer_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

}
