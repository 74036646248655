/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Pharmacy API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CodeDescriptionRestDto } from '../models';
import { PharmacyCodeResponseRestDto } from '../models';
import { PharmacyRestDto } from '../models';
/**
 * PharmacyInformationEndpointsApi - axios parameter creator
 * @export
 */
export const PharmacyInformationEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.PH.01 Create pharmacy information.
         * @param {PharmacyRestDto} body 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPharmacy: async (body: PharmacyRestDto, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createPharmacy.');
            }
            const localVarPath = `/pharmacy/v1/pharmacy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.04 Delete pharmacy information.
         * @param {string} pharmacy_code The unique identifier of this pharmacy.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePharmacy: async (pharmacy_code: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling deletePharmacy.');
            }
            const localVarPath = `/pharmacy/v1/pharmacy/{pharmacy_code}`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.14 Get all pharmacies of a customer.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [filter_is_in_group] Filter the results to the pharmacies of this customer and customers in the group with this customer as a content provider of the group.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPharmacies: async (x_dp_customer_code?: string, filter_is_in_group?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/pharmacy/v1/pharmacy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_is_in_group || typeof(filter_is_in_group) === "boolean") {
                localVarQueryParameter['filter_is_in_group'] = filter_is_in_group;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.03 Get pharmacy information.
         * @param {string} pharmacy_code The unique identifier of this pharmacy.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPharmacy: async (pharmacy_code: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling getPharmacy.');
            }
            const localVarPath = `/pharmacy/v1/pharmacy/{pharmacy_code}`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.02 Update pharmacy information.
         * @param {PharmacyRestDto} body 
         * @param {string} pharmacy_code The unique identifier of this pharmacy.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePharmacy: async (body: PharmacyRestDto, pharmacy_code: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updatePharmacy.');
            }
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling updatePharmacy.');
            }
            const localVarPath = `/pharmacy/v1/pharmacy/{pharmacy_code}`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PharmacyInformationEndpointsApi - functional programming interface
 * @export
 */
export const PharmacyInformationEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.PH.01 Create pharmacy information.
         * @param {PharmacyRestDto} body 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPharmacy(_axios: AxiosInstance, body: PharmacyRestDto, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PharmacyCodeResponseRestDto>> {
            const localVarAxiosArgs = await PharmacyInformationEndpointsApiAxiosParamCreator(configuration).createPharmacy(body, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.04 Delete pharmacy information.
         * @param {string} pharmacy_code The unique identifier of this pharmacy.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePharmacy(_axios: AxiosInstance, pharmacy_code: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PharmacyInformationEndpointsApiAxiosParamCreator(configuration).deletePharmacy(pharmacy_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.14 Get all pharmacies of a customer.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [filter_is_in_group] Filter the results to the pharmacies of this customer and customers in the group with this customer as a content provider of the group.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPharmacies(_axios: AxiosInstance, x_dp_customer_code?: string, filter_is_in_group?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CodeDescriptionRestDto>>> {
            const localVarAxiosArgs = await PharmacyInformationEndpointsApiAxiosParamCreator(configuration).findPharmacies(x_dp_customer_code, filter_is_in_group, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.03 Get pharmacy information.
         * @param {string} pharmacy_code The unique identifier of this pharmacy.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPharmacy(_axios: AxiosInstance, pharmacy_code: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PharmacyRestDto>> {
            const localVarAxiosArgs = await PharmacyInformationEndpointsApiAxiosParamCreator(configuration).getPharmacy(pharmacy_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.PH.02 Update pharmacy information.
         * @param {PharmacyRestDto} body 
         * @param {string} pharmacy_code The unique identifier of this pharmacy.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePharmacy(_axios: AxiosInstance, body: PharmacyRestDto, pharmacy_code: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PharmacyInformationEndpointsApiAxiosParamCreator(configuration).updatePharmacy(body, pharmacy_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PharmacyInformationEndpointsApi - object-oriented interface
 * @export
 * @class PharmacyInformationEndpointsApi
 * @extends {BaseAPI}
 */
export class PharmacyInformationEndpointsApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.CMS.PH.01 Create pharmacy information.
     * @param {PharmacyRestDto} body 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PharmacyInformationEndpointsApi
     */
     public createPharmacy(body: PharmacyRestDto, x_dp_customer_code?: string, options?: any) {
        return PharmacyInformationEndpointsApiFp(this.configuration).createPharmacy(this.axios, body, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.PH.04 Delete pharmacy information.
     * @param {string} pharmacy_code The unique identifier of this pharmacy.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PharmacyInformationEndpointsApi
     */
     public deletePharmacy(pharmacy_code: string, x_dp_customer_code?: string, options?: any) {
        return PharmacyInformationEndpointsApiFp(this.configuration).deletePharmacy(this.axios, pharmacy_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.PH.14 Get all pharmacies of a customer.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {boolean} [filter_is_in_group] Filter the results to the pharmacies of this customer and customers in the group with this customer as a content provider of the group.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PharmacyInformationEndpointsApi
     */
     public findPharmacies(x_dp_customer_code?: string, filter_is_in_group?: boolean, options?: any) {
        return PharmacyInformationEndpointsApiFp(this.configuration).findPharmacies(this.axios, x_dp_customer_code, filter_is_in_group, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.PH.03 Get pharmacy information.
     * @param {string} pharmacy_code The unique identifier of this pharmacy.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PharmacyInformationEndpointsApi
     */
     public getPharmacy(pharmacy_code: string, x_dp_customer_code?: string, options?: any) {
        return PharmacyInformationEndpointsApiFp(this.configuration).getPharmacy(this.axios, pharmacy_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.PH.02 Update pharmacy information.
     * @param {PharmacyRestDto} body 
     * @param {string} pharmacy_code The unique identifier of this pharmacy.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PharmacyInformationEndpointsApi
     */
     public updatePharmacy(body: PharmacyRestDto, pharmacy_code: string, x_dp_customer_code?: string, options?: any) {
        return PharmacyInformationEndpointsApiFp(this.configuration).updatePharmacy(this.axios, body, pharmacy_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
