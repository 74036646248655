
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {CodeDescriptionRestDto, MultilingualFieldRestDto, ShoppingCartDiscountSchemeRestDto,} from "@/api/pharma-cpc-mgmt/models";
import {defineRule} from "vee-validate";
import CatalogBuildStatus from "@/components/layouts/catalog/CatalogBuildStatus.vue";
import DiscountPricelistSection from "@/components/layouts/catalog/discounts-pricelists/DiscountPriceListSection.vue";
import ProductFilters from "@/components/layouts/catalog/product-filter/ProductFilters.vue";
import MultilingualSwitcher from "@/components/UI/MultilingualSwitcher.vue";
import {I18nContext} from "@/context/I18nContext";
import {useToast} from "vue-toastification";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import {isEmptyString} from "@/helpers/functions/string";
import {ProductFilterApplyTypeEnum} from "@/models/enum/ProductFilterApplyTypeEnum";
import ButtonProductFilterPreview from "@/components/layouts/catalog/ButtonProductFilterPreview.vue";
import {mapActions as mapActionsP} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import {StatusEnum} from "@/api/enums/status-enum";
import {EnumClassNameCpcEnum} from "@/models/enum/EnumClassNameCpcEnum";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {UIStateDto} from "@/dtos/UIStateDto";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import I18nInputText from "@/components/UI/I18n/I18nInputText.vue";
import {ShoppingCartDiscountTypeEnum} from "@/api/enums/shopping-cart-discount-type-enum";
import {ShoppingCartDiscountSchemeRestService} from "@/services/rest/cpc-mgmt/ShoppingCartDiscountSchemeRestService";
import {UtilityRestService} from "@/services/rest/cms-utility/UtilityRestService";
import {EnumRestService} from "@/services/rest/enum/EnumRestService";

defineRule("check_date", (value: any, [begin, end]: any) => {
  if ((begin && end) && begin > end) {
    return 'De einddatum moet gelijk aan of later zijn';
  }
  return true;
});

export default defineComponent({
  name: "ShoppingCartDiscountsDetail",
  components: {
    I18nInputText,
    AlertError2,
    ButtonProductFilterPreview,
    BaseTitle,
    MultilingualSwitcher,
    ProductFilters,
    DiscountPricelistSection,
    CatalogBuildStatus
  },
  props: ['mode'],
  data() {
    return {
      id: this.$route.params.id,
      returnRoute: 'discounts-price-lists' as string,

      promoMessageTimeoutId: null as number | null,
      promoMessage: MultilingualRestDtoModel.createWithDefaults(),

      activeLanguage: I18nContext.getDefaultApiLanguageKey(),

      toaster: useToast(),
      pageUI: UIStateDto.createWithDefaults(),
      discountTypeOptions: [] as CodeDescriptionRestDto[],
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cpc_mgmt', ['getShoppingCartDiscountScheme']),
    ...mapGetters('idm_user', ['getClientGroups']),
    ProductFilterApplyTypeEnum() {
      return ProductFilterApplyTypeEnum
    },
    discountScheme(): ShoppingCartDiscountSchemeRestDto {
      return this.getShoppingCartDiscountScheme;
    },
    discountLabel(): string {
      if (this.discountScheme.discount_value && this.discountScheme.discount_value > 0) {
        if (this.discountScheme.type_code === ShoppingCartDiscountTypeEnum.PERCENTAGE_FOR_SHOPPING_CART_PART) {
          return `- ${this.discountScheme.discount_value}%`
        }
        return `€ -${this.discountScheme.discount_value}`
      }
      return '';
    },
    statusOptions(): CodeDescriptionRestDto[] {
      return [
        {
          code: StatusEnum.PUBLISHED,
          description: this.$t('published'),
        },
        {
          code: StatusEnum.NOT_PUBLISHED,
          description: this.$t('notPublished'),
        },
      ];
    },
    clientGroups(): CodeDescriptionRestDto[] {
      return [
        {
          code: '',
          description: this.$t('none'),
        }
      ].concat(this.getClientGroups);
    },
  },
  methods: {
    ...mapActions('cpc_mgmt', [
      'clearShoppingCartDiscountScheme',
      'clearShoppingCartDiscountSchemePeriod',
      'setShoppingCartDiscountSchemeValue',
      'searchShoppingCartDiscountScheme',
    ]),
    ...mapActions('idm_user', ['searchClientGroups']),
    ...mapActionsP(useCustomerStore, ["searchAllBrandsCustomer", "searchAllTrademarksCustomer", "searchAllCategoriesCustomer", "searchProductCategoriesTreeCustomer"]),
    isEmptyString,
    async submitData() {
      this.pageUI
        .clearError()
        .setNotReady();

      try {
        if (this.mode === 'new') {
          await ShoppingCartDiscountSchemeRestService.getInstance().createShoppingCartDiscountScheme(this.discountScheme);
        } else {
          await ShoppingCartDiscountSchemeRestService.getInstance().updateShoppingCartDiscountScheme(this.id, this.discountScheme);
        }

        await this.afterSave();
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
        window.scrollTo(0, 0);
      } finally {
        this.pageUI.setReady();
      }
    },
    async reloadContent() {
      this.pageUI
        .clearError()
        .setNotReady();

      try {
        this.discountTypeOptions = await EnumRestService.getInstance().getCpcEnumValuesByClassname(EnumClassNameCpcEnum.SHOPPING_CART_DISCOUNT_TYPE);
        if (this.mode === 'new') {
          this.clearShoppingCartDiscountScheme();

          await Promise.all([
            this.searchClientGroups(),
          ]);
        } else {
          await Promise.all([
            this.searchShoppingCartDiscountScheme(this.id),
            this.searchClientGroups(),
          ]);
        }
        this.promoMessageUpdated(this.discountScheme.promo_message);
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
        window.scrollTo(0, 0);
      } finally {
        this.pageUI.setReady();
      }
    },
    async afterSave() {
      if (this.mode === 'new') {
        await this.$router.push({name: this.returnRoute});
      } else {
        this.toaster.success(this.$t('savedSuccessfully'));

        await this.reloadContent();
      }
    },
    promoMessageUpdated(value: any): void {
      this.discountScheme.promo_message = value;

      if (this.promoMessageTimeoutId !== null) {
        clearTimeout(this.promoMessageTimeoutId);
      }

      this.promoMessageTimeoutId = setTimeout(() => {
        Object.keys(this.promoMessage).forEach(async (key: string) => {
          if (this.discountScheme.promo_message !== null && this.discountScheme.promo_message !== undefined) {
            let value = this.discountScheme.promo_message[key as keyof MultilingualFieldRestDto];
            if (!isEmptyString(value)) {
              value = await UtilityRestService.getInstance()
                .truncateHtml(value, 100);
            }

            this.promoMessage.setPropValue(key, value);
          }
        });
      }, 1000);
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .catch(exceptions, true);
    }
  },
});
