import {CustomerProductPriceRestDto} from "@/api/pharma-cpc-product-mgmt/models";

export class CustomerProductPriceRestDtoModel implements CustomerProductPriceRestDto {
    standard_price_with_vat: number;
    vat_percentage: number;

    constructor() {
        this.standard_price_with_vat = 0;
        this.vat_percentage = 0;
    }

    public static createWithDefaults(standard_price_with_vat: number, vat_percentage: number): CustomerProductPriceRestDtoModel {
        const result = new this();
        result.standard_price_with_vat = standard_price_with_vat;
        result.vat_percentage = vat_percentage;

        return result;
    }
}
