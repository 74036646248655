<template>
  <img v-if="src" class="card-img-top"
       :src="src"
       :alt="alt"
  >
</template>

<script>
export default {
  name: "BaseCardImage",
  props: {
    alt: {
      type: String,
      required: false,
      default: 'image',
    },
    src: {
      type: String,
      required: false,
    },
  }
};
</script>