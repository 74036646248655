import {CpcProductFiltersEndpointsApiService} from "@/main";
import {
    CnkCodesResponseRestDto, CodeResponseRestDto,
    ProductFilterCopySourceRestDto, ProductFilterOverviewRestDto,
    ProductFilterRestDto
} from "@/api/pharma-cpc-mgmt/models";
import {
    emptyProductFilter,
} from "@/store/pharma-cpc-mgmt/middleware";

export default {
    state() {
        return {
            product_filter_cnk_list: {} as CnkCodesResponseRestDto,
            product_filter_complex: {} as ProductFilterRestDto,
            product_filter_overview: [] as ProductFilterOverviewRestDto[],
        };
    },
    actions: {
        clearComplexProductFilter(context: any) {
            context.commit("clearComplexProductFilter");
        },
        async copyProductFilter(context: any, product_filter_copy_source: ProductFilterCopySourceRestDto): Promise<CodeResponseRestDto> {
            const response = await CpcProductFiltersEndpointsApiService.copyProductFilter(product_filter_copy_source);

            return response.data;
        },
        async deleteComplexProductFilter(context: any, filter_code: string) {
            await CpcProductFiltersEndpointsApiService.deleteProductFilter(filter_code);
        },
        async searchCnkListProductFilter(context: any, filter_code: string) {
            const response = await CpcProductFiltersEndpointsApiService.findCnkCodesOfProductFilter(filter_code);

            context.commit('setCnkListProductFilter', {
                body: response.data,
                filter_code: filter_code,
            });
        },
        async searchComplexProductFilter(context: any, filter_code: string) {
            const response = await CpcProductFiltersEndpointsApiService.findComplexProductFilter(filter_code);

            context.commit('setComplexProductFilter', {
                body: response.data,
                filter_code: filter_code,
            });
        },
        async searchProductFilterOverview(context: any, payload: any) {
            const is_named = payload?.is_named ?? undefined as boolean | undefined;
            const is_precalculated = payload?.is_precalculated ?? undefined as boolean | undefined;

            const response = await CpcProductFiltersEndpointsApiService.findProductFilters(undefined, is_named, is_precalculated);

            context.commit('setProductFilterOverview', response.data);
        },
        async saveCnkListProductFilter(context: any, payload: any) {
            await CpcProductFiltersEndpointsApiService.syncProductFilter(payload.body, payload.filter_code);
        },
        async saveComplexProductFilter(context: any, filter_code: string) {
            await CpcProductFiltersEndpointsApiService.syncComplexProductFilter(context.getters.getComplexProductFilter, filter_code);
        },
    },
    mutations: {
        clearComplexProductFilter(state: any) {
            state.product_filter_complex = emptyProductFilter();
        },
        setCnkListProductFilter(state: any, payload: any) {
            state.product_filter_cnk_list = payload.body;
        },
        setComplexProductFilter(state: any, payload: any) {
            state.product_filter_complex = payload.body;
        },
        setProductFilterOverview(state: any, payload: ProductFilterOverviewRestDto[]) {
            state.product_filter_overview = payload;
        },
    },
    getters: {
        getCnkListProductFilter(state: any): CnkCodesResponseRestDto {
            return state.product_filter_cnk_list;
        },
        getComplexProductFilter(state: any): ProductFilterRestDto {
            return state.product_filter_complex;
        },
        getProductFilterOverview(state: any): ProductFilterOverviewRestDto[] {
            return state.product_filter_overview;
        },
    },
}
