
import {defineComponent} from "vue";

export default defineComponent({
  name: "BaseTab",
  props: {
    tabId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    modalId: 'libContentBlocks' as String,
  }),
});
