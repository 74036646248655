import {RestService} from "@/services/rest/RestService";
import {ContentBlockEndpointsApi} from "@/api/pharma-cms-content-block";
import {
    BannerContentV2RestDto,
    BannerInSlotContentRestDto,
    ContentOverviewRestDto
} from "@/api/pharma-cms-content-block/models";
import {StatusEnum} from "@/api/enums/status-enum";
import {BannerContentV2RestDtoModel} from "@/models/api/pharma-cms-content-block/BannerContentV2RestDtoModel";

export class NormalContentBlockRestService extends RestService {

    private apiService: ContentBlockEndpointsApi;

    protected constructor() {
        super();

        this.apiService = new ContentBlockEndpointsApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cmsUrl);
    }

    public async createNormalContentBlockBannerInSlot(contentBlock: BannerInSlotContentRestDto, type: string): Promise<void> {
        await this.apiService.createNormalContentBlockBannerInSlot(contentBlock, type);
    }

    public async findContentBlockOverview(type: string, filterStatusCode?: StatusEnum, filterLibraryCode?: string, filterLinked?: boolean): Promise<ContentOverviewRestDto[]> {
        const response = await this.apiService
            .findNormalContentBlocks(type, undefined, filterStatusCode, filterLibraryCode, filterLinked);

        return response.data;
    }

    public async createBannerV2(type: string, dto: BannerContentV2RestDto): Promise<void> {
        await this.apiService.createNormalContentBlockBannerV2(dto, type);
    }

    public async getBannerV2(type: string, id: string): Promise<BannerContentV2RestDtoModel> {
        const response = await this.apiService
            .getNormalContentBlockBannerV2(type, id);

        return response.data;
    }

    public async updateBannerV2(id: string, type: string, dto: BannerContentV2RestDto): Promise<void> {
        await this.apiService.updateNormalContentBlockBannerV2(dto, type, id);
    }
}
