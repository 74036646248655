
import {defineComponent} from 'vue'
import {Pp2ProductOverviewRestDtoModel} from "@/models/api/pharma-pim-pp2/Pp2ProductOverviewRestDtoModel";
import PimProductsOverviewItemIcons from "@/components/layouts/pim/PimProductsOverviewItemIcons.vue";
import {mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import {AbilityContext} from "@/context/AbilityContext";

export default defineComponent({
  name: "PimProductsOverviewTilesItem",
  emits: ["openOffCanvasProductDetail"],
  components: {ItemIcons: PimProductsOverviewItemIcons},
  props: {
    product: {
      type: [Pp2ProductOverviewRestDtoModel, Object],
      required: true
    }
  },
  computed: {
    ...mapState(useCustomerStore, ["isPimProductSelectedForCategorization"]),
    standardPriceWithVat(): number | null {
      return this.product.product_info.product_price?.standard_price_with_vat
        ? this.product.product_info.product_price.standard_price_with_vat.toFixed(2)
        : null;
    },
    isSelectedForCategorization(): boolean {
      return this.isPimProductSelectedForCategorization(this.product.product_info.cnk_code);
    },
  },
  methods: {
    openOffCanvasProductDetail(): void {
      if (AbilityContext.isAuthorizedForFeature('PIM_PRODUCT_INFO') && this.product.product_info.cnk_code) {
        this.$emit("openOffCanvasProductDetail", this.product);
      }
    }
  }
})
