
import {defineComponent} from "vue";
import {mapActions} from 'vuex';
import {HtmlContentRestDtoModel} from "@/models/api/pharma-cms-content-block/HtmlContentRestDtoModel";
import AbstractCbCommon from "@/components/layouts/content-pages/wysiwyg/structures/AbstractCbCommon.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import InputHtml from "@/components/UI/InputHtml.vue";

export default defineComponent({
  name: "CbHtml",
  components: {InputHtml, BaseSpinner},
  extends: AbstractCbCommon,
  data: () => ({
    contentBlock: HtmlContentRestDtoModel.createWithDefaults(),
  }),
  methods: {
    ...mapActions('cms_content_block/normal_content_block/html', [
      'create', 'find', 'save',
    ]),
    getDefaultCbModel(): HtmlContentRestDtoModel {
      return HtmlContentRestDtoModel.createWithDefaults();
    },
  }
});
