
import {defineComponent} from "vue";
import {mapActions} from 'vuex';
import AbstractCbCommon from "@/components/layouts/content-pages/wysiwyg/structures/AbstractCbCommon.vue";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import {TeamInfoContentRestDtoModel} from "@/models/TeamInfoContentRestDtoModel";
import {DpException} from "@/exception/DpException";
import {PharmacyInformationRestService} from "@/services/rest/cms-pharmacy/PharmacyInformationRestService";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";

export default defineComponent({
  name: "CbTeamInfo",
  components: {InputCheckboxBoolean, InputSelect, BaseSpinner},
  extends: AbstractCbCommon,
  data: () => ({
    contentBlock: TeamInfoContentRestDtoModel.createWithDefaults(),
    pharmacyOptions: [] as CodeDescriptionRestDto[]
  }),
  methods: {
    ...mapActions('cms_content_block/normal_content_block/team_info', ['create', 'find', 'save']),
    async afterReloadContent(): Promise<void> {
      await this.findPharmacies();

      if (!this.contentBlock.pharmacy_code) {
        this.contentBlock.pharmacy_code = this.pharmacyOptions[0].code;
      }
    },
    beforeSubmitData() {
      if (!this.contentBlock.pharmacy_code) {
        throw [new DpException(this.$t('Pharmacy needs to be selected.'))]
      }
    },
    getDefaultCbModel(): TeamInfoContentRestDtoModel {
      return TeamInfoContentRestDtoModel.createWithDefaults();
    },
    async findPharmacies(): Promise<void> {
      try {
        this.pharmacyOptions = await PharmacyInformationRestService
          .getInstance()
          .findPharmacies(true);
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }
    },
    pasteCbMetadata(): void {
      // When calling clone method directly on object, method is not found. So this is a hacky workaround
      const cbClone = TeamInfoContentRestDtoModel.cloneFrom(this.contentBlock);

      cbClone.cloneMetadataFrom(this.getClipboardContentBlock(this.cbType) as TeamInfoContentRestDtoModel);
      this.contentBlock = cbClone;
    },
  }
});
