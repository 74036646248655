<template>
  <div class="d-flex flex-column justify-content-center align-items-center h-100">
    <div>
      {{ $t('notLoggedInLong') }}
    </div>
    <div>
      <button @click.prevent="this.login" class="btn btn-icon btn-primary mt-3">
        <i class="mas me-2">login</i>{{ $t('login') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    async login() {
      await this.$store.dispatch('login');
    },
  },
};
</script>

<style scoped>
form {
  margin: 1rem;
  padding: 1rem;
}

.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}

input,
textarea {
  display: block;
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  padding: 0.15rem;
}

input:focus,
textarea:focus {
  border-color: #3d008d;
  background-color: #faf6ff;
  outline: none;
}
</style>
