import {RestService} from "@/services/rest/RestService";
import {CategoryAPIApi} from "@/api/pharma-pim-category";
import {CodeDescriptionRestDto} from "@/api/pharma-pim-category/models";

export class PimCategoryRestService extends RestService {

    private apiService: CategoryAPIApi;

    protected constructor() {
        super();

        this.apiService = new CategoryAPIApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().pimUrl);
    }

    public async findInternalProductCategories(search_name?: string | null): Promise<CodeDescriptionRestDto[]> {
        const response = await this.apiService.findInternalProductCategories(undefined, undefined, search_name);

        return response.data;
    }
}
