<template>
  <!-- TODO: fix error when dismissing (see base alert) -->
  <base-alert
      v-if="showIf"
      class="alert-success"
      icon="check_circle"
  >
    {{ (message) ? message : $t('savedSuccessfully') }}
  </base-alert>
</template>

<script>
import {defineComponent} from "vue";
import BaseAlert from "@/components/UI/Bootstrap/Alert/BaseAlert";

export default defineComponent({
  name: "AlertSuccess",
  components: {BaseAlert},
  props: {
    message: {
      type: String,
      required: false,
    },
    showIf: {
      type: Boolean,
      required: true,
    }
  },
});
</script>

<style scoped>

</style>