
import {defineComponent} from 'vue'
import {mapActions as mapActionsP, mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import InputNumber from "@/components/UI/InputNumber.vue";
import InputText from "@/components/UI/InputText.vue";
import {UIStateDto} from "@/dtos/UIStateDto";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import SelectBrands from "@/components/layouts/catalog/SelectBrands.vue";
import InputRadioBoolean from "@/components/UI/InputRadioBoolean.vue";
import {PimLaboProductsCriteriaModel} from "@/models/criteria/PimLaboProductsCriteriaModel";
import {PimProductsPagingModel} from "@/models/paging/PimProductsPagingModel";

export default defineComponent({
  name: "PimLabProductsOverviewCriteria",
  emits: ["reloadedContent", "submit"],
  components: {
    InputRadioBoolean,
    SelectBrands,
    BaseSpinner, AlertError2, InputText, InputNumber
  },
  data() {
    return {
      criteriaUI: UIStateDto.createWithDefaults(),
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapState(useCustomerStore, ["getCriteria", "getPaging", "getPimState"]),
    productsCriteria(): PimLaboProductsCriteriaModel {
      return this.getCriteria.pim_labo_products;
    },
    productsCriteriaIsChanged(): boolean {
      return this.getPimState.pim_labo_products_criteria_is_changed;
    },
    productsPaging(): PimProductsPagingModel {
      return this.getPaging.pim_products;
    },
  },
  methods: {
    ...mapActionsP(useCustomerStore, ["clearPimLaboProductsCriteria"]),
    clearCriteria(): void {
      this.clearPimLaboProductsCriteria();

      this.onCriteriaChanged();
    },
    onCriteriaChanged(): void {
      this.getPimState.pim_labo_products_criteria_is_changed = true;
    },
    setBrandCode(brandCode?: string | null): void {
      this.productsCriteria.filter_brand_code = brandCode;
      this.onCriteriaChanged();
    },
    async reloadContent(): Promise<void> {
      this.criteriaUI
        .setNotReady()
        .clearError();

      try {
        this.getPimState.pim_labo_products_criteria_is_changed = false;
        this.$emit('reloadedContent');
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.criteriaUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.criteriaUI as UIStateDto))
          .catch(exceptions, true);
      } finally {
        this.criteriaUI.setReady();
      }
    },
    submitData(): void {
      if (this.getPimState.pim_labo_products_criteria_is_changed) {
        this.productsPaging.number = 1;
      }

      this.getPimState.pim_labo_products_criteria_is_changed = false;
      this.$emit('submit');
    },
  }
})
