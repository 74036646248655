
import {ContentOverviewRestDto} from "@/api/pharma-cms-content-block/models";
import {Exception} from "@/api/interfaces";
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import CbsOverview from "@/components/layouts/content-blocks/overview/CbsOverview.vue";
import {arrayHasContent} from "@/helpers/functions/arrays";

export default defineComponent({
  name: "BannerOverview",
  components: {CbsOverview},
  emits: ["exceptions"],
  props: {
    isBannerInSlot: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLibrary: {
      type: Boolean,
      required: true,
    },
    canCopyItem: {
      type: Boolean,
      required: false,
      default: false,
    },
    canUseLibrary: {
      type: Boolean,
      required: false,
      default: false,
    },
    bannerSlotPositionCode: {
      type: String,
      required: false,
      default: '',
    },
    provider: {
      type: String,
      required: false,
      default: 'no-provider',
    },
    routeObjectEditCb: {
      type: Object,
      required: false,
    },
    routeObjectNewCb: {
      type: Object,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isReady: false as boolean,
      exceptions: [] as Exception[],
      typeCode: 'BANNER_IMG' as string,
    };
  },
  mounted() {
    this.reloadContent();
  },
  watch: {
    provider() {
      this.reloadContent();
    }
  },
  computed: {
    ...mapGetters('cms_content_block', ['getBannersInSlotsForSlot', 'getNormalContentBlocksOverview',]),
    ...mapGetters('cms_document', ['getPreviewDocument']),
    cbType(): string {
      if (this.isBannerInSlot) {
        return 'BANNER_IN_SLOT';
      }
      if (this.isLibrary) {
        return 'LIB_BANNER';
      }
      return 'BANNER';
    },
    contentBlocks(): ContentOverviewRestDto[] {
      if (this.isBannerInSlot) {
        return this.getBannersInSlotsForSlot;
      }
      return this.getNormalContentBlocksOverview;
    },
    hasContentBlocks(): Boolean {
      return arrayHasContent(this.contentBlocks);
    },
    contentLibraryCode(): String | undefined {
      switch (this.provider) {
        case 'dp':
          return 'LIB_DP_BANNERS_VOOR_DP_SLOTS';
        case 'general':
          return 'LIB_DP_BANNERS_ALG';
        case 'farmada':
          return 'LIB_FARMADA_BANNERS';
        case 'no-provider':
          return undefined;
        default:
          throw new Error('Unsupported provider to search ' + this.provider);
      }
    },
    tableHeaderItems(): string[] {
      if (this.isBannerInSlot) {
        return [this.$t('banner'), this.$t('remark'), this.$t('sortWeight'), this.$t('status'), ''];
      } else {
        return [this.$t('banner'), this.$t('link'), this.$t('category'), this.$t('remark'), this.$t('sortWeight'), this.$t('status'), ''];
      }
    }
  },
  methods: {
    ...mapActions('cms_content_block', [
      'searchBannersInSlotsForSlot',
      'searchNormalContentBlocksOverview',
      'deleteNormalContentBlock'
    ]),
    ...mapActions('cms_document', ['searchPreviewDocument']),
    //TODO backend: add support to copy directly
    // async copyContentBlock(id: String) {
    //   try {
    //     await store.dispatch('cms_content_block/createContentBlockLink', {
    //       from_cb_type_code: this.cbType,
    //       to_cb_id: id,
    //       force_copy: true,
    //     });
    //   } catch (exceptions: any) {
    //     this.exceptions = exceptions;
    //   }
    // },
    async reloadContent() {
      this.isReady = false;

      try {
        if (this.isBannerInSlot) {
          await this.searchBannersInSlotsForSlot({
            type: this.cbType,
            position_code: this.bannerSlotPositionCode,
          });
        } else {
          await this.searchNormalContentBlocksOverview({
            type: this.cbType,
            filter_library_code: this.contentLibraryCode,
          });
        }
      } catch (exceptions: any) {
        if (this.isBannerInSlot) {
          this.$emit('exceptions', exceptions);
        } else {
          this.exceptions = exceptions;
        }
      }

      this.isReady = true;
    },
    async deleteContentBlock(id: String) {
      try {
        const answer = window.confirm(this.$t('deleteConfirmation'));

        if (answer) {
          this.isReady = false;

          await this.deleteNormalContentBlock({
            type: this.cbType,
            id: id,
          });

          await this.reloadContent();
        } else {
          return;
        }
      } catch (exceptions: any) {
        if (this.isBannerInSlot) {
          this.$emit('exceptions', exceptions);
        } else {
          this.exceptions = exceptions;
        }
      }
    },
  }
});
