
import {defineComponent} from "vue";
import {CmsDocumentRestService} from "@/services/rest/cms-document/CmsDocumentRestService";

export default defineComponent({
  name: "LazyImage",
  props: {
    imageKey: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      required: false,
      default: 'Image'
    }
  },
  async setup(props: any) {
    const imageUrl = await CmsDocumentRestService.getInstance().findPreviewUrl(props.imageKey) as string;

    return {
      imageUrl,
    };
  }
});
