
import {defineComponent} from "vue";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import {CodeDescriptionRestDtoModel} from "@/models/CodeDescriptionRestDtoModel";
import {createCodeDescriptionArrayFromEnum} from "@/helpers/functions/enum";
import MultilingualSwitcher from "@/components/UI/MultilingualSwitcher.vue";
import Subtitle from "@/components/UI/Subtitle.vue";
import {FooterContent1LayoutEnum} from "@/api/enums/footer-content1-layout-enum";
import {CmsFooterContent1RestDtoModel} from "@/models/api/pharma-cms-footer/CmsFooterContent1RestDtoModel";
import {PharmacyInfoLayoutEnum} from "@/api/enums/pharmacy-info-layout-enum";
import I18nInputTextEditor from "@/components/UI/I18n/I18nInputTextEditor.vue";
import AbstractHeaderFooterDetailContent
  from "@/components/layouts/header-footer/AbstractHeaderFooterDetailContent.vue";

export default defineComponent({
  name: "FooterDetailContent1",
  components: {
    I18nInputTextEditor,
    Subtitle,
    MultilingualSwitcher,
    BaseSpinner, InputSelect, InputCheckboxBoolean, AlertError2
  },
  extends: AbstractHeaderFooterDetailContent,
  data: () => ({
    footerContent: CmsFooterContent1RestDtoModel.createWithDefaults(),
  }),
  computed: {
    layoutOptions(): CodeDescriptionRestDtoModel[] {
      return createCodeDescriptionArrayFromEnum(FooterContent1LayoutEnum);
    },
    pharmacyInfoLayoutOptions(): CodeDescriptionRestDtoModel[] {
      return createCodeDescriptionArrayFromEnum(PharmacyInfoLayoutEnum);
    }
  }
});
