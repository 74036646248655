import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gx-5 mb-3" }
const _hoisted_2 = { class: "col-right col col-xxl-6" }
const _hoisted_3 = { class: "row row-texts" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "mb-3" }
const _hoisted_6 = {
  class: "btn btn-primary",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertError2 = _resolveComponent("AlertError2")!
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_MultilingualSwitcher = _resolveComponent("MultilingualSwitcher")!
  const _component_I18nInputTextEditor = _resolveComponent("I18nInputTextEditor")!
  const _component_dp_form = _resolveComponent("dp-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AlertError2, {
      error: this.headerFooterContentUI.getError()
    }, null, 8, ["error"]),
    _createVNode(_component_BaseSpinner, {
      spin: this.headerFooterContentUI.isNotReady()
    }, null, 8, ["spin"]),
    (this.headerFooterContentUI.isReady())
      ? (_openBlock(), _createBlock(_component_dp_form, {
          key: 0,
          onSubmit: this.submitData,
          novalidate: ""
        }, {
          default: _withCtx(({errors}) => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_MultilingualSwitcher, {
                    "active-language": this.activeLanguage,
                    onUpdateActiveLanguage: _cache[0] || (_cache[0] = newValue => this.activeLanguage = newValue),
                    errors: errors
                  }, null, 8, ["active-language", "errors"]),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_I18nInputTextEditor, {
                        data: this.footerContent.main_pharmacy_text,
                        "active-language": this.activeLanguage,
                        name: "mainPharmacyText",
                        label: _ctx.$t('mainPharmacyText'),
                        errors: errors
                      }, null, 8, ["data", "active-language", "label", "errors"])
                    ]),
                    _createElementVNode("div", null, [
                      _createVNode(_component_I18nInputTextEditor, {
                        data: this.footerContent.extra_text,
                        "active-language": this.activeLanguage,
                        name: "extraText",
                        label: _ctx.$t('extraText'),
                        errors: errors
                      }, null, 8, ["data", "active-language", "label", "errors"])
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("button", _hoisted_6, _toDisplayString(_ctx.$t('save')), 1)
          ]),
          _: 1
        }, 8, ["onSubmit"]))
      : _createCommentVNode("", true)
  ], 64))
}