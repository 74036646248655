/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum PageReferencesContentLayoutEnum {
    GRID_4XN = 'GRID_4XN',
    GRID_2XN = 'GRID_2XN',
    GRID_1XN = 'GRID_1XN',
    LIST = 'LIST',
}