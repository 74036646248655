/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Content Block API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { BannerContentRestDto } from '../models';
import { BannerContentV2RestDto } from '../models';
import { BannerSlotContentRestDto } from '../models';
import { ContentBlockResponseRestDto } from '../models';
import { FullBannerContentRestDto } from '../models';
import { FullBannerContentV2RestDto } from '../models';
import { FullBannerSlotContentRestDto } from '../models';
import { FullImageContentRestDto } from '../models';
import { FullTextContentRestDto } from '../models';
import { FullTextWithImageContentRestDto } from '../models';
import { FullTextWithTitleContentRestDto } from '../models';
import { IdResponseRestDto } from '../models';
import { ImageContentRestDto } from '../models';
import { LibraryOverviewRestDto } from '../models';
import { TextContentRestDto } from '../models';
import { TextWithImageContentRestDto } from '../models';
import { TextWithTitleContentRestDto } from '../models';
/**
 * ContentBlockLibraryEndpointsApi - axios parameter creator
 * @export
 */
export const ContentBlockLibraryEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.CB.45 Add a content block containing banner for the specified type.
         * @param {BannerContentRestDto} body 
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLibContentBlockBanner: async (body: BannerContentRestDto, library_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createLibContentBlockBanner.');
            }
            // verify required parameter 'library_code' is not null or undefined
            if (library_code === null || library_code === undefined) {
                throw new RequiredError('library_code','Required parameter library_code was null or undefined when calling createLibContentBlockBanner.');
            }
            const localVarPath = `/content_block/v1/library/{library_code}/content_block/banner`
                .replace(`{${"library_code"}}`, encodeURIComponent(String(library_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.82 Add a content block containing bannerslot for the specified type.
         * @param {BannerSlotContentRestDto} body 
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLibContentBlockBannerSlot: async (body: BannerSlotContentRestDto, library_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createLibContentBlockBannerSlot.');
            }
            // verify required parameter 'library_code' is not null or undefined
            if (library_code === null || library_code === undefined) {
                throw new RequiredError('library_code','Required parameter library_code was null or undefined when calling createLibContentBlockBannerSlot.');
            }
            const localVarPath = `/content_block/v1/library/{library_code}/content_block/banner_slot`
                .replace(`{${"library_code"}}`, encodeURIComponent(String(library_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.45 Add a content block containing banner for the specified type.
         * @param {BannerContentV2RestDto} body 
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLibContentBlockBannerV2: async (body: BannerContentV2RestDto, library_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createLibContentBlockBannerV2.');
            }
            // verify required parameter 'library_code' is not null or undefined
            if (library_code === null || library_code === undefined) {
                throw new RequiredError('library_code','Required parameter library_code was null or undefined when calling createLibContentBlockBannerV2.');
            }
            const localVarPath = `/content_block/v2/library/{library_code}/content_block/banner`
                .replace(`{${"library_code"}}`, encodeURIComponent(String(library_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.83 Add a content block to this library as a copy from a content block.
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} source_cb_id The unique identifier of the &#x27;source&#x27; content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLibContentBlockFrom: async (library_code: string, source_cb_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'library_code' is not null or undefined
            if (library_code === null || library_code === undefined) {
                throw new RequiredError('library_code','Required parameter library_code was null or undefined when calling createLibContentBlockFrom.');
            }
            // verify required parameter 'source_cb_id' is not null or undefined
            if (source_cb_id === null || source_cb_id === undefined) {
                throw new RequiredError('source_cb_id','Required parameter source_cb_id was null or undefined when calling createLibContentBlockFrom.');
            }
            const localVarPath = `/content_block/v1/library/{library_code}/content_block/from/{source_cb_id}`
                .replace(`{${"library_code"}}`, encodeURIComponent(String(library_code)))
                .replace(`{${"source_cb_id"}}`, encodeURIComponent(String(source_cb_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.36 Add a content block containing an image_key for the specified type.
         * @param {ImageContentRestDto} body 
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLibContentBlockImage: async (body: ImageContentRestDto, library_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createLibContentBlockImage.');
            }
            // verify required parameter 'library_code' is not null or undefined
            if (library_code === null || library_code === undefined) {
                throw new RequiredError('library_code','Required parameter library_code was null or undefined when calling createLibContentBlockImage.');
            }
            const localVarPath = `/content_block/v1/library/{library_code}/content_block/image`
                .replace(`{${"library_code"}}`, encodeURIComponent(String(library_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.35 Add a content block containing text for the specified type.
         * @param {TextContentRestDto} body 
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLibContentBlockText: async (body: TextContentRestDto, library_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createLibContentBlockText.');
            }
            // verify required parameter 'library_code' is not null or undefined
            if (library_code === null || library_code === undefined) {
                throw new RequiredError('library_code','Required parameter library_code was null or undefined when calling createLibContentBlockText.');
            }
            const localVarPath = `/content_block/v1/library/{library_code}/content_block/text`
                .replace(`{${"library_code"}}`, encodeURIComponent(String(library_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.38 Add a content block containing text with title for the specified type.
         * @param {TextWithImageContentRestDto} body 
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLibContentBlockTextWithImage: async (body: TextWithImageContentRestDto, library_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createLibContentBlockTextWithImage.');
            }
            // verify required parameter 'library_code' is not null or undefined
            if (library_code === null || library_code === undefined) {
                throw new RequiredError('library_code','Required parameter library_code was null or undefined when calling createLibContentBlockTextWithImage.');
            }
            const localVarPath = `/content_block/v1/library/{library_code}/content_block/text_with_image`
                .replace(`{${"library_code"}}`, encodeURIComponent(String(library_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.37 Add a content block containing text with title for the specified type.
         * @param {TextWithTitleContentRestDto} body 
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLibContentBlockTextWithTitle: async (body: TextWithTitleContentRestDto, library_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createLibContentBlockTextWithTitle.');
            }
            // verify required parameter 'library_code' is not null or undefined
            if (library_code === null || library_code === undefined) {
                throw new RequiredError('library_code','Required parameter library_code was null or undefined when calling createLibContentBlockTextWithTitle.');
            }
            const localVarPath = `/content_block/v1/library/{library_code}/content_block/text_with_title`
                .replace(`{${"library_code"}}`, encodeURIComponent(String(library_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.30 Retrieve a list of content block libraries available for the specified content block type.
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [filter_is_owner] Filter de resultaten tot die waarvoor deze customer de owner is.
         * @param {boolean} [expect_external_name] If provided and false, the internal name of the library is expected as description in the result. If not provided, by default, the external name will be returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findContentBlockLibsForType: async (type: string, x_dp_customer_code?: string|null, filter_is_owner?: boolean|null, expect_external_name?: boolean|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling findContentBlockLibsForType.');
            }
            const localVarPath = `/content_block/v1/content_block/{type}/library`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_is_owner || typeof(filter_is_owner) === "boolean") {
                localVarQueryParameter['filter_is_owner'] = filter_is_owner;
            }

            if (!!expect_external_name || typeof(expect_external_name) === "boolean") {
                localVarQueryParameter['expect_external_name'] = expect_external_name;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.44 Retrieve library content blocks that are usable for the specified type.
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_status_code] Filter the results based on a match on status_code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLibraryContentBlocksBanner: async (library_code: string, x_dp_customer_code?: string|null, filter_status_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'library_code' is not null or undefined
            if (library_code === null || library_code === undefined) {
                throw new RequiredError('library_code','Required parameter library_code was null or undefined when calling getLibraryContentBlocksBanner.');
            }
            const localVarPath = `/content_block/v1/library/{library_code}/content_block/banner`
                .replace(`{${"library_code"}}`, encodeURIComponent(String(library_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_status_code || typeof(filter_status_code) === "boolean") {
                localVarQueryParameter['filter_status_code'] = filter_status_code;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.81 Retrieve library content blocks that are usable for the specified type.
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_status_code] Filter the results based on a match on status_code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLibraryContentBlocksBannerSlot: async (library_code: string, x_dp_customer_code?: string|null, filter_status_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'library_code' is not null or undefined
            if (library_code === null || library_code === undefined) {
                throw new RequiredError('library_code','Required parameter library_code was null or undefined when calling getLibraryContentBlocksBannerSlot.');
            }
            const localVarPath = `/content_block/v1/library/{library_code}/content_block/banner_slot`
                .replace(`{${"library_code"}}`, encodeURIComponent(String(library_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_status_code || typeof(filter_status_code) === "boolean") {
                localVarQueryParameter['filter_status_code'] = filter_status_code;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.44 Retrieve library content blocks that are usable for the specified type.
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_status_code] Filter the results based on a match on status_code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLibraryContentBlocksBannerV2: async (library_code: string, x_dp_customer_code?: string|null, filter_status_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'library_code' is not null or undefined
            if (library_code === null || library_code === undefined) {
                throw new RequiredError('library_code','Required parameter library_code was null or undefined when calling getLibraryContentBlocksBannerV2.');
            }
            const localVarPath = `/content_block/v2/library/{library_code}/content_block/banner`
                .replace(`{${"library_code"}}`, encodeURIComponent(String(library_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_status_code || typeof(filter_status_code) === "boolean") {
                localVarQueryParameter['filter_status_code'] = filter_status_code;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.32 Retrieve library content blocks that are usable for the specified type.
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_status_code] Filter the results based on a match on status_code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLibraryContentBlocksImage: async (library_code: string, x_dp_customer_code?: string|null, filter_status_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'library_code' is not null or undefined
            if (library_code === null || library_code === undefined) {
                throw new RequiredError('library_code','Required parameter library_code was null or undefined when calling getLibraryContentBlocksImage.');
            }
            const localVarPath = `/content_block/v1/library/{library_code}/content_block/image`
                .replace(`{${"library_code"}}`, encodeURIComponent(String(library_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_status_code || typeof(filter_status_code) === "boolean") {
                localVarQueryParameter['filter_status_code'] = filter_status_code;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.31 Retrieve library content blocks that are usable for the specified type.
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_status_code] Filter the results based on a match on status_code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLibraryContentBlocksText: async (library_code: string, x_dp_customer_code?: string|null, filter_status_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'library_code' is not null or undefined
            if (library_code === null || library_code === undefined) {
                throw new RequiredError('library_code','Required parameter library_code was null or undefined when calling getLibraryContentBlocksText.');
            }
            const localVarPath = `/content_block/v1/library/{library_code}/content_block/text`
                .replace(`{${"library_code"}}`, encodeURIComponent(String(library_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_status_code || typeof(filter_status_code) === "boolean") {
                localVarQueryParameter['filter_status_code'] = filter_status_code;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.34 Retrieve library content blocks that are usable for the specified type.
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_status_code] Filter the results based on a match on status_code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLibraryContentBlocksTextWithImage: async (library_code: string, x_dp_customer_code?: string|null, filter_status_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'library_code' is not null or undefined
            if (library_code === null || library_code === undefined) {
                throw new RequiredError('library_code','Required parameter library_code was null or undefined when calling getLibraryContentBlocksTextWithImage.');
            }
            const localVarPath = `/content_block/v1/library/{library_code}/content_block/text_with_image`
                .replace(`{${"library_code"}}`, encodeURIComponent(String(library_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_status_code || typeof(filter_status_code) === "boolean") {
                localVarQueryParameter['filter_status_code'] = filter_status_code;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.33 Retrieve library content blocks that are usable for the specified type.
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_status_code] Filter the results based on a match on status_code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLibraryContentBlocksTextWithTitle: async (library_code: string, x_dp_customer_code?: string|null, filter_status_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'library_code' is not null or undefined
            if (library_code === null || library_code === undefined) {
                throw new RequiredError('library_code','Required parameter library_code was null or undefined when calling getLibraryContentBlocksTextWithTitle.');
            }
            const localVarPath = `/content_block/v1/library/{library_code}/content_block/text_with_title`
                .replace(`{${"library_code"}}`, encodeURIComponent(String(library_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_status_code || typeof(filter_status_code) === "boolean") {
                localVarQueryParameter['filter_status_code'] = filter_status_code;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentBlockLibraryEndpointsApi - functional programming interface
 * @export
 */
export const ContentBlockLibraryEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.CB.45 Add a content block containing banner for the specified type.
         * @param {BannerContentRestDto} body 
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLibContentBlockBanner(_axios: AxiosInstance, body: BannerContentRestDto, library_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentBlockResponseRestDto>> {
            const localVarAxiosArgs = await ContentBlockLibraryEndpointsApiAxiosParamCreator(configuration).createLibContentBlockBanner(body, library_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.82 Add a content block containing bannerslot for the specified type.
         * @param {BannerSlotContentRestDto} body 
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLibContentBlockBannerSlot(_axios: AxiosInstance, body: BannerSlotContentRestDto, library_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentBlockResponseRestDto>> {
            const localVarAxiosArgs = await ContentBlockLibraryEndpointsApiAxiosParamCreator(configuration).createLibContentBlockBannerSlot(body, library_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.45 Add a content block containing banner for the specified type.
         * @param {BannerContentV2RestDto} body 
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLibContentBlockBannerV2(_axios: AxiosInstance, body: BannerContentV2RestDto, library_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentBlockResponseRestDto>> {
            const localVarAxiosArgs = await ContentBlockLibraryEndpointsApiAxiosParamCreator(configuration).createLibContentBlockBannerV2(body, library_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.83 Add a content block to this library as a copy from a content block.
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} source_cb_id The unique identifier of the &#x27;source&#x27; content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLibContentBlockFrom(_axios: AxiosInstance, library_code: string, source_cb_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResponseRestDto>> {
            const localVarAxiosArgs = await ContentBlockLibraryEndpointsApiAxiosParamCreator(configuration).createLibContentBlockFrom(library_code, source_cb_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.36 Add a content block containing an image_key for the specified type.
         * @param {ImageContentRestDto} body 
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLibContentBlockImage(_axios: AxiosInstance, body: ImageContentRestDto, library_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await ContentBlockLibraryEndpointsApiAxiosParamCreator(configuration).createLibContentBlockImage(body, library_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.35 Add a content block containing text for the specified type.
         * @param {TextContentRestDto} body 
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLibContentBlockText(_axios: AxiosInstance, body: TextContentRestDto, library_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await ContentBlockLibraryEndpointsApiAxiosParamCreator(configuration).createLibContentBlockText(body, library_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.38 Add a content block containing text with title for the specified type.
         * @param {TextWithImageContentRestDto} body 
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLibContentBlockTextWithImage(_axios: AxiosInstance, body: TextWithImageContentRestDto, library_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentBlockResponseRestDto>> {
            const localVarAxiosArgs = await ContentBlockLibraryEndpointsApiAxiosParamCreator(configuration).createLibContentBlockTextWithImage(body, library_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.37 Add a content block containing text with title for the specified type.
         * @param {TextWithTitleContentRestDto} body 
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLibContentBlockTextWithTitle(_axios: AxiosInstance, body: TextWithTitleContentRestDto, library_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await ContentBlockLibraryEndpointsApiAxiosParamCreator(configuration).createLibContentBlockTextWithTitle(body, library_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.30 Retrieve a list of content block libraries available for the specified content block type.
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [filter_is_owner] Filter de resultaten tot die waarvoor deze customer de owner is.
         * @param {boolean} [expect_external_name] If provided and false, the internal name of the library is expected as description in the result. If not provided, by default, the external name will be returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findContentBlockLibsForType(_axios: AxiosInstance, type: string, x_dp_customer_code?: string|null, filter_is_owner?: boolean|null, expect_external_name?: boolean|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LibraryOverviewRestDto>>> {
            const localVarAxiosArgs = await ContentBlockLibraryEndpointsApiAxiosParamCreator(configuration).findContentBlockLibsForType(type, x_dp_customer_code, filter_is_owner, expect_external_name, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.44 Retrieve library content blocks that are usable for the specified type.
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_status_code] Filter the results based on a match on status_code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLibraryContentBlocksBanner(_axios: AxiosInstance, library_code: string, x_dp_customer_code?: string|null, filter_status_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FullBannerContentRestDto>>> {
            const localVarAxiosArgs = await ContentBlockLibraryEndpointsApiAxiosParamCreator(configuration).getLibraryContentBlocksBanner(library_code, x_dp_customer_code, filter_status_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.81 Retrieve library content blocks that are usable for the specified type.
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_status_code] Filter the results based on a match on status_code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLibraryContentBlocksBannerSlot(_axios: AxiosInstance, library_code: string, x_dp_customer_code?: string|null, filter_status_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FullBannerSlotContentRestDto>>> {
            const localVarAxiosArgs = await ContentBlockLibraryEndpointsApiAxiosParamCreator(configuration).getLibraryContentBlocksBannerSlot(library_code, x_dp_customer_code, filter_status_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.44 Retrieve library content blocks that are usable for the specified type.
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_status_code] Filter the results based on a match on status_code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLibraryContentBlocksBannerV2(_axios: AxiosInstance, library_code: string, x_dp_customer_code?: string|null, filter_status_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FullBannerContentV2RestDto>>> {
            const localVarAxiosArgs = await ContentBlockLibraryEndpointsApiAxiosParamCreator(configuration).getLibraryContentBlocksBannerV2(library_code, x_dp_customer_code, filter_status_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.32 Retrieve library content blocks that are usable for the specified type.
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_status_code] Filter the results based on a match on status_code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLibraryContentBlocksImage(_axios: AxiosInstance, library_code: string, x_dp_customer_code?: string|null, filter_status_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FullImageContentRestDto>>> {
            const localVarAxiosArgs = await ContentBlockLibraryEndpointsApiAxiosParamCreator(configuration).getLibraryContentBlocksImage(library_code, x_dp_customer_code, filter_status_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.31 Retrieve library content blocks that are usable for the specified type.
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_status_code] Filter the results based on a match on status_code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLibraryContentBlocksText(_axios: AxiosInstance, library_code: string, x_dp_customer_code?: string|null, filter_status_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FullTextContentRestDto>>> {
            const localVarAxiosArgs = await ContentBlockLibraryEndpointsApiAxiosParamCreator(configuration).getLibraryContentBlocksText(library_code, x_dp_customer_code, filter_status_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.34 Retrieve library content blocks that are usable for the specified type.
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_status_code] Filter the results based on a match on status_code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLibraryContentBlocksTextWithImage(_axios: AxiosInstance, library_code: string, x_dp_customer_code?: string|null, filter_status_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FullTextWithImageContentRestDto>>> {
            const localVarAxiosArgs = await ContentBlockLibraryEndpointsApiAxiosParamCreator(configuration).getLibraryContentBlocksTextWithImage(library_code, x_dp_customer_code, filter_status_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.33 Retrieve library content blocks that are usable for the specified type.
         * @param {string} library_code The code that identifier the content block library.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_status_code] Filter the results based on a match on status_code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLibraryContentBlocksTextWithTitle(_axios: AxiosInstance, library_code: string, x_dp_customer_code?: string|null, filter_status_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FullTextWithTitleContentRestDto>>> {
            const localVarAxiosArgs = await ContentBlockLibraryEndpointsApiAxiosParamCreator(configuration).getLibraryContentBlocksTextWithTitle(library_code, x_dp_customer_code, filter_status_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ContentBlockLibraryEndpointsApi - object-oriented interface
 * @export
 * @class ContentBlockLibraryEndpointsApi
 * @extends {BaseAPI}
 */
export class ContentBlockLibraryEndpointsApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.CMS.CB.45 Add a content block containing banner for the specified type.
     * @param {BannerContentRestDto} body 
     * @param {string} library_code The code that identifier the content block library.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockLibraryEndpointsApi
     */
     public createLibContentBlockBanner(body: BannerContentRestDto, library_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentBlockLibraryEndpointsApiFp(this.configuration).createLibContentBlockBanner(this.axios, body, library_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.82 Add a content block containing bannerslot for the specified type.
     * @param {BannerSlotContentRestDto} body 
     * @param {string} library_code The code that identifier the content block library.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockLibraryEndpointsApi
     */
     public createLibContentBlockBannerSlot(body: BannerSlotContentRestDto, library_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentBlockLibraryEndpointsApiFp(this.configuration).createLibContentBlockBannerSlot(this.axios, body, library_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.45 Add a content block containing banner for the specified type.
     * @param {BannerContentV2RestDto} body 
     * @param {string} library_code The code that identifier the content block library.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockLibraryEndpointsApi
     */
     public createLibContentBlockBannerV2(body: BannerContentV2RestDto, library_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentBlockLibraryEndpointsApiFp(this.configuration).createLibContentBlockBannerV2(this.axios, body, library_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.83 Add a content block to this library as a copy from a content block.
     * @param {string} library_code The code that identifier the content block library.
     * @param {string} source_cb_id The unique identifier of the &#x27;source&#x27; content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockLibraryEndpointsApi
     */
     public createLibContentBlockFrom(library_code: string, source_cb_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentBlockLibraryEndpointsApiFp(this.configuration).createLibContentBlockFrom(this.axios, library_code, source_cb_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.36 Add a content block containing an image_key for the specified type.
     * @param {ImageContentRestDto} body 
     * @param {string} library_code The code that identifier the content block library.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockLibraryEndpointsApi
     */
     public createLibContentBlockImage(body: ImageContentRestDto, library_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentBlockLibraryEndpointsApiFp(this.configuration).createLibContentBlockImage(this.axios, body, library_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.35 Add a content block containing text for the specified type.
     * @param {TextContentRestDto} body 
     * @param {string} library_code The code that identifier the content block library.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockLibraryEndpointsApi
     */
     public createLibContentBlockText(body: TextContentRestDto, library_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentBlockLibraryEndpointsApiFp(this.configuration).createLibContentBlockText(this.axios, body, library_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.38 Add a content block containing text with title for the specified type.
     * @param {TextWithImageContentRestDto} body 
     * @param {string} library_code The code that identifier the content block library.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockLibraryEndpointsApi
     */
     public createLibContentBlockTextWithImage(body: TextWithImageContentRestDto, library_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentBlockLibraryEndpointsApiFp(this.configuration).createLibContentBlockTextWithImage(this.axios, body, library_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.37 Add a content block containing text with title for the specified type.
     * @param {TextWithTitleContentRestDto} body 
     * @param {string} library_code The code that identifier the content block library.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockLibraryEndpointsApi
     */
     public createLibContentBlockTextWithTitle(body: TextWithTitleContentRestDto, library_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentBlockLibraryEndpointsApiFp(this.configuration).createLibContentBlockTextWithTitle(this.axios, body, library_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.30 Retrieve a list of content block libraries available for the specified content block type.
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {boolean} [filter_is_owner] Filter de resultaten tot die waarvoor deze customer de owner is.
     * @param {boolean} [expect_external_name] If provided and false, the internal name of the library is expected as description in the result. If not provided, by default, the external name will be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockLibraryEndpointsApi
     */
     public findContentBlockLibsForType(type: string, x_dp_customer_code?: string|null, filter_is_owner?: boolean|null, expect_external_name?: boolean|null, options?: any) {
        return ContentBlockLibraryEndpointsApiFp(this.configuration).findContentBlockLibsForType(this.axios, type, x_dp_customer_code, filter_is_owner, expect_external_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.44 Retrieve library content blocks that are usable for the specified type.
     * @param {string} library_code The code that identifier the content block library.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [filter_status_code] Filter the results based on a match on status_code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockLibraryEndpointsApi
     */
     public getLibraryContentBlocksBanner(library_code: string, x_dp_customer_code?: string|null, filter_status_code?: string|null, options?: any) {
        return ContentBlockLibraryEndpointsApiFp(this.configuration).getLibraryContentBlocksBanner(this.axios, library_code, x_dp_customer_code, filter_status_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.81 Retrieve library content blocks that are usable for the specified type.
     * @param {string} library_code The code that identifier the content block library.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [filter_status_code] Filter the results based on a match on status_code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockLibraryEndpointsApi
     */
     public getLibraryContentBlocksBannerSlot(library_code: string, x_dp_customer_code?: string|null, filter_status_code?: string|null, options?: any) {
        return ContentBlockLibraryEndpointsApiFp(this.configuration).getLibraryContentBlocksBannerSlot(this.axios, library_code, x_dp_customer_code, filter_status_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.44 Retrieve library content blocks that are usable for the specified type.
     * @param {string} library_code The code that identifier the content block library.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [filter_status_code] Filter the results based on a match on status_code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockLibraryEndpointsApi
     */
     public getLibraryContentBlocksBannerV2(library_code: string, x_dp_customer_code?: string|null, filter_status_code?: string|null, options?: any) {
        return ContentBlockLibraryEndpointsApiFp(this.configuration).getLibraryContentBlocksBannerV2(this.axios, library_code, x_dp_customer_code, filter_status_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.32 Retrieve library content blocks that are usable for the specified type.
     * @param {string} library_code The code that identifier the content block library.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [filter_status_code] Filter the results based on a match on status_code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockLibraryEndpointsApi
     */
     public getLibraryContentBlocksImage(library_code: string, x_dp_customer_code?: string|null, filter_status_code?: string|null, options?: any) {
        return ContentBlockLibraryEndpointsApiFp(this.configuration).getLibraryContentBlocksImage(this.axios, library_code, x_dp_customer_code, filter_status_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.31 Retrieve library content blocks that are usable for the specified type.
     * @param {string} library_code The code that identifier the content block library.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [filter_status_code] Filter the results based on a match on status_code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockLibraryEndpointsApi
     */
     public getLibraryContentBlocksText(library_code: string, x_dp_customer_code?: string|null, filter_status_code?: string|null, options?: any) {
        return ContentBlockLibraryEndpointsApiFp(this.configuration).getLibraryContentBlocksText(this.axios, library_code, x_dp_customer_code, filter_status_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.34 Retrieve library content blocks that are usable for the specified type.
     * @param {string} library_code The code that identifier the content block library.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [filter_status_code] Filter the results based on a match on status_code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockLibraryEndpointsApi
     */
     public getLibraryContentBlocksTextWithImage(library_code: string, x_dp_customer_code?: string|null, filter_status_code?: string|null, options?: any) {
        return ContentBlockLibraryEndpointsApiFp(this.configuration).getLibraryContentBlocksTextWithImage(this.axios, library_code, x_dp_customer_code, filter_status_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.33 Retrieve library content blocks that are usable for the specified type.
     * @param {string} library_code The code that identifier the content block library.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [filter_status_code] Filter the results based on a match on status_code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockLibraryEndpointsApi
     */
     public getLibraryContentBlocksTextWithTitle(library_code: string, x_dp_customer_code?: string|null, filter_status_code?: string|null, options?: any) {
        return ContentBlockLibraryEndpointsApiFp(this.configuration).getLibraryContentBlocksTextWithTitle(this.axios, library_code, x_dp_customer_code, filter_status_code, options).then((request) => request(this.axios, this.basePath));
    }

}
