/*
* Overview of available enums: https://gitlab.com/digital-pharma-dp3/pharma-customer-product-catalog/-/tree/master/application/src/Model/Enum
*/
export enum EnumClassNameCpcEnum {
    APB_LEGAL_CATEGORY = "App\\Model\\Enum\\Pp2\\ApbLegalCategoryEnum",
    APB_PRODUCT_CATEGORY = "App\\Model\\Enum\\Pp2\\ApbProductCategoryEnum",
    BELMEDIS_AVAILABILITY = "App\\Model\\Enum\\Pp2\\BelmedisAvailabilityEnum",
    CATEGORY_CARDINALITY = "App\\Model\\Enum\\Pp2\\CategoryCardinalityEnum",
    FEBELCO_AVAILABILITY = "App\\Model\\Enum\\Pp2\\FebelcoAvailabilityEnum",
    IPRINS_USAGE_TYPE = "App\\Model\\Enum\\Pp2\\IPrinsUsageTypeEnum",
    // PHOTOGRAPHY_STATUS_FILTER = "App\\Model\\Enum\\Pp2\\PhotographyStatusFilterEnum",
    PRODUCT_USAGE_TYPE = "App\\Model\\Enum\\ProductUsageTypeEnum",
    PRODUCT_PROPERTY_STRATEGY = "App\\Model\\Enum\\ProductPropertyStrategyEnum",
    SHOPPING_CART_DISCOUNT_TYPE = "App\\Model\\Enum\\ShoppingCartDiscountTypeEnum",
    DISCOUNT_TYPE = "App\\Model\\Enum\\DiscountTypeEnum",
    COMBINATION_DISOOUNT_SCOPE = "App\\Model\\Enum\\CombinationDiscountScopeEnum",
    COLOR_SCHEME_COLOR = "App\\Model\\Enum\\ColorSchemeColorEnum",
    PRODUCT_LABEL_POSITION = "App\\Model\\Enum\\ProductLabelPositionEnum",
    PRODUCT_LABEL_LAYOUT = "App\\Model\\Enum\\ProductLabelLayoutEnum",
}
