/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum ItemsHorizontalAlignmentEnum {
    START = 'START',
    END = 'END',
    CENTER = 'CENTER',
    BETWEEN = 'BETWEEN',
    AROUND = 'AROUND',
    EVENLY = 'EVENLY',
}