/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Customer-Product-Catalog
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CodeDescriptionRestDto } from '../models';
import { CustomerProductPropertiesResultsRestDto } from '../models';
import { CustomerProductPropertyRestDto } from '../models';
import { ProductCustomNameRestDto } from '../models';
import { ProductCustomNamesOverviewRestDto } from '../models';
/**
 * ProductPropertyAPIApi - axios parameter creator
 * @export
 */
export const ProductPropertyAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for synchronizing a product property for a product.
         * @summary REST.API.CPC.PMPP.03 Delete a product property for a product.
         * @param {string} product_code Identifies the product.
         * @param {string} type_code Identifies the type of a product property.
         * @param {string} language_code The language of the property value.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductProperty: async (product_code: string, type_code: string, language_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'product_code' is not null or undefined
            if (product_code === null || product_code === undefined) {
                throw new RequiredError('product_code','Required parameter product_code was null or undefined when calling deleteProductProperty.');
            }
            // verify required parameter 'type_code' is not null or undefined
            if (type_code === null || type_code === undefined) {
                throw new RequiredError('type_code','Required parameter type_code was null or undefined when calling deleteProductProperty.');
            }
            // verify required parameter 'language_code' is not null or undefined
            if (language_code === null || language_code === undefined) {
                throw new RequiredError('language_code','Required parameter language_code was null or undefined when calling deleteProductProperty.');
            }
            const localVarPath = `/product_mgmt/v1/catalog/product/{product_code}/customer_property/{type_code}/language/{language_code}`
                .replace(`{${"product_code"}}`, encodeURIComponent(String(product_code)))
                .replace(`{${"type_code"}}`, encodeURIComponent(String(type_code)))
                .replace(`{${"language_code"}}`, encodeURIComponent(String(language_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving the product properties for a product.
         * @summary REST.API.CPC.PMPP.02 Find the product properties for a product.
         * @param {string} product_code Identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_language_code] Filter the results based on the content language.
         * @param {string} [filter_type_code] Filter the results based on the type property.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCustomerProductProperties: async (product_code: string, x_dp_customer_code?: string|null, filter_language_code?: string|null, filter_type_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'product_code' is not null or undefined
            if (product_code === null || product_code === undefined) {
                throw new RequiredError('product_code','Required parameter product_code was null or undefined when calling findCustomerProductProperties.');
            }
            const localVarPath = `/product_mgmt/v1/catalog/product/{product_code}/customer_property`
                .replace(`{${"product_code"}}`, encodeURIComponent(String(product_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_language_code || typeof(filter_language_code) === "boolean") {
                localVarQueryParameter['filter_language_code'] = filter_language_code;
            }

            if (!!filter_type_code || typeof(filter_type_code) === "boolean") {
                localVarQueryParameter['filter_type_code'] = filter_type_code;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving the custom names for a product.
         * @summary REST.API.CPC.PMPP.06 Find the customers custom names for a product.
         * @param {string} product_code Identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductCustomNames: async (product_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'product_code' is not null or undefined
            if (product_code === null || product_code === undefined) {
                throw new RequiredError('product_code','Required parameter product_code was null or undefined when calling findProductCustomNames.');
            }
            const localVarPath = `/product_mgmt/v1/catalog/product/{product_code}/custom_name`
                .replace(`{${"product_code"}}`, encodeURIComponent(String(product_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Find enum values for product property type
         * @summary REST.API.CPC.ENUM.03 Find product property type values.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductPropertyTypeCodes: async (x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/product_mgmt/v1/enum/product_property_type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving the custom names of for a product.
         * @summary REST.API.CPC.PMPP.05 Creates or updates a customers custom names for a product.
         * @param {ProductCustomNameRestDto} body 
         * @param {string} product_code Identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProductCustomNames: async (body: ProductCustomNameRestDto, product_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling syncProductCustomNames.');
            }
            // verify required parameter 'product_code' is not null or undefined
            if (product_code === null || product_code === undefined) {
                throw new RequiredError('product_code','Required parameter product_code was null or undefined when calling syncProductCustomNames.');
            }
            const localVarPath = `/product_mgmt/v1/catalog/product/{product_code}/custom_name`
                .replace(`{${"product_code"}}`, encodeURIComponent(String(product_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for synchronizing a product property for a product.
         * @summary REST.API.CPC.PMPP.01 Creates or updates a product property for a product.
         * @param {CustomerProductPropertyRestDto} body 
         * @param {string} product_code Identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProductProperty: async (body: CustomerProductPropertyRestDto, product_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling syncProductProperty.');
            }
            // verify required parameter 'product_code' is not null or undefined
            if (product_code === null || product_code === undefined) {
                throw new RequiredError('product_code','Required parameter product_code was null or undefined when calling syncProductProperty.');
            }
            const localVarPath = `/product_mgmt/v1/catalog/product/{product_code}/customer_property`
                .replace(`{${"product_code"}}`, encodeURIComponent(String(product_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductPropertyAPIApi - functional programming interface
 * @export
 */
export const ProductPropertyAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for synchronizing a product property for a product.
         * @summary REST.API.CPC.PMPP.03 Delete a product property for a product.
         * @param {string} product_code Identifies the product.
         * @param {string} type_code Identifies the type of a product property.
         * @param {string} language_code The language of the property value.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductProperty(_axios: AxiosInstance, product_code: string, type_code: string, language_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductPropertyAPIApiAxiosParamCreator(configuration).deleteProductProperty(product_code, type_code, language_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving the product properties for a product.
         * @summary REST.API.CPC.PMPP.02 Find the product properties for a product.
         * @param {string} product_code Identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_language_code] Filter the results based on the content language.
         * @param {string} [filter_type_code] Filter the results based on the type property.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCustomerProductProperties(_axios: AxiosInstance, product_code: string, x_dp_customer_code?: string|null, filter_language_code?: string|null, filter_type_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerProductPropertiesResultsRestDto>> {
            const localVarAxiosArgs = await ProductPropertyAPIApiAxiosParamCreator(configuration).findCustomerProductProperties(product_code, x_dp_customer_code, filter_language_code, filter_type_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving the custom names for a product.
         * @summary REST.API.CPC.PMPP.06 Find the customers custom names for a product.
         * @param {string} product_code Identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProductCustomNames(_axios: AxiosInstance, product_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductCustomNamesOverviewRestDto>> {
            const localVarAxiosArgs = await ProductPropertyAPIApiAxiosParamCreator(configuration).findProductCustomNames(product_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Find enum values for product property type
         * @summary REST.API.CPC.ENUM.03 Find product property type values.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProductPropertyTypeCodes(_axios: AxiosInstance, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodeDescriptionRestDto>> {
            const localVarAxiosArgs = await ProductPropertyAPIApiAxiosParamCreator(configuration).findProductPropertyTypeCodes(x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving the custom names of for a product.
         * @summary REST.API.CPC.PMPP.05 Creates or updates a customers custom names for a product.
         * @param {ProductCustomNameRestDto} body 
         * @param {string} product_code Identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncProductCustomNames(_axios: AxiosInstance, body: ProductCustomNameRestDto, product_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductPropertyAPIApiAxiosParamCreator(configuration).syncProductCustomNames(body, product_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for synchronizing a product property for a product.
         * @summary REST.API.CPC.PMPP.01 Creates or updates a product property for a product.
         * @param {CustomerProductPropertyRestDto} body 
         * @param {string} product_code Identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncProductProperty(_axios: AxiosInstance, body: CustomerProductPropertyRestDto, product_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductPropertyAPIApiAxiosParamCreator(configuration).syncProductProperty(body, product_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProductPropertyAPIApi - object-oriented interface
 * @export
 * @class ProductPropertyAPIApi
 * @extends {BaseAPI}
 */
export class ProductPropertyAPIApi extends BaseAPI {
    /**
     * API endpoint responsible for synchronizing a product property for a product.
     * @summary REST.API.CPC.PMPP.03 Delete a product property for a product.
     * @param {string} product_code Identifies the product.
     * @param {string} type_code Identifies the type of a product property.
     * @param {string} language_code The language of the property value.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPropertyAPIApi
     */
     public deleteProductProperty(product_code: string, type_code: string, language_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ProductPropertyAPIApiFp(this.configuration).deleteProductProperty(this.axios, product_code, type_code, language_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving the product properties for a product.
     * @summary REST.API.CPC.PMPP.02 Find the product properties for a product.
     * @param {string} product_code Identifies the product.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [filter_language_code] Filter the results based on the content language.
     * @param {string} [filter_type_code] Filter the results based on the type property.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPropertyAPIApi
     */
     public findCustomerProductProperties(product_code: string, x_dp_customer_code?: string|null, filter_language_code?: string|null, filter_type_code?: string|null, options?: any) {
        return ProductPropertyAPIApiFp(this.configuration).findCustomerProductProperties(this.axios, product_code, x_dp_customer_code, filter_language_code, filter_type_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving the custom names for a product.
     * @summary REST.API.CPC.PMPP.06 Find the customers custom names for a product.
     * @param {string} product_code Identifies the product.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPropertyAPIApi
     */
     public findProductCustomNames(product_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ProductPropertyAPIApiFp(this.configuration).findProductCustomNames(this.axios, product_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find enum values for product property type
     * @summary REST.API.CPC.ENUM.03 Find product property type values.
     * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPropertyAPIApi
     */
     public findProductPropertyTypeCodes(x_dp_customer_code?: string|null, options?: any) {
        return ProductPropertyAPIApiFp(this.configuration).findProductPropertyTypeCodes(this.axios, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving the custom names of for a product.
     * @summary REST.API.CPC.PMPP.05 Creates or updates a customers custom names for a product.
     * @param {ProductCustomNameRestDto} body 
     * @param {string} product_code Identifies the product.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPropertyAPIApi
     */
     public syncProductCustomNames(body: ProductCustomNameRestDto, product_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ProductPropertyAPIApiFp(this.configuration).syncProductCustomNames(this.axios, body, product_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for synchronizing a product property for a product.
     * @summary REST.API.CPC.PMPP.01 Creates or updates a product property for a product.
     * @param {CustomerProductPropertyRestDto} body 
     * @param {string} product_code Identifies the product.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPropertyAPIApi
     */
     public syncProductProperty(body: CustomerProductPropertyRestDto, product_code: string, x_dp_customer_code?: string|null, options?: any) {
        return ProductPropertyAPIApiFp(this.configuration).syncProductProperty(this.axios, body, product_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
