import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cbs_overview = _resolveComponent("cbs-overview")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, { "container-fluid": "" }, {
    default: _withCtx(() => [
      _createVNode(_component_cbs_overview, {
        onCopiedLibCb: _ctx.reloadContent,
        onDeleteContentBlock: _ctx.deleteContentBlock,
        "content-blocks": this.contentBlocks,
        "content-type": "TEXT_WITH_IMAGE",
        "cb-type": _ctx.cbType,
        "can-preview": "",
        "can-use-library": "",
        "is-ready": _ctx.isReady,
        "has-content-blocks": _ctx.hasContentBlocks,
        description: _ctx.$t('descriptionHomepageCbOverview',{limit: _ctx.$can('use-license', 'DP_WEBSITE_STARTER') ? _ctx.$t('descriptionHomepageCbLimit') : '' }),
        title: _ctx.$t('contentBlocks'),
        "route-object-edit-cb": {name: 'edit-standard-homepage-content-block'},
        "route-object-new-cb": {name: 'new-standard-homepage-content-block'},
        "table-header-items": [_ctx.$t('image'), _ctx.$t('title'),_ctx.$t('shortContent'),_ctx.$t('sortWeight'),_ctx.$t('status'),'']
      }, null, 8, ["onCopiedLibCb", "onDeleteContentBlock", "content-blocks", "cb-type", "is-ready", "has-content-blocks", "description", "title", "table-header-items"])
    ]),
    _: 1
  }))
}