import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BannerDetail = _resolveComponent("BannerDetail", true)!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_BannerDetail, {
        "is-library": false,
        mode: _ctx.mode,
        "cb-id": _ctx.cbId
      }, null, 8, ["mode", "cb-id"])
    ]),
    _: 1
  }))
}