
import {defineComponent} from "vue";
import {Exception} from "@/api/interfaces";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {UIStateDto} from "@/dtos/UIStateDto";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {
  CustomerCategorizationActionOverviewRestDto,
  CustomerCategorizationActionOverviewResultsRestDto,
  ProductCategoriesForProductRestDto,
  ProductCategoryWithParentsRestDto
} from "@/api/pharma-cpc-product-mgmt/models";
import {ProductCategorizationRestService} from "@/services/rest/cpc-categorization/ProductCategorizationRestService";
import CategoryWithParents from "@/components/layouts/catalog/CategoryWithParents.vue";
import {ProductCategorizationTypeEnum} from "@/api/enums/product-categorization-type-enum";
import BaseModal from "@/components/UI/Bootstrap/Modal/BaseModal.vue";
import ProductCategoriesTree from "@/components/UI/catalog/ProductCategoriesTree.vue";
import {default as Modal} from "bootstrap/js/dist/modal";
import {generateDomId} from "@/helpers/functions/string";
import BaseModalButton from "@/components/UI/Bootstrap/Modal/BaseModalButton.vue";
import {ProductCategoryRestDto} from "@/api/pharma-cpc-product/models";
import {ActionEnum} from "@/api/enums/action-enum";
import BaseCard from "@/components/UI/Bootstrap/Card/BaseCard.vue";
import BaseCardBody from "@/components/UI/Bootstrap/Card/BaseCardBody.vue";
import BaseCardHeader from "@/components/UI/Bootstrap/Card/BaseCardHeader.vue";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import {ProductRestService} from "@/services/rest/cpc-product/ProductRestService";
import {CustomerContext} from "@/context/CustomerContext";

export default defineComponent({
  name: "ProductDetailCategoriesTab",
  components: {AlertError2, BaseCardHeader, BaseCardBody, BaseCard, BaseModalButton, ProductCategoriesTree, BaseModal, CategoryWithParents},
  emits: ["savedSuccessfully"],
  props: {
    productCode: {
      type: String,
      required: true
    },
    cnkCode: {
      type: String,
      required: true
    },
  },
  data: () => ({
    exceptions: [] as Exception[],
    tabUI: UIStateDto.createWithDefaults(),
    dpCategorisation: null as ProductCategoriesForProductRestDto | null,
    customerCategorisationActions: null as CustomerCategorizationActionOverviewResultsRestDto | null,
    categorizationType: ProductCategorizationTypeEnum.CHANGE_MASTER_CATEGORIZATION as ProductCategorizationTypeEnum | null,
    selectCategoryModal: null as Modal | null,
    selectCategoryModalId: generateDomId('select-category-modal') as string,
    hideCategoriesModalId: generateDomId('hide-category-modal') as string,
    categoriesTree: null as ProductCategoryRestDto | null,
    categoriesModal: null as HTMLElement | null,
    hideCategoriesModal: null as HTMLElement | null,
  }),
  mounted() {
    this.reloadContent();
  },
  methods: {
    async reloadContent() {
      this.tabUI
        .setNotReady()
        .clearError();
      try {
        this.categoriesTree = await ProductRestService.getInstance().getProductCategoriesTreeCustomer(CustomerContext.getCustomerCode());
        this.dpCategorisation = await ProductCategorizationRestService.getInstance().findMasterProductCategories(this.productCode);
        this.customerCategorisationActions = await ProductCategorizationRestService.getInstance().findCustomerCategorizationActions(this.productCode);
        if (this.customerCategorisationActions?.categorization_type_code) {
          this.categorizationType = this.customerCategorisationActions.categorization_type_code;
        }
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.tabUI.setReady();
      }
    },
    async setCategorizationType(type: ProductCategorizationTypeEnum): Promise<void> {
      this.categorizationType = type;
      if (!this.customerCategorisationActions) {
        return;
      }
    },
    async addCategory(category_code: string): Promise<void> {
      this.tabUI
        .setNotReady()
        .clearError();
      try {
        await ProductCategorizationRestService.getInstance().addProductCategorizationAction(
          {
            action_code: this.categorizationType === ProductCategorizationTypeEnum.CHANGE_MASTER_CATEGORIZATION
              ? ActionEnum.COPY_TO
              : ActionEnum.MOVE_TO,
            category_code: category_code
          }, this.productCode);
        this.customerCategorisationActions = await ProductCategorizationRestService.getInstance().findCustomerCategorizationActions(this.productCode);
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.tabUI.setReady();
      }
      this.closeCategoriesModal();
    },
    async hideCategory(category_code: string): Promise<void> {
      this.tabUI
        .setNotReady()
        .clearError();
      try {
        await ProductCategorizationRestService.getInstance().addProductCategorizationAction(
          {
            action_code: ActionEnum.HIDE,
            category_code: category_code
          }, this.productCode);
        this.customerCategorisationActions = await ProductCategorizationRestService.getInstance().findCustomerCategorizationActions(this.productCode);
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.tabUI.setReady();
      }
      this.closeHideCategoriesModal();
    },
    async deleteCategory(action: CustomerCategorizationActionOverviewRestDto): Promise<void> {
      this.tabUI
        .setNotReady()
        .clearError();
      try {
        await ProductCategorizationRestService.getInstance().deleteProductCategorizationAction(
          this.productCode, action.action.code, action.category.category.code);
        this.customerCategorisationActions = await ProductCategorizationRestService.getInstance().findCustomerCategorizationActions(this.productCode);
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.tabUI.setReady();
      }
      this.closeCategoriesModal();
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.tabUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.tabUI as UIStateDto))
        .catch(exceptions, true);
    },
    closeCategoriesModal() {
      if (this.$refs.categoriesModal) {
        (this.$refs.categoriesModal as any).close();
      }
    },
    closeHideCategoriesModal() {
      if (this.$refs.hideCategoriesModal) {
        (this.$refs.hideCategoriesModal as any).close();
      }
    },
  },
  computed: {
    ProductCategorizationTypeEnum() {
      return ProductCategorizationTypeEnum
    },
    userCategorisation(): ProductCategoryWithParentsRestDto[] {
      if (!this.dpCategorisation) {
        return [];
      }
      if (!this.customerCategorisationActions) {
        return this.dpCategorisation.categories ?? [];
      }
      let categories: ProductCategoryWithParentsRestDto[] =
        (this.customerCategorisationActions.categorization_type_code === ProductCategorizationTypeEnum.CHANGE_MASTER_CATEGORIZATION)
          ? JSON.parse(JSON.stringify(this.dpCategorisation.categories))
          : [];
      if (this.customerCategorisationActions?.results?.length) {
        for (const category of this.customerCategorisationActions.results) {
          if (category.action.code === 'COPY_TO') {
            categories.push(category.category);
          } else if (category.action.code === 'HIDE') {
            categories = categories.filter(item => item.category.code !== category.category.category.code);
          } else if (category.action.code === 'MOVE_TO') {
            categories.push(category.category);
          }
        }
      }
      return categories;
    },
  },
});
