import {
    BannerContentV2RestDto,
    BannerSlotContentRestDto,
    CmsBannerImageRestDto
} from "@/api/pharma-cms-content-block/models";
import {DpException} from "@/exception/DpException";
import {stringHasContent, stripHtmlTags} from "@/helpers/functions/string";
import {emptyMultilingualTextField} from "@/helpers/functions/multilingual";
import {BannerLinkTypeEnum} from "@/models/enum/BannerLinkTypeEnum";

const AVAILABLE_CONTENT_SIZE_CODES = ['SIZE_1_1', 'SIZE_1_2'] as string[];
const AVAILABLE_LANGUAGE_CODES = [null, 'nl', 'fr'] as string[] | null[];

function determineBannerLinkType(cb_banner: BannerContentV2RestDto): BannerLinkTypeEnum {
    const hasProductFilter = stringHasContent(cb_banner.product_filter_code) as boolean;
    const hasExtLink = (((cb_banner.internal_page_id && cb_banner.internal_page_id.nl !== null) || (cb_banner.ext_link_url && cb_banner.ext_link_url.nl !== null)) && cb_banner.page_ref_target) as boolean;

    if (hasProductFilter) {
        return BannerLinkTypeEnum.PRODUCT_FILTER;
    } else if (hasExtLink) {
        return BannerLinkTypeEnum.LINK;
    } else {
        return BannerLinkTypeEnum.NO_LINK;
    }
}

function parseSavedNormalContentBlockBanner(cb_banner: BannerContentV2RestDto): BannerContentV2RestDto {
    const parsed_cb_banner = JSON.parse(JSON.stringify(cb_banner)) as BannerContentV2RestDto;
    const link_type = determineBannerLinkType(cb_banner) as BannerLinkTypeEnum;

    if (parsed_cb_banner.description) {
        if (parsed_cb_banner.description?.nl && stripHtmlTags(parsed_cb_banner.description.nl).length < 1) {
            parsed_cb_banner.description.nl = null;
        }
        if (parsed_cb_banner.description?.fr && stripHtmlTags(parsed_cb_banner.description.fr).length < 1) {
            parsed_cb_banner.description.fr = null;
        }
        if (parsed_cb_banner.description?.en && stripHtmlTags(parsed_cb_banner.description.en).length < 1) {
            parsed_cb_banner.description.en = null;
        }
        if (parsed_cb_banner.description?.de && stripHtmlTags(parsed_cb_banner.description.de).length < 1) {
            parsed_cb_banner.description.de = null;
        }
    }

    //Remove empty banners
    parsed_cb_banner.banner_images = parsed_cb_banner.banner_images.filter((bannerImage: CmsBannerImageRestDto) =>
        bannerImage.image_key.length > 0
    );

    switch (link_type) {
        case BannerLinkTypeEnum.NO_LINK:
            parsed_cb_banner.ext_link_url = undefined;
            parsed_cb_banner.internal_page_id = undefined;
            parsed_cb_banner.page_ref_target = undefined;
            parsed_cb_banner.product_filter_code = null;
            break;
        case BannerLinkTypeEnum.PRODUCT_FILTER:
            parsed_cb_banner.ext_link_url = undefined;
            parsed_cb_banner.internal_page_id = undefined;
            parsed_cb_banner.page_ref_target = undefined;
            if (!stringHasContent(parsed_cb_banner.product_filter_code)) {
                throw [new DpException('No product_filter_code set')];
            }
            break;
        case BannerLinkTypeEnum.LINK:
            parsed_cb_banner.product_filter_code = null;
            break;
        default:
            //TODO: ASK proper vue error handling
            throw [new Error('Unsupported link type to search ' + link_type)];
    }

    return parsed_cb_banner;
}

function parseSavedNormalContentBlockBannerSlot(cb_banner_slot: BannerSlotContentRestDto): BannerSlotContentRestDto {
    const parsed_cb_banner_slot = JSON.parse(JSON.stringify(cb_banner_slot)) as BannerSlotContentRestDto;

    if (parsed_cb_banner_slot?.subscription_slot_id && parsed_cb_banner_slot.subscription_slot_id.length < 1) {
        parsed_cb_banner_slot.subscription_slot_id = null;
    }

    if (!AVAILABLE_CONTENT_SIZE_CODES.includes(parsed_cb_banner_slot.max_content_size_code)) {
        throw [new DpException('Unsupported max content size code for parsed saved banner slot: ' + parsed_cb_banner_slot.max_content_size_code)];
    }

    return parsed_cb_banner_slot;
}

function parseSearchedNormalContentBlockBanner(cb_banner: BannerContentV2RestDto, empty_banner_images: CmsBannerImageRestDto[], is_library: boolean): BannerContentV2RestDto {
    const parsed_cb_banner = JSON.parse(JSON.stringify(cb_banner)) as BannerContentV2RestDto;
    const paddedBannerImages = [] as CmsBannerImageRestDto[];

    if (is_library) {
        if (parsed_cb_banner.description) {
            for (let description in parsed_cb_banner.description) {
                description = (description === null) ? '' : description;
            }
        } else {
            parsed_cb_banner.description = emptyMultilingualTextField();
        }
    } else if (parsed_cb_banner.description) {
        delete parsed_cb_banner.description;
    }

    empty_banner_images.forEach((empty_banner_image: CmsBannerImageRestDto) => {
        let bannerToAdd = empty_banner_image as CmsBannerImageRestDto;
        let bannerFound = false as boolean;

        if (parsed_cb_banner.banner_images && Array.isArray(parsed_cb_banner.banner_images)) {
            parsed_cb_banner.banner_images.forEach((banner_image: CmsBannerImageRestDto) => {
                if (!bannerFound
                    && banner_image.content_size_code === empty_banner_image.content_size_code
                    && banner_image.language_code === empty_banner_image.language_code) {
                    bannerToAdd = banner_image;
                    bannerFound = true;
                }
            });
        }

        paddedBannerImages.push(bannerToAdd);
    });
    parsed_cb_banner.banner_images = paddedBannerImages;

    return parsed_cb_banner;
}

function parseSearchedNormalContentBlockBannerSlot(cb_banner_slot: BannerSlotContentRestDto): BannerSlotContentRestDto {
    const parsed_cb_banner_slot = JSON.parse(JSON.stringify(cb_banner_slot)) as BannerSlotContentRestDto;

    if (!AVAILABLE_CONTENT_SIZE_CODES.includes(parsed_cb_banner_slot.max_content_size_code)) {
        throw [new DpException('Unsupported max content size code for parsed searched banner slot: ' + parsed_cb_banner_slot.max_content_size_code)];
    }

    return parsed_cb_banner_slot;
}

export {
    AVAILABLE_CONTENT_SIZE_CODES,
    AVAILABLE_LANGUAGE_CODES,
    determineBannerLinkType,
    parseSavedNormalContentBlockBanner,
    parseSavedNormalContentBlockBannerSlot,
    parseSearchedNormalContentBlockBanner,
    parseSearchedNormalContentBlockBannerSlot
};
