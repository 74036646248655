import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "named-text-fragment-panel col border-left" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "feedback-panel border position-relative p-1 mb-3" }
const _hoisted_4 = { class: "buttons d-flex align-items-center" }
const _hoisted_5 = {
  class: "btn btn-sm btn-primary me-2",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSelect = _resolveComponent("InputSelect")!
  const _component_InputTextEditor = _resolveComponent("InputTextEditor")!
  const _component_dp_form = _resolveComponent("dp-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.namedTextFragment == null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.$t('new')), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", null, [
        _createVNode(_component_dp_form, {
          as: "form",
          novalidate: "",
          class: "mt-3 mb-3",
          onSubmit: _ctx.submitNamedFragmentContent
        }, {
          default: _withCtx(({ errors }) => [
            _createVNode(_component_InputSelect, {
              modelValue: this.localNamedTextFragment.code,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.localNamedTextFragment.code) = $event)),
              options: this.enumFragmentNameCode,
              name: _ctx.$t('fragmentCategoryCode'),
              selectText: _ctx.$t('selectText'),
              rules: "required",
              errors: errors
            }, null, 8, ["modelValue", "options", "name", "selectText", "errors"]),
            _createVNode(_component_InputTextEditor, {
              modelValue: this.localNamedTextFragment.fragment.content,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.localNamedTextFragment.fragment.content) = $event)),
              name: "fragmentContent",
              class: "mb-3",
              errors: errors
            }, null, 8, ["modelValue", "errors"]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", _hoisted_5, _toDisplayString(_ctx.$t('save')), 1),
              _createElementVNode("button", {
                class: "btn btn-sm btn-outline-primary",
                onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.submitNamedFragmentCancel()), ["prevent"]))
              }, _toDisplayString(_ctx.$t('cancel')), 1)
            ])
          ]),
          _: 1
        }, 8, ["onSubmit"])
      ])
    ])
  ]))
}