import {ContentBlockInRowCreateRestDto, ContentBlockInRowRestDto} from "@/api/pharma-cms-content-page/models";

export class ContentBlockInRowCreateRestDtoModel implements ContentBlockInRowCreateRestDto
{
    cb_in_row?: ContentBlockInRowRestDto;
    target_position: any;

    constructor(target_position: any, cb_in_row?: ContentBlockInRowRestDto) {
        this.cb_in_row = cb_in_row;
        this.target_position = target_position;
    }

    static createWithDefaults(): ContentBlockInRowCreateRestDtoModel {
        return new ContentBlockInRowCreateRestDtoModel(null);
    }
}
