
import {defineComponent} from "vue";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import {mapActions} from 'vuex';
import {TargetPageInfoRestDto} from "@/api/pharma-cms-content-page/models";
import InputText from "@/components/UI/InputText.vue";
import AbstractCbCommon from "@/components/layouts/content-pages/wysiwyg/structures/AbstractCbCommon.vue";
import {ButtonContentRestDtoModel} from "@/models/api/pharma-cms-content-block/ButtonContentRestDtoModel";
import {PageLinkPayload} from "@/models/payload/PageLinkPayload";
import PageLinkPicker from "@/components/UI/PageLinkPicker.vue";
import {LinkTargetEnum} from "@/models/enum/LinkTargetEnum";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import {PageLinkTypeEnum} from "@/models/enum/PageLinkTypeEnum";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import {ButtonTargetEnum} from "@/api/enums/button-target-enum";
import {DpException} from "@/exception/DpException";
import {ContentBlockTypesEnum} from "@/helpers/functions/enum";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import InputSelect from "@/components/UI/InputSelect.vue";

export default defineComponent({
  name: "CpCbFormButton",
  extends: AbstractCbCommon,
  components: {InputSelect, BaseSpinner, InputText, PageLinkPicker},
  data: () => ({
    contentBlock: ButtonContentRestDtoModel.createWithDefaults(),
    pageLinkPayload: PageLinkPayload.createWithDefaults(),
    linkedContentPage: {} as TargetPageInfoRestDto,
  }),
  watch: {
    contentBlock() {
      this.buildPageLinkPayload();
    },
  },
  computed: {
    btnLayoutOptions(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.BUTTON_LAYOUT);
    },
    extLinkOptions(): Object[] {
      return this.getEnum(EnumClassNameCmsEnum.LINK_TARGET);
    },
  },
  methods: {
    ...mapActions('cms_content_block/normal_content_block/button', ['create', 'find', 'save']),
    getDefaultCbModel(): ButtonContentRestDtoModel {
      const defaultCbModel = ButtonContentRestDtoModel.createWithDefaults();

      if (this.cbType === ContentBlockTypesEnum.BUTTON) {
        defaultCbModel.button.button_url = undefined;
        defaultCbModel.button.button_page_id = undefined;
        defaultCbModel.button.button_target = undefined;
      }

      return defaultCbModel;
    },
    determineEnums(): EnumClassNameCmsEnum[] {
      return [
        EnumClassNameCmsEnum.BUTTON_LAYOUT,
        EnumClassNameCmsEnum.LINK_TARGET,
      ];
    },
    buildPageLinkPayload(): void {
      const externalUrl = this.contentBlock.button.button_url?.nl ?? null;
      const pageId = this.contentBlock.button.button_page_id?.nl ?? null;
      const target = this.contentBlock.button.button_target ?? LinkTargetEnum.SELF;

      this.pageLinkPayload = new PageLinkPayload(externalUrl, pageId, target.toString() as LinkTargetEnum);
    },
    onPageLinkPickerChanged(payload: PageLinkPayload) {
      if (payload.type === PageLinkTypeEnum.NONE) {
        // will always throw error on paste of metadata when the page link is yet to be filled in
        // if (this.cbType === ContentBlockTypesEnum.CALL_TO_ACTION) {
        //   throw new DpException("Can't use page link type 'NONE' for 'call to action' CB.")
        // }
        this.contentBlock.button.button_url = undefined;
        this.contentBlock.button.button_page_id = undefined;
        this.contentBlock.button.button_target = undefined;
      } else if (payload.type === PageLinkTypeEnum.URL) {
        this.contentBlock.button.button_url = new MultilingualRestDtoModel(null, null, null, payload.external_url);
        this.contentBlock.button.button_page_id = undefined;
        this.contentBlock.button.button_target = payload.target.toString() as ButtonTargetEnum;
      } else if (payload.type === PageLinkTypeEnum.PAGE) {
        this.contentBlock.button.button_url = MultilingualRestDtoModel.createWithDefaults();
        this.contentBlock.button.button_page_id = new MultilingualRestDtoModel(null, null, null, payload.page_id);
        this.contentBlock.button.button_target = payload.target.toString() as ButtonTargetEnum;
      }

      this.onChange();
    },
    pasteCbMetadata(): void {
      // When calling clone method directly on object, method is not found. So this is a hacky workaround
      const cbClone = ButtonContentRestDtoModel.cloneFrom(this.contentBlock);

      cbClone.cloneMetadataFrom(this.getClipboardContentBlock(this.cbType) as ButtonContentRestDtoModel);
      this.contentBlock = cbClone;

      this.buildPageLinkPayload();
    },
  }
});
