
import {defineComponent} from "vue";
import {TinyEmitter} from "tiny-emitter";
import {CmsDocumentRestService} from "@/services/rest/cms-document/CmsDocumentRestService";

export default defineComponent({
  name: "CbLibraryOverviewItem",
  props: {
    contentBlock: {
      type: Object,
      required: true,
    },
    bus: {
      type: TinyEmitter,
      required: true,
    }
  },
  data() {
    return {
      imagePreviewUrl: null as string | null,
    }
  },
  mounted() {
    this.findPreviewUrl();
  },
  computed: {
    existingDocumentKey(): string | null {
      return this.contentBlock.cb?.image?.image_key?.nl ?? null;
    },
    cardStyles(): Object | null {
      if (this.imagePreviewUrl) {
        return {
          flexDirection: 'row',
          alignItems: 'flex-start',
        };
      } else {
        return null
      }
    },
    imageStyles(): Object {
      if (this.imagePreviewUrl) {
        return {
          padding: '1rem 0 1rem 1rem',
          height: 'auto',
          maxWidth: 'calc(50% - 1rem)',
        };
      } else {
        return {
          padding: '1rem 0',
          height: '8rem',
          objectFit: 'contain',
          objectPosition: 'center center',
        };
      }
    }
  },
  methods: {
    async findPreviewUrl() {
      if (this.existingDocumentKey) {
        CmsDocumentRestService.getInstance()
          .findPreviewUrl(this.existingDocumentKey).then((url: string) => {
          this.imagePreviewUrl = url;
        });
      } else {
        this.imagePreviewUrl = null;
      }
    }
  }
});
