import {FindNormalContentBlockPayload} from "@/models/payload/FindNormalContentBlockPayload";
import {ContentBlockEndpointsApiService} from "@/main";
import {SaveNormalContentBlockPayload} from "@/models/payload/SaveNormalContentBlockPayload";
import {CreateNormalContentBlockPayload} from "@/models/payload/CreateNormalContentBlockPayload";
import {SymbolContentRestDtoModel} from "@/models/api/pharma-cms-content-block/SymbolContentRestDtoModel";

export default {
    namespaced: true,
    actions: {
        async create(context: any, payload: CreateNormalContentBlockPayload): Promise<string> {
            console.log('Symbol::create()');
            const response = await ContentBlockEndpointsApiService.createNormalContentBlockSymbol(payload.cb as SymbolContentRestDtoModel, payload.type);

            return response.data.id as string;
        },
        async find(context: any, payload: FindNormalContentBlockPayload): Promise<SymbolContentRestDtoModel> {
            console.log('Symbol::find()');
            const response = await ContentBlockEndpointsApiService.getNormalContentBlockSymbol(payload.type, payload.id);

            return response.data as SymbolContentRestDtoModel;
        },
        async save(context: any, payload: SaveNormalContentBlockPayload): Promise<void> {
            console.log('Symbol::save()');
            await ContentBlockEndpointsApiService.updateNormalContentBlockSymbol(payload.cb as SymbolContentRestDtoModel, payload.type, payload.id);
        },
    }
};
