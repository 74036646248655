import {
    MultilingualMax2000FieldRestDto,
    MultilingualMax500FieldRestDto,
    MultilingualMax50FieldRestDto
} from "@/api/pharma-cms-content-block/models";

export class MultilingualPayload {
    [key: string]: string | null;

    /**
     * The field value in Dutch.
     * @type {string|null}
     * @memberof MultilingualFieldRestDto
     */
    nl: string|null;

    /**
     * The field value in French.
     * @type {string|null}
     * @memberof MultilingualFieldRestDto
     */
    fr: string|null;

    /**
     * The field value in English.
     * @type {string|null}
     * @memberof MultilingualFieldRestDto
     */
    en: string|null;

    /**
     * The field value in German.
     * @type {string|null}
     * @memberof MultilingualFieldRestDto
     */
    de: string|null;

    constructor(nl: string|null = null, fr: string|null = null, en: string|null = null, de: string|null = null) {
        this.nl = nl;
        this.fr = fr;
        this.en = en;
        this.de = de;
    }

    public static fromMultilingualRestDto(dto: MultilingualMax50FieldRestDto | MultilingualMax500FieldRestDto | MultilingualMax2000FieldRestDto | null): MultilingualPayload {
        if (dto === null) {
            return new this();
        }

        return new MultilingualPayload(
            dto.nl ?? null,
            dto.fr ?? null,
            dto.en ?? null,
            dto.de ?? null
        );
    }
}
