// Vee-validate configuration and global validators
// Docs - https://vee-validate.logaretm.com/v4/
import {defineRule} from "vee-validate";
import {max, min, regex, required, url, email} from "@vee-validate/rules";
import {stringHasContent} from "@/helpers/functions/string";


defineRule('required', required);
defineRule('max', max);
defineRule('min', min);
defineRule('regex', regex);
defineRule('url', url);
defineRule('email', email);

//TODO re-evaluate these rules
defineRule("comes_after", (value, [begin, end]) => {
    if ((begin && end) && begin > end) {
        return 'De einddatum moet gelijk aan of later zijn';
    }
    return true;
});
defineRule("req_if_end", (value, [begin, end]) => {
    if ((begin && end) && end.length > 0 && begin.length === 0) {
        return 'Geef een begindatum op';
    }
    return true;
});
defineRule("req_if_begin", (value, [begin, end]) => {
    if ((begin && end) && begin.length > 0 && end.length === 0) {
        return 'Geef een einddatum op';
    }
    return true;
});
defineRule("both_set", (value, [otherValue]) => {
    const hasValue = value && value.length > 0;
    const hasOtherValue = otherValue && otherValue.length > 0;

    if ((!hasValue && hasOtherValue)) {
        //TODO: make translatable
        return 'Voor het toevoegen van een knop zijn zowel de knoptekst als de link verplicht.';
    } else {
        return true;
    }
});

defineRule('all_set', (value, [target, target2, target3]) => {
    const hasTargetValue = stringHasContent(target) || stringHasContent(target2) || stringHasContent(target3);
    console.log(hasTargetValue)

    if (stringHasContent(value) && !hasTargetValue) {
        //TODO: make translatable
        return 'Voor het toevoegen van een knop zijn zowel de knoptekst als de link verplicht.';
    }
    return true;
});
