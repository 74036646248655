import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row mt-2" }
const _hoisted_2 = { class: "col-auto" }
const _hoisted_3 = { class: "col-auto" }
const _hoisted_4 = { class: "row mt-2" }
const _hoisted_5 = { class: "col-auto" }
const _hoisted_6 = { class: "col-auto" }
const _hoisted_7 = { class: "row mt-2" }
const _hoisted_8 = { class: "col-auto" }
const _hoisted_9 = { class: "row mt-2" }
const _hoisted_10 = { class: "col-auto" }
const _hoisted_11 = { class: "buttons d-flex justify-content-begin start" }
const _hoisted_12 = {
  class: "btn btn-sm btn-primary me-2",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_AlertError2 = _resolveComponent("AlertError2")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_input_select = _resolveComponent("input-select")!
  const _component_input_checkbox_boolean = _resolveComponent("input-checkbox-boolean")!
  const _component_dp_form = _resolveComponent("dp-form")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, { "has-build-status-on-page": "" }, {
    default: _withCtx(() => [
      _createVNode(_component_BaseTitle, {
        title: _ctx.$t('addMenu'),
        "help-text": _ctx.$t('descriptionAddMenu'),
        mb: 3,
        "back-route": {name: 'menus'},
        "show-refresh-button": ""
      }, null, 8, ["title", "help-text"]),
      _createVNode(_component_AlertError2, {
        error: _ctx.ui.getError()
      }, null, 8, ["error"]),
      _withDirectives(_createVNode(_component_dp_form, {
        as: "form",
        novalidate: "",
        onSubmit: _ctx.submitForNewMenu
      }, {
        default: _withCtx(({ errors }) => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_InputText, {
                modelValue: _ctx.menu.code,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menu.code) = $event)),
                label: _ctx.$t('code'),
                name: "code",
                rules: "required",
                errors: errors
              }, null, 8, ["modelValue", "label", "errors"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_InputText, {
                modelValue: _ctx.menu.name,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.menu.name) = $event)),
                label: _ctx.$t('name'),
                name: "name",
                rules: "required",
                errors: errors
              }, null, 8, ["modelValue", "label", "errors"])
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_input_select, {
                modelValue: _ctx.menu.layout_code,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.menu.layout_code) = $event)),
                label: _ctx.$t('layout'),
                name: "layoutCode",
                options: _ctx.menuLayoutOptions,
                errors: errors
              }, null, 8, ["modelValue", "label", "options", "errors"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_input_select, {
                modelValue: _ctx.menu.horizontal_position_code,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.menu.horizontal_position_code) = $event)),
                label: _ctx.$t('horizontalPosition'),
                name: "horizontalPositionCode",
                options: _ctx.menuHorizontalPositionOptions,
                errors: errors
              }, null, 8, ["modelValue", "label", "options", "errors"])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_input_checkbox_boolean, {
                modelValue: _ctx.menu.is_submenu_allowed,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.menu.is_submenu_allowed) = $event)),
                label: _ctx.$t('isSubmenuAllowed'),
                name: "isSubmenuAllowed",
                errors: errors
              }, null, 8, ["modelValue", "label", "errors"])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("button", _hoisted_12, _toDisplayString(_ctx.$t('save')), 1)
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"]), [
        [_vShow, _ctx.ui.isReady()]
      ])
    ]),
    _: 1
  }))
}