import ContentBlockLibrariesModule from "@/store/pharma-cms-content-block/content_block_libraries";
import SimpleContentBlocksModule from "@/store/pharma-cms-content-block/simple_content_blocks";
import NormalContentBlocksModule from "@/store/pharma-cms-content-block/normal_content_blocks";
import NormalContentBlock from "@/store/pharma-cms-content-block/normal-content-block";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-page/models";
import {ContentBlockEnumEndpointsApiService} from "@/main";


export default {
    namespaced: true,
    modules: {
        content_block_libraries: ContentBlockLibrariesModule,
        simple_content_blocks: SimpleContentBlocksModule,
        normal_content_blocks: NormalContentBlocksModule,
        normal_content_block: NormalContentBlock,
    },
    state() {
        return {
            contentBlockStructureTypeEnums: new Map<boolean, CodeDescriptionRestDto[]>(),
        };
    },
    actions: {
        async findContentBlockStructureTypeEnums(context: any, filter_is_supported_on_page: boolean = false): Promise<CodeDescriptionRestDto[]> {
            if (!context.state.contentBlockStructureTypeEnums.has(filter_is_supported_on_page)) {
                const response = await ContentBlockEnumEndpointsApiService.findContentBlockStructureTypeEnums(undefined, filter_is_supported_on_page);

                context.state.contentBlockStructureTypeEnums.set(filter_is_supported_on_page, response.data as CodeDescriptionRestDto[]);
            }

            return context.state.contentBlockStructureTypeEnums.get(filter_is_supported_on_page);
        },
    }
}
