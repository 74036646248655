import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cb_form_text = _resolveComponent("cb-form-text")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_cb_form_text, {
        "can-restore-default-values": "",
        "cb-type": "PHARMACY_ON_CALL_URL",
        mode: "edit",
        "input-type": "text",
        title: _ctx.$t('pharmacyOnCall'),
        description: _ctx.$t('pharmacyOnCallDescription'),
        "caching-delay-minutes": 5
      }, null, 8, ["title", "description"])
    ]),
    _: 1
  }))
}