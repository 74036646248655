
import {Exception} from '@/api/interfaces';
import {defineComponent} from "vue";
import {
  CodeDescriptionRestDto,
  TeamMemberRestDtoStatusCodeEnum,
} from "@/api/pharma-cms-pharmacy/models";
import MultilingualSwitcher from "@/components/UI/MultilingualSwitcher.vue";
import {I18nContext} from "@/context/I18nContext";
import I18nInputText from "@/components/UI/I18n/I18nInputText.vue";
import {TeamMemberRestDtoModel} from "@/models/TeamMemberRestDtoModel";
import {useToast} from "vue-toastification";
import {TeamMemberRestService} from "@/services/rest/cms-pharmacy/TeamMemberRestService";
import InputImage from "@/components/UI/InputImage.vue";
import I18nInputTextEditor from "@/components/UI/I18n/I18nInputTextEditor.vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import ConfirmModal from "@/components/UI/ConfirmModal.vue";

export default defineComponent({
  name: "TeamMembersDetail",
  components: {BaseTitle, I18nInputTextEditor, InputImage, I18nInputText, MultilingualSwitcher},
  props: ['mode'],
  data() {
    return {
      isReady: false as boolean,
      exceptions: [] as Exception[],

      id: this.$route.params.id as string,
      docType: 'TEAMMEMBER_IMG' as string,

      activeLanguage: I18nContext.getDefaultApiLanguageKey(),

      teamMember: TeamMemberRestDtoModel.createWithDefaults(),
      toaster: useToast(),
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    statusOptions(): CodeDescriptionRestDto[] {
      return [
        {code: TeamMemberRestDtoStatusCodeEnum.PUBLISHED, description: this.$t('published')},
        {code: TeamMemberRestDtoStatusCodeEnum.NOTPUBLISHED, description: this.$t('notPublished')}
      ];
    }
  },
  methods: {
    async deleteMember(): Promise<void> {
      try {
        const {reveal, onConfirm} = createConfirmDialog(ConfirmModal, {
          title: 'Teamlid verwijderen',
          body: 'Bent u zeker dat u <strong>' + this.teamMember.first_name + ' ' + this.teamMember.last_name + '</strong> wilt verwijderen?',
          confirmButtonText: 'Verwijderen',
          confirmButtonClass: 'btn-danger',
        });

        onConfirm(async () => {
          try {
            await TeamMemberRestService.getInstance().deleteTeamMember(this.id);
            this.toaster.success('Teamlid succesvol verwijderd.');
            await this.$router.push({name: 'team-members'});
          } catch (exceptions: any) {
            this.exceptions = exceptions;
          }
        });

        await reveal();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async reloadContent(): Promise<void> {
      this.isReady = false;
      this.exceptions = [];

      try {
        if (this.id) {
          this.teamMember = await TeamMemberRestService.getInstance().findTeamMember(this.id);
        }
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      } finally {
        this.isReady = true;
      }
    },
    async submitData(): Promise<void> {
      this.exceptions = [];

      try {
        if (this.mode === 'new') {
          const id = await TeamMemberRestService.getInstance().createTeamMember(this.teamMember);
          this.toaster.success(this.$t('savedSuccessfully'));
          await this.$router.push({
            name: 'edit-team-member',
            params: {
              id: id
            }
          });
        } else {
          await TeamMemberRestService.getInstance().updateTeamMember(this.id, this.teamMember);
          this.toaster.success(this.$t('savedSuccessfully'));
          await this.reloadContent();
        }
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
  }
});
