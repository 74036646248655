import {ContentBlockLibraryEndpointsApiService, ContentBlockLinkEndpointsApiService} from "@/main";
import {
    ContentBlockLinkRestDto, LibraryOverviewRestDto,
} from "@/api/pharma-cms-content-block/models";
import {DpException} from "@/exception/DpException";

export default {
    state() {
        return {
            cb_libs_for_type: {} as Object,
            lib_cbs: {} as Object,
        }
    },
    actions: {
        /*
        * Deprecated
         */
        async createContentBlockLink(context: any, payload: any): Promise<string> {
            const body = {
                from_cb_type_code: payload.from_cb_type_code,
                from_cb_id: payload.from_cb_id,
                to_cb_id: payload.to_cb_id,
                force_copy: payload.force_copy,
            } as ContentBlockLinkRestDto;

            const response = await ContentBlockLinkEndpointsApiService.createContentBlockLink(body, '');
            return response.data.id as string;
        },
        /*
        * Deprecated
         */
        async searchContentBlockLibsForType(context: any, payload: any) {
            const type = payload.type as string;
            const filterIsOwner = payload?.filter_is_owner ?? undefined as boolean | undefined;
            const expectExternalName = payload?.expect_external_name ?? undefined as boolean | undefined;

            const response = await ContentBlockLibraryEndpointsApiService.findContentBlockLibsForType(type, undefined, filterIsOwner, expectExternalName);

            context.commit('setContentBlockLibsForType', {
                libs: response.data,
                type: type,
            });
        },
        /*
        * Deprecated
         */
        async searchLibContentBlocks(context: any, libCode: string) {
            let response = null;

            if (libCode === 'LIB_DP_BANNERS_VOOR_DP_SLOTS' || libCode === 'LIB_DP_BANNERS_ALG' || libCode === 'LIB_FARMADA_BANNERS' || libCode.toUpperCase().includes('BANNERS')) {
                response = await ContentBlockLibraryEndpointsApiService.getLibraryContentBlocksBanner(libCode);
            } else if (libCode === 'LIB_DP_BANNER_SLOTS') {
                // Deprecated, refer to the corresponding rest service
                response = await ContentBlockLibraryEndpointsApiService.getLibraryContentBlocksBannerSlot(libCode);
            } else if (libCode.toUpperCase().includes('LOGO') || libCode.toUpperCase().includes('MAIN_IMG') || libCode.toUpperCase().includes('IMAGES')) {
                response = await ContentBlockLibraryEndpointsApiService.getLibraryContentBlocksImage(libCode);
            } else if (libCode.toUpperCase().includes('TEXT_WITH_IMG') || libCode.toUpperCase().includes('TWI')) {
                response = await ContentBlockLibraryEndpointsApiService.getLibraryContentBlocksTextWithImage(libCode);
            } else if (libCode.toUpperCase().includes('LIB_DP_WELCOME_TXT') || libCode.toUpperCase().includes('TWT')) {
                response = await ContentBlockLibraryEndpointsApiService.getLibraryContentBlocksTextWithTitle(libCode);
            } else {
                throw [new DpException('Unsupported library to search ' + libCode)];
            }

            context.commit('setLibContentBlocks', {
                lib: response.data,
                lib_code: libCode,
            });
        },
    },
    mutations: {
        setContentBlockLibsForType(state: any, payload: any) {
            // todo maybe add validation for supported types

            state.cb_libs_for_type[payload.type] = payload.libs;
        },
        setLibContentBlocks(state: any, payload: any) {
            state.lib_cbs[payload.lib_code] = payload.lib;
        }
    },
    getters: {
        getContentBlockLibsForType: (state: any) => (type: string): LibraryOverviewRestDto[] => {
            // todo maybe add validation for supported types
            // ['BANNER', 'LOGO_IMG_ID', 'PHARMACY_WEBSITE_MAIN_IMG_ID', 'SMA_HOMEPAGE_TEXT_WITH_IMAGE', 'CATEGORIES_CONTENT_BLOCK', 'GENERAL_MESSAGE', 'PRODUCTS_IN_THE_SPOTLIGHT', 'WELCOME_TXT', 'CUSTOM_CONTENT_PAGE','SERVICES_PAGE','PAGE_CONTENT_CB_TEXT_WITH_IMAGE','PAGE_CONTENT_CB_TEXT_WITH_TITLE', ...new content types]

            try {
                return state.cb_libs_for_type[type];
            } catch (e) {
                console.log(e);
                throw [new DpException('Unsupported ContentBlockLibsForType to get ' + type)];
            }
        },
        getLibContentBlocks: (state: any) => (lib_code: string): [] => {
            return state.lib_cbs[lib_code] as [];
        },
    }
}
