import {
    BannerContentRestDto, BannerContentV2RestDto, BannerInSlotContentRestDto,
    BannerSlotContentRestDto,
    CmsBannerImageRestDto, ContentOverviewRestDto
} from "@/api/pharma-cms-content-block/models";
import {ContentBlockEndpointsApiService, ContentBlockLibraryEndpointsApiService} from "@/main";
import {
    AVAILABLE_CONTENT_SIZE_CODES,
    AVAILABLE_LANGUAGE_CODES,
    parseSavedNormalContentBlockBanner, parseSavedNormalContentBlockBannerSlot,
    parseSearchedNormalContentBlockBanner, parseSearchedNormalContentBlockBannerSlot
} from "@/store/pharma-cms-content-block/middleware";

export default {
    state() {
        return {
            empty_banner_images: [] as CmsBannerImageRestDto[],

            cb_banner: {} as BannerContentV2RestDto,
            cb_banner_slot: {} as BannerSlotContentRestDto,
            cb_lib_banner: {} as BannerContentV2RestDto,
            cb_lib_banner_slot: {} as BannerSlotContentRestDto,

            banners_in_slots_for_slot: [] as ContentOverviewRestDto[],
        }
    },
    actions: {
        addBannerImage(context: any, payload: any) {
            const doc_key = payload.doc_key as string;
            const lang = payload.lang as string | null;
            const size = payload.size as string;
            const cb_type = payload.cb_type as string;

            context.commit("addBannerImage", {
                doc_key: doc_key,
                banner_image: context.getters.findBannerImage({
                    cb_type: cb_type,
                    lang: lang,
                    size: size,
                }) as CmsBannerImageRestDto,
            });
        },
        clearBannerPeriod(context: any, cb_type: string) {
            context.commit("clearBannerPeriod", {
                cb_banner: context.getters.getNormalContentBlockBanner(cb_type)
            });
        },
        deleteBannerImage(context: any, payload: any) {
            const lang = payload.lang as string | null;
            const size = payload.size as string;
            const cb_type = payload.cb_type as string;

            context.commit("deleteBannerImage", {
                banner_image: context.getters.findBannerImage({
                    cb_type: cb_type,
                    lang: lang,
                    size: size,
                }) as CmsBannerImageRestDto,
            });
        },
        generateEmptyBannerImages(context: any, is_library: boolean) {
            context.commit('generateEmptyBannerImages', is_library);
        },
        setBannerSlotField(context: any, payload: any) {
            const type = payload.type as string;
            const key = payload.key as string;
            const value = payload.value as any;

            context.commit('setBannerSlotField', {
                cb: context.getters.getNormalContentBlockBannerSlot(type) as BannerSlotContentRestDto,
                key: key,
                value: value,
            });
        },

        async createLibContentBlockBanner(context: any, library_code: string) {
            const cb = context.getters.getNormalContentBlockBanner("LIB_BANNER") as BannerContentV2RestDto;

            await ContentBlockLibraryEndpointsApiService.createLibContentBlockBannerV2(parseSavedNormalContentBlockBanner(cb), library_code);
        },
        async createNormalContentBlockBanner(context: any, type: string) {
            const cb = context.getters.getNormalContentBlockBanner(type) as BannerContentV2RestDto;

            await ContentBlockEndpointsApiService.createNormalContentBlockBannerV2(parseSavedNormalContentBlockBanner(cb), type);
        },
        /*
        * Deprecated
         */
        async createNormalContentBlockBannerInSlot(context: any, payload: any) {
            const cb = payload.cb as BannerInSlotContentRestDto;
            const type = payload.type as string;

            await ContentBlockEndpointsApiService.createNormalContentBlockBannerInSlot(cb, type);
        },

        async findBannersInSlotsForSlot(context: any, payload: any): Promise<ContentOverviewRestDto[]> {
            const type = payload.type as string;
            const position_code = payload.position_code as string;
            const filter_status_code = payload.filter_status_code as string | undefined;
            const filter_library_code = payload.filter_library_code as string | undefined;

            const response = await ContentBlockEndpointsApiService.findBannerInSlotsForSlot(type, position_code, undefined, filter_status_code, filter_library_code);

            return response.data;
        },
        async searchBannersInSlotsForSlot(context: any, payload: any) {
            const findPayload = {
                type: payload.type as string,
                position_code: payload.position_code as string,
                filter_status_code: payload.filter_status_code as string | undefined,
                filter_library_code: payload.filter_library_code as string | undefined,
            };
            const response = await context.dispatch("findBannersInSlotsForSlot", findPayload) as ContentOverviewRestDto[];

            context.commit('setBannersInSlotsForSlot', response);
        },
        async searchNormalContentBlockBanner(context: any, payload: any) {
            const id = payload.id as string;
            const type = payload.type as string;
            const is_library = payload.is_library as boolean;

            const response = await ContentBlockEndpointsApiService.getNormalContentBlockBannerV2(type, id);
            await context.dispatch("generateEmptyBannerImages", is_library);

            context.commit("setNormalContentBlockBanner", {
                type: type,
                cb: parseSearchedNormalContentBlockBanner(response.data, context.getters.getEmptyBannerImages, is_library),
            });
        },
        async searchNormalContentBlockBannerSlot(context: any, payload: any) {
            const id = payload.id as string;
            const type = payload.type as string;

            const response = await ContentBlockEndpointsApiService.getNormalContentBlockBannerSlot(type, id);

            context.commit('setNormalContentBlockBannerSlot', {
                type: type,
                cb: parseSearchedNormalContentBlockBannerSlot(response.data),
            });
        },

        async saveNormalContentBlockBanner(context: any, payload: any) {
            const id = payload.id as string;
            const type = payload.type as string;
            const cb = context.getters.getNormalContentBlockBanner(type) as BannerContentV2RestDto;

            await ContentBlockEndpointsApiService.updateNormalContentBlockBannerV2(parseSavedNormalContentBlockBanner(cb), type, id);
        },
        async saveNormalContentBlockBannerSlot(context: any, payload: any) {
            const id = payload.id as string;
            const type = payload.type as string;
            const cb = context.getters.getNormalContentBlockBannerSlot(type) as BannerSlotContentRestDto;

            await ContentBlockEndpointsApiService.updateNormalContentBlockBannerSlot(parseSavedNormalContentBlockBannerSlot(cb), type, id);
        },
    },
    mutations: {
        addBannerImage(state: any, payload: any) {
            payload.banner_image.image_key = payload.doc_key;
        },
        clearBannerPeriod(state: any, payload: any) {
            const cb_banner = payload.cb_banner as BannerContentRestDto;

            cb_banner.begin_date = null;
            cb_banner.end_date = null;
        },
        deleteBannerImage(state: any, payload: any) {
            payload.banner_image.image_key = '';
        },
        generateEmptyBannerImages(state: any, is_library: boolean) {
            const dynamicLanguageCodes = (is_library) ? AVAILABLE_LANGUAGE_CODES : ['nl'] as String[] | null[];

            state.empty_banner_images = [];
            dynamicLanguageCodes.forEach((AVAILABLE_LANGUAGE_CODE: String | null) => {
                AVAILABLE_CONTENT_SIZE_CODES.forEach((AVAILABLE_CONTENT_SIZE_CODE: String) => {
                    state.empty_banner_images.push(
                        {
                            image_key: '',
                            language_code: AVAILABLE_LANGUAGE_CODE,
                            content_size_code: AVAILABLE_CONTENT_SIZE_CODE,
                        }
                    );
                });
            });
        },
        setBannerSlotField(state: any, payload: any) {
            payload.cb[payload.key] = payload.value;
        },
        setBannersInSlotsForSlot(state: any, banners_in_slots: ContentOverviewRestDto[]) {
            state.banners_in_slots_for_slot = banners_in_slots;
        },
        setNormalContentBlockBanner(state: any, payload: any) {
            const cb = payload.cb as BannerContentV2RestDto;
            const type = payload.type as string;

            // todo map gebruiken
            if (type === 'BANNER') {
                state.cb_banner = cb;
            } else if (type === 'LIB_BANNER') {
                state.cb_lib_banner = cb;
            } else {
                throw new Error('Unsupported NormalContentBlockBanner to set ' + type);
            }
        },
        setNormalContentBlockBannerSlot(state: any, payload: any) {
            const cb = payload.cb;
            const type = payload.type;

            // todo map gebruiken
            if (type === 'BANNER_SLOT') {
                state.cb_banner_slot = cb;
            } else if (type === 'LIB_BANNER_SLOT') {
                state.cb_lib_banner_slot = cb;
            } else {
                throw new Error('Unsupported NormalContentBlockBannerSlot to set ' + type);
            }
        },
    },
    getters: {
        findBannerImage: (state: any, getters: any) => (payload: any): CmsBannerImageRestDto => {
            const cb_type = payload.cb_type as string;
            const lang = payload.lang as string | null;
            const size = payload.size as string;

            return getters.getNormalContentBlockBanner(cb_type).banner_images.filter((bannerImage: CmsBannerImageRestDto) =>
                bannerImage.language_code === lang
                && bannerImage.content_size_code === size
            )[0];
        },
        getEmptyBannerImages(state: any): CmsBannerImageRestDto[] {
            return JSON.parse(JSON.stringify(state.empty_banner_images));
        },
        getBannersInSlotsForSlot(state: any): ContentOverviewRestDto[] {
            return state.banners_in_slots_for_slot;
        },
        getNormalContentBlockBanner: (state: any) => (type: string): BannerContentV2RestDto => {
            // todo map gebruiken
            if (type === 'BANNER') {
                return state.cb_banner;
            } else if (type === 'LIB_BANNER') {
                return state.cb_lib_banner;
            } else {
                throw new Error('Unsupported NormalContentBlockBanner to get ' + type);
            }
        },
        getNormalContentBlockBannerSlot: (state: any) => (type: string): BannerSlotContentRestDto => {
            // todo map gebruiken
            if (type === 'BANNER_SLOT') {
                return state.cb_banner_slot;
            } else if (type === 'LIB_BANNER_SLOT') {
                return state.cb_lib_banner_slot;
            } else {
                throw new Error('Unsupported NormalContentBlockBannerSlot to get ' + type);
            }
        },
    }
}
