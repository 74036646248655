import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  type: "submit",
  class: "btn btn-primary mt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_AlertError2 = _resolveComponent("AlertError2")!
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_InputCodeEditor = _resolveComponent("InputCodeEditor")!
  const _component_dp_form = _resolveComponent("dp-form")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_BaseTitle, {
        title: this.title,
        "show-refresh-button": "",
        onRefresh: this.reloadContent
      }, null, 8, ["title", "onRefresh"]),
      _createVNode(_component_AlertError2, {
        error: this.simpleTextCbUI.getError()
      }, null, 8, ["error"]),
      _createVNode(_component_BaseSpinner, {
        spin: this.simpleTextCbUI.isNotReady()
      }, null, 8, ["spin"]),
      (this.simpleTextCbUI.isReady())
        ? (_openBlock(), _createBlock(_component_dp_form, {
            key: 0,
            onSubmit: this.submitData,
            as: "form",
            novalidate: ""
          }, {
            default: _withCtx(({ errors }) => [
              _createVNode(_component_InputCodeEditor, {
                modelValue: this.simpleTextCb.content.nl,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.simpleTextCb.content.nl) = $event)),
                languages: this.codeEditorLanguages,
                height: "80vh",
                errors: errors
              }, null, 8, ["modelValue", "languages", "errors"]),
              _createElementVNode("button", _hoisted_1, _toDisplayString(_ctx.$t('save')), 1)
            ]),
            _: 1
          }, 8, ["onSubmit"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}