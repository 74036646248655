import {
    LibraryOverviewRestDto
} from "@/api/pharma-cms-content-block/models";
import {CodeDescriptionRestDtoModel} from "@/models/CodeDescriptionRestDtoModel";

export class LibraryOverviewRestDtoModel implements LibraryOverviewRestDto {
    library?: CodeDescriptionRestDtoModel | null;
    content_block_type?: CodeDescriptionRestDtoModel | null;
    content_provider?: CodeDescriptionRestDtoModel | null;

    constructor(library?: CodeDescriptionRestDtoModel | null, content_block_type?: CodeDescriptionRestDtoModel | null, content_provider?: CodeDescriptionRestDtoModel | null) {
        this.library = library ?? undefined;
        this.content_block_type = content_block_type ?? undefined;
        this.content_provider = content_provider ?? undefined;
    }

    public static createWithDefaults(): LibraryOverviewRestDtoModel {
        return new this(CodeDescriptionRestDtoModel.createWithDefaults(), null, null);
    }
}
