
import {defineComponent} from "vue";
import {generateDomId} from "@/helpers/functions/string";
import {Offcanvas} from "bootstrap";

export default defineComponent({
  name: 'BaseTitle',
  emits: ['refresh'],
  props: {
    title: {
      type: String,
      required: true,
    },
    backRoute: {
      type: Object,
      required: false,
      default: null,
    },
    isLibrary: {
      type: Boolean,
      required: false,
      default: false,
    },
    mb: {
      type: Number,
      required: false,
      default: 4,
    },
    mode: {
      type: String,
      required: false
    },
    col: {
      type: Number,
      required: false,
      default: 12,
    },
    helpText: {
      type: String,
      required: false,
      default: null,
    },
    showRefreshButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    useRouterBack: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      helpOffcanvas: null as Offcanvas | null,
      helpOffcanvasId: generateDomId('offcanvas-'),
    };
  },
  mounted() {
    if (this.helpText) {
      this.helpOffcanvas = Offcanvas.getOrCreateInstance('#' + this.helpOffcanvasId);
    }
  },
  methods: {
    async goBack(): Promise<void> {
      if (this.backRoute) {
        await this.$router.push(this.backRoute);
      }
      if (this.useRouterBack) {
        await this.$router.back();
      }
    },
    openHelpOffcanvas(): void {
      this.helpOffcanvas?.show();
    }
  }
});
