import {CodeDescriptionRestDto, ProductInfoRestDto} from "@/api/pharma-cpc-mgmt/models";

export class ProductInfoRestDtoModel implements ProductInfoRestDto {
    name: string;
    brand?: CodeDescriptionRestDto | null;
    cnk_code?: string | null;
    description?: string | null;
    is_medical_aid?: boolean | null;
    is_medicine?: boolean | null;
    is_prescription_required?: boolean | null;
    labo?: CodeDescriptionRestDto | null;
    trademark?: CodeDescriptionRestDto | null;
    usage?: CodeDescriptionRestDto | null;


    constructor() {
        this.name = '';
    }

    public static createWithDefaults(): ProductInfoRestDtoModel {
        return new this();
    }
}
