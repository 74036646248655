
import store from "@/store";
import {Exception} from "@/api/interfaces";
import {defineComponent} from "vue";
import {ClosingPeriodRestDto} from "@/api/pharma-cms-pharmacy/models";
import {mapGetters} from "vuex";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";

export default defineComponent({
  name: "ClosingPeriodsOverview",
  components: {BaseTitle},
  data: () => ({
    isReady: false as boolean,
    pharmacyCode: '' as string,
    closingPeriods: [] as ClosingPeriodRestDto[],
    error: null,
    exceptions: [] as Exception[],
  }),
  mounted() {
    this.searchClosingPeriods().then(() => {
      this.isReady = true;
    });
  },
  computed: {
    ...mapGetters('cms_pharmacy', ['getClosingPeriods']),
    ...mapState(useCustomerStore, ["getPharmacyCode"]),
  },
  methods: {
    async searchClosingPeriods() {
      try {
        this.pharmacyCode = this.getPharmacyCode;

        await store.dispatch('cms_pharmacy/searchClosingPeriods', this.pharmacyCode);
        this.closingPeriods = this.getClosingPeriods;
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async deleteClosingPeriod(id: string) {
      try {
        const answer = window.confirm(this.$t('deleteConfirmation'));
        if (answer) {
          const payload = {
            id: id,
            pc: this.pharmacyCode
          };
          await store.dispatch('cms_pharmacy/deleteClosingPeriod', payload).then(() => {
            this.searchClosingPeriods();
          });
        }
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    formatDateString(date: string) {
      let locale = this.$i18n.locale;
      switch (locale) {
        case 'nl':
          locale = 'fr';
          break;
      }

      return new Intl.DateTimeFormat(locale).format(new Date(date));
    }
  }
});
