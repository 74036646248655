
import {defineComponent} from "vue";
import {Exception} from "@/api/interfaces";
import {mapActions, mapGetters} from "vuex";
import store from "@/store";
import {StarterBrandOverviewRestDto} from "@/api/pharma-cpc-mgmt/models";
import {CodeDescriptionRestDto} from "@/api/pharma-cpc-product/models";
import {DpException} from "@/exception/DpException";
import {mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";

export default defineComponent({
  name: "StarterProductSelection",
  components: {BaseSpinner, BaseTitle},
  data: () => ({
    isReady: false as Boolean,
    error: null,
    exceptions: [] as Exception[],
    savedSuccessfully: false as Boolean,
    filteredProductBrands: [] as CodeDescriptionRestDto[],
    starterBrandOverview: {} as StarterBrandOverviewRestDto,
    starterBrandCodes: [] as Array<string>,
    brandsToAdd: [] as Array<string>,
    brandsToRemove: [] as Array<string>,
    searchQuery: '',
  }),
  computed: {
    ...mapGetters('cpc_mgmt', ['getStarterBrandOverview']),
    ...mapGetters('cpc_product', ['getProductBrands']),
    ...mapState(useCustomerStore, ["getPharmacyCode"]),
  },
  mounted() {
    this.reloadContent();
  },
  methods: {
    ...mapActions('cpc_product', ['searchProductBrands']),
    addToBrandsToAdd(brand_code: string) {
      if (!this.brandsToAdd.includes(brand_code)) {
        this.brandsToAdd.push(brand_code);
      } else {
        this.brandsToAdd = this.brandsToAdd.filter((brand: any) => brand !== brand_code);
      }
    },
    addToBrandsToRemove(brand_code: string) {
      if (!this.brandsToRemove.includes(brand_code)) {
        this.brandsToRemove.push(brand_code);
      } else {
        this.brandsToRemove = this.brandsToRemove.filter((brand: any) => brand !== brand_code);
      }
    },
    clearSearchQuery() {
      this.searchQuery = '';
      this.filterProductBrands();
    },
    filterProductBrands() {
      this.filteredProductBrands = this.getProductBrands.filter((brand: any) => !this.starterBrandCodes.includes(brand.code));

      if (this.searchQuery.length > 0) {
        this.filteredProductBrands = this.filteredProductBrands.filter((productBrand: any) => {
          return productBrand.description.toLowerCase().indexOf(this.searchQuery.toLowerCase()) !== -1;
        });
      }
    },
    fillStarterBrandCodes() {
      this.starterBrandCodes = [];
      for (const [, value] of Object.entries(this.starterBrandOverview)) {
        this.starterBrandCodes.push(value.code);
      }
    },
    async reloadContent() {
      this.isReady = false;

      try {
        await Promise.all([
          this.searchStarterBrandOverview(),
          this.searchProductBrandsOverview(),
        ]);

        this.starterBrandOverview = this.getStarterBrandOverview;
        this.fillStarterBrandCodes();
        this.filterProductBrands();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      this.isReady = true;
    },
    async addToStarterBrands(brand_code?: string) {
      try {
        if (typeof brand_code === 'string') {
          await store.dispatch('cpc_mgmt/addStarterBrand', brand_code);
          this.brandsToAdd = this.brandsToAdd.filter(() => !this.brandsToAdd.includes(brand_code));

          await this.reloadContent();
        } else if (this.brandsToAdd.length > 0) {
          for (const brand of this.brandsToAdd) {
            await store.dispatch('cpc_mgmt/addStarterBrand', brand);
          }
          this.brandsToAdd = [];

          await this.reloadContent();
        } else if (this.brandsToAdd.length === 0) {
          //TODO: ASK proper vue error handling
          this.exceptions = [new DpException(this.$t('exceptionNoAddBrandsSelected'))];
        }
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async removeFromStarterBrands(brand_code?: string) {
      try {
        if (typeof brand_code === 'string') {
          await store.dispatch('cpc_mgmt/deleteStarterBrand', brand_code);
          this.brandsToRemove = this.brandsToRemove.filter(() => !this.brandsToRemove.includes(brand_code));

          await this.reloadContent();
        } else if (this.brandsToRemove.length > 0) {
          for (const brand of this.brandsToRemove) {
            await store.dispatch('cpc_mgmt/deleteStarterBrand', brand);
          }
          this.brandsToRemove = [];

          await this.reloadContent();
        } else if (this.brandsToRemove.length === 0) {
          //TODO: ASK proper vue error handling
          this.exceptions = [new DpException(this.$t('exceptionNoRemoveBrandsSelected'))];
        }
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async searchProductBrandsOverview() {
      try {
        await this.searchProductBrands({
          language: this.$i18n.locale
        });
      } catch (exception: any) {
        this.exceptions = exception;
      }
    },
    async searchStarterBrandOverview() {
      try {
        await store.dispatch('cpc_mgmt/searchStarterBrandOverview');
      } catch (exception: any) {
        this.exceptions = exception;
      }
    },
  },
})
