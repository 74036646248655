import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CbsOverview = _resolveComponent("CbsOverview")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, { "container-fluid": "" }, {
    default: _withCtx(() => [
      _createVNode(_component_CbsOverview, {
        "content-blocks": this.contentBlocks,
        "content-type": this.contentType,
        "cb-type": this.cbType,
        "src-lib-code": this.libCode,
        "can-use-library": _ctx.AbilityContext.isAuthorizedForFeature('MGMT_CUSTOMER_LIB_CONTENT'),
        "is-ready": this.isReady,
        "has-content-blocks": this.hasContentBlocks,
        description: _ctx.$t('descriptionLibraries'),
        title: this.title,
        "route-object-back": {name: 'libraries-available', params: {cb_structure: this.cbStructure, cb_type: this.cbType}},
        "route-object-edit-cb": {name: 'library-edit', params: {cb_structure: this.cbStructure, cb_type: this.cbType, lib_type: this.libCode}},
        "route-object-new-cb": {name: 'library-new', params: {cb_structure: this.cbStructure, cb_type: this.cbType, lib_type: this.libCode}},
        "table-header-items": this.tableHeadingItems,
        onDeleteContentBlock: this.deleteContentBlock,
        onCopiedLibCb: this.reloadContent,
        onRefresh: this.reloadContent
      }, null, 8, ["content-blocks", "content-type", "cb-type", "src-lib-code", "can-use-library", "is-ready", "has-content-blocks", "description", "title", "route-object-back", "route-object-edit-cb", "route-object-new-cb", "table-header-items", "onDeleteContentBlock", "onCopiedLibCb", "onRefresh"])
    ]),
    _: 1
  }))
}