/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Pharmacy API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface TeamMemberOverviewRestDto
 */
export interface TeamMemberOverviewRestDto {
    /**
     * ID of the team member
     * @type {string}
     * @memberof TeamMemberOverviewRestDto
     */
    id?: any;
    /**
     * First name.
     * @type {string}
     * @memberof TeamMemberOverviewRestDto
     */
    first_name?: any;
    /**
     * Last name.
     * @type {string}
     * @memberof TeamMemberOverviewRestDto
     */
    last_name?: any;
    /**
     * Function title of the team member.
     * @type {string}
     * @memberof TeamMemberOverviewRestDto
     */
    function_title?: any;
    /**
     * Abbreviated content in the default language of the customer.
     * @type {string}
     * @memberof TeamMemberOverviewRestDto
     */
    short_content?: any;
    /**
     * Image key in the default language of the customer.
     * @type {string}
     * @memberof TeamMemberOverviewRestDto
     */
    image_key?: any;
    /**
     * The sorting value of this content block.
     * @type {number}
     * @memberof TeamMemberOverviewRestDto
     */
    sort_weight?: any;
    /**
     * The status of this content block.
     * @type {string}
     * @memberof TeamMemberOverviewRestDto
     */
    status_code: TeamMemberOverviewRestDtoStatusCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TeamMemberOverviewRestDtoStatusCodeEnum {
    PUBLISHED = 'PUBLISHED',
    NOTPUBLISHED = 'NOT_PUBLISHED'
}

