import {RestService} from "@/services/rest/RestService";
import {FooterEndpointsApi} from "@/api/pharma-cms-footer";
import {
    CmsFooterContent1RestDto,
    CmsFooterContent2RestDto,
    FooterOverviewRestDto
} from "@/api/pharma-cms-footer/models";

export class FooterRestService extends RestService {

    private apiService: FooterEndpointsApi;

    protected constructor() {
        super();

        this.apiService = new FooterEndpointsApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cmsUrl);
    }

    public async findFooterStackOverview(): Promise<FooterOverviewRestDto[]> {
        const response = await this.apiService.getFooterStackOverview();

        return (response.data.results) ?? [];
    }

    public async findFooterContent1(id: string): Promise<CmsFooterContent1RestDto> {
        const response = await this.apiService.getFooterContent1(id);

        return response.data;
    }

    public async updateFooterContent1(id: string, body: CmsFooterContent1RestDto): Promise<void> {
        const response = await this.apiService.updateFooterContent1(body, id);

        return response.data;
    }

    public async findFooterContent2(id: string): Promise<CmsFooterContent2RestDto> {
        const response = await this.apiService.getFooterContent2(id);

        return response.data;
    }

    public async updateFooterContent2(id: string, body: CmsFooterContent2RestDto): Promise<void> {
        const response = await this.apiService.updateFooterContent2(body, id);

        return response.data;
    }
}
