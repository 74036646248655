/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum BackgroundImageRepeatEnum {
    NO_REPEAT = 'NO_REPEAT',
    REPEAT = 'REPEAT',
    REPEAT_X = 'REPEAT_X',
    REPEAT_Y = 'REPEAT_Y',
    SPACE = 'SPACE',
    ROUND = 'ROUND',
}