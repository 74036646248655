import {FindNormalContentBlockPayload} from "@/models/payload/FindNormalContentBlockPayload";
import {ContentBlockEndpointsApiService} from "@/main";
import {SaveNormalContentBlockPayload} from "@/models/payload/SaveNormalContentBlockPayload";
import {CreateNormalContentBlockPayload} from "@/models/payload/CreateNormalContentBlockPayload";
import {TextWithImageContentRestDtoModel} from "@/models/api/pharma-cms-content-block/TextWithImageContentRestDtoModel";

export default {
    namespaced: true,
    actions: {
        async create(context: any, payload: CreateNormalContentBlockPayload): Promise<string> {
            const response = await ContentBlockEndpointsApiService.createNormalContentBlockTextWithImage(payload.cb as TextWithImageContentRestDtoModel, payload.type);

            return response.data.id as string;
        },
        async find(context: any, payload: FindNormalContentBlockPayload): Promise<TextWithImageContentRestDtoModel> {
            const response = await ContentBlockEndpointsApiService.getNormalContentBlockTextWithImage(payload.type, payload.id);

            return response.data as TextWithImageContentRestDtoModel;
        },
        async save(context: any, payload: SaveNormalContentBlockPayload): Promise<void> {
            await ContentBlockEndpointsApiService.updateNormalContentBlockTextWithImage(payload.cb as TextWithImageContentRestDtoModel, payload.type, payload.id);
        },
    }
};
