import {ColorSchemeRestDtoModel} from "@/models/api/pharma-cms-color-scheme/ColorSchemeRestDtoModel";
import {CodeDescriptionRestDtoModel} from "@/models/CodeDescriptionRestDtoModel";

export class ColorSchemeClipboardModel {
    colorScheme: ColorSchemeRestDtoModel | null;
    activeColorScheme: CodeDescriptionRestDtoModel | null;

    constructor(colorScheme: ColorSchemeRestDtoModel | null, activeColorScheme: CodeDescriptionRestDtoModel | null) {
        this.colorScheme = colorScheme;
        this.activeColorScheme = activeColorScheme;
    }

    public static createWithDefaults(): ColorSchemeClipboardModel {
        return new this(null, null);
    }
}
