import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1691a660"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "img-container position-relative" }
const _hoisted_2 = { class: "img-overlay position-absolute top-0 bottom-0 start-0 end-0 d-flex justify-content-between align-items-end p-2" }
const _hoisted_3 = { class: "card-title fs-6 mb-3" }
const _hoisted_4 = { class: "card-text fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_card_image = _resolveComponent("base-card-image")!
  const _component_ItemIcons = _resolveComponent("ItemIcons")!
  const _component_base_card_body = _resolveComponent("base-card-body")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_base_card, {
    class: "tiles-item text-bg-light border-light cu-p",
    onClick: _withModifiers(this.openOffCanvasProductDetail, ["prevent"])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (this.product.product_info.main_image_url)
          ? (_openBlock(), _createBlock(_component_base_card_image, {
              key: 0,
              src: this.product.product_info.main_image_url,
              alt: this.product.product_info.name
            }, null, 8, ["src", "alt"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ItemIcons, {
            product: this.product
          }, null, 8, ["product"])
        ])
      ]),
      _createVNode(_component_base_card_body, { class: "p-2" }, {
        default: _withCtx(() => [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(this.product.product_info.name), 1),
          _createElementVNode("p", _hoisted_4, "CNK: " + _toDisplayString(this.product.product_info.cnk_code), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClick"]))
}