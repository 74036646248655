import {RestService} from "@/services/rest/RestService";
import {PharmacistUserProfileEndpointsApi} from "@/api/pharma-auth-pharmacist-user";
import {PasswordRestDtoModel} from "@/models/api/pharma-auth-pharmacist-user/PasswordRestDtoModel";
import {AuthContext} from "@/context/AuthContext";

export class ProfileRestService extends RestService {

    private apiSerive: PharmacistUserProfileEndpointsApi;

    constructor() {
        super();

        this.apiSerive = new PharmacistUserProfileEndpointsApi(this.getDpAxiosInstance(), undefined, this.getApiGwUrl('pharma-auth'));
    }

    public async updatePharmacistUserPassword(passwordRestDto: PasswordRestDtoModel, pharmacistUserId?: string): Promise<void> {
        if (!pharmacistUserId) {
            pharmacistUserId = AuthContext.getUsername();
        }

        await this.apiSerive.updatePharmacistUserPassword(passwordRestDto, pharmacistUserId);
    }
}
