
import {defineComponent} from "vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {PasswordRestDtoModel} from "@/models/api/pharma-auth-pharmacist-user/PasswordRestDtoModel";
import AlertError from "@/components/UI/Bootstrap/Alert/AlertError.vue";
import InputPassword from "@/components/UI/InputPassword.vue";
import {defineRule} from "vee-validate";
import {Exception} from "@/api/interfaces";
import {ProfileRestService} from "@/services/rest/auth-pharmacist-user/ProfileRestService";
import I18n from "@/modules/i18n";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";

defineRule("pwd", (value: any) => {
  if (!/[a-z]/.test(value)) {
    return I18n.global.t('Password should contain at least 1 lowercase letter.');
  }

  if (!/[A-Z]/.test(value)) {
    return I18n.global.t('Password should contain at least 1 uppercase letter.');
  }

  if (!/[0-9]/.test(value)) {
    return I18n.global.t('Password should contain at least 1 number.');
  }

  if (/^[a-zA-Z0-9]+$/.test(value)) {
    return I18n.global.t('Password should contain at least 1 special character.');
  }

  return true;
});

export default defineComponent({
  name: "Password",
  components: {InputPassword, AlertError, BaseTitle},
  data: () => ({
    isReady: false as Boolean,
    exceptions: [] as Exception[],

    passwordModel: PasswordRestDtoModel.createWithDefaults()
  }),
  mounted() {
    this.isReady = true;
  },
  methods: {
    async submitData(values: any, {resetForm}: any): Promise<void> {
      try {
        await ProfileRestService.getInstance().updatePharmacistUserPassword(this.passwordModel);
        resetForm(PasswordRestDtoModel.createWithDefaults());
        await handleSavedSuccessfully();
      } catch (exceptions: unknown) {
        console.log(exceptions);
        this.exceptions = exceptions as Exception[];
      }
    }
  }
});
