
import {defineComponent} from "vue";
import LightboxJS from "@/components/UI/LightboxJS.vue";
import {PropType} from "vue/dist/vue";
import {FsLightboxTypeEnum} from "@/models/enum/FsLightboxTypeEnum";

export default defineComponent({
  name: "Lightbox",
  emits: ["onOpen", "onClose", "onInit", "onShow"],
  components: {LightboxJS},
  props: {
    sources: {
      type: Array as PropType<string[]>,
      required: true,
    },
    class: {
      type: String,
      required: false,
    },
    customAttributes: {
      type: Array as PropType<object[]>,
      required: false,
    },
    type: {
      type: String as PropType<FsLightboxTypeEnum>,
      required: false
    },
  },
});
// Custom attributes example
// [
//   {
//     alt: 'A first example description.'
//     foo: 'bar'
//   },
//   {
//     alt: 'A second example description.'
//   }
// ]
