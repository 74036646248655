import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", null, [
      _createElementVNode("div", {
        class: "nav nav-tabs",
        id: _ctx.tabId,
        role: "tablist"
      }, [
        _renderSlot(_ctx.$slots, "tabs")
      ], 8, _hoisted_1)
    ]),
    _createElementVNode("div", {
      class: "tab-content",
      id: _ctx.tabId + 'Content'
    }, [
      _renderSlot(_ctx.$slots, "content")
    ], 8, _hoisted_2)
  ], 64))
}