
import {defineComponent} from "vue";
import I18n from "@/modules/i18n";

export default defineComponent({
  name: 'ConfirmModal',
  emits: ['confirm', 'cancel'],
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    body: {
      type: String,
      required: true,
    },
    cancelButtonText: {
      type: String,
      required: false,
      default() {
        return I18n.global.t('cancel');
      }
    },
    cancelButtonClass: {
      type: String,
      required: false,
      default: 'btn-secondary',
    },
    confirmButtonText: {
      type: String,
      required: false,
      default() {
        return I18n.global.t('confirm');
      }
    },
    confirmButtonClass: {
      type: String,
      required: false,
      default: 'btn-primary',
    }
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
    cancel() {
      this.$emit('cancel');
    }
  }
});
