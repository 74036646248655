import {
    ContentBlockMetadataRestDto,
    TextContentRestDto
} from "@/api/pharma-cms-content-block/models";
import {NormalContentBlockModel} from "@/models/api/pharma-cms-content-block/NormalContentBlockModel";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import {StatusEnum} from "@/api/enums/status-enum";

export class TextContentRestDtoModel extends NormalContentBlockModel implements TextContentRestDto {
    cb_metadata?: ContentBlockMetadataRestDto;
    content: MultilingualRestDtoModel;
    sort_weight?: number;
    status_code: StatusEnum;

    constructor(content: MultilingualRestDtoModel, status_code: StatusEnum) {
        super();
        this.content = content;
        this.status_code = status_code;
    }

    static createWithDefaults(): TextContentRestDtoModel {
        return new this(MultilingualRestDtoModel.createWithDefaults(), StatusEnum.PUBLISHED);
    }
}
