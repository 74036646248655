import {DocumentAPIApiService} from "@/main";
import {UrlResponseRestDto} from "@/api/pharma-cms-document/models";

export default {
    namespaced: true,
    state() {
        return {
            document_keys: {} as Object,
            preview_documents: {} as Object,
        };
    },
    actions: {
        clearPreviewDocument(context: any, document_type: string): void {
            context.commit("setPreviewDocument", {
                document_type: document_type,
                document_key: null,
            });
        },
        clearUploadedDocumentKey(context: any, document_type: string): void {
            context.commit("setUploadedDocumentKey", {
                document_type: document_type,
                document_key: null,
            })
        },
        setUploadedDocumentKey(context: any, payload: any): void {
            const document_type = payload.document_type as string;
            const document_key = payload.document_key as string;

            context.commit("setUploadedDocumentKey", {
                document_type: document_type,
                document_key: document_key,
            });
        },
        async searchPreviewDocument(context: any, payload: any): Promise<void> {
            const document_type = payload.document_type as string;
            const document_key = payload.document_key as string;

            const response = await DocumentAPIApiService.documentV1DocumentDocumentKeyPublicUrlGet(document_key);

            context.commit("setPreviewDocument", {
                document_type: document_type,
                document_url: response.data,
            });
            context.commit("setUploadedDocumentKey", {
                document_type: document_type,
                document_key: document_key,
            });
        },
    },
    mutations: {
        setPreviewDocument(state: any, payload: any) {
            const document_type = payload.document_type as string;
            const document_url = payload.document_url as UrlResponseRestDto;

            state.preview_documents[document_type] = document_url as UrlResponseRestDto;
        },
        setUploadedDocumentKey(state: any, payload: any) {
            const document_type = payload.document_type as string;
            const document_key = payload.document_key as string;

            state.document_keys[document_type] = document_key as string;
        },
    },
    getters: {
        getPreviewDocument: (state: any) => (document_type: string): UrlResponseRestDto => {
            return state.preview_documents[document_type];
        },
        getUploadedDocumentKey: (state: any) => (document_type: string): string => {
            return state.document_keys[document_type];
        },
    },
};
