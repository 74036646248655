
import {defineComponent} from "vue";
import {PageElementMetadataRestDtoModel} from "@/models/PageElementMetadataRestDtoModel";
import {WysiwygCustomDesignTemplateRestDtoModel} from "@/models/WysiwygCustomDesignTemplateRestDtoModel";
import {AuthContext} from "@/context/AuthContext";

export default defineComponent({
  name: "MetadataIcons",
  props: {
    customDesignTemplate: [WysiwygCustomDesignTemplateRestDtoModel, Object],
    pageElementMetadata: [PageElementMetadataRestDtoModel, Object],
    iconClass: String
  },
  computed: {
    class(): string {
      return this.iconClass ?? 'page-element-metadata-warning';
    },
    lockedText(): string | null {
      if (this.isLocked) {
        let textArray = [] as string[];

        if (this?.pageElementMetadata?.is_copy_locked) {
          textArray.push('Copy locked');
        }
        if (this?.pageElementMetadata?.is_delete_locked) {
          textArray.push('Delete locked');
        }
        if (this?.pageElementMetadata?.is_write_locked) {
          textArray.push('Write locked');
        }

        let returnText = '' as string;
        for (let i = 0; i < textArray.length; i++) {
          returnText += textArray[i];

          if (i + 1 !== textArray.length) {
            returnText += '<br>';
          }
        }

        return returnText;
      }

      return null;
    },
    isDpUser(): boolean {
      return AuthContext.isDpUser();
    },
    isLocked(): boolean {
      return this?.pageElementMetadata?.is_copy_locked || this?.pageElementMetadata?.is_delete_locked || this?.pageElementMetadata?.is_write_locked;
    }
  }
})
