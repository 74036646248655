
import {CustomerOverviewRestDto} from "@/api/pharma-customer/models";
import {Exception} from "@/api/interfaces";
import {defineComponent, inject} from "vue";
import {ABILITY_TOKEN} from "@casl/vue";
import updateAbilities from '@/config/UpdateAbility';
import {mapActions, mapGetters} from "vuex";
import ability from "@/config/ability";
import BaseContainer from "@/components/UI/Bootstrap/BaseContainer.vue";
import {AuthContext} from "@/context/AuthContext";
import {mapActions as mapActionsP, mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import {CustomerCriteriaRestDtoModel} from "@/models/api/pharma-customer/CustomerCriteriaRestDtoModel";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import InputText from "@/components/UI/InputText.vue";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import {createCodeDescriptionArrayFromEnum} from "@/helpers/functions/enum";
import {CustomerTypeEnum} from "@/api/enums/customer-type-enum";
import InputSelect from "@/components/UI/InputSelect.vue";

export default defineComponent({
  name: "AllPharmacies",
  components: {InputSelect, InputText, BaseSpinner, BaseTitle, BaseContainer},
  setup() {
    // todo kris refactor naar vue store
    const ability = inject(ABILITY_TOKEN);
    return {
      ability
    }
  },
  data: () => ({
    error: null,
    exceptions: [] as Exception[],
    isReady: false as boolean,
    customer: {} as CustomerOverviewRestDto,
  }),
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters(['getCurrentCustomerCode']),
    ...mapState(useCustomerStore, [
      "getCriteria",
      "getCustomersWithCriteria",
      "getPharmacies"
    ]),
    customerCriteria(): CustomerCriteriaRestDtoModel {
      return this.getCriteria.customer;
    },
    customerOverview(): CustomerOverviewRestDto[] {
      return this.getCustomersWithCriteria;
    },
    isDpUser(): boolean {
      return AuthContext.isDpUser(true);
    },
    isMultiPharmacy(): boolean {
      return ability.can('use-feature', 'MULTI_PHARMACY');
    },
    optionsCustomerTypeEnum(): CodeDescriptionRestDto[] {
      return createCodeDescriptionArrayFromEnum(CustomerTypeEnum);
    }
  },
  methods: {
    ...mapActions(["changeCustomer"]),
    ...mapActions("customer_settings", ["searchCustomerMainDomain", "searchCustomerMainLicense"]),
    ...mapActionsP(useCustomerStore, [
      "searchCustomersWithCriteria",
      "clearCustomerCriteria",
      "searchPharmacies"
    ]),
    async reloadContent() {
      this.isReady = false;
      this.exceptions = [];

      try {
        await this.searchCustomersWithCriteria();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      this.isReady = true;
    },
    async switchCustomer(customer_code: string) {
      this.exceptions = [];

      try {
        await this.$router.push({name: 'home'});

        await this.changeCustomer(customer_code);

        await Promise.all([
          this.searchCustomerMainDomain(customer_code),
          this.searchCustomerMainLicense(customer_code),
          this.searchPharmacies(),
          updateAbilities(this.ability, customer_code)
        ]);
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async showAllCustomers() {
      this.clearCustomerCriteria();

      await this.reloadContent();
    },
    async showDemoCustomers() {
      this.customerCriteria.search_name = 'demo';

      await this.reloadContent();
    },
  }
});
