/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum BackgroundImageAttachmentEnum {
    SCROLL = 'SCROLL',
    FIXED = 'FIXED',
    LOCAL = 'LOCAL',
}