import {Context} from "@/patterns/Context";

export class CustomerContext extends Context {
    private static _customerCode: string;
    private static _mainDomain: string;
    private static _pharmacyCode: string;

    static getCustomerCode(): string {
        return this._customerCode;
    }

    static isDpContentProviderCustomerCode(): boolean {
        return CustomerContext.getCustomerCode() === "DP_00000_CP";
    }

    static setCustomerCode(value: string): void {
        this._customerCode = value;
    }

    static getMainDomain(): string {
        return this._mainDomain;
    }

    static setMainDomain(value: string): void {
        this._mainDomain = value;
    }

    static hasMainDomain(): boolean {
        return !!this._mainDomain;
    }

    static getPharmacyCode(): string {
        return this._pharmacyCode;
    }

    static setPharmacyCode(value: string): void {
        this._pharmacyCode = value;
    }
}
