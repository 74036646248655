import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cb_form_text_with_image = _resolveComponent("cb-form-text-with-image")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_cb_form_text_with_image, {
        "cb-id": _ctx.cbId,
        "cb-type": "SMA_HOMEPAGE_TEXT_WITH_IMAGE",
        "doc-type": "CB_TEXT_WITH_IMAGE_IMG",
        "is-normal-content-block": "",
        "is-publishable": "",
        mode: _ctx.mode,
        "return-route": {name: 'standard-homepage-content-blocks'},
        title: _ctx.mode==='new' ? _ctx.$t('newCb') : _ctx.$t('editCb'),
        description: _ctx.$t('descriptionHomepageCbDetail'),
        description2: _ctx.$t('publishedText'),
        description3: _ctx.$can('use-license','DP_WEBSITE_STARTER')? _ctx.$t('publishedBlockText') : undefined,
        description4: _ctx.$t('sortWeightDescription')
      }, null, 8, ["cb-id", "mode", "title", "description", "description2", "description3", "description4"])
    ]),
    _: 1
  }))
}