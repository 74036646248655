import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mb-3" }
const _hoisted_2 = { class: "col-auto" }
const _hoisted_3 = {
  class: "btn btn-primary",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertError2 = _resolveComponent("AlertError2")!
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_Subtitle = _resolveComponent("Subtitle")!
  const _component_InputCheckboxBoolean = _resolveComponent("InputCheckboxBoolean")!
  const _component_dp_form = _resolveComponent("dp-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AlertError2, {
      error: this.headerFooterContentUI.getError()
    }, null, 8, ["error"]),
    _createVNode(_component_BaseSpinner, {
      spin: this.headerFooterContentUI.isNotReady()
    }, null, 8, ["spin"]),
    (this.headerFooterContentUI.isReady())
      ? (_openBlock(), _createBlock(_component_dp_form, {
          key: 0,
          onSubmit: this.submitData,
          novalidate: ""
        }, {
          default: _withCtx(({errors}) => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_Subtitle, {
                  title: _ctx.$t('settings'),
                  mb: "3"
                }, null, 8, ["title"]),
                _createVNode(_component_InputCheckboxBoolean, {
                  modelValue: this.headerContent.is_show_product_search_field,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.headerContent.is_show_product_search_field) = $event)),
                  name: "isShowProductSearchField",
                  label: `${_ctx.$t('show')} ${_ctx.$t('productsSearchField').toLowerCase()}`,
                  errors: errors
                }, null, 8, ["modelValue", "label", "errors"]),
                _createVNode(_component_InputCheckboxBoolean, {
                  modelValue: this.headerContent.is_show_catalog_buttons,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.headerContent.is_show_catalog_buttons) = $event)),
                  name: "isShowCatalogButton",
                  label: `${_ctx.$t('show')} ${_ctx.$t('catalogButtons').toLowerCase()}`,
                  errors: errors
                }, null, 8, ["modelValue", "label", "errors"]),
                _createVNode(_component_InputCheckboxBoolean, {
                  modelValue: this.headerContent.is_sticky,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.headerContent.is_sticky) = $event)),
                  name: "isSticky",
                  label: _ctx.$t('sticksToTop'),
                  errors: errors
                }, null, 8, ["modelValue", "label", "errors"])
              ])
            ]),
            _createElementVNode("button", _hoisted_3, _toDisplayString(_ctx.$t('save')), 1)
          ]),
          _: 1
        }, 8, ["onSubmit"]))
      : _createCommentVNode("", true)
  ], 64))
}