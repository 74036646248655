import {BusinessService} from "@/services/business/BusinessService";
import {ProductFeatureRestService} from "@/services/rest/license-license-info/ProductFeatureRestService";
import {ProductFeatureAuthorizationRestDtoModel} from "@/models/ProductFeatureAuthorizationRestDtoModel";
import {AbilityContext} from "@/context/AbilityContext";
import {CacheService} from "@/services/cache/CacheService";
import {CacheKeyEnum} from "@/services/cache/CacheKeyEnum";
import {AuthContext} from "@/context/AuthContext";

export class AbilityContextService extends BusinessService {



    public async updateAbilityContext(): Promise<void> {


        let result: ProductFeatureAuthorizationRestDtoModel[] =
            CacheService.getInstance().get(CacheKeyEnum.PRODUCT_FEATURE_AUTHORIZATIONS);
        if (!result && AuthContext.oauth2Token) {
            result = await ProductFeatureRestService.getInstance().findProductFeatureAuthorization();
            CacheService.getInstance().set(CacheKeyEnum.PRODUCT_FEATURE_AUTHORIZATIONS, result);
        }

        if (result) {
            AbilityContext.setProductFeatureAuthorizations(result);
        }
    }
}
