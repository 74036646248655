
import {defineComponent, ref} from "vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import {ContentVisibilityRuleRestDtoModel} from "@/models/api/pharma-cms-menu/ContentVisibilityRuleRestDtoModel";
import InputDate from "@/components/UI/InputDate.vue";
import {ProductFilterOverviewRestDto} from "@/api/pharma-cpc-mgmt/models";
import {mapActions, mapGetters} from "vuex";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import {UIStateDto} from "@/dtos/UIStateDto";
import {AbilityContext} from "@/context/AbilityContext";
import {arrayHasContent} from "@/helpers/functions/arrays";
import {VisibilityRuleTypeEnum} from "@/api/enums/visibility-rule-type-enum";

export default defineComponent({
  name: "VisibilityRule",
  emits: ["changed", "delete", "error"],
  components: {InputDate, InputSelect},
  props: {
    payload: {
      type: [ContentVisibilityRuleRestDtoModel, Object],
      required: true
    }
  },
  setup(props) {
    const visibilityRule = ref(JSON.parse(JSON.stringify(props.payload))) as ContentVisibilityRuleRestDtoModel;

    return {
      visibilityRule,
    };
  },
  data() {
    return {
      visibilityRuleUI: UIStateDto.createWithDefaults()
    }
  },
  watch: {
    payload: {
      handler(newPayload: ContentVisibilityRuleRestDtoModel) {
        // console.log('payload changed: ', newPayload);
        this.visibilityRule = ref(JSON.parse(JSON.stringify(newPayload)));
      },
      deep: true,
      immediate: false
    },
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cms_enum', ['getEnumByClassname']),
    ...mapGetters('cpc_mgmt', ["getProductFilterOverview"]),
    VisibilityRuleTypeEnum() {
      return VisibilityRuleTypeEnum
    },
    namedProductFilterOptions(): CodeDescriptionRestDto[] {
      const namedProductFilterOptions = [] as CodeDescriptionRestDto[];

      if (AbilityContext.isAuthorizedForFeature('USE_NAMED_PRODUCT_FILTER')) {
        this.getProductFilterOverview.forEach((product_filter: ProductFilterOverviewRestDto) => {
          namedProductFilterOptions.push(
            {code: product_filter.code, description: product_filter.name}
          );
        });
      }

      return namedProductFilterOptions;
    },
    visibilityRuleOptions(): CodeDescriptionRestDto[] {
      const visibilityRuleOptions = this.getEnumByClassname(EnumClassNameCmsEnum.CONTENT_VISIBILITY_RULE) as CodeDescriptionRestDto[];

      if (AbilityContext.isNotAuthorizedForFeature('USE_NAMED_PRODUCT_FILTER') || !arrayHasContent(this.namedProductFilterOptions)) {
        return visibilityRuleOptions.filter((option: CodeDescriptionRestDto) => !option.code.includes('PRODUCT_FILTER'));
      }

      return visibilityRuleOptions;
    },
  },
  methods: {
    ...mapActions('cms_enum', ["findEnumByClassname"]),
    ...mapActions('cpc_mgmt', ["searchProductFilterOverview"]),
    async reloadContent(): Promise<void> {
      this.visibilityRuleUI.setNotReady();

      try {
        await Promise.all([
          this.findEnumByClassname(EnumClassNameCmsEnum.CONTENT_VISIBILITY_RULE),
          AbilityContext.isAuthorizedForFeature('USE_NAMED_PRODUCT_FILTER')
            ? this.searchProductFilterOverview({is_named: true})
            : null,
        ]);
      } catch (exceptions: unknown) {
        this.$emit('error', exceptions);
      } finally {
        this.visibilityRuleUI.setReady();
      }
    },
    deleteVisibilityRule() {
      this.$emit('delete');
    },
    onChange() {
      switch (this.visibilityRule.type_code) {
        case VisibilityRuleTypeEnum.HIDE:
          this.visibilityRule.begin_date = null;
          this.visibilityRule.end_date = null;
          this.visibilityRule.product_filter_code = null;
          break;
        case VisibilityRuleTypeEnum.VISIBLE_WITHIN_PERIOD:
          this.visibilityRule.product_filter_code = null;
          break;
        case VisibilityRuleTypeEnum.PRODUCT_FILTER_NO_PRODUCTS:
        case VisibilityRuleTypeEnum.PRODUCT_FILTER_WITH_PRODUCTS:
          this.visibilityRule.begin_date = null;
          this.visibilityRule.end_date = null;
          break;
      }

      this.$emit('changed', this.visibilityRule);
    },
  }
});
