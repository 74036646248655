
import {defineComponent} from "vue";
import CpAbstractsOverview from "@/components/layouts/content-pages/overview/CpAbstractsOverview.vue";

export default defineComponent({
  components: {CpAbstractsOverview},
  emits: ["copy", "exceptions"],
  props: {
    contentBlockLibsForType: {
      type: Array,
      required: true,
    },
    libCode: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    }
  },
  data: () => ({
    modalId: 'libContentPages' as string,
  }),
  methods: {
    onCopy(page_id: string) {
      this.$emit('copy', page_id);
    },
  }
});
