<template>
  <i class="mas mas-fill-1"
     :class="this.isActive ? 'text-success' : 'text-danger'"
  >circle</i>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
