
import {defineComponent} from "vue";
import AbstractHeaderFooterDetailContent
  from "@/components/layouts/header-footer/AbstractHeaderFooterDetailContent.vue";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import {CodeDescriptionRestDtoModel} from "@/models/CodeDescriptionRestDtoModel";
import {CmsHeaderContent1RestDtoModel} from "@/models/api/pharma-cms-header/CmsHeaderContent1RestDtoModel";
import {createCodeDescriptionArrayFromEnum} from "@/helpers/functions/enum";
import {HeaderContent1LayoutEnum} from "@/api/enums/header-content1-layout-enum";
import {CmsWebsiteReferenceRestDtoModel} from "@/models/api/pharma-cms-header/CmsWebsiteReferenceRestDtoModel";
import InputImage from "@/components/UI/InputImage.vue";
import I18nInputText from "@/components/UI/I18n/I18nInputText.vue";
import MultilingualSwitcher from "@/components/UI/MultilingualSwitcher.vue";
import Subtitle from "@/components/UI/Subtitle.vue";
import {CmsWebsiteReferenceRestDto} from "@/api/pharma-cms-header/models";

export default defineComponent({
  name: "HeaderDetailContent1",
  components: {
    Subtitle,
    MultilingualSwitcher,
    I18nInputText,
    InputImage, BaseSpinner, InputSelect, InputCheckboxBoolean, AlertError2
  },
  extends: AbstractHeaderFooterDetailContent,
  data: () => ({
    headerContent: CmsHeaderContent1RestDtoModel.createWithDefaults(),
    usePharmacyGroupWebsiteRef: false as boolean,
    storedGroupWebsiteRef: null as CmsWebsiteReferenceRestDto | null
  }),
  watch: {
    usePharmacyGroupWebsiteRef(newValue) {
      if (newValue === true) {
        if (!this.headerContent.pharmacy_group) {
          this.headerContent.pharmacy_group = {
            group_website_ref: (this.storedGroupWebsiteRef)
              ? this.storedGroupWebsiteRef
              : CmsWebsiteReferenceRestDtoModel.createWithDefaults()
          };
        }
      } else {
        this.storedGroupWebsiteRef = this.headerContent.pharmacy_group?.group_website_ref ?? null;
        this.headerContent.pharmacy_group = null;
      }
    }
  },
  computed: {
    layoutOptions(): CodeDescriptionRestDtoModel[] {
      return createCodeDescriptionArrayFromEnum(HeaderContent1LayoutEnum);
    }
  },
  methods: {
    afterReloadContent(): void {
      this.usePharmacyGroupWebsiteRef = !!this.headerContent?.pharmacy_group;
      if (this.usePharmacyGroupWebsiteRef) {
        this.storedGroupWebsiteRef = this.headerContent.pharmacy_group?.group_website_ref ?? null;
      }
    }
  }
});
