import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9b656a64"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row brand-filters mb-3" }
const _hoisted_2 = { class: "col-4" }
const _hoisted_3 = { class: "row brands-selection" }
const _hoisted_4 = { class: "col-4" }
const _hoisted_5 = { class: "col-4" }
const _hoisted_6 = { class: "col-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertError = _resolveComponent("AlertError")!
  const _component_ProductSelectionBrandsFilters = _resolveComponent("ProductSelectionBrandsFilters")!
  const _component_ProductSelectionBrandsSelection = _resolveComponent("ProductSelectionBrandsSelection")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AlertError, {
      exceptions: this.exceptions,
      class: "mb-3"
    }, null, 8, ["exceptions"]),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ProductSelectionBrandsFilters, { onSubmit: _ctx.submitSearch }, null, 8, ["onSubmit"])
      ])
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ProductSelectionBrandsSelection, {
          "is-ready": _ctx.isReadyBase,
          "filter-selection-type-code": "BASE_SELECTION",
          onUpdateBrandItem: _ctx.updateBrandItem,
          onUpdatedPage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchBrands('BASE_SELECTION')))
        }, null, 8, ["is-ready", "onUpdateBrandItem"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_ProductSelectionBrandsSelection, {
          "filter-selection-type-code": "INCLUDE",
          "is-ready": _ctx.isReadyInclude,
          onUpdateBrandItem: _ctx.updateBrandItem,
          onUpdatedPage: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchBrands('INCLUDE')))
        }, null, 8, ["is-ready", "onUpdateBrandItem"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_ProductSelectionBrandsSelection, {
          "filter-selection-type-code": "EXCLUDE",
          "is-ready": _ctx.isReadyExclude,
          onUpdateBrandItem: _ctx.updateBrandItem,
          onUpdatedPage: _cache[2] || (_cache[2] = ($event: any) => (_ctx.searchBrands('EXCLUDE')))
        }, null, 8, ["is-ready", "onUpdateBrandItem"])
      ])
    ])
  ], 64))
}