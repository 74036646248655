import {BorderRadiusRestDto,} from "@/api/pharma-cms-content-page/models";

export class BorderRadiusRestDtoModel implements BorderRadiusRestDto {
    top_left_in_px: number;
    top_right_in_px: number;
    bottom_left_in_px: number;
    bottom_right_in_px: number;
    constructor(top_left_in_px: number, top_right_in_px: number, bottom_left_in_px: number, bottom_right_in_px: number) {
        this.top_left_in_px = top_left_in_px;
        this.top_right_in_px = top_right_in_px;
        this.bottom_left_in_px = bottom_left_in_px;
        this.bottom_right_in_px = bottom_right_in_px;
    }

    static createWithDefaults(): BorderRadiusRestDtoModel {
        return new this(0,0,0,0);
    }

    public static cloneFrom(source: BorderRadiusRestDtoModel): BorderRadiusRestDtoModel {
        return new this(
            source.top_left_in_px,
            source.top_right_in_px,
            source.bottom_left_in_px,
            source.bottom_right_in_px,
        );
    }

}
