<template>
  <div
    v-for="language in this.languages"
    :key="language"
    v-show="this.activeLanguage === language"
  >
    <input-image
      v-model="this.internalData[language]"
      :label="this.label"
      :rules="this.rules"
      :is-cpc="this.isCpc"
      :type-code="this.typeCode"
      :deletable="this.deletable"
      :height="this.height"
      :language="language"
      :isMultilingual="$can('use-feature', 'MULTILINGUAL')"
    />
  </div>
</template>

<script>
import {I18nContext} from "@/context/I18nContext";
import InputImage from "@/components/UI/InputImage.vue";

export default {
  name: "I18nInputImage",
  components: {InputImage},
  extends: InputImage,
  props: {
    languages: {
      type: Array,
      required: false,
      default() {
        return I18nContext.getLanguages();
      }
    },
    activeLanguage: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  mounted() {
      this.languages.forEach(lang => {
        if (this.internalData[lang] === undefined) {
          this.internalData.lang = null;
        }
      });
  },
  data() {
    return {
      internalData: this.data,
    };
  },
}
</script>
