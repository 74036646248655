
import {defineComponent} from 'vue'
import {generateDomId} from "@/helpers/functions/string";

export default defineComponent({
  name: "TheSideBarMenuItem",
  emits: ["toggleMenu"],
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    to: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      id: generateDomId('collapseId') as string,
      isActive: false as boolean,
      parentCollapseId: 'sidebarCollapse' as string
    }
  },
  methods: {
    toggleIsActive(): void {
      this.isActive = !this.isActive;
    },
    toggleMenu(): void {
      this.$emit('toggleMenu')
    }
  },
  computed: {
    hasSubMenu(): boolean {
      return typeof this.to === 'undefined';
    }
  }
})
