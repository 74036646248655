/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */

import {ButtonRestDto} from ".";
import {ContentBlockMetadataRestDto} from ".";
import {ImageRestDto} from ".";
import {MultilingualFieldRestDto} from ".";
import {MultilingualMax500FieldRestDto} from ".";
import {MultilingualMax50FieldRestDto} from ".";
import {ButtonHorizontalPositionEnum} from "@/api/enums/button-horizontal-position-enum";
import {HorizontalImageShiftEnum} from "@/api/enums/horizontal-image-shift-enum";
import {StatusEnum} from "@/api/enums/status-enum";
import {TextWithImageContentBlockLayoutEnum} from "@/api/enums/text-with-image-content-block-layout-enum";
import {TitleSizeEnum} from "@/api/enums/title-size-enum";
import {VerticalImageShiftEnum} from "@/api/enums/vertical-image-shift-enum";

/**
 * @export
 * @interface TextWithImageContentRestDto
 */
export interface TextWithImageContentRestDto {
    /**
     * The status of this content block.
     * @type {StatusEnum}
     * @memberof TextWithImageContentRestDto
     */
    status_code: StatusEnum;

    /**
     * The sorting value of this content block.
     * @type {number|null}
     * @memberof TextWithImageContentRestDto
     */
    sort_weight?: number|null;

    /**
     * Determines the layout of this content block.
     * @type {TextWithImageContentBlockLayoutEnum|null}
     * @memberof TextWithImageContentRestDto
     */
    layout_code?: TextWithImageContentBlockLayoutEnum|null;

    /**
     * Determines the size of the title.
     * @type {TitleSizeEnum|null}
     * @memberof TextWithImageContentRestDto
     */
    title_size_code?: TitleSizeEnum|null;

    /**
     * 
     * @type {MultilingualMax500FieldRestDto|null}
     * @memberof TextWithImageContentRestDto
     */
    title?: MultilingualMax500FieldRestDto|null;

    /**
     * 
     * @type {MultilingualFieldRestDto|null}
     * @memberof TextWithImageContentRestDto
     */
    content?: MultilingualFieldRestDto|null;

    /**
     * 
     * @type {MultilingualMax50FieldRestDto|null}
     * @memberof TextWithImageContentRestDto
     */
    image_key?: MultilingualMax50FieldRestDto|null;

    /**
     * 
     * @type {ImageRestDto|null}
     * @memberof TextWithImageContentRestDto
     */
    image?: ImageRestDto|null;

    /**
     * Determines the size of the title.
     * @type {VerticalImageShiftEnum|null}
     * @memberof TextWithImageContentRestDto
     */
    vertical_image_shift_code?: VerticalImageShiftEnum|null;

    /**
     * Determines the size of the title.
     * @type {HorizontalImageShiftEnum|null}
     * @memberof TextWithImageContentRestDto
     */
    horizontal_image_shift_code?: HorizontalImageShiftEnum|null;

    /**
     * 
     * @type {ButtonRestDto|null}
     * @memberof TextWithImageContentRestDto
     */
    button?: ButtonRestDto|null;

    /**
     * Determines the horizontal object-position for the button.
     * @type {ButtonHorizontalPositionEnum|null}
     * @memberof TextWithImageContentRestDto
     */
    button_horizontal_position_code?: ButtonHorizontalPositionEnum|null;

    /**
     * 
     * @type {ContentBlockMetadataRestDto|null}
     * @memberof TextWithImageContentRestDto
     */
    cb_metadata?: ContentBlockMetadataRestDto|null;

}

/**
 * @export
 * @function cloneTextWithImageContentRestDtoMetaData
 */
export function cloneTextWithImageContentRestDtoMetaData(dto: TextWithImageContentRestDto, source: TextWithImageContentRestDto): void {
    dto.layout_code = source.layout_code;
    dto.title_size_code = source.title_size_code;
    dto.vertical_image_shift_code = source.vertical_image_shift_code;
    dto.horizontal_image_shift_code = source.horizontal_image_shift_code;
    dto.button_horizontal_position_code = source.button_horizontal_position_code;
}
