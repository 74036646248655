import i18n from "@/modules/i18n";

export function registerLanguageInterceptor(instance) {
    instance.interceptors.request.use(
        (config) => {
            const languageCode = i18n.global.locale;

            if (languageCode) {
                config.headers['x-dp-language'] = languageCode;
            }

            return config;
        },
        (error) => {
            Promise.reject(error);
        }
    );
}
