import {
    ClosingPeriodsEndpointsApiService,
    PharmacyInformationEndpointsApiService,
    OpeningInfoOpeningHoursPeriodsEndpointsApiService,
    StandardOpeningHoursEndpointsApiService,
} from "@/main";
import store from "@/store";
import {
    AddressRestDto,
    ClosingPeriodRestDto,
    OpeningHoursForWeekRestDto,
    OpeningHoursPeriodRestDto, PeriodOverviewRestDto,
    PharmacyRestDto,
} from "@/api/pharma-cms-pharmacy/models";

export default {
    namespaced: true,
    state() {
        return {
            closing_period: {} as ClosingPeriodRestDto,
            closing_periods: {} as PeriodOverviewRestDto,
            opening_hours_period: {} as OpeningHoursPeriodRestDto,
            opening_hours_periods: {} as PeriodOverviewRestDto,
            pharmacy_info: {} as PharmacyRestDto,
            standard_opening_hours: {} as OpeningHoursForWeekRestDto,
        }
    },
    actions: {
        async createClosingPeriod(context: any, payload: any) {
            const cp = payload['closingPeriod'];
            const pc = payload['pc']
            await ClosingPeriodsEndpointsApiService.createClosingPeriod(cp, pc).then(response => {
                const payloadResponse = {
                    body: response.data,
                }
                store.commit('cms_pharmacy/setClosingPeriod', payloadResponse);
            });
        },
        async createOpeningHoursPeriod(context: any, payload: any) {
            //TODO: make code fragment into function
            const openingHoursWeekForPeriod = payload.body.opening_hours as OpeningHoursForWeekRestDto;
            for (const [day, openingHoursDay] of Object.entries(openingHoursWeekForPeriod)) {
                if (openingHoursDay.open) {
                    openingHoursDay.hours1_from = (openingHoursDay.hours1_from === '') ? null : openingHoursDay.hours1_from;
                    openingHoursDay.hours1_till = (openingHoursDay.hours1_till === '') ? null : openingHoursDay.hours1_till;
                    openingHoursDay.hours2_from = (openingHoursDay.hours2_from === '') ? null : openingHoursDay.hours2_from;
                    openingHoursDay.hours2_till = (openingHoursDay.hours2_till === '') ? null : openingHoursDay.hours2_till;
                    openingHoursDay.hours3_from = (openingHoursDay.hours3_from === '') ? null : openingHoursDay.hours3_from;
                    openingHoursDay.hours3_till = (openingHoursDay.hours3_till === '') ? null : openingHoursDay.hours3_till;
                } else {
                    openingHoursDay.hours1_from = null;
                    openingHoursDay.hours1_till = null;
                    openingHoursDay.hours2_from = null;
                    openingHoursDay.hours2_till = null;
                    openingHoursDay.hours3_from = null;
                    openingHoursDay.hours3_till = null;
                }
            }

            await OpeningInfoOpeningHoursPeriodsEndpointsApiService.createOpeningHoursPeriod(payload.body, payload.pharmacy_code).then(response => {
                const payloadResponse = response.data;
                store.commit('cms_pharmacy/setOpeningHoursPeriod', payloadResponse);
            });
        },
        async deleteClosingPeriod(context: any, payload: any) {
            const cp = payload['id'];
            const pc = payload['pc']
            await ClosingPeriodsEndpointsApiService.deleteClosingPeriod(cp, pc);
        },
        async deleteOpeningHoursPeriod(context: any, payload: any) {
            await OpeningInfoOpeningHoursPeriodsEndpointsApiService.deleteOpeningHoursPeriod(payload.id, payload.pharmacy_code);
        },
        async searchClosingPeriod(context: any, payload: any) {
            await ClosingPeriodsEndpointsApiService.getClosingPeriod(payload['id'], payload['pc']).then(response => {
                const payloadResponse = {
                    body: response.data
                };
                store.commit('cms_pharmacy/setClosingPeriod', payloadResponse);
            })
        },
        async searchClosingPeriods(context: any, payload: any) {
            await ClosingPeriodsEndpointsApiService.findClosingPeriods(payload).then(response => {
                const payloadResponse = {
                    body: response.data
                };
                store.commit('cms_pharmacy/setClosingPeriods', payloadResponse);
            })
        },
        async searchOpeningHoursPeriod(context: any, payload: any) {
            await OpeningInfoOpeningHoursPeriodsEndpointsApiService.getOpeningHoursPeriod(payload.id, payload.pharmacy_code).then(response => {
                const payloadResponse = response.data;
                store.commit('cms_pharmacy/setOpeningHoursPeriod', payloadResponse);
            })
        },
        async searchOpeningHoursPeriods(context: any, pharmacy_code: any) {
            await OpeningInfoOpeningHoursPeriodsEndpointsApiService.findOpeningHoursPeriods(pharmacy_code).then(response => {
                const payloadResponse = response.data;
                store.commit('cms_pharmacy/setOpeningHoursPeriods', payloadResponse);
            });
        },
        async searchPharmacyInfo(context: any, pharmacy_code: any) {
            let pharmacyInfo = {} as PharmacyRestDto;
            await PharmacyInformationEndpointsApiService.getPharmacy(pharmacy_code).then(response => {
                pharmacyInfo = response.data;

                //TODO: check for better ways to do this
                if (pharmacyInfo.public_address === null) {
                    pharmacyInfo.public_address = {
                        street: {
                            nl: ''
                        },
                        house_number: '',
                        box: '',
                        postal_code: '',
                        postal_code_addition: null,
                        city_name: {
                            nl: ''
                        },
                        country_name: {
                            nl: 'België'
                        },
                        extra_address_line: {
                            nl: ''
                        },
                    } as AddressRestDto;
                }

                store.commit('cms_pharmacy/setPharmacyInfo', pharmacyInfo);
            })
        },
        async searchStandardOpeningHours(context: any, pharmacy_code: any) {
            await StandardOpeningHoursEndpointsApiService.findStandardOpeningHours(pharmacy_code).then(response => {
                const standardOpeningHours = response.data;
                store.commit('cms_pharmacy/setStandardOpeningHours', standardOpeningHours);
            });
        },
        async saveClosingPeriod(context: any, payload: any) {
            const body = payload['cp'];
            const pc = payload['pc'];
            const id = payload['cp_id'];
            // // todo Date hack
            // console.log("begin",body.begin )
            // console.log("null via moment",moment(null, "YYYY-MM-DD") )
            // console.log("begin via moment",moment(body.begin, "YYYY-MM-DD") )
            // const begin = moment(body.begin, "YYYY-MM-DD")
            // console.log("begin converted",moment(begin).format("DD-MM-YYYY") )
            //
            // console.log("begin is date",(body.begin instanceof Date) )


            await ClosingPeriodsEndpointsApiService.updateClosingPeriod(body, id, pc);
        },
        async saveOpeningHoursPeriod(context: any, payload: any) {
            //TODO: make code fragment into function
            const openingHoursWeekForPeriod = payload.body.opening_hours as OpeningHoursForWeekRestDto;
            for (const [day, openingHoursDay] of Object.entries(openingHoursWeekForPeriod)) {
                if (openingHoursDay.open) {
                    openingHoursDay.hours1_from = (openingHoursDay.hours1_from === '') ? null : openingHoursDay.hours1_from;
                    openingHoursDay.hours1_till = (openingHoursDay.hours1_till === '') ? null : openingHoursDay.hours1_till;
                    openingHoursDay.hours2_from = (openingHoursDay.hours2_from === '') ? null : openingHoursDay.hours2_from;
                    openingHoursDay.hours2_till = (openingHoursDay.hours2_till === '') ? null : openingHoursDay.hours2_till;
                    openingHoursDay.hours3_from = (openingHoursDay.hours3_from === '') ? null : openingHoursDay.hours3_from;
                    openingHoursDay.hours3_till = (openingHoursDay.hours3_till === '') ? null : openingHoursDay.hours3_till;
                } else {
                    openingHoursDay.hours1_from = null;
                    openingHoursDay.hours1_till = null;
                    openingHoursDay.hours2_from = null;
                    openingHoursDay.hours2_till = null;
                    openingHoursDay.hours3_from = null;
                    openingHoursDay.hours3_till = null;
                }
            }

            await OpeningInfoOpeningHoursPeriodsEndpointsApiService.updateOpeningHoursPeriod(payload.body, payload.id, payload.pharmacy_code);
        },
        async savePharmacyInfo(context: any, payload: any) {
            //TODO: check for better ways to do this
            if (!payload.pharmacy_info.different_contactinfo) {
                payload.pharmacy_info.public_address = null;
                payload.pharmacy_info.public_email = null;
                payload.pharmacy_info.public_phone = null;
            }

            const body = payload.pharmacy_info;
            const pharmacy_code = payload.pharmacy_code;
            await PharmacyInformationEndpointsApiService.updatePharmacy(body, pharmacy_code);

            //TODO: check for better ways to do this
            if (payload.pharmacy_info.public_address === null) {
                payload.pharmacy_info.public_address = {
                    street: {
                        nl: ''
                    },
                    house_number: '',
                    box: '',
                    postal_code: '',
                    postal_code_addition: null,
                    city_name: {
                        nl: ''
                    },
                    country_name: {
                        nl: 'België'
                    },
                    extra_address_line: {
                        nl: ''
                    },
                } as AddressRestDto;
            }
        },
        async saveStandardOpeningHours(context: any, payload: any) {
            const standardOpeningHoursWeek = payload.body as OpeningHoursForWeekRestDto;
            for (const [day, openingHoursDay] of Object.entries(standardOpeningHoursWeek)) {
                if (openingHoursDay.open) {
                    openingHoursDay.hours1_from = (openingHoursDay.hours1_from === '') ? null : openingHoursDay.hours1_from;
                    openingHoursDay.hours1_till = (openingHoursDay.hours1_till === '') ? null : openingHoursDay.hours1_till;
                    openingHoursDay.hours2_from = (openingHoursDay.hours2_from === '') ? null : openingHoursDay.hours2_from;
                    openingHoursDay.hours2_till = (openingHoursDay.hours2_till === '') ? null : openingHoursDay.hours2_till;
                    openingHoursDay.hours3_from = (openingHoursDay.hours3_from === '') ? null : openingHoursDay.hours3_from;
                    openingHoursDay.hours3_till = (openingHoursDay.hours3_till === '') ? null : openingHoursDay.hours3_till;
                } else {
                    openingHoursDay.hours1_from = null;
                    openingHoursDay.hours1_till = null;
                    openingHoursDay.hours2_from = null;
                    openingHoursDay.hours2_till = null;
                    openingHoursDay.hours3_from = null;
                    openingHoursDay.hours3_till = null;
                }
            }

            await StandardOpeningHoursEndpointsApiService.updateStandardOpeningHours(payload.body, payload.pharmacy_code);
        },
        /*async saveTeamAbout(context: any, payload: any) {
            const body = payload['teamAbout']
            const pharmacy_code = payload['pharmacy_code']
            await TeamEndpointsApiService.updateTeam(body, pharmacy_code);
        },*/
    },
    mutations: {
        setClosingPeriod(state: any, payload: any) {
            state.closing_period = payload['body']
        },
        setClosingPeriods(state: any, payload: any) {
            state.closing_periods = payload['body'];
        },
        setOpeningHoursPeriod(state: any, payload: any) {
            state.opening_hours_period = payload;
        },
        setOpeningHoursPeriods(state: any, payload: any) {
            state.opening_hours_periods = payload;
        },
        setPharmacyInfo(state: any, payload: any) {
            state.pharmacy_info = payload;
        },
        setStandardOpeningHours(state: any, payload: any) {
            state.standard_opening_hours = payload;
        },
    },
    getters: {
        getClosingPeriod(state: any): ClosingPeriodRestDto[] {
            return state.closing_period;
        },
        getClosingPeriods(state: any): PeriodOverviewRestDto[] {
            return state.closing_periods;
        },
        getOpeningHoursPeriod(state: any): OpeningHoursPeriodRestDto[] {
            return state.opening_hours_period;
        },
        getOpeningHoursPeriods(state: any): PeriodOverviewRestDto[] {
            return state.opening_hours_periods;
        },
        getPharmacyInfo(state: any): PharmacyRestDto[] {
            return state.pharmacy_info;
        },
        getStandardOpeningHours(state: any): OpeningHoursForWeekRestDto[] {
            return state.standard_opening_hours;
        },
    }
}
