import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "cp-abstract d-flex px-3" }
const _hoisted_2 = { class: "image me-2" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "content" }
const _hoisted_5 = { class: "fs-5" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.signedUrl)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.signedUrl,
            loading: "lazy",
            alt: "previewCP",
            style: {"width":"5rem","height":"auto"}
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.contentPageAbstract.title), 1),
      _createElementVNode("div", {
        innerHTML: _ctx.content,
        class: "description"
      }, null, 8, _hoisted_6)
    ])
  ]))
}