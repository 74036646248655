<script>
import AbstractInputControl from "@/components/UI/AbstractInputControl";
import {UtilityHelper} from "@/helpers/UtilityHelper";

export default {
  extends: AbstractInputControl,
  methods: {
    determineInputType() {
      return 'text';
    },
    determineRules() {
      return [];
    },
    updateValue(value) {
      value = UtilityHelper.emptyToNull(value);

      this.$emit('update:modelValue', value);
    },
  }
};
</script>

<style scoped lang="scss">
.dp-input {
  &.clearable {
    position: relative;

    button.btn-close {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 0.7rem 0.5rem;
    }
  }

  &.can-be-cleared input {
    padding-right: 2rem;
  }
}
</style>
