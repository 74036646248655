
import {defineComponent} from 'vue'
import {UIStateDto} from "@/dtos/UIStateDto";
import BasePlaceholderTable from "@/components/UI/Bootstrap/BasePlaceholderTable.vue";
import {
  PagedLaboProductOverviewResultsRestDtoModel
} from "@/models/api/pharma-pim-labo/PagedLaboProductOverviewResultsRestDtoModel";
import PimLabProductsOverviewTableItem from "@/components/layouts/pim/PimLabProductsOverviewTableItem.vue";

export default defineComponent({
  name: "PimLabProductsOverviewTable",
  emits: ["openOffCanvasProductDetail"],
  components: {PimLabProductsOverviewTableItem, BasePlaceholderTable},
  props: {
    productsOverview: {
      type: [PagedLaboProductOverviewResultsRestDtoModel, Object],
      required: true
    },
    hasProducts: {
      type: Boolean,
      required: true
    },
    ui: {
      type: UIStateDto,
      required: true
    }
  }
})
