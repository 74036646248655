export class CopyCpAbstractPayload {
    content: string | null;
    image_key: string | null;
    title: string | null;

    constructor(content: string | null, image_key: string | null, title: string | null) {
        this.content = content;
        this.image_key = image_key;
        this.title = title;
    }

    public static createWithDefaults(): CopyCpAbstractPayload {
        return new CopyCpAbstractPayload(null, null, null);
    }
}
