export function registerAcceptInterceptor(instance) {
    instance.interceptors.request.use(
        (config) => {
            switch (config.headers.getAccept()) {
                case 'application/pdf':
                case 'text/csv':
                    config.responseType = 'blob';
                    break;
            }

            return config;
        },
        (error) => {
            Promise.reject(error);
        }
    );
}
