/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 * Generated: Tue, 18 Apr 2023 09:32:32 +0000
 */
export enum PharmacyInfoLayoutEnum {
    MAIN_PHARMACY_ONLY = 'MAIN_PHARMACY_ONLY',
    GRID_2XN = 'GRID_2XN',
    GRID_3XN = 'GRID_3XN',
    GRID_4XN = 'GRID_4XN',
}