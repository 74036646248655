/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */

import {MultilingualMax100FieldRestDto} from ".";
import {MultilingualMax2000FieldRestDto} from ".";
import {MultilingualMax50FieldRestDto} from ".";
import {BorderTypeEnum} from "@/api/enums/border-type-enum";
import {BorderWidthEnum} from "@/api/enums/border-width-enum";
import {ImageTargetEnum} from "@/api/enums/image-target-enum";
import {ImageWidthEnum} from "@/api/enums/image-width-enum";
import {LightboxLayoutEnum} from "@/api/enums/lightbox-layout-enum";

/**
 * @export
 * @interface ImageRestDto
 */
export interface ImageRestDto {
    /**
     * 
     * @type {MultilingualMax100FieldRestDto|null}
     * @memberof ImageRestDto
     */
    title?: MultilingualMax100FieldRestDto|null;

    /**
     * 
     * @type {MultilingualMax50FieldRestDto}
     * @memberof ImageRestDto
     */
    image_key: MultilingualMax50FieldRestDto;

    /**
     * The html target for this image.
     * @type {ImageTargetEnum|null}
     * @memberof ImageRestDto
     */
    image_target?: ImageTargetEnum|null;

    /**
     * 
     * @type {MultilingualMax2000FieldRestDto|null}
     * @memberof ImageRestDto
     */
    image_url?: MultilingualMax2000FieldRestDto|null;

    /**
     * 
     * @type {MultilingualMax50FieldRestDto|null}
     * @memberof ImageRestDto
     */
    image_page_id?: MultilingualMax50FieldRestDto|null;

    /**
     * The specifies width of this image.
     * @type {ImageWidthEnum}
     * @memberof ImageRestDto
     */
    image_width_code: ImageWidthEnum;

    /**
     * The specifies the type of lightbox that is supported for this image.
     * @type {LightboxLayoutEnum}
     * @memberof ImageRestDto
     */
    lightbox_layout_code: LightboxLayoutEnum;

    /**
     * The specifies the type of border for this image.
     * @type {BorderTypeEnum}
     * @memberof ImageRestDto
     */
    border_type_code: BorderTypeEnum;

    /**
     * The specifies the width of the image border if border_type_code &lt;&gt; NONE.
     * @type {BorderWidthEnum|null}
     * @memberof ImageRestDto
     */
    border_width_code?: BorderWidthEnum|null;

}

/**
 * @export
 * @function cloneImageRestDtoMetaData
 */
export function cloneImageRestDtoMetaData(dto: ImageRestDto, source: ImageRestDto): void {
    dto.image_target = source.image_target;
    dto.image_width_code = source.image_width_code;
    dto.lightbox_layout_code = source.lightbox_layout_code;
    dto.border_type_code = source.border_type_code;
    dto.border_width_code = source.border_width_code;
}
