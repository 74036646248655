
import {defineComponent} from 'vue'
import BasePagination from "@/components/UI/Bootstrap/BasePagination.vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import {
  PagedPp2ProductOverviewResultsRestDtoModel
} from "@/models/api/pharma-pim-pp2/PagedPp2ProductOverviewResultsRestDtoModel";
import {PimProductsOverviewPageLayoutEnum} from "@/models/enum/pim/PimProductsOverviewPageLayoutEnum";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import {CodeDescriptionRestDtoModel} from "@/models/CodeDescriptionRestDtoModel";
import {mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import {PimProductsPagingModel} from "@/models/paging/PimProductsPagingModel";
import {
  PagedLaboProductOverviewResultsRestDtoModel
} from "@/models/api/pharma-pim-labo/PagedLaboProductOverviewResultsRestDtoModel";
import {
  PagedCatalogProductOverviewResultsRestDtoModel
} from "@/models/api/pharma-cpc-product-mgmt/PagedCatalogProductOverviewResultsRestDtoModel";

export default defineComponent({
  name: "PimProductsOverviewControls",
  emits: ["change", "downloadCsv"],
  components: {InputSelect, BasePagination},
  props: {
    productsOverview: {
      type: [PagedPp2ProductOverviewResultsRestDtoModel, PagedLaboProductOverviewResultsRestDtoModel, PagedCatalogProductOverviewResultsRestDtoModel, Object],
      required: true
    },
    hasProducts: {
      type: Boolean,
      required: true
    },
    hasCategoryExpectedToggle: {
      type: Boolean,
      required: false,
      default: false
    },
    isLayoutToggleSupported: {
      type: Boolean,
      required: false,
      default: true
    },
    isCsvDownloadSupported: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  computed: {
    ...mapState(useCustomerStore, ["getPaging"]),
    PimProductsOverviewPageLayoutEnum() {
      return PimProductsOverviewPageLayoutEnum
    },
    productsPaging(): PimProductsPagingModel {
      return this.getPaging.pim_products;
    },
    pageSizeOptions(): CodeDescriptionRestDto[] {
      const sizes = ['12', '24', '48', '96', '192'] as string[];
      const pageSizeOptions = [] as CodeDescriptionRestDto[];

      sizes.forEach((size: string) => {
        pageSizeOptions.push(
          new CodeDescriptionRestDtoModel(size, size)
        );
      });

      return pageSizeOptions;
    }
  },
  methods: {
    onChange(): void {
      this.$emit('change');
    },
    onChangePageNumber(number: number): void {
      this.productsPaging.number = number;
      this.onChange();
    },
    onChangePageSize(): void {
      this.onChangePageNumber(1);
    },
    setLayout(layout: PimProductsOverviewPageLayoutEnum): void {
      this.productsPaging.layout = layout;
    },
    toggleExpectCategory(): void {
      this.productsPaging.isCategoriesExpected = !this.productsPaging.isCategoriesExpected;
    }
  }
})
