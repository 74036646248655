import {
    CatalogBuildStatusRestDto,
    PagedBrandSelectionOverviewRestDto, PagedProductSelectionOverviewRestDto, PagedTrademarkSelectionOverviewRestDto,
    ProductImportSettingsRestDto,
    ProductSelectionTypeRestDto
} from "@/api/pharma-cpc-mgmt/models";
import {CpcCatalogSettingsEndpointsApiService} from "@/main";
import {DpException} from "@/exception/DpException";
import {SelectionTypeEnum} from "@/api/enums/selection-type-enum";

export default {
    state() {
        return {
            catalog_build_status: {} as CatalogBuildStatusRestDto,

            product_selection_base: {} as ProductImportSettingsRestDto,
            product_selection_brands_all: {} as PagedBrandSelectionOverviewRestDto,
            product_selection_brands_base_selection: {} as PagedBrandSelectionOverviewRestDto,
            product_selection_brands_include: {} as PagedBrandSelectionOverviewRestDto,
            product_selection_brands_exclude: {} as PagedBrandSelectionOverviewRestDto,
            product_selection_trademarks_all: {} as PagedTrademarkSelectionOverviewRestDto,
            product_selection_trademarks_base_selection: {} as PagedTrademarkSelectionOverviewRestDto,
            product_selection_trademarks_include: {} as PagedTrademarkSelectionOverviewRestDto,
            product_selection_trademarks_exclude: {} as PagedTrademarkSelectionOverviewRestDto,
            product_selection_products_all: {} as PagedProductSelectionOverviewRestDto,
            product_selection_products_base_selection: {} as PagedProductSelectionOverviewRestDto,
            product_selection_products_include: {} as PagedProductSelectionOverviewRestDto,
            product_selection_products_exclude: {} as PagedProductSelectionOverviewRestDto,

            current_page_brands_all: 1 as number,
            current_page_brands_base_selection: 1 as number,
            current_page_brands_include: 1 as number,
            current_page_brands_exclude: 1 as number,
            current_page_trademarks_all: 1 as number,
            current_page_trademarks_base_selection: 1 as number,
            current_page_trademarks_include: 1 as number,
            current_page_trademarks_exclude: 1 as number,
            current_page_products_all: 1 as number,
            current_page_products_base_selection: 1 as number,
            current_page_products_include: 1 as number,
            current_page_products_exclude: 1 as number,
        };
    },
    actions: {
        async buildCatalog() {
            await CpcCatalogSettingsEndpointsApiService.buildCatalog();
        },
        async resetCurrentPage(context: any, payload: any) {
            await context.dispatch("setCurrentPage", {
                type: payload.type,
                filter_selection_type_code: payload.filter_selection_type_code,
                page: 1 as number,
            });
        },
        async setCurrentPage(context: any, payload: any) {
            const type = payload.type.toUpperCase() as string;
            const filter_selection_type_code = payload.filter_selection_type_code as string | undefined;
            const page = payload.page as number;

            switch (type) {
                case 'BRAND':
                    context.commit('setCurrentBrandPage', {
                        filter_selection_type_code: filter_selection_type_code,
                        page: page,
                    });
                    break;
                case 'TRADEMARK':
                    context.commit('setCurrentTrademarkPage', {
                        filter_selection_type_code: filter_selection_type_code,
                        page: page,
                    });
                    break;
                case 'PRODUCT':
                    context.commit('setCurrentProductPage', {
                        filter_selection_type_code: filter_selection_type_code,
                        page: page,
                    });
                    break;
                default:
                    throw [new DpException('Unsupported page type to set: ' + type)];
            }
        },
        async searchCatalogBuildStatus(context: any) {
            const response = await CpcCatalogSettingsEndpointsApiService.getCatalogBuildStatus();
            context.commit('setCatalogBuildStatus', response.data);
        },
        async searchProductSelectionBrands(context: any, payload: any) {
            const filter_selection_type_code = payload.filter_selection_type_code as string | undefined;
            //If no page param is provided, use the stored paged number
            const page = (payload.page)
                ? payload.page
                : context.getters.getCurrentBrandPage(filter_selection_type_code) as number;
            //If no page_size param is provided, use the default size
            const page_size = (payload.page_size)
                ? payload.page_size
                : 50 as number;
            //If no searchQuery param is provided, use the stored searchQuery
            const search_brand_name = (payload.search_brand_name)
                ? payload.search_brand_name
                : context.getters.getSearchBrandName as string | undefined;

            const response = await CpcCatalogSettingsEndpointsApiService
                .findProductSelectionBrands(undefined, search_brand_name, filter_selection_type_code, page, page_size);
            context.commit('setProductSelectionBrands', {
                body: response.data,
                filter_selection_type_code: filter_selection_type_code,
            });
        },
        async searchProductSelectionTrademarks(context: any, payload: any) {
            const filter_selection_type_code = payload.filter_selection_type_code as string | undefined;
            //If no page param is provided, use the stored paged number
            const page = (payload.page)
                ? payload.page
                : context.getters.getCurrentTrademarkPage(filter_selection_type_code) as number;
            //If no page_size param is provided, use the default size
            const page_size = (payload.page_size)
                ? payload.page_size
                : 50 as number;
            //If no searchQuery param is provided, use the stored searchQuery
            const search_trademark_name = (payload.search_trademark_name)
                ? payload.search_trademark_name
                : context.getters.getSearchTrademarkName as string | undefined;

            const response = await CpcCatalogSettingsEndpointsApiService
                .findProductSelectionTrademarks(undefined, search_trademark_name, filter_selection_type_code, page, page_size);
            context.commit('setProductSelectionTrademarks', {
                body: response.data,
                filter_selection_type_code: filter_selection_type_code,
            });
        },
        async searchProductSelectionProducts(context: any, payload: any) {
            const filter_selection_type_code = payload.filter_selection_type_code as string | undefined;
            //If no page param is provided, use the stored paged number
            const page = (payload.page)
                ? payload.page
                : context.getters.getCurrentProductPage(filter_selection_type_code) as number;
            //If no page_size param is provided, use the default size
            const page_size = (payload.page_size)
                ? payload.page_size
                : 50 as number;
            //If no searchQuery param is provided, use the stored searchQuery
            const filter_cnk_code = (payload.filter_cnk_code)
                ? payload.filter_cnk_code
                : context.getters.getSearchProduct.cnk_code as string | undefined;
            //If no searchQuery param is provided, use the stored searchQuery
            const search_brand_name = (payload.search_brand_name)
                ? payload.search_brand_name
                : context.getters.getSearchProduct.brand_name as string | undefined;
            //If no searchQuery param is provided, use the stored searchQuery
            const search_trademark_name = (payload.search_trademark_name)
                ? payload.search_trademark_name
                : context.getters.getSearchProduct.trademark_name as string | undefined;
            //If no searchQuery param is provided, use the stored searchQuery
            const search_product_name = (payload.search_product_name)
                ? payload.search_product_name
                : context.getters.getSearchProduct.product_name as string | undefined;
            //If no searchQuery param is provided, use the stored searchQuery
            const filter_usage_type_code = (payload.filter_usage_type_code)
                ? payload.filter_usage_type_code
                : context.getters.getSearchProduct.usage_type_code as string | undefined;

            //If no searchQuery param is provided, use the stored searchQuery
            let filter_is_medicine = (payload.filter_is_medicine)
                ? payload.filter_is_medicine
                : context.getters.getSearchProduct.is_medicine as boolean | undefined;
            //If filter is false, set to undefined
            if (!filter_is_medicine) {
                filter_is_medicine = undefined;
            }

            //If no searchQuery param is provided, use the stored searchQuery
            let filter_is_medical_aid = (payload.filter_is_medical_aid)
                ? payload.filter_is_medical_aid
                : context.getters.getSearchProduct.is_medical_aid as boolean | undefined;
            //If filter is false, set to undefined
            if (!filter_is_medical_aid) {
                filter_is_medical_aid = undefined;
            }

            //If no searchQuery param is provided, use the stored searchQuery
            let filter_is_prescription_required = (payload.filter_is_prescription_required)
                ? payload.filter_is_prescription_required
                : context.getters.getSearchProduct.is_prescription_required as boolean | undefined;
            //If filter is false, set to undefined
            if (!filter_is_prescription_required) {
                filter_is_prescription_required = undefined;
            }

            const response = await CpcCatalogSettingsEndpointsApiService
                .findProductSelectionProducts(undefined, filter_selection_type_code, filter_usage_type_code, filter_cnk_code, filter_is_medicine, filter_is_medical_aid, filter_is_prescription_required, search_brand_name, null, search_trademark_name, search_product_name, page, page_size);
            context.commit('setProductSelectionProducts', {
                body: response.data,
                filter_selection_type_code: filter_selection_type_code,
            });
        },
        async saveProductSelectionBrand(context: any, payload: any) {
            const brand_code = payload.brand_code as string;
            const selection_type_code = payload.selection_type_code as SelectionTypeEnum;
            const body = {
                selection_type_code: selection_type_code,
            } as ProductSelectionTypeRestDto;

            await CpcCatalogSettingsEndpointsApiService.updateProductSelectionBrand(body, brand_code);
        },
        async saveProductSelectionTrademark(context: any, payload: any) {
            const trademark_code = payload.trademark_code as string;
            const selection_type_code = payload.selection_type_code as SelectionTypeEnum;
            const body = {
                selection_type_code: selection_type_code,
            } as ProductSelectionTypeRestDto;

            await CpcCatalogSettingsEndpointsApiService.updateProductSelectionTrademark(body, trademark_code);
        },
        async saveProductSelectionProduct(context: any, payload: any) {
            const product_code = payload.product_code as string;
            const selection_type_code = payload.selection_type_code as SelectionTypeEnum;
            const body = {
                selection_type_code: selection_type_code,
            } as ProductSelectionTypeRestDto;

            await CpcCatalogSettingsEndpointsApiService.updateProductSelectionProduct(body, product_code);
        },
    },
    mutations: {
        setCatalogBuildStatus(state: any, catalog_build_status: CatalogBuildStatusRestDto) {
            state.catalog_build_status = catalog_build_status;
        },
        setProductSelectionBrands(state: any, payload: any) {
            const filter_selection_type_code = payload.filter_selection_type_code as string | undefined;
            const body = payload.body as PagedBrandSelectionOverviewRestDto;

            switch (filter_selection_type_code) {
                case SelectionTypeEnum.BASE_SELECTION:
                    state.product_selection_brands_base_selection = body;
                    break;
                case SelectionTypeEnum.INCLUDE:
                    state.product_selection_brands_include = body;
                    break;
                case SelectionTypeEnum.EXCLUDE:
                    state.product_selection_brands_exclude = body;
                    break;
                case undefined:
                    state.product_selection_brands_all = body;
                    break;
                default:
                    throw new Error('Unsupported ProductSelectionBrands to set ' + filter_selection_type_code);
            }
        },
        setProductSelectionTrademarks(state: any, payload: any) {
            const filter_selection_type_code = payload.filter_selection_type_code as string | undefined;
            const body = payload.body as PagedTrademarkSelectionOverviewRestDto;

            switch (filter_selection_type_code) {
                case SelectionTypeEnum.BASE_SELECTION:
                    state.product_selection_trademarks_base_selection = body;
                    break;
                case SelectionTypeEnum.INCLUDE:
                    state.product_selection_trademarks_include = body;
                    break;
                case SelectionTypeEnum.EXCLUDE:
                    state.product_selection_trademarks_exclude = body;
                    break;
                case undefined:
                    state.product_selection_trademarks_all = body;
                    break;
                default:
                    throw new Error('Unsupported ProductSelectionTrademarks to set ' + filter_selection_type_code);
            }
        },
        setProductSelectionProducts(state: any, payload: any) {
            const filter_selection_type_code = payload.filter_selection_type_code as string | undefined;
            const body = payload.body as PagedProductSelectionOverviewRestDto;

            switch (filter_selection_type_code) {
                case SelectionTypeEnum.BASE_SELECTION:
                    state.product_selection_products_base_selection = body;
                    break;
                case SelectionTypeEnum.INCLUDE:
                    state.product_selection_products_include = body;
                    break;
                case SelectionTypeEnum.EXCLUDE:
                    state.product_selection_products_exclude = body;
                    break;
                case undefined:
                    state.product_selection_products_all = body;
                    break;
                default:
                    throw new Error('Unsupported ProductSelectionProducts to set ' + filter_selection_type_code);
            }
        },
        setCurrentBrandPage(state: any, payload: any) {
            const filter_selection_type_code = payload.filter_selection_type_code as string | undefined;
            const page = payload.page as number;

            switch (filter_selection_type_code) {
                case SelectionTypeEnum.BASE_SELECTION:
                    state.current_page_brands_base_selection = page;
                    break;
                case SelectionTypeEnum.INCLUDE:
                    state.current_page_brands_include = page;
                    break;
                case SelectionTypeEnum.EXCLUDE:
                    state.current_page_brands_exclude = page;
                    break;
                case undefined:
                    state.current_page_brands_all = page;
                    break;
                default:
                    throw new Error('Unsupported CurrentBrandPage to set ' + filter_selection_type_code);
            }
        },
        setCurrentTrademarkPage(state: any, payload: any) {
            const filter_selection_type_code = payload.filter_selection_type_code as string | undefined;
            const page = payload.page as number;

            switch (filter_selection_type_code) {
                case SelectionTypeEnum.BASE_SELECTION:
                    state.current_page_trademarks_base_selection = page;
                    break;
                case SelectionTypeEnum.INCLUDE:
                    state.current_page_trademarks_include = page;
                    break;
                case SelectionTypeEnum.EXCLUDE:
                    state.current_page_trademarks_exclude = page;
                    break;
                case undefined:
                    state.current_page_trademarks_all = page;
                    break;
                default:
                    throw new Error('Unsupported CurrentTrademarkPage to set ' + filter_selection_type_code);
            }
        },
        setCurrentProductPage(state: any, payload: any) {
            const filter_selection_type_code = payload.filter_selection_type_code as string | undefined;
            const page = payload.page as number;

            switch (filter_selection_type_code) {
                case SelectionTypeEnum.BASE_SELECTION:
                    state.current_page_products_base_selection = page;
                    break;
                case SelectionTypeEnum.INCLUDE:
                    state.current_page_products_include = page;
                    break;
                case SelectionTypeEnum.EXCLUDE:
                    state.current_page_products_exclude = page;
                    break;
                case undefined:
                    state.current_page_products_all = page;
                    break;
                default:
                    throw new Error('Unsupported CurrentProductPage to set ' + filter_selection_type_code);
            }
        },
    },
    getters: {
        getCatalogBuildStatus(state: any): CatalogBuildStatusRestDto {
            return state.catalog_build_status;
        },
        getProductSelectionBrands: (state: any) => (filter_selection_type_code: string | undefined): PagedBrandSelectionOverviewRestDto => {
            switch (filter_selection_type_code) {
                case SelectionTypeEnum.BASE_SELECTION:
                    return state.product_selection_brands_base_selection;
                case SelectionTypeEnum.INCLUDE:
                    return state.product_selection_brands_include;
                case SelectionTypeEnum.EXCLUDE:
                    return state.product_selection_brands_exclude;
                case undefined:
                    return state.product_selection_brands_all;
                default:
                    throw new Error('Unsupported ProductSelectionBrands to get ' + filter_selection_type_code);
            }
        },
        getProductSelectionTrademarks: (state: any) => (filter_selection_type_code: string | undefined): PagedTrademarkSelectionOverviewRestDto => {
            switch (filter_selection_type_code) {
                case SelectionTypeEnum.BASE_SELECTION:
                    return state.product_selection_trademarks_base_selection;
                case SelectionTypeEnum.INCLUDE:
                    return state.product_selection_trademarks_include;
                case SelectionTypeEnum.EXCLUDE:
                    return state.product_selection_trademarks_exclude;
                case undefined:
                    return state.product_selection_trademarks_all;
                default:
                    throw new Error('Unsupported ProductSelectionTrademarks to get ' + filter_selection_type_code);
            }
        },
        getProductSelectionProducts: (state: any) => (filter_selection_type_code: string | undefined): PagedProductSelectionOverviewRestDto => {
            switch (filter_selection_type_code) {
                case SelectionTypeEnum.BASE_SELECTION:
                    return state.product_selection_products_base_selection;
                case SelectionTypeEnum.INCLUDE:
                    return state.product_selection_products_include;
                case SelectionTypeEnum.EXCLUDE:
                    return state.product_selection_products_exclude;
                case undefined:
                    return state.product_selection_products_all;
                default:
                    throw new Error('Unsupported ProductSelectionProducts to get ' + filter_selection_type_code);
            }
        },
        getCurrentBrandPage: (state: any) => (filter_selection_type_code: string | undefined): number => {
            switch (filter_selection_type_code) {
                case SelectionTypeEnum.BASE_SELECTION:
                    return state.current_page_brands_base_selection;
                case SelectionTypeEnum.INCLUDE:
                    return state.current_page_brands_include;
                case SelectionTypeEnum.EXCLUDE:
                    return state.current_page_brands_exclude;
                case undefined:
                    return state.current_page_brands_all;
                default:
                    throw new Error('Unsupported CurrentBrandPage to get ' + filter_selection_type_code);
            }
        },
        getCurrentTrademarkPage: (state: any) => (filter_selection_type_code: string | undefined): number => {
            switch (filter_selection_type_code) {
                case SelectionTypeEnum.BASE_SELECTION:
                    return state.current_page_trademarks_base_selection;
                case SelectionTypeEnum.INCLUDE:
                    return state.current_page_trademarks_include;
                case SelectionTypeEnum.EXCLUDE:
                    return state.current_page_trademarks_exclude;
                case undefined:
                    return state.current_page_trademarks_all;
                default:
                    throw new Error('Unsupported CurrentTrademarkPage to get ' + filter_selection_type_code);
            }
        },
        getCurrentProductPage: (state: any) => (filter_selection_type_code: string | undefined): number => {
            switch (filter_selection_type_code) {
                case SelectionTypeEnum.BASE_SELECTION:
                    return state.current_page_products_base_selection;
                case SelectionTypeEnum.INCLUDE:
                    return state.current_page_products_include;
                case SelectionTypeEnum.EXCLUDE:
                    return state.current_page_products_exclude;
                case undefined:
                    return state.current_page_products_all;
                default:
                    throw new Error('Unsupported CurrentProductPage to get ' + filter_selection_type_code);
            }
        },
    },
}
