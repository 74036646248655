import store from "@/store";
import {ProductFeatureEndpointsApiService} from "@/main";
import {ProductFeatureAuthorizationRestDto} from "@/api/pharma-license-license-info/models";

export default {
    namespaced: true,
    state() {
        return {
            product_feature_authorization_overview: [] as ProductFeatureAuthorizationRestDto[],
        }
    },
    actions: {
        async searchProductFeatureAuthorizationOverview() {
            await ProductFeatureEndpointsApiService.findProductFeatureAuthorization().then(response => {
                const payloadResponse = {
                    body: response.data,
                };
                store.commit('license_info/setProductFeatureAuthorizationOverview', payloadResponse);
            });
        },
    },
    mutations: {
        setProductFeatureAuthorizationOverview(state: any, payload: any) {
            store.state.license_info.product_feature_authorization_overview = payload.body;
        },
    },
    getters: {
        getProductFeatureAuthorizationOverview(): ProductFeatureAuthorizationRestDto[] {
            return store.state.license_info.product_feature_authorization_overview;
        },
    }
}