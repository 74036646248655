
import {defineComponent} from 'vue'

export default defineComponent({
  name: "PimProductsDetailCategories",
  emits: ["removeDpCat"],
  props: {
    categoryCode: {
      type: String,
      required: false
    },
    categoryPath: {
      type: String,
      required: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    categoryTree(): string[] {
      if (this.categoryPath) {
        return this.categoryPath.split('|');
      }

      return [];
    }
  },
  methods: {
    removeDpCat(): void {
      this.$emit('removeDpCat', this.categoryCode);
    },

  },
});
