import {CodeDescriptionRestDto, SnippetContextRestDto, SnippetInSlotOverviewRestDto} from "@/api/pharma-cms-snippet/models";
import {StatusEnum} from "@/api/enums/status-enum";
import {SlotPositionEnum} from "@/api/enums/slot-position-enum";

export class SnippetInSlotOverviewRestDtoModel implements SnippetInSlotOverviewRestDto {
    id: string;
    page_alias?: number|null;
    page_id?: string|null;
    created: string;
    context?: SnippetContextRestDto|null;
    slot_position: CodeDescriptionRestDto;
    status: CodeDescriptionRestDto;
    name: string;

    constructor(id: string,
                slot_position: CodeDescriptionRestDto,
                status: CodeDescriptionRestDto,
                name: string,
                created: string,
                page_alias?: number|null,
                page_id?: string|null,
                context?: SnippetContextRestDto|null) {
        this.id = id;
        this.slot_position = slot_position;
        this.status = status;
        this.name = name;
        this.page_alias = page_alias;
        this.page_id = page_id;
        this.created = created;
        this.context = context;
    }

    public static createWithDefaults(): SnippetInSlotOverviewRestDtoModel {
        return new this('' ,
            {code: SlotPositionEnum.MAIN_CATEGORIES_TOP, description: 'Hoofdcategorieën bovenaan'},
            {code: StatusEnum.PUBLISHED, description: 'Gepubliceerd'},
            '',
            '', /* TODO: is this a required property?  What to fill in? */
            null,
            null,
            null
            );
    }
}
