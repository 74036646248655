import {
    WysiwygCustomDesignTemplateRestDto
} from "@/api/pharma-cms-content-page/models";

export class WysiwygCustomDesignTemplateRestDtoModel implements WysiwygCustomDesignTemplateRestDto {
    id?: string | null;
    code?: string | null;
    description?: string | null;


    constructor(id?: string | null, code?: string | null, description?: string | null) {
        this.id = id ?? undefined;
        this.code = code ?? undefined;
        this.description = description ?? undefined;
    }
}
