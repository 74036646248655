
import {defineComponent} from "vue";
import {useToast} from "vue-toastification";
import {mapActions} from "vuex";
import AlertError from "@/components/UI/Bootstrap/Alert/AlertError.vue";
import {Exception} from "@/api/interfaces";
import InputSelect from "@/components/UI/InputSelect.vue";
import {CodeDescriptionRestDtoModel} from "@/models/CodeDescriptionRestDtoModel";
import {CustomerOverviewRestDto} from "@/api/pharma-customer/models";
import InputText from "@/components/UI/InputText.vue";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import {mapActions as mapActionsP, mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import {CustomerCriteriaRestDtoModel} from "@/models/api/pharma-customer/CustomerCriteriaRestDtoModel";

export default defineComponent({
  name: "ContentPageCopy",
  components: {InputText, InputSelect, AlertError},
  props: {
    pageId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      exceptions: [] as Exception[],
      toaster: useToast(),

      customerCodeCopyTo: null as string | null,
    }
  },
  computed: {
    ...mapState(useCustomerStore, ["getCriteria", "getCustomersWithCriteria"]),
    customerOptions(): CodeDescriptionRestDtoModel[] {
      const customerOverview = this.getCustomersWithCriteria as CustomerOverviewRestDto[];
      const customerOverviewCodeDescription = [] as CodeDescriptionRestDtoModel[];

      customerOverview.forEach((customer: CustomerOverviewRestDto) => {
        if (customer?.customer_code) {
          customerOverviewCodeDescription.push(
            new CodeDescriptionRestDtoModel(customer.customer_code, customer.name)
          );
        }
      });
      // console.log("customerOverviewCodeDescription: ", customerOverviewCodeDescription)

      return customerOverviewCodeDescription;
    },
    customerCriteria(): CustomerCriteriaRestDtoModel {
      return this.getCriteria.customer;
    }
  },
  methods: {
    ...mapActions("cms_content_page", ["copyContentPage"]),
    ...mapActionsP(useCustomerStore, ["searchCustomersWithCriteria"]),
    async copyContentPageToCustomer(): Promise<void> {
      this.exceptions = [];

      try {
        // console.log('copyContentPageToCustomer: ', this.customerCodeCopyTo)
        await this.copyContentPage({
          id: this.pageId,
          to_customer_code: this.customerCodeCopyTo
        });

        await handleSavedSuccessfully('Pagina succesvol gekopieerd naar klant ' + this.customerOptions
          .find((customer: CodeDescriptionRestDtoModel) => customer.code === this.customerCodeCopyTo)?.description
        );
      } catch (exceptions: any) {
        this.exceptions = exceptions;
        console.log(exceptions);
      }
    },
    async searchCustomers(): Promise<void> {
      this.exceptions = [];

      try {
        await this.searchCustomersWithCriteria();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
  }
});
