/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */

import {BackgroundImageAttachmentEnum} from "@/api/enums/background-image-attachment-enum";
import {BackgroundImageHorizontalPositionEnum} from "@/api/enums/background-image-horizontal-position-enum";
import {BackgroundImageRepeatEnum} from "@/api/enums/background-image-repeat-enum";
import {BackgroundImageSizeEnum} from "@/api/enums/background-image-size-enum";
import {BackgroundImageVerticalPositionEnum} from "@/api/enums/background-image-vertical-position-enum";

/**
 * @export
 * @interface BackgroundImageRestDto
 */
export interface BackgroundImageRestDto {
    /**
     * The document key of the image;
     * @type {string}
     * @memberof BackgroundImageRestDto
     */
    image_key: string;

    /**
     * The document key of the image;
     * @type {string}
     * @memberof BackgroundImageRestDto
     */
    image_url: string;

    /**
     * Determines the horizontal object-position for the background image.
     * @type {BackgroundImageHorizontalPositionEnum}
     * @memberof BackgroundImageRestDto
     */
    horizontal_position_code: BackgroundImageHorizontalPositionEnum;

    /**
     * Determines the vertical object-position for the background image.
     * @type {BackgroundImageVerticalPositionEnum}
     * @memberof BackgroundImageRestDto
     */
    vertical_position_code: BackgroundImageVerticalPositionEnum;

    /**
     * Determines the repitition of the background image.
     * @type {BackgroundImageRepeatEnum}
     * @memberof BackgroundImageRestDto
     */
    repeat_code: BackgroundImageRepeatEnum;

    /**
     * Determines the size of the background image.
     * @type {BackgroundImageSizeEnum}
     * @memberof BackgroundImageRestDto
     */
    size_code: BackgroundImageSizeEnum;

    /**
     * Determines the scroll behavior of the background image.
     * @type {BackgroundImageAttachmentEnum}
     * @memberof BackgroundImageRestDto
     */
    attachment_code: BackgroundImageAttachmentEnum;

}

/**
 * @export
 * @function cloneBackgroundImageRestDtoMetaData
 */
export function cloneBackgroundImageRestDtoMetaData(dto: BackgroundImageRestDto, source: BackgroundImageRestDto): void {
    dto.image_key = source.image_key;
    dto.image_url = source.image_url;
    dto.horizontal_position_code = source.horizontal_position_code;
    dto.vertical_position_code = source.vertical_position_code;
    dto.repeat_code = source.repeat_code;
    dto.size_code = source.size_code;
    dto.attachment_code = source.attachment_code;
}
