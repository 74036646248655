
import {ContentBlockTypesEnum} from "@/helpers/functions/enum";
import SimpleTextCbWithFallback from "@/components/layouts/content-blocks/simple-content-blocks/SimpleTextCbWithFallback.vue";

export default {
  name: "GeneralTerms",
  components: {
    SimpleTextCbWithFallback,
  },
  data() {
    return {
      cbType: ContentBlockTypesEnum.GENERAL_TERMS,
    }
  },
}
