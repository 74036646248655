
import {defineComponent} from "vue";
import {Tooltip} from "bootstrap";

export default defineComponent({
  name: "MenusOverviewItemReferenceEdit",
  props: {
    pageId: {
      type: String,
      required: false,
    },
    isDeleted: {
      type: Boolean,
      required: true
    },
    isNotPublished: {
      type: Boolean,
      required: true
    }
  },
  mounted() {
    document.querySelectorAll('#MenusOverviewItemReferenceEdit [data-bs-toggle="tooltip"]').forEach(el => {
      new Tooltip(el as Element);
    });
  },
});
