
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {Exception} from "@/api/interfaces";
import {arrayHasContent} from "@/helpers/functions/arrays";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-page/models";
import CpsOverviewItem from "@/components/layouts/content-pages/overview/CpsOverviewItem.vue";
import BaseContentPageLibraryAvailable from "@/components/UI/BaseContentPageLibraryAvailable.vue";
import {MoveActionEnum} from "@/models/enum/MoveActionEnum";
import {LibraryOverviewRestDto} from "@/api/pharma-cms-content-block/models";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {LibraryStructureTypeEnum} from "@/models/enum/LibraryStructureTypeEnum";
import {AbilityContext} from "@/context/AbilityContext";
import {FullContentPageRestDtoModel} from "@/models/api/pharma-cms-content-page/FullContentPageRestDtoModel";
import {ContentBlockLinkRestService} from "@/services/rest/cms-content-block/ContentBlockLinkRestService";
import {ContentBlockLibraryRestService} from "@/services/rest/cms-content-block/ContentBlockLibraryRestService";
import {ValueEnum} from "@/api/enums/value-enum";
import {ContentPageRestService} from "@/services/rest/cms-content-page/ContentPageRestService";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {UIStateDto} from "@/dtos/UIStateDto";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import {mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import {ContentPageCriteriaModel} from "@/models/criteria/ContentPageCriteriaModel";
import AlertError from "@/components/UI/Bootstrap/Alert/AlertError.vue";

export default defineComponent({
  name: "CpsOverview",
  components: {AlertError, AlertError2, BaseTitle, BaseContentPageLibraryAvailable, CpsOverviewItem},
  props: {
    libCode: {
      type: String,
      required: false,
      default: null
    },
    libPageTypeCode: {
      type: String,
      required: false,
      default: null
    }
  },
  data: () => ({
    exceptions: [] as Exception[],
    isReady: false as boolean,

    contentPageUI: UIStateDto.createWithDefaults(),
  }),
  watch: {
    contentPageCriteria: {
      handler() {
        this.reloadContent();
      },
      deep: true
    },
    libCode() {
      this.reloadContent();
    },
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapState(useCustomerStore, ["getCriteria"]),
    ...mapGetters('cms_content_block', ['getContentBlockLibsForType']),
    ...mapGetters('cms_content_page', ["getContentPageOverview", "getPageTypeEnums", "getContentPageLibraryName"]),
    AbilityContext() {
      return AbilityContext
    },
    LibraryStructureTypeEnum() {
      return LibraryStructureTypeEnum
    },
    contentBlockLibsForType(): LibraryOverviewRestDto[] {
      return (this.isAuthorizedForCopyLibrary) ? this.getContentBlockLibsForType(this.libType) : [];
    },
    contentPageCriteria(): ContentPageCriteriaModel {
      return this.getCriteria.content_page;
    },
    fullContentPages(): FullContentPageRestDtoModel[] {
      return this.getContentPageOverview.results;
    },
    libType(): string | null {
      if (this.isLibrary) {
        return this.libPageTypeCode;
      } else {
        return this.contentPageCriteria.filter_type_code;
      }
    },
    title(): string {
      if (this.isLibrary) {
        return `${this.$t('websiteContent')} ${this.$t('library').toLowerCase()} (${this.getContentPageLibraryName(this.libCode)})`;
      }

      return this.$t('websiteContent');
    },
    hasContentLib(): boolean {
      if (this.isAuthorizedForCopyLibrary) {
        return arrayHasContent(this.contentBlockLibsForType);
      } else {
        return false;
      }
    },
    hasContentPages(): boolean {
      return arrayHasContent(this.fullContentPages);
    },
    isAuthorizedForCopyLibrary(): boolean {
      return (!this.isLibrary && !!this.contentPageCriteria.filter_type_code) || (this.isLibrary && AbilityContext.isAuthorizedForFeature('MGMT_CUSTOMER_LIB_CONTENT'));
    },
    isLibrary(): boolean {
      return !!this.libCode;
    },
    pageTypeEnums(): CodeDescriptionRestDto[] {
      return [
        {
          code: null,
          description: this.$t('all'),
        },
        ...this.getPageTypeEnums
      ];
    }
  },
  methods: {
    ...mapActions("cms_content_block", ["searchContentBlockLibsForType"]),
    ...mapActions("cms_content_page", [
      "copyContentPage",
      "deleteContentPage",
      "moveContentPage",
      "searchContentPageOverview",
      "searchPageTypeEnums",
      "updateContentPageType"
    ]),
    async reloadContent(): Promise<void> {
      this.isReady = false;
      this.clearExceptions();

      try {
        await Promise.all([
          this.searchContentPageOverview({
            filter_type_code: (!this.isLibrary) ? this.contentPageCriteria.filter_type_code : null,
            filter_library_code: (this.isLibrary) ? this.libCode : null,
          }),
          this.searchPageTypeEnums(this.isLibrary),
          this.isAuthorizedForCopyLibrary ? this.searchContentBlockLibsForType({type: this.libType}) : null
        ]);
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }

      window.scrollTo(0, 0);
      this.isReady = true;
    },
    async copyLibCp(page_id: string): Promise<void> {
      this.clearExceptions();

      try {
        if (this.isLibrary) {
          await ContentBlockLibraryRestService.getInstance()
            .createLibContentBlockFrom(this.libCode, page_id);
        } else {
          await ContentBlockLinkRestService.getInstance()
            .createContentBlockLink(page_id, this.contentPageCriteria.filter_type_code);
        }

        await this.afterSave(this.$t('copiedSuccessfully'));
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.contentPageUI as UIStateDto))
          .catch(exceptions, true);
      }
    },
    async copyContent(page_id: string): Promise<void> {
      this.isReady = false;
      this.clearExceptions();

      try {
        await this.copyContentPage({id: page_id});

        await this.afterSave(this.$t('copiedSuccessfully'));
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }
    },
    async deleteContent(id: string): Promise<void> {
      // todo add delete modal
      const answer = window.confirm(this.$t('deleteConfirmation'));

      if (answer) {
        this.isReady = false;
        this.exceptions = [];
        this.contentPageUI.clearError();

        try {
          await this.deleteContentPage(id);

          await this.afterSave(this.$t('deletedSuccessfully'));
        } catch (exceptions: any) {
          this.setExceptions(exceptions);
        }
      }
    },
    async moveContent(id: string, moveAction: MoveActionEnum): Promise<void> {
      this.isReady = false;
      this.clearExceptions();

      try {
        await this.moveContentPage({
          page_id: id,
          move_action: moveAction
        });

        await this.afterSave(this.$t('movedSuccessfully'));
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }
    },
    async updateType(page_id: string, page_type: string): Promise<void> {
      this.clearExceptions();

      try {
        await this.updateContentPageType({
          id: page_id,
          page_type: page_type
        });

        await this.afterSave();
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }
    },
    async updateIsPublishedOnHome(id: string, isPublishedOnHome: boolean): Promise<void> {
      this.clearExceptions();

      try {
        // console.log("updateIsPublishedOnHome", id, isPublishedOnHome);
        await ContentPageRestService.getInstance().updateIsPublishedOnHome(isPublishedOnHome, id);

        await this.afterSave();
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.contentPageUI as UIStateDto))
          .catch(exceptions, true);
      }
    },
    async updateStatus(id: string, status: ValueEnum): Promise<void> {
      this.clearExceptions();

      try {
        // console.log("updateStatus", id, status);
        await ContentPageRestService.getInstance().updatePageStatusCode(status, id);

        await this.afterSave();
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.contentPageUI as UIStateDto))
          .catch(exceptions, true)
      }
    },
    async afterSave(message?: string): Promise<void> {
      window.scrollTo(0, 0);
      await handleSavedSuccessfully(message);
      await this.reloadContent();
    },
    clearExceptions(): void {
      this.exceptions = [];
      this.contentPageUI.clearError();
    },
    setExceptions(exceptions: Exception[]): void {
      this.isReady = true;
      window.scrollTo(0, 0);
      this.exceptions = exceptions;
    },
  },
});
