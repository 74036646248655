import {
    CmsHeaderContent0RestDto,
} from "@/api/pharma-cms-header/models";

export class CmsHeaderContent0RestDtoModel implements CmsHeaderContent0RestDto {
    is_show_product_search_field: boolean;
    is_show_catalog_buttons: boolean;
    is_sticky: boolean;


    constructor(is_show_product_search_field: boolean, is_show_catalog_buttons: boolean, is_sticky: boolean) {
        this.is_show_product_search_field = is_show_product_search_field;
        this.is_show_catalog_buttons = is_show_catalog_buttons;
        this.is_sticky = is_sticky;
    }

    public static createWithDefaults(): CmsHeaderContent0RestDtoModel {
        return new this(true, true, false);
    }
}
