import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51195a24"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "symbol-preview bg-light bg-opacity-25 w-100 rounded p-3" }
const _hoisted_2 = { class: "mas lh-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["symbol-preview-container d-flex overflow-hidden", `${this.horizClass} ${this.vertClass}`])
    }, [
      _createElementVNode("i", _hoisted_2, _toDisplayString(this.symbol.name_code), 1)
    ], 2)
  ]))
}