/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Order Orca Customer Management API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { OrcaReservationCampaignContentRestDto } from '../models';
import { OrcaReservationCampaignOverviewRestDto } from '../models';
import { OrcaReservationCampaignRestDto } from '../models';
import { OrcaReservationCampaignTypeRestDto } from '../models';
/**
 * OrcaReservationCampaignAPIApi - axios parameter creator
 * @export
 */
export const OrcaReservationCampaignAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for registering a new reservation campaign. 
         * @summary REST.API.ORCA.RC.02 Register new reservation campaign.
         * @param {OrcaReservationCampaignRestDto} body 
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReservationCampaign: async (body: OrcaReservationCampaignRestDto, pharmacy_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createReservationCampaign.');
            }
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling createReservationCampaign.');
            }
            const localVarPath = `/orca/reservation_campaign/v1/pharmacy/{pharmacy_code}/reservation_campaign`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for creating a new reservation campaign for a specific type with default values of the campaign and the campaign content.
         * @summary REST.API.ORCA.RC.07 Create a new reservation campaign with default values.
         * @param {OrcaReservationCampaignTypeRestDto} body 
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReservationCampaignWithDefaults: async (body: OrcaReservationCampaignTypeRestDto, pharmacy_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createReservationCampaignWithDefaults.');
            }
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling createReservationCampaignWithDefaults.');
            }
            const localVarPath = `/orca/reservation_campaign/v1/pharmacy/{pharmacy_code}/reservation_campaign/with_defaults`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for deleting an existing reservation campaign. 
         * @summary REST.API.ORCA.RC.05 Delete reservation campaign.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} id The unique identifier of the reservation campaign.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReservationCampaign: async (pharmacy_code: string, id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling deleteReservationCampaign.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteReservationCampaign.');
            }
            const localVarPath = `/orca/reservation_campaign/v1/pharmacy/{pharmacy_code}/reservation_campaign/{id}`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving an overview of reservation campaigns. 
         * @summary REST.API.ORCA.RC.01 Find reservation campaigns.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findReservationCampaigns: async (pharmacy_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling findReservationCampaigns.');
            }
            const localVarPath = `/orca/reservation_campaign/v1/pharmacy/{pharmacy_code}/reservation_campaign`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving a specific reservation campaign.
         * @summary REST.API.ORCA.RC.06 Get reservation campaign
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} id The unique identifier of this reservation campaign.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReservationCampaign: async (pharmacy_code: string, id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling getReservationCampaign.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getReservationCampaign.');
            }
            const localVarPath = `/orca/reservation_campaign/v1/pharmacy/{pharmacy_code}/reservation_campaign/{id}`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving the content of a reservation campaign. 
         * @summary REST.API.ORCA.RCC.01 Get reservation campaign content.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} id The unique identifier of the reservation campaign.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReservationCampaignContent: async (pharmacy_code: string, id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling getReservationCampaignContent.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getReservationCampaignContent.');
            }
            const localVarPath = `/orca/reservation_campaign/v1/pharmacy/{pharmacy_code}/reservation_campaign/{id}/content/`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for updating or creating content of a reservation campaign. 
         * @summary REST.API.ORCA.RCC.02 Update/create reservation campaign content.
         * @param {OrcaReservationCampaignContentRestDto} body 
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} id The unique identifier of the reservation campaign.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchReservationCampaignContent: async (body: OrcaReservationCampaignContentRestDto, pharmacy_code: string, id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling synchReservationCampaignContent.');
            }
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling synchReservationCampaignContent.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling synchReservationCampaignContent.');
            }
            const localVarPath = `/orca/reservation_campaign/v1/pharmacy/{pharmacy_code}/reservation_campaign/{id}/content/`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for updating an existing reservation campaign. 
         * @summary REST.API.ORCA.RC.04 Update reservation campaign.
         * @param {OrcaReservationCampaignRestDto} body 
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} id The unique identifier of the reservation campaign.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReservationCampaign: async (body: OrcaReservationCampaignRestDto, pharmacy_code: string, id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateReservationCampaign.');
            }
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling updateReservationCampaign.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateReservationCampaign.');
            }
            const localVarPath = `/orca/reservation_campaign/v1/pharmacy/{pharmacy_code}/reservation_campaign/{id}`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrcaReservationCampaignAPIApi - functional programming interface
 * @export
 */
export const OrcaReservationCampaignAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for registering a new reservation campaign. 
         * @summary REST.API.ORCA.RC.02 Register new reservation campaign.
         * @param {OrcaReservationCampaignRestDto} body 
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReservationCampaign(_axios: AxiosInstance, body: OrcaReservationCampaignRestDto, pharmacy_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrcaReservationCampaignAPIApiAxiosParamCreator(configuration).createReservationCampaign(body, pharmacy_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for creating a new reservation campaign for a specific type with default values of the campaign and the campaign content.
         * @summary REST.API.ORCA.RC.07 Create a new reservation campaign with default values.
         * @param {OrcaReservationCampaignTypeRestDto} body 
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReservationCampaignWithDefaults(_axios: AxiosInstance, body: OrcaReservationCampaignTypeRestDto, pharmacy_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrcaReservationCampaignAPIApiAxiosParamCreator(configuration).createReservationCampaignWithDefaults(body, pharmacy_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for deleting an existing reservation campaign. 
         * @summary REST.API.ORCA.RC.05 Delete reservation campaign.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} id The unique identifier of the reservation campaign.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReservationCampaign(_axios: AxiosInstance, pharmacy_code: string, id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrcaReservationCampaignAPIApiAxiosParamCreator(configuration).deleteReservationCampaign(pharmacy_code, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving an overview of reservation campaigns. 
         * @summary REST.API.ORCA.RC.01 Find reservation campaigns.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findReservationCampaigns(_axios: AxiosInstance, pharmacy_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrcaReservationCampaignOverviewRestDto>>> {
            const localVarAxiosArgs = await OrcaReservationCampaignAPIApiAxiosParamCreator(configuration).findReservationCampaigns(pharmacy_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving a specific reservation campaign.
         * @summary REST.API.ORCA.RC.06 Get reservation campaign
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} id The unique identifier of this reservation campaign.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReservationCampaign(_axios: AxiosInstance, pharmacy_code: string, id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrcaReservationCampaignRestDto>> {
            const localVarAxiosArgs = await OrcaReservationCampaignAPIApiAxiosParamCreator(configuration).getReservationCampaign(pharmacy_code, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving the content of a reservation campaign. 
         * @summary REST.API.ORCA.RCC.01 Get reservation campaign content.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} id The unique identifier of the reservation campaign.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReservationCampaignContent(_axios: AxiosInstance, pharmacy_code: string, id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrcaReservationCampaignContentRestDto>> {
            const localVarAxiosArgs = await OrcaReservationCampaignAPIApiAxiosParamCreator(configuration).getReservationCampaignContent(pharmacy_code, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for updating or creating content of a reservation campaign. 
         * @summary REST.API.ORCA.RCC.02 Update/create reservation campaign content.
         * @param {OrcaReservationCampaignContentRestDto} body 
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} id The unique identifier of the reservation campaign.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async synchReservationCampaignContent(_axios: AxiosInstance, body: OrcaReservationCampaignContentRestDto, pharmacy_code: string, id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrcaReservationCampaignAPIApiAxiosParamCreator(configuration).synchReservationCampaignContent(body, pharmacy_code, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for updating an existing reservation campaign. 
         * @summary REST.API.ORCA.RC.04 Update reservation campaign.
         * @param {OrcaReservationCampaignRestDto} body 
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} id The unique identifier of the reservation campaign.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReservationCampaign(_axios: AxiosInstance, body: OrcaReservationCampaignRestDto, pharmacy_code: string, id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrcaReservationCampaignAPIApiAxiosParamCreator(configuration).updateReservationCampaign(body, pharmacy_code, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OrcaReservationCampaignAPIApi - object-oriented interface
 * @export
 * @class OrcaReservationCampaignAPIApi
 * @extends {BaseAPI}
 */
export class OrcaReservationCampaignAPIApi extends BaseAPI {
    /**
     * API endpoint responsible for registering a new reservation campaign. 
     * @summary REST.API.ORCA.RC.02 Register new reservation campaign.
     * @param {OrcaReservationCampaignRestDto} body 
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
     * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrcaReservationCampaignAPIApi
     */
     public createReservationCampaign(body: OrcaReservationCampaignRestDto, pharmacy_code: string, x_dp_customer_code?: string|null, options?: any) {
        return OrcaReservationCampaignAPIApiFp(this.configuration).createReservationCampaign(this.axios, body, pharmacy_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for creating a new reservation campaign for a specific type with default values of the campaign and the campaign content.
     * @summary REST.API.ORCA.RC.07 Create a new reservation campaign with default values.
     * @param {OrcaReservationCampaignTypeRestDto} body 
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
     * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrcaReservationCampaignAPIApi
     */
     public createReservationCampaignWithDefaults(body: OrcaReservationCampaignTypeRestDto, pharmacy_code: string, x_dp_customer_code?: string|null, options?: any) {
        return OrcaReservationCampaignAPIApiFp(this.configuration).createReservationCampaignWithDefaults(this.axios, body, pharmacy_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for deleting an existing reservation campaign. 
     * @summary REST.API.ORCA.RC.05 Delete reservation campaign.
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
     * @param {string} id The unique identifier of the reservation campaign.
     * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrcaReservationCampaignAPIApi
     */
     public deleteReservationCampaign(pharmacy_code: string, id: string, x_dp_customer_code?: string|null, options?: any) {
        return OrcaReservationCampaignAPIApiFp(this.configuration).deleteReservationCampaign(this.axios, pharmacy_code, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving an overview of reservation campaigns. 
     * @summary REST.API.ORCA.RC.01 Find reservation campaigns.
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
     * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrcaReservationCampaignAPIApi
     */
     public findReservationCampaigns(pharmacy_code: string, x_dp_customer_code?: string|null, options?: any) {
        return OrcaReservationCampaignAPIApiFp(this.configuration).findReservationCampaigns(this.axios, pharmacy_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving a specific reservation campaign.
     * @summary REST.API.ORCA.RC.06 Get reservation campaign
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
     * @param {string} id The unique identifier of this reservation campaign.
     * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrcaReservationCampaignAPIApi
     */
     public getReservationCampaign(pharmacy_code: string, id: string, x_dp_customer_code?: string|null, options?: any) {
        return OrcaReservationCampaignAPIApiFp(this.configuration).getReservationCampaign(this.axios, pharmacy_code, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving the content of a reservation campaign. 
     * @summary REST.API.ORCA.RCC.01 Get reservation campaign content.
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
     * @param {string} id The unique identifier of the reservation campaign.
     * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrcaReservationCampaignAPIApi
     */
     public getReservationCampaignContent(pharmacy_code: string, id: string, x_dp_customer_code?: string|null, options?: any) {
        return OrcaReservationCampaignAPIApiFp(this.configuration).getReservationCampaignContent(this.axios, pharmacy_code, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for updating or creating content of a reservation campaign. 
     * @summary REST.API.ORCA.RCC.02 Update/create reservation campaign content.
     * @param {OrcaReservationCampaignContentRestDto} body 
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
     * @param {string} id The unique identifier of the reservation campaign.
     * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrcaReservationCampaignAPIApi
     */
     public synchReservationCampaignContent(body: OrcaReservationCampaignContentRestDto, pharmacy_code: string, id: string, x_dp_customer_code?: string|null, options?: any) {
        return OrcaReservationCampaignAPIApiFp(this.configuration).synchReservationCampaignContent(this.axios, body, pharmacy_code, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for updating an existing reservation campaign. 
     * @summary REST.API.ORCA.RC.04 Update reservation campaign.
     * @param {OrcaReservationCampaignRestDto} body 
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
     * @param {string} id The unique identifier of the reservation campaign.
     * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrcaReservationCampaignAPIApi
     */
     public updateReservationCampaign(body: OrcaReservationCampaignRestDto, pharmacy_code: string, id: string, x_dp_customer_code?: string|null, options?: any) {
        return OrcaReservationCampaignAPIApiFp(this.configuration).updateReservationCampaign(this.axios, body, pharmacy_code, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
