import {
    CodeDescriptionRestDto,
    MultilingualProductCategoryRestDto, Pp2LeafletRestDto, Pp2ProducentInfoRestDto,
    Pp2ProductDetailRestDto,
    Pp2ProductPropertyRestDto, WholesalerAvailabilityRestDto
} from "@/api/pharma-pim-pp2/models";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import {Pp2ProductInfoRestDtoModel} from "@/models/api/pharma-pim-pp2/Pp2ProductInfoRestDtoModel";
import {StatusEnum} from "@/api/enums/status-enum";

export class Pp2ProductDetailRestDtoModel implements Pp2ProductDetailRestDto {
    id: number;
    product_code?: string | null;

    name_i18n?: MultilingualRestDtoModel | null;
    status: CodeDescriptionRestDto;

    product_info: Pp2ProductInfoRestDtoModel;
    producent_detail_info?: Pp2ProducentInfoRestDto | null;
    category_apb?: CodeDescriptionRestDto | null;
    category_apb_legal?: CodeDescriptionRestDto | null;
    category_farmaline?: CodeDescriptionRestDto | null;
    internal_categories?: CodeDescriptionRestDto[] | null;
    categories?: MultilingualProductCategoryRestDto[] | null;
    wholesaler_availabilities?: WholesalerAvailabilityRestDto[] | null;
    leaflet_info?: Pp2LeafletRestDto | null;
    product_properties: Pp2ProductPropertyRestDto[];

    constructor(id: number, status: CodeDescriptionRestDto, product_info: Pp2ProductInfoRestDtoModel, product_properties: Pp2ProductPropertyRestDto[]) {
        this.id = id;
        this.status = status;
        this.product_info = product_info;
        this.product_properties = product_properties;
    }

    public static createWithDefaults(): Pp2ProductDetailRestDtoModel {
        return new this(
            0,
            {code: StatusEnum.PUBLISHED, description: 'Gepubliceerd'},
            Pp2ProductInfoRestDtoModel.createWithDefaults(),
            []
        )
    }
}
