import {RestService} from "@/services/rest/RestService";
import {ExternalSystemSyncAPIApi} from "@/api/pharma-cpc-mgmt";
import {ExternalSystemSyncTaskOverviewRestDto} from "@/api/pharma-cpc-mgmt/models";

export class ExternalSystemSyncRestService extends RestService {

    private apiService: ExternalSystemSyncAPIApi;

    protected constructor() {
        super();

        this.apiService = new ExternalSystemSyncAPIApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cpcUrl);
    }

    public async findExternalSystemSyncTaskHistory(filter_ext_system_type_code: string): Promise<ExternalSystemSyncTaskOverviewRestDto[]> {
        const response = await this.apiService.getExternalSystemSyncTaskHistory(null, filter_ext_system_type_code);

        return (response.data.results) ?? [];
    }
}
