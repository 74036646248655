import {RestService} from "@/services/rest/RestService";
import {UtilityEndpointsApi} from "@/api/pharma-cms-utility";
import {TruncateTextRestDtoModel} from "@/models/TruncateTextRestDtoModel";

export class UtilityRestService extends RestService {
    protected apiService: UtilityEndpointsApi;

    protected constructor() {
        super();

        this.apiService = new UtilityEndpointsApi(this.getDpAxiosInstance(), undefined, this.getConfig().cmsUrl);
    }

    /**
     * Truncate html content
     *
     * @param text
     * @param length
     * @param exact
     * @param trimWidth
     * @param ellipsis
     */
    public async truncateHtml(text: string, length: number, exact: boolean = true, trimWidth: boolean = true, ellipsis: string = '...'): Promise<string> {
        const model = new TruncateTextRestDtoModel(text, length)
        model.html = true;
        model.ellipsis = ellipsis;
        model.exact = exact;
        model.trim_width = trimWidth;

        const response = await this.apiService.truncateText(model);

        return response.data.text;
    }
}
