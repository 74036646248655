import {OpeningHoursForWeekRestDto} from "@/api/pharma-cms-pharmacy/models";
import {OpeningHoursForDayRestDtoModel} from "@/models/OpeningHoursForDayRestDtoModel";

export class OpeningHoursForWeekRestDtoModel implements OpeningHoursForWeekRestDto {
    monday: OpeningHoursForDayRestDtoModel;
    tuesday: OpeningHoursForDayRestDtoModel;
    wednesday: OpeningHoursForDayRestDtoModel;
    thursday: OpeningHoursForDayRestDtoModel;
    friday: OpeningHoursForDayRestDtoModel;
    saturday: OpeningHoursForDayRestDtoModel;
    sunday: OpeningHoursForDayRestDtoModel;

    constructor(monday: OpeningHoursForDayRestDtoModel, tuesday: OpeningHoursForDayRestDtoModel, wednesday: OpeningHoursForDayRestDtoModel, thursday: OpeningHoursForDayRestDtoModel, friday: OpeningHoursForDayRestDtoModel, saturday: OpeningHoursForDayRestDtoModel, sunday: OpeningHoursForDayRestDtoModel) {
        this.monday = monday;
        this.tuesday = tuesday;
        this.wednesday = wednesday;
        this.thursday = thursday;
        this.friday = friday;
        this.saturday = saturday;
        this.sunday = sunday;
    }

    public static createWithDefaults(): OpeningHoursForWeekRestDtoModel {
        return new OpeningHoursForWeekRestDtoModel(
            OpeningHoursForDayRestDtoModel.createWithDefaults(),
            OpeningHoursForDayRestDtoModel.createWithDefaults(),
            OpeningHoursForDayRestDtoModel.createWithDefaults(),
            OpeningHoursForDayRestDtoModel.createWithDefaults(),
            OpeningHoursForDayRestDtoModel.createWithDefaults(),
            OpeningHoursForDayRestDtoModel.createWithDefaults(),
            OpeningHoursForDayRestDtoModel.createWithDefaults()
        );
    }
}
