import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentComponent), _mergeProps({
        "cb-id": this.id,
        "content-library-code": this.libType,
        "is-library": "",
        mode: this.mode,
        "return-route": {name: 'library-overview', params: {cb_structure: this.cbStructure, cb_type: this.cbType, lib_type: this.libType}},
        title: this.title
      }, _ctx.currentProperties), null, 16, ["cb-id", "content-library-code", "mode", "return-route", "title"]))
    ]),
    _: 1
  }))
}