import {MultilingualFieldRestDtoModel} from "@/models/api/pharma-order-enum/MultilingualFieldRestDtoModel";
import {MultilingualMax255FieldRestDto, ProductCustomNameRestDto} from "@/api/pharma-cpc-product-mgmt/models";

export class ProductCustomNameRestDtoModel implements ProductCustomNameRestDto {
    name: MultilingualMax255FieldRestDto | null;

    constructor() {
        this.name = MultilingualFieldRestDtoModel.createWithDefaults();
    }

    public static createWithDefaults(): ProductCustomNameRestDtoModel {
        return new this();
    }
}

