/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Pharmacy API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface TeamRestDto
 */
export interface TeamRestDto {
    /**
     * 
     * @type {MultilingualMax250FieldRestDto}
     * @memberof TeamRestDto
     */
    title?: any;
    /**
     * 
     * @type {MultilingualFieldRestDto}
     * @memberof TeamRestDto
     */
    content?: any;
    /**
     * 
     * @type {MultilingualMax50FieldRestDto}
     * @memberof TeamRestDto
     */
    image_key?: any;
    /**
     * The status of this content block.
     * @type {string}
     * @memberof TeamRestDto
     */
    status_code: TeamRestDtoStatusCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TeamRestDtoStatusCodeEnum {
    PUBLISHED = 'PUBLISHED',
    NOTPUBLISHED = 'NOT_PUBLISHED'
}

