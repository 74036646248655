import {NormalContentBlockModel} from "@/models/api/pharma-cms-content-block/NormalContentBlockModel";
import {
    cloneButtonRestDtoMetaData,
    cloneImageRestDtoMetaData,
    cloneTextWithImageContentRestDtoMetaData,
    ContentBlockMetadataRestDto,
    TextWithImageContentRestDto
} from "@/api/pharma-cms-content-block/models";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import {StatusEnum} from "@/api/enums/status-enum";
import {TitleSizeEnum} from "@/api/enums/title-size-enum";
import {ButtonRestDtoModel} from "@/models/api/pharma-cms-content-block/ButtonRestDtoModel";
import {ImageRestDtoModel} from "@/models/api/pharma-cms-content-block/ImageRestDtoModel";
import {TextWithImageContentBlockLayoutEnum} from "@/api/enums/text-with-image-content-block-layout-enum";
import {VerticalImageShiftEnum} from "@/api/enums/vertical-image-shift-enum";
import {HorizontalImageShiftEnum} from "@/api/enums/horizontal-image-shift-enum";
import {ButtonHorizontalPositionEnum} from "@/api/enums/button-horizontal-position-enum";

export class TextWithImageContentRestDtoModel extends NormalContentBlockModel implements TextWithImageContentRestDto {
    button?: ButtonRestDtoModel;
    button_horizontal_position_code?: ButtonHorizontalPositionEnum | null;
    cb_metadata?: ContentBlockMetadataRestDto;
    content?: MultilingualRestDtoModel;
    horizontal_image_shift_code?: HorizontalImageShiftEnum | null;
    image?: ImageRestDtoModel;
    image_key?: MultilingualRestDtoModel;
    layout_code?: TextWithImageContentBlockLayoutEnum;
    sort_weight?: number;
    status_code: StatusEnum;
    title?: MultilingualRestDtoModel;
    title_size_code?: TitleSizeEnum;
    vertical_image_shift_code?: VerticalImageShiftEnum | null;

    constructor(status_code: StatusEnum) {
        super();
        this.status_code = status_code;
    }

    public static createWithDefaults(): TextWithImageContentRestDtoModel {
        const model = new this(StatusEnum.PUBLISHED);
        model.button_horizontal_position_code = ButtonHorizontalPositionEnum.LEFT;
        model.content = MultilingualRestDtoModel.createWithDefaults();
        model.horizontal_image_shift_code = HorizontalImageShiftEnum.NO_SHIFT;
        model.image = ImageRestDtoModel.createWithDefaults();
        model.image_key = MultilingualRestDtoModel.createWithDefaults();
        model.layout_code = TextWithImageContentBlockLayoutEnum.IMAGE_TO_THE_LEFT_OF_TEXT;
        // model.sort_weight = NormalContentBlockModel.getDefaultSortWeight();
        model.title = MultilingualRestDtoModel.createWithDefaults();
        model.title_size_code = TitleSizeEnum.H2;
        model.vertical_image_shift_code = VerticalImageShiftEnum.NO_SHIFT;

        return model;
    }

    public static cloneFrom(source: TextWithImageContentRestDtoModel): TextWithImageContentRestDtoModel {
        const model = new this(source.status_code);
        model.button = source.button;
        model.button_horizontal_position_code = source.button_horizontal_position_code;
        model.cb_metadata = source.cb_metadata;
        model.content = source.content;
        model.horizontal_image_shift_code = source.horizontal_image_shift_code;
        model.image = source.image;
        model.image_key = source.image_key;
        model.layout_code = source.layout_code;
        model.sort_weight = source.sort_weight;
        model.title = source.title;
        model.title_size_code = source.title_size_code;
        model.vertical_image_shift_code = source.vertical_image_shift_code;

        return model;
    }

    public cloneMetadataFrom(source: TextWithImageContentRestDtoModel): void {
        cloneTextWithImageContentRestDtoMetaData(this, source);

        if (this.button && source.button) {
            cloneButtonRestDtoMetaData(this.button, source.button)
        }

        if (this.image && source.image) {
            cloneImageRestDtoMetaData(this.image, source.image)
        }
    }
}
