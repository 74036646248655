
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {
  PagedBrandSelectionOverviewRestDto,
} from "@/api/pharma-cpc-mgmt/models";
import {formatIntlNumber} from '@/helpers/functions/intl-format';
import ProductSelectionBrandsSelectionItem
  from "@/components/layouts/catalog/product-selection/ProductSelectionBrandsSelectionItem.vue";
import BaseCard from "@/components/UI/Bootstrap/Card/BaseCard.vue";
import BaseCardHeader from "@/components/UI/Bootstrap/Card/BaseCardHeader.vue";
import BaseCardBody from "@/components/UI/Bootstrap/Card/BaseCardBody.vue";
import BasePagination from "@/components/UI/Bootstrap/BasePagination.vue";
import {arrayHasContent} from "@/helpers/functions/arrays";
import {SelectionTypeEnum} from "@/api/enums/selection-type-enum";

export default defineComponent({
  name: "ProductSelectionBrandsSelection",
  components: {BasePagination, BaseCardBody, BaseCardHeader, BaseCard, BrandItem: ProductSelectionBrandsSelectionItem},
  emits: ["updateBrandItem", "updatedPage"],
  props: {
    filterSelectionTypeCode: {
      type: String,
      required: false,
      default: undefined,
    },
    isReady: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('cpc_mgmt', ['getProductSelectionBrands']),
    brandSelectionOverview(): PagedBrandSelectionOverviewRestDto {
      return this.getProductSelectionBrands(this.filterSelectionTypeCode);
    },
    hasBrands(): boolean {
      return arrayHasContent(this.brandSelectionOverview.results);
    },
    itemsCountText(): String {
      const itemLabel = (this.brandSelectionOverview.paging_metadata?.total_elements === 1) ? this.$t('item') : this.$t('items');
      return `${formatIntlNumber(this.brandSelectionOverview.paging_metadata?.total_elements ?? 0, this.$i18n.locale)} ${itemLabel.toLowerCase()}`;
    },
    title(): Object {
      switch (this.filterSelectionTypeCode) {
        case SelectionTypeEnum.BASE_SELECTION:
          return {name: 'followBaseSelection', icon: 'B', btnClass: 'btn-warning'};
        case SelectionTypeEnum.INCLUDE:
          return {name: 'doFollow', icon: '+', btnClass: 'btn-secondary'};
        case SelectionTypeEnum.EXCLUDE:
          return {name: 'doNotFollow', icon: '-', btnClass: 'btn-danger'};
        case undefined:
          return {name: 'allBrands', icon: 'A', btnClass: 'btn-dark'};
        default:
          throw new Error('Unsupported filterSelectionTypeCode to map ' + this.filterSelectionTypeCode);
      }
    },
  },
  methods: {
    ...mapActions('cpc_mgmt', ['setCurrentPage']),
    updatePage(page: number) {
      this.setCurrentPage({
        filter_selection_type_code: this.filterSelectionTypeCode,
        page: page,
        type: 'BRAND'
      })
      this.$emit('updatedPage', page);
    },
    updateBrandItem(brand_code: string, selection_type_code: SelectionTypeEnum) {
      this.$emit('updateBrandItem', brand_code, selection_type_code);
    },
  }
});
