import {FindNormalContentBlockPayload} from "@/models/payload/FindNormalContentBlockPayload";
import {ContentBlockEndpointsApiService} from "@/main";
import {SaveNormalContentBlockPayload} from "@/models/payload/SaveNormalContentBlockPayload";
import {CreateNormalContentBlockPayload} from "@/models/payload/CreateNormalContentBlockPayload";
import {ButtonContentRestDtoModel} from "@/models/api/pharma-cms-content-block/ButtonContentRestDtoModel";

export default {
    namespaced: true,
    actions: {
        async create(context: any, payload: CreateNormalContentBlockPayload): Promise<string> {
            console.log('Button::create()');
            const response = await ContentBlockEndpointsApiService.createNormalContentBlockButton(payload.cb as ButtonContentRestDtoModel, payload.type);

            return response.data.id as string;
        },
        async find(context: any, payload: FindNormalContentBlockPayload): Promise<ButtonContentRestDtoModel> {
            console.log('Button::find()');
            const response = await ContentBlockEndpointsApiService.getNormalContentBlockButton(payload.type, payload.id);

            return response.data as ButtonContentRestDtoModel;
        },
        async save(context: any, payload: SaveNormalContentBlockPayload): Promise<void> {
            console.log('Button::save()');
            await ContentBlockEndpointsApiService.updateNormalContentBlockButton(payload.cb as ButtonContentRestDtoModel, payload.type, payload.id);
        },
    }
};
