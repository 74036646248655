
import {defineComponent} from "vue";

export default defineComponent({
  name: "InputCheckbox",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    errors: {
      type: Object,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    rules: {
      type: String,
    },
  },
  computed: {
    model: {
      get(): boolean {
        return this.modelValue;
      },
      set(value: boolean | undefined) {
        if (typeof value === 'undefined') {
          value = false;
        }
        this.$emit('update:modelValue', value);
      }
    }
  },
});
