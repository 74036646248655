/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Content Block API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { SimpleButtonContentRestDto } from '../models';
import { SimpleDocumentContentRestDto } from '../models';
import { SimpleImageContentRestDto } from '../models';
import { SimpleTextContentRestDto } from '../models';
import { SimpleTextWithImageContentRestDto } from '../models';
import { SimpleTextWithTitleContentRestDto } from '../models';
/**
 * SimpleContentBlockEndpointsApi - axios parameter creator
 * @export
 */
export const SimpleContentBlockEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.CB.09 Delete a simple content block.
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSimpleContentBlock: async (type: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling deleteSimpleContentBlock.');
            }
            const localVarPath = `/content_block/v1/simple_content_block/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.54 Get a simple content block containing a button.
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [fallback_allowed] If true, fallback to the &#x27;standard&#x27; content for the specified type is allowed in case there is NO content found for the specified x-dp-customer-code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimpleContentBlockButton: async (type: string, x_dp_customer_code?: string|null, fallback_allowed?: boolean|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getSimpleContentBlockButton.');
            }
            const localVarPath = `/content_block/v1/simple_content_block/button/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!fallback_allowed || typeof(fallback_allowed) === "boolean") {
                localVarQueryParameter['fallback_allowed'] = fallback_allowed;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.91 Get a simple content block containing a document.
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [fallback_allowed] If true, fallback to the &#x27;standard&#x27; content for the specified type is allowed in case there is NO content found for the specified x-dp-customer-code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimpleContentBlockDocument: async (type: string, x_dp_customer_code?: string|null, fallback_allowed?: boolean|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getSimpleContentBlockDocument.');
            }
            const localVarPath = `/content_block/v1/simple_content_block/document/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!fallback_allowed || typeof(fallback_allowed) === "boolean") {
                localVarQueryParameter['fallback_allowed'] = fallback_allowed;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.04 Get a simple content block containing an image.
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [fallback_allowed] If true, fallback to the &#x27;standard&#x27; content for the specified type is allowed in case there is NO content found for the specified x-dp-customer-code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimpleContentBlockImage: async (type: string, x_dp_customer_code?: string|null, fallback_allowed?: boolean|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getSimpleContentBlockImage.');
            }
            const localVarPath = `/content_block/v1/simple_content_block/image/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!fallback_allowed || typeof(fallback_allowed) === "boolean") {
                localVarQueryParameter['fallback_allowed'] = fallback_allowed;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.02 Get a simple content block containing text.
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [fallback_allowed] If true, fallback to the &#x27;standard&#x27; content for the specified type is allowed in case there is NO content found for the specified x-dp-customer-code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimpleContentBlockText: async (type: string, x_dp_customer_code?: string|null, fallback_allowed?: boolean|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getSimpleContentBlockText.');
            }
            const localVarPath = `/content_block/v1/simple_content_block/text/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!fallback_allowed || typeof(fallback_allowed) === "boolean") {
                localVarQueryParameter['fallback_allowed'] = fallback_allowed;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.08 Get a simple content block containing a text with an image and a button.
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [fallback_allowed] If true, fallback to the &#x27;standard&#x27; content for the specified type is allowed in case there is NO content found for the specified x-dp-customer-code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimpleContentBlockTextWithImage: async (type: string, x_dp_customer_code?: string|null, fallback_allowed?: boolean|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getSimpleContentBlockTextWithImage.');
            }
            const localVarPath = `/content_block/v1/simple_content_block/text_with_image/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!fallback_allowed || typeof(fallback_allowed) === "boolean") {
                localVarQueryParameter['fallback_allowed'] = fallback_allowed;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.06 Get a simple content block containing text with a title.
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [fallback_allowed] If true, fallback to the &#x27;standard&#x27; content for the specified type is allowed in case there is NO content found for the specified x-dp-customer-code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimpleContentBlockTextWithTitle: async (type: string, x_dp_customer_code?: string|null, fallback_allowed?: boolean|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getSimpleContentBlockTextWithTitle.');
            }
            const localVarPath = `/content_block/v1/simple_content_block/text_with_title/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!fallback_allowed || typeof(fallback_allowed) === "boolean") {
                localVarQueryParameter['fallback_allowed'] = fallback_allowed;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.92 Get the standard fallback content of a simple content block containing document.
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStandardSimpleContentBlockDocument: async (type: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getStandardSimpleContentBlockDocument.');
            }
            const localVarPath = `/content_block/v1/simple_content_block/document/{type}/standard`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.29 Get the standard fallback content of a simple content block containing text.
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStandardSimpleContentBlockText: async (type: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getStandardSimpleContentBlockText.');
            }
            const localVarPath = `/content_block/v1/simple_content_block/text/{type}/standard`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.39 Get the standard fallback content of a simple content block containing text with title.
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStandardSimpleContentBlockTextWithTitle: async (type: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getStandardSimpleContentBlockTextWithTitle.');
            }
            const localVarPath = `/content_block/v1/simple_content_block/text_with_title/{type}/standard`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.53 Save a simple content block containing a button.
         * @param {SimpleButtonContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSimpleContentBlockButton: async (body: SimpleButtonContentRestDto, type: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateSimpleContentBlockButton.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updateSimpleContentBlockButton.');
            }
            const localVarPath = `/content_block/v1/simple_content_block/button/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.90 Save a simple content block containing a document.
         * @param {SimpleDocumentContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSimpleContentBlockDocument: async (body: SimpleDocumentContentRestDto, type: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateSimpleContentBlockDocument.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updateSimpleContentBlockDocument.');
            }
            const localVarPath = `/content_block/v1/simple_content_block/document/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.03 Save a simple content block containing an image.
         * @param {SimpleImageContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSimpleContentBlockImage: async (body: SimpleImageContentRestDto, type: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateSimpleContentBlockImage.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updateSimpleContentBlockImage.');
            }
            const localVarPath = `/content_block/v1/simple_content_block/image/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.01 Save a simple content block containing text.
         * @param {SimpleTextContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSimpleContentBlockText: async (body: SimpleTextContentRestDto, type: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateSimpleContentBlockText.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updateSimpleContentBlockText.');
            }
            const localVarPath = `/content_block/v1/simple_content_block/text/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.07 Save a simple content block containing a text with an image and a button.
         * @param {SimpleTextWithImageContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSimpleContentBlockTextWithImage: async (body: SimpleTextWithImageContentRestDto, type: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateSimpleContentBlockTextWithImage.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updateSimpleContentBlockTextWithImage.');
            }
            const localVarPath = `/content_block/v1/simple_content_block/text_with_image/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.05 Save a simple content block containing text with a title.
         * @param {SimpleTextWithTitleContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSimpleContentBlockTextWithTitle: async (body: SimpleTextWithTitleContentRestDto, type: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateSimpleContentBlockTextWithTitle.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updateSimpleContentBlockTextWithTitle.');
            }
            const localVarPath = `/content_block/v1/simple_content_block/text_with_title/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SimpleContentBlockEndpointsApi - functional programming interface
 * @export
 */
export const SimpleContentBlockEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.CB.09 Delete a simple content block.
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSimpleContentBlock(_axios: AxiosInstance, type: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SimpleContentBlockEndpointsApiAxiosParamCreator(configuration).deleteSimpleContentBlock(type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.54 Get a simple content block containing a button.
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [fallback_allowed] If true, fallback to the &#x27;standard&#x27; content for the specified type is allowed in case there is NO content found for the specified x-dp-customer-code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimpleContentBlockButton(_axios: AxiosInstance, type: string, x_dp_customer_code?: string|null, fallback_allowed?: boolean|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleButtonContentRestDto>> {
            const localVarAxiosArgs = await SimpleContentBlockEndpointsApiAxiosParamCreator(configuration).getSimpleContentBlockButton(type, x_dp_customer_code, fallback_allowed, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.91 Get a simple content block containing a document.
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [fallback_allowed] If true, fallback to the &#x27;standard&#x27; content for the specified type is allowed in case there is NO content found for the specified x-dp-customer-code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimpleContentBlockDocument(_axios: AxiosInstance, type: string, x_dp_customer_code?: string|null, fallback_allowed?: boolean|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleDocumentContentRestDto>> {
            const localVarAxiosArgs = await SimpleContentBlockEndpointsApiAxiosParamCreator(configuration).getSimpleContentBlockDocument(type, x_dp_customer_code, fallback_allowed, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.04 Get a simple content block containing an image.
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [fallback_allowed] If true, fallback to the &#x27;standard&#x27; content for the specified type is allowed in case there is NO content found for the specified x-dp-customer-code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimpleContentBlockImage(_axios: AxiosInstance, type: string, x_dp_customer_code?: string|null, fallback_allowed?: boolean|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleImageContentRestDto>> {
            const localVarAxiosArgs = await SimpleContentBlockEndpointsApiAxiosParamCreator(configuration).getSimpleContentBlockImage(type, x_dp_customer_code, fallback_allowed, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.02 Get a simple content block containing text.
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [fallback_allowed] If true, fallback to the &#x27;standard&#x27; content for the specified type is allowed in case there is NO content found for the specified x-dp-customer-code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimpleContentBlockText(_axios: AxiosInstance, type: string, x_dp_customer_code?: string|null, fallback_allowed?: boolean|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleTextContentRestDto>> {
            const localVarAxiosArgs = await SimpleContentBlockEndpointsApiAxiosParamCreator(configuration).getSimpleContentBlockText(type, x_dp_customer_code, fallback_allowed, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.08 Get a simple content block containing a text with an image and a button.
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [fallback_allowed] If true, fallback to the &#x27;standard&#x27; content for the specified type is allowed in case there is NO content found for the specified x-dp-customer-code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimpleContentBlockTextWithImage(_axios: AxiosInstance, type: string, x_dp_customer_code?: string|null, fallback_allowed?: boolean|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleTextWithImageContentRestDto>> {
            const localVarAxiosArgs = await SimpleContentBlockEndpointsApiAxiosParamCreator(configuration).getSimpleContentBlockTextWithImage(type, x_dp_customer_code, fallback_allowed, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.06 Get a simple content block containing text with a title.
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [fallback_allowed] If true, fallback to the &#x27;standard&#x27; content for the specified type is allowed in case there is NO content found for the specified x-dp-customer-code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimpleContentBlockTextWithTitle(_axios: AxiosInstance, type: string, x_dp_customer_code?: string|null, fallback_allowed?: boolean|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleTextWithTitleContentRestDto>> {
            const localVarAxiosArgs = await SimpleContentBlockEndpointsApiAxiosParamCreator(configuration).getSimpleContentBlockTextWithTitle(type, x_dp_customer_code, fallback_allowed, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.92 Get the standard fallback content of a simple content block containing document.
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStandardSimpleContentBlockDocument(_axios: AxiosInstance, type: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleDocumentContentRestDto>> {
            const localVarAxiosArgs = await SimpleContentBlockEndpointsApiAxiosParamCreator(configuration).getStandardSimpleContentBlockDocument(type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.29 Get the standard fallback content of a simple content block containing text.
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStandardSimpleContentBlockText(_axios: AxiosInstance, type: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleTextContentRestDto>> {
            const localVarAxiosArgs = await SimpleContentBlockEndpointsApiAxiosParamCreator(configuration).getStandardSimpleContentBlockText(type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.39 Get the standard fallback content of a simple content block containing text with title.
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStandardSimpleContentBlockTextWithTitle(_axios: AxiosInstance, type: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleTextWithTitleContentRestDto>> {
            const localVarAxiosArgs = await SimpleContentBlockEndpointsApiAxiosParamCreator(configuration).getStandardSimpleContentBlockTextWithTitle(type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.53 Save a simple content block containing a button.
         * @param {SimpleButtonContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSimpleContentBlockButton(_axios: AxiosInstance, body: SimpleButtonContentRestDto, type: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SimpleContentBlockEndpointsApiAxiosParamCreator(configuration).updateSimpleContentBlockButton(body, type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.90 Save a simple content block containing a document.
         * @param {SimpleDocumentContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSimpleContentBlockDocument(_axios: AxiosInstance, body: SimpleDocumentContentRestDto, type: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SimpleContentBlockEndpointsApiAxiosParamCreator(configuration).updateSimpleContentBlockDocument(body, type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.03 Save a simple content block containing an image.
         * @param {SimpleImageContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSimpleContentBlockImage(_axios: AxiosInstance, body: SimpleImageContentRestDto, type: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SimpleContentBlockEndpointsApiAxiosParamCreator(configuration).updateSimpleContentBlockImage(body, type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.01 Save a simple content block containing text.
         * @param {SimpleTextContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSimpleContentBlockText(_axios: AxiosInstance, body: SimpleTextContentRestDto, type: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SimpleContentBlockEndpointsApiAxiosParamCreator(configuration).updateSimpleContentBlockText(body, type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.07 Save a simple content block containing a text with an image and a button.
         * @param {SimpleTextWithImageContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSimpleContentBlockTextWithImage(_axios: AxiosInstance, body: SimpleTextWithImageContentRestDto, type: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SimpleContentBlockEndpointsApiAxiosParamCreator(configuration).updateSimpleContentBlockTextWithImage(body, type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.05 Save a simple content block containing text with a title.
         * @param {SimpleTextWithTitleContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSimpleContentBlockTextWithTitle(_axios: AxiosInstance, body: SimpleTextWithTitleContentRestDto, type: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SimpleContentBlockEndpointsApiAxiosParamCreator(configuration).updateSimpleContentBlockTextWithTitle(body, type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SimpleContentBlockEndpointsApi - object-oriented interface
 * @export
 * @class SimpleContentBlockEndpointsApi
 * @extends {BaseAPI}
 */
export class SimpleContentBlockEndpointsApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.CMS.CB.09 Delete a simple content block.
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimpleContentBlockEndpointsApi
     */
     public deleteSimpleContentBlock(type: string, x_dp_customer_code?: string|null, options?: any) {
        return SimpleContentBlockEndpointsApiFp(this.configuration).deleteSimpleContentBlock(this.axios, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.54 Get a simple content block containing a button.
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {boolean} [fallback_allowed] If true, fallback to the &#x27;standard&#x27; content for the specified type is allowed in case there is NO content found for the specified x-dp-customer-code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimpleContentBlockEndpointsApi
     */
     public getSimpleContentBlockButton(type: string, x_dp_customer_code?: string|null, fallback_allowed?: boolean|null, options?: any) {
        return SimpleContentBlockEndpointsApiFp(this.configuration).getSimpleContentBlockButton(this.axios, type, x_dp_customer_code, fallback_allowed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.91 Get a simple content block containing a document.
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {boolean} [fallback_allowed] If true, fallback to the &#x27;standard&#x27; content for the specified type is allowed in case there is NO content found for the specified x-dp-customer-code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimpleContentBlockEndpointsApi
     */
     public getSimpleContentBlockDocument(type: string, x_dp_customer_code?: string|null, fallback_allowed?: boolean|null, options?: any) {
        return SimpleContentBlockEndpointsApiFp(this.configuration).getSimpleContentBlockDocument(this.axios, type, x_dp_customer_code, fallback_allowed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.04 Get a simple content block containing an image.
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {boolean} [fallback_allowed] If true, fallback to the &#x27;standard&#x27; content for the specified type is allowed in case there is NO content found for the specified x-dp-customer-code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimpleContentBlockEndpointsApi
     */
     public getSimpleContentBlockImage(type: string, x_dp_customer_code?: string|null, fallback_allowed?: boolean|null, options?: any) {
        return SimpleContentBlockEndpointsApiFp(this.configuration).getSimpleContentBlockImage(this.axios, type, x_dp_customer_code, fallback_allowed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.02 Get a simple content block containing text.
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {boolean} [fallback_allowed] If true, fallback to the &#x27;standard&#x27; content for the specified type is allowed in case there is NO content found for the specified x-dp-customer-code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimpleContentBlockEndpointsApi
     */
     public getSimpleContentBlockText(type: string, x_dp_customer_code?: string|null, fallback_allowed?: boolean|null, options?: any) {
        return SimpleContentBlockEndpointsApiFp(this.configuration).getSimpleContentBlockText(this.axios, type, x_dp_customer_code, fallback_allowed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.08 Get a simple content block containing a text with an image and a button.
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {boolean} [fallback_allowed] If true, fallback to the &#x27;standard&#x27; content for the specified type is allowed in case there is NO content found for the specified x-dp-customer-code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimpleContentBlockEndpointsApi
     */
     public getSimpleContentBlockTextWithImage(type: string, x_dp_customer_code?: string|null, fallback_allowed?: boolean|null, options?: any) {
        return SimpleContentBlockEndpointsApiFp(this.configuration).getSimpleContentBlockTextWithImage(this.axios, type, x_dp_customer_code, fallback_allowed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.06 Get a simple content block containing text with a title.
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {boolean} [fallback_allowed] If true, fallback to the &#x27;standard&#x27; content for the specified type is allowed in case there is NO content found for the specified x-dp-customer-code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimpleContentBlockEndpointsApi
     */
     public getSimpleContentBlockTextWithTitle(type: string, x_dp_customer_code?: string|null, fallback_allowed?: boolean|null, options?: any) {
        return SimpleContentBlockEndpointsApiFp(this.configuration).getSimpleContentBlockTextWithTitle(this.axios, type, x_dp_customer_code, fallback_allowed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.92 Get the standard fallback content of a simple content block containing document.
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimpleContentBlockEndpointsApi
     */
     public getStandardSimpleContentBlockDocument(type: string, x_dp_customer_code?: string|null, options?: any) {
        return SimpleContentBlockEndpointsApiFp(this.configuration).getStandardSimpleContentBlockDocument(this.axios, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.29 Get the standard fallback content of a simple content block containing text.
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimpleContentBlockEndpointsApi
     */
     public getStandardSimpleContentBlockText(type: string, x_dp_customer_code?: string|null, options?: any) {
        return SimpleContentBlockEndpointsApiFp(this.configuration).getStandardSimpleContentBlockText(this.axios, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.39 Get the standard fallback content of a simple content block containing text with title.
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimpleContentBlockEndpointsApi
     */
     public getStandardSimpleContentBlockTextWithTitle(type: string, x_dp_customer_code?: string|null, options?: any) {
        return SimpleContentBlockEndpointsApiFp(this.configuration).getStandardSimpleContentBlockTextWithTitle(this.axios, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.53 Save a simple content block containing a button.
     * @param {SimpleButtonContentRestDto} body 
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimpleContentBlockEndpointsApi
     */
     public updateSimpleContentBlockButton(body: SimpleButtonContentRestDto, type: string, x_dp_customer_code?: string|null, options?: any) {
        return SimpleContentBlockEndpointsApiFp(this.configuration).updateSimpleContentBlockButton(this.axios, body, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.90 Save a simple content block containing a document.
     * @param {SimpleDocumentContentRestDto} body 
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimpleContentBlockEndpointsApi
     */
     public updateSimpleContentBlockDocument(body: SimpleDocumentContentRestDto, type: string, x_dp_customer_code?: string|null, options?: any) {
        return SimpleContentBlockEndpointsApiFp(this.configuration).updateSimpleContentBlockDocument(this.axios, body, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.03 Save a simple content block containing an image.
     * @param {SimpleImageContentRestDto} body 
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimpleContentBlockEndpointsApi
     */
     public updateSimpleContentBlockImage(body: SimpleImageContentRestDto, type: string, x_dp_customer_code?: string|null, options?: any) {
        return SimpleContentBlockEndpointsApiFp(this.configuration).updateSimpleContentBlockImage(this.axios, body, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.01 Save a simple content block containing text.
     * @param {SimpleTextContentRestDto} body 
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimpleContentBlockEndpointsApi
     */
     public updateSimpleContentBlockText(body: SimpleTextContentRestDto, type: string, x_dp_customer_code?: string|null, options?: any) {
        return SimpleContentBlockEndpointsApiFp(this.configuration).updateSimpleContentBlockText(this.axios, body, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.07 Save a simple content block containing a text with an image and a button.
     * @param {SimpleTextWithImageContentRestDto} body 
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimpleContentBlockEndpointsApi
     */
     public updateSimpleContentBlockTextWithImage(body: SimpleTextWithImageContentRestDto, type: string, x_dp_customer_code?: string|null, options?: any) {
        return SimpleContentBlockEndpointsApiFp(this.configuration).updateSimpleContentBlockTextWithImage(this.axios, body, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.05 Save a simple content block containing text with a title.
     * @param {SimpleTextWithTitleContentRestDto} body 
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SimpleContentBlockEndpointsApi
     */
     public updateSimpleContentBlockTextWithTitle(body: SimpleTextWithTitleContentRestDto, type: string, x_dp_customer_code?: string|null, options?: any) {
        return SimpleContentBlockEndpointsApiFp(this.configuration).updateSimpleContentBlockTextWithTitle(this.axios, body, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
