
import {defineComponent} from "vue";
import {UIStateDto} from "@/dtos/UIStateDto";
import {HeaderRestService} from "@/services/rest/cms-header/HeaderRestService";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {HeaderContentTypeEnum} from "@/models/enum/HeaderContentTypeEnum";
import {DpExceptions} from "@/error/DpExceptions";
import {Exception} from "@/api/interfaces";
import {CmsHeaderContent1RestDtoModel} from "@/models/api/pharma-cms-header/CmsHeaderContent1RestDtoModel";
import {I18nContext} from "@/context/I18nContext";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import {CmsFooterContent1RestDtoModel} from "@/models/api/pharma-cms-footer/CmsFooterContent1RestDtoModel";
import {CmsFooterContent2RestDtoModel} from "@/models/api/pharma-cms-footer/CmsFooterContent2RestDtoModel";
import {FooterContentTypeEnum} from "@/models/enum/FooterContentTypeEnum";
import {FooterRestService} from "@/services/rest/cms-footer/FooterRestService";
import {CmsHeaderContent0RestDtoModel} from "@/models/api/pharma-cms-header/CmsHeaderContent0RestDtoModel";

export default defineComponent({
  name: "AbstractHeaderFooterDetailContent",
  props: {
    id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data: () => ({
    activeLanguage: I18nContext.getDefaultApiLanguageKey(),

    headerContent: null as null | CmsHeaderContent0RestDtoModel|CmsHeaderContent1RestDtoModel,
    footerContent: null as null | CmsFooterContent1RestDtoModel | CmsFooterContent2RestDtoModel,

    headerFooterContentUI: UIStateDto.createWithDefaults()
  }),
  mounted() {
    this.reloadContent();
  },
  methods: {
    async reloadContent(): Promise<void> {
      this.headerFooterContentUI
        .clearError()
        .setNotReady();

      try {
        await this.beforeReloadContent();

        switch (this.type) {
          case HeaderContentTypeEnum.HEADER_CONTENT_0:
            this.headerContent = await HeaderRestService.getInstance()
              .findHeaderContent0(this.id);
            break;
          case HeaderContentTypeEnum.HEADER_CONTENT_1:
            this.headerContent = await HeaderRestService.getInstance()
              .findHeaderContent1(this.id);
            break;
          case FooterContentTypeEnum.FOOTER_CONTENT_1:
            this.footerContent = await FooterRestService.getInstance()
              .findFooterContent1(this.id);
            break;
          case FooterContentTypeEnum.FOOTER_CONTENT_2:
            this.footerContent = await FooterRestService.getInstance()
              .findFooterContent2(this.id);
            break;
          default:
            throw new DpExceptions([
              {message: 'No matching ContentType found.'} as Exception
            ]);
        }

        await this.afterReloadContent();
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.headerFooterContentUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.headerFooterContentUI as UIStateDto))
          .catch(exceptions, true);
      } finally {
        this.headerFooterContentUI.setReady();
      }
    },
    async beforeReloadContent(): Promise<void> {
    },
    async afterReloadContent(): Promise<void> {
    },
    async submitData(): Promise<void> {
      this.headerFooterContentUI.clearError();

      try {
        await this.beforeSubmitData();

        switch (this.type) {
          case HeaderContentTypeEnum.HEADER_CONTENT_0:
            await HeaderRestService.getInstance()
              .updateHeaderContent0(this.id, this.headerContent);
            break;
          case HeaderContentTypeEnum.HEADER_CONTENT_1:
            await HeaderRestService.getInstance()
              .updateHeaderContent1(this.id, this.headerContent);
            break;
          case FooterContentTypeEnum.FOOTER_CONTENT_1:
            await FooterRestService.getInstance()
              .updateFooterContent1(this.id, this.footerContent);
            break;
          case FooterContentTypeEnum.FOOTER_CONTENT_2:
            await FooterRestService.getInstance()
              .updateFooterContent2(this.id, this.footerContent);
            break;
          default:
            throw new DpExceptions([
              {message: 'No matching ContentType found.'} as Exception
            ]);
        }
        await handleSavedSuccessfully();

        await this.reloadContent();

        await this.afterSubmitData();
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.headerFooterContentUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.headerFooterContentUI as UIStateDto))
          .catch(exceptions, true);
      }
    },
    async beforeSubmitData(): Promise<void> {
    },
    async afterSubmitData(): Promise<void> {
    }
  }
});
