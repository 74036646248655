
import {defineComponent} from "vue";
import {Exception} from "@/api/interfaces";
import {mapGetters} from "vuex";
import {PharmacyRestDto} from "@/api/pharma-cms-pharmacy/models";
import store from "@/store";
import {ABILITY_TOKEN} from "@casl/vue";
import Subtitle from "@/components/UI/Subtitle.vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {inject} from "vue";
import ability from "@/config/ability";
import {useToast} from "vue-toastification";
import I18nInputText from "@/components/UI/I18n/I18nInputText.vue";
import MultilingualSwitcher from "@/components/UI/MultilingualSwitcher.vue";
import {AbilityContext} from "@/context/AbilityContext";
import {I18nContext} from "@/context/I18nContext";
import {mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import AlertError from "@/components/UI/Bootstrap/Alert/AlertError.vue";

export default defineComponent({
  name: "PharmacyData",
  components: {
    AlertError,
    BaseSpinner,
    MultilingualSwitcher,
    I18nInputText,
    BaseTitle,
    Subtitle,
  },
  data: () => ({
    isReady: false as Boolean,
    error: null,
    exceptions: [] as Exception[],
    savedSuccessfully: false as Boolean,
    pharmacy_code: '',

    activeLanguage: I18nContext.getDefaultApiLanguageKey(),

    toaster: useToast(),

    pharmacy_info: null as PharmacyRestDto | null,
  }),
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cms_pharmacy', ['getPharmacyInfo']),
    ...mapState(useCustomerStore, ["getPharmacyCode"]),
    AbilityContext() {
      return AbilityContext
    },
    getHelp(): string {
      if (ability.can('use-feature', 'MGMT_PHARMACY_DATA_SOCIAL_MEDIA')) {
        return this.$t('pharmacyInfoDescription');
      }

      return this.$t('pharmacyInfoDescriptionNoSocialMedia');
    },
  },
  methods: {
    onInvalidSubmit() {
      this.toaster.error(this.$t('saveFailed'));
    },
    submitData() {
      this.savePharmacyInfo();
    },
    async reloadContent() {
      try {
        this.isReady = false;

        this.pharmacy_code = this.getPharmacyCode;
        await this.searchPharmacyInfo();
        this.pharmacy_info = this.getPharmacyInfo;

        this.isReady = true;
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async searchPharmacyInfo() {
      try {
        await store.dispatch('cms_pharmacy/searchPharmacyInfo', this.pharmacy_code);
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async savePharmacyInfo() {
      try {
        const payload = {
          pharmacy_info: this.pharmacy_info,
          pharmacy_code: this.pharmacy_code,
        }

        await store.dispatch('cms_pharmacy/savePharmacyInfo', payload);

        this.toaster.success(this.$t('savedSuccessfully'));
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    setup() {
      const ability = inject(ABILITY_TOKEN);

      return {
        ability
      };
    },
  }
});
