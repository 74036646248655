
import {defineComponent} from "vue";

export default defineComponent({
  name: "BaseAccordionHeader",
  props: {
    id: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  }
});
