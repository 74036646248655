import {
    ContentPageRestDto,
    FullContentPageRestDto,
    TextWithImageAbstractRestDto
} from "@/api/pharma-cms-content-page/models";
import {CodeDescriptionRestDtoModel} from "@/models/CodeDescriptionRestDtoModel";
import {generateUUIDv4} from "@/helpers/functions/string";

export class FullContentPageRestDtoModel implements FullContentPageRestDto {
    id: string;
    alias?: number | null;
    created?: string | null;
    type?: CodeDescriptionRestDtoModel | null;
    cp?: ContentPageRestDto | null;
    cp_abstract?: TextWithImageAbstractRestDto | null;

    constructor(id: string) {
        this.id = id;
    }

    public static createWithDefaults(): FullContentPageRestDtoModel {
        return new this(generateUUIDv4());
    }
}
