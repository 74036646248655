import {ClientGroupManagementEndpointsApiService} from "@/main";
import {CodeDescriptionRestDto} from "@/api/pharma-idm-user/models";

export default {
    namespaced: true,
    state() {
        return {
            client_groups: [] as CodeDescriptionRestDto[],
        }
    },
    actions: {
        async searchClientGroups(context: any) {
            const response = await ClientGroupManagementEndpointsApiService.findClientGroupCodes();

            context.commit('setClientGroups', response.data);
        }
    },
    mutations: {
        setClientGroups(state: any, client_groups: CodeDescriptionRestDto[]) {
            state.client_groups = client_groups;
        }
    },
    getters: {
        getClientGroups(state: any): CodeDescriptionRestDto[] {
            return state.client_groups;
        }
    }
}
