import {NormalContentBlockModel} from "@/models/api/pharma-cms-content-block/NormalContentBlockModel";
import {DpException} from "@/exception/DpException";

export class CreateNormalContentBlockPayload {
    type: string;
    cb: NormalContentBlockModel;

    constructor(type: string, cb: NormalContentBlockModel) {
        this.type = type;
        this.cb = cb;
    }

    public static create(type: string, cb: NormalContentBlockModel): CreateNormalContentBlockPayload {
        return new CreateNormalContentBlockPayload(type, cb);
    }
}
