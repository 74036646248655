import {Context} from "@/patterns/Context";
import {Config} from "@/models/facade/Config";

export class AppContext extends Context {

    public static isDev(): boolean
    {
        return this.getDpEnv() === 'dev';
    }

    public static isProd(): boolean
    {
        return this.getDpEnv() === 'prod';
    }

    public static getDpEnv(): string
    {
        return Config.getInstance().read('dpEnv');
    }
}