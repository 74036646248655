import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bb99bf8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = {
  key: 0,
  class: "collapse",
  id: "collapsible"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "mb-0" }
const _hoisted_7 = {
  key: 0,
  class: "collapse",
  id: "collapsible"
}
const _hoisted_8 = { class: "mt-2 mb-0" }
const _hoisted_9 = { class: "mt-2 mb-0" }
const _hoisted_10 = { class: "mt-2 mb-2" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_alert = _resolveComponent("base-alert")!

  return (_ctx.description)
    ? (_openBlock(), _createBlock(_component_base_alert, {
        key: 0,
        class: "alert-info",
        icon: "info"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            (_ctx.canUseHtml)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("p", {
                    innerHTML: _ctx.description,
                    class: "mb-0"
                  }, null, 8, _hoisted_1),
                  (_ctx.isCollapsible)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createElementVNode("p", {
                          innerHTML: _ctx.description2,
                          class: "mt-2 mb-0"
                        }, null, 8, _hoisted_3),
                        _createElementVNode("p", {
                          innerHTML: _ctx.description3,
                          class: "mt-2 mb-0"
                        }, null, 8, _hoisted_4),
                        _createElementVNode("p", {
                          innerHTML: _ctx.description4,
                          class: "mt-2 mb-2"
                        }, null, 8, _hoisted_5)
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.description), 1),
                  (_ctx.isCollapsible)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.description2), 1),
                        _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.description3), 1),
                        _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.description4), 1)
                      ]))
                    : _createCommentVNode("", true)
                ], 64)),
            (_ctx.isCollapsible)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 2,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpen = !_ctx.isOpen)),
                  class: "mt-3 text-primary",
                  "data-bs-toggle": "collapse",
                  href: "#collapsible",
                  role: "button",
                  "aria-expanded": "false",
                  "aria-controls": "collapsible"
                }, [
                  (!_ctx.isOpen)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.readMoreLabel ? _ctx.readMoreLabel : _ctx.$t('readMore')), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.readLessLabel ? _ctx.readLessLabel : _ctx.$t('readLess')), 1))
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}