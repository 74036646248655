import {RestService} from "@/services/rest/RestService";
import {ContentBlockLibraryEndpointsApi} from "@/api/pharma-cms-content-block";
import {StatusEnum} from "@/api/enums/status-enum";
import {
    BannerContentV2RestDto,
    FullBannerSlotContentRestDto,
    IdResponseRestDto
} from "@/api/pharma-cms-content-block/models";
import {LibraryOverviewRestDtoModel} from "@/models/api/pharma-cms-content-block-library/LibraryOverviewRestDtoModel";
import {DpException} from "@/exception/DpException";

export class ContentBlockLibraryRestService extends RestService {

    private apiService: ContentBlockLibraryEndpointsApi;

    protected constructor() {
        super();

        this.apiService = new ContentBlockLibraryEndpointsApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cmsUrl);
    }

    public async createLibContentBlockFrom(libCode: string, sourceCbId: string): Promise<IdResponseRestDto> {
        const response = await this.apiService.createLibContentBlockFrom(libCode, sourceCbId);

        return response.data;
    }

    public async findContentBlockLibsForType(type: string, filterIsOwner?: boolean, expectExternalName?: boolean): Promise<LibraryOverviewRestDtoModel[]> {
        const response = await this.apiService.findContentBlockLibsForType(type, undefined, filterIsOwner, expectExternalName);

        return response.data;
    }

    public async findLibraryContentBlocks(libCode: string): Promise<any[]> {
        let response = null;
        // todo, zorgen dat er niet hardcoded op libcode gewerkt wordt om lib content op te halen.
        if (libCode === 'LIB_DP_BANNERS_VOOR_DP_SLOTS' || libCode === 'LIB_DP_BANNERS_ALG' || libCode === 'LIB_FARMADA_BANNERS' || libCode.toUpperCase().includes('BANNERS')) {
            response = await this.apiService.getLibraryContentBlocksBanner(libCode);
        } else if (libCode === 'LIB_DP_BANNER_SLOTS') {
            // Deprecated, refer to the corresponding rest service
            // response = await this.apiService.getLibraryContentBlocksBannerSlot(libCode);
            throw [new DpException('Deprecated, refer to the corresponding rest service')];
        } else if (libCode.toUpperCase().includes('LOGO') || libCode.toUpperCase().includes('MAIN_IMG') || libCode.toUpperCase().includes('IMAGES')) {
            response = await this.apiService.getLibraryContentBlocksImage(libCode);
        } else if (libCode.toUpperCase().includes('TEXT_WITH_IMG') || libCode.toUpperCase().includes('TWI')) {
            response = await this.apiService.getLibraryContentBlocksTextWithImage(libCode);
        } else if (libCode.toUpperCase().includes('LIB_DP_WELCOME_TXT') || libCode.toUpperCase().includes('TWT')) {
            response = await this.apiService.getLibraryContentBlocksTextWithTitle(libCode);
        } else {
            throw [new DpException('Unsupported library to search ' + libCode)];
        }

        return response.data;
    }

    public async findLibraryContentBlocksBannerSlot(libCode: string, filterStatusCode?: StatusEnum): Promise<FullBannerSlotContentRestDto[]> {
        const response = await this.apiService.getLibraryContentBlocksBannerSlot(libCode, undefined, filterStatusCode);

        return response.data;
    }

    public async createLibBannerV2(libraryCode: string, dto: BannerContentV2RestDto): Promise<void> {
        await this.apiService.createLibContentBlockBannerV2(dto, libraryCode);
    }
}
