
import {defineComponent} from 'vue'
import {ContentVisibilityRuleForElementRestDto} from "@/api/pharma-cms-content-page/models";
import {ContentVisibilityRuleRestDtoModel} from "@/models/api/pharma-cms-menu/ContentVisibilityRuleRestDtoModel";
import VisibilityRule from "@/components/layouts/VisibilityRule.vue";
import {AutoSaveFeedbackStatusEnum} from "@/models/enum/AutoSaveFeedbackStatusEnum";
import AutoSaveFeedback from "@/components/UI/AutoSaveFeedback.vue";
import BaseOffcanvasButtonClose from "@/components/UI/Bootstrap/Offcanvas/BaseOffcanvasButtonClose.vue";
import AlertInfo from "@/components/UI/Bootstrap/Alert/AlertInfo.vue";
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import {ContentBlockInRowRestService} from "@/services/rest/cms-content-page/ContentBlockInRowRestService";
import {ContentVisibilityRuleElementTypeEnum} from "@/models/enum/ContentVisibilityRuleElementTypeEnum";
import {DpException} from "@/exception/DpException";
import {ContentPageRowRestService} from "@/services/rest/cms-content-page/ContentPageRowRestService";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";

export default defineComponent({
  name: "ContentPageElementVisibilityRule",
  emits: ["saved"],
  components: {AlertError2, AlertInfo, BaseOffcanvasButtonClose, AutoSaveFeedback, VisibilityRule},
  props: {
    rowId: {
      type: String,
      required: true
    },
    cbInRowId: {
      type: String,
      required: false
    },
    pageId: {
      type: String,
      required: false
    }
  },
  watch: {
    cbInRowId: {
      handler() {
        if (this.elementType === ContentVisibilityRuleElementTypeEnum.CB_IN_ROW) {
          this.reloadContent();
        }
      },
      immediate: true,
    },
    rowId: {
      handler() {
        if (this.elementType === ContentVisibilityRuleElementTypeEnum.ROW) {
          this.reloadContent();
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      autoSaveFeedbackStatus: AutoSaveFeedbackStatusEnum.NONE as AutoSaveFeedbackStatusEnum,

      visibilityRuleForElement: {
        visibility_rule: null
      } as ContentVisibilityRuleForElementRestDto,
      visibilityRuleForElementUI: UIStateDto.createWithDefaults()
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    elementType(): ContentVisibilityRuleElementTypeEnum {
      if (this.cbInRowId) {
        return ContentVisibilityRuleElementTypeEnum.CB_IN_ROW
      } else if (this.pageId) {
        return ContentVisibilityRuleElementTypeEnum.ROW
      } else {
        throw new DpException('Element props not configured correctly.');
      }
    },
    hasVisibilityRule(): boolean {
      return !!this.visibilityRuleForElement.visibility_rule;
    }
  },
  methods: {
    async reloadContent(): Promise<void> {
      this.visibilityRuleForElementUI
        .clearError()
        .setNotReady();

      try {
        if (this.elementType === ContentVisibilityRuleElementTypeEnum.CB_IN_ROW) {
          this.visibilityRuleForElement = await ContentBlockInRowRestService.getInstance()
            .getContentBlockInRowVisibilityRule(this.rowId, this.cbInRowId);
        } else {
          this.visibilityRuleForElement = await ContentPageRowRestService.getInstance()
            .getContentPageRowVisibilityRule(this.pageId, this.rowId);
        }
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.visibilityRuleForElementUI.setReady();
      }
    },
    async submitData(): Promise<void> {
      this.visibilityRuleForElementUI.clearError();
      this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.BUSY;

      try {
        if (this.elementType === ContentVisibilityRuleElementTypeEnum.CB_IN_ROW) {
          await ContentBlockInRowRestService.getInstance()
            .updateContentBlockInRowVisibilityRule(this.visibilityRuleForElement, this.rowId, this.cbInRowId);
        } else {
          await ContentPageRowRestService.getInstance()
            .updateContentPageRowVisibilityRule(this.visibilityRuleForElement, this.pageId, this.rowId);
        }

        this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.SUCCESS;
        await this.reloadContent();

        await this.$emit('saved');
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);

        this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.FAILED;
      }
    },
    addVisibilityRule(): void {
      if (!this.hasVisibilityRule) {
        this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.UNSAVED_CHANGES;
        this.visibilityRuleForElement.visibility_rule = ContentVisibilityRuleRestDtoModel.createWithDefaults();
      }
    },
    deleteVisibilityRule(): void {
      if (this.hasVisibilityRule) {
        this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.UNSAVED_CHANGES;
        this.visibilityRuleForElement.visibility_rule = null;
      }
    },
    onVisibilityRuleChanged(payload: ContentVisibilityRuleRestDtoModel): void {
      this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.UNSAVED_CHANGES;
      this.visibilityRuleForElement.visibility_rule = payload;
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.visibilityRuleForElementUI as UIStateDto))
        .catch(exceptions, true);
    }
  }
})
