
import {defineComponent} from "vue";
import {Tooltip} from "bootstrap";

export default defineComponent({
  name: "DiscountPricelistSection",
  props: {
    icon: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      isReady: false,
    };
  },
  mounted() {
    document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(el => {
      new Tooltip(el as Element);
    });

    this.isReady = true;
  },
});
