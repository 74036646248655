import {OpeningHoursForDayRestDto} from "@/api/pharma-cms-pharmacy/models";

export class OpeningHoursForDayRestDtoModel implements OpeningHoursForDayRestDto {
    hours1_from?: string;
    hours1_till?: string;
    hours2_from?: string;
    hours2_till?: string;
    hours3_from?: string;
    hours3_till?: string;
    open: boolean;

    constructor(open: boolean) {
        this.open = open;
    }

    public static createWithDefaults(): OpeningHoursForDayRestDtoModel {
        const model = new OpeningHoursForDayRestDtoModel(false);

        model.hours1_from = '';
        model.hours1_till = '';
        model.hours2_from = '';
        model.hours2_till = '';
        model.hours3_from = '';
        model.hours3_till = '';

        return model;
    }
}
