import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LightboxJS = _resolveComponent("LightboxJS")!

  return (_openBlock(), _createBlock(_component_LightboxJS, {
    sources: this.sources,
    class: _normalizeClass(this.class),
    customAttributes: this.customAttributes,
    onOnOpen: _cache[0] || (_cache[0] = ($event: any) => (this.$emit('onOpen'))),
    onOnClose: _cache[1] || (_cache[1] = ($event: any) => (this.$emit('onClose'))),
    onOnInit: _cache[2] || (_cache[2] = ($event: any) => (this.$emit('onInit'))),
    onOnShow: _cache[3] || (_cache[3] = ($event: any) => (this.$emit('onShow')))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["sources", "class", "customAttributes"]))
}