import {ProductCategorizationAPIApi} from "@/api/pharma-cpc-product-mgmt";
import {RestService} from "@/services/rest/RestService";
import {
    CustomerCategorizationActionOverviewResultsRestDto,
    CustomerCategorizationActionRestDto,
    ProductCategoriesForProductRestDto
} from "@/api/pharma-cpc-product-mgmt/models";
import {CustomerContext} from "@/context/CustomerContext";

export class ProductCategorizationRestService extends RestService {
    private productCategorizationApi: ProductCategorizationAPIApi;

    protected constructor() {
        super();

        this.productCategorizationApi = new ProductCategorizationAPIApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cpcUrl);
    }
    public async addProductCategorizationAction(body: CustomerCategorizationActionRestDto, productCode: string): Promise<void> {
        await this.productCategorizationApi.addProductCategorizationAction(body, CustomerContext.getCustomerCode(), productCode);
    }

    public async deleteProductCategorizationAction(productCode: string, actionCode: string, categoryCode: string): Promise<void> {
        await this.productCategorizationApi.deleteProductCategorizationAction(CustomerContext.getCustomerCode(), productCode, actionCode, categoryCode);
    }

    public async findCustomerCategorizationActions(productCode: string): Promise<CustomerCategorizationActionOverviewResultsRestDto> {
        const response =
            await this.productCategorizationApi.findCustomerCategorizationActions(CustomerContext.getCustomerCode(), productCode);

        return response.data;
    }

    public async findMasterProductCategories(productCode: string): Promise<ProductCategoriesForProductRestDto> {
        const response = await this.productCategorizationApi.findMasterProductCategories(productCode);

        return response.data;
    }
}
