
import {defineComponent} from "vue";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import MultilingualSwitcher from "@/components/UI/MultilingualSwitcher.vue";
import I18nInputTextEditor from "@/components/UI/I18n/I18nInputTextEditor.vue";
import AbstractHeaderFooterDetailContent
  from "@/components/layouts/header-footer/AbstractHeaderFooterDetailContent.vue";
import {CmsFooterContent2RestDtoModel} from "@/models/api/pharma-cms-footer/CmsFooterContent2RestDtoModel";

export default defineComponent({
  name: "FooterDetailContent2",
  components: {
    I18nInputTextEditor,
    MultilingualSwitcher,
    BaseSpinner, AlertError2
  },
  extends: AbstractHeaderFooterDetailContent,
  data: () => ({
    footerContent: CmsFooterContent2RestDtoModel.createWithDefaults(),
  })
});
