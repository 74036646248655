import {NormalContentBlockModel} from "@/models/api/pharma-cms-content-block/NormalContentBlockModel";
import {HtmlContentRestDto} from "@/api/pharma-cms-content-block/models";
import {StatusEnum} from "@/api/enums/status-enum";

export class HtmlContentRestDtoModel extends NormalContentBlockModel implements HtmlContentRestDto {
    cb_metadata?: any;
    content: string;
    sort_weight?: number;
    status_code: StatusEnum;

    constructor(content: string, status_code: StatusEnum) {
        super();

        this.content = content;
        this.status_code = status_code;
        // this.sort_weight = NormalContentBlockModel.getDefaultSortWeight();
    }

    public static createWithDefaults(): HtmlContentRestDtoModel {
        return new HtmlContentRestDtoModel('', StatusEnum.PUBLISHED);
    }
}
