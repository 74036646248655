
import {defineComponent} from "vue";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import {UIStateDto} from "@/dtos/UIStateDto";
import {DiscountStepOverviewRestDto} from "@/api/pharma-cpc-mgmt/models";
import {PropType} from "vue/dist/vue";
import {DiscountSchemeRestService} from "@/services/rest/cpc-mgmt/DiscountSchemeRestService";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {DiscountStepRestDtoModel} from "@/models/api/pharma-cpc-mgmt/DiscountStepRestDtoModel";
import {DpException} from "@/exception/DpException";

export default defineComponent({
  name: "StaffDiscountsteps",
  emits: ['stepsUpdated'],
  components: {
    AlertError2,
  },
  data: () => ({
    discountSteps: [] as DiscountStepOverviewRestDto[],
    addStepFrom: null as number | null,
    addStepDiscountValue: null as number | null,
    pageUI: UIStateDto.createWithDefaults(),
  }),
  props: {
    discountSchemeId: {
      type: String as PropType<string>,
      required: true
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {},
  methods: {
    async reloadContent() {
      this.pageUI
        .clearError()
        .setNotReady();

      try {
        if (this.discountSchemeId) {
          const discountStepsResult = await DiscountSchemeRestService.getInstance().findDiscountSteps(this.discountSchemeId);
          if (discountStepsResult && discountStepsResult.results.length > 0) {
            this.discountSteps = discountStepsResult.results;
          } else {
            this.discountSteps = [];
          }
        } else {
          this.discountSteps = [];
        }

      } catch (exceptions: any) {
        this.setExceptions(exceptions);
        window.scrollTo(0, 0);
      } finally {
        this.pageUI.setReady();
      }
    },
    async addDiscountStep() {
      this.pageUI
        .clearError()
        .setNotReady();

      try {
        if (!this.addStepFrom || !this.addStepDiscountValue) {
          throw [new DpException('from_quantity and discount_value are required')];
        }
        const newStep = DiscountStepRestDtoModel.createWithDefaults(this.addStepFrom, this.addStepDiscountValue);
        await DiscountSchemeRestService.getInstance().createDiscountStep(this.discountSchemeId, newStep);
        await this.reloadContent();
        this.$emit('stepsUpdated');
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
        window.scrollTo(0, 0);
      } finally {
        this.pageUI.setReady();
      }
    },
    async deleteDiscountStep(stepId: string) {
      this.pageUI
        .clearError()
        .setNotReady();

      try {
        await DiscountSchemeRestService.getInstance().deleteDiscountStep(this.discountSchemeId, stepId);
        await this.reloadContent();
        this.$emit('stepsUpdated');
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
        window.scrollTo(0, 0);
      } finally {
        this.pageUI.setReady();
      }
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .catch(exceptions, true);
    }
  }
});
