import {ContentVisibilityRuleRestDto, MenuItemRestDto} from "@/api/pharma-cms-menu/models";
import {StatusEnum} from "@/api/enums/status-enum";
import {PageReferenceRestDtoModel} from "@/models/api/pharma-cms-menu/PageReferenceRestDtoModel";

export class MenuItemRestDtoModel implements MenuItemRestDto {
    menu_item_text: string;
    page_reference?: PageReferenceRestDtoModel | null;
    submenu_id?: string | null;
    visibility_rule?: ContentVisibilityRuleRestDto | null;
    status_code?: StatusEnum | null;

    constructor(menu_item_text: string) {
        this.menu_item_text = menu_item_text;
    }

    public static createWithDefaults(): MenuItemRestDtoModel {
        const menuItem = new this('');
        menuItem.page_reference = PageReferenceRestDtoModel.createWithDefaults();
        menuItem.status_code = StatusEnum.PUBLISHED;

        return menuItem;
    }
}
