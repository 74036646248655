/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum OrderFieldEnum {
    SORT_WEIGHT = 'SORT_WEIGHT',
    CREATED = 'CREATED',
    NAME = 'NAME',
    PUBLICATION_DATE = 'PUBLICATION_DATE',
    LAST_MODIFIED_DATE = 'LAST_MODIFIED_DATE',
}