import {MultilingualTextFieldRestDto} from "@/api/pharma-cpc-product-mgmt/models";
import {stringHasContent} from "@/helpers/functions/string";

function emptyMultilingualTextField(): MultilingualTextFieldRestDto {
    return {
        nl: null,
        fr: null,
        en: null,
        de: null,
    } as MultilingualTextFieldRestDto;
}

/*
* parses a MultilingualTextField
* Receives a MultilingualTextFieldRestDto
* Returns a MultilingualTextFieldRestDto
*/
function parseMultilingualTextField(multilingual_text_field: MultilingualTextFieldRestDto): MultilingualTextFieldRestDto {
    multilingual_text_field.nl = stringHasContent(multilingual_text_field.nl) ? multilingual_text_field.nl : null;
    multilingual_text_field.fr = stringHasContent(multilingual_text_field.fr) ? multilingual_text_field.fr : null;
    multilingual_text_field.en = stringHasContent(multilingual_text_field.en) ? multilingual_text_field.en : null;
    multilingual_text_field.de = stringHasContent(multilingual_text_field.de) ? multilingual_text_field.de : null;

    return multilingual_text_field;
}

/*
* checks if a MultilingualTextField is valid
* Receives a MultilingualTextFieldRestDto
* Returns a Boolean
*/
function isValidMultilingualTextField(multilingual_text_field: MultilingualTextFieldRestDto): boolean {
    //todo when multilingual: check if at least 1 field is filled in
    const nl = multilingual_text_field.nl ?? '';
    return nl !== null && nl.length > 0;
}

export {
    emptyMultilingualTextField,
    parseMultilingualTextField,
    isValidMultilingualTextField
};
