
import {defineComponent} from "vue";
import {CodeDescriptionRestDto, CustomerProductPropertyRestDto} from "@/api/pharma-cpc-product-mgmt/models";
import {PropType} from "vue/dist/vue";
import {FullNamedTextFragmentRestDto} from "@/api/pharma-pim-mgmt/models";
import BaseCard from "@/components/UI/Bootstrap/Card/BaseCard.vue";
import BaseCardBody from "@/components/UI/Bootstrap/Card/BaseCardBody.vue";
import BaseCardHeader from "@/components/UI/Bootstrap/Card/BaseCardHeader.vue";
import PimFeedbackPanel from "@/components/layouts/pim/mgmt/PimFeedbackPanel.vue";
import {PimFeedbackRestDtoModel} from "@/models/api/pharma-pim-mgmt/PimFeedbackRestDtoModel";
import {PimMgmtRestService} from "@/services/rest/pim-mgmt/PimMgmtRestService";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {UIStateDto} from "@/dtos/UIStateDto";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {Exception} from "@/api/interfaces";
import InputSelect from "@/components/UI/InputSelect.vue";
import {mapGetters} from "vuex";
import {EnumClassNameCpcEnum} from "@/models/enum/EnumClassNameCpcEnum";
import {
  CustomerProductPropertyRestDtoModel
} from "@/models/api/pharma-cpc-product-mgmt/CustomerProductPropertyRestDtoModel";
import {CatalogProductsRestService} from "@/services/rest/cpc-mgmt/CatalogProductsRestService";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import ConfirmModal from "@/components/UI/ConfirmModal.vue";
import {useToast} from "vue-toastification";
import {Tooltip} from "bootstrap";
import {EnumClassNamePimEnum} from "@/models/enum/EnumClassNamePimEnum";
import {StrategyEnum} from "@/api/enums/strategy-enum";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import {Form as DpForm} from "vee-validate";
import InputTextEditor from "@/components/UI/InputTextEditor.vue";
import {EnumRestService} from "@/services/rest/enum/EnumRestService";

export default defineComponent({
  name: "ProductDetailPimTabTextFragmentCard",
  components: {
    InputTextEditor,
    DpForm,
    InputCheckboxBoolean,
    InputSelect, PimFeedbackPanel, BaseCardHeader, BaseCardBody, BaseCard
  },
  props: {
    productCode: {
      type: String,
      required: true,
    },
    cnkCode: {
      type: Number,
      required: false,
    },
    textFragmentNameCode: {
      type: String,
      required: true,
    },
    namedTextFragments: {
      type: Array as PropType<FullNamedTextFragmentRestDto[]>,
      required: true,
    },
    customerProperty: {
      type: Object as PropType<CustomerProductPropertyRestDto>,
      required: false,
    },
    customerPropertyLanguageCode: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    exceptions: [] as Exception[],
    fragmentUI: UIStateDto.createWithDefaults(),
    toaster: useToast(),
    tooltips: [] as Tooltip[],

    isShowFeedbackForFragmentWithId: null as string | null,

    isShowCustomerEditPanel: false,
    editableCustomerProperty: CustomerProductPropertyRestDtoModel.createWithoutDefaults(),
    customerPropertyToShow: null as CustomerProductPropertyRestDto | null,

    isShowExternalContent: true,
    isShowOwnContent: false,
    propertStrategyOptions: [] as CodeDescriptionRestDto[],
  }),
  mounted() {
    if (this.customerProperty) {
      this.customerPropertyToShow = this.customerProperty;
    }
    this.reloadContent();
  },
  beforeUnmount() {
    this.tooltips.forEach(tt => {
      tt.dispose();
    });
  },
  computed: {
    ...mapGetters('pim_enum', {getEnumByClassnamePIM: "getEnumByClassname"}),
    editCustomerContentModalId(): string {
      return 'modal_' + this.textFragmentNameCode;
    },
    externalShow: {
      get(): boolean {
        // Delegate to a data property to make sure an actual changes is only triggerd once, see set.
        return this.isShowExternalContent;
      },
      set(newValue: boolean) {
        if (this.isShowExternalContent !== newValue) {
          // Delegate to a data property to make sure an actual changes is only triggerd once.
          // It seems that this set method is called multiple times when switching the checkbox
          if (!this.customerPropertyToShow) {
            this.customerPropertyToShow = CustomerProductPropertyRestDtoModel.createWithDefaults(
              this.textFragmentNameCode, this.customerPropertyLanguageCode);
            this.customerPropertyToShow.strategy_code = StrategyEnum.SHOW_EXTERNAL_CONTENT;
          }

          if (this.customerPropertyToShow) {
            if (newValue) {
              if (this.customerPropertyToShow?.strategy_code === StrategyEnum.SHOW_OWN_CONTENT) {
                this.customerPropertyToShow.strategy_code = StrategyEnum.SHOW_BOTH;
              } else if (this.customerPropertyToShow?.strategy_code === StrategyEnum.SHOW_NO_CONTENT) {
                this.customerPropertyToShow.strategy_code = StrategyEnum.SHOW_EXTERNAL_CONTENT;
              }
            } else {
              if (this.customerPropertyToShow?.strategy_code === StrategyEnum.SHOW_EXTERNAL_CONTENT) {
                this.customerPropertyToShow.strategy_code = StrategyEnum.SHOW_NO_CONTENT;
              } else if (this.customerPropertyToShow?.strategy_code === StrategyEnum.SHOW_BOTH) {
                this.customerPropertyToShow.strategy_code = StrategyEnum.SHOW_OWN_CONTENT;
              }
            }
            CatalogProductsRestService.getInstance()
              .syncProductProperty(this.productCode, this.customerPropertyToShow)
            this.reloadContent();
          }
          this.determineShowCheckboxes();
        }
      },
    },
    ownContentShow: {
      get(): boolean {
        console.log('getting isShowOwnContent', this.isShowOwnContent)
        // Delegate to a data property to make sure an actual changes is only triggerd once, see set.
        return this.isShowOwnContent;
      },
      set(newValue: boolean) {
        console.log('setting isShowOwnContent', newValue)
        if (this.isShowOwnContent !== newValue) {
          console.log('actual chaning isShowOwnContent')
          // Delegate to a data property to make sure an actual changes is only triggerd once.
          // It seems that this set method is called multiple times when switching the checkbox
          if (this.customerPropertyToShow) {
            if (newValue) {
              if (this.customerPropertyToShow?.strategy_code === StrategyEnum.SHOW_EXTERNAL_CONTENT) {
                this.customerPropertyToShow.strategy_code = StrategyEnum.SHOW_BOTH;
              } else if (this.customerPropertyToShow?.strategy_code === StrategyEnum.SHOW_NO_CONTENT) {
                this.customerPropertyToShow.strategy_code = StrategyEnum.SHOW_OWN_CONTENT;
              }
            } else {
              if (this.customerPropertyToShow?.strategy_code === StrategyEnum.SHOW_OWN_CONTENT) {
                this.customerPropertyToShow.strategy_code = StrategyEnum.SHOW_NO_CONTENT;
              }
              if (this.customerPropertyToShow?.strategy_code === StrategyEnum.SHOW_BOTH) {
                this.customerPropertyToShow.strategy_code = StrategyEnum.SHOW_EXTERNAL_CONTENT;
              }
            }
            CatalogProductsRestService.getInstance()
              .syncProductProperty(this.productCode, this.customerPropertyToShow)
            this.reloadContent();
          }
          this.determineShowCheckboxes();
        }
      },
    },
    externalContentBadgeClass() {
      let badgeBgColor = 'green';
      if (this.customerPropertyToShow) {
        if (this.customerPropertyToShow.strategy_code === StrategyEnum.SHOW_NO_CONTENT
            || this.customerPropertyToShow.strategy_code === StrategyEnum.SHOW_OWN_CONTENT
        ) {
          badgeBgColor = 'danger';
        }
      }
      return "badge bg-" + badgeBgColor + " text-white mb-2";
    },
    ownContentBadgeClass() {
      let badgeBgColor = 'secondary';
      let textColor = 'black';
      if (this.customerPropertyToShow) {
        if (this.customerPropertyToShow.strategy_code === StrategyEnum.SHOW_BOTH
          || this.customerPropertyToShow.strategy_code === StrategyEnum.SHOW_OWN_CONTENT
        ) {
          badgeBgColor = 'green';
        } else {
          badgeBgColor = 'danger';
        }
        textColor = 'white';
      }
      return "badge bg-" + badgeBgColor + " text-" + textColor + " mb-2";
    },
    namedTextFramgmentType(): CodeDescriptionRestDto | null {
      const foundEnum = this.getEnumByClassnamePIM(EnumClassNamePimEnum.TEXT_FRAGMENT_NAME)
        .find((enumVal: CodeDescriptionRestDto) => enumVal.code === this.textFragmentNameCode);
      if (foundEnum) {
        return foundEnum;
      }
      return null;
    },
  },
  methods: {
    determineShowCheckboxes() {
      if (this.customerPropertyToShow === null) {
        this.isShowExternalContent = true;
      } else {
        this.isShowExternalContent = (this.customerPropertyToShow.strategy_code === StrategyEnum.SHOW_BOTH || this.customerPropertyToShow.strategy_code === StrategyEnum.SHOW_EXTERNAL_CONTENT)
        this.isShowOwnContent = (this.customerPropertyToShow.strategy_code === StrategyEnum.SHOW_BOTH || this.customerPropertyToShow.strategy_code === StrategyEnum.SHOW_OWN_CONTENT)
      }
    },
    async reloadContent() {
      this.fragmentUI
        .clearError()
        .setNotReady();
      try {
        this.propertStrategyOptions = await EnumRestService.getInstance().getCpcEnumValuesByClassname(EnumClassNameCpcEnum.PRODUCT_PROPERTY_STRATEGY);
        if (this.customerPropertyToShow) {
          this.editableCustomerProperty = this.customerPropertyToShow
        } else {
          this.editableCustomerProperty = CustomerProductPropertyRestDtoModel.createWithDefaults(
            this.textFragmentNameCode, this.customerPropertyLanguageCode);
        }
        this.determineShowCheckboxes()
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.fragmentUI.setReady()
      }
    },
    async reloadCustomerPropertyToShow() {
      this.fragmentUI
        .clearError()
        .setNotReady();
      try {
        const customerPropertiesResult = await CatalogProductsRestService.getInstance()
          .findCustomerProductProperties(this.productCode, this.customerPropertyLanguageCode, this.textFragmentNameCode);
        if (customerPropertiesResult.results.length > 0) {
          this.customerPropertyToShow = customerPropertiesResult.results[0];
        } else {
          this.customerPropertyToShow = null;
        }
        this.reloadContent();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.fragmentUI.setReady()
      }
    },
    async submitCustomerContent(): Promise<void> {
      this.fragmentUI
        .clearError();

      try {
        await CatalogProductsRestService.getInstance()
          .syncProductProperty(this.productCode, this.editableCustomerProperty)
        this.closeModal(this.editCustomerContentModalId);
        await this.reloadCustomerPropertyToShow();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }
    },
    confirmDeleteOwnContent(): void {
      const {reveal, onConfirm} = createConfirmDialog(ConfirmModal, {
        title: this.$t('cms.delete'),
        body: this.$t('cms.confirmGeneral'),
        confirmButtonText: this.$t('delete'),
        confirmButtonClass: 'btn-danger',
      });

      onConfirm(async () => {
        await CatalogProductsRestService.getInstance()
          .deleteProductProperty(this.productCode, this.editableCustomerProperty.type_code, this.editableCustomerProperty.language_code);

        this.hideTooltips();
        this.toaster.success(this.$t('cms.successfullyDeleted'));
        this.closeModal(this.editCustomerContentModalId);
        await this.reloadCustomerPropertyToShow();
      });

      reveal();
    },
    async submitPimFeedback(feedback: PimFeedbackRestDtoModel): Promise<void> {
      this.fragmentUI.clearError();

      try {
        await PimMgmtRestService.getInstance().createPimFeedback(this.cnkCode, 'nl', feedback);
        if (feedback.text_fragment_id != null) {
          this.toggleIsShowFeedbackForFragmentWithId(feedback.text_fragment_id);
        }

        await handleSavedSuccessfully();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }
    },
    toggleIsShowFeedbackForFragmentWithId(fragmentId: string): void {
      if (this.isShowFeedbackForFragmentWithId === fragmentId) {
        this.isShowFeedbackForFragmentWithId = null;
      } else {
        this.isShowFeedbackForFragmentWithId = fragmentId;
      }
    },
    async submitPimFeedbackCancel(fragmentId: string | null): Promise<void> {
      if (fragmentId !== null) {
        this.toggleIsShowFeedbackForFragmentWithId(fragmentId);
      }
    },
    hideTooltips(): void {
      this.tooltips.forEach(tt => {
        tt.hide();
      });
    },
    closeModal(modalId: string): void {
      const modal = document.getElementById(modalId);
      const closeButton = modal?.querySelector('[data-bs-dismiss="modal"]');
      if (closeButton) {
        (closeButton as HTMLElement).click();
      }
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.fragmentUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.fragmentUI as UIStateDto))
        .catch(exceptions, true);
    }
  },
});
