
import {defineComponent} from "vue";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import {toInitCapitalize} from "@/helpers/functions/string";
import {AbilityContext} from "@/context/AbilityContext";

export default defineComponent({
  name: "ProductSelectionBaseItem",
  emits: ["update"],
  components: {InputCheckboxBoolean},
  props: {
    productSelectionBase: {
      type: Object,
      required: true
    },
    itemId: {
      type: String,
      required: true
    },
    itemDescriptionKey: {
      type: String,
      required: true
    },
    errors: {
      type: Object
    },
  },
  computed: {
    item: {
      get(): boolean {
        // console.log('getting... ' + this.itemId + ':', this.getProductSelectionBase[this.itemId])
        return this.productSelectionBase[this.itemId];
      },
      set(value: boolean) {
        this.$emit('update', this.itemId, value);
      }
    },
    itemName(): string {
      return this.itemId.replaceAll('_', '-');
    },
    canEditItem(): boolean {
      return AbilityContext.isAuthorizedForFeature('PHARMACY_SW_SYNC_SETTINGS')
        || !(this.itemId.includes('include_pharma_sw_products') || this.itemId.includes('is_filtered') || this.itemId.includes('is_sync'));
    }
  },
  methods: {
    toInitCapitalize
  }
});
