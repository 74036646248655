
import {defineComponent} from 'vue'
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import BaseContainer from "@/components/UI/Bootstrap/BaseContainer.vue";
import {useToast} from "vue-toastification";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import BaseCardBody from "@/components/UI/Bootstrap/Card/BaseCardBody.vue";
import BaseCardHeader from "@/components/UI/Bootstrap/Card/BaseCardHeader.vue";
import BaseCard from "@/components/UI/Bootstrap/Card/BaseCard.vue";
import InputTextEditor from "@/components/UI/InputTextEditor.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import {SimpleTextContentRestDtoModel} from "@/models/api/pharma-cms-simple-content-block/SimpleTextContentRestDtoModel";
import {simpleContentBlockEndpointsApiService} from "@/main";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";

export default defineComponent({
  name: "SimpleTextCbWithFallback",
  components: {
    BaseContainer,
    BaseTitle,
    AlertError2,
    InputTextEditor,
    InputCheckboxBoolean,
    BaseCardBody,
    BaseCardHeader,
    BaseCard,
    BaseSpinner,
  },
  props: {
    cbType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      fallbackModel: SimpleTextContentRestDtoModel.createWithDefaults(),
      detailModel: SimpleTextContentRestDtoModel.createWithDefaults(),

      overrideFallback: false,

      toaster: useToast(),
      pageUI: UIStateDto.createWithDefaults(),
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {},
  methods: {
    async reloadContent(): Promise<void> {
      this.pageUI
        .setNotReady()
        .clearError();

      try {
        this.fallbackModel = (await simpleContentBlockEndpointsApiService.getStandardSimpleContentBlockText(this.cbType)).data as SimpleTextContentRestDtoModel;
        this.detailModel = (await simpleContentBlockEndpointsApiService.getSimpleContentBlockText(this.cbType, undefined, false)).data as SimpleTextContentRestDtoModel;
        if (!this.detailModel.content) {
          this.detailModel = SimpleTextContentRestDtoModel.createWithDefaults();
        }
        if (this.detailModel.content?.nl) {
          this.overrideFallback = true;
        }
      } catch (exceptions: unknown) {
        this.handleExceptions(exceptions);
      } finally {
        this.pageUI.setReady();
      }
    },
    async submitData(): Promise<void> {
      this.pageUI
        .clearError()
        .setNotReady();

      try {
        await this.submitContent();
        await handleSavedSuccessfully();
        await this.reloadContent();
      } catch (exceptions: unknown) {
        this.handleExceptions(exceptions);
      } finally {
        this.pageUI.setReady();
      }
    },
    async submitContent(): Promise<void> {
      if (this.overrideFallback) {
        await simpleContentBlockEndpointsApiService.updateSimpleContentBlockText(this.detailModel, this.cbType);
      } else {
        await simpleContentBlockEndpointsApiService.deleteSimpleContentBlock(this.cbType);
        this.detailModel = SimpleTextContentRestDtoModel.createWithDefaults();
      }
    },
    handleExceptions(exceptions: unknown) {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .catch(exceptions, true);
    }
  }
})
