import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cb_form_button = _resolveComponent("cb-form-button")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_cb_form_button, {
        "cb-type": "EXTERNAL_HOME_PAGE_LINK",
        mode: "edit",
        hasTarget: true,
        "input-type": 'url',
        "is-not-required": "",
        title: (_ctx.$can('use-license', 'PHARMACOLLECT_APB') || _ctx.$can('use-license', 'PHARMACOLLECT_PLUS_APB')) ? _ctx.$t('apbHomepage') : _ctx.$t('pharmacollectHomepage'),
        description: (_ctx.$can('use-license', 'PHARMACOLLECT_APB') || _ctx.$can('use-license', 'PHARMACOLLECT_PLUS_APB')) ? _ctx.$t('descriptionApbHomepage') : _ctx.$t('descriptionPharmacollectHomepage')
      }, null, 8, ["title", "description"])
    ]),
    _: 1
  }))
}