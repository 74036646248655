import {RestService} from "@/services/rest/RestService";
import {CategorizationAPIApi} from "@/api/pharma-pim-category";
import {CnkListRestDto, MoveToRestDto} from "@/api/pharma-pim-category/models";
import {downloadBlobAsFile} from "@/helpers/functions/file";
import {AxiosResponse} from "axios";

export class PimCategorizationRestService extends RestService {

    private apiService: CategorizationAPIApi;

    protected constructor() {
        super();

        this.apiService = new CategorizationAPIApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().pimUrl);
    }

    public async exportDpCategories(filename: string = 'dp_categories.csv'): Promise<void> {
        const response: AxiosResponse<any, any> = await this.apiService.exportDpCategories('text/csv');

        downloadBlobAsFile(response.data as Blob, filename);
    }

    public async exportProductsInDpCategories(filename: string = 'products_in_dp_categories.csv'): Promise<void> {
        const response: AxiosResponse<any, any> = await this.apiService.exportProductsInDpCategories('text/csv');

        downloadBlobAsFile(response.data as Blob, filename);
    }

    public async exportProductsWithDpCategories(filename: string = 'products_with_dp_categories.csv'): Promise<void> {
        const response: AxiosResponse<any, any> = await this.apiService.exportProductsWithDpCategories('text/csv');

        downloadBlobAsFile(response.data as Blob, filename);
    }

    public async copyProductsToDpCategory(cnk_list: CnkListRestDto, category_code: string): Promise<void> {
        // console.log('copyProductsToDpCategory', cnk_list, category_code);
        await this.apiService.copyProductsToDpCategory(cnk_list, category_code);
    }

    public async copyProductsToInternalCategory(cnk_list: CnkListRestDto, category_code: string): Promise<void> {
        // console.log('copyProductsToInternalCategory', cnk_list, category_code);
        await this.apiService.copyProductsToInternalCategory(cnk_list, category_code);
    }

    public async moveProductsToDpCategory(move_to_dto: MoveToRestDto, category_code: string): Promise<void> {
        // console.log('moveProductsToDpCategory', move_to_dto, category_code);
        await this.apiService.moveProductsToDpCategory(move_to_dto, category_code);
    }

    public async moveProductsToInternalCategory(move_to_dto: MoveToRestDto, category_code: string): Promise<void> {
        // console.log('moveProductsToInternalCategory', move_to_dto, category_code);
        await this.apiService.moveProductsToInternalCategory(move_to_dto, category_code);
    }

    public async addInternalCategoryToProduct(cnk_code: number, category_code: string): Promise<void> {
        // console.log('addInternalCategoryToProduct', cnk_code, category_code);
        await this.apiService.addInternalCategoryToProduct(cnk_code, category_code);
    }

    public async addDpCategoryToProduct(cnk_code: number, category_code: string): Promise<void> {
        // console.log('addDpCategoryToProduct', cnk_code, category_code);
        await this.apiService.addDpCategoryToProduct(cnk_code, category_code);
    }

    public async removeProductsFromDpCategory(cnk_list: CnkListRestDto, category_code: string): Promise<void> {
        // console.log('removeProductsFromDpCategory', cnk_list, category_code);
        await this.apiService.removeProductsFromDpCategory(cnk_list, category_code);
    }

    public async removeProductsFromInternalCategory(cnk_list: CnkListRestDto, category_code: string): Promise<void> {
        // console.log('removeProductsFromInternalCategory', cnk_list, category_code);
        await this.apiService.removeProductsFromInternalCategory(cnk_list, category_code);
    }
}
