import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52d431be"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-bs-title"]
const _hoisted_2 = ["data-bs-title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (this.isDpUser && this.customDesignTemplate)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: _normalizeClass([this.class, "mas text-danger ms-1 mb-1"]),
          "data-bs-toggle": "tooltip",
          "data-bs-placement": "top",
          "data-bs-title": this.customDesignTemplate?.description
        }, "edit_document", 10, _hoisted_1))
      : _createCommentVNode("", true),
    (this.isLocked)
      ? (_openBlock(), _createElementBlock("i", {
          key: 1,
          class: _normalizeClass([this.class, "mas text-danger ms-1 mb-1"]),
          "data-bs-toggle": "tooltip",
          "data-bs-html": "true",
          "data-bs-placement": "top",
          "data-bs-title": this.lockedText
        }, "lock", 10, _hoisted_2))
      : _createCommentVNode("", true)
  ], 64))
}