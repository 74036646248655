
import {defineComponent} from "vue";
import {MoveActionEnum} from "@/models/enum/MoveActionEnum";

export default defineComponent({
  name: "BaseMoveButtons",
  data() {
    return {
      moveActionEnum: MoveActionEnum,
    }
  },
  props: {
    onlyTopAndBottom: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  emits: [
    MoveActionEnum.TOP.toLowerCase(),
    MoveActionEnum.UP.toLowerCase(),
    MoveActionEnum.DOWN.toLowerCase(),
    MoveActionEnum.BOTTOM.toLowerCase()
  ]
});
