import {createStore} from 'vuex';

import authModule from './auth/auth.js';
import customerNameInstance from "./pharma-customer-name/customer_name";
import customerSettingsInstance from "./pharma-customer-settings/customer_settings";
import cmsContentBlock from "./pharma-cms-content-block";
import cmsContentPage from "./pharma-cms-content-page";
import cmsContentPageWysiwyg from "./pharma-cms-content-page/content_page_wysiwyg";
import cmsDocument from "./pharma-cms-document/cms_document";
import cmsEnum from "./pharma-cms-enum/cms_enum";
import cmsPharmacy from "@/store/pharma-cms-pharmacy/cms_pharmacy";
import cpcDocument from "@/store/pharma-cpc-document/cpc_document";
import pimEnum from "@/store/pharma-pim-enum/pim_enum";
import cpcMgmtModule from "@/store/pharma-cpc-mgmt";
import cpcProduct from "@/store/pharma-cpc-product/cpc_product";
import cpcProductMgmt from "@/store/pharma-cpc-product-mgmt";
import idmUser from "@/store/pharma-idm-user/idm_user";
import licenseInfo from "@/store/pharma-license-license-info/license_info";
import orderEnum from "./pharma-order-enum/order_enum";
import orderSettings from "@/store/pharma-order-settings/order_settings";
import orderOrcaCustomerMgmt from "@/store/pharma-order-orca-customer-mgmt/order_orca_customer_mgmt";


const store = createStore({
    modules: {
        auth: authModule,
        customer_name: customerNameInstance,
        customer_settings: customerSettingsInstance,
        cms_content_block: cmsContentBlock,
        cms_content_page: cmsContentPage,
        cms_content_page_wysiwyg: cmsContentPageWysiwyg,
        cms_document: cmsDocument,
        cms_enum: cmsEnum,
        cms_pharmacy: cmsPharmacy,
        cpc_document: cpcDocument,
        pim_enum: pimEnum,
        cpc_mgmt: cpcMgmtModule,
        cpc_product: cpcProduct,
        cpc_product_mgmt: cpcProductMgmt,
        idm_user: idmUser,
        license_info: licenseInfo,
        order_enum: orderEnum,
        order_settings: orderSettings,
        order_orca_customer_mgmt: orderOrcaCustomerMgmt,
    },
});

export default store;
