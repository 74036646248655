import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex flex-column justify-content-between h-100" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-6" }
const _hoisted_4 = { class: "row mb-3" }
const _hoisted_5 = { class: "col-6" }
const _hoisted_6 = { class: "col-6" }
const _hoisted_7 = {
  key: 0,
  class: "col-12"
}
const _hoisted_8 = { class: "row mb-3" }
const _hoisted_9 = { class: "col-6" }
const _hoisted_10 = { class: "col-6" }
const _hoisted_11 = { class: "row mb-3" }
const _hoisted_12 = { class: "col-6" }
const _hoisted_13 = { class: "col-6" }
const _hoisted_14 = {
  key: 0,
  class: "col-12"
}
const _hoisted_15 = { class: "row align-items-center pt-3 border-top border-1 border-black border-opacity-25" }
const _hoisted_16 = { class: "col-auto" }
const _hoisted_17 = { class: "col" }
const _hoisted_18 = { class: "col-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_alert_error = _resolveComponent("alert-error")!
  const _component_input_text = _resolveComponent("input-text")!
  const _component_Subtitle = _resolveComponent("Subtitle")!
  const _component_input_date = _resolveComponent("input-date")!
  const _component_input_checkbox_boolean = _resolveComponent("input-checkbox-boolean")!
  const _component_dp_form = _resolveComponent("dp-form")!
  const _component_AutoSaveFeedback = _resolveComponent("AutoSaveFeedback")!
  const _component_base_offcanvas_button_close = _resolveComponent("base-offcanvas-button-close")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_alert_error, {
      exceptions: this.exceptions,
      class: "mb-4"
    }, null, 8, ["exceptions"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_dp_form, {
        onSubmit: this.save,
        as: "form",
        novalidate: ""
      }, {
        default: _withCtx(({ errors }) => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_input_text, {
                modelValue: this.contentPage.cp.name,
                "onUpdate:modelValue": [
                  _cache[0] || (_cache[0] = ($event: any) => ((this.contentPage.cp.name) = $event)),
                  this.onChange
                ],
                label: _ctx.$t('name'),
                name: "name",
                rules: "required|max:100",
                errors: errors
              }, null, 8, ["modelValue", "label", "errors", "onUpdate:modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_input_text, {
                modelValue: this.contentPage.cp.slug,
                "onUpdate:modelValue": [
                  _cache[1] || (_cache[1] = ($event: any) => ((this.contentPage.cp.slug) = $event)),
                  this.onChange
                ],
                label: _ctx.$t('cms.slug'),
                name: "slug",
                rules: "required",
                errors: errors
              }, null, 8, ["modelValue", "label", "errors", "onUpdate:modelValue"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_input_text, {
                modelValue: this.contentPage.cp.label,
                "onUpdate:modelValue": [
                  _cache[2] || (_cache[2] = ($event: any) => ((this.contentPage.cp.label) = $event)),
                  this.onChange
                ],
                label: _ctx.$t('label'),
                name: "label",
                rules: "max:200",
                errors: errors
              }, null, 8, ["modelValue", "label", "errors", "onUpdate:modelValue"])
            ]),
            (this.isMultiLingual)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_input_text, {
                    modelValue: this.contentPage.cp.canonical_key,
                    "onUpdate:modelValue": [
                      _cache[3] || (_cache[3] = ($event: any) => ((this.contentPage.cp.canonical_key) = $event)),
                      this.onChange
                    ],
                    label: _ctx.$t('cms.canonicalKey'),
                    name: "canonicalKey",
                    rules: "max:100",
                    errors: errors
                  }, null, 8, ["modelValue", "label", "errors", "onUpdate:modelValue"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_Subtitle, {
            title: _ctx.$t('source'),
            mb: "3"
          }, null, 8, ["title"]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_input_text, {
                modelValue: this.contentPage.cp.source_text,
                "onUpdate:modelValue": [
                  _cache[4] || (_cache[4] = ($event: any) => ((this.contentPage.cp.source_text) = $event)),
                  this.onChange
                ],
                label: _ctx.$t('text'),
                name: "sourceText",
                rules: "max:200",
                errors: errors
              }, null, 8, ["modelValue", "label", "errors", "onUpdate:modelValue"])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_input_text, {
                modelValue: this.contentPage.cp.source_link,
                "onUpdate:modelValue": [
                  _cache[5] || (_cache[5] = ($event: any) => ((this.contentPage.cp.source_link) = $event)),
                  this.onChange
                ],
                label: _ctx.$t('link'),
                name: "sourceLink",
                rules: "max:2000",
                errors: errors
              }, null, 8, ["modelValue", "label", "errors", "onUpdate:modelValue"])
            ])
          ]),
          _createVNode(_component_Subtitle, {
            title: _ctx.$t('cms.publication'),
            mb: "3"
          }, null, 8, ["title"]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_input_date, {
                modelValue: this.contentPage.cp.publication_date,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((this.contentPage.cp.publication_date) = $event)),
                label: _ctx.$t('cms.publicationDate'),
                name: "publicationDate",
                placeholder: "DD-MM-JJJJ",
                errors: errors
              }, null, 8, ["modelValue", "label", "errors"])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_input_date, {
                modelValue: this.contentPage.cp.last_modified_date,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((this.contentPage.cp.last_modified_date) = $event)),
                label: _ctx.$t('cms.lastModified'),
                name: "lastModified",
                placeholder: "DD-MM-JJJJ",
                errors: errors
              }, null, 8, ["modelValue", "label", "errors"])
            ]),
            (_ctx.$can('use-feature', 'HOMEPAGE_CONTENT_PAGE') && this.pageType !== 'CUSTOM_CONTENT_PAGE')
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createVNode(_component_input_checkbox_boolean, {
                    modelValue: this.contentPage.cp.is_published_on_home,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((this.contentPage.cp.is_published_on_home) = $event)),
                    label: _ctx.$t('cms.publishOnHomepage'),
                    name: "isPublishedOnHome",
                    errors: errors
                  }, null, 8, ["modelValue", "label", "errors"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("button", {
            onClick: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (this.save && this.save(...args)), ["prevent"])),
            class: "btn btn-primary"
          }, _toDisplayString(_ctx.$t('save')), 1)
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createVNode(_component_AutoSaveFeedback, {
            status: this.autoSaveFeedbackStatus
          }, null, 8, ["status"])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createVNode(_component_base_offcanvas_button_close, { class: "btn btn-light" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('close')), 1)
            ]),
            _: 1
          })
        ])
      ])
    ])
  ], 64))
}