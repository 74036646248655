
import {defineComponent} from "vue";
import {Exception} from "@/api/interfaces";
import {arrayHasContent} from "@/helpers/functions/arrays";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {AbilityContext} from "@/context/AbilityContext";
import {AuthContext} from "@/context/AuthContext";
import {UIStateDto} from "@/dtos/UIStateDto";
import {MenuRestService} from "@/services/rest/cms-menu/MenuRestService";
import {MenuOverviewResultsRestDtoModel} from "@/models/api/pharma-cms-menu/MenuOverviewResultsRestDtoModel";
import {MenuOverviewRestDto} from "@/api/pharma-cms-menu/models";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";

export default defineComponent({
  name: "MenuOverview",
  components: {BaseTitle},
  data() {
    return {
      exceptions: [] as Exception[],
      ui: UIStateDto.createWithDefaults(),

      overviewItemResults: MenuOverviewResultsRestDtoModel.createWithDefaults(),
    };
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    AuthContext() {
      return AuthContext
    },
    AbilityContext() {
      return AbilityContext
    },
    overviewItems(): MenuOverviewRestDto[] {
      return this.overviewItemResults.results;
    },
    hasOverviewItems(): boolean {
      return arrayHasContent(this.overviewItemResults.results);
    }
  },
  methods: {
    determineMenuName(menu: MenuOverviewRestDto) {
      if (menu.is_named_menu) {
        return menu.name.description + ' (' + menu.name.code + ')'
      }
      return menu.name.description;
    },
    async reloadContent() {
      this.ui.clearError().setNotReady();
      this.exceptions = [];

      try {
        this.overviewItemResults = await MenuRestService.getInstance().findMenus();
        await this.navigateToDetailIfNecessary();
      } catch (exceptions: any) {
        this.setExceptions(exceptions)
      } finally {
        this.ui.setIsReady(true);
      }
    },
    async navigateToDetailIfNecessary() {
      let namedItemFound = false;
      let top2Id = null;
      this.overviewItemResults.results.forEach(item => {
        if (item.is_named_menu) {
          namedItemFound = true;
        }
        if (item.name.code === 'TOP2') {
          top2Id = item.id;
        }
      });
      if (!namedItemFound && top2Id !== null) {
        await this.$router.push({name: 'edit-menu', params: {menu_id: top2Id}});
      }
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.ui as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.ui as UIStateDto))
        .catch(exceptions, true);
    }
  }
});
