import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-check form-switch" }
const _hoisted_2 = ["id", "checked", "onChange"]
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "d-inline-block mt-3 pt-4 border-top border-2 border-light" }
const _hoisted_5 = {
  type: "submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_AlertError2 = _resolveComponent("AlertError2")!
  const _component_dp_form = _resolveComponent("dp-form")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, { "container-fluid": "" }, {
    default: _withCtx(() => [
      _createVNode(_component_BaseTitle, {
        title: _ctx.$t('deliveryMethods'),
        mb: 5,
        "show-refresh-button": "",
        onRefresh: this.reloadContent
      }, null, 8, ["title", "onRefresh"]),
      _createVNode(_component_AlertError2, {
        error: this.pageUI.getError()
      }, null, 8, ["error"]),
      _createVNode(_component_dp_form, {
        as: "form",
        onSubmit: _ctx.submitData,
        novalidate: ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.authorizedMethods, (method) => {
            return (_openBlock(), _createElementBlock("div", {
              key: method.code,
              class: "d-flex justify-content-begin align-items-center"
            }, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("input", {
                  class: "form-check-input",
                  type: "checkbox",
                  role: "switch",
                  id: method.code,
                  checked: this.isActive(method.code),
                  onChange: ($event: any) => (this.toggleIsActive(method.code))
                }, null, 40, _hoisted_2),
                _createElementVNode("label", {
                  class: "form-check-label",
                  for: method.code
                }, _toDisplayString(method.description), 9, _hoisted_3)
              ])
            ]))
          }), 128)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", _hoisted_5, _toDisplayString(_ctx.$t('save')), 1)
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }))
}