<template>
  <div
    v-for="language in this.languages"
    v-show="language === this.activeLanguage"
    :key="language"
    v-html="this.internalData[language]"
    :class="this.name"
  ></div>
</template>

<script>
import {I18nContext} from "@/context/I18nContext";

export default {
  name: "I18nReadOnlyHtml",
  props: {
    languages: {
      type: Array,
      required: false,
      default() {
        return I18nContext.getLanguages();
      }
    },
    name: {
      type: String,
      required: true,
    },
    activeLanguage: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getHtmlContent(lang) {
      return this.internalData[lang];
    },
  },
  mounted() {
    this.languages.forEach(lang => {
      if (this.internalData[lang] === undefined) {
        this.internalData.lang = '';
      }
    });
  },
  data() {
    return {
      internalData: this.data,
    };
  },
}
</script>
