import {RestService} from "@/services/rest/RestService";
import {ProductFeatureEndpointsApi} from "@/api/pharma-license-license-info";
import {ProductFeatureAuthorizationRestDtoModel} from "@/models/ProductFeatureAuthorizationRestDtoModel";
import {CodeDescriptionRestDto} from "@/api/pharma-license-license-info/models";

export class ProductFeatureRestService extends RestService {
    private apiService : ProductFeatureEndpointsApi;

    constructor() {
        super();

        this.apiService = new ProductFeatureEndpointsApi(this.getDpAxiosInstance(), undefined, this.getConfig().licenseUrl);
    }

    public async findProductFeatureAuthorization(): Promise<ProductFeatureAuthorizationRestDtoModel[]>
    {
        const response = await this.apiService.findProductFeatureAuthorization();

        return response.data as ProductFeatureAuthorizationRestDtoModel[];
    }

    public async findMainProductLicense(): Promise<CodeDescriptionRestDto>
    {
        const response = await this.apiService.findMainProductLicense();

        return response.data as CodeDescriptionRestDto;
    }
}
