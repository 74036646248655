
import {defineComponent} from "vue";
import {Exception} from "@/api/interfaces";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {UIStateDto} from "@/dtos/UIStateDto";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {useToast} from "vue-toastification";
import {Tooltip} from "bootstrap";
import {CatalogProductsRestService} from "@/services/rest/cpc-mgmt/CatalogProductsRestService";
import {CustomerProductPriceForProductRestDto, ProductPriceInfoRestDto} from "@/api/pharma-cpc-product-mgmt/models";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import {
  NulllableCustomerProductPriceRestDtoModel
} from "@/models/api/pharma-cpc-product-mgmt/NullableCustomerProductPriceRestDtoModel";
import BaseCardBody from "@/components/UI/Bootstrap/Card/BaseCardBody.vue";
import BaseCardHeader from "@/components/UI/Bootstrap/Card/BaseCardHeader.vue";
import BaseCard from "@/components/UI/Bootstrap/Card/BaseCard.vue";
import InputNumber from "@/components/UI/InputNumber.vue";
import {CustomerProductPriceRestDtoModel} from "@/models/api/pharma-cpc-product-mgmt/CustomerProductPriceRestDtoModel";

export default defineComponent({
  name: "ProductDetailPriceTab",

  components: {InputNumber, BaseCard, BaseCardHeader, BaseCardBody, AlertError2,},
  emits: ["savedSuccessfully"],
  props: {
    productCode: {
      type: String,
      required: true
    },
    cnkCode: {
      type: String,
      required: false
    }
  },
  data: () => ({
    exceptions: [] as Exception[],
    tabUI: UIStateDto.createWithDefaults(),

    priceInfo: null as ProductPriceInfoRestDto | null,
    customerPrice: NulllableCustomerProductPriceRestDtoModel.createWithDefaults(),

    toaster: useToast(),
    tooltips: [] as Tooltip[],

  }),
  mounted() {
    this.reloadContent();
  },
  methods: {
    async reloadContent() {
      this.tabUI
        .setNotReady()
        .clearError();

      try {
        if (this.productCode) {
          const service = CatalogProductsRestService.getInstance();
          let priceForCustomer = null as CustomerProductPriceForProductRestDto | null;
          await Promise.all([
            this.priceInfo = await service.findProductPriceInfo(this.productCode),
            priceForCustomer = await service.findProductCustomerPrice(this.productCode),
          ]);
          if (priceForCustomer?.price) {
            this.customerPrice = NulllableCustomerProductPriceRestDtoModel.createWithDefaultsFromDto(priceForCustomer?.price);
          } else {
            if (this.priceInfo && this.priceInfo?.standard_prices?.length > 0) {
              this.customerPrice.vat_percentage = this.priceInfo?.standard_prices[0].vat_percentage;
            }
          }
        }

      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.tabUI.setReady();
      }
    },
    async submitCustomerPrice() {
      this.tabUI
        .clearError();

      try {
        if (this.customerPrice.standard_price_with_vat && this.customerPrice.vat_percentage) {
          const price = CustomerProductPriceRestDtoModel.createWithDefaults(this.customerPrice.standard_price_with_vat, this.customerPrice.vat_percentage);
          let service = CatalogProductsRestService.getInstance();
          await service.syncProductCustomerPrice(this.productCode, price);
        }
        this.reloadContent();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }
    },
    async deleteCustomerPrice() {
      this.tabUI
        .clearError();

      try {
        await CatalogProductsRestService.getInstance().deleteProductCustomerPrice(this.productCode);
        this.reloadContent();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.tabUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.tabUI as UIStateDto))
        .catch(exceptions, true);
    }
  },
});
