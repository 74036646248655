
import {defineComponent} from "vue";
import {CodeDescriptionRestDto, MultilingualProductPropertyRestDto} from "@/api/pharma-cpc-product-mgmt/models";
import {mapGetters} from "vuex";
import CustomerProductPropertiesItem
  from "@/components/layouts/catalog/customer-products/CustomerProductPropertiesItem.vue";
import {
  MultilingualProductPropertyRestDtoModel
} from "@/models/api/pharma-cpc-product-mgmt/MultilingualProductPropertyRestDtoModel";
import {
  MultilingualTextFieldRestDtoModel
} from "@/models/api/pharma-cpc-product-mgmt/MultilingualTextFieldRestDtoModel";
import {PRODUCT_PROPERTY_TYPE_CODES_ENUM} from "@/store/pharma-cpc-product-mgmt/middleware";
import {isValidMultilingualTextField} from "@/helpers/functions/multilingual";

export default defineComponent({
  name: "CustomerProductProperties",
  components: {CustomerProductPropertiesItem},
  emits: ["submit"],
  data: () => ({
    productProperties: [] as MultilingualProductPropertyRestDto[],
    isReady: false as boolean,
  }),
  mounted() {
    this.reloadContent();
  },
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
    returnRoute: {
      type: String,
      required: true,
    }
  },
  computed: {
    ...mapGetters('cpc_product_mgmt', ['getCustomerProduct']),
  },
  methods: {
    async reloadContent(): Promise<void> {
      if (!this.getCustomerProduct.product_properties) {
        this.getCustomerProduct.product_properties = [] as MultilingualProductPropertyRestDto[];
      }

      // Add empty properties if not exists
      PRODUCT_PROPERTY_TYPE_CODES_ENUM.forEach((property_type: CodeDescriptionRestDto) => {
        const existingProp = this.getCustomerProduct.product_properties.find((product_property: MultilingualProductPropertyRestDto) => product_property.property_type_code === property_type.code) as MultilingualProductPropertyRestDto;
        if (existingProp) {
          this.productProperties.push(existingProp);
        } else {
          this.productProperties.push(new MultilingualProductPropertyRestDtoModel(
            property_type.code,
            MultilingualTextFieldRestDtoModel.createWithDefaults()));
        }

        this.isReady = true;
      });
    },
    async submitData() {
      console.log('properties submitData')

      const validProperties = [] as MultilingualProductPropertyRestDto[];
      this.productProperties.forEach((property: MultilingualProductPropertyRestDto) => {
        // Only add the valid properties, so that no empty values are added to customerProduct
        if (isValidMultilingualTextField(property.value)) {
          validProperties.push(property);
        }
      });
      this.getCustomerProduct.product_properties = validProperties;
      this.$emit('submit');
    }
  },
});
