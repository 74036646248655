<template>
  <div
    v-for="language in this.languages"
    :key="language"
    v-show="this.activeLanguage === language"
  >
    <input-text-editor
      v-model="this.internalData[language]"
      :language="language"
      :errors="this.errors"
      :label="this.label"
      :height="this.height"
      :name="`${this.name}.${language}`"
      :mode="this.mode"
      :rules="this.rules"
      :wrap-content-tag="this.wrapContentTag"
      @update:model-value="dataUpdated">
      <span v-if="AbilityContext.isAuthorizedForFeature('MULTILINGUAL') && showLanguageIndicator">({{ language.toUpperCase() }})</span>
      <span v-if="isRequired" class="text-danger">*</span>
      <span v-if="AbilityContext.isAuthorizedForFeature('MULTILINGUAL') && showLanguageIndicator" class="mas text-primary float-end">language</span>
    </input-text-editor>
  </div>
</template>

<script>
import {I18nContext} from "@/context/I18nContext";
import InputTextEditor from "@/components/UI/InputTextEditor.vue";
import {generateUUIDv4} from "@/helpers/functions/string";
import {AbilityContext} from "@/context/AbilityContext";

export default {
  name: "I18nInputTextEditor",
  components: {InputTextEditor},
  emits: ['update:data'],
  props: {
    languages: {
      type: Array,
      required: false,
      default() {
        return I18nContext.getLanguages();
      }
    },
    activeLanguage: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default() {
        return generateUUIDv4('input-text-editor');
      }
    },
    mode: {
      type: String,
      required: false,
      default: 'editor',
      validate(value) {
        return value === 'editor' || value === 'bare' || value === 'basic';
      }
    },
    errors: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      required: false,
      default: 300,
    },
    label: {
      type: String,
      required: false,
    },
    rules: {
      type: String,
      required: false,
      default: '',
    },
    nullWhenEmpty: {
      type: Boolean,
      required: false,
      default: false
    },
    showLanguageIndicator: {
      type: Boolean,
      required: false,
      default: false
    },
    wrapContentTag: {
      type: String,
      required: false,
      default: 'p',
      description: 'The tag to wrap around the returned html content.  p is default, other values can be span, div, or an empty string for no tag.'
    }
  },
  computed: {
    AbilityContext() {
      return AbilityContext
    },
    isRequired() {
      return this.rules && this.rules.includes("required");
    }
  },
  data() {
    return {
      internalData: this.data,
    };
  },
  mounted() {
    this.languages.forEach(lang => {
      if (this.internalData[lang] === undefined) {
        this.internalData.lang = null;
      }
    });
  },
  methods: {
    dataUpdated() {
      if (this.nullWhenEmpty && this.internalData[this.activeLanguage] === '') {
        this.internalData[this.activeLanguage] = null;
      }
      this.$emit('update:data', this.internalData);
    }
  }
}
</script>
