import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "data-bs-target", "aria-controls", "aria-selected", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["nav-link", {'active': _ctx.isActive}]),
    id: _ctx.target + '-tab',
    "data-bs-toggle": "tab",
    "data-bs-target": '#' + _ctx.target,
    type: "button",
    role: "tab",
    "aria-controls": _ctx.target,
    "aria-selected": _ctx.isActive,
    disabled: _ctx.isDisabled
  }, _toDisplayString(_ctx.title), 11, _hoisted_1))
}