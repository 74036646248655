import {PagingMetadataRestDto} from "@/api/pharma-pim-pp2/models";
import {CatalogProductOverviewRestDtoModel} from "@/models/api/pharma-cpc-product-mgmt/CatalogProductOverviewRestDtoModel";
import {PagedCatalogProductOverviewResultsRestDto} from "@/api/pharma-cpc-product-mgmt/models";

export class PagedCatalogProductOverviewResultsRestDtoModel implements PagedCatalogProductOverviewResultsRestDto {
    paging_metadata?: PagingMetadataRestDto | null;
    results: CatalogProductOverviewRestDtoModel[];

    constructor(results: CatalogProductOverviewRestDtoModel[]) {
        this.results = results;
    }

    public static createWithDefaults(): PagedCatalogProductOverviewResultsRestDtoModel {
        return new this([]);
    }
}
