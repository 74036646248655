
import {defineComponent} from "vue";
import {mapActions} from 'vuex';
import {
  CodeDescriptionRestDto
} from "@/api/pharma-cms-content-block/models";
import PageLinkPicker from "@/components/UI/PageLinkPicker.vue";
import {PageLinkPayload} from "@/models/payload/PageLinkPayload";
import {PageLinkTypeEnum} from "@/models/enum/PageLinkTypeEnum";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import AbstractCbCommon from "@/components/layouts/content-pages/wysiwyg/structures/AbstractCbCommon.vue";
import {LinkTargetEnum} from "@/models/enum/LinkTargetEnum";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import {SymbolContentRestDtoModel} from "@/models/api/pharma-cms-content-block/SymbolContentRestDtoModel";
import {SymbolTargetEnum} from "@/api/enums/symbol-target-enum";
import Subtitle from "@/components/UI/Subtitle.vue";
import InputNumber from "@/components/UI/InputNumber.vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import InputText from "@/components/UI/InputText.vue";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import PreviewSymbol from "@/components/UI/PreviewSymbol.vue";
import ColorPicker from "@/components/UI/ColorPicker.vue";
import {ColorPickerPayload} from "@/models/payload/ColorPickerPayload";

export default defineComponent({
  name: "CbSymbol",
  extends: AbstractCbCommon,
  components: {
    ColorPicker,
    PreviewSymbol,
    Subtitle, InputCheckboxBoolean, InputText, InputSelect, InputNumber, PageLinkPicker, BaseSpinner
  },
  data: () => ({
    contentBlock: SymbolContentRestDtoModel.createWithDefaults(),
    pageLinkPayload: PageLinkPayload.createWithDefaults()
  }),
  watch: {
    contentBlock() {
      const externalUrl = this.contentBlock.symbol.symbol_url?.nl ?? null;
      const pageId = this.contentBlock.symbol.symbol_page_id?.nl ?? null;
      const target = this.contentBlock.symbol.symbol_target ?? LinkTargetEnum.SELF;

      this.pageLinkPayload = new PageLinkPayload(externalUrl, pageId, target.toString() as LinkTargetEnum);
    },
  },
  computed: {
    enumHorizontalPosition(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.OBJECT_HORIZONTAL_POSITION);
    },
    enumSymbolFamily(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.SYMBOL_FAMILY_ENUM);
    },
    enumSymbolGrade(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.SYMBOL_GRADE_ENUM);
    },
    enumSymbolOpticalSize(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.SYMBOL_OPTICAL_SIZE_ENUM);
    },
    enumSymbolWeight(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.SYMBOL_WEIGHT_ENUM);
    },
    enumVerticalPosition(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.OBJECT_VERTICAL_POSITION);
    },
  },
  methods: {
    ...mapActions('cms_content_block/normal_content_block/symbol', ['create', 'find', 'save']),
    determineEnums(): EnumClassNameCmsEnum[] {
      return [
        EnumClassNameCmsEnum.OBJECT_HORIZONTAL_POSITION,
        EnumClassNameCmsEnum.OBJECT_VERTICAL_POSITION,
        EnumClassNameCmsEnum.SYMBOL_FAMILY_ENUM,
        EnumClassNameCmsEnum.SYMBOL_GRADE_ENUM,
        EnumClassNameCmsEnum.SYMBOL_OPTICAL_SIZE_ENUM,
        EnumClassNameCmsEnum.SYMBOL_WEIGHT_ENUM
      ];
    },
    getDefaultCbModel(): SymbolContentRestDtoModel {
      return SymbolContentRestDtoModel.createWithDefaults();
    },
    onChangeColorDefault(payload: ColorPickerPayload): void {
      this.contentBlock.symbol.default_state_color = payload.colorRestDto;
      this.onChange();
    },
    onChangeColorHover(payload: ColorPickerPayload): void {
      this.contentBlock.symbol.hover_state_color = payload.colorRestDto;
      this.onChange();
    },
    onChangeSymbolName(): void {
      this.contentBlock.symbol.name_code = this.contentBlock.symbol.name_code
        .toLowerCase()
        .replaceAll(' ', '_');
      this.onChange();
    },
    onPageLinkPickerChanged(payload: PageLinkPayload): void {
      if (payload.type === PageLinkTypeEnum.NONE) {
        this.contentBlock.symbol.symbol_url = undefined;
        this.contentBlock.symbol.symbol_page_id = undefined;
        this.contentBlock.symbol.symbol_target = undefined;
      } else if (payload.type === PageLinkTypeEnum.URL) {
        this.contentBlock.symbol.symbol_url = new MultilingualRestDtoModel(null, null, null, payload.external_url);
        this.contentBlock.symbol.symbol_page_id = undefined;
        this.contentBlock.symbol.symbol_target = payload.target.toString() as SymbolTargetEnum;
      } else if (payload.type === PageLinkTypeEnum.PAGE) {
        this.contentBlock.symbol.symbol_url = undefined;
        this.contentBlock.symbol.symbol_page_id = new MultilingualRestDtoModel(null, null, null, payload.page_id);
        this.contentBlock.symbol.symbol_target = payload.target.toString() as SymbolTargetEnum;
      }

      this.onChange();
    },
    pasteCbMetadata(): void {
      // When calling clone method directly on object, method is not found. So this is a hacky workaround
      const cbClone = SymbolContentRestDtoModel.cloneFrom(this.contentBlock);

      cbClone.cloneMetadataFrom(this.getClipboardContentBlock(this.cbType) as SymbolContentRestDtoModel);
      this.contentBlock = cbClone;
    },
  }
});
