import {PageReferenceOrderByRestDto} from "@/api/pharma-cms-content-block/models";
import {OrderDirectionEnum} from "@/api/enums/order-direction-enum";
import {OrderFieldEnum} from "@/api/enums/order-field-enum";

export class PageReferenceOrderByRestDtoModel implements PageReferenceOrderByRestDto {
    order_direction_code?: OrderDirectionEnum;
    order_field_code?: OrderFieldEnum;

    constructor(order_direction_code: OrderDirectionEnum, order_field_code: OrderFieldEnum) {
        this.order_direction_code = order_direction_code;
        this.order_field_code = order_field_code;
    }

    public static createWithDefaults(): PageReferenceOrderByRestDtoModel {
        return new PageReferenceOrderByRestDtoModel(
            OrderDirectionEnum.ASC,
            OrderFieldEnum.SORT_WEIGHT
        );
    }
}
