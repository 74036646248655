
import {defineComponent} from "vue";
import LazyImage from "@/components/UI/LazyImage.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import {UtilityRestService} from "@/services/rest/cms-utility/UtilityRestService";

export default defineComponent({
  name: "LibContentBlocksItemContentBlock",
  components: {BaseSpinner, LazyImage},
  emits: ["copied"],
  data() {
    return {
      content: this.contentBlock?.cb?.content?.nl ?? '' as string
    }
  },
  props: {
    contentBlock: {
      type: Object,
      required: true,
    },
    libType: {
      type: String,
      required: true,
    },
  },
  mounted() {
    if (['TEXT_WITH_IMAGE', 'TEXT_WITH_TITLE'].includes(this.libType)) {
      this.truncateContent();
    }
  },
  computed: {
    cardStyles(): Object | null {
      if (['TEXT_WITH_IMAGE'].includes(this.libType)) {
        return {
          flexDirection: 'row',
          alignItems: 'flex-start',
        };
      } else {
        return null
      }
    },
    imageStyles(): Object {
      if (['TEXT_WITH_IMAGE'].includes(this.libType)) {
        return {
          padding: '1rem 0 1rem 1rem',
          height: 'auto',
          maxWidth: 'calc(50% - 1rem)',
        };
      } else {
        return {
          padding: '1rem 0',
          height: '8rem',
          objectFit: 'contain',
          objectPosition: 'center center',
        };
      }
    }
  },
  methods: {
    async truncateContent() {
      this.content = await UtilityRestService.getInstance()
        .truncateHtml(this.content, 300);
    }
  }
});
