import {
    CodeDescriptionRestDto,
    MultilingualProductCategoryRestDto,
    Pp2ProductOverviewRestDto
} from "@/api/pharma-pim-pp2/models";
import {Pp2ProductInfoRestDtoModel} from "@/models/api/pharma-pim-pp2/Pp2ProductInfoRestDtoModel";

export class Pp2ProductOverviewRestDtoModel implements Pp2ProductOverviewRestDto {
    id: number;
    status: CodeDescriptionRestDto;
    is_removable?: boolean | null;
    product_info: Pp2ProductInfoRestDtoModel;
    categories: MultilingualProductCategoryRestDto[] | null;
    internal_categories: CodeDescriptionRestDto[] | null;

    constructor(id: number, status: CodeDescriptionRestDto, product_info: Pp2ProductInfoRestDtoModel) {
        this.id = id;
        this.status = status;
        this.product_info = product_info;
        this.categories = null;
        this.internal_categories = null;
    }

    public static createWithDefaults(): Pp2ProductOverviewRestDtoModel {
        return new this(
            0,
            {code: 'STATUS', description: 'Status'},
            Pp2ProductInfoRestDtoModel.createWithDefaults()
        );
    }
}
