
import {defineComponent} from "vue";
import {CodeDescriptionRestDto} from "@/api/pharma-cpc-mgmt/models";
import {mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import {PropType} from "vue/dist/vue";
import {ProductRestService} from "@/services/rest/cpc-product/ProductRestService";

export default defineComponent({
  name: "SelectTrademarksSelectedItem",
  emits: ["removeTrademark"],
  props: {
    selectedTrademarkCode: {
      type: String,
      required: true
    },
    catalogCustomerCode: {
      type: String,
      required: false,
    },
    pimTrademarks: {
      type: Array as PropType<CodeDescriptionRestDto[]>,
      required: false
    }
  },
  data() {
    return {
      allTrademarks: [] as CodeDescriptionRestDto[],
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    trademarkName(): string | null {
      if (this.pimTrademarks) {
        return this.pimTrademarks
          .find((trademark: CodeDescriptionRestDto) => trademark.code === this.selectedTrademarkCode)?.description as string;
      }
      const foundTrademark = this.allTrademarks.find((trademark: any) => trademark.code === this.selectedTrademarkCode);

      return foundTrademark?.description as string;
    },
  },
  methods: {
    removeTrademark(trademark_code: string) {
      this.$emit('removeTrademark', trademark_code);
    },
    async reloadContent() {
      this.allTrademarks= await ProductRestService.getInstance().getAllTrademarks(this.catalogCustomerCode);
    }
  }
});
