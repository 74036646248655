
import {defineComponent} from "vue";
import {CodeDescriptionRestDto} from "@/api/pharma-cpc-mgmt/models";
import {PropType} from "vue/dist/vue";
import {ProductRestService} from "@/services/rest/cpc-product/ProductRestService";

export default defineComponent({
  name: "SelectBrandsSelectedItem",
  emits: ["removeBrand"],
  props: {
    selectedBrandCode: {
      type: String,
      required: true
    },
    catalogCustomerCode: {
      type: String,
      required: false,
    },
    pimLaboBrands: {
      type: Array as PropType<CodeDescriptionRestDto[]>,
      required: false
    }
  },
  data() {
    return {
      allBrands: [] as CodeDescriptionRestDto[],
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    brandName(): string | null {
      if (this.pimLaboBrands) {
        return this.pimLaboBrands
          .find((brand: CodeDescriptionRestDto) => brand.code === this.selectedBrandCode)?.description as string;
      }
      const foundBrand = this.allBrands.find((brand: any) => brand.code === this.selectedBrandCode);

      return foundBrand?.description as string;
    },
  },
  methods: {
    removeBrand(brand_code: string) {
      this.$emit('removeBrand', brand_code);
    },
    async reloadContent() {
      this.allBrands= await ProductRestService.getInstance().getAllBrands(this.catalogCustomerCode);
    }
  }
});
