/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum SelectionTypeEnum {
    INCLUDE = 'INCLUDE',
    EXCLUDE = 'EXCLUDE',
    BASE_SELECTION = 'BASE_SELECTION',
}