import {DeliveryCostRuleRestDto, SynchronizeSupportedDeliveryMethodsRestDto} from "@/api/pharma-order-settings/models";

export class DeliveryCostRuleRestDtoModel implements DeliveryCostRuleRestDto {
    delivery_method_code: string;
    country_code?: string | null;
    postal_code?: string | null;
    order_amount_with_vat_from?: number | null;
    order_amount_with_vat_to?: number | null;
    delivery_price_with_vat: number;


    constructor() {
        this.delivery_method_code = '';
        this.delivery_price_with_vat = 0;
    }

    public static createWithDefaults(): DeliveryCostRuleRestDtoModel {
        return new DeliveryCostRuleRestDtoModel();
    }
}
