import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "buttons d-flex align-items-start" }
const _hoisted_3 = {
  type: "submit",
  class: "btn btn-primary mb-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_alert_error = _resolveComponent("alert-error")!
  const _component_InputSelect = _resolveComponent("InputSelect")!
  const _component_ButtonCbLibraries = _resolveComponent("ButtonCbLibraries")!
  const _component_dp_form = _resolveComponent("dp-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_alert_error, {
      exceptions: this.exceptions,
      class: "mb-4"
    }, null, 8, ["exceptions"]),
    (this.isReady)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_dp_form, {
            as: "form",
            onSubmit: _ctx.submitData,
            novalidate: ""
          }, {
            default: _withCtx(({ errors }) => [
              _createVNode(_component_InputSelect, {
                modelValue: this.cbInRow.structure_code,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.cbInRow.structure_code) = $event)),
                options: this.contentBlockStructureTypeEnums,
                label: _ctx.$t('cms.contentType'),
                name: "structure_code",
                selectText: _ctx.$t('selectText'),
                rules: "required",
                errors: errors
              }, null, 8, ["modelValue", "options", "label", "selectText", "errors"]),
              _createVNode(_component_InputSelect, {
                modelValue: this.cbInRow.column_width_code,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.cbInRow.column_width_code) = $event)),
                options: this.columnWidthCodeEnum,
                label: _ctx.$t('cms.columnWidth'),
                name: "column_width_code",
                selectText: _ctx.$t('selectText'),
                rules: "required",
                errors: errors
              }, null, 8, ["modelValue", "options", "label", "selectText", "errors"]),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("button", _hoisted_3, _toDisplayString(_ctx.$t('add')), 1),
                _createVNode(_component_ButtonCbLibraries, {
                  "cb-type": this.cbType,
                  bus: this.bus,
                  title: this.contentBlockStructureDescription,
                  class: "ms-2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('cms.addFromLibrary')), 1)
                  ]),
                  _: 1
                }, 8, ["cb-type", "bus", "title"])
              ])
            ]),
            _: 1
          }, 8, ["onSubmit"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}