import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row gx-5 mb-3" }
const _hoisted_2 = { class: "col-left col-auto" }
const _hoisted_3 = { class: "col-right col col-xxl-6" }
const _hoisted_4 = {
  key: 0,
  class: "row row-website-reference mt-3"
}
const _hoisted_5 = { class: "col" }
const _hoisted_6 = {
  class: "btn btn-primary",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertError2 = _resolveComponent("AlertError2")!
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_Subtitle = _resolveComponent("Subtitle")!
  const _component_InputSelect = _resolveComponent("InputSelect")!
  const _component_InputCheckboxBoolean = _resolveComponent("InputCheckboxBoolean")!
  const _component_MultilingualSwitcher = _resolveComponent("MultilingualSwitcher")!
  const _component_I18nInputText = _resolveComponent("I18nInputText")!
  const _component_InputImage = _resolveComponent("InputImage")!
  const _component_dp_form = _resolveComponent("dp-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AlertError2, {
      error: this.headerFooterContentUI.getError()
    }, null, 8, ["error"]),
    _createVNode(_component_BaseSpinner, {
      spin: this.headerFooterContentUI.isNotReady()
    }, null, 8, ["spin"]),
    (this.headerFooterContentUI.isReady())
      ? (_openBlock(), _createBlock(_component_dp_form, {
          key: 0,
          onSubmit: this.submitData,
          novalidate: ""
        }, {
          default: _withCtx(({errors}) => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_Subtitle, {
                  title: _ctx.$t('settings'),
                  mb: "3"
                }, null, 8, ["title"]),
                _createVNode(_component_InputSelect, {
                  modelValue: this.headerContent.layout_code,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.headerContent.layout_code) = $event)),
                  options: this.layoutOptions,
                  name: "layoutCode",
                  label: _ctx.$t('layout'),
                  selectText: _ctx.$t('selectText'),
                  rules: "required",
                  errors: errors
                }, null, 8, ["modelValue", "options", "label", "selectText", "errors"]),
                _createVNode(_component_InputCheckboxBoolean, {
                  modelValue: this.headerContent.is_show_pharmacy_name,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.headerContent.is_show_pharmacy_name) = $event)),
                  name: "isShowPharmacyName",
                  label: `${_ctx.$t('show')} ${_ctx.$t('pharmacyName').toLowerCase()}`,
                  errors: errors
                }, null, 8, ["modelValue", "label", "errors"]),
                _createVNode(_component_InputCheckboxBoolean, {
                  modelValue: this.headerContent.is_show_pharmacy_on_call,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.headerContent.is_show_pharmacy_on_call) = $event)),
                  name: "isShowPharmacyOnCall",
                  label: `${_ctx.$t('show')} ${_ctx.$t('pharmacyOnCall').toLowerCase()}`,
                  errors: errors
                }, null, 8, ["modelValue", "label", "errors"]),
                _createVNode(_component_InputCheckboxBoolean, {
                  modelValue: this.headerContent.is_show_phone_number,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((this.headerContent.is_show_phone_number) = $event)),
                  name: "isShowPhoneNumber",
                  label: `${_ctx.$t('show')} ${_ctx.$t('phoneNumber').toLowerCase()}`,
                  errors: errors
                }, null, 8, ["modelValue", "label", "errors"]),
                _createVNode(_component_InputCheckboxBoolean, {
                  modelValue: this.headerContent.is_show_opening_info,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((this.headerContent.is_show_opening_info) = $event)),
                  name: "isShowOpeningInfo",
                  label: `${_ctx.$t('show')} ${_ctx.$t('openingInfo').toLowerCase()}`,
                  errors: errors
                }, null, 8, ["modelValue", "label", "errors"]),
                _createVNode(_component_InputCheckboxBoolean, {
                  modelValue: this.headerContent.is_show_social_media,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((this.headerContent.is_show_social_media) = $event)),
                  name: "isShowSocialMedia",
                  label: `${_ctx.$t('show')} ${_ctx.$t('socialMedia').toLowerCase()}`,
                  errors: errors
                }, null, 8, ["modelValue", "label", "errors"]),
                _createVNode(_component_InputCheckboxBoolean, {
                  modelValue: this.headerContent.is_sticky,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((this.headerContent.is_sticky) = $event)),
                  name: "isSticky",
                  label: _ctx.$t('sticksToTop'),
                  errors: errors
                }, null, 8, ["modelValue", "label", "errors"])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_Subtitle, {
                  title: _ctx.$t('reference'),
                  mb: "3"
                }, null, 8, ["title"]),
                _createVNode(_component_InputCheckboxBoolean, {
                  modelValue: this.usePharmacyGroupWebsiteRef,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((this.usePharmacyGroupWebsiteRef) = $event)),
                  name: "usePharmacyGroupWebsiteRef",
                  label: _ctx.$t('useGroupWebsiteReference'),
                  errors: errors
                }, null, 8, ["modelValue", "label", "errors"]),
                (this.headerContent?.pharmacy_group)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_MultilingualSwitcher, {
                        "active-language": this.activeLanguage,
                        onUpdateActiveLanguage: _cache[8] || (_cache[8] = newValue => this.activeLanguage = newValue),
                        errors: errors
                      }, null, 8, ["active-language", "errors"]),
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_I18nInputText, {
                          data: this.headerContent.pharmacy_group.group_website_ref.website_url,
                          "active-language": this.activeLanguage,
                          name: "websiteUrl",
                          label: _ctx.$t('url'),
                          rules: "required|max:500",
                          errors: errors
                        }, null, 8, ["data", "active-language", "label", "errors"]),
                        _createVNode(_component_I18nInputText, {
                          data: this.headerContent.pharmacy_group.group_website_ref.reference_text,
                          "active-language": this.activeLanguage,
                          name: "referenceText",
                          label: _ctx.$t('text'),
                          rules: "max:100",
                          errors: errors
                        }, null, 8, ["data", "active-language", "label", "errors"]),
                        _createVNode(_component_InputImage, {
                          modelValue: this.headerContent.pharmacy_group.group_website_ref.image_key,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((this.headerContent.pharmacy_group.group_website_ref.image_key) = $event)),
                          label: _ctx.$t('image'),
                          errors: errors
                        }, null, 8, ["modelValue", "label", "errors"])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("button", _hoisted_6, _toDisplayString(_ctx.$t('save')), 1)
          ]),
          _: 1
        }, 8, ["onSubmit"]))
      : _createCommentVNode("", true)
  ], 64))
}