
import {Exception} from '@/api/interfaces';
import store from '@/store';
import {defineComponent} from "vue";
import {mapGetters} from 'vuex';
import {
  OpeningHoursForDayRestDto,
  OpeningHoursForWeekRestDto,
} from "@/api/pharma-cms-pharmacy/models";

import {defineRule} from "vee-validate";
import {OpeningHoursPeriodRestDtoModel} from "@/models/OpeningHoursPeriodRestDtoModel";
import {OpeningHoursForWeekRestDtoModel} from "@/models/OpeningHoursForWeekRestDtoModel";
import I18nInputText from "@/components/UI/I18n/I18nInputText.vue";
import MultilingualSwitcher from "@/components/UI/MultilingualSwitcher.vue";
import {useToast} from "vue-toastification";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {I18nContext} from "@/context/I18nContext";
import {mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";

defineRule("check_date", (value: any, [begin, end]: any) => {
  if (begin > end) {
    //TODO: make translatable
    return 'De einddatum moet gelijk aan of later zijn';
  }
  return true;
});

export default defineComponent({
  name: "OpeningHoursPeriodsDetail",
  components: {BaseTitle, MultilingualSwitcher, I18nInputText},
  props: ['mode'],
  data: () => ({
    error: null,
    exceptions: [] as Exception[],
    isReady: false as Boolean,
    savedSuccessfully: false as Boolean,
    statusOptions: [],
    pharmacyCode: '' as String,

    openingHoursPeriod: OpeningHoursPeriodRestDtoModel.createWithDefaults(),

    initialOpeningHoursWeek: OpeningHoursForWeekRestDtoModel.createWithDefaults(),
    standardOpeningHoursWeek: OpeningHoursForWeekRestDtoModel.createWithDefaults(),

    activeLanguage: I18nContext.getDefaultApiLanguageKey(),

    toaster: useToast(),
  }),
  computed: {
    ...mapGetters('cms_pharmacy', ['getOpeningHoursPeriod', "getStandardOpeningHours"]),
    ...mapState(useCustomerStore, ["getPharmacyCode"]),
    inputTimeRulesFromTo() {
      return (openingHoursDayFrom: any) => (openingHoursDayFrom === null || openingHoursDayFrom.length === 0) ? '' : 'required';
    },
    inputTimeRulesOpenClosed() {
      return (openingHoursDayOpen: boolean) => openingHoursDayOpen ? 'required' : '';
    },
  },
  mounted() {
    this.reloadContent();
  },
  methods: {
    toggleDayTimeValues(day: keyof OpeningHoursForWeekRestDto) {
      if (this.openingHoursPeriod.opening_hours[day].open) {
        if (this.mode === 'new' && this.standardOpeningHoursWeek[day].open) {
          this.openingHoursPeriod.opening_hours[day] = JSON.parse(JSON.stringify(this.standardOpeningHoursWeek[day]));
        } else if (this.mode === 'edit') {
          this.initialOpeningHoursWeek[day].open = true;
          this.openingHoursPeriod.opening_hours[day] = JSON.parse(JSON.stringify(this.initialOpeningHoursWeek[day]));
        }
      } else {
        this.openingHoursPeriod.opening_hours[day] = {
          open: false,
          hours1_from: null,
          hours1_till: null,
          hours2_from: null,
          hours2_till: null,
          hours3_from: null,
          hours3_till: null,
        } as OpeningHoursForDayRestDto;
      }
    },
    copyToEndDate() {
      if (this.openingHoursPeriod.begin.length === 10 && this.openingHoursPeriod.end.length < 10) {
        this.openingHoursPeriod.end = this.openingHoursPeriod.begin;
      }
    },
    submitData() {
      if (this.mode === 'new') {
        this.createOpeningHoursPeriod();
      } else if (this.mode === 'edit') {
        this.saveOpeningHoursPeriod();
      }
    },
    async fillWithStandardHours() {
      await this.searchStandardOpeningHours();
      this.standardOpeningHoursWeek = this.getStandardOpeningHours;
      this.openingHoursPeriod.opening_hours = JSON.parse(JSON.stringify(this.standardOpeningHoursWeek));
    },
    async reloadContent() {
      try {
        this.isReady = false;

        this.pharmacyCode = this.getPharmacyCode;

        if (this.mode === 'new') {
          await this.fillWithStandardHours();
        } else if (this.mode === 'edit') {
          await this.searchOpeningHoursPeriod();
          this.openingHoursPeriod = this.getOpeningHoursPeriod;
          this.initialOpeningHoursWeek = JSON.parse(JSON.stringify(this.openingHoursPeriod.opening_hours));
        }

        this.isReady = true;
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async createOpeningHoursPeriod() {
      try {
        const payload = {
          body: this.openingHoursPeriod,
          pharmacy_code: this.pharmacyCode,
        }
        await store.dispatch('cms_pharmacy/createOpeningHoursPeriod', payload);

        this.toaster.success(this.$t('savedSuccessfully'));

        await this.$router.push({
          name: 'exceptional-hours',
        });
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async saveOpeningHoursPeriod() {
      try {
        const payload = {
          body: this.openingHoursPeriod,
          id: this.$route.params.id,
          pharmacy_code: this.pharmacyCode,
        }

        await store.dispatch('cms_pharmacy/saveOpeningHoursPeriod', payload);

        this.toaster.success(this.$t('savedSuccessfully'));

        await this.$router.push({
          name: 'exceptional-hours',
        });
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async searchOpeningHoursPeriod() {
      try {
        const payload = {
          id: this.$route.params.id,
          pharmacy_code: this.pharmacyCode,
        };
        await store.dispatch('cms_pharmacy/searchOpeningHoursPeriod', payload);
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async searchStandardOpeningHours() {
      try {
        await store.dispatch('cms_pharmacy/searchStandardOpeningHours', this.pharmacyCode);
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
  },
});
