
import {defineComponent} from "vue";

export default defineComponent({
  name: "InputCheckboxMultiSelect",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    errors: {
      type: Object,
    },
    label: {
      type: String,
    },
    value: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    rules: {
      type: String,
    },
  },
  data() {
    return {
      selectedValues: [] as String[],
    }
  },
  mounted() {
    this.selectedValues = [...this.modelValue] as String[];
  },
  methods: {
    onUpdate(newValue: any) {
      this.$emit("update:modelValue", newValue);
    },
  }
});
