<template>
  <div class="col-xxl-3 col-lg-4 py-4 d-flex card border-0">
    <router-link :to="{name: link}"
       style="background-color: #c9e2ea;" class="h-100 rounded p-4 text-black text-decoration-none">
      <div class="card-body h-100 mx-auto d-flex flex-column justify-content-center align-items-center">
        <i class="mas fs-1 mb-4">{{ icon }}</i>
        <h2 class="text-center text-break">{{ title }}</h2>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "dashboardCard",
  props: {
    "title": {
      type: String,
      required: true
    },
    "icon": {
      type: String,
      required: true
    },
    "link": {
      type: String,
      required: true
    },
  }
}
</script>

<style scoped>

</style>