/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Content Page API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ContentBlockInRowCreateRestDto } from '../models';
import { ContentBlockInRowRestDto } from '../models';
import { ContentVisibilityRuleForElementRestDto } from '../models';
import { ElementTargetPositionRestDto } from '../models';
import { FullContentBlockInRowRestDto } from '../models';
import { IdResponseRestDto } from '../models';
import { IdRestDto } from '../models';
/**
 * ContentBlockInRowEndpointsApi - axios parameter creator
 * @export
 */
export const ContentBlockInRowEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.CBIR.11 Add content block to content block in row.
         * @param {IdRestDto} body 
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} cb_in_row_id The unique identifier of this content block in row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCbToContentBlockInRow: async (body: IdRestDto, row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addCbToContentBlockInRow.');
            }
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling addCbToContentBlockInRow.');
            }
            // verify required parameter 'cb_in_row_id' is not null or undefined
            if (cb_in_row_id === null || cb_in_row_id === undefined) {
                throw new RequiredError('cb_in_row_id','Required parameter cb_in_row_id was null or undefined when calling addCbToContentBlockInRow.');
            }
            const localVarPath = `/content_page/v1/content_page_row/{row_id}/cb_in_row/{cb_in_row_id}/add_cb`
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)))
                .replace(`{${"cb_in_row_id"}}`, encodeURIComponent(String(cb_in_row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.12 Copy this content block in row to the next position.
         * @param {ElementTargetPositionRestDto} body 
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} cb_in_row_id The unique identifier of this content block in row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyContentBlockInRow: async (body: ElementTargetPositionRestDto, row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling copyContentBlockInRow.');
            }
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling copyContentBlockInRow.');
            }
            // verify required parameter 'cb_in_row_id' is not null or undefined
            if (cb_in_row_id === null || cb_in_row_id === undefined) {
                throw new RequiredError('cb_in_row_id','Required parameter cb_in_row_id was null or undefined when calling copyContentBlockInRow.');
            }
            const localVarPath = `/content_page/v1/content_page_row/{row_id}/cb_in_row/{cb_in_row_id}/copy`
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)))
                .replace(`{${"cb_in_row_id"}}`, encodeURIComponent(String(cb_in_row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.02 Create content block in row for content page row.
         * @param {ContentBlockInRowCreateRestDto} body 
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContentBlockInRow: async (body: ContentBlockInRowCreateRestDto, row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createContentBlockInRow.');
            }
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling createContentBlockInRow.');
            }
            const localVarPath = `/content_page/v1/content_page_row/{row_id}/cb_in_row`
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.05 Delete content block in row.
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} cb_in_row_id The unique identifier of this content block in row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContentBlockInRow: async (row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling deleteContentBlockInRow.');
            }
            // verify required parameter 'cb_in_row_id' is not null or undefined
            if (cb_in_row_id === null || cb_in_row_id === undefined) {
                throw new RequiredError('cb_in_row_id','Required parameter cb_in_row_id was null or undefined when calling deleteContentBlockInRow.');
            }
            const localVarPath = `/content_page/v1/content_page_row/{row_id}/cb_in_row/{cb_in_row_id}`
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)))
                .replace(`{${"cb_in_row_id"}}`, encodeURIComponent(String(cb_in_row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.01 Get all contentblocks in row for content page row.
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findContentBlocksInRow: async (row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling findContentBlocksInRow.');
            }
            const localVarPath = `/content_page/v1/content_page_row/{row_id}/cb_in_row`
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.04 Get content block in row.
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} cb_in_row_id The unique identifier of this content block in row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentBlockInRow: async (row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling getContentBlockInRow.');
            }
            // verify required parameter 'cb_in_row_id' is not null or undefined
            if (cb_in_row_id === null || cb_in_row_id === undefined) {
                throw new RequiredError('cb_in_row_id','Required parameter cb_in_row_id was null or undefined when calling getContentBlockInRow.');
            }
            const localVarPath = `/content_page/v1/content_page_row/{row_id}/cb_in_row/{cb_in_row_id}`
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)))
                .replace(`{${"cb_in_row_id"}}`, encodeURIComponent(String(cb_in_row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.14 Get the visibility rule of this content block in row.
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} cb_in_row_id The unique identifier of this content block in row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentBlockInRowVisibilityRule: async (row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling getContentBlockInRowVisibilityRule.');
            }
            // verify required parameter 'cb_in_row_id' is not null or undefined
            if (cb_in_row_id === null || cb_in_row_id === undefined) {
                throw new RequiredError('cb_in_row_id','Required parameter cb_in_row_id was null or undefined when calling getContentBlockInRowVisibilityRule.');
            }
            const localVarPath = `/content_page/v1/content_page_row/{row_id}/cb_in_row/{cb_in_row_id}/visibility_rule`
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)))
                .replace(`{${"cb_in_row_id"}}`, encodeURIComponent(String(cb_in_row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.09 Move content block in row one position down.
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} cb_in_row_id The unique identifier of this content block in row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveContentBlockInRowDown: async (row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling moveContentBlockInRowDown.');
            }
            // verify required parameter 'cb_in_row_id' is not null or undefined
            if (cb_in_row_id === null || cb_in_row_id === undefined) {
                throw new RequiredError('cb_in_row_id','Required parameter cb_in_row_id was null or undefined when calling moveContentBlockInRowDown.');
            }
            const localVarPath = `/content_page/v1/content_page_row/{row_id}/cb_in_row/{cb_in_row_id}/move/down`
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)))
                .replace(`{${"cb_in_row_id"}}`, encodeURIComponent(String(cb_in_row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.10 Move content block in row to position between two others.
         * @param {ElementTargetPositionRestDto} body 
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} cb_in_row_id The unique identifier of this content block in row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveContentBlockInRowTo: async (body: ElementTargetPositionRestDto, row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling moveContentBlockInRowTo.');
            }
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling moveContentBlockInRowTo.');
            }
            // verify required parameter 'cb_in_row_id' is not null or undefined
            if (cb_in_row_id === null || cb_in_row_id === undefined) {
                throw new RequiredError('cb_in_row_id','Required parameter cb_in_row_id was null or undefined when calling moveContentBlockInRowTo.');
            }
            const localVarPath = `/content_page/v1/content_page_row/{row_id}/cb_in_row/{cb_in_row_id}/move/to`
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)))
                .replace(`{${"cb_in_row_id"}}`, encodeURIComponent(String(cb_in_row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.07 Move content block in row to the bottom position.
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} cb_in_row_id The unique identifier of this content block in row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveContentBlockInRowToBottom: async (row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling moveContentBlockInRowToBottom.');
            }
            // verify required parameter 'cb_in_row_id' is not null or undefined
            if (cb_in_row_id === null || cb_in_row_id === undefined) {
                throw new RequiredError('cb_in_row_id','Required parameter cb_in_row_id was null or undefined when calling moveContentBlockInRowToBottom.');
            }
            const localVarPath = `/content_page/v1/content_page_row/{row_id}/cb_in_row/{cb_in_row_id}/move/to_bottom`
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)))
                .replace(`{${"cb_in_row_id"}}`, encodeURIComponent(String(cb_in_row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.06 Move content block in row to the top position.
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} cb_in_row_id The unique identifier of this content block in row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveContentBlockInRowToTop: async (row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling moveContentBlockInRowToTop.');
            }
            // verify required parameter 'cb_in_row_id' is not null or undefined
            if (cb_in_row_id === null || cb_in_row_id === undefined) {
                throw new RequiredError('cb_in_row_id','Required parameter cb_in_row_id was null or undefined when calling moveContentBlockInRowToTop.');
            }
            const localVarPath = `/content_page/v1/content_page_row/{row_id}/cb_in_row/{cb_in_row_id}/move/to_top`
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)))
                .replace(`{${"cb_in_row_id"}}`, encodeURIComponent(String(cb_in_row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.08 Move content block in row one position up.
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} cb_in_row_id The unique identifier of this content block in row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveContentBlockInRowUp: async (row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling moveContentBlockInRowUp.');
            }
            // verify required parameter 'cb_in_row_id' is not null or undefined
            if (cb_in_row_id === null || cb_in_row_id === undefined) {
                throw new RequiredError('cb_in_row_id','Required parameter cb_in_row_id was null or undefined when calling moveContentBlockInRowUp.');
            }
            const localVarPath = `/content_page/v1/content_page_row/{row_id}/cb_in_row/{cb_in_row_id}/move/up`
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)))
                .replace(`{${"cb_in_row_id"}}`, encodeURIComponent(String(cb_in_row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.03 Update content block in row.
         * @param {ContentBlockInRowRestDto} body 
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} cb_in_row_id The unique identifier of this content block in row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentBlockInRow: async (body: ContentBlockInRowRestDto, row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateContentBlockInRow.');
            }
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling updateContentBlockInRow.');
            }
            // verify required parameter 'cb_in_row_id' is not null or undefined
            if (cb_in_row_id === null || cb_in_row_id === undefined) {
                throw new RequiredError('cb_in_row_id','Required parameter cb_in_row_id was null or undefined when calling updateContentBlockInRow.');
            }
            const localVarPath = `/content_page/v1/content_page_row/{row_id}/cb_in_row/{cb_in_row_id}`
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)))
                .replace(`{${"cb_in_row_id"}}`, encodeURIComponent(String(cb_in_row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.13 Update the visibility rule of this content block in row.
         * @param {ContentVisibilityRuleForElementRestDto} body 
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} cb_in_row_id The unique identifier of this content block in row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentBlockInRowVisibilityRule: async (body: ContentVisibilityRuleForElementRestDto, row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateContentBlockInRowVisibilityRule.');
            }
            // verify required parameter 'row_id' is not null or undefined
            if (row_id === null || row_id === undefined) {
                throw new RequiredError('row_id','Required parameter row_id was null or undefined when calling updateContentBlockInRowVisibilityRule.');
            }
            // verify required parameter 'cb_in_row_id' is not null or undefined
            if (cb_in_row_id === null || cb_in_row_id === undefined) {
                throw new RequiredError('cb_in_row_id','Required parameter cb_in_row_id was null or undefined when calling updateContentBlockInRowVisibilityRule.');
            }
            const localVarPath = `/content_page/v1/content_page_row/{row_id}/cb_in_row/{cb_in_row_id}/visibility_rule`
                .replace(`{${"row_id"}}`, encodeURIComponent(String(row_id)))
                .replace(`{${"cb_in_row_id"}}`, encodeURIComponent(String(cb_in_row_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentBlockInRowEndpointsApi - functional programming interface
 * @export
 */
export const ContentBlockInRowEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.CBIR.11 Add content block to content block in row.
         * @param {IdRestDto} body 
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} cb_in_row_id The unique identifier of this content block in row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCbToContentBlockInRow(_axios: AxiosInstance, body: IdRestDto, row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockInRowEndpointsApiAxiosParamCreator(configuration).addCbToContentBlockInRow(body, row_id, cb_in_row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.12 Copy this content block in row to the next position.
         * @param {ElementTargetPositionRestDto} body 
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} cb_in_row_id The unique identifier of this content block in row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyContentBlockInRow(_axios: AxiosInstance, body: ElementTargetPositionRestDto, row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockInRowEndpointsApiAxiosParamCreator(configuration).copyContentBlockInRow(body, row_id, cb_in_row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.02 Create content block in row for content page row.
         * @param {ContentBlockInRowCreateRestDto} body 
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContentBlockInRow(_axios: AxiosInstance, body: ContentBlockInRowCreateRestDto, row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResponseRestDto>> {
            const localVarAxiosArgs = await ContentBlockInRowEndpointsApiAxiosParamCreator(configuration).createContentBlockInRow(body, row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.05 Delete content block in row.
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} cb_in_row_id The unique identifier of this content block in row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContentBlockInRow(_axios: AxiosInstance, row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockInRowEndpointsApiAxiosParamCreator(configuration).deleteContentBlockInRow(row_id, cb_in_row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.01 Get all contentblocks in row for content page row.
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findContentBlocksInRow(_axios: AxiosInstance, row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FullContentBlockInRowRestDto>>> {
            const localVarAxiosArgs = await ContentBlockInRowEndpointsApiAxiosParamCreator(configuration).findContentBlocksInRow(row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.04 Get content block in row.
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} cb_in_row_id The unique identifier of this content block in row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentBlockInRow(_axios: AxiosInstance, row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentBlockInRowRestDto>> {
            const localVarAxiosArgs = await ContentBlockInRowEndpointsApiAxiosParamCreator(configuration).getContentBlockInRow(row_id, cb_in_row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.14 Get the visibility rule of this content block in row.
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} cb_in_row_id The unique identifier of this content block in row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentBlockInRowVisibilityRule(_axios: AxiosInstance, row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentVisibilityRuleForElementRestDto>> {
            const localVarAxiosArgs = await ContentBlockInRowEndpointsApiAxiosParamCreator(configuration).getContentBlockInRowVisibilityRule(row_id, cb_in_row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.09 Move content block in row one position down.
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} cb_in_row_id The unique identifier of this content block in row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveContentBlockInRowDown(_axios: AxiosInstance, row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockInRowEndpointsApiAxiosParamCreator(configuration).moveContentBlockInRowDown(row_id, cb_in_row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.10 Move content block in row to position between two others.
         * @param {ElementTargetPositionRestDto} body 
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} cb_in_row_id The unique identifier of this content block in row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveContentBlockInRowTo(_axios: AxiosInstance, body: ElementTargetPositionRestDto, row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockInRowEndpointsApiAxiosParamCreator(configuration).moveContentBlockInRowTo(body, row_id, cb_in_row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.07 Move content block in row to the bottom position.
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} cb_in_row_id The unique identifier of this content block in row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveContentBlockInRowToBottom(_axios: AxiosInstance, row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockInRowEndpointsApiAxiosParamCreator(configuration).moveContentBlockInRowToBottom(row_id, cb_in_row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.06 Move content block in row to the top position.
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} cb_in_row_id The unique identifier of this content block in row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveContentBlockInRowToTop(_axios: AxiosInstance, row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockInRowEndpointsApiAxiosParamCreator(configuration).moveContentBlockInRowToTop(row_id, cb_in_row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.08 Move content block in row one position up.
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} cb_in_row_id The unique identifier of this content block in row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveContentBlockInRowUp(_axios: AxiosInstance, row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockInRowEndpointsApiAxiosParamCreator(configuration).moveContentBlockInRowUp(row_id, cb_in_row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.03 Update content block in row.
         * @param {ContentBlockInRowRestDto} body 
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} cb_in_row_id The unique identifier of this content block in row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContentBlockInRow(_axios: AxiosInstance, body: ContentBlockInRowRestDto, row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockInRowEndpointsApiAxiosParamCreator(configuration).updateContentBlockInRow(body, row_id, cb_in_row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CBIR.13 Update the visibility rule of this content block in row.
         * @param {ContentVisibilityRuleForElementRestDto} body 
         * @param {string} row_id The unique identifier of the row the content block in row belongs to.
         * @param {string} cb_in_row_id The unique identifier of this content block in row.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContentBlockInRowVisibilityRule(_axios: AxiosInstance, body: ContentVisibilityRuleForElementRestDto, row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockInRowEndpointsApiAxiosParamCreator(configuration).updateContentBlockInRowVisibilityRule(body, row_id, cb_in_row_id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ContentBlockInRowEndpointsApi - object-oriented interface
 * @export
 * @class ContentBlockInRowEndpointsApi
 * @extends {BaseAPI}
 */
export class ContentBlockInRowEndpointsApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.CMS.CBIR.11 Add content block to content block in row.
     * @param {IdRestDto} body 
     * @param {string} row_id The unique identifier of the row the content block in row belongs to.
     * @param {string} cb_in_row_id The unique identifier of this content block in row.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockInRowEndpointsApi
     */
     public addCbToContentBlockInRow(body: IdRestDto, row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentBlockInRowEndpointsApiFp(this.configuration).addCbToContentBlockInRow(this.axios, body, row_id, cb_in_row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CBIR.12 Copy this content block in row to the next position.
     * @param {ElementTargetPositionRestDto} body 
     * @param {string} row_id The unique identifier of the row the content block in row belongs to.
     * @param {string} cb_in_row_id The unique identifier of this content block in row.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockInRowEndpointsApi
     */
     public copyContentBlockInRow(body: ElementTargetPositionRestDto, row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentBlockInRowEndpointsApiFp(this.configuration).copyContentBlockInRow(this.axios, body, row_id, cb_in_row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CBIR.02 Create content block in row for content page row.
     * @param {ContentBlockInRowCreateRestDto} body 
     * @param {string} row_id The unique identifier of the row the content block in row belongs to.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockInRowEndpointsApi
     */
     public createContentBlockInRow(body: ContentBlockInRowCreateRestDto, row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentBlockInRowEndpointsApiFp(this.configuration).createContentBlockInRow(this.axios, body, row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CBIR.05 Delete content block in row.
     * @param {string} row_id The unique identifier of the row the content block in row belongs to.
     * @param {string} cb_in_row_id The unique identifier of this content block in row.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockInRowEndpointsApi
     */
     public deleteContentBlockInRow(row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentBlockInRowEndpointsApiFp(this.configuration).deleteContentBlockInRow(this.axios, row_id, cb_in_row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CBIR.01 Get all contentblocks in row for content page row.
     * @param {string} row_id The unique identifier of the row the content block in row belongs to.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockInRowEndpointsApi
     */
     public findContentBlocksInRow(row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentBlockInRowEndpointsApiFp(this.configuration).findContentBlocksInRow(this.axios, row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CBIR.04 Get content block in row.
     * @param {string} row_id The unique identifier of the row the content block in row belongs to.
     * @param {string} cb_in_row_id The unique identifier of this content block in row.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockInRowEndpointsApi
     */
     public getContentBlockInRow(row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentBlockInRowEndpointsApiFp(this.configuration).getContentBlockInRow(this.axios, row_id, cb_in_row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CBIR.14 Get the visibility rule of this content block in row.
     * @param {string} row_id The unique identifier of the row the content block in row belongs to.
     * @param {string} cb_in_row_id The unique identifier of this content block in row.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockInRowEndpointsApi
     */
     public getContentBlockInRowVisibilityRule(row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentBlockInRowEndpointsApiFp(this.configuration).getContentBlockInRowVisibilityRule(this.axios, row_id, cb_in_row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CBIR.09 Move content block in row one position down.
     * @param {string} row_id The unique identifier of the row the content block in row belongs to.
     * @param {string} cb_in_row_id The unique identifier of this content block in row.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockInRowEndpointsApi
     */
     public moveContentBlockInRowDown(row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentBlockInRowEndpointsApiFp(this.configuration).moveContentBlockInRowDown(this.axios, row_id, cb_in_row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CBIR.10 Move content block in row to position between two others.
     * @param {ElementTargetPositionRestDto} body 
     * @param {string} row_id The unique identifier of the row the content block in row belongs to.
     * @param {string} cb_in_row_id The unique identifier of this content block in row.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockInRowEndpointsApi
     */
     public moveContentBlockInRowTo(body: ElementTargetPositionRestDto, row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentBlockInRowEndpointsApiFp(this.configuration).moveContentBlockInRowTo(this.axios, body, row_id, cb_in_row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CBIR.07 Move content block in row to the bottom position.
     * @param {string} row_id The unique identifier of the row the content block in row belongs to.
     * @param {string} cb_in_row_id The unique identifier of this content block in row.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockInRowEndpointsApi
     */
     public moveContentBlockInRowToBottom(row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentBlockInRowEndpointsApiFp(this.configuration).moveContentBlockInRowToBottom(this.axios, row_id, cb_in_row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CBIR.06 Move content block in row to the top position.
     * @param {string} row_id The unique identifier of the row the content block in row belongs to.
     * @param {string} cb_in_row_id The unique identifier of this content block in row.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockInRowEndpointsApi
     */
     public moveContentBlockInRowToTop(row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentBlockInRowEndpointsApiFp(this.configuration).moveContentBlockInRowToTop(this.axios, row_id, cb_in_row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CBIR.08 Move content block in row one position up.
     * @param {string} row_id The unique identifier of the row the content block in row belongs to.
     * @param {string} cb_in_row_id The unique identifier of this content block in row.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockInRowEndpointsApi
     */
     public moveContentBlockInRowUp(row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentBlockInRowEndpointsApiFp(this.configuration).moveContentBlockInRowUp(this.axios, row_id, cb_in_row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CBIR.03 Update content block in row.
     * @param {ContentBlockInRowRestDto} body 
     * @param {string} row_id The unique identifier of the row the content block in row belongs to.
     * @param {string} cb_in_row_id The unique identifier of this content block in row.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockInRowEndpointsApi
     */
     public updateContentBlockInRow(body: ContentBlockInRowRestDto, row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentBlockInRowEndpointsApiFp(this.configuration).updateContentBlockInRow(this.axios, body, row_id, cb_in_row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CBIR.13 Update the visibility rule of this content block in row.
     * @param {ContentVisibilityRuleForElementRestDto} body 
     * @param {string} row_id The unique identifier of the row the content block in row belongs to.
     * @param {string} cb_in_row_id The unique identifier of this content block in row.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockInRowEndpointsApi
     */
     public updateContentBlockInRowVisibilityRule(body: ContentVisibilityRuleForElementRestDto, row_id: string, cb_in_row_id: string, x_dp_customer_code?: string|null, options?: any) {
        return ContentBlockInRowEndpointsApiFp(this.configuration).updateContentBlockInRowVisibilityRule(this.axios, body, row_id, cb_in_row_id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
