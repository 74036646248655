
import {defineComponent} from 'vue'
import BaseContainer from "@/components/UI/Bootstrap/BaseContainer.vue";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {UIStateDto} from "@/dtos/UIStateDto";
import {PimCategorizationRestService} from "@/services/rest/pim-category/PimCategorizationRestService";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";

enum PimExportTypesEnum {
  DP_CATEGORIES = 'DP_CATEGORIES',
  PRODUCTS_IN_DP_CATEGORIES = 'PRODUCTS_IN_DP_CATEGORIES',
  PRODUCTS_WITH_DP_CATEGORIES = 'PRODUCTS_WITH_DP_CATEGORIES',
}

export default defineComponent({
  name: "PimExport",
  components: {BaseSpinner, BaseTitle, AlertError2, BaseContainer},
  data() {
    return {
      exportUI: UIStateDto.createWithDefaults().setReady(),
    }
  },
  computed: {
    PimExportTypesEnum() {
      return PimExportTypesEnum;
    }
  },
  methods: {
    async export(exportType: PimExportTypesEnum): Promise<void> {
      this.exportUI
        .setNotReady()
        .clearError();

      try {
        switch (exportType) {
          case PimExportTypesEnum.DP_CATEGORIES:
            await PimCategorizationRestService.getInstance().exportDpCategories();
            break;
          case PimExportTypesEnum.PRODUCTS_IN_DP_CATEGORIES:
            await PimCategorizationRestService.getInstance().exportProductsInDpCategories();
            break;
          case PimExportTypesEnum.PRODUCTS_WITH_DP_CATEGORIES:
            await PimCategorizationRestService.getInstance().exportProductsWithDpCategories();
            break;
          default:
            throw "Unhandled PimExportType";
        }
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.exportUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.exportUI as UIStateDto))
          .catch(exceptions, true);
      } finally {
        this.exportUI.setReady();
      }
    },
  }
})
