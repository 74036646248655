
import {defineComponent} from "vue";
import BaseAlert from "@/components/UI/Bootstrap/Alert/BaseAlert.vue";

export default defineComponent({
  name: "AlertInfo",
  components: {BaseAlert},
  props: {
    canUseHtml: {
      type: Boolean,
      required: false,
      default: false,
    },
    description: {
      type: String,
      required: true,
    },
    description2: {
      type: String,
      required: false
    },
    description3: {
      type: String,
      required: false
    },
    description4: {
      type: String,
      required: false
    },
    readMoreLabel: {
      type: String,
      required: false,
    },
    readLessLabel: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    isOpen: false as Boolean,
  }),
  computed: {
    isCollapsible(): Boolean {
      return (this.description2 !== undefined || this.description3 !== undefined || this.description4 !== undefined);
    },
  }
});
