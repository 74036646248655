import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "aria-labelledby"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tab-pane fade", {'show active': _ctx.isActive}]),
    id: _ctx.id,
    role: "tabpanel",
    "aria-labelledby": _ctx.id + '-tab',
    tabindex: "0"
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}