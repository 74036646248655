import {
    PageElementMetadataRestDto,
    WysiwygContentBlockInRowRestDto, WysiwygContentBlockPreviewRestDto,
    WysiwygCustomDesignTemplateRestDto
} from "@/api/pharma-cms-content-page/models";
import {ContentBlockInRowRestDtoModel} from "@/models/ContentBlockInRowRestDtoModel";

export class WysiwygContentBlockInRowRestDtoModel implements WysiwygContentBlockInRowRestDto {
    id: string;
    content_id: string;
    cb_structure_templates?: WysiwygCustomDesignTemplateRestDto[] | null;
    cb_in_row?: ContentBlockInRowRestDtoModel | null;
    cb_preview?: WysiwygContentBlockPreviewRestDto | null;
    element_metadata?: PageElementMetadataRestDto | null;

    constructor(id: string, content_id: string) {
        this.id = id;
        this.content_id = content_id;
    }
}
