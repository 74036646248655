export class OAuth2Token {
    public readonly expiresAt: number;
    public readonly expiresAtIsoString: string;

    constructor(
        readonly accessToken: string,
        readonly idToken: string,
        readonly refreshToken: string,
        readonly expiresIn: number,
        readonly tokenType: string
    ) {
        this.expiresAt = (new Date()).getTime() + (+expiresIn * 1000);
        this.expiresAtIsoString = (new Date(this.expiresAt)).toISOString();
    }
}
