/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Customer-Product-Catalog
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ShoppingCartDiscountSchemeOverviewRestDto } from '../models';
import { ShoppingCartDiscountSchemeRestDto } from '../models';
/**
 * ShoppingCartDiscountSchemeAPIApi - axios parameter creator
 * @export
 */
export const ShoppingCartDiscountSchemeAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for creating a shopping cart discount scheme.
         * @summary REST.API.CPC.SCDS.02 Create shopping cart discount scheme.
         * @param {ShoppingCartDiscountSchemeRestDto} body 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShoppingCartDiscountScheme: async (body: ShoppingCartDiscountSchemeRestDto, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createShoppingCartDiscountScheme.');
            }
            const localVarPath = `/customer_mgmt/v1/catalog/shopping_cart_discount_scheme`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for deleting a shopping cart discount scheme.
         * @summary REST.API.CPC.SCDS.05 Delete shopping cart discount scheme.
         * @param {string} id The unique identification of the discount scheme.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShoppingCartDiscountScheme: async (id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteShoppingCartDiscountScheme.');
            }
            const localVarPath = `/customer_mgmt/v1/catalog/shopping_cart_discount_scheme/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for fetching the list of shopping cart discount schemes.
         * @summary REST.API.CPC.SCDS.01 Fetch the shopping cart discount schemes.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findShoppingCartDiscountSchemes: async (x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer_mgmt/v1/catalog/shopping_cart_discount_scheme`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for fetching the details of a shopping cart discount scheme.
         * @summary REST.API.CPC.SCDS.03 Fetch the shopping cart discount scheme detail.
         * @param {string} id The unique identification of the shopping cart discount scheme.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShoppingCartDiscountScheme: async (id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getShoppingCartDiscountScheme.');
            }
            const localVarPath = `/customer_mgmt/v1/catalog/shopping_cart_discount_scheme/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for updating a shopping cart discount scheme.
         * @summary REST.API.CPC.SCDS.04 Update shopping cart discount scheme.
         * @param {ShoppingCartDiscountSchemeRestDto} body 
         * @param {string} id The unique identification of the shopping cart discount scheme.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShoppingCartDiscountScheme: async (body: ShoppingCartDiscountSchemeRestDto, id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateShoppingCartDiscountScheme.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateShoppingCartDiscountScheme.');
            }
            const localVarPath = `/customer_mgmt/v1/catalog/shopping_cart_discount_scheme/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShoppingCartDiscountSchemeAPIApi - functional programming interface
 * @export
 */
export const ShoppingCartDiscountSchemeAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for creating a shopping cart discount scheme.
         * @summary REST.API.CPC.SCDS.02 Create shopping cart discount scheme.
         * @param {ShoppingCartDiscountSchemeRestDto} body 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createShoppingCartDiscountScheme(_axios: AxiosInstance, body: ShoppingCartDiscountSchemeRestDto, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ShoppingCartDiscountSchemeAPIApiAxiosParamCreator(configuration).createShoppingCartDiscountScheme(body, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for deleting a shopping cart discount scheme.
         * @summary REST.API.CPC.SCDS.05 Delete shopping cart discount scheme.
         * @param {string} id The unique identification of the discount scheme.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteShoppingCartDiscountScheme(_axios: AxiosInstance, id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ShoppingCartDiscountSchemeAPIApiAxiosParamCreator(configuration).deleteShoppingCartDiscountScheme(id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for fetching the list of shopping cart discount schemes.
         * @summary REST.API.CPC.SCDS.01 Fetch the shopping cart discount schemes.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findShoppingCartDiscountSchemes(_axios: AxiosInstance, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShoppingCartDiscountSchemeOverviewRestDto>>> {
            const localVarAxiosArgs = await ShoppingCartDiscountSchemeAPIApiAxiosParamCreator(configuration).findShoppingCartDiscountSchemes(x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for fetching the details of a shopping cart discount scheme.
         * @summary REST.API.CPC.SCDS.03 Fetch the shopping cart discount scheme detail.
         * @param {string} id The unique identification of the shopping cart discount scheme.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShoppingCartDiscountScheme(_axios: AxiosInstance, id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingCartDiscountSchemeRestDto>> {
            const localVarAxiosArgs = await ShoppingCartDiscountSchemeAPIApiAxiosParamCreator(configuration).getShoppingCartDiscountScheme(id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for updating a shopping cart discount scheme.
         * @summary REST.API.CPC.SCDS.04 Update shopping cart discount scheme.
         * @param {ShoppingCartDiscountSchemeRestDto} body 
         * @param {string} id The unique identification of the shopping cart discount scheme.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateShoppingCartDiscountScheme(_axios: AxiosInstance, body: ShoppingCartDiscountSchemeRestDto, id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ShoppingCartDiscountSchemeAPIApiAxiosParamCreator(configuration).updateShoppingCartDiscountScheme(body, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ShoppingCartDiscountSchemeAPIApi - object-oriented interface
 * @export
 * @class ShoppingCartDiscountSchemeAPIApi
 * @extends {BaseAPI}
 */
export class ShoppingCartDiscountSchemeAPIApi extends BaseAPI {
    /**
     * API endpoint responsible for creating a shopping cart discount scheme.
     * @summary REST.API.CPC.SCDS.02 Create shopping cart discount scheme.
     * @param {ShoppingCartDiscountSchemeRestDto} body 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartDiscountSchemeAPIApi
     */
     public createShoppingCartDiscountScheme(body: ShoppingCartDiscountSchemeRestDto, x_dp_customer_code?: string|null, options?: any) {
        return ShoppingCartDiscountSchemeAPIApiFp(this.configuration).createShoppingCartDiscountScheme(this.axios, body, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for deleting a shopping cart discount scheme.
     * @summary REST.API.CPC.SCDS.05 Delete shopping cart discount scheme.
     * @param {string} id The unique identification of the discount scheme.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartDiscountSchemeAPIApi
     */
     public deleteShoppingCartDiscountScheme(id: string, x_dp_customer_code?: string|null, options?: any) {
        return ShoppingCartDiscountSchemeAPIApiFp(this.configuration).deleteShoppingCartDiscountScheme(this.axios, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for fetching the list of shopping cart discount schemes.
     * @summary REST.API.CPC.SCDS.01 Fetch the shopping cart discount schemes.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartDiscountSchemeAPIApi
     */
     public findShoppingCartDiscountSchemes(x_dp_customer_code?: string|null, options?: any) {
        return ShoppingCartDiscountSchemeAPIApiFp(this.configuration).findShoppingCartDiscountSchemes(this.axios, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for fetching the details of a shopping cart discount scheme.
     * @summary REST.API.CPC.SCDS.03 Fetch the shopping cart discount scheme detail.
     * @param {string} id The unique identification of the shopping cart discount scheme.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartDiscountSchemeAPIApi
     */
     public getShoppingCartDiscountScheme(id: string, x_dp_customer_code?: string|null, options?: any) {
        return ShoppingCartDiscountSchemeAPIApiFp(this.configuration).getShoppingCartDiscountScheme(this.axios, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for updating a shopping cart discount scheme.
     * @summary REST.API.CPC.SCDS.04 Update shopping cart discount scheme.
     * @param {ShoppingCartDiscountSchemeRestDto} body 
     * @param {string} id The unique identification of the shopping cart discount scheme.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartDiscountSchemeAPIApi
     */
     public updateShoppingCartDiscountScheme(body: ShoppingCartDiscountSchemeRestDto, id: string, x_dp_customer_code?: string|null, options?: any) {
        return ShoppingCartDiscountSchemeAPIApiFp(this.configuration).updateShoppingCartDiscountScheme(this.axios, body, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
