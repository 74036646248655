import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row discount-filter-categories" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectCategories = _resolveComponent("SelectCategories")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SelectCategories, {
      "selected-product-categories": this.productCategoryFilters,
      class: "col-8",
      onAddCategory: this.addCategory,
      onRemoveCategory: this.removeCategory
    }, null, 8, ["selected-product-categories", "onAddCategory", "onRemoveCategory"])
  ]))
}