import {TeamRestDto, TeamRestDtoStatusCodeEnum} from "@/api/pharma-cms-pharmacy/models";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";

export class TeamRestDtoModel implements TeamRestDto {
    title: MultilingualRestDtoModel;
    content: MultilingualRestDtoModel;
    image_key: MultilingualRestDtoModel;
    status_code: TeamRestDtoStatusCodeEnum;

    constructor(title: MultilingualRestDtoModel, content: MultilingualRestDtoModel, image_key: MultilingualRestDtoModel, status_code: TeamRestDtoStatusCodeEnum) {
        this.title = title;
        this.content = content;
        this.image_key = image_key;
        this.status_code = status_code;
    }

    public static createWithDefaults(): TeamRestDtoModel {
        return new TeamRestDtoModel(
            MultilingualRestDtoModel.createWithDefaults(),
            MultilingualRestDtoModel.createWithDefaults(),
            MultilingualRestDtoModel.createWithDefaults(),
            TeamRestDtoStatusCodeEnum.PUBLISHED
        );
    }
}
