
import {defineComponent} from "vue";
import {Exception} from "@/api/interfaces";
import {mapActions, mapGetters} from "vuex";
import {CustomerProductRestDto,} from "@/api/pharma-cpc-product-mgmt/models";
import {DpException} from "@/exception/DpException";
import CatalogBuildStatus from "@/components/layouts/catalog/CatalogBuildStatus.vue";
import CustomerProductProduct from "@/components/layouts/catalog/customer-products/CustomerProductProduct.vue";
import CustomerProductProperties from "@/components/layouts/catalog/customer-products/CustomerProductProperties.vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {mapActions as mapActionsP} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";

export default defineComponent({
  name: "CustomerProductsDetail",
  components: {
    BaseTitle,
    CatalogBuildStatus,
    Product: CustomerProductProduct,
    Properties: CustomerProductProperties,
  },
  props: ['mode'],
  data() {
    return {
      currentComponent: 'product' as string,
      exceptions: [] as Exception[],
      productCode: this.$route.params.id as string,
      isReady: false as boolean,
      isSubmitting: false as boolean,
      returnRoute: 'customer-products' as string,
      savedSuccessfully: false as boolean,
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cpc_product_mgmt', ['getCustomerProduct']),
    currentProperties(): Object {
      switch (this.currentComponent) {
        case 'product':
          return {
            mode: this.mode,
            isSubmitting: this.isSubmitting,
            returnRoute: this.returnRoute,
          };
        case 'properties':
          return {
            isSubmitting: this.isSubmitting,
            returnRoute: this.returnRoute,
          };
        default:
          throw [new DpException('Unsupported customer product component')];
      }
    },
    product(): CustomerProductRestDto {
      return this.getCustomerProduct;
    },
  },
  methods: {
    ...mapActions('cpc_product_mgmt', [
      'clearCustomerProduct',
      'createCustomerProduct',
      'saveCustomerProduct',
      'searchCustomerProduct',
    ]),
    ...mapActionsP(useCustomerStore, ["searchAllCategoriesCustomer", "searchProductCategoriesTreeCustomer", "searchAllTrademarksCustomer"]),
    setCurrentComponent(cmp: string) {
      this.currentComponent = cmp;
    },
    async submitData() {
      this.isSubmitting = true;
      this.exceptions = [];

      try {
        if (this.mode === 'new') {
          await this.createCustomerProduct();
        } else {
          await this.saveCustomerProduct(this.productCode);
        }

        await this.afterSave();
      } catch (exceptions: any) {
        this.isSubmitting = false;
        this.exceptions = exceptions;
        window.scrollTo(0, 0);
      }

      this.isSubmitting = false;
    },
    async reloadContent() {
      this.isReady = false;
      this.exceptions = [];

      try {
        if (this.mode === 'new') {
          this.clearCustomerProduct();
          await Promise.all([
            this.searchAllTrademarksCustomer(),
            this.searchAllCategoriesCustomer(),
            this.searchProductCategoriesTreeCustomer(),
          ]);
        } else {
          await Promise.all([
            this.searchAllTrademarksCustomer(),
            this.searchCustomerProduct(this.productCode),
            this.searchAllCategoriesCustomer(),
            this.searchProductCategoriesTreeCustomer(),
          ]);
        }
      } catch (exceptions: any) {
        this.isReady = true;
        this.exceptions = exceptions;
      }

      this.isReady = true;
    },
    async afterSave() {
      if (this.mode === 'new') {
        await this.$router.push({name: this.returnRoute});
      } else {
        window.scrollTo(0, 0);

        await this.reloadContent();
        this.savedSuccessfully = true;
        setTimeout(() => this.savedSuccessfully = false, 2000);
      }
    }
  },
});
