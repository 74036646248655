import {arrayHasContent} from "@/helpers/functions/arrays";

/*
* Receives an array of product codes
* and converts it to a comma seperated string of product codes
*/
function convertProductCodesArrayToList(productCodesArray: string[]): string {
    let productsList = '' as string;

    if (arrayHasContent(productCodesArray)) {
        let index = 0 as number;
        for (const productCode of productCodesArray) {
            const isLastProduct = (productCodesArray.length === index + 1) as boolean;
            productsList = productsList.concat(productCode, (isLastProduct) ? '' : ', ');
            index++;
        }
    }

    return productsList;
}

/*
* Receives comma seperated string of product codes
* and converts it to an array of product codes
*
* First remove html tags and spaces
* Then replace ; or [ENTER] with a comma
* Finally make an array (seperated on comma) and remove empty array values
*/
function convertProductCodesListToArray(productCodesList: string): string[] {
    if (productCodesList.length > 0) {
        const productsListNoHtmlAndSpaces = productCodesList.replace(/<br\s*\/?>/gi, ',').replace(/(<([^>]+)>)/gi, '').replaceAll(' ', ',') as string;
        const productsListAllCommas = productsListNoHtmlAndSpaces.replaceAll(';', ',').replaceAll('\n', ',').replaceAll('&nbsp', ',') as string;

        return productsListAllCommas.split(',').filter((product: string) => product !== '');
    }

    return [];
}

/*
* Checks if non-numeric characters exist in array of product codes
*
* Receives an array of product codes
* Returns a boolean; true if valid, false if invalid
*/
function productCodesArrayIsValid(productCodesArray: string[]): boolean {
    if (arrayHasContent(productCodesArray)) {
        let returnValue = true as boolean;
        for (const productCode of productCodesArray) {
            returnValue = productCodeIsValid(productCode);

            if (!returnValue) {
                break;
            }
        }

        return returnValue;
    }

    return true;
}

/*
* Checks if non-numeric characters exist in product code
*
* Receives a product codes string
* Returns a boolean; true if valid, false if invalid
*/
function productCodeIsValid(productCode: string): boolean {
    return (/^\d+$/).test(productCode);
}

/*
* Checks if product code is within custom cnk range
*
* Receives a product codes string
* Returns a boolean; true if valid, false if invalid
*/
function customProductCodeIsValid(productCode: string): boolean {
    return productCode.startsWith('9') || productCode.startsWith('09');
}


export {
    convertProductCodesArrayToList,
    convertProductCodesListToArray,
    productCodesArrayIsValid,
    productCodeIsValid,
    customProductCodeIsValid
};
