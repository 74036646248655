
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {mapActions as mapActionsP, mapState} from "pinia";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import {Exception} from "@/api/interfaces";
import AutoSaveFeedback from "@/components/UI/AutoSaveFeedback.vue";
import {AutoSaveFeedbackStatusEnum} from "@/models/enum/AutoSaveFeedbackStatusEnum";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import Subtitle from "@/components/UI/Subtitle.vue";
import {AuthContext} from "@/context/AuthContext";
import BaseOffcanvasButtonClose from "@/components/UI/Bootstrap/Offcanvas/BaseOffcanvasButtonClose.vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import {useToast} from "vue-toastification";
import {useClipboardStore} from "@/stores/ClipboardStore";
import {ContentPageRowRestDtoModel} from "@/models/ContentPageRowRestDtoModel";
import InputNumber from "@/components/UI/InputNumber.vue";
import ColorPicker from "@/components/UI/ColorPicker.vue";
import {BackgroundImageRestDtoModel} from "@/models/api/pharma-cms-content-page/BackgroundImageRestDtoModel";
import {CmsDocumentRestService} from "@/services/rest/cms-document/CmsDocumentRestService";
import InputFile from "@/components/UI/InputFile.vue";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import {ColorRestDtoModel} from "@/models/api/ColorRestDtoModel";

export default defineComponent({
  name: "ContentPageRowDetail",
  components: {
    InputCheckboxBoolean,
    InputFile, ColorPicker, InputNumber, InputSelect, BaseOffcanvasButtonClose, Subtitle, AutoSaveFeedback
  },
  props: {
    pageId: {
      type: String,
      required: true,
    },
    rowId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      exceptions: [] as Exception[],
      isReady: false as boolean,
      toaster: useToast(),

      contentId: '' as string,
      row: null as ContentPageRowRestDtoModel | null,

      bgColorDto: null as ColorRestDtoModel | null,
      bgImgDto: null as BackgroundImageRestDtoModel | null,
      isCustomColorPanelVisible: false,
      isBgImgPanelVisible: false as boolean,
      docType: 'PAGE_IMG' as string,
      imagePreviewUrl: null as string | null,

      autoSaveFeedbackStatus: AutoSaveFeedbackStatusEnum.NONE as AutoSaveFeedbackStatusEnum,
    };
  },
  watch: {
    rowId: {
      handler() {
        this.reloadContent();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('cms_content_page_wysiwyg', ["getWysiwygContentPageRowById"]),
    ...mapGetters('cms_enum', ['getEnumByClassname']),
    ...mapState(useClipboardStore, ["getClipboardContentPageRow"]),
    AuthContext() {
      return AuthContext
    },
    existingDocumentKey(): string | null {
      return this.bgImgDto?.image_key ?? null;
    },
    enumBackgroundColorCode(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCmsEnum.BACKGROUND_COLOR);
    },
    enumContainerTypeCode(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCmsEnum.CONTAINER_TYPE);
    },
    enumHorizontalGutterCode(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCmsEnum.GUTTER);
    },
    enumItemsHorizontalAlignmentCode(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCmsEnum.ITEMS_HORIZONTAL_ALIGNMENT);
    },
    enumItemsVerticalAlignmentCode(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCmsEnum.ITEMS_VERTICAL_ALIGNMENT);
    },
    enumMarginCode(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCmsEnum.MARGIN);
    },
    enumPaddingCode(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCmsEnum.PADDING);
    },
    enumBgImgHorizontalPositionCode(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCmsEnum.BG_IMG_HOR_POSITION);
    },
    enumBgImgVerticalPositionCode(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCmsEnum.BG_IMG_VER_POSITION);
    },
    enumBgImgRepeatCode(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCmsEnum.BG_IMG_REPEAT);
    },
    enumBgImgSizeCode(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCmsEnum.BG_IMG_SIZE);
    },
    enumBgImgAttachmentCode(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCmsEnum.BG_IMG_ATTACHMENT);
    },
  },
  methods: {
    ...mapActions('cms_content_page_wysiwyg', ["saveContentPageRow"]),
    ...mapActions('cms_enum', ['findManyEnumByClassname']),
    ...mapActionsP(useClipboardStore, ["copyToClipboardContentPageRow"]),
    setHasUnsavedChanges() {
      this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.UNSAVED_CHANGES;
    },
    toggleBackgroundColorType(): void {
      this.isCustomColorPanelVisible = !this.isCustomColorPanelVisible;
    },
    async setImageKey(key: string | null): Promise<void> {
      if (this.bgImgDto) {
        if (key) {
          this.bgImgDto.image_key = key;
        }
      }

      if (key !== null) {
        this.imagePreviewUrl = await CmsDocumentRestService.getInstance().findPreviewUrl(key);
      } else {
        this.imagePreviewUrl = null;
      }

      // this.onChange();
    },
    onCopyRowMetadata(): void {
      if (this.row) {
        this.copyToClipboardContentPageRow(this.row);

        this.toaster.info(this.$t('cms.successfullyCopiedMetadata'));
      }
    },
    reloadNestedElements() {
      if (this.row !== null && this.row.background_color !== null && this.row.background_color !== undefined) {
        this.bgColorDto = this.row.background_color;
        this.isCustomColorPanelVisible = true;
      } else {
        this.bgColorDto = ColorRestDtoModel.createWithDefaults();
        this.isCustomColorPanelVisible = false;
      }
      if (this.row !== null && this.row.background_image !== null && this.row.background_image !== undefined) {
        this.bgImgDto = this.row.background_image;
        this.imagePreviewUrl = this.bgImgDto.image_url;
        this.isBgImgPanelVisible = true;
      } else {
        this.bgImgDto = BackgroundImageRestDtoModel.createWithDefaults();
        this.imagePreviewUrl = null;
        this.isBgImgPanelVisible = false;
      }
    },
    onPasteRowMetadata(): void {
      // When calling clone method directly on object, method is not found. So this is a hacky workaround
      if (this.row !== null && this.row !== undefined) {
        this.row = ContentPageRowRestDtoModel.cloneFrom(this.getClipboardContentPageRow as ContentPageRowRestDtoModel);
        this.reloadNestedElements();
      }

      this.toaster.info(this.$t('cms.successfullyPastedMetadata'));
      this.setHasUnsavedChanges();
    },
    async reloadContent(): Promise<void> {
      this.isReady = false;

      this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.NONE;

      await this.findManyEnumByClassname([
        EnumClassNameCmsEnum.BACKGROUND_COLOR,
        EnumClassNameCmsEnum.CONTAINER_TYPE,
        EnumClassNameCmsEnum.GUTTER,
        EnumClassNameCmsEnum.ITEMS_HORIZONTAL_ALIGNMENT,
        EnumClassNameCmsEnum.ITEMS_VERTICAL_ALIGNMENT,
        EnumClassNameCmsEnum.MARGIN,
        EnumClassNameCmsEnum.PADDING,
        EnumClassNameCmsEnum.BG_IMG_HOR_POSITION,
        EnumClassNameCmsEnum.BG_IMG_VER_POSITION,
        EnumClassNameCmsEnum.BG_IMG_SIZE,
        EnumClassNameCmsEnum.BG_IMG_REPEAT,
        EnumClassNameCmsEnum.BG_IMG_ATTACHMENT,
      ]);

      const wysiwygRow = this.getWysiwygContentPageRowById(this.rowId);

      this.contentId = wysiwygRow?.content_id ?? '';
      this.row = wysiwygRow?.row ?? null;
      this.reloadNestedElements();

      this.isReady = true;
    },
    async saveRow() {
      this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.BUSY;
      this.exceptions = [];

      if (this.row !== null && this.bgColorDto !== null) {
        if (this.isCustomColorPanelVisible) {
          // A specific color is picked
          this.row.background_color = this.bgColorDto;
        } else {
          this.row.background_color = null as unknown as ColorRestDtoModel;
          this.bgColorDto = ColorRestDtoModel.createWithDefaults();
        }
      }
      if (this.row !== null && this.bgImgDto !== null) {
        if (this.isBgImgPanelVisible && this.bgImgDto.image_key !== '') {
          this.row.background_image = this.bgImgDto;
        } else {
          this.row.background_image = null as unknown as BackgroundImageRestDtoModel;
          this.bgImgDto = BackgroundImageRestDtoModel.createWithDefaults();
        }
      }

      try {
        await this.saveContentPageRow({
          page_id: this.pageId,
          row_id: this.rowId,
          row: this.row,
        });

        this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.SUCCESS;
      } catch (exceptions: any) {
        this.exceptions = exceptions;
        this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.FAILED;
      }
    }
  },
});
