
import {defineComponent} from "vue";
import LibContentBlocksItemContentBlock
  from "@/components/layouts/lib-content-blocks/LibContentBlocksItemContentBlock.vue";
import LibContentBlocksItemBanner from "@/components/layouts/lib-content-blocks/LibContentBlocksItemBanner.vue";

export default defineComponent({
  name: "LibContentBlocksItem",
  components: {LibContentBlocksItemBanner, LibContentBlocksItemContentBlock},
  emits: ["copied"],
  props: {
    contentBlock: {
      type: Object,
      required: true,
    },
    libType: {
      type: String,
      required: true,
    },
  },
  methods: {
    onCopied(cbId: string) {
      this.$emit('copied', cbId);
    }
  }
});
