<template>
  <div class="callback">
      <BaseSpinner />
  </div>
</template>

<script>
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";

export default {
  components: {BaseSpinner},
  methods: {
    async handleOAuth2Callback() {
      const query = this.$route.query;

      if (query.code !== undefined) {
        await this.$store.dispatch('handleOAuth2V2Callback', query);
      }

      this.$router.push('/home');
    },
  },
  mounted() {
      this.handleOAuth2Callback();
  },
};
</script>
