import {FindNormalContentBlockPayload} from "@/models/payload/FindNormalContentBlockPayload";
import {ContentBlockEndpointsApiService} from "@/main";
import {SaveNormalContentBlockPayload} from "@/models/payload/SaveNormalContentBlockPayload";
import {CreateNormalContentBlockPayload} from "@/models/payload/CreateNormalContentBlockPayload";
import {
    TextWithSymbolContentRestDtoModel
} from "@/models/api/pharma-cms-content-block/TextWithSymbolContentRestDtoModel";

export default {
    namespaced: true,
    actions: {
        async create(context: any, payload: CreateNormalContentBlockPayload): Promise<string> {
            console.log('TextWithSymbol::create()');
            const response = await ContentBlockEndpointsApiService.createNormalContentBlockTextWithSymbol(payload.cb as TextWithSymbolContentRestDtoModel, payload.type);

            return response.data.id as string;
        },
        async find(context: any, payload: FindNormalContentBlockPayload): Promise<TextWithSymbolContentRestDtoModel> {
            console.log('TextWithSymbol::find()');
            const response = await ContentBlockEndpointsApiService.getNormalContentBlockTextWithSymbol(payload.type, payload.id);

            return response.data as TextWithSymbolContentRestDtoModel;
        },
        async save(context: any, payload: SaveNormalContentBlockPayload): Promise<void> {
            console.log('TextWithSymbol::save()');
            await ContentBlockEndpointsApiService.updateNormalContentBlockTextWithSymbol(payload.cb as TextWithSymbolContentRestDtoModel, payload.type, payload.id);
        },
    }
};
