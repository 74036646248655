<template>
  <div class="d-flex justify-content-center align-content-center align-items-center flex-column">
    <h2>Pagina niet gevonden!</h2>
    <router-link to="/">Ga naar home</router-link>
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>
.d-flex {
  height: 100vh !important;
}
h2 {
  color: #cccccc;
}
</style>