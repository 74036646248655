import {defineStore} from "pinia";

interface deliveryMethodEnumContent {
    selectedDeliveryTypeCode: string|null;
}

interface State {
    deliveryMethodEnumContent: deliveryMethodEnumContent;
    selectedDeliveryMethodCode: string|null;
}

export const usePharmacyStore = defineStore("PharmacyStore", {
    state: (): State => {
        return {
            deliveryMethodEnumContent: {
                selectedDeliveryTypeCode: null,
            },
            selectedDeliveryMethodCode: null
        }
    },
    actions: {
        async initialize(): Promise<void> {
        },
    },
    getters: {},
});
