import {RestService} from "@/services/rest/RestService";
import {PaymentProviderEndpointsApi} from "@/api/pharma-pay-payment";
import {CodeDescriptionRestDtoModel} from "@/models/CodeDescriptionRestDtoModel";
import {PaymentProviderSettingsRestDto} from "@/api/pharma-pay-payment/models";

export class PaymentProviderRestService extends RestService {

    private apiService: PaymentProviderEndpointsApi;

    protected constructor() {
        super();

        this.apiService = new PaymentProviderEndpointsApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().payUrl);
    }

    public async findAvailablePaymentProviders(pharmacy_code: string): Promise<CodeDescriptionRestDtoModel[]> {
        const response = await this.apiService.getAvailablePaymentProviders(pharmacy_code);

        return response.data;
    }

    public async findPaymentProviderSettings(pharmacy_code: string, payment_provider_code: string): Promise<PaymentProviderSettingsRestDto> {
        const response = await this.apiService.getPaymentProviderSettings(pharmacy_code, payment_provider_code);

        return response.data;
    }

    public async updatePaymentProviderSettings(payment_provider_settings: PaymentProviderSettingsRestDto, pharmacy_code: string, payment_provider_code: string): Promise<void> {
        const response = await this.apiService.updatePaymentProviderSettings(payment_provider_settings, pharmacy_code, payment_provider_code);

        return response.data;
    }

    public async disconnectPaymentProvider(pharmacy_code: string, payment_provider_code: string): Promise<void> {
        const response = await this.apiService.disconnectPaymentProvider(pharmacy_code, payment_provider_code);

        return response.data;
    }
}
