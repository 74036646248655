import {PagedSnippetInSlotOverviewRestDto} from "@/api/pharma-cms-snippet/models";
import {SnippetInSlotOverviewRestDtoModel} from "@/models/api/pharma-cms-snippet/SnippetInSlotOverviewRestDtoModel";

export class PagedSnippetInSlotOverviewRestDtoModel implements PagedSnippetInSlotOverviewRestDto {
    results: SnippetInSlotOverviewRestDtoModel[];

    constructor(results: SnippetInSlotOverviewRestDtoModel[]) {
        this.results = results;
    }

    public static createWithDefaults(): PagedSnippetInSlotOverviewRestDtoModel {
        return new this([]);
    }
}
