import {ErrorHandler} from "@/error/ErrorHandler";
import {DpExceptions} from "@/error/DpExceptions";
import {Exception} from "@/api/interfaces";
import {UIStateDto} from "@/dtos/UIStateDto";

export class DpExceptionsErrorHandler extends ErrorHandler {
    qualify(error: unknown): boolean {
        return error instanceof DpExceptions;
    }

    public static createWithDefaultUIStateBehavior(uiStateDto: UIStateDto): DpExceptionsErrorHandler {
        return new DpExceptionsErrorHandler((error: any) => {
            uiStateDto.setError(DpExceptionsErrorHandler.getHtmlMessageFromError(error));
        });
    }

    public static getHtmlMessageFromError(error: DpExceptions): string {
        const errorMessages: string[] = [];

        error.exceptions.forEach((e: Exception) => {
            errorMessages.push(e.message + ' (' + e.code + ')');
        });

        return errorMessages.join('<br />');
    }
}
