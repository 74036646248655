import {
    OrcaReservationCampaignContentRestDto,
    OrcaReservationCampaignRestDto,
    OrcaReservationCampaignRestDtoStatusCodeEnum
} from "@/api/pharma-order-orca-customer-mgmt/models";
import {stripHtmlTags} from "@/helpers/functions/string";
import {DpException} from "@/exception/DpException";

function parseSearchedReservationCampaign(reservation_campaign: OrcaReservationCampaignRestDto): OrcaReservationCampaignRestDto {
    const parsed_reservation_campaign = JSON.parse(JSON.stringify(reservation_campaign)) as OrcaReservationCampaignRestDto;

    if (parsed_reservation_campaign.description === null) {
        parsed_reservation_campaign.description = '';
    }

    return parsed_reservation_campaign;
}

function parseSearchedReservationCampaignContent(reservation_campaign_content: OrcaReservationCampaignContentRestDto): OrcaReservationCampaignContentRestDto {
    const parsed_reservation_campaign_content = JSON.parse(JSON.stringify(reservation_campaign_content)) as OrcaReservationCampaignContentRestDto;

    if (parsed_reservation_campaign_content.description_before === null) {
        parsed_reservation_campaign_content.description_before = '';
    }
    if (parsed_reservation_campaign_content.description_during === null) {
        parsed_reservation_campaign_content.description_during = '';
    }
    if (parsed_reservation_campaign_content.description_after === null) {
        parsed_reservation_campaign_content.description_after = '';
    }
    if (parsed_reservation_campaign_content.remark_description === null) {
        parsed_reservation_campaign_content.remark_description = '';
    }
    if (parsed_reservation_campaign_content.extensive_explanation === null) {
        parsed_reservation_campaign_content.extensive_explanation = '';
    }
    if (parsed_reservation_campaign_content.ext_agenda_email_notifications_description === null) {
        parsed_reservation_campaign_content.ext_agenda_email_notifications_description = '';
    }
    if (parsed_reservation_campaign_content.ext_agenda_sms_notifications_description === null) {
        parsed_reservation_campaign_content.ext_agenda_sms_notifications_description = '';
    }
    if (parsed_reservation_campaign_content.ext_agenda_webpage_description === null) {
        parsed_reservation_campaign_content.ext_agenda_webpage_description = '';
    }

    return parsed_reservation_campaign_content;
}

function parseSavedReservationCampaign(reservation_campaign: OrcaReservationCampaignRestDto, reservation_campaign_content?: OrcaReservationCampaignContentRestDto): OrcaReservationCampaignRestDto {
    const parsed_reservation_campaign = JSON.parse(JSON.stringify(reservation_campaign)) as OrcaReservationCampaignRestDto;

    if (stripHtmlTags(parsed_reservation_campaign.description).length < 1) {
        parsed_reservation_campaign.description = null;
    }

    if (reservation_campaign.status_code === OrcaReservationCampaignRestDtoStatusCodeEnum.ACTIVE) {
        let canBeActivated = true as boolean;

        if (!reservation_campaign_content) {
            canBeActivated = false;
        } else if (parsed_reservation_campaign.begin_date !== null && stripHtmlTags(reservation_campaign_content.description_before).length < 1) {
            canBeActivated = false;
        } else if (parsed_reservation_campaign.end_date !== null && stripHtmlTags(reservation_campaign_content.description_after).length < 1) {
            canBeActivated = false;
        }

        if (!canBeActivated) {
            //TODO ask error handling
            throw [new DpException('Je kan de campagne pas activeren als je de nodige inhoud hebt ingevuld. Is dit een nieuwe campagne? Sla dan eerst op, hierna kan je de inhoud aanvullen.')];
        }
    }

    return parsed_reservation_campaign;
}

function parseSavedReservationCampaignContent(reservation_campaign_content: OrcaReservationCampaignContentRestDto, reservation_campaign: OrcaReservationCampaignRestDto): OrcaReservationCampaignContentRestDto {
    const parsed_reservation_campaign_content = JSON.parse(JSON.stringify(reservation_campaign_content)) as OrcaReservationCampaignContentRestDto;

    if (stripHtmlTags(parsed_reservation_campaign_content.description_during).length < 1) {
        //TODO ask error handling
        throw [new DpException('Vul een korte uitleg in bij "Inhoud tijdens reservatieperiode", onder campagne inhoud')];
    }
    if (reservation_campaign.begin_date && stripHtmlTags(parsed_reservation_campaign_content.description_before).length < 1) {
        //TODO ask error handling
        throw [new DpException('Vul een korte uitleg in bij "Inhoud voor reservatieperiode", onder campagne inhoud')];
    }
    if (reservation_campaign.end_date && stripHtmlTags(parsed_reservation_campaign_content.description_after).length < 1) {
        //TODO ask error handling
        throw [new DpException('Vul een korte uitleg in bij "Inhoud na reservatieperiode", onder campagne inhoud')];
    }

    if (parsed_reservation_campaign_content.title !== null && parsed_reservation_campaign_content.title.length < 1) {
        parsed_reservation_campaign_content.title = null;
    }
    if (parsed_reservation_campaign_content.menu_label !== null && parsed_reservation_campaign_content.menu_label.length < 1) {
        parsed_reservation_campaign_content.menu_label = null;
    }
    if (parsed_reservation_campaign_content.ext_agenda_url !== null && parsed_reservation_campaign_content.ext_agenda_url.length < 1) {
        parsed_reservation_campaign_content.ext_agenda_url = null;
    }
    if (stripHtmlTags(parsed_reservation_campaign_content.description_before).length < 1) {
        parsed_reservation_campaign_content.description_before = null;
    }
    if (stripHtmlTags(parsed_reservation_campaign_content.description_after).length < 1) {
        parsed_reservation_campaign_content.description_after = null;
    }
    if (stripHtmlTags(parsed_reservation_campaign_content.remark_description).length < 1) {
        parsed_reservation_campaign_content.remark_description = null;
    }
    if (stripHtmlTags(parsed_reservation_campaign_content.extensive_explanation).length < 1) {
        parsed_reservation_campaign_content.extensive_explanation = null;
    }
    if (stripHtmlTags(parsed_reservation_campaign_content.ext_agenda_email_notifications_description).length < 1) {
        parsed_reservation_campaign_content.ext_agenda_email_notifications_description = null;
    }
    parsed_reservation_campaign_content.ext_agenda_sms_notifications_description = stripHtmlTags(parsed_reservation_campaign_content.ext_agenda_sms_notifications_description);
    if (parsed_reservation_campaign_content.ext_agenda_sms_notifications_description.length < 1) {
        parsed_reservation_campaign_content.ext_agenda_sms_notifications_description = null;
    }
    if (stripHtmlTags(parsed_reservation_campaign_content.ext_agenda_webpage_description).length < 1) {
        parsed_reservation_campaign_content.ext_agenda_webpage_description = null;
    }

    return parsed_reservation_campaign_content;
}

export {
    parseSearchedReservationCampaign,
    parseSearchedReservationCampaignContent,
    parseSavedReservationCampaign,
    parseSavedReservationCampaignContent
}