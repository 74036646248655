import {FindNormalContentBlockPayload} from "@/models/payload/FindNormalContentBlockPayload";
import {ContentBlockEndpointsApiService} from "@/main";
import {SaveNormalContentBlockPayload} from "@/models/payload/SaveNormalContentBlockPayload";
import {CreateNormalContentBlockPayload} from "@/models/payload/CreateNormalContentBlockPayload";
import {ProductFilterContentRestDtoModel} from "@/models/ProductFilterContentRestDtoModel";

export default {
    namespaced: true,
    actions: {
        async create(context: any, payload: CreateNormalContentBlockPayload): Promise<string> {
            console.log('ProductFilter::create()');
            const response = await ContentBlockEndpointsApiService.createNormalContentBlockProductFilter(payload.cb as ProductFilterContentRestDtoModel, payload.type);

            return response.data.id as string;
        },
        async find(context: any, payload: FindNormalContentBlockPayload): Promise<ProductFilterContentRestDtoModel> {
            console.log('ProductFilter::find()');
            const response = await ContentBlockEndpointsApiService.getNormalContentBlockProductFilter(payload.type, payload.id);

            return response.data as ProductFilterContentRestDtoModel;
        },
        async save(context: any, payload: SaveNormalContentBlockPayload): Promise<void> {
            console.log('ProductFilter::save()');
            await ContentBlockEndpointsApiService.updateNormalContentBlockProductFilter(payload.cb as ProductFilterContentRestDtoModel, payload.type, payload.id);
        },
    }
};
