import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BannerSlotOverview = _resolveComponent("BannerSlotOverview", true)!

  return (_openBlock(), _createBlock(_component_BannerSlotOverview, {
    "is-library": "",
    "route-object-edit-cb": {name: 'content-provider-banner-slot-edit'},
    description: _ctx.$t('descriptionBannerSlotOverview'),
    title: _ctx.$t('bannerSlots')
  }, null, 8, ["description", "title"]))
}