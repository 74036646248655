
import {defineComponent, ref} from "vue";
import {Exception} from "@/api/interfaces";
import {PageLinkTypeEnum} from "@/models/enum/PageLinkTypeEnum";
import {generateDomId, generateUUIDv4, slugify} from "@/helpers/functions/string";
import {mapActions} from "vuex";
import CpAbstractsOverview from "@/components/layouts/content-pages/overview/CpAbstractsOverview.vue";
import {LinkTargetEnum} from "@/models/enum/LinkTargetEnum";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-enum/models";
import {RelativeUrlRestDto} from "@/api/pharma-cms-menu/models";
import {MenuRestService} from "@/services/rest/cms-menu/MenuRestService";
import MultilingualSwitcher from "@/components/UI/MultilingualSwitcher.vue";
import {I18nContext} from "@/context/I18nContext";
import I18nInputText from "@/components/UI/I18n/I18nInputText.vue";
import {I18nPageLinkPayload} from "@/models/payload/I18nPageLinkPayload";
import {I18nTargetPageInfoDto} from "@/dtos/i18n/I18nTargetPageInfoDto";
import {StatusEnum} from "@/api/enums/status-enum";

export default defineComponent({
  name: "I18nPageLinkPicker",
  emits: ["changed", "changedText", "exceptions"],
  components: {I18nInputText, MultilingualSwitcher, CpAbstractsOverview},
  props: {
    payload: {
      type: I18nPageLinkPayload,
      required: true,
    },
    enableNoLink: {
      type: Boolean,
      required: false,
      default: true
    },
    enableTarget: {
      type: Boolean,
      required: false,
      default: true
    },
    enableUrl: {
      type: Boolean,
      required: false,
      default: true
    },
    enableAltLangPages: {
      type: Boolean,
      required: false,
      default: false
    },
    enableSelectUrl: {
      type: Boolean,
      required: false,
      default: false
    },
    errors: {
      type: Object,
    }
  },
  data() {
    return {
      isReady: false as boolean,
      uuid: generateUUIDv4(),
      linkedContentPage: new I18nTargetPageInfoDto(),
      relativeUrls: [] as RelativeUrlRestDto[],
      selectedRelativeUrlOption: null as string | null,
      extLinkOptions: [
        {code: LinkTargetEnum.BLANK, description: this.$t('newWindow')},
        {code: LinkTargetEnum.SELF, description: this.$t('currentWindow')},
      ] as CodeDescriptionRestDto[],
      activeLanguage: I18nContext.getDefaultLanguage() as string,
    }
  },
  setup(props) {
    const internalPayload = ref(I18nPageLinkPayload.cloneFrom(props.payload));
    const modalId = generateDomId('modal');

    return {
      internalPayload,
      modalId,
    };
  },
  mounted() {
    this.reloadContent();
  },
  watch: {
    internalPayload: {
      handler() {
        this.$emit('changed', this.internalPayload);
      },
      deep: true,
    }
  },
  computed: {
    StatusEnum() {
      return StatusEnum
    },
    PageLinkTypeEnum() {
      return PageLinkTypeEnum;
    },
    relativeUrlsOptions(): CodeDescriptionRestDto[] {
      if (this.enableUrl && this.enableSelectUrl) {
        let relativeUrlsOptions = [] as CodeDescriptionRestDto[];

        this.relativeUrls.forEach((relative_url: RelativeUrlRestDto) => {
          relativeUrlsOptions.push({
            code: slugify(relative_url.name),
            description: relative_url.name,
          });
        });

        return relativeUrlsOptions;
      }

      return [];
    },
  },
  methods: {
    ...mapActions('cms_content_page', ['searchContentPageOverviewCb']),
    clearPageId(): void {
      this.linkedContentPage[this.activeLanguage] = null;
      this.internalPayload.page_id[this.activeLanguage] = null;
    },
    async getLinkedContentPage(lang: string, pageId: string): Promise<void> {
      try {
        this.linkedContentPage[lang] = await this.searchContentPageOverviewCb(pageId);
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }
    },
    isPageLinkType(type: PageLinkTypeEnum): boolean {
      return this.internalPayload.type === type;
    },
    async reloadContent(): Promise<void> {
      try {
        if (this.isPageLinkType(PageLinkTypeEnum.PAGE)) {
          let promises: Promise<void>[] = [];
          I18nContext.getLanguages().forEach(lang => {
            if (this.internalPayload.page_id[lang] !== null) {
              promises.push(this.getLinkedContentPage(lang, this.internalPayload.page_id[lang] ?? ''));
            }
          });
          await Promise.all(promises);
        }
        if (this.enableUrl && this.enableSelectUrl) {
          this.relativeUrls = await MenuRestService.getInstance().findRelativeUrls();
        }
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }
    },
    setExceptions(exceptions: Exception[]): void {
      this.$emit('exceptions', exceptions);
    },
    setLinkType(type: PageLinkTypeEnum): void {
      this.internalPayload.type = type;
    },
    setPageId(id: string): void {
      this.internalPayload.page_id[this.activeLanguage] = id;
      this.getLinkedContentPage(this.activeLanguage, id);
    },
    setRelativeUrl(): void {
      const selectedRelativeUrl = this.relativeUrls.find((relative_url: RelativeUrlRestDto) => slugify(relative_url.name) === this.selectedRelativeUrlOption) as RelativeUrlRestDto;

      if (selectedRelativeUrl) {
        this.internalPayload.external_url[this.activeLanguage] = selectedRelativeUrl.url;
        this.internalPayload.page_id[this.activeLanguage] = null;
        this.setLinkType(PageLinkTypeEnum.URL);

        this.$emit('changedText', selectedRelativeUrl.name);
      }
    },
  }
});
