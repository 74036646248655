import {CmsBannerImageRestDto} from "@/api/pharma-cms-content-block/models";

export class CmsBannerImageRestDtoModel implements CmsBannerImageRestDto {
    content_size_code: string;
    image_key: string;
    language_code: string | null;

    constructor(content_size_code: string, image_key: string, language_code: string | null) {
        this.content_size_code = content_size_code;
        this.image_key = image_key;
        this.language_code = language_code;
    }
}
