import {StarterBrandOverviewRestDto, StarterBrandRestDto, StarterDiscountRestDto} from "@/api/pharma-cpc-mgmt/models";
import {CpcStarterSettingsEndpointsApiService} from "@/main";
import {parseSavedNumber, parseSavedRequiredNumber, parseSearchedNumber} from "@/helpers/functions/number";

export default {
    state() {
        return {
            starter_brand_overview: {} as StarterBrandOverviewRestDto,
            starter_discount: {} as StarterDiscountRestDto,
        };
    },
    actions: {
        async addStarterBrand(context: any, brand_code: string) {
            // console.log('Adding brand: ' + brand_code);
            const body = {brand_code: brand_code} as StarterBrandRestDto;
            await CpcStarterSettingsEndpointsApiService.addBrandToStarterProductSelection(body);
        },
        async saveStarterDiscount(context: any, payload: any) {
            const body = JSON.parse(JSON.stringify(payload.body)) as StarterDiscountRestDto;
            body.discount_percentage = parseSavedRequiredNumber(body.discount_percentage);

            //TODO fix type in call name
            await CpcStarterSettingsEndpointsApiService.updateStaterDiscount(body);
        },
        async searchStarterBrandOverview(context: any, search_name?: string) {
            const response = await CpcStarterSettingsEndpointsApiService.findBrandsOfStarterProductSelection(search_name);
            context.commit('setStarterBrandOverview', {body: response.data});
        },
        async searchStarterDiscount(context: any) {
            const response = await CpcStarterSettingsEndpointsApiService.getDiscountPercentage();
            response.data.discount_percentage = parseSearchedNumber(response.data.discount_percentage);

            context.commit('setStarterDiscount', {body: response.data});
        },
        async deleteStarterBrand(context: any, brand_code: string) {
            await CpcStarterSettingsEndpointsApiService.deleteBrandFromStarterProductSelection(brand_code);
        },
    },
    mutations: {
        setStarterBrandOverview(state: any, payload: any) {
            state.starter_brand_overview = payload.body;
        },
        setStarterDiscount(state: any, payload: any) {
            state.starter_discount = payload.body;
        },
    },
    getters: {
        getStarterBrandOverview(state: any): StarterBrandOverviewRestDto[] {
            return state.starter_brand_overview;
        },
        getStarterDiscount(state: any): StarterDiscountRestDto[] {
            return state.starter_discount;
        },
    },
}
