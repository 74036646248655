import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-023f8a19"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-inline-block mt-3 pt-4 border-top border-2 border-light" }
const _hoisted_2 = {
  type: "submit",
  class: "btn btn-primary"
}
const _hoisted_3 = {
  key: 0,
  class: "spinner-border spinner-border-sm ms-2",
  role: "status",
  "aria-hidden": "true"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomerProductPropertiesItem = _resolveComponent("CustomerProductPropertiesItem")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_dp_form = _resolveComponent("dp-form")!

  return (_openBlock(), _createBlock(_component_dp_form, {
    as: "form",
    onSubmit: _ctx.submitData,
    novalidate: ""
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productProperties, (productProperty) => {
        return (_openBlock(), _createBlock(_component_CustomerProductPropertiesItem, {
          key: productProperty,
          "product-property": productProperty
        }, null, 8, ["product-property"]))
      }), 128)),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.$t('save')) + " ", 1),
          (_ctx.isSubmitting)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_router_link, {
          to: {name: _ctx.returnRoute},
          class: "ms-3 btn btn-outline-primary"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}