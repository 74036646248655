
import {defineComponent} from "vue";
import LibContentBlocks from "@/components/layouts/lib-content-blocks/LibContentBlocks.vue";
import {generateUUIDv4} from "@/helpers/functions/string";
import {LibraryOverviewRestDtoModel} from "@/models/api/pharma-cms-content-block-library/LibraryOverviewRestDtoModel";
import {arrayHasContent} from "@/helpers/functions/arrays";
import {
  ContentBlockLibraryRestService
} from "@/services/rest/cms-content-block/ContentBlockLibraryRestService";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {UIStateDto} from "@/dtos/UIStateDto";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import AlertInfo from "@/components/UI/Bootstrap/Alert/AlertInfo.vue";
import {default as Modal} from "bootstrap/js/dist/modal";

export default defineComponent({
  components: {AlertInfo, AlertError2, LibContentBlocks},
  emits: ["copied"],
  props: {
    cbType: {
      type: String,
      required: true,
    },
    class: {
      type: String,
      required: false,
    },
    srcLibCode: {
      type: String,
      required: false,
      default: null
    },
    title: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    modalId: generateUUIDv4('modal-content-library-available-') as string,
    modalContentLibraryAvailable: null as Modal | null,
    contentBlockLibsForType: [] as LibraryOverviewRestDtoModel[],
    contentLibraryAvailableUI: UIStateDto.createWithDefaults()
  }),
  mounted() {
    this.modalContentLibraryAvailable = new Modal('#' + this.modalId);
    this.reloadContent();
  },
  unmounted() {
    this.modalContentLibraryAvailable?.dispose();
  },
  computed: {
    hasContentBlockLibsForType(): boolean {
      return arrayHasContent(this.contentBlockLibsForType);
    },
    isLibrary(): boolean {
      return !!this.srcLibCode;
    }
  },
  methods: {
    async reloadContent(): Promise<void> {
      this.contentLibraryAvailableUI
        .clearError()
        .setNotReady();

      try {
        let filterIsOwner = undefined as boolean | undefined;
        if (this.isLibrary) {
          filterIsOwner = false;
        }

        this.contentBlockLibsForType = await ContentBlockLibraryRestService.getInstance()
          .findContentBlockLibsForType(this.cbType, filterIsOwner, undefined);
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.contentLibraryAvailableUI.setReady();
      }
    },
    setExceptions(exceptions: unknown) {
      this.contentLibraryAvailableUI.clearError();

      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.contentLibraryAvailableUI as UIStateDto))
        .catch(exceptions, true);
    }
  }
});
