
import {defineComponent} from "vue";
import CbFormTextWithImage from "@/components/layouts/content-blocks/forms/CbFormTextWithImage.vue";

export default defineComponent({
  name: "StandardHomepageContentBlocksDetail",
  components: {CbFormTextWithImage},
  props: ['mode'],
  data() {
    return {
      cbId: this.$route.params.id as String,
    };
  },
  mounted() {
    const menuItem = document.getElementById('content-detail');
    if (menuItem != null) {
      menuItem.classList.add('active', 'router-link-exact-active');
    }
  }
});
