import {DpNameValuePairValueRestDto} from "@/api/pharma-master-nvp/models";

export class DpNameValuePairValueRestDtoModel implements DpNameValuePairValueRestDto {
    customer_code?: string | null;
    pharmacy_code?: string | null;
    value?: string | null;

    constructor(customer_code: string, value: string) {
        this.customer_code = customer_code;
        this.value = value;
    }
}
