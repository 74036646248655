import {RestService} from "@/services/rest/RestService";
import {ShoppingCartDiscountSchemeAPIApi} from "@/api/pharma-cpc-mgmt";
import {ShoppingCartDiscountSchemeRestDto} from "@/api/pharma-cpc-mgmt/models";

export class ShoppingCartDiscountSchemeRestService extends RestService {

    private apiService: ShoppingCartDiscountSchemeAPIApi;

    protected constructor() {
        super();

        this.apiService = new ShoppingCartDiscountSchemeAPIApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cpcUrl);
    }

    public async createShoppingCartDiscountScheme(body: ShoppingCartDiscountSchemeRestDto): Promise<void> {
        await this.apiService.createShoppingCartDiscountScheme(body);
    }

    public async updateShoppingCartDiscountScheme(id: string, body: ShoppingCartDiscountSchemeRestDto): Promise<void> {
        await this.apiService.updateShoppingCartDiscountScheme(body, id);
    }
}
