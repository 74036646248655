
import {defineComponent} from "vue";
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {CatalogProductsRestService} from "@/services/rest/cpc-mgmt/CatalogProductsRestService";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {CatalogProductDetailRestDtoModel} from "@/models/api/pharma-cpc-product-mgmt/CatalogProductDetailRestDtoModel";
import ProductDetailPricesTab from "@/components/layouts/catalog/product-detail/ProductDetailPricesTab.vue";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import ProductDetailCategoriesTab from "@/components/layouts/catalog/product-detail/ProductDetailCategoriesTab.vue";
import ProductDetailPhotosTab from "@/components/layouts/catalog/product-detail/ProductDetailPhotosTab.vue";
import ProductDetailPimTab from "@/components/layouts/catalog/product-detail/ProductDetailPimTab.vue";
import CatalogBuildStatus from "@/components/layouts/catalog/CatalogBuildStatus.vue";
import ProductDetailCustomerProductTab
  from "@/components/layouts/catalog/product-detail/ProductDetailCustomerProductTab.vue";
import CategoryWithParents from "@/components/layouts/catalog/CategoryWithParents.vue";

export default defineComponent({
  name: "CatalogProductDetail",
  components: {
    CategoryWithParents,
    CatalogBuildStatus,
    AlertError2,
    BaseTitle,
    ProductDetailCustomerProductTab,
    ProductDetailPricesTab,
    ProductDetailCategoriesTab,
    ProductDetailPhotosTab,
    ProductDetailPimTab,
  },
  data() {
    return {
      returnRoute: {name: 'catalog-products-overview'} as object,
      product_code: this.$route.params.product_code as string,
      productDetailUI: UIStateDto.createWithDefaults(),

      product: CatalogProductDetailRestDtoModel.createWithDefaults(),

      currentComponent: 'product-detail-dummy-tab' as string,
    }
  },
  async mounted() {
    await this.reloadContent();
    this.setCurrentComponent('product-detail-prices-tab');
  },
  computed: {
    currentProperties(): object {
      switch (this.currentComponent) {
        case 'product-detail-pim-tab':
          return {
            "product-code" : this.product.product_code,
            "cnk-code" : this.product.product_info.cnk_code
          };
        default:
          return {
            "product-code" : this.product.product_code,
            "cnk-code" : this.product.product_info.cnk_code
          };
      }
    },

  },
  methods: {
    async reloadContent() {
      this.productDetailUI
        .setNotReady()
        .clearError();

      try {
        this.product = await CatalogProductsRestService.getInstance()
          .getCatalogProductDetail(this.product_code, 'nl');
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.productDetailUI.setReady();
      }
    },
    handleSavedSuccessfully,
    setCurrentComponent(cmp: string) {
      if (this.product.product_source_code === 'CUSTOMER') {
        // Detail scherm ondersteunt momenteel geen eigen producten
        this.currentComponent = 'product-detail-customer-product-tab';
      } else {
        this.currentComponent = cmp;
      }
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.productDetailUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.productDetailUI as UIStateDto))
        .catch(exceptions, true);
    }
  },
});
