
import {defineComponent} from "vue";
import {WysiwygContentBlockInRowRestDtoModel} from "@/models/WysiwygContentBlockInRowRestDtoModel";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import {mapGetters} from "vuex";
import {WysiwygContentBlockPreviewRestDto} from "@/api/pharma-cms-content-page/models";
import {UtilityRestService} from "@/services/rest/cms-utility/UtilityRestService";
import MetadataIcons from "@/components/layouts/content-pages/wysiwyg/misc/MetadataIcons.vue";
import {StatusEnum} from "@/api/enums/status-enum";
import {getDescriptionVisibilityRuleStatus} from "@/helpers/functions/visibility-rules";

export default defineComponent({
  name: "ContentPageColumn",
  components: {MetadataIcons},
  emits: ["activateColumn"],
  props: {
    column: {
      type: [WysiwygContentBlockInRowRestDtoModel, Object],
      required: true
    },
    rowId: {
      type: String,
      required: true
    },
    activeColumnId: {
      type: String,
      required: false
    },
    isInvisibleRow: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isReady: false as boolean,
      isShowPreview: false as boolean,
      cbPreviewContent: null as string | null,
      cbPreviewDescription: null as string | null,
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cms_enum', ['getCodeDescriptionByCodeFromEnumByClassname']),
    cbPreview(): WysiwygContentBlockPreviewRestDto {
      return this.column.cb_preview;
    },
    hasPreview(): boolean {
      return this.column.cb_preview && (this.column.cb_preview.description || this.column.cb_preview.title || this.column.cb_preview.content || this.column.cb_preview.button_text || this.column.cb_preview.image_url);
    },
    hasPreviewContent(): boolean {
      return this.column.cb_preview && (this.column.cb_preview.description || this.column.cb_preview.title || this.column.cb_preview.content || this.column.cb_preview.button_text);
    },
    hasPreviewImage(): boolean {
      return this.column.cb_preview && this.column.cb_preview.image_url;
    },
    isVisibleColumn(): boolean {
      return this.column.element_metadata?.status?.code === StatusEnum.PUBLISHED;
    },
    hasVisibilityRule(): boolean {
      return this.column.element_metadata?.visibility_rule_id;
    }
  },
  methods: {
    getDescriptionVisibilityRuleStatus,
    async reloadContent(): Promise<void> {
      this.isReady = false;

      try {
        if (this.column?.cb_preview?.content) {
          this.cbPreviewContent = await UtilityRestService.getInstance()
            .truncateHtml(this.cbPreview.content, 50);
        }
        if (this.column?.cb_preview?.description) {
          this.cbPreviewDescription = await UtilityRestService.getInstance()
            .truncateHtml(this.cbPreview.description, 50);
        }
      } catch (e) {
        console.log(e);
      }

      this.isReady = true;
    },
    activateColumn(columnId: string): void {
      this.$emit('activateColumn', columnId, this.rowId);
    },
    getContentBlockStructureDescription(structure_code: string): string {
      return this.getCodeDescriptionByCodeFromEnumByClassname(EnumClassNameCmsEnum.CONTENT_BLOCK_STRUCTURE, structure_code)?.description ?? structure_code;
    },
    setIsShowPreview(value: boolean): void {
      this.isShowPreview = value;
    }
  }
})
