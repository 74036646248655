/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Pharmacy API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface TeamMemberRestDto
 */
export interface TeamMemberRestDto {
    /**
     * First name.
     * @type {string}
     * @memberof TeamMemberRestDto
     */
    first_name?: any;
    /**
     * Last name.
     * @type {string}
     * @memberof TeamMemberRestDto
     */
    last_name?: any;
    /**
     * 
     * @type {MultilingualMax100FieldRestDto}
     * @memberof TeamMemberRestDto
     */
    function_title?: any;
    /**
     * 
     * @type {MultilingualFieldRestDto}
     * @memberof TeamMemberRestDto
     */
    content?: any;
    /**
     * Image key in the default language of the customer.
     * @type {string}
     * @memberof TeamMemberRestDto
     */
    image_key?: any;
    /**
     * The sorting value of this content block.
     * @type {number}
     * @memberof TeamMemberRestDto
     */
    sort_weight?: any;
    /**
     * The status of this content block.
     * @type {string}
     * @memberof TeamMemberRestDto
     */
    status_code: TeamMemberRestDtoStatusCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TeamMemberRestDtoStatusCodeEnum {
    PUBLISHED = 'PUBLISHED',
    NOTPUBLISHED = 'NOT_PUBLISHED'
}

