/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-PIM Labo API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { LaboProductDetailRestDto } from '../models';
import { PagedLaboProductOverviewResultsRestDto } from '../models';
import { LaboProductsCriteriaRestDto } from '../models';
/**
 * LaboProductAPIApi - axios parameter creator
 * @export
 */
export const LaboProductAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for retrieving the products.
         * @summary REST.API.LABO.PR.01 Find Labo products
         * @param {string} [accept] text/csv
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {number} [page] Specifies the page number that is expected of the paged result.
         * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
         * @param {string} [filter_brand_code] A &#x27;|&#x27; separated list of product brand codes used as search criterium.
         * @param {number} [filter_cnk_code] Filters the results to the specified CNK code.
         * @param {string} [filter_category_code] Filters the results to the specified category.
         * @param {boolean} [filter_is_image_available] If provided, only filters the results based on the availability of product images.
         * @param {boolean} [filter_is_commercially_available] If true, only returns products that are commercially available. If false, only returns products that are NOT commercially available.
         * @param {string} [search_keyword] A keyword used as search criterium
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLaboProducts: async (accept?: string|null, x_dp_customer_code?: string|null, x_dp_language?: string|null, page?: number|null, page_size?: number|null, filter_brand_code?: string|null, filter_cnk_code?: number|null, filter_category_code?: string|null, filter_is_image_available?: boolean|null, filter_is_commercially_available?: boolean|null, search_keyword?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/labo/v1/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (!!page || typeof(page) === "boolean") {
                localVarQueryParameter['page'] = page;
            }

            if (!!page_size || typeof(page_size) === "boolean") {
                localVarQueryParameter['page_size'] = page_size;
            }

            if (!!filter_brand_code || typeof(filter_brand_code) === "boolean") {
                localVarQueryParameter['filter_brand_code'] = filter_brand_code;
            }

            if (!!filter_cnk_code || typeof(filter_cnk_code) === "boolean") {
                localVarQueryParameter['filter_cnk_code'] = filter_cnk_code;
            }

            if (!!filter_category_code || typeof(filter_category_code) === "boolean") {
                localVarQueryParameter['filter_category_code'] = filter_category_code;
            }

            if (!!filter_is_image_available || typeof(filter_is_image_available) === "boolean") {
                localVarQueryParameter['filter_is_image_available'] = filter_is_image_available;
            }

            if (!!filter_is_commercially_available || typeof(filter_is_commercially_available) === "boolean") {
                localVarQueryParameter['filter_is_commercially_available'] = filter_is_commercially_available;
            }

            if (!!search_keyword || typeof(search_keyword) === "boolean") {
                localVarQueryParameter['search_keyword'] = search_keyword;
            }

            if (accept !== undefined && accept !== null) {
                localVarHeaderParameter['Accept'] = String(accept);
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving the product details.
         * @summary REST.API.LABO.PR.02 Retrieve product details
         * @param {number} cnk_code The product CNK code that uniquely identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLaboProductForCnkCode: async (cnk_code: number, x_dp_customer_code?: string|null, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cnk_code' is not null or undefined
            if (cnk_code === null || cnk_code === undefined) {
                throw new RequiredError('cnk_code','Required parameter cnk_code was null or undefined when calling getLaboProductForCnkCode.');
            }
            const localVarPath = `/labo/v1/product/{cnk_code}`
                .replace(`{${"cnk_code"}}`, encodeURIComponent(String(cnk_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LaboProductAPIApi - functional programming interface
 * @export
 */
export const LaboProductAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for retrieving the products.
         * @summary REST.API.LABO.PR.01 Find Labo products
         * @param {string} [accept] text/csv
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {number} [page] Specifies the page number that is expected of the paged result.
         * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
         * @param {string} [filter_brand_code] A &#x27;|&#x27; separated list of product brand codes used as search criterium.
         * @param {number} [filter_cnk_code] Filters the results to the specified CNK code.
         * @param {string} [filter_category_code] Filters the results to the specified category.
         * @param {boolean} [filter_is_image_available] If provided, only filters the results based on the availability of product images.
         * @param {boolean} [filter_is_commercially_available] If true, only returns products that are commercially available. If false, only returns products that are NOT commercially available.
         * @param {string} [search_keyword] A keyword used as search criterium
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findLaboProducts(_axios: AxiosInstance, accept?: string|null, x_dp_customer_code?: string|null, x_dp_language?: string|null, page?: number|null, page_size?: number|null, filter_brand_code?: string|null, filter_cnk_code?: number|null, filter_category_code?: string|null, filter_is_image_available?: boolean|null, filter_is_commercially_available?: boolean|null, search_keyword?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedLaboProductOverviewResultsRestDto>> {
            const localVarAxiosArgs = await LaboProductAPIApiAxiosParamCreator(configuration).findLaboProducts(accept, x_dp_customer_code, x_dp_language, page, page_size, filter_brand_code, filter_cnk_code, filter_category_code, filter_is_image_available, filter_is_commercially_available, search_keyword, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving the product details.
         * @summary REST.API.LABO.PR.02 Retrieve product details
         * @param {number} cnk_code The product CNK code that uniquely identifies the product.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLaboProductForCnkCode(_axios: AxiosInstance, cnk_code: number, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LaboProductDetailRestDto>> {
            const localVarAxiosArgs = await LaboProductAPIApiAxiosParamCreator(configuration).getLaboProductForCnkCode(cnk_code, x_dp_customer_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LaboProductAPIApi - object-oriented interface
 * @export
 * @class LaboProductAPIApi
 * @extends {BaseAPI}
 */
export class LaboProductAPIApi extends BaseAPI {
    /**
     * API endpoint responsible for retrieving the products.
     * @summary REST.API.LABO.PR.01 Find Labo products
     * @param {string} [accept] text/csv
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {number} [page] Specifies the page number that is expected of the paged result.
     * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
     * @param {string} [filter_brand_code] A &#x27;|&#x27; separated list of product brand codes used as search criterium.
     * @param {number} [filter_cnk_code] Filters the results to the specified CNK code.
     * @param {string} [filter_category_code] Filters the results to the specified category.
     * @param {boolean} [filter_is_image_available] If provided, only filters the results based on the availability of product images.
     * @param {boolean} [filter_is_commercially_available] If true, only returns products that are commercially available. If false, only returns products that are NOT commercially available.
     * @param {string} [search_keyword] A keyword used as search criterium
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LaboProductAPIApi
     */
     private findLaboProducts(accept?: string|null, x_dp_customer_code?: string|null, x_dp_language?: string|null, page?: number|null, page_size?: number|null, filter_brand_code?: string|null, filter_cnk_code?: number|null, filter_category_code?: string|null, filter_is_image_available?: boolean|null, filter_is_commercially_available?: boolean|null, search_keyword?: string|null, options?: any) {
        return LaboProductAPIApiFp(this.configuration).findLaboProducts(this.axios, accept, x_dp_customer_code, x_dp_language, page, page_size, filter_brand_code, filter_cnk_code, filter_category_code, filter_is_image_available, filter_is_commercially_available, search_keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving the products.
     * @summary REST.API.LABO.PR.01 Find Labo products
     * @param {LaboProductsCriteriaRestDto} [dto] The CriteriaRestDto for the api call.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LaboProductAPIApi
     */
    public findLaboProductsByLaboProductsCriteriaRestDto(dto: LaboProductsCriteriaRestDto, options?: any){
        return this.findLaboProducts(dto?.accept, dto?.x_dp_customer_code, dto?.x_dp_language, dto?.page, dto?.page_size, dto?.filter_brand_code, dto?.filter_cnk_code, dto?.filter_category_code, dto?.filter_is_image_available, dto?.filter_is_commercially_available, dto?.search_keyword, options);
    }
    /**
     * API endpoint responsible for retrieving the product details.
     * @summary REST.API.LABO.PR.02 Retrieve product details
     * @param {number} cnk_code The product CNK code that uniquely identifies the product.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LaboProductAPIApi
     */
     public getLaboProductForCnkCode(cnk_code: number, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any) {
        return LaboProductAPIApiFp(this.configuration).getLaboProductForCnkCode(this.axios, cnk_code, x_dp_customer_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

}
