import {FindNormalContentBlockPayload} from "@/models/payload/FindNormalContentBlockPayload";
import {ContentBlockEndpointsApiService} from "@/main";
import {SaveNormalContentBlockPayload} from "@/models/payload/SaveNormalContentBlockPayload";
import {CreateNormalContentBlockPayload} from "@/models/payload/CreateNormalContentBlockPayload";
import {TextWithTitleContentRestDtoModel} from "@/models/api/pharma-cms-content-block/TextWithTitleContentRestDtoModel";

export default {
    namespaced: true,
    actions: {
        async create(context: any, payload: CreateNormalContentBlockPayload): Promise<string> {
            console.log('TextWithTitle::create()');
            const response = await ContentBlockEndpointsApiService.createNormalContentBlockTextWithTitle(payload.cb as TextWithTitleContentRestDtoModel, payload.type);

            return response.data.id as string;
        },
        async find(context: any, payload: FindNormalContentBlockPayload): Promise<TextWithTitleContentRestDtoModel> {
            console.log('TextWithTitle::find()');
            const response = await ContentBlockEndpointsApiService.getNormalContentBlockTextWithTitle(payload.type, payload.id);

            return response.data as TextWithTitleContentRestDtoModel;
        },
        async save(context: any, payload: SaveNormalContentBlockPayload): Promise<void> {
            console.log('TextWithTitle::save()');
            await ContentBlockEndpointsApiService.updateNormalContentBlockTextWithTitle(payload.cb as TextWithTitleContentRestDtoModel, payload.type, payload.id);
        },
    }
};
