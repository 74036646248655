import {CmsWebsiteReferenceRestDto} from "@/api/pharma-cms-header/models";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";

export class CmsWebsiteReferenceRestDtoModel implements CmsWebsiteReferenceRestDto {
    image_key?: string | null;
    website_url?: MultilingualRestDtoModel | null;
    reference_text?: MultilingualRestDtoModel | null;


    constructor(image_key: string | null, website_url: MultilingualRestDtoModel | null, reference_text: MultilingualRestDtoModel | null) {
        this.image_key = image_key;
        this.website_url = website_url;
        this.reference_text = reference_text;
    }

    public static createWithDefaults(): CmsWebsiteReferenceRestDtoModel {
        return new this(
            null,
            MultilingualRestDtoModel.createWithDefaults(),
            MultilingualRestDtoModel.createWithDefaults(),
        );
    }
}
