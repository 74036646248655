import {RestService} from "@/services/rest/RestService";
import {PIMFeedbackAPIApi, PIMMgmtAPIApi} from "@/api/pharma-pim-mgmt";
import {
    FullProductInfoRestDto,
    NamedTextFragmentRestDto,
    PimFeedbackRestDto, ProductDetailsCoreRestDto,
    TextFragmentRestDto
} from "@/api/pharma-pim-mgmt/models";

export class PimMgmtRestService extends RestService {

    private apiService: PIMMgmtAPIApi;
    private feedbackApiService: PIMFeedbackAPIApi;

    protected constructor() {
        super();

        this.apiService = new PIMMgmtAPIApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().pimUrl);
        this.feedbackApiService = new PIMFeedbackAPIApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().pimUrl);
    }

    public async createPimFeedback(cnk_code: number, language_code: string, feedback: PimFeedbackRestDto): Promise<void> {
       await this.feedbackApiService.createPimFeedback(feedback, cnk_code, language_code);
    }

    public async findMgmtProductInfo(cnk_code: number, language_code: string, data_profile?: string | null): Promise<FullProductInfoRestDto> {
        const response = await this.apiService.findMgmtProductInfo(cnk_code, language_code, null, null, data_profile);

        return response.data;
    }

    public async updatePimDetailsCore(body: ProductDetailsCoreRestDto, productCode: string): Promise<void> {
        await this.apiService.updatePimDetailsCore(body, productCode);
    }

    public async createNamedTextFragment(cnk_code: number, language_code: string, fragment: NamedTextFragmentRestDto): Promise<void> {
        await this.apiService.createNamedTextFragment(fragment, cnk_code, language_code);
    }

    public async createTextFragment(cnk_code: number, language_code: string, fragment: TextFragmentRestDto): Promise<void> {
        await this.apiService.createTextFragment(fragment, cnk_code,language_code);
    }

    public async updateNamedTextFragment(fragmentId: string, fragment: NamedTextFragmentRestDto): Promise<void> {
        await this.apiService.updateNamedTextFragment(fragment, fragmentId);
    }

    public async updateTextFragment(fragmentId: string, fragment: TextFragmentRestDto): Promise<void> {
        await this.apiService.updateTextFragment(fragment, fragmentId);
    }

    public async deleteNamedTextFragment(fragmentId: string): Promise<void> {
        await this.apiService.deleteNamedTextFragment(fragmentId);
    }

    public async deleteTextFragment(fragmentId: string): Promise<void> {
        await this.apiService.deleteTextFragment(fragmentId);
    }
}
