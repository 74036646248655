/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Auth Users API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CodeDescriptionRestDto } from '../models';
/**
 * ClientGroupManagementEndpointsApi - axios parameter creator
 * @export
 */
export const ClientGroupManagementEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for retrieving the client group codes.
         * @summary REST.API.IDM.CG.01 Find the code list of client groups
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findClientGroupCodes: async (x_dp_customer_code?: string, x_dp_language?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cg_mgmt/v1/client_group/codelist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientGroupManagementEndpointsApi - functional programming interface
 * @export
 */
export const ClientGroupManagementEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for retrieving the client group codes.
         * @summary REST.API.IDM.CG.01 Find the code list of client groups
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findClientGroupCodes(_axios: AxiosInstance, x_dp_customer_code?: string, x_dp_language?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CodeDescriptionRestDto>>> {
            const localVarAxiosArgs = await ClientGroupManagementEndpointsApiAxiosParamCreator(configuration).findClientGroupCodes(x_dp_customer_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ClientGroupManagementEndpointsApi - object-oriented interface
 * @export
 * @class ClientGroupManagementEndpointsApi
 * @extends {BaseAPI}
 */
export class ClientGroupManagementEndpointsApi extends BaseAPI {
    /**
     * API endpoint responsible for retrieving the client group codes.
     * @summary REST.API.IDM.CG.01 Find the code list of client groups
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientGroupManagementEndpointsApi
     */
     public findClientGroupCodes(x_dp_customer_code?: string, x_dp_language?: string, options?: any) {
        return ClientGroupManagementEndpointsApiFp(this.configuration).findClientGroupCodes(this.axios, x_dp_customer_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

}
