import {RestService} from "@/services/rest/RestService";
import {CustomerAPIApi} from "@/api/pharma-content-customer";
import {ColorSchemeRestDto, LanguageSettingsRestDto} from "@/api/pharma-content-customer/models";

export class ContentCustomerRestService extends RestService {

    private apiService: CustomerAPIApi;

    protected constructor() {
        super();

        this.apiService = new CustomerAPIApi(this.getDpAxiosInstance(), undefined, this.getConfig().contentUrl);
    }

    public async findCustomerColorScheme(): Promise<ColorSchemeRestDto> {
        const response = await this.apiService.getCustomerColorScheme();

        return response.data;
    }

    public async findSupportedLanguages(): Promise<LanguageSettingsRestDto> {
        const response = await this.apiService.getSupportedLanguages();

        return response.data;
    }
}
