import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xxl-10 col-xl-12 mb-4" }
const _hoisted_3 = { class: "mb-4" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-xxl-4" }
const _hoisted_6 = { class: "col-xxl-8" }
const _hoisted_7 = { class: "d-inline-block mt-3 pt-4 border-top border-2 border-light" }
const _hoisted_8 = {
  type: "submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_alert_error = _resolveComponent("alert-error")!
  const _component_base_spinner = _resolveComponent("base-spinner")!
  const _component_MultilingualSwitcher = _resolveComponent("MultilingualSwitcher")!
  const _component_i18n_input_text = _resolveComponent("i18n-input-text")!
  const _component_i18n_input_image = _resolveComponent("i18n-input-image")!
  const _component_i18n_input_text_editor = _resolveComponent("i18n-input-text-editor")!
  const _component_input_select = _resolveComponent("input-select")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_dp_form = _resolveComponent("dp-form")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BaseTitle, {
            title: _ctx.$t('aboutUs')
          }, null, 8, ["title"]),
          _createVNode(_component_alert_error, {
            exceptions: _ctx.exceptions,
            class: "mt-3"
          }, null, 8, ["exceptions"]),
          (!this.isReady)
            ? (_openBlock(), _createBlock(_component_base_spinner, { key: 0 }))
            : _createCommentVNode("", true),
          (this.isReady)
            ? (_openBlock(), _createBlock(_component_dp_form, {
                key: 1,
                as: "form",
                novalidate: "",
                onSubmit: _ctx.saveTeamAbout
              }, {
                default: _withCtx(({ errors }) => [
                  _createVNode(_component_MultilingualSwitcher, {
                    errors: errors,
                    "active-language": this.activeLanguage,
                    onUpdateActiveLanguage: _cache[0] || (_cache[0] = newValue => this.activeLanguage = newValue)
                  }, null, 8, ["errors", "active-language"]),
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_i18n_input_text, {
                      data: this.teamAbout.title,
                      "active-language": this.activeLanguage,
                      label: _ctx.$t('title'),
                      errors: errors,
                      rules: "max:250",
                      name: "title"
                    }, null, 8, ["data", "active-language", "label", "errors"]),
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_i18n_input_image, {
                          data: this.teamAbout.image_key,
                          "active-language": this.activeLanguage,
                          label: _ctx.$t('image'),
                          "type-code": "TEAM_IMG"
                        }, null, 8, ["data", "active-language", "label"])
                      ]),
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_i18n_input_text_editor, {
                          data: this.teamAbout.content,
                          "active-language": this.activeLanguage,
                          label: _ctx.$t('content'),
                          errors: errors,
                          "show-language-indicator": "",
                          name: "content"
                        }, null, 8, ["data", "active-language", "label", "errors"])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_input_select, {
                      name: "cbStatus",
                      class: "fw-bold",
                      selectText: _ctx.$t('selectText'),
                      modelValue: _ctx.teamAbout.status_code,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.teamAbout.status_code) = $event)),
                      options: [{code: 'PUBLISHED', description: _ctx.$t('published'),},{code: 'NOT_PUBLISHED', description: _ctx.$t('notPublished'),},],
                      errors: errors
                    }, null, 8, ["selectText", "modelValue", "options", "errors"]),
                    _createElementVNode("button", _hoisted_8, _toDisplayString(_ctx.$t('save')), 1),
                    _createVNode(_component_router_link, {
                      class: "ms-3 btn btn-outline-primary",
                      to: "/"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }, 8, ["onSubmit"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}