/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */

import {ContentBlockMetadataRestDto} from ".";
import {TextWithImageContentRestDto} from ".";
import {BackgroundColorEnum} from "@/api/enums/background-color-enum";
import {PharmacyInfoContentLayoutEnum} from "@/api/enums/pharmacy-info-content-layout-enum";
import {StatusEnum} from "@/api/enums/status-enum";

/**
 * @export
 * @interface PharmacyInfoContentRestDto
 */
export interface PharmacyInfoContentRestDto {
    /**
     * The status of this content block.
     * @type {StatusEnum}
     * @memberof PharmacyInfoContentRestDto
     */
    status_code: StatusEnum;

    /**
     * The sorting value of this content block.
     * @type {number|null}
     * @memberof PharmacyInfoContentRestDto
     */
    sort_weight?: number|null;

    /**
     * Uniquely identifies the pharmacy for which information should be shown.
     * @type {string|null}
     * @memberof PharmacyInfoContentRestDto
     */
    pharmacy_code?: string|null;

    /**
     * Specifies the layout of this contentblock.
     * @type {PharmacyInfoContentLayoutEnum}
     * @memberof PharmacyInfoContentRestDto
     */
    layout_code: PharmacyInfoContentLayoutEnum;

    /**
     * 
     * @type {TextWithImageContentRestDto|null}
     * @memberof PharmacyInfoContentRestDto
     */
    twi?: TextWithImageContentRestDto|null;

    /**
     * When true, the contact info of the pharmacy should be shown.
     * @type {boolean}
     * @memberof PharmacyInfoContentRestDto
     */
    is_show_contactinfo: boolean;

    /**
     * When true, the company info of the pharmacy should be shown.
     * @type {boolean}
     * @memberof PharmacyInfoContentRestDto
     */
    is_show_companyinfo: boolean;

    /**
     * When true, the apb number of the pharmacy should be shown.
     * @type {boolean}
     * @memberof PharmacyInfoContentRestDto
     */
    is_show_apb_number: boolean;

    /**
     * When true, the pharmacy holder info of the pharmacy should be shown.
     * @type {boolean}
     * @memberof PharmacyInfoContentRestDto
     */
    is_show_pharmacy_holder: boolean;

    /**
     * When true, the opening hours title should be shown.
     * @type {boolean}
     * @memberof PharmacyInfoContentRestDto
     */
    is_show_openinghours_title: boolean;

    /**
     * When true, the opening hours for today of the pharmacy should be shown.
     * @type {boolean}
     * @memberof PharmacyInfoContentRestDto
     */
    is_show_openinghours_today: boolean;

    /**
     * When true, the opening hours for this week of the pharmacy should be shown.
     * @type {boolean}
     * @memberof PharmacyInfoContentRestDto
     */
    is_show_openinghours_week: boolean;

    /**
     * When true, the social media info of the pharmacy should be shown.
     * @type {boolean}
     * @memberof PharmacyInfoContentRestDto
     */
    is_show_social_media: boolean;

    /**
     * Specifies the background color of this row.
     * @type {BackgroundColorEnum|null}
     * @memberof PharmacyInfoContentRestDto
     */
    background_color_code?: BackgroundColorEnum|null;

    /**
     * 
     * @type {ContentBlockMetadataRestDto|null}
     * @memberof PharmacyInfoContentRestDto
     */
    cb_metadata?: ContentBlockMetadataRestDto|null;

}

/**
 * @export
 * @function clonePharmacyInfoContentRestDtoMetaData
 */
export function clonePharmacyInfoContentRestDtoMetaData(dto: PharmacyInfoContentRestDto, source: PharmacyInfoContentRestDto): void {
    dto.layout_code = source.layout_code;
    dto.is_show_contactinfo = source.is_show_contactinfo;
    dto.is_show_companyinfo = source.is_show_companyinfo;
    dto.is_show_apb_number = source.is_show_apb_number;
    dto.is_show_pharmacy_holder = source.is_show_pharmacy_holder;
    dto.is_show_openinghours_title = source.is_show_openinghours_title;
    dto.is_show_openinghours_today = source.is_show_openinghours_today;
    dto.is_show_openinghours_week = source.is_show_openinghours_week;
    dto.is_show_social_media = source.is_show_social_media;
    dto.background_color_code = source.background_color_code;
}
