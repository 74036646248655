/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum LinkTypeEnum {
    IMMUTABLE = 'IMMUTABLE',
    MUTABLE = 'MUTABLE',
    COPIED = 'COPIED',
}