<template>
  <div class="dropdown">
    <button
      class="dropdown-toggle btn btn-outline-primary px-2"
      type="button"
      id="localeChanger"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ $t($i18n.locale) }}
    </button>
    <ul class="dropdown-menu fs-6" aria-labelledby="localeChanger">
      <li v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`">
        <button class="dropdown-item" @click="changeLocale(locale)">{{ $t(locale) }}</button>
      </li>
    </ul>
  </div>
</template>

<script>
import {setLocale} from '@vee-validate/i18n';

export default {
  name: "LocaleChanger",
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
      setLocale(locale);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-menu {
  min-width: 4rem !important;
  left: inherit !important;
  right: 0 !important;
  text-align: center;

  .dropdown-item {
    &:hover {
      color: var(--dp-primary) !important;
    }
  }
}
</style>
