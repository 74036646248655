import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, KeepAlive as _KeepAlive, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 col-xl-11 col-xxl-9" }
const _hoisted_3 = { class: "mb-4" }
const _hoisted_4 = {
  class: "btn-group",
  role: "group",
  "aria-label": "Reservation campaign tabs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_alert_success = _resolveComponent("alert-success")!
  const _component_alert_error = _resolveComponent("alert-error")!
  const _component_base_spinner = _resolveComponent("base-spinner")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BaseTitle, {
            title: _ctx.$t('reservationCampaign'),
            mode: this.mode,
            mb: 5,
            "use-router-back": ""
          }, null, 8, ["title", "mode"]),
          _createElementVNode("nav", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                type: "button",
                class: _normalizeClass(["btn btn-outline-primary", {'active':_ctx.currentComponent === 'reservation-campaign-settings'}]),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setCurrentComponent('reservation-campaign-settings')))
              }, _toDisplayString(_ctx.$t('campaign')), 3),
              (_ctx.mode === 'edit')
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    class: _normalizeClass(["btn btn-outline-primary", {'active':_ctx.currentComponent === 'reservation-campaign-content'}]),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setCurrentComponent('reservation-campaign-content')))
                  }, _toDisplayString(_ctx.$t('campaignContent')), 3))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_alert_success, {
              "show-if": _ctx.savedSuccessfully,
              class: "mt-3"
            }, null, 8, ["show-if"]),
            _createVNode(_component_alert_error, {
              exceptions: _ctx.exceptions,
              class: "mt-3"
            }, null, 8, ["exceptions"])
          ]),
          (!this.isReady)
            ? (_openBlock(), _createBlock(_component_base_spinner, { key: 0 }))
            : _createCommentVNode("", true),
          (this.isReady)
            ? (_openBlock(), _createBlock(_KeepAlive, { key: 1 }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(this.currentComponent), _mergeProps(this.currentProperties, {
                  onSubmit: this.submitData
                }), null, 16, ["onSubmit"]))
              ], 1024))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}