
import {defineComponent} from "vue";
import {SelectableProductRestDto} from "@/api/pharma-cpc-mgmt/models";
import ProductSelectionProductsSelectionItemBadge
  from "@/components/layouts/catalog/product-selection/ProductSelectionProductsSelectionItemBadge.vue";
import ProductSelectionProductsSelectionItemPreview
  from "@/components/layouts/catalog/product-selection/ProductSelectionProductsSelectionItemPreview.vue";
import {stringHasContent} from "@/helpers/functions/string";
import {PropType} from "vue/dist/vue";
import {AbilityContext} from "@/context/AbilityContext";
import {SelectionTypeEnum} from "@/api/enums/selection-type-enum";

export default defineComponent({
  name: "ProductSelectionProductsSelectionItem",
  components: {ProductSelectionProductsSelectionItemPreview, ProductSelectionProductsSelectionItemBadge},
  emits: ["updateProductItem", "openOffCanvasProductDetail"],
  props: {
    productItem: {
      type: Object as PropType<SelectableProductRestDto>,
      required: true,
    },
    filterSelectionTypeCode: {
      type: String,
      required: false,
      default: undefined,
    }
  },
  data() {
    return {
      showPreview: false as boolean
    }
  },
  computed: {
    hasProducts(): Boolean {
      return this.productItem.base_selection_product_count > 0 || this.productItem.customer_catalog_product_count > 0;
    },
    hasMainImageUrl(): boolean {
      return stringHasContent(this.productItem?.main_image_url);
    },
  },
  methods: {
    updateProductItem(product_code: string, selection_type: string) {
      let selection_type_code = '' as SelectionTypeEnum;
      switch (selection_type) {
        case 'BASE_SELECTION':
          selection_type_code = SelectionTypeEnum.BASE_SELECTION;
          break;
        case 'INCLUDE':
          selection_type_code = SelectionTypeEnum.INCLUDE;
          break;
        case 'EXCLUDE':
          selection_type_code = SelectionTypeEnum.EXCLUDE;
          break;
      }

      this.$emit('updateProductItem', product_code, selection_type_code);
    },
    openOffCanvasProductDetail(): void {
      if (AbilityContext.isAuthorizedForFeature('PIM_PRODUCT_INFO_GET_AS_PHARMACIST')) {
        this.$emit("openOffCanvasProductDetail", this.productItem);
      }
    },
  }
});
