import {RestService} from "@/services/rest/RestService";
import {CacheService} from "@/services/cache/CacheService";
import {CacheKeyEnum} from "@/services/cache/CacheKeyEnum";
import {ProductBrandAPIApi, ProductCategoryAPIApi, ProductTrademarkAPIApi} from "@/api/pharma-cpc-product";
import {CodeDescriptionRestDto, ProductCategoryOverviewRestDto, ProductCategoryRestDto} from "@/api/pharma-cpc-product/models";
import {CustomerContext} from "@/context/CustomerContext";

export class ProductRestService extends RestService {

    private trademarkApiService: ProductTrademarkAPIApi;
    private brandApiService: ProductBrandAPIApi;
    private categoryApiService: ProductCategoryAPIApi;

    protected constructor() {
        super();

        this.trademarkApiService = new ProductTrademarkAPIApi(this.getDpAxiosInstance(), undefined, this.getConfig().cpcProductUrl);
        this.brandApiService = new ProductBrandAPIApi(this.getDpAxiosInstance(), undefined, this.getConfig().cpcProductUrl);
        this.categoryApiService = new ProductCategoryAPIApi(this.getDpAxiosInstance(), undefined, this.getConfig().cpcProductUrl);
    }

    public async getAllTrademarks(customerCode: string): Promise<Array<CodeDescriptionRestDto>> {
        const nonNullCustomerCode = customerCode ?? CustomerContext.getCustomerCode();
        let result: Array<CodeDescriptionRestDto> =
            CacheService.getInstance().get(CacheKeyEnum.PRODUCT_TRADEMARKS, nonNullCustomerCode);
        if (result) {
            return result;
        }

        const response = await this.trademarkApiService.findProductTrademarks(nonNullCustomerCode);
        result = response.data;
        CacheService.getInstance().set(CacheKeyEnum.PRODUCT_TRADEMARKS, result, nonNullCustomerCode);

        return result;
    }

    public async getAllBrands(customerCode: string): Promise<Array<CodeDescriptionRestDto>> {
        const nonNullCustomerCode = customerCode ?? CustomerContext.getCustomerCode();
        let result: Array<CodeDescriptionRestDto> =
            CacheService.getInstance().get(CacheKeyEnum.PRODUCT_BRANDS, nonNullCustomerCode);
        if (result) {
            return result;
        }

        const response = await this.brandApiService.findProductBrands(nonNullCustomerCode);
        result = response.data;
        CacheService.getInstance().set(CacheKeyEnum.PRODUCT_BRANDS, result, nonNullCustomerCode);

        return result;
    }

    public async getAllCategoriesCustomer(customerCode: string): Promise<Array<ProductCategoryOverviewRestDto>> {
        const nonNullCustomerCode = customerCode ?? CustomerContext.getCustomerCode();
        let result: Array<ProductCategoryOverviewRestDto> =
            CacheService.getInstance().get(CacheKeyEnum.ALL_CATEGORIES, nonNullCustomerCode);
        if (result) {
            return result;
        }

        const response = await this.categoryApiService.findProductCategories(nonNullCustomerCode);
        result = response.data;
        CacheService.getInstance().set(CacheKeyEnum.ALL_CATEGORIES, result, nonNullCustomerCode);

        return result;
    }

    public async getProductCategoriesTreeCustomer(customerCode: string): Promise<ProductCategoryRestDto> {
        const nonNullCustomerCode = customerCode ?? CustomerContext.getCustomerCode();
        let result: ProductCategoryRestDto = CacheService.getInstance().get(CacheKeyEnum.PRODUCT_CATEGORIES_TREE, nonNullCustomerCode);
        if (result) {
            return result;
        }

        const response = await this.categoryApiService.findProductCategoriesTree(nonNullCustomerCode);
        result = response.data;
        CacheService.getInstance().set(CacheKeyEnum.PRODUCT_CATEGORIES_TREE, result, nonNullCustomerCode);

        return result;
    }
}
