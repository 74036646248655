
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {stringHasContent} from "@/helpers/functions/string";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";

export default defineComponent({
  name: "ProductSelectionProductsFilters",
  emits: ["submit"],
  computed: {
    ...mapGetters('cpc_mgmt', ['getSearchProduct']),
    searchQuery(): any {
      return this.getSearchProduct;
    },
    searchQueryCnkCode: {
      get(): string | undefined {
        return this.getSearchProduct.cnk_code;
      },
      set(value: any) {
        this.setPropertyValue('cnk_code', value)
      },
    },
    searchQueryProductName: {
      get(): string | undefined {
        return this.getSearchProduct.product_name;
      },
      set(value: any) {
        this.setPropertyValue('product_name', value)
      },
    },
    searchQueryBrandName: {
      get(): string | undefined {
        return this.getSearchProduct.brand_name;
      },
      set(value: any) {
        this.setPropertyValue('brand_name', value)
      },
    },
    searchQueryTrademarkName: {
      get(): string | undefined {
        return this.getSearchProduct.trademark_name;
      },
      set(value: any) {
        this.setPropertyValue('trademark_name', value)
      },
    },
    searchQueryUsageTypeCode: {
      get(): string | undefined {
        return this.getSearchProduct.usage_type_code;
      },
      set(value: any) {
        this.setPropertyValue('usage_type_code', value)
      },
    },
    searchQueryIsMedicine: {
      get(): boolean {
        return this.getSearchProduct.is_medicine;
      },
      set(value: any) {
        this.setSearchProductProperty({
          property: 'is_medicine',
          value: value
        });
      },
    },
    searchQueryIsMedicalAid: {
      get(): boolean {
        return this.getSearchProduct.is_medical_aid;
      },
      set(value: any) {
        this.setSearchProductProperty({
          property: 'is_medical_aid',
          value: value
        });
      },
    },
    searchQueryIsPrescriptionRequired: {
      get(): boolean {
        return this.getSearchProduct.is_prescription_required;
      },
      set(value: any) {
        this.setSearchProductProperty({
          property: 'is_prescription_required',
          value: value
        });
      },
    },
    usageTypeOptions(): CodeDescriptionRestDto[] {
      const FilterUsageTypeCodesEnum = ["HUMAN", "PHYTOTHERAPY", "VETERINARY"] as string[];
      const usageTypeOptions = [] as CodeDescriptionRestDto[];

      FilterUsageTypeCodesEnum.forEach((FilterUsageTypeCode: string) => {
        usageTypeOptions.push(
          {
            code: FilterUsageTypeCode,
            description: this.$t(FilterUsageTypeCode.toLowerCase())
          }
        );
      });

      return usageTypeOptions;
    }
  },
  mounted() {
    this.clearSearchProduct();
  },
  methods: {
    ...mapActions('cpc_mgmt', [
      'clearSearchProduct',
      'setSearchProductProperty',
    ]),
    async clearSearch(property?: string) {
      this.clearSearchProduct(property);
      if (!property) {
        this.$emit('submit');
      }
    },
    hasSearchQuery(property: string): boolean {
      if (property in this.searchQuery) {
        return stringHasContent(this.searchQuery[property]);
      }
      return false;
    },
    setPropertyValue(property: string, value: any) {
      if (value && value.length > 0) {
        this.setSearchProductProperty({
          property: property,
          value: value
        });
      } else {
        this.clearSearchProduct(property);
      }
    }
  }
});
