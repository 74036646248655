import {RestService} from "@/services/rest/RestService";
import {TeamMemberEndpointsApi} from "@/api/pharma-cms-pharmacy";
import {TeamMemberRestDtoModel} from "@/models/TeamMemberRestDtoModel";
import {TeamMemberOverviewRestDtoModel} from "@/models/TeamMemberOverviewRestDtoModel";
import {IdResponseRestDto} from "@/api/pharma-cms-pharmacy/models";

export class TeamMemberRestService extends RestService {
    private apiService: TeamMemberEndpointsApi;

    protected constructor() {
        super();

        this.apiService = new TeamMemberEndpointsApi(this.getDpAxiosInstance(), undefined, this.getConfig().cmsUrl);
    }

    public async findTeamMembers(): Promise<TeamMemberOverviewRestDtoModel[]> {
        const response = await this.apiService.findTeamMembers(this.getPharmacyCode());

        return response.data as TeamMemberOverviewRestDtoModel[];
    }

    public async findTeamMember(id: string): Promise<TeamMemberRestDtoModel> {
        const response = await this.apiService.getTeamMember(id, this.getPharmacyCode());

        return response.data as TeamMemberRestDtoModel;
    }

    public async createTeamMember(teamMember: TeamMemberRestDtoModel): Promise<string> {
        const response = await this.apiService.createTeamMember(teamMember, this.getPharmacyCode());
        const dto = response.data as IdResponseRestDto;

        return dto.id;
    }

    public async updateTeamMember(id: string, teamMember: TeamMemberRestDtoModel): Promise<void> {
        await this.apiService.updateTeamMember(teamMember, id, this.getPharmacyCode());
    }

    public async deleteTeamMember(id: string): Promise<void> {
        await this.apiService.deleteTeamMember(id, this.getPharmacyCode());
    }
}
