import {
    CustomerProductRestDto,
    MultilingualMax50FieldRestDto,
    MultilingualProductPropertyRestDto,
    MultilingualTextFieldRestDto
} from "@/api/pharma-cpc-product-mgmt/models";
import {StatusEnum} from "@/api/enums/status-enum";
import {UsageTypeEnum} from "@/api/enums/usage-type-enum";
import {
    MultilingualTextFieldRestDtoModel
} from "@/models/api/pharma-cpc-product-mgmt/MultilingualTextFieldRestDtoModel";

export class CustomerProductRestDtoModel implements CustomerProductRestDto {
    category_codes?: string[] | null;
    cnk_code: string;
    description?: MultilingualTextFieldRestDto | null;
    main_image_key?: MultilingualMax50FieldRestDto | null;
    name: string;
    price_with_vat: number;
    product_code?: string | null;
    product_properties?: MultilingualProductPropertyRestDto[] | null;
    status_code: StatusEnum;
    trademark_code?: string | null;
    usage_type_code?: UsageTypeEnum | null;
    vat_percentage: number;



    constructor() {
        this.cnk_code = '';
        this.name = '';
        this.price_with_vat = 0.00;
        this.vat_percentage = 0.00;
        this.status_code = StatusEnum.PUBLISHED;
        this.description = MultilingualTextFieldRestDtoModel.createWithDefaults();
        this.main_image_key = MultilingualTextFieldRestDtoModel.createWithDefaults();
        this.category_codes = [];
    }

    public static createWithDefaults(): CustomerProductRestDtoModel {
        return new this();
    }
}
