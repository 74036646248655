import {RestService} from "@/services/rest/RestService";
import {CustomerCRUDApi} from "@/api/pharma-customer";
import {CodeDescriptionRestDto, CustomerOverviewRestDto} from "@/api/pharma-customer/models";
import {CustomerCriteriaRestDtoModel} from "@/models/api/pharma-customer/CustomerCriteriaRestDtoModel";

export class CustomerCRUDRestService extends RestService {

    private apiService: CustomerCRUDApi;

    constructor() {
        super();

        this.apiService = new CustomerCRUDApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().customerUrl);
    }

    async findCustomerOverview(criteria: CustomerCriteriaRestDtoModel): Promise<CustomerOverviewRestDto[]> {
        const response = await this.apiService
            .findCustomerByCustomerCriteriaRestDto(criteria);

        return _fillCustomerOverviewLicenses(response.data);
    }
}


function _fillCustomerOverviewLicenses(customer_overview: CustomerOverviewRestDto[]): CustomerOverviewRestDto[] {
    const parsed_customer_overview = JSON.parse(JSON.stringify(customer_overview));

    parsed_customer_overview.forEach((customer_overview_item: CustomerOverviewRestDto) => {
        if (customer_overview_item.main_license === null || customer_overview_item.main_license === undefined) {
            customer_overview_item.main_license = {
                code: '',
                description: ''
            } as CodeDescriptionRestDto;
        }
    });

    return parsed_customer_overview;
}
