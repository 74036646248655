/*
* Overview of available enums: https://gitlab.com/digital-pharma-dp3/pharma-cms/-/tree/master/application/src/Model/Enum
*/
import {BackgroundImageAttachmentEnum} from "@/api/enums/background-image-attachment-enum";
import {BackgroundImageHorizontalPositionEnum} from "@/api/enums/background-image-horizontal-position-enum";
import {BackgroundImageRepeatEnum} from "@/api/enums/background-image-repeat-enum";
import {BackgroundImageSizeEnum} from "@/api/enums/background-image-size-enum";
import {BackgroundImageVerticalPositionEnum} from "@/api/enums/background-image-vertical-position-enum";

export enum EnumClassNameCmsEnum {
    BACKGROUND_COLOR = "BackgroundColorEnum",
    BANNER_CATEGORY = "BannerCategoryEnum",
    BG_IMG_HOR_POSITION = "BackgroundImageHorizontalPositionEnum",
    BG_IMG_VER_POSITION = "BackgroundImageVerticalPositionEnum",
    BG_IMG_SIZE = "BackgroundImageSizeEnum",
    BG_IMG_REPEAT = "BackgroundImageRepeatEnum",
    BG_IMG_ATTACHMENT = "BackgroundImageAttachmentEnum",
    BORDER_TYPE = "BorderTypeEnum",
    BORDER_WIDTH = "BorderWidthEnum",
    BUTTON_LAYOUT = "ButtonLayoutEnum",
    CONTAINER_TYPE = "ContainerTypeEnum",
    COLUMN_HORIZONTAL_ALIGNMENT = "SingleHorizontalAlignmentEnum",
    COLUMN_SIZE = "ColumnSizeEnum",
    CONTENT_BLOCK_LINK_TYPE = "ContentBlockLinkTypeEnum",
    CONTENT_BLOCK_STRUCTURE = "ContentBlockStructureEnum",
    CONTENT_BLOCK_TYPE = "ContentBlockTypeEnum",
    CONTENT_SLOT_SIZE = "ContentSlotSizeEnum",
    CONTENT_STATUS = "ContentStatusEnum",
    CONTENT_VISIBILITY_RULE = "ContentVisibilityRuleEnum",
    DOCUMENT_TYPE = "DocumentTypeEnum",
    GUTTER = "GutterEnum",
    HORIZONTAL_IMAGE_SHIFT = "HorizontalImageShiftEnum",
    IMAGE_HEIGHT = "ImageHeightEnum",
    IMAGE_WIDTH = "ImageWidthEnum",
    ITEMS_HORIZONTAL_ALIGNMENT = "ItemsHorizontalAlignmentEnum",
    ITEMS_VERTICAL_ALIGNMENT = "ItemsVerticalAlignmentEnum",
    LIGHTBOX = "LigthboxEnum",
    LINK_TARGET = "LinkTargetEnum",
    MARGIN = "MarginEnum",
    MENU_SLOT = "MenuSlotEnum",
    OBJECT_FIT = "ObjectFitEnum",
    OBJECT_HORIZONTAL_POSITION = "ObjectHorizontalPositionEnum",
    OBJECT_VERTICAL_POSITION = "ObjectVerticalPositionEnum",
    OPENING_HOURS_TYPE = "OpeningHoursTypeEnum",
    ORDER_DIRECTION = "OrderDirectionEnum",
    OVERVIEW_PAGE_LAYOUT = "OverviewPageLayoutEnum",
    PADDING = "PaddingEnum",
    PAGE_REFS_ORDER_FIELD = "PageRefsOrderFieldEnum",
    PAGE_REFS_VISIBILITY = "PageRefsVisibilityEnum",
    SNIPPET_SLOT_POSITION = "App\\Model\\Enum\\SnippetSlotPositionEnum",
    SYMBOL_FAMILY_ENUM = "SymbolFamilyEnum",
    SYMBOL_GRADE_ENUM = "SymbolGradeEnum",
    SYMBOL_OPTICAL_SIZE_ENUM = "SymbolOpticalSizeEnum",
    SYMBOL_WEIGHT_ENUM = "SymbolWeightEnum",
    TEXT_WITH_IMAGE_LAYOUT = "TextWithImageLayoutEnum",
    TITLE_SIZE = "TitleSizeEnum",
    T_SHIRT_SIZE = "TShirtSizeEnum",
    VERTICAL_IMAGE_SHIFT = "VerticalImageShiftEnum",
}
