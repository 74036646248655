
import {defineComponent, PropType} from 'vue'
import SelectBrandsSelected from "@/components/layouts/catalog/SelectBrandsSelected.vue";
import {mapActions, mapGetters} from "vuex";
import {Exception} from "@/api/interfaces";
import {stringHasContent} from "@/helpers/functions/string";
import {CodeDescriptionRestDto} from "@/api/pharma-cpc-product/models";
import {arrayHasContent} from "@/helpers/functions/arrays";
import {Config} from "@/models/facade/Config";
import {PimProductBrandRestService} from "@/services/rest/pim-pp2/PimProductBrandRestService";

export default defineComponent({
  name: "SelectBrands",
  components: {SelectBrandsSelected},
  emits: ["addBrand", "removeBrand"],
  props: {
    selectedBrandCodes: {
      type: Array as PropType<string[]>,
      required: true
    },
    btnClass: {
      type: String,
      required: false,
      default: 'btn btn-icon btn-primary border-0'
    },
    catalogCustomerCode: {
      type: String,
      required: false,
    },
    isPimLabo: {
      type: Boolean,
      required: false,
      default: false
    },
    isPimProducts: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      exceptions: [] as Exception[],
      isReadySearch: true as boolean,

      searchQuery: '' as string,
      pimLaboBrands: [] as CodeDescriptionRestDto[],
    }
  },
  mounted() {
    this.clearSearch();
  },
  computed: {
    ...mapGetters('cpc_product', ['getProductBrands']),
    searchedBrands(): CodeDescriptionRestDto[] {
      if (arrayHasContent(this.selectedBrandCodes)) {
        if (this.isPimLabo) {
          return this.pimLaboBrands.filter((brand: CodeDescriptionRestDto) => {
            return !brand.code.includes(this.selectedBrandCodes);
          });
        } else {
          return this.getProductBrands.filter((brand: CodeDescriptionRestDto) => {
            return !brand.code.includes(this.selectedBrandCodes);
          });
        }
      }

      if (this.isPimLabo) {
        return this.pimLaboBrands;
      }
      return this.getProductBrands;
    },
    hasSearchedBrands(): boolean {
      return arrayHasContent(this.searchedBrands);
    },
    hasSearchQuery(): boolean {
      return stringHasContent(this.searchQuery);
    },
  },
  methods: {
    ...mapActions('cpc_product', ['clearProductBrands', 'searchProductBrands']),
    addBrand(brand_code: string): void {
      this.$emit('addBrand', brand_code);

      this.clearSearch();
    },
    removeBrand(brand_code: string): void {
      this.$emit('removeBrand', brand_code);
    },
    submitSearch(): void {
      if (this.searchQuery.length === 0) {
        this.clearSearch();
      } else if (this.searchQuery.length >= 2) {
        this.searchBrands();
      }
    },
    clearSearch(): void {
      this.searchQuery = '';
      if (!this.isPimLabo) {
        this.clearProductBrands();
      }
    },
    async searchBrands(): Promise<void> {
      this.isReadySearch = false;
      this.exceptions = [];

      try {
        if (this.isPimLabo) {
          this.pimLaboBrands = await PimProductBrandRestService.getInstance()
            .findPp2ProductBrands(this.searchQuery);
        } else {
          const searchPayload = {
            search_name: this.searchQuery,
            language: this.$i18n.locale,
          } as any;
          if (this.isPimProducts) {
            searchPayload['customer_code'] = Config.getInstance().getConfig().STARTER_CATALOG_CUSTOMER_CODE;
          }

          await this.searchProductBrands(searchPayload);
        }
      } catch (exceptions: any) {
        this.exceptions.push(exceptions);
      } finally {
        this.isReadySearch = true;
      }
    },
  }
})
