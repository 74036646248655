
import CbFormDocument from "@/components/layouts/content-blocks/forms/CbFormDocument.vue";
import {CustomerContext} from "@/context/CustomerContext";

export default {
  computed: {
    CustomerContext() {
      return CustomerContext
    }
  },
  components: {CbFormDocument}
}
