import {MultilingualMax100FieldRestDto, MultilingualProductLabelRestDto} from "@/api/pharma-cpc-mgmt/models";
import {ProductLabelPositionEnum} from "@/api/enums/product-label-position-enum";
import {ProductLabelLayoutEnum} from "@/api/enums/product-label-layout-enum";
import {SchemeColorEnum} from "@/api/enums/scheme-color-enum";
import {BaseColorRestDto} from "@/api/interfaces/base-color-rest-dto";
import {MultilingualTextFieldRestDtoModel} from "@/models/api/pharma-cpc-product-mgmt/MultilingualTextFieldRestDtoModel";

export class MultilingualProductLabelRestDtoModel implements MultilingualProductLabelRestDto {

    content: MultilingualMax100FieldRestDto;
    position_code: ProductLabelPositionEnum;
    layout_code: ProductLabelLayoutEnum;
    scheme_color_code: SchemeColorEnum;
    background_color?: BaseColorRestDto;
    text_color?: BaseColorRestDto;

    constructor(position_code: ProductLabelPositionEnum,
                layout_code: ProductLabelLayoutEnum,
                scheme_color_code: SchemeColorEnum) {
        this.content = MultilingualTextFieldRestDtoModel.createWithDefaults();
        this.position_code = position_code;
        this.layout_code = layout_code;
        this.scheme_color_code = scheme_color_code;
    }

    static createWithDefaultsForDiscountScheme(): MultilingualProductLabelRestDtoModel {
        return new this(
            ProductLabelPositionEnum.TOP_LEFT,
            ProductLabelLayoutEnum.DEFAULT,
            SchemeColorEnum.PRIMARY,
        );
    }

    static createWithDefaultsForProductFilter(): MultilingualProductLabelRestDtoModel {
        return new this(
            ProductLabelPositionEnum.BOTTOM_LEFT,
            ProductLabelLayoutEnum.DEFAULT,
            SchemeColorEnum.SECONDARY,
        );
    }
}
