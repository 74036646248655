
import {defineComponent} from "vue";
import LazyImage from "@/components/UI/LazyImage.vue";

export default defineComponent({
  name: "LibContentBlocksItemBanner",
  components: {LazyImage},
  emits: ["copied"],
  props: {
    contentBlock: {
      type: Object,
      required: true,
    },
    libType: {
      type: String,
      required: true,
    },
  },
  computed: {
    imageKey(): string | null {
      if (this.libType === 'BANNER') {
        return this.contentBlock.cb?.banner_images[0]?.image_key;
      } else if (this.libType === 'BANNER_IN_SLOT') {
        return this.contentBlock?.image_key;
      }
      return null;
    }
  }
});
