import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-857111d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "badge-group rounded-pill" }
const _hoisted_2 = ["title"]
const _hoisted_3 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: "badge bg-warning",
      "data-bs-toggle": "tooltip",
      ref: _ctx.refBadgeBs,
      title: _ctx.tooltipTitle('bs')
    }, _toDisplayString(_ctx.trademarkItem.base_selection_product_count), 9, _hoisted_2),
    _createElementVNode("span", {
      class: "badge bg-success",
      "data-bs-toggle": "tooltip",
      ref: _ctx.refBadgeCc,
      title: _ctx.tooltipTitle('cc')
    }, _toDisplayString(_ctx.trademarkItem.customer_catalog_product_count), 9, _hoisted_3)
  ]))
}