import {stringHasContent} from "@/helpers/functions/string";
import {PageReferenceRestDto} from "@/api/pharma-cms-menu/models";

/*
* Checks if a page reference has an external url
*/
function hasExternalPageReference(page_reference: PageReferenceRestDto | null | undefined): boolean {
    return !!page_reference?.external_url && stringHasContent(page_reference.external_url);
}

/*
* Checks if a page reference has an internal page reference
*/
function hasInternalPageReference(page_reference: PageReferenceRestDto | null | undefined): boolean {
    return !!page_reference?.internal_page_reference && stringHasContent(page_reference.internal_page_reference.page_id);
}


export {
    hasExternalPageReference,
    hasInternalPageReference
};
