import {CpcPriceListEndpointsApiService} from "@/main";
import {
    PricelistOverviewRestDto,
    PricelistRestDto,
} from "@/api/pharma-cpc-mgmt/models";
import {formatDateObjectToDateString} from "@/helpers/functions/date-time";
import {MoveActionEnum} from "@/models/enum/MoveActionEnum";
import {emptyMultilingualTextField} from "@/helpers/functions/multilingual";
import {StatusEnum} from "@/api/enums/status-enum";


export default {
    state() {
        return {
            price_list: {} as PricelistRestDto,
            price_lists: [] as PricelistOverviewRestDto[],
        };
    },
    actions: {
        clearPriceList(context: any) {
            context.commit('clearPriceList');
        },
        clearPriceListPeriod(context: any) {
            context.commit('clearPriceListPeriod');
        },
        setPriceListValue(context: any, payload: any) {
            context.commit('setPriceListValue', {
                key: payload.key as string,
                value: payload.value as boolean | string | number,
            });
        },
        async createPriceList(context: any, price_list: PricelistRestDto) {
            await CpcPriceListEndpointsApiService.createPricelist(price_list);
        },
        async deletePriceList(context: any, id: string) {
            await CpcPriceListEndpointsApiService.deletePricelist(id);
        },
        async movePriceList(context: any, payload: any) {
            const id = payload.id as string;
            const moveAction = payload.move_action as MoveActionEnum;

            // console.log('moving list ' + moveAction + ' with id: ' + id);
            switch (moveAction) {
                case MoveActionEnum.TOP:
                    await CpcPriceListEndpointsApiService.movePricelistToTop(id);
                    break;
                case MoveActionEnum.BOTTOM:
                    await CpcPriceListEndpointsApiService.movePricelistToBottom(id);
                    break;
                case MoveActionEnum.UP:
                    await CpcPriceListEndpointsApiService.movePricelistUp(id);
                    break;
                case MoveActionEnum.DOWN:
                    await CpcPriceListEndpointsApiService.movePricelistDown(id);
                    break;
                default:
                    throw new Error('Unsupported moveAction to call ' + moveAction);
            }
        },
        async savePriceList(context: any, payload: any) {
            const id = payload.id as string;

            await CpcPriceListEndpointsApiService.updatePricelist(payload.price_list, id);
        },
        async searchPriceList(context: any, id: string) {
            const response = await CpcPriceListEndpointsApiService.getPricelist(id);

            context.commit('setPriceList', response.data);
        },
        async searchPriceLists(context: any) {
            const response = await CpcPriceListEndpointsApiService.findPricelists(undefined);

            context.commit('setPriceLists', response.data);
        },
    },
    mutations: {
        clearPriceList(state: any) {
            state.price_list = {
                name: '',
                client_group_code: null,
                discount_message: emptyMultilingualTextField(),
                is_standard_price_visible: false,
                is_discount_visible: false,
                begin_date: formatDateObjectToDateString(new Date()),
                end_date: null,
                status_code: StatusEnum.NOT_PUBLISHED,
            } as PricelistRestDto;
        },
        clearPriceListPeriod(state: any) {
            state.price_list.begin_date = formatDateObjectToDateString(new Date());
            state.price_list.end_date = undefined;
        },
        setPriceList(state: any, price_list: PricelistRestDto) {
            state.price_list = price_list;
        },
        setPriceListValue(state: any, payload: any) {
            state.price_list[payload.key as string] = payload.value as boolean | string | number;
        },
        setPriceLists(state: any, price_lists: PricelistOverviewRestDto[]) {
            state.price_lists = price_lists;
        }
    },
    getters: {
        getPriceList(state: any): PricelistRestDto {
            return state.price_list;
        },
        getPriceLists(state: any): PricelistOverviewRestDto[] {
            return state.price_lists;
        }
    },
}
