
import {defineComponent} from "vue";
import {mapActions} from "vuex";
import {SelectableProductRestDto} from "@/api/pharma-cpc-mgmt/models";
import ProductSelectionProductsSelection
  from "@/components/layouts/catalog/product-selection/ProductSelectionProductsSelection.vue";
import ProductSelectionProductsFilters
  from "@/components/layouts/catalog/product-selection/ProductSelectionProductsFilters.vue";
import {Exception} from "@/api/interfaces";
import AlertError from "@/components/UI/Bootstrap/Alert/AlertError.vue";
import BaseOffcanvas from "@/components/UI/Bootstrap/Offcanvas/BaseOffcanvas.vue";
import ProductSelectionProductsProductDetail
  from "@/components/layouts/catalog/product-selection/ProductSelectionProductsProductDetail.vue";
import {default as Offcanvas} from "bootstrap/js/dist/offcanvas";
import {generateDomId} from "@/helpers/functions/string";
import {AbilityContext} from "@/context/AbilityContext";
import {SelectionTypeEnum} from "@/api/enums/selection-type-enum";

export default defineComponent({
  name: "ProductSelectionProducts",
  components: {AlertError,
    ProductSelectionProductsFilters,
    ProductSelectionProductsSelection,
    ProductSelectionProductsProductDetail,
    BaseOffcanvas,
  },
  emits: ["savedSuccessfully"],
  data: () => ({
    exceptions: [] as Exception[],

    isReadyBase: false as Boolean,
    isReadyInclude: false as Boolean,
    isReadyExclude: false as Boolean,

    offcanvasProductDetail: null as Offcanvas | null,
    offcanvasProductDetailId: generateDomId('offcanvas-product-detail') as string,
    selectedProductDto: null as SelectableProductRestDto | null,
  }),
  mounted() {
    this.offcanvasProductDetail = new Offcanvas('#' + this.offcanvasProductDetailId);
    this.reloadContent(false);
  },
  computed: {
    AbilityContext() {
      return AbilityContext
    },
  },
  methods: {
    ...mapActions('cpc_mgmt', [
      'resetCurrentPage',
      'searchProductSelectionProducts',
      'saveProductSelectionProduct'
    ]),
    resetAllCurrentPages() {
      this.resetCurrentPage({
        filter_selection_type_code: SelectionTypeEnum.BASE_SELECTION,
        type: 'PRODUCT'
      });
      this.resetCurrentPage({
        filter_selection_type_code: SelectionTypeEnum.INCLUDE,
        type: 'PRODUCT'
      });
      this.resetCurrentPage({
        filter_selection_type_code: SelectionTypeEnum.EXCLUDE,
        type: 'PRODUCT'
      });
    },
    setIsReady(filter_selection_type_code: string, value: boolean) {
      switch (filter_selection_type_code) {
        case SelectionTypeEnum.BASE_SELECTION:
          this.isReadyBase = value;
          break;
        case SelectionTypeEnum.INCLUDE:
          this.isReadyInclude = value;
          break;
        case SelectionTypeEnum.EXCLUDE:
          this.isReadyExclude = value;
          break;
        default:
          throw new Error('Unsupported isReady to search ' + filter_selection_type_code);
      }
    },
    async submitSearch() {
      //When initiating a search, set all pages back to 1
      this.resetAllCurrentPages();
      await this.reloadContent(true);
    },
    async reloadContent(isBaseSelectionNeeded : boolean) {
      try {
        const promises = [
          this.searchProducts(SelectionTypeEnum.INCLUDE),
          this.searchProducts(SelectionTypeEnum.EXCLUDE)
        ];
        if (isBaseSelectionNeeded) {
          promises.unshift(this.searchProducts(SelectionTypeEnum.BASE_SELECTION));
        } else {
          this.setIsReady(SelectionTypeEnum.BASE_SELECTION, true);
        }
        await Promise.all(promises);
        await Promise.all([
        ]);
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async searchProducts(filter_selection_type_code: string) {
      this.setIsReady(filter_selection_type_code, false);

      try {
        await this.searchProductSelectionProducts({
          filter_selection_type_code: filter_selection_type_code,
        });
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      this.setIsReady(filter_selection_type_code, true);
    },
    async updateProductItem(product_code: string, selection_type_code: SelectionTypeEnum) {
      this.exceptions = [];

      try {
        // console.log(`Update product with code: ${product_code} (${selection_type_code})`);
        await this.saveProductSelectionProduct({
          product_code: product_code,
          selection_type_code: selection_type_code,
        });
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      await this.reloadContent(true);
    },
    onOpenProductDetail(selectedProduct: SelectableProductRestDto): void {
      this.selectedProductDto = selectedProduct;
      this.offcanvasProductDetail?.show();
    },
  }
});
