import {createInstance} from '@/helpers/axios';
import {config} from '@/helpers/fetch-config';

const axiosOptions = {
    baseURL: config.contentBlock
};

const cmsEnumInstance = createInstance(axiosOptions);

// possibility to add instance specific configuration like interceptors etc.

export default cmsEnumInstance;
