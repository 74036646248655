
import {mapActions} from "vuex";
import {defineComponent} from "vue";
import {
  ProductFilterConditionRestDto,
  ProductFilterCriteriumBrandRestDto,
} from "@/api/pharma-cpc-mgmt/models";
import {DpException} from "@/exception/DpException";
import {PropType} from "vue/dist/vue";
import {ProductFilterApplyTypeEnum} from "@/models/enum/ProductFilterApplyTypeEnum";
import SelectBrands from "@/components/layouts/catalog/SelectBrands.vue";
import {SelectionTypeEnum} from "@/api/enums/selection-type-enum";

export default defineComponent({
  name: "ProductFilterBrands",
  components: {SelectBrands},
  props: {
    applyOnType: {
      type: String as PropType<ProductFilterApplyTypeEnum>,
      required: true
    },
    filterIndex: {
      type: Number,
      required: true,
    },
    filterConditionObject: {
      type: Object,
      required: true,
    },
    selectionType: {
      type: String,
      required: true,
    },
  },
  computed: {
    filterCondition(): ProductFilterConditionRestDto {
      return this.filterConditionObject;
    },
    productBrandFilters(): ProductFilterCriteriumBrandRestDto[] {
      return this.filterCondition.product_brand_filters ?? [];
    },
    productBrandFilterCodes(): string[] {
      const productBrandFilterCodes = [] as string[];
      for (const productBrandFilter of this.productBrandFilters) {
        if (productBrandFilter.selection_type_code === this.selectionTypeCode) {
          productBrandFilterCodes.push(productBrandFilter.brand_code);
        }
      }
      return productBrandFilterCodes;
    },
    selectionTypeCode(): SelectionTypeEnum {
      switch (this.selectionType.toUpperCase()) {
        case 'INCLUDE':
          return SelectionTypeEnum.INCLUDE;
        case 'EXCLUDE':
          return SelectionTypeEnum.EXCLUDE;
        default:
          throw [new DpException('Unsupported selectionType to search for discount brand filter')];
      }
    }
  },
  methods: {
    ...mapActions('cpc_mgmt', ['addProductBrandFilter', 'removeProductBrandFilter']),
    addBrand(brand_code: string) {
      this.addProductBrandFilter({
        filter_index: this.filterIndex,
        product_brand_filter: {
          selection_type_code: this.selectionTypeCode,
          brand_code: brand_code,
        } as ProductFilterCriteriumBrandRestDto,
        apply_on_type: this.applyOnType
      });
    },
    removeBrand(brand_code: string) {
      this.removeProductBrandFilter({
        filter_index: this.filterIndex,
        product_brand_filter: {
          selection_type_code: this.selectionTypeCode,
          brand_code: brand_code,
        } as ProductFilterCriteriumBrandRestDto,
        apply_on_type: this.applyOnType
      });
    },
  }
});
