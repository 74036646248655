import {NormalContentBlockModel} from "@/models/api/pharma-cms-content-block/NormalContentBlockModel";

export class SaveNormalContentBlockPayload {
    id: string;
    type: string;
    cb: NormalContentBlockModel;

    constructor(id: string, type: string, cb: NormalContentBlockModel) {
        this.id = id;
        this.type = type;
        this.cb = cb;
    }

    public static create(id: string, type: string, cb: NormalContentBlockModel): SaveNormalContentBlockPayload {
        return new SaveNormalContentBlockPayload(id, type, cb);
    }
}
