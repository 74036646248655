
import {defineComponent} from "vue";
import {PropType} from "vue/dist/vue";
import {SelectableProductRestDto} from "@/api/pharma-cpc-mgmt/models";

export default defineComponent({
  name: "ProductSelectionProductsSelectionItemBadge",
  props: {
    productItem: {
      type: Object as PropType<SelectableProductRestDto>,
      required: true,
    },
  },
  computed: {
    isInBaseSelection(): boolean {
      return this.productItem.base_selection_product_count > 0;
    },
    isInCustomerCatalog(): boolean {
      return this.productItem.customer_catalog_product_count > 0;
    },
    tooltipInfo(): object {
      let toolTipInfo = {
        title: '' as string,
        class: '' as string
      };

      if (!this.isInCustomerCatalog && this.isInBaseSelection) {
        toolTipInfo.title = this.$t('legendProductSelectionProductItemNotOnWebsite');
        toolTipInfo.class = 'not-on-website';
      } else if (this.isInCustomerCatalog && this.isInBaseSelection) {
        toolTipInfo.title = this.$t('legendProductSelectionProductItemOnWebsiteInBaseSelection');
        toolTipInfo.class = 'on-website-in-base';
      } else if (this.isInCustomerCatalog) {
        toolTipInfo.title = this.$t('legendProductSelectionProductItemOnWebsite');
        toolTipInfo.class = 'on-website';
      }

      return toolTipInfo;
    },
  }
});
