
import {defineComponent} from "vue";
import BaseAlert from "@/components/UI/Bootstrap/Alert/BaseAlert.vue";

export default defineComponent({
  name: "AlertInfo",
  components: {BaseAlert},
  props: {
    message: {
      type: String,
      required: true,
    },
  },
});
