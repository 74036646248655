import {ProductFilterConditionRestDto} from "@/api/pharma-cpc-mgmt/models";
import {DpException} from "@/exception/DpException";

/*
* Determines the type of filter condition
*
* Receives a ProductFilterConditionRestDto
* Returns a string value that is either: "all", "cnk-list" or "brands-categories"
*/
function determineProductFilterConditionType(filter_condition: ProductFilterConditionRestDto): string {
    if (filter_condition.include_all_products !== undefined && filter_condition.include_all_products) {
        return 'all';
    } else if (filter_condition.cnk_codes_included !== undefined) {
        return 'cnk-list';
    } else if (filter_condition.product_trademark_filters !== undefined) {
        return 'trademarks-categories';
    } else if (filter_condition.product_brand_filters !== undefined || filter_condition.product_category_filters !== undefined) {
        return 'brands-categories';
    } else {
        //TODO ask proper validation
        throw [new DpException('Filter condition is built incorrectly')];
    }
}


export {determineProductFilterConditionType};
