
import {defineComponent} from "vue";
import {mapGetters} from "vuex";
import {DiscountSchemeOverviewRestDto, PricelistOverviewRestDto} from "@/api/pharma-cpc-mgmt/models";
import DiscountsPriceListsOverviewItem
  from "@/components/layouts/catalog/discounts-pricelists/DiscountsPriceListsOverviewItem.vue";
import {MoveActionEnum} from "@/models/enum/MoveActionEnum";

export default defineComponent({
  name: "DiscountsPriceListsOverview",
  components: {DiscountsPriceListsOverviewItem},
  emits: ["deleteDiscountScheme", "deletePriceList", "moveDiscountScheme", "movePriceList"],
  props: {
    type: {
      type: String,
      required: true,
      validate(value: string) {
        return (value === 'DISCOUNT') || (value === 'PRICELIST');
      }
    },
    isReady: {
      type: Boolean,
      required: true,
    }
  },
  computed: {
    ...mapGetters('cpc_mgmt', ['getDiscountSchemes', 'getPriceLists']),
    isDiscount(): Boolean {
      return this.type === 'DISCOUNT';
    },
    isPriceList(): Boolean {
      return this.type === 'PRICELIST';
    },
    discountSchemes(): DiscountSchemeOverviewRestDto[] {
      return this.getDiscountSchemes;
    },
    priceLists(): PricelistOverviewRestDto[] {
      return this.getPriceLists;
    },
    hasDiscountSchemes(): Boolean {
      return (Array.isArray(this.discountSchemes) && this.discountSchemes.length > 0);
    },
    hasPriceLists(): Boolean {
      return (Array.isArray(this.priceLists) && this.priceLists.length > 0);
    }
  },
  methods: {
    deleteScheme(id: string) {
      this.isDiscount
          ? this.$emit('deleteDiscountScheme', id)
          : this.$emit('deletePriceList', id);
    },
    moveScheme(id: string, moveAction: MoveActionEnum) {
      this.isDiscount
          ? this.$emit('moveDiscountScheme', id, moveAction)
          : this.$emit('movePriceList', id, moveAction);
    },
  }
});
