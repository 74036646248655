import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xxl-8 col-xl-12 mb-4" }
const _hoisted_3 = { class: "row align-items-center" }
const _hoisted_4 = { class: "button" }
const _hoisted_5 = {
  key: 0,
  class: "d-flex align-items-center"
}
const _hoisted_6 = { class: "mb-2" }
const _hoisted_7 = { class: "button-text-url row align-items-center" }
const _hoisted_8 = {
  key: 0,
  class: "col"
}
const _hoisted_9 = { class: "col" }
const _hoisted_10 = {
  key: 1,
  class: "button-target row"
}
const _hoisted_11 = { class: "d-inline-block mt-3 pt-4 border-top border-2 border-light" }
const _hoisted_12 = {
  type: "submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_text = _resolveComponent("input-text")!
  const _component_input_url = _resolveComponent("input-url")!
  const _component_input_select = _resolveComponent("input-select")!
  const _component_input_number = _resolveComponent("input-number")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_dp_form = _resolveComponent("dp-form")!
  const _component_cb_form = _resolveComponent("cb-form")!

  return (_openBlock(), _createBlock(_component_cb_form, {
    onCopiedLibCb: this.copiedLibContentBlock,
    "cb-type": this.cbType,
    exceptions: this.exceptions,
    "can-copy-from-library": this.canCopyFromLibrary,
    "is-ready": this.isReady,
    "saved-successfully": this.savedSuccessfully,
    title: this.title ?? null,
    description: this.description,
    description2: this.description2,
    description3: this.description3,
    description4: this.description4
  }, {
    default: _withCtx(() => [
      _createVNode(_component_dp_form, {
        as: "form",
        onSubmit: _ctx.submitData,
        novalidate: ""
      }, {
        default: _withCtx(({ errors }) => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("section", _hoisted_4, [
                  (_ctx.isButton)
                    ? (_openBlock(), _createElementBlock("header", _hoisted_5, [
                        _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t('button')), 1),
                        _createElementVNode("button", {
                          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.clearSimpleContentBlock(_ctx.cbType)), ["prevent"])),
                          class: "btn btn-danger mb-2 ms-4"
                        }, _toDisplayString(_ctx.$t('dontUseButton')), 1)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_7, [
                    (_ctx.hasText)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createVNode(_component_input_text, {
                            label: _ctx.$t('buttonText'),
                            placeholder: "buttonText",
                            name: "buttonText",
                            modelValue: _ctx.contentBlock.button.button_text.nl,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.contentBlock.button.button_text.nl) = $event)),
                            rules: "both_set:@buttonUrl",
                            errors: errors
                          }, null, 8, ["label", "modelValue", "errors"])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_9, [
                      (_ctx.isButton)
                        ? (_openBlock(), _createBlock(_component_input_url, {
                            key: 0,
                            label: _ctx.$t('buttonUrl'),
                            placeholder: "buttonUrl",
                            name: "buttonUrl",
                            modelValue: _ctx.contentBlock.button.button_url.nl,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.contentBlock.button.button_url.nl) = $event)),
                            rules: (_ctx.hasText) ? 'both_set:@buttonText' : '',
                            errors: errors
                          }, null, 8, ["label", "modelValue", "rules", "errors"]))
                        : (_openBlock(), _createBlock(_component_input_url, {
                            key: 1,
                            modelValue: _ctx.contentBlock.button.button_url.nl,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.contentBlock.button.button_url.nl) = $event)),
                            placeholder: "https://www.mijnapotheek.be",
                            label: _ctx.$t('url'),
                            name: "url",
                            rules: _ctx.isNotRequired ? '' : 'required',
                            errors: errors
                          }, null, 8, ["modelValue", "label", "rules", "errors"]))
                    ])
                  ]),
                  (_ctx.hasTarget)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createVNode(_component_input_select, {
                          class: "col-5",
                          name: "buttonTarget",
                          selectText: _ctx.$t('selectText'),
                          modelValue: _ctx.contentBlock.button.button_target,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.contentBlock.button.button_target) = $event)),
                          options: _ctx.extLinkOptions,
                          errors: errors
                        }, null, 8, ["selectText", "modelValue", "options", "errors"])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            (this.mode !== 'new' && this.isLibrary)
              ? (_openBlock(), _createBlock(_component_input_number, {
                  key: 0,
                  label: _ctx.$t('sortWeight'),
                  placeholder: "sortWeight",
                  name: "sortWeight",
                  modelValue: _ctx.contentBlock.sort_weight,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.contentBlock.sort_weight) = $event)),
                  errors: errors
                }, null, 8, ["label", "modelValue", "errors"]))
              : _createCommentVNode("", true),
            (_ctx.isPublishable)
              ? (_openBlock(), _createBlock(_component_input_select, {
                  key: 1,
                  name: "cbStatus",
                  class: "fw-bold",
                  selectText: _ctx.$t('selectText'),
                  modelValue: _ctx.contentBlock.status_code,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.contentBlock.status_code) = $event)),
                  options: _ctx.statusOptions,
                  errors: errors
                }, null, 8, ["selectText", "modelValue", "options", "errors"]))
              : _createCommentVNode("", true),
            _createElementVNode("button", _hoisted_12, _toDisplayString(_ctx.$t('save')), 1),
            _createVNode(_component_router_link, {
              to: {name: (_ctx.returnRoute) ? _ctx.returnRoute : 'home'},
              class: "ms-3 btn btn-outline-primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }, 8, ["onCopiedLibCb", "cb-type", "exceptions", "can-copy-from-library", "is-ready", "saved-successfully", "title", "description", "description2", "description3", "description4"]))
}