
import {defineComponent} from "vue";
import {UIStateDto} from "@/dtos/UIStateDto";
import {MonthlyDashboardRowRestDto, MonthlyDashboardTypeOverviewRestDto} from "@/api/pharma-master-dashboard/models";
import {DashboardRestService} from "@/services/rest/master/DashboardRestService";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {Exception} from "@/api/interfaces";
import {DpModuleEnum} from "@/api/enums/dp-module-enum";
import {getMonthsForLocale} from "@/helpers/functions/date";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import {arrayHasContent} from "@/helpers/functions/arrays";
import {DpExceptions} from "@/error/DpExceptions";
import BaseAccordion from "@/components/UI/Bootstrap/Accordion/BaseAccordion.vue";
import BaseAccordionItem from "@/components/UI/Bootstrap/Accordion/BaseAccordionItem.vue";
import {toInitCapitalize} from "@/helpers/functions/string";

let Lodash = require("lodash");

export default defineComponent({
  name: "Dashboard",
  components: {BaseAccordionItem, BaseAccordion, BaseSpinner, AlertError2},
  props: {
    dashboardTypeCode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      monthlyDashboards: [] as MonthlyDashboardRowRestDto[],
      monthlyDashboardPerCustomer: undefined as any,
      monthlyDashboardTypes: [] as MonthlyDashboardTypeOverviewRestDto[],
      dashboardUI: UIStateDto.createWithDefaults(),

      filters: {
        dashboardTypeCode: '' as string,
        xDpModuleCode: undefined as DpModuleEnum | undefined,
      }
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    hasMonthlyDashboards(): boolean {
      return arrayHasContent(this.monthlyDashboards);
    }
  },
  methods: {
    getMonthsForLocale,
    toInitCapitalize,
    catchExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.dashboardUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.dashboardUI as UIStateDto))
        .catch(exceptions, true);
    },
    async onDashboardTypeChanged(dashboardTypeCode: string): Promise<void> {
      try {
        this.filters.dashboardTypeCode = dashboardTypeCode;

        const moduleCode = this.monthlyDashboardTypes
          .find((monthlyDashboardType: MonthlyDashboardTypeOverviewRestDto) => monthlyDashboardType.type.code === dashboardTypeCode)
          ?.dp_module_code as DpModuleEnum | undefined;
        if (!moduleCode) {
          throw new DpExceptions([
            {message: 'No dp_module_code found for this dashboard type.'} as Exception
          ]);
        }
        this.filters.xDpModuleCode = moduleCode;

        await this.searchDashboard();
      } catch (exceptions: unknown) {
        this.catchExceptions(exceptions);
      }
    },
    async reloadContent(): Promise<void> {
      this.dashboardUI
        .clearError()
        .setNotReady();

      try {
        this.monthlyDashboardTypes = await DashboardRestService.getInstance().findMonthlyDashboardTypes();

        if (arrayHasContent(this.monthlyDashboardTypes)) {
          this.filters.dashboardTypeCode = this.dashboardTypeCode;

          const moduleCode = this.monthlyDashboardTypes
            .find((monthlyDashboardType: MonthlyDashboardTypeOverviewRestDto) => monthlyDashboardType.type.code === this.dashboardTypeCode)
            ?.dp_module_code as DpModuleEnum | undefined;
          if (!moduleCode) {
            throw new DpExceptions([
              {message: 'No dp_module_code found for this dashboard type.'} as Exception
            ]);
          }
          this.filters.xDpModuleCode = moduleCode;

          await this.searchDashboard();
        }
      } catch (exceptions: unknown) {
        this.catchExceptions(exceptions);
      } finally {
        this.dashboardUI.setReady();
      }
    },
    async searchDashboard(): Promise<void> {
      this.dashboardUI
        .clearError()
        .setNotReady();

      try {
        // Search and sort by name and year respectively
        this.monthlyDashboards = (await DashboardRestService.getInstance()
          .findMonthlyDashboardsForType(
            this.filters.dashboardTypeCode,
            undefined,
            this.filters.xDpModuleCode
          ))
          .sort((a: MonthlyDashboardRowRestDto, b: MonthlyDashboardRowRestDto) => {
            const nameA = a.customer.name.toUpperCase();
            const nameB = b.customer.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            return 0;
          })
          .sort((a: MonthlyDashboardRowRestDto, b: MonthlyDashboardRowRestDto) => a.year - b.year);

        //Group by customer
        this.monthlyDashboardPerCustomer = Lodash.groupBy(this.monthlyDashboards, "customer.customer_code");
      } catch (exceptions: unknown) {
        this.catchExceptions(exceptions);
      } finally {
        this.dashboardUI.setReady();
      }
    }
  }
});
