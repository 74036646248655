import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";

enum ContentBlockTypesEnum {
    ARTICLE_HEADING = "PAGE_CONTENT_ARTICLE_HEADING",
    BANNER = "PAGE_CONTENT_CB_BANNER",
    BANNER_SLOT = "PAGE_CONTENT_CB_BANNER_SLOT",
    BUTTON = "PAGE_CONTENT_CB_BUTTON",
    CALL_TO_ACTION = "PAGE_CONTENT_CALL_TO_ACTION",
    CONTACT_FORM = "PAGE_CONTENT_CONTACT_FORM",
    DOCUMENT = "PAGE_CONTENT_DOCUMENT",
    GENERAL_TERMS = 'GENERAL_TERMS',
    HTML = "PAGE_CONTENT_HTML",
    LOGO = "LOGO_IMG_ID",
    IMAGE = "PAGE_CONTENT_CB_IMAGE",
    PAGE_REFERENCES = "PAGE_CONTENT_CB_PAGE_REFERENCES",
    PAGE_TITLE = "PAGE_CONTENT_TITLE",
    PHARMACY_INFO = "PAGE_CONTENT_PHARMACY_INFO",
    PRIVACY_POLICY = 'PRIVACY_POLICY',
    PRODUCT_FILTER_AS_GRID = "PAGE_CONTENT_PRODUCT_FILTER_AS_GRID",
    PRODUCT_FILTER_AS_SLIDER = "PAGE_CONTENT_PRODUCT_FILTER_AS_SLIDER",
    ROW_SEPARATOR = "PAGE_CONTENT_CB_ROW_SEPARATOR",
    SYMBOL = "PAGE_CONTENT_CB_SYMBOL",
    TEAM_INFO = "PAGE_CONTENT_TEAM_INFO",
    TEXT = "PAGE_CONTENT_CB_TEXT",
    TEXT_CARD = "PAGE_CONTENT_TEXT_CARD",
    TEXT_WITH_IMAGE = "PAGE_CONTENT_CB_TEXT_WITH_IMAGE",
    TEXT_WITH_SYMBOL = "PAGE_CONTENT_CB_TEXT_WITH_SYMBOL",
    TEXT_WITH_TITLE = "PAGE_CONTENT_CB_TEXT_WITH_TITLE",
    OVERVIEW_INFO = "PAGE_CONTENT_OVERVIEW_INFO",
}

enum ContentBlockInRowStructuresEnum {
    ARTICLE_HEADING = "ARTICLE_HEADING",
    BANNER = "BANNER",
    BANNER_SLOT = "BANNER_SLOT",
    BUTTON = "BUTTON",
    CALL_TO_ACTION = "CALL_TO_ACTION",
    CONTACT_FORM = "CONTACT_FORM",
    DOCUMENT = "DOCUMENT",
    HTML = "HTML",
    IMAGE = "IMAGE",
    PAGE_REFERENCES = "PAGE_REFERENCES",
    PAGE_TITLE = "PAGE_TITLE",
    PHARMACY_INFO = "PHARMACY_INFO",
    PRODUCT_FILTER_AS_GRID = "PRODUCT_FILTER_AS_GRID",
    PRODUCT_FILTER_AS_SLIDER = "PRODUCT_FILTER_AS_SLIDER",
    ROW_SEPARATOR = "ROW_SEPARATOR",
    TEAM_INFO = "TEAM_INFO",
    TEXT = "TEXT",
    TEXT_CARD = "TEXT_CARD",
    TEXT_WITH_IMAGE = "TEXT_WITH_IMAGE",
    TEXT_WITH_TITLE = "TEXT_WITH_TITLE",
}

/*
* Creates an array from an enumeration
* Receives an enumeration with key-value pairs
* Returns a CodeDescription array
*/
function createCodeDescriptionArrayFromEnum(enumeration: any): CodeDescriptionRestDto[] {
    const array = [] as CodeDescriptionRestDto[];

    const keys = Object.keys(enumeration) as [];
    const values = Object.values(enumeration) as [];
    keys.forEach((key, index) => {
        array.push({
            code: values[index],
            description: key,
        })
    });

    return array;
}


export {
    ContentBlockTypesEnum,
    ContentBlockInRowStructuresEnum,
    createCodeDescriptionArrayFromEnum
};
