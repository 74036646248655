
import {mapActions} from "vuex";
import {defineComponent} from "vue";
import {
  ProductFilterConditionRestDto, ProductFilterCriteriumCategoryRestDto,
} from "@/api/pharma-cpc-mgmt/models";
import {Exception} from "@/api/interfaces";
import {DpException} from "@/exception/DpException";
import SelectCategories from "@/components/layouts/catalog/SelectCategories.vue";
import {PropType} from "vue/dist/vue";
import {ProductFilterApplyTypeEnum} from "@/models/enum/ProductFilterApplyTypeEnum";
import {SelectionTypeEnum} from "@/api/enums/selection-type-enum";

export default defineComponent({
  name: "ProductFilterCategories",
  components: {SelectCategories},
  props: {
    applyOnType: {
      type: String as PropType<ProductFilterApplyTypeEnum>,
      required: true
    },
    filterIndex: {
      type: Number,
      required: true,
    },
    filterConditionObject: {
      type: Object,
      required: true,
    },
    selectionType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      exceptions: [] as Exception[],
    }
  },
  computed: {
    filterCondition(): ProductFilterConditionRestDto {
      return this.filterConditionObject;
    },
    productCategoryFilters(): ProductFilterCriteriumCategoryRestDto[] {
      return this.filterCondition.product_category_filters ?? [];
    },
    // productCategoryFilterCodes(): string[] {
    //   const productCategoryFilterCodes = [] as string[];
    //   for (const productCategoryFilter of this.productCategoryFilters) {
    //     if (productCategoryFilter.selection_type_code === this.selectionTypeCode) {
    //       productCategoryFilterCodes.push(productCategoryFilter.category_code);
    //     }
    //   }
    //
    //   return productCategoryFilterCodes;
    // },
    selectionTypeCode(): SelectionTypeEnum {
      switch (this.selectionType.toUpperCase()) {
        case 'INCLUDE':
          return SelectionTypeEnum.INCLUDE;
        case 'EXCLUDE':
          return SelectionTypeEnum.EXCLUDE;
        default:
          throw [new DpException('Unsupported selectionType to search for discount category filter')];
      }
    },
  },
  methods: {
    ...mapActions('cpc_mgmt', ['addProductCategoryFilter', 'removeProductCategoryFilter']),
    addCategory(category_code: string) {
      this.addProductCategoryFilter({
        filter_index: this.filterIndex,
        product_category_filter: {
          selection_type_code: this.selectionTypeCode,
          category_code: category_code,
        } as ProductFilterCriteriumCategoryRestDto,
        apply_on_type: this.applyOnType
      });
    },
    removeCategory(category_code: string) {
      this.removeProductCategoryFilter({
        filter_index: this.filterIndex,
        product_category_filter: {
          selection_type_code: this.selectionTypeCode,
          category_code: category_code,
        } as ProductFilterCriteriumCategoryRestDto,
        apply_on_type: this.applyOnType
      });
    },
  }
});
