import {Singleton} from "@/patterns/Singleton";
import {Config} from "@/models/facade/Config";
import {CustomerContext} from "@/context/CustomerContext";

export class Service extends Singleton {
    protected constructor() {
        super();
    }

    protected getConfig(): any {
        return Config.getInstance().getConfig();
    }

    protected getCustomerCode(): string {
        return CustomerContext.getCustomerCode();
    }

    protected getPharmacyCode(): string {
        return CustomerContext.getPharmacyCode();
    }
}
