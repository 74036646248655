import {FindNormalContentBlockPayload} from "@/models/payload/FindNormalContentBlockPayload";
import {ContentBlockEndpointsApiService} from "@/main";
import {SaveNormalContentBlockPayload} from "@/models/payload/SaveNormalContentBlockPayload";
import {CreateNormalContentBlockPayload} from "@/models/payload/CreateNormalContentBlockPayload";
import {RowSeparatorContentRestDtoModel} from "@/models/RowSeparatorContentRestDtoModel";

export default {
    namespaced: true,
    actions: {
        async create(context: any, payload: CreateNormalContentBlockPayload): Promise<string> {
            console.log('RowSeparator::create()');
            const response = await ContentBlockEndpointsApiService.createNormalContentBlockRowSeparator(payload.cb as RowSeparatorContentRestDtoModel, payload.type);

            return response.data.id as string;
        },
        async find(context: any, payload: FindNormalContentBlockPayload): Promise<RowSeparatorContentRestDtoModel> {
            console.log('RowSeparator::find()');
            const response = await ContentBlockEndpointsApiService.getNormalContentBlockRowSeparator(payload.type, payload.id);

            return response.data as RowSeparatorContentRestDtoModel;
        },
        async save(context: any, payload: SaveNormalContentBlockPayload): Promise<void> {
            console.log('RowSeparator::save()');
            await ContentBlockEndpointsApiService.updateNormalContentBlockRowSeparator(payload.cb as RowSeparatorContentRestDtoModel, payload.type, payload.id);
        },
    }
};
