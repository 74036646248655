/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Order Settings API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { DeliveryCostRuleOverviewResultsRestDto } from '../models';
import { DeliveryCostRuleRestDto } from '../models';
import { DeliveryMethodOverviewResultsRestDto } from '../models';
import { NameValuePairCompactRestDto } from '../models';
import { NameValuePairOverviewRestDto } from '../models';
import { NameValuePairRestDto } from '../models';
import { NameValuePairValueRestDto } from '../models';
import { SynchronizeSupportedDeliveryMethodsRestDto } from '../models';
/**
 * OrderSettingsAPIApi - axios parameter creator
 * @export
 */
export const OrderSettingsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.ORD.SET.13 Create a new delivery cost rule.
         * @param {DeliveryCostRuleRestDto} body 
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDeliveryCostRule: async (body: DeliveryCostRuleRestDto, pharmacy_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addDeliveryCostRule.');
            }
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling addDeliveryCostRule.');
            }
            const localVarPath = `/settings/v1/pharmacy/{pharmacy_code}/delivery_cost_rule`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.16 Delete Delivery Cost Rule.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} id Uniquely identifies the entity
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeliveryCostRule: async (pharmacy_code: string, id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling deleteDeliveryCostRule.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDeliveryCostRule.');
            }
            const localVarPath = `/settings/v1/pharmacy/{pharmacy_code}/delivery_cost_rule/{id}`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.11 Find the delivery methods this customer is authorized to use.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAuthorizedDeliveryMethods: async (x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/v1/authorized_delivery_method`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.12 Find the delivery cost rules.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDeliveryCostRules: async (pharmacy_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling findDeliveryCostRules.');
            }
            const localVarPath = `/settings/v1/pharmacy/{pharmacy_code}/delivery_cost_rule`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.01 Find name value pairs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findNvps: async (x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/v1/nvp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.05 Find name value pairs for pharmacy.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPharmacyNvps: async (pharmacy_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling findPharmacyNvps.');
            }
            const localVarPath = `/settings/v1/pharmacy/{pharmacy_code}/nvp`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.09 Find the supported delivery methods.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSupportedDeliveryMethods: async (pharmacy_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling findSupportedDeliveryMethods.');
            }
            const localVarPath = `/settings/v1/pharmacy/{pharmacy_code}/delivery_method`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.14 Get a Delivery Cost Rule.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} id Uniquely identifies the entity
         * @param {string} [x_dp_customer_code] De unieke identificatie van de Customer, indien niét meegegeven, gaat het over Digital-Pharma content.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryCostRule: async (pharmacy_code: string, id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling getDeliveryCostRule.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDeliveryCostRule.');
            }
            const localVarPath = `/settings/v1/pharmacy/{pharmacy_code}/delivery_cost_rule/{id}`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.03 Get name value pair for name.
         * @param {string} name The name that uniquely identifies a NVP. (Only upper case characters and underscores allowed.)
         * @param {string} [x_dp_customer_code] De unieke identificatie van de Customer, indien niét meegegeven, gaat het over Digital-Pharma content.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNvpByName: async (name: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling getNvpByName.');
            }
            const localVarPath = `/settings/v1/nvp/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.07 Get Pharmacy name value pair for name.
         * @param {string} name The name that uniquely identifies a NVP. (Only upper case characters and underscores allowed.)
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} [x_dp_customer_code] De unieke identificatie van de Customer, indien niét meegegeven, gaat het over Digital-Pharma content.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPharmacyNvpByName: async (name: string, pharmacy_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling getPharmacyNvpByName.');
            }
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling getPharmacyNvpByName.');
            }
            const localVarPath = `/settings/v1/pharmacy/{pharmacy_code}/nvp/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.10 Synchronize the supported delivery methods.
         * @param {SynchronizeSupportedDeliveryMethodsRestDto} body 
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizeSupportedDeliveryMethods: async (body: SynchronizeSupportedDeliveryMethodsRestDto, pharmacy_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling synchronizeSupportedDeliveryMethods.');
            }
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling synchronizeSupportedDeliveryMethods.');
            }
            const localVarPath = `/settings/v1/pharmacy/{pharmacy_code}/delivery_method`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.15 Update Delivery Cost Rule.
         * @param {DeliveryCostRuleRestDto} body 
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} id Uniquely identifies the entity
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryCostRule: async (body: DeliveryCostRuleRestDto, pharmacy_code: string, id: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateDeliveryCostRule.');
            }
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling updateDeliveryCostRule.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateDeliveryCostRule.');
            }
            const localVarPath = `/settings/v1/pharmacy/{pharmacy_code}/delivery_cost_rule/{id}`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.02 Update name value pair for name.
         * @param {NameValuePairValueRestDto} body 
         * @param {string} name The name that uniquely identifies a NVP.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNvp: async (body: NameValuePairValueRestDto, name: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateNvp.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling updateNvp.');
            }
            const localVarPath = `/settings/v1/nvp/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.04 Update name value pairs in bulk.
         * @param {Array&lt;NameValuePairCompactRestDto&gt;} body 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNvpsInBulk: async (body: Array<NameValuePairCompactRestDto>, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateNvpsInBulk.');
            }
            const localVarPath = `/settings/v1/nvp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.08 Update Pharmacy name value pair for name.
         * @param {NameValuePairValueRestDto} body 
         * @param {string} name The name that uniquely identifies a NVP.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePharmacyNvp: async (body: NameValuePairValueRestDto, name: string, pharmacy_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updatePharmacyNvp.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling updatePharmacyNvp.');
            }
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling updatePharmacyNvp.');
            }
            const localVarPath = `/settings/v1/pharmacy/{pharmacy_code}/nvp/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.06 Update Pharmacy name value pairs in bulk.
         * @param {Array&lt;NameValuePairCompactRestDto&gt;} body 
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePharmacyNvpsInBulk: async (body: Array<NameValuePairCompactRestDto>, pharmacy_code: string, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updatePharmacyNvpsInBulk.');
            }
            // verify required parameter 'pharmacy_code' is not null or undefined
            if (pharmacy_code === null || pharmacy_code === undefined) {
                throw new RequiredError('pharmacy_code','Required parameter pharmacy_code was null or undefined when calling updatePharmacyNvpsInBulk.');
            }
            const localVarPath = `/settings/v1/pharmacy/{pharmacy_code}/nvp`
                .replace(`{${"pharmacy_code"}}`, encodeURIComponent(String(pharmacy_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderSettingsAPIApi - functional programming interface
 * @export
 */
export const OrderSettingsAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.ORD.SET.13 Create a new delivery cost rule.
         * @param {DeliveryCostRuleRestDto} body 
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDeliveryCostRule(_axios: AxiosInstance, body: DeliveryCostRuleRestDto, pharmacy_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrderSettingsAPIApiAxiosParamCreator(configuration).addDeliveryCostRule(body, pharmacy_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.16 Delete Delivery Cost Rule.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} id Uniquely identifies the entity
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeliveryCostRule(_axios: AxiosInstance, pharmacy_code: string, id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrderSettingsAPIApiAxiosParamCreator(configuration).deleteDeliveryCostRule(pharmacy_code, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.11 Find the delivery methods this customer is authorized to use.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAuthorizedDeliveryMethods(_axios: AxiosInstance, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryMethodOverviewResultsRestDto>> {
            const localVarAxiosArgs = await OrderSettingsAPIApiAxiosParamCreator(configuration).findAuthorizedDeliveryMethods(x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.12 Find the delivery cost rules.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findDeliveryCostRules(_axios: AxiosInstance, pharmacy_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryCostRuleOverviewResultsRestDto>> {
            const localVarAxiosArgs = await OrderSettingsAPIApiAxiosParamCreator(configuration).findDeliveryCostRules(pharmacy_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.01 Find name value pairs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findNvps(_axios: AxiosInstance, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NameValuePairOverviewRestDto>>> {
            const localVarAxiosArgs = await OrderSettingsAPIApiAxiosParamCreator(configuration).findNvps(x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.05 Find name value pairs for pharmacy.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPharmacyNvps(_axios: AxiosInstance, pharmacy_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NameValuePairOverviewRestDto>>> {
            const localVarAxiosArgs = await OrderSettingsAPIApiAxiosParamCreator(configuration).findPharmacyNvps(pharmacy_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.09 Find the supported delivery methods.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSupportedDeliveryMethods(_axios: AxiosInstance, pharmacy_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryMethodOverviewResultsRestDto>> {
            const localVarAxiosArgs = await OrderSettingsAPIApiAxiosParamCreator(configuration).findSupportedDeliveryMethods(pharmacy_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.14 Get a Delivery Cost Rule.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} id Uniquely identifies the entity
         * @param {string} [x_dp_customer_code] De unieke identificatie van de Customer, indien niét meegegeven, gaat het over Digital-Pharma content.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeliveryCostRule(_axios: AxiosInstance, pharmacy_code: string, id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryCostRuleRestDto>> {
            const localVarAxiosArgs = await OrderSettingsAPIApiAxiosParamCreator(configuration).getDeliveryCostRule(pharmacy_code, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.03 Get name value pair for name.
         * @param {string} name The name that uniquely identifies a NVP. (Only upper case characters and underscores allowed.)
         * @param {string} [x_dp_customer_code] De unieke identificatie van de Customer, indien niét meegegeven, gaat het over Digital-Pharma content.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNvpByName(_axios: AxiosInstance, name: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NameValuePairRestDto>> {
            const localVarAxiosArgs = await OrderSettingsAPIApiAxiosParamCreator(configuration).getNvpByName(name, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.07 Get Pharmacy name value pair for name.
         * @param {string} name The name that uniquely identifies a NVP. (Only upper case characters and underscores allowed.)
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} [x_dp_customer_code] De unieke identificatie van de Customer, indien niét meegegeven, gaat het over Digital-Pharma content.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPharmacyNvpByName(_axios: AxiosInstance, name: string, pharmacy_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NameValuePairRestDto>> {
            const localVarAxiosArgs = await OrderSettingsAPIApiAxiosParamCreator(configuration).getPharmacyNvpByName(name, pharmacy_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.10 Synchronize the supported delivery methods.
         * @param {SynchronizeSupportedDeliveryMethodsRestDto} body 
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async synchronizeSupportedDeliveryMethods(_axios: AxiosInstance, body: SynchronizeSupportedDeliveryMethodsRestDto, pharmacy_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrderSettingsAPIApiAxiosParamCreator(configuration).synchronizeSupportedDeliveryMethods(body, pharmacy_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.15 Update Delivery Cost Rule.
         * @param {DeliveryCostRuleRestDto} body 
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} id Uniquely identifies the entity
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeliveryCostRule(_axios: AxiosInstance, body: DeliveryCostRuleRestDto, pharmacy_code: string, id: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrderSettingsAPIApiAxiosParamCreator(configuration).updateDeliveryCostRule(body, pharmacy_code, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.02 Update name value pair for name.
         * @param {NameValuePairValueRestDto} body 
         * @param {string} name The name that uniquely identifies a NVP.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNvp(_axios: AxiosInstance, body: NameValuePairValueRestDto, name: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrderSettingsAPIApiAxiosParamCreator(configuration).updateNvp(body, name, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.04 Update name value pairs in bulk.
         * @param {Array&lt;NameValuePairCompactRestDto&gt;} body 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNvpsInBulk(_axios: AxiosInstance, body: Array<NameValuePairCompactRestDto>, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrderSettingsAPIApiAxiosParamCreator(configuration).updateNvpsInBulk(body, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.08 Update Pharmacy name value pair for name.
         * @param {NameValuePairValueRestDto} body 
         * @param {string} name The name that uniquely identifies a NVP.
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePharmacyNvp(_axios: AxiosInstance, body: NameValuePairValueRestDto, name: string, pharmacy_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrderSettingsAPIApiAxiosParamCreator(configuration).updatePharmacyNvp(body, name, pharmacy_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.ORD.SET.06 Update Pharmacy name value pairs in bulk.
         * @param {Array&lt;NameValuePairCompactRestDto&gt;} body 
         * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePharmacyNvpsInBulk(_axios: AxiosInstance, body: Array<NameValuePairCompactRestDto>, pharmacy_code: string, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrderSettingsAPIApiAxiosParamCreator(configuration).updatePharmacyNvpsInBulk(body, pharmacy_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OrderSettingsAPIApi - object-oriented interface
 * @export
 * @class OrderSettingsAPIApi
 * @extends {BaseAPI}
 */
export class OrderSettingsAPIApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.ORD.SET.13 Create a new delivery cost rule.
     * @param {DeliveryCostRuleRestDto} body 
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderSettingsAPIApi
     */
     public addDeliveryCostRule(body: DeliveryCostRuleRestDto, pharmacy_code: string, x_dp_customer_code?: string|null, options?: any) {
        return OrderSettingsAPIApiFp(this.configuration).addDeliveryCostRule(this.axios, body, pharmacy_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.ORD.SET.16 Delete Delivery Cost Rule.
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
     * @param {string} id Uniquely identifies the entity
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderSettingsAPIApi
     */
     public deleteDeliveryCostRule(pharmacy_code: string, id: string, x_dp_customer_code?: string|null, options?: any) {
        return OrderSettingsAPIApiFp(this.configuration).deleteDeliveryCostRule(this.axios, pharmacy_code, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.ORD.SET.11 Find the delivery methods this customer is authorized to use.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderSettingsAPIApi
     */
     public findAuthorizedDeliveryMethods(x_dp_customer_code?: string|null, options?: any) {
        return OrderSettingsAPIApiFp(this.configuration).findAuthorizedDeliveryMethods(this.axios, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.ORD.SET.12 Find the delivery cost rules.
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderSettingsAPIApi
     */
     public findDeliveryCostRules(pharmacy_code: string, x_dp_customer_code?: string|null, options?: any) {
        return OrderSettingsAPIApiFp(this.configuration).findDeliveryCostRules(this.axios, pharmacy_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.ORD.SET.01 Find name value pairs.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderSettingsAPIApi
     */
     public findNvps(x_dp_customer_code?: string|null, options?: any) {
        return OrderSettingsAPIApiFp(this.configuration).findNvps(this.axios, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.ORD.SET.05 Find name value pairs for pharmacy.
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderSettingsAPIApi
     */
     public findPharmacyNvps(pharmacy_code: string, x_dp_customer_code?: string|null, options?: any) {
        return OrderSettingsAPIApiFp(this.configuration).findPharmacyNvps(this.axios, pharmacy_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.ORD.SET.09 Find the supported delivery methods.
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderSettingsAPIApi
     */
     public findSupportedDeliveryMethods(pharmacy_code: string, x_dp_customer_code?: string|null, options?: any) {
        return OrderSettingsAPIApiFp(this.configuration).findSupportedDeliveryMethods(this.axios, pharmacy_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.ORD.SET.14 Get a Delivery Cost Rule.
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
     * @param {string} id Uniquely identifies the entity
     * @param {string} [x_dp_customer_code] De unieke identificatie van de Customer, indien niét meegegeven, gaat het over Digital-Pharma content.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderSettingsAPIApi
     */
     public getDeliveryCostRule(pharmacy_code: string, id: string, x_dp_customer_code?: string|null, options?: any) {
        return OrderSettingsAPIApiFp(this.configuration).getDeliveryCostRule(this.axios, pharmacy_code, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.ORD.SET.03 Get name value pair for name.
     * @param {string} name The name that uniquely identifies a NVP. (Only upper case characters and underscores allowed.)
     * @param {string} [x_dp_customer_code] De unieke identificatie van de Customer, indien niét meegegeven, gaat het over Digital-Pharma content.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderSettingsAPIApi
     */
     public getNvpByName(name: string, x_dp_customer_code?: string|null, options?: any) {
        return OrderSettingsAPIApiFp(this.configuration).getNvpByName(this.axios, name, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.ORD.SET.07 Get Pharmacy name value pair for name.
     * @param {string} name The name that uniquely identifies a NVP. (Only upper case characters and underscores allowed.)
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
     * @param {string} [x_dp_customer_code] De unieke identificatie van de Customer, indien niét meegegeven, gaat het over Digital-Pharma content.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderSettingsAPIApi
     */
     public getPharmacyNvpByName(name: string, pharmacy_code: string, x_dp_customer_code?: string|null, options?: any) {
        return OrderSettingsAPIApiFp(this.configuration).getPharmacyNvpByName(this.axios, name, pharmacy_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.ORD.SET.10 Synchronize the supported delivery methods.
     * @param {SynchronizeSupportedDeliveryMethodsRestDto} body 
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderSettingsAPIApi
     */
     public synchronizeSupportedDeliveryMethods(body: SynchronizeSupportedDeliveryMethodsRestDto, pharmacy_code: string, x_dp_customer_code?: string|null, options?: any) {
        return OrderSettingsAPIApiFp(this.configuration).synchronizeSupportedDeliveryMethods(this.axios, body, pharmacy_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.ORD.SET.15 Update Delivery Cost Rule.
     * @param {DeliveryCostRuleRestDto} body 
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
     * @param {string} id Uniquely identifies the entity
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderSettingsAPIApi
     */
     public updateDeliveryCostRule(body: DeliveryCostRuleRestDto, pharmacy_code: string, id: string, x_dp_customer_code?: string|null, options?: any) {
        return OrderSettingsAPIApiFp(this.configuration).updateDeliveryCostRule(this.axios, body, pharmacy_code, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.ORD.SET.02 Update name value pair for name.
     * @param {NameValuePairValueRestDto} body 
     * @param {string} name The name that uniquely identifies a NVP.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderSettingsAPIApi
     */
     public updateNvp(body: NameValuePairValueRestDto, name: string, x_dp_customer_code?: string|null, options?: any) {
        return OrderSettingsAPIApiFp(this.configuration).updateNvp(this.axios, body, name, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.ORD.SET.04 Update name value pairs in bulk.
     * @param {Array&lt;NameValuePairCompactRestDto&gt;} body 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderSettingsAPIApi
     */
     public updateNvpsInBulk(body: Array<NameValuePairCompactRestDto>, x_dp_customer_code?: string|null, options?: any) {
        return OrderSettingsAPIApiFp(this.configuration).updateNvpsInBulk(this.axios, body, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.ORD.SET.08 Update Pharmacy name value pair for name.
     * @param {NameValuePairValueRestDto} body 
     * @param {string} name The name that uniquely identifies a NVP.
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderSettingsAPIApi
     */
     public updatePharmacyNvp(body: NameValuePairValueRestDto, name: string, pharmacy_code: string, x_dp_customer_code?: string|null, options?: any) {
        return OrderSettingsAPIApiFp(this.configuration).updatePharmacyNvp(this.axios, body, name, pharmacy_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.ORD.SET.06 Update Pharmacy name value pairs in bulk.
     * @param {Array&lt;NameValuePairCompactRestDto&gt;} body 
     * @param {string} pharmacy_code The unique identifier of the pharmacy to which the reservation campaign belongs.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderSettingsAPIApi
     */
     public updatePharmacyNvpsInBulk(body: Array<NameValuePairCompactRestDto>, pharmacy_code: string, x_dp_customer_code?: string|null, options?: any) {
        return OrderSettingsAPIApiFp(this.configuration).updatePharmacyNvpsInBulk(this.axios, body, pharmacy_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
